import { Box, Stack} from '@mui/system';
import React, { useEffect, useState } from 'react';
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { Accordion, AccordionDetails, AccordionSummary, Button, CircularProgress, FormControl, InputLabel, MenuItem, Select, TextField, Typography } from '@mui/material';
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import TuneOutlinedIcon from "@mui/icons-material/TuneOutlined";

import { SuperAdminSidebar } from '../SidebarNavbar/SuperAdminSidebar';
import { SuperAdminNavbar } from '../SidebarNavbar/SuperAdminNavbar';
import { Apiaddress } from "../../../utils/consts";
import axios from 'axios';
import { Link, useLocation } from 'react-router-dom';
import { GridExpandMoreIcon } from '@mui/x-data-grid';
import LocationCityIcon from '@mui/icons-material/LocationCity';  
import AddIcon from '@mui/icons-material/Add';

function SuperAdminBuilder() {
  
  //get data from previous page 
    const location = useLocation();
    const data = location.state;
    console.log("preve data =>.>",data);

   let  token  = localStorage.getItem("crm_token")
    // console.log(token)
   const [curpage, setcurpage] = useState(1);
   const [pageRange, setPageRange] = useState([0, 5]);
   const [pages, setPages] = useState([]);
   const [trigger,setTrigger]=useState(false);
   const [error,seterror]=useState(false);

   const [credit,setcredit]=useState();
   const [credittoadd,setcredittoadd]=useState();
   const [plank,setplank]=useState(false);

   const [loading,setloading]= useState(true);

   const [newRowData,setnewRowData]= useState([]);

    const nextClick = () => {
    if (
      !(newRowData.length >= pageRange[0] && newRowData.length <= pageRange[1])
    ) {
      setcurpage(curpage + 1);
      setPageRange([pageRange[0] + 5, pageRange[1] + 5]);
    }
    };

  const prvClick = () => {
    if (pageRange[0] != 0 && pageRange[1] != 5) {
      setcurpage(curpage - 1);
      setPageRange([pageRange[0] - 5, pageRange[1] - 5]);
    }
    };

    useEffect(() => {
    if (newRowData) {
      let arr = [];
      for (
        let i = 1;
        i <=
        (newRowData.length % 5 == 0
          ? newRowData.length / 5
          : newRowData.length / 5 + 1);
        i++
      ) {
        arr.push(i);
      }
      setPages(arr);
    }
  }, [newRowData]);

   //fetching builderList
  // const fetchbuilderdata=async ()=>{
  //    const headers = {
  //       Authorization: `JWT ${token}`,
  //   };  
  //   const builderurl = Apiaddress+"/api/builderlist/"
  //   const res = await axios.get(builderurl,{headers});
  //   console.log("=============================",res.data.users);
  //   let temp=res.data.users;

  //   let arr=[];

  //   for(let i=0;i<temp.length;i++){

  //       let status="Pending"
  //       if(temp[i].is_active==true){
  //           status="Approved"
  //       }

  //       let obj={
  //           id:temp[i].id,
  //           name:temp[i].user_details.first_name,
  //           state:temp[i].state,
  //           city:temp[i].city,
  //           pincode:temp[i].pin_code,
  //           status:status,
  //           address1:temp[i].address_line1,
  //           address2:temp[i].address_line2,
  //           orglist:temp[i].orgList,
  //           buildeEmail:temp[i].user_details.email,
  //       }
  //       arr.push(obj);
  //   }
  //   setnewRowData(arr);
  //   setloading(false);
  // }

  // useEffect(()=>{
     
  //   fetchbuilderdata();
   
  // },[])

  const fetchCredit=async ()=>{
    try{
     const headers = {
        Authorization: `JWT ${token}`,
    };  
    const builderurl = Apiaddress+`/api/role/getbuilder/?profile_id=${data?.id}`
    const res = await axios.get(builderurl,{headers});
    console.log("=============================",res.data.builder);
    setcredit(res?.data?.builder?.available_credits);
    } 
    catch(err){
      console.log(err);
    }
  }

  const fetchCreditHistory=async ()=>{
    try{
     const headers = {
        Authorization: `JWT ${token}`,
    };  
    const builderurl = Apiaddress+`/api/role/creditstransaction/?profile_id=${data?.id}`
    const res = await axios.get(builderurl,{headers});
    console.log("=============================",res?.data?.trans);
    let temp=res?.data?.trans;
    if(temp=="trans not found"){
      setnewRowData([]);
    }
    else{
      let arr=[];

    for(let i=0;i<temp?.length;i++){

        let obj={
            time:new Date(temp[i].timestamp).toDateString(),
            amount:temp[i].amount,
            action:temp[i].is_credit==true?"Credited":"Debited"
        }
        arr.push(obj);
    }
    setnewRowData(arr);
    }
    setloading(false);
    } 
    catch(err){
      console.log(err);
    }
  }

  const AddCredit=async ()=>{
    let amt=+credittoadd;
    if(amt<=0){
      seterror(true);
      return;
    }
    try{
     const headers = {
        Authorization: `JWT ${token}`,
    }; 
    let obj={
      profile:data.id,
      amount:+credittoadd
    } 
    const builderurl = Apiaddress+`/api/role/creditstransaction/`
    const res = await axios.post(builderurl,obj,{headers});
    console.log("eeeee",res.data);
    setcredittoadd("");
    setplank(false);
    setTrigger(!trigger);
    seterror(false);
    } 
    catch(err){
      console.log(err);
    }
  }

  useEffect(()=>{
   fetchCredit();
   fetchCreditHistory();
  },[trigger]);

  return (
    <>
    <Stack direction="row" sx={{width:"100%",height:"auto" }}>
      {/* 1st stack */}
      <Stack sx={{minHeight:"100vh",width:"20%",boxSizing:"border-box",}}> {/*for project directory 20% and for sales dashboard 225*/}
        <SuperAdminSidebar active={"Builder Directory"}/>
      </Stack>

     {/* 2nd stack */}
     <Stack direction="column" sx={{ backgroundColor:"#f9f9f9",width:"80%", padding:"25px",boxSizing:"border-box",}}  >  {/*  width for main sidebar 78px and for 80px*/}
      <SuperAdminNavbar builder={true} />  {/*send props for get popup to verify otp from builder */}
      

      <Stack direction="row" justifyContent="space-between" alignItems="center" sx={{}}>
            <Box sx={{}}>
              <Typography sx={{marginTop:"10px", fontSize:"16px", fontWeight:"600", marginLeft:"0px", marginBottom:"0px",color:"#2979F2",fontWeight: "400"}}>
                Here is your Builders Credit History  
              </Typography>
              <Typography sx={{marginTop:"20px", fontSize:"28px", fontWeight:"600", marginLeft:"0px"}}>
                Builder Overview   
              </Typography>
              <Typography sx={{marginTop:"10px", fontSize:"16px", fontWeight:"600", marginLeft:"0px", marginBottom:"50px",color: "#9299BC",fontWeight: "500"}}>
                Here is your Builders Credit History 
              </Typography>
            </Box>

      </Stack>
      

     {/* actual content  */}
    <Stack sx={{backgroundColor:"#f9f9f9"}}>  {/* mt:"28px", */}

        {/* Overview Section    */}
        <Stack direction="row" sx={{boxShadow:"0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 1px 3px rgba(0, 0, 0, 0.12)",borderRadius:"16px",width:"48%",background:"#FFFFFF",marginBottom:"50px"}}> 
        <Box sx={{padding:"20px 50px 20px 20px",width:"100%"}}>
            <Typography sx={{fontFamily:"Poppins",fontStyle:"normal",fontWeight:"600",fontSize:"28px",lineHeight:"42px"}}>{data.name}</Typography>
            <Stack direction="row" sx={{alignItems:"center"}}>
                <LocationCityIcon sx={{color:"#2979F2",width:"70px",height:"70px"}}/>
                <Box>
                    <Typography sx={{fontFamily:"Product Sans",fontStyle:"normal",fontWeight:"600",fontSize:"12px",lineHeight:"20px",color:"#2979F2"}}>{data.state}</Typography>
                    <Typography sx={{fontFamily:"Product Sans",fontStyle:"normal",fontWeight:"600",fontSize:"12px",lineHeight:"20px",color:"#2979F2"}}>{data.city}</Typography>
                    <Typography sx={{fontFamily:"Product Sans",fontStyle:"normal",fontWeight:"600",fontSize:"12px",lineHeight:"20px",color:"#2979F2"}}>{data.pincode}</Typography>
                </Box>
            </Stack>
            {/* <Typography sx={{fontFamily:"Poppins",fontStyle:"normal",fontWeight:"500",fontSize:"20px",lineHeight:"30px"}}>{`Total Projects : 50`}</Typography> */}
        </Box>
        <Stack direction="column" spacing={2} sx={{background:"linear-gradient(90deg, #0F5DAE 0%, #009DCF 100.33%)",justifyContent:"center",alignItems:"center",padding:"0px 10px 0px 10px",width:"100%",borderRadius:"0px 16px 16px 0px"}}>
            <Typography sx={{color:"#F4F5F9",fontFamily:"Poppins",fontStyle:"normal",fontWeight:"600",fontSize:"18px",lineHeight:"27px"}}>Available Credits</Typography>
            <Typography sx={{color:"#F4F5F9",fontFamily:"Poppins",fontStyle:"normal",fontWeight:"600",fontSize:"28px",lineHeight:"42px"}}>{credit}</Typography>
            <Button
            onClick={()=>{
              setplank(true)
              console.log("rrrr",plank)
            }}
            sx={{background:"#FFFFFF",borderRadius:"8px",width:"112px",height:"34px",color:"#000000",fontFamily:"Poppins",fontStyle:"normal",fontWeight:"600",fontSize:"12px",lineHeight:"18px",
           "&:hover": {
            backgroundColor: '#FFFFFF'
            },
             
          }}><AddIcon sx={{width:"15px",height:"15px",color:"gray"}} 
            onClick={()=>{
              setplank(true)
              console.log("rrrr",plank)
            }}
            />Add credit</Button>
        </Stack>
    </Stack>
     
     {/* Add Credits Section */}
      {plank? 
     <Stack sx={{width:"55%",marginBottom:"0px"}}>
     <Stack direction="row" sx={{alignItems:"center"}}>
        <Typography sx={{fontFamily:"Product Sans",fontStyle:"normal",fontWeight:"400",fontSize:"24px",lineHeight:"20px",color:"#000000"}}>Number of credit to be added</Typography>
        <TextField sx={{width:"150px",marginLeft:"40px"}} value={credittoadd} type="number" InputProps={{ inputProps: { min: 0} }} onChange={(e)=>{setcredittoadd(e.target.value)}}></TextField>
     </Stack>
     {error && <Typography sx={{color:"red"}}>Please add only positive value</Typography>}
     <Box sx={{textAlign:"left",margin:"20px 0px 20px 20px"}}>
        <Button variant="contained" size="" sx={{background:"linear-gradient(90deg, #0F5DAE 0%, #009DCF 100.33%)"}}>
            <Box sx={{ textTransform: "capitalize" }} onClick={()=>{AddCredit()}}>Save</Box>
        </Button>
     </Box>
     </Stack>:null
     }

     <Typography sx={{margin:"20px 0px 40px 0px", fontSize:"28px", fontWeight:"600", marginLeft:"0px"}}>Credit History</Typography>

    {/* builder list table */}
    <Box sx={{height:"100vh",width:"100%"}}>
        <Box sx={{ color: "black", width: "100%" ,boxSizing:"border-box",display:"flex",flexDirection:"column"}}>

            <Stack direction="row" justifyContent="space-around" alignItems="center" sx={{width:"100%",backgroundColor:"white",borderRadius: "5px",margin:"0px 0px 10px 0px",padding:"10px 0px 10px 0px"}}>
              
                <Stack direction="row" justifyContent="center" alingItems="center" sx={{width:"14%",textAlign:"center",color: "#9299BC",fontWeight: "600"}}>
                        <Typography sx={{ display: "grid", placeItems: "center",fontWeight:"600",color:"black" }}>Date</Typography>
                        {/* <Box sx={{ display: "grid", marginLeft: "4px" }}>
                            <ArrowDropUpIcon fontSize="medium" sx={{ marginBottom: "-8px" }}/>
                            <ArrowDropDownIcon fontSize="medium" sx={{ marginTop: "-8px" }}/>
                        </Box> */}
                </Stack>

                 <Stack direction="row" justifyContent="center" alingItems="center" sx={{width:"14%",textAlign:"center",color: "#9299BC",fontWeight: "600"}}>
                        <Typography sx={{ display: "grid", placeItems: "center",fontWeight:"600",color:"black" }}>Action</Typography>
                        {/* <Box sx={{ display: "grid", marginLeft: "4px" }}>
                            <ArrowDropUpIcon fontSize="medium" sx={{ marginBottom: "-8px" }}/>
                            <ArrowDropDownIcon fontSize="medium" sx={{ marginTop: "-8px" }}/>
                        </Box> */}
                </Stack>

                 <Stack direction="row" justifyContent="center" alingItems="center" sx={{width:"14%",textAlign:"center",color: "#9299BC",fontWeight: "600"}}>
                        <Typography sx={{ display: "grid", placeItems: "center",fontWeight:"600",color:"black" }}>No of Credit Added</Typography>
                        {/* <Box sx={{ display: "grid", marginLeft: "4px" }}>
                            <ArrowDropUpIcon fontSize="medium" sx={{ marginBottom: "-8px" }}/>
                            <ArrowDropDownIcon fontSize="medium" sx={{ marginTop: "-8px" }}/>
                        </Box> */}
                </Stack>

            </Stack>

             {loading==true?
            <Box sx={{display:"flex",height:"80vh",padding:"10% 5% 5% 45%"}}>
                <CircularProgress color="inherit" sx={{ color: 'grey.500' }}/>
            </Box>
             :
            <Box sx={{}}>
                {newRowData.length==0?
                    <Box sx={{textAlign:"center",marginTop:"50px"}}>
                        <Typography sx={{fontSize:"25px",fontWeight:"500",color:"#B9B7D0",}}>No Matches Found</Typography>
                    </Box>:
                    <Box>
                        {newRowData && newRowData?.map((item, index) => {
                            if (index >= pageRange[0] && index <= pageRange[1]) {
                                return (
                                    
                                    <>
                                      <Stack direction="row" justifyContent="space-around" alignItems="center" sx={{width:"100%",borderRadius: "5px",margin:"0px 0px 10px 0px",padding:"10px 0px 10px 0px",backgroundColor:"white"}}>

                                       <Stack direction="row" justifyContent="center" alingItems="center" sx={{width:"14%",textAlign:"center",color: "#9299BC",fontWeight: "600"}}>
                                        <Typography >
                                           {item?.time}
                                        </Typography>
                                       </Stack>
                                       <Stack direction="row" justifyContent="center" alingItems="center" sx={{width:"14%",textAlign:"center",color: "#9299BC",fontWeight: "600"}}>
                                        <Typography >
                                          {item?.action}
                                        </Typography>
                                       </Stack>
                                       <Stack direction="row" justifyContent="center" alingItems="center" sx={{width:"14%",textAlign:"center",color: "#9299BC",fontWeight: "600"}}>
                                        <Typography >
                                          {item?.amount}
                                        </Typography>
                                       </Stack>
                                    </Stack>
                                    </>  
                                );
                            }
                        })}

        <Box sx={{margin:"50px 0px 40px 0px"}}>
          <Box sx={{ display: "flex", justifyContent: "right" }}>
            {newRowData.length!=0 && 
            <Button variant="contained" sx={{background:"linear-gradient(90deg, #0F5DAE 0%, #009DCF 100.33%)"}}
              onClick={prvClick}>
                <ArrowBackIosIcon fontSize="small" />
                <Box sx={{ textTransform: "capitalize" }}>Previous</Box>
            </Button>}

            <Box sx={{background: "#E3E4EB",display: "flex",placeItems: "center",margin: " 0 10px",borderRadius: "5px"}}>
                <Box sx={{background: `white`,color: "black",width: "30px",borderRadius: "5px",margin: "0 10px",display: "grid",placeItems: "center"}}>{curpage}</Box>
                <Box sx={{color: "black",width: "30px",borderRadius: "5px",margin: "0 0px",display: "grid",placeItems: "center"}}>of</Box>
                <Box sx={{background: `white`,color: "black",width: "30px",borderRadius: "5px",margin: "0 10px",display: "grid",placeItems: "center"}}>{pages?.length}</Box>
            </Box>

            {newRowData.length!=0 && 
                <Button variant="contained" sx={{background:"linear-gradient(90deg, #0F5DAE 0%, #009DCF 100.33%)"}}
                    onClick={nextClick}>
                    <Box sx={{ textTransform: "capitalize" }}>Next</Box>
                    <ArrowForwardIosIcon fontSize="small" />
                </Button>
            }
          </Box>
        </Box>
      </Box>
    }
    </Box>
    }
    </Box>
    </Box>

  </Stack>
  </Stack>
  </Stack>

   
    </>
  )
}

export default SuperAdminBuilder