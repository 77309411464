import React from 'react'
import SideBar from '../components/sideBar/SideBar'

const Finances = () => {
  return (
    <SideBar active={"Finances"}>

    </SideBar>
  )
}

export default Finances