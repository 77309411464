import { Box, Container, Stack, Typography } from "@mui/material";
import CircleCheckIcon from "../../Imgs/circle-check-icon.svg";
import HomeBuyerManagement from "../../Imgs/home-buyer-management.png";

const CommonPagesHomeBuyerManagement = () => {
  return (
    <Box
      sx={{
        backgroundColor: "#FFF",
        pt: { xs: "0px", md: "50px" },
        overflowX: "hidden",
      }}
    >
      <Container>
        <Box
          sx={{
            display: "flex",
            gap: { xs: "10px", md: "30px" },
            alignItems: "center",
            flexDirection: { xs: "column", md: "row" },
          }}
        >
          <Box
            data-aos="zoom-in"
            data-aos-offset="210"
            sx={{ width: { xs: "100%", md: "45%" } }}
          >
            <img style={{ width: "100%" }} src={HomeBuyerManagement} alt="" />
          </Box>
          <Stack sx={{ width: { xs: "100%", md: "55%" } }}>
            <Typography
              data-aos="fade-left"
              sx={{
                color: "#272D4E",
                fontSize: { xs: "24px", md: "33px", lg: "45px" },
                fontWeight: 700,
                fontFamily: "Circular Std",
                lineHeight: "1.2",
              }}
            >
              Home Buyer Management
            </Typography>

            <Stack spacing={2}>
              <Typography
                sx={{
                  fontSize: "18px",
                  fontFamily: "Product Sans",
                }}
              >
                Give your buyers a delightful post-sales experience from an
                integrated app
              </Typography>
              <Box sx={{ display: "flex", gap: "10px" }}>
                <Box sx={{ lineHeight: "10px", fontSize: "30px" }}>
                  {/* <img src={CircleCheckIcon} alt="" /> */}
                  &#x2022;
                </Box>
                <Typography
                  sx={{
                    fontSize: "16px",
                    fontFamily: "Product Sans",
                    color: "#343434",
                    lineHeight: "1.3",
                  }}
                >
                  <span style={{ fontWeight: 600 }}>Manage your buyers,</span>{" "}
                  and <span style={{ fontWeight: 600 }}>track payments</span>{" "}
                  from a central dashboard
                </Typography>
              </Box>
              <Box sx={{ display: "flex", gap: "10px" }}>
                <Box sx={{ lineHeight: "10px", fontSize: "30px" }}>
                  {/* <img src={CircleCheckIcon} alt="" /> */}
                  &#x2022;
                </Box>
                <Typography
                  sx={{
                    fontSize: "16px",
                    fontFamily: "Product Sans",
                    color: "#343434",
                    lineHeight: "1.3",
                  }}
                >
                  <span style={{ fontWeight: 600 }}>
                    Share project progress
                  </span>{" "}
                  with images, videos, 3D tours, and more
                </Typography>
              </Box>
              <Box sx={{ display: "flex", gap: "10px" }}>
                <Box sx={{ lineHeight: "10px", fontSize: "30px" }}>
                  {/* <img src={CircleCheckIcon} alt="" /> */}
                  &#x2022;
                </Box>
                <Typography
                  sx={{
                    fontSize: "16px",
                    color: "#343434",
                    fontFamily: "Product Sans",
                    lineHeight: "1.3",
                  }}
                >
                  <span style={{ fontWeight: 600 }}>
                    Automated payment alerts
                  </span>{" "}
                  sent to home buyers
                </Typography>
              </Box>
              <Box sx={{ display: "flex", gap: "10px" }}>
                <Box sx={{ lineHeight: "10px", fontSize: "30px" }}>
                  {/* <img src={CircleCheckIcon} alt="" /> */}
                  &#x2022;
                </Box>
                <Typography
                  sx={{
                    fontSize: "16px",
                    fontFamily: "Product Sans",
                    color: "#343434",
                    lineHeight: "1.3",
                  }}
                >
                  <span style={{ fontWeight: 600 }}>Automated generation</span>{" "}
                  of Quotations, Booking Agreements, Booking Request Letters,
                  and more
                </Typography>
              </Box>
              <Box sx={{ display: "flex", gap: "10px" }}>
                <Box sx={{ lineHeight: "10px", fontSize: "30px" }}>
                  {/* <img src={CircleCheckIcon} alt="" /> */}
                  &#x2022;
                </Box>
                <Typography
                  sx={{
                    fontSize: "16px",
                    fontFamily: "Product Sans",
                    color: "#343434",
                    lineHeight: "1.3",
                  }}
                >
                  <span style={{ fontWeight: 600 }}>System-generated </span>{" "}
                  demand letters, auto-interest calculations, and past-due
                  notices
                </Typography>
              </Box>
            </Stack>
          </Stack>
        </Box>
      </Container>
    </Box>
  );
};

export default CommonPagesHomeBuyerManagement;
