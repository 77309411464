import { Box } from "@mui/system";
import React, { useContext, useEffect, useState } from "react";
import profileContext from "../../../../context/profile/context";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { countryDetails } from "../../../../utils/contryDetails";
import { Country, State, City }  from 'country-state-city';

import {
  Button,
  Checkbox,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  Modal,
  OutlinedInput,
  TextField,
  select,
  Select,
  Stack
} from "@mui/material";
// import Select from 'react-select';
import DeleteIcon from "@mui/icons-material/Delete";

import AddIcon from "@mui/icons-material/Add";
import axios from "axios";
import { api, Apiaddress } from "../../../../utils/consts";
import { AuthService } from "../../../../context/buyer/services/auth";
import { CitySelector, StateSelector } from "volkeno-react-country-state-city";

function RightSidebar({
  setTabledata,
  curselectedRows,
  orgID,
  toggleDrawer,
  updateData,
  setTrigger,
  trigger,
  makePriority,
  makeProject,
  setMakePriority,
  setMakeProject,
  setupdateData,
  setState,
}) {

  const [expanded, setExpanded] = useState(
    makePriority ? "panel2" : makeProject ? "panel3" : "panel1"
  );

  const handleChange = (i) => {
    setExpanded(i);
  };

  const cpDetails = JSON.parse(localStorage.getItem("cpDetails"));

  const [location, setLocation] = useState({});

  useEffect(() => {
    setLocation(countryDetails);
  }, []);

  let cpOrg = cpDetails?.channelPartnerOrg;

  const [priority, setPriority] = useState(null);
  const handleChangeSelect = (event) => {
    setPriority(event.target.value);
  };

  //Starts from here
  const [name, setName] = useState(
    curselectedRows && curselectedRows.Name ? curselectedRows.Name : ""
  );
  const [phone, setPhne] = useState("");
  const [Email, setEmail] = useState("");
  const [address, setAddresss] = useState("");
  const [stateName,setStateName] = useState("");
  const [city,setCity] = useState("");
  const [pincode,setPincode] = useState("");
  const [builderId, setbuilderId] = useState(null);
  const [cpdata, setcpdata] = useState(null);
  const [allpriority, setallpriority] = useState(null);
  const [datafromapi, setdatafromapi] = useState([]);
  const [err,setError]=useState(false);
  const [pinError,setPinError]=useState(false);
  const [cityError,setCityError] = useState(false);
  const [msg,setMsg]= useState("");
  const [temp,setTemp] = useState("");

  const user = useContext(profileContext);
  
  //state and city selector
  let States=State.getStatesOfCountry('IN');
  const [stateCode,setStateCode] = useState("");
  const [allCities,setAllCities] = useState("");

  console.log(stateName);
  console.log(stateCode);
  console.log(city);

  useEffect(()=>{
    let Cities=City.getCitiesOfState('IN',stateCode);
    setAllCities(Cities);
  },[stateCode])

  useEffect(() => {
    if (updateData) {
      setName(updateData.Name);
      setEmail(updateData.Email);
      setPhne(updateData.Phone);
      setPriority(updateData.priority);
      setAddresss(updateData.Address);
    }
  }, [updateData]);

  useEffect(() => {
    if (user) {
      if (user.UserState) {
        if (user.UserState.user_obj) {
          setbuilderId(user.UserState.user_obj.id);
        }
      }
    }
  }, [user, trigger]);

  const getCP = async () => {
    try {
      const res = await axios.get(
        Apiaddress + `/api/role/getcpusingbuilderid/?builder_id=${builderId}`
      );
      if (res.data.channel_partner !== "channel_partner not found") {
        setcpdata(res.data.channel_partner);
      } else {
        setcpdata([]);
      }
      console.log(res.data.channel_partner);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (cpdata) {
      let arr = [];
      for (let i = 0; i < cpdata.length; i++) {
        const obj = {
          id: i + 1,
          CPid: cpdata[i]?.id,
          RERARegNo: cpdata[i]?.rera_no,
          Type: cpdata[i]?.profile.role_type,
          Name: cpdata[i]?.profile.user_details.first_name,
          Email: cpdata[i]?.profile.user_details.email,
          Phone: cpdata[i]?.profile.phone,
        };
        arr.push(obj);
      }
      setdatafromapi(arr);
    }
  }, [cpdata]);

  useEffect(() => {
    if (builderId) {
      getCP();
    }
  }, [builderId, trigger]);

  const getpriority = async () => {
    try {
      const res = await axios.get(Apiaddress + "/api/leads/leadprority/");
      console.log(res.data.leadprority);
      setallpriority(res.data.leadprority);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getpriority();
  }, []);

  const createCp = async (e) => {
    e.preventDefault();
    if(!Email && !phone){
      setError(true);
      setMsg("Fill one either from Email or Phone Number");
      console.log("Yes ksdk")
      return;
    }
    if(pincode=="" || stateName=="" || city==""){
      console.log("second condition");
      setCityError(true);
      return;
    }
    if(pincode?.length<6){
      console.log(pincode);
      setPinError(true);
      return;
    }
    const Auth = new AuthService();
    let phoneNo;
    if(phone && phone!==""){
      phoneNo = "+91" + phone;
    }
    console.log(phoneNo);
    const res =await( await Auth.makeUnAuthenticatedPostRequest(
      api("/api/leads/lead/"),
      {
        JsonBody: {
          name: name,
          phone: phoneNo?phoneNo:null,
          state:stateName,
          city:city,
          postcode:pincode,
          email: Email?Email?.toLowerCase():null,
          address_line: address,
          lead_prority: priority ? priority : 1,
          assigned_to: [Auth.getCPID()],
          channel_partner: Auth.getCPID(),
          created_by: Auth.getCPID(),
          cp_org: cpOrg ? cpOrg : null,
        },
      }
    )).json();
    console.log(res);
    if(res?.error==true){
      setError(true);
      setMsg(res?.message);
    }
    else{
      setState(false);
      setTrigger(!trigger);
    }
  };

  return (
    <Box
      sx={{
        width: "420px",
        height: "100vh",
        marginTop: "-25px",
        marginRight: "25px",
        padding: "10px",
      }}
    >
      <Box sx={{ position: "relative" }}>
        <form onSubmit={createCp}>
          <Box
            sx={{
              position: "absolute",
              top: "0px",
              right: "0px",
              background: "white",
              padding: "10px",
              height: "105vh",
              width: "400px",
              marginTop: "20px",
            }}
          >
            {" "}
            <Box>
              <Box sx={{ display: "flex" }}>
                {/* <Box>
                <ArrowBackIosIcon
                  sx={{
                    fontSize: "small",
                    marginBottom: "-2px",
                  }}
                />
              </Box> */}
                <Box>Lead Invitation</Box>
              </Box>

              <Box
                sx={{
                  display: "grid",
                  alignItems: "center",
                  height: "100vh",
                }}
              >
                <Box>
                  <Accordion
                    expanded={expanded === "panel1"}
                    onClick={() => {
                      if (updateData.length == 0) {
                        handleChange("panel1");
                      }
                    }}
                  >
                    <AccordionSummary
                      sx={{
                        background: `${
                          expanded === "panel1" ? "#FFAF2A" : "white"
                        }`,
                        borderRadius: "10px",
                      }}
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <Typography
                        sx={{
                          fontFamily: "Circular Std",
                          color: "#272D4E",
                          fontWeight: "400",
                          fontSize: "18px",
                        }}
                      >
                        Lead Invitation
                        {/* {`${JSON.stringify(temp)}`} */}
                      </Typography>
                    </AccordionSummary>

                    <AccordionDetails>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          width: "400px",
                          margin: "10px 0",
                        }}
                      >
                        <Box
                          sx={{
                            fontFamily: "Circular Std",
                            color: "#272D4E",
                            fontWeight: "500",
                            fontSize: "18px",
                          }}
                        >
                          Name
                        </Box>
                        <Box>
                          <TextField
                            value={name}
                            required
                            id="outlined-basic"
                            onChange={(e) => setName(e.target.value)}
                          />
                        </Box>
                      </Box>

                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          width: "400px",
                          margin: "10px 0",
                        }}
                      >
                        <Box
                          sx={{
                            fontFamily: "Circular Std",
                            color: "#272D4E",
                            fontWeight: "500",
                            fontSize: "18px",
                          }}
                        >
                          Phone Number
                        </Box>
                        <Box>
                          <TextField
                            value={phone}
                            type="number"
                            // required
                            placeholder=""
                            id="outlined-basic"
                            onChange={(e) => {
                              if (e.target.value.length > 10) {
                                return;
                              }
                              setPhne(e.target.value);
                            }}
                          />
                        </Box>
                      </Box>

                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          width: "400px",
                          margin: "10px 0",
                        }}
                      >
                        <Box
                          sx={{
                            fontFamily: "Circular Std",
                            color: "#272D4E",
                            fontWeight: "500",
                            fontSize: "18px",
                          }}
                        >
                          Email
                        </Box>
                        <Box>
                          <TextField
                            value={Email}
                            // required
                            type="email"
                            id="outlined-basic"
                            onChange={(e) => setEmail(e.target.value)}
                          />
                        </Box>
                      </Box>

                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          width: "400px",
                          margin: "10px 0",
                        }}
                        >
                        <Box
                          sx={{
                            fontFamily: "Circular Std",
                            color: "#272D4E",
                            fontWeight: "500",
                            fontSize: "18px",
                          }}
                        >
                          Address
                        </Box>
                        <Box>
                          <TextField
                            required
                            value={address}
                            id="outlined-basic"
                            onChange={(e) => setAddresss(e.target.value)}
                          />
                        </Box>
                      </Box>

                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          width: "400px",
                          margin: "10px 0",
                        }}
                        >
                        <Box
                          sx={{
                            fontFamily: "Circular Std",
                            color: "#272D4E",
                            fontWeight: "500",
                            fontSize: "18px",
                          }}
                        >
                          State
                        </Box>
                        <Box sx={{width:"200px"}}>   

                        <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">Select State</InputLabel>
                          <Select
                            required
                            label="Select State"
                            MenuProps={{ PaperProps: { sx: { maxHeight: 200,maxWidth: 250} } }}
                            onChange={(e)=>{
                              let temp = e.target.value.split("-");
                              console.log(temp);
                              setStateName(temp[0]);
                              setStateCode(temp[1]);
                            }}
                          >
                          {States &&
                            States?.map((option) => (
                              <MenuItem key={option.name+"-"+option.isoCode} value={option.name+"-"+option.isoCode}>
                                {option.name}
                              </MenuItem>
                          ))}
                          </Select>
                        </FormControl>

                        </Box>
                      </Box>

                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          width: "400px",
                          margin: "10px 0",
                        }}
                        >
                        <Box
                          sx={{
                            fontFamily: "Circular Std",
                            color: "#272D4E",
                            fontWeight: "500",
                            fontSize: "18px",
                          }}
                        >
                          City
                        </Box>
                        <Box sx={{width:"200px"}}>
                          
                        <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">Select City</InputLabel>
                        <Select
                            required
                            value={city}
                            label="Select City"
                            MenuProps={{ PaperProps: { sx: { maxHeight: 200,maxWidth: 250} } }}
                            onChange={(e)=>{
                              let temp = e.target.value.split("-");
                              setCity(temp[0]);
                            }}
                          >
                            {allCities &&
                            allCities?.map((option) => (
                              <MenuItem key={option.name+"-"+option.isoCode} value={option.name}>
                                {option.name}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>

                        </Box>
                      </Box>

                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          width: "400px",
                          margin: "10px 0",
                        }}
                        >
                        <Box
                          sx={{
                            fontFamily: "Circular Std",
                            color: "#272D4E",
                            fontWeight: "500",
                            fontSize: "18px",
                          }}
                        >
                          Pincode
                        </Box>
                        <Box>
                          <TextField
                            required
                            value={pincode}
                            type="number"
                            id="outlined-basic"
                            onChange={(e) =>
                              e.target.value.length <= 6
                                ? setPincode(e.target.value)
                                : null
                            }
                          />
                        </Box>
                      </Box>
                      {cityError && (
                          <Typography
                            sx={{
                              fontWeight: "500",
                              fontSize: "14px",
                              marginBottom: "5px",
                              color: "#da6061",
                            }}
                          >
                          State and City is required
                          </Typography>
                      )}
                      {pinError && (
                          <Typography
                            sx={{
                              fontWeight: "500",
                              fontSize: "14px",
                              marginBottom: "5px",
                              color: "#da6061",
                            }}
                          >
                            Pincode Should be of 6 Characters
                          </Typography>
                        )}
                      {err===true && <Typography sx={{color:"red"}}>{msg}</Typography>}
                    </AccordionDetails>
                  </Accordion>

                  <Accordion
                    expanded={expanded === "panel2"}
                    onClick={() => {
                      if (makePriority) handleChange("panel2");
                      else if (updateData.length == 0 && !makePriority)
                        handleChange("panel2");
                    }}
                  >
                    <AccordionSummary
                      sx={{
                        background: `${
                          expanded === "panel2" ? "#FFAF2A" : "white"
                        }`,
                        borderRadius: "10px",
                      }}
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <Typography
                        sx={{
                          fontFamily: "Circular Std",
                          color: "#272D4E",
                          fontWeight: "400",
                          fontSize: "18px",
                        }}
                      >
                        Lead Priority
                      </Typography>
                    </AccordionSummary>

                    <AccordionDetails>
                      <Box
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems:"center",
                          width: "400px",
                          margin: "10px 0",
                        }}
                      >
                        <Box>Mark As</Box>
                        <TextField
                          id="outlined-select-currency"
                          select
                          value={priority}
                          onChange={handleChangeSelect}
                          sx={{ width: "300px" }}
                        >
                          {allpriority &&
                            allpriority?.map((option) => (
                              <MenuItem key={option.id} value={option.id}>
                                {option.name}
                              </MenuItem>
                            ))}
                        </TextField>
                      </Box>
                    </AccordionDetails>
                  </Accordion>

                  {/* {`${JSON.stringify(updateData)}`} */}
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      marginTop: "20px",
                    }}
                  >
                    {updateData.length == 0 ? (
                      <Button
                        type="submit"
                        // onClick={createCp}
                        className="univercel_btn_class"
                        sx={{
                          width: "120px",
                          color: "#FFFFFF",
                          textTransform: "none",
                          marginBottom: "20px",
                        }}
                      >
                        Add Lead
                      </Button>
                    ) : (
                      <Button
                        onClick={async () => {
                          for (let i = 0; i < updateData.length; i++) {
                            const Auth = new AuthService();
                            const res = await Auth.makeUnAuthenticatedPatchRequest(
                              api("/api/leads/lead/"),
                              {
                                JsonBody: {
                                  lead_id: updateData[i],
                                  lead_prority: priority,
                                  assigned_to: [Auth.getCPID()],
                                  channel_partner: Auth.getCPID(),
                                },
                              }
                            );
                            console.log(res.data);
                            setTrigger(!trigger);
                            setMakePriority(false);
                            setupdateData([]);
                            setState(false);
                          }
                        }}
                        className="univercel_btn_class"
                        sx={{
                          width: "200px",
                          color: "#FFFFFF",
                          textTransform: "none",
                          marginBottom: "20px",
                        }}
                      >
                        Update Lead
                      </Button>
                    )}
                    <Box
                      sx={{
                        textAlign: "center",
                        margin: "6px 6px 6px 25px",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        setName("");
                        setEmail("");
                        setPhne("");
                        setAddresss("");
                        setupdateData([]);
                        setMakePriority(false);
                        setState(false);
                      }}
                    >
                      <Typography sx={{}}>Cancel</Typography>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </form>
      </Box>
    </Box>
  );
}

export default RightSidebar;
