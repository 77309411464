import React, { useEffect, useState } from 'react';
import { Box, Button, Divider, Stack, TextField, Typography } from "@mui/material";
import DoneIcon from '@mui/icons-material/Done';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import Pagination from '@mui/material/Pagination';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete'; 
import Checkbox from '@mui/material/Checkbox';
import Modal from '@mui/material/Modal';

import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';

import currentProjectState from '../../../../context/createproject/context' ;
import { Apiaddress } from '../../../../utils/consts';
import axios from 'axios';

import { useNavigate, Link, useLocation } from "react-router-dom";

import { SuperAdminNavbar } from "../../SidebarNavbar/SuperAdminNavbar"
import { SuperAdminSidebar } from "../../SidebarNavbar/SuperAdminSidebar"
import { BackeToOverviewBtn } from '../../../BuilderComponents/ProjectDashboard/CreateProject/BackeToOverviewBtn';
import ConfirmProject_PricingDetails_Section from '../../../BuilderComponents/ProjectDashboard/CreateProject/ConfirmProject_PricingDetails_Section';
import { SAProjectSidebar } from '../../SidebarNavbar/SAProjectSidebar';



export const ConfirmProject_PricingDetailsSA = ()=>{
  
  ////get data from previous page 
  const location = useLocation();
  const data = location.state.state;

  const floorData = location.state.current_data

 // console.log("preve data =>.>",data)
 

 // let [current_data, setCurrentData] = useState({})

 const navigate = useNavigate();

 const [open, setOpen] = React.useState(false);
 const handleOpen = () => setOpen(true);
 const handleClose = () => setOpen(false);

 const [seccost,setseccost]= useState({index:0,cost:0});
 const [grandtotal,setgrandtotal]=useState(0);

  const [charges_data,settitle]=useState([]);

 const [addsec,setaddsec]=useState({
       titlename:"",
       number:0,
       field:[]
   });

   const totalcostcalculate=()=>{
       let data=0
       charges_data?.map((ele)=>{
           data+=+(ele.sectioncost);
       })
       setgrandtotal(data);

       // let obj=unitdata;
       // obj.total_cost=data;
       // setunitdata(obj);
   }

 const style = {
       position: 'absolute',
       top: '50%',
       left: '50%',
       transform: 'translate(-50%, -50%)',
       width: "350px",
       height:"250px",
       bgcolor: 'background.paper',
       boxShadow: 4,
       borderRadius:"16px",
       p: 4
     };

     useEffect(()=>{
       const data=charges_data?.map((ele)=>{
           if(ele.number==seccost.index){
               ele.sectioncost=seccost.cost;
           }
           return ele;
       })
       settitle(data);
       totalcostcalculate();

   },[seccost]);



 console.log(charges_data);

 useEffect(()=>{

  const uniturl = Apiaddress+"/api/projects/unittemplate/?unit_template_id="+data.unit_id;
  console.log(data);
  axios.get(uniturl)
  .then((res)=>{
       const data = res?.data
       console.log("updated layout  data=>>>>>>>.",data?.unit_template[0].charges_data)
       settitle(data.unit_template[0].charges_data)
       // setCurrentData(data?.unit_template[0].layout_data)
       countTotal(data.unit_template[0].charges_data)
    })
    .catch((err)=>{
      console.log(err)
    })

 },[]);
  ///count total price
  
  const [totalCost, setTotalCost] = useState(0);
  const countTotal = (charges_data)=>{
    let temp = 0;
    for(let i=0; i<charges_data.length; i++){
      temp+=charges_data[i].sectioncost;
    }
    setTotalCost(temp)
  } 
  useEffect(()=>{
       countTotal(charges_data)
  },[charges_data])
  

  //function for create unit Templates
 const createPricingTemplate = ()=>{

     // setCurrentData({...current_data,bedroomData});
     // console.log({...current_data,bedroomData}); 
 //  console.log(unit); 
 //  setunitTemplates([...unitTemplates,unit])
 //  if(unit.unit_id=="" || unit.unit_name=="" || unit.unit_no=="") return;
   let url = Apiaddress+"/api/projects/unittemplate/?unit_template_id="+data.unit_id;
   
   let st = {
     unit_template_id:data.unit_id,
     // layout_data:{...current_data,bedroomData}
     charges_data:charges_data
   }

   let headers = {
     headers:{
       "Content-Type":"application/json"
   }
   }
 //   console.log(st);

   axios.patch(url,st,headers)
   .then((res)=>{
     console.log("add bedrrom template in unit======>", res.data);
   })
   .catch((err)=>{
     console.log(err);
   })

   }
 
    return(
        <>
            
<Stack direction="row" sx={{width:"100%",height:"auto" }}>
      {/* 1st stack */}
      <Stack sx={{minHeight:"100vh",width:"20%",boxSizing:"border-box",}}> {/*for project directory 20% and for sales dashboard 225*/}
        {/* <SuperAdminSidebar active={"Builder Directory"}/> */}
        <SAProjectSidebar active={"Project Dashboard"}/>
      </Stack>

     {/* 2nd stack */}
     <Stack direction="column" sx={{ backgroundColor:"#f9f9f9",width:"80%", padding:"25px",boxSizing:"border-box",}}  >  {/*  width for main sidebar 78px and for 80px*/}
     <SuperAdminNavbar createProject={true} /> {/*send props for get popup to verify otp from builder */}
      


      <Typography sx={{marginTop:"20px", fontSize:"28px", fontWeight:"600", marginLeft:"10px", marginBottom:"50px"}}>
           Pricing Overview
            </Typography>
     {/* actual content  */}
    <Stack sx={{backgroundColor:"#f9f9f9"}}>  {/* mt:"28px", */}

    <Stack direction="column"  sx={{  padding:"20px 20px 20px 70px", backgroundColor:"#fff", marginright:"0px"}}>

           {/* confirm project bathroom component */}
                     
   

        <Stack direction="row" spacing={1.5}>
        <Stack direction="row" alignItems="center" spacing={1.5} sx={{margin:"70px 0px 70px 0px"}}>
           <Box  sx={{width:"29px", height:"29px",borderRadius:"50%", backgroundColor:"#FFA946", display:"flex", justifyContent:"center", alignItems:"center"}}>
              <DoneIcon sx={{color:"#fff", fontSize:"20px"}}/>
            </Box>
            <Typography sx={{fontSize:"18px", fontWeight:"500"}}>Project Home</Typography>
          </Stack>
          <Stack direction="row" alignItems="center" spacing={1.5} sx={{margin:"70px 0px 70px 0px"}}>
           <Box  sx={{width:"29px", height:"29px",borderRadius:"50%", backgroundColor:"#FFA946", display:"flex", justifyContent:"center", alignItems:"center"}}>
              <DoneIcon sx={{color:"#fff", fontSize:"20px"}}/>
            </Box>
            <Typography sx={{fontSize:"18px", fontWeight:"500"}}>Building 1</Typography>
          </Stack>
          <Stack direction="row" alignItems="center" spacing={1.5} sx={{margin:"70px 0px 70px 0px"}}>
           <Box  sx={{width:"29px", height:"29px",borderRadius:"50%", backgroundColor:"#FFA946", display:"flex", justifyContent:"center", alignItems:"center"}}>
              <DoneIcon sx={{color:"#fff", fontSize:"20px"}}/>
            </Box>
            <Typography sx={{fontSize:"18px", fontWeight:"500"}}>Wing 1</Typography>
          </Stack>
          <Stack direction="row" alignItems="center" spacing={1.5} sx={{margin:"70px 0px 70px 0px"}}>
           <Box  sx={{width:"29px", height:"29px",borderRadius:"50%", backgroundColor:"#FFA946", display:"flex", justifyContent:"center", alignItems:"center"}}>
              <DoneIcon sx={{color:"#fff", fontSize:"20px"}}/>
            </Box>
            <Typography sx={{fontSize:"18px", fontWeight:"500"}}>Floor 1</Typography>
          </Stack>
          <Stack direction="row" alignItems="center" spacing={1.5} sx={{margin:"70px 0px 70px 0px"}}>
           <Box  sx={{width:"29px", height:"29px",borderRadius:"50%", backgroundColor:"#FFA946", display:"flex", justifyContent:"center", alignItems:"center"}}>
              <DoneIcon sx={{color:"#fff", fontSize:"20px"}}/>
            </Box>
            <Typography sx={{fontSize:"18px", fontWeight:"500"}}>{data.unit_name} (Pricing)</Typography>
          </Stack>
          </Stack>

          <Box sx={{}}>

            <Stack direction="row" sx={{margin:"0px 0px 0px 0px",display:"flex",alignItems:"center"}} >
              <Typography sx={{fontSize:"18px", fontWeight:"500"}}>Pricing</Typography>
              <AddIcon sx={{color:"#A2A3A6",fontSize:"18px",marginLeft:"10px",cursor:"pointer"}} onClick={()=>{setOpen(true)}}/>
            </Stack>

            <Modal
                         open={open}
                         onClose={handleClose}
                         aria-labelledby="modal-modal-title"
                         aria-describedby="modal-modal-description">
                        <Box sx={style}>
                        <Stack direction="row" justifyContent="space-between" alignItems="center">
                             <Typography id="modal-modal-title" variant="h6" component="span" sx={{fontFamily:'Poppins',fontStyle:"normal",fontWeight:"500",fontSize: "18px",lineHeight:"27px",color:"#FF6140"}}>
                             Add Section
                             </Typography>
                             <Box onClick={()=>{setOpen(false);setaddsec("")}} sx={{cursor:"pointer"}}><CloseIcon /></Box>   
                        </Stack>
                        <Box sx={{alignItems:"center",margin:"40px 0px 0px 0px"}}>
                         <Stack direction="row" justifyContent="start" alignItems="center" sx={{margin:"10px 0px 0px 0px",fontSize: "18px"}}>
                             <Typography component="span" className='field-name'>Input Section Name</Typography>
                             <TextField type="number" inputProps={{ min: 0 }}
                              sx={{
                                 
                                  width:"188px",
                                  marginLeft:"10px"
                                 }} name="titlename" value={addsec.titlename} onChange={(e)=>{setaddsec(
                                     {
                                         titlename:e.target.value,
                                         number:charges_data.length+1,
                                         field:[]
                                     }
                                     )}}/>
                         </Stack>
                         </Box>
                         <Button 
                            className="univercel_btn_class"
                             variant="contained"
                              color="primary" sx={{ height:"45px",textTransform: "none", borderRadius: "4px", width:"100px", fontSize:"15px",margin:"60px 0px 30px 120px"}} onClick={()=>{
                                 if(!addsec.titlename){
                                     return;
                                 }
                                 settitle(charges_data=> charges_data.concat(addsec));
                                 setOpen(false);
                                //  {console.log(title)};
                                 setaddsec("");
                             }}>
                             Done
                     </Button>
                     </Box>
                   </Modal>
              

                     <Stack sx={{}}>
                             {charges_data?.map((ele,index)=>{
                                 return(
                                     <>
                                     <ConfirmProject_PricingDetails_Section header={ele} index={index} title={charges_data} settitle={settitle} setseccost={setseccost}/>
                                     </>
                                 )
                             })}
                     </Stack>
                                        
                     <Divider sx={{margin:"40px 0px 0px 0px"}}/>

<Stack direction="row" justifyContent="right" alignItems="center" sx={{margin:"50px 50px 0px 0px"}}>
  <Typography className='field-name' sx={{fontSize:"18px",color:"#2979F2",marginRight:"20px"}}>Grand Total</Typography>
  <Stack direction="row" sx={{alignItems:"center"}}>
     <CurrencyRupeeIcon sx={{color:"#2979F2"}}/>
     <Box sx={{width:"188px",height:"52px",background:"#ECECEC",borderRadius:"8px",display:"flex",justifyContent:"center",alignItems:"center"}}>
         <Typography className='field-name' sx={{fontSize:"20px",color:"#2979F2"}}>{totalCost}</Typography>
     </Box>
  </Stack>
</Stack>


            {/* <Box sx={{display:"flex",alignItems:"center",margin:"80px 0px 80px 0px"}}>
            <ArrowBackIcon/>
            <Typography sx={{fontSize:"18px", fontWeight:"500",margin:"0px 10px 0px 10px"}}>Back</Typography>
            </Box> */}

             <Stack direction="row" sx={{alignItems:"center"}}>

            <Box 
             onClick={()=>{
              navigate(-1)
            }}
            sx={{display:"flex",alignItems:"center",margin:"0px 0px 0px 0px"}}>
            <ArrowBackIcon/>
            <Typography sx={{fontSize:"18px", fontWeight:"500",margin:"0px 10px 0px 10px"}}>Back</Typography>
            </Box>
            
             <Box sx={{margin:"60px 0px 60px 60px"}}>

              <Link to={`/super-admin-confirm-project-Unit`} state={floorData}
                onClick={()=>{
                createPricingTemplate()
              }}
              >
                <Button 
                className="univercel_btn_class"
                variant="contained"
                sx={{padding:"16px 50px 16px 50px", color:"#fff",fontSize:"18px", fontWeight:"700",height:"50px"}}>
                    Save 
                </Button>
              </Link>

            </Box>

            </Stack>

          </Box>

                     
           </Stack>

           <BackeToOverviewBtn SuperAdmin={true}/>
    </Stack>
      </Stack>
</Stack>
        </>
    )
}