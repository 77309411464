import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Checkbox,
  Drawer,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { Stack } from "@mui/system";
import TuneOutlinedIcon from "@mui/icons-material/TuneOutlined";
import KeyboardArrowRightOutlinedIcon from "@mui/icons-material/KeyboardArrowRightOutlined";
import KeyboardArrowUpOutlinedIcon from "@mui/icons-material/KeyboardArrowUpOutlined";
import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined";
import { CheckBox } from "@mui/icons-material";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import DriveFileRenameOutlineIcon from "@mui/icons-material/DriveFileRenameOutline";
import VisibilityIcon from "@mui/icons-material/Visibility";
import DeleteIcon from "@mui/icons-material/Delete";
import "./LDmainPage.css";
import React, { useContext, useEffect, useState } from "react";
import RightSidebar from "./RightSidebar";
import { DataGrid, GridExpandMoreIcon } from "@mui/x-data-grid";
import CategoryIcon from "@mui/icons-material/Category";
import HandshakeIcon from "@mui/icons-material/Handshake";
import axios from "axios";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import LDcustomTable from "./LDcustomTable";
import { api, Apiaddress } from "../../../../utils/consts";
import profileContext from "../../../../context/profile/context";
import { NavbarForDirectory } from "../../../BuilderComponents/ProjectDashboard/DashboardComponents/NavbarForDirectory";
import TabBar from "../../Components/tabBar/TabBar";
import CPC from "../../context/context";
import { useNavigate } from "react-router-dom";
import AddIcon from "@mui/icons-material/Add";
import { AuthService } from "../../../../context/buyer/services/auth";

function LDmainPage(p){

  let roles = JSON.parse(localStorage.getItem("user_roles"));
  let crm_user = JSON.parse(localStorage.getItem("crm_user"));
  const cpDetails = JSON.parse(localStorage.getItem("cpDetails"));
  let cpOrg = cpDetails?.channelPartnerOrg;

  const [TabIndex, setTabIndex] = useState(0);
  const [loading, setloading] = useState(false);
  const [state, setState] = React.useState(false);
  const [trigger, setTrigger] = useState(false);
  const [rowSelected, setRowSelected] = useState(false);
  const [curpage, setcurpage] = useState(1);

  const toggleDrawer = (open, item) => (event) => {
    // console.log(open);
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    updateLead(item);
    setState(open);
  };

  const [tabledata, setTabledata] = useState(null);
  const [rowData, setrowData] = useState([]);
  const [idCont, setidCunt] = useState(1);
  const [orgID, setOrgID] = useState(null);
  const [leadsFromApi, setleadsFromApi] = useState([]);
  const [totalLead,setTotalLead] = useState(null);
  const [startLead,setStartLead] = useState(0);

  //context
  const user = useContext(profileContext);
  useEffect(() => {
    if (user)
      if (user.UserState)
        if (user.UserState.user_obj)
          if (user.UserState.user_obj.id) setOrgID(user.UserState.user_obj.id);
  }, [user]);

  const fetchLeadsInactive = async () => {
    try {
      setloading(true);
      const Auth = new AuthService();
      let url = "";
      if (cpOrg) {
        url = `/api/leads/requirementsofcp/?org_id=${cpOrg}&isUpdated=False&limit=${7}&offset=${startLead}`;
      } else {
        url = `/api/leads/requirementsofcp/?isUpdated=False&limit=${7}&offset=${startLead}`;
      }
      const res = await await (
        await Auth.makeAuthenticatedGetRequest(api(url))
      ).json();

      if (res?.requirements == "requirements not found") {
        setleadsFromApi([]);
      } else {
        if (TabIndex == 0) {
          setleadsFromApi(res?.requirements);
          setTotalLead(res?.total);
        }
      }
      setloading(false);
    } catch (err) {
      console.log(err);
    }
  };

  const fetchLeadsActive = async () => {
    try {
      setloading(true);
      const Auth = new AuthService();
      let url = "";
      if (cpOrg) {
        url = `/api/leads/requirementsofcp/?org_id=${cpOrg}&isUpdated=True&limit=${7}&offset=${startLead}`;
      } else {
        url = `/api/leads/requirementsofcp/?isUpdated=True&limit=${7}&offset=${startLead}`;
      }
      const res = await await (
        await Auth.makeAuthenticatedGetRequest(api(url))
      ).json();

      if (res?.requirements == "requirements not found") {
        setleadsFromApi([]);
      } else {
        if(TabIndex == 1){
          setleadsFromApi(res?.requirements);
          setTotalLead(res?.total);
        }
      }
      setloading(false);
    }catch (err) {
      console.log(err.message);
    }
  };

  useEffect(() => {
    if (leadsFromApi) {
      let arr = [];
      setrowData(arr)
      for (let i = 0; i < leadsFromApi.length; i++) {
        let temp_priority;
        if(leadsFromApi[i]?.lead?.lead_prority==1){
          temp_priority="Low"
        }
        else if(leadsFromApi[i]?.lead?.lead_prority==2){
          temp_priority="Medium"
        }
        else{
          temp_priority="High"
        }

        let obj = {
          id: i + 1,
          lead_id: leadsFromApi[i]?.lead?.id,
          Name: leadsFromApi[i]?.lead?.name,
          Phone: leadsFromApi[i]?.lead?.phone,
          Email: leadsFromApi[i]?.lead?.email,
          priority: temp_priority,
          requirement_form_id: leadsFromApi[i]?.id,
          requirement_form_name: leadsFromApi[i]?.form_name,
          replyed: leadsFromApi[i].replyed,
        };
        setidCunt(idCont + 1);
        arr.push(obj);
      }
      setrowData(arr);
    }
  },[leadsFromApi, trigger]);

  useEffect(() => {
    if (orgID){
      fetchLeadsActive();
      fetchLeadsInactive();
    }
  }, [orgID, state, trigger, TabIndex]);

  const navigator = useNavigate();
  const [updateData, setupdateData] = useState(null);
  const updateLead = (item) => {
    setupdateData(item);
  };

  //fitering data starts:====
  const [filterselect, setfilterselect] = useState();
  const [filtervalue, setfiltervalue] = useState();

  const handleChangeFilter = (event) => {
    setfilterselect(event.target.value);
    setfiltervalue("");
  };

  // console.log(filterselect);
  // console.log(filtervalue);

  const fetchfilter = async () => {
    try {
      if (TabIndex == 0) {
      setloading(true);
      const Auth = new AuthService();
      let url = "";
      if (cpOrg) {
        url = `/api/leads/requirementsofcp/?org_id=${cpOrg}&isUpdated=False&limit=${7}&offset=${startLead}&filterkey=${filterselect}&filtervalue=${filtervalue}`;
      } else {
        url = `/api/leads/requirementsofcp/?isUpdated=False&limit=${7}&offset=${startLead}&filterkey=${filterselect}&filtervalue=${filtervalue}`;
      }
      const res = await await (
        await Auth.makeAuthenticatedGetRequest(api(url))
      ).json();

      if (res?.requirements == "requirements not found") {
        setleadsFromApi([]);
      }else {
        setleadsFromApi(res?.requirements);
        setTotalLead(res?.total);
      }
      setloading(false);
      }
      else{
      setloading(true);
      const Auth = new AuthService();
      let url = "";
      if (cpOrg) {
        url = `/api/leads/requirementsofcp/?org_id=${cpOrg}&isUpdated=True&limit=${7}&offset=${startLead}&filterkey=${filterselect}&filtervalue=${filtervalue}`;
      } else {
        url = `/api/leads/requirementsofcp/?isUpdated=True&limit=${7}&offset=${startLead}&filterkey=${filterselect}&filtervalue=${filtervalue}`;
      }
      const res = await await (
        await Auth.makeAuthenticatedGetRequest(api(url))
      ).json();

      if (res?.requirements == "requirements not found") {
        setleadsFromApi([]);
      }else {
        setleadsFromApi(res?.requirements);
        setTotalLead(res?.total);
      }
      setloading(false);
      }
    } catch (err) {
      console.log(err);
    }
  };

  //////select btn to make priority and mark project ;
  const [makePriority, setMakePriority] = useState(false);
  const [makeProject, setMakeProject] = useState(false);

  return (
    <>
      <Stack direction="row" sx={{ width: "100%", height: "auto" }}>
        <Stack
          direction="column"
          sx={{
            backgroundColor: "#f9f9f9",
            width: "100%",
            padding: "0px",
            boxSizing: "border-box",
          }}
        >
          <Stack sx={{ backgroundColor: "#f9f9f9", mb: "40px" }}>
            <React.Fragment>
              <Drawer
                anchor="right"
                open={state}
                onClose={() => {
                  setState(false);
                  setMakePriority(false);
                  setMakeProject(false);
                }}
              >
                <RightSidebar
                  setTabledata={setTabledata}
                  toggleDrawer={toggleDrawer}
                  orgID={orgID}
                  updateData={updateData}
                  setTrigger={setTrigger}
                  trigger={trigger}
                  makePriority={makePriority}
                  makeProject={makeProject}
                  setMakePriority={setMakePriority}
                  setMakeProject={setMakeProject}
                  setState={setState}
                />
              </Drawer>
            </React.Fragment>  

            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              sx={{ height: "68px", marginBottom: "20px" }}
              >
              <Stack direction="column">
                <Typography
                  sx={{
                    fontSize: "22px",
                    fontWeight: "600",
                    color: "black",
                  }}
                >
                  Requirement Forms
                </Typography>
                <Typography  
                  sx={{
                    color: "#B9B7D0",
                    fontSize: "16px",
                    fontWeight: "400",
                  }}
                >
                  Manage your Requirement Forms
                </Typography>
              </Stack>
            </Stack>

            <TabBar
              onIndexChange={(e) => {
                setTabIndex(e);
                setStartLead(0);
                setcurpage(1);
                setTrigger(!trigger);
              }}
              Tabs={[
                {
                  children: [  
                    <LDcustomTable
                      newRowData={rowData}
                      updateLead={updateLead}
                      toggleDrawer={toggleDrawer}
                      setRowSelected={setRowSelected}
                      rowSelected={rowSelected}
                      loading={loading}
                      totalLead={totalLead}
                      setStartLead={setStartLead}
                      startLead={startLead}
                      curpage={curpage}
                      setcurpage={setcurpage}
                      trigger={trigger}
                      setTrigger={setTrigger}
                      filterselect={filterselect}
                      filtervalue={filtervalue}
                      setfilterselect={setfilterselect}
                      setfiltervalue={setfiltervalue}
                      handleChangeFilter={handleChangeFilter}
                      fetchfilter={fetchfilter}
                    />,
                  ],
                  Name: "Forms Sent",
                },
                {
                  children: [
                    <LDcustomTable
                      newRowData={rowData}
                      updateLead={updateLead}
                      toggleDrawer={toggleDrawer}
                      setRowSelected={setRowSelected}
                      rowSelected={rowSelected}
                      loading={loading}
                      formReceived={true}
                      totalLead={totalLead}
                      setStartLead={setStartLead}
                      startLead={startLead}
                      curpage={curpage}
                      setcurpage={setcurpage}
                      trigger={trigger}
                      setTrigger={setTrigger}
                      filterselect={filterselect}
                      filtervalue={filtervalue}
                      setfilterselect={setfilterselect}
                      setfiltervalue={setfiltervalue}
                      handleChangeFilter={handleChangeFilter}
                      fetchfilter={fetchfilter}
                    />,
                  ],
                  Name: "Forms Received",
                },
              ]}
            ></TabBar>
          </Stack>
        </Stack>
      </Stack>
    </>
  );
}

export default LDmainPage;
