import {
  Box,
  Button,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { Stack } from "@mui/system";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import SideBar from "../Components/sideBar/SideBar";
import { AuthService } from "../../../context/buyer/services/auth";
import { api, Apiaddress } from "../../../utils/consts";
import NavigateBack from "../../CommonPages/Shared/NavigateBack";

function TeamMember() {
  const navigate = useNavigate();

  const { state } = useLocation();
  const { member } = state;
  console.log(member);

  const user = JSON.parse(localStorage.getItem("cpDetails"));
  let cp_type = user?.cp_type;
  let account_type= user?.account_type;
  console.log(user?.user_details?.first_name);

  const [name, setName] = useState();
  const [phone, setPhone] = useState();
  const [email, setEmail] = useState();
  const [role, setRole] = useState();

  useEffect(() => {
    if (member) {
      setName(member?.profile?.user_details?.first_name);
      setPhone(member?.profile?.phone);
      setEmail(member?.profile?.user_details?.email);
      setRole(member?.cp_type);
    }
  }, []);

  const saveChanges = async () => {
    if (role == "") return;
    const server = new AuthService();
    // return;
    const x = await (
      await server.makeAuthenticatedPatchRequest(api("/api/role/createcp/"), {
        JsonBody: { cp_type: role, id: member?.id },
      })
    ).json();
    console.log("Channel partner teammember updated", x);
    navigate(-1);
  };

  return (
    <SideBar active={"Teams"}>

<Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              sx={{ height: "68px", marginBottom: "20px" }}

            >
              <Stack>
                <Stack direction="row">
                  <Box sx={{ pt: "5px" }}>
                    <NavigateBack fontSize="22px" />
                  </Box>
                  <Typography
                    sx={{
                      fontSize: "22px",
                      fontWeight: "600",
                      color: "black",
                    }}
                    >
                    Teams Directory
                  </Typography>
                </Stack>
                <Typography
                  sx={{
                    color: "#B9B7D0",
                    fontSize: "16px",
                    fontWeight: "400",
                  }}
                >
                  Manage your Teams
                </Typography>
              </Stack>
            </Stack>

      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <Stack
          spacing={3}
          sx={{
            mt: "30px",
            borderRadius: "8px",
            padding: "30px",
            backgroundColor: "#FFF",
            border: "2px solid transparent",
            width: "40%",
            "&:hover": {
              boxShadow: "-6px 18px 15px rgba(0, 0, 0, 0.1)",
              border: "2px solid  #2979F2",
            },
          }}
        >
          <Typography
            sx={{
              fontSize: "22px",
              fontWeight: "700",
              fontFamily: "Circular Std",
            }}
          >
            Member Overview
          </Typography>

          <Box sx={{ display: "flex", alignItems: "center", gap: "10px" }}>
            <Typography sx={{ width: "70px", fontFamily: "Product Sans" }}>
              Name
            </Typography>
            <TextField
              value={name}
              disabled
              sx={{ width: "300px" }}
            ></TextField>
          </Box>
          <Box sx={{ display: "flex", alignItems: "center", gap: "10px" }}>
            <Typography sx={{ width: "70px", fontFamily: "Product Sans" }}>
              Phone
            </Typography>
            <TextField
              value={phone}
              disabled
              sx={{ width: "300px" }}
            ></TextField>
          </Box>

          <Box sx={{ display: "flex", alignItems: "center", gap: "10px" }}>
            <Typography sx={{ width: "70px", fontFamily: "Product Sans" }}>
              Email
            </Typography>
            <TextField
              value={email}
              disabled
              sx={{ width: "300px" }}
            ></TextField>
          </Box>
          <Box sx={{ display: "flex", alignItems: "center", gap: "10px" }}>
            <Typography sx={{ width: "70px", fontFamily: "Product Sans" }}>
              Role
            </Typography>
            <Box sx={{ width: "300px" }}>
              <Select 
                disabled={member?.owner==true?true:(cp_type == "Individual" && account_type == "Org")?true:false}
                value={role == "Individual" ? "Individual" : "Admin"}
                onChange={(e) => {
                  setRole(e.target.value);
                }}
              >
                <MenuItem value={"Individual"}>Individual</MenuItem>
                <MenuItem value={"Admin"}>Admin</MenuItem>
              </Select>
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              mt: "10px",
              width: "100%",
            }}
          >
            {member?.owner==true?<Typography sx={{color: "#06386B",fontSize: "20px"}}>Organisation Owner</Typography>:
             (cp_type == "Individual" && account_type == "Org")?<Typography sx={{color: "#06386B",fontSize: "20px"}}>Can't Change Role</Typography>:
            <Button
              onClick={saveChanges}
              sx={{
                // (cp_type == "Individual" && account_type == "Org")
                padding: "10px 60px",
                color: "#06386B",
                textTransform: "none",
                fontSize: "20px",
                fontFamily: "Product Sans",
                background:
                  "linear-gradient(97.85deg, #FFAF2A 0.95%, #FFC666 100%)",
                "&:hover": {
                  boxShadow: "0px 9px 12px rgba(242, 178, 73, 0.59)",
                },
              }}
            >
              Update Member
            </Button>
            }
          </Box>
        </Stack>
      </Box>
    </SideBar>
  );
}

export default TeamMember;
