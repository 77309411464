import React, { useContext, useEffect, useRef, useState } from 'react'
import AddIcon from '@mui/icons-material/Add';
import { useNavigate } from "react-router-dom";
import EditIcon from "@mui/icons-material/Edit";
import TabBar from '../../components/tabBar/TabBar'
import UploadIcon from '@mui/icons-material/Upload';
import DeleteIcon from "@mui/icons-material/Delete";
import VisibilityIcon from '@mui/icons-material/Visibility';
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { Box, Button, Drawer, Stack, TextField } from '@mui/material'
import { BuyerService } from '../../../../context/buyer/services/buyerServices';
import buyerContext from '../../../../context/buyer/context';

export const View = (props: any) => {
    const bc: BuyerService = useContext(buyerContext);
    const [CreateDocumnetModalState, setCreateDocumnetModalState] = useState(false)
    const [Documents, setDocuments] = useState([])
    const [Category, setCategotry] = useState('')
    const [notes, setNotes] = useState("")

    useEffect(() => {
        const x = async()=>{
            const b=  (await bc.server.tickets.getTicketsListUsingToken()).ticket
            setDocuments(b ==="Ticket not found" ? []:b)}
        x();
    }, [])
    const [file, setfile] = useState<FileList | null>(null)
    const inputFile = useRef<HTMLInputElement | null>(null)


    return (
        <>
            <Box sx={{
                // minHeight: 500,
                margin: 5,
                color: "white",
                boxShadow: 10,
                padding: 2
            }}>
                <Box sx={{
                    color: "#0065FE",
                    fontSize: 24,
                    fontWeight: 700,
                }}>
                    Tickets
                </Box>
                <TabBar

                    floatingActonButton={
                        <Button
                            onClick={() => {
                                setCreateDocumnetModalState(true)
                            }}
                            className="univercel_btn_class"
                            sx={{ color: "#FFFFFF", padding: "6px 12px 6px 12px", height: "auto", margin: "10px" }}
                            startIcon={<AddIcon />}
                        >
                            Add Ticket
                        </Button>
                    }
                    Tabs={[
                        {
                            Name: 'Open',
                            children: [
                                <>
                                    <Box sx={{ color: "black", width: "100%" }}>
                                        <TitleRow />
                                        {  Documents  ?Documents.map((e)=>{
                                            //@ts-ignore
                                            if(e.status === "open"){
                                            //@ts-ignore
                                            return <RowItem category={e.category} description={e.disc} ticketid={e.id} date={e.created_on}/>
                                            }
                                        }):[<div>
                                            You do not have any tickets
                                        </div>]}


                                    </Box>
                                </>
                            ]
                        },
                        {
                            Name: 'Closed',
                            children: [
                                <>
                                    <Box sx={{ color: "black", width: "100%" }}>
                                        <TitleRow />
                                        {  Documents  ?countWhereClosed(Documents) == 0? <div>
                                            You do not have any closed tickets
                                        </div>
                                        :Documents.map((e)=>{
                                            //@ts-ignore
                                            if(e.status === "closed"){
                                            //@ts-ignore
                                                return <RowItem category={e.category} description={e.disc} ticketid={e.id} date={e.created_on}/>
                                            }

                                        }):null}

                                    </Box>
                                </>
                            ]
                        },
                    ]} />
            </Box>
            <Drawer anchor="right" open={CreateDocumnetModalState}>
                <Box
                    sx={{
                        width: "420px",
                        height: "100%",
                        marginRight: "25px",
                        padding: "10px",
                    }}
                >

                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            height: "100%"
                        }}
                    >

                        <Box
                            sx={{
                                display: "flex",
                                flexDirection: "column",
                                width: "100%"
                            }}>

                            <Box
                                sx={{
                                    backgroundColor: "#FFAF2A",
                                    width: "95%",
                                    height: "60px",
                                    borderRadius: "4px",
                                    color: "white",
                                    display: "flex",
                                    alignItems: "center",
                                    paddingLeft: "20px"
                                }}
                            >
                                New Tickets

                            </Box>

                            <Box
                                sx={{
                                    width: "100%",
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "center",

                                }}
                            >
                                <table cellPadding={20}>
                                    <tr>
                                        <td>Choose Category</td>
                                        <td><TextField onChange={(e) => {
                                            setCategotry(e.target.value)
                                        }
                                        }></TextField></td>
                                    </tr>
                                    <tr>
                                        <td>Attach</td>
                                        <td><Button

                                            onClick={() => {
                                                inputFile?.current?.click();

                                            }}
                                            variant="contained"
                                            className="univercel_btn_class"
                                            sx={{ color: "#FFFFFF", padding: "6px 12px 6px 12px", height: "auto", margin: "10px" }}
                                            startIcon={<UploadIcon />}
                                        >
                                            Upload
                                            <input type='file' multiple onInput={(e) => {
                                                // @ts-ignore
                                                setfile(e.currentTarget.files)
                                                console.log("Files", e.currentTarget.files);

                                            }} id='file' ref={inputFile} style={{ display: 'none' }} />
                                        </Button></td>
                                    </tr>
                                    <tr>
                                        <td>Description</td>
                                        <td><textarea onChange={(e) => {
                                            setNotes(e.target.value)
                                        }
                                        }></textarea></td>
                                    </tr>
                                    <tr>
                                        <th colSpan={2}>

                                            <Box sx={{
                                                display: 'flex',
                                                flexDirection: "row",
                                                justifyContent: "center",
                                                alignItems: "center"
                                            }}>
                                                <Button
                                                    onClick={async () => {
                                                        console.log(file);

                                                        const uploaded = await bc.server.tickets.createTicket(
                                                            Category,
                                                            notes,
                                                            file,
                                                        );
                                                        console.log("UPLOADED",uploaded);
                                                        
                                                        setDocuments(uploaded.ticket ==="Ticket not found" ? []:uploaded.ticket);
                                                        setCreateDocumnetModalState(false);
                                                    }}


                                                    className="univercel_btn_class"
                                                    sx={{ color: "#FFFFFF", padding: "6px 20px 6px 20px", height: "auto", margin: "10px" }}

                                                >
                                                    Upload
                                                </Button>
                                                <Button onClick={() => {
                                                    setCreateDocumnetModalState(false)
                                                }} variant="text">Cancel</Button>

                                            </Box>
                                        </th>
                                    </tr>
                                </table>
                            </Box>


                        </Box>
                    </Box>

                </Box>


            </Drawer>
        </>
    )
}


const countWhereClosed = (arr:any)=>{
    let  ans = 0 
    for (let i = 0; i < arr.length ; i++ ){
        if(arr[i].status === "closed"){
            //@ts-ignore
            ans += 1
            }
    }
    return ans
}
const TitleRow = () => {
    return (
        <Box
            sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                background: "white",
                borderRadius: "5px",
                margin: " 10px 0",
                color: "#9299BC",
                fontWeight: "600",
            }}
        >
            <Box
                sx={{
                    width: "15vw",
                    display: "grid",
                    placeItems: "center",
                    height: "50px",
                }}
            >
                <Box sx={{ display: "flex" }}>
                    <Box sx={{ display: "grid", placeItems: "center" }}>
                        <input type="checkbox" name="" id="" />
                    </Box>



                </Box>
            </Box>
            <Box
                sx={{
                    width: "15vw",
                    display: "grid",
                    placeItems: "center",
                    height: "50px",
                }}
            >
                <Box sx={{ display: "flex" }}>


                    <Box sx={{ display: "grid", placeItems: "center" }}>
                        Date
                    </Box>
                    <Box sx={{ display: "grid", marginLeft: "4px" }}>
                        <ArrowDropUpIcon
                            fontSize="medium"
                            sx={{ marginBottom: "-8px" }}
                        />
                        <ArrowDropDownIcon
                            fontSize="medium"
                            sx={{ marginTop: "-8px" }}
                        />
                    </Box>
                </Box>
            </Box>

            <Box
                sx={{
                    width: "15vw",
                    display: "grid",
                    placeItems: "center",
                    height: "50px",
                }}
            >
                <Box sx={{ display: "flex" }}>
                    <Box sx={{ display: "grid", placeItems: "center" }}>Category</Box>
                    <Box sx={{ display: "grid", marginLeft: "4px" }}>
                        <ArrowDropUpIcon
                            fontSize="medium"
                            sx={{ marginBottom: "-8px" }}
                        />
                        <ArrowDropDownIcon
                            fontSize="medium"
                            sx={{ marginTop: "-8px" }}
                        />
                    </Box>
                </Box>
            </Box>

            <Box
                sx={{
                    width: "30vw",
                    display: "grid",
                    placeItems: "center",
                    height: "50px",
                }}
            >
                <Box sx={{ display: "flex" }}>
                    <Box sx={{ display: "grid", placeItems: "center" }}>Description</Box>
                    <Box sx={{ display: "grid", marginLeft: "4px" }}>
                        <ArrowDropUpIcon
                            fontSize="medium"
                            sx={{ marginBottom: "-8px" }}
                        />
                        <ArrowDropDownIcon
                            fontSize="medium"
                            sx={{ marginTop: "-8px" }}
                        />
                    </Box>
                </Box>
            </Box>
            <Box
                sx={{
                    width: "15vw",
                    display: "grid",
                    placeItems: "center",
                    height: "50px",
                }}
            >
                <Box sx={{ display: "flex" }}>
                    <Box sx={{ display: "grid", placeItems: "center" }}>Actions</Box>
                    <Box sx={{ display: "grid", marginLeft: "4px" }}>
                        <ArrowDropUpIcon
                            fontSize="medium"
                            sx={{ marginBottom: "-8px" }}
                        />
                        <ArrowDropDownIcon
                            fontSize="medium"
                            sx={{ marginTop: "-8px" }}
                        />
                    </Box>
                </Box>
            </Box>

        </Box>
    )
}

export { TitleRow };


export function RowItem({date,category,description,ticketid}:{date:string,category:string,description:string,ticketid:number}) {
    const bc: BuyerService = useContext(buyerContext);
    
    const navigator = useNavigate();
    return (
        <Box
       
            sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                background: "white",
                borderRadius: "5px",
                margin: " 10px 0",
                color: "#06152C",
                fontWeight: "500"
            }}
        // onClick={()=>{
        //    console.log("Prent Called");
        //    navigate(`/channelpartneroverview/${item.CPid}`, {
        //       state: item,
        //     });
        // }}
        ><Box
            sx={{
                width: "15vw",
                display: "grid",
                placeItems: "center",
                height: "50px",
            }}
        >
                <input type="checkbox" name="" id="" />
            </Box>


            <Box
                sx={{
                    width: "15vw",
                    display: "grid",
                    placeItems: "center",
                    height: "50px",
                }}
            >
                {
                    date.split("T")[0]
}
            </Box>
            <Box
                sx={{
                    width: "15vw",
                    display: "grid",
                    placeItems: "center",
                    height: "50px",
                }}
            >
                {category}
            </Box>
            <Box
                sx={{
                    width: "30vw",
                    display: "grid",
                    placeItems: "center",
                    height: "50px",
                }}
            >
                {description}
            </Box>

            <Box
                sx={{
                    width: "15vw",
                    display: "grid",
                    placeItems: "center",
                    height: "50px",
                }}
            >
                <Stack direction="row" sx={{ marginLeft: "10px" }}>

                    {/* <RemoveRedEyeIcon
          fontSize="medium"
          onClick={(e) => {
            e.stopPropagation();
            navigate(`/channelpartneroverview/${item.CPid}`, {
              state: item,
            });
          }}
        /> */}

                    <EditIcon
                        onClick={() => {
                            navigator(`/buyer/tickets/${ticketid}/edit`)
                        }}
                        fontSize="medium"
                        sx={{ marginLeft: "10px" }}
                    />
                    <VisibilityIcon
                        onClick={() => {
                            navigator(`/buyer/tickets/${ticketid}`)
                        }}
                        sx={{ marginLeft: "10px" }}
                    />
                    {/* <DeleteIcon
                        fontSize="medium"
                        sx={{ marginLeft: "10px" }}
                        onClick={async() => { 
                            await bc.server.tickets.delete((ticketid.toString()))
                        }}
                    /> */}
                </Stack>
            </Box>
        </Box>
    )
}
