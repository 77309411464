import { Box, Container, Stack, Typography } from "@mui/material";
import BookingProcessManagement from "../../Imgs/booking-process-management.png";
import CircleCheckIcon from "../../Imgs/circle-check-icon.svg";

const CommonPagesBookingProcessManagement = () => {
  return (
    <Box sx={{ backgroundColor: "#FFF", overflowX: "hidden" }}>
      <Container>
        <Box
          sx={{
            display: "flex",
            gap: { xs: "10px", md: "30px" },
            alignItems: "center",
            flexDirection: { xs: "column", md: "row" },
          }}
        >
          <Box
            data-aos="zoom-in"
            data-aos-offset="180"
            sx={{ width: { xs: "100%", md: "45%" } }}
          >
            <img
              style={{ width: "100%" }}
              src={BookingProcessManagement}
              alt=""
            />
          </Box>
          <Stack sx={{ width: { xs: "100%", md: "55%" } }}>
            <Typography
              data-aos="fade-left"
              sx={{
                color: "#272D4E",
                fontSize: { xs: "24px", md: "32px", lg: "40px" },
                fontWeight: 700,
                fontFamily: "Circular Std",
                lineHeight: "1.2",
              }}
            >
              Booking Process Management
            </Typography>

            <Stack spacing={2}>
              <Typography
                sx={{
                  fontSize: "18px",
                  fontFamily: "Product Sans",
                }}
              >
                Manage and optimize your bookings with a seamless booking
                process designed by industry experts
              </Typography>
              <Box sx={{ display: "flex", gap: "10px" }}>
                <Box sx={{ lineHeight: "10px", fontSize: "30px" }}>
                  {/* <img src={CircleCheckIcon} alt="" /> */}
                  &#x2022;
                </Box>
                <Typography
                  sx={{
                    fontSize: "16px",
                    fontFamily: "Product Sans",
                    color: "#343434",
                    lineHeight: "1.3",
                  }}
                >
                  Capture and{" "}
                  <span style={{ fontWeight: 600 }}>
                    keep track of the site visits
                  </span>{" "}
                </Typography>
              </Box>
              <Box sx={{ display: "flex", gap: "10px" }}>
                <Box sx={{ lineHeight: "10px", fontSize: "30px" }}>
                  {/* <img src={CircleCheckIcon} alt="" /> */}
                  &#x2022;
                </Box>
                <Typography
                  sx={{
                    fontSize: "16px",
                    fontFamily: "Product Sans",
                    color: "#343434",
                    lineHeight: "1.3",
                  }}
                >
                  Send{" "}
                  <span style={{ fontWeight: 600 }}>
                    automated and personalized
                  </span>{" "}
                  demand letters, agreements, and other documents
                </Typography>
              </Box>
              <Box sx={{ display: "flex", gap: "10px" }}>
                <Box sx={{ lineHeight: "10px", fontSize: "30px" }}>
                  {/* <img src={CircleCheckIcon} alt="" /> */}
                  &#x2022;
                </Box>
                <Typography
                  sx={{
                    fontSize: "16px",
                    fontFamily: "Product Sans",
                    color: "#343434",
                    lineHeight: "1.3",
                  }}
                >
                  <span style={{ fontWeight: 600 }}>Manage and monitor</span>{" "}
                  negotiations, booking amounts, and more
                </Typography>
              </Box>
              <Box sx={{ display: "flex", gap: "10px" }}>
                <Box sx={{ lineHeight: "10px", fontSize: "30px" }}>
                  {/* <img src={CircleCheckIcon} alt="" /> */}
                  &#x2022;
                </Box>
                <Typography
                  sx={{
                    fontSize: "16px",
                    fontFamily: "Product Sans",
                    color: "#343434",
                    lineHeight: "1.3",
                  }}
                >
                  <span style={{ fontWeight: 600 }}>Negotiate</span> with home
                  buyers in real-time
                </Typography>
              </Box>
              <Box sx={{ display: "flex", gap: "10px" }}>
                <Box sx={{ lineHeight: "10px", fontSize: "30px" }}>
                  {/* <img src={CircleCheckIcon} alt="" /> */}
                  &#x2022;
                </Box>
                <Typography
                  sx={{
                    fontSize: "16px",
                    fontFamily: "Product Sans",
                    color: "#343434",
                    lineHeight: "1.3",
                  }}
                >
                  <span style={{ fontWeight: 600 }}>Analyze trends</span> across
                  your projects
                </Typography>
              </Box>
            </Stack>
          </Stack>
        </Box>
      </Container>
    </Box>
  );
};

export default CommonPagesBookingProcessManagement;
