import {
  Button,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  SelectChangeEvent,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Box } from "@mui/system";
import { GridCloseIcon } from "@mui/x-data-grid";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { AuthService } from "../../../../../../context/buyer/services/auth";
import { BlueButton } from "../../../../../../utils/common/BlueButton";
import { api } from "../../../../../../utils/consts";
import { BasicLayout } from "../../basic_layout";
import { GetSection } from "../../RequirementFormTemplate";
import { GetItemInput } from "../input/InputReduser";
import { GetItem } from "../view/ViewReduser";

function FormBuild({
  open,
  setExtraSections,
  ExtraSections,
  Selected,
  setopen,
  setSelected,
  con,
}) {
  const nav = useNavigate();
  const [FieldN, setFieldN] = useState("");
  const [description, setDescription] = useState("");
  const [SectionData, setSectionData] = useState({ title: "", formFields: [] });
  const [clicked, setClicked] = useState(true);
  const cpDetails = JSON.parse(localStorage.getItem("cpDetails"));
  const [error, setError] = useState(false);
  const [showFeild,setShowField] = useState(false);
  // // console.log(cpDetails);
  let cpOrg = cpDetails?.channelPartnerOrg;

  // // console.log(FieldN);
  // // console.log(SectionData);

  return (
    <Modal
      onClose={() => {
        setopen({ open: false, reason: "" });
      }}
      open={open.open}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        className="hidescrollbar"
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: open.reason == "save" ? 400 : "600px",
          bgcolor: "white",
          border: "2px solid white",
          borderRadius: "10px",
          boxShadow: 24,
          pt: 3,
          px: 3,
          pb: 3,
          maxHeight: "80vh",
          minHeight: open.reason == "save" ? "20vh" : "80vh",
          overflowY: "scroll",
        }}
      >
        <Box sx={{ textAlign: "right"}}>
          <GridCloseIcon
            sx={{ color: "gray", cursor: "pointer" }}
            onClick={(e) => {
              setopen({ ...open, open: !open.open });
            }}
          />
        </Box>

        {open.reason === "save" && (
          <>
            <Typography
              sx={{
                fontFamily: "Product Sans",
                fontSize: "18px",
                fontWeight: "600",
                marginBottom: "25px",
                marginTop: "-20px",
                color: "black",
              }}
            >
              Template Name
            </Typography>
            <Typography
              sx={{
                fontSize: "1vw",
                fontWeight: 500,
                fontFamily: "Product Sans",
                marginBottom: "15px",
                marginTop: "-20px",
                color: error ? "red" : "#B0AFAF",
              }}
            >
              Please add a name & description to your template
            </Typography>
          </>
        )}

        {open.reason === "save" && (
          <Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
              }}
            >
              <Typography
                sx={{
                  width: "100px",
                  fontSize: "16px",
                  fontWeight: 500,
                  fontFamily: "Product Sans",
                  color: "#B0AFAF",
                  mr: "15px",
                }}
              >
                Name:
              </Typography>
              <TextField
                onBlur={(e) => {
                  if (e.target.value.length > 1) {
                    setError(false);
                  }
                }}
                fullWidth
                onChange={(e) => {
                  setFieldN(e.target.value);
                }}
              />
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Typography
                sx={{
                  width: "100px",
                  fontSize: "16px",
                  fontWeight: 500,
                  mr: "6px",
                  fontFamily: "Product Sans",
                  color: "#B0AFAF",
                }}
              >
                Description:
              </Typography>
              <TextField
                onBlur={(e) => {
                  if (e.target.value.length > 1) {
                    setError(false);
                    setClicked(false);
                  } else {
                    setClicked(true);
                  }
                }}
                fullWidth
                inputProps={{
                  maxLength: 115,
                }}
                sx={{
                  mt: 2,
                }}
                onChange={(e) => {
                  setDescription(e.target.value);
                }}
              />
            </Box>
          </Box>
        )}

        {open.reason === "add" && (
          <Box>
            <Typography
              sx={{
                fontSize: "22px",
                fontWeight: "600",
                marginBottom: "30px",
                marginTop: "-20px",
                color: "black",
                textAlign: "center",
              }}
            >
              Add Section
            </Typography>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                // alignItems: "center",
              }}
             >
              <Stack direction="row" justifyContent="space-between" alignItems="center">
              <Box
                sx={{
                  width: "340px",
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "row",
                  // border:"1px solid green"
                }}
              >
                <Typography
                  sx={{
                    fontFamily: "Product Sans",
                    my: 2,
                    fontStyle: "normal",
                    fontWeight: "500",
                    fontSize: "18px",
                    lineHeight: "21px",
                    color: "#84828C",
                    marginRight: "20px",
                  }}
                >
                  Section Title
                </Typography>
                <TextField
                  sx={{ margin: "10px", width: "200px" }}
                  // label={"Title"}
                  onChange={(e) => {
                    if (e.target.value.length < 1) {
                      setClicked(true);
                      return;
                    }
                    setFieldN(e.target.value);
                    setClicked(false);
                  }}
                ></TextField>
              </Box>
              </Stack>
            </Box>

            <Stack direction="row" justifyContent="space-between" alignItems="center" sx={{}}>
            <Typography sx={{
              my: 0,
              fontSize: "16px",
              lineHeight: "21px",
              fontFamily: "Product Sans",
              fontStyle: "normal",
              fontWeight: "500",
              color: "#84828C",
              marginRight: "15px",
              marginTop:"20px",
            }}>You Can Add Multiple fields</Typography>
              <Button
                className="univercel_btn_class"
                sx={{
                  // marginRight: "32px",
                  color: "#FFFFFF",
                  textTransform: "none",
                  padding: "8px 24px",
                  fontFamily: "Product Sans",
                  fontSize: "16px",
                }}
                onClick={() => {setShowField(true)}}
                >
                Add Field
              </Button>
            </Stack>

            {showFeild &&
              <Box
                sx={{
                  display: "flex",
                  width: "340px",
                  alignItems: "center",
                  gap: "10px",
                  flexDirection: "row",
                  marginTop:"20px"
                  // border:"1px solid red"
                }}
                >
                <Typography
                  sx={{
                    my: 2,
                    fontSize: "18px",
                    lineHeight: "21px",
                    fontFamily: "Product Sans",
                    fontStyle: "normal",
                    fontWeight: "500",
                    color: "#84828C",
                    marginRight: "15px",
                  }}
                >
                  Type of Field
                </Typography>
                <Box
                  sx={{
                    width: "200px",
                  }}
                >
                  <Select
                    value={Selected}
                    onChange={(e) => {
                      setSelected(e.target.value);
                      // // console.log("This is selected", Selected);
                    }}
                    displayEmpty
                    inputProps={{ "aria-label": "Without label" }}
                    // renderValue={
                    //   Selected !== ""
                    //     ? undefined
                    //     : () => (
                    //         <Typography sx={{ color: "#aaa" }}>
                    //           Types Of Field
                    //         </Typography>
                    //       )
                    // }
                    fullWidth
                    sx={{
                      textTransform: "capitalize",
                    }}
                  >
                    <MenuItem
                      value=""
                      disabled
                      sx={{ textTransform: "capitalize" }}
                    >
                      Select
                    </MenuItem>
                    {[
                      "normal",
                      "withtrailing",
                      "radio",
                      "check",
                      "doubletrailing",
                    ].map((e) => {
                      return (
                        <MenuItem
                          value={e}
                          sx={{ textTransform: "capitalize" }}
                        >
                          {e}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </Box>
              </Box>}

            {/* pratham */}
            {Selected && (
              <Box
                sx={{
                  my: "15px",
                  py: "10px",
                  fontSize: "18px",
                  color: "#aaa",
                  pl: "10px",
                  // height:"",
                  background: "#CEE9FF",
                  textTransform: "capitalize",
                  fontFamily: "Product Sans",
                  fontStyle: "normal",
                  color: "#84828C",
                }}
              >
                {Selected}
              </Box>
            )}
            <Box sx={{}}>
              {Selected != "" &&
                GetItemInput({
                  ...getPreviewData(Selected),
                  setData: (e) => {
                    setSelected("");
                    setSectionData({
                      ...SectionData,
                      formFields: [...SectionData.formFields, e],
                    });
                  },
                  setShow: ()=>{setShowField(false)},
                  deleteSelf: () => {},
                })}
            </Box>
            <Box
              sx={{
                border: "1px solid #9299BC",
                height: "0px",
                my: 2,
              }}
            ></Box>
            <Box
              style={{
                display: "flex",
                alignItems: "flex-start",
                gap: "0.75rem",
                flexDirection: "column",
                // border:"1px solid red"
              }}
            >
              {SectionData.formFields.map((e) => {
                return GetItem(e);
              })}
            </Box>
          </Box>
        )}

        <Box
          sx={{
            textAlign: "center",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Button
            onClick={async () => {
              if (
                (FieldN == "" || description == "") &&
                open.reason == "save"
              ) {
                setError(true);
                return;
              }
              if (
                FieldN !== "" &&
                description !== "" &&
                open.reason == "save"
              ) {
                setClicked(true);
                const x = new AuthService();
                await x.makeAuthenticatedPostRequest(
                  api("/api/leads/requirementtemplate/"),
                  {
                    JsonBody: {
                      name: FieldN,
                      description: description,
                      basic_details: BasicLayout,
                      other_details: ExtraSections,
                      channelPartner: x.getCPID(),
                      cp_org: cpOrg ? cpOrg : null,
                    },
                  }
                );
                setFieldN("");
                setDescription("");
                setClicked(false);
                nav("/channelpartner/requirements-form");
              } else if (
                FieldN !== "" &&
                open.reason == "add" &&
                SectionData?.formFields?.length !== 0
              ) {
                // console.log(FieldN);
                setClicked(true);
                setExtraSections([
                  ...ExtraSections,
                  { ...SectionData, title: FieldN },
                ]);
                setSectionData({ title: FieldN, formFields: [] });
                setopen({ open: false, reason: "" });
                setFieldN("");
                setClicked(false);
              }
            }}
            variant="contained"
            disabled={clicked}
            sx={{
              padding: "10px 20px",
              color: "#06386B",
              textTransform: "none",
              mt: 4,
              fontSize: "20px",
              fontFamily: "Product Sans",
              background:
                "linear-gradient(97.85deg, #FFAF2A 0.95%, #FFC666 100%)",
              "&:hover": {
                boxShadow: "0px 9px 12px rgba(242, 178, 73, 0.59)",
              },
            }}
          >
            Save Section
          </Button>
        </Box>
      </Box>
    </Modal>
  );
}

export const getPreviewData = (type) => {
  for (let i = 0; i < BasicLayout.formFields.length; i++) {
    // console.log(
    // "asdasdasdasdasdasdasdasdasd",
    //   BasicLayout.formFields[i].type == type
    // );
    if (BasicLayout.formFields[i].type == type) {
      // console.log("Preview", BasicLayout.formFields[i]);
      return BasicLayout.formFields[i];
    }
  }
};

export default FormBuild;
