import { Box, Stack} from '@mui/system';
import React, { useEffect, useState } from 'react';
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { Accordion, AccordionDetails, AccordionSummary, Button, CircularProgress, FormControl, InputLabel, MenuItem, Modal, Select, TextField, Typography } from '@mui/material';
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import TuneOutlinedIcon from "@mui/icons-material/TuneOutlined";

import { SuperAdminSidebar } from '../SidebarNavbar/SuperAdminSidebar';
import { SuperAdminNavbar } from '../SidebarNavbar/SuperAdminNavbar';
import { Apiaddress } from "../../../utils/consts";
import axios from 'axios';
import { Link, useLocation, useNavigate } from 'react-router-dom';

import OTPInput, { ResendOTP } from "otp-input-react";
import { GridExpandMoreIcon } from '@mui/x-data-grid';
import { UserRole } from '../../../UserRoles/UserRoles';

function SuperAdminProject() {

     ////get user roles from local storage and assign 
     let roles = JSON.parse(localStorage.getItem("user_roles"))
     let project = roles["project"];
     let unit = roles["unit"];

    const [selectedProjectId,setSelectedProjectId]= useState();

    //for navigation to create project or unit directory 
    const [navcreateP, setnavcrateP] = useState(false)

    //for modal  confirm otp
    const [open, setOpen] = useState(false);
    const handleOpen = (addbtn) => {
      setOpen(true);
      if(addbtn){
        setnavcrateP(true)
      }
    };
    const handleClose = () => {
      setOpen(false);
      setnavcrateP(false)
    };

    //get email from local storage 
    const  builderEmail = JSON.parse(localStorage.getItem("SABuilderDetails"));
    console.log("builder email from local", builderEmail.BuilderEmail)
    console.log("navigation status ", navcreateP)
    ///for otp 
    const [email, setEmail] = useState(builderEmail.BuilderEmail)
    const [EmailOTP, setEmailOTP] = useState("");
    const navigate = useNavigate();

    //// get otp function ;
    const getOtp = async()=>{
      const url = Apiaddress+"/api/auth/accessbuilder/"
      let data = {
        email:email,//"builder@demo.com"
      }
      const res = await axios.put(url,data);
      console.log(res.data);

    }
    

    const [error,seterror]=useState(false);
    ///verify otp function
    const vrifyOTP = async()=>{
      try{
        const url =  Apiaddress+"/api/auth/accessbuilder/"
        let data = {
          email:email,//"builder@demo.com",
          otp:EmailOTP
        }

        const res = await axios.post(url,data);
        console.log("Verify OTP========>",res.data)


         //////////////////////////////////////////
         const url1 = Apiaddress+"/api/profile/"
         const re = await axios.get(url1,
           {
             headers: {
               Authorization: "JWT " + res?.data?.token,
             }
           }
         );
         console.log("builder Profile details", re.data)
         //store builder details to local storage 
         let datafromlocal = JSON.parse(localStorage.getItem("SABuilderDetails"));// get data from local storage
         console.log("ff",datafromlocal)
         const content = {
           builder_token:res?.data?.token,
           builder_Details:re.data.user_obj,
           selectedProjectId:selectedProjectId
         }
         if(datafromlocal){
           localStorage.setItem("SABuilderDetails", JSON.stringify({...datafromlocal,...content}))
         }
         else{
          localStorage.setItem("SABuilderDetails", JSON.stringify(content));
         }

         //if user click on add project btn then move to create project page otherwise unit directory
         console.log(navcreateP) 
         if(navcreateP){
          navigate("/super-admin-create-project-profile")
         }
         else{
          // navigate("/superadmin-unit")
          navigate("/super-admin-unit-directory")
         }
        //  {navcreateP? navigate("/super-admin-create-project-profile"):navigate("/superadmin-unit")}


      }
      catch(err){
        setEmailOTP("");
        seterror(true); 
      }     
    }

    //get data from previous page 
    let datafromlocal = JSON.parse(localStorage.getItem("SABuilderDetails"));// get data from local storage

    const location = useLocation();
    // const data = location?.state;
    const data = datafromlocal.selectedOrgId

    console.log("preve data =>.>",data)

    const [orgid,setorgid]=useState("");

  
   const [curpage, setcurpage] = useState(1);
   const [pageRange, setPageRange] = useState([0, 9]);
   const [pages, setPages] = useState([]);

   const [loading,setloading]= useState(true);

   const [newRowData,setnewRowData]= useState([]);

    const nextClick = () => {
    if (
      !(newRowData.length >= pageRange[0] && newRowData.length <= pageRange[1])
    ) {
      setcurpage(curpage + 1);
      setPageRange([pageRange[0] + 9, pageRange[1] + 9]);
    }
    };

  const prvClick = () => {
    if (pageRange[0] != 0 && pageRange[1] != 9) {
      setcurpage(curpage - 1);
      setPageRange([pageRange[0] - 9, pageRange[1] - 9]);
    }
    };

    useEffect(() => {
    if (newRowData) {
      let arr = [];
      for (
        let i = 1;
        i <=
        (newRowData.length % 9 == 0
          ? newRowData.length / 9
          : newRowData.length / 9 + 1);
        i++
      ) {
        arr.push(i);
      }
      setPages(arr);
    }
  }, [newRowData]);


   //fetching ProjectList
  const fetchProjectdata=async ()=>{
       
       const projecturl = Apiaddress+"/api/projects/orgprojectlist/"
        
        let orglist=[];
        let obj={"orgid":orgid};
        orglist.push(obj);

       const data= {
        orglist:orglist
       }

       const headers = {
        headers:{
          "Content-Type": "application/json"
        }
        };
       const res = await axios.post(
        projecturl,
        data
      );
      console.log(res.data.projects);
      let temp=res.data.projects;
      if(temp=="projects not found"){
        setnewRowData([]);
      }
      else{
        let arr=[];

        for(let i=0;i<temp.length;i++){ 
          let obj={
            id:temp[i].id,
            name:temp[i].name,
            state:temp[i].state,
            city:temp[i].city,
            pincode:temp[i].pin_code,
          }
        arr.push(obj);
    }
      setnewRowData(arr);
    }
    setloading(false);
  }

  console.log(newRowData);

  useEffect(()=>{
    
    setorgid(data);
    if(orgid!=="")fetchProjectdata();
   
  },[orgid])


  //fitering data starts:====
  const [filterselect, setfilterselect] = useState();
  const [filtervalue, setfiltervalue] = useState();

  const handleChange = (event) => {
    setfilterselect(event.target.value);
    setfiltervalue("");
  };

  console.log(filterselect);
  console.log(filtervalue);

  const fetchfilter= async()=>{
  
     try {
      
       const projecturl = Apiaddress+`/api/projects/orgprojectlist/`
        
        let orglist=[];
        let obj={"orgid":orgid};
        orglist.push(obj);
  
       const data= {
        orglist:orglist,
        filterkey:filterselect,
        filtervalue:filtervalue
       }

       const headers = {
        headers:{
          "Content-Type": "application/json"
        }
        };
       const res = await axios.post(
        projecturl,
        data
      );
      console.log(res.data);
      let temp=res.data.projects
    let arr=[];

    for(let i=0;i<temp.length;i++){ 
        let obj={
            id:temp[i].id,
            name:temp[i].name,
            state:temp[i].state,
            city:temp[i].city,
            pincode:temp[i].pin_code,
        }
        arr.push(obj);
    }
    setnewRowData(arr);

    } catch (err) {
      console.log(err);
    }
  }

  return (
    <>
      <Stack direction="row" sx={{width:"100%",height:"auto" }}>
      {/* 1st stack */}
      <Stack sx={{minHeight:"100vh",width:"20%",boxSizing:"border-box",}}> {/*for project directory 20% and for sales dashboard 225*/}
        <SuperAdminSidebar active={"Builder Directory"}/>
      </Stack>

     {/* 2nd stack */}
     <Stack direction="column" sx={{ backgroundColor:"#f9f9f9",width:"80%", padding:"25px",boxSizing:"border-box",}}  >  {/*  width for main sidebar 78px and for 80px*/}
      <SuperAdminNavbar 
      handleOpen={handleOpen}
       showAddProjectbtn={UserRole(project, "add_project")?true:false} 
       project={true}
       />  {/*send props for get popup to verify otp from builder */}
      
       <Stack direction="row" justifyContent="space-between" alignItems="center" sx={{}}>

          <Box sx={{}}>
            <Typography sx={{marginTop:"10px", fontSize:"16px", fontWeight:"600", marginLeft:"0px", marginBottom:"0px",color:"#2979F2",fontWeight: "400"}}>
              Here is your Projects Directory list Data 
            </Typography>
            <Typography sx={{marginTop:"20px", fontSize:"28px", fontWeight:"600", marginLeft:"0px"}}> 
              Project Directory   
            </Typography>
            <Typography sx={{marginTop:"10px", fontSize:"16px", fontWeight:"600", marginLeft:"0px", marginBottom:"50px",color: "#9299BC",fontWeight: "500"}}>
              Here is your Projects Directory list associated with Organisation 
            </Typography>
          </Box>

             <Accordion sx={{ width: "250px", boxShadow: "none",position:"absolute",right:"1%" }}>
                  <AccordionSummary
                    expandIcon={<GridExpandMoreIcon/>}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography sx={{ display: "flex", placeItems: "center" }}>
                      <TuneOutlinedIcon sx={{ color: "#2F4CDD" }} />
                      <Typography sx={{ marginLeft: "25px" }}>
                        Fillters
                      </Typography>
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Box sx={{textAlign:"center"}}>
                      <FormControl sx={{ width: "200px" }}>
                        <InputLabel id="demo-simple-select-label">
                          Select
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={filterselect}
                          label="Select"
                          onChange={handleChange}
                        >
                          <MenuItem value={"name"}>Name</MenuItem>
                          <MenuItem value={"state"}>State</MenuItem>
                          <MenuItem value={"city"}>City</MenuItem>
                          <MenuItem value={"pin_code"}>Pincode</MenuItem>
                          {/* <MenuItem value={"Name"}>Name</MenuItem> */}
                        </Select>
                      </FormControl>
                      <TextField
                        sx={{ marginTop: "20px",width:"92%" }}
                        placeholder="Enter the value" 
                        value={filtervalue} onChange={(e)=>{setfiltervalue(e.target.value)}}
                      />
                      <Button 
                        onClick={fetchfilter}
                        variant="contained"
                        color="primary" sx={{ height:"40px", background: "linear-gradient(90deg, #0F5DAE 0%, #009DCF 100.33%)",boxShadow: "0px 13.7981px 22.5788px rgba(50, 174, 137, 0.25)", textTransform: "none", borderRadius: "4px", width:"100px", fontSize:"15px",margin:"20px 0px 10px 0px"}}>
                         Submit
                      </Button>

                    </Box>
                  </AccordionDetails>
                </Accordion>

       </Stack>

     {/* actual content  */}
    <Stack sx={{backgroundColor:"#f9f9f9"}}>  {/* mt:"28px", */}

    {/* builder list table */}
    <Box sx={{height:"100vh",width:"100%"}}>
        <Box sx={{ color: "black", width: "100%" ,boxSizing:"border-box",display:"flex",flexDirection:"column"}}>

            <Stack direction="row" justifyContent="space-between" alignItems="center" sx={{width:"100%",backgroundColor:"white",borderRadius: "5px",margin:"0px 0px 10px 0px",padding:"10px 0px 10px 0px"}}>
              
                <Stack direction="row" justifyContent="center" alingItems="center" sx={{width:"14%",textAlign:"center",color: "#9299BC",fontWeight: "600"}}>
                        <Typography sx={{ display: "grid", placeItems: "center",fontWeight:"600",color:"black" }}>Name</Typography>
                        {/* <Box sx={{ display: "grid", marginLeft: "4px" }}>
                            <ArrowDropUpIcon fontSize="medium" sx={{ marginBottom: "-8px" }}/>
                            <ArrowDropDownIcon fontSize="medium" sx={{ marginTop: "-8px" }}/>
                        </Box> */}
                </Stack>

                 <Stack direction="row" justifyContent="center" alingItems="center" sx={{width:"14%",textAlign:"center",color: "#9299BC",fontWeight: "600"}}>
                        <Typography sx={{ display: "grid", placeItems: "center",fontWeight:"600",color:"black" }}>State</Typography>
                        {/* <Box sx={{ display: "grid", marginLeft: "4px" }}>
                            <ArrowDropUpIcon fontSize="medium" sx={{ marginBottom: "-8px" }}/>
                            <ArrowDropDownIcon fontSize="medium" sx={{ marginTop: "-8px" }}/>
                        </Box> */}
                </Stack>

                 <Stack direction="row" justifyContent="center" alingItems="center" sx={{width:"14%",textAlign:"center",color: "#9299BC",fontWeight: "600"}}>
                        <Typography sx={{ display: "grid", placeItems: "center",fontWeight:"600",color:"black" }}>City</Typography>
                        {/* <Box sx={{ display: "grid", marginLeft: "4px" }}>
                            <ArrowDropUpIcon fontSize="medium" sx={{ marginBottom: "-8px" }}/>
                            <ArrowDropDownIcon fontSize="medium" sx={{ marginTop: "-8px" }}/>
                        </Box> */}
                </Stack>

                 <Stack direction="row" justifyContent="center" alingItems="center" sx={{width:"14%",textAlign:"center",color: "#9299BC",fontWeight: "600"}}>
                        <Typography sx={{ display: "grid", placeItems: "center",fontWeight:"600",color:"black" }}>Pincode</Typography>
                        {/* <Box sx={{ display: "grid", marginLeft: "4px" }}>
                            <ArrowDropUpIcon fontSize="medium" sx={{ marginBottom: "-8px" }}/>
                            <ArrowDropDownIcon fontSize="medium" sx={{ marginTop: "-8px" }}/>
                        </Box> */}
                </Stack>

                 <Stack direction="row" justifyContent="center" alingItems="center" sx={{width:"14%",textAlign:"center",color: "#9299BC",fontWeight: "600"}}>
                        <Typography sx={{ display: "grid", placeItems: "center",fontWeight:"600",color:"black" }}>Detailed View</Typography>
                        {/* <Box sx={{ display: "grid", marginLeft: "4px" }}>
                            <ArrowDropUpIcon fontSize="medium" sx={{ marginBottom: "-8px" }}/>
                            <ArrowDropDownIcon fontSize="medium" sx={{ marginTop: "-8px" }}/>
                        </Box> */}
                </Stack>

            </Stack>
            
             {loading==true?
            <Box sx={{display:"flex",height:"80vh",padding:"10% 5% 5% 45%"}}>
                <CircularProgress color="inherit" sx={{ color: 'grey.500' }}/>
            </Box> 
             :
            <Box sx={{}}>
                {newRowData.length==0?
                    <Box sx={{textAlign:"center",marginTop:"50px"}}>
                        <Typography sx={{fontSize:"25px",fontWeight:"500",color:"#B9B7D0",}}>No Matches Found</Typography>
                    </Box>:
                    <Box>
                        {newRowData && newRowData?.map((item, index) => {
                            if (index >= pageRange[0] && index <= pageRange[1]) {
                                return (
                                    <Stack direction="row" justifyContent="space-between" alignItems="center" sx={{width:"100%",borderRadius: "5px",margin:"0px 0px 10px 0px",padding:"10px 0px 10px 0px",backgroundColor:"white",cursor:"pointer"}} 
                                    onClick={()=>{
                                      if(UserRole(unit, "view_unit")){
                                        setSelectedProjectId(item.id);
                                        handleOpen()
                                      }
                                      }}>

                                       <Stack direction="row" justifyContent="center" alingItems="center" sx={{width:"14%",textAlign:"center",color: "#9299BC",fontWeight: "600"}}>
                                        <Typography >
                                           {item?.name}
                                        </Typography>
                                       </Stack>
                                       <Stack direction="row" justifyContent="center" alingItems="center" sx={{width:"14%",textAlign:"center",color: "#9299BC",fontWeight: "600"}}>
                                        <Typography >
                                          {item?.state}
                                        </Typography>
                                       </Stack>
                                       <Stack direction="row" justifyContent="center" alingItems="center" sx={{width:"14%",textAlign:"center",color: "#9299BC",fontWeight: "600"}}>
                                        <Typography >
                                          {item?.city}
                                        </Typography>
                                       </Stack>
                                       <Stack direction="row" justifyContent="center" alingItems="center" sx={{width:"14%",textAlign:"center",color: "#9299BC",fontWeight: "600"}}>
                                        <Typography >
                                           {item?.pincode}
                                        </Typography>
                                       </Stack>

                                       <Stack direction="row" justifyContent="center" alingItems="center" spacing={1.5} sx={{width:"14%",textAlign:"center"}}>
                                          {/* <EditIcon sx={{color: "#9299BC",cursor:"pointer"}}/> */}
                                          {UserRole(unit,)}
                                          <RemoveRedEyeIcon sx={{color: "#9299BC",cursor:"pointer"}} 
                                          onClick={(e)=>{
                                            e.stopPropagation();
                                            setSelectedProjectId(item.id);
                                            handleOpen()
                                            }}/>  
                                          {/* <DeleteIcon sx={{color: "#9299BC"}}/> */}
                                        </Stack> 
                                    </Stack>  
                                );
                            }
                        })}

        <Box sx={{margin:"50px 0px 40px 0px"}}>
          <Box sx={{ display: "flex", justifyContent: "right" }}>
            {newRowData.length!=0 && 
            <Button variant="contained" sx={{background:"linear-gradient(90deg, #0F5DAE 0%, #009DCF 100.33%)"}}
              onClick={prvClick}>
                <ArrowBackIosIcon fontSize="small" />
                <Box sx={{ textTransform: "capitalize" }}>Previous</Box>
            </Button>}

            <Box sx={{background: "#E3E4EB",display: "flex",placeItems: "center",margin: " 0 10px",borderRadius: "5px"}}>
                <Box sx={{background: `white`,color: "black",width: "30px",borderRadius: "5px",margin: "0 10px",display: "grid",placeItems: "center"}}>{curpage}</Box>
                <Box sx={{color: "black",width: "30px",borderRadius: "5px",margin: "0 0px",display: "grid",placeItems: "center"}}>of</Box>
                <Box sx={{background: `white`,color: "black",width: "30px",borderRadius: "5px",margin: "0 10px",display: "grid",placeItems: "center"}}>{pages?.length}</Box>
            </Box>

            {newRowData.length!=0 && 
                <Button variant="contained" sx={{background:"linear-gradient(90deg, #0F5DAE 0%, #009DCF 100.33%)"}}
                    onClick={nextClick}>
                    <Box sx={{ textTransform: "capitalize" }}>Next</Box>
                    <ArrowForwardIosIcon fontSize="small" />
                </Button>
            }
          </Box>
        </Box>
      </Box>
    }
  </Box>
  }
  </Box>

  </Box>

  </Stack>
  </Stack>
</Stack>

{/* verify builder otp */}
    <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
        <Box sx={{
             position: 'absolute',
             top: '50%',
             left: '50%',
             transform: 'translate(-50%, -50%)',
             width: 400,
             bgcolor: 'white',
             border: '2px solid white',
             borderRadius:"10px",
             boxShadow: 24,
             pt: 2,
             px: 4,
             pb: 3,
              width: 400 
              }}>
                  <Typography sx={{fontSize:"18px", fontWeight:"500"}}>Email</Typography>
                   <Typography sx={{fontSize:"14px", fontWeight:"400", color:"gray"}}>An OTP will be send to this e-mail</Typography>
                <Stack direction="row" justifyContent="space-between" alignItems="center">
                  <TextField
                  type="email"
                  value={email}
                  onChange={(e)=>{
                    setEmail(e.target.value)
                  }}
                  />
                  <Button className="univercel_btn_class" sx={{textTransform:"none"}} 
                  onClick={getOtp}
                  >Get OTP</Button>
                </Stack>
                <Typography sx={{fontSize:"18px", fontWeight:"500", mt:"10px"}}>Enter OTP</Typography>
                <OTPInput inputStyles={{border:"1px solid gray",borderRadius:"8px",boxShadow: "0px 2px 6px rgba(19, 18, 66, 0.07)", width:"50px",height:"50px",margin:"2px"}} value={EmailOTP} onChange={setEmailOTP} OTPLength={6} otpType="number" disabled={false}  />
                {error && <Typography sx={{fontSize:"14px", fontWeight:"500", mt:"10px",color:"red"}}>Please enter valid OTP</Typography>}
                <Button className="univercel_btn_class" 
                sx={{textTransform:"none", mt:"30px", padding:"10px 30px 10px 30px"}}
                onClick={()=>{
                    // navigate("/super-admin-create-project-profile")
                  vrifyOTP();
                }}
                >Submit</Button>
        </Box>
    </Modal>
    </>
  )
}

export default SuperAdminProject