import { Box, Button, Container, Stack, Typography } from "@mui/material";

import HomeBuyersImg from "../../Imgs/home-buyers-img.png";

const BuyerLandingForHomeBuyers = () => {
  return (
    <Box
      sx={{
        backgroundColor: "#0085FF",
        py: { xs: "30px", md: "0px" },
        overflow: "hidden",
      }}
    >
      <Container>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            flexDirection: { xs: "column-reverse", md: "row" },
            gap: { xs: "30px", md: "50px" },
          }}
        >
          <Box
            data-aos="fade-right"
            sx={{
              width: { xs: "100%", md: "55%" },
              textAlign: { xs: "center", md: "left" },
              py: { md: "50px" },
            }}
          >
            <Stack spacing={1.5}>
              {/* <Box>
                <Typography
                  sx={{
                    fontSize: { xs: "32px", md: "40px" },
                    fontWeight: 700,
                    fontFamily: "Circular Std",
                    color: "#FFF",
                    textTransform: "capitalize",
                  }}
                >
                  for home buyers
                </Typography>
                <Typography
                  sx={{
                    fontSize: "24px",
                    fontWeight: 700,
                    fontFamily: "Product Sans",
                    color: "#FFF",
                    textTransform: "capitalize",
                  }}
                >
                  Envision Next is free to use
                </Typography>
              </Box> */}
              <Box>
                <Typography
                  sx={{
                    fontSize: { xs: "28px", md: "36px" },
                    fontWeight: 700,
                    fontFamily: "Circular Std",
                    color: "#FFF",
                    textTransform: "capitalize",
                  }}
                >
                  For Home Buyers, <br /> EnvisionNext is completely free
                </Typography>
              </Box>
              <Typography
                sx={{
                  fontSize: "18px",
                  fontWeight: 400,
                  fontFamily: "Product Sans",
                  color: "#FFF",
                }}
              >
                If your Builder is a part of EnvisionNext, they’ll share access
                to your EnvisionNext account – the home for your purchase
                details, documents, and everything in between.
              </Typography>
              <Typography
                sx={{
                  fontSize: "16px",
                  fontWeight: 700,
                  fontFamily: "Product Sans",
                  color: "#FFCB75",
                }}
              >
                Ask your Builder to onboard our platform today!
              </Typography>
              <Box>
                <Button
                  sx={{
                    background:
                      "linear-gradient(97.85deg, #FFAF2A 0.95%, #FFC666 100%)",
                    fontWeight: 500,
                    fontSize: { lg: "28px", xs: "16px" },
                    boxShadow: "rgba(242, 178, 73, 0.59) 0px 9px 12px",
                    borderRadius: "10px",
                    color: "#06386B",
                    textTransform: "none",
                    padding: "10px 30px",
                    fontFamily: "Product Sans",
                    mt: "20px",
                    "&:hover": {
                      background:
                        "linear-gradient(97.85deg, #FFAF2A 0.95%, #FFC666 100%)",
                      boxShadow: "rgba(242, 178, 73, 0.59) 10px 12px 16px",
                    },
                  }}
                >
                  Refer Your Builder
                </Button>
              </Box>
            </Stack>
          </Box>
          <Box
            data-aos="fade-up"
            sx={{ width: { xs: "60%", md: "45%" }, mb: { md: "-5%" } }}
          >
            <img style={{ width: "129%" }} src={HomeBuyersImg} alt="" />
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

export default BuyerLandingForHomeBuyers;
