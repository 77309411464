import { Box, Container, Stack, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import {
  HeaderIcon,
  HeaderIconSmall,
} from "../../BuilderComponents/BuilderDasboard/assets/icons";

const CommonPagesRoute = () => {
  const navigate = useNavigate();
  return (
    <Box sx={{ overflowX: "hidden" }}>
      <Box
        sx={{
          backgroundColor: "#0085FF",
          display: { xs: "none", md2: "block" },
        }}
      >
        <Container>
          <Box sx={{ display: "flex" }}>
            <Box
              onClick={() => navigate("builder-landing-page")}
              sx={{
                width: "33.33%",
                cursor: "pointer",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                gap: "10px",
                py: { xs: "10px", md: "20px" },
                px: { xs: "5px", md: "10px" },
                borderRight: "8px solid #FFF",
              }}
            >
              <Typography
                sx={{
                  color: "#FFF",
                  fontSize: "28px",
                  fontFamily: "Circular Std",
                }}
              >
                For Builders
              </Typography>
            </Box>
            <Box
              onClick={() => navigate("channel-landing-page")}
              sx={{
                width: "33.33%",
                cursor: "pointer",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                gap: "10px",
                py: { xs: "10px", md: "20px" },
                px: { xs: "5px", md: "10px" },
                borderRight: "8px solid #FFF",
              }}
            >
              <Typography
                sx={{
                  color: "#FFF",
                  fontSize: "28px",
                  fontFamily: "Circular Std",
                }}
              >
                For Channel Partners
              </Typography>
            </Box>
            <Box
              onClick={() => navigate("buyer-landing-page")}
              sx={{
                width: "33.33%",
                cursor: "pointer",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                gap: { xs: "5px", md: "10px" },
                py: { xs: "10px", md: "20px" },
                px: { xs: "5px", md: "10px" },
              }}
            >
              <Typography
                sx={{
                  color: "#FFF",
                  fontSize: "28px",
                  fontFamily: "Circular Std",
                }}
              >
                For Home Buyers
              </Typography>
            </Box>
          </Box>
        </Container>
      </Box>
      <Box
        sx={{ backgroundColor: "#FFF", display: { xs: "block", md2: "none" } }}
      >
        <Container>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              gap: "20px",
            }}
          >
            <Box
              onClick={() => navigate("builder-landing-page")}
              sx={{
                backgroundColor: "#0085FF",
                width: "90%",
                cursor: "pointer",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                gap: "10px",
                py: { xs: "10px", md: "20px" },
                px: { xs: "5px", md: "10px" },
                borderRight: "8px solid #FFF",
              }}
            >
              <Typography
                sx={{
                  color: "#FFF",
                  fontSize: "28px",
                  fontFamily: "Circular Std",
                }}
              >
                Builder
              </Typography>
            </Box>
            <Box
              onClick={() => navigate("channel-landing-page")}
              sx={{
                backgroundColor: "#0085FF",
                width: "90%",
                cursor: "pointer",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                gap: "10px",
                py: { xs: "10px", md: "20px" },
                px: { xs: "5px", md: "10px" },
                borderRight: "8px solid #FFF",
              }}
            >
              <Typography
                sx={{
                  color: "#FFF",
                  fontSize: "28px",
                  fontFamily: "Circular Std",
                }}
              >
                Channel Partner
              </Typography>
            </Box>
            <Box
              onClick={() => navigate("buyer-landing-page")}
              sx={{
                backgroundColor: "#0085FF",
                width: "90%",
                cursor: "pointer",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                gap: { xs: "10px" },
                py: { xs: "10px", md: "20px" },
                px: { xs: "5px", md: "10px" },
              }}
            >
              <Typography
                sx={{
                  color: "#FFF",
                  fontSize: "28px",
                  fontFamily: "Circular Std",
                }}
              >
                Home Buyer
              </Typography>
            </Box>
          </Box>
        </Container>
      </Box>
    </Box>
  );
};

export default CommonPagesRoute;
