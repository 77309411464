import { Box, Button, Stack, Typography } from '@mui/material'
import React, { useEffect, useState } from "react";
import { useLocation } from 'react-router-dom';
import { NavbarForDirectory } from '../../BuilderComponents/ProjectDashboard/DashboardComponents/NavbarForDirectory'
import { ProjectDashboardSidebar } from '../../BuilderComponents/ProjectDashboard/DashboardComponents/ProjectDashboardSidebar'
import AppointmentProjectList from './AppointmentProjectList';
import AssociatedProjectLead from './AssociatedProjectLead';
import AssociateProjectUnits from './AssociatedProjectUnit';
import CommissionProjectList from './CommissionProjectList';

function ChannelPartnerView() {

  const [curUserFromRoute, setcurUserFromRoute] =useState(null);
  const [curPage, setCurPage] = useState(1);

  const location = useLocation();

  useEffect(() => {
    console.log(location.state);
    setcurUserFromRoute(location.state);
  },[location]);

  return (
    <>
      <Stack direction="row" sx={{width:"100%",height:"auto" }}>
        {/* 1st stack */}
        <Stack sx={{minHeight:"100vh",width:"22%",boxSizing:"border-box"}}>
            <ProjectDashboardSidebar active={"Channel Partnter Directory"}/>
        </Stack>
        {/* 2nd stack */}
        <Stack direction="column" sx={{ backgroundColor:"#f9f9f9",width:"78%", padding:"25px",boxSizing:"border-box"}}>
          {/* <Navbar/> */}
          <NavbarForDirectory
            leftRightTitle2={"Channel Partner"}
            leftTitle={"Project Dashboard"}
            // showLeftTitle={false}
            righshow={true}
            longOrangBgr={true}
          />
          {/* actual content  */}
          <Stack sx={{ mt:"10px",backgroundColor:"#f9f9f9"}}>
            <Typography sx={{color: "#2979F2",fontSize: "12px",fontWeight: "400"}}>
              Here is your Channel Partner Directory list data
            </Typography>
            <Stack direction="row" justifyContent="space-between" alignItems="center" sx={{}}>
              <Stack direction="row" alignItems="center" spacing={2} sx={{margin:"30px 0px"}}>
                <Typography sx={{color: "#B9B7D0",fontSize: "12px",fontWeight: "400",fontFamily: 'Product Sans'}}>
                  Showing Data of
                </Typography>
                <Typography sx={{fontSize: "22px",fontWeight: "600",color: "#2979F2",fontFamily: 'Product Sans',lineHeight:"20px"}}>
                  {curUserFromRoute && curUserFromRoute.Name}
                </Typography>
              </Stack>
            </Stack>
            
            <Box sx={{ width: "100%", display: "grid",background:"#FFFFFF"}}>
              <Box sx={{width: "90%",margin: "70px auto",color: "#A2A3A6",display: "flex"}}>
                <Box sx={{ width: "70%", marginRight: "30px"}}>
                  <Box sx={{ marginLeft:"10px",fontFamily:'Poppins',fontStyle:"normal",fontWeight:"500",fontSize:"18px",lineHeight:"27px"}}>Name</Box>
                  <Box
                    sx={{
                      marginTop: "10px",
                      width: "15vw",
                      height: "6vh",
                      display: "grid",
                      placeItems: "center",
                      borderRadius: "5px",
                      background: "#e7e7e7",
                      color: "#A2A3A6",
                      fontSize: "20px",
                      }}>
                     <Typography>{curUserFromRoute && curUserFromRoute.Name}</Typography>
                  </Box>
                </Box>
                <Box sx={{ width: "70%", marginRight: "30px" }}>
                  <Box sx={{ marginLeft:"10px",fontFamily:'Poppins',fontStyle:"normal",fontWeight:"500",fontSize:"18px",lineHeight:"27px"}}>Phone no</Box>
                  <Box
                    sx={{
                      marginTop: "10px",
                      background: "#e7e7e7",
                      width: "15vw",
                      height: "6vh",
                      display: "grid",
                      placeItems: "center",
                      borderRadius: "5px",
                      color: "#A2A3A6",
                      fontSize: "20px",
                    }}>
                    <Typography>{curUserFromRoute && curUserFromRoute.Phone}</Typography>  
                  </Box>
                </Box>
                <Box sx={{ width: "70%", marginRight: "30px" }}>
                  <Box sx={{ marginLeft:"10px",fontFamily:'Poppins',fontStyle:"normal",fontWeight:"500",fontSize:"18px",lineHeight:"27px"}}>E-mail</Box>
                  <Box
                    sx={{
                      marginTop: "10px",
                      background: "#e7e7e7",
                      width: "15vw",
                      height: "6vh",
                      display: "grid",
                      placeItems: "center",
                      borderRadius: "5px",
                      color: "#A2A3A6",
                      fontSize: "20px",
                      padding: "0 10px",
                    }}>
                    <Typography>{curUserFromRoute && curUserFromRoute.Email}</Typography> 
                  </Box>
                </Box>
                <Box sx={{ width: "70%" }}>
                  <Box sx={{ marginLeft:"10px",fontFamily:'Poppins',fontStyle:"normal",fontWeight:"500",fontSize:"18px",lineHeight:"27px"}}>RERA No</Box>{" "}
                  <Box
                    sx={{
                      marginTop: "10px",
                      background: "#e7e7e7",
                      width: "15vw",
                      height: "6vh",
                      display: "grid",
                      placeItems: "center",
                      borderRadius: "5px",
                      color: "#A2A3A6",
                      fontSize: "20px",
                    }}>
                    <Typography>{curUserFromRoute && curUserFromRoute.RERARegNo}</Typography>  
                  </Box>
                </Box>
              </Box>
              
              <Box sx={{ width: "90%", margin: "0px auto" }}>
              <Stack direction="row" justifyContent="space-between" alignItems="center">
                <Box
                  onClick={() => setCurPage(1)}
                  sx={{
                    background: curPage==1?"white":"#e7e7e7",
                    display: "grid",
                    justifyContent: "center",
                    alignItems:"center",
                    width: "70%",
                    height:"49px",
                    borderRadius:"8px 8px 0px 0px",
                    display:'flex',
                    cursor:"pointer",
                    border: curPage==1?"1px solid #2979F2":"1px solid #e7e7e7"
                  }}
                  variant="text"
                >
                <Typography sx={{fontFamily:'Product Sans',lineHeight:"20px",fontWeight:"400",fontSize:"16px",color:"#000000"}}>Associated units</Typography>
                </Box>
                <Box
                  onClick={() => setCurPage(2)}
                  sx={{  
                     background: curPage==2?"white":"#e7e7e7",
                    display: "grid",
                    justifyContent: "center",
                    alignItems:"center",
                    width: "70%",
                    height:"49px",
                    borderRadius:"8px 8px 0px 0px",
                    display:'flex',
                    cursor:"pointer",
                    border: curPage==2?"1px solid #2979F2":"1px solid #e7e7e7"
                  }}
                  variant="text"
                >
                <Typography sx={{fontFamily:'Product Sans',lineHeight:"20px",fontWeight:"400",fontSize:"16px",color:"#000000"}}>Associated leads</Typography>  
                </Box>
                <Box
                  onClick={() => setCurPage(3)}
                  sx={{ 
                     background: curPage==3?"white":"#e7e7e7",
                    display: "grid",
                    justifyContent: "center",
                    alignItems:"center",
                    width: "70%",
                    height:"49px",
                    borderRadius:"8px 8px 0px 0px",
                    display:'flex',
                    cursor:"pointer",
                    border: curPage==3?"1px solid #2979F2":"1px solid #e7e7e7"
                   }}
                  variant="text"
                >
                <Typography sx={{fontFamily:'Product Sans',lineHeight:"20px",fontWeight:"400",fontSize:"16px",color:"#000000"}}>Appointment list</Typography>  
                </Box>
                <Box
                  onClick={() => setCurPage(4)}
                  sx={{ 
                     background: curPage==4?"white":"#e7e7e7",
                    display: "grid",
                    justifyContent: "center",
                    alignItems:"center",
                    width: "70%",
                    height:"49px",
                    borderRadius:"8px 8px 0px 0px",
                    display:'flex',
                    cursor:"pointer",
                    border: curPage==4?"1px solid #2979F2":"1px solid #e7e7e7"
                   }}
                  variant="text"
                >
                <Typography sx={{fontFamily:'Product Sans',lineHeight:"20px",fontWeight:"400",fontSize:"16px",color:"#000000"}}>Commission list</Typography>   
                </Box>
              </Stack>
              </Box>

              {curUserFromRoute && (
              <Box sx={{ color: "black", width: "90%", margin: "auto",border:"1px solid #2979F2",height:"530px",marginBottom:"70px"}}>
                {curPage === 1 && (
                  <AssociateProjectUnits cpid={curUserFromRoute.CPid} /> 
                )}
                {curPage === 2 && (
                  <AssociatedProjectLead cpid={curUserFromRoute.CPid} />
                )}
                {curPage === 3 && (
                  <AppointmentProjectList cpid={curUserFromRoute.CPid} />
                )}
                {curPage === 4 && (
                  <CommissionProjectList cpid={curUserFromRoute.CPid} />
                )}
              </Box>
            )}

            </Box>

        </Stack>
        </Stack>
      </Stack>
    </>
  )
}

export default ChannelPartnerView