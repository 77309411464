import { Box, Button, TextField, Typography } from "@mui/material"
import { Stack } from "@mui/system"
import DoneIcon from '@mui/icons-material/Done';
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";


import Modal from '@mui/material/Modal';
import axios from "axios";
import { SAProjectSidebar } from "../SidebarNavbar/SAProjectSidebar"
import { SuperAdminNavbar } from "../SidebarNavbar/SuperAdminNavbar"
import { Apiaddress } from "../../../utils/consts";
import { UserRole } from "../../../UserRoles/UserRoles";
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #fff',
    boxShadow: 24,
    borderRadius:"20px",
    pt: 2,
    px: 4,
    pb: 3,
  };

export const SAMediaUpload = ()=>{
  let roles = JSON.parse(localStorage.getItem("user_roles"))
  let project = roles["project"];
  let unit = roles["unit"];
  let media = roles["project media"];
  let builder = roles["builder"];
  let teams = roles["teams"];


     //get data from local Storage selected builder id; 
     let data = JSON.parse(localStorage.getItem("SABuilderDetails"));
     console.log("projectId =>.>",data.builder_Details.id);
     let builId = data.builder_Details.id

    let mediaName = useParams().id;
    const [update, setUpdate] = useState(false)
  const navigate = useNavigate();

  ////for modal 
  const [open, setOpen] = useState(false);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    setimg("");
    setText("")
  };


  const [listmedia, setlistmedia] = useState([]); ///"Videos","Walkthrough",

  const [createdMedia, setCreatedMedia] = useState([]);

  const [selected, setSelected] = useState(listmedia[0])
  
  let builderData = JSON.parse(window.localStorage.getItem('crm_user'));
  
  console.log("local data:===>", builderData)
  useEffect(()=>{
      //get already present data through api 
       let url = Apiaddress+"/api/projects/mediaofbuilder/?builder_id="+builId

       axios.get(url)
       .then((res)=>{
           let data = res.data.media
        let arr = [];
        for(let i=0; i<data.length; i++){
            // console.log(data[i].media_type)
            if(mediaName==data[i].media_type)
            arr.push(data[i])
        }

        setlistmedia(arr)

       }).catch((err)=>{
        console.log(err)
       })

  },[update])

// // for upload media 
const [img,setimg] = useState("");

const imgHandler = (e)=>{
  setimg(e.target.files[0]);
  setText(e.target.files[0].name);
}

let [text, setText] = useState("")


  const onSubmitMedia = ()=>{

    if(img=="" || text=="")return

    let url = Apiaddress+"/api/projects/projectmedia/";
    let type = img?.type?.split("/")[0]
    let data;

    console.log("hhhhhhhhhhhhhhhh", img)
    const headers ={ 
        headers: {
        'content-type': 'multipart/form-data',
      }
    }
    
    if(type=="image"){
         data = {
               tag: text,
               is_file: false,
               image: img,
               media_type: mediaName,
               builder_id: builId,
               istrue:"false"
        }
    }
    else if(type=="application"){
         data = {
               tag: text,
               is_file: true,
               uploaded_file: img,
               media_type: mediaName,
               builder_id: builId,
               istrue:"false"
        }

    }
    console.log(data)
    axios.post(url, data, headers)
    .then((res)=>{
        console.log("post media", res.data);
        setUpdate(!update);
        alert("media added")
    }).catch((err)=>{
        console.log(err);
    }).finally(()=>{
        handleClose()
    })
  }

  ///////// delete media images from database;
  const deleteMedia = (id)=>{
    console.log(id);
     let url = Apiaddress+"/api/projects/projectmedia/";
       let data ={
        data:{
            media_id:id
        }
    }
    axios.delete(url,data).then((res)=>{
        console.log(res.data);
        setUpdate(!update);
     }).catch((err)=>{
        console.log(err)
     })
  }

    return(
        <>
               <Stack direction="row" sx={{width:"100%",height:"auto" }}>
      {/* 1st stack */}
      <Stack sx={{minHeight:"100vh",width:"22%",boxSizing:"border-box",}}> {/*for project directory 20% and for sales dashboard 22%*/}
        <SAProjectSidebar active={"Media Library"}/>
      </Stack>

     {/* 2nd stack */}
     <Stack direction="column" sx={{ backgroundColor:"#f9f9f9",width:"78%", padding:"25px",boxSizing:"border-box",}}  >  {/*  width for main sidebar 78px and for 80px*/}
      <SuperAdminNavbar media={true} />  {/*send props for get popup to verify otp from builder */}
      

    {/* actual content  */}
    <Stack sx={{backgroundColor:"#f9f9f9",mt:"28px"}}>  {/* mt:"28px", */}

    <Stack sx={{width:"100%",padding:"20px",bgcolor:"#fff",}} >
              <Typography sx={{fontSize:"28px", fontWeight:"600"}}>Media View</Typography>
                 <Stack direction="row" justifyContent="start" alignItems="center">
                  <Typography sx={{fontSize:"20px", fontWeight:"600", mt:"30px",mb:"20px"}}>{mediaName}</Typography>
                  </Stack>
                  <Stack>
                    <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{padding:"20px"}}>
                      <Typography sx={{fontSize:"18px", fontWeight:"500", color:"#A2A3A6"}}>Select Category</Typography>
                      {UserRole(media, "add_projectmedia") && 
                       <Button onClick={handleOpen}
                      size="large" className="univercel_btn_class" sx={{textTransform:"none", color:"#fff"}} 
                       >Add {mediaName}
                       </Button>
                      }
                    </Stack>
                      
                      <Box sx={{ marginTop:"40px",display:"flex", justifyContent:"start", flexWrap:"wrap", gap:"30px"}}>
                        {listmedia.map((ele,indx)=>{
                            let imgurl = ele.uploaded_file!==null?ele.uploaded_file:ele.image;
                            return (
                                <>
                                <Stack direction="column" 
                                >  
                            <Stack  direction="row" alignItems="center" justifyContent="center" 
                            sx={{borderRadius:"20px",cursor:"pointer", width:"300px", height:"300px",padding:"5px", bgcolor:"#D9D9D9" }}>
                               <img 
                                onClick={()=>{
                                  window.open(Apiaddress+imgurl,'_blank');
                              }}
                               src={Apiaddress+imgurl} alt="" style={{width:'100%', height:"100%", borderRadius:"20px"}} />
                            </Stack>
                            <Stack direction="row" justifyContent="space-around" sx={{mt:"10px"}}>
                               <Typography>{ele.tag}</Typography>
                               <svg 
                               onClick={()=>{
                                deleteMedia(ele.id)
                               }}
                               width="16" height="18" viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                             <path d="M3 18C2.45 18 1.97933 17.8043 1.588 17.413C1.196 17.021 1 16.55 1 16V3H0V1H5V0H11V1H16V3H15V16C15 16.55 14.8043 17.021 14.413 17.413C14.021 17.8043 13.55 18 13 18H3ZM13 3H3V16H13V3ZM5 14H7V5H5V14ZM9 14H11V5H9V14Z" fill="#EF3649"/>
                              </svg>

                            </Stack>
                                </Stack>
                                </>
                            )
                        })}
                           
                      </Box>
                     {!listmedia[0] && <Stack direction="column" justifyContent="center" alignItems="center">
                                 <h3>No Media found !</h3>
                      </Stack>}
                  </Stack>
                  <Stack direction="row" justifyContent="right" alignItems="center" sx={{padding:"20px"}}>
                  {/* <Button 
                         onClick={()=>{
                          
                         }}
                       size="large" className="univercel_btn_class" sx={{textTransform:"none", color:"#fff"}}  >Next
                       
                       </Button> */}
                  </Stack>
             </Stack>

             {/* modal */}
             <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
        <Box sx={{ ...style, width: 300, height:200, }}>
    
       <Stack sx={{display:"flex", justifyContent:"center", alignItems:"center", mb:"20px"}}>
        <Button
       className="univercel_btn_class"
            variant="contained"
            sx={{padding:"9px 26px 9px 26px",}}
            component="label"
             startIcon={
              <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M2 16C1.45 16 0.979333 15.8043 0.588 15.413C0.196 15.021 0 14.55 0 14V11H2V14H14V11H16V14C16 14.55 15.8043 15.021 15.413 15.413C15.021 15.8043 14.55 16 14 16H2ZM7 12V3.85L4.4 6.45L3 5L8 0L13 5L11.6 6.45L9 3.85V12H7Z" fill="white"/>
              </svg>
          }
          >
             Upload
            <input
            onChange={imgHandler}
              type="file"
              hidden
            />
      </Button>
       </Stack>
       <Stack sx={{display:"flex", justifyContent:"center", alignItems:"center"}}>
       <TextField
       value={text}
       onChange={(e)=>{
        setText(e.target.value)
       }} 
        placeholder="Add Name "
       />
       </Stack>
       <Stack sx={{display:"flex", justifyContent:"center", alignItems:"center", mt:"20px"}}>
        <Button
        onClick={onSubmitMedia}
       className="univercel_btn_class"
            variant="contained"
            sx={{padding:"9px 26px 9px 26px",}}
            component="label"
          //   startIcon={
          //     <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          //     <path d="M2 16C1.45 16 0.979333 15.8043 0.588 15.413C0.196 15.021 0 14.55 0 14V11H2V14H14V11H16V14C16 14.55 15.8043 15.021 15.413 15.413C15.021 15.8043 14.55 16 14 16H2ZM7 12V3.85L4.4 6.45L3 5L8 0L13 5L11.6 6.45L9 3.85V12H7Z" fill="white"/>
          //     </svg>
          // }
          >
             Save
      </Button>
       </Stack>
        </Box>
      </Modal>

    </Stack>
      </Stack>
       </Stack>
        </>
    )
}