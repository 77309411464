import {
  Box,
  Checkbox,
  Container,
  Divider,
  FormControl,
  FormControlLabel,
  Stack,
  styled,
  Typography, 
  useRadioGroup,
} from "@mui/material";

import { flexBetween, itemsCenter } from "./styles/commonStyles";
import classes from "./styles/PageOne.module.css";

const PdfTwoPageOneUnit = ({quotationInfo,projectInfo,unitInfo,leadInfo}) => {
  return (
    <Box>
      <Typography sx={{ fontSize: "14px" }}>
        Quotation for {`(${leadInfo?.lead_name})`} of {`(${projectInfo?.project_name})`} Dated{" "}
        {`(${quotationInfo?.created?.substring(0, 10)})`}
      </Typography>
      
      <Typography sx={{ fontSize: "14px", my: "20px" }}>
        <span
          style={{
            borderBottom: "2px solid #000",
            display: "inline",
            fontSize: "16px",
            fontWeight: 400,
          }}
        >
          UNIT DETAILS :
        </span>{" "}
        <span
          style={{
            fontWeight: 400,
          }} 
        >
        </span>
      </Typography>

      <Stack spacing={0.5}>
        <Typography sx={{marginBottom:"10px"}}>{`Unit Number: ${unitInfo?.unit_no}`}</Typography>
        <Stack direction="row" sx={{width:"100%",display:"flex",flexWrap:"wrap"}}>
        {
          unitInfo?.single_data?.map((ele)=>{
            return(
              <Typography sx={{margin:"0px 40px 10px 0px"}}>{`${ele?.label} : ${ele?.value} ${ele?.type}` }</Typography>
            )
          })
        }
        </Stack>
        <Stack direction="row" sx={{width:"100%",display:"flex",flexWrap:"wrap",paddingTop:"5px"}}>
         {
          unitInfo?.double_data?.map((ele)=>{
            return(
              <Typography sx={{margin:"0px 40px 10px 0px"}}>{`${ele?.label} : ${ele?.value1} ${ele?.type}  X  ${ele?.value2} ${ele?.type}`}</Typography>
            )
          })
        }
        </Stack>
        <Typography sx={{}}>
            <span style={{borderBottom: "1.5px solid #000",display: "inline",fontSize: "16px",fontWeight: 400,}}>Bedrooms :</span>{" "}
            <span style={{fontWeight: 400}}></span>
        </Typography>
        <Stack direction="row" sx={{width:"100%",display:"flex",flexWrap:"wrap"}}>
         {
          unitInfo?.bedroom?.map((ele)=>{
            return(
              <Typography sx={{margin:"0px 40px 10px 0px"}}>{`Bedroom ${ele?.bedroom_no} : ${ele?.length}  X  ${ele?.breadth}`}</Typography>
            )
          })
        }
        </Stack>
        <Typography sx={{}}>
            <span style={{borderBottom: "1.5px solid #000",display: "inline",fontSize: "16px",fontWeight: 400,}}>Bathrooms :</span>{" "}
            <span style={{fontWeight: 400}}></span>
        </Typography>
        <Stack direction="row" sx={{width:"100%",display:"flex",flexWrap:"wrap"}}>
         {
          unitInfo?.bathroom?.map((ele)=>{
            return(
               <Typography sx={{margin:"0px 40px 10px 0px"}}>{`Bathroom ${ele?.bathroom_no} : ${ele?.length}  X  ${ele?.breadth}`}</Typography>
            )
          })
        }
        </Stack>
        <Typography sx={{}}>
            <span style={{borderBottom: "1.5px solid #000",display: "inline",fontSize: "16px",fontWeight: 400,}}>Balcony :</span>{" "}
            <span style={{fontWeight: 400}}></span>
        </Typography>
        <Stack direction="row" sx={{width:"100%",display:"flex",flexWrap:"wrap"}}>
         {
          unitInfo?.balcony?.map((ele)=>{
            return(
              <Typography sx={{margin:"0px 40px 10px 0px"}}>{`Balcony ${ele?.balcony_no} : ${ele?.length}  X  ${ele?.breadth}`}</Typography>
            )
          })
        }
        </Stack>
      </Stack>
    </Box>
  );
};

export default PdfTwoPageOneUnit;
