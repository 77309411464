import { Box, Container, Stack, Typography } from "@mui/material";

import WhatsAppIntegration from "../../Imgs/whats-app-integration.png";
import AiFilterForFakeAccounts from "../../Imgs/ai-filter-for-fake-accounts.png";
import InAppCommissionPayment from "../../Imgs/in-app-commission-payment.png";
import DigitizedRequirementForm from "../../Imgs/digitized-requirement-form.png";
import ProjectInventoryDataManagement from "../../Imgs/project-inventory-data-management.png";

const ChannelLandingFuelYourRealEstateBusiness = () => {
  return (
    <Box sx={{ backgroundColor: "#FFF", overflowX: "hidden" }}>
      <Container>
        <Typography
          data-aos="fade-down"
          sx={{
            color: "#272D4E",
            fontSize: { xs: "28px", md: "36px", lg: "40px" },
            fontWeight: 700,
            fontFamily: "Circular Std",
            textAlign: "center",
            lineHeight: "1.2",
            display: { xs: "none", md2: "block" },
          }}
        >
          Fuel your Real Estate business with empowering <br /> features and an
          intuitive interface
        </Typography>
        <Typography
          data-aos="fade-down"
          sx={{
            color: "#272D4E",
            fontSize: { xs: "28px", md: "36px", lg: "40px" },
            fontWeight: 700,
            fontFamily: "Circular Std",
            textAlign: "center",
            lineHeight: "1.2",
            display: { xs: "none", md: "block", md2: "none" },
          }}
        >
          Fuel your Real Estate business with empowering features and <br /> an
          intuitive interface
        </Typography>
        <Typography
          data-aos="fade-down"
          sx={{
            color: "#272D4E",
            fontSize: { xs: "28px", md: "36px", lg: "40px" },
            fontWeight: 700,
            fontFamily: "Circular Std",
            textAlign: "center",
            lineHeight: "1.2",
            display: { xs: "block", md: "none" },
          }}
        >
          Fuel your Real Estate business with empowering features and an
          intuitive interface
        </Typography>
        <Typography
          sx={{
            color: "#343434",
            fontSize: "20px",
            fontFamily: "Product Sans",
            textAlign: "center",
            textTransform: "capitalize",
          }}
        >
          Manage inventory, leads, commissions, and more from one single place.
        </Typography>
        <Box sx={{ mt: "60px" }}>
          <Stack spacing={{ xs: 4, md: 8 }}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: { xs: "20px", md: "50px" },
                flexDirection: { xs: "column-reverse", md: "row" },
              }}
            >
              <Box
                data-aos="fade-right"
                sx={{
                  width: { xs: "100%", md: "50%" },
                  textAlign: { xs: "center", md: "left" },
                }}
              >
                <Typography
                  sx={{
                    color: "#272D4E",
                    fontSize: { xs: "24px", lg: "32px" },
                    fontWeight: 700,
                    fontFamily: "Circular Std",
                  }}
                >
                  AI Filter for Fake Accounts
                </Typography>
                <Typography
                  sx={{
                    color: "#343434",
                    fontSize: "18px",
                    fontFamily: "Product Sans",
                    mt: "5px",
                    display: { xs: "none", md2: "block" },
                  }}
                >
                  EnvisionNext is the first and only platform for Channel <br />
                  Partners that uses AI to filter out fake accounts.
                </Typography>
                <Typography
                  sx={{
                    color: "#343434",
                    fontSize: "18px",
                    fontFamily: "Product Sans",
                    mt: "5px",
                    display: { xs: "block", md2: "none" },
                  }}
                >
                  EnvisionNext is the first and only platform for Channel
                  Partners that uses AI to filter out fake accounts.
                </Typography>
              </Box>
              <Box
                data-aos="fade-left"
                sx={{ width: { xs: "100%", md: "50%" }, textAlign: "center" }}
              >
                <img
                  style={{ width: "70%" }}
                  src={AiFilterForFakeAccounts}
                  alt=""
                />
              </Box>
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: { xs: "20px", md: "50px" },
                flexDirection: { xs: "column", md: "row" },
              }}
            >
              <Box
                data-aos="fade-right"
                sx={{ width: { xs: "100%", md: "50%" }, textAlign: "center" }}
              >
                <img
                  style={{ width: "70%" }}
                  src={WhatsAppIntegration}
                  alt=""
                />
              </Box>
              <Box
                data-aos="fade-left"
                sx={{
                  width: { xs: "100%", md: "50%" },
                  textAlign: { xs: "center", md: "left" },
                }}
              >
                <Typography
                  sx={{
                    color: "#272D4E",
                    fontSize: { xs: "24px", lg: "32px" },
                    fontWeight: 700,
                    fontFamily: "Circular Std",
                  }}
                >
                  WhatsApp Integration
                </Typography>
                <Typography
                  sx={{
                    color: "#343434",
                    fontSize: "18px",
                    fontFamily: "Product Sans",
                    mt: "5px",
                    display: { xs: "none", md2: "block" },
                  }}
                >
                  Connect and communicate with your leads directly <br />{" "}
                  through WhatsApp integrated within the platform.
                </Typography>
                <Typography
                  sx={{
                    color: "#343434",
                    fontSize: "18px",
                    fontFamily: "Product Sans",
                    mt: "5px",
                    display: { xs: "block", md2: "none" },
                  }}
                >
                  Connect and communicate with your leads directly through
                  WhatsApp integrated within the platform.
                </Typography>
              </Box>
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: { xs: "20px", md: "50px" },
                flexDirection: { xs: "column-reverse", md: "row" },
              }}
            >
              <Box
                data-aos="fade-right"
                sx={{
                  width: { xs: "100%", md: "50%" },
                  textAlign: { xs: "center", md: "left" },
                }}
              >
                <Typography
                  sx={{
                    color: "#272D4E",
                    fontSize: { xs: "24px", lg: "32px" },
                    fontWeight: 700,
                    fontFamily: "Circular Std",
                  }}
                >
                  In-App Commission Payment
                </Typography>
                <Typography
                  sx={{
                    color: "#343434",
                    fontSize: "18px",
                    fontFamily: "Product Sans",
                    mt: "5px",
                  }}
                >
                  Builders pay your commissions directly to your EnvisionNext
                  account. You can withdraw your payments anytime.
                </Typography>
              </Box>
              <Box
                data-aos="fade-left"
                sx={{ width: { xs: "100%", md: "50%" }, textAlign: "center" }}
              >
                <img
                  style={{ width: "70%" }}
                  src={InAppCommissionPayment}
                  alt=""
                />
              </Box>
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: { xs: "20px", md: "50px" },
                flexDirection: { xs: "column", md: "row" },
              }}
            >
              <Box
                data-aos="fade-right"
                sx={{ width: { xs: "100%", md: "50%" }, textAlign: "center" }}
              >
                <img
                  style={{ width: "70%" }}
                  src={DigitizedRequirementForm}
                  alt=""
                />
              </Box>
              <Box
                data-aos="fade-left"
                sx={{
                  width: { xs: "100%", md: "50%" },
                  textAlign: { xs: "center", md: "left" },
                }}
              >
                <Typography
                  sx={{
                    color: "#272D4E",
                    fontSize: { xs: "24px", lg: "32px" },
                    fontWeight: 700,
                    fontFamily: "Circular Std",
                  }}
                >
                  Digitized Requirement Form
                </Typography>
                <Typography
                  sx={{
                    color: "#343434",
                    fontSize: "18px",
                    fontFamily: "Product Sans",
                    mt: "5px",
                  }}
                >
                  Capture and manage leads with digitized Requirement Forms.
                  Easily use this data to optimize your sales.
                </Typography>
              </Box>
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: { xs: "20px", md: "50px" },
                flexDirection: { xs: "column-reverse", md: "row" },
              }}
            >
              <Box
                data-aos="fade-right"
                sx={{
                  width: { xs: "100%", md: "50%" },
                  textAlign: { xs: "center", md: "left" },
                }}
              >
                <Typography
                  sx={{
                    color: "#272D4E",
                    fontSize: { xs: "24px", lg: "32px" },
                    fontWeight: 700,
                    fontFamily: "Circular Std",
                  }}
                >
                  Project/Inventory Data Management
                </Typography>
                <Typography
                  sx={{
                    color: "#343434",
                    fontSize: "18px",
                    fontFamily: "Product Sans",
                    mt: "5px",
                  }}
                >
                  No more managing inventory details and photos on paper. Save
                  time by getting all project-related data you need directly
                  from the Builder.
                </Typography>
              </Box>
              <Box
                data-aos="fade-left"
                sx={{ width: { xs: "100%", md: "50%" }, textAlign: "center" }}
              >
                <img
                  style={{ width: "70%" }}
                  src={ProjectInventoryDataManagement}
                  alt=""
                />
              </Box>
            </Box>
          </Stack>
        </Box>
      </Container>
    </Box>
  );
};

export default ChannelLandingFuelYourRealEstateBusiness;
