import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  MenuItem,
  Drawer,
  Box,
  Button,
  Checkbox,
  FormControl,
  InputLabel,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { Stack } from "@mui/system";
import TuneOutlinedIcon from "@mui/icons-material/TuneOutlined";
import KeyboardArrowRightOutlinedIcon from "@mui/icons-material/KeyboardArrowRightOutlined";
import KeyboardArrowUpOutlinedIcon from "@mui/icons-material/KeyboardArrowUpOutlined";
import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined";
import { CheckBox } from "@mui/icons-material";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import DriveFileRenameOutlineIcon from "@mui/icons-material/DriveFileRenameOutline";
import VisibilityIcon from "@mui/icons-material/Visibility";
import DeleteIcon from "@mui/icons-material/Delete";
import "../MembersDirectory/MDmainPage.css";
import { Sidebar } from "../ProjectDashboard/DashboardComponents/Sidebar";
import { Navbar } from "../ProjectDashboard/DashboardComponents/Navbar";
import React, { useContext, useEffect, useState } from "react";
import { DataGrid, GridExpandMoreIcon } from "@mui/x-data-grid";
import CategoryIcon from "@mui/icons-material/Category";
import HandshakeIcon from "@mui/icons-material/Handshake";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import axios from "axios";
import { useTheme } from "@emotion/react";
import { NavbarForDirectory } from "../ProjectDashboard/DashboardComponents/NavbarForDirectory";
import { basicheaders } from "../../../utils/consts";
import profileContext from "../../../context/profile/context";
import RightSidebarOD from "./RightSidebarOD";
import ODcustomTable from "./ODcustomTable";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Apiaddress } from "../../../utils/consts";
import { MainSidebar } from "../ProjectDashboard/DashboardComponents/MainSidebar";
import { UserRole } from "../../../UserRoles/UserRoles";

function ODmainPage() {

  ////get user roles from local storage and assign 
  let roles = JSON.parse(localStorage.getItem("user_roles"))
  let org = roles["org"];


  const [delIconClicked, setdelIconClicked] = useState(false);
  const columns = [
    { field: "id", headerName: "id", width: 130 },
    { field: "Name", headerName: "Name", width: 130 },
    { field: "State", headerName: "State", width: 130 },
    { field: "City", headerName: "City", width: 130 },
    {
      field: "Pincode",
      headerName: "Pincode",
      // type: "number",
      width: 130,
    },
    {
      field: "Action",
      headerName: "Action",
      headerAlign: "center",
      width: 130,
      renderCell: (params) => {
        return (
          <Stack direction="row" sx={{ marginLeft: "10px" }}>
            <RemoveRedEyeIcon fontSize="medium" />
            <DeleteIcon
              fontSize="medium"
              sx={{ marginLeft: "10px" }}
              onClick={() => delIconClick(params)}
            />
          </Stack>
        );
      },
    },
  ];

  const rows = [
    {
      id: 1,
      Name: "#4560",
      State: "26 March 2022, 12:42 AM",
      City: "Seema Agarwal",
      Pincode: "67 St. pune",
    },
  ];
  const [state, setState] = React.useState(false);

  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState(open);
  };

  const [stateadd, setStateadd] = React.useState(false);

  const toggleDrawerAdd = (open, item) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    updateBuyers(item);
    setStateadd(open);
  };

  const [selectedRows, setSelectedRows] = React.useState([]);
  useEffect(() => {
    console.log(selectedRows);
  }, [selectedRows]);

  const [curselectedRows, setcurselectedRows] = useState(null);
  const onRowsSelectionHandler = (ids) => {
    if (delIconClicked) setdelIconClicked(false);
    const selectedRowsData = ids.map((id) => rows.find((row) => row.id === id));
    // console.log(selectedRowsData);
    setcurselectedRows(selectedRowsData);
  };

  const [tabledata, setTabledata] = useState(null);
  const [rowData, setrowData] = useState([]);
  const [idCont, setidCunt] = useState(1);

  //api

  //api

  useEffect(() => {
    const rows = [
      {
        id: 8,
        Name: "Frances",
        Phone: "912912912",
        Email: "dummmy@gamil.com",
        Team: "Agent",
        Role: "Agent",
        DetailedView: "View",
      },
    ];
    if (tabledata) {
      let obj = {
        id: idCont,
        Name: tabledata.name,
        Phone: tabledata.phone,
        Email: tabledata.Email,
        Team: tabledata.Team,
        Role: tabledata.Role,
      };
      setidCunt(idCont + 1);
      console.log(obj);
      setrowData([...rowData, obj]);
    }

    console.log(tabledata);
  }, [tabledata]);

  const [trigger, setTrigger] = useState(false);
  const [rowSelected, setRowSelected] = useState(false);
  const [token, setToken] = useState(null);
  const [data, setData] = useState(null);

  const getOrgs = async () => {
    try {
      const headers = {
        Authorization: `JWT ${token}`,
      };
      const res = await axios.get(
        Apiaddress + "/api/orglistusingtoken/",
        {
          headers,
        }
      );
      console.log(res);
      if (res.data) if (res.data.org_list) setData(res.data.org_list);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (token) getOrgs();
  }, [token, trigger]);
  useEffect(() => {
    setToken(localStorage.getItem("crm_token"));
  }, [trigger]);

  const [newRowData, setnewRowData] = useState([]);
  useEffect(() => {
    console.log(data);
    if (data) {
      // {
      //   id: 1,
      //   Name: "#4560",
      //   State: "26 March 2022, 12:42 AM",
      //   City: "Seema Agarwal",
      //   Pincode: "67 St. pune",
      // },

      let arr = [];

      for (let i = 0; i < data.length; i++) {
        let obj = {
          id: i + 1,
          org_id: data[i].id,
          Name: data[i].org_name,
          State: data[i].state,
          City: data[i].city,
          Pincode: data[i].zip_code,
        };
        arr.push(obj);
      }
      setnewRowData(arr);
    }
  }, [data]);
  // console.log(newRowData);

  const delIconClick = async (params) => {
    console.log(params);
    try {
      const obj = {
        data: { org_id: params.org_id },
        headers: basicheaders,
      };
      const res = await axios.delete(Apiaddress + "/api/org/", obj);
      console.log(res);
      setTrigger(!trigger);
    } catch (err) {
      console.log(err);
    }
  };

  const [updateInfo, setupdateInfo] = useState(null);
  const updateBuyers = (params) => {
    setupdateInfo(params);
  };

  //fitering data starts:====
  const [filterselect, setfilterselect] = useState();
  const [filtervalue, setfiltervalue] = useState();

  const handleChange = (event) => {
    setfilterselect(event.target.value);
    setfiltervalue("");
  };

  console.log(filterselect);
  console.log(filtervalue);

  const fetchfilter= async ()=>{
     try {
      const headers = {
        Authorization: `JWT ${token}`,
      };
      const res = await axios.get(
        Apiaddress + `/api/orglistusingtoken/?filterkey=${filterselect}&filtervalue=${filtervalue}`,
        {
          headers,
        }
      );
      console.log(res);
      if (res.data) if (res.data.org_list) setData(res.data.org_list);
    } catch (err) {
      console.log(err);
    }
  }

  return (

    <>

<Stack direction="row" sx={{width:"100%",height:"auto" }}>
      {/* 1st stack */}
      <Stack sx={{minHeight:"100vh",width:"20%",boxSizing:"border-box",}}>
        <MainSidebar active={"Organization Directory"}/>
      </Stack>

     {/* 2nd stack */}
     <Stack direction="column" sx={{ backgroundColor:"#f9f9f9",width:"80%", padding:"25px",boxSizing:"border-box",}}  >  {/*  width for main sidebar 78px and for 80px*/}
      {/* <Navbar/> */}
      <NavbarForDirectory
          showRightSideBar={UserRole(org,"add_org")?true:false}
          RightBarName={"Add Organizations"}
          toggleDrawer={toggleDrawerAdd}
          leftTitle={"Sales Dashboard"}
          toggleDrawerAdd={toggleDrawerAdd}
          RightBarNameleft={"Send invite"}
          leftRightTitle2={"Organization Directory"}
          longOrangBgr={true}
          righshow={true}
        />
      


    {/* <Typography sx={{mt:"20px", fontSize:"22px", fontWeight:"500",color:"#2979F2"}}>
    Project Name
    </Typography> */}
     {/* actual content  */}
    <Stack sx={{ mt:"28px",backgroundColor:"#f9f9f9"}}>

        <React.Fragment>
          <Drawer
            anchor="right"
            open={stateadd}
            onClose={toggleDrawerAdd(false)}
          >
            <RightSidebarOD
              settrigger={setTrigger}
              setTabledata={setTabledata}
              trigger={trigger}
              curselectedRows={curselectedRows}
              toggleDrawerAdd={toggleDrawerAdd}
              updateInfo={updateInfo}
              setStateadd={setStateadd}
            />
          </Drawer>
        </React.Fragment>

        <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            sx={{ height: "68px", marginBottom: "20px" }}
          >
            <Stack direction="column" alignItems="center">
              <Typography
                sx={{
                  fontSize: "22px",
                  fontWeight: "600",
                  marginLeft: "10px",
                  color: "black",
                }}
              >
                Organization Directory
              </Typography>
              <Typography
                sx={{
                  color: "#B9B7D0",
                  fontSize: "12px",
                  fontWeight: "400",
                  paddingLeft: "10px",
                }}
              >
                Here is your Organization Directory list data
              </Typography>
            </Stack>
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Stack
                direction="row"
                justifyContent="space-around"
                alignItems="center"
                sx={{
                  width: "203px",
                  height: "68px",
                  borderRadius: "12px",
                  color: "#3E4954",
                  backgroundColor: "#f4f5f9",
                }}
              >
                
               <Accordion sx={{ width: "250px", boxShadow: "none" }}>
                  <AccordionSummary
                    expandIcon={<GridExpandMoreIcon/>}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography sx={{ display: "flex", placeItems: "center" }}>
                      <TuneOutlinedIcon sx={{ color: "#2F4CDD" }} />
                      <Typography sx={{ marginLeft: "25px" }}>
                        Fillters
                      </Typography>
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Box sx={{textAlign:"center"}}>
                      <FormControl sx={{ width: "200px" }}>
                        <InputLabel id="demo-simple-select-label">
                          Select
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={filterselect}
                          label="Select"
                          onChange={handleChange}
                        >
                          <MenuItem value={"org_name"}>Organization</MenuItem>
                          <MenuItem value={"state"}>State</MenuItem>
                          <MenuItem value={"city"}>City</MenuItem>
                          <MenuItem value={"zip_code"}>Pincode</MenuItem>
                          {/* <MenuItem value={"Name"}>Name</MenuItem> */}
                        </Select>
                      </FormControl>
                      <TextField
                        sx={{ marginTop: "20px" }}
                        placeholder="Enter the value" 
                        value={filtervalue} onChange={(e)=>{setfiltervalue(e.target.value)}}
                      />
                      <Button 
                        onClick={fetchfilter}
                        variant="contained"
                        color="primary" sx={{ height:"40px", background: "linear-gradient(90deg, #0F5DAE 0%, #009DCF 100.33%)",boxShadow: "0px 13.7981px 22.5788px rgba(50, 174, 137, 0.25)", textTransform: "none", borderRadius: "4px", width:"100px", fontSize:"15px",margin:"20px 0px 10px 0px"}}>
                         Submit
                      </Button>

                    </Box>
                  </AccordionDetails>
                </Accordion>


              </Stack>
            </Stack>
          </Stack>

          <ODcustomTable
            newRowData={newRowData}
            delIconClick={delIconClick}
            updateBuyers={updateBuyers}
            setTrigger={setTrigger}
            trigger={trigger}
            toggleDrawerAdd={toggleDrawerAdd}
          />
   

    </Stack>



      </Stack>
</Stack>




  
    </>
  );
}

export default ODmainPage;
