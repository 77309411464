import { Box, Button, Container, Typography } from "@mui/material";
import React from "react";

const ChannelLandingMakeTheMostOfTheEnvisionNext = () => {
  return (
    <Box
      sx={{
        backgroundColor: "#0085FF",
        py: "20px",
      }}
    >
      <Container>
        <Box
          data-aos="fade-zoom-in"
          data-aos-easing="ease-in-back"
          data-aos-delay="50"
          data-aos-offset="0"
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            gap: "20px",
            padding: { xs: "15px", md: "30px 20px" },
            borderRadius: { xs: "10px", md: "20px" },
            flexDirection: { xs: "column", md: "row" },
          }}
        >
          <Typography
            sx={{
              color: "#FFF",
              fontSize: { xs: "28px", md: "32px", lg: "40px" },
              fontWeight: 700,
              fontFamily: "Circular Std",
              lineHeight: "1.3",
              display: { xs: "none", md2: "block" },
            }}
          >
            Make the most of the EnvisionNext <br />
            ecosystem by getting started today!
          </Typography>
          <Typography
            sx={{
              color: "#FFF",
              fontSize: { xs: "28px", md: "32px", lg: "40px" },
              fontWeight: 700,
              fontFamily: "Circular Std",
              lineHeight: "1.3",
              display: { xs: "none", md: "block", md2: "none" },
            }}
          >
            Make the most of the <br /> EnvisionNext ecosystem by <br /> getting
            started today!
          </Typography>
          <Typography
            sx={{
              color: "#FFF",
              fontSize: { xs: "32px", lg: "40px" },
              fontWeight: 700,
              fontFamily: "Circular Std",
              lineHeight: "1.3",
              display: { xs: "block", md: "none" },
              textAlign: "center",
            }}
          >
            Make the most of the EnvisionNext ecosystem by getting started
            today!
          </Typography>
          <Box>
            <Button
              sx={{
                background:
                  "linear-gradient(97.85deg, #FFAF2A 0.95%, #FFC666 100%)",
                fontWeight: 500,
                fontSize: { lg: "28px", xs: "16px" },
                boxShadow: "rgba(242, 178, 73, 0.59) 0px 9px 12px",
                borderRadius: "10px",
                color: "#06386B",
                textTransform: "none",
                padding: "10px 30px",
                fontFamily: "Product Sans",
                mt: "20px",
                "&:hover": {
                  background:
                    "linear-gradient(97.85deg, #FFAF2A 0.95%, #FFC666 100%)",
                  boxShadow: "rgba(242, 178, 73, 0.59) 10px 12px 16px",
                },
              }}
            >
              Sign Up for Free
            </Button>
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

export default ChannelLandingMakeTheMostOfTheEnvisionNext;
