import React, { useContext, useEffect, useState } from 'react';
import { Box, Button, Radio, Stack, TextField, Typography } from "@mui/material";
import DoneIcon from '@mui/icons-material/Done';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import Pagination from '@mui/material/Pagination';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';
import Checkbox from '@mui/material/Checkbox';
import Modal from '@mui/material/Modal';

import axios from 'axios';
import { useNavigate, Link, useLocation } from "react-router-dom";

import { Apiaddress } from '../../../utils/consts';
import { BackeToOverviewBtn } from '../ProjectDashboard/CreateProject/BackeToOverviewBtn';
import currentProjectState from "../../../context/createproject/context";
import { MainSidebar } from '../ProjectDashboard/DashboardComponents/MainSidebar';
import { Navbar } from '../ProjectDashboard/DashboardComponents/Navbar';


function EditProjectUnit_Media() {
  const navigate = useNavigate();
  
  ////get data from previous page 
   const location = useLocation();
   const toSend= location.state;
   const data = location.state.state; 
   const floorData = location.state.current_data;  
 
  console.log("preve data =>.>",toSend);

  let retrievedObject=localStorage.getItem('state');
  let fromPrev= JSON.parse(retrievedObject);
  console.log(fromPrev);

  let [current_data, setCurrentData] = useState({})

   ////get data of buliding templates 
  const cpContext = useContext(currentProjectState);
  const projectID = cpContext?.createProjectState?.project?.id;

   const [media,setmedia]=useState([]);

    const handlechange=(event,ele)=>{

     let data= media?.map((element,idx)=>{
         if(idx==ele){
          element.istrue=event.target.value
         }
         else{
           element.istrue="false"
         }
         return element;
      })
      setmedia(data); 
    }

  //   const addmedia=()=>{
  //   let temp=media.length+1;
  //   let to_insert={
  //     url:"",
  //     istrue:false
  //   }
  //   setmedia([...media,to_insert]);
  // }

  useEffect(()=>{

    const uniturl = Apiaddress+"/api/projects/unittemplate/?unit_template_id="+data.unit_id;

   axios.get(uniturl)
   .then((res)=>{
        const data = res?.data
        console.log()
        console.log("unit layout  data=>>>>>>>.",data?.unit_template[0].layout_data)
        console.log("media data=>>>>>>>.",data?.unit_template[0].layout_data.image)
        setCurrentData(data?.unit_template[0].layout_data)
        
        setmedia(data?.unit_template[0].layout_data.image);

     })
     .catch((err)=>{
       console.log(err)
     })

  },[])

   //function for create unit Templates
  const createmediaTemplate = ()=>{

      setCurrentData({...current_data,image: media});
      console.log({...current_data,image: media}); 

    let url = Apiaddress+"/api/projects/unittemplate/?unit_template_id="+data.unit_id;
    
    let st = {
      unit_template_id:data.unit_id,
      layout_data:{...current_data,image: media}
    }

    let headers = {
      headers:{
        "Content-Type":"application/json"
    }
    }
  //   console.log(st); 
 
    axios.patch(url,st,headers)
    .then((res)=>{
      console.log("add media template in unit======>", res.data);
    })
    .catch((err)=>{
      console.log(err);
    })

    }

    
  return (
   <>
   <Stack direction="row" sx={{width:"100%",height:"auto" }}>
         {/* 1st stack */}
         <Stack sx={{minHeight:"100vh",width:"20%",boxSizing:"border-box",}}> {/*for project directory 20% and for sales dashboard 225*/}
           <MainSidebar active={"Project Directory"}/>
         </Stack>
   
        {/* 2nd stack */}
        <Stack direction="column" sx={{ backgroundColor:"#f9f9f9",width:"80%", padding:"25px",boxSizing:"border-box",}}  >  {/*  width for main sidebar 78px and for 80px*/}
         <Navbar editProject={true}/>
         
   
   
         <Typography sx={{marginTop:"20px 20px 20px 70px", fontSize:"28px", fontWeight:"600", marginLeft:"10px", marginBottom:"50px"}}>
                    Project Overview 
           </Typography>
        {/* actual content  */}
       <Stack sx={{backgroundColor:"#f9f9f9"}}>  {/* mt:"28px", */}
   
         <Stack direction="column"  sx={{  padding:"20px", backgroundColor:"#fff", marginRight:"0px"}}>

           <Stack direction="row" spacing={1.5}>
        <Stack direction="row" alignItems="center" spacing={1.5} sx={{margin:"70px 0px 70px 0px"}}>
           <Box  sx={{width:"29px", height:"29px",borderRadius:"50%", backgroundColor:"#FFA946", display:"flex", justifyContent:"center", alignItems:"center"}}>
              <DoneIcon sx={{color:"#fff", fontSize:"20px"}}/>
            </Box>
            <Typography sx={{fontSize:"18px", fontWeight:"500"}}>Project Home</Typography>
          </Stack>
          <Stack direction="row" alignItems="center" spacing={1.5} sx={{margin:"70px 0px 70px 0px"}}>
           <Box  sx={{width:"29px", height:"29px",borderRadius:"50%", backgroundColor:"#FFA946", display:"flex", justifyContent:"center", alignItems:"center"}}>
              <DoneIcon sx={{color:"#fff", fontSize:"20px"}}/>
            </Box>
            <Typography sx={{fontSize:"18px", fontWeight:"500"}}>Building 1</Typography>
          </Stack>
          <Stack direction="row" alignItems="center" spacing={1.5} sx={{margin:"70px 0px 70px 0px"}}>  
           <Box  sx={{width:"29px", height:"29px",borderRadius:"50%", backgroundColor:"#FFA946", display:"flex", justifyContent:"center", alignItems:"center"}}>
              <DoneIcon sx={{color:"#fff", fontSize:"20px"}}/>
            </Box>
            <Typography sx={{fontSize:"18px", fontWeight:"500"}}>Wing 1</Typography>
          </Stack>
          <Stack direction="row" alignItems="center" spacing={1.5} sx={{margin:"70px 0px 70px 0px"}}>
           <Box  sx={{width:"29px", height:"29px",borderRadius:"50%", backgroundColor:"#FFA946", display:"flex", justifyContent:"center", alignItems:"center"}}>
              <DoneIcon sx={{color:"#fff", fontSize:"20px"}}/>
            </Box>
            <Typography sx={{fontSize:"18px", fontWeight:"500"}}>Floor 1</Typography>
          </Stack>
          <Stack direction="row" alignItems="center" spacing={1.5} sx={{margin:"70px 0px 70px 0px"}}>
           <Box  sx={{width:"29px", height:"29px",borderRadius:"50%", backgroundColor:"#FFA946", display:"flex", justifyContent:"center", alignItems:"center"}}>
              <DoneIcon sx={{color:"#fff", fontSize:"20px"}}/>
            </Box>
            <Typography sx={{fontSize:"18px", fontWeight:"500"}}>{data.name} (Media)</Typography>
          </Stack>
          </Stack>

          <Box sx={{margin:"0px 0px 0px 60px"}}>
            <Stack direction="row" sx={{margin:"0px 0px 0px 0px",display:"flex",alignItems:"center"}} >
              <Typography sx={{fontSize:"18px", fontWeight:"500"}}>Media</Typography>
              <Link to="/builder-edit-project-unit-media-add" state={toSend}>
              <AddIcon sx={{color:"#A2A3A6",fontSize:"18px",marginLeft:"10px",cursor:"pointer"}}/>
              </Link>
            </Stack>

            <Box sx={{display:"flex",flexWrap:"wrap",margin:"30px 0px 0px 0px"}}>
            {media?.map((ele,idx)=>{
                return(
                 <Stack direction="row" sx={{alignItems:"center",width:"35%",margin:"20px 10px 20px 0px"}}>
                 <Box sx={{width:"100px",height:"100px",borderRadius:"20px",backgroundColor:"#D9D9D9"}}>
                 <img src={Apiaddress+ele.image} style={{height:"100%",width:"100%",borderRadius:"20px"}}></img> 
                 </Box>
        
                  <input type="radio" id="r1" name="rate" value="true" style={{width:"30px", marginBottom:"4px"}} checked={ele?.istrue === "true"} onChange={(e)=>{handlechange(e,idx)}}></input>
                  
                 <Typography sx={{color:"#A4A4AD", fontSize:"12px", fontWeight:500, marginRight:"10px"}}>Highlight</Typography>
                 <DeleteIcon sx={{fontSize:"26px", fontWeight:"500",color:"#EF3649",margin:"0px 0px 0px 0px",cursor:"pointer"}}
                   onClick={()=>{
                            const newmedia = media.filter((x) => x !== ele);
                            setmedia(newmedia);
                        }}
                />
              </Stack>
                )
            })}
            </Box>

           <Stack direction="row" sx={{alignItems:"center"}}>

            <Box
             onClick={()=>{
              navigate(-1)
            }}
            sx={{display:"flex",alignItems:"center",margin:"0px 0px 0px 0px"}}>
            <ArrowBackIcon/>
            <Typography sx={{fontSize:"18px", fontWeight:"500",margin:"0px 10px 0px 10px"}}>Back</Typography>
            </Box>
            
             <Box sx={{margin:"60px 0px 60px 60px"}}>

              <Link to={`/builder-edit-project-unit`} state={floorData}
                onClick={()=>{
                createmediaTemplate()
              }}
              >
                <Button 
                className="univercel_btn_class"
                variant="contained"
                sx={{padding:"16px 50px 16px 50px", color:"#fff",fontSize:"18px", fontWeight:"700",height:"50px"}}>
                    Save 
                </Button>
              </Link>

            </Box>

            </Stack>

           </Box>
           </Stack>
           <BackeToOverviewBtn projectDirectory={true}/>
   
       </Stack>
         </Stack>
   </Stack>
    
    </>
  )
}

export default EditProjectUnit_Media