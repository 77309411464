import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { Apiaddress } from '../../../utils/consts'
import ProjectSlider from '../../BuyerComponents/Projects/components/ProjectSlider'
import CPDashboardLock from './CPDashboardLock'

const DashboardView = (props:any) => {

  const crm_token = localStorage.getItem("crm_token")
  const [open, setOpen] = useState(false);

  useEffect(() => {
    const headers = {
      'Content-Type': 'application/json',
      "Authorization": "JWT " + crm_token
    }
    axios.get(Apiaddress + "/api/profile/", {
      headers: headers
    })
      .then((res) => {
        const data = res.data;
        console.log(data);

        if(data.user_obj.is_active===false){
          setOpen(true); 
        }
        // setOpen(!(data.user_obj.is_active))

        console.log("res:", res.data)
      })
      .catch((err) => {
        console.log("err:", err)
      })
  }, [])

  return (
    <>
      {/* <CPDashboardLock open={open}/> */} 
      <ProjectSlider setUpdate={()=>{}} update={false}></ProjectSlider>    
    </>
  )
}

export default DashboardView