
import Backdrop from '@mui/material/Backdrop';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import { useState } from 'react';
import { Apiaddress } from '../../utils/consts';
import { Box, Button, TextField, Typography } from '@mui/material';
import { useForm } from 'react-hook-form';
import axios from 'axios';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #fff',
    borderRadius:"10px",
    boxShadow: 24,
    p: 4,
  };
  

export const SendMail = ({setOpen,handleOpen,handleClose,setSuccess,success,open})=>{
    

    const { register, handleSubmit, watch, formState: { errors } } = useForm();

    ////modal for forgot password;
    const [sendEmail, setSendEmail] = useState("")
    const [err,setErr] = useState("");

    const sendmail = (data)=>{
        let url = Apiaddress+"/api/forgetpass/";

        axios.post(
            url,
        {email:data.send_mail}
        ).then((res)=>{
            console.log("res", res.data)
            if(res.data.error==false){
                setSuccess(true)
            }
        }).catch((err)=>{
            console.log("err", err)
            setErr("Invalid Email");
        })


    }
    return(
        <>
            {/* forgot password popup */}
            <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <Box sx={style}>

            <Typography id="transition-modal-title" variant="h6" component="h2" sx={{color:success?"green":"black"}}>
              {success?"Check your Mail Inbox or Spam to set Password":" Enter your Registered Email ID"}
            </Typography>
            {!success && 
            <form onSubmit={handleSubmit(sendmail)}>
            <TextField 
                 sx={{
                    width:"100%",
                    margin:"10px 0px 10px 0px"
                 }}
                 type="email"
                 id="outlined-error-helper-text"
                 name="send_mail"
                 fullWidth
                 {...register("send_mail", {required:"Email is required."})}
                    error={Boolean(errors.send_mail)}
                    helperText={errors.send_mail?.message}
                />
              {err!==""?
                <Typography
                  sx={{
                    fontWeight: "500",
                    fontSize: "14px",
                    marginBottom: "5px",
                    color: "#da6061",
                  }}
                >
                {err}
                </Typography>:
                null
              }
                <Button 
                type="submit"
                sx={{width:"100%"}} className="univercel_btn_class">
                    Submit
                </Button>
            </form>
            }
          </Box>
        </Fade>
      </Modal>
        </>
    )
}