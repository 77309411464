import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";

const TabBar = ({
  Tabs,
  floatingActonButton,
  onIndexChange,
  peresistantid,
}: {
  peresistantid?: string;
  Tabs: TabData[];
  onIndexChange?: any;
  floatingActonButton?: React.ReactElement;
}) => {
  if (Tabs.length == 0) {
    throw "noTabsProvided";
  }

  // useEffect(() => {
  //     if(peresistantid !=  null){
  //         try{

  //             if (localStorage.getItem(peresistantid) != undefined)
  //                 setSelectedTab( localStorage.getItem(peresistantid))
  //         }catch{

  //         }
  //     }
  // }, [])

  const [SelectedTab, st] = useState(0);
  const setSelectedTab = (e: any) => {
    st(e);
    try {
      onIndexChange(e);
      if (peresistantid != null) {
        localStorage.setItem(peresistantid, e);
      }
    } catch {}
  };
  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          borderBottom: "3px solid #e4e4e4",
          marginTop: "10px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            gap: "50px",
          }}
        >
          {Tabs.map((e, i) => {
            const selected = SelectedTab === i;
            return (
              <Box
                sx={{
                  padding: "10px 0px",
                  fontFamily: "Circular Std",
                  fontWeight: "400",
                  color: selected ? "#2979F2" : "#A4A4AD",
                  fontSize: selected ? "20px" : "18px",
                  borderBottom: selected ? "3px solid #2979F2" : "",
                  cursor: "pointer",
                  marginBottom: "-2px",
                  borderRadius: "3px",
                }}
                onClick={(b) => {
                  setSelectedTab(i);
                }}
              >
                {e.Name}
              </Box>
            );
          })}
        </Box>
        {floatingActonButton}
      </Box>
      <Box
        sx={{
          border: "1.44px solid #0F5DA",
        }}
      >
        {Tabs[SelectedTab].children}
      </Box>
    </Box>
  );
};

export default TabBar;

interface TabData {
  Name: String;
  children: React.ReactElement[];
}

/* import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";

const TabBar = ({
  Tabs,
  floatingActonButton,
  onIndexChange,
  peresistantid,
}: {
  peresistantid?: string;
  Tabs: TabData[];
  onIndexChange?: any;
  floatingActonButton?: React.ReactElement;
}) => {
  if (Tabs.length == 0) {
    throw "noTabsProvided";
  }

  // useEffect(() => {
  //     if(peresistantid !=  null){
  //         try{

  //             if (localStorage.getItem(peresistantid) != undefined)
  //                 setSelectedTab( localStorage.getItem(peresistantid))
  //         }catch{

  //         }
  //     }
  // }, [])

  const [SelectedTab, st] = useState(0);
  const setSelectedTab = (e: any) => {
    st(e);
    try {
      onIndexChange(e);
      if (peresistantid != null) {
        localStorage.setItem(peresistantid, e);
      }
    } catch {}
  };
  return (
    <Box
      sx={{
        color: "black",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          borderBottom: "3px solid #e4e4e4",
          marginTop: "10px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
          }}
        >
          {Tabs.map((e, i) => {
            const selected = SelectedTab === i;
            return (
              <Box
                sx={{
                  borderRadius: "10px 10px 0px 0px",
                  padding: "15px 70px 15px 70px",
                  backgroundColor: selected ? "#2979F2" : "#CCCCCC",
                  fontFamily: "Circular Std",
                  fontWeight: "400",
                  fontSize: "20px",
                  color: selected ? "#FFF" : "#787878",
                  cursor: "pointer",
                }}
                onClick={(b) => {
                  setSelectedTab(i);
                }}
              >
                {e.Name}
              </Box>
            );
          })}
        </Box>
        {floatingActonButton}
      </Box>
      <Box
        sx={{
          border: "1.44px solid #0F5DA",
        }}
      >
        {Tabs[SelectedTab].children}
      </Box>
    </Box>
  );
};

export default TabBar;

interface TabData {
  Name: String;
  children: React.ReactElement[];
}
 */
