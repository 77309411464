import { Box, Container, Stack, Typography } from "@mui/material";
import CircleCheckIcon from "../../Imgs/circle-check-icon.svg";
import LeadManagement from "../../Imgs/lead-management.png";

const CommonPagesLeadManagement = () => {
  return (
    <Box sx={{ overflowX: "hidden" }}>
      <Container>
        <Box
          sx={{
            display: "flex",
            gap: { xs: "10px", md: "30px" },
            alignItems: "center",
            flexDirection: { xs: "column-reverse", md: "row" },
            mt: { xs: "1rem", md: "50px" },
          }}
        >
          <Stack sx={{ width: { xs: "100%", md: "55%" } }}>
            <Typography
              data-aos="fade-right"
              sx={{
                color: "#272D4E",
                fontSize: { xs: "24px", md: "32px", lg: "45px" },
                fontWeight: 700,
                fontFamily: "Circular Std",
                lineHeight: "1.2",
              }}
            >
              Lead Management
            </Typography>

            <Stack spacing={2}>
              <Typography
                sx={{
                  fontSize: "18px",
                  fontFamily: "Product Sans",
                }}
              >
                Manage all your leads on a central dashboard across all
                your projects
              </Typography>
              <Box sx={{ display: "flex", gap: "10px" }}>
                <Box sx={{ lineHeight: "10px", fontSize: "30px" }}>
                  {/* <img src={CircleCheckIcon} alt="" /> */}
                  &#x2022;
                </Box>
                <Typography
                  sx={{
                    fontSize: "16px",
                    fontFamily: "Product Sans",
                    color: "#343434",
                    lineHeight: "1.3",
                  }}
                >
                  <span style={{ fontWeight: 600 }}>Build</span> and
                  <span style={{ fontWeight: 600 }}> manage</span> the database
                  of your leads
                </Typography>
              </Box>
              <Box sx={{ display: "flex", gap: "10px" }}>
                <Box sx={{ lineHeight: "10px", fontSize: "30px" }}>
                  {/* <img src={CircleCheckIcon} alt="" /> */}
                  &#x2022;
                </Box>
                <Typography
                  sx={{
                    fontSize: "16px",
                    fontFamily: "Product Sans",
                    color: "#343434",
                    lineHeight: "1.3",
                  }}
                >
                  See key{" "}
                  <span style={{ fontWeight: 600 }}>
                    actionable data points
                  </span>{" "}
                </Typography>
              </Box>
              <Box sx={{ display: "flex", gap: "10px" }}>
                <Box sx={{ lineHeight: "10px", fontSize: "30px" }}>
                  {/* <img src={CircleCheckIcon} alt="" /> */}
                  &#x2022;
                </Box>
                <Typography
                  sx={{
                    fontSize: "16px",
                    fontFamily: "Product Sans",
                    color: "#343434",
                    lineHeight: "1.3",
                  }}
                >
                  <span style={{ fontWeight: 600 }}>Segment, automate,</span>{" "}
                  and <span style={{ fontWeight: 600 }}>track</span>{" "}
                  communication and lead-specific workflows
                </Typography>
              </Box>
              <Box sx={{ display: "flex", gap: "10px" }}>
                <Box sx={{ lineHeight: "10px", fontSize: "30px" }}>
                  {/* <img src={CircleCheckIcon} alt="" /> */}
                  &#x2022;
                </Box>
                <Typography
                  sx={{
                    fontSize: "16px",
                    fontFamily: "Product Sans",
                    color: "#343434",
                    lineHeight: "1.3",
                  }}
                >
                  <span style={{ fontWeight: 600 }}>Increase conversions</span>{" "}
                  with a seamless process and valuable insights
                </Typography>
              </Box>
            </Stack>
          </Stack>
          <Box data-aos="zoom-in" sx={{ width: { xs: "100%", md: "45%" } }}>
            <img style={{ width: "100%" }} src={LeadManagement} alt="" />
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

export default CommonPagesLeadManagement;
