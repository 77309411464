import { Box, Button, MobileStepper, Modal, Typography } from '@mui/material'
import { display, margin } from '@mui/system'
import React, { useState } from 'react'
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import { Apiaddress } from '../../../../utils/consts';
export const ImageCorrousal = (
    {open,images,inverter}
) => {
    const [activeStep, setindex] = useState(0)
  return (
<Modal
  open={open}
  aria-labelledby="modal-modal-title"
  aria-describedby="modal-modal-description"
>
  <Box
  sx={{
    // width:"50%",
    // height:"50%",
    // display:"flex",
    // justifyContent:'center',
    // alignItems:"center",
    // top: '55%',
    // left: '50%',
    // position: 'absolute',
    position: 'absolute',
             top: '50%',
             left: '50%',
             transform: 'translate(-50%, -50%)',
             width: "40%",
             height:"80%",
             bgcolor: 'white',
             border: '2px solid white',
             borderRadius:"10px",
             boxShadow: 24,
             pt: 3,
             px: 3,
             pb: 1.5,
  }}
  >
  <Box sx={{
      width:"100%",
      height:"100%",
      backgroundColor:"white",
      borderRadius:"20px",
      display:"flex",
      justifyContent:"space-between",
      flexDirection:"column",
      // border:"1px solid red"

}}>

    <Box
    onClick={inverter}
    sx={{
        width:"100%",
        height:"5%",
        display:"flex",
        // paddingTop:"20px",
        flexDirection:"row",
        justifyContent:"end",
        // border:"1px solid green",
        cursor:"default"
        
    }}>
        X
    </Box>
    <Box
       sx={{
        display:"flex",
        justifyContent:"center",
        alignItems:"center",
        // border:"1px solid green",
        width:"100%",
        height:"70%"
    }}>
        <img style={{
          height:"100%",
          width:"100%",
          borderRadius:10}} src={Apiaddress +images[activeStep]}  />
    </Box>
    <Box
      sx={{
        display:"flex",
        justifyContent:"center",
        alignItems:"center",
        // border:"1px solid orange",
      }}
      >
        {`${activeStep} of ${images.length-1}`}
      </Box>
    <Box
        sx={{
            display:"flex",
            justifyContent:"center",
            alignItems:"center",
            // border:"1px solid black",
        }}
    >
     
    
    <MobileStepper
      variant="progress"
      steps={images.length}
      position="static"
      activeStep={activeStep}
      sx={{ width: "80%" }}
      nextButton={
          <Button size="small" onClick={()=>setindex(activeStep+1)} disabled={activeStep === images.length-1}>
          Next
          <KeyboardArrowRight />
          
        </Button>
      }
      backButton={
        <Button size="small" onClick={()=>setindex(activeStep-1)} disabled={activeStep === 0}>
           
            <KeyboardArrowLeft />
          Back
        </Button>
      }
      />
      </Box>
</Box>

 
</Box>  
</Modal>
  )
}
