import { Stack } from "@mui/system";
import { Typography,TextField } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import "../Signup.css";
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import { Apiaddress } from "../../../../utils/consts";
import {useForm} from "react-hook-form"
import axios from "axios";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

export const EmailPassNo = ({stepsno,setStepsno, signupdata, setSignupdata}) => {

    const navigate = useNavigate();
    const { register, handleSubmit, watch, formState: { errors } } = useForm();
    const[disable, setDisable] = useState(false)
    const [eerr, setEerr] = useState(false);



    const onSubmit = (data) => {
        setEerr(false)
        setSignupdata({...signupdata,email:data.email,phone:"+91"+data.phoneNumber,name:data.name})
        // return;
        // console.log(data)
        // link:"+Apiaddress+"/api/signup/
        if(data.name.trim()=="")return
        console.log("hello","hell"+data.name.trim());
        setDisable(true)
        axios.post(Apiaddress+"/api/signup/",{
            name:data.name.trim(),
            email:data.email,
            phoneNo:"+91"+data.phoneNumber
        })
        .then((res)=>{
            console.log("res:", res)
            setDisable(false)
            setStepsno(prev=>prev+1)
        })
        .catch((err)=>{
            console.log("res:", err)
            if(err.response.data.msg = "Email already exists")setEerr(true)
            setDisable(false)
        })
        
    };
    return (
        <>
                <form onSubmit={handleSubmit(onSubmit)}>

            <Box sx={{padding:"10px 46px", height:"70vh"}}>
                <Typography sx={{marginBottom:"20px"}}>Please Enter Your Name, Phone Number and Email Address to register</Typography>
                

                <Typography sx={{fontWeight: "500",fontSize: "18px",color:errors.name?"#da6061":"black"}}>Name</Typography>
                <TextField
                 sx={{
                     width:"45%",
                     margin:"10px 0px 10px 0px"
                    }}
                    type="text"
                 id="outlined-error-helper-text"
                 name="name"
                 fullWidth
                 {...register("name", {required:"Name is required."})}
                    error={Boolean(errors.name)}
                    helperText={errors.name?.message}
                    />

                <Typography sx={{fontWeight: "500",fontSize: "18px",color:errors.email?"#da6061":"black"}}>Email</Typography>
                <Typography sx={{fontSize:"0.8vw", color:errors.email?"#da6061":"gray"}}>An OTP will be send to this Email</Typography>
                <TextField
                 sx={{
                     width:"45%",
                     margin:"10px 0px 10px 0px"
                    }}
                    type="email"
                 id="outlined-error-helper-text"
                 name="email"
                 fullWidth
                 {...register("email", {required:"Email is required."})}
                    error={Boolean(errors.email)}
                    helperText={errors.email?.message}
                    />
                    {/* {eerr && 
                        <Typography sx={{fontWeight: "500",fontSize: "14px",marginBottom:"5px", color:"#da6061"}}>Email already exist</Typography>
                    } */}

                <Typography sx={{fontWeight: "500",fontSize: "18px",color:errors.phoneNumber?"#da6061":"black"}}>Phone Number</Typography>
                <Typography sx={{fontSize:"0.8vw", color:errors.phoneNumber?"#da6061":"gray"}}>An OTP will be send to this Number</Typography>
                <TextField
                type="tel"
                inputProps={{maxLength: 10}}
                 sx={{
                     width:"45%",
                     margin:"10px 0px 10px 0px"
                    }}
                 id="outlined-error-helper-text"
                 name="phoneNumber"
                 fullWidth
                 {...register("phoneNumber", {required:"PhoneNumber is required.",minLength:10,maxLength:10})}
                    error={Boolean(errors.phoneNumber)}
                    helperText={errors.phoneNumber?.message}
                    />
                    {eerr && 
                <Typography sx={{fontWeight: "500",fontSize: "14px",marginBottom:"5px", color:"#da6061"}}>User already exist! Please try to log in </Typography>
                    }


                {/* <Typography sx={{fontWeight: "500",fontSize: "18px"}}>Name</Typography>
                <input type="text" className="input_signup"/>
                
                <Typography sx={{fontWeight: "500",fontSize: "18px"}}>Email</Typography>
                <Typography sx={{fontSize:"0.8vw", color:"gray"}}>An OTP will be send to this email</Typography>
                <input type="text" className="input_signup"/>
                
                <Typography sx={{fontWeight: "500",fontSize: "18px"}}>Phone Number</Typography>
                <Typography sx={{fontSize:"0.8vw", color:"gray"}}>An OTP will be send to this Number</Typography>
            <input type="text" className="input_signup"/> */}
            </Box>

            <Stack direction="row" justifyContent="space-between" spacing={0} sx={{height:"10vh"}}>
                {/* {(stepsno>0 && stepsno<4) && <Button
                 className="univercel_btn_class"
                onClick={()=>setStepsno(prev=>prev-1)}
                variant="contained" startIcon={<KeyboardDoubleArrowLeftIcon/>} 
                color="primary" sx={{marginRight:"20px",width:"114px",height:"53px",textTransform: "none"}}>
                    Previous
                </Button>} */}
                <Button
                 className="univercel_btn_class"
                onClick={()=>{
                    navigate("/login")
                }}
                variant="contained" 
                color="primary" sx={{marginLeft:"20px",width:"114px",height:"53px",textTransform: "none"}}>
                    Log In 
                </Button>

               {stepsno<=3 && <Button 
                disabled={disable}
                className="univercel_btn_class"
                 type="submit"
                variant="contained" endIcon={<KeyboardDoubleArrowRightIcon/>} 
                color="primary" sx={{marginRight:"20px",height:"53px", textTransform: "none"}}>
                    Next 
                </Button>}
            </Stack>

           </form>
        </>
    );
}