import { Box, Radio, RadioGroup, TextField, Typography } from "@mui/material";
import React from "react";
import { BlueButton } from "../../../../../../utils/common/BlueButton";
import { removeAtIndex } from "../../../../../../utils/consts";

const RadioInput = (props: any) => {
  const [state, setstate] = React.useState({
    type: "radio", // normal || withtrailing || radio || check || doubletrailing
    orientation: "horizontal",
    name: "Enter Field Name",
    trailing: "Sq.ft",
    options: {
      selected: undefined,
      names: ["yes", "no"],
    },
  });
  return (
    <Box
      sx={{
        padding: "10px",
        display: "flex",
        alignItems: "flex-start",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          padding: "10px",
          justifyContent: "center",
          alignItems: "center",
          gap: "20px",
        }}
      >
        <TextField
          value={state.name}
          onChange={(e) => {
            setstate({ ...state, name: e.target.value });
          }}
        ></TextField>

        <div
          style={{
            display: "flex",
            flexDirection: state.orientation == "vertical" ? "column" : "row",
          }}
        >
          <RadioGroup
            onChange={(e) => {}}
            sx={{ display: "flex", gap: "20px" }}
          >
            {state.options.names.map((e: any, index: number) => {
              return (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    gap: "0.5rem",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Radio onSelect={(e: any) => {}}></Radio>
                  <TextField
                    value={e}
                    onChange={(e) => {
                      const nn = e.target.value;
                      let newnames = [...state.options.names];
                      newnames[index] = nn;
                      setstate({
                        ...state,
                        options: {
                          selected: undefined,
                          names: newnames,
                        },
                      });
                    }}
                  ></TextField>
                  <Typography
                    onClick={() => {
                      let newnames = [...state.options.names];

                      setstate({
                        ...state,
                        options: {
                          selected: undefined,
                          names: removeAtIndex({ arr: newnames, index: index }),
                        },
                      });
                    }}
                    sx={{
                      color: "#B0AFAF",
                      fontWeight: 500,
                      cursor: "pointer",
                      fontFamily: "Product Sans",
                    }}
                  >
                    Delete
                  </Typography>
                </div>
              );
            })}
          </RadioGroup>
        </div>
        <Box>
          <BlueButton
            onClick={() => {
              setstate({
                ...state,
                options: {
                  selected: undefined,
                  names: [...state.options.names, ""],
                },
              });
            }}
          >
            Add
          </BlueButton>
          <BlueButton
            onClick={() => {
              // console.log("LELODE", props, state);
              props.setData(state);
              props.setShow();
            }}
          >
            Save Field
          </BlueButton>
        </Box>
      </div>
    </Box>
  );
};

export default RadioInput;
