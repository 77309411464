import {
  Box,
  Button,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import { Stack } from "@mui/system";
import "./LoginUser.css";
import Lottie from "react-lottie";
import { useForm } from "react-hook-form";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Apiaddress } from "../../utils/consts";
import axios from "axios";
import { useState } from "react";
import animationData from "../../Lotties/lf30_editor_0xawoggq.json";
import { Visibility, VisibilityOff } from "@mui/icons-material";

export const SetupPassword = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();
  const [notSame, setNotSame] = useState(false);
  const navigate = useNavigate();
  const [show, setShow] = useState(false);

  const onSubmit = (data) => {
    setNotSame(false);
    if (data.password !== data.confirmPassword) {
      setNotSame(true);
      return;
    }
    console.log(key, data);

    let url = Apiaddress + "/api/role/setpassword/";
    // let url = Apiaddress+"/auth/forgot-password/"
    let headers = {
      headers: "application/json",
    };
    axios
      .post(url, {
        key,
        password: data.password,
      })
      .then((res) => {
        console.log("res: ", res.data);
        navigate("/login");
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  // searchParams.get("activation_key");
  const [key, setkey] = useState(searchParams.get("activation_key"));
  // console.log(searchParams.get("activation_key"))

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  return (
    <>
      <Box
        sx={{
          display: "flex",
        }}
      >
        <Stack
          direction="column"
          alignItems="stretch"
          justifyContent="space-between"
          sx={{
            width: { xs: "100%", md: "40%" },
            backgroundColor: "#F4F6FB",
            height: "100vh",
            textAlign: "left",
            padding: "24px 24px",
            boxSizing: "border-box",
          }}
        >
          <Stack
            direction="row"
            justifyContent="end"
            spacing={2}
            alignItems="center"
            sx={{ height: "10vh" }}
          >
            <Typography sx={{ fontFamily: "Product Sans", fontWeight: 400 }}>
              Having trouble?{" "}
              <span style={{ color: "#0085FF", fontWeight: 400 }}>
                Get Help
              </span>{" "}
            </Typography>
          </Stack>

          <Stack
            direction="row"
            justifyContent="center"
            spacing={2}
            alignItems="center"
            sx={{ height: { xs: "150vh", md: "90vh" } }}
          >
            <Box sx={{ padding: "10px", width: "75%" }}>
              <Typography
                sx={{
                  fontFamily: "Circular Std",
                  fontWeight: "600",
                  fontSize: { xs: "1.5em", md: "2em" },
                  lineHeight: "41px",
                }}
              >
                Set password for EnvisionNext
              </Typography>
              <Typography
                sx={{
                  // fontSize: "1vw",
                  fontSize: { xs: "10px", md: "14px" },
                  color: "gray",
                  margin: "0px 0px 30px 0px",
                  fontFamily: "Product Sans",
                }}
              >
                Protect Your Account: Set Your Login Password
              </Typography>
              <ul style={{ marginLeft: "-25px" }}>
                <li>
                  <Typography sx={{ fontSize: "14px" }}>
                    Password must be at least 8 characters long.
                  </Typography>
                </li>
                <li>
                  <Typography sx={{ fontSize: "14px" }}>
                    Password must contain at least one uppercase, one lowercase
                    and a number.
                  </Typography>
                </li>
              </ul>

              <form onSubmit={handleSubmit(onSubmit)}>
                <Typography
                  sx={{
                    fontWeight: "500",
                    fontSize: "18px",
                    color: errors.email?.message ? "#da6061" : "black",
                    fontFamily: "Product Sans",
                  }}
                >
                  Set Password
                </Typography>
                <TextField
                  sx={{
                    width: "100%",
                    margin: "10px 0px 20px 0px",
                    fontFamily: "Product Sans",
                  }}
                  type={show ? "text" : "password"}
                  id="outlined-error-helper-text"
                  name="password"
                  fullWidth
                  {...register("password", {
                    required: "Password is required.",
                    pattern: {
                      value: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/,
                      message:
                        "Password must contain at least one uppercase, one lowercase and a number ",
                    },
                    minLength: {
                      value: 8,
                      message: "Password must be at least 8 characters long",
                    },
                  })}
                  error={Boolean(errors.password)}
                  helperText={errors.password && errors.password.message}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment>
                        <IconButton onClick={() => setShow((p) => !p)}>
                          {show ? (
                            <VisibilityOff sx={{ color: "#99B2C6" }} />
                          ) : (
                            <Visibility sx={{ color: "#99B2C6" }} />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
                {/* {...register("password", {
                  required: "Password is required",
                  pattern: {
                    value:
                      /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/,
                    message:
                      "Password must contain at least one uppercase, one lowercase and a number ",
                  },
                  minLength: {
                    value: 8,
                    message: "Password must be at least 8 characters long",
                  },
                })}
 */}

                <Typography
                  sx={{
                    fontWeight: "500",
                    fontSize: "18px",
                    color: errors.email?.message ? "#da6061" : "black",
                    fontFamily: "Product Sans",
                  }}
                >
                  Confirm Password
                </Typography>
                <TextField
                  sx={{
                    width: "100%",
                    margin: "10px 0px 20px 0px",
                    fontFamily: "Product Sans",
                  }}
                  type="password"
                  id="outlined-error-helper-text"
                  name="confirmPassword"
                  fullWidth
                  {...register("confirmPassword", {
                    required: "Confirm Password is required.",
                    pattern: {
                      value: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/,
                      message:
                        "Password must contain at least one uppercase, one lowercase and a number ",
                    },
                    minLength: {
                      value: 8,
                      message: "Password must be at least 8 characters long",
                    },
                  })}
                  error={errors.confirmPassword ? true : false}
                  helperText={
                    errors.confirmPassword && errors.confirmPassword.message
                  }
                />

                {notSame && (
                  <Typography
                    sx={{
                      fontWeight: "500",
                      fontSize: "16px",
                      color: "#da6061",
                    }}
                  >
                    Password not match{" "}
                  </Typography>
                )}
                {/*<input type="text" className="input_login" placeholder="Example@gmail.com"/>

                <Typography sx={{fontWeight: "500",fontSize: "18px"}}>Password</Typography>
                <input type="text" className="input_login"/>

                <Typography sx={{fontWeight: "500",fontSize: "18px"}}>Confirm Password</Typography>
                <input type="text" className="input_login"/> */}

                <Stack direction="row" sx={{ marginTop: "20px" }}>
                  <Button
                    type="submit"
                    sx={{
                      background:
                        "linear-gradient(97.85deg, #FFAF2A 0.95%, #FFC666 100%)",
                      fontWeight: 500,
                      fontSize: "16px",
                      boxShadow: "rgba(242, 178, 73, 0.59) 0px 9px 12px",
                      borderRadius: "6px",
                      color: "#06386B",
                      textTransform: "none",
                      padding: "10px 64px",
                      fontFamily: "Product Sans",
                      "&:hover": {
                        background:
                          "linear-gradient(97.85deg, #FFAF2A 0.95%, #FFC666 100%)",
                        boxShadow: "rgba(242, 178, 73, 0.59) 10px 12px 16px",
                      },
                    }}
                  >
                    Proceed
                  </Button>
                </Stack>
              </form>
            </Box>
          </Stack>
        </Stack>

        <Stack
          direction="column"
          alignItems="center"
          justifyContent="center"
          sx={{
            width: "60%",
            height: "100vh",
            display: { xs: "none", md: "flex" },
          }}
        >
          <Lottie options={defaultOptions} height="auto" width="60%" />
        </Stack>
      </Box>
    </>
  );
};
