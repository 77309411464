import { Box } from '@mui/material'
import React from 'react'
import { Availability } from '../../../AllDirectories/Occupancy/Availability/Availability';
import { QuestionMarkChart } from './Histogram'
import VaccencyStatus from './VaccencyStatus'

export default function Statistics({update, setUpdate}) {
  const dat = [
    { name: 'Group A', value: 400, start:"#0085FF", end:"#65C5FF"},
    { name: 'Group B', value: 300, start:"#FF4267", end:"#FF7A93"},
    { name: 'Group C', value: 300, start:"#FFAF2A", end:"#FFC666"},
  ];
  const datMoney = [
    { name: 'Group A', value: 400, start:"#0085FF", end:"#65C5FF"},
    { name: 'Group B', value: 300, start:"#FF4267", end:"#FF7A93"},
    { name: 'Group C', value: 300, start:"#FFAF2A", end:"#FFC666"},
  ];
  return (
    <Box sx={{
        height:"auto",
        borderRadius:2,
        display:"flex",
        justifyContent:"space-between",
        alignItems:"center",
        flexDirection:"row",
        width:"55%"
    }}>
      {/* updated process bar */}
      <Availability update={update}/>
    {/* <VaccencyStatus setUpdate={setUpdate} update={update}/> */}
    {/* <QuestionMarkChart data={datMoney} datakey={"value"}/> */}
    </Box>
  )
}
