import {
  Box,
  Checkbox,
  Stack,
  Button,
  Accordion,
  AccordionSummary,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { Link, useNavigate } from "react-router-dom";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AccordionDetails from "@mui/material/AccordionDetails";
import { UserRole } from "../../../../UserRoles/UserRoles";
import KeyboardDoubleArrowLeftIcon from "@mui/icons-material/KeyboardDoubleArrowLeft";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import {
  ArrowDropDownIconSvg,
  ArrowDropUpIconSvg,
} from "../../../BuilderComponents/BuilderDasboard/assets/icons";
import Lottie from "react-lottie";
import animationData from "../../../../Lotties/NEW sin movs.json";

function StandAloneCustomTable({
  newRowData,
  projectid,
  delIconClick,
  updateBuyers,
  setTrigger,
  trigger,
  loading,
  CircularProgress,
  selectedUnits,
  setSelectedUnits,
  isStand,
  totalUnit,
  setStartUnit,
  startUnit,
  fetchfilter,
  matchText,
  curpage,
  setcurpage
}) {
  ////get user roles from local storage and assign
  let roles = JSON.parse(localStorage.getItem("user_roles"));
  let builderSelected = JSON.parse(localStorage.getItem("BuilderSelected"));
  let unit = roles["unit"];
  const navigate = useNavigate();

  const [pageRange, setPageRange] = useState([0, 6]);
  const [pages, setPages] = useState([]);
  const [NoPages, setNopages] = useState(null);
  
  console.log(NoPages);
  
  const nextClick = () => {
    if (curpage !== NoPages) {
      setStartUnit(startUnit + 7);
      setcurpage(curpage + 1);
      setSelectedUnits([])
    }
  };

  const prvClick = () => {
    if (curpage !== 1) {
      setStartUnit(startUnit - 7);
      setcurpage(curpage - 1);
      setSelectedUnits([]);
    }
  };

  useEffect(() => {
    if (newRowData) {
      if (totalUnit % 7 == 0) {
        let temp = totalUnit / 7;
        setNopages(temp);
      } else {
        let temp = Math.floor(totalUnit / 7 + 1);
        setNopages(temp);
      }
    }
  }, [newRowData]);

  const [curData, setcurData] = useState([]);

  useEffect(() => {
    if (newRowData) {
      setcurData(newRowData);
    }
  }, [newRowData]);

  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  let temp = [];
  const onCheck = (id) => {
    if (id === "selectAll") {
      for (let i = 0; i < newRowData?.length; i++) {
        temp.push(newRowData[i].id);
      }
      setSelectedUnits(temp);
    } else if (id == "removeAll") {
      temp = [];
      setSelectedUnits([]);
    } else {
      let array = selectedUnits;
      const index = array.indexOf(id);
      if (index > -1) {
        let temp1 = array.filter((ele) => {
          return ele != selectedUnits[index];
        });
        temp = temp1;
      } else {
        temp = [...selectedUnits, id];
      }
    }
    console.log("temp", temp);
    setSelectedUnits(temp);
  };

  const checkeOrNot = (id) => {
    const index = selectedUnits.indexOf(id);

    if (index > -1) {
      return true;
    } // only splice array when item is found
    return false;
  };
  const [selectedSortUp, setSelectedSortUp] = useState("");
  const [selectedSortDown, setSelectedSortDown] = useState("");

  const sortArr = (para) => {
    setSelectedSortUp(para);
    setSelectedSortDown("");
    function compare(a, b) {
      let fir = a[para];
      let sec = b[para];
      let fir1, sec1;

      fir1 = fir.toLowerCase();
      sec1 = sec.toLowerCase();

      console.log(fir1, "===", sec1);
      if (fir1 < sec1) {
        return -1;
      }
      if (fir1 > sec1) {
        return 1;
      }
      return 0;
    }

    let arr = [...curData];
    console.log(arr);
    arr.sort(compare);
    console.log(arr);

    setcurData(arr);
  };

  const sortArrDec = (para) => {
    setSelectedSortUp("");
    setSelectedSortDown(para);
    function compare(a, b) {
      let fir = a[para];
      let sec = b[para];
      let fir1, sec1;

      fir1 = fir.toLowerCase();
      sec1 = sec.toLowerCase();

      if (fir1 < sec1) {
        return -1;
      }
      if (fir1 > sec1) {
        return 1;
      }
      return 0;
    }
    let arr = [...curData];
    arr.sort(compare);
    arr.reverse();
    console.log(arr);
    setcurData([...arr]);
  };

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <>
      <Box
        sx={{
          mt: "20px",
          color: "black",
          width: "100%",
          height: "400px",
          boxSizing: "border-box",
          "&:hover": {
            boxShadow: "-6px 18px 15px rgba(0, 0, 0, 0.1)",
          },
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            background: "white",
            borderRadius: "5px",
            color: "#272D4E",
            fontWeight: "700",
            fontFamily: "Circular Std",
            fontSize: "18px",
            padding: "10px 20px",
            borderBottom: "1px solid #E9ECFF",
            borderRadius: "20px 20px 0 0",
          }}
        >

          <Box sx={{ width: "5%" }}>
            <Checkbox
              checked={
                newRowData?.length === selectedUnits.length ? true : false
              }
              onClick={() => {
                if (newRowData?.length === selectedUnits.length) {
                  onCheck("removeAll");
                } else {
                  onCheck("selectAll");
                }
              }}
            />
          </Box>
          <Box
            sx={{
              width: "10%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              margin: "0px 7.5px",
            }}
          >
            <Box>Unit</Box>
            {/* <Box sx={{ display: "flex", flexDirection: "column" }}>
              <ArrowDropUpIcon
                fontSize="large"
                sx={{ marginBottom: "-12px", cursor: "pointer" }}
              />
              <ArrowDropDownIcon
                fontSize="large"
                sx={{ marginTop: "-12px", cursor: "pointer" }}
              />
            </Box> */}
          </Box>
          <Box
            sx={{
              width: "15%",
              display: "flex",
              alignItems: "center",
              margin: "0px 7.5px",
            }}
          >
            <Box>Name</Box>
            <Box sx={{ display: "flex", flexDirection: "column", gap: "5px" }}>
              <Box
                sx={{
                  cursor: "pointer",
                  height: "10px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginLeft: "10px",
                }}
                onClick={() => sortArr("name")}
              >
                <ArrowDropUpIconSvg focus={selectedSortUp == "name"} />
              </Box>
              <Box
                sx={{
                  cursor: "pointer",
                  height: "10px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginLeft: "10px",
                }}
                onClick={() => sortArrDec("name")}
              >
                <ArrowDropDownIconSvg focus={selectedSortDown == "name"} />
              </Box>
            </Box>
          </Box>
          <Box
            sx={{
              width: "15%",
              display: "flex",
              alignItems: "center",
              margin: "0px 7.5px",
            }}
          >
            <Box>Type</Box>
            {/* <Box sx={{ display: "flex", flexDirection: "column" }}>
              <ArrowDropUpIcon
                fontSize="large"
                sx={{ marginBottom: "-12px", cursor: "pointer" }}
              />
              <ArrowDropDownIcon
                fontSize="large"
                sx={{ marginTop: "-12px", cursor: "pointer" }}
              />
            </Box> */}
          </Box>
          <Box
            sx={{
              width: "15%",
              display: "flex",
              alignItems: "center",
              margin: "0px 7.5px",
            }}
          >
            <Box>For</Box>
            {/* <Box sx={{ display: "flex", flexDirection: "column" }}>
              <ArrowDropUpIcon
                fontSize="large"
                sx={{ marginBottom: "-12px", cursor: "pointer" }}
              />
              <ArrowDropDownIcon
                fontSize="large"
                sx={{ marginTop: "-12px", cursor: "pointer" }}
              />
            </Box> */}
          </Box>
          <Box
            sx={{
              width: "15%",
              display: "flex",
              alignItems: "center",
              margin: "0px 7.5px",
            }}
          >
            <Box>City</Box>
            <Box sx={{ display: "flex", flexDirection: "column", gap: "5px" }}>
              <Box
                sx={{
                  cursor: "pointer",
                  height: "10px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginLeft: "10px",
                }}
                onClick={() => sortArr("city")}
              >
                <ArrowDropUpIconSvg focus={selectedSortUp == "city"} />{" "}
              </Box>
              <Box
                sx={{
                  cursor: "pointer",
                  height: "10px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginLeft: "10px",
                }}
                onClick={() => sortArrDec("city")}
              >
                <ArrowDropDownIconSvg focus={selectedSortDown == "city"} />{" "}
              </Box>
            </Box>
          </Box>
          <Box
            sx={{
              width: "15%",
              display: "flex",
              alignItems: "center",
              margin: "0px 7.5px",
            }}
          >
            <Box>Status</Box>
            {/* <Box sx={{ display: "flex", flexDirection: "column" }}>
              <ArrowDropUpIcon
                fontSize="large"
                sx={{ marginBottom: "-12px", cursor: "pointer" }}
              />
              <ArrowDropDownIcon
                fontSize="large"
                sx={{ marginTop: "-12px", cursor: "pointer" }}
              />
            </Box> */}
          </Box>
          {/* <Box sx={{ width: "10%", textAlign: "center" }}>Unit</Box>

          <Box sx={{ width: "15%" }}>Name</Box>

          <Box sx={{ width: "15%" }}>Type</Box>

          <Box sx={{ width: "15%" }}>For</Box>

          <Box sx={{ width: "15%" }}>City</Box>

          <Box sx={{ width: "15%" }}>Status</Box> */}

          <Box sx={{ width: "10%", textAlign: "center", padding: "0px 7.5px" }}>
            Actions
          </Box>
        </Box>
        {loading ? (
          <>
            <Box sx={{ textAlign: "center", marginTop: "50px" }}>
              <CircularProgress color="inherit" sx={{ color: "grey.500" }} />
            </Box>
          </>
        ) : newRowData.length == 0 ? (
          <Box sx={{ textAlign: "center", marginTop: "50px" }}>
            <Typography
              sx={{ fontSize: "25px", fontWeight: "500", color: "#B9B7D0" }}
            >
              <Lottie options={defaultOptions} height="20%" width="20%" />
              <Typography
                sx={{
                  color: "#B9B7D0",
                  fontSize: "18px",
                  fontWeight: "400",
                  marginTop: "-40px",
                }}
              >
               {matchText}
              </Typography>
            </Typography>  
          </Box>
        ) : (
          <>
            <Box sx={{ minHeight: "450px" }}>
              {curData &&
                curData.map((item, index) => {
                  // console.log(item);
                  if (index >= pageRange[0] && index <= pageRange[1]) {
                    return (
                      <Accordion
                        expanded={expanded === `panel${index}`}
                        onChange={handleChange(`panel${index}`)}
                        sx={{
                          boxShadow: "none",
                          border: "none",
                          width: "auto",
                        }}
                      >
                        <AccordionSummary
                          sx={{
                            backgroundColor: "#FFF",
                            padding: "0px 20px",
                            display: "flex",
                            alignItems: "center",
                            gap: "15px",
                            "&:hover": {
                              backgroundColor: "#F5F7FF",
                            },
                          }}
                        >
                          <Box
                            sx={{
                              width: "5%",
                              fontFamily: "Product Sans",
                              fontWeight: "400",
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            <Checkbox
                              checked={
                                selectedUnits.indexOf(item.id) > -1
                                  ? true
                                  : false
                              }
                              onClick={() => {
                                onCheck(item.id);
                              }}
                            />
                          </Box>

                          <Box  
                            sx={{
                              width: "7%",
                              fontFamily: "Product Sans",
                              fontWeight: "400",
                              justifyContent: "center",
                              display: "flex",
                              alignItems: "center",
                              margin: "0px 7.5px",
                              // border:"1px solid red",
                              paddingRight:"30px"
                            }}
                          >
                            <Box sx={{fontFamily: "Product Sans",fontWeight: "400"}}>{item.id}</Box>
                          </Box>

                          <Box
                            sx={{
                              width: "15%",
                              fontFamily: "Product Sans",
                              fontWeight: "400",
                              display: "flex",
                              alignItems: "center",
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            <Box sx={{fontFamily: "Product Sans",fontWeight: "400"}}>{item.name}</Box>
                          </Box>

                          <Box
                            sx={{
                              width: "15%",
                              fontFamily: "Product Sans",
                              fontWeight: "400",
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            <Box sx={{fontFamily: "Product Sans",fontWeight: "400"}}>{item.type}</Box>
                          </Box>

                          <Box
                            sx={{
                              width: "15%",
                              fontFamily: "Product Sans",
                              fontWeight: "400",
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            <Box sx={{fontFamily: "Product Sans",fontWeight: "400"}}>{item.for}</Box>
                          </Box>

                          <Box
                            sx={{
                              width: "15%",
                              fontFamily: "Product Sans",
                              fontWeight: "400",
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            <Box sx={{fontFamily: "Product Sans",fontWeight: "400"}}>{item.city}</Box>
                          </Box>

                          <Box
                            sx={{
                              width: "15%",
                              fontFamily: "Product Sans",
                              fontWeight: "400",
                              display: "flex",
                              alignItems: "center",  
                            }}
                          >
                            <Box sx={{fontFamily: "Product Sans",fontWeight: "400"}}>{item.status}</Box>
                          </Box>

                          <Box  
                            sx={{
                              width: "10%",
                              fontFamily: "Product Sans",
                              fontWeight: "400",
                              textAlign: "center",
                              mt: "1%",
                            }}
                          >
                            <ExpandMoreIcon />
                          </Box>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Typography>
                            <Box
                              sx={{
                                color: "#969BA0",
                                fontSize: "18px",
                                padding: "0 40px",
                                marginTop: "20px",
                                fontFamily: "Circular Std",
                              }}
                            >
                              The Details Review of Unit
                            </Box>
                            <Box
                              sx={{
                                display: "grid",
                                gridTemplateColumns: "1fr 1fr 1fr",
                                padding: "0 40px",
                                marginTop: "20px",
                                rowGap: "30px",
                              }}
                            >
                              <Box
                                sx={{
                                  display: "flex",
                                  gap: "5px",
                                  fontFamily: "Product Sans",
                                }}
                              >
                                <Box sx={{ fonFamily: "Product Sans" }}>
                                  Unit No :{" "}
                                </Box>{" "}
                                <Box sx={{ color: "#6F6F6F" }}>{item?.id}</Box>
                              </Box>
                              <Box
                                sx={{
                                  display: "flex",
                                  gap: "5px",
                                  fontFamily: "Product Sans",
                                }}
                              >
                                <Box>Unit Name : </Box>
                                <Box sx={{ color: "#6F6F6F" }}>
                                  {item?.name}
                                </Box>
                              </Box>
                              <Box
                                sx={{
                                  display: "flex",
                                  gap: "5px",
                                  fontFamily: "Product Sans",
                                }}
                              >
                                <Box>Unit Type : </Box>
                                <Box sx={{ color: "#6F6F6F" }}>
                                  {item?.type}
                                </Box>
                              </Box>
                              <Box
                                sx={{
                                  display: "flex",
                                  gap: "5px",
                                  fontFamily: "Product Sans",
                                }}
                              >
                                <Box>Available For : </Box>
                                <Box sx={{ color: "#6F6F6F" }}>{item?.for}</Box>
                              </Box>
                              <Box
                                sx={{
                                  display: "flex",
                                  gap: "5px",
                                  fontFamily: "Product Sans",
                                }}
                              >
                                <Box>City : </Box>
                                <Box sx={{ color: "#6F6F6F" }}>
                                  {item?.city}
                                </Box>
                              </Box>
                              <Box
                                sx={{
                                  display: "flex",
                                  gap: "5px",
                                  fontFamily: "Product Sans",
                                }}
                              >
                                <Box>Status : </Box>
                                <Box sx={{ color: "#6F6F6F" }}>
                                  {item?.status}
                                </Box>
                              </Box>
                            </Box>
                            <Box
                              sx={{
                                display: "flex",
                                justifyContent: "right",
                              }}
                            >
                              <Box
                                sx={{
                                  width: "45vw",
                                  display: "grid",
                                  placeItems: "center",
                                  height: "10vh",
                                  marginRight: "30px",
                                }}
                              >
                                <Stack
                                  direction="row"
                                  justifyContent="end"
                                  sx={{ width: "100%", marginTop: "30px" }}
                                >
                                  <Button
                                    className="univercel_btn_class"
                                    variant="contained"
                                    sx={{
                                      width: "200px",
                                    }}
                                    onClick={() => {
                                      navigate(
                                        `/Standalone-channelpartner/unit/${item.id}`,
                                        {
                                          state: {
                                            unit: item,
                                            projectid: projectid ?? null,
                                          },
                                        }
                                      );
                                    }}
                                  >
                                    Unit Overview
                                  </Button>
                                  {/* <Link to="/unit-directory-w-sb/edit" state={item.id}> */}

                                  <Button
                                    className="univercel_btn_class"
                                    variant="contained"
                                    sx={{
                                      width: "100px",
                                      marginLeft: "20px",
                                    }}
                                    onClick={() => {
                                      navigate(
                                        `/channelpartner/unit/edit/${item.id}`,
                                        {
                                          state: {
                                            unit: item,
                                            isStand: isStand,
                                          },
                                        }
                                      );
                                    }}
                                  >
                                    Edit
                                  </Button>

                                  {/* </Link> */}
                                  {/* <Button
                                variant="contained"
                                sx={{
                                  marginLeft: "20px",
                                  width: "100px",
                                  background:
                                    "linear-gradient(90deg, #0F5DAE 0%, #009DCF 100.33%)",
                                }}
                                onClick={() => delIconClick(item)}
                              >
                                Delete
                              </Button> */}
                                </Stack>
                              </Box>
                            </Box>
                          </Typography>
                        </AccordionDetails>
                      </Accordion>
                    );
                  }
                })}
            </Box>
            <Box sx={{ margin: "50px 0px 20px 0px" }}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "right",
                  marginRight: "20px",
                  mt: 3,
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "right",
                    marginRight: "20px",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      placeItems: "center",
                      margin: " 0 10px",
                      borderRadius: "5px",
                    }}
                  >
                    <Typography sx={{}}>Page</Typography>
                    <Typography
                      sx={{
                        background: `white`,
                        color: "black",
                        width: "60px",
                        height: "30px",
                        borderRadius: "4px",
                        margin: "0 10px",
                        display: "grid",
                        placeItems: "center",
                        border: "1px solid #2979F2",
                      }}
                    >
                      {curpage}
                    </Typography>
                    <Typography
                      sx={{
                        color: "black",
                        width: "30px",
                        borderRadius: "5px",
                        margin: "0 0px",
                        display: "grid",
                        placeItems: "center",
                      }}
                    >
                      of
                    </Typography>
                    <Typography
                      sx={{
                        color: "black",
                        margin: "0px 10px 0px 0px",
                        display: "grid",
                        placeItems: "center",
                      }}
                    >
                      {NoPages}
                    </Typography>
                  </Box>

                  {curData.length != 0 && (
                    <Button
                      variant="contained"
                      className="paginationButton"
                      sx={{ marginRight: "10px" }}
                      disabled={curpage == 1}
                      onClick={prvClick}
                    >
                      <KeyboardDoubleArrowLeftIcon fontSize="medium" />
                    </Button>
                  )}

                  {curData.length != 0 && (
                    <Button
                      variant="contained"
                      className="paginationButton"
                      onClick={nextClick}
                      disabled={curpage == NoPages}
                    >
                      <KeyboardDoubleArrowRightIcon fontSize="medium" />
                    </Button>
                  )}
                </Box>
              </Box>
            </Box>
          </>
        )}
      </Box>
    </>
  );
}

export default StandAloneCustomTable;
