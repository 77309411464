const KIsDebug = process.env.NODE_ENV == "development";
const Apiaddress = KIsDebug ? "http://139.59.70.194" : "https://api.EnvisionNext.in";
// "http://192.168.1.166:8000";
// "http://139.59.70.194/";

// axios.post("/api/signup/",{

const basicheaders = {
        "Content-Type": "application/json"
}

const api = (s) => (Apiaddress + s)

const net = (url) => (Apiaddress + "/api/" + url)

const contains = ({ arr, obj }) => {
        let ans = false
        let index = 0
        for (let i = 0; i < arr.length; i++) {
                if (arr[i] == obj) {
                        ans = true;
                        index = i
                        break;
                }
        }
        return (ans, index);


}
export const removeAtIndex = ({ arr, index }) => {
        let brr = []
        for (let i = 0; i < arr.length; i++) {
                if (index != i) {
                        brr.push(arr[i])
                }
        }
        return brr
}


export { basicheaders, Apiaddress, api, contains, net }