import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import {
  CitySelector,
  StateSelector,
} from "volkeno-react-country-state-city";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import SendIcon from "@mui/icons-material/Send";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Button,
  Checkbox,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  Modal,
  OutlinedInput,
  Select,
  TextField,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";

import AddIcon from "@mui/icons-material/Add";
import axios from "axios";
import { Apiaddress } from "../../../utils/consts";
import { countryDetails } from "../../../utils/contryDetails";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const names = ["Aditya Birla Appartments", "Tata Farms"];

function RightSidebarOD({
  setTabledata,
  curselectedRows,
  settrigger,
  trigger,
  updateInfo,
  toggleDrawerAdd,
  setStateadd,
}) {
  const [expanded, setExpanded] = useState("panel1");
  const [disabelAccrodian, setdisabelAccrodian] = useState(
    curselectedRows ? "true" : "false"
  );
  const [selectValue, setSelectValue] = useState("High");
  const [setProjects, setsetProjects] = React.useState([]);

  const handleChangeLeadProject = (event) => {
    const {
      target: { value },
    } = event;
    setsetProjects(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };
  const [selectArr, setselectArr] = useState([
    {
      value: "High",
      label: "High",
    },
    {
      value: "low",
      label: "low",
    },
  ]);

  const createOpt = () => { };

  const handleChange = (i) => {
    setExpanded(i);
  };

  const [colors, setColors] = useState([
    { name: "Ajay", clr: "green" },
    { name: " Tarun", clr: " yellow" },
    { name: "  Ranbir", clr: "red" },
  ]);
  const [leadname, setLeadName] = useState("");
  const [leadclr, setLeadclr] = useState("");

  const addClr = () => {
    const obj = { name: leadname, clr: leadclr };
    setColors([...colors, obj]);
    handleClose();
  };

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [name, setName] = useState("");
  const [state, setState] = useState("");
  const [city, setCity] = useState("");
  const [pincode, setPincode] = useState("");

  const [orgId, setOrgID] = useState(null);

  const addprofiledata = async () => {
    console.log(pincode,updateInfo)
    if (updateInfo && pincode.length == 6) {
      try {
        const obj = {
          org_id: updateInfo.org_id,
          org_name: name,
          city: city,
          zip_code: pincode,
          state: state,
        };
        const res = await axios.patch(Apiaddress + "/api/org/", obj);
        settrigger(!trigger);
        setName("");
        setState("");
        setCity("");
        setPincode("");
        setStateadd(false);
        setchange(!change)
        console.log(res);
      } catch (err) {
        console.log(err);
      }
    } else if (pincode.length == 6) {
      try {
        const obj = {
          org_name: name,
          city: city,
          zip_code: pincode,
          state: state,
        };
        const res = await axios.post(Apiaddress + "/api/org/", obj);
        console.log(res);
        setName("");
        setState("");
        setCity("");
        setPincode("");
        setStateadd(false);
        setchange(!change)
        if (res.data)
          if (res.data.org) if (res.data.org.id) setOrgID(res.data.org.id);
      } catch (err) {
        console.log(err);
      }
    }
  };

  // useEffect(() => {
  //   if (orgId) {
  //     console.log(orgId);
  //   }
  // }, [orgId]);

  const [token, setToken] = useState(null);

  useEffect(() => {
    setToken(localStorage.getItem("crm_token"));
  }, []);

  const addOrgtoProfile = async () => {
    try {
      const headers = {
        Authorization: `JWT ${token}`,
      };
      const obj = {
        org_id: orgId,
      };

      const res = await axios.post(
        Apiaddress + "/api/addorgtoprofile/",
        obj,
        { headers: headers }
      );

      settrigger(!trigger);

      console.log(res);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (token && orgId) {
      addOrgtoProfile();
    }
  }, [token, orgId]);

  useEffect(() => {
    if (updateInfo) {
      console.log(updateInfo);
      setName(updateInfo.Name);
      setState(updateInfo.State);
      setCity(updateInfo.City);
      setPincode(updateInfo.Pincode.toString());
    }
  }, [updateInfo]);




  //====================//===============================//
  //select state and city from dropdown
  console.log("country detils:=======>", countryDetails)
  const [location, setLocation] = useState({});
 
  const [change,setchange] = useState(false)
  useEffect(() => {
    //for set country
    setLocation(countryDetails);
    console.log("hello")
  }, [change]);


  return (
    <Box
      sx={{
        width: "420px",
        height: "80vh",
        marginTop: "-25px",
        marginRight: "25px",
        padding: "10px",
      }}
    >
      <Box
        sx={{
          position: "relative",
        }}
      >
        <Box
          sx={{
            position: "absolute",
            top: "0px",
            right: "0px",
            background: "white",
            padding: "10px",
            height: "105vh",
            width: "400px",
            marginTop: "20px",
          }}
        >
          {" "}
          <Box>
            <Box
            onClick={toggleDrawerAdd(false)}
            sx={{ display: "flex", cursor: "pointer" }}>
              <Box>
                <ArrowBackIosIcon
                  sx={{
                    fontSize: "small",
                    marginBottom: "-2px",
                  }}
                />
              </Box>
              <Box>{!updateInfo? "Add":"Update"} Organization</Box>
            </Box>
            <Box
              sx={{
                display: "grid",
                alignItems: "center",
                height: "100vh",
              }}
            >
              <Box>
                <Accordion
                  expanded={expanded === "panel1"}
                  onClick={() => handleChange("panel1")}
                >
                  <AccordionSummary
                    sx={{
                      background: `${expanded === "panel1" ? "#FFAF2A" : "white"
                        }`,
                      borderRadius: "10px",
                    }}
                    // expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography sx={{ color: "white" }}>
                      Organization Profile{" "}
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        width: "400px",
                        alignItems:"center"
                        // margin: "10px 0",
                      }}
                    >
                      <Box>Name</Box>
                      <Box sx={{width:"60%"}}>
                        <TextField
                          value={name}
                          id="outlined-basic"
                          onChange={(e) => setName(e.target.value)}
                        />
                      </Box>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        width: "400px",
                        alignItems:"center"
                        // margin: "10px 0",
                      }}
                    >
                      <Box>State</Box>
                      <Box sx={{width:"56%", marginRight:"17px"}}>
                        {/* <TextField
                          value={state}
                          id="outlined-basic"
                          onChange={(e) => setState(e.target.value)}
                        /> */}
                         <StateSelector
                  country={location.country}
                  name="state"
                  value={location.state}
                  countryPlaceholder="Select a country first"
                  onChange={(e) => {
                    console.log(e);
                    // setValue("country", e.name);

                    // setFormState({ ...formState, state: e.name });
                    setState(e.name)
                    setLocation({ ...location, state: e });
                  }}
                />
                      </Box>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        width: "400px",
                        alignItems:"center"
                        // margin: "10px 0",
                      }}
                    >
                      <Box>City</Box>
                      <Box sx={{width:"56%", marginRight:"17px"}}>
                        {/* <TextField
                          value={city}
                          id="outlined-basic"
                          onChange={(e) => setCity(e.target.value)}
                        /> */}
                          <CitySelector
                  state={location.state}
                  name="city"
                  value={location.city}
                  statePlaceholder="Select a state first"
                  onChange={(e) => {
                    console.log(e);
                    // setFormState({ ...formState, city: e.name });
                    setCity(e.name)
                    setLocation({ ...location, city: e });
                  }}
                />
                      </Box>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        width: "400px",
                        alignItems:"center",
                        margin: "10px 0",
                      }}
                    >
                      <Box>Pincode</Box>
                      {/* <Box sx={{width:"60%"}}> */}
                      <Box
                        sx={{
                          // boxShadow: `${pincode.length != 6 ? "red 0 0 10px" : ""
                          //   }`,
                          // borderRadius: "12px",
                          marginRight:"17px",
                          width:"60", 
                        }}
                      >
                        <TextField
                        type="number" inputProps={{ min: 0 , max:999999}}
                        sx={{width:"220px"}}
                      //   sx={{ boxShadow: `${pincode.length != 6 ? "red 0 0 10px" : ""
                      // }`,}}
                          value={pincode}
                          id="outlined-basic"
                          onChange={(e) => {
                            if(e.target.value>=999999)return;
                            setPincode(e.target.value)
                          }}
                        />
                      </Box>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        width: "400px",
                        margin: "10px 0",
                      }}
                    >
                      <Box></Box>
                      <Box  >
                        {!updateInfo && (
                          <Button
                          value={false}
                            startIcon={<SendIcon />}
                            onClick={(e)=>{
                              addprofiledata();
                            }}
                            className="univercel_btn_class"
                            sx={{
                              color: "#FFFFFF",
                              textTransform: "none",
                            }}
                          >
                            Submit
                          </Button>
                        )}
                        {updateInfo && (
                          <Button
                            startIcon={<SendIcon />}
                            onClick={addprofiledata}
                            className="univercel_btn_class"
                            sx={{
                              color: "#FFFFFF",
                              textTransform: "none",
                            }}
                          >
                            Update
                          </Button>
                        )}
                      </Box>
                    </Box>
                  </AccordionDetails>
                </Accordion>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export default RightSidebarOD;
