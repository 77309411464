import { Button, Typography,TextField } from "@mui/material"
import { borderRadius, Stack } from "@mui/system"
import { useState } from "react"
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';

import Box from "@mui/material/Box";
import {
    CitySelector,
    CountrySelector,
    StateSelector,
  } from "volkeno-react-country-state-city";
  
import {useForm} from "react-hook-form"
import Divider from '@mui/material/Divider';

export const OrganizationList = ({organizationlist ,ind,setorganizationList,orgData,allLocations})=>{
    const [showDetails, setShowDetails] = useState(false);

    const { register, handleSubmit, watch, formState: { errors } } = useForm();
    const [state,setState] = useState(organizationlist[ind].state)
    const [city,setCity] = useState(organizationlist[ind].city)
    const [location, setLocation] = useState({
      
        ...allLocations[ind]
    });

    const [orgname,setOrgname] = useState(organizationlist[ind].orgname)
    const [orgstate,setOrgstate] = useState(organizationlist[ind].state)
    const [orgcity,setOrgcity] = useState(organizationlist[ind].city)
    const [orgpin,setOrgpin] = useState(organizationlist[ind].zipcode)
    const [orgwebUrl,setOrgwebUrl] = useState(organizationlist[ind].website_address)

    const onChangeValue =(type,i,value)=>{
        let list = organizationlist

        if(type=="orgname")list[ind].orgname=value
        if(type=="orgpin")list[ind].zipcode=value
        if(type=="orgwebUrl")list[ind].website_address=value
        if(type=="orgstate")list[ind].state=value
        if(type=="orgcity")list[ind].city=value
       
        

        setorganizationList(list)
    } 
   
    return (
        <>
        <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={4}
         sx={{
            height:"50px",
            backgroundColor:"#ffa946",
            borderRadius:"10px",
            marginBottom:"10px",
            padding:"0px 23px 0px 23px"
         }}
         onClick={()=>setShowDetails(!showDetails)}
        >
            <Typography>{orgname}</Typography>

            {showDetails ?  <ArrowDropUpIcon />:<ArrowDropDownIcon/>}
        </Stack>
       {showDetails ? <Stack>


         <Box >

        <Stack direction="row" justifyContent="right" sx={{padding:"20px"}}>
            <Button onClick={()=>{
            let arr =  organizationlist.filter((e,i)=>{
                return i!==ind
              })
              setorganizationList([...arr])
            }}>Remove</Button>
        </Stack>

         {/* <Typography sx={{marginBottom:"20px"}}>Please Provide the Organization Names</Typography> */}

<Typography sx={{fontWeight: "500",fontSize: "18px"}}>Organization Name</Typography>
{/* <input type="text" className="input_organization"/> */}
<TextField
     sx={{
         width:"75%",
         margin:"10px 0px 10px 0px"
        }}
        type="text"
     id="outlined-error-helper-text"
     name="organizationName"
     fullWidth
     value={orgname}
     onChange={(e)=>{
        setOrgname(e.target.value)
        onChangeValue("orgname",ind,e.target.value)}}
    //  {...register("organizationName", {required:"Organization Name is required."})}
    //     error={Boolean(errors.organizationName)}
    //     helperText={errors.organizationName?.message}
        />


<Stack direction="row" spacing={4} sx={{marginTop:"8px",width:"75%"}} >
    <Box className="box_row">
        <Typography sx={{fontWeight: "500",fontSize: "18px"}}>state</Typography>

        <StateSelector
  country={location.country}
  name="state"
  value={location.state || state}
  countryPlaceholder="Select a country first"
  onChange={(e) => {
    // console.log(e);
    // setValue("country", e.name);
    setState(e.name)
    setLocation({ ...location, state: e });
  }}
/>
    </Box>
    <Box className="box_row">
        <Typography sx={{fontWeight: "500",fontSize: "18px"}}>city</Typography>
        
        <CitySelector
  state={location.state}
  name="city"
  value={location.city || city}
  statePlaceholder="Select a state first"
  onChange={(e) => {
    setCity(e.name)
    setLocation({ ...location, city: e });
  }}
/>
    </Box>
    <Box className="box_row">
        <Typography sx={{fontWeight: "500",fontSize: "18px"}}>Pincode</Typography>
        {/* <input type="text" className="input_organization_row"/> */}
        <TextField
     sx={{
        //  width:"75%",
         margin:"10px 0px 10px 0px"
        }}
      type="number"
     id="outlined-error-helper-text"
     name="pincode"
     inputProps={{ maxLength: 6 }}
     fullWidth
     value={orgpin}
     onChange={(e)=>{
        setOrgpin(e.target.value)
        onChangeValue("orgpin",ind,e.target.value)}}
    //  {...register("pincode", {required:"pincode is required"})}
    //     error={Boolean(errors.pincode)}
    //     helperText={errors.pincode?.message}
        />
    </Box>
</Stack>

<Typography sx={{fontWeight: "500",fontSize: "18px",marginTop:"8px"}}>Website Address</Typography>
{/* <input type="text" className="input_signup"/> */}
<TextField
     sx={{
         width:"50%",
         margin:"10px 0px 10px 0px"
        }}
        type="text"
     id="outlined-error-helper-text"
     name="webAddress"
     fullWidth
     value={orgwebUrl}
     onChange={(e)=>{
        setOrgwebUrl(e.target.value)
        onChangeValue("orgwebUrl",ind,e.target.value)}}
    //  {...register("webAddress", {required:"Web Address is required."})}
    //     error={Boolean(errors.webAddress)}
    //     helperText={errors.webAddress?.message}
        />

         </Box>
        
          
        </Stack>:null}

        
        </>
    )
}