import React, { useState } from 'react';
import { Box, Button, Stack, TextField, Typography } from "@mui/material";
import DoneIcon from '@mui/icons-material/Done';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import Pagination from '@mui/material/Pagination';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';


import { Navbar } from "../DashboardComponents/Navbar"
import { Sidebar } from "../DashboardComponents/Sidebar"
import { useNavigate } from "react-router-dom";

export const  ConfirmProject_steps = ({building_name,wing_name,floor_name})=>{
    return(
        <>
        <Stack direction="row" spacing={1.5}>
        <Stack direction="row" alignItems="center" spacing={1.5} sx={{margin:"70px 0px 70px 0px"}}>
           <Box  sx={{width:"29px", height:"29px",borderRadius:"50%", backgroundColor:"#FFA946", display:"flex", justifyContent:"center", alignItems:"center"}}>
              <DoneIcon sx={{color:"#fff", fontSize:"20px"}}/>
            </Box>
            <Typography sx={{fontSize:"18px", fontWeight:"500"}}>Project home</Typography>
          </Stack>
          {building_name &&
          <Stack direction="row" alignItems="center" spacing={1.5} sx={{margin:"70px 0px 70px 0px"}}>
           <Box  sx={{width:"29px", height:"29px",borderRadius:"50%", backgroundColor:"#FFA946", display:"flex", justifyContent:"center", alignItems:"center"}}>
              <DoneIcon sx={{color:"#fff", fontSize:"20px"}}/>
            </Box>
            <Typography sx={{fontSize:"18px", fontWeight:"500"}}>{building_name}</Typography>
          </Stack>
          }

          {wing_name && 
          <Stack direction="row" alignItems="center" spacing={1.5} sx={{margin:"70px 0px 70px 0px"}}>
           <Box  sx={{width:"29px", height:"29px",borderRadius:"50%", backgroundColor:"#FFA946", display:"flex", justifyContent:"center", alignItems:"center"}}>
              <DoneIcon sx={{color:"#fff", fontSize:"20px"}}/>
            </Box>
            <Typography sx={{fontSize:"18px", fontWeight:"500"}}>{wing_name}</Typography>
          </Stack>
          }
          {floor_name && 
          <Stack direction="row" alignItems="center" spacing={1.5} sx={{margin:"70px 0px 70px 0px"}}>
           <Box  sx={{width:"29px", height:"29px",borderRadius:"50%", backgroundColor:"#FFA946", display:"flex", justifyContent:"center", alignItems:"center"}}>
              <DoneIcon sx={{color:"#fff", fontSize:"20px"}}/>
            </Box>
            <Typography sx={{fontSize:"18px", fontWeight:"500"}}>{floor_name}</Typography>
          </Stack>
          }

          </Stack>
        </>
    )
}