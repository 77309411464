import { Apiaddress } from "../../../utils/consts";

enum AuthState {
    loggedin,
    notLoggedin,
    tokenExpired,
}

class AuthService {
    state = AuthState.notLoggedin;

    ServerUrl = Apiaddress

    token: String | undefined;


    constructor() {
        this.token = this.retriveTokenFromLocalStorageAndUpdateState()

    }

    retriveTokenFromLocalStorageAndUpdateState() {
        const item = localStorage.getItem("crm_token")

        if (item) {
            return item;
        }
    }


    logout() {

    }

    setPassword() {

    }

    getAuthHeaders(ismultipartformdata?: boolean) {
        return {
            'Content-Type': ismultipartformdata ? "multipart/form-data" : 'application/json',
            "Authorization": "JWT " + this.token
        };
    }

    getCurrentBuyerId() {
        const buyer: number = JSON.parse(localStorage.getItem("crm_user") ?? '')['id']
        return buyer// buyer
    }

    getCPID() {
        const cpid = JSON.parse(localStorage.getItem("cpDetails") ?? '')["id"]
        return cpid;
    }


    getToken() {
        return this.token;
    }

    async makeAuthenticatedPostRequest(url: any, { JsonBody, ismultipart }: { JsonBody: any, ismultipart?: boolean }) {
        return await fetch(
            url,
            {
                method: 'POST',
                headers: { ...this.getAuthHeaders(ismultipart), },
                body: ismultipart ? JsonBody : JSON.stringify(JsonBody),

            }
        )

    }
    async makeUnAuthenticatedPostRequest(url: any, { JsonBody, ismultipart }: { JsonBody: any, ismultipart?: boolean }) {
        return await fetch(
            url,
            {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: ismultipart ? JsonBody : JSON.stringify(JsonBody),

            }
        )

    }

    async makeAuthenticatedPatchRequest(url: any, { JsonBody, ismultipart }: { JsonBody: any, ismultipart?: boolean }) {
        return await fetch(
            url,
            {
                method: 'PATCH',
                headers: { ...this.getAuthHeaders(ismultipart), },
                body: ismultipart ? JsonBody : JSON.stringify(JsonBody),

            }
        )

    }

    async makeUnAuthenticatedPatchRequest(url: any, { JsonBody, ismultipart }: { JsonBody: any, ismultipart?: boolean }) {
        return await fetch(
            url,
            {
                headers: {
                    'Content-Type': 'application/json',
                },
                method: 'PATCH',
                body: ismultipart ? JsonBody : JSON.stringify(JsonBody),

            }
        )

    }


    async authenticatedDelete(url: any) {
        return await fetch(
            url,
            {
                method: 'DELETE',
                headers: { ...this.getAuthHeaders() },


            }
        )
    }


    async makeAuthenticatedGetRequest(url: any) {
        return await fetch(
            url,
            {
                method: 'GET',
                headers: { ...this.getAuthHeaders() },
            }
        )

    }
    async makeUnAuthenticatedGetRequest(url: any) {
        return await fetch(
            url,
            {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
            }
        )

    }


}

export { AuthService, AuthState };