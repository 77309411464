import { Box, Typography } from '@mui/material'
import React from 'react'

function AssociatedProjectLead() {
  return (
    <div>
        <Box sx={{textAlign:"center",marginTop:"100px"}}>
            <Typography sx={{fontSize:"25px",fontWeight:"500",color:"#B9B7D0",}}>No Leads Found</Typography>
        </Box>
    </div>
  )
}

export default AssociatedProjectLead