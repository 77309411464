import React, { useEffect, useState } from 'react';
import { Box, Button, Stack, TextField, Typography } from "@mui/material";
import DoneIcon from '@mui/icons-material/Done';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import Pagination from '@mui/material/Pagination';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';
import axios from 'axios';
import { Link, useLocation, useNavigate } from "react-router-dom";

import { MainSidebar } from '../ProjectDashboard/DashboardComponents/MainSidebar';
import { Navbar } from '../ProjectDashboard/DashboardComponents/Navbar';
import { Apiaddress } from '../../../utils/consts';
import { BackeToOverviewBtn } from '../ProjectDashboard/CreateProject/BackeToOverviewBtn';


function EditProjectUnit_Bedroom() {

  ////get data from previous page 
   const location = useLocation();
   const data = location.state.state;
   const floorData = location.state.current_data
 
   // console.log("preve data =>.>",data)
   
   
   let [current_data, setCurrentData] = useState({})
   const [bedroomData,setbedroomData]=useState([]);

  const navigate = useNavigate();


  // console.log(bedroomData);
  // console.log(current_data);
  
  const addbedroom=()=>{
    let temp=bedroomData.length+1;
    let to_insert={
      bedroom_no:temp,
      length:"",
      breadth:""
    }
    setbedroomData([...bedroomData,to_insert]);
  }
 
  const updatenumber=(newbedroomData,index)=>{
    for(let i=index;i<newbedroomData.length;i++){
      newbedroomData[i].bedroom_no=newbedroomData[i].bedroom_no-1;
    }
    // console.log(newbedroomData);
    setbedroomData(newbedroomData);
  }

  useEffect(()=>{

   const uniturl = Apiaddress+"/api/projects/unittemplate/?unit_template_id="+data.unit_id;
   console.log(data);
   axios.get(uniturl)
   .then((res)=>{
        const data = res?.data
        console.log("updated layout  data=>>>>>>>.",data?.unit_template[0].layout_data)
        setbedroomData(data.unit_template[0].layout_data.bedroomData)
        setCurrentData(data?.unit_template[0].layout_data)
     })
     .catch((err)=>{
       console.log(err)
     })
 

  },[]);

  //function for create unit Templates
  const createBedroomTemplate = ()=>{

      setCurrentData({...current_data,bedroomData});
      console.log({...current_data,bedroomData}); 
  //  console.log(unit); 
  //  setunitTemplates([...unitTemplates,unit])
  //  if(unit.unit_id=="" || unit.unit_name=="" || unit.unit_no=="") return;
    let url = Apiaddress+"/api/projects/unittemplate/?unit_template_id="+data.unit_id;
    
    let st = {
      unit_template_id:data.unit_id,
      layout_data:{...current_data,bedroomData}
    }

    let headers = {
      headers:{
        "Content-Type":"application/json"
    }
    }
  //   console.log(st);

    axios.patch(url,st,headers)
    .then((res)=>{
      console.log("add bedrrom template in unit======>", res.data);
    })
    .catch((err)=>{
      console.log(err);
    })

    }
  

  return (
    <>
    <Stack direction="row" sx={{width:"100%",height:"auto" }}>
      {/* 1st stack */}
      <Stack sx={{minHeight:"100vh",width:"20%",boxSizing:"border-box",}}> {/*for project directory 20% and for sales dashboard 225*/}
        <MainSidebar active={"Project Directory"}/>
      </Stack>

     {/* 2nd stack */}
     <Stack direction="column" sx={{ backgroundColor:"#f9f9f9",width:"80%", padding:"25px",boxSizing:"border-box",}}  >  {/*  width for main sidebar 78px and for 80px*/}
      <Navbar editProject={true}/>
      


      <Typography sx={{marginTop:"20px", fontSize:"28px", fontWeight:"600", marginLeft:"10px", marginBottom:"50px"}}>
                    Bedroom Overview
           </Typography>
     {/* actual content  */}
    <Stack sx={{backgroundColor:"#f9f9f9"}}>  {/* mt:"28px", */}

    <Stack direction="column"  sx={{  padding:"20px", backgroundColor:"#fff", marginRight:"0px"}}>

{/* confirm project bathroom component */}
          
<Stack direction="row" spacing={1.5}>
<Stack direction="row" alignItems="center" spacing={1.5} sx={{margin:"70px 0px 70px 0px"}}>
<Box  sx={{width:"29px", height:"29px",borderRadius:"50%", backgroundColor:"#FFA946", display:"flex", justifyContent:"center", alignItems:"center"}}>
   <DoneIcon sx={{color:"#fff", fontSize:"20px"}}/>
 </Box>
 <Typography sx={{fontSize:"18px", fontWeight:"500"}}>Project Home</Typography>
</Stack>
<Stack direction="row" alignItems="center" spacing={1.5} sx={{margin:"70px 0px 70px 0px"}}>
<Box  sx={{width:"29px", height:"29px",borderRadius:"50%", backgroundColor:"#FFA946", display:"flex", justifyContent:"center", alignItems:"center"}}>
   <DoneIcon sx={{color:"#fff", fontSize:"20px"}}/>
 </Box>
 <Typography sx={{fontSize:"18px", fontWeight:"500"}}>Building 1</Typography>
</Stack>
<Stack direction="row" alignItems="center" spacing={1.5} sx={{margin:"70px 0px 70px 0px"}}>
<Box  sx={{width:"29px", height:"29px",borderRadius:"50%", backgroundColor:"#FFA946", display:"flex", justifyContent:"center", alignItems:"center"}}>
   <DoneIcon sx={{color:"#fff", fontSize:"20px"}}/>
 </Box>
 <Typography sx={{fontSize:"18px", fontWeight:"500"}}>Wing 1</Typography>
</Stack>
<Stack direction="row" alignItems="center" spacing={1.5} sx={{margin:"70px 0px 70px 0px"}}>
<Box  sx={{width:"29px", height:"29px",borderRadius:"50%", backgroundColor:"#FFA946", display:"flex", justifyContent:"center", alignItems:"center"}}>
   <DoneIcon sx={{color:"#fff", fontSize:"20px"}}/>
 </Box>
 <Typography sx={{fontSize:"18px", fontWeight:"500"}}>Floor 1</Typography>
</Stack>
<Stack direction="row" alignItems="center" spacing={1.5} sx={{margin:"70px 0px 70px 0px"}}>
<Box  sx={{width:"29px", height:"29px",borderRadius:"50%", backgroundColor:"#FFA946", display:"flex", justifyContent:"center", alignItems:"center"}}>
   <DoneIcon sx={{color:"#fff", fontSize:"20px"}}/>
 </Box>
 <Typography sx={{fontSize:"18px", fontWeight:"500"}}>{data.unit_name} (Bedroom)</Typography>
</Stack>
</Stack>

<Box sx={{}}>
 <Stack direction="row" sx={{margin:"0px 0px 0px 0px",display:"flex",alignItems:"center"}} >
   <Typography sx={{fontSize:"18px", fontWeight:"500"}}>Bedroom</Typography>
   <AddIcon sx={{color:"#A2A3A6",fontSize:"18px",marginLeft:"10px",cursor:"pointer"}} onClick={addbedroom}/>
 </Stack>

 
 
 {bedroomData?.map((ele,index)=>{
     return(
         <Stack direction="row" sx={{display:"flex",alignItems:"center",margin:"20px 0px 0px 0px"}} >
           <Typography sx={{fontSize:"18px", fontWeight:"500",color:"#A2A3A6"}}>Bedroom {ele.bedroom_no}</Typography>
            <TextField
            type="number" inputProps={{ min: 0 }}
                 sx={{
                      width:"188px",
                      marginLeft:"10px"
                     }} value={ele.length}  onChange={(event)=>{
                       setbedroomData((prev) => {
                     return prev.filter((curvalue, idx) => {
                     if (idx == index) {
                         curvalue.length = event.target.value;
                     }
                     return curvalue;
                })
                 })
             }}/>
            <Typography sx={{fontSize:"18px", fontWeight:"500",color:"#A2A3A6",margin:"0px 0px 0px 10px"}}>L</Typography>
            <CloseIcon sx={{fontSize:"22px", fontWeight:"500",color:"#A2A3A6",margin:"0px 0px 0px 20px"}}/>
            <TextField
            type="number" inputProps={{ min: 0 }}
                 sx={{
                      width:"188px",
                      marginLeft:"10px"
                     }} value={ele.breadth} onChange={(event)=>{
                       setbedroomData((prev) => {
                     return prev.filter((curvalue, idx) => {
                     if (idx == index) {
                         curvalue.breadth = event.target.value;
                     }
                     return curvalue;
                })
                 })
             }}/>
             <Typography sx={{fontSize:"18px", fontWeight:"500",color:"#A2A3A6",margin:"0px 0px 0px 10px"}}>B</Typography>
             <DeleteIcon sx={{fontSize:"26px", fontWeight:"500",color:"#EF3649",margin:"0px 0px 0px 20px",cursor:"pointer"}} 
             onClick={()=>{
                 const newbedroomData = bedroomData.filter((x) => x !== ele);
                 console.log(newbedroomData);
                 updatenumber(newbedroomData,index);
             }}/>
         </Stack>
     )
 })}

 <Stack direction="row" sx={{alignItems:"center"}}>

 <Box 
  onClick={()=>{
   navigate(-1)
 }}
 sx={{display:"flex",alignItems:"center",margin:"0px 0px 0px 0px"}}>
 <ArrowBackIcon/>
 <Typography sx={{fontSize:"18px", fontWeight:"500",margin:"0px 10px 0px 10px"}}>Back</Typography>
 </Box>
 
  <Box sx={{margin:"60px 0px 60px 60px"}}>

   <Link to={`/builder-edit-project-unit`} state={floorData}
     onClick={()=>{
     createBedroomTemplate()
   }}
   >
     <Button 
     className="univercel_btn_class"
     variant="contained"
     sx={{padding:"16px 50px 16px 50px", color:"#fff",fontSize:"18px", fontWeight:"700",height:"50px"}}>
         Save 
     </Button>
   </Link>

 </Box>

 </Stack>
 
</Box>
          
</Stack>

<BackeToOverviewBtn projectDirectory={true}/>
    </Stack>
      </Stack>
</Stack>





       
    </>
  )
}

export default EditProjectUnit_Bedroom