import { Box, Button, Checkbox, Drawer, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import TuneOutlinedIcon from "@mui/icons-material/TuneOutlined";
import KeyboardArrowRightOutlinedIcon from "@mui/icons-material/KeyboardArrowRightOutlined";
import KeyboardArrowUpOutlinedIcon from "@mui/icons-material/KeyboardArrowUpOutlined";
import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined";
import { CheckBox } from "@mui/icons-material";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import DriveFileRenameOutlineIcon from "@mui/icons-material/DriveFileRenameOutline";
import VisibilityIcon from "@mui/icons-material/Visibility";
import DeleteIcon from "@mui/icons-material/Delete"; 
import "../MembersDirectory/MDmainPage.css";
import { Sidebar } from "../ProjectDashboard/DashboardComponents/Sidebar";
import { Navbar } from "../ProjectDashboard/DashboardComponents/Navbar";
import React, { useContext, useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import CategoryIcon from "@mui/icons-material/Category";
import HandshakeIcon from "@mui/icons-material/Handshake";

import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";

import axios from "axios";
import { useTheme } from "@emotion/react";
import { NavbarForDirectory } from "../ProjectDashboard/DashboardComponents/NavbarForDirectory";
import { basicheaders } from "../../../utils/consts";
import profileContext from "../../../context/profile/context";
import { Apiaddress } from "../../../utils/consts";
import { MainSidebar } from "../ProjectDashboard/DashboardComponents/MainSidebar";

function LDoverviewPage() {
  const [delIconClicked, setdelIconClicked] = useState(false);
  const columns = [
    { field: "id", headerName: "id", width: 130 },
    { field: "FlatNo", headerName: "Flat No", width: 130 },
    { field: "BookedDate", headerName: "Booked Date", width: 130 },
    { field: "CustomerName", headerName: "Customer Name", width: 130 },
    {
      field: "Location",
      headerName: "Location",
      // type: "number",
      width: 130,
    },
    {
      field: "TotalSpent",
      headerName: "Total Spent",
      width: 130,
    },
    {
      field: "Action",
      headerName: "Action",
      headerAlign: "center",
      width: 130,
      renderCell: (params) => {
        return (
          <Stack direction="row" sx={{ marginLeft: "10px" }}>
            <RemoveRedEyeIcon fontSize="medium" />
            <DeleteIcon
              fontSize="medium"
              sx={{ marginLeft: "10px" }}
              onClick={() => delIconClick(params)}
            />
          </Stack>
        );
      },
    },
  ];

  const rows = [
    {
      id: 1,
      FlatNo: "#4560",
      BookedDate: "26 March 2022, 12:42 AM",
      CustomerName: "Seema Agarwal",
      Location: "67 St. pune",
      TotalSpent: "Agent",
    },
    {
      id: 1,
      FlatNo: "#4560",
      BookedDate: "26 March 2022, 12:42 AM",
      CustomerName: "Seema Agarwal",
      Location: "67 St. pune",
      TotalSpent: "Agent",
    },
    {
      id: 1,
      FlatNo: "#4560",
      BookedDate: "26 March 2022, 12:42 AM",
      CustomerName: "Seema Agarwal",
      Location: "67 St. pune",
      TotalSpent: "Agent",
    },
    {
      id: 1,
      FlatNo: "#4560",
      BookedDate: "26 March 2022, 12:42 AM",
      CustomerName: "Seema Agarwal",
      Location: "67 St. pune",
      TotalSpent: "Agent",
    },
  ];
  const [state, setState] = React.useState(false);

  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState(open);
  };

  const [stateadd, setStateadd] = React.useState(false);

  const toggleDrawerAdd = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setStateadd(open);
  };

  const [selectedRows, setSelectedRows] = React.useState([]);
  useEffect(() => {
    console.log(selectedRows);
  }, [selectedRows]);

  const [curselectedRows, setcurselectedRows] = useState(null);
  const onRowsSelectionHandler = (ids) => {
    if (delIconClicked) setdelIconClicked(false);
    const selectedRowsData = ids.map((id) => rows.find((row) => row.id === id));
    // console.log(selectedRowsData);
    setcurselectedRows(selectedRowsData);
  };

  const [tabledata, setTabledata] = useState(null);
  const [rowData, setrowData] = useState([]);
  const [idCont, setidCunt] = useState(1);

  //api

  //api

  useEffect(() => {
    const rows = [
      {
        id: 8,
        Name: "Frances",
        Phone: "912912912",
        Email: "dummmy@gamil.com",
        Team: "Agent",
        Role: "Agent",
        DetailedView: "View",
      },
    ];
    if (tabledata) {
      let obj = {
        id: idCont,
        Name: tabledata.name,
        Phone: tabledata.phone,
        Email: tabledata.Email,
        Team: tabledata.Team,
        Role: tabledata.Role,
      };
      setidCunt(idCont + 1);
      console.log(obj);
      setrowData([...rowData, obj]);
    }

    console.log(tabledata);
  }, [tabledata]);

  const [rowSelected, setRowSelected] = useState(false);
  const [token, setToken] = useState(null);
  const [data, setData] = useState(null);
  const getBuyers = async () => {
    try {
      const headers = {
        Authorization: `JWT ${token}`,
      };
      const res = await axios.get(Apiaddress + "/api/buyer/buyerlist/", {
        headers,
      });
      // console.log(res);
      if (res.data) if (res.data.buyerList) setData(res.data.buyerList);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (token) getBuyers();
  }, [token]);

  useEffect(() => {
    setToken(localStorage.getItem("crm_token"));
  }, []);

  const [newRowData, setnewRowData] = useState([]);
  useEffect(() => {
    console.log(data);
    if (data) {
      // {
      //   id: 1,
      //   FlatNo: "#4560",
      //   BookedDate: "26 March 2022, 12:42 AM",
      //   CustomerName: "Seema Agarwal",
      //   Location: "67 St. pune",
      //   TotalSpent: "Agent",
      // },

      let arr = [];

      for (let i = 0; i < data.length; i++) {
        let obj = {
          id: i + 1,
          buyer_id: data[i].id,
          FlatNo: data[i].flat_no,
          BookedDate: data[i].booked_date,
          CustomerName: data[i].customer_name,
          Location: data[i].location,
          TotalSpent: data[i].total_spent,
        };
        arr.push(obj);
      }
      setnewRowData(arr);
    }
  }, [data]);
  // console.log(newRowData);

  const delIconClick = async (params) => {
    console.log(params.row.buyer_id);
    try {
      const obj = {
        data: { buyer_id: params.row.buyer_id },
        headers: basicheaders,
      };
      const res = await axios.delete(
        Apiaddress + "/api/buyer/buyers/",
        obj
      );
      console.log(res);
    } catch (err) {
      console.log(err);
    }
  };
  return (

    <> 
     
     
<Stack direction="row" sx={{width:"100%",height:"auto" }}>
      {/* 1st stack */}
      <Stack sx={{minHeight:"100vh",width:"22%",boxSizing:"border-box",}}>
        <MainSidebar active={""}/>
      </Stack>

     {/* 2nd stack */}
     <Stack direction="column" sx={{ backgroundColor:"#f9f9f9",width:"80%", padding:"25px",boxSizing:"border-box",}}  >  {/*  width for main sidebar 78px and for 80px*/}
      {/* <Navbar/> */}
      <NavbarForDirectory
          showAddProjectbtn={false}
          showRightSideBar={false}
          showRightSideBarleft={true}
          RightBarName={"Add Buyers"}
          toggleDrawer={toggleDrawer}
          leftTitle={"Buyers Directory"}
          toggleDrawerAdd={toggleDrawerAdd}
          RightBarNameleft={"Send invite"}
        />
      


    {/* <Typography sx={{mt:"20px", fontSize:"22px", fontWeight:"500",color:"#2979F2"}}>
    Project Name
    </Typography> */}
     {/* actual content  */}
    <Stack sx={{ mt:"28px",backgroundColor:"#f9f9f9"}}>

    <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            sx={{ height: "68px", marginBottom: "20px" }}
          >
            <Stack direction="column" alignItems="center">
              <Typography
                sx={{
                  fontSize: "22px",
                  fontWeight: "600",
                  marginLeft: "5px",
                  color: "black",
                }}
              >
                Buyers Directory
              </Typography>
              <Typography
                sx={{
                  color: "#B9B7D0",
                  fontSize: "12px",
                  fontWeight: "400",
                  paddingLeft: "20px",
                }}
              >
                Here is your Leads Directory list data
              </Typography>
            </Stack>
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Stack
                direction="row"
                justifyContent="space-around"
                alignItems="center"
                sx={{
                  width: "203px",
                  height: "68px",
                  borderRadius: "12px",
                  color: "#3E4954",
                  backgroundColor: "#f4f5f9",
                }}
              >
                <TuneOutlinedIcon sx={{ color: "#2F4CDD" }} />
                <Typography sx={{ fontSize: "16px", fontWeight: "600" }}>
                  Filter
                </Typography>
                <KeyboardArrowDownOutlinedIcon />
              </Stack>
            </Stack>
          </Stack>

          {/* ================filters and all=============================== */}
        
          <Box>
            <div style={{ height: 400, width: "100%" }}>
              <DataGrid
                rows={newRowData}
                columns={columns}
                pageSize={10}
                rowsPerPageOptions={[5]}
                onSelectionModelChange={(ids) => onRowsSelectionHandler(ids)}
              />
            </div>
          </Box>
   

    </Stack>




      </Stack>
</Stack>

    </>


  );
}

export default LDoverviewPage;
