import { Box, Button, FormControlLabel, Stack, Switch, TextField } from "@mui/material"
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import SearchRoundedIcon from '@mui/icons-material/SearchRounded';
import DragIndicatorRoundedIcon from '@mui/icons-material/DragIndicatorRounded';
import { useContext, useEffect, useState } from "react";
import { styled } from '@mui/material/styles';
import builderDashboard from "../../../../context/builder_dashboard/context";
const OrganizationSelectionButton = ({setUpdate, update,OpenOrganization,setOpenOrganization}) => {
  console.log("update", update)
  // const [OpenOrganization, setOpenOrganization] = useState(false)
  const OrganizationsContext = useContext(builderDashboard)
  console.log(OrganizationsContext);
  
  const [orgIds, setOrgIds] = useState([]);

   const setIdstoLocal = (id)=>{
             let arr;
                   if(orgIds.includes(id)){
                     arr = orgIds.filter((ele)=>{
                      return id!==ele;
                    })
                  }
                  else{
                    arr = [...orgIds,id] 
                  }
                   setOrgIds(arr)
  }
  useEffect(()=>{
        localStorage.setItem("crm_orgIds",JSON.stringify(orgIds) )  // JSON.stringify()
  },[orgIds])

  return (
    <Box>

      <Button
        className="univercel_btn_class"
        endIcon={OpenOrganization==false?<KeyboardArrowDownIcon />:<KeyboardArrowUpIcon/>}
        sx={{ color: "#FFFFFF", textTransform: "none", boxShadow: null,fontFamily: "Product Sans",
        fontSize: "15px"}}  
        onClick={(event) => {
          event.stopPropagation();///for stop event bubbling (child has to stop event bubbling )
          setOpenOrganization(!OpenOrganization)
        }}
      >
        Organization
      </Button>
      {
        //The Drop Down 
        OpenOrganization && <Box sx={{ zIndex: 10 }}>
          <Box sx={{
            position: "absolute", marginLeft: 10,
            // border:"1px solid orange"
          }}>
            <Box
              sx={{
                width: 0,
                height: 0,
                borderLeft: "15px solid transparent",
                borderRight: "15px solid transparent",
                borderBottom: "15px solid white",
              }}
            ></Box>
          </Box>
          <Box
            sx={{
              position: "absolute",
              marginTop: "15px",
              zIndex: 2,
              // right: 185,
              right: 25,
              width: 247,
              backgroundColor: "white",
              boxShadow: "0px 11px 15px rgba(0, 0, 0, 0.2)",
              borderRadius: "12px",
              padding: 3

            }}
          >
            {/* Search Bar */}
            {/* <Box
              sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}
            >

              <TextField
                variant="standard"
                placeholder="Search"
                InputProps={{
                  disableUnderline: true,
                  startAdornment: <SearchRoundedIcon sx={{ color: "#2979F2", margin: 1 }} />
                }} />

            </Box> */}
            <Box sx={{
              marginTop: 2,
              // border:"1px solid green"
            }}>
              {
                OrganizationsContext.BuilderDashboardState.map((e, i) => {
                  // console.log("hello", OrganizationsContext.BuilderDashboardState)
                  return(
                    <OrganizationSelectionTile OrgName={e.org_name} org_id={e.id} selected={e.selected} setIdstoLocal={setIdstoLocal}
                    setterFunction={()=>{
                      let odstate = OrganizationsContext.BuilderDashboardState;
                      odstate[i].selected = (!OrganizationsContext.BuilderDashboardState[i].selected)
                      console.log("99999999999999999==>",odstate, update);
                      OrganizationsContext.setBuilderDashboardState(odstate)
                      setUpdate(!update)
                      
                       
    
                    }}
                    />
                  )
                }
              )
              }

            </Box>
          </Box></Box>
      }
    </Box>
  )
}

export default OrganizationSelectionButton


function OrganizationSelectionTile({ OrgName, setterFunction ,selected, org_id, setIdstoLocal}) {
  return (
    <Box
      sx={{

        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems:"center",
        // border:"1px solid black"
      }}
    >
      <Stack direction="row" alignItems="center">
        <DragIndicatorRoundedIcon sx={{ color: "gray"}} />
        <Box sx={{ alignItems: "center", display: "flex" }}>
        {OrgName}
        </Box>
      </Stack>
  
      <FormControlLabel
        onClick={() =>{
          setterFunction();
          
          setIdstoLocal(org_id)
        
        }}
        control={<IOSSwitch sx={{ m: 1, margin: 1 }} defaultChecked={selected?true:false} />}
      />
    </Box>
  )
}



const IOSSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: 2,
    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: 'translateX(16px)',
      color: '#1976D2',
      '& + .MuiSwitch-track': {
        backgroundColor: theme.palette.mode === 'dark' ? "#D1E4F6" : '#D1E4F6',
        opacity: 1,
        border: 0,
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5,
      },
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      color: '#33cf4d',
      border: '6px solid #fff',
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
      color:
        theme.palette.mode === 'light'
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
    },
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 22,
    height: 22,
  },
  '& .MuiSwitch-track': {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
    opacity: 1,
    transition: theme.transitions.create(['background-color'], {
      duration: 500,
    }),
  },
}));
