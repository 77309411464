import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    MenuItem,
    Drawer,
    Box,
    Button,
    Checkbox,
    FormControl,
    InputLabel,
    Select,
    TextField,
    Typography,
    Modal,
    Radio,
  } from "@mui/material";
  import { Stack } from "@mui/system";
  import TuneOutlinedIcon from "@mui/icons-material/TuneOutlined";
  import KeyboardArrowRightOutlinedIcon from "@mui/icons-material/KeyboardArrowRightOutlined";
  import KeyboardArrowUpOutlinedIcon from "@mui/icons-material/KeyboardArrowUpOutlined";
  import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined";
  import { CheckBox } from "@mui/icons-material";
  import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
  import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
  import DriveFileRenameOutlineIcon from "@mui/icons-material/DriveFileRenameOutline";
  import VisibilityIcon from "@mui/icons-material/Visibility";
  import DeleteIcon from "@mui/icons-material/Delete";
  import React, { useContext, useEffect, useState } from "react";
  import { DataGrid, GridCloseIcon, GridExpandMoreIcon } from "@mui/x-data-grid";
  import CategoryIcon from "@mui/icons-material/Category";
  import HandshakeIcon from "@mui/icons-material/Handshake";
  import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
  import SearchRoundedIcon from '@mui/icons-material/SearchRounded';
  import PercentIcon from '@mui/icons-material/Percent';
  import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';
  import CircularProgress from '@mui/material/CircularProgress';
  import AddIcon from "@mui/icons-material/Add";
  import SaveAltIcon from '@mui/icons-material/SaveAlt';
  import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
  
  import axios from "axios";
  
  import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
  import QDcustomTable from "./QDcustomTable";
  import RightSidebarQD from "./RightSidebarQD";
  import profileContext from "../../../context/profile/context";
  import { Apiaddress } from "../../../utils/consts";
  import { ProjectDashboardSidebar } from "../../BuilderComponents/ProjectDashboard/DashboardComponents/ProjectDashboardSidebar";
  import { NavbarForDirectory } from "../../BuilderComponents/ProjectDashboard/DashboardComponents/NavbarForDirectory";

import builderDashboard from "../../../context/builder_dashboard/context";
import { UserRole } from "../../../UserRoles/UserRoles";
import { Link } from "react-router-dom";
  
  const rows = [
    {
      id: 1,
      Name: "#4560",
      State: "26 March 2022, 12:42 AM",
      City: "Seema Agarwal",
      Pincode: "67 St. pune",
      Unit: "Agent",
    },
  ];

export const Quotation_Directory_w_sb = ()=>{

     ////get user roles from local storage and assign 
     let roles = JSON.parse(localStorage.getItem("user_roles"))  
  
     let quotation = roles['quotation'];

    const OrganizationsContext = useContext(builderDashboard);
    console.log("organisation Context=====",OrganizationsContext);

    const [state, setState] = React.useState(false);

    const [trigger, setTrigger] = useState(false);
  
    const delIconClick = async (item) => {
      try {
        let obj = { quotation_id: item.q_id };
        const res = await axios.delete(
          Apiaddress + "/api/leads/quotation/",
          {
            data: obj,
          }
        );
        setTrigger(!trigger);
      } catch (err) {
        console.log(err);
      }
    };
  
    const toggleDrawer = (open) => (event) => {
      if (
        event.type === "keydown" &&
        (event.key === "Tab" || event.key === "Shift")
      ) {
        return;
      }
  
      setState(open);
    };
  
    const [data, setData] = useState([]);
    const [token, setToken] = useState(null);
    const [loading,setloading]= useState(true);
  
    useEffect(() => {
      setToken(localStorage.getItem("crm_token"));
    }, [trigger]);
    const [newRowData, setNewRowData] = useState([]);
  
    useEffect(() => {
      
      if (data!=="quotations not found") {
        let arr = [];
        for (let i = 0; i < data.length; i++) {
          let obj = {
            q_id: data[i]?.id,
            lead_name: data[i]?.lead?.name,
            created_at: data[i]?.created_on,
            valid: data[i]?.valid_till,
            price: data[i]?.adjustment,
            status: data[i]?.status,
            unit_id: data[i]?.unit,
            lead_id: data[i]?.lead?.id
          };
          arr.push(obj);
        }
        setNewRowData(arr);
      }
      setloading(false);
    }, [data, trigger]);
    
    console.log(newRowData);
    //lead
  
    const [orgID, settOrgID] = useState(null);
  
    const [rowData, setrowData] = useState([]);
    const [idCont, setidCunt] = useState(1);
    const [leadsFromApi, setleadsFromApi] = useState([]);
    //context
  
    const user = useContext(profileContext);
  
    useEffect(() => {
      console.log(user);
      if (user)
        if (user.UserState)
          if (user.UserState.user_obj)
            if (user.UserState.user_obj.orgList)
              if (user.UserState.user_obj.orgList[0]) {
                console.log(user.UserState.user_obj.orgList[0]);
                settOrgID(user.UserState.user_obj.orgList[0]);
              }
    }, [user, trigger]);
    //context
  
    const fetchLeads = async () => {
      try {
        const res = await axios.get(
          Apiaddress + `/api/leads/getquotations/?project_id=${id}`
        );
        console.log(res?.data?.quotations);
        setData(res?.data?.quotations);
      } catch (err) {
        console.log(err);
      }
    };
  
    const [allpriority, setallpriority] = useState(null);
  
    const getpriority = async () => {
      try {
        const res = await axios.get(
          Apiaddress + "/api/leads/leadprority/"
        );
        console.log(res.data.leadprority);
        setallpriority(res.data.leadprority);
      } catch (err) {
        console.log(err);
      }
    };
  
    useEffect(() => {
      getpriority();
    }, [trigger]);
    const [prioritymap, setprioritymap] = useState(null);
  
    const setPri = () => {
      const mp = new Map();
      for (let i = 0; i < allpriority.length; i++) {
        mp.set(allpriority[i].id, allpriority[i].name);
      }
      setprioritymap(mp);
    };
  
    useEffect(() => {
      if (allpriority) {
        setPri();
      }
    }, [allpriority, trigger]);
  
    useEffect(() => {
      if (leadsFromApi) {
        let arr = [];
        for (let i = 0; i < leadsFromApi.length; i++) {
          let obj = {
            id: i + 1,
            lead_id: leadsFromApi[i].id,
            Name: leadsFromApi[i].name,
            Phone: leadsFromApi[i].phone,
            Email: leadsFromApi[i].email,
            Projects: leadsFromApi[i].project,
            Priority: prioritymap
              ? prioritymap.get(leadsFromApi[i].lead_prority)
              : "",
            DetailedView: "View",
            Address: leadsFromApi[i].address_line,
          };
          setidCunt(idCont + 1);
          arr.push(obj);
        }
        setrowData(arr);
      }
    }, [leadsFromApi, trigger]);

    useEffect(() => {
      if (orgID) fetchLeads();
    }, [orgID, state, trigger]);

  /// get selected project id from local storage
    let builderSelectedData = JSON.parse(localStorage.getItem("BuilderSelected"));
    let id = builderSelectedData.selectedProjectID;
    console.log("selectedProjectId===",id);
    const [mat, stmat] = useState(null);
    const getQ = async () => {
      try {
        let arr = [];
        for (let i = 0; i < rowData.length; i++) {
          const res = await axios.get(
            Apiaddress + `/api/leads/getquotations/?project_id=${id}`
          );
           console.log("quotation",res.data)
           if (
             res.data.quotations &&
             res.data.quotations != "quotations not found"
             )
             arr.push(res.data.quotations);
            }
            stmat(arr);
          } catch (err) {
        console.log("quotation",err)
        console.log(err);
      }
    };
  
    useEffect(() => {
      if (mat) {
        let arr = [];
        for (let i = 0; i < mat.length; i++) {
          for (let j = 0; j < mat[i].length; j++) {
            arr.push(mat[i][j]);
          }
        }
        setData(arr);
      }
    }, [mat, trigger]);
    
    useEffect(() => {
      console.log(rowData);
      // if (rowData) getQ();
    }, [rowData, trigger]);

        //fitering data starts:====
  const [filterselect, setfilterselect] = useState();
  const [filtervalue, setfiltervalue] = useState();

  const handleChange = (event) => {
    setfilterselect(event.target.value);
    setfiltervalue("");
  };

  console.log(filterselect);
  console.log(filtervalue);
  

   const fetchfilter = async () => {
     try {
        const res = await axios.get(
          Apiaddress + `/api/leads/getquotations/?project_id=${id}&filterkey=${filterselect}&filtervalue=${filtervalue}`
        );
        console.log(res?.data?.quotations);
        setData(res?.data?.quotations);
      } catch (err) {
        console.log(err);
      }
  };


  // Deepak's work and function for quotation Module 
  //Quotation Create States
  const [fetchedUnit,setfetchedUnit]= useState([]);
  const [projectInfo,setProjectInfo]= useState({});
  const [selectedUnit,setSelectedUnit]=useState({});
  const [tempUnit,setTempUnit]=useState({});
  const [unitCharge,setUnitCharge]=useState([]);
  const [leadForQuotation,setleadForQuotation]=useState([]);
  const [selectedLead, setSelectedLead] = useState();
  const [error,seterror]=useState(false);
  const [date,setDate]=useState();
  const [discount,setDiscount]=useState();
  const [grandTotal,setGrandTotal]=useState();
  const [unchangedTotal,setUnchangedTotal]=useState(0);
  const [input,setinput]=useState();
  const [loadingSubmit,setLoadingSubmit]=useState(false);

  //Quotation Overview States
  const [leadName,setLeadName]=useState();
  const [validOverview,setValidOverview]=useState();
  const [grandTotalOverview,setGrandTotalOverview]=useState();
  const [unitOverviewCharge,setUnitOverviewCharge]=useState();
  const [unitOverviewName,setUnitOverviewName]=useState();
  const [leadId,setLeadId]=useState();
  const [unitId,setUnitId]=useState();
  const [quotationId,setQuotationId]=useState();
  const [dateEdit,setDateEdit]=useState();
  const [quotationStatus,setQuotationStatus]=useState();
  const [loadingSubmitApprove,setLoadingSubmitApprove]=useState(false);
  const [loadingSubmitReject,setLoadingSubmitReject]=useState(false);


  //create Lead States and Functionality
  const [createLeadName,setCreateLeadName]=useState();
  const [createLeadPhone,setCreateLeadPhone]=useState();
  const [createLeadEmail,setCreateLeadEmail]=useState();
  const [createLeadPriority,setCreateLeadPriority]=useState();

  const [userID, setUserID] = useState(null);

  useEffect(() => {
    if (user)
      if (user.UserState)
        if (user.UserState.user_obj)
          if (user.UserState.user_obj.id) setUserID(user.UserState.user_obj.id);
  }, [user]);

  const createNewLead=async ()=>{
    try{
      let objPost = {
        name:createLeadName,
        phone:createLeadPhone,
        email:createLeadEmail,
        project:id,
        lead_prority:createLeadPriority,
        assigned_to: [userID]
      };
      console.log("createLead====",objPost);
      const res = await axios.post(
      Apiaddress + "/api/leads/lead/",
      objPost
      );
      console.log("createLead====",res.data);
      setCreateLeadEmail("");
      setCreateLeadName("");
      setCreateLeadPhone("");
      setCreateLeadPriority("");
      handleCloseChildLead();
      fetchLeadForQuotation();
      }
      catch (err){
        console.log(err);
      }
  }

  //Edit validDate of Quotation
  const editValidDate=async ()=>{
     try {
      if(quotationId==="" || dateEdit==="")return;
      setLoadingSubmit(true);
      let converted_Date=new Date(dateEdit);
      const headers = {
        Authorization: `JWT ${token}`,
      };
      const obj = {
        quotation_id:quotationId,
        valid_till:converted_Date
        };
        console.log(obj);
        const res = await axios.patch(
          Apiaddress + "/api/leads/quotation/",
          obj
          // { headers: headers }
        );
        console.log("ApprovedQuotation====",res?.data);
        handleCloseEdit();
      } catch (err) {
        console.log("quotationApprovederror",err);
      }
      setLoadingSubmit(false);
      setTrigger(!trigger);
  }

  //Approve or reject Quotation
  const ApproveOrReject=async (status)=>{
    console.log(status);
    try {
      if(leadId==="" || unitId==="" || grandTotalOverview==="" || validOverview==="" || id==="")return;
      if(status=="Approved"){
        setLoadingSubmitApprove(true);
      }
      else{
        setLoadingSubmitReject(true);
      }
      // let converted_Date=new Date(date);
      const headers = {
        Authorization: `JWT ${token}`,
      };
      const obj = {
        quotation_id:quotationId,
        lead: leadId,
        unit: unitId,
        adjustment: grandTotalOverview,
        project: id,
        status:status,
        };
        console.log(obj);
        const res = await axios.patch(
          Apiaddress + "/api/leads/quotation/",
          obj
          // { headers: headers }
        );
        console.log("ApprovedQuotation====",res?.data);
        handleCloseOverview();
      } catch (err) {
        console.log("quotationApprovederror",err);
      }
      if(status=="Approved"){
        setLoadingSubmitApprove(false);
      }
      else{
        setLoadingSubmitReject(false);
      }
      setTrigger(!trigger);
  }


  //final generate Quotation
  const onSubmit=async ()=>{
    try {
      if(selectedLead==="" || discount==="" || date==="")return;
      setLoadingSubmit(true);
      let converted_Date=new Date(date);
      const headers = {
        Authorization: `JWT ${token}`,
      };
      const obj = {
        lead: selectedLead?.id,
        unit: selectedUnit?.id,
        adjustment: grandTotal,
        valid_till: converted_Date,
        project: id
        };
        console.log(obj);
        const res = await axios.post(
          Apiaddress + "/api/leads/quotation/",
          obj
          // { headers: headers }
        );
        console.log("generatedQuotation====",res?.data);
        handleClose();
        setDiscount("");
        setDate("");
        setinput("");
        setSelectedLead("");
        setGrandTotal(unchangedTotal);
      } catch (err) {
        console.log("quotationerror",err);
      }
      setLoadingSubmit(false);
      setTrigger(!trigger);
  }

  //searching lead of project
   const searchLead=()=>{

        let value = input.toLowerCase();
        if(selectedValue=="phone"){
          value='+91 '+value;
        }
        let result = leadForQuotation.filter((data) => {

            if(selectedValue=="email"){
              return data.email==value;
            }
            else{
              return (data.phone)==value;
            }

          });
        console.log("result====",result);
        if(result.length==0){
          setSelectedLead({});
          seterror(true);
        }
        else{
          setSelectedLead(result[0]);
          seterror(false);
        }
    }
   
   //fetch lead for quotation
   const fetchLeadForQuotation = async () => {

    try {
      const res = await axios.get(
        Apiaddress + "/api/leads/leadsofproject/?project_id=" + id
      );
      console.log(res?.data?.leads);
      if(res?.data?.leads=="lead not found"){
       setleadForQuotation([]);
      }
      
      else{
        setleadForQuotation(res?.data?.leads);
      }
    } catch (err) {
      console.log(err);
    }
  };

  // return Previous when click on Closed
  const returnPrevious=()=>{
    setTempUnit(selectedUnit);
    handleCloseChild();
  }

  //set charges data accrding to select
  const selectCharges=()=>{
    let temp=fetchedUnit.filter((ele)=>{
      return ele.id==tempUnit.id
    })
    let obj=temp[0]?.charges_data;
    let total=0;
    console.log("temp===",obj);
    if(Object.keys(obj).length === 0 && obj.constructor === Object){
      setUnitCharge([]);
    }
    else{
      setUnitCharge(temp[0]?.charges_data);
      
      for(let i=0;i<obj.length;i++){
           total+=+(obj[i].sectioncost);
      }
    }
    setUnchangedTotal(total);
    setGrandTotal(total);
    setSelectedUnit(tempUnit);
    handleCloseChild();
  }
  //get Unit data of Projects
   const fetchUnitdata =async ()=>{
    try {
        const res = await axios.get(
          Apiaddress + `/api/projects/getunitsusingprojectid/?project_id=${id}`
        );
      console.log("===========================.>",res?.data?.unit);
      if(res.data.unit=="Unit not found"){
            setfetchedUnit([])
        }
      else{      
            setfetchedUnit(res?.data?.unit);
            let obj={
              id:res?.data?.unit[0]?.id,
              name:res?.data?.unit[0]?.name,
            }
            setSelectedUnit(obj);
            setTempUnit(obj);
            // setUnitCharge(res?.data?.unit[2]?.charges_data);
             let temp=res?.data?.unit[0]?.charges_data;
             let total=0;
             if(Object.keys(temp).length === 0 && temp.constructor === Object){
                setUnitCharge([]);
              }
              else{
                setUnitCharge(temp);
                for(let i=0;i<temp.length;i++){
                  total+=+(temp[i].sectioncost);
                }
              }
              setUnchangedTotal(total);
              setGrandTotal(total);
            }
          } catch (err) {
            console.log("====================",err);
            setfetchedUnit([]);
          }
        };

  const fetchProjectInfo= async ()=>{
     try {
        const res = await axios.get(
          Apiaddress + `/api/projects/project/?project_id=${id}`
        );
      console.log("===========================.>",res?.data?.projects[0]);
      let projectInfo=res?.data?.projects[0];
      let obj={
        address:projectInfo?.address_line,
        city:projectInfo?.city,
        state:projectInfo?.state,
        pincode:projectInfo?.pin_code,
        name:projectInfo?.name,
      }
      setProjectInfo(obj);
    } catch (err) {
      console.log("====================",err);
    }
  }
   
  //Parent Modal Functionality
  const [selectedValue, setSelectedValue] = useState("a");

  const handleChangeRadio = (event) => {
    setSelectedValue(event.target.value);
    setinput("");
    console.log(event.target.value);
  };

  const controlProps = (item) => ({
    checked: selectedValue === item,
    onChange: handleChangeRadio,
    value: item,
    name: "color-radio-button-demo",
    inputProps: { "aria-label": item },
  });

  const [selectedAdjustment, setSelectedAdjustment] = useState("a");

  //calculating and setting after Discount
  const grandTotalSetting=(e)=>{
    if(selectedAdjustment=="percentDiscount"){
      if(e.target.value<0 || e.target.value>100)return;
      else{
        setDiscount(e.target.value);
        
        let calculatedDiscount=unchangedTotal*((e.target.value)/100);
        let result=unchangedTotal-calculatedDiscount;
        console.log("result===",result,unchangedTotal);
        setGrandTotal(result);
      }
    }
    else{
      if(e.target.value<0 || e.target.value>unchangedTotal)return;
      else{
        setDiscount(e.target.value);
        
        let calculatedDiscount=(e.target.value);
        let result=unchangedTotal-calculatedDiscount;
        console.log("result===",result,unchangedTotal);
        setGrandTotal(result);
      }  
    }
  }

  const handleChangeRadioAdjustment = (event) => {
    setSelectedAdjustment(event.target.value);
    console.log(event.target.value);
    setDiscount("");
    setGrandTotal(unchangedTotal);
  };

  const controlPropsAdjustment = (item) => ({
    checked: selectedAdjustment === item,
    onChange: handleChangeRadioAdjustment,
    value: item,
    name: "color-radio-button-demo",
    inputProps: { "aria-label": item },
  });

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  //child Modal functionality to select Unit
  const [openChild, setOpenChild] = React.useState(false);
  const handleOpenChild = () => {
    setOpenChild(true);
  };
  const handleCloseChild = () => {
    setOpenChild(false);
  };

  //child Modal functionality to create Lead
  const [openChildLead, setOpenChildLead] = React.useState(false);
  const handleOpenChildLead = () => {
    setOpenChildLead(true);
  };
  const handleCloseChildLead = () => {
    setOpenChildLead(false);
  };

  //overview Modal functionality
  const [openOverview, setOpenOverview] = React.useState(false);
  const handleOpenOverview = () => setOpenOverview(true);
  const handleCloseOverview = () => setOpenOverview(false);

  //edit Modal functionality
  const [openEdit, setOpenEdit] = React.useState(false);
  const handleOpenEdit = () => setOpenEdit(true);
  const handleCloseEdit = () => setOpenEdit(false);


  useEffect(()=>{
      fetchUnitdata();
      fetchProjectInfo();
      fetchLeadForQuotation();
      setSelectedValue("email");
      setSelectedAdjustment("flatDiscount")
  },[])


    return(
        <>

<Stack direction="row" sx={{width:"100%",height:"auto" }}>
      {/* 1st stack */}
      <Stack sx={{minHeight:"100vh",width:"22%",boxSizing:"border-box",}}>
      <ProjectDashboardSidebar active={ "Quotation Directory"}/>
      </Stack>

     {/* 2nd stack */}
     <Stack direction="column" sx={{ backgroundColor:"#f9f9f9",width:"78%", padding:"25px",boxSizing:"border-box",}}  >
      {/* <Navbar/> */}
      <NavbarForDirectory
          RightBarNameProAdd={"Add Project"}
          // toggleDrawer={toggleDrawer}
          handleOpen={handleOpen}
          RightBarNameleft={"Send invite"}
          leftRightTitle1={"Unit Dashboard"}
          qdadd={UserRole(quotation,"add_quotation")? true :false}
          qdaddtext={"Create Quotation"}
          leftRightTitle2={"Quotation Directory"}
          leftTitle={"Project Dashboard"}
          showLeftTitle={false}
          righshow={true}
          longOrangBgr={true}
        />


    {/* <Typography sx={{mt:"20px", fontSize:"22px", fontWeight:"500",color:"#2979F2"}}>
    Project Name
    </Typography> */}
     {/* actual content  */}
    <Stack sx={{ mt:"28px",backgroundColor:"#f9f9f9"}}>

    <React.Fragment>
          <Drawer anchor="right" open={state} onClose={toggleDrawer(false)}>
            <RightSidebarQD
            // setTabledata={setTabledata}
            // curselectedRows={curselectedRows}
            />
          </Drawer>
        </React.Fragment>


        {/* Quotation Edit Modal */}
        <Modal
        hideBackdrop
        open={openEdit}
        onClose={handleCloseEdit}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
        >
        <Box sx={{position: 'absolute',
             top: '50%',
             left: '50%',
             transform: 'translate(-50%, -50%)',
             width: "20%",
             height:"auto",
             bgcolor: 'white',
             border: '2px solid white',
             borderRadius:"10px",
             boxShadow: 24,
             pt: 3,
             px: 3,
             pb: 1.5,
             textAlign:"center"
            }}>
            
            <Stack direction="row" sx={{justifyContent:"space-between"}}>
              <Typography sx={{fontFamily:'Product Sans',fontStyle:"normal",fontWeight:"600",fontSize:"20px",lineHeight:"21px",color:"#000000"}}>Select Unit</Typography>
              <Box sx={{textAlign:"right"}}>
                <GridCloseIcon sx={{color:"gray",cursor:"pointer"}} onClick={()=>{handleCloseEdit();setDateEdit("")}}/>
              </Box>
            </Stack>

            <Stack direction="row" justifyContent="start" alignItems="center" sx={{margin:"30px 0px 20px 0px",fontSize: "18px",justifyContent:"center"}}>
              <Typography component="span" className='field-name' sx={{width:"35%",fontFamily:'Product Sans',fontStyle:"normal",fontWeight:"500",fontSize:"18px",lineHeight:"21px",color:"#000000"}}>Valid Till</Typography>
               <TextField onChange={(e)=>{
                 setDateEdit(e.target.value);
                }}
                value={dateEdit}
                type="Date"
                 sx={{
                     width:"188px",
                     marginLeft:"40px"
                    }}/>
            </Stack>
           
          <Button variant="contained" onClick={selectCharges} sx={{background:"#0F5DAE",border:"1px solid #EFF0F7",boxShadow:"0px 2px 6px rgba(19, 18, 66, 0.07)",borderRadius:"4px",  border:'1px solid #0F5DAE',margin:"20px 0px"}}>
            <Box sx={{ textTransform: "capitalize",margin:"0px 7px"}} onClick={editValidDate}>Submit</Box>
          </Button> 
    
        </Box>
      </Modal>
        
        {/* Quotation Overview Modal */}
        <Modal
        open={openOverview}
        onClose={handleCloseOverview}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        >

        <Box sx={{position: 'absolute',
             top: '50%',
             left: '50%',
             transform: 'translate(-50%, -50%)',
             width: "70%",
             height:"80%",
             bgcolor: 'white',
             border: '2px solid white',
             borderRadius:"4px",
             boxShadow: 24,
             pt: 3,
             px: 3,
             pb: 1.5,
             overflow:"scroll",
             whiteSpace:"nowrap",
             overflowX:"hidden"
            //  textAlign:"center" 
            }}>
          <Stack direction="row" justifyContent="space-between">
            <Typography sx={{fontFamily:'Product Sans',fontStyle:"normal",fontWeight:"600",fontSize:"28px",lineHeight:"33px",color:"#000000"}}>Quotation Overview</Typography>
            {quotationStatus=="Approved" && 
            <Link to="/pdf-for-quotation" state={{project_id:id,quotation_id:quotationId}}> 
              <Button variant="contained" sx={{background:"#0F5DAE",border:"1px solid #EFF0F7",boxShadow:"0px 2px 6px rgba(19, 18, 66, 0.07)",borderRadius:"5px",border:'1px solid #0F5DAE',marginLeft:"25px"}}
              startIcon={<PictureAsPdfIcon/>}>
              <Box sx={{ textTransform: "capitalize",margin:"0px 10px"}}>Generate PDF</Box>
            </Button>
            </Link>}
          </Stack>

           <Box sx={{alignItems:"center",margin:"40px 0px 40px 0px"}}>
            <Stack direction="row" justifyContent="start" alignItems="center" sx={{margin:"30px 0px 0px 0px",fontSize: "18px"}}>
                <Typography component="span" className='field-name' sx={{width:"15%",fontFamily:'Product Sans',fontStyle:"normal",fontWeight:"500",fontSize:"18px",lineHeight:"21px",color:"#000000"}}>Customer Name</Typography>
                <TextField value={leadName} disabled
                 sx={{
                     width:"325px",
                     marginLeft:"40px"
                    }}/>
            </Stack>
            <Stack direction="row" justifyContent="start" alignItems="center" sx={{margin:"30px 0px 0px 0px",fontSize: "18px"}}>
                <Typography component="span" className='field-name' sx={{width:"15%",fontFamily:'Product Sans',fontStyle:"normal",fontWeight:"500",fontSize:"18px",lineHeight:"21px",color:"#000000"}}>Project Name</Typography>
                <TextField value={projectInfo.name} disabled
                 sx={{
                     width:"325px",
                     marginLeft:"40px"
                    }}/>
            </Stack>
            <Stack direction="row" justifyContent="start" alignItems="center" sx={{margin:"30px 0px 0px 0px",fontSize: "18px"}}>
                <Typography component="span" className='field-name' sx={{width:"15%",fontFamily:'Product Sans',fontStyle:"normal",fontWeight:"500",fontSize:"18px",lineHeight:"21px",color:"#000000"}}>Site Address</Typography>
                <TextField value={projectInfo.address} disabled
                 sx={{
                     width:"325px",
                     marginLeft:"40px"
                    }}/>
            </Stack>
            <Stack direction="row" justifyContent="start" alignItems="center" sx={{margin:"30px 0px 0px 0px",fontSize: "18px"}}>
                <Typography component="span" className='field-name' sx={{width:"15%",fontFamily:'Product Sans',fontStyle:"normal",fontWeight:"500",fontSize:"18px",lineHeight:"21px",color:"#000000"}}>City</Typography>
                <TextField value={projectInfo.city} disabled
                 sx={{
                     width:"325px",
                     marginLeft:"40px"
                    }}/>
            </Stack>
            <Stack direction="row" justifyContent="start" alignItems="center" sx={{margin:"30px 0px 0px 0px",fontSize: "18px"}}>
                <Typography component="span" className='field-name' sx={{width:"15%",fontFamily:'Product Sans',fontStyle:"normal",fontWeight:"500",fontSize:"18px",lineHeight:"21px",color:"#000000"}}>State</Typography>
                <TextField value={projectInfo.state} disabled
                 sx={{
                     width:"325px",
                     marginLeft:"40px"
                    }}/>
            </Stack>
            <Stack direction="row" justifyContent="start" alignItems="center" sx={{margin:"30px 0px 0px 0px",fontSize: "18px"}}>
                <Typography component="span" className='field-name' sx={{width:"15%",fontFamily:'Product Sans',fontStyle:"normal",fontWeight:"500",fontSize:"18px",lineHeight:"21px",color:"#000000"}}>Pin Code</Typography>
                <TextField value={projectInfo.pincode} disabled
                 sx={{
                     width:"325px",
                     marginLeft:"40px"
                    }}/>
            </Stack>
             <Stack direction="row" justifyContent="start" alignItems="center" sx={{margin:"30px 0px 0px 0px",fontSize: "18px"}}>
                <Typography component="span" className='field-name' sx={{width:"15%",fontFamily:'Product Sans',fontStyle:"normal",fontWeight:"500",fontSize:"18px",lineHeight:"21px",color:"#000000"}}>Unit Number</Typography>
                <TextField value={unitOverviewName} disabled
                 sx={{
                     width:"325px",
                     marginLeft:"40px"
                    }}/>
            </Stack>
            </Box>
            
            <Box sx={{}}>
              <Box sx={{background:"#0F5DAE",borderRadius:"4px",height:"50px",justifyContent:"center",alignItems:"center",display:"flex"}}>
                <Typography sx={{fontFamily:'Product Sans',fontStyle:"normal",fontWeight:"700",fontSize:"20px",lineHeight:"20px",color:"#FFFFFF"}}>Pricing Details</Typography>
              </Box>

              <Stack direction="row" sx={{width:"100%",display:"flex",flexWrap:'wrap',height:"325px",overflow:"scroll",whiteSpace:"nowrap",overflowX:"hidden"}}>

                {
                  unitOverviewCharge?.map((ele,idx)=>{
                    return(
                      <Box sx={{width:"420px",boxShadow:"0px 1px 97px rgba(3, 12, 21, 0.1)",borderRadius:"0px 0px 10px 10px",margin:"0px 5px 10px 5px"}}>
                  <Box sx={{background:"linear-gradient(90deg, #0F5DAE 0%, #009DCF 100.33%)",borderRadius:"4px",height:"40px",alignItems:"center",display:"flex",width:"50%",borderRadius:"0px 100px 0px 0px",margin:"20px"}}>
                  <Typography sx={{fontFamily:'Product Sans',fontStyle:"normal",fontWeight:"500",fontSize:"18px",lineHeight:"20px",color:"#FFFFFF",margin:"0px 20px 0px 20px"}}>{ele?.titlename}</Typography>
                  </Box>
                 
                  <Box sx={{margin:"20px"}}>
                    {ele?.field?.map((e)=>{
                    return(
                      <Stack direction="row" sx={{marginBottom:"10px"}} >
                        <Typography sx={{width:"50%",fontFamily:'Poppins',fontStyle:"normal",fontWeight:"500",fontSize:"18px",lineHeight:"27px",color:"#A2A3A6"}}>{e?.key}</Typography>
                        <CurrencyRupeeIcon sx={{fontWeight:"500",fontSize:"18px",lineHeight:"27px",marginTop:"3px"}}/>
                        <Typography>{e?.value}</Typography>
                    </Stack>
                    )
                    })}
                  </Box>
                  <Box sx={{margin:"20px"}}>
                    <Stack direction="row" sx={{}} >
                      <Typography sx={{width:"50%",fontFamily:'Poppins',fontStyle:"normal",fontWeight:"500",fontSize:"18px",lineHeight:"27px",color:"#FF6140"}}>Section Cost</Typography>
                      <CurrencyRupeeIcon sx={{fontWeight:"500",fontSize:"18px",lineHeight:"27px",marginTop:"3px"}}/>
                      <Typography>{ele?.sectioncost}</Typography>
                    </Stack>
                  </Box>
                </Box>
                    )
                  })
                }
              </Stack>
            </Box>

            <Box sx={{background:"linear-gradient(90deg, #0F5DAE 0%, #009DCF 100.33%)",borderRadius:"4px",height:"40px",alignItems:"center",display:"flex",width:"20%",         borderRadius:"0px 100px 0px 0px",margin:"20px 20px 20px 0px"}}>
            <Typography sx={{fontFamily:'Product Sans',fontStyle:"normal",fontWeight:"500",fontSize:"18px",lineHeight:"20px",color:"#FFFFFF",margin:"0px 20px 0px 20px"}}>Adjustment</Typography>
            </Box>

            <Stack direction="row" justifyContent="start" alignItems="center" sx={{margin:"30px 0px 0px 0px",fontSize: "18px"}}>
                <Typography component="span" className='field-name' sx={{width:"15%",fontFamily:'Product Sans',fontStyle:"normal",fontWeight:"500",fontSize:"18px",lineHeight:"21px",color:"#000000"}}>Valid Till</Typography>
                <TextField disabled
                value={validOverview}
                type="Date"
                 sx={{
                     width:"188px",
                     marginLeft:"40px"
                    }}/>
            </Stack>

            <Box sx={{margin:"30px 20px 20px 0px", width:"50%"}}>
              <Stack direction="row" sx={{}} >
                <Typography sx={{width:"41%",fontFamily:'Poppins',fontStyle:"normal",fontWeight:"500",fontSize:"18px",lineHeight:"27px",color:"#FF6140"}}>Grand Total</Typography>
                <CurrencyRupeeIcon sx={{fontWeight:"500",fontSize:"18px",lineHeight:"27px",marginTop:"3px"}}/>
                <Typography>{grandTotalOverview}</Typography>
              </Stack>
            </Box>
            {quotationStatus=="Pending" && 
              <>
              <Button variant="contained" sx={{background:"#0F5DAE",border:"1px solid #EFF0F7",boxShadow:"0px 2px 6px rgba(19, 18, 66, 0.07)",borderRadius:"4px",  border:'1px solid #0F5DAE',margin:"20px 0px"}} onClick={()=>{ApproveOrReject("Approved")}}>
              {
                loadingSubmitApprove?<CircularProgress color="inherit" sx={{ padding:"0px 20px"}}/>:<Box sx={{ textTransform: "capitalize",margin:"0px 7px"}}>Approve</Box>
              }
            </Button>
            <Button variant="contained" sx={{background:"white",border:"1px solid #EFF0F7",boxShadow:"0px 2px 6px rgba(19, 18, 66, 0.07)",borderRadius:"4px",  border:'1px solid   #0F5DAE',margin:"20px 20px",color:"#0F5DAE","&:hover": {background:"white",color:"#0F5DAE"
              }}} onClick={()=>{ApproveOrReject("Rejected")}}>
              {
                loadingSubmitReject?<CircularProgress color="inherit" sx={{ padding:"0px 20px"}}/>:<Box sx={{ textTransform: "capitalize",margin:"0px 15px"}}>Reject</Box>
              }
            </Button>  
            </>
            }
          </Box>
       
      </Modal>


        {/*Quotation Generation Modal */}
        {/* Parent Modal  */}
        <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
        >
        <Box sx={{position: 'absolute',
             top: '50%',
             left: '50%',
             transform: 'translate(-50%, -50%)',
             width: "70%",
             height:"80%",
             bgcolor: 'white',
             border: '2px solid white',
             borderRadius:"4px",
             boxShadow: 24,
             pt: 3,
             px: 3,
             pb: 1.5,
             overflow:"scroll",
             whiteSpace:"nowrap",
             overflowX:"hidden"
            //  textAlign:"center"
            }}>
          <Typography sx={{fontFamily:'Product Sans',fontStyle:"normal",fontWeight:"600",fontSize:"28px",lineHeight:"33px",color:"#000000"}}>Quotation Generation Form</Typography>
            <Box>
              <Stack
                direction="row"
                justifyContent="start"
                alignItems="center"
                sx={{margin:"35px 0px 20px -10px"}}
              >
                <Radio
                  required
                  name="search_type"
                  {...controlProps("email")}
                  sx={{
                    color:"linear-gradient(264.48deg, #79F1A4 -45.37%, #0E5CAD 88.97%)",
                    color: "#0F5DAE",
                  }}
                />
                <Typography sx={{fontFamily:'Product Sans',fontStyle:"normal",fontWeight:"500",fontSize:"18px",lineHeight:"21px",color:"#000000",marginRight:"20px"}}>Email</Typography>
                <Radio
                  required
                  name="search_type"
                  {...controlProps("phone")}
                  sx={{
                    color:"linear-gradient(264.48deg, #79F1A4 -45.37%, #0E5CAD 88.97%)",
                    color: "#0F5DAE",
                  }}/>
                <Typography sx={{fontFamily:'Product Sans',fontStyle:"normal",fontWeight:"500",fontSize:"18px",lineHeight:"21px",color:"#000000"}}>Phone Number</Typography>
              </Stack>
            </Box>
            
            <Box sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "start",
              alignItems: "center",
              color: "#0065FE",
              // border:'1px solid green'
              }}>
              <TextField
                onChange={(e)=>{
                  setinput(e.target.value);
                }}
                value={input}
                variant="standard"
                placeholder={selectedValue=="email"?"Search by Email":"Search by Phone"}
                sx={{boxShadow:"0px 2px 6px rgba(19, 18, 66, 0.07)",borderRadius:"10px 0px 0px 10px",border:'1px solid #2979F2',width:"45%"}}
                InputProps={{disableUnderline: true,startAdornment: <SearchRoundedIcon sx={{ color: "#2979F2", margin: 1 }}/>}}/>
              <Button variant="contained" sx={{background:"#0F5DAE",border:"1px solid #EFF0F7",boxShadow:"0px 2px 6px rgba(19, 18, 66, 0.07)",borderRadius:"0px 10px 10px 0px",border:'1px solid #0F5DAE'}}
              onClick={searchLead}>
                <Box sx={{ textTransform: "capitalize",margin:"7px"}}>Search</Box>
              </Button>
        
              <Button variant="contained" sx={{background:"#0F5DAE",border:"1px solid #EFF0F7",boxShadow:"0px 2px 6px rgba(19, 18, 66, 0.07)",borderRadius:"5px",border:'1px solid #0F5DAE',marginLeft:"25px"}}
              startIcon={<AddIcon/>}
              onClick={handleOpenChildLead}>
                <Box sx={{ textTransform: "capitalize",margin:"6px 0px"}}>Create New</Box>
              </Button>

            </Box>

            {error && <Typography sx={{fontFamily:'Poppins',fontStyle:"normal",fontWeight:"500",fontSize:"14px",lineHeight:"21px",color:"#FF6140",margin:"7px 0px 0px 10px"}}>No Lead Found</Typography>}

            
           <Box sx={{alignItems:"center",margin:"40px 0px 40px 0px"}}>
            <Stack direction="row" justifyContent="start" alignItems="center" sx={{margin:"30px 0px 0px 0px",fontSize: "18px"}}>
                <Typography component="span" className='field-name' sx={{width:"15%",fontFamily:'Product Sans',fontStyle:"normal",fontWeight:"500",fontSize:"18px",lineHeight:"21px",color:"#000000"}}>Customer Name</Typography>
                <TextField value={selectedLead?.name?selectedLead?.name:""} disabled
                 sx={{
                     width:"325px",
                     marginLeft:"40px"
                    }}/>
            </Stack>
            <Stack direction="row" justifyContent="start" alignItems="center" sx={{margin:"30px 0px 0px 0px",fontSize: "18px"}}>
                <Typography component="span" className='field-name' sx={{width:"15%",fontFamily:'Product Sans',fontStyle:"normal",fontWeight:"500",fontSize:"18px",lineHeight:"21px",color:"#000000"}}>Project Name</Typography>
                <TextField value={projectInfo.name} disabled
                 sx={{
                     width:"325px",
                     marginLeft:"40px"
                    }}/>
            </Stack>
            <Stack direction="row" justifyContent="start" alignItems="center" sx={{margin:"30px 0px 0px 0px",fontSize: "18px"}}>
                <Typography component="span" className='field-name' sx={{width:"15%",fontFamily:'Product Sans',fontStyle:"normal",fontWeight:"500",fontSize:"18px",lineHeight:"21px",color:"#000000"}}>Site Address</Typography>
                <TextField value={projectInfo.address} disabled
                 sx={{
                     width:"325px",
                     marginLeft:"40px"
                    }}/>
            </Stack>
            <Stack direction="row" justifyContent="start" alignItems="center" sx={{margin:"30px 0px 0px 0px",fontSize: "18px"}}>
                <Typography component="span" className='field-name' sx={{width:"15%",fontFamily:'Product Sans',fontStyle:"normal",fontWeight:"500",fontSize:"18px",lineHeight:"21px",color:"#000000"}}>City</Typography>
                <TextField value={projectInfo.city} disabled
                 sx={{
                     width:"325px",
                     marginLeft:"40px"
                    }}/>
            </Stack>
            <Stack direction="row" justifyContent="start" alignItems="center" sx={{margin:"30px 0px 0px 0px",fontSize: "18px"}}>
                <Typography component="span" className='field-name' sx={{width:"15%",fontFamily:'Product Sans',fontStyle:"normal",fontWeight:"500",fontSize:"18px",lineHeight:"21px",color:"#000000"}}>State</Typography>
                <TextField value={projectInfo.state} disabled
                 sx={{
                     width:"325px",
                     marginLeft:"40px"
                    }}/>
            </Stack>
            <Stack direction="row" justifyContent="start" alignItems="center" sx={{margin:"30px 0px 0px 0px",fontSize: "18px"}}>
                <Typography component="span" className='field-name' sx={{width:"15%",fontFamily:'Product Sans',fontStyle:"normal",fontWeight:"500",fontSize:"18px",lineHeight:"21px",color:"#000000"}}>Pin Code</Typography>
                <TextField value={projectInfo.pincode} disabled
                 sx={{
                     width:"325px",
                     marginLeft:"40px"
                    }}/>
            </Stack>
             <Stack direction="row" justifyContent="start" alignItems="center" sx={{margin:"30px 0px 0px 0px",fontSize: "18px"}}>
                <Typography component="span" className='field-name' sx={{width:"15%",fontFamily:'Product Sans',fontStyle:"normal",fontWeight:"500",fontSize:"18px",lineHeight:"21px",color:"#000000"}}>Unit Number</Typography>
                <TextField value={selectedUnit?.name} disabled
                 sx={{
                     width:"325px",
                     marginLeft:"40px"
                    }}/>
                <Button onClick={handleOpenChild} sx={{margin:"0px 5px"}}><Box sx={{ textTransform: "capitalize",fontFamily:'Product Sans',fontStyle:"normal",fontWeight:"500",fontSize:"16px",lineHeight:"20px",color:"#FF6140"}}>Select</Box></Button>
            </Stack>
            </Box>
            
            <Box sx={{}}>
              <Box sx={{background:"#0F5DAE",borderRadius:"4px",height:"50px",justifyContent:"center",alignItems:"center",display:"flex"}}>
                <Typography sx={{fontFamily:'Product Sans',fontStyle:"normal",fontWeight:"700",fontSize:"20px",lineHeight:"20px",color:"#FFFFFF"}}>Pricing Details</Typography>
              </Box>

              <Stack direction="row" sx={{width:"100%",display:"flex",flexWrap:'wrap',height:"325px",overflow:"scroll",whiteSpace:"nowrap",overflowX:"hidden"}}>

                {
                  unitCharge?.map((ele,idx)=>{
                    return(
                      <Box sx={{width:"420px",boxShadow:"0px 1px 97px rgba(3, 12, 21, 0.1)",borderRadius:"0px 0px 10px 10px",margin:"0px 5px 10px 5px"}}>
                  <Box sx={{background:"linear-gradient(90deg, #0F5DAE 0%, #009DCF 100.33%)",borderRadius:"4px",height:"40px",alignItems:"center",display:"flex",width:"50%",borderRadius:"0px 100px 0px 0px",margin:"20px"}}>
                  <Typography sx={{fontFamily:'Product Sans',fontStyle:"normal",fontWeight:"500",fontSize:"18px",lineHeight:"20px",color:"#FFFFFF",margin:"0px 20px 0px 20px"}}>{ele?.titlename}</Typography>
                  </Box>
                 
                  <Box sx={{margin:"20px"}}>
                    {ele?.field?.map((e)=>{
                    return(
                      <Stack direction="row" sx={{marginBottom:"10px"}} >
                        <Typography sx={{width:"50%",fontFamily:'Poppins',fontStyle:"normal",fontWeight:"500",fontSize:"18px",lineHeight:"27px",color:"#A2A3A6"}}>{e?.key}</Typography>
                        <CurrencyRupeeIcon sx={{fontWeight:"500",fontSize:"18px",lineHeight:"27px",marginTop:"3px"}}/>
                        <Typography>{e?.value}</Typography>
                    </Stack>
                    )
                    })}
                  </Box>
                  <Box sx={{margin:"20px"}}>
                    <Stack direction="row" sx={{}} >
                      <Typography sx={{width:"50%",fontFamily:'Poppins',fontStyle:"normal",fontWeight:"500",fontSize:"18px",lineHeight:"27px",color:"#FF6140"}}>Section Cost</Typography>
                      <CurrencyRupeeIcon sx={{fontWeight:"500",fontSize:"18px",lineHeight:"27px",marginTop:"3px"}}/>
                      <Typography>{ele?.sectioncost}</Typography>
                    </Stack>
                  </Box>
                </Box>
                    )
                  })
                }

              </Stack>
            </Box>

            <Box sx={{background:"linear-gradient(90deg, #0F5DAE 0%, #009DCF 100.33%)",borderRadius:"4px",height:"40px",alignItems:"center",display:"flex",width:"20%",         borderRadius:"0px 100px 0px 0px",margin:"20px 20px 20px 0px"}}>
            <Typography sx={{fontFamily:'Product Sans',fontStyle:"normal",fontWeight:"500",fontSize:"18px",lineHeight:"20px",color:"#FFFFFF",margin:"0px 20px 0px 20px"}}>Adjustment</Typography>
            </Box>

             <Box>
              <Stack
                direction="row"
                justifyContent="start"
                alignItems="center"
                sx={{margin:"20px 0px 20px -10px"}}
              >
                <Radio
                  required
                  name="search_type"
                  {...controlPropsAdjustment("flatDiscount")}
                  sx={{
                    color:"linear-gradient(264.48deg, #79F1A4 -45.37%, #0E5CAD 88.97%)",
                    color: "#0F5DAE",
                  }}
                />
                <Typography sx={{fontFamily:'Product Sans',fontStyle:"normal",fontWeight:"500",fontSize:"18px",lineHeight:"21px",color:"#000000",marginRight:"20px"}}>Flat Discount</Typography>
                <Radio
                  required
                  name="search_type"
                  {...controlPropsAdjustment("percentDiscount")}
                  sx={{
                    color:"linear-gradient(264.48deg, #79F1A4 -45.37%, #0E5CAD 88.97%)",
                    color: "#0F5DAE",
                  }}/>
                <Typography sx={{fontFamily:'Product Sans',fontStyle:"normal",fontWeight:"500",fontSize:"18px",lineHeight:"21px",color:"#000000"}}>Percentage Discount</Typography>
              </Stack>
            </Box>
            {
              selectedAdjustment=="flatDiscount"?
              <Stack direction="row" justifyContent="start" alignItems="center" sx={{margin:"30px 0px 0px 0px",fontSize: "18px"}}>
                <Typography component="span" className='field-name' sx={{width:"15%",fontFamily:'Product Sans',fontStyle:"normal",fontWeight:"500",fontSize:"18px",lineHeight:"21px",color:"#000000"}}>Flat Discount</Typography>
                <CurrencyRupeeIcon sx={{color:"#000000",marginLeft:"0px"}}/>
                <TextField  onChange={grandTotalSetting}
                value={discount}
                 type="number"
                 inputProps={{ min: 0 , max:{unchangedTotal}}}
                 sx={{
                     width:"188px",
                     marginLeft:"16px"
                    }}/>
              </Stack>:
               <Stack direction="row" justifyContent="start" alignItems="center" sx={{margin:"30px 0px 0px 0px",fontSize: "18px"}}>
                <Typography component="span" className='field-name' sx={{width:"15%",fontFamily:'Product Sans',fontStyle:"normal",fontWeight:"500",fontSize:"18px",lineHeight:"21px",color:"#000000"}}>Percentage Discount</Typography>
                <TextField  onChange={grandTotalSetting}
                value={discount}
                 type="number"
                 inputProps={{ min: 0 , max:100}}
                 sx={{
                     width:"188px",
                     marginLeft:"40px"
                    }}/>
                <PercentIcon sx={{color:"#000000",marginLeft:"10px"}}/>
            </Stack>

            }

            <Stack direction="row" justifyContent="start" alignItems="center" sx={{margin:"20px 0px 0px 0px",fontSize: "18px"}}>
                <Typography component="span" className='field-name' sx={{width:"15%",fontFamily:'Product Sans',fontStyle:"normal",fontWeight:"500",fontSize:"18px",lineHeight:"21px",color:"#000000"}}>Valid Till</Typography>
                <TextField onChange={(e)=>{
                  setDate(e.target.value);
                }}
                value={date}
                type="Date"
                 sx={{
                     width:"188px",
                     marginLeft:"40px"
                    }}/>
            </Stack>

            <Box sx={{margin:"40px 20px 20px 0px", width:"50%"}}>
              <Stack direction="row" sx={{}} >
                <Typography sx={{width:"41%",fontFamily:'Poppins',fontStyle:"normal",fontWeight:"500",fontSize:"18px",lineHeight:"27px",color:"#FF6140"}}>Grand Total</Typography>
                <CurrencyRupeeIcon sx={{fontWeight:"500",fontSize:"18px",lineHeight:"27px",marginTop:"3px"}}/>
                <Typography>{grandTotal}</Typography>
              </Stack>
            </Box>

            <Button variant="contained" sx={{background:"#0F5DAE",border:"1px solid #EFF0F7",boxShadow:"0px 2px 6px rgba(19, 18, 66, 0.07)",borderRadius:"4px",  border:'1px solid #0F5DAE',margin:"20px 0px"}} onClick={onSubmit}>
              {
                loadingSubmit?<CircularProgress color="inherit" sx={{ padding:"0px 20px"}}/>:<Box sx={{ textTransform: "capitalize",margin:"0px 7px"}}>Submit</Box>
              }
              {/*  */}
            </Button> 

        {/* <ChildModal /> */}
        {/* Child Modal For Selecting Unit */}
        <Modal
        hideBackdrop
        open={openChild}
        onClose={handleCloseChild}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
        >
        <Box sx={{position: 'absolute',
             top: '50%',
             left: '50%',
             transform: 'translate(-50%, -50%)',
             width: "20%",
             height:"auto",
             bgcolor: 'white',
             border: '2px solid white',
             borderRadius:"10px",
             boxShadow: 24,
             pt: 3,
             px: 3,
             pb: 1.5,
             textAlign:"center"
            }}>
            
            <Stack direction="row" sx={{justifyContent:"space-between"}}>
              <Typography sx={{fontFamily:'Product Sans',fontStyle:"normal",fontWeight:"600",fontSize:"20px",lineHeight:"21px",color:"#000000"}}>Select Unit</Typography>
              <Box sx={{textAlign:"right"}}>
                <GridCloseIcon sx={{color:"gray",cursor:"pointer"}} onClick={returnPrevious}/>
              </Box>
            </Stack>

            <Stack direction="row" justifyContent="start" alignItems="center" sx={{margin:"30px 0px 20px 0px",fontSize: "18px",justifyContent:"center"}}>
              <Typography component="span" className='field-name' sx={{width:"35%",fontFamily:'Product Sans',fontStyle:"normal",fontWeight:"500",fontSize:"18px",lineHeight:"21px",color:"#000000"}}>Select Unit</Typography>
              <FormControl sx={{width:"125px", marginLeft:"20px"}}>
                <Select
                  displayEmpty
                  inputProps={{ 'aria-label': 'Without label' }}
                  sx={{height:"45px"}}
                   value={tempUnit.name+"-"+tempUnit.id}
                          onChange={(e) => {
                            let [name,id] = e.target.value.split("-");
                            let obj={};
                            obj.name = name;
                            obj.id = id;
                            setTempUnit(obj);
                          }}
                  >
                  {
                    fetchedUnit.map((ele,index)=>{
                      return <MenuItem key={index} value={ele.name+"-"+ele.id}>{ele?.name}</MenuItem>
                    })
                  }
                </Select>
              </FormControl>
            </Stack>
           
          <Button variant="contained" onClick={selectCharges} sx={{background:"#0F5DAE",border:"1px solid #EFF0F7",boxShadow:"0px 2px 6px rgba(19, 18, 66, 0.07)",borderRadius:"4px",  border:'1px solid #0F5DAE',margin:"20px 0px"}}>
            <Box sx={{ textTransform: "capitalize",margin:"0px 7px"}}>Submit</Box>
          </Button> 
    
        </Box>
      </Modal>
      
      {/* Child Modal for Creating Lead */}
      <Modal
        hideBackdrop
        open={openChildLead}
        onClose={handleCloseChildLead}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
        >
        <Box sx={{position: 'absolute',
             top: '55%',
             left: '50%',
             transform: 'translate(-50%, -50%)',
             width: "30%",
             height:"60%",
             bgcolor: 'white',
             border: '2px solid white',
             borderRadius:"10px",
             boxShadow: 24,
             pt: 3,
             px: 3,
             pb: 1.5,
             overflow:"scroll",
             whiteSpace:"nowrap",
             overflowX:"hidden",
             textAlign:""
            }}>
            
            <Stack direction="row" sx={{justifyContent:"space-between"}}>
              <Typography sx={{fontFamily:'Product Sans',fontStyle:"normal",fontWeight:"600",fontSize:"20px",lineHeight:"21px",color:"#000000"}}>Create New Lead</Typography>
              <Box sx={{textAlign:"right"}}>
                <GridCloseIcon sx={{color:"gray",cursor:"pointer"}} onClick={()=>{handleCloseChildLead();
                  setCreateLeadEmail("");
                  setCreateLeadName("");
                  setCreateLeadPhone("");
                  setCreateLeadPriority("");
                  }}/>
              </Box>
            </Stack>

            <Stack direction="column" justifyContent="start" sx={{margin:"20px 0px 20px 0px",fontSize: "18px"}}>
              <Typography component="span" className='' sx={{width:"35%",fontFamily:'Product Sans',fontStyle:"normal",fontWeight:"500",fontSize:"18px",lineHeight:"21px",color:"#000000"}}>Name</Typography>
              <TextField onChange={(e)=>{setCreateLeadName(e.target.value)}}
                value={createLeadName}
                 sx={{
                     width:"100%",
                     margin:"10px 0px"
                    }}/>
            </Stack>

             <Stack direction="column" justifyContent="start" sx={{margin:"20px 0px 20px 0px",fontSize: "18px"}}>
              <Typography component="span" className='field-name' sx={{width:"35%",fontFamily:'Product Sans',fontStyle:"normal",fontWeight:"500",fontSize:"18px",lineHeight:"21px",color:"#000000"}}>Phone</Typography>
              <TextField onChange={(e)=>{setCreateLeadPhone(e.target.value)}}
                placeholder="+91 9999999999"
                value={createLeadPhone}
                 sx={{
                     width:"100%",
                     margin:"10px 0px"
                    }}/>
            </Stack>

            <Stack direction="column" justifyContent="start" sx={{margin:"20px 0px 20px 0px",fontSize: "18px"}}>
              <Typography component="span" className='field-name' sx={{width:"35%",fontFamily:'Product Sans',fontStyle:"normal",fontWeight:"500",fontSize:"18px",lineHeight:"21px",color:"#000000"}}>Email</Typography>
              <TextField onChange={(e)=>{setCreateLeadEmail(e.target.value)}}
                value={createLeadEmail}
                 sx={{
                     width:"100%",
                     margin:"10px 0px"
                    }}/>
            </Stack>
            
            <Stack direction="column" justifyContent="start" sx={{margin:"20px 0px 20px 0px",fontSize: "18px"}}>
              <Typography component="span" className='field-name' sx={{width:"35%",fontFamily:'Product Sans',fontStyle:"normal",fontWeight:"500",fontSize:"18px",lineHeight:"21px",color:"#000000"}}>Priority</Typography>
              <TextField
                id="outlined-select-currency"
                  select
                  value={createLeadPriority}
                  onChange={(e)=>{setCreateLeadPriority(e.target.value)}}
                  sx={{ width: "100%",margin:"10px 0px"}}
                >
                {allpriority &&
                  allpriority.map((option) => (
                  <MenuItem key={option.id} value={option.id}>
                    {option.name}
                  </MenuItem>
                  ))}
              </TextField>
            </Stack>

            <Box sx={{textAlign:"center"}}>
              <Button variant="contained" onClick={selectCharges} sx={{background:"#0F5DAE",border:"1px solid #EFF0F7",boxShadow:"0px 2px 6px rgba(19, 18, 66, 0.07)",borderRadius:"4px",  border:'1px solid #0F5DAE',margin:"15px 0px 30px 0px"}}>
                <Box sx={{ textTransform: "capitalize",margin:"0px 7px"}} onClick={createNewLead}>Submit</Box>
              </Button> 
            </Box>
    
        </Box>
      </Modal>

      </Box>
      </Modal>

        <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            sx={{ height: "68px", marginBottom: "20px" }}
          >
            <Stack direction="column" alignItems="center">
              <Typography
                sx={{
                  fontSize: "22px",
                  fontWeight: "600",
                  marginLeft: "-45px",
                  color: "black",
                }}
              >
                Quotation Directory
              </Typography>
              <Typography
                sx={{
                  color: "#B9B7D0",
                  fontSize: "12px",
                  fontWeight: "400",
                  paddingLeft: "20px",
                }}
              >
                Here is your Quotation Directory list data
              </Typography>
            </Stack>
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Stack
                direction="row"
                justifyContent="space-around"
                alignItems="center"
                sx={{
                  width: "203px",
                  height: "68px",
                  borderRadius: "12px",
                  color: "#3E4954",
                  backgroundColor: "#f4f5f9",
                }}
              >

               <Accordion sx={{ width: "250px", boxShadow: "none" }}>
              <AccordionSummary
                expandIcon={<GridExpandMoreIcon/>}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography sx={{ display: "flex", placeItems: "center" }}>
                  <TuneOutlinedIcon sx={{ color: "#2F4CDD" }} />
                  <Typography sx={{ marginLeft: "25px" }}>
                    Fillters
                  </Typography>
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Box sx={{textAlign:"center"}}>
                  <FormControl sx={{ width: "200px" }}>
                    <InputLabel id="demo-simple-select-label">
                      Select
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={filterselect}
                      label="Select"
                      onChange={handleChange}
                    >
                      <MenuItem value={"created_on"}>Created Date</MenuItem>
                      <MenuItem value={"valid_till"}>Valid Date</MenuItem>
                      {/* <MenuItem value={"Name"}>Name</MenuItem> */}
                    </Select>
                  </FormControl>
                  <TextField type="date"
                    sx={{ marginTop: "20px" }}
                    placeholder="Enter the value" 
                    value={filtervalue} onChange={(e)=>{setfiltervalue(e.target.value)}}
                  />
                  <Button 
                    onClick={fetchfilter}
                    variant="contained"
                    color="primary" sx={{ height:"40px", background: "linear-gradient(90deg, #0F5DAE 0%, #009DCF 100.33%)",boxShadow: "0px 13.7981px 22.5788px rgba(50, 174, 137, 0.25)", textTransform: "none", borderRadius: "4px", width:"100px", fontSize:"15px",margin:"20px 0px 10px 0px"}}>
                     Submit
                  </Button>

                </Box>
              </AccordionDetails>
            </Accordion>

              </Stack>
            </Stack>
          </Stack>

          <QDcustomTable 
          newRowData={newRowData} 
          delIconClick={delIconClick} 
          loading={loading} 
          handleOpenOverview={handleOpenOverview}
          setGrandTotalOverview={setGrandTotalOverview}
          setLeadName={setLeadName}
          setValidOverview={setValidOverview}
          setUnitOverviewCharge={setUnitOverviewCharge}
          setUnitOverviewName={setUnitOverviewName}
          setLeadId={setLeadId}
          setUnitId={setUnitId}
          setQuotationId={setQuotationId}
          handleOpenEdit={handleOpenEdit}
          setDateEdit={setDateEdit}
          setQuotationStatus={setQuotationStatus}
          />

    </Stack>




      </Stack>
</Stack>
        
        </>
    )
}