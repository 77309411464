import { Check } from "@mui/icons-material";
import { Checkbox, FormControlLabel, Radio, TextField } from "@mui/material";
import CheckInput from "./Check";
import DoubleTrailing from "./DoubleTrailing";
import { NormalInput } from "./NormalInput";
import RadioInput from "./Radio";
import WithTrailingInput from "./withTrailing";

export const GetItemInput = (p: any) => {
  console.log("get Item props", p)
  if (p == null) {
    return <div></div>;
  }

  // setdata()
  let props = p;
  switch (props.type) {
    case "normal":
      return (
        <NormalInput
          setData={(e: any) => p.setData(e)}
          deleteSelf={() => {
            p.deleteSelf();
          }}
          setShow={() => {
            p.setShow();
          }}
        />
      );

    case "withtrailing":
      return (
        <WithTrailingInput
          setData={(e: any) => p.setData(e)}
          deleteSelf={() => {
            p.deleteSelf();
          }}
          setShow={() => {
            p.setShow();
          }}
        />
      );
    case "radio":
      return (
        <RadioInput
          setData={(e: any) => p.setData(e)}
          deleteSelf={() => {
            p.deleteSelf();
          }}
          setShow={() => {
            p.setShow();
          }}
        />
      );
    case "check":
      return (
        <CheckInput
          setData={(e: any) => p.setData(e)}
          deleteSelf={() => {
            p.deleteSelf();
          }}
          setShow={() => {
            p.setShow();
          }}
        />
      );

    case "doubletrailing":
      return (
        <DoubleTrailing
          setData={(e: any) => p.setData(e)}
          deleteSelf={() => {
            p.deleteSelf();
          }}
          setShow={() => {
            p.setShow();
          }}
        ></DoubleTrailing>
      );

    default:
      return <div>{props.type}</div>;
  }
};
