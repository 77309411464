import { Stack } from "@mui/system"
import axios from "axios"
import { useEffect, useState } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import { Apiaddress } from "../../../../utils/consts"
import { NavbarForDirectory } from "../../../BuilderComponents/ProjectDashboard/DashboardComponents/NavbarForDirectory"
import { ProjectDashboardSidebar } from "../../../BuilderComponents/ProjectDashboard/DashboardComponents/ProjectDashboardSidebar"
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import Pagination from '@mui/material/Pagination';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';
import { Box, Button, Checkbox, TextField, Typography } from "@mui/material";
import DoneIcon from '@mui/icons-material/Done';

export const EditBalcony = ()=>{
    const navigate = useNavigate();
    const location = useLocation();
    let prevUnitId = location.state;
    console.log("unit id: ", prevUnitId)
    
    const projectfromlocal = JSON.parse(localStorage.getItem("BuilderSelected"))
    const projectID = projectfromlocal.selectedProjectID

    ////state for update layout data;
    
   let [current_data, setCurrentData] = useState({})
   const [balconyData,setbalconyData]=useState([]);
//    const [balconyData,setbalconyData]=useState([]);
   
  const addbalcony=()=>{
    let temp=balconyData.length+1;
    let to_insert={
      balcony_no:temp,
      length:"",
      breadth:""
    }
    setbalconyData([...balconyData,to_insert]);
  }
 
  const updatenumber=(newbalconyData,index)=>{
    for(let i=index;i<newbalconyData.length;i++){
      newbalconyData[i].bedroom_no=newbalconyData[i].bedroom_no-1;
    }
    // console.log(newbalconyData);
    setbalconyData(newbalconyData);
  }

 
///get unit Details from database 
const [unitName,setUnitName] = useState("")
useEffect(()=>{
  const uniturl = Apiaddress+"/api/projects/unit/?unit_id="+prevUnitId
 axios.get(uniturl)
 .then((res)=>{
      const data = res?.data
      console.log("unit data=>>>>>>>.",data)
      setUnitName(data.unit[0].name)
      setbalconyData(data?.unit[0].layout_data.balconyData)
        setCurrentData(data?.unit[0].layout_data)
   })
   .catch((err)=>{
     console.log(err)
   })
   
},[])


const label = { inputProps: { "aria-label": "Checkbox demo" } };

 //function for create unit Templates
 const createBalconyTemplate = ()=>{

    setCurrentData({...current_data,balconyData});
    console.log({...current_data,balconyData}); 

  const uniturl = Apiaddress+"/api/projects/unit/?unit_id="+prevUnitId
  
  let st = {
    unit_id:prevUnitId,
    layout_data:{...current_data,balconyData}
  }

  let headers = {
    headers:{
      "Content-Type":"application/json"
  }
  }
//   console.log(st);

  axios.patch(uniturl,st,headers)
  .then((res)=>{
    console.log("add balcony in unit======>", res.data);
    navigate("/unit-directory-w-sb/edit")
  })
  .catch((err)=>{
    console.log(err);
  })

  }
    return(
        <>
                <Stack direction="row" sx={{width:"100%",height:"auto" }}>
      {/* 1st stack */}
      <Stack sx={{minHeight:"100vh", width:"22%",boxSizing:"border-box",}}>
      <ProjectDashboardSidebar active={"Unit Directory"}/>
      </Stack>

     {/* 2nd stack */}
      <Stack direction="column" sx={{ backgroundColor:"#f9f9f9",width:"78%", padding:"25px",boxSizing:"border-box",}}  >
      {/* <Navbar/> */}
      <NavbarForDirectory
            leftRightTitle1={"Unit Dashboard"}
            leftRightTitle2={"Unit Directory"}
            leftTitle={"Project Dashboard"}
            showLeftTitle={false}
            righshow={true}
          />
         

    {/* <Typography sx={{mt:"20px", fontSize:"22px", fontWeight:"500",color:"#2979F2"}}>
    Project Name
    </Typography> */}
     {/* actual content  */}
     <Stack sx={{ mt:"28px",backgroundColor:"#f9f9f9",}}>

     <Stack direction="column"  sx={{  padding:"20px", backgroundColor:"#fff", marginRight:"0px"}}>

     <Stack direction="row" spacing={1.5}>
     <Stack direction="row" alignItems="center" spacing={1.5} sx={{margin:"70px 0px 70px 0px"}}>
     <Box  sx={{width:"29px", height:"29px",borderRadius:"50%", backgroundColor:"#FFA946", display:"flex", justifyContent:"center", alignItems:"center"}}>
        <DoneIcon sx={{color:"#fff", fontSize:"20px"}}/>
      </Box>
      <Typography sx={{fontSize:"18px", fontWeight:"500"}}>{projectfromlocal.projectName}</Typography>
    </Stack>
    <Stack direction="row" alignItems="center" spacing={1.5} sx={{margin:"70px 0px 70px 0px"}}>
     <Box  sx={{width:"29px", height:"29px",borderRadius:"50%", backgroundColor:"#FFA946", display:"flex", justifyContent:"center", alignItems:"center"}}>
        <DoneIcon sx={{color:"#fff", fontSize:"20px"}}/>
      </Box>
      <Typography sx={{fontSize:"18px", fontWeight:"500"}}>{unitName}</Typography>
    </Stack>
    <Stack direction="row" alignItems="center" spacing={1.5} sx={{margin:"70px 0px 70px 0px"}}>
     <Box  sx={{width:"29px", height:"29px",borderRadius:"50%", backgroundColor:"#FFA946", display:"flex", justifyContent:"center", alignItems:"center"}}>
        <DoneIcon sx={{color:"#fff", fontSize:"20px"}}/>
      </Box>
      <Typography sx={{fontSize:"18px", fontWeight:"500"}}>{"Balcony"}</Typography>
    </Stack>
     </Stack>

     <Box sx={{}}>
              <Stack
                direction="row"
                sx={{
                  margin: "0px 0px 0px 0px",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Typography sx={{ fontSize: "18px", fontWeight: "500" }}>
                  Balcony 
                </Typography>
                <AddIcon
                  sx={{
                    color: "#A2A3A6",
                    fontSize: "18px",
                    marginLeft: "10px",
                    cursor: "pointer",
                  }}
                  onClick={addbalcony}
                />
              </Stack>

              {balconyData?.map((ele, index) => {
                return (
                  <Stack
                    direction="row"
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      margin: "20px 0px 0px 0px",
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: "18px",
                        fontWeight: "500",
                        color: "#A2A3A6",
                      }}
                    >
                      {index+1}
                    </Typography>
                    <TextField
                    type="number" inputProps={{ min: 0 }}
                      sx={{
                        width: "188px",
                        marginLeft: "10px",
                      }}
                      value={ele.length}
                      onChange={(event) => {
                        setbalconyData((prev) => {
                          return prev.filter((curvalue, idx) => {
                            if (idx == index) {
                              curvalue.length = event.target.value;
                            }
                            return curvalue;
                          });
                        });
                      }}
                    />
                    <Typography
                      sx={{
                        fontSize: "18px",
                        fontWeight: "500",
                        color: "#A2A3A6",
                        margin: "0px 0px 0px 10px",
                      }}
                    >
                      L
                    </Typography>
                    <CloseIcon
                      sx={{
                        fontSize: "22px",
                        fontWeight: "500",
                        color: "#A2A3A6",
                        margin: "0px 0px 0px 20px",
                      }}
                    />
                    <TextField
                    type="number" inputProps={{ min: 0 }}
                      sx={{
                        width: "188px",
                        marginLeft: "10px",
                      }}
                      value={ele.breadth}
                      onChange={(event) => {
                        setbalconyData((prev) => {
                          return prev.filter((curvalue, idx) => {
                            if (idx == index) {
                              curvalue.breadth = event.target.value;
                            }
                            return curvalue;
                          });
                        });
                      }}
                    />
                    <Typography
                      sx={{
                        fontSize: "18px",
                        fontWeight: "500",
                        color: "#A2A3A6",
                        margin: "0px 0px 0px 10px",
                      }}
                    >
                      B
                    </Typography>
                    {/* <Checkbox
                      {...label}
                      sx={{ color: "#A2A3A6", margin: "0px 0px 0px 20px" }}
                    />
                    <Typography
                      sx={{
                        fontSize: "18px",
                        fontWeight: "500",
                        color: "#A2A3A6",
                        margin: "0px 0px 0px 10px",
                      }}
                    >
                      Attached
                    </Typography> */}
                    <DeleteIcon
                      sx={{
                        fontSize: "26px",
                        fontWeight: "500",
                        color: "#EF3649",
                        margin: "0px 0px 0px 30px",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        const newbalconyData = balconyData.filter(
                          (x) => x !== ele
                        );
                        console.log(newbalconyData);
                        updatenumber(newbalconyData, index);
                      }}
                    />
                  </Stack>
                );
              })}

              <Stack direction="row" sx={{alignItems:"center"}}>

              <Box
              onClick={()=>{
                navigate(-1)
              }}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  margin: "0px 0px 0px 0px",
                }}
              >
                <ArrowBackIcon />
                <Typography
                  sx={{
                    fontSize: "18px",
                    fontWeight: "500",
                    margin: "0px 10px 0px 10px",
                  }}
                >
                  Back
                </Typography>
              </Box>

              <Box sx={{margin:"60px 0px 60px 60px"}}>

              {/* <Link to={`/builder-edit-project-unit`} state={floorData}
                onClick={()=>{
                createBalconyTemplate()
              }}
            > */}
                <Button 
            onClick={()=>{
             createBalconyTemplate()
                  }}
                className="univercel_btn_class"
                variant="contained"
                sx={{padding:"16px 50px 16px 50px", color:"#fff",fontSize:"18px", fontWeight:"700",height:"50px"}}>
                    Save 
                </Button>
              {/* </Link> */}

            </Box>
            </Stack>


            </Box>

     </Stack>

     </Stack>
 


      </Stack>
</Stack>
        </>
    )
}