import { Box, Typography } from '@mui/material'
import React from 'react'

export function HistogramItem({ percentage,
    color,
    showBackground,
    height,
    leadingtext,
    trailing_text,
    vertical
}) {
    const background = showBackground ? "#F3F3F3" : null;
    return (
        <Box sx={{ display: "flex", flexDirection:!vertical?"row":"column" ,justifyContent: "space-between", minWidth: 20, margin: 1 }}>
            <Typography>
                {leadingtext}
            </Typography>

            <Box sx={{ height: vertical ? height : 20, width: vertical ? 20 : height, flex: 1, margin: "1px" }}>

                <Box sx={{ width: vertical ? 20 : "100%", height: vertical ? "100%" : 20, backgroundColor: background, borderRadius: vertical?8:8,borderEndStartRadius:vertical?8:null , transform:vertical ?"rotate(0.5turn)":""}}>
                    <Box sx={{ width: vertical ? 20 : `${percentage}%`, height: vertical ? `${percentage}%` : 20, borderRadius: vertical?8:8,borderStartEndRadius:vertical?8:null,borderEndStartRadius:vertical?8:null, backgroundImage: color }} />
                </Box>
            </Box>
            <Typography>
                {trailing_text}
            </Typography>
        </Box>
    )
}


export function VaccencyStatusHistogram({ booked, paid, vacant }) {
    const data = [
        {
            value: booked,
            color: "linear-gradient(60deg, #FF4267 13.4%, #FF7A93 86.6%)",
            leading: "Booked",
        },
        {
            value: paid,
            color: "linear-gradient(97.85deg, #FFAF2A 0.95%, #FFC666 100%)",
            leading: "Paid",
        },
        {
            value: vacant,
            color: "linear-gradient(96.67deg, #0085FF 1.65%, #65C5FF 96.59%)",
            leading: "Vacant",
        },
    ]

    const percentageCalculator = (x) => {
        const total = booked + paid + vacant
        if(total==0){
            return total;
        }
        return ((x / total) * 100).toFixed(1)
    }

    return (
        <Box sx={{}}>
            {
                data.map(e =>
                    <HistogramItem percentage={percentageCalculator(e.value)} color={e.color} height={40} leadingtext={`${e.leading} (${percentageCalculator(e.value)})%`} trailing_text={e.value} showBackground />)}
        </Box>
    )
}


export const QuestionMarkChart = ({ datakey, data }) => {
    let total = 0;
    data.map(e => total += e[datakey])
    const steps = [4, 3, 2, 1]
    return (
        <Box
            sx={{
                boxShadow: 5,
                margin: 5,
                padding: 2,
                width: "45%",
                height: 150,
                borderRadius: 2,
                display: "flex",
                justifyContent: "space-between",
                flexDirection: "row"

            }}>
            <Box sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-start"
            }}>
                <Box>
                    {
                        steps.map(e => <Typography>
                            {(Math.round((total / e) * 100) / 100).toFixed(0)}
                        </Typography>).reverse()
                    }
                </Box>
            </Box>
            <Box sx={{
                    height:150,
                    width:"85%",
                    padding:2,
                    display:"flex",

                }}>
                {
                        [19,39,53,24,29,20,101,70,13,50].map(e => 
                        <HistogramItem color={e % 2 == 0 ? "linear-gradient(97.85deg, #FFAF2A 0.95%, #FFC666 100%)" : "linear-gradient(60deg, #FF4267 13.4%, #FF7A93 86.6%)"}
                            height={110}
                            percentage={e}
                            trailing_text={"jan"}
                            vertical
                        />
                    )
                }
            </Box>
        </Box>
    )
}
