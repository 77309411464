import {
  Backdrop,
  Box,
  Button,
  Fade,
  IconButton,
  Modal,
  Snackbar,
  Typography,
} from "@mui/material";
import { Stack } from "@mui/system";
import { GridCloseIcon } from "@mui/x-data-grid";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { AuthService } from "../../../context/buyer/services/auth";
import { BlueButton } from "../../../utils/common/BlueButton";
import { net } from "../../../utils/consts";
import { EnvisionLogo } from "../../BuilderComponents/BuilderDasboard/assets/icons";
import { BasicLayout } from "./LeadsDirectory/basic_layout";
import { GetSection } from "./LeadsDirectory/RequirementFormTemp";
import {
  // GetSection,
  UpdateAtIndex,
} from "./LeadsDirectory/RequirementFormTemplate";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  border: "2px solid #fff",
  borderRadius: "10px",
  boxShadow: 24,
  width: "250px",
  p: 4,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  gap: "15px",
};

const FillRequirement = () => {
  const navigate = useNavigate();
  const params = useParams();
  const requirementID = params.id;
  const Auth = new AuthService();
  let user, username;
  try {
    user = JSON.parse(localStorage.getItem("crm_user") || "");
    username = user?.user_details?.first_name;
    console.log(user?.user_details?.first_name);
  } catch (e) {
    console.log(e);
  }

  const time = new Date();

  // // console.log(time);

  const [FormData, setFormData] = useState({});

  const fetchRequirement = async () => {
    const res = await (
      await Auth.makeUnAuthenticatedGetRequest(
        net(`leads/requirement/?requirement_id=${requirementID}`)
      )
    ).json();
    // // console.log(res?.requirement);
    setFormData(res.requirement);
  };

  useEffect(() => {
    fetchRequirement();
  }, []);
  // const handleClose = (
  //   event: React.SyntheticEvent | Event,
  //   reason?: string
  // ) => {
  //   if (reason === "clickaway") {
  //     return;
  //   }

  //   setOpen(false);
  // };

  const [BasicLayoutState, setBasicLayoutState] = useState<any>();
  const [OtherLayout, setOtherLayout] = useState<[]>([]);

  // // console.log("====", BasicLayoutState);
  // // console.log("====", OtherLayout);

  // const action = (
  //   <React.Fragment>
  //     <IconButton
  //       size="small"
  //       aria-label="close"
  //       color="inherit"
  //       onClick={handleClose}
  //     >
  //       <GridCloseIcon fontSize="small" />
  //     </IconButton>
  //   </React.Fragment>
  // );

  const [open, setOpen] = React.useState(false);
  const [clicked, setClicked] = useState(false);
  return (
    <>
      <Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "column", md: "row" },
            justifyContent: { md: "space-between" },
            alignItems: { md: "center" },
            // my: { md: "10px" },
            my: "10px",
            mt: { xs: "15px" },
            mx: 2,
          }}
        >
          <Box onClick={() => navigate("/")}>
            <EnvisionLogo />
          </Box>
          <Box
            sx={{
              width: "79%",
              mt: { xs: "15px", md: "0px" },
            }}
          >
            <Typography
              sx={{
                fontSize: "1.5em",
                fontWeight: "600",
              }}
            >
              Requirement Form
            </Typography>
            <Stack direction="row">
              <Typography
                sx={{
                  color: "#B9B7D0",
                  fontSize: { xs: "10px", md: "14px" },
                  fontWeight: "400",
                  marginRight: "8px",
                }}
              >
                Please fill out the requirement form sent by
              </Typography>
              <Typography
                sx={{
                  color: "#2979F2",
                  fontSize: { xs: "10px", md: "14px" },
                  fontWeight: "550",
                }}
              >
                {//@ts-ignore
                FormData?.channelPartner?.profile?.user_details?.first_name ??
                  "NA"}
              </Typography>
            </Stack>
          </Box>
        </Box>
      </Box>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          margin: "10px",
        }}
      >
        <Box
          className="hidescrollbar"
          sx={{
            marginTop: 2,
            width: { xs: "100%", md: "60%" },
            px: "30px",
            pb: "30px",
            background: "white",
            borderRadius: "10px",
            minHeight: "75vh",
            overflowY: "scroll",
            color: "black",
            border: "1px solid #2979F2",
            boxSizing: "border-box",
            "&:hover": {
              boxShadow: "-6px 18px 15px rgba(0, 0, 0, 0.1)",
            },
          }}
        >
          <GetSection
            page={"fill"}
            Section={BasicLayout}
            setSuperState={setBasicLayoutState}
          ></GetSection>
          {console.log({ FormData })}
          {//@ts-ignore
          FormData?.other_details?.map((e: any, index: number) => {
            return (
              <GetSection
                page={"fill"}
                Section={e}
                setSuperState={(e: any) => {
                  //@ts-ignore
                  setOtherLayout(UpdateAtIndex(OtherLayout, index, e));
                }}
              ></GetSection>
            );
          })}
        </Box>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {//@ts-ignore
          FormData.replyed ? (
            <Typography
              sx={{
                color: "green",
                fontWeight: 700,
                mt: 5,
                mb: 5,
                fontFamily: "Product Sans",
              }}
            >
              Response Already Sent
            </Typography>
          ) : (
            <Button
              disabled={clicked || !BasicLayoutState}
              sx={{
                padding: "10px 20px",
                color: "#06386B",
                textTransform: "none",
                mt: 4,
                fontSize: "20px",
                fontFamily: "Product Sans",
                background:
                  "linear-gradient(97.85deg, #FFAF2A 0.95%, #FFC666 100%)",
                "&:hover": {
                  boxShadow: "0px 9px 12px rgba(242, 178, 73, 0.59)",
                },
                "&.Mui-disabled": {
                  background: "#cccccc",
                },
              }}
              onClick={() => {
                const Auth = new AuthService();
                if (!BasicLayoutState) {
                  return;
                }
                delete BasicLayoutState.page;
                Auth.makeUnAuthenticatedPatchRequest(
                  net("leads/requirement/"),
                  {
                    JsonBody: {
                      requirement_id: requirementID,
                      basic_details: BasicLayoutState,
                      other_details: OtherLayout,
                      replyed: true,
                      update_on: new Date(),
                    },
                  }
                );
                setOpen(true);
                setClicked(true);
              }}
            >
              {clicked ? "Submitted" : "Submit"}
            </Button>
          )}
        </div>
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={clicked}
          // onClose={handleClose}
          closeAfterTransition
        >
          <Fade in={clicked}>
            <Box sx={style}>
              <Typography
                sx={{
                  fontFamily: "Product Sans",
                  fontSize: "18px",
                  fontWeight: "600",
                }}
              >
                Requirement Form Submitted
              </Typography>
              {/* <Box sx={{ textAlign: "right" }}>
                  <GridCloseIcon
                  sx={{ color: "gray", cursor: "pointer" }}
                  // onClick={())}
                  />
                </Box> */}
              <Typography
                sx={{
                  mb: "10px",
                }}
              >
                Thank you
              </Typography>
              <Button
                onClick={() => {
                  navigate("/", { replace: true });
                }}
                sx={{
                  // width: "40%",
                  color: "#06386B",
                  textTransform: "none",
                  fontFamily: "Product Sans",
                  ml: 1,
                  "&:hover": {
                    boxShadow: "0px 9px 12px rgba(242, 178, 73, 0.59)",
                  },
                }}
                className="univercel_btn_class"
              >
                Go to home page
              </Button>
            </Box>
          </Fade>
        </Modal>
        {/* <Snackbar
          open={open}
          autoHideDuration={60000}
          onClose={handleClose}
          message="Requirement Form Saved"
          action={action}
        /> */}
      </div>
    </>
  );
};

export default FillRequirement;
