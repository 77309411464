import { Box, Button, Stack, TextField, Typography } from "@mui/material";
import SearchRoundedIcon from "@mui/icons-material/SearchRounded";
import { useContext, useEffect, useRef, useState } from "react";
import "./carousel.css";
import builderDashboard from "../../../../context/builder_dashboard/context";
import { Apiaddress, net } from "../../../../utils/consts";
import buyerContext from "../../../../context/buyer/context";
import CPC from "../../../ChannelPartner/context/context";
import { useNavigate } from "react-router-dom";
import { AuthService } from "../../../../context/buyer/services/auth";
import Lottie from "react-lottie";
import axios from "axios";
import animationData from "../../../../Lotties/NEW sin movs.json";
import CircularProgress from "@mui/material/CircularProgress";

const ProjectSlider = ({ update, setUpdate }) => {
  
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [loading, setloading] = useState(false);

  //for search functionality
  const [searchData, setsearchdata] = useState([]);   

  const cpDetails = JSON.parse(localStorage.getItem("cpDetails"));
  console.log(cpDetails);
  let cpOrg = cpDetails?.channelPartnerOrg;

  const OrganizationsContext = useContext(builderDashboard);
  const previousState = JSON.parse(localStorage.getItem("BuilderDashboard"));
  console.log("=================>", OrganizationsContext.BuilderDashboardState);
  const bc = useContext(buyerContext);
  const cps = useContext(CPC);

  const user = JSON.parse(localStorage.getItem("crm_user"));
  let username = user?.user_details?.first_name;
  console.log(user?.user_details?.first_name);

  let builderSelected = JSON.parse(localStorage.getItem("BuilderSelected"));

  const setBuilderSelectedProject = (id) => {
    let data = {
      selectedProjectID: id,
      selectedProjectType: true,
    };
    if (builderSelected) {
      localStorage.setItem(
        "BuilderSelected",
        JSON.stringify({ ...builderSelected, ...data })
      );
    } else {
      localStorage.setItem("BuilderSelected", JSON.stringify(data));
    }
  };

  useEffect(() => {
    setloading(true);
    let newDat = [];
    if (JSON.parse(localStorage.getItem("crm_user"))["role_type"] === "BUILDER")
      OrganizationsContext.BuilderDashboardState?.map((e) => {
        if (e.selected) {
          e.Projects?.map((ele, i) => {
            let tempName;
            if (OrganizationsContext.setSelectedProjectId === undefined) {
              const projectID = e.Projects[i].project.id;
              OrganizationsContext.setSelectedProjectId(projectID);
            }
            tempName=e.Projects[i].project.name.charAt(0).toUpperCase() + e.Projects[i].project.name.slice(1);
              console.log("project====");
            return {
              banner_img: ele.project.banner_image,
              id: e.Projects[i].project.id,
              img:
                "https://images.unsplash.com/photo-1618505497364-f97e23c8b70a?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=386&q=80",
              name: tempName,
              city: e.Projects[i].project.city,
            };
          })?.map((b) => newDat.push(b));
          setData(newDat);
          setsearchdata(newDat);
        }
      });
    if (JSON.parse(localStorage.getItem("crm_user"))["role_type"] === "BUYER") {
      const x = async () => {
        const y = await bc.server.projects.getBuyerProjects();
        console.log("projects", y);
        setData(y);
        setsearchdata(y);
      };
      x();
    }
    if (JSON.parse(localStorage.getItem("crm_user"))["role_type"] ==="CHANNELPARTNER") {
      let tempBuilder = [];
      let tempSelf = [];
      let overall = [];
      const BuilderProject = async () => {
        const y = await cps.server.projects.getListOfProjects();
        console.log(y);
        tempBuilder = y;
        for (let i = 0; i < tempBuilder?.length; i++) {
          let obj = { ...tempBuilder[i], builderInvited: true };
          overall.push(obj);
        }
      };
      const selfProject = async () => {
        const auth = new AuthService();
        let url = "";
        console.log(cpOrg);
        if (cpOrg) {
          url = `role/inventeryofcp/?orgId=${cpOrg}`;
        } else {
          url = `role/inventeryofcp/`;
        }
        const res1 = await (
          await auth.makeAuthenticatedGetRequest(net(url))
        ).json();
        console.log(res1?.self["filtured Data"]?.projects);
        tempSelf = res1?.self["filtured Data"]?.projects;

        for (let i = 0; i <tempSelf?.length; i++) {
          const res = await axios.get(
            Apiaddress + `/api/projects/project/?project_id=${tempSelf[i].id}`
          );
          let temp=res?.data?.projects[0];
          let obj = { ...temp, builderInvited: false };
          overall.push(obj);
        }
        console.log(overall);
        setData(overall);
        setsearchdata(overall);
      };
      selfProject();
    }
    // setloading(false);
  }, [update]);

  useEffect(() => {
    if (data?.length > 0 ?? false) {
      console.log("", data[0].id);
      setUpdate(data[0].id);
    }
  }, [data]);

  const setSelectedProject = (e) => {
    spsp(e);
    setUpdate(data[e].id);
  };

  const [selectedProject, spsp] = useState(0);

  ///searching function
  const [input, setinput] = useState("");
  const searchProject = (val) => {
    setloading(true);
    if (val === "") {
      setData(searchData);
      return;
    }
    let value = val.toLowerCase();
    let result = searchData.filter((data) => {
      console.log(
        "lllll",
        data?.name,
        data?.name?.toLowerCase().includes(value)
      );
      return data?.name?.toLowerCase().includes(value);
    });
    setData([...result]);
    setloading(false);
  };

  return (
    <>
      <Stack
        direction="row"
        justifyContent=""
        alignItems="center"
        sx={{ marginBottom: "24px" }}
      >
        <Stack direction="row">
          <Typography className="WelcomeText" sx={{ fontSize: "24px",fontWeight:"600",marginRight:"8px"}}>
            Hello,
          </Typography>
          <Typography className="WelcomeText" sx={{ fontSize: "24px" }}>
            {username}
          </Typography>
        </Stack>
      </Stack>

      <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              sx={{ marginBottom: "32px" }}
             >
              <Stack>
                <Typography
                  sx={{
                    fontSize: "22px",
                    fontWeight: "600",
                    color: "black",
                  }}
                >
                  Dashboard
                </Typography>
                <Typography
                  sx={{
                    color: "#B9B7D0",
                    fontSize: "16px",
                    fontWeight: "400",
                    width:"100%",
                   
                  }}
                >
                 Select a Project to get started 
                </Typography>
              </Stack>
            </Stack>
      <Box
        className="box-main"
        sx={{
          padding: "30px",
          backgroundColor: "#FFF",
          border: "2px solid transparent",
          "&:hover": {
            boxShadow: "-6px 18px 15px rgba(0, 0, 0, 0.1)",
          },
        }}
      >
        <ProjectCarousel
          children={
            data?.map((e, i) => (
              <Box
                onClick={() => {
                  setSelectedProject(i);
                  OrganizationsContext.setSelectedProjectId(e.id);
                  let builderSelected = JSON.parse(
                    localStorage.getItem("BuilderSelected")
                  );
                  let data = {
                    selectedProjectID: e.id,
                    projectName: e.name,
                  };
                  if (builderSelected) {
                    localStorage.setItem(
                      "BuilderSelected",
                      JSON.stringify({ ...builderSelected, ...data })
                    );
                  } else {
                    localStorage.setItem(
                      "BuilderSelected",
                      JSON.stringify(data)
                    );
                  }
                  if (e.builderInvited == true) {
                    navigate("/channelpartner/details");
                  } else {
                    localStorage.setItem("BuilderSelectedProject", e.id);
                    navigate("/channelpartner/project/unit/");
                  }
                }}
                sx={{
                  width: "200px",
                  height: "300px",
                  backgroundColor: "#fff",
                  margin: "25px 15px",
                  padding: "15px",
                  // color: "#9299BC",
                  color: "#272D4E",
                  border: "2px solid transparent",
                  "&:hover": {
                    cursor: "pointer",
                    // border: "2px solid #2979F2",
                    boxShadow: "-6px 18px 15px rgba(0, 0, 0, 0.1)",
                    // borderRadius: "8px",
                  },
                }}
              >
                <Box>
                  <img
                    // src={"http://139.59.70.194/media/projectbanner/Screenshot_5.png"}
                    src={
                      (e.banner_image
                        ? `${Apiaddress}${e.banner_image}`
                        : e.img) ??
                      "https://images.unsplash.com/photo-1618505497364-f97e23c8b70a?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=386&q=80"
                    }
                    style={{
                      width: "100%",
                      height: "180px",
                      borderRadius: "10px",
                    }}
                  />
                  <Box
                    sx={{
                      fontFamily: "Circular Std",
                      fontSize: "18px",
                      mt: "20px",
                    }}
                  >
                    {e.name.charAt(0).toUpperCase() + e.name.slice(1)}
                  </Box>
                  <Box
                    sx={{
                      fontFamily: "Product Sans",
                      fontSize: "14px",
                      mt: "5px",
                      color: "#9299BC",
                    }}
                  >
                    {e.city}
                  </Box>
                </Box>
              </Box>
            )) ?? []
          }
          searchProject={searchProject}
          data={data}
          loading={loading}
          setloading={setloading}
        />
      </Box>
    </>
  );
};

export default ProjectSlider;

const ProjectCarousel = (props) => {
  const { children, searchProject, data,loading,setloading } = props;
  const [offset, setOffset] = useState(0);
  const [touchPosition, setTouchPosition] = useState(null);

  /// show all projects
  const [showall, setshowAll] = useState(false);

  const navigate = useNavigate();

  useEffect(()=>{
    setloading(false);
  },[data])

  // Set the length to match current children from props

  const next = () => {
    if ((children.length - 1) * 175 > offset) {
      setOffset((prevState) => prevState + 175);
    }
  };

  const prev = () => {
    if (offset > 0) {
      setOffset((prevState) => prevState - 175);
    }
  };

  const handleTouchStart = (e) => {
    const touchDown = e.touches[0].clientX;
    setTouchPosition(touchDown);
  };

  const handleTouchMove = (e) => {
    const touchDown = touchPosition;

    if (touchDown === null) {
      return;
    }

    const currentTouch = e.touches[0].clientX;
    const diff = touchDown - currentTouch;

    if (diff > 5) {
      next();
    }

    if (diff < -5) {
      prev();
    }

    setTouchPosition(null);
  };

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  
  console.log(loading);
  console.log(data);

  return (
    <>
      <Box
        sx={{
          // height: 100,
          width: "100%",
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          color: "#0065FE",
          marginBottom: "10px",
          marginLeft:"20px"
        }}
      >
        <Stack direction="column">
        <Typography
          className=""
          sx={{
            color: "#4E5A85",
            fontSize: "24px",
            fontWeight: "500",
            display: "flex",
            fontFamily: "Circular Std",
          }}
        >
          My Projects
        </Typography>
        <Typography
                  sx={{
                    color: "#B9B7D0",
                    fontSize: "16px",
                    fontWeight: "400",
                  }}
                >
                 To get started, please select the desired project from the list
                </Typography>
        </Stack>
        
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            alignItems: "end",
          }}
        >
          <Box
            sx={{
              paddingRight: "10px",
              cursor: "pointer",
              fontSize: "18px",
              color: "#0085FF",
              paddingBottom: "10px",
              fontWeight: "400",
            }}
            onClick={() => {
              setshowAll(!showall);
            }}
          >
            {showall ? "Show Less" : "Show All"}
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "end",
              alignItems: "center",
              color: "#0065FE",
            }}
          >
            <TextField
              sx={{
                // border: "2px solid #2979F2",
                marginRight: "20px",
                borderRadius: "8px",
                "& .MuiInputBase-root": {
                  "& fieldset": {
                    borderColor: "#2979F2",
                    borderRadius: "8px",
                  },
                  "&:hover fieldset": {
                    borderColor: "#2979F2",
                  },
                },
              }}
              onChange={(e) => {
                searchProject(e.target.value);
              }}
              // variant="standard"
              variant="outlined"
              placeholder="Search"
              InputProps={{
                disableUnderline: true,
                startAdornment: (
                  <SearchRoundedIcon sx={{ color: "#2979F2", margin: 1 }} />
                ),
              }}
            />

            {!showall && (
              <Button
                onClick={prev}
                sx={{
                  minWidth: 40,
                  height: 30,
                  border: "1px solid #0065FE",
                  borderRadius: 2,
                  margin: 1,
                }}
              >
                <svg
                  width="9"
                  height="16"
                  viewBox="0 0 6 10"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M5.1646 9.28016C4.97597 9.28016 4.78734 9.21015 4.63843 9.06015L1.1339 5.53016C0.845992 5.24016 0.845992 4.76016 1.1339 4.47016L4.63843 0.940156C4.92633 0.650156 5.40287 0.650156 5.69078 0.940156C5.97869 1.23016 5.97869 1.71016 5.69078 2.00016L2.71243 5.00016L5.69078 8.00016C5.97869 8.29016 5.97869 8.77015 5.69078 9.06015C5.55179 9.21015 5.36316 9.28016 5.1646 9.28016Z"
                    fill="#2979F2"
                  />
                </svg>
              </Button>
            )}

            {!showall && (
              <Button
                onClick={next}
                sx={{
                  minWidth: 40,
                  height: 30,
                  border: "1px solid #0065FE",
                  borderRadius: 2,
                  margin: 1,
                }}
              >
                <svg
                  width="9"
                  height="16"
                  viewBox="0 0 6 10"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1.48869 9.28016C1.30006 9.28016 1.11143 9.21015 0.962513 9.06015C0.674605 8.77015 0.674605 8.29016 0.962513 8.00016L3.94086 5.00016L0.962513 2.00016C0.674605 1.71016 0.674605 1.23016 0.962513 0.940156C1.25042 0.650156 1.72696 0.650156 2.01486 0.940156L5.51939 4.47016C5.8073 4.76016 5.8073 5.24016 5.51939 5.53016L2.01486 9.06015C1.86595 9.21015 1.67732 9.28016 1.48869 9.28016Z"
                    fill="#2979F2"
                  />
                </svg>
              </Button>
            )}
          </Box>
        </Box>
      </Box>

      {!showall && (
        <Box className="carousel-container" sx={{ minHeight: "120px" }}>
          <Box className="carousel-wrapper">
            <Box
              className="carousel-content-wrapper"
              onTouchStart={handleTouchStart}
              onTouchMove={handleTouchMove}
            >
              <Box
                className="carousel-content"
                style={{ transform: `translateX(-${offset}px)` }}
                sx={{ color: "black" }}
                >
                {children}  
                {/* {data ? (
                  data[0] ? null : ( */}
                {data ? (
                  data?.length!==0 ? null : (
                    <>
                    {loading ? (
                    <>
                      <Box sx={{ textAlign: "center", marginTop: "40px",width:"100%"}}>
                        <CircularProgress
                          color="inherit"
                          sx={{ color: "grey.500" }}
                        />
                      </Box>
                    </>
                    ):
                    <Stack
                      direction="column"
                      alignItems="center"
                      justifyContent="center"
                      sx={{ color: "gray", width: "100%",height:"100%"}}
                      >
                      <Stack sx={{height:"100%"}}>
                      <Lottie options={defaultOptions} height="100%" width="30%" />
                      </Stack>
                      <Typography
                        sx={{
                          color: "#B9B7D0",
                          fontSize: "18px",
                          fontWeight: "400",
                          marginTop: "-30px",
                        }}
                      >
                      Please Add Your Project First
                      </Typography>
                    </Stack>}
                </>
                  )
                ) : null}
              </Box>
            </Box>
          </Box>
        </Box>
      )}
      {showall && (
        <Box
          sx={{
            width: "100%",
            minHeight: "200px",
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "start",
            gap: "10px",
          }}
        >
          {children}
          {/* {data[0] ? null : ( */}
          {data?.length!==0 ? null : (
            <>
            {loading ? (
            <>
              <Box sx={{ textAlign: "center", marginTop: "40px",width:"100%"}}>
                <CircularProgress
                  color="inherit"
                  sx={{ color: "grey.500" }}
                />
              </Box>
            </>
            ):<Stack
                direction="column"
                justifyContent="center"
                alignItems="center"
                sx={{ color: "gray", width: "100%" }}
                >
                <Stack sx={{height:"100%"}}>
                  <Lottie options={defaultOptions} height="100%" width="30%" />
                </Stack>
                <Typography
                  sx={{
                    color: "#B9B7D0",
                    fontSize: "18px",
                    fontWeight: "400",
                    marginTop: "-30px",
                  }}
                  >
                Please Add Your Project First
                </Typography>
            </Stack>}
            </>
          )}
        </Box>
      )}
    </>
  );
};

export { ProjectCarousel };
