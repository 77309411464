import { Backdrop, Box, Button, Fade, Modal, TextField, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import GroupsIcon from "@mui/icons-material/Groups";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import KeyboardArrowRightOutlinedIcon from "@mui/icons-material/KeyboardArrowRightOutlined";
import MessageOutlinedIcon from "@mui/icons-material/MessageOutlined";
import CallOutlinedIcon from "@mui/icons-material/CallOutlined";
import "./Sidebar.css";
import { Link, useNavigate } from "react-router-dom";
import { UserRole } from "../../../../UserRoles/UserRoles";
import MarkAsUnreadIcon from "@mui/icons-material/MarkAsUnread";
import InventoryIcon from "@mui/icons-material/Inventory";
import { EnvisionLogo } from "../../../BuilderComponents/BuilderDasboard/assets/icons";
import LogoutIcon from "@mui/icons-material/Logout";
import userContext from "../../../../context/profile/context";
import { useContext, useEffect, useState } from "react";
import GroupIcon from '@mui/icons-material/Group';
import Support from "./Support";
import { Style } from "@mui/icons-material";
import ReportProblemIcon from "@mui/icons-material/ReportProblem";
import { GridCloseIcon } from "@mui/x-data-grid";
import DeleteIcon from "@mui/icons-material/Delete";
import axios from "axios";
import { Apiaddress } from "../../../../utils/consts";

export const BuyerSidebar = ({ active }: { active: string }) => {
  ////get user roles from local storage and assign
  let roles = JSON.parse(localStorage.getItem("user_roles") ?? "");
  let cp = JSON.parse(localStorage.getItem("cpDetails") ?? "");
  let owner = cp["owner"];
  let account_type = cp["account_type"];
  let cp_type = cp["cp_type"];
  // console.log(account_type);

  const [open, setOpen] = useState(false);
  const [match, setMatch] = useState(false);
  const [confirmText, setConfirmText] = useState("");
  const [openDelete, setOpenDelete] = useState(false);

  const handleCloseDelete = () => setOpenDelete(false);
  const handleOpenDelete = () => {
    setOpenDelete(true);
  }

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 450,
    bgcolor: "background.paper",
    border: "2px solid #fff",
    borderRadius: "10px",
    boxShadow: 24,
    p: 4,
  };


  const UC = useContext(userContext);

  let teams = roles["teams"];
  let buyers = roles["buyers"];

  let project = roles["project"];
  let org = roles["org"];
  let channelpartner = roles["channel partner"];
  let lead = roles["lead"];
  let media = roles["project media"];

  console.log("roles", UserRole(org, "view_org"));

  const navigate = useNavigate();

  const tabs = [
    {
      title: "Dashboard",
      active: active === "Dashboard",
      linkto: "/channelpartner/dashboard",
      icon: (
        <svg
          width="17"
          height="19"
          viewBox="0 0 17 19"
          className="sidebar_option_iconn"
          style={{
            fill: active == "Dashboard" ? "#1976D2" : "#8E97BA",
          }}
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M5.92 2.96C5.92 4.59476 4.59476 5.92 2.96 5.92C1.32524 5.92 0 4.59476 0 2.96C0 1.32524 1.32524 0 2.96 0C4.59476 0 5.92 1.32524 5.92 2.96ZM17.0001 2.96C17.0001 4.59476 15.6748 5.92 14.0401 5.92C12.4053 5.92 11.0801 4.59476 11.0801 2.96C11.0801 1.32524 12.4053 0 14.0401 0C15.6748 0 17.0001 1.32524 17.0001 2.96ZM14.0401 18.2208C15.6748 18.2208 17.0001 16.8955 17.0001 15.2608C17.0001 13.626 15.6748 12.3008 14.0401 12.3008C12.4053 12.3008 11.0801 13.626 11.0801 15.2608C11.0801 16.8955 12.4053 18.2208 14.0401 18.2208ZM5.92 15.2608C5.92 16.8955 4.59476 18.2208 2.96 18.2208C1.32524 18.2208 0 16.8955 0 15.2608C0 13.626 1.32524 12.3008 2.96 12.3008C4.59476 12.3008 5.92 13.626 5.92 15.2608Z"
          />
        </svg>
      ),
    },
    {
      title: "Inventory",
      active: active === "Inventory",
      linkto: "/channelpartner/inventory",
      icon: (
        // <svg
        //   width="17"
        //   height="19"
        //   viewBox="0 0 17 19"
        //   className="sidebar_option_iconn"
        //       style={{
        //         fill: active == "Inventory" ? "white" :"#8E97BA",
        //       }}
        //   xmlns="http://www.w3.org/2000/svg"
        // >
        //   <path
        //     fill-rule="evenodd"
        //     clip-rule="evenodd"
        //     d="M5.92 2.96C5.92 4.59476 4.59476 5.92 2.96 5.92C1.32524 5.92 0 4.59476 0 2.96C0 1.32524 1.32524 0 2.96 0C4.59476 0 5.92 1.32524 5.92 2.96ZM17.0001 2.96C17.0001 4.59476 15.6748 5.92 14.0401 5.92C12.4053 5.92 11.0801 4.59476 11.0801 2.96C11.0801 1.32524 12.4053 0 14.0401 0C15.6748 0 17.0001 1.32524 17.0001 2.96ZM14.0401 18.2208C15.6748 18.2208 17.0001 16.8955 17.0001 15.2608C17.0001 13.626 15.6748 12.3008 14.0401 12.3008C12.4053 12.3008 11.0801 13.626 11.0801 15.2608C11.0801 16.8955 12.4053 18.2208 14.0401 18.2208ZM5.92 15.2608C5.92 16.8955 4.59476 18.2208 2.96 18.2208C1.32524 18.2208 0 16.8955 0 15.2608C0 13.626 1.32524 12.3008 2.96 12.3008C4.59476 12.3008 5.92 13.626 5.92 15.2608Z"/>
        // </svg>
        <InventoryIcon
          className="sidebar_option_icon"
          sx={{
            fill: active == "Inventory" ? "#1976D2" : "#8E97BA",
          }}
        />
      ),
    },
    {
      title: "Leads",
      active: active === "Leads",
      linkto: "/channelpartner/leads",
      icon: (
        <GroupsIcon
          className="sidebar_option_icon"
          sx={{
            fill: active == "Leads" ? "#1976D2" : "#8E97BA",
          }}
        />
      ),
    },
    {
      title: "Clients",
      active: active === "Clients",
      linkto: "/channelpartner/clients",
      icon: (
        <PeopleAltIcon
          sx={{
            fill: active == "Clients" ? "#1976D2" : "#8E97BA",
          }}
          className="sidebar_option_icon"
        />
      ),
    },
    //   {title:"Appointments",active:active==="Appointments",linkto:"/channelpartner/Appointments",icon:<MarkAsUnreadIcon
    //   sx={{
    //    fill: active == "Appointments" ? "white" : "#8E97BA",
    //  }}
    //  className="sidebar_option_icon" />
    //   },
    {
      title: "Requirements",
      active: active === "Requirements",
      linkto: "/channelpartner/Requirements",
      icon: (
        <svg
          width="17"
          height="17"
          viewBox="0 0 17 17"
          className="sidebar_option_iconn"
          style={{
            fill: active == "Requirements" ? "#1976D2" : "#8E97BA",
          }}
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M7.71254 0.0195602C5.72087 0.213217 3.89905 1.07137 2.48481 2.48205C1.16339 3.80016 0.35721 5.41544 0.0618012 7.33677C-0.0206004 7.87263 -0.0206004 9.12931 0.0618012 9.66517C0.35701 11.5852 1.15648 13.1885 2.48039 14.5155C3.79865 15.8367 5.41411 16.6428 7.33567 16.9382C7.87159 17.0206 9.12841 17.0206 9.66433 16.9382C11.5846 16.643 13.1881 15.8437 14.5152 14.5199C15.8366 13.2018 16.6428 11.5865 16.9382 9.66517C17.0206 9.12931 17.0206 7.87263 16.9382 7.33677C16.643 5.4167 15.8435 3.81343 14.5196 2.48647C13.2103 1.17415 11.5757 0.354917 9.6976 0.0697872C9.28193 0.00668743 8.14178 -0.0221515 7.71254 0.0195602ZM9.83067 1.1025C10.9957 1.30348 12.2767 1.89183 13.2235 2.66063C14.3485 3.57427 15.1763 4.7694 15.6328 6.1393C16.8045 9.65553 15.2191 13.5083 11.9098 15.1866C11.0155 15.6402 10.1213 15.9057 9.25682 15.9745L8.999 15.995V8.50097V1.00697L9.25682 1.02746C9.3986 1.03874 9.65685 1.0725 9.83067 1.1025Z"
          />
        </svg>
      ),
    },

    {
      title: "Teams",
      active: active === "Teams",
      linkto: "/channelpartner/Teams",
      icon: (
        <GroupIcon
          sx={{
            fill: active == "Clients" ? "#1976D2" : "#8E97BA",
          }}
          className="sidebar_option_icon"
        />
        // <svg
        //   width="20"
        //   height="14"
        //   viewBox="0 0 20 14"
        //   className="sidebar_option_iconn"
        //   style={{
        //     fill: active == "Teams" ? "#1976D2" : "#8E97BA",
        //   }}
        //   fill="none"
        //   xmlns="http://www.w3.org/2000/svg"
        // >
        //   <path
        //     fill-rule="evenodd"
        //     clip-rule="evenodd"
        //     d="M7.50036 1.48018C5.92777 2.47205 5.11254 3.00836 5.06719 3.08093C4.9029 3.34393 4.99836 3.50965 5.4984 3.82949L5.90126 4.08714L3.0752 5.7638C1.43763 6.73533 0.193783 7.49768 0.117518 7.57653C0.00079499 7.6973 -0.0116748 7.73031 0.00712761 7.86917C0.0195583 7.96103 0.0658411 8.06165 0.11916 8.11276C0.169156 8.16061 2.37369 9.50483 5.01814 11.0999C9.50327 13.8052 9.83803 14 10.0021 14C10.1662 14 10.5008 13.8053 14.9861 11.0993C17.6306 9.50398 19.8335 8.16305 19.8816 8.11946C20.0044 8.00807 20.0368 7.79939 19.9547 7.64828C19.9017 7.55084 19.3314 7.19597 16.996 5.80739L14.1029 4.08722L14.5058 3.82953C14.9991 3.51399 15.089 3.36536 14.949 3.09689C14.9076 3.01762 14.2528 2.58465 12.5351 1.5006C10.5958 0.276754 10.1503 0.0109241 10.0217 0.000811591C9.87501 -0.010657 9.71854 0.0811301 7.50036 1.48018ZM8.31829 5.60653C9.60963 6.4225 9.88252 6.58031 10.0021 6.58031C10.1218 6.58031 10.3946 6.4225 11.686 5.60653C12.5336 5.07096 13.2546 4.62516 13.2882 4.6159C13.3484 4.59931 13.834 4.86123 13.8319 4.90912C13.8298 4.95503 10.0737 7.2971 10.0021 7.2971C9.93459 7.2971 6.1747 4.95654 6.17239 4.91303C6.17012 4.86999 6.66266 4.60389 6.71856 4.61795C6.75081 4.62609 7.47069 5.07096 8.31829 5.60653ZM7.31594 6.63006C8.97934 7.67719 9.21396 7.83647 9.15317 7.87758C9.11487 7.90346 8.23413 8.45992 7.19597 9.11409L5.30842 10.3035L3.27448 9.07612C1.99553 8.30435 1.25426 7.83515 1.27752 7.8121C1.3738 7.71667 5.33574 5.3894 5.36995 5.4082C5.39145 5.41997 6.26715 5.96984 7.31594 6.63006ZM16.7452 6.61762C17.7987 7.24216 18.684 7.77459 18.7125 7.80074C18.7544 7.83918 18.3725 8.08482 16.7301 9.07592L14.6958 10.3035L12.8083 9.11409C11.7701 8.45992 10.8894 7.90342 10.851 7.8775C10.7901 7.83631 11.0281 7.67467 12.7245 6.60488C14.5059 5.48158 14.6746 5.38371 14.7488 5.43075C14.7933 5.45895 15.6917 5.99305 16.7452 6.61762Z"
        //   />
        // </svg>
      ),
    },
    {
      title: "Appointments",
      active: active === "Appointments",
      linkto: "/channelpartner/appointment",
      icon: (
        <svg
          width="20"
          height="14"
          viewBox="0 0 20 14"
          className="sidebar_option_iconn"
          style={{
            fill: active == "Appointments" ? "#1976D2" : "#8E97BA",
          }}
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M7.50036 1.48018C5.92777 2.47205 5.11254 3.00836 5.06719 3.08093C4.9029 3.34393 4.99836 3.50965 5.4984 3.82949L5.90126 4.08714L3.0752 5.7638C1.43763 6.73533 0.193783 7.49768 0.117518 7.57653C0.00079499 7.6973 -0.0116748 7.73031 0.00712761 7.86917C0.0195583 7.96103 0.0658411 8.06165 0.11916 8.11276C0.169156 8.16061 2.37369 9.50483 5.01814 11.0999C9.50327 13.8052 9.83803 14 10.0021 14C10.1662 14 10.5008 13.8053 14.9861 11.0993C17.6306 9.50398 19.8335 8.16305 19.8816 8.11946C20.0044 8.00807 20.0368 7.79939 19.9547 7.64828C19.9017 7.55084 19.3314 7.19597 16.996 5.80739L14.1029 4.08722L14.5058 3.82953C14.9991 3.51399 15.089 3.36536 14.949 3.09689C14.9076 3.01762 14.2528 2.58465 12.5351 1.5006C10.5958 0.276754 10.1503 0.0109241 10.0217 0.000811591C9.87501 -0.010657 9.71854 0.0811301 7.50036 1.48018ZM8.31829 5.60653C9.60963 6.4225 9.88252 6.58031 10.0021 6.58031C10.1218 6.58031 10.3946 6.4225 11.686 5.60653C12.5336 5.07096 13.2546 4.62516 13.2882 4.6159C13.3484 4.59931 13.834 4.86123 13.8319 4.90912C13.8298 4.95503 10.0737 7.2971 10.0021 7.2971C9.93459 7.2971 6.1747 4.95654 6.17239 4.91303C6.17012 4.86999 6.66266 4.60389 6.71856 4.61795C6.75081 4.62609 7.47069 5.07096 8.31829 5.60653ZM7.31594 6.63006C8.97934 7.67719 9.21396 7.83647 9.15317 7.87758C9.11487 7.90346 8.23413 8.45992 7.19597 9.11409L5.30842 10.3035L3.27448 9.07612C1.99553 8.30435 1.25426 7.83515 1.27752 7.8121C1.3738 7.71667 5.33574 5.3894 5.36995 5.4082C5.39145 5.41997 6.26715 5.96984 7.31594 6.63006ZM16.7452 6.61762C17.7987 7.24216 18.684 7.77459 18.7125 7.80074C18.7544 7.83918 18.3725 8.08482 16.7301 9.07592L14.6958 10.3035L12.8083 9.11409C11.7701 8.45992 10.8894 7.90342 10.851 7.8775C10.7901 7.83631 11.0281 7.67467 12.7245 6.60488C14.5059 5.48158 14.6746 5.38371 14.7488 5.43075C14.7933 5.45895 15.6917 5.99305 16.7452 6.61762Z"
          />
        </svg>
      ),
    },
  ];
  const [token, setToken] = useState("");
  useEffect(() => {
    setToken(localStorage.getItem("crm_token")?? "");
  },[]);

  const DeleteAccount=async ()=>{  
    try{
      const obj = {};
      const headers = {
        Authorization: `JWT ${token}`,
      };
      const res = await axios.post(
        Apiaddress + "/api/deleteAccount/",
        obj,
        { headers: headers }
      );
      console.log(res.data);
      UC.logOut();
      navigate("/");
      handleCloseDelete();
    }catch(err){
      console.log(err);
    }
  }

  return (
    <>
    <Stack direction="row" sx={{ height: "100%" }}>
      <Stack
        direction="column"
        justifyContent="space-between"
        sx={{
          width: "100%",
          padding: "20px 10px 20px 10px",
          height: "100%",
        }}
      >
        {/* directory routs */}
        <Stack>
          <Box
            sx={{ mb: "50px" }}
            onClick={() => navigate("/channelpartner/dashboard")}
          >
            <EnvisionLogo />
          </Box>

          <Stack direction="column" justifyContent="start">
            {/* sales dashboard */}
            {tabs.map((e) => {
              if (e.title == "Teams" && account_type == "Individual") {
                return;
              }
              //  else if (
              //   e.title == "Teams" &&
              //   account_type == "Org" &&
              //   cp_type == "Individual"
              // ) {
              //   return;
              // } 
              else {
                return (
                  <SideBarimplIitem
                    active={e.active}
                    icon={e.icon}
                    linkto={e.linkto}
                    title={e.title}
                  />
                );
              }
            })}
          </Stack>
        </Stack>

        {/* teams and message */}
        <Stack sx={{ marginTop: "30px" }}>

          <Link
            to="/"
            onClick={() => {
              UC.logOut();
            }}
          >
            <Stack
              direction="row"
              alignItems="center"
              sx={{ marginLeft: "10px" }}
            >
              <LogoutIcon sx={{ color: "black" }} />
              <Typography
                sx={{
                  color: "#0085FF",
                  cursor: "pointer",
                  fontSize: "18px",
                  fontWeight: "600",
                  marginLeft: "10px",
                  fontFamily: "Product Sans",
                }}
              >
                Logout
              </Typography>
            </Stack>
          </Link>
          
          {owner && 
          <Stack
              direction="row"
              alignItems="center"
              sx={{ marginLeft: "10px",marginTop:"10px"}}
            >
              <DeleteIcon sx={{ color: "black" }} />
              
            {/* <Typography sx={{ fontFamily: "Product Sans",margin:"0px 5px 0px 10px"}}>
              Delete {" "}
            </Typography> */}
            <Typography
              sx={{
                color: "#0085FF",
                cursor: "pointer",
                fontFamily: "Product Sans",
                fontWeight: 600,
                marginLeft:"10px"
              }}
              onClick={()=>{setOpenDelete(true)}}
            >
              {" "}{" "}
              Delete Account
            </Typography>
          </Stack>}

          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              padding: "10px",
              gap: "5px",
            }}
            >
            <Typography sx={{ fontFamily: "Product Sans" }}>
              Get in touch with{" "}
            </Typography>
            <Typography
              sx={{
                color: "#0085FF",
                cursor: "pointer",
                fontFamily: "Product Sans",
                fontWeight: 600,
              }}
              onClick={()=>{setOpen(true)}}
            >
              {" "}
              our team
            </Typography>
          </Box>
        </Stack>
      </Stack>
    </Stack>

    <Support open={open} setOpen={setOpen}></Support>
    
    {/* delete Account popup */}
    <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={openDelete}
        onClose={handleCloseDelete}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={openDelete}>
          <Box sx={style}>
            <Box
              sx={{
                display: "flex",
                marginBottom: "25px",
                justifyContent: "space-between",
                marginTop: "-10px",
              }}
            >
              <Box sx={{ display: "flex", gap: "0.2rem" }}>
                <ReportProblemIcon sx={{ color: "red" }} />
                <Typography
                  sx={{
                    fontFamily: "Product Sans",
                    fontSize: "18px",
                    fontWeight: "600",
                    color: "red",
                  }}
                >
                  Delete Account
                </Typography>
              </Box>
              <Box sx={{ textAlign: "right" }}>
                <GridCloseIcon
                  sx={{ color: "gray", cursor: "pointer" }}
                  onClick={()=>{handleCloseDelete();setConfirmText("");setMatch(false)}}
                />
              </Box>
            </Box>
              <>
                <Box>
                  <Typography
                    id="transition-modal-title"
                  >
                    Confirm that you want to delete your account by typing - " 
                    <span style={{ fontWeight: "600" }}>Delete Account</span>
                    "?
                  </Typography>
                  <TextField
                    placeholder="Delete Account"
                    onChange={(data) => {
                      setMatch(false);
                      setConfirmText(data.target.value);
                      if (data.target.value === "Delete Account") {
                        setMatch(true);
                        console.log("Yes..")
                      }
                    }}
                    sx={{ mt: 3, width: "100%" }}
                  />
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "flex-end",
                    mt: 3,
                  }}
                >
                  <Button
                    onClick={()=>{handleCloseDelete();setConfirmText("");setMatch(false)}}
                    sx={{
                      color: "#B9B7D0",
                    }}
                  >
                    Cancel
                  </Button>
                  <Button
                    disabled={!match}
                    sx={{
                      width: "20%",
                      color: "#06386B",
                      textTransform: "none",
                      fontFamily: "Product Sans",
                      ml: 1,
                      background: !match ? "gray" : "red",
                      "&:hover": {
                        background: "red",
                        boxShadow: "0px 9px 12px rgba(242, 178, 73, 0.59)",
                      },
                    }}
                    className="univercel_btn_class"
                    onClick={() => {DeleteAccount()}}
                  >
                    Yes
                  </Button>
                </Box>
              </>
          </Box>
        </Fade>
      </Modal>

    </>
  );
};

export const SideBarimplIitem = ({
  title,
  active,
  linkto,
  icon,
}: {
  title: string;
  active: boolean;
  linkto: string;
  icon: any;
}) => {
  return (
    <Link to={linkto} onClick={() => {}}>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        spacing={2}
        className="sidebar_option"
        sx={{
          background: active ? "#F5F7FF" : "white",
          borderLeft:active ?"4px solid #1976D2":null,
          borderRadius:"0px"
        }}
      >
        <Stack
          spacing={2}
          direction="row"
          justifyContent="start"
          alignItems="center"
          sx={{color: active ? "#1976D2" : "#A4A4AD"}}
        >
          {icon}
          <Typography
            className="link_name"
            sx={{
              fontWeight: active ? 600 : 400,
              fontSize: "18px",
              color: active ? "#1976D2" : "#A4A4AD",
            }}
          >
            {title}
          </Typography>
        </Stack>
        <KeyboardArrowRightOutlinedIcon
          sx={{ fontSize: "20px",  color: active ? "#1976D2" : "#A4A4AD"}}
        />
      </Stack>
    </Link>
  );
};
