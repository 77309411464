import React, { useContext, useEffect } from 'react';
import { Radio, TextField} from "@mui/material";
import { Link, useLocation } from 'react-router-dom';
import { useState } from 'react';

import { Box, Button, Typography } from "@mui/material"
import { Stack } from "@mui/system"
import DoneIcon from '@mui/icons-material/Done';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import { useNavigate } from "react-router-dom";
import Divider from '@mui/material/Divider';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';

import currentProjectState from "../../../../context/createproject/context";
import axios from 'axios';
import { Apiaddress } from '../../../../utils/consts';
import { SuperAdminNavbar } from "../../SidebarNavbar/SuperAdminNavbar"
import { SuperAdminSidebar } from "../../SidebarNavbar/SuperAdminSidebar"
import { SAProjectSidebar } from '../../SidebarNavbar/SAProjectSidebar';



export const  ConfirmProject_Building_DetailsSA = ()=>{
     ////get data from previous page 
  const location = useLocation();
  let data = location.state;

  console.log("preve data =>.>",data)

  
  const navigate = useNavigate();

  const [buildingname,setbuildingname]=useState({name:data?.name,haswing:data?.has_wings});

  const [selectedValue, setSelectedValue] = useState(data?.has_wings==true?"wing":"nowing");

  const [floornumber,setfloornumber]=useState(data?.wing_template?.length>0?data.wing_template.length:0);

  const [floordata,setfloordata]=useState(data?.has_wings==false? data.wing_template:[]);

  const [floortemp,setfloortemp]=useState(['temp_floor1','temp_floor2','temp_floor3','temp_floor4','temp_floor5']);

  const [wingnumber,setwingnumber]=useState(data?.wing_template?.length>0?data.wing_template.length:0);

  const [wingdata,setwingdata]=useState(data?.has_wings==true? data.wing_template:[]);

  const [wingtemp,setwingtemp]=useState(['temp_wing1','temp_wing2','temp_wing3','temp_wing4','temp_wing5']);
 
  console.log(floordata);

  console.log(wingdata);

  const addfloordata=()=>{
    let temp=floordata.length+1;
    let to_insert={
      floor_no:temp,
      floor_name:"",
      floor_id:""
    }
    setfloordata([...floordata,to_insert]);
    setfloornumber(floordata.length+1);
  }

   const removefloordata=(newfloordata,index)=>{
    for(let i=index;i<newfloordata.length;i++){
      newfloordata[i].floor_no=newfloordata[i].floor_no-1;
    }
    // console.log(newbedroomdata);
    setfloordata(newfloordata);
    setfloornumber(floordata.length-1);
  }

   const addwingdata=()=>{
    let temp=wingdata.length+1;
    let to_insert={
      wing_no:temp,
      wing_name:"",
      wing_id:""

    }
    setwingdata([...wingdata,to_insert]);
    setwingnumber(wingdata.length+1);
  }

   const removewingdata=(newwingdata,index)=>{
    for(let i=index;i<newwingdata.length;i++){
      newwingdata[i].wing_no=newwingdata[i].wing_no-1;
    }
    // console.log(newbedroomdata);
    setwingdata(newwingdata);
    setwingnumber(wingdata.length-1);
  }


  const handleChange = (event) => {
      setSelectedValue(event.target.value);
    };

    const controlProps = (item) => ({
        checked: selectedValue === item,
        onChange: handleChange,
        value: item,
        name: 'color-radio-button-demo',
        inputProps: { 'aria-label': item },
      });
    


   ////get data of buliding templates 
   const cpContext = useContext(currentProjectState);
   const projectID = cpContext?.createProjectState?.project?.id;
   useEffect(()=>{
     const buildingurl = Apiaddress+"/api/projects/buildingtemplatesbyproject/?project_id="+projectID
 
     axios.get(buildingurl)
     .then((res)=>{
           const data = res?.data
           console.log(data)
          //  if(data.building_template!=="building_template not found")
          // setbuildingdata(data.building_template)
        })
        .catch((err)=>{
          console.log(err)
        })
 
   },[])

   ////get floor nd wing templates;

   useEffect(()=>{
    const floorurl = Apiaddress+"/api/projects/floortemplatesbyproject/?project_id="+projectID
    const wingurl = Apiaddress+"/api/projects/wingtemplatesbyproject/?project_id="+projectID
    
    axios.get(floorurl)
          .then((res)=>{
            const data = res?.data
            console.log(data)
            if(data.floor_templates!=="floor templates not found")
            setfloortemp(data?.floor_templates)
          })
          .catch((err)=>{
            console.log(err)
          })

       axios.get(wingurl)
       .then((res)=>{
             const data = res?.data
             console.log(data)
             if(data.wing_templates!=="Wing Template not found")
             setwingtemp(data?.wing_templates)
          })
          .catch((err)=>{
            console.log(err)
          })

   },[])


   //// update the building templates 

   const updatebuildingTemplate = ()=>{
    let url = Apiaddress+"/api/projects/buildingtemplates/";
  
    let st = {
      building_template_id:data?.id,
      has_wings:selectedValue=="wing"?true:false,
      wing_template:selectedValue=="wing"?wingdata:floordata,
    }

    let headers = {
      headers:{
        "Content-Type":"application/json"
    }
    }
    console.log(st)
    
  //   const data = {
  //     name:buildingtitle[i].titlename,
  //     project:projectID,
  //     has_wings :buildingtitle[i].has_wing,
  //     wing_template:buildingtitle[i].field,
  // }

    axios.patch(url,st,headers)
    .then((res)=>{
      console.log(res);
      navigate("/super-admin-confirm-project-building")
    })
    .catch((err)=>{
      console.log(err);
    })

   }
    return(
        <>
                
<Stack direction="row" sx={{width:"100%",height:"auto" }}>
      {/* 1st stack */}
      <Stack sx={{minHeight:"100vh",width:"20%",boxSizing:"border-box",}}> {/*for project directory 20% and for sales dashboard 225*/}
        {/* <SuperAdminSidebar active={"Builder Directory"}/> */}
        <SAProjectSidebar active={"Project Dashboard"}/>
      </Stack>

     {/* 2nd stack */}
     <Stack direction="column" sx={{ backgroundColor:"#f9f9f9",width:"80%", padding:"25px",boxSizing:"border-box",}}  >  {/*  width for main sidebar 78px and for 80px*/}
      <SuperAdminNavbar/>  {/*send props for get popup to verify otp from builder */}
      
      <Typography sx={{marginTop:"20px", fontSize:"28px", fontWeight:"600", marginLeft:"10px", marginBottom:"50px"}}>
            Building Template Details
            </Typography>
     {/* actual content  */}
    <Stack sx={{backgroundColor:"#f9f9f9"}}>  {/* mt:"28px", */}

    <Stack direction="column"  sx={{  padding:"20px", backgroundColor:"#fff", marginRight:"0px"}}>

<Box sx={{}}>
<Box sx={{paddingBottom:"40px"}}>
<Box sx={{display:"flex",alignItems:"center",margin:"30px 0px 0px 0px"}}>
    <Typography component="span" className='field-name'>Building Template Name</Typography>
    <TextField
         disabled
         sx={{
             width:"188px",
             marginLeft:"40px"
            }} value={buildingname.name}/>
 </Box>

   <Stack direction="row" justifyContent="start" alignItems="center" sx={{margin:"30px 0px 0px 0px"}} >
                    <Radio 
                    name="wing"
                    {...controlProps('wing')}
                        sx={{
                            color: "linear-gradient(264.48deg, #79F1A4 -45.37%, #0E5CAD 88.97%)",
                            color: "#0F5DAE",
                            margin:"0px 0px 0px 0px",
                            '&.Mui-checked': {
                            },
                          }}
                    />
                    <Typography sx={{color:"#170F49", fontSize:"18px", fontWeight:500, marginRight:"70px"}}>Has Wings</Typography>
                    <Radio 
                     name="wing"
                     {...controlProps('nowing')}
                        sx={{
                            color: "linear-gradient(264.48deg, #79F1A4 -45.37%, #0E5CAD 88.97%)",
                              color: "#0F5DAE",
                            '&.Mui-checked': {
                            },
                          }}
                    />
                    <Typography sx={{color:"#170F49", fontSize:"18px", fontWeight:500}}>Has no Wings</Typography>
    </Stack>

{selectedValue=="nowing" && 
  <Box>
  <Box sx={{display:"flex",alignItems:"center",margin:"30px 0px 0px 0px"}}>
    <Typography component="span" className='field-name'>No of Floors in Building </Typography>
    <TextField
         inputProps={{ min: 0}}
         disabled
         sx={{
             width:"188px",
             marginLeft:"40px"
            }} type="Number" value={floornumber}/>       
  </Box>
 
<Button 
       type="submit"
        variant="contained"
         color="primary" sx={{ height:"50px", background: "linear-gradient(90deg, #0F5DAE 0%, #009DCF 100.33%)",boxShadow: "0px 13.7981px 22.5788px rgba(50, 174, 137, 0.25)", textTransform: "none", borderRadius: "4px", width:"150px", fontSize:"15px",margin:"30px 0px 40px 0px"}} onClick={addfloordata}>
        <AddIcon sx={{marginRight:"10px"}}/>Add Floor
</Button>

<Divider/>

{floordata?.map((ele,index)=>{
      return(
        <Box key={index} sx={{display:"flex",alignItems:"center",margin:"30px 0px 0px 70px"}}>
        <Typography component="span" className='field-name'>Floor {ele.floor_no}</Typography>

        <FormControl sx={{width:"188px", marginLeft:"40px"}}>
          <Select value={ele.floor_name+"-"+ele.floor_id}
            
            onChange={(e)=>{


              // console.log(e.target.value.split('-'));return;
              let arr = floordata;
                let [name,id] = e.target.value.split("-");
                arr[index].floor_name = name;
                arr[index].floor_id = +id;
                 setfloordata([...arr])
            }}
            displayEmpty
            inputProps={{ 'aria-label': 'Without label' }}
          >
            {floortemp.map((ele,index)=>{
              return(
                <MenuItem key={index}  value={ele.name+"-"+ele.id}>{ele.name}</MenuItem>
              )
            })}
          </Select>
        </FormControl>

        <DeleteIcon sx={{fontSize:"26px", fontWeight:"500",color:"#EF3649",margin:"0px 0px 0px 20px",cursor:"pointer"}} 
                onClick={()=>{
                    const newfloordata = floordata.filter((x) => x !== ele);
                    console.log(newfloordata);
                    removefloordata(newfloordata,index);
                }}/>
      </Box>
      )
    })}

    {floornumber>0 && <Button 
       onClick={()=>{
        updatebuildingTemplate()
       }}
        variant="contained"
         color="primary" sx={{ height:"50px", background: "linear-gradient(90deg, #0F5DAE 0%, #009DCF 100.33%)",boxShadow: "0px 13.7981px 22.5788px rgba(50, 174, 137, 0.25)", textTransform: "none", borderRadius: "4px", width:"150px", fontSize:"15px",margin:"100px 0px 70px 70px"}}>
        Save
 </Button>}    
</Box>

}

{selectedValue=="wing" && 
  <Box>
  <Box sx={{display:"flex",alignItems:"center",margin:"30px 0px 0px 0px"}}>
    <Typography component="span" className='field-name'>No of Wings in Building </Typography>
    <TextField
         inputProps={{ min: 0}}
         disabled
         sx={{
             width:"188px",
             marginLeft:"40px"
            }} type="Number" value={wingnumber}/>       
  </Box>
 
<Button 
       type="submit"
        variant="contained"
         color="primary" sx={{ height:"50px", background: "linear-gradient(90deg, #0F5DAE 0%, #009DCF 100.33%)",boxShadow: "0px 13.7981px 22.5788px rgba(50, 174, 137, 0.25)", textTransform: "none", borderRadius: "4px", width:"150px", fontSize:"15px",margin:"30px 0px 40px 0px"}} onClick={addwingdata}>
        <AddIcon sx={{marginRight:"10px"}}/>Add Wing
</Button>

<Divider/>

{wingdata.map((ele,index)=>{
      return(
        <Box key={index} sx={{display:"flex",alignItems:"center",margin:"30px 0px 0px 70px"}}>
        <Typography component="span" className='field-name'>Wing {ele.wing_no}</Typography>

        <FormControl sx={{width:"188px", marginLeft:"40px"}}>
          <Select value={ele.wing_name+"-"+ele.wing_id}
            onChange={(e)=>{
              // console.log(e.target.value.split('-'));return;

                 let arr = wingdata;
                 let [name,id] = e.target.value.split("-");
                 arr[index].wing_name =name
                 arr[index].wing_id = +id;
                 setwingdata([...arr])

                
            }}
            displayEmpty
            inputProps={{ 'aria-label': 'Without label' }}
          >
            {wingtemp.map((ele,index)=>{
              return(
                <MenuItem key={index} value={ele.name+"-"+ele.id}>{ele.name}</MenuItem>
              )
            })}
          </Select>
        </FormControl>

        <DeleteIcon sx={{fontSize:"26px", fontWeight:"500",color:"#EF3649",margin:"0px 0px 0px 20px",cursor:"pointer"}} 
                onClick={()=>{
                    const newwingdata = wingdata.filter((x) => x !== ele);
                    console.log(newwingdata);
                    removewingdata(newwingdata,index);
                }}/>
      </Box>
      )
    })}

    {wingnumber>0 && <Button 
       onClick={()=>{
        updatebuildingTemplate()
       }}
        variant="contained"
         color="primary" sx={{ height:"50px", background: "linear-gradient(90deg, #0F5DAE 0%, #009DCF 100.33%)",boxShadow: "0px 13.7981px 22.5788px rgba(50, 174, 137, 0.25)", textTransform: "none", borderRadius: "4px", width:"150px", fontSize:"15px",margin:"100px 0px 70px 70px"}}>
        Save
 </Button>}    
</Box>
}
</Box> 
<Box>
</Box>
</Box>
           
    </Stack>

    </Stack>
      </Stack>
</Stack>

        </>
    )
}