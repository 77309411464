import { Box, Typography } from "@mui/material";
import React from "react";

const PdfTwoPageFour = ({quotationInfo,projectInfo,unitInfo,leadInfo}) => {
  return (
    <Box>
      <Typography sx={{ fontSize: "14px" }}>
         Quotation for {`(${leadInfo?.lead_name})`} of {`(${projectInfo?.project_name})`} Dated{" "}
        {`(${quotationInfo?.created?.substring(0, 10)})`}
      </Typography>
      <Typography sx={{ fontSize: "14px", my: "20px" }}>
        <span
          style={{
            borderBottom: "2px solid #000",
            display: "inline",
            fontSize: "16px",
          }}
        >
          Terms and Conditions:
        </span>{" "}
        <span>(to follow)</span>
      </Typography>
    </Box>
  );
};

export default PdfTwoPageFour;
