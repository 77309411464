import {
  Box,
  Button,
  Checkbox,
  Drawer,
  TextField,
  Typography,
} from "@mui/material";
import { Stack } from "@mui/system";
import TuneOutlinedIcon from "@mui/icons-material/TuneOutlined";
import KeyboardArrowRightOutlinedIcon from "@mui/icons-material/KeyboardArrowRightOutlined";
import KeyboardArrowUpOutlinedIcon from "@mui/icons-material/KeyboardArrowUpOutlined";
import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined";
import { CheckBox } from "@mui/icons-material";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import DriveFileRenameOutlineIcon from "@mui/icons-material/DriveFileRenameOutline";
import VisibilityIcon from "@mui/icons-material/Visibility";
import DeleteIcon from "@mui/icons-material/Delete";
import "../MembersDirectory/MDmainPage.css";
import { Sidebar } from "../ProjectDashboard/DashboardComponents/Sidebar";
import { Navbar } from "../ProjectDashboard/DashboardComponents/Navbar";
import React, { useContext, useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import CategoryIcon from "@mui/icons-material/Category";
import HandshakeIcon from "@mui/icons-material/Handshake";

import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import Appointmentlist from "./CPOsubpages/Appointmentlist";
import AssociatedLeads from "./CPOsubpages/AssociatedLeads";
import AssociatedUnits from "./CPOsubpages/AssociatedUnits";
import Commissionlist from "./CPOsubpages/Commissionlist";
import profileContext from "../../../context/profile/context";
import { NavbarForDirectory } from "../ProjectDashboard/DashboardComponents/NavbarForDirectory";
import RightSidebarCPD from "./RightSidebarCPD";
import RightSidebarCPDadd from "./RightSidebarCPDadd";
import { useLocation } from "react-router-dom";
import { MainSidebar } from "../ProjectDashboard/DashboardComponents/MainSidebar";

function CPOmainPage() {

  const [state, setState] = React.useState(false);

  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState(open);
  };

  const [stateadd, setStateadd] = React.useState(false);

  const toggleDrawerAdd = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setStateadd(open);
  };

  const [selectedRows, setSelectedRows] = React.useState([]);
  useEffect(() => {
    console.log(selectedRows);
  }, [selectedRows]);

  const [curselectedRows, setcurselectedRows] = useState(null);

  const [tabledata, setTabledata] = useState(null);
  const [rowData, setrowData] = useState([]);
  const [idCont, setidCunt] = useState(1);

  //api

  //api

  useEffect(() => {
    const rows = [
      {
        id: 8,
        Name: "Frances",
        Phone: "912912912",
        Email: "dummmy@gamil.com",
        Team: "Agent",
        Role: "Agent",
        DetailedView: "View",
      },
    ];
    if (tabledata) {
      let obj = {
        id: idCont,
        Name: tabledata.name,
        Phone: tabledata.phone,
        Email: tabledata.Email,
        Team: tabledata.Team,
        Role: tabledata.Role,
      };
      setidCunt(idCont + 1);
      console.log(obj);
      setrowData([...rowData, obj]);
    }

    console.log(tabledata);
  }, [tabledata]);

  const [rowSelected, setRowSelected] = useState(false);
  const [curPage, setCurPage] = useState(1);
  const [curUserFromRoute, setcurUserFromRoute] = useState(null);

  const location = useLocation();

  useEffect(() => {
    console.log(location.state);
    setcurUserFromRoute(location.state);
  }, [location]);

  return (

<>
<Stack direction="row" sx={{width:"100%",height:"auto" }}>
      {/* 1st stack */}
      <Stack sx={{minHeight:"100vh",width:"20%",boxSizing:"border-box",}}>
        <MainSidebar  active={"Channel Partner Directory"}/>
      </Stack>

     {/* 2nd stack */}
     <Stack direction="column" sx={{ backgroundColor:"#f9f9f9",width:"80%", padding:"25px",boxSizing:"border-box",}}  >  {/*  width for main sidebar 78px and for 80px*/}
      {/* <Navbar/> */}
      {/* <NavbarForDirectory
          showAddProjectbtn={false}
          showRightSideBar={true}
          showRightSideBarleft={true}
          RightBarNameleft={"Invite Channel Partner"}
          toggleDrawer={toggleDrawer}
          toggleDrawerAdd={toggleDrawerAdd}
          leftTitle={"Channel partner Directory"}
          RightBarName={"Add Channel Partner"}
        /> */}
         <NavbarForDirectory
          showAddProjectbtn={false}
          showRightSideBar={false}
          showRightSideBarleft={true}
          RightBarNameleft={"Invite Channel Partner"}
          toggleDrawer={toggleDrawer}
          toggleDrawerAdd={toggleDrawerAdd}
          leftTitle={"Sales Dashboard"}
          RightBarName={"Add Channel Partner"}
          leftRightTitle1={"Channel partner Dashboard"}
          leftRightTitle2={"Channel partner Directory"}
          showLeftTitle={false}
          cpadd={true}
          cpaddtitle={"Add channerl partner"}
          longOrangBgr={true}
          righshow={true}
        />
      
     {/* actual content  */}
    <Stack sx={{ mt:"28px",backgroundColor:"#f9f9f9"}}>

    <React.Fragment>
          <Drawer
            anchor="right"
            open={stateadd}
            onClose={toggleDrawerAdd(false)}
          >
            <RightSidebarCPD
              setTabledata={setTabledata}
              curselectedRows={curselectedRows}
            />
          </Drawer>
        </React.Fragment>

        <React.Fragment>
          <Drawer anchor="right" open={state} onClose={toggleDrawer(false)}>
            <RightSidebarCPDadd
              setTabledata={setTabledata}
              curselectedRows={curselectedRows}
            />
          </Drawer>
        </React.Fragment>

        <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            sx={{ height: "68px", marginBottom: "20px" }}
          >
            <Stack direction="column" alignItems="center">
              <Typography
                sx={{
                  fontSize: "22px",
                  fontWeight: "600",
                  marginLeft: "5px",
                  color: "black",
                }}
              >
                That Channel partner Name
              </Typography>
              <Typography
                sx={{
                  color: "#B9B7D0",
                  fontSize: "12px",
                  fontWeight: "400",
                  paddingLeft: "20px",
                }}
              >
                Here is your Channel Partner Over Directory list data
              </Typography>
            </Stack>
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Stack
                direction="row"
                justifyContent="space-around"
                alignItems="center"
                sx={{
                  width: "203px",
                  height: "68px",
                  borderRadius: "12px",
                  color: "#3E4954",
                  backgroundColor: "#f4f5f9",
                }}
              >
                <TuneOutlinedIcon sx={{ color: "#2F4CDD" }} />
                <Typography sx={{ fontSize: "16px", fontWeight: "600" }}>
                  Filter
                </Typography>
                <KeyboardArrowDownOutlinedIcon />
              </Stack>
            </Stack>
          </Stack>

          <Box sx={{ width: "100%", display: "grid" }}>
            <Box
              sx={{
                width: "90%",
                margin: "auto",
                color: "#A2A3A6",
                display: "flex",
              }}
            >
              <Box sx={{ width: "70%" }}>
                <Box sx={{ marginLeft: "10px" }}>Name</Box>
                <Box
                  sx={{
                    marginTop: "10px",
                    width: "15vw",
                    height: "6vh",
                    display: "grid",
                    placeItems: "center",
                    borderRadius: "10px",
                    background: "#BDBDBD",
                    color: "black",
                    fontSize: "20px",
                  }}
                >
                  {curUserFromRoute && curUserFromRoute.Name}
                </Box>
              </Box>
              <Box sx={{ width: "70%" }}>
                <Box sx={{ marginLeft: "10px" }}>Phone no</Box>{" "}
                <Box
                  sx={{
                    marginTop: "10px",
                    background: "#BDBDBD",
                    width: "15vw",
                    height: "6vh",
                    display: "grid",
                    placeItems: "center",
                    borderRadius: "10px",
                    color: "black",
                    fontSize: "20px",
                  }}
                >
                  {curUserFromRoute && curUserFromRoute.Phone}
                </Box>
              </Box>
              <Box sx={{ width: "70%", marginRight: "20px" }}>
                <Box sx={{ marginLeft: "10px" }}>E-mail</Box>{" "}
                <Box
                  sx={{
                    marginTop: "10px",
                    background: "#BDBDBD",
                    width: "auto",
                    height: "6vh",
                    display: "grid",
                    placeItems: "center",
                    borderRadius: "10px",
                    color: "black",
                    fontSize: "20px",
                    padding: "0 10px",
                  }}
                >
                  {curUserFromRoute && curUserFromRoute.Email}
                </Box>
              </Box>
              <Box sx={{ width: "70%" }}>
                <Box sx={{ marginLeft: "10px" }}>RERA No</Box>{" "}
                <Box
                  sx={{
                    marginTop: "10px",
                    background: "#BDBDBD",
                    width: "15vw",
                    height: "6vh",
                    display: "grid",
                    placeItems: "center",
                    borderRadius: "10px",
                    color: "black",
                    fontSize: "20px",
                  }}
                >
                  {curUserFromRoute && curUserFromRoute.RERARegNo}
                </Box>
              </Box>
            </Box>
            <Box sx={{ width: "90%", margin: "40px auto" }}>
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
              >
                <Button
                  onClick={() => setCurPage(1)}
                  sx={{
                    background: "#DFDFDF",
                    display: "grid",
                    justifyItems: "center",
                    width: "70%",
                  }}
                  variant="text"
                >
                  Associated units
                </Button>
                <Button
                  onClick={() => setCurPage(2)}
                  sx={{ background: "#DFDFDF", width: "70%" }}
                  variant="text"
                >
                  Associated leads
                </Button>
                <Button
                  onClick={() => setCurPage(3)}
                  sx={{ background: "#DFDFDF", width: "70%" }}
                  variant="text"
                >
                  Appointment list
                </Button>
                <Button
                  onClick={() => setCurPage(4)}
                  sx={{ background: "#DFDFDF", width: "70%" }}
                  variant="text"
                >
                  Commission list
                </Button>
              </Stack>
            </Box>
            {curUserFromRoute && (
              <Box sx={{ color: "black", width: "90%", margin: "auto"}}>
                {curPage === 1 && (
                  <AssociatedUnits cpid={curUserFromRoute.CPid} /> 
                )}
                {curPage === 2 && (
                  <AssociatedLeads cpid={curUserFromRoute.CPid} />
                )}
                {curPage === 3 && (
                  <Appointmentlist cpid={curUserFromRoute.CPid} />
                )}
                {curPage === 4 && (
                  <Commissionlist cpid={curUserFromRoute.CPid} />
                )}
              </Box>
            )}
          </Box>

    </Stack>




      </Stack>
</Stack>



    

</>
    
  );
}

export default CPOmainPage;
