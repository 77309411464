import { Box, List, ListItem, Typography } from "@mui/material";
import "./styles/PageSix.css";
import React, {useState } from 'react';
import { Stack } from "@mui/system";
import classes from "./styles/PageOne.module.css";

const PdfOnePageSeventeen = () => {

  const [pageData17,setPageData17]=useState({
    sign_first:"",
    sign_second:"",
    sign_third:"",
    date:"",
    month:"",
    year:"",
    place:"",
  })

  let name,value;
  const handleChange=(e)=>{
    name=e.target.name;
    value=e.target.value;
    console.log(name," ",value);
    setPageData17({...pageData17,[name]:value});
  }

  return (
    <Box>
      <Box sx={{ mb: "40px" }}>
        <Stack spacing={0.5} sx={{ ml: "10px", mt: "5px" }}>
          <Box sx={{ display: "flex", gap: "10px" }}>
            <Typography sx={{ fontSize: "10px" }}>16.7</Typography>
            <Typography sx={{ fontSize: "10px" }}>
              The singular includes the plural and vice versa and any work or
              expression defined in the singular shall have a corresponding
              meaning if used in the plural or vice versa. A reference to any
              gender includes a reference to all other genders. 16.8
            </Typography>
          </Box>
          <Box sx={{ display: "flex", gap: "10px" }}>
            <Typography sx={{ fontSize: "10px" }}>16.8</Typography>
            <Typography sx={{ fontSize: "10px" }}>
              The Applicant(s) is making this Application after reading and
              understanding the terms and conditions contained herein and has
              agreed to abide by the same.
            </Typography>
          </Box>
        </Stack>

        <Box sx={{ mt: "20px" }}>
          <Typography sx={{ fontSize: "10px" }}>
            I hereby to be bound by the terms and conditions contained in this
            Application Form. I hereby confirm that the details provided by me
            in this Application Form are true and accurate.
          </Typography>
        </Box>

        <Box
          className={classes.input}
          sx={{ display: "flex", fontSize: "10px", my: "30px" }}
        >
          <span>Dated</span>
          <input style={{ width: "100px" }} value={pageData17.date} type="number" name="date" onChange={handleChange}/>
          <span>day of</span>
          <input style={{ width: "100px" }} value={pageData17.month} type="text" name="month" onChange={handleChange}/>
          <span>20</span>
          <input style={{ width: "100px" }} value={pageData17.year} type="number" name="year" onChange={handleChange}/>
          <span>at</span>
          <input style={{ width: "100px" }} value={pageData17.place} type="text" name="place" onChange={handleChange}/>
        </Box>

        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <Box sx={{ width: "90%" }}>
            <Typography sx={{ textAlign: "center" }}>
              <span
                style={{
                  fontSize: "12px",
                  borderBottom: "2px solid #000",
                  display: "inline-block",
                  fontWeight: 600,
                }}
              >
                Annexures to the Application Form
              </span>
            </Typography>

            <Stack spacing={1} sx={{ my: "20px" }}>
              <Typography sx={{ fontSize: "10px" }}>
                ANNEXURE 1- Payment Schedule & Oher Charges (To be attached
                separately)
              </Typography>
              <Typography sx={{ fontSize: "10px" }}>
                ANNEXURE 2- Tentative layout plan (To be attached separately){" "}
              </Typography>
              <Typography sx={{ fontSize: "10px" }}>
                ANNEXURE 3- Tentative floor plan of the premises (To be attached
                separately)
              </Typography>
              <Typography sx={{ fontSize: "10px" }}>
                ANNEXURE 4- Commencement Certificate (To be attached separately){" "}
              </Typography>
              <Typography sx={{ fontSize: "10px" }}>
                ANNEXURE 5- Currently Approved Layout plans as approved by the
                Competent Authority (To be attached separately){" "}
              </Typography>
              <Typography sx={{ fontSize: "10px" }}>
                ANNEXURE 6- The bank details for the payment of Stamp Duty
                Charges, Registration Charges, Document Handling Charges and
                Service Charges (To be attached separately)
              </Typography>
              <Typography sx={{ fontSize: "10px" }}>
                ANNEXURE 7- Project Registration certificate (To be attached
                separately
              </Typography>
            </Stack>
          </Box>
        </Box>
        <Box sx={{ display: "flex", flexDirection: "column", gap: "15px" }}>
          <Typography>
            <span
              style={{
                fontSize: "10px",
                borderBottom: "1px solid #000",
                display: "inline-block",
                fontWeight: 600,
              }}
            >
              For Office use only:
            </span>
          </Typography>
          <Typography>
            <span
              style={{
                fontSize: "10px",
                borderBottom: "1px solid #000",
                display: "inline-block",
                fontWeight: 600,
              }}
            >
              Remarks:{" "}
            </span>
          </Typography>
        </Box>
      </Box>

      <Box
        sx={{
          display: "flex",
          gap: "40px",
          justifyContent: "center",
          position: "absolute",
          bottom: "30px",
        }}
      >
        <Box className={classes.input}>
          <Box>
            <input
              style={{ textAlign: "center" }}
              value={pageData17.sign_first}
              type="text"
              name="sign_first"
              onChange={handleChange}
            />
            <Typography sx={{ textAlign: "center", mt: "10px" }}>
              Signature of First Applicant
            </Typography>
          </Box>
        </Box>
        <Box className={classes.input}>
          <Box sx={{ display: "inline-block" }}>
            <input
              style={{ margin: "0px", textAlign: "center" }}
              value={pageData17.sign_second}
              type="text"
              name="sign_second"
              onChange={handleChange}
            />
            <Typography sx={{ textAlign: "center", mt: "10px" }}>
              Signature of Second Applicant
            </Typography>
          </Box>
        </Box>
        <Box className={classes.input}>
          <Box sx={{ display: "inline-block" }}>
            <input
              style={{ margin: "0px", textAlign: "center" }}
              value={pageData17.sign_third}
              type="text"
              name="sign_third"
              onChange={handleChange}
            />
            <Typography sx={{ textAlign: "center", mt: "10px" }}>
              Signature of Third Applicant
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default PdfOnePageSeventeen;
