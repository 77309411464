import { Box, Button, Container, Stack, Typography } from "@mui/material";
import BuilderLandingFooter from "../BuilderLandingPage/BuilderLandingFooter";
import CommonPagesFooter from "../Shared/CommonPagesFooter";
import CommonPagesNavbar from "../Shared/CommonPagesNavbar";
import CommonPagesResponsiveNavbar from "../Shared/CommonPagesResponsiveNavbar";
import ChannelLandingBecomePartOfTheEnvisionNext from "./ChannelLandingBecomePartOfTheEnvisionNext";
import ChannelLandingBecomingPartOfEnvisionNextPowersUp from "./ChannelLandingBecomingPartOfEnvisionNextPowersUp";
import ChannelLandingBridgingGaps from "./ChannelLandingBridgingGaps";
import ChannelLandingChannelPartnersBrokers from "./ChannelLandingChannelPartnersBrokers";
import ChannelLandingCustomerReview from "./ChannelLandingCustomerReview";
import ChannelLandingFeatures from "./ChannelLandingFeatures";
import ChannelLandingFuelYourRealEstateBusiness from "./ChannelLandingFuelYourRealEstateBusiness";
import ChannelLandingGetStartWithEnvisionNext from "./ChannelLandingGetStartWithEnvisionNext";
import ChannelLandingHeader from "./ChannelLandingHeader";
import ChannelLandingMakeTheMostOfTheEnvisionNext from "./ChannelLandingMakeTheMostOfTheEnvisionNext";
import ChannelLandingReadyToExploreNewAvenues from "./ChannelLandingReadyToExploreNewAvenues";

export const ChannelLandingPage = ({profile}) => {
  return (
    <>
      <CommonPagesNavbar profile={"channel partner"}/>
      <CommonPagesResponsiveNavbar />
      <Stack spacing={{ lg: 14, md2: 12, md: 7, xs: 5 }}>
        <ChannelLandingHeader />
        <ChannelLandingChannelPartnersBrokers />
        <ChannelLandingBridgingGaps />
        <ChannelLandingBecomePartOfTheEnvisionNext />
        <ChannelLandingFuelYourRealEstateBusiness />
        <ChannelLandingGetStartWithEnvisionNext />
        <ChannelLandingMakeTheMostOfTheEnvisionNext />
        <ChannelLandingBecomingPartOfEnvisionNextPowersUp />
        <Box>
          <ChannelLandingReadyToExploreNewAvenues />
          <CommonPagesFooter />
        </Box>
      </Stack>
    </>
  );
};
