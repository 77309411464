import { Stack, Typography } from "@mui/material";
import axios from "axios";
import { useEffect, useState } from "react"
import { Apiaddress } from "../../utils/consts";
import { MainSidebar } from "../BuilderComponents/ProjectDashboard/DashboardComponents/MainSidebar";




export const NotificationProject = ()=>{

    const [allNotification, setAllNotification] = useState({});

    useEffect(()=>{
     let url = Apiaddress+"";

     axios.get()
     .then((res)=>{
        console.log("notifications", res.data);
     }).catch((err)=>{
        console.log(err);
     })
    },[])

    return(
        <>

<Stack direction="row" sx={{width:"100%",height:"auto" }}>
      {/* 1st stack */}
      <Stack sx={{minHeight:"100vh",width:"20%",boxSizing:"border-box",}}> {/*for project directory 20% and for sales dashboard 225*/}
        <MainSidebar active={""}/>
      </Stack>

     {/* 2nd stack */}
     <Stack direction="column" sx={{ backgroundColor:"#f9f9f9",width:"80%", padding:"25px",boxSizing:"border-box",}}  >  {/*  width for main sidebar 78px and for 80px*/}
      {/* <Navbar/> */}
      


    <Typography sx={{mt:"20px", fontSize:"22px", fontWeight:"500",color:"#2979F2"}}>
    Project Name
    </Typography>
     {/* actual content  */}
    <Stack sx={{backgroundColor:"#f9f9f9"}}>  {/* mt:"28px", */}

      

    </Stack>
      </Stack>
</Stack>
        
        </>
    )
}