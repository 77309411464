import { Box, List, ListItem, Typography } from "@mui/material";
import "./styles/PageSix.css";
import React, {useState } from 'react'
import { Stack } from "@mui/system";
import classes from "./styles/PageOne.module.css";
import "./styles/PageSix.css";

const PdfOnePageSix = () => {

   const [pageData6,setPageData6]=useState({
    sign_first:"",
    sign_second:"",
    sign_third:""
  })

  // console.log(pageData6);

  let name,value;
  const handleChange=(e)=>{
    name=e.target.name;
    value=e.target.value;
    console.log(name," ",value);
    setPageData6({...pageData6,[name]:value});
  }

  return (
    <Box>
      <Box sx={{ mb: "40px" }}>
        <Box sx={{ ml: "10px", display: "flex", gap: "10px" }}>
          <Typography sx={{ fontSize: "10px" }}>4.</Typography>
          <Typography sx={{ fontSize: "10px" }}>
            The applicable GST, stamp duty and registration charges payable on
            Agreement for Sale shall be paid independently by the Applicant as
            per the demand raised by the Developer. The Confirmation Letter will
            be issued by the Developer to the Applicant only after receipt of
            10% of Application Money.
          </Typography>
        </Box>
        <Box sx={{ mt: "10px" }}>
          <Typography sx={{ fontSize: "10px" }}>
            TERMS AND CONDITIONS
          </Typography>

          <Stack spacing={0.5} sx={{ ml: "10px", mt: "10px" }}>
            <Box sx={{ display: "flex", gap: "10px" }}>
              <Box>
                <Typography sx={{ fontSize: "10px" }}>1.</Typography>
              </Box>
              <Stack spacing={0.5}>
                <Typography sx={{ fontSize: "10px" }}>DEFINITIONS</Typography>
                <Box sx={{ display: "flex", gap: "10px" }}>
                  <Typography sx={{ fontSize: "10px" }}>1.1</Typography>
                  <Typography sx={{ fontSize: "10px" }}>
                    <span>Allotment Premium:</span> Being the lumpsum price
                    offered by the Applicant(s) for the allotment of the
                    Premises.
                  </Typography>
                </Box>
                <Box sx={{ display: "flex", gap: "10px" }}>
                  <Typography sx={{ fontSize: "10px" }}>1.2</Typography>
                  <Typography sx={{ fontSize: "10px" }}>
                    Owners/Co-Promoters: Narayani Commodities Private Limited
                  </Typography>
                </Box>
                <Box sx={{ display: "flex", gap: "10px" }}>
                  <Typography sx={{ fontSize: "10px" }}>1.3</Typography>
                  <Typography sx={{ fontSize: "10px" }}>
                    Developer/Promoter: Nupur Highland Llp
                  </Typography>
                </Box>
                <Box sx={{ display: "flex", gap: "10px" }}>
                  <Typography sx={{ fontSize: "10px" }}>1.4</Typography>
                  <Typography sx={{ fontSize: "10px" }}>
                    GST: Goods and Services Tax
                  </Typography>
                </Box>
                <Box sx={{ display: "flex", gap: "10px" }}>
                  <Typography sx={{ fontSize: "10px" }}>1.5</Typography>
                  <Typography sx={{ fontSize: "10px" }}>
                    Carpet Area: Net Usable floor area of unit, excluding the
                    area covered by the external walls, areas under services
                    shafts, appurtenant to the said unit for exclusive use of
                    the Allottee(s) or verandah area appurtenant to the said
                    unit for exclusive use of the Allottee(s), but includes the
                    area covered by the internal partition walls of the unit.
                  </Typography>
                </Box>
                <Box sx={{ display: "flex", gap: "10px" }}>
                  <Typography sx={{ fontSize: "10px" }}>1.6</Typography>
                  <Typography sx={{ fontSize: "10px" }}>
                    Interest Rate: Interest at the rate at which the State Bank
                    of India’s highest Marginal CoSst of Lending Rate prevailing
                    plus 2 % (as specified in Rule 18 of the RERA Rules) on the
                    date on which the amounts and Other Charges payable by the
                    Applicant(s) to the Developer, if any, becomes due. Provided
                    in case the State Bank of India Marginal Cost of Lending
                    Rate is not in use, it would be replaced by such benchmark
                    lending rates which the State Bank of India may fix from
                    time to time for lending to the general public.
                  </Typography>
                </Box>
                <Box sx={{ display: "flex", gap: "10px" }}>
                  <Typography sx={{ fontSize: "10px" }}>1.7</Typography>
                  <Typography sx={{ fontSize: "10px" }}>
                    Other Charges: As defined in clause 7.4 and 7.5.
                  </Typography>
                </Box>
                <Box sx={{ display: "flex", gap: "10px" }}>
                  <Typography sx={{ fontSize: "10px" }}>1.8</Typography>
                  <Typography sx={{ fontSize: "10px" }}>
                    Regulatory Authority: Maharashtra Real Estate Regulatory
                    Authority.
                  </Typography>
                </Box>
                <Box sx={{ display: "flex", gap: "10px" }}>
                  <Typography sx={{ fontSize: "10px" }}>1.9</Typography>
                  <Typography sx={{ fontSize: "10px" }}>
                    RERA Act: Real Estate (Regulation and Development) Act,
                    2016.
                  </Typography>
                </Box>
                <Box sx={{ display: "flex", gap: "10px" }}>
                  <Typography sx={{ fontSize: "10px" }}>1.10</Typography>
                  <Typography sx={{ fontSize: "10px" }}>
                    RERA Rules: Maharashtra Real Estate (Regulation of real
                    estate projects, registration of Real Estate agents, rates
                    of interest and disclosures on website) Rules, 2017.
                  </Typography>
                </Box>
                <Box sx={{ display: "flex", gap: "10px" }}>
                  <Typography sx={{ fontSize: "10px" }}>1.11</Typography>
                  <Typography sx={{ fontSize: "10px" }}>
                    Premises: The Flats as are more particularly set out in
                    clause 6.13 below.
                  </Typography>
                </Box>
                <Box sx={{ display: "flex", gap: "10px" }}>
                  <Typography sx={{ fontSize: "10px" }}>1.12</Typography>
                  <Typography sx={{ fontSize: "10px" }}>
                    Project Property: A portion of the land admeasuring about
                    3166 Sq. Mtrs demarcated out of all that piece and parcel of
                    land admeasuring about 22935 Sq. Mtrs in Phase I of the
                    Project, “Agrawals Finopolis’, at Plot No.IS-3, in the
                    Patalganga Industrial Area within the Village limits of
                    Wasambe and outside the limits of Municipal Council in rural
                    area, Taluka Khalapur, District and Registration District
                    Raigad
                  </Typography>
                </Box>
                <Box sx={{ display: "flex", gap: "10px" }}>
                  <Typography sx={{ fontSize: "10px" }}>1.13</Typography>
                  <Typography sx={{ fontSize: "10px" }}>
                    Project: A mixed use re-development project comprising of
                    commercial and residential development, to be known as
                    “Agrawals Finopolis” being constructed on the Project
                    Property duly registered with the Regulatory Authority,
                    under the provisions of Section 5 of RERA Act read with RERA
                    Rules and under registration no. P52000027215.
                  </Typography>
                </Box>
              </Stack>
            </Box>
            <Box sx={{ display: "flex", gap: "10px" }}>
              <Box>
                <Typography sx={{ fontSize: "10px" }}>2.</Typography>
              </Box>
              <Stack spacing={0.5}>
                <Typography sx={{ fontSize: "10px" }}>APPLICATION</Typography>
                <Box sx={{ display: "flex", gap: "10px" }}>
                  <Typography
                    sx={{ width: "30px", fontSize: "10px" }}
                  ></Typography>
                  <Typography sx={{ width: "100%", fontSize: "10px" }}>
                    The signature of the Applicant(s) on this Application Form
                    shall constitute a contract to acquire the Premises in the
                    Project at or for the Allotment Premium together with the
                    Other Charges as mentioned in this Application Form subject
                    to the terms and conditions contained herein. This
                    Application does not confer or constitute any right or
                    reservation or entitlement or option to purchase in favour
                    of the Applicant of allottment and/or agreement for
                    acquisition of the Premises in the Project. The Applicant is
                    aware and acknowledges that the Developer shall reserve the
                    right to accept or reject this Application, at its sole
                    discretion and without assigning any reason for the same.
                    This Application and the terms thereof shall supersede all
                    the prior discussions/correspondence/emails (written or
                    otherwise) between the Applicant(s) and the Developer or its
                    agents or representatives.
                  </Typography>
                </Box>
              </Stack>
            </Box>
            <Box sx={{ display: "flex", gap: "10px" }}>
              <Box>
                <Typography sx={{ fontSize: "10px" }}>3.</Typography>
              </Box>
              <Stack spacing={0.5}>
                <Typography sx={{ fontSize: "10px" }}>
                  NRI/ NON RESIDENT/ FOREIGN NATIONAL OF INDIAN ORIGIN/ FOREIGN
                  NATIONALS/ FOREIGN COMPANIES/ PERSON OF INDIAN ORIGIN{" "}
                </Typography>
                <Box sx={{ display: "flex", gap: "10px" }}>
                  <Typography
                    sx={{ width: "30px", fontSize: "10px" }}
                  ></Typography>
                  <Typography sx={{ width: "100%", fontSize: "10px" }}>
                    The Applicant(s) agrees that in case of the Applicant(s) is
                    an NRI or Non-Resident/ Foreign National of Indian Origin/
                    Foreign Nationals/ Foreign Companies/ Person of Indian
                    Origin, then in that event, the Applicant(s) shall be solely
                    responsible for complying with the necessary formalities as
                    laid down in the FEMA Act, RBI Act and Rules/ guidelines/
                    circulars/ notifications made/ issued thereunder (including
                    the amendments and modifications made therein from time to
                    time) and all other applicable laws including that of
                    remittance of payments, acquisition/ sale or transfer of
                    immovable properties in India. In case, any such permission
                    is refused or subsequently cancelled/ revoked, by any
                    statutory authority, due to any violation or breach
                    committed by the Applicant(s), the same shall constitute
                    breach of the terms hereof and without prejudice to the
                    right of the Developer under this Application, Agreement for
                    Sale and under law, the Developer shall be entitled to
                    cancel this Application/ Confirmation Letter and/or
                    Agreement for Sale. Any implications arising out of any such
                    default by the Applicant(s), shall be the sole
                    responsibility of the Applicant(s). The Developer
                  </Typography>
                </Box>
              </Stack>
            </Box>
          </Stack>
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          gap: "40px",
          justifyContent: "center",
          position: "absolute",
          bottom: "30px",
        }}
      >
        <Box className={classes.input}>
          <Box>
            <input
              style={{ textAlign: "center" }}
              value={pageData6.sign_first}
              type="text"
              name="sign_first"
              onChange={handleChange}
            />
            <Typography sx={{ textAlign: "center", mt: "10px" }}>
              Signature of First Applicant
            </Typography>
          </Box>
        </Box>
        <Box className={classes.input}>
          <Box sx={{ display: "inline-block" }}>
            <input
              style={{ margin: "0px", textAlign: "center" }}
              value={pageData6.sign_second}
              type="text"
              name="sign_second"
              onChange={handleChange}
            />
            <Typography sx={{ textAlign: "center", mt: "10px" }}>
              Signature of Second Applicant
            </Typography>
          </Box>
        </Box>
        <Box className={classes.input}>
          <Box sx={{ display: "inline-block" }}>
            <input
              style={{ margin: "0px", textAlign: "center" }}
              value={pageData6.sign_third}
              type="text"
              name="sign_third"
              onChange={handleChange}
            />
            <Typography sx={{ textAlign: "center", mt: "10px" }}>  
              Signature of Third Applicant
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default PdfOnePageSix;
