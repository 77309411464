import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  MenuItem,
  Drawer,
  Box,
  Button,
  Checkbox,
  FormControl,
  InputLabel,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { Stack } from "@mui/system";
import TuneOutlinedIcon from "@mui/icons-material/TuneOutlined";
import KeyboardArrowRightOutlinedIcon from "@mui/icons-material/KeyboardArrowRightOutlined";
import KeyboardArrowUpOutlinedIcon from "@mui/icons-material/KeyboardArrowUpOutlined";
import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined";
import { CheckBox } from "@mui/icons-material";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import DriveFileRenameOutlineIcon from "@mui/icons-material/DriveFileRenameOutline";
import VisibilityIcon from "@mui/icons-material/Visibility";
import DeleteIcon from "@mui/icons-material/Delete";
import "./MDmainPage.css";
import { Sidebar } from "../ProjectDashboard/DashboardComponents/Sidebar";
import { Navbar } from "../ProjectDashboard/DashboardComponents/Navbar";
import React, { useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import CategoryIcon from "@mui/icons-material/Category";
import HandshakeIcon from "@mui/icons-material/Handshake";
import RightSidebarMD from "./RightSidebarMD";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import { useParams } from "react-router-dom";
import axios from "axios";
import { NavbarForDirectory } from "../ProjectDashboard/DashboardComponents/NavbarForDirectory";
import EditIcon from "@mui/icons-material/Edit";
import MDcustomTable from "./MDcustomTable";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Apiaddress } from "../../../utils/consts";
import { MainSidebar } from "../ProjectDashboard/DashboardComponents/MainSidebar";
import { UserRole } from "../../../UserRoles/UserRoles";

function MDmainPage() {
  
  ////get user roles from local storage and assign 
  let roles = JSON.parse(localStorage.getItem("user_roles"))
  let member = roles["members"];

  const delIconClick = async (param) => {
    try {
      const obj = {
        member_id: param.Member_id,
      };
      const res = await axios.delete(
        Apiaddress + "/api/role/memberview/",
        { data: obj }
      );
      setTrigger(!trigger);
      console.log(res.data);
    } catch (err) {
      console.log(err);
    }
  };

  const [updateInfo, setupdateInfo] = useState(null);

  const toggleDrawer = (open, item) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
   if(item) updateMembers(item);
    console.log("clicked");
    setState(open);
  };

  const updateMembers = (params) => {
    const obj = {
      member_id: params.Member_id,
      name: params.Name,
      phone: params.Phone,
      email: params.Email,
    };
    setupdateInfo(obj);
    console.log(params);
  };

  const [state, setState] = React.useState(false);

  const [selectedRows, setSelectedRows] = React.useState([]);
  useEffect(() => {
    // console.log(selectedRows);
  }, [selectedRows]);

  const [curselectedRows, setcurselectedRows] = useState(null);
  const onRowsSelectionHandler = (ids) => {
    // setRowSelected(true);
    const selectedRowsData = ids.map((id) =>
      APIrows.find((row) => row.id === id)
    );
    console.log(selectedRowsData);
    setcurselectedRows(selectedRowsData);
  };

  const [tabledata, setTabledata] = useState(null);
  const [rowData, setrowData] = useState([]);
  const [idCont, setidCunt] = useState(1);

  //api

  //api

  useEffect(() => {
    const rows = [
      {
        id: 8,
        Name: "Frances",
        Phone: "912912912",
        Email: "dummmy@gamil.com",
        Team: "Agent",
        Role: "Agent",
        DetailedView: "View",
      },
    ];
    if (tabledata) {
      let obj = {
        id: idCont,
        Name: tabledata.name,
        Phone: tabledata.phone,
        Email: tabledata.Email,
        Team: tabledata.Team,
        Role: tabledata.Role,
      };
      setidCunt(idCont + 1);
      console.log(obj);
      setrowData([...rowData, obj]);
    }

    console.log(tabledata);
  }, [tabledata]);

  const [rowSelected, setRowSelected] = useState(false);

  const id = useParams().id.split("__")[0];
  const showName = useParams().id.split("__")[1];
  console.log("params",showName,id)
  const [teams, setTeams] = useState(null);
  const [members, setMembers] = useState([]);

  const fetchTeams = async () => {
    const token = localStorage.getItem("crm_token");

    try {
      const headers = {
        Authorization: `JWT ${token}`,
      };
      const res = await axios.get(
        Apiaddress + "/api/role/teamlistview/",
        { headers }
      );
      // console.log(res.data.teams);
      setTeams(res.data.teams);
      const data = res.data.teams;

      for (let i = 0; i < data.length; i++) {
        if (data[i].id == id) {
          const k = data[i].members;
          setMembers(k);
          break;
        }
      }
    } catch (err) {
      console.log(err);
    }
  };
  const [trigger, setTrigger] = useState(false);

  useEffect(() => {
    fetchTeams();
  }, [trigger]);

  const [APIrows, setApiRows] = useState([]);

  useEffect(() => {
    if (members) {
      console.log(members);
      // {
      //   id: 7,
      //   Name: "Clifford",
      //   Phone: "912912912",
      //   Email: "dummmy@gamil.com",
      //   Team: "Agent",
      //   Role: "Agent",
      //   DetailedView: "View",
      // },

      let arr = [];
      for (let i = 0; i < members.length; i++) {
        const obj = {
          id: i + 1,
          Name: members[i].name,
          Phone: members[i].phone,
          Email: members[i].email,
          Team: id,
          Role: members[i].role,
          Member_id: members[i].id,
          DetailedView: "View",
        };
        // console.log(obj);
        arr.push(obj);
      }
      setApiRows(arr);
      // console.log(members);
    }
  }, [members, trigger]);

  useEffect(() => {
    if (APIrows) {
      // console.log(APIrows);
    }
  }, [APIrows]);

  const [filterselect, setfilterselect] = useState("");

  const handleChange = (event) => {
    setfilterselect(event.target.value);
  };

  const [stateadd, setStateadd] = React.useState(false);

  const toggleDrawerAdd = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setupdateInfo(null);
    setState(open);
  };

  return (

    <>

<Stack direction="row" sx={{width:"100%",height:"auto" }}>
      {/* 1st stack */}
      <Stack sx={{minHeight:"100vh",width:"22%",boxSizing:"border-box",}}>
        <MainSidebar active={"Teams Directory"}/>
      </Stack>

     {/* 2nd stack */}
     <Stack direction="column" sx={{ backgroundColor:"#f9f9f9",width:"80%", padding:"25px",boxSizing:"border-box",}}  >  {/*  width for main sidebar 78px and for 80px*/}
      {/* <Navbar/> */}
      <NavbarForDirectory
          // showRightSideBar={UserRole(member,"add_members")?true:false}
          RightBarName={"Add Member"}
          // toggleDrawer={toggleDrawer}
          toggleDrawerAdd={toggleDrawerAdd}
          leftTitle={"Team directory"}
          righshow={true}
          leftRightTitle1={"Project Dashboard"}
          leftRightTitle2={"Member Directory"}
          memberadd={true}
        />
      


    {/* <Typography sx={{mt:"20px", fontSize:"22px", fontWeight:"500",color:"#2979F2"}}>
    Project Name
    </Typography> */}
     {/* actual content  */}
    <Stack sx={{ mt:"28px",backgroundColor:"#f9f9f9"}}>

    <React.Fragment>
          <Drawer anchor="right" open={state} onClose={toggleDrawer(false)}>
            <RightSidebarMD
              setTabledata={setTabledata}
              curselectedRows={curselectedRows}
              id={id}
              setTrigger={setTrigger}
              trigger={trigger}
              updateMember={updateInfo}
              setState={setState}
            />
          </Drawer>
        </React.Fragment>


        <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            sx={{ height: "68px", marginBottom: "20px" }}
          >
            <Stack direction="column" alignItems="center">
              <Typography
                sx={{
                  fontSize: "22px",
                  fontWeight: "600",
                  marginLeft: "10px",
                  color: "black",
                }}
              >
                Members Directory
              </Typography>
              <Typography
                sx={{
                  color: "#B9B7D0",
                  fontSize: "12px",
                  fontWeight: "400",
                }}
              >
                Showing Member list of <span style={{color:"blue",  fontSize: "12px",fontWeight: "400",}}>{showName}</span> 
              </Typography>
            </Stack>
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Stack
                direction="row"
                justifyContent="space-around"
                alignItems="center"
                sx={{
                  width: "203px",
                  height: "68px",
                  borderRadius: "12px",
                  color: "#3E4954",
                  backgroundColor: "#f4f5f9",
                }}
              >
                <Accordion sx={{ width: "250px", boxShadow: "none" }}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography sx={{ display: "flex", placeItems: "center" }}>
                      <TuneOutlinedIcon sx={{ color: "#2F4CDD" }} />
                      <Typography sx={{ marginLeft: "25px" }}>
                        Fillters
                      </Typography>
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Box>
                      <FormControl sx={{ width: "200px" }}>
                        <InputLabel id="demo-simple-select-label">
                          Age
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={filterselect}
                          label="Age"
                          onChange={handleChange}
                        >
                          <MenuItem value={10}>Name</MenuItem>
                          <MenuItem value={20}>State</MenuItem>
                          <MenuItem value={30}>City</MenuItem>
                          <MenuItem value={40}>Pincode</MenuItem>
                        </Select>
                      </FormControl>
                      <TextField
                        sx={{ marginTop: "20px" }}
                        placeholder="Enter to filter"
                      />
                       <Button 
                        // onClick={fetchfilter}
                        variant="contained"
                        color="primary" sx={{ height:"40px", background: "linear-gradient(90deg, #0F5DAE 0%, #009DCF 100.33%)",boxShadow: "0px 13.7981px 22.5788px rgba(50, 174, 137, 0.25)", textTransform: "none", borderRadius: "4px", width:"100px", fontSize:"15px",margin:"20px 0px 10px 0px"}}>
                         Submit
                      </Button>
                    </Box>
                  </AccordionDetails>
                </Accordion>
              </Stack>
            </Stack>
          </Stack>


           {/* ================filters and all=============================== */}
           <Box>
            {rowSelected && (
              <Stack direction="row" sx={{ margin: "20px" }}>
                <Button
                  onClick={toggleDrawer(true)}
                  startIcon={<CategoryIcon />}
                  className="univercel_btn_class"
                  sx={{
                    color: "#FFFFFF",
                    textTransform: "none",
                    width: "200px",
                    background:
                      " linear-gradient(150deg, #79F1A4 13.4%, #0E5CAD 86.6%)",
                  }}
                >
                  Mark Roles and Teams
                </Button>
                <Button
                  startIcon={<HandshakeIcon />}
                  className="univercel_btn_class"
                  sx={{
                    color: "#FFFFFF",
                    textTransform: "none",
                    background:
                      " linear-gradient(150deg, #79F1A4 13.4%, #0E5CAD 86.6%)",
                    width: "200px",
                    marginLeft: "20px",
                  }}
                >
                  Mark Channel Partner
                </Button>
              </Stack>
            )}
            <Stack></Stack>
          </Box>
          <MDcustomTable
            delIconClick={delIconClick}
            newRowData={APIrows}
            updateMembers={updateMembers}
            toggleDrawer={toggleDrawer}
            team_id={id}
            team_name={showName}
          />


          

    </Stack>




      </Stack>
</Stack>


    </>
  );
}

export default MDmainPage;
