import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  MenuItem,
  Drawer,
  Box,
  Button,
  Checkbox,
  FormControl,
  InputLabel,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { Stack } from "@mui/system";
import TuneOutlinedIcon from "@mui/icons-material/TuneOutlined";
import KeyboardArrowRightOutlinedIcon from "@mui/icons-material/KeyboardArrowRightOutlined";
import KeyboardArrowUpOutlinedIcon from "@mui/icons-material/KeyboardArrowUpOutlined";
import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined";
import { CheckBox } from "@mui/icons-material";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import DriveFileRenameOutlineIcon from "@mui/icons-material/DriveFileRenameOutline";
import VisibilityIcon from "@mui/icons-material/Visibility";
import DeleteIcon from "@mui/icons-material/Delete";
import "./MDmainPage.css";
import { Sidebar } from "../ProjectDashboard/DashboardComponents/Sidebar";
import { Navbar } from "../ProjectDashboard/DashboardComponents/Navbar";
import React, { useEffect, useState } from "react";
import { DataGrid, GridExpandMoreIcon } from "@mui/x-data-grid";
import CategoryIcon from "@mui/icons-material/Category";
import HandshakeIcon from "@mui/icons-material/Handshake";
import RightSidebarMD from "./RightSidebarMD";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import RightSidebarTD from "./RightSidebarTD";
import { NavbarForDirectory } from "../ProjectDashboard/DashboardComponents/NavbarForDirectory";
import TDcustomTable from "./TDcustomTable";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Apiaddress } from "../../../utils/consts";
import { MainSidebar } from "../ProjectDashboard/DashboardComponents/MainSidebar";
import { UserRole } from "../../../UserRoles/UserRoles";

function TDmainPgae() {
  
  ////get user roles from local storage and assign 
  let roles = JSON.parse(localStorage.getItem("user_roles"))
  let team = roles["teams"];

  const viewMembers = (params) => {
    console.log("params",params);
    navigate(`/membersdirectory/${params.team_id+"__"+params.Name}`); 
  };

  const [updateData, setupdateData] = useState(null);
  const updateTeam = (item) => {
    setupdateData(item);
    // console.log(item);
  };
  const [trigger, setTrigger] = useState(false);

  const delIconClick = async (params) => {
    // console.log(params.row.team_id);
    try {
      const obj = { team_id: params.team_id };
      const res = await axios.delete(
        Apiaddress + "/api/role/teamview/",
        {
          data: obj,
        }
      );
      setTrigger(!trigger);
      console.log(res);
    } catch (err) {
      console.log(err);
    }
  };
  const navigate = useNavigate();
  const [state, setState] = React.useState(false);

  const toggleDrawer = (open, item) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    if (item) updateTeam(item);
    setState(open);
  };

  const [selectedRows, setSelectedRows] = React.useState([]);
  useEffect(() => {
    console.log(selectedRows);
  }, [selectedRows]);

  const [curselectedRows, setcurselectedRows] = useState(null);
  const onRowsSelectionHandler = (ids) => {
    setRowSelected(true);
    const selectedRowsData = ids.map((id) =>
      APIrows.find((row) => row.id === id)
    );
    console.log(selectedRowsData);
    setcurselectedRows(selectedRowsData);
  };

  const [tabledata, setTabledata] = useState(null);
  const [rowData, setrowData] = useState([]);
  const [idCont, setidCunt] = useState(1);

  //api

  //api

  useEffect(() => {
    const rows = [
      {
        id: 1,
        Name: "Snow",
        Phone: "912912912",
        Email: "dummmy@gamil.com",
        Projects: 2,
        Priority: "High",
        DetailedView: "View",
      },
    ];
    if (tabledata) {
      let obj = {
        id: idCont,
        Name: tabledata.name,
        Phone: tabledata.phone,
        Email: tabledata.Email,
        Projects: tabledata.setProjects.length,
        Priority: tabledata.priority,
        DetailedView: "View",
      };
      setidCunt(idCont + 1);
      console.log(obj);
      setrowData([...rowData, obj]);
    }

    // console.log(tabledata);
  }, [tabledata]);

  const [rowSelected, setRowSelected] = useState(false);

  const [teams, setTeams] = useState(null);

  const fetchTeams = async () => {
    const token = localStorage.getItem("crm_token");

    try {
      const headers = {
        Authorization: `JWT ${token}`,
      };
      const res = await axios.get(
        Apiaddress + "/api/role/teamlistview/",
        { headers }
      );
      console.log(res.data.teams);
      if(res.data.teams=="team not found"){
        setTeams([]);
      }
      else{
        setTeams(res.data.teams);
      }
    } catch (err) {
      console.log(err);
      setTeams([]);
    }
  };

  useEffect(() => {
    fetchTeams();
  }, [trigger]);

  const [APIrows, setApiRows] = useState([]);

  useEffect(() => {
    if (teams) {
      // {
      //   id: 1,
      //   Name: "Snow",
      //   Members: "2",
      //   Email: "dummmy@gamil.com",
      // },
      let arr = [];
      for (let i = 0; i < teams.length; i++) {
        const obj = {
          id: i + 1,
          team_id: teams[i].id,
          Name: teams[i].name,
          Members: teams[i].members ? teams[i].members.length : 0,
          Email: "test@email.com",
        };
        arr.push(obj);
      }
      setApiRows(arr);
      // console.log(teams);
    }
  }, [teams, trigger]);

  useEffect(() => {
    // if (APIrows) console.log(APIrows);
  }, [APIrows]);

  //fitering data starts:====
  const [filterselect, setfilterselect] = useState();
  const [filtervalue, setfiltervalue] = useState();

  const handleChange = (event) => {
    setfilterselect(event.target.value);
    setfiltervalue("");
  };

  console.log(filterselect);
  console.log(filtervalue);
  
  const fetchfilter= async ()=>{

    const token = localStorage.getItem("crm_token");
    try {
      const headers = {
        Authorization: `JWT ${token}`,
      };
      const res = await axios.get(
        Apiaddress + `/api/role/teamlistview/?filterkey=${filterselect}&filtervalue=${filtervalue}`,
        { headers }
      );
      
      if(res.data.teams=="team not found"){
        setTeams([]);
      }
      else{
        setTeams(res.data.teams);
      }
    } catch (err) {
      console.log(err);
      setTeams([]);
    }

  }

  const toggleDrawerAdd = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setupdateData(null);
    setState(open);
  };

  return (
    <>

<Stack direction="row" sx={{width:"100%",height:"auto" }}>
      {/* 1st stack */}
      <Stack sx={{minHeight:"100vh",width:"20%",boxSizing:"border-box",}}>
        <MainSidebar active={"Teams Directory"} />
      </Stack>

     {/* 2nd stack */}
     <Stack direction="column" sx={{ backgroundColor:"#f9f9f9",width:"80%", padding:"25px",boxSizing:"border-box",}}  >  {/*  width for main sidebar 78px and for 80px*/}
      {/* <Navbar/> */}
      <NavbarForDirectory
          // showRightSideBar={UserRole(team, "add_teams")?true:false}
          toggleDrawer={toggleDrawer}
          toggleDrawerAdd={toggleDrawerAdd}
          RightBarName={"Add Team"}
          leftTitle={"Sales Dashboard"}
          righshow={true}
          leftRightTitle2={"Teams Directory"}
          teamadd={true}
        />
      


    {/* <Typography sx={{mt:"20px", fontSize:"22px", fontWeight:"500",color:"#2979F2"}}>
    Project Name
    </Typography> */}
     {/* actual content  */}
    <Stack sx={{ mt:"28px",backgroundColor:"#f9f9f9"}}>

        <React.Fragment>
          <Drawer anchor="right" open={state} onClose={toggleDrawer(false)}>
            <RightSidebarTD
              setTabledata={setTabledata}
              curselectedRows={curselectedRows}
              setTrigger={setTrigger}
              trigger={trigger}
              updateData={updateData}
              setState={setState}
            />
          </Drawer>
        </React.Fragment>

        <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            sx={{ height: "68px", marginBottom: "20px" }}
          >
            <Stack direction="column" alignItems="center">
              <Typography
                sx={{
                  fontSize: "22px",
                  fontWeight: "600",
                  marginLeft: "-30px",
                  color: "black",
                }}
              >
                Teams Directory
              </Typography>
              <Typography
                sx={{
                  color: "#B9B7D0",
                  fontSize: "12px",
                  fontWeight: "400",
                  paddingLeft: "10px",
                }}
              >
                Here is your Teams Directory List data
              </Typography>
            </Stack>
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Stack
                direction="row"
                justifyContent="space-around"
                alignItems="center"
                sx={{
                  width: "203px",
                  height: "68px",
                  borderRadius: "12px",
                  color: "#3E4954",
                  backgroundColor: "#f4f5f9",
                }}
              >
                
              <Accordion sx={{ width: "250px", boxShadow: "none" }}>
                  <AccordionSummary
                    expandIcon={<GridExpandMoreIcon/>}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography sx={{ display: "flex", placeItems: "center" }}>
                      <TuneOutlinedIcon sx={{ color: "#2F4CDD" }} />
                      <Typography sx={{ marginLeft: "25px" }}>
                        Fillters
                      </Typography>
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Box sx={{textAlign:"center"}}>
                      <FormControl sx={{ width: "200px" }}>
                        <InputLabel id="demo-simple-select-label">
                          Select
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={filterselect}
                          label="Select"
                          onChange={handleChange}
                        >
                          <MenuItem value={"name"}>Name</MenuItem>
                          {/* <MenuItem value={"Name"}>Name</MenuItem> */}
                        </Select>
                      </FormControl>
                      <TextField
                        sx={{ marginTop: "20px" }}
                        placeholder="Enter the value" 
                        value={filtervalue} onChange={(e)=>{setfiltervalue(e.target.value)}}
                      />
                      <Button 
                        onClick={fetchfilter}
                        variant="contained"
                        color="primary" sx={{ height:"40px", background: "linear-gradient(90deg, #0F5DAE 0%, #009DCF 100.33%)",boxShadow: "0px 13.7981px 22.5788px rgba(50, 174, 137, 0.25)", textTransform: "none", borderRadius: "4px", width:"100px", fontSize:"15px",margin:"20px 0px 10px 0px"}}>
                         Submit
                      </Button>

                    </Box>
                  </AccordionDetails>
                </Accordion>

              </Stack>
            </Stack>
          </Stack>

          <TDcustomTable
            newRowData={APIrows}
            viewMembers={viewMembers}
            updateTeam={updateTeam}
            delIconClick={delIconClick}
            toggleDrawer={toggleDrawer}
            setState={setState}
          />

    </Stack>




      </Stack>
</Stack>

    </>
  );
}

export default TDmainPgae;
