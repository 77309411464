import {
  Box,
  Checkbox,
  Stack,
  Button,
  Accordion,
  AccordionSummary,
  Typography,
  CircularProgress,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { useNavigate } from "react-router-dom";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AccordionDetails from "@mui/material/AccordionDetails";
import { UserRole } from "../../../UserRoles/UserRoles";
import axios from "axios";
import { Apiaddress } from "../../../utils/consts";

function QDcustomTable({
  newRowData,
  delIconClick,
  updateBuyers,
  setTrigger,
  trigger,
  loading,
  handleOpenOverview,
  setLeadName,
  setGrandTotalOverview,
  setValidOverview,
  setUnitOverviewCharge,
  setUnitOverviewName,
  setLeadId,
  setUnitId,
  setQuotationId,
  handleOpenEdit,
  setDateEdit,
  setQuotationStatus
}) {


   //Deepak code for Quotation Overview
   
   //function to fetch unit Charges Details
   const fetchUnitChargeOverview= async(unit_id)=>{
      try {
      setUnitId(unit_id);
      const res = await axios.get(
        Apiaddress + "/api/projects/unit/?unit_id=" + unit_id
      );
      console.log("quotation Charge====",res?.data?.unit[0]);
      setUnitOverviewName(res?.data?.unit[0]?.name);
      let temp=res?.data?.unit[0]?.charges_data;
        if(Object.keys(temp).length === 0 && temp.constructor === Object){
          setUnitOverviewCharge([]);
          }
        else{
          setUnitOverviewCharge(temp);
          }

    } catch (err) {
      console.log(err);
      setUnitOverviewCharge([]);
    } 
   }



    ////get user roles from local storage and assign 
    let roles = JSON.parse(localStorage.getItem("user_roles"))
  
    let quotation = roles['quotation'];

  //   console.log(newRowData);
  const navigate = useNavigate();
  const [curpage, setcurpage] = useState(1);
  const [pageRange, setPageRange] = useState([0, 6]);

  const nextClick = () => {
    if (
      !(newRowData.length >= pageRange[0] && newRowData.length <= pageRange[1])
    ) {
      setcurpage(curpage + 1);
      setPageRange([pageRange[0] + 6, pageRange[1] + 6]);
    }
  };

  const prvClick = () => {
    if (pageRange[0] != 0 && pageRange[1] != 6) {
      setcurpage(curpage - 1);
      setPageRange([pageRange[0] - 6, pageRange[1] - 6]);
    }
  };

  const [pages, setPages] = useState([]);
  useEffect(() => {
    if (newRowData) {
      let arr = [];
      for (
        let i = 1;
        i <=
        (newRowData.length % 6 == 0
          ? newRowData.length / 6
          : newRowData.length / 6 + 1);
        i++
      ) {
        arr.push(i);
      }
      setPages(arr);
    }
  }, [newRowData]);

  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

   return (
    
    <Box sx={{height:"100vh",width:"100%"}}>   
        <Box sx={{ color: "black", width: "100%" ,boxSizing:"border-box",display:"flex",flexDirection:"column"}}>

            <Stack direction="row" justifyContent="left" alignItems="center" sx={{width:"100%",backgroundColor:"white",borderRadius: "5px",margin:"0px 0px 10px 0px",padding:"10px 0px 10px 0px"}}>
              
                <Stack direction="row" justifyContent="center" alingItems="center" sx={{width:"15vw",textAlign:"center"}}>
                        <Typography sx={{ display: "grid", placeItems: "center",color: "#272D4E",fontWeight: "700",fontFamily:"Circular Std",fontSize:"18px" }}>Lead Name</Typography>
                        {/* <Box sx={{ display: "grid", marginLeft: "4px" }}>
                            <ArrowDropUpIcon fontSize="medium" sx={{ marginBottom: "-8px" }}/>
                            <ArrowDropDownIcon fontSize="medium" sx={{ marginTop: "-8px" }}/>
                        </Box> */}
                </Stack>

                 <Stack direction="row" justifyContent="center" alingItems="center" sx={{width:"15vw",textAlign:"center",color: "#272D4E",fontWeight: "700",fontFamily:"Circular Std",fontSize:"18px"}}>
                        <Typography sx={{ display: "grid", placeItems: "center",color: "#272D4E",fontWeight: "700",fontFamily:"Circular Std",fontSize:"18px" }}>Price</Typography>
                        {/* <Box sx={{ display: "grid", marginLeft: "4px" }}>
                            <ArrowDropUpIcon fontSize="medium" sx={{ marginBottom: "-8px" }}/>
                            <ArrowDropDownIcon fontSize="medium" sx={{ marginTop: "-8px" }}/>
                        </Box> */}
                </Stack>

                 <Stack direction="row" justifyContent="center" alingItems="center" sx={{width:"15vw",textAlign:"center",color: "#272D4E",fontWeight: "700",fontFamily:"Circular Std",fontSize:"18px"}}>
                        <Typography sx={{ display: "grid", placeItems: "center",color: "#272D4E",fontWeight: "700",fontFamily:"Circular Std",fontSize:"18px" }}>Created Date</Typography>
                        {/* <Box sx={{ display: "grid", marginLeft: "4px" }}>
                            <ArrowDropUpIcon fontSize="medium" sx={{ marginBottom: "-8px" }}/>
                            <ArrowDropDownIcon fontSize="medium" sx={{ marginTop: "-8px" }}/>
                        </Box> */}
                </Stack>

                 <Stack direction="row" justifyContent="center" alingItems="center" sx={{width:"15vw",textAlign:"center",color: "#272D4E",fontWeight: "700",fontFamily:"Circular Std",fontSize:"18px"}}>
                        <Typography sx={{ display: "grid", placeItems: "center",color: "#272D4E",fontWeight: "700",fontFamily:"Circular Std",fontSize:"18px" }}>Valid Date</Typography>
                        {/* <Box sx={{ display: "grid", marginLeft: "4px" }}>
                            <ArrowDropUpIcon fontSize="medium" sx={{ marginBottom: "-8px" }}/>
                            <ArrowDropDownIcon fontSize="medium" sx={{ marginTop: "-8px" }}/>
                        </Box> */}
                </Stack>

                 <Stack direction="row" justifyContent="center" alingItems="center" sx={{width:"15vw",textAlign:"center",color: "#272D4E",fontWeight: "700",fontFamily:"Circular Std",fontSize:"18px"}}>
                        <Typography sx={{ display: "grid", placeItems: "center",color: "#272D4E",fontWeight: "700",fontFamily:"Circular Std",fontSize:"18px" }}>Status</Typography>
                        {/* <Box sx={{ display: "grid", marginLeft: "4px" }}>
                            <ArrowDropUpIcon fontSize="medium" sx={{ marginBottom: "-8px" }}/>
                            <ArrowDropDownIcon fontSize="medium" sx={{ marginTop: "-8px" }}/>
                        </Box> */}
                </Stack>

                 <Stack direction="row" justifyContent="center" alingItems="center" sx={{width:"15vw",textAlign:"center",color: "#272D4E",fontWeight: "700",fontFamily:"Circular Std",fontSize:"18px"}}>
                        <Typography sx={{ display: "grid", placeItems: "center",color: "#272D4E",fontWeight: "700",fontFamily:"Circular Std",fontSize:"18px" }}>Actions</Typography>
                        {/* <Box sx={{ display: "grid", marginLeft: "4px" }}>
                            <ArrowDropUpIcon fontSize="medium" sx={{ marginBottom: "-8px" }}/>
                            <ArrowDropDownIcon fontSize="medium" sx={{ marginTop: "-8px" }}/>
                        </Box> */}
                </Stack>

            </Stack>

             {loading==true?
            <Box sx={{display:"flex",height:"80vh",padding:"10% 5% 5% 45%"}}>
                <CircularProgress color="inherit" sx={{ color: 'grey.500' }}/>
            </Box>
             :
            <Box sx={{}}>
                {newRowData.length==0?
                    <Box sx={{textAlign:"center",marginTop:"50px"}}>
                        <Typography sx={{fontSize:"25px",fontWeight:"500",color:"#B9B7D0",}}>No Matches Found</Typography>
                    </Box>:
                    <Box>
                        {newRowData && newRowData?.map((item, index) => {
                            if (index >= pageRange[0] && index <= pageRange[1]) {
                                return (
                                    
                                    <>
                                      <Stack 
                                      direction="row" justifyContent="left" alignItems="center" sx={{width:"100%",borderRadius: "5px",margin:"0px 0px 10px 0px",padding:"10px 0px 10px 0px",backgroundColor:"white"}}>

                                       <Stack direction="row" justifyContent="center" alingItems="center" sx={{width:"15vw",textAlign:"left",fontFamily:"Product Sans",fontWeight:"400"}}>
                                        <Typography sx={{fontFamily:"Product Sans",color:"#343434",fontWeight:"400"}}>
                                           {item?.lead_name}
                                        </Typography>
                                       </Stack>
                                       <Stack direction="row" justifyContent="center" alingItems="center" sx={{width:"15vw",textAlign:"center",fontWeight: "600"}}>
                                        <Typography sx={{fontFamily:"Product Sans",color:"#343434",fontWeight:"400"}}>
                                          {item?.price}
                                        </Typography>
                                       </Stack>
                                       <Stack direction="row" justifyContent="center" alingItems="center" sx={{width:"15vw",textAlign:"center",fontWeight: "600"}}>
                                        <Typography sx={{fontFamily:"Product Sans",color:"#343434",fontWeight:"400"}}>
                                          {item?.created_at.substring(0, 10)}
                                        </Typography>
                                       </Stack>
                                       <Stack direction="row" justifyContent="center" alingItems="center" sx={{width:"15vw",textAlign:"center",fontWeight: "600"}}>
                                        <Typography sx={{fontFamily:"Product Sans",color:"#343434",fontWeight:"400"}}>
                                           {item?.valid.substring(0, 10)}
                                        </Typography>
                                       </Stack>
                                       <Stack direction="row" justifyContent="center" alingItems="center" sx={{width:"15vw",textAlign:"center",color: "",fontWeight: "600"}}>
                                        <Typography sx={{color:item?.status=="Pending"?"orange":item?.status=="Approved"?"green":"red"}} >
                                           {item?.status}
                                        </Typography>
                                       </Stack>

                                       <Stack direction="row" justifyContent="center" alingItems="center" spacing={1.5} sx={{width:"15vw",textAlign:"center"}}>
                                        {
                                          item?.status=="Pending" &&  <EditIcon sx={{color: "#9299BC",cursor:"pointer"}} onClick={()=>{
                                            setQuotationId(item?.q_id);
                                            setDateEdit(item?.valid.substring(0, 10));
                                            handleOpenEdit();
                                          }}/>
                                        }
                                          <RemoveRedEyeIcon sx={{color:"#343434",cursor:"pointer"}} onClick={()=>{
                                            setLeadName(item?.lead_name);
                                            setValidOverview(item?.valid.substring(0, 10));
                                            setGrandTotalOverview(item?.price);
                                            fetchUnitChargeOverview(item?.unit_id);
                                            setLeadId(item?.lead_id);
                                            setQuotationId(item?.q_id);
                                            setQuotationStatus(item?.status);
                                            handleOpenOverview()
                                          }}/>
                                          <DeleteIcon sx={{color:"#343434",cursor:"pointer"}} onClick={() => delIconClick(item)}/>
                                        </Stack> 

                                    </Stack>
                                    
                                    </>  
                                );
                            }
                        })}

        <Box sx={{margin:"50px 0px 40px 0px"}}>
          <Box sx={{ display: "flex", justifyContent: "right" }}>
            {newRowData.length!=0 && 
            <Button variant="contained" sx={{background:"linear-gradient(90deg, #0F5DAE 0%, #009DCF 100.33%)"}}
              onClick={prvClick}>
                <ArrowBackIosIcon fontSize="small" />
                <Box sx={{ textTransform: "capitalize" }}>Previous</Box>
            </Button>}

            <Box sx={{background: "#E3E4EB",display: "flex",placeItems: "center",margin: " 0 10px",borderRadius: "5px"}}>
                <Box sx={{background: `white`,color: "black",width: "30px",borderRadius: "5px",margin: "0 10px",display: "grid",placeItems: "center"}}>{curpage}</Box>
                <Box sx={{color: "black",width: "30px",borderRadius: "5px",margin: "0 0px",display: "grid",placeItems: "center"}}>of</Box>
                <Box sx={{background: `white`,color: "black",width: "30px",borderRadius: "5px",margin: "0 10px",display: "grid",placeItems: "center"}}>{pages?.length}</Box>
            </Box>

            {newRowData.length!=0 && 
                <Button variant="contained" sx={{background:"linear-gradient(90deg, #0F5DAE 0%, #009DCF 100.33%)"}}
                    onClick={nextClick}>
                    <Box sx={{ textTransform: "capitalize" }}>Next</Box>
                    <ArrowForwardIosIcon fontSize="small" />
                </Button>
            }
          </Box>
        </Box>
      </Box>
    }
    </Box>
    }
    </Box>
    </Box>
  );
}

export default QDcustomTable;
