import { Box, List, ListItem, Typography } from "@mui/material";
import "./styles/PageSix.css";
import React, {useState } from 'react';
import { Stack } from "@mui/system";
import classes from "./styles/PageOne.module.css";

const PdfOnePageSixteen = () => {

  const [pageData16,setPageData16]=useState({
    sign_first:"",
    sign_second:"",
    sign_third:"",
  })

  let name,value;
  const handleChange=(e)=>{
    name=e.target.name;
    value=e.target.value;
    console.log(name," ",value);
    setPageData16({...pageData16,[name]:value});
  }

  return (
    <Box>
      <Box sx={{ mb: "40px" }}>
        <Stack sx={{ ml: "10px", mt: "10px" }}>
          <Box sx={{ display: "flex", gap: "10px" }}>
            <Box>
              <Typography sx={{ fontSize: "10px" }}>13.</Typography>
            </Box>
            <Stack spacing={0.5}>
              <Typography sx={{ fontSize: "10px" }}>
                DISPUTE RESOLUTION
              </Typography>
              <Box sx={{ display: "flex", gap: "10px" }}>
                <Typography sx={{ fontSize: "10px" }}>13.1</Typography>
                <Typography sx={{ fontSize: "10px" }}>
                  This Application form shall be governed, interpreted and
                  continued in accordance with laws of India. The courts at Navi
                  Mumbai shall have exclusive jurisdiction over all the matters
                  arising out of or relating to this Application Form.
                </Typography>
              </Box>
              <Box sx={{ display: "flex", gap: "10px" }}>
                <Typography sx={{ fontSize: "10px" }}>13.2</Typography>
                <Typography sx={{ fontSize: "10px" }}>
                  All disputes and differences concerning or arising out of,
                  touching, concerning or in respect of this application form
                  including the construction, interpretation, validity,
                  performance, obligation or the breach and/or validity of the
                  termination thereof, then in such case, the aggrieved party
                  shall notify the other party in writing thereof, and the
                  Parties shall endeavor to resolve the same by mutual
                  discussions and agreement. In case of failure to sette the
                  dispute amicably, the dispute shall be referred to the
                  Regulatory Authority as per the provisions of the RERA. This
                  transaction contemplated herein in respect of the Premises
                  shall be governed and interpreted by and construed in
                  accordance with the laws in India.
                </Typography>
              </Box>
            </Stack>
          </Box>
        </Stack>
        <Stack sx={{ ml: "10px", mt: "10px" }}>
          <Box sx={{ display: "flex", gap: "10px" }}>
            <Box>
              <Typography sx={{ fontSize: "10px" }}>14.</Typography>
            </Box>
            <Stack spacing={0.5}>
              <Typography sx={{ fontSize: "10px" }}>
                DECLARATION OF FREE WILL
              </Typography>
              <Box sx={{ display: "flex", gap: "10px" }}>
                <Typography sx={{ fontSize: "10px" }}>14.1</Typography>
                <Typography sx={{ fontSize: "10px" }}>
                  The Applicant(s) hereby expressly admits, acknowledges and
                  confirms that no terms, conditions, particulars or
                  information, whether oral, written or otherwise given or made
                  or represented, including those contained/given in any
                  advertisement or brochure, by the Developer and/or its agents
                  to the Applicant(s) and/or his Agents, shall deemed to form
                  part of this Application Form or to have induces the
                  Applicant(s) to make this Application. The relationship
                  between the Applicant(s) and the Developer in respect of the
                  Premises shall be governed only by the terms and conditions of
                  this Application. The applicant is aware that the marketing
                  collaterals provided by the Developer to the Applicant in
                  respect of the Project contained materials/pictorial
                  depictions in the nature of artists’ impressions and the same
                  would differ on actual basis. The Applicant undertakes not to
                  raise any objection with respect to any difference in the
                  Project from such marketing collaterals.
                </Typography>
              </Box>
            </Stack>
          </Box>
        </Stack>
        <Stack sx={{ ml: "10px", mt: "10px" }}>
          <Box sx={{ display: "flex", gap: "10px" }}>
            <Box>
              <Typography sx={{ fontSize: "10px" }}>15.</Typography>
            </Box>
            <Stack spacing={0.5}>
              <Typography sx={{ fontSize: "10px" }}>COMMUNICATION</Typography>
              <Box sx={{ display: "flex", gap: "10px" }}>
                <Typography sx={{ fontSize: "10px" }}>15.1</Typography>
                <Typography sx={{ fontSize: "10px" }}>
                  The Applicant(s) shall be bound to notify promptly in writing
                  to the Developer any change in the preferred address for all
                  correspondence and/or the Applicant(s) email ID and other
                  relevant contact details and in absence of any such
                  notification by the Applicant(s), all communication (including
                  the Demand Letter for the payments) shall be deemed to have
                  been duly served, if sent to the Applicant(s), to the Address/
                  email ID mentioned in this Application Form.
                </Typography>
              </Box>
              <Box sx={{ display: "flex", gap: "10px" }}>
                <Typography sx={{ fontSize: "10px" }}>15.2</Typography>
                <Typography sx={{ fontSize: "10px" }}>
                  In case of joint Applicants, communication sent to the first
                  Applicant shall be deemed to have been sent to all
                  Applicant(s).
                </Typography>
              </Box>
              <Box sx={{ display: "flex", gap: "10px" }}>
                <Typography sx={{ fontSize: "10px" }}>15.3</Typography>
                <Typography sx={{ fontSize: "10px" }}>
                  The Applicant(s) grants consent to the Developer to
                  communicate its marketing communication of the Developer
                  and/or its Group Companies by letters/emails/voice, SMS or
                  otherwise, to the contact details of the Applicant(s) included
                  in this Application form. The Applicant(s) further consents
                  and is made aware that the telephonic conversations of the
                  Applicant(s) may be recorded by the Developer for quality and
                  internal assessment purposes.
                </Typography>
              </Box>
            </Stack>
          </Box>
        </Stack>
        <Stack sx={{ ml: "10px", mt: "10px" }}>
          <Box sx={{ display: "flex", gap: "10px" }}>
            <Box>
              <Typography sx={{ fontSize: "10px" }}>16.</Typography>
            </Box>
            <Stack spacing={0.5}>
              <Typography sx={{ fontSize: "10px" }}>
                GENERAL PROVISIONS
              </Typography>
              <Box sx={{ display: "flex", gap: "10px" }}>
                <Typography sx={{ fontSize: "10px" }}>16.1</Typography>
                <Typography sx={{ fontSize: "10px" }}>
                  The Applicant agrees that the Developer shall have right to
                  raise finance/loan from any financial instituion/bank against
                  the security of the Developers rights and entitlement in the
                  Project Property and/or by way of mortgage/charge of premises
                  in the Project and/or the receivables therefrom.
                </Typography>
              </Box>
              <Box sx={{ display: "flex", gap: "10px" }}>
                <Typography sx={{ fontSize: "10px" }}>16.2</Typography>
                <Typography sx={{ fontSize: "10px" }}>
                  The invalidity of any of the terms, conditions or stipulations
                  of this Application shall not affect the validity of the
                  remaining terms, conditions or stipulations of this
                  Application or the validity of the Application itself.
                </Typography>
              </Box>
              <Box sx={{ display: "flex", gap: "10px" }}>
                <Typography sx={{ fontSize: "10px" }}>16.3</Typography>
                <Typography sx={{ fontSize: "10px" }}>
                  No failure to exercise or delay in exercising or enforcing any
                  rights or remedies under this Application shall constitue a
                  waiver thereof and no single or partial exercise or
                  enforcement of any rights or remedies under this Application
                  shall preclude or restrict the further exercise or enforcement
                  of any such rights or remedies.
                </Typography>
              </Box>
              <Box sx={{ display: "flex", gap: "10px" }}>
                <Typography sx={{ fontSize: "10px" }}>16.4</Typography>
                <Typography sx={{ fontSize: "10px" }}>
                  The Applicant agrees and confirms not to seek any amendment,
                  modification and/or change in the terms and conditions of this
                  Application Form.
                </Typography>
              </Box>
              <Box sx={{ display: "flex", gap: "10px" }}>
                <Typography sx={{ fontSize: "10px" }}>16.5</Typography>
                <Typography sx={{ fontSize: "10px" }}>
                  The Applicant(s) shall treat all information pertaining to the
                  Project, including without limitation, the terms of this
                  Application and its Annexures and all writings and
                  communications, plans, drawings, approvals relating to the
                  Project as Confidential and shall not disclose the same to any
                  third party (ies) and shall neither use, or reproduce for use
                  in any manner whatsoever the same, save and except to any
                  family member and/or lender for the Premises. This clause
                  shall survive the termination of this Application/Confirmation
                  Letter.
                </Typography>
              </Box>
              <Box sx={{ display: "flex", gap: "10px" }}>
                <Typography sx={{ fontSize: "10px" }}>16.6</Typography>
                <Typography sx={{ fontSize: "10px" }}>
                  The Developer shall either by itself or through its
                  Nominees/Associates, and on such terms and conditions as may
                  be mutually agreed between the Applicant(s) and the Developer,
                  provide the Premises with fit-outs in addition to the existing
                  specifications, amenities and facilities, if any. The
                  Applicant(s) agrees to enter and execute a separate
                  Agreement/Contract, if required, either with the Developer, or
                  its Nominees/Associates for carrying out such fit-out works in
                  the Premises.
                </Typography>
              </Box>
            </Stack>
          </Box>
        </Stack>
      </Box>

      <Box
        sx={{
          display: "flex",
          gap: "40px",
          justifyContent: "center",
          position: "absolute",
          bottom: "30px",
        }}
      >
        <Box className={classes.input}>
          <Box>
            <input
              style={{ textAlign: "center" }}
              value={pageData16.sign_first}
              type="text"
              name="sign_first"
              onChange={handleChange}
            />
            <Typography sx={{ textAlign: "center", mt: "10px" }}>
              Signature of First Applicant
            </Typography>
          </Box>
        </Box>
        <Box className={classes.input}>
          <Box sx={{ display: "inline-block" }}>
            <input
              style={{ margin: "0px", textAlign: "center" }}
              value={pageData16.sign_second}
              type="text"
              name="sign_second"
              onChange={handleChange}
            />
            <Typography sx={{ textAlign: "center", mt: "10px" }}>
              Signature of Second Applicant
            </Typography>
          </Box>
        </Box>
        <Box className={classes.input}>
          <Box sx={{ display: "inline-block" }}>
            <input
              style={{ margin: "0px", textAlign: "center" }}
              value={pageData16.sign_third}
              type="text"
              name="sign_third"
              onChange={handleChange}
            />
            <Typography sx={{ textAlign: "center", mt: "10px" }}>
              Signature of Third Applicant
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default PdfOnePageSixteen;
