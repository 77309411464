import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  MenuItem,
  Drawer,
  Box,
  Button,
  Checkbox,
  FormControl,
  InputLabel,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { Stack } from "@mui/system";
import TuneOutlinedIcon from "@mui/icons-material/TuneOutlined";
import KeyboardArrowRightOutlinedIcon from "@mui/icons-material/KeyboardArrowRightOutlined";
import KeyboardArrowUpOutlinedIcon from "@mui/icons-material/KeyboardArrowUpOutlined";
import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined";
import { CheckBox } from "@mui/icons-material";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import DriveFileRenameOutlineIcon from "@mui/icons-material/DriveFileRenameOutline";
import VisibilityIcon from "@mui/icons-material/Visibility";
import DeleteIcon from "@mui/icons-material/Delete";
//   import "../MembersDirectory/MDmainPage.css";
import React, { useContext, useEffect, useState } from "react";
import { DataGrid, GridExpandMoreIcon } from "@mui/x-data-grid";
import CategoryIcon from "@mui/icons-material/Category";
import HandshakeIcon from "@mui/icons-material/Handshake";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";

import axios from "axios";
import UDcustomTable from "./UDcustomTable";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import CircularProgress from '@mui/material/CircularProgress';
import { MarkOccupancy } from "./MarkOccupancy";
import builderDashboard from "../../../../context/builder_dashboard/context";
import { api, Apiaddress, contains, net } from "../../../../utils/consts";
import { AuthService } from "../../../../context/buyer/services/auth";
import { useNavigate } from "react-router-dom";


const columns = [
  { field: "id", headerName: "id", width: 130 },
  { field: "Unit No", headerName: "Unit No", width: 130 },
  { field: "Floor No", headerName: "Floor No", width: 130 },
  { field: "Bedrooms", headerName: "Bedrooms", width: 130 },
  {
    field: "Bathrooms",
    headerName: "Bathrooms",
    // type: "number",
    width: 130,
  },
  {
    field: "Pricing",
    headerName: "Pricing",
    width: 130,
  },

  {
    field: "View",
    headerName: "View",
    // headerAlign: "center",
    width: 130,
    renderCell: (params) => {
      return (
        <Stack direction="row">
          <RemoveRedEyeIcon fontSize="small" />
          <DeleteIcon fontSize="small" />
        </Stack>
      );
    },
  },
];



export const CPUnit_Directory = ({ projectid }) => {

   let builderSelected = JSON.parse(localStorage.getItem("BuilderSelected"));
   console.log(builderSelected);

  const [loading, setloading] = useState(false)

  const [newRowData, setNewRowData] = useState([]);
  const OrganizationsContext = useContext(builderDashboard);



  const [state, setState] = React.useState(false);

  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState(open);
  };

  const [selectedRows, setSelectedRows] = React.useState([]);
  useEffect(() => {
    console.log(selectedRows);
  }, [selectedRows]);

  const [curselectedRows, setcurselectedRows] = useState([]);

  const [selectedUnits, setSelectedUnits] = useState([]);




  const [tabledata, setTabledata] = useState(null);
  const [rowData, setrowData] = useState([]);
  const [idCont, setidCunt] = useState(1);

  //api

  //api

  useEffect(() => {

    if (tabledata) {
      let obj = {
        id: idCont,
        Name: tabledata.name,
        Phone: tabledata.phone,
        Email: tabledata.Email,
        Team: tabledata.Team,
        Role: tabledata.Role,
      };
      setidCunt(idCont + 1);
      console.log(obj);
      setrowData([...rowData, obj]);
    }

    console.log(tabledata);
  }, [tabledata]);


  const [data, setData] = useState(null);
  const [token, setToken] = useState(null);

  useEffect(() => {

    const x = async () => {
      const Auth = new AuthService()
      const res = await (await Auth.makeUnAuthenticatedGetRequest(api("/api/projects/project/?project_id=" + projectid))).json()
      setData(res)
      console.log("", res)
    }
    x()
  }, [])


  let builderSelectedData = JSON.parse(localStorage.getItem("BuilderSelected"));

  let id = builderSelectedData?.selectedProjectID;


  const fetchUnit = async () => {
    setloading(true)
    try {
      const auth = new AuthService()
      if (projectid == undefined) {

        const res = await axios.get(
          api("/api/role/channelpartner/?cp_id=" + auth.getCPID())
        );

        // if (res) if (res.data) if (res.data.units) arr.push(res.data.units);
        // }
        console.log("===========================.>", res?.data?.cp[0]?.units);

        if (res?.data?.cp[0]?.units == " Unit not found") { setNewRowData([]) }
        else {

          let rd = []
          // for (let i = 0; i < res?.data?.cp[0]?.units?.length; i++) {
          //   const r = await (await auth.makeUnAuthenticatedGetRequest(api(`/api/projects/unit/?unit_id=${res?.data?.cp[0]?.units[i]}`))).json()
          //   rd.push(r.unit[0])
          // }
          const Auth = new AuthService()
          const res3 = await (await (await Auth.makeUnAuthenticatedGetRequest(net("projects/cpunit/?cp_id=" + Auth.getCPID()))).json())
          const  unitsre= res3.units
          for (let i = 0 ; i < unitsre.length;i++ ){
            rd.push(unitsre[i])
          }
          console.log(rd);
          setNewRowData(rd);
        }
      }
      else {
        const res = await axios.get(
          api("/api/role/channelpartner/?cp_id=" + auth.getCPID())
        );

        const res2 = await axios.get(
          api("/api/projects/unitsofproject/?project_id=" + projectid)
        );
        const cpUnits = res?.data?.cp[0]?.units;
        const projectUnits = res2.data.units;
        console.log(cpUnits,"=======",projectUnits);
        let rd = []
        for (let i = 0; i < cpUnits.length; i++) {
          for (let j = 0; j < projectUnits.length; j++) {
            // console.log(cpUnits[i], cpUnits[i] == projectUnits[j].id, projectUnits[j].id)
            if (cpUnits[i] == projectUnits[j].id) {
              const r = await (await auth.makeUnAuthenticatedGetRequest(api(`/api/projects/unit/?unit_id=${cpUnits[i]}`))).json()
              rd.push(r.unit[0])
            }
          }

        }
        const Auth = new AuthService()
        const res3 = await (await (await Auth.makeUnAuthenticatedGetRequest(net("projects/cpunit/?cp_id=" + Auth.getCPID()))).json())
        console.log("res3",res3)

        setNewRowData(rd);
      }
    } catch (err) {
      console.log("====================", err);
      setNewRowData([]);
    } finally {
      setloading(false)
    }
  };

  useEffect(() => {

    fetchUnit();

  }, []);

  const [newUnits, setNewUnits] = useState([]);
  useEffect(() => {
    if (newRowData) {
      let arr = [];
      for (let i = 0; i < newRowData?.length; i++) {

        let totalcost = 0;

        for (let j = 0; j < newRowData[i].charges_data?.length; j++) {

          totalcost = totalcost + (newRowData[i].charges_data[j].sectioncost);

        }

        let obj = {
          id: newRowData[i]?.id,
          floor_no: newRowData[i].floor?.id,
          bedroom: newRowData[i].layout_data?.bedroomData?.length ? newRowData[i].layout_data?.bedroomData?.length : "0",
          bathroom: newRowData[i].layout_data?.bathroomData?.length ? newRowData[i].layout_data?.bathroomData?.length : "0",
          balcony: newRowData[i].layout_data?.balconyData?.length ? newRowData[i].layout_data?.balconyData?.length : "0",
          totalarea: newRowData[i].layout_data?.singleData ?  newRowData[i].layout_data?.singleData[0]?.value : [],
          single_data: newRowData[i].layout_data?.singleData ?? [],
          double_data: newRowData[i].layout_data?.doubleData ?? [],
          pricing: totalcost
        }
        arr.push(obj);
      }

      setNewUnits(arr);
    }

  }, [newRowData]);



  //fitering data starts:====
  const [filterselect, setfilterselect] = useState();
  const [filtervalue, setfiltervalue] = useState();

  const handleChange = (event) => {
    setfilterselect(event.target.value);
    setfiltervalue("");
  };


  const fetchfilter = async () => {
    try {
      const res = await axios.get(
        Apiaddress + `/api/projects/getunitsusingprojectid/?project_id=${id}&filterkey=${filterselect}&filtervalue=${filtervalue}`
      );

      console.log(res.data.unit);
      setNewRowData(res.data.unit);
    } catch (err) {
      console.log(err);
    }
  };
  const nav = useNavigate()


  return (
    <>
      <Stack direction="row" sx={{ width: "100%", height: "auto",marginTop:"-25px"}}>
        {/* 1st stack */}


        {/* 2nd stack */}
        <Stack direction="column" sx={{ backgroundColor: "#f9f9f9",width: "100%",boxSizing: "border-box"}}  >
          {/* <Navbar/> */}

          {/* actual content  */}

          <Drawer anchor="right" open={state} onClose={() => {
            // toggleDrawer(false)
            setState(false)
            //   setMakePriority(false);
            //   setMakeProject(false);
          }}>
            {/* <RightSidebar
              setTabledata={setTabledata}
              toggleDrawer={toggleDrawer}
              curselectedRows={
                curselectedRows.length == 0 ? null : curselectedRows
              }
              orgID={orgID}
              updateData={updateData}
              setTrigger={setTrigger}
              trigger={trigger}
              makePriority={makePriority}
              makeProject={makeProject}
              setMakePriority={setMakePriority}
              setMakeProject={setMakeProject}
            /> */}

            <MarkOccupancy setState={setState} selectedUnits={selectedUnits} setSelectedUnits={setSelectedUnits} />
          </Drawer>

          <Stack sx={{ mt: "0px", backgroundColor: "#f9f9f9"}}>

            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              sx={{marginBottom: "0px",marginLeft:"40px"}}
            >
              <Typography
                sx={{
                 fontSize: "22px",
                    fontWeight: "600",
                    marginLeft: "-15px",
                    color: "black",
                }}
              >
                {
                  data == null ?
                    "Individual Unit List" :
                    data?.projects[0]?.name
                }
              </Typography>
              
              {builderSelected?.selectedProjectType && 
              <Button
                onClick={() => {
                  // toggleDrawer(true);
                  // setState(true)
                  // setMakePriority(true);
                  nav("/channelpartner/unit/add")
                }}
                className="univercel_btn_class"
                sx={{
                  color: "#FFFFFF",
                  textTransform: "none",
                  width: "120px",
                  padding:"8px 0px",
                  background:"linear-gradient(90deg, #0F5DAE 0%, #009DCF 100.33%)",
                  boxShadow:"0px 13.7981px 22.5788px rgba(50, 174, 137, 0.25)"
                }}
              >
                Add unit
              </Button>}

            </Stack>

            {/* ================unit availability btn =============================== */}

            <Box>
              {selectedUnits.length > 0 &&
                <Stack direction="row" sx={{ margin: "20px"}}>
                  <Button
                    onClick={() => {
                      // toggleDrawer(true);
                      setState(true)
                      // setMakePriority(true);
                    }}
                    startIcon={<CategoryIcon />}
                    className="univercel_btn_class"
                    sx={{
                      color: "#FFFFFF",
                      textTransform: "none",
                      width: "170px",
                      background:
                        " linear-gradient(150deg, #79F1A4 13.4%, #0E5CAD 86.6%)",
                    }}
                  >
                    Mark Occupancy
                  </Button>

                </Stack>
              }
              <Stack></Stack>
            </Box>

            <UDcustomTable
              selectedUnits={selectedUnits}
              setSelectedUnits={setSelectedUnits}
              newRowData={newUnits}
              loading={loading}
              CircularProgress={CircularProgress}
            />

          </Stack>



        </Stack>
      </Stack>
    </>
  )
}