import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  MenuItem,
  Drawer,
  Box,
  Button,
  Checkbox,
  FormControl,
  InputLabel,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { Stack } from "@mui/system";
import TuneOutlinedIcon from "@mui/icons-material/TuneOutlined";
import KeyboardArrowRightOutlinedIcon from "@mui/icons-material/KeyboardArrowRightOutlined";
import KeyboardArrowUpOutlinedIcon from "@mui/icons-material/KeyboardArrowUpOutlined";
import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined";
import { CheckBox } from "@mui/icons-material";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import DriveFileRenameOutlineIcon from "@mui/icons-material/DriveFileRenameOutline";
import VisibilityIcon from "@mui/icons-material/Visibility";
import DeleteIcon from "@mui/icons-material/Delete";
import "../MembersDirectory/MDmainPage.css";
import { Sidebar } from "../ProjectDashboard/DashboardComponents/Sidebar";
import { Navbar } from "../ProjectDashboard/DashboardComponents/Navbar";
import React, { useContext, useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import CategoryIcon from "@mui/icons-material/Category";
import HandshakeIcon from "@mui/icons-material/Handshake";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import RightSidebarCPD from "./RightSidebarCPD";
import profileContext from "../../../context/profile/context";
import { NavbarForDirectory } from "../ProjectDashboard/DashboardComponents/NavbarForDirectory";
import axios from "axios";
import RightSidebarCPDadd from "./RightSidebarCPDadd";
import { basicheaders } from "../../../utils/consts";
import { useNavigate } from "react-router-dom";
import CPDcustomTable from "./CPDcustomTable";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Apiaddress } from "../../../utils/consts";
import { MainSidebar } from "../ProjectDashboard/DashboardComponents/MainSidebar";

function CPDmainPage() {
  const navigate = useNavigate();
  const columns = [
    { field: "id", headerName: "id", width: 130 },
    { field: "RERARegNo", headerName: "RERA Reg No", width: 130 },
    { field: "Type", headerName: "Type", width: 130 },
    { field: "Name", headerName: "Name", width: 130 },
    {
      field: "Email",
      headerName: "Email",
      // type: "number",
      width: 130,
    },
    {
      field: "Phone",
      headerName: "Phone",
      width: 130,
    },
    {
      field: "Action",
      headerName: "Action",
      headerAlign: "center",
      width: 130,
      renderCell: (params) => {
        return (
          <Stack direction="row" sx={{ marginLeft: "10px" }}>
            <RemoveRedEyeIcon
              fontSize="medium"
              onClick={() => navTocpo(params)}
            />
            <DeleteIcon
              fontSize="medium"
              sx={{ marginLeft: "10px" }}
              onClick={() => delIconClick(params)}
            />
          </Stack>
        );
      },
    },
  ];

  const navTocpo = (params) => {
    return; /// for temporary 
    navigate(`/channelpartneroverview/${params?.row?.CPid}`);
  };

  const rows = [
    {
      id: 1,
      RERARegNo: "#4560",
      Type: "26 March 2022, 12:42 AM",
      Name: "Seema Agarwal",
      Email: "67 St. pune",
      Phone: "Agent",
    },
  ];

  const [state, setState] = React.useState(false);

  const toggleDrawer = (open, item) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    updateBuyers(item);

    setState(open);
  };

  const [stateadd, setStateadd] = React.useState(false);

  const toggleDrawerAdd = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setStateadd(open);
  };

  const [selectedRows, setSelectedRows] = React.useState([]);
  useEffect(() => {
    console.log(selectedRows);
  }, [selectedRows]);

  const [curselectedRows, setcurselectedRows] = useState(null);
  const onRowsSelectionHandler = (ids) => {
    setRowSelected(true);
    const selectedRowsData = ids?.map((id) => rows.find((row) => row.id === id));
    console.log(selectedRowsData);
    setcurselectedRows(selectedRowsData);
  };

  const [tabledata, setTabledata] = useState(null);
  const [rowData, setrowData] = useState([]);
  const [idCont, setidCunt] = useState(1);

  //api

  //api

  useEffect(() => {
    const rows = [
      {
        id: 8,
        Name: "Frances",
        Phone: "912912912",
        Email: "dummmy@gamil.com",
        Team: "Agent",
        Role: "Agent",
        DetailedView: "View",
      },
    ];
    if (tabledata) {
      let obj = {
        id: idCont,
        Name: tabledata.name,
        Phone: tabledata.phone,
        Email: tabledata.Email,
        Team: tabledata.Team,
        Role: tabledata.Role,
      };
      setidCunt(idCont + 1);
      console.log(obj);
      setrowData([...rowData, obj]);
    }

    console.log(tabledata);
  }, [tabledata]);

  const [rowSelected, setRowSelected] = useState(false);

  const [orgID, setOrgID] = useState(null);
  const user = useContext(profileContext);

  const [builderId, setbuilderId] = useState(null);

  useEffect(() => {
    // console.log(user);
    // console.log(user.UserState);
    if (user)
      if (user.UserState)
        if (user.UserState.user_obj)
          if (user.UserState.user_obj.orgList)
            if (user.UserState.user_obj.orgList[0]) {
              // console.log(user.UserState.user_obj.orgList[0]);
              setOrgID(user.UserState.user_obj.orgList[0]);
            }
  }, [user]);

  const [data, setData] = useState(null);
  const [trigger, setTrigger] = useState(false);
  useEffect(() => {
    // console.log(user);
    if (user) {
      if (user.UserState) {
        if (user.UserState.user_obj) {
          setbuilderId(user.UserState.user_obj.id);
        }
      }
    }
  }, [user, trigger]);

  const getCP = async () => {
    // console.log(builderId);
    try {
      const res = await axios.get(
        Apiaddress + `/api/role/getcpusingbuilderid/?builder_id=${builderId}`
      );
      if(res.data.channel_partner=="channel_partner not found"){
        setData([]);
      }
      else{
        console.log(res.data?.channel_partner);
        setData(res.data?.channel_partner);
      }
      
    } catch (err) {
      console.log(err);
      setData([]);
    }
  };

  const [datafromapi, setdatafromapi] = useState([]);

  useEffect(() => {
    if (data) {
      console.log(data);

      let arr = [];
      for (let i = 0; i < data.length; i++) {
        const obj = {
          id: i + 1,
          CPid: data[i]?.id,
          RERARegNo: data[i]?.rera_no,
          Type: data[i]?.cp_type,
          Name: data[i]?.profile?.user_details.first_name,
          Email: data[i]?.profile?.user_details.email,
          Phone: data[i]?.profile?.phone,
          profile_id: data[i]?.profile ? data[i]?.profile.id : 1,
        };
        // console.log(obj);
        arr.push(obj);
      }
      setdatafromapi(arr);
    }
  }, [data]);

  console.log(datafromapi);

  useEffect(() => {
    console.log(builderId);
    if (builderId) {
      getCP();
    }
  }, [builderId, trigger]);
  
  const delIconClick = async (params) => {
    console.log(params.CPid);
    // return;
    try {
      const obj = {
        data: { cp_id: params.CPid },
        headers: basicheaders,
      };
      const res = await axios.delete(
        Apiaddress + "/api/role/getcpusingbuilderid/",
        obj
      );
      setTrigger(!trigger);
      console.log(res);
    } catch (err) {
      console.log(err);
    }
  };

  const [updateInfo, setupdateInfo] = useState(null);
  const updateBuyers = (params) => {
    setupdateInfo(params);
  };


  //fitering data starts:====
  const [filterselect, setfilterselect] = useState();
  const [filtervalue, setfiltervalue] = useState();

  const handleChange = (event) => {
    setfilterselect(event.target.value);
    setfiltervalue("");
  };

  console.log(filterselect);
  console.log(filtervalue);
  console.log(data);

  const fetchfilter = async () => {
    try {
      const res = await axios.get(
        Apiaddress + `/api/role/getcpusingbuilderid/?builder_id=${builderId}&filterkey=${filterselect}&filtervalue=${filtervalue}`
      );
      if(res.data?.channel_partner=="channel_partner not found"){
        setData([]);
      }
      else{
        setData(res.data?.channel_partner);
      }
      console.log(res.data?.channel_partner);
    } catch (err) {
      console.log(err);
      setData([]);
    }
  };


  return (
    <>

    
<Stack direction="row" sx={{width:"100%",height:"auto" }}>
      {/* 1st stack */}
      <Stack sx={{minHeight:"100vh",width:"20%",boxSizing:"border-box",}}>
        <MainSidebar  active={"Channel Partner Directory"}/>
      </Stack>
     
     {/* 2nd stack */}
     <Stack direction="column" sx={{ backgroundColor:"#f9f9f9",width:"80%", padding:"25px",boxSizing:"border-box",}}  >  {/*  width for main sidebar 78px and for 80px*/}
      {/* <Navbar/> */}
      <NavbarForDirectory
          showAddProjectbtn={false}
          showRightSideBar={false}
          // showRightSideBarleft={true}
          RightBarNameleft={"Invite Channel Partner"}
          toggleDrawer={toggleDrawer}
          toggleDrawerAdd={toggleDrawerAdd}
          RightBarName={"Add Channel Partner"}
          leftTitle={"Sales Dashboard"}
          leftRightTitle1={"Channel partner Dashboard"}
          leftRightTitle2={"Channel partner Directory"}
          longOrangBgr={true}
          showLeftTitle={false}
          righshow={true}
          cpadd={true}
          cpaddtitle={"Add channerl partner"}
        />
      

     {/* actual content  */}
     
    <Stack sx={{ mt:"28px",backgroundColor:"#f9f9f9"}}>

    <React.Fragment>
          <Drawer
            anchor="right"
            open={stateadd}
            onClose={toggleDrawerAdd(false)}
          >
            <RightSidebarCPD
              setTabledata={setTabledata}
              curselectedRows={curselectedRows}
              setStateadd={setStateadd}
              setTrigger={setTrigger}
              trigger={trigger}
            />
          </Drawer>
        </React.Fragment>

        <React.Fragment>
          <Drawer anchor="right" open={state} onClose={toggleDrawer(false)}>
            <RightSidebarCPDadd
              updateInfo={updateInfo}
              setTabledata={setTabledata}
              setState={setState}
              curselectedRows={curselectedRows}
              setTrigger={setTrigger}
              trigger={trigger}
            />
          </Drawer>
        </React.Fragment>


        <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            sx={{ height: "68px", marginBottom: "20px" }}
          >
            <Stack direction="column" alignItems="center">
              <Typography
                sx={{
                  fontSize: "22px",
                  fontWeight: "600",
                  marginLeft: "-45px",
                  color: "black",
                }}
              >
                All Channel partner
              </Typography>
              <Typography
                sx={{
                  color: "#B9B7D0",
                  fontSize: "12px",
                  fontWeight: "400",
                }}
              >
                Here is your Channel Partner Directory list data
              </Typography>
            </Stack>
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Stack
                direction="row"
                justifyContent="space-around"
                alignItems="center"
                sx={{
                  width: "203px",
                  height: "68px",
                  borderRadius: "12px",
                  color: "#3E4954",
                  backgroundColor: "#f4f5f9",
                }}
              >

                
                <Accordion sx={{ width: "250px", boxShadow: "none" }}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography sx={{ display: "flex", placeItems: "center" }}>
                      <TuneOutlinedIcon sx={{ color: "#2F4CDD" }} />
                      <Typography sx={{ marginLeft: "25px" }}>
                        Fillters
                      </Typography>
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Box sx={{textAlign:"center"}}>
                      <FormControl sx={{ width: "200px" }}>
                        <InputLabel id="demo-simple-select-label">
                          Select
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={filterselect}
                          label="Select"
                          onChange={handleChange}
                        >
                          <MenuItem value={"rera_no"}>Rera No.</MenuItem>
                          <MenuItem value={"cp_type"}>Type</MenuItem>
                        </Select>
                      </FormControl>
                      <TextField
                        sx={{ marginTop: "20px" }}
                        placeholder="Enter the value" 
                        value={filtervalue} onChange={(e)=>{setfiltervalue(e.target.value)}}
                      />
                      <Button 
                        onClick={fetchfilter}
                        variant="contained"
                        color="primary" sx={{ height:"40px", background: "linear-gradient(90deg, #0F5DAE 0%, #009DCF 100.33%)",boxShadow: "0px 13.7981px 22.5788px rgba(50, 174, 137, 0.25)", textTransform: "none", borderRadius: "4px", width:"100px", fontSize:"15px",margin:"20px 0px 10px 0px"}}>
                         Submit
                      </Button>

                    </Box>
                  </AccordionDetails>
                </Accordion>


              </Stack>
            </Stack>
          </Stack>

          <CPDcustomTable
            newRowData={datafromapi}
            delIconClick={delIconClick}
            updateBuyers={updateBuyers}
            setTrigger={setTrigger}
            trigger={trigger}
            toggleDrawer={toggleDrawer}
          />


    </Stack>




      </Stack>
      
</Stack>


    

    </>
  );
}

export default CPDmainPage;
