import React, { useContext, useEffect } from "react";
import { TextField } from "@mui/material";
import { useState } from "react";

import { Box, Button, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Divider from "@mui/material/Divider";

import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

import { listUnitTemplateOfProject } from "../../../connection/project/unit_templates_of_project";

import { MainSidebar } from "../ProjectDashboard/DashboardComponents/MainSidebar";
import { Navbar } from "../ProjectDashboard/DashboardComponents/Navbar";
import { Apiaddress } from "../../../utils/consts";
import { ConfirmProject_steps } from "../ProjectDashboard/CreateProject/ConfirmProject_steps";
import { BackeToOverviewBtn } from "../ProjectDashboard/CreateProject/BackeToOverviewBtn";
import currentProjectState from "../../../context/createproject/context";
import { createFloorTemplate } from "../../../connection/project/create_floor_template";

function EditProjectFloor_Create() {
  ////get data from previous page
  const location = useLocation();
  const data = location.state;

  console.log("data===============>", data);

  const navigate = useNavigate();

  const [unitnumber, setunitnumber] = useState();

  const [unitdata, setunitdata] = useState([]);

  const [unittemp, setunittemp] = useState([]);

  const [name, setname] = useState("");
  // console.log(unitdata);
  const cpContext = useContext(currentProjectState);
  //get project id from local storage;

const projectfromlocal = JSON.parse(localStorage.getItem("BuilderSelected"))
const projectID = projectfromlocal.selectedProjectID

  ///// get unit list
  useEffect(() => {
    const fetch_all_unit_templates = async () => {
      const data = await listUnitTemplateOfProject(projectID);
      if (data.unit_template !== "Unit template not found")
        setunittemp(data.unit_template);
    };
    fetch_all_unit_templates();
  }, []);

  useEffect(() => {
    let data = [];
    for (let i = 1; i <= unitnumber; i++) {
      let to_insert = {
        unit_no: i,
        unit_name: "",
        unit_id: "",
      };
      data.push(to_insert);
    }
    setunitdata(data);
  }, [unitnumber]);

  return (
    <>
      <Stack direction="row" sx={{ width: "100%", height: "auto" }}>
        {/* 1st stack */}
        <Stack
          sx={{ minHeight: "100vh", width: "20%", boxSizing: "border-box" }}
        >
          {" "}
          {/*for project directory 20% and for sales dashboard 225*/}
          <MainSidebar active={"Project Directory"} />
        </Stack>

        {/* 2nd stack */}
        <Stack
          direction="column"
          sx={{
            backgroundColor: "#f9f9f9",
            width: "80%",
            padding: "25px",
            boxSizing: "border-box",
          }}
        >
          {" "}
          {/*  width for main sidebar 78px and for 80px*/}
          <Navbar editProject={true} />
          <Typography
            sx={{
              marginTop: "20px",
              fontSize: "28px",
              fontWeight: "600",
              marginLeft: "10px",
              marginBottom: "50px",
            }}
          >
            Floor Template
          </Typography>
          {/* actual content  */}
          <Stack sx={{ backgroundColor: "#f9f9f9" }}>
            {" "}
            {/* mt:"28px", */}
            <Stack
              direction="column"
              sx={{
                padding: "20px",
                backgroundColor: "#fff",
                marginRight: "0px",
              }}
            >
              {/* floor Template */}
              <Box sx={{}}>
                <Box sx={{ paddingBottom: "40px" }}>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      margin: "30px 0px 0px 0px",
                    }}
                  >
                    <Typography component="span" className="field-name">
                      Floor Template Name
                    </Typography>
                    <TextField
                      onChange={(e) => setname(e.target.value)}
                      sx={{
                        width: "188px",
                        marginLeft: "40px",
                      }}
                    />
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      margin: "30px 0px 0px 0px",
                    }}
                  >
                    <Typography component="span" className="field-name">
                      No of Units in Floors{" "}
                    </Typography>
                    <TextField
                      inputProps={{ min: 0 }}
                      sx={{
                        width: "188px",
                        marginLeft: "40px",
                      }}
                      type="Number"
                      value={unitnumber}
                      onChange={(e) => {
                        setunitnumber(e.target.value);
                      }}
                    />
                  </Box>
                </Box>
                {/* <Box sx={{border:"0.5px solid #000000",opacity:"0.1",width:"1050px",marginLeft:"0px"}}></Box> */}
                <Divider />
                <Box>
                  {unitdata.map((ele, index) => {
                    return (
                      <Box
                        key={index}
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          margin: "30px 0px 0px 70px",
                        }}
                      >
                        <Typography component="span" className="field-name">
                          Unit {ele.unit_no}
                        </Typography>

                        <FormControl
                          sx={{ width: "188px", marginLeft: "40px" }}
                        >
                          <Select
                            onChange={(e) => {
                              let arr = unitdata;
                              arr[index].unit_name =
                                unittemp[e.target.value].name;
                              arr[index].unit_id = unittemp[e.target.value].id;
                              setunitdata([...arr]);
                            }}
                            displayEmpty
                            inputProps={{ "aria-label": "Without label" }}
                          >
                            {unittemp.map((ele, index) => {
                              return (
                                <MenuItem key={ele.id} value={index}>
                                  {ele.name}
                                </MenuItem>
                              );
                            })}
                          </Select>
                        </FormControl>

                        {/* <Typography className='' sx={{margin:"0px 0px 0px 20px",fontFamily:'Poppins',fontStyle:"normal",fontWeight:"500",fontSize: "12px",lineHeight:"18px",color:"#A2A3A6"}}>Can't Find The Template?</Typography>
<Typography className='' sx={{margin:"0px 0px 0px 5px",fontFamily:'Poppins',fontStyle:"normal",fontWeight:"500",fontSize: "12px",lineHeight:"18px",color:"#FF6140",cursor:"pointer"}} onClick={()=>{navigate('/unittemplate')}}>Create New</Typography> */}
                      </Box>
                    );
                  })}

                  {unitnumber > 0 && (
                    <Link
                      onClick={async () => {
                        console.log(
                          name,
                          projectID,
                          [...unitdata]
                        );
                        await createFloorTemplate(
                          name,
                          projectID,
                          [...unitdata]
                        );
                      }}
                      to="/builder-edit-project-floor"
                      state={data}
                    >
                      <Button
                        //    onClick={async()=>{
                        //     console.log(name,projectID,[...unitdata])
                        //     await createFloorTemplate(name,projectID,[...unitdata])
                        //     navigate("/createproject/projectpricing/projectcreateoption/mainscreen")
                        //    }}
                        variant="contained"
                        color="primary"
                        sx={{
                          height: "50px",
                          background:
                            "linear-gradient(90deg, #0F5DAE 0%, #009DCF 100.33%)",
                          boxShadow:
                            "0px 13.7981px 22.5788px rgba(50, 174, 137, 0.25)",
                          textTransform: "none",
                          borderRadius: "4px",
                          width: "150px",
                          fontSize: "15px",
                          margin: "100px 0px 70px 70px",
                        }}
                      >
                        Save
                      </Button>
                    </Link>
                  )}
                </Box>
              </Box>
            </Stack>
          </Stack>
        </Stack>
      </Stack>
    </>
  );
}

export default EditProjectFloor_Create;
