import { Box, Button, Typography } from "@mui/material"
import { Stack } from "@mui/system"
import DoneIcon from '@mui/icons-material/Done';
import { useNavigate } from "react-router-dom";


export const CompleteBuilderSignup =()=>{
    const navigate = useNavigate()
    return (
        <>
           <Stack direction="column" justifyContent="center" alignItems="center">
              
              <Box sx={{width:"100px", height:"100px",borderRadius:"50%",margin:"20px",backgroundColor:"#FFA946",display:"flex", justifyContent:"center", alignItems:"center"}}>
                              <DoneIcon  sx={{color:"#fff",width:"60%", height:"60%"}}/>
              </Box>
                      <Typography sx={{fontWeight: "700",fontSize: "24px",lineHeight: "35px"}}>Congrats You Completed Your Profile</Typography>

                      <Box sx={{display:"flex", justifyContent:"center",alignItems:"center",width:"45%"}}>
                        <Typography align="center">Lorem ipsum dolor sit amet consectetur adipisc.Lorem ipsum dolor sit amet consectetur adipisc.Lorem ipsum dolor sit amet consectetur adipisc.</Typography>
                      </Box>

                      <Button 
                      sx={{background: "linear-gradient(90deg, #0F5DAE 0%, #009DCF 100.33%)",boxShadow: "0px 13.7981px 22.5788px rgba(50, 174, 137, 0.25)", color:"#fff", padding:"10px", margin:"10px",textTransform: "none"}}
                      onClick={()=>{
                          navigate("/dashboard")
                      }}
                      >Lets Get Started</Button>
                     
           </Stack>

           <Stack direction="row" justifyContent="right" spacing={0} sx={{height:"10vh"}}>
                {/* {(stepsno>0 && stepsno<4) && <Button
                onClick={()=>setStepsno(prev=>prev-1)}
                variant="contained" startIcon={<KeyboardDoubleArrowLeftIcon/>} 
                 color="primary" sx={{marginRight:"20px",width:"114px",height:"53px", background: "linear-gradient(90deg, #0F5DAE 0%, #009DCF 100.33%)",boxShadow: "0px 13.7981px 22.5788px rgba(50, 174, 137, 0.25)",textTransform: "none"}}>
                    Previous
                </Button>}
               {stepsno<=3 && <Button 
                onClick={()=>setStepsno(prev=>prev+1)}
                variant="contained" endIcon={<KeyboardDoubleArrowRightIcon/>} 
                 color="primary" sx={{marginRight:"20px",height:"53px", background: "linear-gradient(90deg, #0F5DAE 0%, #009DCF 100.33%)",boxShadow: "0px 13.7981px 22.5788px rgba(50, 174, 137, 0.25)", textTransform: "none"}}>
                    Next 
                </Button>} */}
            </Stack>
        </>
    )
}