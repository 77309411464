import { ReactElement, useEffect, useState } from "react";
import { getProfile } from "../../connection/user/get_profile";
import BuyerContext from "./context";
import { BuyerService } from "./services/buyerServices";

export default function BuyerContextState(props:any)  {
    const BS = new  BuyerService();
  
    return (
    <BuyerContext.Provider value={BS}>
        {props.children}
    </BuyerContext.Provider>
  );
}

