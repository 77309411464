import { Box, Checkbox } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Apiaddress } from "../../../../utils/consts";

function AssociatedUnits({ cpid }) {
  const [data, setData] = useState([]);

  const getUnitcp = async () => {
    console.log("cpid",cpid)
    try {
      const res = await axios.get(
        Apiaddress + `/api/role/channelpartnerunits/?cp_id=${cpid}`
      );
      console.log("channel partnnet unite assigned====>",res.data);
      if(res.data.units=="units not found")setData([])
      else setData(res.data.units);
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    getUnitcp();
  }, []);

  return (
    <Box sx={{ color: "#A2A3A6" }}>
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: "1fr 1fr 1fr 1fr",
          rowGap: "40px",
          placeItems: "center",
          columnGap: "20px",
        }}
      >
        {data &&
          data?.map((item) => {
            return (
              <Box sx={{ display: "flex" }}>
                <Box
                  sx={{
                    display: "grid",
                    justifyContent: "left",
                    alignItems: "center",
                  }}
                >
                  <Checkbox />
                </Box>
                <Box
                  sx={{
                    display: "grid",
                    justifyContent: "left",
                    alignItems: "center",
                    width: "100px",
                  }}
                >
                  {item.name}
                </Box>
              </Box>
            );
          })}
      </Box>
    </Box>
  );
}

export default AssociatedUnits;
