import { Avatar, Box, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import React, { useEffect, useState } from "react";
import { Apiaddress } from "../../../utils/consts";

const PdfTwoPageThree = ({quotationInfo,projectInfo,unitInfo,leadInfo}) => {

  return (
    <Box>
      <Typography sx={{ fontSize: "14px" }}>
        Quotation for {`(${leadInfo?.lead_name})`} of {`(${projectInfo?.project_name})`} Dated{" "}
        {`(${quotationInfo?.created?.substring(0, 10)})`}
      </Typography>
      <Typography sx={{ fontSize: "14px", my: "20px" }}>
        <span
          style={{
            borderBottom: "2px solid #000",
            display: "inline",
            fontSize: "16px",
            fontWeight: 400, 
          }}
        >Unit Snapshot:
        </span>{" "}
        <span
          style={{
            fontWeight: 400,
          }}
        >
          (This will be a printed version of the Unit dashboard)
        </span>
      </Typography>
      
        <img src={`https://api.EnvisionNext.in${unitInfo.image}`} alt="loading" width="500" height="400"/>
        <Typography sx={{ fontSize: "14px", my: "30px",}}>
        <span
          style={{
            borderBottom: "2px solid #000",
            display: "inline",
            fontSize: "16px",
            fontWeight: 400, 
          }}
        >Floor Snapshot:
        </span>{" "}
        <span
          style={{
            fontWeight: 400,
          }}
        >
          (This will be a printed version of the Unit dashboard)
        </span>
      </Typography>
        <img src={`https://api.EnvisionNext.in${unitInfo.floor}`} alt="loading" width="500" height="400"/>
     
    </Box>
  );
};

export default PdfTwoPageThree; 
