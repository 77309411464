import { Box, Container, Stack, Typography } from "@mui/material";
import CircleCheckIcon from "../../Imgs/circle-check-icon.svg";
import CommonLandingKeyFeaturesImg from "../../Imgs/common-landing-key-features-img.png";

const CommonLandingKeyFeatures = () => {
  return (
    <Box sx={{ overflowX: "hidden" }}>
      <Container>
        <Box
          sx={{
            display: "flex",
            gap: { xs: "10px", md: "30px" },
            alignItems: "center",
            flexDirection: { xs: "column-reverse", md: "row" },
          }}
        >
          <Stack spacing={2} sx={{ width: { xs: "100%", md: "55%" } }}>
            <Typography
              data-aos="fade-up"
              sx={{
                fontSize: { xs: "20px", lg: "24px" },
                fontFamily: "Product Sans",
                color: "#0085FF",
              }}
            >
              KEY FEATURES
            </Typography>
            <Typography
              data-aos="fade-right"
              sx={{
                fontSize: { xs: "24px", md: "32px", lg: "40px" },
                fontWeight: 700,
                color: "#343434",
                fontFamily: "Circular Std",
                lineHeight: "1.2",
              }}
            >
              Powerful & easy to use Real Estate CRM software
            </Typography>
            <Stack
              spacing={2}
              data-aos="fade-zoom-in"
              data-aos-easing="ease-in-back"
              data-aos-delay="200"
              data-aos-offset="0"
            >
              <Box sx={{ display: "flex", gap: "10px" }}>
                <Box sx={{ lineHeight: "10px", fontSize: "30px" }}>
                  {/* <img src={CircleCheckIcon} alt="" /> */}
                  &#x2022;
                </Box>
                <Typography
                  sx={{
                    fontSize: "16px",
                    fontFamily: "Product Sans",
                    color: "#343434",
                    lineHeight: "1.3",
                    display: { xs: "none", md2: "block" },
                  }}
                >
                  EnvisionNext is a Real Estate CRM software with an <br />
                  <span style={{ fontWeight: 600 }}>
                    extensive set of features
                  </span>{" "}
                  on an intuitive and <br /> user-friendly interface
                </Typography>
                <Typography
                  sx={{
                    fontSize: "16px",
                    fontFamily: "Product Sans",
                    color: "#343434",
                    lineHeight: "1.3",
                    display: { xs: "block", md2: "none" },
                  }}
                >
                  EnvisionNext is a Real Estate CRM software with an{" "}
                  <span style={{ fontWeight: 600 }}>
                    extensive set of features
                  </span>{" "}
                  on an intuitive and user-friendly interface
                </Typography>
              </Box>
              <Box sx={{ display: "flex", gap: "10px" }}>
                <Box sx={{ lineHeight: "10px", fontSize: "30px" }}>
                  {/* <img src={CircleCheckIcon} alt="" /> */}
                  &#x2022;
                </Box>
                <Typography
                  sx={{
                    fontSize: "16px",
                    fontFamily: "Product Sans",
                    color: "#343434",
                    lineHeight: "1.3",
                  }}
                >
                  Manage your projects and processes across all <br /> entities
                  easily
                </Typography>
              </Box>
              <Box sx={{ display: "flex", gap: "10px" }}>
                <Box sx={{ lineHeight: "10px", fontSize: "30px" }}>
                  {/* <img src={CircleCheckIcon} alt="" /> */}
                  &#x2022;
                </Box>
                <Typography
                  sx={{
                    fontSize: "16px",
                    fontFamily: "Product Sans",
                    color: "#343434",
                    lineHeight: "1.3",
                    display: { xs: "none", md2: "block" },
                  }}
                >
                  Everything will be{" "}
                  <span style={{ fontWeight: 600 }}>seamless</span> and{" "}
                  <span style={{ fontWeight: 600 }}>
                    compliant with <br /> industry standards,
                  </span>{" "}
                  whether booking, sales, legal <br /> formalities, or
                  administrative tasks
                </Typography>
                <Typography
                  sx={{
                    fontSize: "16px",
                    fontFamily: "Product Sans",
                    color: "#343434",
                    lineHeight: "1.3",
                    display: { xs: "block", md2: "none" },
                  }}
                >
                  Everything will be{" "}
                  <span style={{ fontWeight: 600 }}>seamless</span> and{" "}
                  <span style={{ fontWeight: 600 }}>
                    compliant with industry standards,
                  </span>{" "}
                  whether booking, sales, legal formalities, or administrative
                  tasks
                </Typography>
              </Box>
            </Stack>
          </Stack>
          <Box data-aos="fade-left" sx={{ width: { xs: "100%", md: "45%" } }}>
            <img
              style={{ width: "100%" }}
              src={CommonLandingKeyFeaturesImg}
              alt=""
            />
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

export default CommonLandingKeyFeatures;
