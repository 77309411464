import React, { useContext, useEffect, useState } from 'react';
import { Box, Button, Stack, TextField, Typography } from "@mui/material";
import DoneIcon from '@mui/icons-material/Done';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import Pagination from '@mui/material/Pagination';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import { Link, useLocation, useNavigate } from "react-router-dom";

import axios from 'axios';

import { MainSidebar } from '../ProjectDashboard/DashboardComponents/MainSidebar';
import { Navbar } from '../ProjectDashboard/DashboardComponents/Navbar';
import { Apiaddress } from '../../../utils/consts';
import { ConfirmProject_steps } from '../ProjectDashboard/CreateProject/ConfirmProject_steps';
import { BackeToOverviewBtn } from '../ProjectDashboard/CreateProject/BackeToOverviewBtn';
import currentProjectState from "../../../context/createproject/context";

function EditProjectFloor() {

   ////get data from previous page 
   const location = useLocation();
   let data = location.state;
   let [current_data, setCurrentData] = useState(data)
   console.log("preve data =>.>",data)

  const navigate = useNavigate();

   const [floornum,setfloornum]=useState(1);

  const [floordata,setfloordata]=useState(data?.wing_template? data.wing_template:[]);


// ////get data of buliding templates 
// const cpContext = useContext(currentProjectState);
// const projectID = cpContext?.createProjectState?.project?.id;

//get project id from local storage;

const projectfromlocal = JSON.parse(localStorage.getItem("BuilderSelected"))
const projectID = projectfromlocal.selectedProjectID

useEffect(()=>{
  const buildingurl = Apiaddress+"/api/projects/buildingtemplates/?building_template_id="+data.id
   console.log(data);
  axios.get(buildingurl)
  .then((res)=>{
        const data = res?.data
        console.log("updated data=>>>>>>>.",data.building_template[0])
        setfloordata(data?.building_template[0]?.wing_template)
        setCurrentData(data?.building_template[0])
     })
     .catch((err)=>{
       console.log(err)
     })
 
},[])



  return (
    <>

<Stack direction="row" sx={{width:"100%",height:"auto" }}>
      {/* 1st stack */}
      <Stack sx={{minHeight:"100vh",width:"20%",boxSizing:"border-box",}}> {/*for project directory 20% and for sales dashboard 225*/}
        <MainSidebar active={"Project Directory"}/>
      </Stack>

     {/* 2nd stack */}
     <Stack direction="column" sx={{ backgroundColor:"#f9f9f9",width:"80%", padding:"25px",boxSizing:"border-box",}}  >  {/*  width for main sidebar 78px and for 80px*/}
     <Navbar editProject={true}/>
      

      {/* <Typography sx={{marginTop:"20px", fontSize:"28px", fontWeight:"600", marginLeft:"10px", marginBottom:"50px"}}>
                    Project Overview
           </Typography> */}
     {/* actual content  */}
    <Stack sx={{backgroundColor:"#f9f9f9", mt:"28px"}}>  {/* mt:"28px", */}

    <Stack direction="column"  sx={{  padding:"20px", backgroundColor:"#fff", marginRight:"0px"}}>

{/* confirm project flores content */}

<ConfirmProject_steps building_name={data?.name} />

    <Box sx={{}}>
      <Stack direction="row" sx={{margin:"0px 0px 0px 0px",display:"flex",alignItems:"center"}} >
        <Typography sx={{fontSize:"18px", fontWeight:"500"}}>Floors</Typography>
        <Link to="/builder-edit-project-floor-add" state={current_data}>
        <AddIcon sx={{color:"#A2A3A6",fontSize:"18px",marginLeft:"10px",cursor:"pointer"}}
        //  onClick={()=>{
        //   navigate("/confirm-project-floor-add");
        // }}
        />
        </Link>
      </Stack> 

        <Stack spacing={2} sx={{margin:"20px 0px 0px 0px"}}>
          <Pagination count={floordata.length} showFirstButton showLastButton onChange={(e,page)=>{setfloornum(page)}}/>
        </Stack>
     
      <Stack direction="row" sx={{margin:"30px 0px 0px 0px"}} >
        <Typography sx={{fontSize:"18px", fontWeight:"500",color:"#A2A3A6"}}>Floor {floornum}</Typography>

        <Link to="/builder-edit-project-floor-details" state={{state:floordata[floornum-1],data:current_data}} >
        <EditIcon sx={{color:"#A2A3A6",marginLeft:"10px",cursor:"pointer"}} />
        </Link>


        {/* <EditIcon sx={{color:"#A2A3A6",marginLeft:"10px",cursor:"pointer"}} onClick={()=>{
          navigate("/confirm-project-Floors-details");
        }}/> */}
      </Stack>
      <Stack direction="row" sx={{display:"flex",alignItems:"center",margin:"20px 0px 0px 0px"}} >
        <Typography sx={{fontSize:"18px", fontWeight:"500",color:"#A2A3A6"}}>Floor Name</Typography>
        <TextField
           sx={{
               width:"188px",
               marginLeft:"10px"
              }} value={floordata[floornum-1].floor_name} disabled/>
      </Stack>

      <Stack direction="row" sx={{alignItems:"center"}}>
      <Box sx={{display:"flex",alignItems:"center",marginRight:"150px",'&:hover': {
            cursor: 'pointer',
         }}}
      onClick={()=>{
        navigate(-1)
      }}
      >
      <ArrowBackIcon/>
      <Typography sx={{fontSize:"18px", fontWeight:"500",margin:"0px 10px 0px 10px"}}>Back</Typography>
      </Box>
      <Box sx={{margin:"60px 0px 60px 0px"}}>
      <Link
      to="/builder-edit-project-unit" state={floordata[floornum-1]}>
          <Button 
          className="univercel_btn_class"
         
          variant="contained"
          sx={{padding:"16px 50px 16px 50px", color:"#fff",fontSize:"18px", fontWeight:"700"}}>
              Details 
          </Button>
        </Link>
      </Box>
      </Stack>
  </Box>


     </Stack>

     <BackeToOverviewBtn projectDirectory={true}/>

    </Stack>
      </Stack>
</Stack>

        
    </>
  )
}

export default EditProjectFloor;
