import {
  Box,
  Container,
  Grid,
  Paper,
  Stack,
  styled,
  Typography,
} from "@mui/material";

import ChannelPartnersBrokers from "../../Imgs/channel-partners-brokers.png";
import ChannelLandingInefficientSalesWorkflowsIcon from "../../Imgs/channel-landing-inefficient-sales-workflows.svg";
import ChannelLandingInventoryAndLeadsIcon from "../../Imgs/channel-landing-Inventory-and-leads.svg";
import ChannelLandingLimitedAccessIcon from "../../Imgs/channel-landing-limited-access.svg";
import ChannelLandingLackOfTransparencyIcon from "../../Imgs/channel-landing-lack-of-transparency.svg";

const Item3 = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(1),
  color: theme.palette.text.secondary,
  boxShadow: "none",
  backgroundColor: "transparent",
}));

const ChannelLandingChannelPartnersBrokers = () => {
  return (
    <Box sx={{ backgroundColor: "#FFF", overflowX: "hidden" }}>
      <Container>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            flexDirection: { xs: "column-reverse", md: "row" },
            gap: "50px",
          }}
        >
          <Box sx={{ width: { xs: "100%", md: "60%" } }}>
            <Stack>
              <Typography
                data-aos="fade-down"
                sx={{
                  fontSize: { xs: "32px", md: "40px" },
                  fontWeight: 700,
                  fontFamily: "Circular Std",
                  color: "#272D4E",
                  lineHeight: "1.3",
                  textAlign: { xs: "center", md: "left" },
                }}
              >
                Channel Partners & Brokers struggle with slow growth
              </Typography>
              <Typography
                data-aos="fade-up"
                sx={{
                  fontSize: "18px",
                  fontFamily: "Product Sans",
                  color: "#343434",
                  textAlign: { xs: "center", md: "left" },
                }}
              >
                Amid growing competition and changing customer expectations,
                multiple challenges hamper your business growth.
              </Typography>
            </Stack>
            <Box sx={{ flexGrow: 1, py: "30px" }}>
              <Grid container spacing={2}>
                <Grid xs={12} md2={5.5} data-aos="fade-right">
                  <Item3>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: "10px",
                        background: "#FFFFFF",
                        border: "1px solid #FFAF2A",
                        boxShadow:
                          "0px 8px 22px -6px rgba(24, 39, 75, 0.12), 0px 14px 64px -4px rgba(24, 39, 75, 0.12)",
                        borderRadius: "8px",
                        padding: "10px 20px",
                      }}
                    >
                      <Box>
                        <img
                          src={ChannelLandingInefficientSalesWorkflowsIcon}
                          alt=""
                        />
                      </Box>
                      <Typography
                        sx={{
                          fontSize: "18px",
                          fontWeight: 400,
                          fontFamily: "Product Sans",
                          color: "#343434",
                        }}
                      >
                        Inefficient sales workflows
                      </Typography>
                    </Box>
                  </Item3>
                </Grid>
                <Grid xs={12} md2={6.5} data-aos="fade-left">
                  <Item3>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: "10px",
                        background: "#FFFFFF",
                        border: "1px solid #FF4267",
                        boxShadow:
                          "0px 8px 22px -6px rgba(24, 39, 75, 0.12), 0px 14px 64px -4px rgba(24, 39, 75, 0.12)",
                        borderRadius: "8px",
                        padding: "10px 20px",
                      }}
                    >
                      <Box>
                        <img src={ChannelLandingInventoryAndLeadsIcon} alt="" />
                      </Box>
                      <Typography
                        sx={{
                          fontSize: "18px",
                          fontWeight: 400,
                          fontFamily: "Product Sans",
                          color: "#343434",
                        }}
                      >
                        Inventory and leads management on paper
                      </Typography>
                    </Box>
                  </Item3>
                </Grid>
                <Grid xs={12} md2={5.5} data-aos="fade-right">
                  <Item3>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: "10px",
                        background: "#FFFFFF",
                        border: "1px solid #0890FE",
                        boxShadow:
                          "0px 8px 22px -6px rgba(24, 39, 75, 0.12), 0px 14px 64px -4px rgba(24, 39, 75, 0.12)",
                        borderRadius: "8px",
                        padding: "10px 20px",
                      }}
                    >
                      <Box>
                        <img src={ChannelLandingLimitedAccessIcon} alt="" />
                      </Box>
                      <Typography
                        sx={{
                          fontSize: "18px",
                          fontWeight: 400,
                          fontFamily: "Product Sans",
                          color: "#343434",
                        }}
                      >
                        Limited access to project data
                      </Typography>
                    </Box>
                  </Item3>
                </Grid>
                <Grid xs={12} md2={6.5} data-aos="fade-left">
                  <Item3>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: "10px",
                        background: "#FFFFFF",
                        border: "1px solid #00C29A",
                        boxShadow:
                          "0px 8px 22px -6px rgba(24, 39, 75, 0.12), 0px 14px 64px -4px rgba(24, 39, 75, 0.12)",
                        borderRadius: "8px",
                        padding: "10px 20px",
                      }}
                    >
                      <Box>
                        <img
                          src={ChannelLandingLackOfTransparencyIcon}
                          alt=""
                        />
                      </Box>
                      <Typography
                        sx={{
                          fontSize: "18px",
                          fontWeight: 400,
                          fontFamily: "Product Sans",
                          color: "#343434",
                        }}
                      >
                        Lack of transparency in commissions
                      </Typography>
                    </Box>
                  </Item3>
                </Grid>
              </Grid>
            </Box>
          </Box>

          <Box sx={{ width: { xs: "100%", md: "40%" } }}>
            <img
              data-aos="fade-left"
              style={{ width: "100%" }}
              src={ChannelPartnersBrokers}
              alt=""
            />
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

export default ChannelLandingChannelPartnersBrokers;
