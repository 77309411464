import { Box, Button, Container, Typography } from "@mui/material";

const CommonLandingUnleashYourGrowth = () => {
  return (
    <Box
      sx={{
        backgroundColor: "#0890FE",
        py: "30px",
        overflowX: "hidden",
      }}
    >
      <Container>
        <Box
          data-aos="fade-zoom-in"
          data-aos-easing="ease-in-back"
          data-aos-delay="50"
          data-aos-offset="0"
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-around",
            gap: "20px",
            padding: { xs: "15px", md: "30px 20px" },
            flexDirection: { xs: "column", md: "row" },
          }}
        >
          <Typography
            sx={{
              color: "#FFF",
              fontSize: { xs: "20px", md: "28px", md2: "36px", lg: "44px" },
              fontWeight: 700,
              fontFamily: "Circular Std",
              display: { xs: "none", md: "block" },
            }}
          >
            Unleash your growth by joining <br /> the EnvisionNext platform!
          </Typography>
          <Typography
            sx={{
              color: "#FFF",
              fontSize: { xs: "20px", md: "36px", lg: "44px" },
              fontWeight: 700,
              fontFamily: "Circular Std",
              display: { xs: "block", md: "none" },
            }}
          >
            Unleash your growth by joining the EnvisionNext platform!
          </Typography>
          <Box>
            <Button
              sx={{
                background:
                  "linear-gradient(97.85deg, #FFAF2A 0.95%, #FFC666 100%)",
                fontWeight: 500,
                fontSize: { lg: "28px", xs: "16px" },
                boxShadow: "rgba(242, 178, 73, 0.59) 0px 9px 12px",
                borderRadius: "10px",
                color: "#06386B",
                textTransform: "none",
                padding: "10px 30px",
                fontFamily: "Product Sans",
                mt: { xs: "5px", md: "20px" },
                "&:hover": {
                  background:
                    "linear-gradient(97.85deg, #FFAF2A 0.95%, #FFC666 100%)",
                  boxShadow: "rgba(242, 178, 73, 0.59) 10px 12px 16px",
                },
              }}
            >
              Book A Demo
            </Button>
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

export default CommonLandingUnleashYourGrowth;
