import { Stack } from '@mui/material'
import React, { useState } from 'react'
import SideBar from '../components/sideBar/SideBar'
import ProjectSlider from '../Projects/components/ProjectSlider'
import ProjectDetails from './components/projectDetails'
import ProjectDetailsWithUnts from './components/projectDetailsWithUnts'
const BuyerDashboard = () => {
  const [ProjectID, setProjectID] = useState(0)
  return (
    <SideBar active={"Dashboard"}>
      <ProjectSlider setUpdate={(projectid:number)=>setProjectID(projectid)} update={true}></ProjectSlider>
      <ProjectDetails projectid={ProjectID}></ProjectDetails>
      <ProjectDetailsWithUnts ProjectID={ProjectID} ></ProjectDetailsWithUnts>
    </SideBar>



  )
}

export default BuyerDashboard