import { Box, Container, Typography } from "@mui/material";
import React from "react";

const CommonPagesFooter = () => {
  return (
    <Box sx={{ backgroundColor: "#000000", py: "30px" }}>
      <Container>
        <Typography
          sx={{
            color: "#E5E5E5",
            fontSize: "18px",
            textAlign: "center",
            fontFamily: "Product Sans",
          }}
        >
          Copyright@Krishworks
        </Typography>
      </Container>
    </Box>
  );
};

export default CommonPagesFooter;
