import React, { useContext } from 'react';
import { TextField, Typography, Radio,Box, Button } from "@mui/material";
import { Stack } from '@mui/system';
import { Link, useNavigate } from 'react-router-dom';
import { useState } from 'react';
import AddIcon from '@mui/icons-material/Add';
import BuildingPatternSection from './BuildingPatternSection';
import { Navbar } from '../DashboardComponents/Navbar';
import { Sidebar } from '../DashboardComponents/Sidebar';

import DoneIcon from '@mui/icons-material/Done';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import axios from 'axios';
import { Apiaddress } from '../../../../utils/consts';

import currentProjectState from '../../../../context/createproject/context';
import { MainSidebar } from '../DashboardComponents/MainSidebar';


function BuildingPatternTemplate() {

   const ProjectData=useContext(currentProjectState);

   const buildingState=ProjectData?.createProjectState?.project?.is_multi_building;

   console.log(buildingState);

    const navigate = useNavigate();

    const [buildingtitle,setbuildingtitle]=useState([{building_no:1, titlename:"", field:[],has_wing:true}]);

    const [plank,setplank]=useState(false);
   
    {console.log(buildingtitle)};

    const addBuilding = ()=>{
        setbuildingtitle([...buildingtitle,{
            titlename:"",
            building_no:buildingtitle.length+1,
            field:[],
            has_wing:true
        }])
    }

    //// function for create building Template;
    const cpContext = useContext(currentProjectState);
    const projectID = cpContext?.createProjectState?.project?.id;
    console.log(projectID);

    const createBuildingTemplate = ()=>{
        const url = Apiaddress+"/api/projects/buildingtemplates/"
        
        for(let i=0; i<buildingtitle.length; i++){
            if(buildingtitle[i].field.length==0){
                return;
            }
        }
        for(let i=0; i<buildingtitle.length; i++){

            const data = {
                name:buildingtitle[i].titlename,
                project:projectID,
                has_wings :buildingtitle[i].has_wing,
                wing_template:buildingtitle[i].field,
            }
            
            console.log(data)

            axios.post(url,data)
            .then((res)=>{
                console.log("building template  :  ", res.data)
                navigate("/createproject/projectpricing/projectcreateoption/mainscreen")
            }).catch((err)=>{
                console.log(err)
            })
        }

    }
    
  return (
    <>

<Stack direction="row" sx={{width:"100%",height:"auto" }}>
      {/* 1st stack */}
      <Stack sx={{minHeight:"100vh",width:"20%",boxSizing:"border-box",}}> {/*for project directory 20% and for sales dashboard 225*/}
        <MainSidebar active={"Project Directory"}/>
      </Stack>

     {/* 2nd stack */}
     <Stack direction="column" sx={{ backgroundColor:"#f9f9f9",width:"80%", padding:"25px",boxSizing:"border-box",}}  >  {/*  width for main sidebar 78px and for 80px*/}
      <Navbar/>
      


      <Typography sx={{marginTop:"20px", fontSize:"28px", fontWeight:"600", marginLeft:"10px", marginBottom:"50px"}}>
                    Building Pattern Template 
           </Typography>
     {/* actual content  */}
    <Stack sx={{backgroundColor:"#f9f9f9"}}>  {/* mt:"28px", */}

    <Stack direction="column"  sx={{  padding:"20px", backgroundColor:"#fff", marginRight:"0px"}}>    

<Box>
{
   buildingState && 
   <Button 
       type="submit"
        variant="contained"
         color="primary" sx={{ height:"50px", background: "linear-gradient(90deg, #0F5DAE 0%, #009DCF 100.33%)",boxShadow: "0px 13.7981px 22.5788px rgba(50, 174, 137, 0.25)", textTransform: "none", borderRadius: "4px", width:"170px", fontSize:"15px",margin:"40px 0px 20px 0px"}} onClick={()=>{
            addBuilding()              
        }}>
        <AddIcon sx={{marginRight:"10px"}}/>Add Building
    </Button> 
}

<Stack>
        {buildingtitle.map((ele,index)=>{
            return(
                <>
                <BuildingPatternSection header={ele} index={index} buildingtitle={buildingtitle} setbuildingtitle={setbuildingtitle} setplank={setplank} plank={plank}/>
                </>
            )
        })}
</Stack>


{ buildingtitle.length >0 && <Button 
       onClick={()=>{
        createBuildingTemplate()
        // navigate("/createproject/projectpricing/projectcreateoption/mainscreen")
       }}
        variant="contained"
         color="primary" sx={{ height:"50px", background: "linear-gradient(90deg, #0F5DAE 0%, #009DCF 100.33%)",boxShadow: "0px 13.7981px 22.5788px rgba(50, 174, 137, 0.25)", textTransform: "none", borderRadius: "4px", width:"150px", fontSize:"15px",margin:"70px 0px 70px 70px"}}>
        Save
 </Button>}


</Box>


</Stack>

    </Stack>
      </Stack>
</Stack>


  
    </>
  )
}

export default BuildingPatternTemplate;