import React, { useContext, useEffect } from 'react';
import { TextField} from "@mui/material";
import { Link, useLocation } from 'react-router-dom';
import { useState } from 'react';

import { Box, Button, Typography } from "@mui/material"
import { Stack } from "@mui/system"
import DoneIcon from '@mui/icons-material/Done';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import { useNavigate } from "react-router-dom";
import { Navbar } from '../DashboardComponents/Navbar';
import { Sidebar } from '../DashboardComponents/Sidebar';
import Divider from '@mui/material/Divider';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

import currentProjectState from '../../../../context/createproject/context';
import axios from 'axios';
import { Apiaddress } from '../../../../utils/consts';
import { Rtt } from '@mui/icons-material';
import WingTemplate from '../ProjectTemplate/WingTemplate';
import { MainSidebar } from '../DashboardComponents/MainSidebar';

function ConfirmProject2_Wings_add() {
    const navigate = useNavigate();

    ////get data from previous page 
  const location = useLocation();
  const data = location.state;

  console.log("preve data =>.>",data)
    
    
    const [wingtemp,setwingtemp]=useState([]);
    

    const [wingTemplates, setWingTemplates] = useState(data?.wing_template);

    const [wing, setWing] = useState({
       wing_no:"",
       wing_id:"",
       wing_name:""
    })

    const cpContext = useContext(currentProjectState);
    const projectID = cpContext?.createProjectState?.project?.id;
    console.log(projectID);
    ///for get floor list 
    useEffect(()=>{          
      const wingurl = Apiaddress+"/api/projects/wingtemplatesbyproject/?project_id="+projectID
      axios.get(wingurl)
       .then((res)=>{
             const data = res?.data
             console.log(data)
             if(data.wing_templates!=="Wing Template not found"){
              let arr =  data.wing_templates;
              ////finding template already present or not if present then not show 
              let flag = false;
              let temp = []
              for(let i=0; i<arr.length; i++){
                let ele = arr[i].id;
                for(let j=0; j<wingTemplates.length; j++){
                  if(wingTemplates[j].wing_id==ele){
                    flag=true;
                    console.log("=>>>.>>>>>>>>>>>>>>>>>>>>>>>",wingTemplates[j].wing_id,ele)
                  }
                }
                if(flag===false){
                  temp.push(arr[i]);
                  console.log(arr[i]);
                }
                flag=false;
              }
              setwingtemp([...temp])

             }
          })
          .catch((err)=>{
            console.log(err)
          })
    },[])
    
    // //function for create wing Templates
    const createWingTemplate = ()=>{
     console.log(wing); 
     setWingTemplates([...wingTemplates,wing])
     if(wing.wing_id=="" || wing.wing_name=="" || wing.wing_no=="") return
      let url = Apiaddress+"/api/projects/buildingtemplates/";
      
      let st = {
        building_template_id:data?.id,
        wing_template:[...wingTemplates,wing]
      }
  
      let headers = {
        headers:{
          "Content-Type":"application/json"
      }
      }
      console.log(st);
  
      axios.patch(url,st,headers)
      .then((res)=>{
        console.log("add wing template in building======>", res.data);
        // navigate("/confirm-project-wings")
      })
      .catch((err)=>{
        console.log(err);
      })


        }




  return (
    <>

    
<Stack direction="row" sx={{width:"100%",height:"auto" }}>
      {/* 1st stack */}
      <Stack sx={{minHeight:"100vh",width:"20%",boxSizing:"border-box",}}> {/*for project directory 20% and for sales dashboard 225*/}
        <MainSidebar active={"Project Directory"}/>
      </Stack>

     {/* 2nd stack */}
     <Stack direction="column" sx={{ backgroundColor:"#f9f9f9",width:"80%", padding:"25px",boxSizing:"border-box",}}  >  {/*  width for main sidebar 78px and for 80px*/}
      <Navbar/>
      


      <Typography sx={{marginTop:"20px", fontSize:"28px", fontWeight:"600", marginLeft:"10px", marginBottom:"50px"}}>
                    Add Wing 
           </Typography>
     {/* actual content  */}
    <Stack sx={{backgroundColor:"#f9f9f9"}}>  {/* mt:"28px", */}

    <Stack direction="column"  sx={{  padding:"20px", backgroundColor:"#fff", marginRight:"0px"}}>

        

                       {/* Wing Template */}
                       <Box sx={{}}>
      



        <Typography> Select wing to add in Building</Typography>

        <Box sx={{display:"flex",alignItems:"center",margin:"30px 0px 0px 70px"}}>
                <Typography component="span" className='field-name'>Wings</Typography>
        
                <FormControl sx={{width:"188px", marginLeft:"40px"}}>
                  <Select
                    onChange={(e)=>{
                      console.log(wingtemp[e.target.value])

                      setWing({
                        wing_no:wingtemp.length+1,
                        wing_id:wingtemp[e.target.value].id,
                        wing_name:wingtemp[e.target.value].name,
                      })


                    }}
                    displayEmpty
                    inputProps={{ 'aria-label': 'Without label' }}
                  >
                    {wingtemp.map((ele,index)=>{
                      return(
                        <MenuItem key={index} value={index} >{ele.name}</MenuItem>
                      )
                    })}
                  </Select>
                </FormControl>

                  <Typography className='' sx={{margin:"0px 0px 0px 20px",fontFamily:'Poppins',fontStyle:"normal",fontWeight:"500",fontSize: "12px",lineHeight:"18px",color:"#A2A3A6"}}>Can't Find The Template?</Typography>
                  <Link
                   to="/confirm-project-wings-create"
                    state={data} >
                <Typography className='' sx={{margin:"0px 0px 0px 5px",fontFamily:'Poppins',fontStyle:"normal",fontWeight:"500",fontSize: "12px",lineHeight:"18px",color:"#FF6140",cursor:"pointer"}} >Create New</Typography>
                   </Link>
              




              </Box>
              
              
                <Box sx={{margin:"60px 0px 60px 0px"}}>
                <Link 
                  onClick={()=>{
                    createWingTemplate()
                  }}
                to="/confirm-project-wings" state={data}>
                <Button 
                className="univercel_btn_class"
                variant="contained"
                sx={{padding:"16px 50px 16px 50px", color:"#fff",fontSize:"18px", fontWeight:"700"}}>
                    Save
                </Button>
              </Link>
            </Box>



    </Box>
                   
           </Stack>

    </Stack>
      </Stack>
</Stack>


      
    </>
  )
}

export default  ConfirmProject2_Wings_add