import {
  Box,
  Button,
  Fade,
  Modal,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { AuthService } from "../../../context/buyer/services/auth";
import { net } from "../../../utils/consts";
import { EnvisionLogo } from "../../BuilderComponents/BuilderDasboard/assets/icons";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  border: "2px solid #fff",
  borderRadius: "10px",
  boxShadow: 24,
  width: "250px",
  p: 4,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  gap: "15px",
};

const initData = {
  id: 0,
  appointment_time: "",
  status: "",
  channelPartner: {
    profile: {
      user_details: {
        email: "",
        first_name: "",
      },
      phone: "",
    },
  },
};

const FillAppointment = () => {
  const navigate = useNavigate();
  const parms = useParams();
  const appointment_id = parms.id;
  const Auth = new AuthService();

  const user = JSON.parse(localStorage.getItem("crm_user"));
  let username = user?.user_details?.first_name;
  console.log(user?.user_details?.first_name);

  const [appointmentDetails, setAppointmentDetails] = useState(initData);
  const [unitDetails, setUnitDetails] = useState(null);
  const fetchAppointment = async () => {
    try {
      const res = await (
        await Auth.makeUnAuthenticatedGetRequest(
          net(`leads/appointmentResponse/?appointment_id=${appointment_id}`)
        )
      ).json();
      // console.log({ res });
      if (res?.appointment?.cpStandaloneUnits.length > 0) {
        setUnitDetails(res?.appointment?.cpStandaloneUnits);
      } else {
        setUnitDetails(res?.appointment?.cpProjectsUnits);
      }
      setAppointmentDetails(res?.appointment);
    } catch (e) {
      console.log(e);
    }
    // setFormData(res.requirement);
  };
  // console.log({ unitDetails });
  // if (unitDetails) console.log(JSON.parse(unitDetails[0].unit_data));
  useEffect(() => {
    fetchAppointment();
  }, []);

  const [clicked, setClicked] = useState("");
  const [reason, setReason] = useState("");
  return (
    <>
      <Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "column", md: "row" },
            justifyContent: { md: "space-between" },
            alignItems: { md: "center" },
            // my: { md: "10px" },
            my: "10px",
            mt: { xs: "15px" },
            mx: 2,
          }}
        >
          <Box onClick={() => navigate("/")}>
            <EnvisionLogo />
          </Box>
          <Box
            sx={{
              width: { xs: "100%", md: "79%" },
              mt: { xs: "15px", md: "0px" },
            }}
          >
            <Typography
              sx={{
                fontSize: "1.5em",
                fontWeight: "600",
              }}
            >
              Appointment Confirmation
            </Typography>
            <Stack direction="row">
              <Typography
                sx={{
                  color: "#B9B7D0",
                  fontSize: { xs: "10px", md: "14px" },
                  fontWeight: "400",
                  marginRight: "8px",
                }}
              >
                Your appointment is scheduled with below details.
              </Typography>
            </Stack>
          </Box>
        </Box>
      </Box>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          margin: "10px",
        }}
      >
        <Box
          className="hidescrollbar"
          sx={{
            marginTop: 2,
            // height: "20vh",
            width: { xs: "100%", md: "58%" },
            px: { xs: "10px", md: "30px" },
            pb: "10px",
            background: "white",
            borderRadius: "10px",
            color: "black",
            border: "1px solid #2979F2",
            boxSizing: "border-box",
            "&:hover": {
              boxShadow: "-6px 18px 15px rgba(0, 0, 0, 0.1)",
            },
          }}
        >
          <Box
            sx={{
              display: "flex",
              my: 1,
              flexDirection: { xs: "column", md: "row" },
            }}
          >
            <Typography
              sx={{
                fontFamily: "Product Sans",
                my: { xs: 0.75, md: 1.5 },
                fontStyle: "normal",
                fontWeight: "500",
                fontSize: { xs: "16px", md: "22px" },
                textSizeAdjust: "auto",
                lineHeight: "1.2em",
                color: "#84828C",
                marginRight: { md: "20px" },
                width: { xs: "100%", md: "30%" },
              }}
            >
              Channel Partner Name:
            </Typography>
            <Typography
              sx={{
                fontFamily: "Product Sans",
                my: { xs: 0.75, md: 1.5 },
                fontStyle: "normal",
                fontWeight: "500",
                fontSize: { xs: "16px", md: "22px" },
                textSizeAdjust: "auto",
                lineHeight: "1.2em",
                color: "#272D4E",
                marginRight: { md: "20px" },
              }}
            >
              {/* Name of CP */}
              {
                appointmentDetails?.channelPartner?.profile?.user_details
                  ?.first_name
              }
            </Typography>
            {/* <TextField sx={{ width: { xs: "100%", md: "50%" } }}></TextField> */}
          </Box>
          <Box
            sx={{
              display: "flex",
              my: 1,
              flexDirection: { xs: "column", md: "row" },
            }}
          >
            <Typography
              sx={{
                fontFamily: "Product Sans",
                my: { xs: 0.75, md: 1.5 },
                fontStyle: "normal",
                fontWeight: "500",
                fontSize: { xs: "16px", md: "22px" },
                textSizeAdjust: "auto",
                lineHeight: "1.2em",
                color: "#84828C",
                marginRight: { md: "20px" },
                width: { xs: "100%", md: "30%" },
              }}
            >
              Channel partner No.
            </Typography>
            <Typography
              sx={{
                fontFamily: "Product Sans",
                my: { xs: 0.75, md: 1.5 },
                fontStyle: "normal",
                fontWeight: "500",
                fontSize: { xs: "16px", md: "22px" },
                textSizeAdjust: "auto",
                lineHeight: "1.2em",
                color: "#272D4E",
                marginRight: { md: "20px" },
              }}
            >
              {/* Number of CP */}
              {appointmentDetails?.channelPartner?.profile?.phone}
            </Typography>{" "}
          </Box>
          <Box
            sx={{
              display: "flex",
              my: 1,
              flexDirection: { xs: "column", md: "row" },
            }}
          >
            <Typography
              sx={{
                fontFamily: "Product Sans",
                my: { xs: 0.75, md: 1.5 },
                fontStyle: "normal",
                fontWeight: "500",
                fontSize: { xs: "16px", md: "22px" },
                textSizeAdjust: "auto",
                lineHeight: "1.2em",
                color: "#84828C",
                marginRight: { md: "20px" },
                width: { xs: "100%", md: "30%" },
              }}
            >
              Date & Time:
            </Typography>
            <Typography
              sx={{
                fontFamily: "Product Sans",
                my: { xs: 0.75, md: 1.5 },
                fontStyle: "normal",
                fontWeight: "500",
                fontSize: { xs: "16px", md: "22px" },
                textSizeAdjust: "auto",
                lineHeight: "1.2em",
                color: "#272D4E",
                marginRight: { md: "20px" },
              }}
            >
              {/* Appointment Date & Time */}
              {appointmentDetails.appointment_time
                ? new Date(appointmentDetails?.appointment_time)
                    .toString()
                    .split("GMT")[0]
                : ""}
            </Typography>{" "}
          </Box>
          <Box
            sx={{
              display: "flex",
              my: 1,
              flexDirection: { xs: "column", md: "row" },
            }}
          >
            <Typography
              sx={{
                fontFamily: "Product Sans",
                my: { xs: 0.75, md: 1.5 },
                fontStyle: "normal",
                fontWeight: "500",
                fontSize: { xs: "16px", md: "22px" },
                textSizeAdjust: "auto",
                lineHeight: "1.2em",
                color: "#84828C",
                marginRight: { md: "20px" },
                width: { xs: "100%", md: "30%" },
              }}
            >
              Address:
            </Typography>
            {unitDetails && (
              <Typography
                sx={{
                  fontFamily: "Product Sans",
                  my: { xs: 0.75, md: 1.5 },
                  fontStyle: "normal",
                  fontWeight: "500",
                  fontSize: { xs: "16px", md: "22px" },
                  textSizeAdjust: "auto",
                  lineHeight: "1.2em",
                  color: "#272D4E",
                  marginRight: { md: "20px" },
                }}
              >
                {/* Address */}
                {JSON.parse(unitDetails[0].unit_data).Address ?? ""}{" "}
                {/* {JSON.parse(unitDetails[0].unit_data).City}{" "}
                {JSON.parse(unitDetails[0].unit_data).State}{" "}
                {JSON.parse(unitDetails[0].unit_data).Pincode} */}
              </Typography>
            )}
          </Box>
          <Box
            sx={{
              display: "flex",
              my: 1,
              flexDirection: { xs: "column", md: "row" },
            }}
          >
            <Typography
              sx={{
                fontFamily: "Product Sans",
                my: { xs: 0.75, md: 1.5 },
                fontStyle: "normal",
                fontWeight: "500",
                fontSize: { xs: "16px", md: "22px" },
                textSizeAdjust: "auto",
                lineHeight: "1.2em",
                color: "#84828C",
                marginRight: { md: "20px" },
                width: { xs: "100%", md: "30%" },
              }}
            >
              City:
            </Typography>
            {unitDetails && (
              <Typography
                sx={{
                  fontFamily: "Product Sans",
                  my: { xs: 0.75, md: 1.5 },
                  fontStyle: "normal",
                  fontWeight: "500",
                  fontSize: { xs: "16px", md: "22px" },
                  textSizeAdjust: "auto",
                  lineHeight: "1.2em",
                  color: "#272D4E",
                  marginRight: { md: "20px" },
                }}
              >
                {/* Address */}
                {/* {JSON.parse(unitDetails[0].unit_data).Address}{" "} */}
                {JSON.parse(unitDetails[0].unit_data).City ?? ""}{" "}
                {/* {JSON.parse(unitDetails[0].unit_data).State}{" "}
                {JSON.parse(unitDetails[0].unit_data).Pincode} */}
              </Typography>
            )}
          </Box>
          <Box
            sx={{
              display: "flex",
              my: 1,
              flexDirection: { xs: "column", md: "row" },
            }}
          >
            <Typography
              sx={{
                fontFamily: "Product Sans",
                my: { xs: 0.75, md: 1.5 },
                fontStyle: "normal",
                fontWeight: "500",
                fontSize: { xs: "16px", md: "22px" },
                textSizeAdjust: "auto",
                lineHeight: "1.2em",
                color: "#84828C",
                marginRight: { md: "20px" },
                width: { xs: "100%", md: "30%" },
              }}
            >
              State:
            </Typography>
            {unitDetails && (
              <Typography
                sx={{
                  fontFamily: "Product Sans",
                  my: { xs: 0.75, md: 1.5 },
                  fontStyle: "normal",
                  fontWeight: "500",
                  fontSize: { xs: "16px", md: "22px" },
                  textSizeAdjust: "auto",
                  lineHeight: "1.2em",
                  color: "#272D4E",
                  marginRight: { md: "20px" },
                }}
              >
                {/* Address */}
                {/* {JSON.parse(unitDetails[0].unit_data).Address}{" "}
                {JSON.parse(unitDetails[0].unit_data).City}{" "} */}
                {JSON.parse(unitDetails[0].unit_data).State ?? ""}{" "}
                {/* {JSON.parse(unitDetails[0].unit_data).Pincode} */}
              </Typography>
            )}
          </Box>
          <Box
            sx={{
              display: "flex",
              my: 1,
              flexDirection: { xs: "column", md: "row" },
            }}
          >
            <Typography
              sx={{
                fontFamily: "Product Sans",
                my: { xs: 0.75, md: 1.5 },
                fontStyle: "normal",
                fontWeight: "500",
                fontSize: { xs: "16px", md: "22px" },
                textSizeAdjust: "auto",
                lineHeight: "1.2em",
                color: "#84828C",
                marginRight: { md: "20px" },
                width: { xs: "100%", md: "30%" },
              }}
            >
              Pincode:
            </Typography>
            {unitDetails && (
              <Typography
                sx={{
                  fontFamily: "Product Sans",
                  my: { xs: 0.75, md: 1.5 },
                  fontStyle: "normal",
                  fontWeight: "500",
                  fontSize: { xs: "16px", md: "22px" },
                  textSizeAdjust: "auto",
                  lineHeight: "1.2em",
                  color: "#272D4E",
                  marginRight: { md: "20px" },
                }}
              >
                {/* Address */}
                {/* {JSON.parse(unitDetails[0].unit_data).Address}{" "}
                {JSON.parse(unitDetails[0].unit_data).City}{" "}
                {JSON.parse(unitDetails[0].unit_data).State}{" "} */}
                {JSON.parse(unitDetails[0].unit_data).Pincode ?? ""}
              </Typography>
            )}
          </Box>
        </Box>
        {appointmentDetails?.status == "Pending" ? (
          <Box
            sx={{
              mt: 2,
              display: "flex",
              alignItems: "center",
              flexDirection: { xs: "column", md: "row" },
              gap: { md: "1.5rem" },
            }}
          >
            <Button
              onClick={async () => {
                const Auth = new AuthService();
                const res = await (
                  await Auth.makeUnAuthenticatedPatchRequest(
                    net("leads/appointmentResponse/"),
                    {
                      JsonBody: {
                        id: appointmentDetails?.id,
                        status: "Accepted",
                        address:
                          JSON.parse(unitDetails[0].unit_data).Address ?? "",
                        response_time: new Date(),
                      },
                    }
                  )
                ).json();
                // console.log({ res });
                setClicked("accept");
              }}
              className="univercel_btn_class"
              sx={{
                // marginRight:"30px",
                color: "#FFFFFF",
                textTransform: "none",
                margin: "3px",
                padding: "8px 24px",
                fontFamily: "Product Sans",
                fontSize: "16px",
              }}
            >
              Accept
            </Button>
            <Box
              sx={{
                textAlign: "center",
                margin: "6px",
                cursor: "pointer",
              }}
              onClick={async () => {
                const Auth = new AuthService();
                const res = await (
                  await Auth.makeUnAuthenticatedPatchRequest(
                    net("leads/appointmentResponse/"),
                    {
                      JsonBody: {
                        id: appointmentDetails?.id,
                        status: "Rejected",
                        address:
                          JSON.parse(unitDetails[0].unit_data).Address ?? "",
                        response_time: new Date(),
                      },
                    }
                  )
                ).json();
                // console.log({ res });
                setClicked("reject");
              }}
            >
              <Typography
                sx={{
                  color: "red",
                  fontWeight: 500,
                  fontFamily: "Product Sans",
                }}
              >
                Reject
              </Typography>
            </Box>
          </Box>
        ) : (
          <Typography
            sx={{
              color: "green",
              fontWeight: 700,
              mt: 5,
              fontFamily: "Product Sans",
            }}
          >
            Response Sent
          </Typography>
        )}
        {/* <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Button
            disabled={clicked}
            sx={{
              padding: "10px 20px",
              color: "#06386B",
              textTransform: "none",
              mt: 4,
              fontSize: "20px",
              fontFamily: "Product Sans",
              background:
                "linear-gradient(97.85deg, #FFAF2A 0.95%, #FFC666 100%)",
              "&:hover": {
                boxShadow: "0px 9px 12px rgba(242, 178, 73, 0.59)",
              },
            }}
            onClick={() => {
              const Auth = new AuthService();
              Auth.makeUnAuthenticatedPatchRequest(net("leads/requirement/"), {
                JsonBody: {
                  requirement_id: requirementID,
                  basic_details: BasicLayoutState,
                  other_details: OtherLayout,
                  replyed: true,
                  update_on: new Date(),
                },
              });
              setOpen(true);
              setClicked(true);
            }}
          >
            {clicked ? "Submitted" : "Submit"}
          </Button>
        </div> */}
        {/* Accept Modal */}
        {clicked == "accept" && (
          <Modal
            open={clicked == "accept"}
            // onClose={handleClose}
            closeAfterTransition
          >
            <Fade in={clicked == "accept"}>
              <Box sx={style}>
                <Typography
                  sx={{
                    fontFamily: "Product Sans",
                    fontSize: "18px",
                    fontWeight: "600",
                  }}
                >
                  Appointment Confirmed
                </Typography>
                <Typography
                  sx={{
                    mb: "10px",
                  }}
                >
                  Thank you
                </Typography>
                <Button
                  onClick={() => {
                    window.location.reload();
                  }}
                  sx={{
                    //   width: "20%",
                    color: "#06386B",
                    textTransform: "none",
                    fontFamily: "Product Sans",
                    ml: 1,
                    "&:hover": {
                      boxShadow: "0px 9px 12px rgba(242, 178, 73, 0.59)",
                    },
                  }}
                  className="univercel_btn_class"
                >
                  Close
                </Button>
              </Box>
            </Fade>
          </Modal>
        )}
        {/* Reject Modal */}
        {clicked == "reject" && (
          <Modal
            open={clicked == "reject"}
            // onClose={handleClose}
            closeAfterTransition
          >
            <Fade in={clicked == "reject"}>
              <Box sx={style}>
                <Typography
                  sx={{
                    fontFamily: "Product Sans",
                    fontSize: "18px",
                    fontWeight: "600",
                  }}
                >
                  Reason
                </Typography>
                <TextField
                  autoFocus
                  value={reason}
                  placeholder={"Please type rejection reason"}
                  onChange={(e) => {
                    setReason(e.target.value);
                  }}
                  fullWidth
                ></TextField>
                <Button
                  onClick={async () => {
                    if (!reason) {
                      return;
                    }
                    const Auth = new AuthService();
                    const res = await (
                      await Auth.makeUnAuthenticatedPatchRequest(
                        net("leads/appointmentResponse/"),
                        {
                          JsonBody: {
                            id: appointmentDetails?.id,
                            description: reason,
                          },
                        }
                      )
                    ).json();
                    // console.log({ res });
                    window.location.reload();
                  }}
                  sx={{
                    width: "40%",
                    color: "#06386B",
                    textTransform: "none",
                    fontFamily: "Product Sans",
                    ml: 1,
                    "&:hover": {
                      boxShadow: "0px 9px 12px rgba(242, 178, 73, 0.59)",
                    },
                  }}
                  className="univercel_btn_class"
                >
                  Submit
                </Button>
              </Box>
            </Fade>
          </Modal>
        )}
      </div>
    </>
  );
};

export default FillAppointment;
