import {
  Box,
  Container,
  Grid,
  Paper,
  Stack,
  styled,
  Typography,
} from "@mui/material";

import EasilyCollaborate from "../../Imgs/easily-collaborate.svg";
import GetReadyToSee from "../../Imgs/get-ready-to-see.png";
import OnboardProject from "../../Imgs/onboard-project.svg";
import SignUp from "../../Imgs/sign-up.svg";

const Item = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(1),
  boxShadow: "none",
}));

const ChannelLandingGetStartWithEnvisionNext = () => {
  return (
    <Box sx={{ overflowX: "hidden" }}>
      <Container>
        <Box sx={{ textAlign: "center", mt: "50px" }}>
          <Typography
            data-aos="zoom-in"
            sx={{
              fontSize: { xs: "30px", md: "40px", lg: "54px" },
              fontWeight: 700,
              color: "#272D4E",
              fontFamily: "Circular Std",
              lineHeight: "1.2",
            }}
          >
            Get started with EnvisionNext
          </Typography>
          <Typography
            data-aos="fade-up"
            sx={{
              fontSize: { xs: "20px", md: "36px" },
              color: "#343434",
              fontFamily: "Product Sans",
              display: { xs: "none", md: "block" },
              lineHeight: "1.2",
            }}
          >
            It’s free to use for Channel Partners & Brokers. <br /> No hidden fees
            or subscriptions.
          </Typography>
          <Typography
            data-aos="fade-up"
            sx={{
              fontSize: { xs: "20px", md: "36px" },
              color: "#343434",
              fontFamily: "Product Sans",
              display: { xs: "block", md: "none" },
              lineHeight: "1.1",
            }}
          >
            It’s free to use for Channel Partners & Brokers. No hidden fees
            or subscriptions.
          </Typography>
        </Box>
        <Box
          sx={{
            flexGrow: 1,
            mt: { xs: "30px", md: "50px" },
            px: { xs: "30px", md: "50px" },
          }}
        >
          <Grid container spacing={2} justifyContent="center">
            <Grid item xs={12} md={6} md2={3}>
              <Item>
                <Stack
                  data-aos="fade-zoom-in"
                  data-aos-easing="ease-in-back"
                  data-aos-delay="200"
                  data-aos-offset="0"
                  spacing={1}
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    textAlign: "center",
                    gap: "5px",
                  }}
                >
                  <Box sx={{ width: "150px" }}>
                    <img style={{ width: "100%" }} src={SignUp} alt="" />
                  </Box>
                  <Typography
                    sx={{
                      fontSize: { xs: "18px", lg: "20px" },
                      fontWeight: 600,
                      color: "#0890FE",
                      fontFamily: "Product Sans",
                      py: "16px",
                    }}
                  >
                    Sign up for free
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: { xs: "18px" },
                      color: "#343434",
                      fontFamily: "Product Sans",
                    }}
                  >
                    Create your account and use EnvisionNext for free forever.
                  </Typography>
                </Stack>
              </Item>
            </Grid>
            <Grid item xs={12} md={6} md2={3}>
              <Item>
                <Stack
                  data-aos="fade-zoom-in"
                  data-aos-easing="ease-in-back"
                  data-aos-delay="400"
                  data-aos-offset="0"
                  spacing={1}
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    textAlign: "center",
                    gap: "5px",
                  }}
                >
                  <Box sx={{ width: "150px" }}>
                    <img
                      style={{ width: "100%" }}
                      src={OnboardProject}
                      alt=""
                    />
                  </Box>
                  <Typography
                    sx={{
                      fontSize: { xs: "18px", lg: "20px" },
                      fontWeight: 600,
                      color: "#FF4267",
                      fontFamily: "Product Sans",
                    }}
                  >
                    Start collaborating <br /> with your builders
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: { xs: "18px" },
                      color: "#343434",
                      fontFamily: "Product Sans",
                    }}
                  >
                    Partner with builders to sell properties and get your
                    commissions.
                  </Typography>
                </Stack>
              </Item>
            </Grid>
            <Grid item xs={12} md={6} md2={3}>
              <Item>
                <Stack
                  data-aos="fade-zoom-in"
                  data-aos-easing="ease-in-back"
                  data-aos-delay="600"
                  data-aos-offset="0"
                  spacing={1}
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    textAlign: "center",
                    gap: "5px",
                  }}
                >
                  <Box sx={{ width: "150px" }}>
                    <img
                      style={{ width: "100%" }}
                      src={EasilyCollaborate}
                      alt=""
                    />
                  </Box>
                  <Typography
                    sx={{
                      fontSize: { xs: "18px", lg: "20px" },
                      fontWeight: 600,
                      color: "#4F46BA",
                      fontFamily: "Product Sans",
                    }}
                  >
                    Connect with leads <br />
                    through the platform
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: { xs: "18px" },
                      color: "#343434",
                      fontFamily: "Product Sans",
                    }}
                  >
                    Capture, manage, and communicate with your leads from a
                    single place.
                  </Typography>
                </Stack>
              </Item>
            </Grid>
            <Grid item xs={12} md={6} md2={3}>
              <Item>
                <Stack
                  data-aos="fade-zoom-in"
                  data-aos-easing="ease-in-back"
                  data-aos-delay="800"
                  data-aos-offset="0"
                  spacing={1}
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    textAlign: "center",
                    gap: "5px",
                  }}
                >
                  <Box sx={{ width: "150px" }}>
                    <img style={{ width: "100%" }} src={GetReadyToSee} alt="" />
                  </Box>
                  <Typography
                    sx={{
                      fontSize: { xs: "18px", lg: "20px" },
                      fontWeight: 600,
                      color: "#4F46BA",
                      fontFamily: "Product Sans",
                    }}
                  >
                    Get ready to see your business thriving
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: { xs: "18px" },
                      color: "#343434",
                      fontFamily: "Product Sans",
                    }}
                  >
                    See your business flourish with increased efficiency and
                    new growth avenues.
                  </Typography>
                </Stack>
              </Item>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </Box>
  );
};

export default ChannelLandingGetStartWithEnvisionNext;
