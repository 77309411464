import { Box, Button, FormControl, MenuItem, Select, Stack, TextField, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import { NavbarForDirectory } from "../ProjectDashboard/DashboardComponents/NavbarForDirectory";
import { MainSidebar } from "../ProjectDashboard/DashboardComponents/MainSidebar";
import { Apiaddress } from "../../../utils/consts";
import axios from 'axios';
import { UserRole } from "../../../UserRoles/UserRoles";

function BuilderRolePermission() {

   let  token  = localStorage.getItem("crm_token")
    console.log(token);

    let roleDetails = JSON.parse(localStorage.getItem("roleDetails"));
    console.log(roleDetails);

    const [rolename,setrolename]=useState();
    const [trigger,setTrigger]=useState();
    const [rowData,setRowData]=useState();
    const [createdroleId,setcreatedroleId]=useState();
    const [plank,setplank]=useState();

    const navigate = useNavigate();

    useEffect(()=>{
      setrolename(roleDetails?.rolename);
      setcreatedroleId(roleDetails?.roleid);
    },[])


    //fetching Total Roles
    const fetchTotalPermission=async ()=>{
    try{
        const headers = {
        Authorization: `JWT ${token}`,
      };  
       const roleurl = Apiaddress+`/api/modelpermissions/`
       const res = await axios.get(roleurl,{headers});
    console.log("eee=============================",res?.data);

    let temp=res?.data;
    let newArray=[];
    for (let [key, value] of Object.entries( temp)) {
        
        let added={
            id:value[0].id,
            codename:value[0].codename,
            is_permission:false
        };
        let updated={
            id:value[1].id,
            codename:value[1].codename,
            is_permission:false
        };
        let deleted={
            id:value[2].id,
            codename:value[2].codename,
            is_permission:false
        };
        let viewed={
            id:value[3].id,
            codename:value[3].codename,
            is_permission:false
        };

        let obj={}
        obj.title=key;
        obj.add=added;
        obj.update=updated;
        obj.delete=deleted;
        obj.view=viewed;
        
        newArray.push(obj);
        // console.log("wwwwwww",key, value);
    }
        setRowData(newArray);
    }
    catch(err){
      console.log(err);
    }
    }

    const assignPermission=async (id,permission)=>{
         console.log(id," === ",permission);
        if(id && permission){
            try{
            const headers = {
            Authorization: `JWT ${token}`,
            };  
           let obj={
                role_id:createdroleId,
                permission_id:id
            }
            const roleurl = Apiaddress+"/api/roleprofile/"
            const res = await axios.put(roleurl,obj,{headers});
            console.log("tttt=========================",res?.data);
            }
            catch(err){
                console.log(err);
            }
        }
        else{
            try{
            const headers = {
            Authorization: `JWT ${token}`,
            };  
           let obj={
                role_id:createdroleId,
                permission_id:id
            }
            const roleurl = Apiaddress+"/api/roleprofile/"
            const res = await axios.delete(roleurl,{
              headers: {
                Authorization: `JWT ${token}`
              },
              data: {
                role_id:createdroleId,
                permission_id:id
              }
            });
            console.log("dddd=========================",res?.data);
            }
            catch(err){
                console.log(err);
            }
        }
    }


    useEffect(()=>{
      if(token)
      fetchTotalPermission();
    },[])

    console.log("wwwww=====",rowData);

  return (
    <>
       <Stack direction="row" sx={{width:"100%",height:"auto" }}>
        {/* 1st stack */}
        <Stack sx={{minHeight:"100vh",width:"20%",boxSizing:"border-box",}}> {/*for project directory 20% and for sales dashboard 225*/}
            <MainSidebar active={"Teams Directory"}/>
        </Stack>
         {/* 2nd stack */}
        <Stack direction="column" sx={{ backgroundColor:"#f9f9f9",width:"80%", padding:"25px",boxSizing:"border-box",}}  >  {/*  width for main sidebar 78px and for 80px*/}
           <NavbarForDirectory
            RightBarName={"Add Member"}
            leftTitle={"Team directory"}
            righshow={true}
            leftRightTitle1={"Project Dashboard"}
            leftRightTitle2={"Member Directory"}
            />   {/*send props for get popup to verify otp from builder */}

            <Typography sx={{fontFamily:'Poppins',fontStyle:"normal",fontWeight:"600",fontSize:"28px",lineHeight:"42px"}}>Roles Overview</Typography>

             <Stack sx={{margin:"50px 0px 30px 0px",background:"#FFFFFF"}}>
                <Stack direction="row" sx={{alignItems:"center",margin:"30px 0px 30px 40px"}}>
                  <Typography sx={{width:"20%",fontFamily:'Poppins',fontStyle:"normal",fontWeight:"500",fontSize:"18px",lineHeight:"27px"}}>Roles Profile Name</Typography>
                  <TextField value={rolename} disabled/>
                </Stack>
             </Stack>

    
              <Stack direction="row" justifyContent="space-around" alignItems="center" sx={{width:"100%",backgroundColor:"white",borderRadius: "5px",margin:"0px 0px 10px 0px",padding:"10px 0px 10px 0px"}}>
              
                <Stack direction="row" justifyContent="center" alingItems="center" sx={{width:"14%",textAlign:"center",color: "#9299BC",fontWeight: "600"}}>
                        <Typography sx={{ display: "grid", placeItems: "center" }}>Module</Typography>
                </Stack>

                 <Stack direction="row" justifyContent="center" alingItems="center" sx={{width:"14%",textAlign:"center",color: "#9299BC",fontWeight: "600"}}>
                        <Typography sx={{ display: "grid", placeItems: "center" }}>Add</Typography>
                </Stack>

                <Stack direction="row" justifyContent="center" alingItems="center" sx={{width:"14%",textAlign:"center",color: "#9299BC",fontWeight: "600"}}>
                        <Typography sx={{ display: "grid", placeItems: "center" }}>Edit</Typography>
                </Stack>

                <Stack direction="row" justifyContent="center" alingItems="center" sx={{width:"14%",textAlign:"center",color: "#9299BC",fontWeight: "600"}}>
                        <Typography sx={{ display: "grid", placeItems: "center" }}>Delete</Typography>
                </Stack>


                 <Stack direction="row" justifyContent="center" alingItems="center" sx={{width:"14%",textAlign:"center",color: "#9299BC",fontWeight: "600"}}>
                        <Typography sx={{ display: "grid", placeItems: "center" }}>View</Typography>
                </Stack>

            </Stack>

            <Stack sx={{overflowY:"scroll",height:"500px"}}>
             {rowData && rowData.map((item,idx)=>{
                   return(
                    <>  
                    <Stack direction="row" justifyContent="space-around" alignItems="center" sx={{width:"100%",borderRadius: "5px",margin:"0px 0px 10px 0px",padding:"10px 0px 10px 0px",backgroundColor:"white",cursor:"pointer"}}>
                    <Stack direction="row" justifyContent="center" alingItems="center" sx={{width:"14%",textAlign:"center",color: "#9299BC",fontWeight: "600"}}>
                    <Typography >{item?.title}</Typography>                 
                    </Stack>
                    <Stack direction="row" justifyContent="center" alingItems="center" sx={{width:"14%",textAlign:"center",color: "#9299BC",fontWeight: "600"}}>
                    <input type="checkbox" checked={item.add.is_permission} onChange={()=>{
                        let temp = rowData;
                        temp[idx].add.is_permission = !item.add.is_permission
                        setRowData([...temp])
                        // console.log(item.add.is_permission,item) 
                        assignPermission(item.add.id,item.add.is_permission);
                        }} ></input>                 
                    </Stack>
                    <Stack direction="row" justifyContent="center" alingItems="center" sx={{width:"14%",textAlign:"center",color: "#9299BC",fontWeight: "600"}}>
                    <input type="checkbox" checked={item.update.is_permission} onChange={()=>{
                        let temp = rowData;
                        temp[idx].update.is_permission = !item.update.is_permission
                        setRowData([...temp])
                        // console.log(item.update.is_permission,item)
                        assignPermission(item.update.id,item.update.is_permission);
                        }}></input>                   
                    </Stack>
                    <Stack direction="row" justifyContent="center" alingItems="center" sx={{width:"14%",textAlign:"center",color: "#9299BC",fontWeight: "600"}}>
                    <input type="checkbox" checked={item.delete.is_permission} onChange={()=>{
                        let temp = rowData;
                        temp[idx].delete.is_permission = !item.delete.is_permission
                        setRowData([...temp])
                        // console.log(item.delete.is_permission,item)
                        assignPermission(item.delete.id,item.delete.is_permission);
                        }}></input>                  
                    </Stack>
                    <Stack direction="row" justifyContent="center" alingItems="center" sx={{width:"14%",textAlign:"center",color: "#9299BC",fontWeight: "600"}}>
                    <input type="checkbox" checked={item.view.is_permission} onChange={()=>{
                        let temp = rowData;
                        temp[idx].view.is_permission = !item.view.is_permission
                        setRowData([...temp])
                        // console.log(item.view.is_permission,item)
                        assignPermission(item.view.id,item.view.is_permission);
                        }}></input>                  
                    </Stack>
                    </Stack>
                    </> 
                   )
                })}
            </Stack>
            <Stack direction="row" sx={{marginTop:"50px",justifyContent:"",alignItems:"center"}}>
                <Typography sx={{width:"20%",fontFamily:'Poppins',fontStyle:"normal",fontWeight:"500",fontSize:"18px",lineHeight:"27px",marginLeft:"40px",cursor:"pointer"}}
                  onClick={()=>{navigate("/builder-team-memberview")}}
                >Back</Typography>
                <Button variant="contained" sx={{background:"linear-gradient(90deg, #0F5DAE 0%, #009DCF 100.33%)",marginLeft:"580px",width:"100px"}}>
                    <Box sx={{ textTransform: "capitalize" }}
                        onClick={()=>{navigate("/builder-team-memberview")}}
                    >Save</Box>
                </Button>
            </Stack>
       </Stack> 

      </Stack>
      
    </>
  )
}

export default BuilderRolePermission