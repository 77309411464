

 const  countryDetails = {
    country: {
      value: "IN",
      label: {
        type: "div",
        key: null,
        ref: null,
        props: {
          className: "_3sXmFundefined",
          children: [
            {
              type: "img",
              key: null,
              ref: null,
              props: {
                src: "http://purecatamphetamine.github.io/country-flag-icons/3x2/IN.svg",
                alt: "flag",
                className: "_2z69Y",
              },
              _owner: {
                tag: 0,
                key: null,
                stateNode: null,
                return: null,
                child: null,
                sibling: null,
                index: 0,
                ref: null,
                pendingProps: null,
                memoizedProps: null,
                updateQueue: null,
                memoizedState: null,
                dependencies: null,
                mode: 27,
                flags: 1,
                subtreeFlags: 7340055,
                deletions: null,
                lanes: 0,
                childLanes: 1,
                alternate: null,
                actualDuration: 12.599999994039536,
                actualStartTime: 227624.40000000596,
                selfBaseDuration: 6.5,
                treeBaseDuration: 11.200000002980232,
                _debugSource: {
                  fileName:
                    "C:\\CRM\\envision-frontend-web\\src\\Components\\ChannelPartnerComponents\\SignUp\\SignupSubComponents\\Organization.jsx",
                  lineNumber: 33,
                  columnNumber: 21,
                },
                _debugOwner: null,
                _debugNeedsRemount: false,
                _debugHookTypes: null,
              },
              _store: {},
            },
            {
              type: "span",
              key: null,
              ref: null,
              props: {
                className: "_1qEzs",
                children: "India",
              },
              _owner: {
                tag: 0,
                key: null,
                stateNode: null,
                return: null,
                child: null,
                sibling: null,
                index: 0,
                ref: null,
                pendingProps: null,
                memoizedProps: null,
                updateQueue: null,
                memoizedState: null,
                dependencies: null,
                mode: 27,
                flags: 1,
                subtreeFlags: 7340055,
                deletions: null,
                lanes: 0,
                childLanes: 1,
                alternate: null,
                actualDuration: 12.599999994039536,
                actualStartTime: 227624.40000000596,
                selfBaseDuration: 6.5,
                treeBaseDuration: 11.200000002980232,
                _debugSource: {
                  fileName:
                    "C:\\CRM\\envision-frontend-web\\src\\Components\\ChannelPartnerComponents\\SignUp\\SignupSubComponents\\Organization.jsx",
                  lineNumber: 33,
                  columnNumber: 21,
                },
                _debugOwner: null,
                _debugNeedsRemount: false,
                _debugHookTypes: null,
              },
              _store: {},
            },
          ],
        },
        _owner: {
          tag: 0,
          key: null,
          stateNode: null,
          return: null,
          child: null,
          sibling: null,
          index: 0,
          ref: null,
          pendingProps: null,
          memoizedProps: null,
          updateQueue: null,
          memoizedState: null,
          dependencies: null,
          mode: 27,
          flags: 1,
          subtreeFlags: 7340055,
          deletions: null,
          lanes: 0,
          childLanes: 1,
          alternate: null,
          actualDuration: 12.599999994039536,
          actualStartTime: 227624.40000000596,
          selfBaseDuration: 6.5,
          treeBaseDuration: 11.200000002980232,
          _debugSource: {
            fileName:
              "C:\\CRM\\envision-frontend-web\\src\\Components\\ChannelPartnerComponents\\SignUp\\SignupSubComponents\\Organization.jsx",
            lineNumber: 33,
            columnNumber: 21,
          },
          _debugOwner: null,
          _debugNeedsRemount: false,
          _debugHookTypes: null,
        },
        _store: {},
      },
      id: 101,
      name: "India",
      iso3: "IND",
      iso2: "IN",
      numeric_code: 356,
      phone_code: 91,
      capital: "New Delhi",
      currency: "INR",
      currency_name: "Indian rupee",
      currency_symbol: "₹",
      tld: ".in",
      native: "भारत",
      region: "Asia",
      subregion: "Southern Asia",
      timezones: "[{zoneName:'Asia\\/Kolkata'",
      latitude: "gmtOffset:19800",
      longitude: "gmtOffsetName:'UTC+05:30'",
      emoji: "abbreviation:'IST'",
      emojiU: "tzName:'Indian Standard Time'}]",
    },
  }


  export {countryDetails}