import { Box } from "@mui/system";
import React, { useContext, useEffect, useState } from "react";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";

import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Button,
  Checkbox,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  Modal,
  OutlinedInput,
  Select,
  Stack,
  TextField,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";

import AddIcon from "@mui/icons-material/Add";
import profileContext from "../../../context/profile/context";
import axios from "axios";
import { Apiaddress } from "../../../utils/consts";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const names = ["Aditya Birla Appartments", "Tata Farms"];

function RightSidebarTD({
  setTabledata,
  curselectedRows,
  id,
  setTrigger,
  trigger,
  updateData,
  toggleDrawer,
  setState,
  setupdateData
}) {
  const [expanded, setExpanded] = useState("panel1");
  const [disabelAccrodian, setdisabelAccrodian] = useState(
    curselectedRows ? "true" : "false"
  );
  const [selectValue, setSelectValue] = useState("High");
  const [setProjects, setsetProjects] = React.useState([]);

  const handleChangeLeadProject = (event) => {
    const {
      target: { value },
    } = event;
    setsetProjects(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };
  const [selectArr, setselectArr] = useState([
    {
      value: "High",
      label: "High",
    },
    {
      value: "low",
      label: "low",
    },
  ]);

  const createOpt = () => { };

  const handleChange = (i) => {
    setExpanded(i);
  };

  const [colors, setColors] = useState([
    { name: "Ajay", clr: "green" },
    { name: " Tarun", clr: " yellow" },
    { name: "  Ranbir", clr: "red" },
  ]);
  const [leadname, setLeadName] = useState("");
  const [leadclr, setLeadclr] = useState("");

  const addClr = () => {
    const obj = { name: leadname, clr: leadclr };
    setColors([...colors, obj]);
    handleClose();
  };

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);


////starts here
  useEffect(() => {
    console.log(updateData);
  }, [updateData]);


  const [name, setName] = useState(updateData ? updateData.name : "");

  /////
  const [phone, setPhne] = useState("");
  const [Email, setEmail] = useState("");
  const [Team, setTeam] = useState("");
  const [Role, setRole] = useState("");

  const user = useContext(profileContext);

  const [newMemberId, setnewMemeberId] = useState(null);

  const [userID, setUserID] = useState(null);

  useEffect(() => { 
    if (user)
      if (user.UserState)
        if (user.UserState.user_obj)
          if (user.UserState.user_obj.id) setUserID(user.UserState.user_obj.id);
}, [user]);
//////

  const saveChanges = async () => {
    if (name) {
      if (updateData) {
        const obj = {
          name: name,
          team_id: updateData.id,
        };
        console.log(obj);
        const res = await axios.patch(
          Apiaddress + "/api/role/teamview/",
          obj
        );
        setTrigger(!trigger);
        console.log(res);
        setName("");
        setState({right:false})
      } else {
        try {
          const obj = {
            name: name,
            created_by: userID,
          };
          const res = await axios.post(
            Apiaddress + "/api/role/teamview/",
            obj
          );
          console.log(res);
          setTrigger(!trigger);
          setName("");
          setState({right:false})
        } catch (err) {
          console.log(err);
        }
      }
    }
  };

  const sidebarClosed=()=>{
    if(updateData){
        setupdateData(null);
        setName("");
    }
    setState({right:false});
  }

  return (
    <Box sx={{width: "420px",height: "80vh",marginTop: "-25px",marginRight: "25px",padding: "10px",}}>
      <Box sx={{position: "relative"}}>
        <Box sx={{position: "absolute",top: "0px",right: "0px",background: "white",padding: "10px",height: "105vh",width: "400px",marginTop: "20px"}}>{" "}
            <Box>
                <Box sx={{ display: "flex"}}>
                    <Stack direction="row" sx={{cursor: "pointer"}} 
                        // onClick={()=>{setState({right:false})}}
                        onClick={sidebarClosed}
                        >
                        <Box>
                            <ArrowBackIosIcon sx={{fontSize: "small",marginBottom: "-2px"}}/>
                        </Box>
                        <Box>Team Directory</Box>
                    </Stack>
                </Box>
            <Box sx={{display: "grid",alignItems: "center",height: "100vh"}}>
              <Box>
                <Accordion
                  expanded={expanded === "panel1"}
                  onClick={() => handleChange("panel1")}
                >
                <AccordionSummary
                    sx={{
                      background: `${expanded === "panel1" ? "#FFAF2A" : "white"}`,
                      borderRadius: "10px",
                    }}
                    // expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    >
                    <Typography sx={{ color: "white" }}>Team Directory</Typography>
                </AccordionSummary>

                <AccordionDetails>
                    <Box sx={{display: "flex",justifyContent: "space-between",width: "400px",margin: "10px 0"}}>
                      <Box>Name</Box>
                      <Box>
                        <TextField value={name} id="outlined-basic"
                          onChange={(e) => setName(e.target.value)}
                        />
                      </Box>
                    </Box>
                    
                    <Box sx={{display: "flex",justifyContent: "space-between",width: "400px",margin: "10px 0"}}>
                      <Box></Box>
                      {!updateData && (
                        <Button
                        //   onClick={()=>{
                            //     setState({right:false})
                            // }}
                            onClick={()=>{
                                saveChanges();
                            }}
                          className="univercel_btn_class"
                          sx={{
                            color: "#FFFFFF",
                            textTransform: "none",
                          }}
                        >
                          Add Team
                        </Button>
                      )}
                      {updateData && (
                        <Button
                          onClick={saveChanges}
                          className="univercel_btn_class"
                          sx={{
                            color: "#FFFFFF",
                            textTransform: "none",
                          }}
                        >
                          Update Team
                        </Button>
                      )}
                    </Box>
                  </AccordionDetails>
                </Accordion>
              </Box>
            </Box>
            <Box
              sx={{
                display: "grid",
                placeItems: "center",
              }}
            >
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export default RightSidebarTD;
