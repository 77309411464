import { Radio, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import { Stack } from "@mui/system";
import Button from "@mui/material/Button";
import "./Signup.css";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import KeyboardDoubleArrowLeftIcon from "@mui/icons-material/KeyboardDoubleArrowLeft";
import { EmailPassNo } from "./SignupSubComponents/EmailPassNo";
import { Otp } from "./SignupSubComponents/Otp";
import { useEffect, useState } from "react";
import { Organization } from "./SignupSubComponents/Organization";
import { CompleteBuilderSignup } from "./SignupSubComponents/CompleteBuilderSignup";
import DoneIcon from "@mui/icons-material/Done";
import { PasswordCreate } from "./SignupSubComponents/PasswordCreate";
import { useNavigate, useParams } from "react-router";
import EnvisionLogo from "../../Imgs/EnvisionLogo.svg";
import Support from "../../ChannelPartner/Components/sideBar/Support";

export const SignupChannelPartner = () => {
  const navigate = useNavigate();

  const [open, setOpen] = useState(false);
  const [signupdata, setSignupdata] = useState({
    name: "",
    phoneNumber: "",
    email: "",
    state: "",
    city: "",
    pincode: "",
  });

  const steps = ["Profile", "Verification", "Organization Details", "Complete"];
  const params = useParams();

  const [stepcolor, setStepcolor] = useState("#FFA946");
  const [stepsno, setStepsno] = useState(0);

  const [selectedValue, setSelectedValue] = useState("ind");
  const [inputType, setinputType] = useState("Individual");

  const handleChangeRadio = (event) => {
    setSelectedValue(event.target.value);
    console.log(event.target.value);
  };

  const controlProps = (item) => ({
    checked: selectedValue === item,
    onChange: handleChangeRadio,
    value: item,
    name: "color-radio-button-demo",
    inputProps: { "aria-label": item },
  });

  useEffect(() => {
    if (selectedValue == "org") {
      setinputType("Organization");
    } else {
      setinputType("Individual");
    }
  }, [selectedValue]);

  return (
    <>
      <Box
        sx={{
          display: "flex",
        }}
      >
        <Box
          sx={{
            width: "25%",
            backgroundColor: "#F4F6FB",
            height: "100vh",
            textAlign: "left",
            padding: "28px 30px",
            boxSizing: "border-box",
          }}
        >
          <Box sx={{}}>
            <img src={EnvisionLogo} style={{ width: "70%" }} />
            {/* <Typography
              sx={{ fontWeight: "600", fontSize: "2vw", lineHeight: "41px" }}
            >
              Envision
            </Typography> */}
          </Box>
          <Box sx={{ marginTop: "20vh", marginLeft: "20px" }}>
            {/* {steps.map((step,index)=>{
                return(
                    <> */}
            {/* step 1 */}
            <Stack
              direction="row"
              justifyContent="left"
              alignItems="center"
              spacing={2}
            >
              <Box
                className="stepper_signup"
                sx={{ backgroundColor: stepsno >= 0 ? "#FFA946" : "#D9D9D9" }}
              >
                {stepsno >= 0 ? <DoneIcon sx={{ color: "#fff" }} /> : 1}
              </Box>
              <Typography
                sx={{
                  fontSize: "1.1vw",
                  fontWeight: "500",
                  fontFamily: "Product Sans",
                }}
              >
                Profile
              </Typography>
            </Stack>
            {/* color> #FFA946 */}
            <Box>
              <Box
                sx={{
                  borderLeft:
                    stepsno > 0
                      ? "2.5px #FFA946 dashed"
                      : "2.5px Transparent dashed",
                }}
                className="vertical_line"
              ></Box>
            </Box>
            <Box>
              <Box
                sx={{
                  width: "6px",
                  height: "6px",
                  marginLeft: "16.2px",
                  marginBottom: "2px",
                  borderRadius: "50%",
                  backgroundColor: stepsno > 0 ? "#FFA946" : "transparent",
                }}
              ></Box>
            </Box>

            {/* step 2 */}
            <Stack
              direction="row"
              justifyContent="left"
              alignItems="center"
              spacing={2}
            >
              <Box
                className="stepper_signup"
                sx={{ backgroundColor: stepsno >= 1 ? "#FFA946" : "#D9D9D9" }}
              >
                {stepsno >= 1 ? <DoneIcon sx={{ color: "#fff" }} /> : 2}
              </Box>
              <Typography
                sx={{
                  fontSize: "1.1vw",
                  fontWeight: "500",
                  fontFamily: "Product Sans",
                }}
              >
                Verification
              </Typography>
            </Stack>
            {/* color> #FFA946 */}
            <Box>
              <Box
                sx={{
                  borderLeft:
                    stepsno > 1
                      ? "2.5px #FFA946 dashed"
                      : "2.5px Transparent dashed",
                }}
                className="vertical_line"
              ></Box>
            </Box>
            <Box>
              <Box
                sx={{
                  width: "6px",
                  height: "6px",
                  marginLeft: "16.2px",
                  marginBottom: "2px",
                  borderRadius: "50%",
                  backgroundColor: stepsno > 1 ? "#FFA946" : "transparent",
                }}
              ></Box>
            </Box>

            {/* step 3 */}
            <Stack
              direction="row"
              justifyContent="left"
              alignItems="center"
              spacing={2}
            >
              <Box
                className="stepper_signup"
                sx={{ backgroundColor: stepsno >= 2 ? "#FFA946" : "#D9D9D9" }}
              >
                {stepsno >= 2 ? <DoneIcon sx={{ color: "#fff" }} /> : 3}
              </Box>
              <Typography
                sx={{
                  fontSize: "1.1vw",
                  fontWeight: "500",
                  fontFamily: "Product Sans",
                }}
              >
                Setup Password
              </Typography>
            </Stack>
            {/* color> #FFA946 */}

            <Box>
              <Box
                sx={{
                  borderLeft:
                    stepsno > 2
                      ? "2.5px #FFA946 dashed"
                      : "2.5px Transparent dashed",
                }}
                className="vertical_line"
              ></Box>
            </Box>
            <Box>
              <Box
                sx={{
                  width: "6px",
                  height: "6px",
                  marginLeft: "16.2px",
                  marginBottom: "2px",
                  borderRadius: "50%",
                  backgroundColor: stepsno > 2 ? "#FFA946" : "transparent",
                }}
              ></Box>
            </Box>

            {/* step 4 */}
            <Stack
              direction="row"
              justifyContent="left"
              alignItems="center"
              spacing={2}
            >
              <Box
                className="stepper_signup"
                sx={{ backgroundColor: stepsno >= 3 ? "#FFA946" : "#D9D9D9" }}
              >
                {stepsno >= 3 ? <DoneIcon sx={{ color: "#fff" }} /> : 4}
              </Box>
              <Typography
                sx={{
                  fontSize: "1.1vw",
                  fontWeight: "500",
                  fontFamily: "Product Sans",
                }}
              >
                Complete
              </Typography>
            </Stack>
            {/* color> #FFA946 */}

            <Box>
              <Box
                sx={{
                  borderLeft:
                    stepsno > 3
                      ? "2.5px #FFA946 dashed"
                      : "2.5px Transparent dashed",
                }}
                className="vertical_line"
              ></Box>
            </Box>
            <Box>
              <Box
                sx={{
                  width: "6px",
                  height: "6px",
                  marginLeft: "16.2px",
                  marginBottom: "2px",
                  borderRadius: "50%",
                  backgroundColor: stepsno > 3 ? "#FFA946" : "transparent",
                }}
              ></Box>
            </Box>
          </Box>

          <Typography sx={{ fontFamily: "Product Sans", margin: "30px" }}>
            Having trouble? <span style={{ color: "#FF4228",cursor:"pointer"}} onClick={()=>setOpen(true)}>Get Help</span>{" "}
          </Typography>
        </Box>

        <Stack
          direction="column"
          alignItems="stretch"
          justifyContent="space-between"
          sx={{ width: "75%", height: "100vh", overflowY: "scroll" }}
        >
          <Stack
            direction="row"
            justifyContent="space-between"
            spacing={2}
            alignItems="center"
            sx={{ height: "15vh", padding: "46px 0px 0px 46px" }}
          >
            <Typography
              sx={{
                color: "#0085FF",
                fontFamily: "Circular Std",
                fontSize: "24px",
                fontWeight: "700",
                lineHeight: "30.36px",
              }}
            >
              {`Channel Partner ${inputType} Registration`}
            </Typography>

            <Stack
              direction="column"
              justifyContent="center"
              alignItems="center"
            >
              {/* <Button
                sx={{
                  background:
                    "linear-gradient(97.85deg, #FFAF2A 0.95%, #FFC666 100%)",
                  textTransform: "none",
                  fontFamily: "Poppins",
                  fontWeight: "500",
                  fontSize: "18px",
                  lineHeight: "27px",
                  fontStyle: "normal",
                  marginBottom: "10px",
                  px: "20px",
                  color: "#FFF",
                  "&:hover": {
                    background:
                      "linear-gradient(97.85deg, #FFAF2A 0.95%, #FFC666 100%)",
                    boxShadow: "0px 9px 12px rgba(242, 178, 73, 0.59)",
                  },
                }}
                onClick={() => {
                  navigate("/login");
                }}
              >
                Login
              </Button> */}

              <Typography
                sx={{ marginRight: "20px", fontFamily: "Product Sans" }}
              >
                Already have an account?{" "}
                <span
                  onClick={() => {
                    navigate("/login");
                  }}
                  style={{ color: "#0085FF", cursor: "pointer" }}
                >
                  Login
                </span>{" "}
              </Typography>
            </Stack>
          </Stack>

          {stepsno == 0 && (
            <EmailPassNo
              stepsno={stepsno}
              setStepsno={setStepsno}
              cp_id={params.cp_id}
              selectedValue={selectedValue}
              signupdata={signupdata}
              setSignupdata={setSignupdata}
              setSelectedValue={setSelectedValue}
            />
          )}
          {stepsno == 1 && (
            <Otp
              stepsno={stepsno}
              setStepsno={setStepsno}
              signupdata={signupdata}
              setSignupdata={setSignupdata}
              selectedValue={selectedValue}
            />
          )}
          {/* {stepsno == 2 && (
            <Organization stepsno={stepsno} setStepsno={setStepsno} signupdata={signupdata}
              setSignupdata={setSignupdata}/>
          )} */}
          {stepsno == 2 && (
            <PasswordCreate
              stepsno={stepsno}
              setStepsno={setStepsno}
              signupdata={signupdata}
              setSignupdata={setSignupdata}
              selectedValue={selectedValue}
            />
          )}
          {stepsno == 3 && <CompleteBuilderSignup />}
        </Stack>
      </Box>
      <Support open={open} setOpen={setOpen}></Support>
    </>
  );
};
