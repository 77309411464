import {
  Box,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@mui/material";
import { useState } from "react";

// const style = `
// .normalField {
//   flex-direction: row;
//   align-items: center;
//   gap: 20px;
// }

// @media only screen and (max-width: 770px) {
//     .normalField {
//         flex-direction: column;
//         align-items: flex-start;
//         gap: 10px;
//   }
//   .normalField p{
//     margin: 0%;
//     font-size: 14px;
//     width: 100%;
//   }
// }

// `;

export const GetItem = (props: any) => {
  // //console.log("get Item props", props);
  if (props == null) {
    return <></>;
  }
  switch (props.type) {
    case "normal":
      let name = props.name;
      return (
        <>
          <Box
            sx={{
              display: "flex",
              my: 3,
              flexDirection: { xs: "column", md: "row" },
              alignItems: "center",
            }}
          >
            <Typography
              sx={{
                fontFamily: "Product Sans",
                my: { xs: 0.75, md: 2 },
                fontStyle: "normal",
                fontWeight: "500",
                fontSize: { xs: "16px", md: "22px" },
                textSizeAdjust: "auto",
                lineHeight: "1.2em",
                color: "#84828C",
                marginRight: { md: "20px" },
                width: { xs: "100%", md: "35%" },
              }}
            >
              {name}
            </Typography>
            <TextField
              disabled={!props.editMode}
              value={props.value}
              onChange={(e) => {
                props.setCurrentValue(e.target.value);
              }}
              sx={{ width: { xs: "100%", md: "50%" } }}
            ></TextField>
          </Box>
        </>
      );
    case "withtrailing":
      return (
        <Box
          sx={{
            display: "flex",
            my: 3,
            alignItems: "center",
            flexDirection: { xs: "column", md: "row" },
          }}
        >
          <Typography
            sx={{
              fontFamily: "Product Sans",
              my: { xs: 0.75, md: 2 },
              fontStyle: "normal",
              fontWeight: "500",
              fontSize: { xs: "16px", md: "22px" },
              lineHeight: "30px",
              color: "#84828C",
              marginRight: { md: "20px" },
              width: { xs: "100%", md: "35%" },
            }}
          >
            {props.name}
          </Typography>
          <Box
            sx={{
              display: "flex",
              gap: "0.5rem",
              alignItems: "center",
              width: { xs: "100%", md: "50%" },
            }}
          >
            <TextField
              value={props.value}
              disabled={!props.editMode}
              onChange={(e) => {
                props.setCurrentValue(e.target.value);
              }}
              sx={{ width: "100%" }}
            ></TextField>
            <Typography
              sx={{
                fontFamily: "Product Sans",
              }}
            >
              {props.trailing}
            </Typography>
          </Box>
        </Box>
      );
    case "radio":
      return (
        <Box
          sx={{
            display: "flex",
            my: 3,
            flexDirection: { xs: "column", md: "row" },
            alignItems: { md: "center" },
          }}
        >
          <Typography
            sx={{
              fontFamily: "Product Sans",
              my: { xs: 0.75, md: 2 },
              fontStyle: "normal",
              fontWeight: "500",
              fontSize: { xs: "16px", md: "22px" },
              lineHeight: "30px",
              color: "#84828C",
              marginRight: "20px",
              width: { xs: "100%", md: "35%" },
            }}
          >
            {props.name}
          </Typography>
          <Box sx={{ width: { md: "50%" } }}>
            <RadioGroup
              sx={{
                display: "flex",
                flexDirection:
                  props.orientation == "vertical" ? "column" : "row",
              }}
              value={props.value}
              onChange={(e) => {
                props.setCurrentValue(e.target.value);
                // console.log(e.target.value);
              }}
            >
              {props.options.names.map((e: any) => {
                return (
                  <div>
                    <FormControlLabel
                      value={e}
                      control={
                        <Radio
                          disabled={!props.editMode}
                          checked={props.options.selected == e}
                        />
                      }
                      label={e}
                    />
                  </div>
                );
              })}
            </RadioGroup>
          </Box>
        </Box>
      );
    case "check":
      return (
        <Box
          sx={{
            display: "flex",
            my: 3,
            flexDirection: { xs: "column", md: "row" },
            alignItems: { md: "center" },
          }}
        >
          <Typography
            sx={{
              fontFamily: "Product Sans",
              my: { xs: 0.75, md: 2 },
              fontStyle: "normal",
              fontWeight: "500",
              fontSize: { xs: "16px", md: "22px" },
              lineHeight: "30px",
              color: "#84828C",
              marginRight: "20px",
              width: { xs: "100%", md: "35%" },
            }}
          >
            {props.name}
          </Typography>
          {
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                flexWrap: "wrap",
              }}
            >
              {props.options.map((e: any, index: number) => {
                return (
                  <FormControlLabel
                    control={
                      <Checkbox
                        disabled={!props.editMode}
                        onChange={(_) => {
                          // console.log(e);
                          props.setCurrentValue(index);
                        }}
                        checked={e.selected}
                      />
                    }
                    label={e.name}
                  />
                );
              })}
            </Box>
          }
        </Box>
      );

    case "doubletrailing":
      return (
        <Box
          sx={{
            display: "flex",
            my: 3,
            flexDirection: { xs: "column", md: "row" },
            alignItems: { md: "center" },
          }}
        >
          <Typography
            sx={{
              fontFamily: "Product Sans",
              my: { xs: 0.75, md: 2 },
              fontStyle: "normal",
              fontWeight: "500",
              fontSize: { xs: "16px", md: "22px" },
              lineHeight: "30px",
              color: "#84828C",
              marginRight: "20px",
              width: { xs: "100%", md: "35%" },
            }}
          >
            {props.name}
          </Typography>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "1rem",
            }}
          >
            <TextField
              value={props.value1}
              disabled={!props.editMode}
              onChange={(e) => {
                props.setCurrentValue({ value: e.target.value, index: 1 });
              }}
            ></TextField>
            <Typography sx={{ fontFamily: "Product Sans" }}>
              {props.trailing[0]}
            </Typography>
            <TextField
              value={props.value2}
              disabled={!props.editMode}
              onChange={(e) => {
                props.setCurrentValue({ value: e.target.value, index: 2 });
              }}
            ></TextField>
            <Typography sx={{ fontFamily: "Product Sans" }}>
              {props.trailing[1]}
            </Typography>
          </Box>
        </Box>
      );

    default:
      return <div></div>;
  }
};
