import {
  Box,
  Button,
  Container,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { AuthService } from "../../../context/buyer/services/auth";
import { net } from "../../../utils/consts";

const CommonPagesFooterForm = () => {

  const [name,setName] = useState();
  const [email,setEmail] = useState();
  const [phone,setPhone] = useState();
  const [msg,setMsg] = useState();

  const submitMessage=async(e)=>{
    e.preventDefault();
    try{
      const Auth = new AuthService();
      let obj={
        name:name,
        email:email,
        phone:phone,
        message:msg
      }
      console.log(obj);
      const res=await Auth.makeUnAuthenticatedPostRequest(
        net("send-enquiry-to-Admin"),
          {
          JsonBody: obj
        }
      );
      console.log(res?.status);
      if(res?.status==200){
        setName("");
        setPhone("");
        setEmail("");
        setMsg("");
      }
    }catch(err){
      console.log(err.message);
    }
  }

  return (
    <Box sx={{ overflowX: "hidden", py: "30px" }}>
      <Container>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            flexDirection: { xs: "column", md: "row" },
            gap: "30px",
          }}
        >
          <Stack
            spacing={3}
            sx={{
              width: { xs: "100%", md: "50%", md2: "60%" },
              textAlign: { xs: "center", md: "left" },
            }}
          >
            <Typography
              data-aos="fade-down"
              sx={{
                fontSize: { xs: "30px", md2: "40px" },
                fontWeight: 700,
                color: "#272D4E",
                fontFamily: "Circular Std",
                lineHeight: "1.2",
                display: { xs: "block", md: "none" },
              }}
            >
              Ready to unleash your <br /> business potential?
            </Typography>
            <Typography
              data-aos="fade-down"   
              sx={{
                fontSize: { xs: "30px", lg: "40px" },
                fontWeight: 700,
                color: "#272D4E",
                fontFamily: "Circular Std",
                lineHeight: "1.2",
                display: { xs: "none", md: "block" },
              }}
            >
              Ready to unleash your business potential?
            </Typography>
            <Typography
              data-aos="fade-up"
              sx={{
                fontSize: "18px",
                color: "#343434",
                fontFamily: "Product Sans",
              }}
            >
              Send us a message to book a demo, or if you have any questions. We
              will get back to you soon!
            </Typography>
            <Box>
              <Typography>
                ✔ Get an in-depth tour of the entire platform
              </Typography>
              <Typography>
                ✔ See how you can leverage our features for your workflows
              </Typography>
              <Typography>
                ✔ Discover how you can benefit from the EnvisionNext ecosystem
              </Typography>
              <Typography>
                ✔ Get answers to all your queries, one-on-one
              </Typography>
            </Box>
          </Stack>
          <Box
            data-aos="fade-zoom-in"
            data-aos-easing="ease-in-back"
            data-aos-delay="100"
            data-aos-offset="380"
            sx={{
              width: { xs: "80%", md: "50%", md2: "40%" },
              padding: { xs: "20px", md: "30px", lg: "60px" },
              background: "#FFFFFF",
              boxShadow: "0px 4px 28px rgba(0, 0, 0, 0.05)",
              borderRadius: "8px",
            }}
          >
            <form onSubmit={submitMessage}>
              <Stack spacing={4} sx={{}}>
                <Typography
                  sx={{
                    fontSize: { xs: "28px", md: "32px" },
                    fontWeight: 700,
                    fontFamily: "Product Sans",
                  }}
                >
                  Send us your message
                </Typography>
                <Box>
                  <Typography
                    sx={{
                      fontSize: "18px",
                      color: "#637381",
                      fontFamily: "Product Sans",
                    }}
                  >
                    Full Name*
                  </Typography>
                  <TextField
                    id="standard-size-small"
                    variant="standard"
                    placeholder="Type your name..."
                    required
                    value={name}
                    onChange={(e)=>setName(e.target.value)}
                    sx={{
                      width: "100%",
                      fontSize: "15px",
                      color: "#637381",
                      fontFamily: "Product Sans",
                    }}
                  />
                </Box>
                <Box>
                  <Typography
                    sx={{
                      fontSize: "18px",
                      color: "#637381",
                      fontFamily: "Product Sans",
                    }}
                  >
                    Email*
                  </Typography>
                  <TextField
                    id="standard-size-small"
                    variant="standard"
                    placeholder="Type your email..."
                    required
                    type="email"
                    value={email}
                    onChange={(e)=>setEmail(e.target.value)}
                    sx={{
                      width: "100%",
                      fontSize: "15px",
                      color: "#637381",
                      fontFamily: "Product Sans",
                    }}
                  />
                </Box>
                <Box>
                  <Typography
                    sx={{
                      fontSize: "18px",
                      color: "#637381",
                      fontFamily: "Product Sans",
                    }}
                  >
                    Phone*
                  </Typography>
                  <TextField
                    id="standard-size-small"
                    variant="standard"
                    placeholder="+91 873975678"
                    type="number"
                    value={phone}
                    required
                    onChange={(e) => {
                      if (e.target.value.length > 10) {
                        return;
                      }
                      setPhone(e.target.value);
                    }}
                    sx={{
                      width: "100%",
                      fontSize: "15px",
                      color: "#637381",
                      fontFamily: "Product Sans",
                    }}
                  />
                </Box>
                <Box>
                  <Typography
                    sx={{
                      fontSize: "18px",
                      color: "#637381",
                      fontFamily: "Product Sans",
                    }}
                  >
                    Message*
                  </Typography>
                  <TextField
                    id="standard-size-small"
                    variant="standard"
                    placeholder="Type your message here..."
                    value={msg}
                    required
                    onChange={(e)=>{setMsg(e.target.value)}}
                    sx={{
                      width: "100%",
                      fontSize: "15px",
                      color: "#637381",
                      fontFamily: "Product Sans",
                    }}
                  />
                </Box>
                <Box>
                  <Button
                    sx={{
                      background:
                        "linear-gradient(97.85deg, #FFAF2A 0.95%, #FFC666 100%)",
                      fontWeight: 500,
                      fontSize: { lg: "28px", xs: "16px" },
                      boxShadow: "rgba(242, 178, 73, 0.59) 0px 9px 12px",
                      borderRadius: "10px",
                      color: "#06386B",
                      textTransform: "none",
                      padding: "10px 30px",
                      fontFamily: "Product Sans",
                      mt: "20px",
                      "&:hover": {
                        background:
                          "linear-gradient(97.85deg, #FFAF2A 0.95%, #FFC666 100%)",
                        boxShadow: "rgba(242, 178, 73, 0.59) 10px 12px 16px",
                      },
                    }}
                    type="submit"
                  >
                    Submit
                  </Button>
                </Box>
              </Stack>
            </form>
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

export default CommonPagesFooterForm;
