import { TextField, Typography } from "@mui/material"
import { Stack } from "@mui/system"
import { SuperAdminNavbar } from "./SuperAdminNavbar"
import { SuperAdminSidebar } from "./SuperAdminSidebar"

/// for modal otp confirmation 
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';


//otp component

import OTPInput, { ResendOTP } from "otp-input-react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Apiaddress } from "../../../utils/consts";
import axios from "axios";
import { SAProjectSidebar } from "./SAProjectSidebar";

export const BasicStructurSA = ()=>{

    //for modal  confirm otp
    const [open, setOpen] = useState(false);
    const handleOpen = () => {
      setOpen(true);
    };
    const handleClose = () => {
      setOpen(false);
    };

    ///for otp 
    const [email, setEmail] = useState("")
    const [EmailOTP, setEmailOTP] = useState("");
    const navigate = useNavigate();

    //// get otp function ;
    const getOtp = async()=>{
      const url = Apiaddress+"/api/auth/accessbuilder/"
      let data = {
        email:email//"builder@demo.com"
      }

      const res = await axios.put(url,data);
      console.log("send OTP=======>",res.data);

    }
    ///verify otp function
    const vrifyOTP = async()=>{
      const url =  Apiaddress+"/api/auth/accessbuilder/"
      let data = {
        email:email,//"builder@demo.com",
        otp:EmailOTP
      }

      const res = await axios.post(url,data);
      console.log("Verify OTP========>",res.data)
      
      const url1 = Apiaddress+"/api/profile/"
      const re = await axios.get(url1,
        {
          headers: {
            Authorization: "JWT " + res?.data?.token,
          }
        }
      );
      console.log("builder Profile details", re.data)
      //store builder details to local storage 
      const content = {
        builder_token:res?.data?.token,
        builder_Details:re.data.user_obj
      }
      localStorage.setItem("SABuilderDetails", JSON.stringify(content))
      navigate("/super-admin-create-project-profile");
    }

    return (
        <>
     

        {/* sidebar for super admin for project creation */}
        <Stack direction="row" sx={{width:"100%",height:"auto" }}>
      {/* 1st stack */}
      <Stack sx={{minHeight:"100vh",width:"22%",boxSizing:"border-box",}}> {/*for project directory 20% and for sales dashboard 22%*/}
        <SAProjectSidebar active={""}/>
      </Stack>

     {/* 2nd stack */}
     <Stack direction="column" sx={{ backgroundColor:"#f9f9f9",width:"78%", padding:"25px",boxSizing:"border-box",}}  >  {/*  width for main sidebar 78px and for 80px*/}
      <SuperAdminNavbar handleOpen={handleOpen}  showAddProjectbtn={true}/>  {/*send props for get popup to verify otp from builder */}
      


      <Typography sx={{marginTop:"20px", fontSize:"28px", fontWeight:"600", marginLeft:"10px", marginBottom:"50px"}}>
           Content Title 
            </Typography>
     {/* actual content  */}
    <Stack sx={{backgroundColor:"#f9f9f9"}}>  {/* mt:"28px", */}

   

    </Stack>
      </Stack>
       </Stack>

{/* verify builder otp */}
    <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
        <Box sx={{
             position: 'absolute',
             top: '50%',
             left: '50%',
             transform: 'translate(-50%, -50%)',
             width: 400,
             bgcolor: 'background.paper',
             border: '2px solid white',
             borderRadius:"10px",
             boxShadow: 24,
             pt: 2,
             px: 4,
             pb: 3,
              width: 400 
              }}>
                  <Typography sx={{fontSize:"18px", fontWeight:"500"}}>Email</Typography>
                   <Typography sx={{fontSize:"14px", fontWeight:"400", color:"gray"}}>An OTP will be send to this e-mail</Typography>
                <Stack direction="row" justifyContent="space-between" alignItems="center">
                  <TextField 
                  type="email"
                  value={email}
                  onChange={(e)=>{
                    setEmail(e.target.value)
                  }}
                  />
                  <Button className="univercel_btn_class" sx={{textTransform:"none"}} 
                  onClick={getOtp}
                  >Get OTP</Button>
                </Stack>
                <Typography sx={{fontSize:"18px", fontWeight:"500", mt:"10px"}}>Enter OTP</Typography>
                <OTPInput inputStyles={{border:"1px solid gray",borderRadius:"8px",boxShadow: "0px 2px 6px rgba(19, 18, 66, 0.07)", width:"50px",height:"50px",margin:"2px"}} value={EmailOTP} onChange={setEmailOTP} OTPLength={6} otpType="number" disabled={false}  /> 
                <Button className="univercel_btn_class" 
                sx={{textTransform:"none", mt:"30px", padding:"10px 30px 10px 30px"}}
                onClick={()=>{
                  vrifyOTP();
                }}
                >Submit</Button>
        </Box>
      </Modal>


        </>
    )
}




 //  {/* sidebar for super admin normal */}

// <Stack direction="row" sx={{width:"100%",height:"auto" }}>
//       {/* 1st stack */}
//       <Stack sx={{minHeight:"100vh",width:"20%",boxSizing:"border-box",}}> {/*for project directory 20% and for sales dashboard 225*/}
//         <SuperAdminSidebar active={"Builder Directory"}/>
//       </Stack>

//      {/* 2nd stack */}
//      <Stack direction="column" sx={{ backgroundColor:"#f9f9f9",width:"80%", padding:"25px",boxSizing:"border-box",}}  >  {/*  width for main sidebar 78px and for 80px*/}
//       <SuperAdminNavbar handleOpen={handleOpen}  showAddProjectbtn={true}/>  {/*send props for get popup to verify otp from builder */}
      


//       <Typography sx={{marginTop:"20px", fontSize:"28px", fontWeight:"600", marginLeft:"10px", marginBottom:"50px"}}>
//            Content Title 
//             </Typography>
//      {/* actual content  */}
//     <Stack sx={{backgroundColor:"#f9f9f9"}}>  {/* mt:"28px", */}

   

//     </Stack>
//       </Stack>
// </Stack>