import React, { useEffect, useState } from 'react'
import { AuthService } from '../../../context/buyer/services/auth';
import { api } from '../../../utils/consts';
import SideBar from '../Components/sideBar/SideBar'
import LDmainPage from './LeadsDirectory/LDmainPage'
import { CPUnit_Directory } from './UnitDirectory/Unit_Directory_w_sb';

const CPInventory = (props: any) => {
  let builderSelected = JSON.parse(localStorage.getItem("BuilderSelected") ?? '{}');

  const [projectsDetails, setprojectsDetails] = useState()

  useEffect(() => {

    const x = async () => {
      const Auth = new AuthService()
      const res = await Auth.makeUnAuthenticatedGetRequest(api("/api/projects/unitsofproject/?project_id=" + builderSelected.selectedProjectID))
      console.log("INNNNNNNNNNNNN", await res.json())
    }
    x();

  }, [])


  return (
    <SideBar active={"Inventory"}>
      {!props.details &&
        <LDmainPage></LDmainPage>}
      {props.details && <div>
        <CPUnit_Directory projectid={builderSelected.selectedProjectID} />
      </div>}
    </SideBar>
  )
}

export default CPInventory