import axios from "axios";
import { Apiaddress, basicheaders } from "../../utils/consts";

const patchProject = async ( 
    project_id:number,
    data:any
) => {
    const url = Apiaddress+"/api/projects/project/"
    const re =  await axios.patch(
        url,
        {
            headers:basicheaders,
            "project_id":project_id,
            ...data,
        }    
    )
    console.log("patched project data>", re.data)

    return {"project":re.data};

};

export {patchProject};