import { Box, Button, Stack, Typography } from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import SideBar from "../../Components/sideBar/SideBar";
import AssignProject from "./AssignProject";
import AssignProjectSelf from "./AssignProjectSelf";
import { Apiaddress, net } from "../../../../utils/consts";
import { useParams } from "react-router-dom";
import AssignStandAloneUnit from "./AssignStandAloneUnit";
import NavigateBack from "../../../CommonPages/Shared/NavigateBack";

function AssignProjectMainPage() {
  let lead_id = useParams().id;
  console.log(lead_id);

  const [curPage, setCurPage] = useState(2);
  const [selectedProject, setSelectedProject] = useState([]);
  const [lead, setLead] = useState();
  const [Builder, setBuilder] = useState([]);
  const [self, setSelf] = useState([]);
  const [standAlone, setStandAlone] = useState([]);
  const [trigger, setTrigger] = useState(false);

  console.log(Builder);
  console.log(self);
  console.log(standAlone);

  const allocateProject = async () => {
    try {
      if (curPage == 2) {
        let temp = selectedProject;
        for (let i = 0; i < self?.length; i++) {
          temp.push(self[i]);
        }
        let obj = {
          lead_id: lead_id,
          cpProjects: temp,
        };
        console.log(temp);
        const res = await axios.patch(Apiaddress + "/api/leads/lead/", obj);
        console.log(res.data);
        setSelectedProject([]);
      } else if (curPage == 3) {
        let temp = selectedProject;
        for (let i = 0; i < standAlone?.length; i++) {
          temp.push(standAlone[i]);
        }
        let obj = {
          lead_id: lead_id,
          cpStandaloneUnits: temp,
        };
        const res = await axios.patch(Apiaddress + "/api/leads/lead/", obj);
        console.log(res.data);
        setSelectedProject([]);
      }
      setTrigger(!trigger);
    } catch (err) {
      console.log(err);
    }
  };

  const fetchLeadDetails = async () => {
    try {
      const res = await axios.get(
        Apiaddress + `/api/leads/lead/?lead_id=${lead_id}`
      );
      setLead(res?.data?.lead[0]);
      setBuilder(res?.data?.lead[0].projects);
      setSelf(res?.data?.lead[0].cpProjects);
      setStandAlone(res?.data?.lead[0].cpStandaloneUnits);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    fetchLeadDetails();
  }, [trigger]);

  return (
    <div>
      <SideBar active={"Leads"}>
        <Stack sx={{ mt: "0px", backgroundColor: "#f9f9f9" }}>

        <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              sx={{ marginBottom: "20px" }}
            >
              <Box>
              <Stack direction="row">
                  <Box sx={{ pt: "5px" }}>
                    <NavigateBack fontSize="22px" />
                  </Box>
                  <Typography
                    sx={{
                      fontSize: "22px",
                      fontWeight: "600",
                      color: "black",
                    }}
                    >
                    Leads Directory
                  </Typography>
                </Stack>
                <Typography
                  sx={{
                    color: "#B9B7D0",
                    fontSize: "16px",
                    fontWeight: "400",
                  }}
                >
                  Manage your Leads
                </Typography>
              </Box>

            </Stack>

          {/* ================Mark Priority======================= */}
          {/* <Box>
            {selectedProject.length != 0 && (
              <Stack direction="row" sx={{ margin: "20px" }}>
                {
                  <Button
                    onClick={() => {
                      allocateProject();
                    }}
                    className="univercel_btn_class"
                    sx={{
                      color: "#FFFFFF",
                      textTransform: "none",
                      width: "150px",
                      marginLeft: "30px",
                      background: "#008dff",
                      boxShadow:
                        "0px 13.7981px 22.5788px rgba(50, 174, 137, 0.25)",
                      "&:hover": { background: "#008dff" },
                    }}
                  >
                    Allocate
                  </Button>
                }
              </Stack>
            )}
          </Box> */}

          <Box
            sx={{
              mt: "20px",
              borderBottom: "3px solid #e4e4e4",
              display: "flex",
              gap: "50px",
            }}
          >
            {/* <Box
                  onClick={() => setCurPage(1)}
                  sx={{
                    background: curPage==1?"white":"#E0E0E0",
                    display: "grid",
                    justifyContent: "center",
                    alignItems:"center",
                    width: "70%",
                    height:"49px",
                    borderRadius:"8px 8px 0px 0px",
                    display:'flex',
                    cursor:"pointer",
                    color:"#000000",
                    fontFamily:'Product Sans',
                    lineHeight:"20px",
                    fontWeight:"400",
                    fontSize:"16px",
                    border: curPage==1?"1px solid #0F5DAE":"1px solid gray"
                  }}
                  variant="text"
                >
                <Typography sx={{fontFamily:'Product Sans',lineHeight:"20px",fontWeight:"400",fontSize:"16px",color:"#000000"}}>Builder Invited Projects</Typography>
                </Box> */}
            <Box
              onClick={() => {
                setCurPage(2);
                setSelectedProject([]);
              }}
              sx={{
                padding: "10px 0px",
                fontFamily: "Circular Std",
                fontWeight: "400",
                color: curPage == 2 ? "#2979F2" : "#A4A4AD",
                fontSize: curPage == 2 ? "20px" : "18px",
                borderBottom: curPage == 2 ? "3px solid #2979F2" : "",
                cursor: "pointer",
                marginBottom: "-2px",
                borderRadius: "3px",
              }}
            >
              Self Projects
            </Box>
            <Box
              onClick={() => {
                setCurPage(3);
                setSelectedProject([]);
              }}
              sx={{
                padding: "10px 0px",
                fontFamily: "Circular Std",
                fontWeight: "400",
                color: curPage == 3 ? "#2979F2" : "#A4A4AD",
                fontSize: curPage == 3 ? "20px" : "18px",
                borderBottom: curPage == 3 ? "3px solid #2979F2" : "",
                cursor: "pointer",
                marginBottom: "-2px",
                borderRadius: "3px",
              }}
            >
              Stand Alone Units
            </Box>
          </Box>

          <Box
            sx={{
              width: "100%",
              margin: "0px 20px 25px 0px",
              height: "530px",
            }}
          >
            {/* {curPage === 1 && (
                  <AssignProject/> 
                )} */}
            {curPage === 2 && (
              <AssignProjectSelf
                selectedProject={selectedProject}
                setSelectedProject={setSelectedProject}
                self={self}
                trigger={trigger}
                setTrigger={setTrigger}
                allocateProject={allocateProject}
                lead_id={lead_id}
                ToClient={false}
              />
            )}
            {curPage === 3 && (
              <AssignStandAloneUnit
                selectedProject={selectedProject}
                setSelectedProject={setSelectedProject}
                self={standAlone}
                trigger={trigger}
                setTrigger={setTrigger}
                allocateProject={allocateProject}
              />
            )}
          </Box>
        </Stack>
      </SideBar>
    </div>
  );
}

export default AssignProjectMainPage;

{
  /* import { Box, Button, Stack, Typography } from '@mui/material'
import axios from 'axios';
import React, { useEffect, useState } from 'react'
import SideBar from '../../Components/sideBar/SideBar'
import AssignProject from './AssignProject';
import AssignProjectSelf from './AssignProjectSelf';
import { Apiaddress, net } from "../../../../utils/consts";
import { useParams } from 'react-router-dom';
import AssignStandAloneUnit from './AssignStandAloneUnit';

function AssignProjectMainPage() {

  let lead_id=useParams().id;
  console.log(lead_id);

  const [curPage, setCurPage] = useState(2);
  const [selectedProject,setSelectedProject] = useState([]);
  const [lead,setLead] = useState();
  const [Builder,setBuilder] = useState([]);
  const [self,setSelf]= useState([]);
  const [standAlone,setStandAlone]=useState([]); 
  const [trigger,setTrigger]=useState(false);

  console.log(Builder);
  console.log(self);
  console.log(standAlone);

  const allocateProject=async ()=>{
    try{
      if(curPage==2){

        let temp=selectedProject;
        for(let i=0;i<self?.length;i++){
          temp.push(self[i]);
        }
        let obj={
          lead_id:lead_id,
          cpProjects:temp
        }
        console.log(temp);
        const res=await axios.patch(Apiaddress + "/api/leads/lead/",obj);
        console.log(res.data);
        setSelectedProject([]);
      }
      else if(curPage==3){

        let temp=selectedProject;
        for(let i=0;i<standAlone?.length;i++){
          temp.push(standAlone[i]);
        }
        let obj={
          lead_id:lead_id,
          cpStandaloneUnits:temp
        }
        const res=await axios.patch(Apiaddress + "/api/leads/lead/",obj);
        console.log(res.data);
        setSelectedProject([]);
      }
      setTrigger(!trigger);
    }
    catch(err){
      console.log(err);
    }
  }

  const fetchLeadDetails=async ()=>{
    try{
      const res=await axios.get(Apiaddress + `/api/leads/lead/?lead_id=${lead_id}`);
      setLead(res?.data?.lead[0]);
      setBuilder(res?.data?.lead[0].projects);
      setSelf(res?.data?.lead[0].cpProjects);
      setStandAlone(res?.data?.lead[0].cpStandaloneUnits);
    }
    catch(err){
      console.log(err);
    }
  }

  useEffect(()=>{
    fetchLeadDetails();
  },[trigger])
  
  return (
    <div>
         <SideBar active={"Leads"}>
            <Stack sx={{ mt:"-20px",backgroundColor:"#f9f9f9"}}>

            {/* ================Mark Priority======================= /}
            <Box>
              {selectedProject.length != 0 && (
                <Stack direction="row" sx={{ margin: "20px" }}>
                  {
                    <Button
                      onClick={() => {
                        allocateProject();
                      }}
                      className="univercel_btn_class"
                      sx={{
                        color: "#FFFFFF",
                        textTransform: "none",
                        width: "150px",
                        marginLeft:"30px",
                        background:"#008dff",
                        boxShadow:"0px 13.7981px 22.5788px rgba(50, 174, 137, 0.25)",
                        "&:hover":{background:"#008dff"}
                      }}>Allocate
                    </Button>
                  }
                </Stack>
              )}
            </Box>  
              
              <Box sx={{ width: "90%", margin: "0px auto" }}>
                <Stack direction="row" justifyContent="space-between" alignItems="center">
                  {/* <Box
                  onClick={() => setCurPage(1)}
                  sx={{
                    background: curPage==1?"white":"#E0E0E0",
                    display: "grid",
                    justifyContent: "center",
                    alignItems:"center",
                    width: "70%",
                    height:"49px",
                    borderRadius:"8px 8px 0px 0px",
                    display:'flex',
                    cursor:"pointer",
                    color:"#000000",
                    fontFamily:'Product Sans',
                    lineHeight:"20px",
                    fontWeight:"400",
                    fontSize:"16px",
                    border: curPage==1?"1px solid #0F5DAE":"1px solid gray"
                  }}
                  variant="text"
                >
                <Typography sx={{fontFamily:'Product Sans',lineHeight:"20px",fontWeight:"400",fontSize:"16px",color:"#000000"}}>Builder Invited Projects</Typography>
                </Box> /}
                <Box
                  onClick={() =>{setCurPage(2);setSelectedProject([])}}
                  sx={{  
                     background: curPage==2?"white":"#E0E0E0",
                    display: "grid",
                    justifyContent: "center",
                    alignItems:"center",
                    width: "70%",
                    height:"49px",
                    borderRadius:"8px 8px 0px 0px",
                    display:'flex',
                    cursor:"pointer",
                    color:"#000000",
                    fontFamily:'Product Sans',
                    lineHeight:"20px",
                    fontWeight:"400",
                    fontSize:"16px",
                    border: curPage==2?"1px solid #0F5DAE":"1px solid gray"
                  }}
                  variant="text"
                >
                <Typography sx={{fontFamily:'Product Sans',lineHeight:"20px",fontWeight:"400",fontSize:"16px",color:"#000000"}}>Self Projects</Typography>  
                </Box>
                <Box
                  onClick={() =>{setCurPage(3);setSelectedProject([])}}
                  sx={{  
                    background: curPage==3?"white":"#E0E0E0",
                    display: "grid",
                    justifyContent: "center",
                    alignItems:"center",
                    width: "70%",
                    height:"49px",
                    borderRadius:"8px 8px 0px 0px",
                    display:'flex',
                    cursor:"pointer",
                    color:"#000000",
                    fontFamily:'Product Sans',
                    lineHeight:"20px",
                    fontWeight:"400",
                    fontSize:"16px",
                    border: curPage==3?"1px solid #0F5DAE":"1px solid gray"
                  }}
                  variant="text"
                >
                <Typography sx={{fontFamily:'Product Sans',lineHeight:"20px",fontWeight:"400",fontSize:"16px",color:"#000000"}}>Stand Alone Units</Typography>  
                </Box>
                </Stack>
              </Box>
               
              <Box sx={{ color: "black", width: "90%", margin: "auto",border:"1px solid #2979F2",height:"630px",marginBottom:"70px"}}>
               {/* {curPage === 1 && (
                  <AssignProject/> 
                )} /}
                {curPage === 2 && (
                  <AssignProjectSelf
                  selectedProject={selectedProject}
                  setSelectedProject={setSelectedProject}
                  self={self}
                  trigger={trigger}
                  setTrigger={setTrigger}
                  /> 
                )}
                {curPage === 3 && (
                  <AssignStandAloneUnit
                  selectedProject={selectedProject}
                  setSelectedProject={setSelectedProject}
                  self={standAlone}
                  trigger={trigger}
                  setTrigger={setTrigger}
                  /> 
                )}
              </Box>

            </Stack>
         </SideBar>
    </div>  
  )
}

export default AssignProjectMainPage   */
}
