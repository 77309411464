import { Box, Stack } from "@mui/system";
import React, { useContext, useEffect, useState } from "react";
import profileContext from "../../../../context/profile/context";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { CitySelector, StateSelector } from "volkeno-react-country-state-city";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { countryDetails } from "../../../../utils/contryDetails";
import {
  Button,
  Checkbox,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  Modal,
  OutlinedInput,
  Select,
  TextField,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";

import AddIcon from "@mui/icons-material/Add";
import axios from "axios";
import { api, Apiaddress, net } from "../../../../utils/consts";
import { AuthService } from "../../../../context/buyer/services/auth";
import { Country, State, City }  from 'country-state-city';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function RightSidebar({
  statesetter,
  setTabledata,
  curselectedRows,
  orgID,
  toggleDrawer,
  updateData,
  setTrigger,
  trigger,
  makePriority,
  makeProject,
  setMakePriority,
  setMakeProject,
}) {
  const [location, setLocation] = useState({});

  const cpDetails=JSON.parse(localStorage.getItem('cpDetails'));

  console.log(cpDetails);

  let cpOrg=cpDetails?.channelPartnerOrg;

  useEffect(() => {
    setLocation(countryDetails);
  }, []);

  const [names, setNames] = useState([
    { name: "Aditya Birla Appartments", id: 2 },
    { name: "Tata Farms", id: 3 },
  ]);
  const [expanded, setExpanded] = useState(
    // curselectedRows ? "panel2" : "panel1"
    makePriority ? "panel2" : makeProject ? "panel3" : "panel1"
  );
  const [disabelAccrodian, setdisabelAccrodian] = useState(
    curselectedRows ? "true" : "false"
  );
  const [selectValue, setSelectValue] = useState("High");
  const [setProjects, setsetProjects] = React.useState([]);

  const [priority, setPriority] = useState(null);
  const handleChangeSelect = (event) => {
    setPriority(event.target.value);
  };

  const [choosenproject, setchoosenproject] = useState("");
  const handleChangeSelectpro = (event) => {
    setchoosenproject(event.target.value);
  };

  const [projectsSelected, setprojectsSelected] = useState(0);
  const [projectsSelectedID, setprojectsSelectedID] = useState(0);

  const addProjectID = (para) => {
    setprojectsSelectedID(para);
  };

  const addProjects = (e) => {
    setprojectsSelected(e.target.value);
  };
  const [selectArr, setselectArr] = useState([
    {
      value: "high",
      label: "high",
    },
    {
      value: "low",
      label: "low",
    },
  ]);

  const createOpt = () => {};

  const handleChange = (i) => {
    setExpanded(i);
  };

  const [colors, setColors] = useState([
    { name: "Ajay", clr: "green" },
    { name: " Tarun", clr: " yellow" },
    { name: "  Ranbir", clr: "red" },
  ]);
  const [leadname, setLeadName] = useState("");
  const [leadclr, setLeadclr] = useState("");

  const addClr = () => {
    const obj = { name: leadname, clr: leadclr };
    setColors([...colors, obj]);
    handleClose();
  };

  //state and city selector
  let States=State.getStatesOfCountry('IN');
  const [stateCode,setStateCode] = useState("");
  const [allCities,setAllCities] = useState();

  console.log(stateCode);

  useEffect(()=>{
    let Cities=City.getCitiesOfState('IN',stateCode);
    setAllCities(Cities);
  },[stateCode])

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [name, setName] = useState(
    curselectedRows && curselectedRows.Name ? curselectedRows.Name : ""
  );
  const [phone, setPhne] = useState("");
  const [Email, setEmail] = useState("");
  const [address, setAddresss] = useState("");
  const [image, setImage] = useState("");
  const [sendImage, setSendImage] = useState("");
  const [city, setCity] = useState();

  console.log(sendImage);

  const user = useContext(profileContext);
  const [userID, setUserID] = useState(null);

  useEffect(() => {
    console.log(user);
    // console.log(user.UserState);
    if (user)
      if (user.UserState)
        if (user.UserState.user_obj)
          if (user.UserState.user_obj.id) setUserID(user.UserState.user_obj.id);
  }, [user]);
  // console.log(userID);
  useEffect(() => {
    if (updateData) {
      setName(updateData.Name);
      setEmail(updateData.Email);
      setPhne(updateData.Phone);
      setPriority(updateData.priority);
      setAddresss(updateData.Address);
    }
  }, [updateData]);

  const clearItems = () => {
    setName(null);
    setEmail(null);
    setPhne(null);
    setPriority(null);
    setAddresss(null);
  };
  // console.log(curselectedRows);
  console.log(priority);
  const [slectedCPid, setselectedCPid] = useState(null);

  const saveChanges = async () => {
    console.log(curselectedRows);
    if (curselectedRows) {
      for (let i = 0; i < curselectedRows.length; i++) {
        let obj = {};
        if (makePriority) {
          obj = {
            lead_id: curselectedRows[i].lead_id,
            lead_prority: priority,
            org: orgID,
            assigned_to: userID ? [userID] : [7],
          };
        } else {
          obj = {
            lead_id: curselectedRows[i].lead_id,
            org: orgID,
            assigned_to: userID ? [userID] : [7],
            project: projectsSelectedID,
          };
        }

        console.log(obj);
        try {
          const res = await axios.patch(Apiaddress + "/api/leads/lead/", obj);
          console.log("eeeee", res.data);
          toggleDrawer(false);
          setTrigger(!trigger);
          setMakePriority(false);
          setMakeProject(false);
        } catch (err) {
          console.log(err);
        }
      }
    } else if (updateData) {
      const obj = {
        lead_id: updateData.lead_id,
        name: name,
        phone: phone,
        Email: Email,
        address_line: address,
        colors: colors,
        channel_partner: slectedCPid,
      };

      if (priority) {
        obj.lead_prority = priority;
      }
      if (projectsSelected) {
        obj.project = projectsSelectedID;
      }
      if (selectedCP) {
        obj.channel_partner = slectedCPid;
      }

      console.log(obj);

      try {
        const res = await axios.patch(Apiaddress + "/api/leads/lead/", obj);
        console.log(res.data);
        setName("");
        setEmail("");
        setAddresss("");
        setPhne("");
        toggleDrawer(false);
        setTrigger(!trigger);
        setMakePriority(false);
        setMakeProject(false);
      } catch (err) {
        console.log(err);
      }
    } else {
      const obj = {
        name: name,
        phone: phone,
        Email: Email,
        address: address,
        priority: priority,
        setProjects: setProjects,
        colors: colors,
        slectedCPid: slectedCPid,
      };

      console.log(obj);

      let objPost = {
        name: obj.name,
        phone: obj.phone,
        email: obj.Email,
        project: projectsSelectedID,
        lead_prority: obj.priority,
        org: orgID,
        assigned_to: [userID],
        channel_partner: obj.slectedCPid,
        address_line: obj.address,
      };
      console.log(objPost);
      try {
        const res = await axios.post(Apiaddress + "/api/leads/lead/", objPost);
        setName("");
        setEmail("");
        setAddresss("");
        setPhne("");
        console.log(res.data);
        setTabledata(obj);
        toggleDrawer(false);
        setTrigger(!trigger);
        setMakePriority(false);
        setMakeProject(false);
      } catch (err) {
        console.log(err);
      }
      setTrigger(!trigger);
    }
    // console.log(name, phone, Email, address, priority, setProjects);
  };

  const addprofiledata = () => {};

  const [data, setData] = useState(null);
  const [token, setToken] = useState(null);

  const getProjects = async () => {
    try {
      const headers = {
        Authorization: `JWT ${token}`,
      };
      const res = await axios.get(
        Apiaddress + "/api/projects/getprojectsusingtoken/",
        { headers }
      );
      // console.log(res.data.projects);
      if (res.data) if (res.data.projects) setData(res.data.projects);
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    if (token) getProjects();
  }, [token]);

  useEffect(() => {
    setToken(localStorage.getItem("crm_token"));
  }, []);
  const [newRowData, setNewRowData] = useState([]);
  useEffect(() => {
    if (data) {
      // console.log(data);
    }
  }, [data]);

  const [selectedCP, setselectedCP] = useState("");

  const addCP = (e) => {
    setselectedCP(e.target.value);
  };

  const addCPid = (para) => {
    setselectedCPid(para);
  };

  const [builderId, setbuilderId] = useState(null);

  useEffect(() => {
    // console.log(user);
    // console.log(user.UserState);
    if (user)
      if (user.UserState)
        if (user.UserState.user_obj)
          if (user.UserState.user_obj.orgList)
            if (user.UserState.user_obj.orgList[0]) {
              // console.log(user.UserState.user_obj.orgList[0]);
              // setOrgID(user.UserState.user_obj.orgList[0]);
            }
  }, [user]);

  const [cpdata, setcpdata] = useState(null);
  // const [trigger, setTrigger] = useState(false);
  useEffect(() => {
    // console.log(user);
    if (user) {
      if (user.UserState) {
        if (user.UserState.user_obj) {
          setbuilderId(user.UserState.user_obj.id);
        }
      }
    }
  }, [user, trigger]);

  const getCP = async () => {
    try {
      const res = await axios.get(
        Apiaddress + `/api/role/getcpusingbuilderid/?builder_id=${builderId}`
      );
      // console.log(res.data);
      if (res.data.channel_partner !== "channel_partner not found") {
        setcpdata(res.data.channel_partner);
      } else {
        setcpdata([]);
      }
      console.log(res.data.channel_partner);
    } catch (err) {
      console.log(err);
    }
  };

  const [datafromapi, setdatafromapi] = useState([]);

  useEffect(() => {
    if (cpdata) {
      // id: 1,
      // RERARegNo: "#4560",
      // Type: "26 March 2022, 12:42 AM",
      // Name: "Seema Agarwal",
      // Email: "67 St. pune",
      // Phone: "Agent",

      let arr = [];
      for (let i = 0; i < cpdata.length; i++) {
        const obj = {
          id: i + 1,
          CPid: cpdata[i]?.id,
          RERARegNo: cpdata[i]?.rera_no,
          Type: cpdata[i]?.profile.role_type,
          Name: cpdata[i]?.profile.user_details.first_name,
          Email: cpdata[i]?.profile.user_details.email,
          Phone: cpdata[i]?.profile.phone,
        };
        // console.log(obj);
        arr.push(obj);
      }
      setdatafromapi(arr);
    }
  }, [cpdata]);

  useEffect(() => {
    console.log(builderId);
    if (builderId) {
      getCP();
    }
  }, [builderId, trigger]);

  const [allpriority, setallpriority] = useState(null);

  const getpriority = async () => {
    try {
      const res = await axios.get(Apiaddress + "/api/leads/leadprority/");
      console.log(res.data.leadprority);
      setallpriority(res.data.leadprority);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getpriority();
  }, []);

  
  const [Pincode, setPincode] = useState();
  const [NoOfUnits, setNoOfUnits] = useState();
  const [phoneError,setPhoneError]=useState(false);
  const [imgError,setImgError] = useState(false);
  const [imgErrorText,setImgErrorText] = useState("");

  const createProject=async(e)=>{
    e.preventDefault();
    try{
      if(sendImage==""){
        setImgError(true);
        setImgErrorText("Please Upload Banner Image");
        return;
      }
      if (name == "" || Email == "" || city == "" ||Pincode == "" || phone == "" || sendImage == "")return;
      if(Pincode?.length<6){
        console.log(Pincode);
        setPhoneError(true);
        return;
      }
        const Auth = new AuthService();
        const headers = {
        headers: {
          "content-type": "multipart/form-data",
        },
        };
                      let data = {
                        name: name,
                        state: Email,
                        city: city,
                        pin_code: Pincode,
                        address_line: phone,
                        banner_image: sendImage,
                      };
                      console.log(data);
                      let url = Apiaddress + "/api/projects/project/";
                      let res = await axios.post(url, data, headers);

                      let url1="";
                      console.log(cpOrg);
                      if(cpOrg){
                        url1=`role/inventeryofcp/?orgId=${cpOrg}`
                      }
                      else{
                        url1=`role/inventeryofcp/`
                      }

                      await Auth.makeAuthenticatedPatchRequest(
                        net(url1),
                        {
                          JsonBody: {
                            project: [res.data.project.id],
                            unit: [],
                          },
                        }
                      );
                      setName("");
                      setPhne("");
                      setEmail("");
                      setAddresss("");
                      setCity("");
                      setPincode("");
                      setNoOfUnits("");
                      setTrigger(!trigger);
                      statesetter(false);
    }catch(err){
      console.log(err);
    }
  }

  return (
    <Box
      sx={{
        width: "420px",
        marginTop: "-25px",
        marginRight: "25px",
        padding: "10px",
      }}
    >
      <Box sx={{ position: "relative" }}>
        <Box
          sx={{
            position: "absolute",
            top: "0px",
            right: "0px",
            background: "white",
            padding: "10px",
            height: "105vh",
            width: "400px",
            marginTop: "20px",
          }}
        >
          <Box>
            <Stack
              direction="row"
              onClick={() => !statesetter()}
              sx={{
                display: "flex",
                alignItems: "center",
                gap: "10px",
                cursor: "pointer",
              }}
            >
              <ArrowBackIcon />
              <Typography
                sx={{
                  color: "#2979F2",
                  fontSize: "22px",
                  fontWeight: 600,
                  fontFamily: "Circular Std",
                }}
              >
                Add Projects
              </Typography>
            </Stack>
            
            <form onSubmit={createProject}>
            <Box sx={{ mt: "20px" }}>
              <Box>
                <Accordion
                  expanded={expanded === "panel1"}
                  onClick={() => {
                    if (!curselectedRows) {
                      handleChange("panel1");
                    }
                  }}
                  sx={{ boxShadow: "none" }}
                >
                  {/* <AccordionSummary
                    sx={{
                      background: `${
                        expanded === "panel1" ? "#FFAF2A" : "white"
                      }`,
                      borderRadius: "10px",
                    }}
                    // expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography
                      sx={{
                        color: "#FFF",
                        fontSize: "18px",
                        fontFamily: "Product Sans",
                      }}
                    >
                      Add Project
                    </Typography>
                  </AccordionSummary> */}

                  <Stack spacing={3}>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        width: "400px",

                        alignItems: "center",
                      }}
                    >
                      <Typography 
                        sx={{
                          color: "#84828c",

                          fontWeight: 500,
                          fontFamily: "Product Sans",
                        }}
                      >
                        Project Name
                      </Typography>
                      <Box>
                        <TextField required
                          sx={{ width: "225px" }}
                          value={name}
                          id="outlined-basic"
                          onChange={(e) => setName(e.target.value)}
                        />
                      </Box>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        width: "400px",

                        alignItems: "center",
                      }}
                    >
                      <Typography 
                        sx={{
                          color: "#84828c",
                          fontWeight: 500,
                          fontFamily: "Product Sans",
                        }}
                      >
                        Address
                      </Typography>
                      <Box>
                        <TextField required
                          value={phone}
                          id="outlined-basic"
                          onChange={(e) => setPhne(e.target.value)}  
                          sx={{ width: "225px" }}
                        />
                      </Box>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        width: "400px",  

                        alignItems: "center",
                      }}
                    >
                      <Typography
                        sx={{
                          color: "#84828c",

                          fontWeight: 500,
                          fontFamily: "Product Sans",
                        }}
                      >
                        State
                      </Typography>
                      <Box sx={{ width: "225px" }}>

                        <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">Select State</InputLabel>
                          <Select
                            required
                            label="Select State"
                            MenuProps={{ PaperProps: { sx: { maxHeight: 200,maxWidth: 250} } }}
                            onChange={(e)=>{
                              let temp = e.target.value.split("-");
                              console.log(temp);
                              setEmail(temp[0]);
                              setStateCode(temp[1]);
                            }}
                          >
                          {States &&
                            States?.map((option) => (
                              <MenuItem key={option.name+"-"+option.isoCode} value={option.name+"-"+option.isoCode}>
                                {option.name}
                              </MenuItem>
                          ))}
                          </Select>
                        </FormControl>

                      </Box>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        width: "400px",
                      }}
                    >
                      <Typography
                        sx={{
                          color: "#84828c",

                          fontWeight: 500,
                          fontFamily: "Product Sans",
                        }}
                      >
                        City
                      </Typography>
                      <Box sx={{ width: "225px" }}>

                      <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">Select City</InputLabel>
                        <Select
                            required
                            label="Select City"
                            MenuProps={{ PaperProps: { sx: { maxHeight: 200,maxWidth: 250} } }}
                            onChange={(e)=>{
                              let temp = e.target.value.split("-");
                              setCity(temp[0]);
                            }}
                          >
                            {allCities &&
                            allCities?.map((option) => (
                              <MenuItem key={option.name+"-"+option.isoCode} value={option.name}>
                                {option.name}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>

                        {/* <CitySelector
                          state={location.state}
                          name="city"
                          value={location.city}
                          statePlaceholder="Select a state first"
                          onChange={(e) => {
                            console.log(e);
                            setCity(e.name);
                            setLocation({ ...location, city: e });
                          }}
                        /> */}
                      </Box>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        width: "400px",  
                      }}
                    >
                      <Typography 
                        sx={{
                          color: "#84828c",

                          fontWeight: 500,
                          fontFamily: "Product Sans",
                        }}
                      >
                        Pincode
                      </Typography>
                      <Box>
                        <TextField required
                          value={Pincode}
                          type="number"
                          id="outlined-basic"
                          inputProps={{ minLength: 10, maxLength: 10 }}
                          onChange={(e) =>
                            e.target.value.length <= 6
                              ? setPincode(e.target.value)
                              : null
                          }
                          sx={{ width: "225px" }}
                        />
                      </Box>
                    </Box>
                    {phoneError && (
                          <Typography
                            sx={{
                              fontWeight: "500",
                              fontSize: "14px",
                              marginBottom: "5px",
                              color: "#da6061",
                            }}
                          >
                            Pincode Should be of 6 Characters
                          </Typography>
                        )}

                    <Box>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        {image == "" ? (
                          <Box
                            sx={{
                              width: "230px",
                              height: "180px",
                              border:
                                image === "" ? "3px dashed #D7D7D7" : "none",
                              borderRadius: "16px",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              flexDirection: "column",
                              gap: "5px",
                            }}
                          >
                            <Button
                              component="label"
                              sx={{
                                width: "80px",
                                height: "80px",
                                borderRadius: "50%",
                                border: "2px dashed #D7D7D7",
                                display: "flex",
                                justifyContent: "center",
                                "&:hover": {
                                  backgroundColor: "#FFF",
                                },
                              }}
                            >
                              <Box
                                sx={{
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                }}
                              >
                                <svg
                                  width="25"
                                  height="26"
                                  viewBox="0 0 25 26"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M3.27998 25.2535C2.42931 25.2535 1.70135 24.9509 1.09609 24.3456C0.489792 23.7393 0.186646 23.0108 0.186646 22.1602V17.5202H3.27998V22.1602H21.84V17.5202H24.9333V22.1602C24.9333 23.0108 24.6307 23.7393 24.0254 24.3456C23.4191 24.9509 22.6906 25.2535 21.84 25.2535H3.27998ZM11.0133 19.0668V6.4615L6.99198 10.4828L4.82665 8.24017L12.56 0.506836L20.2933 8.24017L18.128 10.4828L14.1066 6.4615V19.0668H11.0133Z"
                                    fill="#D9D9D9"
                                  />
                                </svg>
                              </Box>
                              <input
                                type="file"
                                accept="image/*"
                                hidden
                                onChange={(e) => {
                                  let fileSize =
                                    e.target.files[0].size / 1024 / 1024;
                                  if (fileSize > 5) {
                                    setImgErrorText("Image size is greater than 5 MB");
                                    setImgError(true);
                                    return;
                                  }
                                  let url = URL.createObjectURL(
                                    e.target.files[0]
                                  );
                                  console.log(e.target.files[0]);
                                  setImage(url);
                                  setSendImage(e.target.files[0]);
                                  setImgError(false);
                                }}
                              />
                            </Button>
                            <Typography sx={{ color: "#717579" }}>
                              Upload Project Photo
                            </Typography>
                          </Box>
                        ) : (
                          <Stack direction="column">
                            <Box
                              sx={{
                                width: "230px",
                                height: "180px",
                                border: "3px dashed #D7D7D7",
                                borderRadius: "16px",
                              }}
                            >
                              <img
                                src={image}
                                style={{
                                  width: "100%",
                                  height: "100%",
                                  borderRadius: "16px",
                                }}
                              />
                            </Box>
                            <Box
                              sx={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                marginTop: "10px",
                              }}
                            >
                              <Button
                                component="label"
                                sx={{
                                  color: "#B0AFAF",
                                  fontSize: "18px",
                                  fontWeight: 500,
                                  fontFamily: "Product Sans",
                                  cursor: "pointer",
                                  textTransform: "none",
                                }}
                              >
                                <input
                                  type="file"
                                  accept="image/*"
                                  hidden
                                  onChange={(e) => {
                                  let fileSize =
                                    e.target.files[0].size / 1024 / 1024;
                                  if (fileSize > 5) {
                                    setImgErrorText("Image size is greater than 5 MB");
                                    setImgError(true);
                                    return;
                                  }
                                  let url = URL.createObjectURL(
                                    e.target.files[0]
                                  );
                                  console.log(e.target.files[0]);
                                  setImage(url);
                                  setSendImage(e.target.files[0]);
                                  setImgError(false);
                                  }}
                                />
                                Edit
                              </Button>
                            </Box>
                          </Stack>
                        )}
                      </Box>
                      <Stack justifyContent="center" alignItems="center">
                      {imgError && (
                          <Typography
                            sx={{
                              fontWeight: "500",
                              fontSize: "14px",
                              marginTop: "15px",
                              color: "#da6061",
                            }}
                          >
                          {imgErrorText}
                          </Typography>
                        )}
                      </Stack>
                    </Box>

                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        width: "400px",
                      }}
                    >
                      <Box></Box>
                    </Box>
                  </Stack>
                </Accordion>

                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    flexDirection: "column",
                    alignItems: "center",
                    gap: "10px",
                  }}
                >
                  <Button
                    type="submit"
                    sx={{
                      padding: "10px 60px",
                      color: "#06386B",
                      textTransform: "none",
                      fontSize: "20px",
                      fontFamily: "Product Sans",
                      background:
                        "linear-gradient(97.85deg, #FFAF2A 0.95%, #FFC666 100%)",
                      "&:hover": {
                        boxShadow: "0px 9px 12px rgba(242, 178, 73, 0.59)",
                      },
                    }}
                  >
                    Add Project
                  </Button>
                  <Box
                    sx={{
                      textAlign: "center",
                      margin: "6px",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      // toggleDrawer(false)
                      setName("");
                      setPhne("");
                      setEmail("");
                      setAddresss("");
                      setCity("");
                      setPincode("");
                      setNoOfUnits("");
                      statesetter(false);
                    }}
                  >
                    <Typography
                      sx={{
                        color: "#B0AFAF",
                        fontWeight: 500,
                        fontFamily: "Product Sans",
                      }}
                    >
                      Cancel
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Box>
          </form>
            {/* <Box
              sx={{
                placeItems: "center",
                display: "grid",
                marginTop: "-100px",
              }}
            > */}
            {/* <Box>
                {!updateData && (
                  <Button
                    onClick={saveChanges}
                    className="univercel_btn_class"
                    sx={{
                      width: "200px",
                      color: "#FFFFFF",
                      textTransform: "none",

                      marginBottom: "20px",
                    }}
                  >
                    Add Lead  
                  </Button>
                )}
                {updateData && (
                  <Button
                    onClick={saveChanges}
                    className="univercel_btn_class"
                    sx={{
                      width: "200px",
                      color: "#FFFFFF",
                      textTransform: "none",

                      marginBottom: "20px",
                    }}
                  >
                    Update Lead
                  </Button>
                )}
              </Box> */}
            {/* </Box> */}
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export default RightSidebar;
