import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  MenuItem,
  Drawer,
  Box,
  Button,
  Checkbox,
  FormControl,
  InputLabel,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { Stack } from "@mui/system";
import TuneOutlinedIcon from "@mui/icons-material/TuneOutlined";
import KeyboardArrowRightOutlinedIcon from "@mui/icons-material/KeyboardArrowRightOutlined";
import KeyboardArrowUpOutlinedIcon from "@mui/icons-material/KeyboardArrowUpOutlined";
import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined";
import { CheckBox } from "@mui/icons-material";
import DriveFileRenameOutlineIcon from "@mui/icons-material/DriveFileRenameOutline";
import VisibilityIcon from "@mui/icons-material/Visibility";
import DeleteIcon from "@mui/icons-material/Delete";
import React, { useContext, useEffect, useState } from "react";
import { DataGrid, GridExpandMoreIcon } from "@mui/x-data-grid";
import CategoryIcon from "@mui/icons-material/Category";
import HandshakeIcon from "@mui/icons-material/Handshake";
import RightSidebarMD from "./RightSidebarBD";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import RightSidebarBD from "./RightSidebarBD";
import RightSidebarBDInvite from "./RightSidebarBDInvite";
import axios from "axios";
import { useTheme } from "@emotion/react";
import { basicheaders } from "../../../utils/consts";
import profileContext from "../../../context/profile/context";

import EditIcon from "@mui/icons-material/Edit";

import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import BDCustomTabel from "./CustomTable/BDCustomTabel";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Apiaddress } from "../../../utils/consts";
import { ProjectDashboardSidebar } from "../../BuilderComponents/ProjectDashboard/DashboardComponents/ProjectDashboardSidebar";
import { NavbarForDirectory } from "../../BuilderComponents/ProjectDashboard/DashboardComponents/NavbarForDirectory";

import builderDashboard from "../../../context/builder_dashboard/context";


export const Buyers_Directory_w_sb = ()=>{

  const OrganizationsContext = useContext(builderDashboard);
  console.log(OrganizationsContext);

  const [trigger, setTrigger] = useState(false);
  const [delIconClicked, setdelIconClicked] = useState(false);

  const [updateInfo, setupdateInfo] = useState(null);

  const updateBuyers = (item) => {
    setupdateInfo(item);
  };

  const rows = [
    {
      id: 1,
      FlatNo: "#4560",
      BookedDate: "26 March 2022, 12:42 AM",
      CustomerName: "Seema Agarwal",
      Location: "67 St. pune",
      TotalSpent: "Agent",
    },
    {
      id: 1,
      FlatNo: "#4560",
      BookedDate: "26 March 2022, 12:42 AM",
      CustomerName: "Seema Agarwal",
      Location: "67 St. pune",
      TotalSpent: "Agent",
    },
    {
      id: 1,
      FlatNo: "#4560",
      BookedDate: "26 March 2022, 12:42 AM",
      CustomerName: "Seema Agarwal",
      Location: "67 St. pune",
      TotalSpent: "Agent",
    },
    {
      id: 1,
      FlatNo: "#4560",
      BookedDate: "26 March 2022, 12:42 AM",
      CustomerName: "Seema Agarwal",
      Location: "67 St. pune",
      TotalSpent: "Agent",
    },
  ];
  const [state, setState] = React.useState(false);

  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState(open);
  };

  const [stateadd, setStateadd] = React.useState(false);

  const toggleDrawerAdd = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setStateadd(open);
  };

  const [selectedRows, setSelectedRows] = React.useState([]);
  useEffect(() => {
    // console.log(selectedRows);
  }, [selectedRows]);

  const [curselectedRows, setcurselectedRows] = useState(null);
  const onRowsSelectionHandler = (ids) => {
    if (delIconClicked) setdelIconClicked(false);
    const selectedRowsData = ids.map((id) => rows.find((row) => row.id === id));
    // console.log(selectedRowsData);
    setcurselectedRows(selectedRowsData);
  };

  const [tabledata, setTabledata] = useState(null);
  const [rowData, setrowData] = useState([]);
  const [idCont, setidCunt] = useState(1);

  //api

  //api

  useEffect(() => {
    const rows = [
      {
        id: 8,
        Name: "Frances",
        Phone: "912912912",
        Email: "dummmy@gamil.com",
        Team: "Agent",
        Role: "Agent",
        DetailedView: "View",
      },
    ];
    if (tabledata) {
      let obj = {
        id: idCont,
        Name: tabledata.name,
        Phone: tabledata.phone,
        Email: tabledata.Email,
        Team: tabledata.Team,
        Role: tabledata.Role,
      };
      setidCunt(idCont + 1);
      console.log(obj);
      setrowData([...rowData, obj]);
    }

    // console.log(tabledata);
  }, [tabledata]);

  const [rowSelected, setRowSelected] = useState(false);
  const [token, setToken] = useState(null);
  const [data, setData] = useState(null);
  
  /// get selected project id from local storage
  let builderSelectedData = JSON.parse(localStorage.getItem("BuilderSelected"));
  let id = builderSelectedData.selectedProjectID;

  const getBuyers = async () => {
    try {
      const headers = {
        Authorization: `JWT ${token}`,
      };
      const res = await axios.get(Apiaddress + "/api/buyer/buyersofproject/?project_id="+id, {
        headers,
      });
      console.log(res.data.buyer);

      if(res.data.buyer=="buyer not found"){
        setData([]);
      }
      else{
        setData(res.data.buyer);
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (token) getBuyers();
  }, [token, trigger]);

  useEffect(() => {
    setToken(localStorage.getItem("crm_token"));
  }, [trigger]);

  const [newRowData, setnewRowData] = useState([]);
  useEffect(() => {
    // console.log(data);
    if (data) {
      // {
      //   id: 1,
      //   FlatNo: "#4560",
      //   BookedDate: "26 March 2022, 12:42 AM",
      //   CustomerName: "Seema Agarwal",
      //   Location: "67 St. pune",
      //   TotalSpent: "Agent",
      // },

      let arr = [];

      for (let i = 0; i < data.length; i++) {
        let obj = {
          id: i + 1,
          buyer_id: data[i].id,
          FlatNo: data[i].flat_no,
          BookedDate: data[i].booked_date,
          CustomerName: data[i].customer_name,
          Location: data[i].location,
          TotalSpent: data[i].total_spent,
        };
        arr.push(obj);
      }
      setnewRowData(arr);
    }
  }, [data]);

  const delIconClick = async (params) => {
    // console.log(params);
    try {
      const obj = {
        buyer_id: params.buyer_id,
      };
      const headers = {
        Authorization: `JWT ${token}`,
      };

      console.log(obj);
      console.log(headers);
      const res = await axios.delete(Apiaddress + "/api/buyer/buyers/", {
        headers: headers,
        data: obj,
      });
      setTrigger(!trigger);
      window.alert("delted successfully");
      console.log(res);
    } catch (err) {
      console.log(err);
    }
  };

  const [checkBoxarr, setcheckBoxarr] = useState(new Map());

  const handelCheckChange = (item, index) => {
    console.log(item);
    console.log(index);
    console.log(checkBoxarr[index]);
  };

  //fitering data starts:====
  const [filterselect, setfilterselect] = useState();
  const [filtervalue, setfiltervalue] = useState();

  const handleChange = (event) => {
    setfilterselect(event.target.value);
    setfiltervalue("");
  };

  console.log(filterselect);
  console.log(filtervalue);

   const fetchfilter = async () => {
    console.log(id);
     try {
      const headers = {
        Authorization: `JWT ${token}`,
      };
      const res = await axios.get(
        Apiaddress + `/api/buyer/buyersofproject/?project_id=${id}&filterkey=${filterselect}&filtervalue=${filtervalue}`, {
        headers,
      });
      console.log(res.data.buyer);

      if(res.data.buyer=="buyer not found"){
        setData([]);
      }
      else{
        setData(res.data.buyer);
      }
    } catch (err) {
      console.log(err);
    }
  };


  //table functins

  //tabel functions
    return (
      <>

      <Stack direction="row" sx={{width:"100%",height:"auto" }}>
            {/* 1st stack */}
            <Stack sx={{minHeight:"100vh",width:"22%",boxSizing:"border-box",}}>
            <ProjectDashboardSidebar active={"Buyers Directory"}/>
            </Stack>
      
           {/* 2nd stack */}
           <Stack direction="column" sx={{ backgroundColor:"#f9f9f9",width:"78%", padding:"25px",boxSizing:"border-box",}}  >
            {/* <Navbar/> */}
            <NavbarForDirectory
          showAddProjectbtn={false}
          showRightSideBar={false}
          showRightSideBarleft={true}
          RightBarName={"Add Buyers"}
          toggleDrawer={toggleDrawer}
          toggleDrawerAdd={toggleDrawerAdd}
          RightBarNameleft={"Send invite"}
          leftRightTitle1={"Buyers Dashboard"}
          leftRightTitle2={"Buyers Directory"}
          leftTitle={"Project Dashboard"}
          showLeftTitle={false}
          righshow={true}
        />
      
      
          {/* <Typography sx={{mt:"20px", fontSize:"22px", fontWeight:"500",color:"#2979F2"}}>
          Project Name
          </Typography> */}
           {/* actual content  */}
           <Stack sx={{ mt:"28px",backgroundColor:"#f9f9f9"}}>

<React.Fragment>
      <Drawer
        anchor="right"
        open={stateadd}
        onClose={toggleDrawerAdd(false)}
      >
        <RightSidebarBDInvite
          setTabledata={setTabledata}
          curselectedRows={curselectedRows}
        />
      </Drawer>
    </React.Fragment>

    <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        sx={{ height: "68px", marginBottom: "20px" }}
      >
        <Stack direction="column" alignItems="center">
          <Typography
            sx={{
              fontSize: "22px",
              fontWeight: "600",
              marginLeft: "-15px",
              color: "black",
            }}
          >
            Buyers Directory
          </Typography>
          <Typography
            sx={{
              color: "#B9B7D0",
              fontSize: "12px",
              fontWeight: "400",
              marginLeft: "10px",
            }}
          >
            Here is your Buyers Directory list data
          </Typography>
        </Stack>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Stack
            direction="row"
            justifyContent="space-around"
            alignItems="center"
            sx={{
              width: "203px",
              height: "68px",
              borderRadius: "12px",
              color: "#3E4954",
              backgroundColor: "#f4f5f9",
            }}
          >

            <Accordion sx={{ width: "250px", boxShadow: "none" }}>
              <AccordionSummary
                expandIcon={<GridExpandMoreIcon/>}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography sx={{ display: "flex", placeItems: "center" }}>
                  <TuneOutlinedIcon sx={{ color: "#2F4CDD" }} />
                  <Typography sx={{ marginLeft: "25px" }}>
                    Fillters
                  </Typography>
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Box sx={{textAlign:"center"}}>
                  <FormControl sx={{ width: "200px" }}>
                    <InputLabel id="demo-simple-select-label">
                      Select
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={filterselect}
                      label="Select"
                      onChange={handleChange}
                    >
                      <MenuItem value={"flat_no"}>Flat No.</MenuItem>
                      <MenuItem value={"booked_date"}>Booked Date</MenuItem>
                      <MenuItem value={"customer_name"}>Customer Name</MenuItem>
                      <MenuItem value={"location"}>Location</MenuItem>
                      {/* <MenuItem value={"Name"}>Name</MenuItem> */}
                    </Select>
                  </FormControl>
                  <TextField
                    sx={{ marginTop: "20px" }}
                    placeholder="Enter the value" 
                    value={filtervalue} onChange={(e)=>{setfiltervalue(e.target.value)}}
                  />
                  <Button 
                    onClick={fetchfilter}
                    variant="contained"
                    color="primary" sx={{ height:"40px", background: "linear-gradient(90deg, #0F5DAE 0%, #009DCF 100.33%)",boxShadow: "0px 13.7981px 22.5788px rgba(50, 174, 137, 0.25)", textTransform: "none", borderRadius: "4px", width:"100px", fontSize:"15px",margin:"20px 0px 10px 0px"}}>
                     Submit
                  </Button>

                </Box>
              </AccordionDetails>
            </Accordion>

          </Stack>
        </Stack>
      </Stack>

      <BDCustomTabel
        newRowData={newRowData}
        delIconClick={delIconClick}
        updateBuyers={updateBuyers}
        setTrigger={setTrigger}
        trigger={trigger}
      />

</Stack>
      
      
      
      
            </Stack>
      </Stack>
              
              </>
    )
}