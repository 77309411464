import axios from "axios";
import { Apiaddress, basicheaders } from "../../utils/consts";

const fetchUnitLayout = async (unit_layout_id: number) => {
    const url = Apiaddress + "/api/projects/unitlayout/?unit_layout_id=" + unit_layout_id;
    const re = await axios.get(
        url,
        {
            headers: basicheaders
        }
    )
    console.log(re.data)
    return re.data;
}
export {fetchUnitLayout};