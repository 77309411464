import {
  Box,
  Button,
  Container,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import CommonPagesFooter from "./CommonPagesFooter";
import CommonPagesNavbar from "./CommonPagesNavbar";
import CommonPagesResponsiveNavbar from "./CommonPagesResponsiveNavbar";
import ContactImgMobile from "../../Imgs/contact-img-mobile.jpg";
import ContactImg from "../../Imgs/contact-img.jpg";
import { useState } from "react";
import { AuthService } from "../../../context/buyer/services/auth";
import { api, Apiaddress, net } from "../../../utils/consts";

const CommonPagesContact = () => {
  
  const [name,setName] = useState();
  const [email,setEmail] = useState();
  const [phone,setPhone] = useState();
  const [msg,setMsg] = useState();

  const submitMessage=async(e)=>{
    e.preventDefault();
    try{
      const Auth = new AuthService();
      let obj={
        name:name,
        email:email,
        phone:phone,
        message:msg
      }
      console.log(obj);
      const res=await Auth.makeUnAuthenticatedPostRequest(
        net("send-enquiry-to-Admin"),
          {
          JsonBody: obj
        }
      );
      console.log(res?.status);
      if(res?.status==200){
        setName("");
        setPhone("");
        setEmail("");
        setMsg("");
      }
    }catch(err){
      console.log(err.message);
    }
  }
  return (
    <>
      <CommonPagesNavbar />
      <CommonPagesResponsiveNavbar />
      <Stack spacing={{ lg: 14, md: 12, xs: 6 }}>
        <Box
          sx={{
            py: "20px",
          }}
        >
          <Container>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                flexDirection: { xs: "column", md: "row" },
                boxShadow: "0px 4px 28px rgba(0, 0, 0, 0.05)",
                borderRadius: "8px",
                backgroundColor: { xs: "#FFF", md: "transparent" },
                gap: { xs: "20px", md: "none" },
              }}
            >
              <Box
                sx={{
                  width: { xs: "100%", md: "50%" },
                  display: { xs: "none", md: "block" },
                }}
              >
                <img style={{ width: "100%" }} src={ContactImg} alt="" />
              </Box>
              <Box
                sx={{
                  width: { xs: "100%", md: "50%" },
                  display: { xs: "block", md: "none" },
                }}
              >
                <img style={{ width: "100%" }} src={ContactImgMobile} alt="" />
              </Box>
              <Box
                data-aos="fade-zoom-in"
                data-aos-easing="ease-in-back"
                data-aos-delay="100"
                data-aos-offset="380"
                sx={{
                  width: { xs: "80%", md: "50%" },
                  padding: { xs: "20px", md: "30px", lg: "60px" },
                  background: "#FFFFFF",
                }}
              >
                <form onSubmit={submitMessage} style={{ width: "100%" }}>
                  <Stack spacing={4} sx={{}}>
                    <Typography
                      sx={{
                        fontSize: { xs: "28px", md: "32px" },
                        fontWeight: 700,
                        fontFamily: "Product Sans",
                      }}
                    >
                      Send us a Message
                    </Typography>
                    <Box>
                      <Typography
                        sx={{
                          fontSize: "18px",
                          color: "#637381",
                          fontFamily: "Product Sans",
                        }}
                      >
                        Full Name*
                      </Typography>
                      <TextField
                        id="standard-size-small"
                        variant="standard"
                        placeholder="Type your name..."
                        required
                        value={name}
                        onChange={(e)=>setName(e.target.value)}
                        sx={{
                          width: "100%",
                          fontSize: "15px",
                          color: "#637381",
                          fontFamily: "Product Sans",
                        }}
                      />
                    </Box>
                    <Box>
                      <Typography
                        sx={{
                          fontSize: "18px",
                          color: "#637381",
                          fontFamily: "Product Sans",
                        }}
                      >
                        Email*
                      </Typography>
                      <TextField
                        id="standard-size-small"
                        variant="standard"
                        placeholder="Type your email..."
                        required
                        type="email"
                        value={email}
                        onChange={(e)=>setEmail(e.target.value)}
                        sx={{
                          width: "100%",
                          fontSize: "15px",
                          color: "#637381",
                          fontFamily: "Product Sans",
                        }}
                      />
                    </Box>
                    <Box>
                      <Typography
                        sx={{
                          fontSize: "18px",
                          color: "#637381",
                          fontFamily: "Product Sans",
                        }}
                      >
                        Phone*
                      </Typography>
                      <TextField
                        id="standard-size-small"
                        variant="standard"
                        placeholder="+91 873975678"
                        type="number"
                        value={phone}
                        required
                        onChange={(e) => {
                          if (e.target.value.length > 10) {
                            return;
                          }
                          setPhone(e.target.value);
                        }}
                        sx={{
                          width: "100%",
                          fontSize: "15px",
                          color: "#637381",
                          fontFamily: "Product Sans",
                        }}
                      />
                    </Box>
                    <Box>
                      <Typography
                        sx={{
                          fontSize: "18px",
                          color: "#637381",
                          fontFamily: "Product Sans",
                        }}
                      >
                        Message*
                      </Typography>
                      <TextField
                        id="standard-size-small"
                        variant="standard"
                        placeholder="Type your message here..."
                        value={msg}
                        required
                        onChange={(e)=>{setMsg(e.target.value)}}
                        sx={{
                          width: "100%",
                          fontSize: "15px",
                          color: "#637381",
                          fontFamily: "Product Sans",
                        }}
                      />
                    </Box>
                    <Box>
                      <Button
                        sx={{
                          background:
                            "linear-gradient(97.85deg, #FFAF2A 0.95%, #FFC666 100%)",
                          fontWeight: 500,
                          fontSize: { lg: "28px", xs: "20px" },
                          boxShadow: "0px 9px 12px rgba(242, 178, 73, 0.59)",
                          borderRadius: "10px",
                          color: "#06386B",
                          textTransform: "none",
                          padding: "10px 30px",
                          fontFamily: "Product Sans",
                          mt: "20px",
                          "&:hover": {
                            background:
                              "linear-gradient(97.85deg, #FFAF2A 0.95%, #FFC666 100%)",
                          },
                        }}
                        type="submit"
                      >
                        Submit
                      </Button>
                    </Box>
                  </Stack>
                </form>
              </Box>
            </Box>
          </Container>
        </Box>
        <CommonPagesFooter />
      </Stack>
    </>
  );
};

export default CommonPagesContact;
