import { Box } from "@mui/system";
import React, { useContext, useEffect, useState } from "react";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";

import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Button,
  Checkbox,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  Modal,
  OutlinedInput,
  Select,
  TextField,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";

import AddIcon from "@mui/icons-material/Add";
import axios from "axios";

import profileContext from "../../../context/profile/context";
import { Apiaddress } from "../../../utils/consts";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function RightSidebar({
  setTabledata,
  curselectedRows,
  orgID,
  toggleDrawer,
  updateData,
  setTrigger,
  trigger,
  makePriority,
  makeProject,
  setMakePriority,
  setMakeProject,
}) {
  const [names, setNames] = useState([
    { name: "Aditya Birla Appartments", id: 2 },
    { name: "Tata Farms", id: 3 },
  ]);
  const [expanded, setExpanded] = useState(
    // curselectedRows ? "panel2" : "panel1"
    makePriority? "panel2":makeProject?"panel3":"panel1"
  );
  const [disabelAccrodian, setdisabelAccrodian] = useState(
    curselectedRows ? "true" : "false"
  );
  const [selectValue, setSelectValue] = useState("High");
  const [setProjects, setsetProjects] = React.useState([]);

  const [priority, setPriority] = useState(null);
  const handleChangeSelect = (event) => {
    setPriority(event.target.value);
  };

  const [choosenproject,setchoosenproject]= useState("");
  const handleChangeSelectpro = (event) => {
    setchoosenproject(event.target.value);
  };

  const [projectsSelected, setprojectsSelected] = useState(0);
  const [projectsSelectedID, setprojectsSelectedID] = useState(0);

  const addProjectID = (para) => {
    setprojectsSelectedID(para);
  };

  const addProjects = (e) => {
    setprojectsSelected(e.target.value);
  };
  const [selectArr, setselectArr] = useState([
    {
      value: "high",
      label: "high",
    },
    {
      value: "low",
      label: "low",
    },
  ]);

  const createOpt = () => { };

  const handleChange = (i) => {
    setExpanded(i);
  };

  const [colors, setColors] = useState([
    { name: "Ajay", clr: "green" },
    { name: " Tarun", clr: " yellow" },
    { name: "  Ranbir", clr: "red" },
  ]);
  const [leadname, setLeadName] = useState("");
  const [leadclr, setLeadclr] = useState("");

  const addClr = () => {
    const obj = { name: leadname, clr: leadclr };
    setColors([...colors, obj]);
    handleClose();
  };

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [name, setName] = useState(
    curselectedRows && curselectedRows.Name ? curselectedRows.Name : ""
  );
  const [phone, setPhne] = useState("");
  const [Email, setEmail] = useState("");
  const [address, setAddresss] = useState("");
  

  const user = useContext(profileContext);
  const [userID, setUserID] = useState(null);

  useEffect(() => {
    console.log(user);
    // console.log(user.UserState);
    if (user)
      if (user.UserState)
        if (user.UserState.user_obj)
          if (user.UserState.user_obj.id) setUserID(user.UserState.user_obj.id);
  }, [user]);
  // console.log(userID);
  useEffect(() => {
    if (updateData) {
      setName(updateData.Name);
      setEmail(updateData.Email);
      setPhne(updateData.Phone);
      setPriority(updateData.priority);
      setAddresss(updateData.Address);
    }
  }, [updateData]);

  const clearItems = () => {
    setName(null);
    setEmail(null);
    setPhne(null);
    setPriority(null);
    setAddresss(null);
  };
  // console.log(curselectedRows);
  console.log(priority);
  const [slectedCPid, setselectedCPid] = useState(null);

  const saveChanges = async () => {
    console.log(curselectedRows);
    if (curselectedRows) {
      for (let i = 0; i < curselectedRows.length; i++) {
        let obj={}
        if(makePriority){
          obj = {
          lead_id: curselectedRows[i].lead_id,
          lead_prority: priority,
          org: orgID,
          assigned_to: userID ? [userID] : [7],
          }
        }
        else{
          obj = {
          lead_id: curselectedRows[i].lead_id,
          org: orgID,
          assigned_to: userID ? [userID] : [7],
          project:projectsSelectedID
          };
        }
  
        console.log(obj);
        try {
          const res = await axios.patch(
            Apiaddress + "/api/leads/lead/",
            obj
          );
          console.log("eeeee",res.data);
          toggleDrawer(false);
          setTrigger(!trigger);
           setMakePriority(false);
          setMakeProject(false);
        } catch (err) {
          console.log(err);
        }
      }
    } else if (updateData) {
      const obj = {
        lead_id: updateData.lead_id,
        name: name,
        phone: phone,
        Email: Email,
        address_line: address,
        colors: colors,
        channel_partner: slectedCPid
      };

      if(priority){
         obj.lead_prority=priority
      }
      if(projectsSelected){
         obj.project=projectsSelectedID
      }
      if(selectedCP){
         obj.channel_partner=slectedCPid
      }
      
      console.log(obj);

      try {
        const res = await axios.patch(
          Apiaddress + "/api/leads/lead/",
          obj
        );
        console.log(res.data);
        setName("");
        setEmail("");
        setAddresss("");
        setPhne("");
        toggleDrawer(false);
        setTrigger(!trigger);
           setMakePriority(false);
          setMakeProject(false);
      } catch (err) {
        console.log(err);
      }
    } else {
      const obj = {
        name: name,
        phone: phone,
        Email: Email,
        address: address,
        priority: priority,
        setProjects: setProjects,
        colors: colors,
        slectedCPid: slectedCPid,
      };
      
      console.log(obj);

      let objPost = {
        name: obj.name,
        phone: obj.phone,
        email: obj.Email,
        project: projectsSelectedID,
        lead_prority: obj.priority,
        org: orgID,
        assigned_to: [userID],
        channel_partner: obj.slectedCPid,
        address_line:obj.address
      };
      console.log(objPost);
      try{
        const res = await axios.post(
        Apiaddress + "/api/leads/lead/",
        objPost
      );
      setName("");
      setEmail("");
      setAddresss("");
      setPhne("");
      console.log(res.data);
      setTabledata(obj);
      toggleDrawer(false);
      setTrigger(!trigger);
         setMakePriority(false);
          setMakeProject(false);
      }
      catch (err){
        console.log(err);
      }
      setTrigger(!trigger);
    }
    // console.log(name, phone, Email, address, priority, setProjects);
  };
  /// get selected project id from local storage
  let builderSelectedData = JSON.parse(localStorage.getItem("BuilderSelected"));
  let id = builderSelectedData.selectedProjectID;


  const addprofiledata = () => { };

  const [data, setData] = useState(null);
  const [token, setToken] = useState(null);

  const getProjects = async () => {
    try {
      const headers = {
        Authorization: `JWT ${token}`,
      };
      const res = await axios.get(
        Apiaddress + "/api/projects/getprojectsusingtoken/",
        { headers }
      );
      console.log("dddddd",res.data.projects);

      if (res.data) if (res.data.projects){
        let temp = [];
        for(let ele of res.data.projects){
          if(ele.id===id){
            temp.push(ele);
          }
        }
        setData(temp);
      } 
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    if (token) getProjects();
  }, [token]);

  useEffect(() => {
    setToken(localStorage.getItem("crm_token"));
  }, []);
  const [newRowData, setNewRowData] = useState([]);
  useEffect(() => {
    if (data) {
      // console.log(data);
    }
  }, [data]);

  const [selectedCP, setselectedCP] = useState("");

  const addCP = (e) => {
    setselectedCP(e.target.value);
  };

  const addCPid = (para) => {
    setselectedCPid(para);
  };

  const [builderId, setbuilderId] = useState(null);

  useEffect(() => {
    // console.log(user);
    // console.log(user.UserState);
    if (user)
      if (user.UserState)
        if (user.UserState.user_obj)
          if (user.UserState.user_obj.orgList)
            if (user.UserState.user_obj.orgList[0]) {
              // console.log(user.UserState.user_obj.orgList[0]);
              // setOrgID(user.UserState.user_obj.orgList[0]);
            }
  }, [user]);

  const [cpdata, setcpdata] = useState(null);
  // const [trigger, setTrigger] = useState(false);
  useEffect(() => {
    // console.log(user);
    if (user) {
      if (user.UserState) {
        if (user.UserState.user_obj) {
          setbuilderId(user.UserState.user_obj.id);
        }
      }
    }
  }, [user, trigger]);

  const getCP = async () => {
    try {
      const res = await axios.get(
        Apiaddress + `/api/role/getcpusingbuilderid/?builder_id=${builderId}`
      );
      // console.log(res.data);
      if(res.data.channel_partner!=="channel_partner not found"){
        setcpdata(res.data.channel_partner);
      }else{
        setcpdata([])
      }
    } catch (err) {
      console.log(err);
    }
  };

  const [datafromapi, setdatafromapi] = useState([]);

  useEffect(() => {
    if (cpdata) {
      // id: 1,
      // RERARegNo: "#4560",
      // Type: "26 March 2022, 12:42 AM",
      // Name: "Seema Agarwal",
      // Email: "67 St. pune",
      // Phone: "Agent",

      let arr = [];
      for (let i = 0; i < cpdata.length; i++) {
        const obj = {
          id: i + 1,
          CPid: cpdata[i].id,
          RERARegNo: cpdata[i].rera_no,
          Type: cpdata[i].profile.role_type,
          Name: cpdata[i].profile.user_details.first_name,
          Email: cpdata[i].profile.user_details.email,
          Phone: cpdata[i].profile.phone,
        };
        // console.log(obj);
        arr.push(obj);
      }
      setdatafromapi(arr);
    }
  }, [cpdata]);

  useEffect(() => {
    console.log(builderId);
    if (builderId) {
      getCP();
    }
  }, [builderId, trigger]);

  const [allpriority, setallpriority] = useState(null);

  const getpriority = async () => {
    try {
      const res = await axios.get(
        Apiaddress + "/api/leads/leadprority/"
      );
      console.log(res.data.leadprority);
      setallpriority(res.data.leadprority);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getpriority();
  }, []);

  return (
    <Box
      sx={{
        width: "420px",
        height: "100vh",
        marginTop: "-25px",
        marginRight: "25px",
        padding: "10px",
      }}
    >
      <Box
        sx={{
          position: "relative",
        }}
      >
        <Box
          sx={{
            position: "absolute",
            top: "0px",
            right: "0px",
            background: "white",
            padding: "10px",
            height: "105vh",
            width: "400px",
            marginTop: "20px",
          }}
        >
          {" "}
          <Box>
            <Box sx={{ display: "flex", cursor: "pointer" }}
            // onClick={toggleDrawer(false)}
             >
              <Box>
                <ArrowBackIosIcon
                  sx={{
                    fontSize: "small",
                    marginBottom: "-2px",
                  }}
                />
              </Box>
              <Box>Add Leads</Box>
            </Box>
            <Box
              sx={{
                display: "grid",
                alignItems: "center",
                height: "100vh",
              }}
            >
              <Box>
                <Accordion
                  expanded={expanded === "panel1"}
                  onClick={() => {
                    if (!curselectedRows) {
                      handleChange("panel1");
                    }
                  }}
                >
                  <AccordionSummary
                    sx={{
                      background: `${expanded === "panel1" ? "#FFAF2A" : "white"
                        }`,
                      borderRadius: "10px",
                    }}
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography>Leads Profile</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        width: "400px",
                        margin: "10px 0",
                      }}
                    >
                      <Box>Name</Box>
                      <Box>
                        <TextField
                          value={name}
                          id="outlined-basic"
                          onChange={(e) => setName(e.target.value)}
                        />
                      </Box>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        width: "400px",
                        margin: "10px 0",
                      }}
                    >
                      <Box>Phone Number</Box>
                      <Box>
                        <TextField
                          value={phone}
                          placeholder="+91 9999999999"
                          id="outlined-basic"
                          onChange={(e) => setPhne(e.target.value)}
                        />
                      </Box>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        width: "400px",
                        margin: "10px 0",
                      }}
                    >
                      <Box>Email</Box>
                      <Box>
                        <TextField
                          value={Email}
                          id="outlined-basic"
                          onChange={(e) => setEmail(e.target.value)}
                        />
                      </Box>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        width: "400px",
                        margin: "10px 0",
                      }}
                    >
                      <Box>Address</Box>
                      <Box>
                        <TextField
                          value={address}
                          id="outlined-basic"
                          onChange={(e) => setAddresss(e.target.value)}
                        />
                      </Box>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        width: "400px",
                        margin: "10px 0",
                      }}
                    >
                      <Box></Box>
                      {/* <Button
                        onClick={addprofiledata}
                        className="univercel_btn_class"
                        sx={{
                          color: "#FFFFFF",
                          textTransform: "none",
                        }}
                      >
                        Add Unit
                      </Button> */}
                    </Box>
                  </AccordionDetails>
                </Accordion>
                <Accordion
                  expanded={expanded === "panel2"}
                  onClick={() => {
                    if(makePriority)handleChange("panel2")
                    else if(!curselectedRows && !makePriority) handleChange("panel2");  
                  }}
                >
                  <AccordionSummary
                    sx={{
                      background: `${expanded === "panel2" ? "#FFAF2A" : "white"
                        }`,
                      borderRadius: "10px",
                    }}
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography>Lead Priority</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Box
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        width: "400px",
                        margin: "10px 0",
                      }}
                    >
                      <Box>Mark As</Box>
                      <TextField
                        id="outlined-select-currency"
                        select
                        value={priority}
                        onChange={handleChangeSelect}
                        sx={{ width: "300px" }}
                      >
                        {allpriority &&
                          allpriority.map((option) => (
                            <MenuItem key={option.id} value={option.id}>
                              {option.name}
                            </MenuItem>
                          ))}
                        {/* <MenuItem
                          onClick={createOpt}
                          sx={{ fontWeight: "500", fontSize: "12px" }}
                        >
                          Create New
                        </MenuItem> */}
                      </TextField>
                    </Box>
                  </AccordionDetails>
                </Accordion>
                <Accordion
                  expanded={expanded === "panel3"}
                  onClick={() => {
                    // if (!curselectedRows) handleChange("panel3");
                    if(makeProject) handleChange("panel3");
                    else if(!curselectedRows && !makeProject) handleChange("panel3");
                  }}
                >
                  <AccordionSummary
                    sx={{
                      background: `${expanded === "panel3" ? "#FFAF2A" : "white"
                        }`,
                      borderRadius: "10px",
                    }}
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography>Leads Projects</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Box>
                      <FormControl variant="standard" sx={{ width: "400px" }}>
                        <InputLabel id="demo-simple-select-standard-label">
                          select project
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-standard-label"
                          id="demo-simple-select-standard"
                          value={projectsSelected}
                          onChange={addProjects}
                          label="Age"
                        >
                          {data &&
                            data.map((item) => {
                              return (
                                <MenuItem
                                  value={item.name}
                                  onClick={() => addProjectID(item.id)}
                                >
                                  {item.name}
                                </MenuItem>
                              );
                            })}
                        </Select>
                      </FormControl>
                    </Box>
                  </AccordionDetails>
                </Accordion>
                <Accordion
                  expanded={expanded === "panel4"}
                  onClick={() => {
                    if (!curselectedRows) handleChange("panel4");
                  }}
                >
                  <AccordionSummary
                    sx={{
                      background: `${expanded === "panel4" ? "#FFAF2A" : "white"
                        }`,
                      borderRadius: "10px",
                    }}
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                  <Typography>Lead Channel Partner</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Box>
                      <FormControl variant="standard" sx={{ width: "400px" }}>
                        <InputLabel id="demo-simple-select-standard-label">
                          select channel partner
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-standard-label"
                          id="demo-simple-select-standard"
                          value={selectedCP}
                          onChange={addCP}
                          label="Age"
                        >
                          {datafromapi &&
                            datafromapi.map((item) => {
                              return (
                                <MenuItem
                                  value={item.Name}
                                  onClick={() => addCPid(item.CPid)}
                                >
                                  {item.Name}
                                  {item.CPid}
                                </MenuItem>
                              );
                            })}
                        </Select>
                      </FormControl>
                    </Box>
                    {/* <Box>
                      {" "}
                      <Button
                        className="univercel_btn_class"
                        sx={{
                          color: "#FFFFFF",
                          textTransform: "none",
                        }}
                        startIcon={<AddIcon />}
                        onClick={handleOpen}
                      >
                        Create New
                      </Button>
                    </Box> */}
                    {/* <Box>
                      {" "}
                      <Modal
                        open={open}
                        onClose={handleClose}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                      >
                        <Box
                          sx={{
                            position: "absolute",
                            top: "50%",
                            left: "50%",
                            transform: "translate(-50%, -50%)",
                            width: 400,
                            height: 400,
                            bgcolor: "white",
                            border: "2px solid #000",
                            boxShadow: 24,
                            borderRadius: "10px",
                            p: 4,
                          }}
                        >
                          <Box sx={{ fontSize: "24px", fontWeight: "600" }}>
                            Edit or Add Catagories
                          </Box>
                          <Box sx={{ marginTop: "100px" }}>
                            <Box
                              sx={{
                                display: "flex",
                                justifyContent: "space-between",
                                width: "400px",
                                margin: "10px 0",
                              }}
                            >
                              <Box>name</Box>
                              <Box>
                                <TextField
                                  id="outlined-basic"
                                  onChange={(e) => setLeadName(e.target.value)}
                                />
                              </Box>
                            </Box>
                            <Box
                              sx={{
                                display: "flex",
                                justifyContent: "space-between",
                                width: "400px",
                                margin: "10px 0",
                              }}
                            >
                              <Box>color</Box>
                              <Box>
                                <TextField
                                  id="outlined-basic"
                                  onChange={(e) => setLeadclr(e.target.value)}
                                />
                              </Box>
                            </Box>
                            <Box
                              sx={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                width: "400px",
                                margin: "10px 0",
                              }}
                            >
                              <Button
                                onClick={addClr}
                                className="univercel_btn_class"
                                sx={{
                                  width: "200px",
                                  color: "#FFFFFF",
                                  textTransform: "none",
                                }}
                              >
                                Submit
                              </Button>
                            </Box>
                          </Box>
                        </Box>
                      </Modal>
                    </Box>
                    <Box>
                      {colors.map((item) => {
                        return (
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "space-between",
                              width: "400px",
                              margin: "10px 0",
                            }}
                          >
                            <Box sx={{ width: "30px   " }}>{item.name}</Box>
                            <Box
                              sx={{
                                hight: "25px",
                                width: "37px",
                                background: `${item.clr}`,
                              }}
                            ></Box>
                            <Box>
                              <DeleteIcon />
                            </Box>
                          </Box>
                        );
                      })}
                    </Box> */}
                  </AccordionDetails>
                </Accordion>

                <Box sx={{textAlign:"center",marginTop:"30px"}}
                onClick={toggleDrawer(false)}>
                {!updateData && !makePriority && !makeProject && (
                  <Button
                    onClick={()=>{saveChanges()}}
                    className="univercel_btn_class"
                    sx={{
                      width: "200px",
                      color: "#FFFFFF",
                      textTransform: "none",

                      marginBottom: "20px",
                    }}
                  >
                    Add Lead
                  </Button>
                )}
                 {!updateData && (makePriority || makeProject) &&(
                  <Button
                    onClick={()=>{
                      saveChanges()
                    }}
                    className="univercel_btn_class"
                    sx={{
                      width: "200px",
                      color: "#FFFFFF",
                      textTransform: "none",

                      marginBottom: "20px",
                    }}
                  >
                  Update Lead
                  </Button>
                )}
                {updateData && (
                  <Button
                    onClick={saveChanges}
                    className="univercel_btn_class"
                    sx={{
                      width: "200px",
                      color: "#FFFFFF",
                      textTransform: "none",

                      marginBottom: "20px",
                    }}
                  >
                    Update Lead
                  </Button>
                )}
                 {/* {curselectedRows && (
                  <Button
                    onClick={saveChanges}
                    className="univercel_btn_class"
                    sx={{
                      width: "200px",
                      color: "#FFFFFF",
                      textTransform: "none",

                      marginBottom: "20px",
                    }}
                  >
                    Update Lead
                  </Button>
                )} */}
              </Box>

            </Box>
            </Box>
            {/* <Box
              sx={{
                placeItems: "center",
                display: "grid",
                marginTop: "-100px",
              }}
            > */}
              {/* <Box>
                {!updateData && (
                  <Button
                    onClick={saveChanges}
                    className="univercel_btn_class"
                    sx={{
                      width: "200px",
                      color: "#FFFFFF",
                      textTransform: "none",

                      marginBottom: "20px",
                    }}
                  >
                    Add Lead
                  </Button>
                )}
                {updateData && (
                  <Button
                    onClick={saveChanges}
                    className="univercel_btn_class"
                    sx={{
                      width: "200px",
                      color: "#FFFFFF",
                      textTransform: "none",

                      marginBottom: "20px",
                    }}
                  >
                    Update Lead
                  </Button>
                )}
              </Box> */}
            {/* </Box> */}

          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export default RightSidebar;
