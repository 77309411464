import { Box, TextField, Typography, Radio, MenuItem } from '@mui/material'
import React, { useContext, useState } from 'react';
import { Stack } from '@mui/system';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import FileCopyRoundedIcon from '@mui/icons-material/FileCopyRounded';
import Button from "@mui/material/Button";
import Modal from '@mui/material/Modal';
import { useEffect } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { useNavigate } from 'react-router-dom';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Divider from '@mui/material/Divider';
import { Apiaddress } from '../../../../utils/consts';

import currentProjectState from '../../../../context/createproject/context';
import axios from 'axios';

function BuildingPatternSection(props) {

    const navigate = useNavigate();

    const [selectedValue, setSelectedValue] = useState('wing');

    const [wingnumber,setwingnumber]=useState();

  const [wingdata,setwingdata]=useState([]);

  const [wingtemp,setwingtemp]=useState([]);
 
//   console.log(wingdata);

  const [floornumber,setfloornumber]=useState();

  const [floordata,setfloordata]=useState([]);

  const [floortemp,setfloortemp]=useState([]);
 
//   set floor template list for dropdown;
const cpContext = useContext(currentProjectState);
const projectID = cpContext?.createProjectState?.project?.id;

useEffect(()=>{          
  const url = Apiaddress+"/api/projects/floortemplatesbyproject/?project_id="+projectID;
  axios.get(url)
  .then((res)=>{
        console.log("floor templates:   ",res.data.floor_templates);
        if(res.data.floor_templates=="floor templates not found"){
          return
        }
        setfloortemp(res?.data?.floor_templates)
      })
  .catch((err)=>{
    console.log(err)
  })
},[])

//   set wing template list for dropdown;
useEffect(()=>{          
  const url = Apiaddress+"/api/projects/wingtemplatesbyproject/?project_id="+projectID;
  axios.get(url)
  .then((res)=>{
        console.log("wing templates:   ",res.data);
        if(res.data.wing_templates=="Wing Template not found"){
          return;
        }
        setwingtemp(res?.data?.wing_templates)
      })
  .catch((err)=>{
    console.log(err)
  })
},[])


  useEffect(()=>{
    if(selectedValue=='wing'){
      let data=[];
    for(let i=1;i<=wingnumber;i++){
      let to_insert={
        wing_no:i,
        wing_name:"",
        wing_id:"",
      }
      data.push(to_insert);
      }
    setwingdata(data);
    }
    else{
        let data=[];
    for(let i=1;i<=floornumber;i++){
      let to_insert={
        floor_no:i,
        floor_name:"",
        floor_id:"",
      }
      data.push(to_insert);
      }
    setfloordata(data);
    }
    

  },[wingnumber,floornumber]);

    

    const handleChange = (event) => {
      setSelectedValue(event.target.value);
      if(event.target.value=="wing"){
        props.setbuildingtitle((prev,index) => {
          return prev.filter((curvalue, idx) => {
          if (idx == props.index) {
              curvalue.has_wing = true;
          }
          return curvalue;
     })
      })
      }
      else{
        {
          props.setbuildingtitle((prev,index) => {
            return prev.filter((curvalue, idx) => {
            if (idx == props.index) {
                curvalue.has_wing = false;
            }
            return curvalue;
       })
        })
        }
      }
    };

    const controlProps = (item) => ({
        checked: selectedValue === item,
        onChange: handleChange,
        value: item,
        name: 'color-radio-button-demo',
        inputProps: { 'aria-label': item },
      });
    

  return (
    <div>
         <Box>
            <Box sx={{display:"flex",alignItems:"center",margin:"0px 0px 20px 0px"}}>
            <Typography component="span" className='field-name' sx={{fontSize:"18px"}}>Building {props.header.building_no}</Typography>
            <TextField
                 sx={{
                     width:"188px",
                     marginLeft:"130px"
                    }} value={props.header.titlename} onChange={(event)=>{
                            props.setbuildingtitle((prev) => {
                                return prev.filter((curvalue, idx) => {
                                if (idx == props.index) {
                                    curvalue.titlename = event.target.value;
                                }
                                return curvalue;
                           })
                            })
                        }}/>
            </Box>

            <Stack direction="row" justifyContent="start" alignItems="center" sx={{}} >
                            <Radio 
                            name="wing"
                            {...controlProps('wing')}
                                sx={{
                                    color: "linear-gradient(264.48deg, #79F1A4 -45.37%, #0E5CAD 88.97%)",
                                    color: "#0F5DAE",
                                    margin:"0px 0px 0px 0px",
                                    '&.Mui-checked': {
                                    //   color: "red",
                                    //   color: "linear-gradient(264.48deg, #79F1A4 -45.37%, #0E5CAD 88.97%)"
                                    },
                                  }}
                            />
                            <Typography sx={{color:"#170F49", fontSize:"18px", fontWeight:500, marginRight:"70px"}}>Has Wings</Typography>
                            <Radio 
                             name="wing"
                             {...controlProps('nowing')}
                                sx={{
                                    color: "linear-gradient(264.48deg, #79F1A4 -45.37%, #0E5CAD 88.97%)",
                                      color: "#0F5DAE",
                                    '&.Mui-checked': {
                                    //   color: "red",
                                    //   color: "linear-gradient(264.48deg, #79F1A4 -45.37%, #0E5CAD 88.97%)"
                                    },
                                  }}
                            />
                            <Typography sx={{color:"#170F49", fontSize:"18px", fontWeight:500}}>Has no Wings</Typography>
            </Stack>

        {selectedValue=="wing" && 
        <Box sx={{paddingBottom:"30px"}}>
         <Box sx={{display:"flex",alignItems:"center",margin:"30px 0px 0px 0px"}}>
            <Typography component="span" className='field-name'>No of Wings </Typography>
            <TextField
            inputProps={{min:0, max: 100,maxLength:3}}
                 sx={{
                     width:"188px",
                     marginLeft:"30px"
                    }} type="Number" value={wingnumber} onChange={(e)=>{setwingnumber(e.target.value)}}/>
         </Box>
            <Box>
                 {wingdata.map((ele,index)=>{
              return(
                <Box key={index} sx={{display:"flex",alignItems:"center",margin:"30px 0px 0px 70px"}}>
                <Typography component="span" className='field-name'>Wing {ele.wing_no}</Typography>
        
                <FormControl sx={{width:"188px", marginLeft:"40px"}}>
                  <Select
                    onChange={(e)=>{
                         let arr = wingdata;
                         arr[index].wing_name = wingtemp[e.target.value].name;
                         arr[index].wing_id = wingtemp[e.target.value].id;
                         setwingdata([...arr]);


                          props.buildingtitle.map((ele)=>{
                        if(ele.building_no==props.header.building_no){
                            let arr = props.buildingtitle
                             arr[props.index].field = [...wingdata];
                            props.setplank(!props.plank);
                        }
                    })

                    }}
                    displayEmpty
                    inputProps={{ 'aria-label': 'Without label' }}
                  >
                    {wingtemp?.map((ele,index)=>{
                      return(
                        <MenuItem key={index} value={index}>{ele.name}</MenuItem>
                      )
                    })}
                  </Select>
                </FormControl>


                {/* <Typography className='' sx={{margin:"0px 0px 0px 20px",fontFamily:'Poppins',fontStyle:"normal",fontWeight:"500",fontSize: "12px",lineHeight:"18px",color:"#A2A3A6"}}>Can't Find The Template?</Typography> */}
                {/* <Typography className='' sx={{margin:"0px 0px 0px 5px",fontFamily:'Poppins',fontStyle:"normal",fontWeight:"500",fontSize: "12px",lineHeight:"18px",color:"#FF6140",cursor:"pointer"}} onClick={()=>{navigate('/wingtemplate')}}>Create New</Typography> */}
              </Box>
              )
            })}
            
            </Box>
            {/* <Button 
               type="submit"
                variant="contained"
                 color="primary" sx={{ height:"50px", background: "linear-gradient(90deg, #0F5DAE 0%, #009DCF 100.33%)",boxShadow: "0px 13.7981px 22.5788px rgba(50, 174, 137, 0.25)", textTransform: "none", borderRadius: "4px", width:"150px", fontSize:"15px",margin:"50px 0px 0px 0px"}}>
                Save
            </Button> */}
        </Box>}

        {selectedValue=="nowing" && 
        <Box sx={{paddingBottom:"30px"}}>
         <Box sx={{display:"flex",alignItems:"center",margin:"30px 0px 0px 0px"}}>
            <Typography component="span" className='field-name'>No of Floor </Typography>
            <TextField
                     inputProps={{min:0, max: 100,maxLength:3}} 
                 sx={{
                     width:"188px",
                     marginLeft:"30px"
                    }} type="Number" value={floornumber} onChange={(e)=>{setfloornumber(e.target.value)}}/>
                  
         </Box>
            <Box>
                {floordata.map((ele,index)=>{
              return(
                <Box key={index} sx={{display:"flex",alignItems:"center",margin:"30px 0px 0px 70px"}}>
                <Typography component="span" className='field-name'>Floor {ele.floor_no}</Typography>
        
                <FormControl sx={{width:"188px", marginLeft:"40px"}}>
                  <Select
                    onChange={(e)=>{
                         let arr = floordata;
                         arr[index].floor_name = floortemp[e.target.value].name;
                         arr[index].floor_id = floortemp[e.target.value].id;
                         setfloordata([...arr]);
                        
                        props.buildingtitle.map((ele)=>{
                        if(ele.building_no==props.header.building_no){
                            let arr = props.buildingtitle
                             arr[props.index].field = [...floordata];
                            props.setplank(!props.plank);
                        }
                    })

                    }}
                    displayEmpty
                    inputProps={{ 'aria-label': 'Without label' }}
                  >
                    {floortemp?.map((ele,index)=>{
                      return(
                        <MenuItem key={index} value={index}>{ele.name}</MenuItem>
                      )
                    })}
                  </Select>
                </FormControl>


                {/* <Typography className='' sx={{margin:"0px 0px 0px 20px",fontFamily:'Poppins',fontStyle:"normal",fontWeight:"500",fontSize: "12px",lineHeight:"18px",color:"#A2A3A6"}}>Can't Find The Template?</Typography> */}
                {/* <Typography className='' sx={{margin:"0px 0px 0px 5px",fontFamily:'Poppins',fontStyle:"normal",fontWeight:"500",fontSize: "12px",lineHeight:"18px",color:"#FF6140",cursor:"pointer"}} onClick={()=>{navigate('/floortemplate')}}>Create New</Typography> */}
              </Box>
              )
            })}
            
            </Box>
        </Box>}

        <Divider sx={{margin:"20px 0px 40px 0px"}}/>

        </Box>
    </div>
  )
}

export default BuildingPatternSection