import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  MenuItem,
  Drawer,
  Box,
  Button,
  Checkbox,
  FormControl,
  InputLabel,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { Stack } from "@mui/system";
import TuneOutlinedIcon from "@mui/icons-material/TuneOutlined";
import KeyboardArrowRightOutlinedIcon from "@mui/icons-material/KeyboardArrowRightOutlined";
import KeyboardArrowUpOutlinedIcon from "@mui/icons-material/KeyboardArrowUpOutlined";
import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined";
import { CheckBox } from "@mui/icons-material";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import DriveFileRenameOutlineIcon from "@mui/icons-material/DriveFileRenameOutline";
import VisibilityIcon from "@mui/icons-material/Visibility";
import DeleteIcon from "@mui/icons-material/Delete";
import "../../../BuilderComponents/MembersDirectory/MDmainPage.css";
import React, { useEffect, useState } from "react";
import { DataGrid, GridExpandMoreIcon } from "@mui/x-data-grid";
import CategoryIcon from "@mui/icons-material/Category";
import HandshakeIcon from "@mui/icons-material/Handshake";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import RightSidebarPD from "./RightSidebarPD";
import axios from "axios";
import PDcustomTable from "./PDcustomTable";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { UserRole } from "../../../../UserRoles/UserRoles";
import { Apiaddress, net } from "../../../../utils/consts";
import { AuthService } from "../../../../context/buyer/services/auth";
import FilterAltOffIcon from '@mui/icons-material/FilterAltOff';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import CircularProgress from "@mui/material/CircularProgress";

function PDmainPage({ showonlyself, builderInvited, setTrigger, trigger,setProState}) {

  let roles = JSON.parse(localStorage.getItem("user_roles"));
  let project = roles["project"];
  const cpDetails=JSON.parse(localStorage.getItem('cpDetails'));
  // console.log(cpDetails);
  let cpOrg=cpDetails?.channelPartnerOrg;

  const [state, setState] = React.useState(false);
  const [drawerState,setDrawerState] = useState(false);
  const [tabledata, setTabledata] = useState(null);
  const [rowData, setrowData] = useState([]);
  const [idCont, setidCunt] = useState(1);
  const [data, setData] = useState(null);
  const [token, setToken] = useState(null);
  const [newRowData, setNewRowData] = useState([]);
  const [startProject,setStartProject] = useState(0);
  const [totalProject,setTotalProject] = useState();
  const [loading, setloading] = useState(false);
  const [curpage, setcurpage] = useState(1);
  const [matchText, setmatchText] = useState(
    "Let's Get Started, Please Add Your Data"
  );


  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setDrawerState(open);
  };

  const getProjects = async () => {
    setloading(true);
    const auth = new AuthService()
    let url="";
    if(cpOrg){
      url=`role/inventeryofcp/?orgId=${cpOrg}`
    }
    else{
      url=`role/inventeryofcp/`
    }
    try {
      const headers = {
        Authorization: `JWT ${token}`,
      };
      const res = showonlyself ?
        await (await auth.makeAuthenticatedGetRequest(net(url))).json()
        : await axios.get(
          Apiaddress + "/api/projects/getprojectsusingtoken/",
          { headers }
        );
      if (!showonlyself) {
        if(res.data)
        if(res.data.projects)
        setData(res.data.projects);
        console.log("projectssss==>", res.data.projects);
      } else {
        setData(res.self["filtured Data"].projects);
        setTotalProject(res.self["filtured Data"].projects?.length);
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (token) getProjects();
  }, [token, trigger]);

  useEffect(() => {
    setToken(localStorage.getItem("crm_token"));
  }, [trigger]);


  const getInfoOfParticularproject=async ()=>{
    setloading(true);
    let arr = [];
    let st=startProject;
    let ed=startProject+7<data?.length?startProject+7:data?.length;
    for (let i = st; i < ed; i++) {
      const res = await axios.get(
        Apiaddress + `/api/projects/project/?project_id=${data[i].id}`
      );
      let temp=res?.data?.projects[0];
      console.log("===========================.>", res?.data?.projects[0]);
      let obj = {
          id: i + 1,
          project_id: temp?.id,
          Name: temp?.name,
          State: temp?.state,
          City: temp?.city,
          Pincode: temp?.pin_code,
          Unit: temp?.cp_units?.length,
        };
      arr.push(obj);
    }
    setNewRowData(arr);
    setloading(false);
  }

  useEffect(() => {
    if (data) {
      getInfoOfParticularproject();
    }
  }, [data,startProject]);

  const delIconClick = async (params) => {
    console.log(params);
    try {
      const obj = {
        project_id: params.project_id,
      };

      const res = await axios.delete(Apiaddress + "/api/projects/project/", {
        data: obj,
      });
      setTrigger(!trigger);
      window.alert("delted successfully");
      console.log(res);
    } catch (err) {
      console.log(err);
    }
  };
  const [updateInfo, setupdateInfo] = useState(null);
  const updateBuyers = (params) => {
    setupdateInfo(params.row);
  };

  //fitering data starts:====
  const [filterselect, setfilterselect] = useState();
  const [filtervalue, setfiltervalue] = useState();
  const [expanded, setExpanded] = useState(false);

  const handleChange = (event) => {
    setfilterselect(event.target.value);
    setfiltervalue("");
  };

  console.log(filterselect);
  console.log(filtervalue);

  const fetchfilter = async () => {
    try {
      const auth = new AuthService()

      let url="";
      if(cpOrg){
        url=`role/inventeryofcp/?orgId=${cpOrg}&filtervalue=${filtervalue}&filterkey=${filterselect}&by=project`
      }
      else{
        url=`role/inventeryofcp/?by=project&filterkey=${filterselect}&filtervalue=${filtervalue}`
      }
      const res = await (await auth.makeAuthenticatedGetRequest(net(url))).json()
      setData(res.self["filtured Data"].projects);
      setTotalProject(res.self["filtured Data"].projects?.length);
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <>
      <Stack direction="row" sx={{ width: "100%", height: "auto" }}>
        {/* 2nd stack */}
        <Stack
          direction="column"
          sx={{
            backgroundColor: "#f9f9f9",
            width: "100%",
            padding: "2px",
            boxSizing: "border-box",
          }}
        >
          {/* <Navbar/> */}
          {/* actual content  */}
          <Stack sx={{ mt: "2px", backgroundColor: "#f9f9f9" }}>

            <React.Fragment>
              <Drawer anchor="right" open={drawerState} onClose={()=>{setDrawerState(false)}}>
                <RightSidebarPD
                  statesetter={setDrawerState}
                  toggleDrawer={toggleDrawer}
                  setTrigger={setTrigger}
                  trigger={trigger}
                />
              </Drawer>
            </React.Fragment>

            
            {/* Button Container */}
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              sx={{}}
             >
              <Stack direction="row" alignItems="center">
                <Button
                  className="univercel_btn_class"
                    sx={{
                      color: "#FFFFFF",
                      textTransform: "none",
                      margin: "3px",
                      padding: "6px 24px",
                      fontFamily: "Product Sans",
                      fontSize: "16px",
                    }}
                    onClick={() => setProState(true)}
                >
                Add New Project
                </Button>
              </Stack>

             <Stack
                direction="row"
                justifyContent="right"
                alignItems="center"
                sx={{
                  width: "203px",
                  height: "68px",
                  borderRadius: "12px",
                  color: "#3E4954"
                }}
              >
              <Accordion 
              expanded={expanded}
              sx={{ width: "150px", boxShadow: "none"}}>
                  <AccordionSummary onClick={()=>{setExpanded(!expanded)}}
                    expandIcon={<GridExpandMoreIcon/>}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography sx={{ display: "flex", placeItems: "center" }}>
                      <TuneOutlinedIcon sx={{ color: "#2F4CDD" }} />
                      <Typography sx={{ marginLeft: "10px" }}>
                        Fillters
                      </Typography>
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Box sx={{textAlign:"center"}}>
                      <FormControl sx={{ width: "118px" }}>
                        <InputLabel id="demo-simple-select-label">
                          Select
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={filterselect}
                          label="Select"
                          onChange={handleChange}
                        >
                          <MenuItem value={"name"}>Name</MenuItem>
                          <MenuItem value={"state"}>State</MenuItem>
                          <MenuItem value={"city"}>City</MenuItem>
                          <MenuItem value={"pin_code"}>Pincode</MenuItem>
                        </Select>
                      </FormControl>
                      <TextField
                        sx={{ marginTop: "20px",fontSize:"8px"}}
                        placeholder="Value" 
                        value={filtervalue} onChange={(e)=>{setfiltervalue(e.target.value)}}
                      />
                      <Button 
                        // onClick={fetchfilter}
                        onClick={()=>{fetchfilter();setExpanded(false);setmatchText(
                          "No Results Found With The Applied Filters"
                        )}}
                        variant="contained"
                        className="univercel_btn_class"
                        color="primary" 
                        sx={{margin:"20px 0px 10px 0px"}}
                        >
                         Submit
                      </Button>

                    </Box>
                  </AccordionDetails>
                </Accordion>
                {expanded ==false && filtervalue && <RestartAltIcon 
                sx={{margin:"0px 10px",cursor:"pointer"}}
                onClick={()=>{
                  setfiltervalue("");
                  setStartProject(0);
                  setmatchText("Let's Get Started, Please Add Your Data");
                  setcurpage(1);
                  setTrigger(!trigger);
                }}/>}
                
              </Stack>
            </Stack>

            <PDcustomTable
              newRowData={newRowData}
              delIconClick={delIconClick}
              updateBuyers={updateBuyers}
              setTrigger={setTrigger}
              trigger={trigger}
              builderInvited={builderInvited}
              setProState={setProState}
              setStartProject={setStartProject}
              startProject={startProject}
              totalProject={totalProject}
              loading={loading}
              CircularProgress={CircularProgress}
              fetchfilter={fetchfilter}
              matchText={matchText}
              curpage={curpage}
              setcurpage={setcurpage}
              setState={setState}
              setDrawerState={setDrawerState}
            />
          </Stack>
        </Stack>
      </Stack>
    </>
  );
}

export default PDmainPage;
