import { Box, List, ListItem, Typography } from "@mui/material";
import "./styles/PageSix.css";
import React, {useState } from 'react';
import { Stack } from "@mui/system";
import classes from "./styles/PageOne.module.css";

const PdfOnePageFifteen = () => {

  const [pageData15,setPageData15]=useState({
    sign_first:"",
    sign_second:"",
    sign_third:"",
  })

  let name,value;
  const handleChange=(e)=>{
    name=e.target.name;
    value=e.target.value;
    console.log(name," ",value);
    setPageData15({...pageData15,[name]:value});
  }

  return (
    <Box>
      <Box sx={{ mb: "40px" }}>
        <Stack spacing={0.5} sx={{ ml: "10px", mt: "5px" }}>
          <Box sx={{ display: "flex", gap: "10px" }}>
            <Typography sx={{ fontSize: "10px" }}>10.3</Typography>
            <Typography sx={{ fontSize: "10px" }}>
              In case if the Applicant elects his remedy under clause 10.2(a)
              above then in such a case Applicant shall not subsequently be
              entitled to the remedy under sub clause 10.2(b) above.
            </Typography>
          </Box>
          <Box sx={{ display: "flex", gap: "10px" }}>
            <Typography sx={{ fontSize: "10px" }}>10.4</Typography>
            <Typography sx={{ fontSize: "10px" }}>
              The Applicant(s) agree that the Applicant(s) shall make payments
              of all amounts payable under the Agreement for Sale within 10 days
              of the Developer intimating the Applicant(s), in writing, that the
              Premises are ready for use and occupation (“Possession Intimaton
              Letter”) and shall thereafter, take possession of the Premises. In
              the event the Applicant(s) fails and or neglects to take
              possession of the Premises within 14 days from the date of the
              Possession Intimation Letter, then Applicant(s) shall be liable to
              pay demurrage charges to the Developer as set out in the Agreement
              for Sale from the expiry of the aforementioned 14 day period till
              such time the Applicant(s) takes the possession of the Premises.
              The amounts payable by the Applicant(s) pursuant to this clause
              shall be in addition to the common area maintenance charges
              payable in respect of the said Premises. ant(s).
            </Typography>
          </Box>
        </Stack>
        <Stack sx={{ ml: "10px", mt: "10px" }}>
          <Box sx={{ display: "flex", gap: "10px" }}>
            <Box>
              <Typography sx={{ fontSize: "10px" }}>11.</Typography>
            </Box>
            <Stack spacing={0.5}>
              <Typography sx={{ fontSize: "10px" }}>
                TERMS OF TRANSFER
              </Typography>
              <Box sx={{ display: "flex", gap: "10px" }}>
                <Typography sx={{ fontSize: "10px" }}>11.1</Typography>
                <Typography sx={{ fontSize: "10px" }}>
                  The Applicant(s) shall not transfer or assign the Applicant(s)
                  interest or benefit under this Application untill the
                  possession of the Premises is taken by the Applicant(s)
                  (Lock-in-period). The Applicant(s) agree/undertake/confirm
                  that Applicant(s) shall not transfer the Premises to any other
                  person(s) during this Lock-in-period.
                </Typography>
              </Box>
              <Box sx={{ display: "flex", gap: "10px" }}>
                <Typography sx={{ fontSize: "10px" }}>11.2</Typography>
                <Typography sx={{ fontSize: "10px" }}>
                  Keeping in mind and to safeguard the exclusivity of the
                  Project, the Applicant(s) shall not transfer or assign the
                  Applicant(s) interest or benefit under this Application
                  without the prior written consent of the Developer.
                </Typography>
              </Box>
              <Box sx={{ display: "flex", gap: "10px" }}>
                <Typography sx={{ fontSize: "10px" }}>11.3</Typography>
                <Typography sx={{ fontSize: "10px" }}>
                  If the Applicant(s) wishes/desires to transfer or assign the
                  Applicant(s) interest or benefit under this Applicaton or part
                  with the possession of or interest in the Premises, the same
                  will be subject to the prior written consent of the Developer.
                  The Developer shall be entitled to charge administrative
                  charges, legal charges and other costs, charges and expenses
                  pertaining to such transfer and the Applicant(s) hereby agree
                  not to oppose or object to the stipulations of such charges
                  and/or such other terms and conditions as may be stipulated by
                  the Developer and shall pay and abide by the same. In the
                  event of any transfer or assignment of the Applicant(s)
                  interest or benefit under this Application or the Applicant(s)
                  part with the possession of or interest in the Premises,
                  without the prior written consent of the Developer such
                  Transfer/Assignment shall not be valid and binding upon the
                  Developer.
                </Typography>
              </Box>
              <Box sx={{ display: "flex", gap: "10px" }}>
                <Typography sx={{ fontSize: "10px" }}>11.4</Typography>
                <Typography sx={{ fontSize: "10px" }}>
                  The term “transfer” shall mean and include, handing over
                  possession of the premises to the third party, assignment of
                  the interest and benefits under this Agreement/Rights to the
                  Premises, sale of Premises, license/lease of Premises
                  (including all renewals/extensions/options, if any). Transfer
                  of shares of a company (if the Purchaser is a company), change
                  of control of the Developers of a Public Limited Company,
                  reconstitution of a Partnership Firm/LLP including but not
                  limited to, by adding new Partners or Resignation of any of
                  the Partners from the Firm/LLP or dissolving the Firm/LLP or
                  change in the commercial terms of the firm/LLP.
                </Typography>
              </Box>
              <Box sx={{ display: "flex", gap: "10px" }}>
                <Typography sx={{ fontSize: "10px" }}>11.5</Typography>
                <Typography sx={{ fontSize: "10px" }}>
                  In case, the Premises is tranferred/ assigned by the
                  Applicant(s) to the third party, subject to compliance of the
                  above provisions, Applicant(s) shall hold the third party
                  accountable/liable to all the terms of this Application
                  including all amounts outstanding or payable and due in the
                  future.
                </Typography>
              </Box>
            </Stack>
          </Box>
        </Stack>
        <Stack sx={{ ml: "10px", mt: "10px" }}>
          <Box sx={{ display: "flex", gap: "10px" }}>
            <Box>
              <Typography sx={{ fontSize: "10px" }}>12.</Typography>
            </Box>
            <Stack spacing={0.5}>
              <Typography sx={{ fontSize: "10px" }}>
                MEMBERSHIP OF ASSOCIATION OF PURCHASERS OF PREMISES
              </Typography>
              <Box sx={{ display: "flex", gap: "10px" }}>
                <Typography sx={{ fontSize: "10px" }}>12.1</Typography>
                <Typography sx={{ fontSize: "10px" }}>
                  Subject to the provisions of RERA, the Developer shall at its
                  own option form one or more
                  condominium/s/companies/societies/apex body/ies or such other
                  associations of the purchsers of the Premises in the Project
                  (the “said Association/s”) and the Applicant(s) agrees and
                  undertakes to become a member of the said Association and for
                  such the Applicant(s) shall sign all papers as may from time
                  to time be purpose required in respect thereof. The Applicant
                  also agrees and undertakes to sign any other writing or
                  writings and all necessary forms and papers for the purpose of
                  formation and registration of the said Association/s. The
                  Applicant(s) shall observe the rules and regulations that may
                  from time to time be framed by the Owner/Developer for the
                  purpose of management of the Project and the bye-laws, rules
                  and regulations of the said Association/s and amendments and
                  modifications thereto from time to time. The Applicant(s)
                  agrees that on or before taking possession of the Premises,
                  the Applicant(s) shall keep deposited with the developer in
                  advance various deposits/amounts including legal charges,
                  share money, society formation, charges, advance common area
                  maintenance charges, application/entrance fee etc. in respect
                  of the said Association/s and various utility deposits.
                </Typography>
              </Box>
            </Stack>
          </Box>
        </Stack>
      </Box>

      <Box
        sx={{
          display: "flex",
          gap: "40px",
          justifyContent: "center",
          position: "absolute",
          bottom: "30px",
        }}
      >
        <Box className={classes.input}>
          <Box>
            <input
              style={{ textAlign: "center" }}
              value={pageData15.sign_first}
              type="text"
              name="sign_first"
              onChange={handleChange}
            />
            <Typography sx={{ textAlign: "center", mt: "10px" }}>
              Signature of First Applicant
            </Typography>
          </Box>
        </Box>
        <Box className={classes.input}>
          <Box sx={{ display: "inline-block" }}>
            <input
              style={{ margin: "0px", textAlign: "center" }}
              value={pageData15.sign_second}
              type="text"
              name="sign_second"
              onChange={handleChange}
            />
            <Typography sx={{ textAlign: "center", mt: "10px" }}>
              Signature of Second Applicant
            </Typography>
          </Box>
        </Box>
        <Box className={classes.input}>
          <Box sx={{ display: "inline-block" }}>
            <input
              style={{ margin: "0px", textAlign: "center" }}
             value={pageData15.sign_third}
              type="text"
              name="sign_third"
              onChange={handleChange}
            />
            <Typography sx={{ textAlign: "center", mt: "10px" }}>
              Signature of Third Applicant
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default PdfOnePageFifteen;
