import React, { useContext, useEffect, useState } from 'react';
import { Box, Button, Stack, TextField, Typography } from "@mui/material";
import DoneIcon from '@mui/icons-material/Done';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import Pagination from '@mui/material/Pagination';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';


import { Link, useLocation, useNavigate } from "react-router-dom";
import axios from 'axios';

import { MainSidebar } from '../ProjectDashboard/DashboardComponents/MainSidebar';
import { Navbar } from '../ProjectDashboard/DashboardComponents/Navbar';
import { Apiaddress } from '../../../utils/consts';
import { BackeToOverviewBtn } from '../ProjectDashboard/CreateProject/BackeToOverviewBtn';
import currentProjectState from "../../../context/createproject/context";
import { ConfirmProject_steps } from '../ProjectDashboard/CreateProject/ConfirmProject_steps';


function EditProjectUnit() {

   ////get data from previous page 
   const location = useLocation();
   let data = location.state;
   console.log("preve data =>.>",data)
   let id = data?.floor_id!==undefined? data.floor_id:data.id
  // //needs to update
  // let data= {id:64,name:"floor 1",sequence_number:0,"unittemplates":[ {unit_no: 1,unit_name: "unit 1",unit_id: 70}],project:66};
  //  console.log("ffffffffff===>", data)

  let [current_data, setCurrentData] = useState(data)


  const [unitnum,setunitnum]=useState(1);
  
  const navigate = useNavigate(); 
  const [unitdata,setunitdata]=useState(data?.unittemplates? data.unittemplates:[]);

  const unitsList = ["Bedroom Details","Bathroom Details","Balcony Details","Pricing Details","Floor Plan Details","Media Details"];
  const [selectedUnit,setSelectedUnit] = useState("bedroomdetails");

//   ///get data of buliding templates 
// const cpContext = useContext(currentProjectState);
// const projectID = cpContext?.createProjectState?.project?.id;


//get project id from local storage;

const projectfromlocal = JSON.parse(localStorage.getItem("BuilderSelected"))
const projectID = projectfromlocal.selectedProjectID

useEffect(()=>{
  const floorurl = Apiaddress+"/api/projects/floortemplate/?floor_template_id="+id

   console.log(data);
  axios.get(floorurl)
  .then((res)=>{
        const data = res?.data
        console.log("updated data=>>>>>>>.",data)
        setunitdata(data?.floor_template[0].unittemplates)
        setCurrentData(data?.floor_template[0])
     })
     .catch((err)=>{
       console.log(err)
     })
 
},[])

  
  return (
    <>
    
<Stack direction="row" sx={{width:"100%",height:"auto" }}>
      {/* 1st stack */}
      <Stack sx={{minHeight:"100vh",width:"20%",boxSizing:"border-box",}}> {/*for project directory 20% and for sales dashboard 225*/}
        <MainSidebar active={"Project Directory"}/>
      </Stack>

     {/* 2nd stack */}
     <Stack direction="column" sx={{ backgroundColor:"#f9f9f9",width:"80%", padding:"25px",boxSizing:"border-box",}}  >  {/*  width for main sidebar 78px and for 80px*/}
      <Navbar editProject={true} />
      


      {/* <Typography sx={{marginTop:"20px", fontSize:"28px", fontWeight:"600", marginLeft:"10px", marginBottom:"50px"}}>
                    Unit  Overview 
           </Typography> */}
     {/* actual content  */}
    <Stack sx={{backgroundColor:"#f9f9f9", mt:"28px"}}>  {/* mt:"28px", */}

    <Stack direction="column"  sx={{  padding:"20px", backgroundColor:"#fff", marginRight:"0px"}}>

{/* confirm project unit content */}
  
<Stack direction="row" spacing={1.5}>
  <Stack direction="row" alignItems="center" spacing={1.5} sx={{margin:"70px 0px 70px 0px"}}>
     <Box  sx={{width:"29px", height:"29px",borderRadius:"50%", backgroundColor:"#FFA946", display:"flex", justifyContent:"center", alignItems:"center"}}>
        <DoneIcon sx={{color:"#fff", fontSize:"20px"}}/>
      </Box>
      <Typography sx={{fontSize:"18px", fontWeight:"500"}}>Project Home</Typography>
    </Stack>
    <Stack direction="row" alignItems="center" spacing={1.5} sx={{margin:"70px 0px 70px 0px"}}>
     <Box  sx={{width:"29px", height:"29px",borderRadius:"50%", backgroundColor:"#FFA946", display:"flex", justifyContent:"center", alignItems:"center"}}>
        <DoneIcon sx={{color:"#fff", fontSize:"20px"}}/>
      </Box>
      <Typography sx={{fontSize:"18px", fontWeight:"500"}}>Building 1</Typography>
    </Stack>
    <Stack direction="row" alignItems="center" spacing={1.5} sx={{margin:"70px 0px 70px 0px"}}>
     <Box  sx={{width:"29px", height:"29px",borderRadius:"50%", backgroundColor:"#FFA946", display:"flex", justifyContent:"center", alignItems:"center"}}>
        <DoneIcon sx={{color:"#fff", fontSize:"20px"}}/>
      </Box>
      <Typography sx={{fontSize:"18px", fontWeight:"500"}}>Wing 1</Typography>
    </Stack>
    <Stack direction="row" alignItems="center" spacing={1.5} sx={{margin:"70px 0px 70px 0px"}}>
     <Box  sx={{width:"29px", height:"29px",borderRadius:"50%", backgroundColor:"#FFA946", display:"flex", justifyContent:"center", alignItems:"center"}}>
        <DoneIcon sx={{color:"#fff", fontSize:"20px"}}/>
      </Box>
      <Typography sx={{fontSize:"18px", fontWeight:"500"}}>{data?.name}</Typography>
    </Stack>
    </Stack>

    <Box sx={{}}> 
      <Stack direction="row" sx={{margin:"0px 0px 0px 0px",display:"flex",alignItems:"center"}} >
        <Typography sx={{fontSize:"18px", fontWeight:"500"}}>Units</Typography>
        <Link to="/builder-edit-project-unit-add" state={current_data}>
        <AddIcon sx={{color:"#A2A3A6",fontSize:"18px",marginLeft:"10px",cursor:"pointer"}}
        />
        </Link>
      </Stack>

        <Stack spacing={2} sx={{margin:"20px 0px 0px 0px"}}>
          <Pagination count={unitdata.length} showFirstButton showLastButton onChange={(e,page)=>{setunitnum(page)}}/>
        </Stack>
     {/*  */}
      <Stack direction="row" sx={{margin:"30px 0px 0px 0px"}} >
        <Typography sx={{fontSize:"18px", fontWeight:"500",color:"#A2A3A6"}}>Unit {unitnum}</Typography>
        {/* <EditIcon sx={{color:"#A2A3A6",marginLeft:"10px"}}/> */}
      </Stack>

      <Stack direction="row" spacing={4} sx={{display:"flex",alignItems:"center",margin:"20px 0px 0px 0px"}} >
        {unitsList?.map((ele,index)=>{
          let id = ele.replaceAll(/\s/g,'').toLowerCase();
          console.log(id);
          return (
          <Box
           onClick={()=>{
            setSelectedUnit(id)
           }}
          sx={{width:"63px",height:"71px",background:selectedUnit===id?"#ffb12f":"#EAECF2",borderRadius:"7px",display:"flex",justifyContent:"center",alignItems:"center",textAlign:"center",'&:hover': {
            cursor: 'pointer',
         }}}>
              <Typography sx={{fontSize:"12px",fontWeight:"500",color:selectedUnit===id?"#fff":"#A2A3A6"}}>{ele}</Typography>
          </Box>
          )
        })}
         

      </Stack>

      <Stack direction="row" sx={{alignItems:"center"}}>
      <Box sx={{display:"flex",alignItems:"center",marginRight:"150px",'&:hover': {
            cursor: 'pointer',
         }}}
      onClick={()=>{
        navigate(-1)
      }}
      >
      <ArrowBackIcon/>
      <Typography sx={{fontSize:"18px", fontWeight:"500",margin:"0px 10px 0px 10px"}}>Back</Typography>
      </Box>


      <Box sx={{margin:"60px 0px 60px 0px"}}>
        <Link to={`/builder-edit-project-unit-${selectedUnit}`} state={{state:unitdata[unitnum-1],current_data:current_data}}>
          <Button 
          className="univercel_btn_class"
          variant="contained"
          sx={{padding:"16px 50px 16px 50px", color:"#fff",fontSize:"18px", fontWeight:"700"}}>
              Details 
          </Button>
        </Link>
         

      </Box>
      
      </Stack>
  </Box>
               

     </Stack>

     <BackeToOverviewBtn projectDirectory={true}/>

    </Stack>
      </Stack>
</Stack>

 
      
    </>
  )
}

export default EditProjectUnit



