import { Box, Stack, Typography } from "@mui/material";
import { flexBetweenWithItemsCenter } from "./styles/commonStyles";
import classes from "./styles/PageOne.module.css";
import React, {useState } from 'react';

const PdfOnePageEighteen = ({fileInfo,unitInfo}) => {

  const [pageData18,setPageData18]=useState({
    sign_first:"",
    sign_second:"",
    sign_third:"",
    sale_man_name:"",
    sale_man_sign:"",
    site_head_name:"",
    site_head_sign:"",
    vp_name:"",
    vp_sign:"",
    agm_name:"",
    agm_sign:"",
    appli_name:"",
    tower:"",
    type:"",
    carpet_sq:"",
    carpet_msq:"",
    addi_sq:"",
    addi_msq:"",
    park_req:"",
    total_allot:"",
    total_allot_fig:"",
    source_applicant:"",
  })

  let name,value;
  const handleChange=(e)=>{
    name=e.target.name;
    value=e.target.value;
    console.log(name," ",value);
    setPageData18({...pageData18,[name]:value});
  }

  return (
    <Box>
      <Box sx={{ mb: "50px" }}>
        <Typography sx={{ fontSize: "14px", fontWeight: 600 }}>
          SNAPSHOT
        </Typography>
        <Stack spacing={1} sx={{ mt: "20px" }}>
          <Box sx={{ display: "flex", gap: "20px" }}>
            <Typography sx={{ fontSize: "13px" }}>A</Typography>
            <Typography sx={{ fontSize: "13px" }}>
              Date of Booking :{" "}
              <span
                style={{
                  borderBottom: "2px dotted #000",
                  paddingBottom: "3px",
                  display: true ? "inline" : "inline-block",
                  minWidth: "100%",
                  lineHeight: "24px",
                  fontSize: "13px",
                }}
              >
               {fileInfo.created?.substring(0,10)}
              </span>
            </Typography>
          </Box>
          <Box sx={{ display: "flex", gap: "20px", flexDirection:"row"}} className={classes.input}>
            <Typography sx={{ fontSize: "13px" }}>B</Typography>
            <Typography sx={{ fontSize: "13px" }}>
              Name of Applicant(s) :{" "} </Typography>
              <input 
                style={{
                  borderBottom: "2px dotted #000",
                  paddingBottom: "3px",
                  display: true ? "inline" : "inline-block",
                  minWidth: "30%",
                  lineHeight: "24px",
                }}
                value={pageData18.appli_name}
                type="text"
                name="appli_name"
                onChange={handleChange}
              />
          </Box>
          <Box sx={{ display: "flex", gap: "20px" }}>
            <Typography sx={{ fontSize: "13px" }}>C</Typography>
            <Box sx={{ display: "flex", gap: "10px"}}>
              <Typography sx={{ fontSize: "13px", minWidth: "120px" }}>
                Premises Details :{" "}
              </Typography>
              <Stack spacing={0.5}>
                <Stack direction="row" spacing={0.5} className={classes.input}>
                <Typography sx={{ fontSize: "12px" }}>
                  Tower:{" "} </Typography>
                  <input
                    style={{
                      borderBottom: "2px dotted #000",
                      paddingBottom: "3px",
                      display: true ? "inline" : "inline-block",
                      minWidth: "100%",
                      lineHeight: "24px",
                      fontSize: "12px",
                    }}
                    value={pageData18.tower}
                    type="text"
                    name="tower"
                    onChange={handleChange}
                  />
                  </Stack>
                <Stack direction="row" spacing={0.5} className={classes.input}>
                <Typography sx={{ fontSize: "12px" }}>
                  Type:{" "} </Typography>
                  <input
                    style={{
                      borderBottom: "2px dotted #000",
                      paddingBottom: "3px",
                      display: true ? "inline" : "inline-block",
                      minWidth: "100%",
                      lineHeight: "24px",
                    }}
                    value={pageData18.type}
                    type="text"
                    name="type"
                    onChange={handleChange}
                  />
                </Stack>
                <Stack direction="row" spacing={0.5} className={classes.input}>
                <Typography sx={{ fontSize: "12px" }}>
                  Floor:{" "} </Typography>
                  <input
                    style={{
                      borderBottom: "2px dotted #000",
                      paddingBottom: "3px",
                      display: true ? "inline" : "inline-block",
                      minWidth: "100%",
                      lineHeight: "24px",
                      paddingLeft:"20px"
                    }}
                    value={unitInfo.floor_no}
                  />
                  </Stack>
                <Stack direction="row" spacing={0.5} className={classes.input}>
                <Typography sx={{ fontSize: "12px"}}>
                  Flat No:{" "}</Typography>
                  <input
                    style={{
                      borderBottom: "2px dotted #000",
                      paddingBottom: "3px",
                      display: true ? "inline" : "inline-block",
                      minWidth: "100%",
                      lineHeight: "24px",
                      marginLeft:"10px",
                      paddingLeft:"20px"
                    }}
                    value={unitInfo.unit_no}
                  />
                  </Stack>
              </Stack>
            </Box>
          </Box>
          <Box sx={{ display: "flex", gap: "20px" }}>
            <Typography sx={{ fontSize: "13px" }}>D</Typography>
            <Typography sx={{ fontSize: "13px" }}>
              Carpet Area :{" "}
              <input
                style={{
                  borderTop: "none",
                  borderLeft: "none",
                  borderRight: "none",
                  outline: "none",
                  borderBottom: "2px dotted #000",
                }}
                type="number"
                value={pageData18.carpet_sq}
                name="carpet_sq"
                onChange={handleChange}
              />{" "}
              <span>sq.ft. equivalent to</span>
              <input
                style={{
                  borderTop: "none",
                  borderLeft: "none",
                  borderRight: "none",
                  outline: "none",
                  borderBottom: "2px dotted #000",
                  paddingLeft:"10px"
                }}
                type="number"
                value={pageData18.carpet_msq}
                name="carpet_msq"
                onChange={handleChange}
              />
              <span>sq.mtrs.</span>
            </Typography>
          </Box>
          <Box sx={{ display: "flex", gap: "20px" }}>
            <Typography sx={{ fontSize: "13px" }}>E</Typography>
            <Typography sx={{ fontSize: "13px" }}>
              Additional Area:{" "}
              <input
                style={{
                  borderTop: "none",
                  borderLeft: "none",
                  borderRight: "none",
                  outline: "none",
                  borderBottom: "2px dotted #000",
                }}
                type="number"
                value={pageData18.addi_sq}
                name="addi_sq"
                onChange={handleChange}
              />{" "}
              <span>sq.ft. equivalent to</span>
              <input
                style={{
                  borderTop: "none",
                  borderLeft: "none",
                  borderRight: "none",
                  outline: "none",
                  borderBottom: "2px dotted #000",
                  paddingLeft:"10px"
                }}
                type="number"
                value={pageData18.addi_msq}
                name="addi_msq"
                onChange={handleChange}
              />
              <span>sq.mtrs.</span>
              <span style={{ fontSize: "12px", display: "block" }}>
                (Enclosed Balcony, FB, Exclusive Balcony, Deck, whichever is
                applicable)
              </span>
            </Typography>
          </Box>
          <Box sx={{ display: "flex", gap: "20px" }}>
            <Typography sx={{ fontSize: "13px" }}>F</Typography>
            <Typography sx={{ fontSize: "13px" }}>
              Car Parks Requested :{" "}
              <input
                style={{
                  borderTop: "none",
                  borderLeft: "none",
                  borderRight: "none",
                  outline: "none",
                  borderBottom: "2px dotted #000",
                }}
                type="number"
                value={pageData18.park_req}
                name="park_req"
                onChange={handleChange}
              />{" "}
              <span>(Nos.)</span>
            </Typography>
          </Box>

          <Box sx={{ display: "flex", gap: "20px" }}>
            <Typography sx={{ fontSize: "13px" }}>G</Typography>
            <Typography sx={{ fontSize: "13px", display: "flex", gap: "5px" }}>
              Total Allotment Premium Offered :{" "}
              <Box className={classes.input}>
                <input
                  style={{
                    borderBottom: "2px dotted #000",
                    paddingBottom: "3px",
                    display: true ? "inline" : "inline-block",
                    minWidth: "100%",
                    lineHeight: "24px",
                  }}
                  value={pageData18.total_allot}
                  type="text"
                  name="total_allot"
                  onChange={handleChange}
                />
                <span
                  style={{
                    fontSize: "10px",
                    display: "inline-block",
                    marginLeft: "5px",
                  }}
                >
                  {" "}
                  (In Words)
                </span>
                <br />
                <input
                  style={{
                    borderBottom: "2px dotted #000",
                    paddingBottom: "3px",
                    display: true ? "inline" : "inline-block",
                    minWidth: "100%",
                    lineHeight: "24px",
                  }}
                  value={pageData18.total_allot_fig}
                  type="number"
                  name="total_allot_fig"
                  onChange={handleChange}
                />
                <span
                  style={{
                    fontSize: "10px",
                    display: "inline-block",
                    marginLeft: "5px",
                  }}
                >
                  {" "}
                  (In Figures)
                </span>
              </Box>
            </Typography>
          </Box>
          <Box sx={{ display: "flex", gap: "20px" }} className={classes.input}>
            <Typography sx={{ fontSize: "13px" }}>H</Typography>
            <Typography sx={{ fontSize: "13px" }}>
              Name of Applicant(s) :{" "} </Typography>
              <input
                style={{
                  borderBottom: "2px dotted #000",
                  paddingBottom: "3px",
                  display: true ? "inline" : "inline-block",
                  minWidth: "30%",
                  lineHeight: "24px",
                }}
                 value={pageData18.source_applicant}
                  type="text"
                  name="source_applicant"
                  onChange={handleChange}
              />
          </Box>
        </Stack>

        <Box sx={{ mt: "30px" }}>
          <Typography sx={{ fontSize: "13px" }}>
            Remarks:{" "}
            <span 
              style={{
                borderBottom: "2px solid #000",
                paddingBottom: "3px",
                display: true ? "inline" : "inline-block",
                minWidth: "100%",
              }}
            >
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Facere
              magnam esse alias qui in tempore, inventore aspernatur animi
              laudantium et quasi sed molestiae, ea veniam amet iusto dicta
              ipsam quam.
            </span>
          </Typography>
        </Box>

        <Stack spacing={2} sx={{ my: "30px" }}>
          <Box sx={{ ...flexBetweenWithItemsCenter, width: "50%" }}>
            <Box className={classes.input} sx={{ display: "flex" }}>
              <Box sx={{ display: "inline-block" }}>
                <input
                  style={{ margin: "0px", textAlign: "center" }}
                  value={pageData18.sale_man_name}
                  type="text"
                  name="sale_man_name"
                  onChange={handleChange}
                />
                <Typography sx={{ textAlign: "center", fontSize: "10px" }}>
                  Name of Sales Manager
                </Typography>
              </Box>
            </Box>
            <Box className={classes.input} sx={{ display: "flex" }}>
              <Box sx={{ display: "inline-block" }}>
                <input
                  style={{ margin: "0px", textAlign: "center" }}
                  value={pageData18.sale_man_sign}
                  type="text"
                  name="sale_man_sign"
                  onChange={handleChange}
                />
                <Typography sx={{ textAlign: "center", fontSize: "10px" }}>
                  Signature
                </Typography>
              </Box>
            </Box>
          </Box>
          <Box sx={{ ...flexBetweenWithItemsCenter, width: "50%" }}>
            <Box className={classes.input} sx={{ display: "flex" }}>
              <Box sx={{ display: "inline-block" }}>
                <input
                  style={{ margin: "0px", textAlign: "center" }}
                  value={pageData18.site_head_name}
                  type="text"
                  name="site_head_name"
                  onChange={handleChange}
                />
                <Typography sx={{ textAlign: "center", fontSize: "10px" }}>
                  Name of Site Head
                </Typography>
              </Box>
            </Box>
            <Box className={classes.input} sx={{ display: "flex" }}>
              <Box sx={{ display: "inline-block" }}>
                <input
                  style={{ margin: "0px", textAlign: "center" }}
                  value={pageData18.site_head_sign}
                  type="text"
                  name="site_head_sign"
                  onChange={handleChange}
                />
                <Typography sx={{ textAlign: "center", fontSize: "10px" }}>
                  Signature
                </Typography>
              </Box>
            </Box>
          </Box>
          <Box sx={{ ...flexBetweenWithItemsCenter, width: "50%" }}>
            <Box className={classes.input} sx={{ display: "flex" }}>
              <Box sx={{ display: "inline-block" }}>
                <input
                  style={{ margin: "0px", textAlign: "center" }}
                  value={pageData18.vp_name}
                  type="text"
                  name="vp_name"
                  onChange={handleChange}
                />
                <Typography sx={{ textAlign: "center", fontSize: "10px" }}>
                  Name of VP Sales & Marketing
                </Typography>
              </Box>
            </Box>
            <Box className={classes.input} sx={{ display: "flex" }}>
              <Box sx={{ display: "inline-block" }}>
                <input
                  style={{ margin: "0px", textAlign: "center" }}
                  value={pageData18.vp_sign}
                  type="text"
                  name="vp_sign"
                  onChange={handleChange}
                />
                <Typography sx={{ textAlign: "center", fontSize: "10px" }}>
                  Signature
                </Typography>
              </Box>
            </Box>
          </Box>
          <Box sx={{ ...flexBetweenWithItemsCenter, width: "50%" }}>
            <Box className={classes.input} sx={{ display: "flex" }}>
              <Box sx={{ display: "inline-block" }}>
                <input
                  style={{ margin: "0px", textAlign: "center" }}
                  value={pageData18.agm_name}
                  type="text"
                  name="agm_name"
                  onChange={handleChange}
                />
                <Typography sx={{ textAlign: "center", fontSize: "10px" }}>
                  Name of AGM / VP (CRM)
                </Typography>
              </Box>
            </Box>
            <Box className={classes.input} sx={{ display: "flex" }}>
              <Box sx={{ display: "inline-block" }}>
                <input
                  style={{ margin: "0px", textAlign: "center" }}
                  value={pageData18.agm_sign}
                  type="text"
                  name="agm_sign"
                  onChange={handleChange}
                />
                <Typography sx={{ textAlign: "center", fontSize: "10px" }}>
                  Signature
                </Typography>
              </Box>
            </Box>
          </Box>
        </Stack>
      </Box>

      <Box
        sx={{
          display: "flex",
          gap: "40px",
          justifyContent: "center",
          position: "absolute",
          bottom: "30px",
        }}
      >
        <Box className={classes.input}>
          <Box>
            <input
              style={{ textAlign: "center" }}
              value={pageData18.sign_first}
              type="text"
              name="sign_first"
              onChange={handleChange}
            />
            <Typography sx={{ textAlign: "center", mt: "10px" }}>
              Signature of First Applicant
            </Typography>
          </Box>
        </Box>
        <Box className={classes.input}>
          <Box sx={{ display: "inline-block" }}>
            <input
              style={{ margin: "0px", textAlign: "center" }}
              value={pageData18.sign_second}
              type="text"
              name="sign_second"
              onChange={handleChange}
            />
            <Typography sx={{ textAlign: "center", mt: "10px" }}>
              Signature of Second Applicant
            </Typography>
          </Box>
        </Box>
        <Box className={classes.input}>
          <Box sx={{ display: "inline-block" }}>
            <input
              style={{ margin: "0px", textAlign: "center" }}
              value={pageData18.sign_third}
              type="text"
              name="sign_third"
              onChange={handleChange}
            />
            <Typography sx={{ textAlign: "center", mt: "10px" }}>
              Signature of Third Applicant
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default PdfOnePageEighteen;
