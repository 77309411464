import { Box, Button, CircularProgress, Stack, Typography } from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { Apiaddress } from "../../../utils/consts";
import { NavbarForDirectory } from "../../BuilderComponents/ProjectDashboard/DashboardComponents/NavbarForDirectory";
import { ProjectDashboardSidebar } from "../../BuilderComponents/ProjectDashboard/DashboardComponents/ProjectDashboardSidebar";
import { Availability } from "./Availability/Availability";
import { LinearStatusBar } from "./Components/LinearStatusBar";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import OccupancyStats from "./OccupancyStats";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";

import OccuPieMaker from './OccuPieChart';
 import { useNavigate } from "react-router-dom";

function Occupancy_Directory_w_sb() {

  const navigate = useNavigate();

  const [update, setUpdate] = useState(true);

   /// get selected project id from local storage
   let builderSelectedData = JSON.parse(localStorage.getItem("BuilderSelected"));
   let id = builderSelectedData.selectedProjectID;
 

  const [noOfUnit, setNoOfUnit] = useState();

  const [dat, setDat] = useState([
    { name: 'Available', value: 0, start: "#0085FF", end: "#65C5FF" },
    { name: 'Booked', value: 0, start: "#FF4267", end: "#FF7A93" },
    { name: 'Registered', value: 0, start: "#FFAF2A", end: "#FFC666" },
    { name: 'Reserved', value: 0, start: "#FFAF2A", end: "#FFC666" },
    { name: 'Unavailable', value: 0, start: "#FFAF2A", end: "#FFC666" },
  ]);

  const [vacancyData, setVacancyData] = useState([])


  const fetchStats = async () => {
    try {
        const res = await axios.get(
          Apiaddress + `/api/projects/vaccencystatusofproject/?project_id=${id}`
        );
      console.log("===========================.>",res?.data,id);
      setNoOfUnit(res?.data.total_units);

      setDat(
      [
    { name: 'Available', value:res.data?.Available, start: "#FF4267", end: "#FF4267",per:getPercent(res.data?.Available,res?.data.total_units) },
    { name: 'Booked', value:res.data?.Booked, start: "#FFAF2A", end: "#FFAF2A",per:getPercent(res.data?.Booked,res?.data.total_units) },
    { name: 'Registered', value:res.data?.Registered, start: "#87C7C6", end: "#87C7C6",per:getPercent(res.data?.Registered,res?.data.total_units) },
    { name: 'Reserved', value:res.data?.Reserved, start: "#105EAD", end: "#105EAD",per:getPercent(res.data?.Reserved,res?.data.total_units) },
    { name: 'Unavailable', value:res.data?.Unavailable, start: "#0890FE", end: "#0890FE",per:getPercent(res.data?.Unavailable,res?.data.total_units) },
      ]
    )

    } catch (err) {
      console.log("====================",err);
    }
  };

  useEffect(() => {
    fetchStats();
  },[]);

  let temp = ["Available","Booked","Registered","Reserved","Unavailable"]
  let color = ["#FF4267","#FFAF2A","#87C7C6","#105EAD","#0890FE"]

  const getPercent = (Aof, B)=>{
      let per = (Aof*100)/B
      return per.toFixed(2)
  } 

  const [data, setData] = useState(null);
  const [newRowData, setNewRowData] = useState([]);
  const [curpage, setcurpage] = useState(1);
  const [pageRange, setPageRange] = useState([0, 5]);
  const [pages, setPages] = useState([]);
  const [loading,setloading]= useState(true);
  const [CpUnits,setCpUnits]= useState([]);

  // console.log(CpUnits);

   const nextClick = () => {
    if (
      !(newRowData.length >= pageRange[0] && newRowData.length <= pageRange[1])
    ) {
      setcurpage(curpage + 1);
      setPageRange([pageRange[0] + 5, pageRange[1] + 5]);
    }
    };

  const prvClick = () => {
    if (pageRange[0] != 0 && pageRange[1] != 5) {
      setcurpage(curpage - 1);
      setPageRange([pageRange[0] - 5, pageRange[1] - 5]);
    }
    };

    useEffect(() => {
    if (newRowData) {
      let arr = [];
      for (
        let i = 1;
        i <=
        (newRowData.length % 5 == 0
          ? newRowData.length / 5
          : newRowData.length / 5 + 1);
        i++
      ) {
        arr.push(i);
      }
      setPages(arr);
    }
  }, [newRowData]);

  const getCP = async () => {
    try {
      const res = await axios.get(
        Apiaddress + "/api/role/cpofproject/?project_id="+id
      );
      console.log(res.data.cplist);
      if(res.data?.cplist=="channel partner not found"){
       setData([]);
      }
      else{
        setData(res.data.cplist);
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (data) {
      let arr = [];
      for (let i = 0; i < data.length; i++) {
        const obj = {
          id: i + 1,
          CPid: data[i]?.id,
          RERARegNo: data[i]?.rera_no,
          Type: data[i]?.cp_type,
          Name: data[i]?.profile?.user_details.first_name,
          Email: data[i]?.profile?.user_details.email,
          Phone: data[i]?.profile?.phone,
          profile_id: data[i]?.profile ? data[i]?.profile.id : 1,
          projects:data[i]?.projects,
          units:data[i]?.units
        };
        arr.push(obj);
      }
      setNewRowData(arr);
    }
  }, [data]);

  //fetch total Projects Units associated with CP
  const getProjectUnitcp = async () => {
    setloading(true);
     try{
        let tempcp=[];
        for(let j=0;j<newRowData?.length;j++){
          let units=newRowData[j].units;
          let tempUnitId=[]; 
          for(let i=0;i<units?.length;i++){
            const res1 = await axios.get(
              Apiaddress + `/api/projects/unit/?unit_id=${units[i]}`
              );
              // console.log("===========================.>",res1?.data?.unit[0]);
              let unitInfo=res1?.data?.unit[0];
              if(unitInfo?.project==id){
                tempUnitId.push(unitInfo?.id);
              }
          }
          let obj={
            Name:newRowData[j]?.Name,
            Phone:newRowData[j]?.Phone,
            units:tempUnitId,
            CPid:newRowData[j]?.CPid
          }
          tempcp.push(obj);
        }

      setCpUnits(tempcp);
      setloading(false);

      }catch (err) {
        console.log(err);
      }
    }

  useEffect(() => {
    if(newRowData){
      getProjectUnitcp();
    }
  }, [newRowData]);

  
  useEffect(() => {
    getCP();
  },[]);

  return (
    <>
      <Stack direction="row" sx={{ width: "100%", height: "auto" }}>
        {/* 1st stack */}
        <Stack
          sx={{ minHeight: "100vh", width: "22%", boxSizing: "border-box" }}
        >
          <ProjectDashboardSidebar active={"Occupancy Directory"} />
        </Stack>
        {/* 2nd stack */}
        <Stack
          direction="column"
          sx={{
            backgroundColor: "#f9f9f9",
            width: "78%",
            padding: "25px",
            boxSizing: "border-box",
          }}
        >
          <NavbarForDirectory
            RightBarNameleft={"Invite Channel Partner"}
            leftRightTitle2={"Occupancy"}
            leftTitle={"Project Dashboard"}
            showLeftTitle={false}
            righshow={true}
          />
          {/* actual content  */}
          <Stack sx={{ mt: "28px", backgroundColor: "#f9f9f9" }}>
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              sx={{ height: "68px", marginBottom: "20px" }}
            >
              <Stack direction="column" alignItems="center">
                <Typography
                  sx={{
                    fontSize: "22px",
                    fontWeight: "600",
                    marginLeft: "-45px",
                    color: "black",
                  }}
                >
                  Occupancy
                </Typography>
                <Typography
                  sx={{
                    color: "#B9B7D0",
                    fontSize: "16px",
                    fontWeight: "400",
                  }}
                >
                  Here is your Occupancy Status
                </Typography>
              </Stack>
            </Stack>
          </Stack>
          <Stack direction="row" sx={{ mt: "28px", backgroundColor: "#f9f9f9" }}>
            {/* <OccupancyStats setUpdate={setUpdate} update={update}/> */}

            {/* <Box
              sx={{
                boxShadow: 1,margin: 5,padding: 2,width: "45%",height: 280,borderRadius: 2,alignItems: "center",backgroundColor:"#fff"
              }}>
                 <Typography sx={{fontFamily:'Poppins',fontStyle: "normal",fontWeight: "600",fontSize: "18px",lineHeight: "20px",color:"#2979F2",marginBottom:"20px", }}>Occupancy Status</Typography>
                 <Stack direction="row" sx={{ height:"76%"}}>
                  <Stack direction="column" justifyContent="center" alignItems="center" sx={{width:"30%", }}>
                  <OccuPieMaker data={dat} />
                 <Typography sx={{fontFamily:'Poppins',fontStyle: "normal",fontWeight: "600",fontSize: "16px",lineHeight: "20px",color:"#EB6270",marginTop:"15px"}}>
                  {`Total Units:`}
                 </Typography>
                  </Stack>
                  <Stack direction="column" justifyContent="space-around"  sx={{width:"80%",  }}>
                    {temp.map((ele,index)=>{
                      return (
                    <Stack direction="row" justifyContent="space-between" alignItems="center" sx={{}}>
                      
                      <Typography sx={{color:"#3E4954", fontWeight:"500", fontSize:"16px", ml:"5%", width:"30%"}}>{ele}</Typography>
                      <Stack direction="row" justifyContent="space-between" alignItems="center" sx={{width:"70%",ml:"15%", }}>
                      <Box sx={{width:"70%"}}>
                      <LinearStatusBar value={Math.random()*100} color={color[index]}/>
                      </Box>
                      <Typography>00%</Typography>
                      </Stack>

                    </Stack>
                      )
                    })}

                  </Stack>

                 </Stack>
            </Box> */}

              <Availability/>

             <Box
              sx={{
                boxShadow: 1,margin: 5,padding: 2,width: "45%",height: 130,borderRadius: 2,alignItems: "center",backgroundColor:"#fff"
              }}>
                 <Typography sx={{fontFamily:'Poppins',fontStyle: "normal",fontWeight: "600",fontSize: "18px",lineHeight: "20px",color:"#2979F2",marginBottom:"20px", }}>
                  Sale Team View
                 </Typography>
                 <Stack direction="column" justifyContent="space-around"  sx={{width:"80%", height:"80%" }}>
                 {dat.map((ele,index)=>{
                     if(ele.name=="Available" || ele.name=="Unavailable"){ return (
                    <Stack direction="row" justifyContent="space-between" alignItems="center" sx={{}}>
                      
                      <Typography sx={{color:"#3E4954", fontWeight:"500", fontSize:"16px",  width:"30%"}}>{ele.name}</Typography>
                      <Stack direction="row" justifyContent="space-between" alignItems="center" sx={{width:"100%",ml:"15%"}}>
                      <Box sx={{width:"70%"}}>
                      <LinearStatusBar value={ele.per} color={ele.start}/>
                      </Box>
                      <Typography>{ele.value}</Typography>
                      </Stack>

                    </Stack>
                      )
                     }
                    })}
                 </Stack>

              </Box>

          </Stack>  
          
          <Stack sx={{border:"1px solid lightgray",margin:"10px 20px",backgroundColor:"white",padding:"0px 25px"}}>

            <Typography sx={{margin:"20px 0px 25px 0px", fontSize:"22px", fontWeight:"600",color:"#2979F2"}}>Channel Partner View</Typography>
          
            {/* CP list table */}
            <Box sx={{height:"500px",width:"100%"}}>
              <Box sx={{ color: "black", width: "100%" ,boxSizing:"border-box",display:"flex",flexDirection:"column"}}>

                <Stack direction="row" justifyContent="space-around" alignItems="center" sx={{width:"100%",backgroundColor:"white",borderRadius: "5px",margin:"0px 0px 10px 0px",padding:"10px 0px 10px 0px",border:"1px solid lightgray"}}>
              
                <Stack direction="row" justifyContent="start" alingItems="center" sx={{width:"14%",textAlign:"center",color: "#9299BC",fontWeight: "600"}}>
                        <Typography sx={{ display: "grid", placeItems: "center",color: "#272D4E",fontWeight: "700",fontFamily:"Circular Std",fontSize:"18px" }}>Name</Typography>
                </Stack>

                 <Stack direction="row" justifyContent="start" alingItems="center" sx={{width:"14%",textAlign:"center",color: "#9299BC",fontWeight: "600"}}>
                        <Typography sx={{ display: "grid", placeItems: "center",color: "#272D4E",fontWeight: "700",fontFamily:"Circular Std",fontSize:"18px"  }}>Phone No.</Typography>
                </Stack>

                 <Stack direction="row" justifyContent="start" alingItems="center" sx={{width:"14%",textAlign:"center",color: "#9299BC",fontWeight: "600"}}>
                        <Typography sx={{ display: "grid", placeItems: "center",color: "#272D4E",fontWeight: "700",fontFamily:"Circular Std",fontSize:"18px" }}>Assigned Unit</Typography>
                </Stack>

                <Stack direction="row" justifyContent="start" alingItems="center" sx={{width:"14%",textAlign:"center",color: "#9299BC",fontWeight: "600"}}>
                        <Typography sx={{ display: "grid", placeItems: "center",color: "#272D4E",fontWeight: "700",fontFamily:"Circular Std",fontSize:"18px"  }}>Action</Typography>
                </Stack>

                </Stack>
                
                {loading==true?
                  <Box sx={{display:"flex",height:"80vh",padding:"10% 5% 5% 45%"}}>
                    <CircularProgress color="inherit" sx={{ color: 'grey.500' }}/>
                  </Box>
                  :
            <Box sx={{}}>
                {CpUnits.length==0?
                    <Box sx={{textAlign:"center",marginTop:"50px"}}>
                        <Typography sx={{fontSize:"25px",fontWeight:"500",color:"#B9B7D0",}}>No Matches Found</Typography>
                    </Box>:
                    <Box sx={{}}>
                      <Box sx={{height:"365px",overflowY:"scroll"}}>
                        {CpUnits && CpUnits?.map((item, index) => {
                            if (index >= pageRange[0] && index <= pageRange[1]) {
                                return (
                                    
                                    <>
                                      <Stack direction="row" justifyContent="space-around" alignItems="center" sx={{width:"100%",borderRadius: "5px",margin:"0px 0px 10px 0px",padding:"10px 0px 10px 0px",backgroundColor:"white",cursor:"pointer"}} 
                                       onClick={(e) => {
                                            e.stopPropagation();
                                            navigate(`/project-cp-assignunit/${item.CPid}`, {
                                            state: item,
                                            });
                                          }}
                                      >

                                       <Stack direction="row" justifyContent="start" alingItems="center" sx={{width:"14%",textAlign:"center",color: "#9299BC",fontWeight: "600",}}>
                                        <Typography sx={{fontFamily:"Product Sans",color:"#343434",fontWeight:"400"}}>
                                           {item?.Name}
                                        </Typography>
                                       </Stack>
                                       <Stack direction="row" justifyContent="start" alingItems="center" sx={{width:"14%",textAlign:"center",color: "#9299BC",fontWeight: "600",}}>
                                        <Typography sx={{fontFamily:"Product Sans",color:"#343434",fontWeight:"400"}}>
                                          {item?.Phone}
                                        </Typography>
                                       </Stack>
                                       <Stack direction="row" justifyContent="center" alingItems="center" sx={{width:"14%",textAlign:"center",color: "#9299BC",fontWeight: "600",}}>
                                        <Typography sx={{fontFamily:"Product Sans",color:"#343434",fontWeight:"400"}}>
                                          {item?.units?.length}  
                                        </Typography>
                                       </Stack>
                                       <Stack direction="row" justifyContent="start" alingItems="center" sx={{width:"14%",textAlign:"center",color: "#9299BC",fontWeight: "600",}}>
                                        <RemoveRedEyeIcon
                                          fontSize="medium"
                                          sx={{cursor:"pointer",paddingLeft:"15px",color:"#343434",}}

                                            onClick={(e) => {
                                              e.stopPropagation();
                                              navigate(`/project-cp-assignunit/${item.CPid}`, {
                                              state: item,
                                              });
                                            }}

                                          />
                                       </Stack>
                                    </Stack>
                                    </>  
                                );
                            }
                        })}
                      </Box>

        <Box sx={{margin:"20px 0px 20px 0px"}}>
          <Box sx={{ display: "flex", justifyContent: "right" }}>
            {newRowData.length!=0 && 
            <Button variant="contained" sx={{background:"linear-gradient(90deg, #0F5DAE 0%, #009DCF 100.33%)"}}
              onClick={prvClick}>
                <ArrowBackIosIcon fontSize="small" />
                <Box sx={{ textTransform: "capitalize" }}>Previous</Box>
            </Button>}

            <Box sx={{background: "#E3E4EB",display: "flex",placeItems: "center",margin: " 0 10px",borderRadius: "5px"}}>
                <Box sx={{background: `white`,color: "black",width: "30px",borderRadius: "5px",margin: "0 10px",display: "grid",placeItems: "center"}}>{curpage}</Box>
                <Box sx={{color: "black",width: "30px",borderRadius: "5px",margin: "0 0px",display: "grid",placeItems: "center"}}>of</Box>
                <Box sx={{background: `white`,color: "black",width: "30px",borderRadius: "5px",margin: "0 10px",display: "grid",placeItems: "center"}}>{pages?.length}</Box>
            </Box>

            {newRowData.length!=0 && 
                <Button variant="contained" sx={{background:"linear-gradient(90deg, #0F5DAE 0%, #009DCF 100.33%)"}}
                    onClick={nextClick}>
                    <Box sx={{ textTransform: "capitalize" }}>Next</Box>
                    <ArrowForwardIosIcon fontSize="small" />
                </Button>
            }
          </Box>
        </Box>
      </Box>
    }
    </Box>
    }


              </Box>
            </Box>
          </Stack>

        </Stack>
      </Stack>
    </>
  );
}

export default Occupancy_Directory_w_sb;
