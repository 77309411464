import { Stack } from "@mui/system";
import {
  IconButton,
  InputAdornment,
  OutlinedInput,
  Typography,
} from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import "../Signup.css";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import KeyboardDoubleArrowLeftIcon from "@mui/icons-material/KeyboardDoubleArrowLeft";

import { useForm } from "react-hook-form";
import { useState } from "react";
// import Visibility from '@material-ui/icons/Visibility';
// import VisibilityOff from '@material-ui/icons/VisibilityOff';
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import VisibilityIcon from "@mui/icons-material/Visibility";
import axios from "axios";
// import { Apiaddress } from "../../../../utils/consts";
import { api, Apiaddress, net } from "../../../../utils/consts";
import { AuthService } from "../../../../context/buyer/services/auth";

export const PasswordCreate = ({
  stepsno,
  setStepsno,
  signupdata,
  setSignupdata,
  selectedValue,
}) => {
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();

  const [pass, setpass] = useState("");
  console.log(signupdata);
  
  const createInventory=async (res,data)=>{
    try{
      let obj={};
        if(res?.data?.data?.orgId){
          obj.channel_partner=res?.data?.data?.cpId;
          obj.channelPartnerOrg=res?.data?.data?.orgId;
        }
        else{
          obj.channel_partner=res?.data?.data?.cpId;
        }
      const Auth = new AuthService();
      const headers = {
        headers: {
          "content-type": "multipart/form-data",
        },
      };
      await Auth.makeUnAuthenticatedPostRequest(
      net("role/createinventeryofcp/"),
        {
        JsonBody: obj
      }
    );
     storeTokenToLocal(data);
    }catch(err){
      console.log(err.message);
    }
  }

  const onSubmit =(data) => {
    console.log(data);
    console.log(signupdata);

    ////create password
    axios
      .post(Apiaddress + "/api/createpasswordCp/", {
        email: signupdata.email,
        phoneNo: signupdata.phoneNo,
        deviceToken: "token",
        password: data.password,
        account_type: selectedValue === "org" ? "Org" : "Individual",
        org_name: selectedValue === "org" ? signupdata.org : "",
        // org_address:selectedValue==='org'?signupdata.orgAddress:"",
        rera_no: signupdata.rera_no,
        address1: signupdata.address_line1,
        address2: signupdata.address_line2,
        state: signupdata.state,
        city: signupdata.city,
        pin: signupdata.pincode,
      })
      .then((res) => {
        console.log("res", res);
        createInventory(res,data);
        // storeTokenToLocal(data);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  const storeTokenToLocal = (data) => {
    ////login to get token for store locally in background
    axios
      .post(Apiaddress + "/api/auth/login/", {
        email: signupdata.email,
        password: data.password,
      })
      .then((res) => {
        console.log(res);
        localStorage.setItem("crm_token", res.data.token); //store to local storage
        setStepsno((prev) => prev + 1);
      })
      .catch((err) => {
        console.log("err:", err);
        alert("err");
      });
  };

  const [values, setValues] = useState({
    password: "",
    showPassword: false,
    confirmPassword: "",
    showConfirmPassword: false,
  });

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box sx={{ padding: "10px 46px", height: "70vh" }}>
          <Typography
            sx={{ marginBottom: "10px", fontSize: "20px", fontWeight: "400" }}
          >
            Please setup your Password
          </Typography>
          <ul style={{marginLeft:"-25px"}}>
            <li><Typography sx={{fontSize:"14px"}}>Password must be at least 8 characters long.</Typography></li>
            <li><Typography sx={{fontSize:"14px"}}>Password must contain at least one uppercase, one lowercase and a number.</Typography></li>
          </ul>
          <Typography
            sx={{
              fontWeight: "500",
              fontSize: "18px",
              fontFamily: "Product Sans",
            }}
          >
            Create Password
          </Typography>

          <Box
            sx={{ width: "40%", height: "50px", margin: "10px 0px 0px 0px" }}
          >
            <OutlinedInput
              id="outlined-adornment-password"
              type={values.showPassword ? "text" : "password"}
              // value={values.password}
              // onChange={handleChange('password')}
              {...register("password", {
                required: "password is required.",
                pattern: {
                  value: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/,
                  message:
                    "Password must contain at least one uppercase, one lowercase and a number ",
                },
                minLength: {
                  value: 8,
                  message: "Password must be at least 8 characters long",
                },
              })}
              onChange={(e) => setpass(e.target.value)}
              error={Boolean(errors.password)}
              helperText={errors.password && errors.password.message}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() =>
                      setValues({
                        ...values,
                        showPassword: !values.showPassword,
                      })
                    }
                    edge="end"
                  >
                    {values.showPassword ? (
                      <VisibilityIcon />
                    ) : (
                      <VisibilityOffIcon />
                    )}
                  </IconButton>
                </InputAdornment>
              }
              labelWidth={70}
            />
          </Box>
          {errors?.password ? (
            <Typography
              sx={{
                fontSize: "14px",
                fontWeight: "400",
                color: "#da6061",
                marginLeft: "10px",
              }}
            >
              {errors.password?.message}
            </Typography>
          ) : null}

          <Typography
            sx={{
              fontWeight: "500",
              fontSize: "18px",
              marginTop: "20px",
              fontFamily: "Product Sans",
            }}
          >
            Confirm Password
          </Typography>
          <Box
            sx={{ width: "40%", height: "50px", margin: "10px 0px 0px 0px" }}
          >
            {/* <input type="text" className="input_signup"/> */}
            <OutlinedInput
              id="outlined-adornment-password"
              type={values.showConfirmPassword ? "text" : "password"}
              {...register("confirmPassword", {
                required: "Confirm Password is required.",
                pattern: {
                  value: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/,
                  message:
                    "Password must contain at least one uppercase, one lowercase and a number ",
                },
                minLength: {
                  value: 8,
                  message: "Password must be at least 8 characters long",
                },
                validate: (value) => value === pass,
              })}
              error={Boolean(errors?.confirmPassword)}
              helperText={
                errors.confirmPassword && errors.confirmPassword.message
              }
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() =>
                      setValues({
                        ...values,
                        showConfirmPassword: !values.showConfirmPassword,
                      })
                    }
                    edge="end"
                  >
                    {values.showConfirmPassword ? (
                      <VisibilityIcon />
                    ) : (
                      <VisibilityOffIcon />
                    )}
                  </IconButton>
                </InputAdornment>
              }
              labelWidth={70}
            />
          </Box>
          {errors?.confirmPassword ? (
            <Typography
              sx={{
                fontSize: "14px",
                fontWeight: "400",
                color: "#da6061",
                marginLeft: "10px",
              }}
            >
              {errors.confirmPassword?.message}
            </Typography>
          ) : null}

          {/* <Typography sx={{fontWeight: "500",fontSize: "18px"}}>Phone Number</Typography>
                <Typography sx={{fontSize:"0.8vw", color:"gray"}}>An OTP will be send to this Number</Typography>
                <input type="text" className="input_signup"/> */}
        </Box>

        {/* <Box sx={{padding:"10px 46px", height:"70vh"}}>
                <Typography sx={{marginBottom:"20px", fontSize:"20px", fontWeight:"400"}}>Please setup your password</Typography>
                
                    

                <Typography sx={{fontWeight: "500",fontSize: "18px"}}>Create Password</Typography>

                <Box sx={{ width: "40%",  height:"50px", margin:"10px 0px 0px 0px" }}>
                <OutlinedInput
            id="outlined-adornment-password"
            type={values.showPassword ? 'text' : 'password'}
            value={values.password}
            onChange={handleChange('password')}
            {...register("password", {required:"password is required."})}
            error={Boolean(errors.password)}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={()=>setValues({ ...values, showPassword: !values.showPassword })}
                  edge="end"
                >
                  {values.showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                </IconButton>
              </InputAdornment>
            }
            labelWidth={70}
              />
                </Box> 
              {errors?.password?<Typography 
              sx={{fontSize:"14px",fontWeight:"400", color:"#da6061",marginLeft:"10px",}}
              >{errors.password?.message}</Typography>:null}


                <Typography sx={{fontWeight: "500",fontSize: "18px", marginTop:"20px"}}>Confirm Password</Typography>
                <Box sx={{ width: "40%",  height:"50px", margin:"10px 0px 0px 0px" }}>
                <input type="text" className="input_signup"/>
                <OutlinedInput
            id="outlined-adornment-password"
            type={values.showConfirmPassword ? 'text' : 'password'}
            {...register("confirmPassword", {required:"Confirm Password is required."})}
            error={Boolean(errors.confirmPassword)}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={()=>setValues({ ...values, showConfirmPassword: !values.showConfirmPassword })}
                  edge="end"
                >
                  {values.showConfirmPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                </IconButton>
              </InputAdornment>
            }
            labelWidth={70}
                />
                </Box> 
                {errors?.confirmPassword?<Typography 
              sx={{fontSize:"14px",fontWeight:"400", color:"#da6061",marginLeft:"10px",}}
              >{errors.confirmPassword?.message}</Typography>:null}

                <Typography sx={{fontWeight: "500",fontSize: "18px"}}>Phone Number</Typography>
                <Typography sx={{fontSize:"0.8vw", color:"gray"}}>An OTP will be send to this Number</Typography>
                <input type="text" className="input_signup"/>
                
            </Box> */}

        <Stack
          direction="row"
          justifyContent="right"
          spacing={0}
          sx={{ height: "10vh" }}
        >
          {stepsno > 0 && stepsno < 4 && (
            <Button
              className="univercel_btn_class"
              onClick={() => setStepsno((prev) => prev - 1)}
              variant="contained"
              startIcon={<KeyboardDoubleArrowLeftIcon />}
              color="primary"
              sx={{
                marginRight: "20px",
                width: "114px",
                height: "53px",
                textTransform: "none",
              }}
            >
              Previous
            </Button>
          )}
          {stepsno <= 3 && (
            <Button
              className="univercel_btn_class"
              type="submit"
              variant="contained"
              endIcon={<KeyboardDoubleArrowRightIcon />}
              color="primary"
              sx={{
                marginRight: "20px",
                height: "53px",
                textTransform: "none",
              }}
            >
              Next
            </Button>
          )}
        </Stack>
      </form>
    </>
  );
};
