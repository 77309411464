import { Box, List, ListItem, Typography } from "@mui/material";
import "./styles/PageSix.css";
import React, {useState } from 'react';
import { Stack } from "@mui/system";
import classes from "./styles/PageOne.module.css";

const PdfOnePageTwelve = () => {

  const [pageData12,setPageData12]=useState({
    sign_first:"",
    sign_second:"",
    sign_third:"",
  })

  let name,value;
  const handleChange=(e)=>{
    name=e.target.name;
    value=e.target.value;
    console.log(name," ",value);
    setPageData12({...pageData12,[name]:value});
  }

  return (
    <Box>
      <Box sx={{ mb: "40px" }}>
        <Stack spacing={0.5} sx={{ ml: "10px", mt: "5px" }}>
          <Box sx={{ display: "flex", gap: "10px" }}>
            <Typography sx={{ fontSize: "10px" }}>7.9</Typography>
            <Typography sx={{ fontSize: "10px" }}>
              The Applicant(s) declares and affirms that in case of joint
              allotment, failure to pay by anyone shall be deemed as failure to
              pay by both and all the Applicant(s) shall be treated as one
              single person/ entity for the purpose of this Application and all
              shall be liable for the consequences jointly as well as severally.
            </Typography>
          </Box>
          <Box sx={{ display: "flex", gap: "10px" }}>
            <Typography sx={{ fontSize: "10px" }}>7.10</Typography>
            <Typography sx={{ fontSize: "10px" }}>
              No payments towards the Allotment Premium and/or other charges
              shall be accepted from any other parties/third parties other than
              the Applicant(s).
            </Typography>
          </Box>
          <Box sx={{ display: "flex", gap: "10px" }}>
            <Typography sx={{ fontSize: "10px" }}>7.11</Typography>
            <Typography sx={{ fontSize: "10px" }}>
              The preferantial location charges (PLC) are the charges levied in
              respect of certain types of premises owing to their description
              and location within the Project. The Applicant(s) agrees that in
              case he/ she have opted for premises of such description and
              location, he/she shall be liable to pay the PLC, computed on the
              basis of carpet area of such premises, as part of Allotment
              Premium.
            </Typography>
          </Box>
          <Box sx={{ display: "flex", gap: "10px" }}>
            <Typography sx={{ fontSize: "10px" }}>7.12</Typography>
            <Typography sx={{ fontSize: "10px" }}>
              The Applicant(s) is aware that the brickwork and flooring,
              external façade/ windows and partition wall, whether internal or
              external of the building, may be initiated by the Developer at any
              stage during the construction cycle simultaneously with casting of
              slabs and the Developer shall raise demands for the instalments in
              respect thereof simultaneously with demands for the instalments
              payable for commencement/ completion of slabs. The Applicant
              undertakes to make payments of instalments in respect of the same
              as and when demanded by the Developer and shall not dispute or
              challenge the said demands under any circumstances or any reason
              whatsoever.
            </Typography>
          </Box>
        </Stack>
        <Stack sx={{ ml: "10px", mt: "10px" }}>
          <Box sx={{ display: "flex", gap: "10px" }}>
            <Box>
              <Typography sx={{ fontSize: "10px" }}>8.</Typography>
            </Box>
            <Stack spacing={0.5}>
              <Typography sx={{ fontSize: "10px" }}>
                ACCEPTANCE OF APPLICATION
              </Typography>
              <Box sx={{ display: "flex", gap: "10px" }}>
                <Typography sx={{ fontSize: "10px" }}>8.1</Typography>
                <Typography sx={{ fontSize: "10px" }}>
                  The Applicant(s) is/are aware that the Developer has
                  availed/may avail financial assistance from the banks/
                  financial institutions (herein after referred to as
                  “Lender(s)” against the security of Project Property and the
                  buildings to be construed thereon and receivable from sale of
                  the apartments constructed in the Project.
                </Typography>
              </Box>
              <Box sx={{ display: "flex", gap: "10px" }}>
                <Typography sx={{ fontSize: "10px" }}>8.2</Typography>
                <Typography sx={{ fontSize: "10px" }}>
                  The Developer may also approach other bank/s or financial
                  institution/s for availing further financial assistance
                  against the security of the Project Property and the buildings
                  to be constructed thereon and receviables from sale of
                  apartments constructed in the Project. However, such
                  additional security created by the Developer shall not affect
                  the right and the interest of the Applicant(s) in relation to
                  the Premises.
                </Typography>
              </Box>
              <Box sx={{ display: "flex", gap: "10px" }}>
                <Typography sx={{ fontSize: "10px" }}>8.3</Typography>
                <Typography sx={{ fontSize: "10px" }}>
                  The Developer shall be entitled to offer receivables from the
                  Premises as security to any credit/ financial institution,
                  bank or other person/body.
                </Typography>
              </Box>
              <Box sx={{ display: "flex", gap: "10px" }}>
                <Typography sx={{ fontSize: "10px" }}>8.4</Typography>
                <Typography sx={{ fontSize: "10px" }}>
                  In the event the Applicant(s) obtains a housing loan from any
                  bank/ financial institution for payment of the Allotment
                  Premium (or part thereof) in respect of Premises, the same
                  shall be subject to issuance of prior written consent by the
                  Developer in favour of the bank/ financial institution and
                  subject to such terms and conditions as may be prescribed by
                  the Developer. It shall be the sole responsibility of the
                  Applicant(s) to ensure timely repayment of loan amount and the
                  Developer shall not be liable or responsible for repayment of
                  such loan amount or such part thereof to the concerned
                  bank/financial institution. The Applicant(s) shall indemnify
                  and keep indemnified the Developer from and against all
                  claims, cost, expenses, damages, actions, charges and losses
                  which the Developer may suffer or incur by reason of any
                  action that the bank/ financial institute may initiate on
                  account of such loan or for the recovery of the loan amount or
                  part thereof. The Applicant(s) shall solely be responsible and
                  liable to ensure timely payment of the Allotment Premium (or
                  part thereof) to the Developer as and when due, even if the
                  loan or part therof is not disbursed by bank/financial
                  institution.
                </Typography>
              </Box>
              <Box sx={{ display: "flex", gap: "10px" }}>
                <Typography sx={{ fontSize: "10px" }}>8.5</Typography>
                <Typography sx={{ fontSize: "10px" }}>
                  It is further agreed that the Developer shall have charge/
                  lien over the Premises against the amount due and payable by
                  the Applicant(s) to the Developer towards the balance agreed
                  consideration, GST and/or any other tax, levies, duty, cess,
                  surcharge, premium, penalties etc. relating to this
                  transaction. It is futher agreed that the charge of the
                  Developer over the Premises shall be prior or superior to the
                  charge created by the Applicant(s) over the Premises in favour
                  of his/her/its/their bank/financial institution for availing
                  housing loan.
                </Typography>
              </Box>
            </Stack>
          </Box>
        </Stack>
        <Stack sx={{ ml: "10px", mt: "10px" }}>
          <Box sx={{ display: "flex", gap: "10px" }}>
            <Box>
              <Typography sx={{ fontSize: "10px" }}>9.</Typography>
            </Box>
            <Stack spacing={0.5}>
              <Typography sx={{ fontSize: "10px" }}>
                EVENTS OF DEFAULT, CANCELLATION AND CONSEQUENCES
              </Typography>
              <Box sx={{ display: "flex", gap: "10px" }}>
                <Typography sx={{ fontSize: "10px" }}>9.1</Typography>
                <Typography sx={{ fontSize: "10px" }}>
                  In the event the Applicant(s) commits a breach of the terms
                  and conditions contained in this Application including (i)
                  payment of any part of the Application Monies and Other
                  Charges, if any, within the due date, or (ii) failure of the
                  Applicant(s) to execute the Agreement for Sale in the manner
                  mentioned in Clause 5.8 above, then without prejudice to the
                  right of the Developer to charge interest at the Interest Rate
                  and any other rights and remedies available to the Developer,
                  the Developer shall be entitled to at his own option and
                  discretion, reject this application of the Applicant(s).
                  Provided that, the Developer shall give 2 notices within 15
                  (fifteen) days each in writing to the Applicant(s)
                  (collectively the”Application Default Notices”), by
                  courier/Email/Registered post A.D. at the address provided by
                  the Applicant(s), of its intention to reject this Application
                  of the Premises with detail/s of the specific breach of terms
                  and conditions in respect of which it is intended to reject
                  this Application. If the Applicant(s) fails to rectify the
                  breach or breaches mentioned by the Developer within the
                  period of the last Application Default Notice, including
                  making full and final payment of any outstanding dues together
                  with interest at the Interest Rate thereon, then at the end of
                  the period mentioned in the Application Default Notice, the
                  Developer shall be entitled to reject this Application by
                  issuance of a written notice to the Applicant(s) (“Developer
                  Rejection Notice”), by courier/ Email/registered post A.D. at
                  the address provided by the Applicant(s). On the receipt of
                  the Developer Rejection Notice by the Applicant(s), this
                  Application of the Premises shall stand cancelled. On the
                  cancellation of this Application in the manner as stated in
                  this sub-clause, the Applicant(s) agree and confirm tht the
                  Developer shall be entitled to forfeit the amounts in
                  accordance with this Application Form abd thereafter, neither
                  party shall have any caims or demands against the other.
                </Typography>
              </Box>
            </Stack>
          </Box>
        </Stack>
      </Box>

      <Box
        sx={{
          display: "flex",
          gap: "40px",
          justifyContent: "center",
          position: "absolute",
          bottom: "30px",
        }}
      >
        <Box className={classes.input}>
          <Box>
            <input
              style={{ textAlign: "center" }}
              value={pageData12.sign_first}
              type="text"
              name="sign_first"
              onChange={handleChange}
            />
            <Typography sx={{ textAlign: "center", mt: "10px" }}>
              Signature of First Applicant
            </Typography>
          </Box>
        </Box>
        <Box className={classes.input}>
          <Box sx={{ display: "inline-block" }}>
            <input
              style={{ margin: "0px", textAlign: "center" }}
              value={pageData12.sign_second}
              type="text"
              name="sign_second"
              onChange={handleChange}
            />
            <Typography sx={{ textAlign: "center", mt: "10px" }}>
              Signature of Second Applicant
            </Typography>
          </Box>
        </Box>
        <Box className={classes.input}>
          <Box sx={{ display: "inline-block" }}>
            <input
              style={{ margin: "0px", textAlign: "center" }}
              value={pageData12.sign_third}
              type="text"
              name="sign_third"
              onChange={handleChange}
            />
            <Typography sx={{ textAlign: "center", mt: "10px" }}>
              Signature of Third Applicant
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default PdfOnePageTwelve;
