import { Box, TextField, Typography } from "@mui/material";
import React from "react";
import { BlueButton } from "../../../../../../utils/common/BlueButton";

const WithTrailingInput = (props: any) => {
  const [State, setState] = React.useState({
    type: "withtrailing", // normal || withtrailing || radio || check || doubletrailing
    name: "Enter Field Name",
    trailing: "Sq.ft",
    value1: "",
    value2: "",
  });

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        padding: "10px",
        justifyContent: "center",
        alignItems: "flex-start",
        gap: "20px",
        marginLeft: "16px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "flex-start",
          gap: "8px",
          flexDirection: "column",
        }}
      >
        <h3 style={{ textTransform: "capitalize" }}>{State.name}</h3>
        <TextField
          value={State.name}
          onChange={(e) => {
            setState({ ...State, name: e.target.value });
          }}
          sx={{ textTransform: "capitalize" }}
        ></TextField>
      </Box>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          gap: "5px",
        }}
      >
        <TextField
          value={State.trailing}
          onChange={(e) => {
            setState({ ...State, trailing: e.target.value });
          }}
        ></TextField>
        <h3>{State.trailing}</h3>
      </Box>
      <Box
        sx={{
          display: "flex",
          gap: "0.5rem",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <BlueButton
          onClick={() => {
            props.setData(State);
            props.setShow();
          }}
        >
          Save Field
        </BlueButton>
        <Typography
          onClick={() => {
            props.deleteSelf();
          }}
          sx={{
            color: "#B0AFAF",
            fontWeight: 500,
            cursor: "pointer",
            fontFamily: "Product Sans",
          }}
        >
          Delete
        </Typography>
      </Box>
    </div>
  );
};

export default WithTrailingInput;
