import React, { useContext, useEffect } from 'react';
import { TextField} from "@mui/material";
import { Link, useLocation } from 'react-router-dom';
import { useState } from 'react';

import { Box, Button, Typography } from "@mui/material"
import { Stack } from "@mui/system"
import DoneIcon from '@mui/icons-material/Done';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import { useNavigate } from "react-router-dom";
import Divider from '@mui/material/Divider';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';


import { MainSidebar } from '../ProjectDashboard/DashboardComponents/MainSidebar';
import { Navbar } from '../ProjectDashboard/DashboardComponents/Navbar';
import { Apiaddress } from '../../../utils/consts';
import { ConfirmProject_steps } from '../ProjectDashboard/CreateProject/ConfirmProject_steps';
import { BackeToOverviewBtn } from '../ProjectDashboard/CreateProject/BackeToOverviewBtn';
import currentProjectState from "../../../context/createproject/context";
import axios from 'axios';

function EditProjectWing_Create() {

     ////get data from previous page 
  const location = useLocation();
  const data = location.state;

  console.log("data===============>",data)

    const navigate = useNavigate();
    
    const [floornumber,setfloornumber]=useState();
    
    const [floordata,setfloordata]=useState([]);
    
    const [floortemp,setfloortemp]=useState([]);
    
    console.log(floordata);

    const [wingTemplatename,setWingTemplateName] = useState("");

    // const cpContext = useContext(currentProjectState);
    // const projectID = cpContext?.createProjectState?.project?.id;

    //get project id from local storage;  
  const projectfromlocal = JSON.parse(localStorage.getItem("BuilderSelected"))
  const projectID = projectfromlocal.selectedProjectID

    console.log(projectID);
    ///for get floor list 
    useEffect(()=>{          
      const url = Apiaddress+"/api/projects/floortemplatesbyproject/?project_id="+projectID;
      axios.get(url)
      .then((res)=>{
            console.log(res.data);
            const data = res.data
            if(data.floor_templates!=="floor templates not found")
            setfloortemp(res?.data?.floor_templates)
          })
      .catch((err)=>{
        console.log(err)
      })
    },[])
    
    // //function for create wing Templates
    const createWingTemplate = ()=>{
      console.log(floordata)
          const url = Apiaddress+"/api/projects/wingtemplate/";
          const data =  {
            name:wingTemplatename,
            project:projectID,
            floortemplate:floordata
            }

            if(wingTemplatename=="")return;

          axios.post(
            url,
             data
          )
          .then((res)=>{
            console.log("ctrate wing template=====>",res.data)
          })
          .catch((err)=>{
            console.log(err)
          })
        }


  useEffect(()=>{
    let data=[];
    for(let i=1;i<=floornumber;i++){
      let to_insert={
        floor_no:i,
        floor_name:"",
        floor_id:"",
      }
      data.push(to_insert);
      }
    setfloordata(data);
    console.log("flooredata:",data)

  },[floornumber]);

  return (
    <>

<Stack direction="row" sx={{width:"100%",height:"auto" }}>
      {/* 1st stack */}
      <Stack sx={{minHeight:"100vh",width:"20%",boxSizing:"border-box",}}> {/*for project directory 20% and for sales dashboard 225*/}
        <MainSidebar active={"Project Directory"}/>
      </Stack>

     {/* 2nd stack */}
     <Stack direction="column" sx={{ backgroundColor:"#f9f9f9",width:"80%", padding:"25px",boxSizing:"border-box",}}  >  {/*  width for main sidebar 78px and for 80px*/}
      <Navbar editProject={true}/>
      


      <Typography sx={{marginTop:"20px", fontSize:"28px", fontWeight:"600", marginLeft:"10px", marginBottom:"50px"}}>
                    Wing Template 
           </Typography>
     {/* actual content  */}
    <Stack sx={{backgroundColor:"#f9f9f9"}}>  {/* mt:"28px", */}

    <Stack direction="column"  sx={{  padding:"20px", backgroundColor:"#fff", marginRight:"0px"}}>

        

{/* Wing Template */}
<Box sx={{}}>
<Box sx={{paddingBottom:"40px"}}>
<Box sx={{display:"flex",alignItems:"center",margin:"30px 0px 0px 0px"}}>
<Typography component="span" className='field-name'>Wing Template Name</Typography>
<TextField
value={wingTemplatename}
onChange={(e)=>{
setWingTemplateName(e.target.value)
}}
sx={{
width:"188px",
marginLeft:"40px"
}}/>
</Box>
<Box sx={{display:"flex",alignItems:"center",margin:"30px 0px 0px 0px"}}>
<Typography component="span" className='field-name'>No of Floors in Wing </Typography>
<TextField
inputProps={{ min: 0}}
sx={{
width:"188px",
marginLeft:"40px"
}} type="Number" value={floornumber} onChange={(e)=>{setfloornumber(e.target.value)}}/>       
</Box>
</Box>
{/* <Box sx={{border:"0.5px solid #000000",opacity:"0.1",width:"1050px",marginLeft:"0px"}}></Box> */}
<Divider  />
<Box>
{floordata.map((ele,index)=>{
return(
<Box key={index} sx={{display:"flex",alignItems:"center",margin:"30px 0px 0px 70px"}}>
<Typography component="span" className='field-name'>Floor {ele.floor_no}</Typography>

<FormControl sx={{width:"188px", marginLeft:"40px"}}>
<Select
onChange={(e)=>{
  let arr = floordata;
  arr[index].floor_name =  floortemp[e.target.value].name
  arr[index].floor_id =  floortemp[e.target.value].id
  setfloordata([...arr])
}}
displayEmpty
inputProps={{ 'aria-label': 'Without label' }}
>
{floortemp.map((ele,index)=>{
return(
 <MenuItem key={index} value={index}>{ele.name}</MenuItem>
)
})}
</Select>
</FormControl>


{/* <Typography className='' sx={{margin:"0px 0px 0px 20px",fontFamily:'Poppins',fontStyle:"normal",fontWeight:"500",fontSize: "12px",lineHeight:"18px",color:"#A2A3A6"}}>Can't Find The Template?</Typography>
<Typography className='' sx={{margin:"0px 0px 0px 5px",fontFamily:'Poppins',fontStyle:"normal",fontWeight:"500",fontSize: "12px",lineHeight:"18px",color:"#FF6140",cursor:"pointer"}} onClick={()=>{navigate('/floortemplate')}}>Create New</Typography> */}
</Box>
)
})}

{floornumber>0 && 
<Link
onClick={()=>{
createWingTemplate();
}}
to="/builder-edit-project-wing"
state={data} >
<Button 
variant="contained"
color="primary" sx={{ height:"50px", background: "linear-gradient(90deg, #0F5DAE 0%, #009DCF 100.33%)",boxShadow: "0px 13.7981px 22.5788px rgba(50, 174, 137, 0.25)", textTransform: "none", borderRadius: "4px", width:"150px", fontSize:"15px",margin:"100px 0px 70px 70px"}}>
Save
</Button>  
</Link>
}               

</Box>

</Box>

      </Stack>

    </Stack>
      </Stack>
</Stack>

        



      
    </>
  )
}

export default EditProjectWing_Create;