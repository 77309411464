import { Box, Stack} from '@mui/system';
import React, { useEffect, useState } from 'react';
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { Accordion, AccordionDetails, AccordionSummary, Button, CircularProgress, FormControl, InputLabel, MenuItem, Select, TextField, Typography } from '@mui/material';
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import TuneOutlinedIcon from "@mui/icons-material/TuneOutlined";

import { SuperAdminSidebar } from '../SidebarNavbar/SuperAdminSidebar';
import { SuperAdminNavbar } from '../SidebarNavbar/SuperAdminNavbar';
import { Apiaddress } from "../../../utils/consts";
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';
import { GridExpandMoreIcon } from '@mui/x-data-grid';
import { UserRole } from '../../../UserRoles/UserRoles';

function SuperAdminBuilder() {

  ////get user roles from local storage and assign 
  let roles = JSON.parse(localStorage.getItem("user_roles"))
  let builder = roles["builder"];
  let org = roles["org"];
  const navigate = useNavigate();

   let  token  = localStorage.getItem("crm_token")
    console.log(token)
   const [curpage, setcurpage] = useState(1);
   const [pageRange, setPageRange] = useState([0, 9]);
   const [pages, setPages] = useState([]);

   const [loading,setloading]= useState(true);

   const [newRowData,setnewRowData]= useState([]);

    const nextClick = () => {
    if (
      !(newRowData.length >= pageRange[0] && newRowData.length <= pageRange[1])
    ) {
      setcurpage(curpage + 1);
      setPageRange([pageRange[0] + 9, pageRange[1] + 9]);
    }
    };

  const prvClick = () => {
    if (pageRange[0] != 0 && pageRange[1] != 9) {
      setcurpage(curpage - 1);
      setPageRange([pageRange[0] - 9, pageRange[1] - 9]);
    }
    };

    useEffect(() => {
    if (newRowData) {
      let arr = [];
      for (
        let i = 1;
        i <=
        (newRowData.length % 9 == 0
          ? newRowData.length / 9
          : newRowData.length / 9 + 1);
        i++
      ) {
        arr.push(i);
      }
      setPages(arr);
    }
  }, [newRowData]);
  
   //fetching builderList
  const fetchbuilderdata=async ()=>{
     const headers = {
        Authorization: `JWT ${token}`,
    };  
    const builderurl = Apiaddress+"/api/builderlist/"
    const res = await axios.get(builderurl,{headers});
    console.log("=============================",res.data.users);
    let temp=res.data.users;

    let arr=[];

    for(let i=0;i<temp.length;i++){

        let status="Pending"
        if(temp[i].is_active==true){
            status="Approved"
        }

        let obj={
            id:temp[i].id,
            name:temp[i].user_details.first_name,
            state:temp[i].state,
            city:temp[i].city,
            pincode:temp[i].pin_code,
            status:status,
            address1:temp[i].address_line1,
            address2:temp[i].address_line2,
            orglist:temp[i].orgList,
            buildeEmail:temp[i].user_details.email,
        }
        arr.push(obj);
    }
    setnewRowData(arr);
    setloading(false);
  }

  useEffect(()=>{
     
    fetchbuilderdata();
   
  },[])


  //fitering data starts:====
  const [filterselect, setfilterselect] = useState();
  const [filtervalue, setfiltervalue] = useState();

  const handleChanged = (event) => {
    setfilterselect(event.target.value);
    setfiltervalue("");
  };

  console.log(filterselect);
  console.log(filtervalue);

   const fetchfilter = async () => {
    
     const headers = {
        Authorization: `JWT ${token}`,
    };  
    const builderurl = Apiaddress+`/api/builderlist/?filterkey=${filterselect}&filtervalue=${filtervalue}`
    const res = await axios.get(builderurl,{headers});
    console.log(res.data);
    let temp=res.data.users;

    let arr=[];

    for(let i=0;i<temp.length;i++){

        let status="Pending"
        if(temp[i].is_active==true){
            status="Approved"
        }

        let obj={
            id:temp[i].id,
            name:temp[i].user_details.first_name,
            state:temp[i].state,
            city:temp[i].city,
            pincode:temp[i].pin_code,
            status:status,
            address1:temp[i].address_line1,
            address2:temp[i].address_line2,
            orglist:temp[i].orgList
        }
        arr.push(obj);
    }
    setnewRowData(arr);
    
  };



 
  return (
    <>
    <Stack direction="row" sx={{width:"100%",height:"auto" }}>
      {/* 1st stack */}
      <Stack sx={{minHeight:"100vh",width:"20%",boxSizing:"border-box",}}> {/*for project directory 20% and for sales dashboard 225*/}
        <SuperAdminSidebar active={"Builder Directory"}/>
      </Stack>

     {/* 2nd stack */}
     <Stack direction="column" sx={{ backgroundColor:"#f9f9f9",width:"80%", padding:"25px",boxSizing:"border-box",}}  >  {/*  width for main sidebar 78px and for 80px*/}
      <SuperAdminNavbar builder={true} />  {/*send props for get popup to verify otp from builder */}
      

      <Stack direction="row" justifyContent="space-between" alignItems="center" sx={{}}>
            <Box sx={{}}>
              <Typography sx={{marginTop:"10px", fontSize:"16px", fontWeight:"600", marginLeft:"0px", marginBottom:"0px",color:"#2979F2",fontWeight: "400"}}>
                Here is your Builders Directory list Data 
              </Typography>
              <Typography sx={{marginTop:"20px", fontSize:"28px", fontWeight:"600", marginLeft:"0px"}}>
                Builder Directory   
              </Typography>
              <Typography sx={{marginTop:"10px", fontSize:"16px", fontWeight:"600", marginLeft:"0px", marginBottom:"50px",color: "#9299BC",fontWeight: "500"}}>
                Here is your Builders Directory list associated with you 
              </Typography>
            </Box>

            <Accordion sx={{ width: "250px", boxShadow: "none",position:"absolute",right:"1%"}}>
                    <AccordionSummary expandIcon={<GridExpandMoreIcon/>} aria-controls="panel1a-content" id="panel1a-header">
                        <Typography sx={{ display: "flex", placeItems: "center" }}>
                            <TuneOutlinedIcon sx={{ color: "#2F4CDD"}} />
                            <Typography sx={{ marginLeft: "25px"}}>Fillters</Typography>
                        </Typography>
                    </AccordionSummary>

                    <AccordionDetails>
                        <Box sx={{textAlign:"center"}}>
                            <FormControl sx={{ width: "200px" }}>
                                <InputLabel id="demo-simple-select-label">Select</InputLabel>
                                <Select labelId="demo-simple-select-label" id="demo-simple-select" value={filterselect} label="Select"
                                    onChange={handleChanged}>

                                    {/* <MenuItem value={"name"}>Name</MenuItem> */}
                                    <MenuItem value={"state"}>State</MenuItem>
                                    <MenuItem value={"city"}>City</MenuItem>
                                    <MenuItem value={"pin_code"}>Pincode</MenuItem>
                                    <MenuItem value={"is_active"}>Status</MenuItem>

                                </Select>
                            </FormControl> 
                            {
                                filterselect=="is_active"?
                                    <FormControl sx={{ width: "200px", marginTop: "20px"}}>
                                        <InputLabel id="demo-simple-select-label">Choose</InputLabel>
                                        <Select labelId="demo-simple-select-label" id="demo-simple-select" value={filtervalue} label="Select" onChange={(e)=>{setfiltervalue(e.target.value)}}>
                                            <MenuItem value={'1'}>Approved</MenuItem>
                                            <MenuItem value={'0'}>Pending</MenuItem>
                                        </Select>
                                    </FormControl>:
                                    <TextField sx={{ marginTop: "20px",width:"92%" }} placeholder="Enter the value" value={filtervalue} onChange={(e)=>{setfiltervalue(e.target.value)}}/>

                                    
                            }
                            <Button onClick={fetchfilter} variant="contained" color="primary" sx={{ height:"40px", background: "linear-gradient(90deg, #0F5DAE 0%, #009DCF 100.33%)",boxShadow: "0px 13.7981px 22.5788px rgba(50, 174, 137, 0.25)", textTransform: "none", borderRadius: "4px", width:"100px", fontSize:"15px",margin:"20px 0px 10px 0px"}}>Submit
                            </Button>

                        </Box>
                    </AccordionDetails>

            </Accordion>
      </Stack>
      

     {/* actual content  */}
    <Stack sx={{backgroundColor:"#f9f9f9"}}>  {/* mt:"28px", */}

    {/* builder list table */}
    <Box sx={{height:"100vh",width:"100%"}}>
        <Box sx={{ color: "black", width: "100%" ,boxSizing:"border-box",display:"flex",flexDirection:"column"}}>

            <Stack direction="row" justifyContent="space-between" alignItems="center" sx={{width:"100%",backgroundColor:"white",borderRadius: "5px",margin:"0px 0px 10px 0px",padding:"10px 0px 10px 0px"}}>
              
                <Stack direction="row" justifyContent="center" alingItems="center" sx={{width:"14%",textAlign:"center",color: "#9299BC",fontWeight: "600"}}>
                        <Typography sx={{ display: "grid", placeItems: "center",fontWeight: "600",color:"black" }}>Name</Typography>
                        {/* <Box sx={{ display: "grid", marginLeft: "4px" }}>
                            <ArrowDropUpIcon fontSize="medium" sx={{ marginBottom: "-8px" }}/>
                            <ArrowDropDownIcon fontSize="medium" sx={{ marginTop: "-8px" }}/>
                        </Box> */}
                </Stack>

                 <Stack direction="row" justifyContent="center" alingItems="center" sx={{width:"14%",textAlign:"center",color: "#9299BC",fontWeight: "600"}}>
                        <Typography sx={{ display: "grid", placeItems: "center",fontWeight: "600",color:"black" }}>State</Typography>
                        {/* <Box sx={{ display: "grid", marginLeft: "4px" }}>
                            <ArrowDropUpIcon fontSize="medium" sx={{ marginBottom: "-8px" }}/>
                            <ArrowDropDownIcon fontSize="medium" sx={{ marginTop: "-8px" }}/>
                        </Box> */}
                </Stack>

                 <Stack direction="row" justifyContent="center" alingItems="center" sx={{width:"14%",textAlign:"center",color: "#9299BC",fontWeight: "600"}}>
                        <Typography sx={{ display: "grid", placeItems: "center",fontWeight: "600",color:"black" }}>City</Typography>
                        {/* <Box sx={{ display: "grid", marginLeft: "4px" }}>
                            <ArrowDropUpIcon fontSize="medium" sx={{ marginBottom: "-8px" }}/>
                            <ArrowDropDownIcon fontSize="medium" sx={{ marginTop: "-8px" }}/>
                        </Box> */}
                </Stack>

                 <Stack direction="row" justifyContent="center" alingItems="center" sx={{width:"14%",textAlign:"center",color: "#9299BC",fontWeight: "600"}}>
                        <Typography sx={{ display: "grid", placeItems: "center",fontWeight: "600",color:"black" }}>Pincode</Typography>
                        {/* <Box sx={{ display: "grid", marginLeft: "4px" }}>
                            <ArrowDropUpIcon fontSize="medium" sx={{ marginBottom: "-8px" }}/>
                            <ArrowDropDownIcon fontSize="medium" sx={{ marginTop: "-8px" }}/>
                        </Box> */}
                </Stack>

                 <Stack direction="row" justifyContent="center" alingItems="center" sx={{width:"14%",textAlign:"center",color: "#9299BC",fontWeight: "600"}}>
                        <Typography sx={{ display: "grid", placeItems: "center" ,fontWeight: "600",color:"black"}}>Status</Typography>
                        {/* <Box sx={{ display: "grid", marginLeft: "4px" }}>
                            <ArrowDropUpIcon fontSize="medium" sx={{ marginBottom: "-8px" }}/>
                            <ArrowDropDownIcon fontSize="medium" sx={{ marginTop: "-8px" }}/>
                        </Box> */}
                </Stack>

                 <Stack direction="row" justifyContent="center" alingItems="center" sx={{width:"14%",textAlign:"center",color: "#9299BC",fontWeight: "600"}}>
                        <Typography sx={{ display: "grid", placeItems: "center",fontWeight: "600",color:"black" }}>Detailed View</Typography>
                        {/* <Box sx={{ display: "grid", marginLeft: "4px" }}>
                            <ArrowDropUpIcon fontSize="medium" sx={{ marginBottom: "-8px" }}/>
                            <ArrowDropDownIcon fontSize="medium" sx={{ marginTop: "-8px" }}/>
                        </Box> */}
                </Stack>

            </Stack>

             {loading==true?
            <Box sx={{display:"flex",height:"80vh",padding:"10% 5% 5% 45%"}}>
                <CircularProgress color="inherit" sx={{ color: 'grey.500' }}/>
            </Box>
             :
            <Box sx={{}}>
                {newRowData.length==0?
                    <Box sx={{textAlign:"center",marginTop:"50px"}}>
                        <Typography sx={{fontSize:"25px",fontWeight:"500",color:"#B9B7D0",}}>No Matches Found</Typography>
                    </Box>:
                    <Box>
                        {newRowData && newRowData?.map((item, index) => {
                            if (index >= pageRange[0] && index <= pageRange[1]) {
                                return (
                                    
                                    <>

                                      {/* <Link
                                        to="/superadmin-org" state={item.orglist}
                                        > */}

                                      <Stack 
                                        onClick={(e)=>{
                                         e.stopPropagation();
                                         if(UserRole(org, "view_org")){
                                           console.log("builder details:=========>", item.buildeEmail )
                                           let builder = JSON.parse(localStorage.getItem("SABuilderDetails"))
                                           if(builder){
                                           console.log("builder is present", builder)
                                           let data = {
                                             ...builder,BuilderEmail:item.buildeEmail,orgList:item.orglist
                                           }
                                           console.log("jjjjjjjjjjj", data)
                                           localStorage.setItem("SABuilderDetails", JSON.stringify(data))
                                           }else{
                                           console.log("builder not present")
                                           localStorage.setItem("SABuilderDetails", JSON.stringify({
                                           BuilderEmail:item.buildeEmail,orgList:item.orglist
                                           }))
                                         }
                                         navigate("/superadmin-org")
                                         }
                                     }}
                                      direction="row" justifyContent="space-between" alignItems="center" sx={{width:"100%",borderRadius: "5px",margin:"0px 0px 10px 0px",padding:"10px 0px 10px 0px",backgroundColor:"white"}}>

                                       <Stack direction="row" justifyContent="center" alingItems="center" sx={{width:"14%",textAlign:"center",color: "#9299BC",fontWeight: "600"}}>
                                        <Typography >
                                           {item?.name}
                                        </Typography>
                                       </Stack>
                                       <Stack direction="row" justifyContent="center" alingItems="center" sx={{width:"14%",textAlign:"center",color: "#9299BC",fontWeight: "600"}}>
                                        <Typography >
                                          {item?.state}
                                        </Typography>
                                       </Stack>
                                       <Stack direction="row" justifyContent="center" alingItems="center" sx={{width:"14%",textAlign:"center",color: "#9299BC",fontWeight: "600"}}>
                                        <Typography >
                                          {item?.city}
                                        </Typography>
                                       </Stack>
                                       <Stack direction="row" justifyContent="center" alingItems="center" sx={{width:"14%",textAlign:"center",color: "#9299BC",fontWeight: "600"}}>
                                        <Typography >
                                           {item?.pincode}
                                        </Typography>
                                       </Stack>
                                       <Stack direction="row" justifyContent="center" alingItems="center" sx={{width:"14%",textAlign:"center",color: "#9299BC",fontWeight: "600"}}>
                                        <Typography sx={{color:item?.status=="Approved"?"green":"orange"}} >
                                           {item?.status}
                                        </Typography>
                                       </Stack>

                                       <Stack direction="row" justifyContent="center" alingItems="center" spacing={1.5} sx={{width:"14%",textAlign:"center"}}>
                                             {UserRole(builder, "change_builder") && 
                                             <Link 
                                             to="/superadmin-builderedit" state={item}
                                              onClick={(e)=>{
                                                  e.stopPropagation();
                                              }}
                                            >
                                                <EditIcon sx={{color: "#9299BC",cursor:"pointer"}}/>
                                             </Link>
                                             }
                                             {UserRole(builder, "view_builder") &&
                                             <Link 
                                                   to="/superadmin-builder-overview"
                                                   state={item} onClick={(e)=>{
                                                  e.stopPropagation();
                                                  console.log("builder details:=========>", item.buildeEmail )
                                                   let builder = JSON.parse(localStorage.getItem("SABuilderDetails"))
                                                   if(builder){
                                                    console.log("builder is present", builder)
                                                    let data = {
                                                      ...builder,BuilderEmail:item.buildeEmail,
                                                    }
                                                    console.log("jjjjjjjjjjj", data)
                                                    localStorage.setItem("SABuilderDetails", JSON.stringify(data))
                                                  }else{
                                                    console.log("builder not present") 
                                                    localStorage.setItem("SABuilderDetails", JSON.stringify({
                                                      BuilderEmail:item.buildeEmail
                                                    }))
                                                   }
                                                }}
                                                >
                                                <RemoveRedEyeIcon sx={{color: "#9299BC",cursor:"pointer"}}/>
                                              </Link>
                                             } 
                                             
                                            {/* <DeleteIcon sx={{color: "#9299BC"}}/> */}
                                        </Stack> 

                                    </Stack>
                                    
                                    </>  
                                );
                            }
                        })}

        <Box sx={{margin:"50px 0px 40px 0px"}}>
          <Box sx={{ display: "flex", justifyContent: "right" }}>
            {newRowData.length!=0 && 
            <Button variant="contained" sx={{background:"linear-gradient(90deg, #0F5DAE 0%, #009DCF 100.33%)"}}
              onClick={prvClick}>
                <ArrowBackIosIcon fontSize="small" />
                <Box sx={{ textTransform: "capitalize" }}>Previous</Box>
            </Button>}

            <Box sx={{background: "#E3E4EB",display: "flex",placeItems: "center",margin: " 0 10px",borderRadius: "5px"}}>
                <Box sx={{background: `white`,color: "black",width: "30px",borderRadius: "5px",margin: "0 10px",display: "grid",placeItems: "center"}}>{curpage}</Box>
                <Box sx={{color: "black",width: "30px",borderRadius: "5px",margin: "0 0px",display: "grid",placeItems: "center"}}>of</Box>
                <Box sx={{background: `white`,color: "black",width: "30px",borderRadius: "5px",margin: "0 10px",display: "grid",placeItems: "center"}}>{pages?.length}</Box>
            </Box>

            {newRowData.length!=0 && 
                <Button variant="contained" sx={{background:"linear-gradient(90deg, #0F5DAE 0%, #009DCF 100.33%)"}}
                    onClick={nextClick}>
                    <Box sx={{ textTransform: "capitalize" }}>Next</Box>
                    <ArrowForwardIosIcon fontSize="small" />
                </Button>
            }
          </Box>
        </Box>
      </Box>
    }
    </Box>
    }
    </Box>
    </Box>

  </Stack>
  </Stack>
  </Stack>

   
    </>
  )
}

export default SuperAdminBuilder