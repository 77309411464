import React from 'react'
import UnitProjects from '../../Units/components/units'

const ProjectDetailsWithUnts = ({ProjectID}:{ProjectID:number}) => {
const BuildingDetails ={
    projectName:"SkyScraper Flats",
    state:"Pune",
    projectDetails:{
        status:"Under construction",
        getPossessionBy:"Expected Possession on 10/10/2023"
    },
}


  return (
    <div>
        <UnitProjects projectId={ProjectID} clickable />

    </div>
  )
}

export default ProjectDetailsWithUnts