import {
  Box,
  Checkbox,
  Stack,
  Button,
  Accordion,
  AccordionSummary,
  MenuItem,
  TextField,
  Select,
  InputLabel,
  FormControl,
} from "@mui/material";
import React, { useEffect, useState } from "react";

import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import KeyboardDoubleArrowLeftIcon from "@mui/icons-material/KeyboardDoubleArrowLeft";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useNavigate } from "react-router-dom";
import ArrowDropDownCircleIcon from "@mui/icons-material/ArrowDropDownCircle";
import {
  ArrowDropDownIconSvg,
  ArrowDropUpIconSvg,
} from "../../../BuilderComponents/BuilderDasboard/assets/icons";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import { DataGrid, GridExpandMoreIcon } from "@mui/x-data-grid";
import CategoryIcon from "@mui/icons-material/Category";
import Lottie from "react-lottie";
import animationData from "../../../../Lotties/NEW sin movs.json";
import TuneOutlinedIcon from "@mui/icons-material/TuneOutlined";

function LDcustomTable({
  newRowData,
  delIconClick,
  updateBuyers,
  setTrigger,
  trigger,
  updateLead,
  onRowsSelectionHandler,
  toggleDrawer,
  rowSelected,
  setRowSelected,
  onView,
  loading,
  CircularProgress,
  handleOpen,
  setToDelete,
  setcurpage,
  setStartLead,
  startLead,
  totalLead,
  setMakePriority,
  filterselect,
  filtervalue,
  setfilterselect,
  setfiltervalue,
  handleChangeFilter,
  fetchfilter,
  curpage,
}) {
  ////get user roles from local storage and assign
  let roles = JSON.parse(localStorage.getItem("user_roles"));
  let lead = roles["lead"];
  let leadPriority = roles["lead prority"];

  const navigate = useNavigate();

  const [pageRange, setPageRange] = useState([0, 6]);
  const [pages, setPages] = useState([]);
  const [NoPages, setNopages] = useState(null);
  const [matchText, setmatchText] = useState(
    "Let's get started, Please add your data"
  );

  const nextClick = () => {
    if (curpage !== NoPages) {
      setStartLead(startLead + 7);
      setcurpage(curpage + 1);
      setTrigger(!trigger);
    }
  };

  const prvClick = () => {
    if (curpage !== 1) {
      setStartLead(startLead - 7);
      setcurpage(curpage - 1);
      setTrigger(!trigger);
    }
  };

  useEffect(() => {
    if (newRowData) {
      if (totalLead % 7 == 0) {
        let temp = totalLead / 7;
        setNopages(temp);
      } else {
        let temp = Math.floor(totalLead / 7 + 1);
        setNopages(temp);
      }
    }
  }, [newRowData]);

  const [curData, setcurData] = useState([]);
  const [selectedSortUp, setSelectedSortUp] = useState("");
  const [selectedSortDown, setSelectedSortDown] = useState("");
  useEffect(() => {
    if (newRowData) {
      setcurData(newRowData);
      setSelectedSortUp("");
      setSelectedSortDown("");
    }
  }, [newRowData]);

  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const sortArr = (para) => {
    setSelectedSortUp(para);
    setSelectedSortDown("");

    function compare(a, b) {
      let fir, sec;
      if (para == "name") {
        fir = a?.lead?.name;
        sec = b?.lead?.name;
      } else {
        fir = a?.lead?.lead_prority?.name;
        sec = b?.lead?.lead_prority?.name;
      }

      let fir1, sec1;

      fir1 = fir.toLowerCase();
      sec1 = sec.toLowerCase();

      console.log(fir1, "===", sec1);
      if (fir1 < sec1) {
        return -1;
      }
      if (fir1 > sec1) {
        return 1;
      }
      return 0;
    }

    let arr = [...curData];
    console.log(arr);
    arr.sort(compare);
    console.log(arr);

    setcurData(arr);
  };

  const sortArrDec = (para) => {
    setSelectedSortUp("");
    setSelectedSortDown(para);

    function compare(a, b) {
      let fir, sec;
      if (para == "name") {
        fir = a?.lead?.name;
        sec = b?.lead?.name;
      } else {
        fir = a?.lead?.lead_prority?.name;
        sec = b?.lead?.lead_prority?.name;
      }
      let fir1, sec1;

      fir1 = fir.toLowerCase();
      sec1 = sec.toLowerCase();

      if (fir1 < sec1) {
        return -1;
      }
      if (fir1 > sec1) {
        return 1;
      }
      return 0;
    }
    let arr = [...curData];
    arr.sort(compare);
    arr.reverse();
    console.log(arr);
    setcurData([...arr]);
  };

  const [expandedFilter, setExpandedFilter] = useState(false);

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <Box>
      {/* Button Container */}
      <Stack direction="row" justifyContent="right" alignItems="center" sx={{}}>
        <Stack
          direction="row"
          justifyContent="right"
          alignItems="center"
          sx={{
            width: "203px",
            height: "68px",
            borderRadius: "12px",
            color: "#3E4954",
          }}
        >
          <Accordion
            expanded={expandedFilter}
            sx={{ width: "150px", boxShadow: "none" }}
          >
            <AccordionSummary
              onClick={() => {
                setExpandedFilter(!expandedFilter);
              }}
              expandIcon={<GridExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography sx={{ display: "flex", placeItems: "center" }}>
                <TuneOutlinedIcon sx={{ color: "#2F4CDD" }} />
                <Typography sx={{ marginLeft: "10px" }}>Fillters</Typography>
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Box sx={{ textAlign: "center" }}>
                <FormControl sx={{ width: "118px" }}>
                  <InputLabel id="demo-simple-select-label">Select</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={filterselect}
                    label="Select"
                    onChange={handleChangeFilter}
                  >
                    <MenuItem value={"name"}>Name</MenuItem>
                    <MenuItem value={"email"}>Email</MenuItem>
                    <MenuItem value={"phone"}>Phone</MenuItem>
                  </Select>
                </FormControl>
                <TextField
                  sx={{ marginTop: "20px", fontSize: "8px" }}
                  placeholder="Value"
                  value={filtervalue}
                  onChange={(e) => {
                    setfiltervalue(e.target.value);
                  }}
                />
                <Button
                  onClick={() => {
                    fetchfilter();
                    setExpandedFilter(false);
                    setmatchText("No results found with the applied Filters");
                  }}
                  variant="contained"
                  className="univercel_btn_class"
                  color="primary"
                  sx={{ margin: "20px 0px 10px 0px" }}
                >
                  Submit
                </Button>
              </Box>
            </AccordionDetails>
          </Accordion>
          {expandedFilter == false && filtervalue && (
            <RestartAltIcon
              sx={{ margin: "0px 10px", cursor: "pointer" }}
              onClick={() => {
                setfiltervalue("");
                setStartLead(0);
                setmatchText("Let's get started, Please add your data");
                setcurpage(1);
                setTrigger(!trigger);
              }}
            />
          )}
        </Stack>
      </Stack>

      <Box
        sx={{
          color: "black",
          width: "100%",
          boxSizing: "border-box",
          height: "400px",
          "&:hover": {
            boxShadow: "-6px 18px 15px rgba(0, 0, 0, 0.1)",
          },
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            background: "white",
            borderRadius: "5px",
            color: "#272D4E",
            fontWeight: "700",
            fontFamily: "Circular Std",
            fontSize: "18px",
            gap: "15px",
            padding: "10px 20px",
            borderBottom: "1px solid #E9ECFF",
            borderRadius: "20px 20px 0 0",
          }}
        >
          <Box
            sx={{
              width: "22%",
              display: "flex",
              alignItems: "center",
            }}
          >
            <Box>Name</Box>
            <Box sx={{ display: "flex", flexDirection: "column", gap: "5px" }}>
              <Box
                sx={{
                  cursor: "pointer",
                  height: "10px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginLeft: "10px",
                }}
                onClick={() => sortArr("name")}
              >
                <ArrowDropUpIconSvg focus={selectedSortUp == "name"} />
              </Box>
              <Box
                sx={{
                  cursor: "pointer",
                  height: "10px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginLeft: "10px",
                }}
                onClick={() => sortArrDec("name")}
              >
                <ArrowDropDownIconSvg focus={selectedSortDown == "name"} />
              </Box>
            </Box>
          </Box>
          <Box
            sx={{
              width: "22%",
              display: "flex",
              alignItems: "center",
            }}
          >
            <Box>Phone</Box>
          </Box>
          <Box
            sx={{
              width: "22%",
              display: "flex",
              alignItems: "center",
            }}
          >
            <Box>Email</Box>
          </Box>
          <Box
            sx={{
              width: "22%",
              display: "flex",
              alignItems: "center",
            }}
          >
            <Box>Priority</Box>
            <Box sx={{ display: "flex", flexDirection: "column", gap: "5px" }}>
              <Box
                sx={{
                  cursor: "pointer",
                  height: "10px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginLeft: "10px",
                }}
                onClick={() => sortArr("Prioprity")}
              >
                <ArrowDropUpIconSvg focus={selectedSortUp == "Prioprity"} />
              </Box>
              <Box
                sx={{
                  cursor: "pointer",
                  height: "10px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginLeft: "10px",
                }}
                onClick={() => sortArrDec("Prioprity")}
              >
                <ArrowDropDownIconSvg focus={selectedSortDown == "Prioprity"} />
              </Box>
            </Box>
          </Box>
          <Box sx={{ width: "12%", textAlign: "center" }}>Actions</Box>
        </Box>

        {loading ? (
          <>
            <Box sx={{ textAlign: "center", marginTop: "50px" }}>
              <CircularProgress color="inherit" sx={{ color: "grey.500" }} />
            </Box>
          </>
        ) : newRowData.length == 0 ? (
          <>
            <Box sx={{ textAlign: "center", marginTop: "50px" }}>
              <Typography
                sx={{ fontSize: "25px", fontWeight: "500", color: "#B9B7D0" }}
              >
                <Lottie options={defaultOptions} height="20%" width="20%" />
                <Typography
                  sx={{
                    color: "#B9B7D0",
                    fontSize: "18px",
                    fontWeight: "400",
                    marginTop: "-40px",
                  }}
                >
                  {matchText}
                </Typography>
              </Typography>
            </Box>
          </>
        ) : (
          <>
            <Box sx={{ height: "400px" }}>
              {curData &&
                curData.map((item, index) => {
                  console.log("hhhhh", item);
                  if (index >= pageRange[0] && index <= pageRange[1]) {
                    return (
                      <Box
                        sx={{
                          backgroundColor: "#FFF",
                          padding: "15px 20px",
                          display: "flex",
                          alignItems: "center",
                          gap: "15px",
                          "&:hover": {
                            backgroundColor: "#F5F7FF",
                            cursor: "pointer",
                          },
                        }}
                        onClick={(e) => {
                          e.stopPropagation();
                          navigate("/channelpartner/client/" + item?.id);
                        }}
                      >
                        <Box
                          sx={{
                            width: "22%",
                            fontFamily: "Product Sans",
                            fontWeight: "400",
                          }}
                        >
                          {item?.lead?.name}
                        </Box>
                        <Box
                          sx={{
                            width: "22%",
                            fontFamily: "Product Sans",
                            fontWeight: "400",
                          }}
                        >
                          {item?.lead?.phone?item?.lead?.phone?.substring(3):"__"}
                        </Box>
                        <Box
                          sx={{
                            width: "22%",
                            fontFamily: "Product Sans",
                            fontWeight: "400",
                          }}
                        >
                          {item?.lead?.email?item?.lead?.email?.substring(0, 14) + "...":"__"}
                        </Box>
                        <Box
                          sx={{
                            width: "22%",
                            fontFamily: "Product Sans",
                            fontWeight: "400",
                          }}
                        >
                          {item?.lead?.lead_prority?.name}
                        </Box>
                        <Box
                          sx={{
                            width: "12%",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            gap: "10px",
                          }}
                        >
                          <RemoveRedEyeIcon
                            sx={{ cursor: "pointer" }}
                            onClick={(e) => {
                              e.stopPropagation();
                              navigate("/channelpartner/client/" + item.id);
                            }}
                          ></RemoveRedEyeIcon>
                          <DeleteIcon
                            sx={{ cursor: "pointer" }}
                            onClick={(e) => {
                              e.stopPropagation();
                              setToDelete(item);
                              handleOpen();
                            }}
                          />
                        </Box>
                      </Box>
                    );
                  }
                })}
            </Box>

            <Box sx={{ margin: "50px 0px 20px 0px" }}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "right",
                  marginRight: "20px",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    placeItems: "center",
                    margin: " 0 10px",
                    borderRadius: "5px",
                  }}
                >
                  <Typography sx={{}}>Page</Typography>
                  <Typography
                    sx={{
                      background: `white`,
                      color: "black",
                      width: "60px",
                      height: "30px",
                      borderRadius: "4px",
                      margin: "0 10px",
                      display: "grid",
                      placeItems: "center",
                      border: "1px solid #2979F2",
                    }}
                  >
                    {curpage}
                  </Typography>
                  <Typography
                    sx={{
                      color: "black",
                      width: "30px",
                      borderRadius: "5px",
                      margin: "0 0px",
                      display: "grid",
                      placeItems: "center",
                    }}
                  >
                    of
                  </Typography>
                  <Typography
                    sx={{
                      color: "black",
                      margin: "0px 10px 0px 0px",
                      display: "grid",
                      placeItems: "center",
                    }}
                  >
                    {NoPages}
                  </Typography>
                </Box>

                {newRowData.length != 0 && (
                  <Button
                    variant="contained"
                    className="paginationButton"
                    sx={{ marginRight: "10px" }}
                    disabled={curpage == 1}
                    onClick={prvClick}
                  >
                    <KeyboardDoubleArrowLeftIcon fontSize="medium" />
                  </Button>
                )}

                {newRowData.length != 0 && (
                  <Button
                    variant="contained"
                    className="paginationButton"
                    disabled={curpage == NoPages}
                    onClick={nextClick}
                  >
                    <KeyboardDoubleArrowRightIcon fontSize="medium" />
                  </Button>
                )}
              </Box>
            </Box>
          </>
        )}
      </Box>
    </Box>
  );
}

export default LDcustomTable;
