import { Box, Typography } from "@mui/material";
import { Stack } from "@mui/system";

import GroupsIcon from "@mui/icons-material/Groups";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import DragIndicatorOutlinedIcon from "@mui/icons-material/DragIndicatorOutlined";
import KeyboardArrowRightOutlinedIcon from "@mui/icons-material/KeyboardArrowRightOutlined";
import MessageOutlinedIcon from "@mui/icons-material/MessageOutlined";
import CallOutlinedIcon from "@mui/icons-material/CallOutlined";
// import "./Sidebar.css";
import { Link } from "react-router-dom";
import {
  OriginzationDirectoryIcon,
  SalesDashboardIcon,
} from "../../Icons/allsvg";
// import {
//   OriginzationDirectoryIcon,
//   SalesDashboardIcon,
// } from "../../../Icons/allsvg";
import GroupIcon from "@mui/icons-material/Group";
import ViewQuiltIcon from "@mui/icons-material/ViewQuilt";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import TextSnippetIcon from '@mui/icons-material/TextSnippet';
import PlayLessonIcon from '@mui/icons-material/PlayLesson';
import { UserRole } from "../../../UserRoles/UserRoles";

export const SuperAdminSidebar = ({ active }) => {
   ////get user roles from local storage and assign 
   let roles = JSON.parse(localStorage.getItem("user_roles"))
   let project = roles["project"];
   let unit = roles["unit"];
   let org = roles["org"];
   let builder = roles["builder"];
   let media = roles["project media"];
   let teams = roles["teams"];


  return (
    <>
      <Stack direction="row" sx={{ height: "100%" }}>
       

        <Stack
          direction="column"
          justifyContent="space-between"
          sx={{
            width: "100%",  ////////////////width of sidebar
            padding: "20px 10px 20px 10px",
            boxSizing: "border-box",
            height: "100%",
          }}
        >
            {/* directory routs */}
          <Stack>
            <Typography
              sx={{
                fontWeight: "700",
                fontSize: "24px",
                marginBottom: "30px",
                marginLeft: "20px",
              }}
            >
              Envision
            </Typography>

            <Stack direction="column" justifyContent="start">
                {/* super admin  dashboard */}
              <Link to="/super-admin-dashboard">
                <Stack
                  direction="row"
                  justifyContent="start"
                  alignItems="center"
                  spacing={2.5}
                  className="sidebar_option"
                  sx={{
                    background:
                      active == "Dashboard"
                        ? "linear-gradient(90deg, #0F5DAE 0%, #009DCF 100.33%)"
                        : "white",
                  }}
                >
                  {/* <DragIndicatorOutlinedIcon className="sidebar_option_icon" /> */}
                  {/* <SalesDashboardIcon/> */}
                  <svg
                width="17"
                height="19"
                viewBox="0 0 17 19"
                className="sidebar_option_iconn"
                    style={{
                      fill: active == "Dashboard" ? "white" :"#0F5DAE",
                    }}
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M5.92 2.96C5.92 4.59476 4.59476 5.92 2.96 5.92C1.32524 5.92 0 4.59476 0 2.96C0 1.32524 1.32524 0 2.96 0C4.59476 0 5.92 1.32524 5.92 2.96ZM17.0001 2.96C17.0001 4.59476 15.6748 5.92 14.0401 5.92C12.4053 5.92 11.0801 4.59476 11.0801 2.96C11.0801 1.32524 12.4053 0 14.0401 0C15.6748 0 17.0001 1.32524 17.0001 2.96ZM14.0401 18.2208C15.6748 18.2208 17.0001 16.8955 17.0001 15.2608C17.0001 13.626 15.6748 12.3008 14.0401 12.3008C12.4053 12.3008 11.0801 13.626 11.0801 15.2608C11.0801 16.8955 12.4053 18.2208 14.0401 18.2208ZM5.92 15.2608C5.92 16.8955 4.59476 18.2208 2.96 18.2208C1.32524 18.2208 0 16.8955 0 15.2608C0 13.626 1.32524 12.3008 2.96 12.3008C4.59476 12.3008 5.92 13.626 5.92 15.2608Z"/>
              </svg>
                  <Typography
                    className="link_name"
                    sx={{
                      fontWeight: "600",
                      fontSize: "12px",
                      color:
                        active == "Dashboard" ? "white" : "#A4A4AD",
                    }}
                  >
                    Dashboard
                  </Typography>
                  <KeyboardArrowRightOutlinedIcon
                    sx={{ fontSize: "12px", color: "#ffffff" }}
                  />
                </Stack>
              </Link>
               {/* Builder directory */}
               {UserRole(builder, "view_builder") && 
               <Link to="/superadmin-builder">
                <Stack
                  direction="row"
                  justifyContent="start"
                  alignItems="center"
                  spacing={2.5}
                  className="sidebar_option"
                  sx={{
                    background:
                      active == "Builder Directory"
                        ? "linear-gradient(90deg, #0F5DAE 0%, #009DCF 100.33%)"
                        : "white",
                  }}
                >
                  <svg
                    width="20"
                    height="14"
                    viewBox="0 0 20 14"
                    className="sidebar_option_iconn"
                    style={{
                      fill: active == "Builder Directory" ? "white" :"#0F5DAE",
                    }}
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M7.50036 1.48018C5.92777 2.47205 5.11254 3.00836 5.06719 3.08093C4.9029 3.34393 4.99836 3.50965 5.4984 3.82949L5.90126 4.08714L3.0752 5.7638C1.43763 6.73533 0.193783 7.49768 0.117518 7.57653C0.00079499 7.6973 -0.0116748 7.73031 0.00712761 7.86917C0.0195583 7.96103 0.0658411 8.06165 0.11916 8.11276C0.169156 8.16061 2.37369 9.50483 5.01814 11.0999C9.50327 13.8052 9.83803 14 10.0021 14C10.1662 14 10.5008 13.8053 14.9861 11.0993C17.6306 9.50398 19.8335 8.16305 19.8816 8.11946C20.0044 8.00807 20.0368 7.79939 19.9547 7.64828C19.9017 7.55084 19.3314 7.19597 16.996 5.80739L14.1029 4.08722L14.5058 3.82953C14.9991 3.51399 15.089 3.36536 14.949 3.09689C14.9076 3.01762 14.2528 2.58465 12.5351 1.5006C10.5958 0.276754 10.1503 0.0109241 10.0217 0.000811591C9.87501 -0.010657 9.71854 0.0811301 7.50036 1.48018ZM8.31829 5.60653C9.60963 6.4225 9.88252 6.58031 10.0021 6.58031C10.1218 6.58031 10.3946 6.4225 11.686 5.60653C12.5336 5.07096 13.2546 4.62516 13.2882 4.6159C13.3484 4.59931 13.834 4.86123 13.8319 4.90912C13.8298 4.95503 10.0737 7.2971 10.0021 7.2971C9.93459 7.2971 6.1747 4.95654 6.17239 4.91303C6.17012 4.86999 6.66266 4.60389 6.71856 4.61795C6.75081 4.62609 7.47069 5.07096 8.31829 5.60653ZM7.31594 6.63006C8.97934 7.67719 9.21396 7.83647 9.15317 7.87758C9.11487 7.90346 8.23413 8.45992 7.19597 9.11409L5.30842 10.3035L3.27448 9.07612C1.99553 8.30435 1.25426 7.83515 1.27752 7.8121C1.3738 7.71667 5.33574 5.3894 5.36995 5.4082C5.39145 5.41997 6.26715 5.96984 7.31594 6.63006ZM16.7452 6.61762C17.7987 7.24216 18.684 7.77459 18.7125 7.80074C18.7544 7.83918 18.3725 8.08482 16.7301 9.07592L14.6958 10.3035L12.8083 9.11409C11.7701 8.45992 10.8894 7.90342 10.851 7.8775C10.7901 7.83631 11.0281 7.67467 12.7245 6.60488C14.5059 5.48158 14.6746 5.38371 14.7488 5.43075C14.7933 5.45895 15.6917 5.99305 16.7452 6.61762Z"
                    />
                  </svg>

                  <Typography
                     className="link_name"
                    sx={{
                      fontWeight: "600",
                      fontSize: "12px",
                      color:
                        active == "Builder Directory" ? "white" : "#A4A4AD",
                    }}
                  >

                    {" "}
                   Builder Directory
                  </Typography>
                  <KeyboardArrowRightOutlinedIcon
                    sx={{ fontSize: "12px", color: "#ffffff" }}
                  />
                </Stack>
              </Link>
               }
              {/* team directory */}
              {UserRole(teams, "view_teams") && 
              <Link to="/superadmin-team">
                <Stack
                  direction="row"
                  justifyContent="start"
                  alignItems="center"
                  spacing={2.5}
                  className="sidebar_option"
                  sx={{
                    background:
                      active == "Teams Directory"
                        ? "linear-gradient(90deg, #0F5DAE 0%, #009DCF 100.33%)"
                        : "white",
                  }}
                >
                  <svg
                    width="17"
                    height="17"
                    viewBox="0 0 17 17"
                    className="sidebar_option_iconn"
                    style={{
                        fill: active == "Teams Directory" ? "white" :"#0F5DAE",
                      }}
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M7.71254 0.0195602C5.72087 0.213217 3.89905 1.07137 2.48481 2.48205C1.16339 3.80016 0.35721 5.41544 0.0618012 7.33677C-0.0206004 7.87263 -0.0206004 9.12931 0.0618012 9.66517C0.35701 11.5852 1.15648 13.1885 2.48039 14.5155C3.79865 15.8367 5.41411 16.6428 7.33567 16.9382C7.87159 17.0206 9.12841 17.0206 9.66433 16.9382C11.5846 16.643 13.1881 15.8437 14.5152 14.5199C15.8366 13.2018 16.6428 11.5865 16.9382 9.66517C17.0206 9.12931 17.0206 7.87263 16.9382 7.33677C16.643 5.4167 15.8435 3.81343 14.5196 2.48647C13.2103 1.17415 11.5757 0.354917 9.6976 0.0697872C9.28193 0.00668743 8.14178 -0.0221515 7.71254 0.0195602ZM9.83067 1.1025C10.9957 1.30348 12.2767 1.89183 13.2235 2.66063C14.3485 3.57427 15.1763 4.7694 15.6328 6.1393C16.8045 9.65553 15.2191 13.5083 11.9098 15.1866C11.0155 15.6402 10.1213 15.9057 9.25682 15.9745L8.999 15.995V8.50097V1.00697L9.25682 1.02746C9.3986 1.03874 9.65685 1.0725 9.83067 1.1025Z"
                    />
                  </svg>

                  <Typography
                     className="link_name"
                    sx={{
                      fontWeight: "600",
                      fontSize: "12px",
                      color:
                        active == "Teams Directory" ? "white" : "#A4A4AD",
                    }}
                  >
                   Teams Directory
                  </Typography>
                  <KeyboardArrowRightOutlinedIcon
                    sx={{ fontSize: "12px", color: "#ffffff" }}
                  />
                </Stack>
              </Link>
              }
       

            </Stack>
          </Stack>

            {/* teams and message */}
          <Stack>
          <Typography
            sx={{ color: "#51459E", fontSize: "12px", fontWeight: "700" }}
          >
            TEAMS
          </Typography>

          <Stack direction="column" sx={{}}>
            <Stack
              direction="row"
              justifyContent="start"
              alignItems="center"
              spacing={2}
              className="sidebar_option"
            >
              <MessageOutlinedIcon className="sidebar_option_icon" />
              <Typography
                sx={{ fontWeight: "600", fontSize: "12px", color: "#A4A4AD" }}
              >
                Message
              </Typography>
              <KeyboardArrowRightOutlinedIcon
                sx={{ fontSize: "12px", color: "#ffffff" }}
              />
            </Stack>
            <Stack
              direction="row"
              justifyContent="start"
              alignItems="center"
              spacing={2}
              className="sidebar_option"
            >
              <CallOutlinedIcon className="sidebar_option_icon" />
              <Typography
                sx={{ fontWeight: "600", fontSize: "12px", color: "#A4A4AD" }}
              >
                Support
              </Typography>
              {/* <KeyboardArrowRightOutlinedIcon sx={{fontSize:"12px", color:"#ffffff"}}/> */}
              <svg
                width="11"
                height="7"
                viewBox="0 0 11 7"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M9.88629 0.568359H0.659141C0.480523 0.568359 0.326108 0.633574 0.195643 0.763894C0.0651783 0.894359 0 1.04877 0 1.22728C0 1.40579 0.0651783 1.56032 0.195643 1.69071L4.80923 6.30423C4.93984 6.4347 5.09426 6.50002 5.27273 6.50002C5.45121 6.50002 5.60577 6.4347 5.73612 6.30423L10.3497 1.69067C10.48 1.56032 10.5455 1.40579 10.5455 1.22725C10.5455 1.04877 10.4801 0.894359 10.3497 0.763858C10.2194 0.633429 10.0648 0.568359 9.88629 0.568359Z"
                  fill="#D3D6E4"
                />
              </svg>
            </Stack>
          </Stack>
        </Stack>

         
        </Stack>
      </Stack>
    </>
  );
};
