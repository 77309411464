import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    MenuItem,
    Drawer,
    Box,
    Button,
    Checkbox,
    FormControl,
    InputLabel,
    Select,
    TextField,
    Typography,
  } from "@mui/material";
  import { Stack } from "@mui/system";
  import TuneOutlinedIcon from "@mui/icons-material/TuneOutlined";
  import KeyboardArrowRightOutlinedIcon from "@mui/icons-material/KeyboardArrowRightOutlined";
  import KeyboardArrowUpOutlinedIcon from "@mui/icons-material/KeyboardArrowUpOutlined";
  import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined";
  import { CheckBox } from "@mui/icons-material";
  import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
  import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
  import DriveFileRenameOutlineIcon from "@mui/icons-material/DriveFileRenameOutline";
  import VisibilityIcon from "@mui/icons-material/Visibility";
  import DeleteIcon from "@mui/icons-material/Delete";
//   import "../MembersDirectory/MDmainPage.css";
  import React, { useContext, useEffect, useState } from "react";
  import { DataGrid, GridExpandMoreIcon } from "@mui/x-data-grid";
  import CategoryIcon from "@mui/icons-material/Category";
  import HandshakeIcon from "@mui/icons-material/Handshake";
  import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
  
  import axios from "axios";
  import UDcustomTable from "./UDcustomTable";
  
  import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Navbar } from "../../BuilderComponents/ProjectDashboard/DashboardComponents/Navbar";
import { ProjectDashboardSidebar } from "../../BuilderComponents/ProjectDashboard/DashboardComponents/ProjectDashboardSidebar";
import { Apiaddress } from "../../../utils/consts";
import { NavbarForDirectory } from "../../BuilderComponents/ProjectDashboard/DashboardComponents/NavbarForDirectory";

import CircularProgress from '@mui/material/CircularProgress';
import builderDashboard from "../../../context/builder_dashboard/context";
import { MarkOccupancy } from "./MarkOccupancy";


export const Unit_Directory_w_sb = ()=>{

  const [loading, setloading] = useState(false)
   
  const [newRowData, setNewRowData] = useState([]);
  const OrganizationsContext = useContext(builderDashboard);
  


  const [state, setState] = React.useState(false);

  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift") 
    ) {
      return;
    }

    setState(open);
  };

  const [selectedRows, setSelectedRows] = React.useState([]);
  useEffect(() => {
    console.log(selectedRows);
  }, [selectedRows]);

  const [curselectedRows, setcurselectedRows] = useState([]);

  const [selectedUnits, setSelectedUnits] = useState([]);


   

  const [tabledata, setTabledata] = useState(null);
  const [rowData, setrowData] = useState([]);
  const [idCont, setidCunt] = useState(1);

  //api

  //api

  useEffect(() => {

    if (tabledata) {
      let obj = {
        id: idCont,
        Name: tabledata.name,
        Phone: tabledata.phone,
        Email: tabledata.Email,
        Team: tabledata.Team,
        Role: tabledata.Role,
      };
      setidCunt(idCont + 1);
      console.log(obj);
      setrowData([...rowData, obj]);
    }

    console.log(tabledata);
  }, [tabledata]);


  const [data, setData] = useState(null);
  const [token, setToken] = useState(null);

  
  let builderSelectedData = JSON.parse(localStorage.getItem("BuilderSelected"));

  let id = builderSelectedData.selectedProjectID;
  

  const fetchUnit = async () => {
    setloading(true);
    try {
        const res = await axios.get(
          Apiaddress + `/api/projects/getunitsusingprojectid/?project_id=${id}`
        );
      console.log("===========================.>",res?.data);
      if(res.data.unit==" Unit not found"){setNewRowData([])}
      else{
        setNewRowData(res?.data?.unit);
      }
    } catch (err) {
      console.log("====================",err);
      setNewRowData([]);
    }finally{
      setloading(false)
    }
  };

  useEffect(() => {
    fetchUnit();
  }, []);

  const [newUnits, setNewUnits] = useState([]);
  useEffect(() => {
    if (newRowData) {
      let arr = [];
      for (let i = 0; i < newRowData?.length; i++) {
        
        let totalcost=0;
        
        for (let j = 0; j < newRowData[i].charges_data?.length ; j++) {
          
          totalcost=totalcost+(newRowData[i].charges_data[j].sectioncost);
          
        }
          let obj={
            id:newRowData[i]?.id,
            floor_no:newRowData[i].floor?.id,
            bedroom:newRowData[i].layout_data?.bedroomData?.length?newRowData[i].layout_data?.bedroomData?.length:"0",
            bathroom:newRowData[i].layout_data?.bathroomData?.length? newRowData[i].layout_data?.bathroomData?.length:"0",
            balcony:newRowData[i].layout_data?.balconyData?.length? newRowData[i].layout_data?.balconyData?.length:"0",
            totalarea:newRowData[i].layout_data?.singleData[0]?.value,
            single_data:newRowData[i].layout_data?.singleData,
            double_data:newRowData[i].layout_data?.doubleData,
            pricing:totalcost
          }
          arr.push(obj);
        }

    setNewUnits(arr);
  }

  }, [newRowData]);

  //fitering data starts:====
  const [filterselect, setfilterselect] = useState();
  const [filtervalue, setfiltervalue] = useState();

  const handleChange = (event) => {
    setfilterselect(event.target.value);
    setfiltervalue("");
  };


   const fetchfilter = async () => {
     try {
        const res = await axios.get(
          Apiaddress + `/api/projects/getunitsusingprojectid/?project_id=${id}&filterkey=${filterselect}&filtervalue=${filtervalue}`
        );

      console.log(res.data.unit);
      setNewRowData(res.data.unit);
    } catch (err) {
      console.log(err);
    }
  };

    return (
        <>
        <Stack direction="row" sx={{width:"100%",height:"auto" }}>
      {/* 1st stack */}
      <Stack sx={{minHeight:"100vh", width:"22%",boxSizing:"border-box",}}>
      <ProjectDashboardSidebar active={"Unit Directory"}/>
      </Stack>

     {/* 2nd stack */}
      <Stack direction="column" sx={{ backgroundColor:"#f9f9f9",width:"78%", padding:"25px",boxSizing:"border-box",}}  >
      {/* <Navbar/> */}
      <NavbarForDirectory
            showAddProjectbtn={false}
            showRightSideBar={false}
            showRightSideBarProjAdd={false}
            RightBarNameProAdd={"Add Project"}
            toggleDrawer={toggleDrawer}
            showRightSideBarleft={false}
            RightBarNameleft={"Send invite"}
            leftRightTitle1={"Unit Dashboard"}
            leftRightTitle2={"Unit Directory"}
            leftTitle={"Project Dashboard"}
            showLeftTitle={false}
            righshow={true}
          />
        


   
     {/* actual content  */}

     <Drawer anchor="right" open={state} onClose={()=>{
            // toggleDrawer(false)
             setState(false)
            //   setMakePriority(false);
            //   setMakeProject(false);
          }}>
            {/* <RightSidebar
              setTabledata={setTabledata}
              toggleDrawer={toggleDrawer}
              curselectedRows={
                curselectedRows.length == 0 ? null : curselectedRows
              }
              orgID={orgID}
              updateData={updateData}
              setTrigger={setTrigger}
              trigger={trigger}
              makePriority={makePriority}
              makeProject={makeProject}
              setMakePriority={setMakePriority}
              setMakeProject={setMakeProject}
            /> */}

            <MarkOccupancy setState={setState} selectedUnits={selectedUnits} setSelectedUnits={setSelectedUnits}/>
          </Drawer>

    <Stack sx={{ mt:"28px",backgroundColor:"#f9f9f9",}}>
   
           

          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            sx={{ height: "68px", marginBottom: "20px" }}
          >
            <Stack direction="column" alignItems="center">
              <Typography
                sx={{
                  fontSize: "22px",
                  fontWeight: "600",
                  // marginLeft: "-45px",
                  color: "black",
                }}
              >
                Unit Directory
              </Typography>
              <Typography
                sx={{
                  color: "#B9B7D0",
                  fontSize: "12px",
                  fontWeight: "400",
                  paddingLeft: "20px",
                }}
              >
                Here is your Unit Directory list data
              </Typography>
            </Stack>
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Stack
                direction="row"
                justifyContent="space-around"
                alignItems="center"
                sx={{
                  width: "203px",
                  height: "68px",
                  borderRadius: "12px",
                  color: "#3E4954",
                  backgroundColor: "#f4f5f9",
                }}
              >

              <Accordion sx={{ width: "250px", boxShadow: "none" }}>
              <AccordionSummary
                expandIcon={<GridExpandMoreIcon/>}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography sx={{ display: "flex", placeItems: "center" }}>
                  <TuneOutlinedIcon sx={{ color: "#2F4CDD" }} />
                  <Typography sx={{ marginLeft: "25px" }}>
                    Fillters
                  </Typography>
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Box sx={{textAlign:"center"}}>
                  <FormControl sx={{ width: "200px" }}>
                    <InputLabel id="demo-simple-select-label">
                      Select
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={filterselect}
                      label="Select"
                      onChange={handleChange}
                    >
                      <MenuItem value={"name"}>Name</MenuItem>
                      <MenuItem value={"is_booked"}>Booked</MenuItem>
                      <MenuItem value={"is_paid"}>Paid</MenuItem>
                      <MenuItem value={"is_occupy"}>Occupied</MenuItem>
                      {/* <MenuItem value={"Name"}>Name</MenuItem> */}
                    </Select>
                  </FormControl>
                  {
                    filterselect=="name"?
                    <TextField
                    sx={{ marginTop: "20px" }}
                    placeholder="Enter the value" 
                    value={filtervalue} onChange={(e)=>{setfiltervalue(e.target.value)}}
                  />:
                    <FormControl sx={{ width: "200px", marginTop: "20px"}}>
                    <InputLabel id="demo-simple-select-label">
                      Choose
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={filtervalue}
                      label="Select"
                      onChange={(e)=>{setfiltervalue(e.target.value)}}
                    >
                      <MenuItem value={'1'}>True</MenuItem>
                      <MenuItem value={'0'}>False</MenuItem>
                    </Select>
                  </FormControl>

                  }
                  <Button 
                    onClick={fetchfilter}
                    variant="contained"
                    color="primary" sx={{ height:"40px", background: "linear-gradient(90deg, #0F5DAE 0%, #009DCF 100.33%)",boxShadow: "0px 13.7981px 22.5788px rgba(50, 174, 137, 0.25)", textTransform: "none", borderRadius: "4px", width:"100px", fontSize:"15px",margin:"20px 0px 10px 0px"}}>
                     Submit
                  </Button>

                </Box>
              </AccordionDetails>
            </Accordion>

              </Stack>
            </Stack>
          </Stack>

          {/* ================unit availability btn =============================== */}

          <Box>
           {selectedUnits.length>0 && 
              <Stack direction="row" sx={{ margin: "20px" }}>
                <Button
                  onClick={()=>{
                    // toggleDrawer(true);
                    setState(true)
                      // setMakePriority(true);
                  }}
                  startIcon={<CategoryIcon />}
                  className="univercel_btn_class"
                  sx={{
                    color: "#FFFFFF",
                    textTransform: "none",
                    width: "170px",
                    background:
                      " linear-gradient(150deg, #79F1A4 13.4%, #0E5CAD 86.6%)",
                  }}
                >
                  Mark Occupancy
                </Button>
             
                {/* <Button
                  startIcon={<HandshakeIcon />}
                  onClick={()=>{
                    // toggleDrawer(true);
                    // setState(true)
                    //  setMakeProject(true)
                  }
                }
                  className="univercel_btn_class"
                  sx={{
                    color: "#FFFFFF",
                    textTransform: "none",
                    background:
                      " linear-gradient(150deg, #79F1A4 13.4%, #0E5CAD 86.6%)",
                    width: "150px",
                    marginLeft: "20px",
                  }}
                >
                  Mark Project
                </Button> */}
              </Stack>
           }
            <Stack></Stack>
          </Box>
          
          <UDcustomTable 
          selectedUnits={selectedUnits}
          setSelectedUnits={setSelectedUnits}
          newRowData={newUnits}
          loading={loading}
          CircularProgress={CircularProgress}
          />
    
    </Stack>
 


      </Stack>
</Stack>
        </>
    )
}