import { Box, List, ListItem, Typography } from "@mui/material";
import "./styles/PageSix.css";
import React, {useState } from 'react';
import { Stack } from "@mui/system";
import classes from "./styles/PageOne.module.css";

const PdfOnePageThirteen = () => {

  const [pageData13,setPageData13]=useState({
    sign_first:"",
    sign_second:"",
    sign_third:"",
  })

  let name,value;
  const handleChange=(e)=>{
    name=e.target.name;
    value=e.target.value;
    console.log(name," ",value);
    setPageData13({...pageData13,[name]:value});
  }

  return (
    <Box>
      <Box sx={{ mb: "40px" }}>
        <Stack spacing={0.5} sx={{ ml: "20px", mt: "5px" }}>
          <Box sx={{ display: "flex", gap: "10px" }}>
            <Typography sx={{ fontSize: "10px" }}>9.2</Typography>
            <Typography sx={{ fontSize: "10px" }}>
              The Applicant(s) may at any time elect to cancel the
              Application/Confirmation Letter, after the Applicant(s) gives the
              Developer, 30 (thirty) days notice in writing of its intention to
              do so. The Application/Confirmation Letter shall stand
              terminated/cancelled without any further act, deed or thing, on
              the expiry of the aforesaid 30 (thirty) days notice period,
              subject to receipt of the aforesaid notice of the Applicant(s) by
              the Developer. Upon termination of the Application/Confirmation
              Letter, the Applicant(s) shall not have any claim whatsoever to
              the Premises and the Developer shall be at liberty to dispose off
              and sell the Premises to such person and at such price as the
              Developer may in its absolute dicretion think fit and proper. The
              Developer shall refund the amounts paid by the Applicant(s) as per
              the provisions of Clause 9.4 and clauses 9.5 hereof. On
              cancellation/ termination of this Application and/or Confirmation
              Letter, the Applicant(s) shall have no right, title, interest,
              claim, demand or dispute of any nature whatsoever either against
              the Developer or against the Premises/Project.
            </Typography>
          </Box>
          <Box sx={{ display: "flex", gap: "10px" }}>
            <Typography sx={{ fontSize: "10px" }}>9.3</Typography>
            <Typography sx={{ fontSize: "10px" }}>
              In the event the Applicant(s) commits breach of the terms and
              conditions contained in this Application (including towards
              non-payment of Allotment Premium, Other Charges, taxes and
              outgoings, then without prejudice to the right of the Devleloper
              to charge interest at the Interest Rate and any other rights and
              remedies available to the Developer in law and under the Agreement
              for Sale, on the Applicant(s) committing 3 defaults in payment of
              any Allotment Premium on due date of any amount due and payable by
              the Applicant(s) to the Developer in respect of the Premises
              (including default of payment of instalments of the Allotment
              Premium and/or the charges mentioned hereinbelow and/or
              his/her/their/its proportionate share of taxes levied by concerned
              local authority and other outgoings) the Developer shall be
              entitled to at his own option and discretion, terminate the
              allotment of Premises. Provided that, the Developer shall give 3
              notices of 15 (fifteen) days each in writing to the Applicant(s)
              (“Default Notices”), by courier/Email/registered post A.D. at the
              address provided by the Applicant(s), of its intention to
              terminate this Application/allotment of the Premises with details
              of the specific breach or breaches of terms and conditions in
              respect of which it is intended to teminate he allotment of the
              Premises, if the Applicant(s) fails to rectify the breach or
              breaches mentioned by the Developer within the period of the last
              Default Notice, including making full and final payment of any
              outstanding dues together with interest at the Interest Rate
              thereon, then at the end of the period mentioned in the Default
              Notice, the developer shall be entitled to terminate the allotment
              of the Premises by issuance of a written notice to the
              Applicant(s) (“Developer Termination Notice”), by
              courier/Email/registered post A.D. at the address provided by the
              Applicant(s). On the receipt of the Developer Termination Notice
              by the Applicant(s), the allotment of the Premises shall stand
              terminated and cancelled and the Developer shall be entitled to
              deal with the Premises as the Developer may in its absolute
              discretion think fit and proper. On the termination and
              cancellation of the allotment of the Premises/Agreement for Sale
              (as the case may be) in the manner as stated in this sub-clause,
              the Developer shall refund the amounts received by the Developer
              after deducting any and all amounts set out in clause 9.4 hereof.
              It is clarified that the Developer shall refund the amounts under
              this clause within 30 days from the date of cancellation
              letter/execution and registration of the Deed of Cancellation of
              Agreement for Sale (as the case may be) and on resale of the said
              Premises to any third party, whichever is later.
            </Typography>
          </Box>
          <Box sx={{ display: "flex", gap: "10px" }}>
            <Typography sx={{ fontSize: "10px" }}>9.4</Typography>
            <Box>
              <Typography sx={{ fontSize: "10px" }}>
                The Developer shall refund to the Applicant(s) the amounts
                received directly from the Applicant(s), after deducting
                therefrom:
              </Typography>
              <ol type="a" style={{ fontSize: "10px" }}>
                <li>
                  10% of the Allotment Premium of the said Premises shall stand
                  forfeited by the Developer.
                </li>
                <li>
                  GST and all other taxes/charges/fees if any (including
                  interest) paid or payable on this Agreement.
                </li>
                <li>
                  The interest payable by the Applicant(s) on any outstanding
                  amounts payable to the Developer in terms of this Agreement
                  from the dates of default in payment till the date of
                  cancellation as aforesaid.{" "}
                </li>
                <li>
                  {" "}
                  Amounts of brokerage paid on this transaction by the
                  Developer.{" "}
                </li>
                <li>
                  In case the Applicant(s) has opted for subvention scheme, the
                  total amount of PRE-EMI interest paid and/or payable by the
                  Developer to the lending bank/financial institution.{" "}
                </li>
                <li>
                  In case the Applicant(s) has availed any loans (including
                  subvention scheme), then the amounts disbursed by the lending
                  bank/financial instituion to the Developer, which amounts
                  shall be refunded by the Developer to such lending
                  Bank/financial institution directly and the Applicant(s)
                  authorizes the Developer to collect the original Agreement for
                  Sale from such bank/financial institution shall not be
                  required to take any consent/confirmation from the
                  Applicant(s) at anytime.{" "}
                </li>
                <li>
                  The amounts to be refunded to the Applicant(s) after deducting
                  the amounts as set out in (a) to (f) above is hereinafter
                  referred to as “Refund Amount”){" "}
                </li>
                <li>
                  The Applicant(s) agrees that the amounts deducted by the
                  Developer under sub clause (a) to (f) hereinabove are as and
                  by way of mutually pre agreed liquidated damages for
                  cancellation of the allotment of the Premises/ Agreement for
                  Sale (as the case may be). The Applicant(s) confirm(s) and
                  warrant(s) that the liquidated damages is a genuine
                  pre-estimate of the loss or damage as agreed between the
                  parties that is likely to be suffered by the Developer on
                  account of breach of the terms of Application/Confirmation
                  Letter/Agreement for Sale by the Applicant(s) waive(s)
                  his/her/their/its rght to raise any objecton to the payment or
                  determination of liquidated damages in the manner and under
                  the circumstances set out herein.
                </li>
              </ol>
            </Box>
          </Box>
        </Stack>
      </Box>

      <Box
        sx={{
          display: "flex",
          gap: "40px",
          justifyContent: "center",
          position: "absolute",
          bottom: "30px",
        }}
      >
        <Box className={classes.input}>
          <Box>
            <input
              style={{ margin: "0px", textAlign: "center" }}
              value={pageData13.sign_first}
              type="text"
              name="sign_first"
              onChange={handleChange}
            />
            <Typography sx={{ textAlign: "center", mt: "10px" }}>
              Signature of First Applicant
            </Typography>
          </Box>
        </Box>
        <Box className={classes.input}>
          <Box sx={{ display: "inline-block" }}>
             <input
              style={{ margin: "0px", textAlign: "center" }}
              value={pageData13.sign_second}
              type="text"
              name="sign_second"
              onChange={handleChange}
            />
            <Typography sx={{ textAlign: "center", mt: "10px" }}>
              Signature of Second Applicant
            </Typography>
          </Box>
        </Box>
        <Box className={classes.input}>
          <Box sx={{ display: "inline-block" }}>
            <input
              style={{ margin: "0px", textAlign: "center" }}
              value={pageData13.sign_third}
              type="text"
              name="sign_third"
              onChange={handleChange}
            />
            <Typography sx={{ textAlign: "center", mt: "10px" }}>
              Signature of Third Applicant
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default PdfOnePageThirteen;
