import { Box, Stack} from '@mui/system';
import React, { useEffect, useState } from 'react';
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { Button, FormControl, MenuItem, Select, TextField, Typography } from '@mui/material';
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

import { SuperAdminSidebar } from '../SidebarNavbar/SuperAdminSidebar';
import { SuperAdminNavbar } from '../SidebarNavbar/SuperAdminNavbar';
import { Apiaddress } from "../../../utils/consts";
import axios from 'axios';
import { Link, useLocation } from 'react-router-dom';



function SuperAdminBuilderEdit() {

    // let token="eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpZCI6NCwiZW1haWwiOiJidWlsZGVybG9ja0BkZW1vLmNvbSIsImZpbGVfcHJlcGVuZCI6InVzZXJzL3Byb2ZpbGVfcGljcyIsInVzZXJuYW1lIjpudWxsLCJmaXJzdF9uYW1lIjoiQnVpbGRlciIsImxhc3RfbmFtZSI6IiIsImlzX2FjdGl2ZSI6dHJ1ZSwiaXNfc3RhZmYiOmZhbHNlfQ.PXp_w8t86x-XFG6pkal7xDcwKODSDhZJ7d7H-38ht4I";
    let  token  = localStorage.getItem("crm_token")
    console.log(token)
    //get data from previous page 
    const location = useLocation();
    const data = location.state;
    console.log("preve data =>.>",data)

    const [status,setstatus]=useState('');
     
    const updateStatus= async ()=>{

      if(status=="Approved" && data.status!="Approved"){
        const headers = {
        Authorization: `JWT ${token}`,
       };  
      const builderurl = Apiaddress+`/api/changeactivestatus/?profile_id=${data.id}`
      const res = await axios.get(builderurl,{headers});
      console.log(res.data);
      }
      }
    
    useEffect(()=>{
      setstatus(data.status);
    },[])

    console.log(status);

  return (
    <>
      <Stack direction="row" sx={{width:"100%",height:"auto" }}>

        {/* 1st stack */}
      <Stack sx={{minHeight:"100vh",width:"20%",boxSizing:"border-box",}}> {/*for project directory 20% and for sales dashboard 225*/}
        <SuperAdminSidebar active={"Builder Directory"}/>
      </Stack>

     {/* 2nd stack */}
     <Stack direction="column" sx={{ backgroundColor:"#f9f9f9",width:"80%", padding:"25px",boxSizing:"border-box",}}  >  {/*  width for main sidebar 78px and for 80px*/}
      <SuperAdminNavbar builder={true}/>  {/*send props for get popup to verify otp from builder */}
      
       <Typography sx={{marginTop:"10px", fontSize:"16px", fontWeight:"600", marginLeft:"0px", marginBottom:"0px",color:"#2979F2",fontWeight: "400"}}>
        Here is your Builders Directory list Data 
        </Typography>
       <Typography sx={{marginTop:"20px", fontSize:"28px", fontWeight:"600", marginLeft:"0px"}}>
         Builder Directory   
       </Typography>
       <Typography sx={{marginTop:"10px", fontSize:"16px", fontWeight:"600", marginLeft:"0px", marginBottom:"50px",color: "#9299BC",fontWeight: "500"}}>
         Here is your Builders Directory list associated with you 
       </Typography>
        
        {/* Actual Content Starts  */}
       <Box sx={{display:"flex",flexWrap:"wrap",backgroundColor:"white",padding:"25px"}}>
        <Stack direction="row" sx={{alignItems:"center",width:"45%",margin:"0px 0px 25px 0px"}}>
            <Box sx={{width:"30%"}}>
                <Typography sx={{fontWeight:"500",fontSize:"18px",margin:"0px 40px 0px 0px"}}>Name</Typography>
            </Box>
            <TextField sx={{width:"65%"}} value={data?.name} disabled>
            </TextField>
        </Stack>
        <Stack direction="row" sx={{alignItems:"center",width:"45%",margin:"0px 0px 25px 30px"}}>
            <Box sx={{width:"30%"}}>
                <Typography sx={{fontWeight:"500",fontSize:"18px",margin:"0px 40px 0px 0px"}}>State</Typography>
            </Box>
            <TextField sx={{width:"65%"}} value={data?.state} disabled>
            </TextField>
        </Stack>
        <Stack direction="row" sx={{alignItems:"center",width:"45%",margin:"0px 0px 25px 0px"}}>
             <Box sx={{width:"30%"}}>
                <Typography sx={{fontWeight:"500",fontSize:"18px",margin:"0px 40px 0px 0px"}}>City</Typography>
            </Box>
            <TextField sx={{width:"65%"}} value={data?.city} disabled>
            </TextField>
        </Stack>
        <Stack direction="row" sx={{alignItems:"center",width:"45%",margin:"0px 0px 25px 30px"}}>
             <Box sx={{width:"30%"}}>
                <Typography sx={{fontWeight:"500",fontSize:"18px",margin:"0px 40px 0px 0px"}}>Pincode</Typography>
            </Box>
            <TextField sx={{width:"65%"}} value={data?.pincode} disabled>
            </TextField>
        </Stack>
        <Stack direction="row" sx={{alignItems:"center",width:"45%",margin:"0px 0px 25px 0px"}}>
             <Box sx={{width:"30%"}}>
                <Typography sx={{fontWeight:"500",fontSize:"18px",margin:"0px 40px 0px 0px"}}>Address 1</Typography>
            </Box>
            <TextField sx={{width:"65%"}} value={data?.address1} disabled>  
            </TextField>
        </Stack>
         <Stack direction="row" sx={{alignItems:"center",width:"45%",margin:"0px 0px 25px 30px"}}>
             <Box sx={{width:"30%"}}>
                <Typography sx={{fontWeight:"500",fontSize:"18px",margin:"0px 40px 0px 0px"}}>Address 2</Typography>
            </Box>
            <TextField sx={{width:"65%"}} value={data?.address2} disabled>
            </TextField>
        </Stack>
        <Stack direction="row" sx={{alignItems:"center",width:"45%",margin:"0px 0px 25px 0px"}}>
             <Box sx={{width:"30%"}}>
                <Typography sx={{fontWeight:"500",fontSize:"18px",margin:"0px 40px 0px 0px"}}>Status</Typography>
            </Box>
            <FormControl sx={{width:"65%"}}>
                  <Select value={status}
                    onChange={(e)=>{
                      setstatus(e.target.value)
                    }}
                    displayEmpty
                    inputProps={{ 'aria-label': 'Without label' }}
                    disabled={data.status=="Approved"?true:false}
                    >
                    <MenuItem value={"Approved"}>Approved</MenuItem>
                    <MenuItem value={"Pending"}>Pending</MenuItem>
                  </Select>
                </FormControl>
        </Stack>
       </Box>
       
       <Box sx={{textAlign:"right"}}>
            <Link 
              onClick={()=>{
                updateStatus()
              }}
              to="/superadmin-builder">
              <Button variant="contained" sx={{background:"linear-gradient(90deg, #0F5DAE 0%, #009DCF 100.33%)",width:"15%",height:"70%",margin:"30px 0px 0px 0px"}}>
                Update
              </Button>
            </Link>
       </Box>

      </Stack>
    </Stack>
    </>
  )
}

export default SuperAdminBuilderEdit