import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Checkbox,
  Drawer,
  FormControl,
  FormControlLabel,
  FormLabel,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import "./LDmainPage.css";
import { NavbarForDirectory } from "../../../BuilderComponents/ProjectDashboard/DashboardComponents/NavbarForDirectory";
import AddIcon from "@mui/icons-material/Add";
import SideBar from "../../Components/sideBar/SideBar";
import { Stack } from "@mui/system";
import TuneOutlinedIcon from "@mui/icons-material/TuneOutlined";
import KeyboardArrowRightOutlinedIcon from "@mui/icons-material/KeyboardArrowRightOutlined";
import KeyboardArrowUpOutlinedIcon from "@mui/icons-material/KeyboardArrowUpOutlined";
import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined";
import { CheckBox } from "@mui/icons-material";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import DriveFileRenameOutlineIcon from "@mui/icons-material/DriveFileRenameOutline";
import VisibilityIcon from "@mui/icons-material/Visibility";
import "./LDmainPage.css";
import React, { useContext, useEffect, useState } from "react";
import RightSidebar from "./RightSidebar";
import { DataGrid, GridExpandMoreIcon } from "@mui/x-data-grid";
import CategoryIcon from "@mui/icons-material/Category";
// import HandshakeIcon from "@mui/icons-material/Handshake";
import axios from "axios";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import LDcustomTable from "./LDcustomTable";
import { api, Apiaddress } from "../../../../utils/consts";
import profileContext from "../../../../context/profile/context";
import TabBar from "../../Components/tabBar/TabBar";
import CPC from "../../context/context";
import { useNavigate } from "react-router-dom";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import CreateIcon from "@mui/icons-material/Create";
import { AuthService } from "../../../../context/buyer/services/auth";
import CircularProgress from "@mui/material/CircularProgress";
import NavigateBack from "../../../CommonPages/Shared/NavigateBack";
import { DeleteConfirmationModal } from "./DeleteConfirmationModal";
import Lottie from "react-lottie";
import animationData from "../../../../Lotties/NEW sin movs.json";


const RequirementForm = () => {
  const navigate = useNavigate();
  //fitering data starts:====
  const [filterselect, setfilterselect] = useState();
  const [filtervalue, setfiltervalue] = useState();
  const [leadsFromApi, setleadsFromApi] = useState([]);
  const [loading, setloading] = useState(false);

  const cpDetails = JSON.parse(localStorage.getItem("cpDetails"));
  // // console.log(cpDetails);
  let cpOrg = cpDetails?.channelPartnerOrg;

  const user = JSON.parse(localStorage.getItem("crm_user"));
  let username = user?.user_details?.first_name;
  // // console.log(user?.user_details?.first_name);

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const fetchfilter = async () => {
    const x = async () => {
      let url = "";
      // // console.log(cpOrg);
      if (cpOrg) {
        url = `/api/leads/reqtemofcp/?org_id=${cpOrg}`;
      } else {
        url = `/api/leads/reqtemofcp/`;
      }
      const res = await (
        await new AuthService().makeAuthenticatedGetRequest(api(url))
      ).json();
      // // console.log("requirement Forms", res);
      if (res.requirements != "requirements not found") {
        setleadsFromApi(res.requirements);
      }
    };

    x();
  };

  const handleChange = (event) => {
    setfilterselect(event.target.value);
    setfiltervalue("");
  };

  const [TemplateData, setTemplateData] = useState([]);
  const [trigger, setTrigger] = useState(false);
  const [selectedRows, setSelectedRows] = React.useState([]);

  const fetchTemplates = async () => {
    setloading(true);
    const y = new AuthService();
    let url = "";
    // // console.log(cpOrg);
    if (cpOrg) {
      url = `/api/leads/reqtemofcp/?org_id=${cpOrg}`;
    } else {
      url = `/api/leads/reqtemofcp/`;
    }
    const res = await (await y.makeAuthenticatedGetRequest(api(url))).json();
    // // console.log(res.templates);
    if (res.templates != "template not found") {
      setTemplateData(res.templates);
    }
    setloading(false);
  };
  useEffect(() => {
    fetchTemplates();
  }, [trigger]);

  const toggleDrawer = (open, item) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    updateLead(item);
    setState(open);
  };
  const updateLead = (item) => {
    // // console.log("le lead",item);
    // setupdateData(item);
    // navigator("/channelpartner/leads/"+item.lead_id)
  };
  const [updateData, setupdateData] = useState(null);
  const [curselectedRows, setcurselectedRows] = useState([]);
  const [rowSelected, setRowSelected] = useState(false);
  const [state, setState] = useState();
  //////select btn to make priority and mark project ;
  const [makePriority, setMakePriority] = useState(false);
  const [makeProject, setMakeProject] = useState(false);
  const [tabledata, setTabledata] = useState(null);
  const [rowData, setrowData] = useState([]);
  const [idCont, setidCunt] = useState(1);
  const [orgID, setOrgID] = useState(null);
  const [TemplateId, setTemplateId] = useState();
  const [TemplateName, setTemplateName] = useState();
  return (
    <SideBar active={"Requirements"} dontShowNav>
      <React.Fragment>
        <Drawer
          anchor="right"
          open={state}
          onClose={() => {
            // toggleDrawer(false)
            setState(false);
            setMakePriority(false);
            setMakeProject(false);
          }}
        >
          <RightSidebar
            setTabledata={setTabledata}
            toggleDrawer={toggleDrawer}
            curselectedRows={
              curselectedRows.length == 0 ? null : curselectedRows
            }
            TemplateId={TemplateId}
            TemplateName={TemplateName}
            orgID={orgID}
            updateData={updateData}
            setTrigger={setTrigger}
            trigger={trigger}
            makePriority={makePriority}
            makeProject={makeProject}
            setMakePriority={setMakePriority}
            setMakeProject={setMakeProject}
            setState={setState}
          />
        </Drawer>
      </React.Fragment>

      <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              sx={{ height: "68px", marginBottom: "20px" }}
            >
              <Stack direction="column">
                <Typography
                  sx={{
                    fontSize: "22px",
                    fontWeight: "600",
                    color: "black",
                  }}
                >
                  Requirement Forms
                </Typography>
                <Typography  
                  sx={{
                    color: "#B9B7D0",
                    fontSize: "16px",
                    fontWeight: "400",
                  }}
                >
                 Manage your Requirement Forms
                </Typography>
              </Stack>
            </Stack>

      <Stack direction="row" sx={{ width: "100%", height: "auto"}}>
        {/* 2nd stack */}
        <Stack
          direction="column"
          sx={{
            width: "100%",
            padding: "0px",
            boxSizing: "border-box",
          }}
        >
          {" "} 
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            sx={{ height: "68px", marginBottom: "20px" }}  
          >
            <Stack direction="row">
              <Box sx={{ pt: "5px" }}>
                <NavigateBack fontSize="24px" />
              </Box>
              <Stack direction="column">
                <Typography
                  sx={{
                    fontSize: "22px",
                    fontWeight: "600",
                    color: "black",
                  }}
                >
                  Requirement Form Templates
                </Typography>
                <Typography
                  sx={{
                    color: "#B9B7D0",
                    fontSize: "16px",
                    fontWeight: "400",
                  }}
                >
                  Manage your Requirement Forms
                </Typography>
              </Stack>
            </Stack>

            
            {/* <Stack
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Stack
                    direction="row"
                    justifyContent="space-around"
                    alignItems="center"
                    sx={{
                      width: "203px",
                      height: "68px",
                      borderRadius: "12px",
                      color: "#3E4954",
                      backgroundColor: "#f4f5f9",
                    }}
                  >
                    <Accordion sx={{ width: "250px", boxShadow: "none" }}>
                      <AccordionSummary
                        expandIcon={<GridExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                      >
                        <Typography
                          sx={{ display: "flex", placeItems: "center" }}
                        >
                          <TuneOutlinedIcon sx={{ color: "#2F4CDD" }} />
                          <Typography sx={{ marginLeft: "25px" }}>
                            Fillters
                          </Typography>
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Box sx={{ textAlign: "center" }}>
                          <FormControl sx={{ width: "200px" }}>
                            <InputLabel id="demo-simple-select-label">
                              Select
                            </InputLabel>
                            <Select
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              value={filterselect}
                              label="Select"
                              onChange={handleChange}
                            >
                              <MenuItem value={"name"}>Name</MenuItem>
                              <MenuItem value={"email"}>Email</MenuItem>
                              <MenuItem value={"phone"}>Phone</MenuItem>
                              <MenuItem value={"status"}>Status</MenuItem>
                              <MenuItem value={"city"}>City</MenuItem>
                              <MenuItem value={"state"}>State</MenuItem>
                              {/* <MenuItem value={"Name"}>Name</MenuItem> }
                            </Select>
                          </FormControl>
                          <TextField
                            sx={{ marginTop: "20px" }}
                            placeholder="Enter the value"
                            value={filtervalue}
                            onChange={(e) => {
                              setfiltervalue(e.target.value);
                            }}
                          />
                          <Button
                            onClick={fetchfilter}
                            variant="contained"
                            color="primary"
                            sx={{
                              height: "40px",
                              background:
                                "linear-gradient(90deg, #0F5DAE 0%, #009DCF 100.33%)",
                              boxShadow:
                                "0px 13.7981px 22.5788px rgba(50, 174, 137, 0.25)",
                              textTransform: "none",
                              borderRadius: "4px",
                              width: "100px",
                              fontSize: "15px",
                              margin: "20px 0px 10px 0px",
                            }}
                          >
                            Submit
                          </Button>
                        </Box>
                      </AccordionDetails>
                    </Accordion>
                  </Stack>
                </Stack> */}
          </Stack>
          {/* </Stack> */}
          {/* </Stack> */}
          

          <Button
                className="univercel_btn_class"
                sx={{
                  // marginRight:"30px",
                  color: "#FFFFFF",
                  textTransform: "none",
                  margin: "3px",
                  padding: "6px 24px",
                  fontFamily: "Product Sans",
                  fontSize: "16px",
                  width:"200px",
                  marginBottom:"20px"
                }}
                onClick={() =>
                  // navigate("/channelpartner/requirements-form-template")
                  navigate("/channelpartner/requirements-form-template-js")
                }
              >
                Add New Template
              </Button>

          <Box>
            <Box
              className="custom-scrollbar"
              sx={{
                borderRadius: "8px",
                padding: "30px",
                backgroundColor: "#FFF",
                height: "65vh",
                overflowY: "auto",
                border: "2px solid transparent",
                "&:hover": {
                  boxShadow: "-6px 18px 15px rgba(0, 0, 0, 0.1)",
                  border: "2px solid  #2979F2",
                },
              }}
            >
              {loading ? (
                <>
                  <Box sx={{ textAlign: "center", marginTop: "50px" }}>
                    <CircularProgress
                      color="inherit"
                      sx={{ color: "grey.500" }}
                    />
                  </Box>
                </>
              ) : TemplateData?.length == 0 ? (
                <Stack direction="column"  sx={{marginTop: "100px"}}>
                  <Typography
                    sx={{ fontSize: "25px", fontWeight: "500", color: "#B9B7D0"}}
                  >
                  <Lottie options={defaultOptions} height="20%" width="20%" />
                  <Typography
                    sx={{
                      color: "#B9B7D0",
                      fontSize: "18px",
                      fontWeight: "400",
                      textAlign:"center",
                      marginTop: "-40px",
                    }}
                    >
                    Please add your data
                  </Typography>
                </Typography>
              </Stack>
                
              ) : (
                <Box
                  sx={{
                    display: "flex",
                    flexWrap: "wrap",
                    gap: "20px",
                    justifyContent: "left",
                  }}
                >
                  {TemplateData.map((e) => {
                    // // console.log({ e });
                    let nameInitials = e.name
                      ?.split(" ")
                      .map((e) => e[0].toUpperCase());

                    // // console.log({ nameInitials });

                    return (
                      <Box className="Template_Card">
                        <Box
                          sx={{
                            borderRadius: "8px",
                            width: "400px",
                            // height: "190px",
                            display: "flex",
                            border: "1px solid #0890FE",
                            flexDirection: "row",
                          }}
                        >
                          <div
                            style={{
                              width: "100%",
                              padding: "12px",
                              height: "100%",
                              display: "flex",
                              flexDirection: "column",
                              gap: "8px",
                            }}
                          >
                            <Box
                              sx={{
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                            >
                              <Box
                                sx={{
                                  display: "flex",
                                  gap: "0.5rem",
                                  alignItems: "center",
                                }}
                              >
                                <Box
                                  sx={{
                                    height: "50px",
                                    width: "50px",
                                    background: "#C9E4FA",
                                    border: "1px solid #C9E4FA",
                                    borderRadius: "10px",
                                  }}
                                >
                                  <Typography
                                    sx={{
                                      my: "12px",
                                      textAlign: "center",
                                      fontSize: "18px",
                                      lineHeight: "27px",
                                      fontFamily: "Product Sans",
                                      fontStyle: "normal",
                                      fontWeight: "600",
                                      color: "#272D4E",
                                    }}
                                  >
                                    {nameInitials?.join("")}
                                  </Typography>
                                </Box>
                                <div
                                  style={{
                                    fontFamily: "Product Sans",
                                    color: "#272D4E",
                                    fontWeight: "600",
                                    // textTransform: "capitalize",
                                    fontSize: "18px",
                                  }}
                                >
                                  {e.name ? e.name : ""}
                                </div>
                              </Box>

                              <Box>
                                <Typography
                                  sx={{
                                    fontSize: "13px",
                                    lineHeight: "18px",
                                    fontFamily: "Product Sans",
                                    fontWeight: "400",
                                  }}
                                >
                                  Date : {e.created_on?.split("T")[0]}
                                </Typography>
                              </Box>
                            </Box>
                            {/* <div
                              style={{
                                fontFamily: "Product Sans",
                                color: "#272D4E",
                                fontWeight: "600",
                                // textTransform: "capitalize",
                                fontSize: "18px",
                              }}
                            >
                              {e.name}
                            </div> */}
                            <Box
                              sx={{
                                height: "40px",
                                width: "100%",
                                textOverflow: "ellipsis",
                                overflow: "hidden",
                              }}
                            >
                              <Typography
                                sx={{
                                  fontSize: "14px",
                                  fontFamily: "Product Sans",
                                  fontStyle: "normal",
                                  fontWeight: "400",
                                  color: "#84828C",
                                }}
                              >
                                {e.description ? e.description : ""}
                                {/* Get access to the data you need, transparent
                                commissions, and
                                buildtrust.asdklfhasdofnseuifsduhfsdifuysdgfyidgasjksfidfvb */}
                              </Typography>
                            </Box>
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "flex-end",
                              }}
                            >
                              {/* <Typography
                                sx={{
                                  fontSize: "12px",
                                  lineHeight: "21px",
                                  fontFamily: "Product Sans",
                                  fontStyle: "normal",
                                  fontWeight: "400",
                                  color: "#84828C",
                                }}
                              >
                              {e.description ? e.description : ""}
                              {/*Get access to the data you need, transparent
                                commissions, and build trust.
                              </Typography> */}
                              <Button
                                className="univercel_btn_class"
                                sx={{
                                  // marginRight:"30px",
                                  color: "#FFFFFF",
                                  width: "200px",
                                  textTransform: "none",
                                  height: "43px",
                                  fontFamily: "Product Sans",
                                  fontSize: "14px",
                                }}
                                onClick={() => {
                                  setState(true);
                                  setTemplateId(e.id);
                                  setTemplateName(e.name);
                                }}
                              >
                                Send Template
                              </Button>
                            </Box>
                            <Box
                              sx={{
                                border: "1px solid #9299BC",
                                height: "0px",
                              }}
                            ></Box>
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "space-between",
                                alignItems: "center",
                              }}
                            >
                              <Box
                                sx={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  alignItems: "center",
                                  gap: "6px",
                                  cursor: "pointer",
                                }}
                                onClick={() => {
                                  navigate(
                                    `/channelpartner/requirements-form/edit`,
                                    { state: { temp: e } }
                                  );
                                }}
                              >
                                <CreateIcon
                                  sx={{ cursor: "pointer", height: "16px" }}
                                ></CreateIcon>
                                <Typography
                                  sx={{
                                    fontFamily: "Product Sans",
                                    fontStyle: "normal",
                                    fontWeight: "500",
                                    fontSize: "14px",
                                    lineHeight: "21px",
                                    color: "#000000",
                                    marginRight: "20px",
                                  }}
                                >
                                  Edit
                                </Typography>
                              </Box>
                              <Box
                                sx={{
                                  display: "flex",
                                  flexDirection: "row",
                                  justifyContent: "space-between",
                                  alignItems: "center",
                                  gap: "6px",
                                  cursor: "pointer",
                                }}
                                onClick={() => {
                                  navigate(
                                    `/channelpartner/requirements-form/view`,
                                    { state: { temp: e } }
                                  );
                                }}
                              >
                                <RemoveRedEyeIcon
                                  sx={{ cursor: "pointer", height: "16px" }}
                                ></RemoveRedEyeIcon>
                                <Typography
                                  sx={{
                                    fontFamily: "Product Sans",
                                    fontStyle: "normal",
                                    fontWeight: "500",
                                    fontSize: "14px",
                                    lineHeight: "21px",
                                    color: "#000000",
                                    marginRight: "20px",
                                  }}
                                >
                                  View
                                </Typography>
                              </Box>
                              <DeleteConfirmationModal
                                e={e}
                                fetchTemplates={fetchTemplates}
                                setTrigger={setTrigger}
                                trigger={trigger}
                              />
                            </div>
                          </div>
                          {/* <div
                        onClick={() => {
                          setState(true);
                          setTemplateId("e.id");
                          setTemplateName("e.name");
                        }}
                        style={{
                          width: "15%",
                          color: "white",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          borderRadius: "0px 8px 8px 0px",
                          background:
                            "linear-gradient(90deg, #0F5DAE 0%, #009DCF 100.33%)",
                        }}
                      >
                        <ArrowForwardIosIcon></ArrowForwardIosIcon>
                      </div> */}
                        </Box>
                      </Box>
                      // <div
                      //   style={{
                      //     borderRadius: "8px",
                      //     boxShadow: "1px 1px 5px 1px grey",
                      //     width: "400px",
                      //     height: "144px",
                      //     display: "flex",
                      //     flexDirection: "row",
                      //   }}
                      // >
                      //   <div
                      //     style={{
                      //       padding: "20px",
                      //       height: "100%",
                      //       width: "90%",
                      //       display: "flex",
                      //       flexDirection: "column",
                      //       gap: "30px",
                      //     }}
                      //   >
                      //     <div style={{ fontWeight: 700, fontSize: "24px" }}>
                      //       {e.name}
                      //     </div>

                      //     <div
                      //       style={{
                      //         display: "flex",
                      //         flexDirection: "row",
                      //         justifyContent: "space-between",
                      //         alignItems: "center",
                      //       }}
                      //     >
                      //       <div
                      //         style={{
                      //           display: "flex",
                      //           justifyContent: "space-between",
                      //           alignItems: "center",
                      //           gap: "10px",
                      //         }}
                      //       >
                      //         <CreateIcon
                      //           sx={{ cursor: "pointer" }}
                      //           onClick={() => {
                      //             navigate(
                      //               `/channelpartner/requirements-form/edit`,
                      //               { state: { temp: e } }
                      //             );
                      //           }}
                      //         ></CreateIcon>
                      //         <Box>Edit</Box>
                      //       </div>
                      //       <div
                      //         style={{
                      //           display: "flex",
                      //           flexDirection: "row",
                      //           justifyContent: "space-between",
                      //           alignItems: "center",
                      //           gap: "10px",
                      //         }}
                      //       >
                      //         <RemoveRedEyeIcon
                      //           sx={{ cursor: "pointer" }}
                      //           onClick={() => {
                      //             navigate(
                      //               `/channelpartner/requirements-form/view`,
                      //               { state: { temp: e } }
                      //             );
                      //           }}
                      //         ></RemoveRedEyeIcon>
                      //         <Box>View</Box>
                      //       </div>
                      //       <div
                      //         style={{
                      //           display: "flex",
                      //           flexDirection: "row",
                      //           justifyContent: "space-between",
                      //           alignItems: "center",
                      //           gap: "10px",
                      //         }}
                      //       >
                      //         <DeleteIcon
                      //           sx={{ cursor: "pointer" }}
                      //           onClick={async () => {
                      //             const x = new AuthService();
                      //             await x.authenticatedDelete(
                      //               api(
                      //                 "/api/leads/requirementtemplate/?template_id=" +
                      //                   e.id
                      //               )
                      //             );
                      //             await fetchTemplates();
                      //           }}
                      //         ></DeleteIcon>
                      //         <Box>Delete</Box>
                      //       </div>
                      //     </div>
                      //   </div>
                      //   <div
                      //     onClick={() => {
                      //       setState(true);
                      //       setTemplateId(e.id);
                      //       setTemplateName(e.name);
                      //     }}
                      //     style={{
                      //       width: "15%",
                      //       color: "white",
                      //       display: "flex",
                      //       justifyContent: "center",
                      //       alignItems: "center",
                      //       borderRadius: "0px 8px 8px 0px",
                      //       background:
                      //         "linear-gradient(90deg, #0F5DAE 0%, #009DCF 100.33%)",
                      //     }}
                      //   >
                      //     <ArrowForwardIosIcon></ArrowForwardIosIcon>
                      //   </div>
                      // </div>
                    );
                  })}
                </Box>
              )}
            </Box>
          </Box>
        </Stack>
      </Stack>
    </SideBar>
  );
};

export default RequirementForm;
