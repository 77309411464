import { useEffect, useState } from "react";
import CreateProjectContext from "./context";

const CreateProjectState = (props) => {
  
  //take project details from locals torage first if it is present
  const [createProjectState, setBrowserState] = useState();

  ///when it call first time in projectprofilecreate page it will overwrite local storage;
  const setcreateProjectState = (state) =>{
    localStorage.setItem("createProjectState",JSON.stringify(state))
    setBrowserState(state)
  }

 //take project details from locals torage first if it is present
 const [cpsp, sbsp] = useState();

 ///when it call first time in projectprofilecreate page it will overwrite local storage;
 const scpsp = (state) =>{
   localStorage.setItem("createProjectStatePersistant",JSON.stringify(state))
   sbsp(state)
 }

  useEffect(() => {
  const ps =  JSON.parse(localStorage.getItem('createProjectStatePersistant'))
  const previousState =  JSON.parse(localStorage.getItem('createProjectState'))
    scpsp(ps)
    setcreateProjectState(previousState)

  }, [])
  
  
 
  return (
    <CreateProjectContext.Provider
      value={{ createProjectState, setcreateProjectState, cpsp, scpsp }}
    >
      {props.children}
    </CreateProjectContext.Provider>
  );
};

export default CreateProjectState;
