import { Button, FormControl, MenuItem, Select, TextField, Typography } from "@mui/material"
import { Stack } from "@mui/system"
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { useEffect, useState } from "react";
import { Apiaddress } from "../../../utils/consts";
import axios from "axios";

export const RightBarUnitAdd = ({setState,buildingIds})=>{

  const [building, setBuilding] = useState("");
  const [wing, setWing] = useState("");
  const [floor, setFloor] = useState("");
  const [unit, setUnit] = useState("")
  const [name, setName] = useState("")
  const [layoutData, setLayoutData] = useState("")
  const [chargeData, setChargeData] = useState("")

  ///get project id from local storage;
  const projectfromlocal = JSON.parse(localStorage.getItem("BuilderSelected"))
  const projectID = projectfromlocal.selectedProjectID

  const [unittemplates, setUnitTemplates] = useState([]);
  useEffect(()=>{
     getUnitTemplates()
    },[])
    
    //get unit templates;
    const getUnitTemplates = async()=>{
    const UnitUrl = Apiaddress+"/api/projects/unittemplatesbyproject/?project_id="+projectID;
    
    await axios.get(UnitUrl).then((res)=>{
      console.log("unit templates: ", res.data);
      if(res.data.unit_template!=="Unit template not found"){
        setUnitTemplates(res.data?.unit_template)
      }
      
    }).catch((err)=>{
      console.log(err);
    })
  }

  //// get wings or floor present inside the selected building 
  let [wingIds, setWingIds] = useState([]);
  let [floorIds, setFloorIds] = useState([]);
  const getWingsOrFloor = async(id)=>{
    setWingIds([]);
    setFloorIds([]);
    const url = Apiaddress+`/api/projects/getwingsusingprojectid/?project_id=${projectID}&filterkey=building__id&filtervalue=${id}`;
    await axios.get(url).then((res)=>{
      console.log("wings", res.data,projectID,id);
      if(res.data.wing.length){
          setWingIds(res.data.wing);
      }else{
        const floorurl = Apiaddress+`/api/projects/getfloorsusingprojectid/?project_id=${projectID}&filterkey=building__id&filtervalue=${id}`
        axios.get(floorurl).then((res)=>{
          console.log("floors", res.data);
          if(res.data.floor.length){
            setFloorIds(res.data.floor);
          }
        }).catch((err)=>{
          console.log(err);
        })
      }
    }).catch((err)=>{
      console.log(err);
    })
  }

  ///get floors by selected wing
  const getFloorByWings = async(id)=>{
    setFloorIds([]);
    const floorurl = Apiaddress+`/api/projects/getfloorsusingprojectid/?project_id=${projectID}&filterkey=wing__id&filtervalue=${id}`
    await axios.get(floorurl).then((res)=>{
      console.log("floorByWing",res.data);
       if(res.data.floor.length){
        setFloorIds(res.data.floor);
       }
    }).catch((err)=>{
      console.log(err);
    })
  } 


  ////create new unit to project 
  const createNewUnit = async()=>{
    let url = Apiaddress+"/api/projects/unit/";
    if(name=="" || floor==""  || projectID=="" || unit=="")return;
    const data = {
      name:name,
      layout_data:layoutData==""?{}:layoutData,
      charges_data:chargeData==""?[]:chargeData,
      floor:+floor.split("-")[0],
      wing:+wing.split("-")[0],
      building:+building.split("-")[0],
      project:projectID
    }
    let headers = {
      headers:{
        "Content-Type":"application/json"
    }
    }
    console.log("ddddddd", data);
    // return;

    await axios.post(url,data,headers).then((res)=>{
      console.log("unit created",res.data);
      setState(false)
      // alert("unit added ")
    }).catch((err)=>{
      console.log("unit created not ",err);
    })
  }
  return(
    <>
          <Stack sx={{
              width: "420px",
              height: "80vh",
              marginTop: "20px",
            //   marginRight: "20px",
              padding: "30px",
          }}>
            <Typography sx={{fontSize:"20px", color:"#2979F2"}}>
                Add Unit
            </Typography>
            <Typography sx={{fontSize:"15px", color:"#B9B7D0"}}>
            Add The Unit Details
            </Typography>

           <Stack direction="row" justifyContent="space-between" alignItems="center" sx={{
            bgcolor:"orange",
            mt:"20px",
            padding:"10px",
            borderRadius:"4px"
           }}>
            <Typography sx={{fontSize:"18px", color:"white"}}>Choose Location</Typography>
            
           </Stack>
           <Stack direction="column" justifyContent="space-between" sx={{mt:"10px"}}>
               <Stack direction="row" justifyContent="space-between" alignItems="center">
                <Typography sx={{fontSize:"18px"}}>Building</Typography>
                <FormControl sx={{ m: 1, minWidth: 220 }}>
                 <Select
                   value={building}
                   onChange={(e)=>{
                       setBuilding(e.target.value)
                       getWingsOrFloor(e.target.value.split("-")[0]);
                   }}
                   displayEmpty
                   inputProps={{ 'aria-label': 'Without label' }}
                 >
                   <MenuItem value="">
                     <em>Select</em>
                   </MenuItem>
                   {buildingIds.map((ele,ind)=>{
                    return <MenuItem value={ele.id+"-"+ele.name}>{ele.name}</MenuItem> 
                   })}
                 </Select>
               </FormControl>
               </Stack>

               {wingIds.length>0 && 
               <Stack direction="row" justifyContent="space-between" alignItems="center">
                <Typography sx={{fontSize:"18px"}}>Wing</Typography>
                <FormControl sx={{ m: 1, minWidth: 220 }}>
                 <Select
                   value={wing}
                   onChange={(e)=>{
                    setWing(e.target.value);
                    getFloorByWings(e.target.value.split("-")[0])
                }}
                   displayEmpty
                   inputProps={{ 'aria-label': 'Without label' }}
                 >
                   <MenuItem value="">
                     <em>Select</em>
                   </MenuItem>
                   {wingIds.map((ele,ind)=>{
                    return <MenuItem value={ele.id+"-"+ele.name}>{ele.name}</MenuItem> 
                   })}
                   {/* <MenuItem value={46}>wing 1</MenuItem> */}
                 </Select>
               </FormControl>
               </Stack>
               }

               {floorIds.length>0 && 
               <Stack direction="row" justifyContent="space-between" alignItems="center">
                <Typography sx={{fontSize:"18px"}}>Floor</Typography>
                <FormControl sx={{ m: 1, minWidth: 220 }}>
                 <Select
                   value={floor}
                   onChange={(e)=>{
                    setFloor(e.target.value)
                }}
                   displayEmpty
                   inputProps={{ 'aria-label': 'Without label' }}
                 >
                   <MenuItem value="">
                     <em>Select</em>
                   </MenuItem>
                   {floorIds.map((ele,ind)=>{
                    return <MenuItem value={ele.id+"-"+ele.name}>{ele.name}</MenuItem> 
                   })}
                   {/* <MenuItem value={173}>floor 1</MenuItem> */}
                 </Select>
               </FormControl>
               </Stack>
               }
           </Stack>

           <Stack direction="row" justifyContent="space-between" alignItems="center" sx={{
            bgcolor:"orange",
            mt:"20px",
            padding:"10px",
            borderRadius:"4px"
           }}>
            <Typography sx={{fontSize:"18px", color:"white"}}>Add Unit</Typography>
            
           </Stack>

           <Stack direction="column" justifyContent="space-between" sx={{mt:"10px"}} spacing={2}>
           <Stack direction="row" justifyContent="space-between" alignItems="center">
           <Typography sx={{fontSize:"18px"}}>Unit Name/Unit No.</Typography>
           <TextField
            value={name}
            onChange={(e)=>{
              setName(e.target.value)
            }}
           />
           </Stack>
           <Stack direction="row" justifyContent="space-between" alignItems="center">
           <Typography sx={{fontSize:"18px"}}>Unit Template</Typography>
            <FormControl sx={{ minWidth: 230 }}>
                 <Select
                   value={unit}
                   onChange={(e)=>{
                     let temp = e.target.value.split("-");
                     let i = +temp[2]
                     setLayoutData(unittemplates[i].layout_data);
                     if(Array.isArray(unittemplates[i].charges_data)){
                       setChargeData(unittemplates[i].charges_data);
                      }else{
                        setChargeData([])
                      }
                      console.log("temp", unittemplates[i])
                     setUnit(e.target.value)
                   }}
                   displayEmpty
                   inputProps={{ 'aria-label': 'Without label' }}
                 >
                   <MenuItem value="">
                     <em>Select</em>
                   </MenuItem>
                   {unittemplates?.map((ele,i)=>{
                     return <MenuItem value={ele.id+"-"+ele.name+"-"+i}>{ele.name}</MenuItem>
                   })}
                 </Select>
               </FormControl>
           </Stack>
           {/* <Stack direction="row" justifyContent="space-between" alignItems="center">
           <Typography sx={{fontSize:"18px"}}>Pricing Template</Typography>
            <FormControl sx={{  minWidth: 230 }}>
                 <Select
                   value={floor}
                   // onChange={handleChange}
                   displayEmpty
                   inputProps={{ 'aria-label': 'Without label' }}
                 >
                   <MenuItem value="">
                     <em>Select</em>
                   </MenuItem>
                   <MenuItem value={10}>Ten</MenuItem>
                 </Select>
               </FormControl>
           </Stack> */}
           </Stack>

           <Stack direction="row" justifyContent="end" spacing={2} sx={{mt:"20px"}}>
               <Button 
               onClick={()=>{
                createNewUnit()
               }}
               className="univercel_btn_class">Create</Button>
               <Button
               onClick={()=>{
                setState(false)
               }}
               className="univercel_btn_class">Cancel</Button>
           </Stack>

          </Stack>
        </>
    )
}