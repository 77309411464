import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Checkbox,
  Drawer,
  FormControl,
  FormControlLabel,
  FormLabel,
  InputLabel,
  MenuItem,
  Modal,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { Stack } from "@mui/system";
import "./LDmainPage.css";
import { NavbarForDirectory } from "../../../BuilderComponents/ProjectDashboard/DashboardComponents/NavbarForDirectory";
import AddIcon from "@mui/icons-material/Add";
import SideBar from "../../Components/sideBar/SideBar";
import React, { useState } from "react";
import { GridCloseIcon } from "@mui/x-data-grid";
import { AuthService } from "../../../../context/buyer/services/auth";
import { api } from "../../../../utils/consts";
import { BasicLayout } from "./basic_layout";
import { GetItem } from "./components/view/ViewReduser";
import FormBuilderModal, {
  getPreviewData,
} from "./components/formBuilderModal/FormBuilderModal";

export const UpdateAtIndex = (arr: [], index: number, newVal: any) => {
  // takes in an array and replaces and returns a new array
  let ar = [...arr];
  //@ts-ignore
  ar[index] = newVal;
  return ar;
};

export const GetSection = (props: any) => {
  // console.log({ props });
  const [SectionState, _setSectionState] = useState(props);
  const setSectionState: React.Dispatch<any> = (e) => {
    _setSectionState(e);
    props.setSuperState(e);
  };

  const updateFormFields = (newFormFields: any) => {
    setSectionState({
      ...SectionState,
      Section: {
        formFields: newFormFields,
      },
    });
  };
  // // console.log("SectionState", SectionState);
  return (
    <div>
      <h2>{SectionState?.Section?.title ?? ""}</h2>
      {(SectionState?.Section?.formFields ?? []).map(
        (e: any, index: number) => {
          // // console.log("basic");
          return GetItem({
            ...e,
            setCurrentValue: (y: any) => {
              if (e.type == "normal") {
                updateFormFields(
                  UpdateAtIndex(SectionState.Section.formFields, index, {
                    ...e,
                    value: y,
                  })
                );
              }
              if (e.type == "withtrailing") {
                updateFormFields(
                  UpdateAtIndex(SectionState.Section.formFields, index, {
                    ...e,
                    value: y,
                  })
                );
              }
              if (e.type == "radio") {
                updateFormFields(
                  UpdateAtIndex(SectionState.Section.formFields, index, {
                    ...e,
                    options: { ...e.options, selected: y },
                  })
                );
              }

              if (e.type == "check") {
                updateFormFields(
                  UpdateAtIndex(SectionState.Section.formFields, index, {
                    ...e,
                    options: UpdateAtIndex(e.options, y, {
                      ...e.options[y],
                      selected: !e.options[y].selected,
                    }),
                  })
                );
              }

              if (e.type == "doubletrailing") {
                if (y.index == 1) {
                  updateFormFields(
                    UpdateAtIndex(SectionState.Section.formFields, index, {
                      ...e,
                      value1: y.value,
                    })
                  );
                } else {
                  updateFormFields(
                    UpdateAtIndex(SectionState.Section.formFields, index, {
                      ...e,
                      value2: y.value,
                    })
                  );
                }
              }
            },
          });
        }
      )}
    </div>
  );
};

function RequirementFormTemplate() {
  const [basicDetailLayout, setBasicDetailLayout] = useState(BasicLayout);
  const [ExtraSections, setExtraSections] = useState([]);
  const [data, setdata] = useState();

  const [open, setopen] = useState({ open: false, reason: "" });
  const [FieldN, setFieldN] = useState("");
  const [Selected, setSelected] = useState("");

  return (
    <>
      <SideBar active={"Requirements"} dontShowNav>
        {/* <Modal
                    open={open.open}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: "40%",
                        height: "auto",
                        bgcolor: 'white',
                        border: '2px solid white',
                        borderRadius: "10px",
                        boxShadow: 24,
                        pt: 3,
                        px: 3,
                        pb: 1.5,
                        textAlign: "center"
                    }}>
                        <Box sx={{ textAlign: "right" }}>
                            <GridCloseIcon sx={{ color: "gray", cursor: "pointer" }} onClick={(e) => {
                                setopen({ ...open, open: !open.open })

                            }} />
                        </Box>
                        {open.reason === "save" && <Typography sx={{
                            fontSize: "18px",
                            fontWeight: "600",
                            marginBottom: "30px",
                            marginTop: "-20px",
                            color: "black",
                        }}>Template Name</Typography>}

                        {open.reason === "save" && <TextField onChange={(e) => {
                            setFieldN(e.target.value)
                        }} >

                        </TextField>}
                        {open.reason === "add" &&
                            <div>
                                <Typography sx={{
                                    fontSize: "18px",
                                    fontWeight: "600",
                                    marginBottom: "30px",
                                    marginTop: "-20px",
                                    color: "black",
                                }}>Add Section</Typography>
                                <div style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "start",
                                    flexDirection: "column"

                                }}>

                                    <TextField sx={{ margin: '10px' }} label={"Title"} onChange={(e) => {
                                        setFieldN(e.target.value)
                                    }} >
                                    </TextField>
                                    <Select
                                        value={Selected}
                                        onChange={(e) => {
                                            setSelected(e.target.value)
                                            // console.log("This is selected", Selected)
                                        }}
                                        displayEmpty
                                        fullWidth
                                        inputProps={{ 'aria-label': 'Without label' }}
                                    >
                                        {["normal", "withtrailing", "radio", "check", "doubletrailing"].map((e) => {
                                            return <MenuItem value={e}>
                                                {e}
                                            </MenuItem>
                                        })}
                                    </Select>

                                </div >

                                <div style={{ marginTop: '10px' }}>
                                    preview

                                </div>
                                {
                                    (Selected != "") && GetItem(getPreviewData(Selected))

                                }

                                <div style={{}}></div>

                            </div>}

                        <Box sx={{ textAlign: "center", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>


                            <Button
                                onClick={async () => {
                                    if (FieldN !== "") {
                                        const x = new AuthService()
                                        await x.makeAuthenticatedPostRequest(api("/api/leads/requirementtemplate/"), {
                                            JsonBody: {
                                                "name": FieldN,
                                                "basic_details": basicDetailLayout,
                                                "other_details": [
                                                    "This field is required."
                                                ],
                                                "channelPartner": x.getCPID()
                                            }
                                        })




                                    }
                                }}
                                variant="contained"
                                color="primary" sx={{ height: "40px", background: "linear-gradient(90deg, #0F5DAE 0%, #009DCF 100.33%)", boxShadow: "0px 13.7981px 22.5788px rgba(50, 174, 137, 0.25)", textTransform: "none", borderRadius: "4px", width: "100px", fontSize: "15px", margin: "20px 0px 10px 0px" }}>
                                Save
                            </Button>

                        </Box>
                        <Typography sx={{ color: "#ff4500", fontWeight: "500", marginBottom: "20px", fontFamily: "Poppins", fontSize: "1.2rem" }}></Typography>
                    </Box>
                </Modal> */}

        <FormBuilderModal
          ExtraSections={ExtraSections}
          setExtraSections={setExtraSections}
          Selected={Selected}
          open={open}
          setopen={setopen}
          setSelected={setSelected}
        ></FormBuilderModal>
        <Stack direction="row" sx={{ width: "100%", height: "auto" }}>
          {/* 2nd stack */}
          <Stack
            direction="column"
            sx={{
              backgroundColor: "#f9f9f9",
              width: "100%",
              padding: "0px",
              boxSizing: "border-box",
            }}
          >
            {" "}
            {/*  width for main sidebar 78px and for 80px*/}
            {/* <Navbar/> */}
            <NavbarForDirectory
              name="Channel Partner"
              showAddProjectbtn={false}
              showRightSideBar={false}
              leftTitle={"Sales Dashboard"}
              memberadd={false}
              teamadd={false}
              dontShowPath
              righshow={true}
              toggleDrawer={undefined}
              RightBarName={undefined}
              showRightSideBarleft={undefined}
              RightBarNameleft={undefined}
              addButton={undefined}
              addTitle={undefined}
              toggleDrawerAdd={undefined}
              RightBarNameProAdd={undefined}
              showRightSideBarProjAdd={undefined}
              leftRightTitle1={undefined}
              showLeftTitle={undefined}
              leftRightTitle2={undefined}
              showUnitAdd={undefined}
              cpaddtitle={undefined}
              cpadd={undefined}
              qdadd={undefined}
              qdaddtext={undefined}
              unitadd={undefined}
              longOrangBgr={undefined}
              openModal={undefined}
              handleOpen={undefined}
            />
            {/* actual content  */}
            <Stack
              sx={{
                backgroundColor: "#f9f9f9",
                mt: "-40px",
              }}
            >
              <Stack spacing={2} sx={{}}>
                <Typography
                  sx={{ fontSize: "22px", fontWeight: "600", color: "black" }}
                >
                  Create New Template
                </Typography>
                <Stack direction="row" alignItems="center">
                  <Button
                    className="univercel_btn_class"
                    sx={{
                      color: "#FFFFFF",
                      textTransform: "none",
                      width: "180px",
                      marginRight: "50px",
                    }}
                    startIcon={<AddIcon />}
                  >
                    Create New
                  </Button>
                  <Typography
                    sx={{
                      fontSize: "14px",
                      fontWeight: "600",
                      color: "#A4A4AD",
                      fontFamily: "Poppins",
                      fontStyle: "normal",
                      lineHeight: "20px",
                      marginRight: "50px",
                    }}
                  >
                    or
                  </Typography>
                  <Typography
                    sx={{
                      fontFamily: "Inter",
                      fontStyle: "normal",
                      fontWeight: "600",
                      fontSize: "16px",
                      lineHeight: "136.02%",
                      marginRight: "50px",
                    }}
                  >
                    Copy from existing
                  </Typography>
                  <Box sx={{ width: "341px" }}>
                    <Select></Select>
                  </Box>
                </Stack>
              </Stack>

              <Box
                className="custom-scrollbar"
                sx={{
                  border: "3px solid lightgray",
                  marginTop: "50px",
                  width: "90%",
                  padding: "30px",
                  borderRadius: "10px",
                  boxShadow: 4,
                  height: "75vh",
                  overflowY: "scroll",
                }}
              >
                <Button
                  onClick={() => {
                    setopen({ reason: "add", open: !open.open });
                  }}
                  className="univercel_btn_class"
                  sx={{
                    color: "#FFFFFF",
                    textTransform: "none",
                    width: "120px",
                  }}
                  startIcon={<AddIcon />}
                >
                  Section
                </Button>

                <GetSection Section={basicDetailLayout}></GetSection>
                {ExtraSections.map((e) => {
                  return <GetSection Section={e}></GetSection>;
                })}
              </Box>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "90%",
                }}
              >
                <Button
                  onClick={() => {
                    setopen({ reason: "save", open: !open.open });
                  }}
                  className="univercel_btn_class"
                  sx={{
                    color: "#FFFFFF",
                    textTransform: "none",
                    width: "120px",
                    my: "50px",
                  }}
                >
                  Save
                </Button>
              </div>
            </Stack>
          </Stack>
        </Stack>
      </SideBar>
    </>
  );
}

export default RequirementFormTemplate;
