import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";

import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import SendIcon from "@mui/icons-material/Send";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Button,
  Checkbox,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  Modal,
  OutlinedInput,
  Select,
  TextField,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";

import AddIcon from "@mui/icons-material/Add";
import axios from "axios";
import { Apiaddress } from "../../../utils/consts";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const names = ["Aditya Birla Appartments", "Tata Farms"];

function RightSidebarUO({
  setTabledata,
  curselectedRows,
  settrigger,
  trigger,
}) {
  const [expanded, setExpanded] = useState("panel1");
  const [disabelAccrodian, setdisabelAccrodian] = useState(
    curselectedRows ? "true" : "false"
  );
  const [selectValue, setSelectValue] = useState("High");
  const [setProjects, setsetProjects] = React.useState([]);

  const handleChangeLeadProject = (event) => {
    const {
      target: { value },
    } = event;
    setsetProjects(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };
  const [selectArr, setselectArr] = useState([
    {
      value: "High",
      label: "High",
    },
    {
      value: "low",
      label: "low",
    },
  ]);

  const createOpt = () => { };

  const handleChange = (i) => {
    setExpanded(i);
  };

  const [colors, setColors] = useState([
    { name: "Ajay", clr: "green" },
    { name: " Tarun", clr: " yellow" },
    { name: "  Ranbir", clr: "red" },
  ]);
  const [leadname, setLeadName] = useState("");
  const [leadclr, setLeadclr] = useState("");

  const addClr = () => {
    const obj = { name: leadname, clr: leadclr };
    setColors([...colors, obj]);
    handleClose();
  };

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [name, setName] = useState("");
  const [state, setState] = useState("");
  const [city, setCity] = useState("");
  const [pincode, setPincode] = useState("");

  const [orgId, setOrgID] = useState(null);

  const addprofiledata = async () => {
    try {
      const obj = {
        org_name: name,
        city: city,
        zip_code: pincode,
        state: state,
      };
      const res = await axios.post(Apiaddress + "/api/org/", obj);
      console.log(res);
      if (res.data)
        if (res.data.org) if (res.data.org.id) setOrgID(res.data.org.id);
    } catch (err) {
      console.log(err);
    }
  };

  // useEffect(() => {
  //   if (orgId) {
  //     console.log(orgId);
  //   }
  // }, [orgId]);

  const [token, setToken] = useState(null);

  useEffect(() => {
    setToken(localStorage.getItem("crm_token"));
  }, []);

  const addOrgtoProfile = async () => {
    try {
      const headers = {
        Authorization: `JWT ${token}`,
      };
      const obj = {
        org_id: orgId,
      };

      const res = await axios.post(
        Apiaddress + "/api/addorgtoprofile/",
        obj,
        { headers: headers }
      );

      settrigger(!trigger);

      console.log(res);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (token && orgId) {
      addOrgtoProfile();
    }
  }, [token, orgId]);

  return (
    <Box
      sx={{
        width: "420px",
        height: "80vh",
        marginTop: "-25px",
        marginRight: "25px",
        padding: "10px",
      }}
    >
      <Box
        sx={{
          position: "relative",
        }}
      >
        <Box
          sx={{
            position: "absolute",
            top: "0px",
            right: "0px",
            background: "white",
            padding: "10px",
            height: "105vh",
            width: "400px",
            marginTop: "20px",
          }}
        >
          {" "}
          <Box>
            <Box sx={{ display: "flex", cursor: "pointer" }}>
              <Box>
                <ArrowBackIosIcon
                  sx={{
                    fontSize: "small",
                    marginBottom: "-2px",
                  }}
                />
              </Box>
              <Box>Buyer file generation</Box>
            </Box>
            <Box
              sx={{
                display: "grid",
                alignItems: "center",
                height: "100vh",
              }}
            >
              <Box>
                <Accordion
                  expanded={true}
                  onClick={() => handleChange("panel1")}
                >
                  <AccordionSummary
                    sx={{
                      background: "white",
                      color: "black",
                      borderRadius: "10px",
                    }}
                    // expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <TextField
                      placeholder="Search by Email"
                      sx={{
                        borderTopRightRadius: "0",
                        borderTopLeftRadius: "0",
                      }}
                    />
                    <Button className="univercel_btn_class">Create new</Button>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        width: "400px",
                        margin: "10px 0",
                      }}
                    >
                      <Box>Name</Box>
                      <Box>
                        <TextField
                          id="outlined-basic"
                          onChange={(e) => setName(e.target.value)}
                        />
                      </Box>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        width: "400px",
                        margin: "10px 0",
                      }}
                    >
                      <Box>Phone Number</Box>
                      <Box>
                        <TextField
                          id="outlined-basic"
                          onChange={(e) => setState(e.target.value)}
                        />
                      </Box>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        width: "400px",
                        margin: "10px 0",
                      }}
                    >
                      <Box>Email</Box>
                      <Box>
                        <TextField
                          id="outlined-basic"
                          onChange={(e) => setCity(e.target.value)}
                        />
                      </Box>
                    </Box>

                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        width: "400px",
                        margin: "10px 0",
                      }}
                    >
                      <Box></Box>
                      <Box>
                        <Button
                          onClick={addprofiledata}
                          className="univercel_btn_class"
                          sx={{
                            color: "#FFFFFF",
                            textTransform: "none",
                          }}
                        >
                          skip
                        </Button>
                        <Button
                          onClick={addprofiledata}
                          className="univercel_btn_class"
                          sx={{
                            color: "#B0AFAF",
                            textTransform: "none",
                            marginLeft: "10px",
                          }}
                        >
                          procced
                        </Button>
                      </Box>
                    </Box>
                  </AccordionDetails>
                </Accordion>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export default RightSidebarUO;
