import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import axios from "axios";

export default function ErrorDialog({
  open,
  handleClose,
  errorData,
  setErrorData,
  setCSVData,
  errorType,
  setValidState,
}) {
  // const [open, setOpen] = React.useState(false);
  const [localCopy, setLocalCopy] = React.useState(errorData);
  const [errorCount, setErrorCount] = React.useState(0);
  let headers = errorData[0] ? Object.keys(errorData[0]?.rowData) : [];
  const onClose = () => {
    setErrorData([]);
    handleClose();
  };
  const handleInput = (e, index, headerVal) => {
    console.log(e.target.value, index, headerVal);
    setLocalCopy((prev) => {
      let newData = Array.from(prev);
      newData[index].rowData[headerVal] = e.target.value;
      return newData;
    });
  };
  let checkError = (value) => {
    if (value == "") return false;
    let numVal = Number(value);
    // console.log(numVal);
    if (isNaN(numVal)) return false;
    if (numVal < 0) return false;
    else return true;
  };
  let getErrorCount = () => {
    let count = 0;
    let rowCount = localCopy.length;
    for (let i = 0; i < rowCount; i++) {
      headers.forEach((ele) => {
        if (!checkError(localCopy[i].rowData[ele])) count++;
      });
    }
    setErrorCount(count);
  };
  const handelSubmit = () => {
    setCSVData((prev) => {
      let newCopy = Array.from(errorType === "pricing" ? prev.pricing : prev.layout);
      localCopy.forEach((data) => {
        newCopy[data.index] = data.rowData;
      });
      if (errorType === "pricing") {
        setValidState((prev) => ({ ...prev, isPricingValid: true }));

        return { ...prev, pricing: newCopy };
      } else if (errorType === "layout") {
        setValidState((prev) => ({ ...prev, isLayoutValid: true }));

        return { ...prev, layout: newCopy };
      }
    });
    onClose();
    // axios({
    //   method: "patch",
    //   url: "http://139.59.70.194/api/csv/preprojectcsv/",
    //   data: {
    //     error: localCopy.map((ele) => ({ ...ele.rowData, index: ele.index })),
    //     email: 1663679276224,
    //   },
    //   headers: { "Content-Type": "application/json" },
    // })
    //   .then(function (response) {
    //     //handle success
    //     console.log(response);
    //     // setsettleddata(response.data.msg);
    //   })
    //   .catch(function (response) {
    //     //handle error
    //     console.log(response);
    //   });
  };

  React.useEffect(() => {
    setLocalCopy(errorData);
  }, [errorData]);

  React.useEffect(() => {
    getErrorCount();
  }, [localCopy]);
  return (
    <Dialog fullWidth maxWidth={"lg"} open={open} onClose={onClose}>
      <DialogTitle>Error Dialog</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {errorCount == 0
            ? "Errors Resolved, Click Submit."
            : `Correct ${errorCount} errors maunally.`}
        </DialogContentText>
        <table style={{ overflow: "scroll" }}>
          <thead>
            <tr>
              {headers.map((ele, i) => {
                return <td key={i}>{ele}</td>;
              })}
            </tr>
          </thead>
          <tbody>
            {localCopy?.map((ele, i) => {
              return (
                <tr key={i}>
                  {headers?.map((keys, j) => {
                    let value = ele.rowData[keys];
                    let background = checkError(value) ? "#fff" : "#F00";
                    return (
                      <td key={j} style={{ backgroundColor: background }}>
                        <input value={value} onChange={(e) => handleInput(e, i, keys)}></input>
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </DialogContent>
      <DialogActions>
        <Button disabled={errorCount != 0} onClick={handelSubmit}>
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
}
