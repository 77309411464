import { Box, Button, TextField, Typography } from "@mui/material"
import { Stack } from "@mui/system"
import { Navbar } from "../DashboardComponents/Navbar"
import { Sidebar } from "../DashboardComponents/Sidebar"
import DoneIcon from '@mui/icons-material/Done';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import { useNavigate } from "react-router-dom";

import { useEffect, useState ,useContext } from "react";


import Section from './Section';
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import Modal from '@mui/material/Modal';
import currentProjectContext from "../../../../context/createproject/context"
import { partchUnitWithPricingData } from "../../../../connection/project/patch_unit_layout";
import { MainSidebar } from "../DashboardComponents/MainSidebar";



export const ProjectPricing = ()=>{
    const navigate = useNavigate();
    const cp = useContext(currentProjectContext)

      const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const [plank,setplank]=useState(false);

    const [addsec,setaddsec]=useState({
        titlename:"", 
        number:0,
        field:[]
    }); 

     const [title,settitle]=useState([{titlename:"Basic",number:1,sectioncost:0,field:[{key:"Basic Amount",value:"",index:1},{key:"Floor Rise",value:"",index:2},{key:"Parking Charges-1",value:"",index:3},{key:"Parking Charges-2",value:"",index:4},{key:"Parking Charges-3",value:"",index:5}]},{titlename:"Other Charges",number:2,sectioncost:0,field:[{key:"Club Membership",value:"",index:1},{key:"Society Formation",value:"",index:2},{key:"Conveyance",value:"",index:3},{key:"Water Connection",value:"",index:4},{key:"Advance Maintainance",value:"",index:5}]},{titlename:"Official Charges",number:3,sectioncost:0,field:[{key:"Stamp Duty",value:"",index:1},{key:"Registration Fees",value:"",index:2},{key:"Dsc Charges",value:"",index:3},{key:"GST",value:"",index:4}]}]);

    let name,value;
     const getinfodata=(e)=>{
     name=e.target.name;
     value=e.target.value;
     setaddsec({...addsec,[name]:value,number:title.length+1,field:[],sectioncost:0});
    }

    console.log(title);

    useEffect(()=>{
       settitle(title);
    },[plank]);
      
    
    

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: "350px",
        height:"250px",
        bgcolor: 'background.paper',
        boxShadow: 4,
        borderRadius:"16px",
        p: 4
      };
      

    return (
        <>


<Stack direction="row" sx={{width:"100%",height:"auto" }}>
      {/* 1st stack */}
      <Stack sx={{minHeight:"100vh",width:"20%",boxSizing:"border-box",}}> {/*for project directory 20% and for sales dashboard 225*/}
        <MainSidebar active={"Project Directory"}/>
      </Stack>

     {/* 2nd stack */}
     <Stack direction="column" sx={{ backgroundColor:"#f9f9f9",width:"80%", padding:"25px",boxSizing:"border-box",}}  >  {/*  width for main sidebar 78px and for 80px*/}
      <Navbar/>
      


      <Typography sx={{marginTop:"20px", fontSize:"28px", fontWeight:"600", marginLeft:"10px",marginBottom:"50px"}}>
                    Create New Project 
           </Typography>
     {/* actual content  */}
    <Stack sx={{ backgroundColor:"#f9f9f9"}}>

   
    <Stack direction="column"  sx={{  padding:"20px", backgroundColor:"#fff", marginRight:"0px"}}>

<Stack direction="row" justifyContent="start" alignItems="center" sx={{height:"100px"}}>
                
                <Stack direction="row" alignItems="center" spacing={1.5} sx={{ }}>
                  <Box  sx={{width:"29px", height:"29px",borderRadius:"50%", backgroundColor:"#FFA946", display:"flex", justifyContent:"center", alignItems:"center"}}>
                    <DoneIcon sx={{color:"#fff", fontSize:"20px"}}/>
                  </Box>
                    <Typography sx={{fontSize:"18px", fontWeight:"500"}}>Profile</Typography>
                    <Stack direction="row" alignItems="center" >
                     <Box sx={{width:"25px", height:"0px", borderBottom:"2px dashed orange"}}></Box>
                     <Box sx={{height:"10px", width:"10px",borderRadius:"50%",  backgroundColor:"orange"}}></Box>
                    </Stack>
                </Stack>
                <Stack direction="row" alignItems="center" spacing={1.5} sx={{ marginLeft:"20px"}}>
                  <Box  sx={{width:"29px", height:"29px",borderRadius:"50%", backgroundColor:"#FFA946", display:"flex", justifyContent:"center", alignItems:"center"}}>
                    <DoneIcon sx={{color:"#fff", fontSize:"20px"}}/>
                  </Box>
                    <Typography sx={{fontSize:"18px", fontWeight:"500"}}>Unit layout Items</Typography>
                    <Stack direction="row" alignItems="center" >
                     <Box sx={{width:"25px", height:"0px", borderBottom:"2px dashed orange"}}></Box>
                     <Box sx={{height:"10px", width:"10px",borderRadius:"50%",  backgroundColor:"orange"}}></Box>
                    </Stack>
                </Stack>
                <Stack direction="row" alignItems="center" spacing={1.5} sx={{ marginLeft:"20px"}}>
                  <Box  sx={{width:"29px", height:"29px",borderRadius:"50%", backgroundColor:"#FFA946", display:"flex", justifyContent:"center", alignItems:"center"}}>
                    <DoneIcon sx={{color:"#fff", fontSize:"20px"}}/>
                  </Box>
                    <Typography sx={{fontSize:"18px", fontWeight:"500"}}>Unit Pricing Items</Typography>
                    <Stack direction="row" alignItems="center" >
                     <Box sx={{width:"25px", height:"0px", borderBottom:"2px dashed #A2A3A6"}}></Box>
                     <Box sx={{height:"10px", width:"10px",borderRadius:"50%",  backgroundColor:"#A2A3A6"}}></Box>
                    </Stack>
                </Stack>
                <Stack direction="row" alignItems="center" spacing={1.5} sx={{ marginLeft:"20px"}}>
                  <Box  sx={{width:"29px", height:"29px",borderRadius:"50%", backgroundColor:"#A2A3A6", display:"flex", justifyContent:"center", alignItems:"center"}}>
                    <DoneIcon sx={{color:"#fff", fontSize:"20px"}}/>
                  </Box>
                    <Typography sx={{fontSize:"18px", fontWeight:"500"}}>Templates</Typography>
                </Stack>
            </Stack>

         {/* pricing box */}
         <Box sx={{}}>
 <Typography className='' sx={{margin:"30px 0px 0px 40px",fontFamily:'Poppins',fontStyle:"normal",fontWeight:"500",fontSize: "18px",lineHeight:"27px",color:"#FF6140"}}>Define Your Unit Pricing Items*</Typography>

 <Button sx={{margin:"25px 0px 0px 30px",fontFamily:'Poppins',fontStyle:"normal",fontWeight:"500",fontSize: "18px",lineHeight:"27px",color:"#2979F2"}} onClick={()=>{setOpen(true)}}>Add a New Section<AddIcon/></Button>
 
 <Modal
 open={open}  
 onClose={handleClose}
 aria-labelledby="modal-modal-title"
 aria-describedby="modal-modal-description">
<Box sx={style}>
<Stack direction="row" justifyContent="space-between" alignItems="center">
     <Typography id="modal-modal-title" variant="h6" component="span" sx={{fontFamily:'Poppins',fontStyle:"normal",fontWeight:"500",fontSize: "18px",lineHeight:"27px",color:"#FF6140"}}>
     Add Section
     </Typography>
     <Box onClick={()=>{setOpen(false);setaddsec("")}} sx={{cursor:"pointer"}}><CloseIcon /></Box>   
</Stack>
<Box sx={{alignItems:"center",margin:"40px 0px 0px 0px"}}>
 <Stack direction="row" justifyContent="start" alignItems="center" sx={{margin:"10px 0px 0px 0px",fontSize: "18px"}}>
     <Typography component="span" className='field-name'>Input Section Name</Typography>
     <TextField
      sx={{
         
          width:"188px",
          marginLeft:"10px"
         }} name="titlename" value={addsec.titlename} onChange={getinfodata}/>
 </Stack>
 </Box>
 <Button 
    type="submit"
     variant="contained"
      color="primary" sx={{ height:"45px", background: "linear-gradient(90deg, #0F5DAE 0%, #009DCF 100.33%)",boxShadow: "0px 13.7981px 22.5788px rgba(50, 174, 137, 0.25)", textTransform: "none", borderRadius: "4px", width:"100px", fontSize:"15px",margin:"60px 0px 30px 120px"}} onClick={()=>{
         if(!addsec.titlename){
             return;
         }
         settitle(title=> title.concat(addsec));
         setOpen(false);
         setaddsec("");
     }}>
     Done
</Button>
</Box>
</Modal>


 <Stack>
     {title.map((ele,index)=>{
         return(
             <>
             <Section index={index} header={ele} title={title} settitle={settitle} setplank={setplank} plank={plank}/>
             </>
         )
     })}
 </Stack>

</Box>

            <Stack direction="row" justifyContent="end" alignItems="center" sx={{marginTop:"50px"}}>
               <Button size="large" className="univercel_btn_class" sx={{textTransform:"none", color:"#fff"}}
                onClick={async()=>{
                 console.log('====================================');
                 console.log(cp);
                 console.log('====================================');                            

                 await partchUnitWithPricingData(
                   title,
                   cp.createProjectState.project.unit_layout,
                 )
                  navigate("/createproject/projectpricing/projectcreateoption")
                }}
               endIcon={<KeyboardDoubleArrowRightIcon/>} >Next</Button>
              
            </Stack>

</Stack>

    </Stack>




      </Stack>
</Stack>



          
        
        </>
    )
}