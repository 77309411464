import { Box, Button, Checkbox, Stack, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Apiaddress, net } from "../../../../utils/consts";
import { AuthService } from "../../../../context/buyer/services/auth";
import axios from "axios";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import KeyboardDoubleArrowLeftIcon from "@mui/icons-material/KeyboardDoubleArrowLeft";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import CircularProgress from "@mui/material/CircularProgress";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { useNavigate, useParams } from "react-router";
import Lottie from "react-lottie";
import animationData from "../../../../Lotties/oGze35Xf1B.json";

let stIndex=0;
let edIndex=0;

function DeassignStandAloneUnitClient({
  selectedProject,
  setSelectedProject,
  self,
  trigger,
  setTrigger,
  deAllocateProject
}) {
  const [data, setData] = useState(null);
  const [tempData, setTempData] = useState(null);
  const [token, setToken] = useState(null);
  const [newRowData, setNewRowData] = useState([]);
  const [loading, setloading] = useState(false);

  console.log(newRowData);

  const navigate = useNavigate();
  const par = useParams();

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  let cp_id = JSON.parse(localStorage.getItem("crm_user")).id;
  const cpDetails=JSON.parse(localStorage.getItem('cpDetails'));
    console.log(cpDetails);
    let cpOrg=cpDetails?.channelPartnerOrg;


  useEffect(() => {
    setToken(localStorage.getItem("crm_token"));
  }, [trigger]);

  const filterData=(datah)=>{
    console.log(self);
    let temp=[];
    if(self?.length==0){
       setData([]); 
    }
    else{
      for(let i=0;i<self?.length;i++){
        let temp1=datah.filter((ele)=>{
          return(
            ele.id==self[i]
          )
        })
        if(temp1[0]){
          temp.push(temp1[0]);
        }
      }
    setData(temp);
    console.log(temp);
    }
    setloading(false);
  };

  const getProjects = async () => {
    setloading(true);
    try {
      const auth = new AuthService();
      let url="";
        console.log(cpOrg);
      if(cpOrg){
        url=`role/inventeryofcp/?orgId=${cpOrg}`
      }
      else{
        url=`role/inventeryofcp/`
      }
      const res1 =await (await auth.makeAuthenticatedGetRequest(net(url))).json();
      if(res1?.self["filtured Data"]){
        let datahai= res1?.self["filtured Data"]?.units;
        console.log(datahai);
        setTempData(datahai);
      }

    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (tempData) {
      filterData(tempData);
    }
  }, [tempData]);

  const fitData=async ()=>{
    let arr = [];
    for (let i = 0; i < data.length; i++) {
      const res = await axios.get(Apiaddress + "/api/projects/cpunit/?unit_id="+data[i].id);
      let temp=res?.data?.unit[0];
      console.log("===========================.>", res?.data?.unit[0]);
      let unit_data = JSON.parse(temp?.unit_data);
        let status = "";
        if (temp?.unit_status == 1) {
          status = "Unavailable";
        } else if (temp?.unit_status == 2) {
          status = "Reserved";
        } else if (temp?.unit_status == 3) {
          status = "Registered";
        } else if (temp?.unit_status == 4) {
          status = "Booked";
        } else if (temp?.unit_status == 5) {
          status = "Available";
        }
        // if(status=="Booked"){
        //   continue;
        // }
        let obj = {
          id: i + 1,
          project_id: temp?.id,
          Name: temp?.name,
          State: unit_data.Type,
          City: unit_data.City,
          Pincode: unit_data.For,
          Unit: unit_data.Area,
        };
        arr.push(obj);
      }
      setNewRowData(arr);
  }


  useEffect(() => {
    if (data) {
      console.log(data);
      fitData();
      // let arr = [];
      // for (let i = 0; i < data.length; i++) {
      //   let unit_data = JSON.parse(data[i].unit_data);
      //   console.log(unit_data);
      //   let obj = {
      //     id: i + 1,
      //     project_id: data[i].id,
      //     Name: data[i].name,
      //     State: unit_data.Type,
      //     City: unit_data.City,
      //     Pincode: unit_data.For,
      //     Unit: unit_data.Area,
      //   };
      //   arr.push(obj);
      // }
      // setNewRowData(arr);
    }
  }, [data]);

  // useEffect(() => {
  //   if (data) {
  //     console.log(data);
  //     let arr = [];
  //     for (let i = 0; i < data.length; i++) {
  //       let unit_data=JSON.parse(data[i]?.unit_data);
  //       console.log(unit_data);
  //       let obj = {
  //         id: i + 1,
  //         project_id: data[i]?.id,
  //         Name: data[i]?.name,
  //         State:  unit_data?.Type,
  //         City:  unit_data?.City,
  //         Pincode: unit_data?.For,
  //         Unit:unit_data?.Area,
  //       };
  //       arr.push(obj);
  //     }
  //     setNewRowData(arr);
  //   }
  // }, [data]);

  useEffect(() => {
    if (cp_id) getProjects();
  }, [token, trigger]);

  //table Content
  const [curpage, setcurpage] = useState(1);
  const [pageRange, setPageRange] = useState([0, 5]);
  const [pages, setPages] = useState([]);

  const nextClick = () => {
    if (
      !(newRowData.length >= pageRange[0] && newRowData.length <= pageRange[1])
    ) {
      setcurpage(curpage + 1);
       stIndex=pageRange[0]+6;
      edIndex=pageRange[1]+6;
       if(newRowData?.length<edIndex){
        edIndex=newRowData?.length-1;
      }
      setPageRange([pageRange[0] + 6, pageRange[1] + 6]);
      setSelectedProject([]);
    }
  };

  const prvClick = () => {
    if (pageRange[0] != 0 && pageRange[1] != 5) {
      setcurpage(curpage - 1);
      stIndex=pageRange[0]-6;
      edIndex=pageRange[1]-6;
      setPageRange([pageRange[0] - 6, pageRange[1] - 6]);
      setSelectedProject([]);
    }
  };

  useEffect(() => {
    if (newRowData) {
      let arr = [];
      for (
        let i = 1;
        i <=
        (newRowData.length % 5 == 0
          ? newRowData.length / 5
          : newRowData.length / 5 + 1);
        i++
      ) {
        arr.push(i);
      }
      stIndex=0;
      console.log(newRowData?.length);
      if(newRowData?.length>0 && newRowData?.length<edIndex){
        edIndex=newRowData?.length-1;
      }
      else{
        edIndex=5;
      }
      setPages(arr);
      setPageRange([0,5]);
    }
  }, [newRowData]);

  let temp = [];
  const onCheck = (id) => {
    if(id==="selectAll"){
     if(newRowData?.length>0 && newRowData?.length<edIndex){
        edIndex=newRowData?.length-1;
      }
      for(let i=stIndex; i<=edIndex; i++){
        temp.push(newRowData[i].project_id);
      }
      setSelectedProject(temp);
    } else if (id == "removeAll") {
      temp = [];
      setSelectedProject(temp);
    } else {
      let array = selectedProject;
      const index = array.indexOf(id);
      if (index > -1) {
        let temp1 = array.filter((ele) => {
          return ele != selectedProject[index];
        });
        temp = temp1;
      } else {
        temp = [...selectedProject, id];
      }
    }
    console.log("temp", temp);
    setSelectedProject(temp);
  };

   console.log("PageRange===",pageRange,"st===",stIndex,"end====",edIndex,"===",selectedProject);

  return (
    <>
    <Stack direction="row" justifyContent="space-between" alignItems="center" sx={{marginTop:"10px"}}>
      <Stack>
      <Box>
            {selectedProject.length != 0 && (
              <Stack direction="row" sx={{}}>
                {
                  <Button
                    onClick={() => {
                      deAllocateProject();
                    }}
                    className="univercel_btn_class"
                    sx={{
                      color: "#FFFFFF",
                      textTransform: "none",
                      width: "150px",
                      marginLeft: "0px",
                      background: "#008dff",
                      boxShadow:
                        "0px 13.7981px 22.5788px rgba(50, 174, 137, 0.25)",
                      "&:hover": { background: "#008dff" },
                    }}
                  >
                    Deallocate
                  </Button>
                }
              </Stack>
            )}
          </Box>
      </Stack>
      {/* <Stack>Hello</Stack> */}
    </Stack>
    <Box>
      <Box
        sx={{
          color: "black",
          width: "100%",
          marginTop:"10px",
          boxSizing: "border-box",
          "&:hover": {
            boxShadow: "-6px 18px 15px rgba(0, 0, 0, 0.1)",
          },
        }}
       >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            background: "white",
            borderRadius: "5px",
            color: "#272D4E",
            fontWeight: "700",
            fontFamily: "Circular Std",
            fontSize: "18px",
            gap: "15px",
            padding: "10px 20px",
            borderBottom: "1px solid #E9ECFF",
            borderRadius: "20px 20px 0 0",
          }}
         >
          <Box sx={{ width: "10%" }}>
            <Checkbox
              checked={
                (edIndex-stIndex)+1 === selectedProject.length ? true : false
              }
              onClick={() => {
                if ((edIndex-stIndex)+1 === selectedProject.length) {
                  onCheck("removeAll");
                } else {
                  onCheck("selectAll");
                }
              }}
            />
          </Box>
          <Box
            sx={{
              width: "18%",
              display: "flex",
              alignItems: "center",
            }}
          >
            <Box>Name</Box>
            {/* <Box sx={{ display: "flex", flexDirection: "column" }}>
              <ArrowDropUpIcon
                fontSize="large"
                sx={{ marginBottom: "-12px", cursor: "pointer" }}
                // onClick={() => sortArr("Name")}
              />
              <ArrowDropDownIcon
                fontSize="large"
                sx={{ marginTop: "-12px", cursor: "pointer" }}
                // onClick={() => sortArrDec("Name")}
              />
            </Box> */}
          </Box>
          <Box
            sx={{
              width: "18%",
              display: "flex",
              alignItems: "center",
            }}
          >
            <Box>Type</Box>
            {/* <Box sx={{ display: "flex", flexDirection: "column" }}>
              <ArrowDropUpIcon
                fontSize="large"
                sx={{ marginBottom: "-12px", cursor: "pointer" }}
                // onClick={() => sortArr("Name")}
              />
              <ArrowDropDownIcon
                fontSize="large"
                sx={{ marginTop: "-12px", cursor: "pointer" }}
                // onClick={() => sortArrDec("Name")}
              />
            </Box> */}
          </Box>
          <Box
            sx={{
              width: "18%",
              display: "flex",
              alignItems: "center",
            }}
          >
            <Box>City</Box>
            {/* <Box sx={{ display: "flex", flexDirection: "column" }}>
              <ArrowDropUpIcon
                fontSize="large"
                sx={{ marginBottom: "-12px", cursor: "pointer" }}
                // onClick={() => sortArr("Name")}
              />
              <ArrowDropDownIcon
                fontSize="large"
                sx={{ marginTop: "-12px", cursor: "pointer" }}
                // onClick={() => sortArrDec("Name")}
              />
            </Box> */}
          </Box>
          <Box
            sx={{
              width: "18%",
              display: "flex",
              alignItems: "center",
            }}
          >
            <Box>For</Box>
            {/* <Box sx={{ display: "flex", flexDirection: "column" }}>
              <ArrowDropUpIcon
                fontSize="large"
                sx={{ marginBottom: "-12px", cursor: "pointer" }}
                // onClick={() => sortArr("Name")}
              />
              <ArrowDropDownIcon
                fontSize="large"
                sx={{ marginTop: "-12px", cursor: "pointer" }}
                // onClick={() => sortArrDec("Name")}
              />
            </Box> */}
          </Box>
          <Box
            sx={{
              width: "18%",
              display: "flex",
              alignItems: "center",
            }}
          >
            <Box>Area</Box>
            {/* <Box sx={{ display: "flex", flexDirection: "column" }}>
              <ArrowDropUpIcon
                fontSize="large"
                sx={{ marginBottom: "-12px", cursor: "pointer" }}
                // onClick={() => sortArr("Name")}
              />
              <ArrowDropDownIcon
                fontSize="large"
                sx={{ marginTop: "-12px", cursor: "pointer" }}
                // onClick={() => sortArrDec("Name")}
              />
            </Box> */}
          </Box>
        </Box>
        {loading ? (
          <>
            <Box sx={{ textAlign: "center", marginTop: "50px" }}>
              <CircularProgress color="inherit" sx={{ color: "grey.500" }} />
            </Box>
          </>
          ) : newRowData?.length == 0 ? (
          <Box sx={{ textAlign: "center", marginTop: "60px" }}>
            <Typography
              sx={{ fontSize: "25px", fontWeight: "500", color: "#B9B7D0" }}
            >
              <Lottie options={defaultOptions} height="20%" width="20%" />
                <Typography
                  sx={{
                    color: "#B9B7D0",
                    fontSize: "18px",
                    fontWeight: "400",
                    marginTop: "-40px",
                  }}
                >
                  Please add your data
                </Typography>
            {/* <Button
                  className="univercel_btn_class"
                  sx={{
                    textTransform: "none",
                    borderRadius: "4px",
                    width: "150px",
                  }}
                  onClick={(e) => {
                    navigate(`/channelpartner/client/project/assign/${par.id}`);
                  }}
                >
                  Add New Unit
                </Button> */}
            </Typography>
          </Box>
          ) : (
          <>
          <Box sx={{height:"400px"}}> 
            {newRowData &&
              newRowData.map((item, index) => {
                if (index >= pageRange[0] && index <= pageRange[1]) {
                  return (
                    <Box
                      sx={{
                        backgroundColor: "#FFF",
                        padding: "15px 20px",
                        display: "flex",
                        alignItems: "center",
                        gap: "15px",
                        "&:hover": {
                          backgroundColor: "#F5F7FF",
                        },
                      }}
                    >
                      
                      <Box sx={{ width: "10%" }}>
                        {/* {`${JSON.stringify(selectedProject.indexOf(item.project_id))}`} */}
                        <Checkbox
                          checked={
                            selectedProject.indexOf(item.project_id) > -1
                              ? true
                              : false
                          }
                          onClick={() => {
                            onCheck(item.project_id);
                          }}
                        />
                      </Box>
                      <Box
                        sx={{
                          width: "18%",
                          fontFamily: "Product Sans",
                          fontWeight: "400",
                        }}
                      >
                        {item.Name}
                      </Box>
                      <Box
                        sx={{
                          width: "18%",
                          fontFamily: "Product Sans",
                          fontWeight: "400",
                        }}
                      >
                        {item.State}
                      </Box>
                      <Box
                        sx={{
                          width: "18%",
                          fontFamily: "Product Sans",
                          fontWeight: "400",
                        }}
                      >
                        {item.City}
                      </Box>
                      <Box
                        sx={{
                          width: "18%",
                          fontFamily: "Product Sans",
                          fontWeight: "400",
                        }}
                      >
                        {item.Pincode}
                      </Box>
                      <Box
                        sx={{
                          width: "18%",
                          fontFamily: "Product Sans",
                          fontWeight: "400",
                        }}
                      >
                        {item.Unit}
                      </Box>
                    </Box>
                  );
                }
              })}
          </Box>
           <Box sx={{ margin: "50px 0px 20px 0px" }}>
           <Box
             sx={{
               display: "flex",
               justifyContent: "right",
               marginRight: "20px",
             }}
           >
             <Box
               sx={{
                 display: "flex",
                 placeItems: "center",
                 margin: " 0 10px",
                 borderRadius: "5px",
               }}
             >
               <Typography sx={{}}>Page</Typography>
               <Typography
                 sx={{
                   background: `white`,
                   color: "black",
                   width: "60px",
                   height: "30px",
                   borderRadius: "4px",
                   margin: "0 10px",
                   display: "grid",
                   placeItems: "center",
                   border: "1px solid #2979F2",
                 }}
               >
                 {curpage}
               </Typography>
               <Typography
                 sx={{
                   color: "black",
                   width: "30px",
                   borderRadius: "5px",
                   margin: "0 0px",
                   display: "grid",
                   placeItems: "center",
                 }}
               >
                 of
               </Typography>
               <Typography
                 sx={{
                   color: "black",
                   margin: "0px 10px 0px 0px",
                   display: "grid",
                   placeItems: "center",
                 }}
               >
                 {pages?.length}
               </Typography>
             </Box>
   
             {newRowData.length != 0 && (
               <Button
                 variant="contained"
                 sx={{marginRight: "10px" }}
                 className="paginationButton"
                 disabled={curpage == 1}
                 onClick={prvClick}
               >
                 <KeyboardDoubleArrowLeftIcon fontSize="medium" />
               </Button>
             )}
   
             {newRowData.length != 0 && (
               <Button
                 variant="contained"
                 className="paginationButton"
                 disabled={curpage == pages?.length}
                 sx={{}}
                 onClick={nextClick}
               >
                 <KeyboardDoubleArrowRightIcon fontSize="medium" />
               </Button>
             )}
           </Box>
         </Box>
         </>
        )}

      </Box>
     
    </Box>
    </>
  );
}

export default DeassignStandAloneUnitClient;
