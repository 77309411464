import { Box, Button, Checkbox, CircularProgress, Modal, Tooltip, Typography } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Apiaddress } from "../../../utils/consts";
import CloseIcon from '@mui/icons-material/Close';
import { Stack } from "@mui/system";

function AssociateProjectUnits({ cpid }) {

  const [cpUnitId,setCpUnitId]=useState();
  const [cpProUnitId,setCpProUnitId]=useState();
  const [cpProUnit,setCpUnit]=useState();
  const [cpProFilterUnit,setCpProFilterUnit]=useState();
  const [selectedUnitToAdd,setSelectedUnitToAdd]=useState([]);
  const [selectedUnitToDelete,setSelectedUnitToDelete]=useState([]);
  const [trigger,setTrigger]=useState(false);
  const [com_trigger,setComTrigger]=useState(false);

  // console.log(cpUnitId);
  console.log(cpProUnitId);
  // console.log(cpProUnit);
  // console.log(cpProFilterUnit);
  // console.log(selectedUnitToAdd);
  console.log(selectedUnitToDelete);

  const [loading, setloading] = useState(false);
  const [loadingUnit,setLoadingUnit] = useState(false);
  const [newRowData, setNewRowData] = useState([]);

  let builderSelectedData = JSON.parse(localStorage.getItem("BuilderSelected"));
  let id = builderSelectedData.selectedProjectID;
  
  //fetch total units of project
  const fetchTotalUnit = async () => {
    try {
        const res = await axios.get(
          Apiaddress + `/api/projects/getunitsusingprojectid/?project_id=${id}`
        );
      // console.log("===========================.>",res?.data);
      if(res.data.unit=="Unit not found"){setNewRowData([])}
      else{
        setNewRowData(res?.data?.unit);
      }
    } catch (err) {
      console.log("====================",err);
      setNewRowData([]);
    }
  };
  
  //fetch total Projects Units associated with CP
  const getProjectUnitcp = async () => {
      setloading(true);
     try{
        let tempUnit=[];
        let tempUnitId=[]; 
        for(let i=0;i<cpUnitId?.length;i++){
        const res1 = await axios.get(
        Apiaddress + `/api/projects/unit/?unit_id=${cpUnitId[i]}`
        );
        // console.log("===========================.>",res1?.data?.unit[0]);
        let unitInfo=res1?.data?.unit[0];

        if(unitInfo?.project==id){
          tempUnit.push(unitInfo);
          tempUnitId.push(unitInfo?.id);
        }
       }
       setCpUnit(tempUnit);
       setCpProUnitId(tempUnitId);

     }catch (err) {
      console.log(err);
    }finally{
      setloading(false)
    }
   }
  
  useEffect(() => {
    getProjectUnitcp();
  },[trigger]); 
  
  //fetch total Units associated with CP
  const getUnitcp = async () => { 

    // console.log("cpid",cpid)
    try {
      const res = await axios.get(
        Apiaddress + `/api/role/channelpartner/?cp_id=${cpid}`
      );
      // console.log("channel partnnet unit assigned====>",res?.data?.cp[0]?.units);

      if(res?.data?.cp[0]?.units.length==0){
        setCpUnitId([]);
      }
      else{
       setCpUnitId(res?.data?.cp[0]?.units);
       setTrigger(!trigger);
      } 
    } catch (err) {
      console.log(err);
    }
  };

useEffect(() => {

  fetchTotalUnit();
  getUnitcp();

  }, [com_trigger]);

  const [open, setOpen] = React.useState(false);
    const handleOpen = () => {
      setOpen(true);
  };
  const handleClose = () => {
      setOpen(false);
  };

  const filterUnits=()=>{
    setLoadingUnit(true);
    let temp=newRowData;
    for(let i=0;i<cpProUnit.length;i++){
      let filterdata=temp.filter((ele)=>{
        return cpProUnit[i].id!=ele.id;
      })
      temp=filterdata;
    }
    setCpProFilterUnit(temp);
    setLoadingUnit(false);
  }

  const AllocateUnits=async ()=>{
      
      let temp=cpProUnitId;
      temp=[...temp,...selectedUnitToAdd];
      console.log(temp);
      // return;
      try {
        const url= Apiaddress + `/api/role/channelpartner/`;
        let obj={
            cp_id:cpid,
            units:temp,
        }
        const res = await axios.patch(
          url,
          obj
        );
      console.log("===========================.>",res?.data);  
      setComTrigger(!com_trigger);
      setSelectedUnitToAdd([]);
      handleClose();
    } catch (err) {
      console.log("====================",err);
    }
  }

  const DeallocateUnits=async ()=>{

      let temp=cpProUnitId;
      for(let i=0;i<selectedUnitToDelete?.length;i++){
      let filterdata=temp.filter((ele)=>{
        return selectedUnitToDelete[i]!=ele;
      })
      temp=filterdata;
      }
      console.log(temp);
      try {
        const url= Apiaddress + `/api/role/channelpartner/`;
        let obj={
            cp_id:cpid,
            units:temp,
        }
        const res = await axios.patch(
          url,
          obj
        );
      console.log("===========================.>",res?.data);
      setComTrigger(!com_trigger);
      setSelectedUnitToDelete([]);
      handleClose();
    } catch (err) {
      console.log("====================",err);
    }
  }

  return (
    <>
    <Box sx={{ color: "#A2A3A6",height:"400px",overflowY:"scroll",paddingTop:"20px"}}>
      {loading?<Box sx={{textAlign:"center",marginTop:"100px"}}><CircularProgress color="inherit" sx={{ color: 'grey.500' }}/></Box>:cpProUnit?.length==0?
           <Box sx={{textAlign:"center",marginTop:"150px"}}>
            <Typography sx={{fontSize:"25px",fontWeight:"500",color:"#B9B7D0",}}>
              No Units Found
            </Typography>
            <Stack direction="row" justifyContent="center" sx={{marginTop:"80px"}}>
              <Box title={selectedUnitToDelete?.length?"Please unselect Unit":null}>
              <Button 
                onClick={()=>{
                  handleOpen();
                  filterUnits();
                }}
                disabled={selectedUnitToDelete?.length}
                variant="contained"
                color="primary" sx={{ height:"40px", background: "linear-gradient(90deg, #0F5DAE 0%, #009DCF 100.33%)",boxShadow: "0px 13.7981px 22.5788px rgba(50, 174, 137, 0.25)", textTransform: "none", borderRadius: "4px", width:"120px", fontSize:"15px",margin:"20px 30px 10px 0px"}}>Allot More
              </Button>
              </Box>
              <Box title={!selectedUnitToDelete?.length?"Please Select any Unit":null}>
              <Button 
                onClick={()=>{
                  DeallocateUnits();
                }}
                disabled={!selectedUnitToDelete?.length}
                variant="contained"
                color="primary" sx={{ height:"40px", background: "linear-gradient(90deg, #0F5DAE 0%, #009DCF 100.33%)",boxShadow: "0px 13.7981px 22.5788px rgba(50, 174, 137, 0.25)", textTransform: "none", borderRadius: "4px", width:"100px", fontSize:"15px",margin:"20px 0px 10px 0px"}}>
                Deallocate
              </Button>
              </Box>
            </Stack>
          </Box>:
      <Box sx={{display: "grid",gridTemplateColumns: "1fr 1fr 1fr 1fr",rowGap: "40px",placeItems: "center",columnGap: "20px"}}>
        {cpProUnit &&
          cpProUnit?.map((item) => {
            return (
              <Box sx={{ display: "flex" }}>
               <Box sx={{display: "grid",justifyContent: "left",alignItems: "center",marginRight:"10px",marginTop:"2px"}}>
                  <input type="checkbox" 
                    onChange={()=>{
                      let temp=selectedUnitToDelete.filter((ele)=>{
                        return ele==item?.id;
                      })
                      console.log(temp);
                      if(temp.length==0){
                        setSelectedUnitToDelete([...selectedUnitToDelete,item?.id]);
                      }
                      else{
                        let temp=selectedUnitToDelete.filter((ele)=>{
                        return ele!==item?.id;
                      })
                        setSelectedUnitToDelete(temp);
                      }
                    }} 
                  >
                  </input>         
                </Box>
                <Box sx={{display: "grid",justifyContent: "left",alignItems: "center",width: "100px",fontFamily:'Poppins',fontStyle:"normal",fontWeight:"500",fontSize:"18px",lineHeight:"27px"}}>{item.name}
                </Box>
              </Box>
            );
          })}
      </Box>}
    </Box>
    {cpProUnit && cpProUnit?.length!==0 && 
    <Stack direction="row" justifyContent="center" sx={{margin:"10px 0px"}}>
      <Box title={selectedUnitToDelete?.length?"Please unselect Unit":null}>
      <Button 
        onClick={()=>{
          handleOpen();
          filterUnits();
        }}
        disabled={selectedUnitToDelete?.length}
        variant="contained"
        color="primary" sx={{ height:"40px", background: "linear-gradient(90deg, #0F5DAE 0%, #009DCF 100.33%)",boxShadow: "0px 13.7981px 22.5788px rgba(50, 174, 137, 0.25)", textTransform: "none", borderRadius: "4px", width:"120px", fontSize:"15px",margin:"20px 30px 10px 0px"}}>Allot More
      </Button>
      </Box>
      <Box title={!selectedUnitToDelete?.length?"Please Select any Unit":null}>
      <Button 
        onClick={()=>{
          DeallocateUnits();
        }}
        disabled={!selectedUnitToDelete?.length}
        variant="contained"
        color="primary" sx={{ height:"40px", background: "linear-gradient(90deg, #0F5DAE 0%, #009DCF 100.33%)",boxShadow: "0px 13.7981px 22.5788px rgba(50, 174, 137, 0.25)", textTransform: "none", borderRadius: "4px", width:"100px", fontSize:"15px",margin:"20px 0px 10px 0px"}}>
        Deallocate
        </Button>
      </Box>
    </Stack>}

    {/* Modal to allocate units */}

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description" 
      >
        <Box sx={{position: 'absolute',
             top: '50%',
             left: '50%',
             transform: 'translate(-50%, -50%)',
             width: "55%",
             height:"80%",
             bgcolor: 'white',
             border: '2px solid white',
             borderRadius:"10px",
             boxShadow: 24,
             pt: 3,
             px: 3,
             pb: 1.5,
             textAlign:"center"
            }}>
            <Box sx={{textAlign:"right"}}>
                <CloseIcon sx={{color:"gray",cursor:"pointer"}} onClick={(e)=>{
                   handleClose();
                   setSelectedUnitToAdd([]);
                  //  setTrigger(!trigger);
                }}/>
            </Box>
              <Typography sx={{
              fontSize: "18px",
              fontWeight: "600",
              marginBottom: "30px",
              marginTop:"-20px",
              color: "black",
            }}>Allot More Units</Typography>
    
            <Box sx={{ color: "#A2A3A6",height:"60%",overflowY:"scroll",paddingTop:"20px"}}>
              {loadingUnit?<Box sx={{textAlign:"center",marginTop:"100px"}}><CircularProgress color="inherit" sx={{ color: 'grey.500' }}/></Box>:cpProFilterUnit?.length==0?
                <Box sx={{textAlign:"center",marginTop:"100px"}}><Typography sx={{fontSize:"25px",fontWeight:"500",color:"#B9B7D0",}}>No Units Found</Typography></Box>:
                  <Box sx={{display: "grid",gridTemplateColumns: "1fr 1fr 1fr 1fr",rowGap: "40px",placeItems: "center",columnGap: "20px"}}>
                    {cpProFilterUnit &&
                      cpProFilterUnit?.map((item,idx) => {
            return (
              <Box sx={{ display: "flex" }}>
                <Box sx={{display: "grid",justifyContent: "left",alignItems: "center",marginRight:"10px",marginTop:"2px"}}>
                  <input type="checkbox" 
                    onChange={()=>{
                      let temp=selectedUnitToAdd.filter((ele)=>{
                        return ele==item?.id;
                      })
                      console.log(temp);
                      if(temp.length==0){
                        setSelectedUnitToAdd([...selectedUnitToAdd,item?.id]);
                      }
                      else{
                        let temp=selectedUnitToAdd.filter((ele)=>{
                        return ele!==item?.id;
                      })
                        setSelectedUnitToAdd(temp);
                      }
                    }} 
                  >
                  </input>         
                </Box>
                <Box sx={{display: "grid",justifyContent: "left",alignItems: "center",width: "100px",fontFamily:'Poppins',fontStyle:"normal",fontWeight:"500",fontSize:"18px",lineHeight:"27px"}}>{item.name}
                </Box>
              </Box>
            );
          })}
      </Box>}
    </Box>
      <Stack direction="row" justifyContent="center">
        <Button 
        onClick={()=>{
          AllocateUnits();
        }}
          variant="contained"
          color="primary" sx={{ height:"40px", background: "linear-gradient(90deg, #0F5DAE 0%, #009DCF 100.33%)",boxShadow: "0px 13.7981px 22.5788px rgba(50, 174, 137, 0.25)", textTransform: "none", borderRadius: "4px", width:"120px", fontSize:"15px",margin:"30px 0px 0px 0px"}}>Save
        </Button>
      </Stack>
    </Box>
    </Modal>
    </>  
  );
}

export default AssociateProjectUnits;
