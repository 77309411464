
import { Box, Button, Input, Typography } from "@mui/material"
import { Stack } from "@mui/system"
import DoneIcon from '@mui/icons-material/Done';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import { useNavigate } from "react-router-dom";
import SearchIcon from '@mui/icons-material/Search';

import InputAdornment from '@mui/material/InputAdornment';
import OutlinedInput from '@mui/material/OutlinedInput';
import Checkbox from '@mui/material/Checkbox';
import { useContext, useEffect, useState } from "react";
import { Apiaddress } from "../../../../utils/consts";
import axios from "axios";

import currentProjectState from "../../../../context/createproject/context";

import { SAProjectSidebar } from "../../SidebarNavbar/SAProjectSidebar"
import { SuperAdminNavbar } from "../../SidebarNavbar/SuperAdminNavbar"


export const AttachMediaTo_UnitSA = ()=>{
    const navigate = useNavigate();
    ////get project id from local/context 
 const cpContext = useContext(currentProjectState);
 const projectID = cpContext?.createProjectState?.project?.id;
   
   const tempunits = ["103","104","104","104","104","104","104","104","104","104","104","104","104","104","104","104","104"];
   const [units, setUnits]  = useState([...tempunits]);
   const [selectedCategory, setSelectCategory] = useState("");
   const selectcat = (val)=>{
       selectedCategory==val ? setSelectCategory(""):setSelectCategory(val)
   }

   const searchunit = (val)=>{
       console.log(val)
       if(val==""){
           setUnits(tempunits)
           return;
       };
       
           let value = val.toLowerCase()
           let result = tempunits.filter((data) => {
               return data.includes(value) === true ;  ////data.search(value)!==-1
               });
           setUnits([...result])
           console.log(result)
      
       
   }

   ////get unit template form api 
   useEffect(()=>{
     let url = Apiaddress+""+projectID
     const uniturl = Apiaddress+"/api/projects/unittemplatesbyproject/?project_id="+projectID

     axios.get(uniturl)
     .then((res)=>{
       const data = res?.data
        console.log("unit temp",res.data);
        if(data.unit_template!=="Unit template not found")
        setUnits(data.unit_template)
          
        })
        .catch((err)=>{
          console.log(err)
        })
   },[])
// console.log(units)

    return(
        <>
        <Stack direction="row" sx={{width:"100%",height:"auto" }}>
      {/* 1st stack */}
      <Stack sx={{minHeight:"100vh",width:"22%",boxSizing:"border-box",}}> {/*for project directory 20% and for sales dashboard 22%*/}
        <SAProjectSidebar active={"Project Directory"}/>
      </Stack>

     {/* 2nd stack */}
     <Stack direction="column" sx={{ backgroundColor:"#f9f9f9",width:"78%", padding:"25px",boxSizing:"border-box",}}  >  {/*  width for main sidebar 78px and for 80px*/}
      <SuperAdminNavbar project={true} />  {/*send props for get popup to verify otp from builder */}
      
      <Typography sx={{marginTop:"20px", fontSize:"28px", fontWeight:"600", marginLeft:"10px", marginBottom:"50px"}}>
       Attach media to Units
            </Typography>
     {/* actual content  */}
    <Stack sx={{backgroundColor:"#f9f9f9"}}>  {/* mt:"28px", */}

    <Stack direction="column"  sx={{  padding:"20px 0px 20px 0px", backgroundColor:"#fff", marginRight:"0px"}}>
           {/* attach media to unit */}
           <Stack direction="row" justifyContent="space-between" >
             <Stack sx={{width:"50%",paddingLeft:"20px"}} >
              <Typography sx={{fontSize:"28px", fontWeight:"600"}}>Media View</Typography>
                 <Stack direction="row" justifyContent="center" alignItems="center">
                  <Typography sx={{fontSize:"20px", fontWeight:"600", mt:"30px",mb:"20px"}}>Media Library</Typography>
                  </Stack>
                  <Stack>
                      <Typography sx={{fontSize:"18px", fontWeight:"500", color:"#A2A3A6"}}>Select Category</Typography>
                      
                      <Box sx={{margin:"auto", width:{sm:"80%", lg:"55%"},  marginTop:"40px",display:"flex", justifyContent:"space-between"}}>
                            <Stack onClick={()=>selectcat("broucher")} direction="row" alignItems="center" justifyContent="center" sx={{borderRadius:"20px", width:"100px", height:"100px", bgcolor:selectedCategory=="broucher"?"#FBB040":"#D9D9D9", }}>
                               <Typography sx={{fontSize:"18px", fontWeight:"600"}}>Broucher</Typography>  
                            </Stack>
                            <Stack onClick={()=>selectcat("image")} direction="row" alignItems="center" justifyContent="center" sx={{borderRadius:"20px", width:"100px", height:"100px", bgcolor:selectedCategory=="image"?"#FBB040":"#D9D9D9",}}>
                               <Typography sx={{fontSize:"18px", fontWeight:"600"}}>Images</Typography>  
                            </Stack>
                      </Box>
                      <Box sx={{margin:"auto", width:{sm:"80%", lg:"55%"},  marginTop:"40px",display:"flex", justifyContent:"space-between"}}>
                            <Stack onClick={()=>selectcat("floor")} direction="row" alignItems="center" justifyContent="center" sx={{borderRadius:"20px", width:"100px", height:"100px", bgcolor:selectedCategory=="floor"?"#FBB040":"#D9D9D9",}}>
                               <Typography sx={{fontSize:"18px", fontWeight:"600"}}>Floor Plans</Typography>  
                            </Stack>
                            {/* <Stack onClick={()=>selectcat("video")} direction="row" alignItems="center" justifyContent="center" sx={{borderRadius:"20px", width:"100px", height:"100px", bgcolor:selectedCategory=="video"?"#FBB040":"#D9D9D9", }}>
                               <Typography sx={{fontSize:"18px", fontWeight:"600"}}>Video</Typography>  
                            </Stack> */}
                      </Box>
                  </Stack>
             </Stack>
             <Stack sx={{width:"50%", borderLeft:"1px solid #D9D9D9",padding:"0px 20px 0px 20px"}}>
               <Typography sx={{fontSize:"28px", fontWeight:"600"}}>Unit View</Typography>
               <Stack direction="row" justifyContent="center" alignItems="center">
                  <Typography sx={{fontSize:"20px", fontWeight:"600", mt:"30px",mb:"20px"}}>Select Units</Typography>
                  </Stack>
                  {/* <Stack direction="row" alignItems="center"  sx={{width:"65%", height:"40px",marginLeft:"20%"}}> 
                     <OutlinedInput
                            id="outlined-adornment-weight"
                          //   value={values.weight}
                          onChange={(e)=>{
                            searchunit(e.target.value)
                         }}
                            startAdornment={<InputAdornment position="end"> <SearchIcon/></InputAdornment>}
                            aria-describedby="outlined-weight-helper-text"
                            inputProps={{
                              'aria-label': 'weight',
                            }}
                          />
                  </Stack> */}
                  <Stack>
                      <Typography sx={{fontSize:"18px", fontWeight:"500", color:"#A2A3A6"}}>Units</Typography>
                      <Box sx={{display:"flex", justifyContent:"start",alignItems:"center",flexWrap:"wrap",overflowY:"scroll", maxHeight:"400px"}}>
                        {units?.map((ele,index)=>{
                            return(
                          <Stack direction="row" alignItems="center" sx={{marginRight:"20px",  width:{sm:"20%",lg:"15%"}}}>
                          <Checkbox
                            edge="start"
                            disabled
                          //   checked={checked.indexOf(value) !== -1}
                            tabIndex={-1}
                            disableRipple
                          //   inputProps={{ 'aria-labelledby': labelId }}
                          />
                          <Typography sx={{fontSize:"18px", fontWeight:"500", color:"#A2A3A6"}}>{ele.id}</Typography>
                          </Stack>
                            )
                        })}
                      </Box> 
                  </Stack>
                             
             </Stack>
           </Stack>
             <Stack direction="row" justifyContent="end" alignItems="center" spacing={2} sx={{marginTop:"50px", padding:"20px"}}>
             {/* <Button 
               onClick={()=>{
                navigate(-1)
               }}
             size="large" className="univercel_btn_class" sx={{textTransform:"none", color:"#fff"}}  startIcon={<KeyboardDoubleArrowLeftIcon/>}>Previous</Button> */}
                
                <Button size="large" className="univercel_btn_class" sx={{textTransform:"none", color:"#fff", padding:"9px 28px 9px 28px"}}
                 onClick={()=>{
                   navigate(`/super-admin-attachmedia-to-unit/${selectedCategory}`)
                 }}
                 >Next</Button>
                
             </Stack>

           </Stack>


    </Stack>
      </Stack>
       </Stack>
        </>
    )
}