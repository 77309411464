import { Box, TextField, Typography } from "@mui/material";
import { useState } from "react";
import { BlueButton } from "../../../../../../utils/common/BlueButton";

export const NormalInput = ({
  setData,
  deleteSelf,
  setShow,
}: {
  setData: any;
  deleteSelf: any;
  setShow: any
}) => {
  const [state, setstate] = useState("");

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        padding: "10px",
        ml: 2,
        alignItems: "flex-start",
        gap: "20px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          gap: "5px",
          flexDirection: "column",
          alignItems: "flex-start",
        }}
      >
        <h3 style={{ textTransform: "capitalize" }}>
          {state == "" ? "Enter Field Name" : state}
        </h3>
        <TextField
          onChange={(e) => {
            setstate(e.target.value);
          }}
        ></TextField>
      </Box>
      <Box
        sx={{
          mt: 1.5,
          display: "flex",
          gap: "0.5rem",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <BlueButton
          onClick={() => {
            if (!state) {
              window.alert("Enter Title")
              return
            }
            setData({
              type: "normal", // normal || withtrailing || radio || check || doubletrailing
              name: state,
              value: "",
            });
            setShow();
          }}
        >
          Save Field
        </BlueButton>
        <Typography
          onClick={() => {
            deleteSelf();
          }}
          sx={{
            color: "#B0AFAF",
            fontWeight: 500,
            cursor: "pointer",
            fontFamily: "Product Sans",
          }}
        >
          Delete
        </Typography>
        {/* <BlueButton
          onClick={() => {
            deleteSelf();
          }}
        >
          Delete
        </BlueButton> */}
      </Box>
    </Box>
  );
};
