import { Accordion, AccordionDetails, AccordionSummary, Box, Button, Checkbox, Drawer, FormControl, InputLabel, MenuItem, Select, TextField, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import TuneOutlinedIcon from "@mui/icons-material/TuneOutlined";
import KeyboardArrowRightOutlinedIcon from "@mui/icons-material/KeyboardArrowRightOutlined";
import KeyboardArrowUpOutlinedIcon from "@mui/icons-material/KeyboardArrowUpOutlined";
import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined";
import { CheckBox } from "@mui/icons-material";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import DriveFileRenameOutlineIcon from "@mui/icons-material/DriveFileRenameOutline";
import VisibilityIcon from "@mui/icons-material/Visibility";
import DeleteIcon from "@mui/icons-material/Delete";
import "./LDmainPage.css";
import React, { useContext, useEffect, useState } from "react";
import RightSidebar from "./RightSidebar";
import { DataGrid, GridExpandMoreIcon } from "@mui/x-data-grid";
import CategoryIcon from "@mui/icons-material/Category";
import HandshakeIcon from "@mui/icons-material/Handshake";
import profileContext from "../../../context/profile/context";
import { createProject } from "../../../connection/project/create_project";
import axios from "axios";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import LDcustomTable from "./LDcustomTable";
import { Apiaddress } from '../../../utils/consts';
import { ProjectDashboardSidebar } from "../../BuilderComponents/ProjectDashboard/DashboardComponents/ProjectDashboardSidebar";
import { Navbar } from "../../BuilderComponents/ProjectDashboard/DashboardComponents/Navbar";
import { NavbarForDirectory } from "../../BuilderComponents/ProjectDashboard/DashboardComponents/NavbarForDirectory";
import builderDashboard from "../../../context/builder_dashboard/context";
import { UserRole } from "../../../UserRoles/UserRoles";
import CircularProgress from '@mui/material/CircularProgress';


export const Lead_Directory_w_sb = ()=>{

  const [loading, setloading] = useState(false);
  ////get user roles from local storage and assign 
  let roles = JSON.parse(localStorage.getItem("user_roles"))
  let lead = roles["lead"];
  let leadPriority = roles["lead prority"];    

  const columns = [
    { field: "id", headerName: "id", width: 130 },
    { field: "Name", headerName: "Name", width: 130 },
    { field: "Phone", headerName: "Phone", width: 130 },
    { field: "Email", headerName: "Email", width: 130 },
    {
      field: "Projects",
      headerName: "Projects",
      // type: "number",
      width: 130,
    },
    {
      field: "Priority",
      headerName: "Priority",
      width: 130,
    },
    {
      field: "DetailedView",
      headerName: "Detailed View",
      width: 130,
      renderCell: (params) => {
        return (
          <Stack direction="row" sx={{ marginLeft: "10px" }}>
            {/* <RemoveRedEyeIcon fontSize="medium" />
            <DeleteIcon fontSize="medium" sx={{ marginLeft: "10px" }} /> */}
            <Box>view</Box>
          </Stack>
        );
      },
    },
  ];
  const [state, setState] = React.useState(false);

  const toggleDrawer = (open, item) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    updateLead(item);
    setState(open);
  };
  
  const [trigger, setTrigger] = useState(false);
  const [selectedRows, setSelectedRows] = React.useState([]);
  useEffect(() => {
    console.log(selectedRows);
  }, [selectedRows]);

  const [curselectedRows, setcurselectedRows] = useState([]);
  const [rowSelected, setRowSelected] = useState(false);

  const onRowsSelectionHandler = (item, ind, mod) => {
    if (mod == "all") {
      if (curselectedRows.length != 0) {
        if (curselectedRows.length == item.length) {
          setcurselectedRows([]);
          setRowSelected(false);
          return;
        }
      } else {
        setcurselectedRows(item);
        setRowSelected(true);
      }
    } else {
      let flag = 0;
      let arr = [];

      console.log("ccc",item.lead_id);
      for (let i = 0; i < curselectedRows.length; i++) {
        if (curselectedRows[i].lead_id == item.lead_id) {
          // console.log("called");
          flag = 1;
        } else {
          arr.push(curselectedRows[i]);
          // setcurselectedRows([...curselectedRows, curselectedRows[i]]);
        }
      }
      if (flag===0){
        setcurselectedRows([...curselectedRows, item]);
      }
      else{
        setcurselectedRows([...arr]);
      } 
      setRowSelected(true);
    }
  };

  console.log(curselectedRows);
  const [tabledata, setTabledata] = useState(null);
  const [rowData, setrowData] = useState([]);
  const [idCont, setidCunt] = useState(1);
  const [orgID, setOrgID] = useState(null);
  const [leadsFromApi, setleadsFromApi] = useState([]);
  //context

  const user = useContext(profileContext);

  const [formState, setFormState] = useState({});

   /// get selected project id from local storage
   let builderSelectedData = JSON.parse(localStorage.getItem("BuilderSelected"));
   let id = builderSelectedData.selectedProjectID;
 

  useEffect(() => {
    console.log(user);
    // console.log(user.UserState);
    if (user)
      if (user.UserState)
        if (user.UserState.user_obj)
          if (user.UserState.user_obj.orgList)
            if (user.UserState.user_obj.orgList[0]) {
              console.log(user.UserState.user_obj.orgList[0]);
              setOrgID(user.UserState.user_obj.orgList[0]);
            }
  }, [user]);
  //context

  const fetchLeads = async () => {

    setloading(true)
    try {
      const res = await axios.get(
        // Apiaddress + `/api/leads/leadsoforg/?org_id=${orgID}`
        Apiaddress + "/api/leads/leadsofproject/?project_id=" + id
      );
      console.log(res.data.leads);
      if(res.data?.leads=="lead not found"){
       setleadsFromApi([]);
      }
      
      else{
        setleadsFromApi(res.data.leads);
        console.log("wwww",res.data.leads);
      }
    } catch (err) {
      console.log(err);
    }finally{
    setloading(false)
    }
  };

  const [allpriority, setallpriority] = useState(null);

  const getpriority = async () => {
    try {
      const res = await axios.get(
        Apiaddress + "/api/leads/leadprority/"
      );
      console.log(res.data.leadprority);
      setallpriority(res.data.leadprority);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getpriority();
  }, []);
  const [prioritymap, setprioritymap] = useState(null);

  const setPri = () => {
    const mp = new Map();
    for (let i = 0; i < allpriority.length; i++) {
      mp.set(allpriority[i].id, allpriority[i].name);
    }
    setprioritymap(mp);
  };

  useEffect(() => {
    if (allpriority) {
      setPri();
    }
  }, [allpriority]);

  useEffect(() => {
    if (leadsFromApi) {
      let arr = [];
      for (let i = 0; i < leadsFromApi.length; i++) {
        // console.log(leadsFromApi[i]);
        let obj = {
          id: i + 1,
          lead_id: leadsFromApi[i].id,
          Name: leadsFromApi[i].name,
          Phone: leadsFromApi[i].phone,
          Email: leadsFromApi[i].email,
          Projects: leadsFromApi[i].project,
          // Priority: prioritymap
          //   ? prioritymap.get(leadsFromApi[i].lead_prority)
          //   : "",
          Priority:leadsFromApi[i].lead_prority.name,
          DetailedView: "View",
          Address: leadsFromApi[i].address_line,
        };
        setidCunt(idCont + 1);
        arr.push(obj);
      }
      setrowData(arr);
    }
  }, [leadsFromApi,trigger]);

  useEffect(() => {
    if (orgID) fetchLeads();
    if (orgID) fetchLeads();
    if (orgID) fetchLeads();
    if (orgID) fetchLeads();
    if (orgID) fetchLeads();
  }, [orgID, state, trigger]);

  useEffect(() => {
    const rows = [
      {
        id: 1,
        Name: "Snow",
        Phone: "912912912",
        Email: "dummmy@gamil.com",
        Projects: 2,
        Priority: "High",
        DetailedView: "View",
      },
    ];
    if (tabledata) {
      let obj = {
        id: idCont,
        Name: tabledata.name,
        Phone: tabledata.phone,
        Email: tabledata.Email,
        Projects: tabledata.setProjects.length,
        Priority: tabledata.priority,
        DetailedView: "View",
      };
      setidCunt(idCont + 1);
      // console.log(obj);
      setrowData([...rowData, obj]);
    }

    console.log(tabledata);
  }, [tabledata]);

  useEffect(() => {
    console.log(rowData);
  }, [rowData]);

  const [updateData, setupdateData] = useState(null);
  const updateLead = (item) => {
    // console.log(item);
    setupdateData(item);
  };

  const delIconClick = async (item) => {
    console.log(item);
    try {
      let arr=[];
      arr.push(item.lead_id)
      const obj = {lead_id:arr};
      const res = await axios.delete(Apiaddress + "/api/leads/lead/", {
        data: obj,
      });
      setTrigger(!trigger);
      console.log(res);
    } catch (err) {
      console.log(err);
    }
  };

  //fitering data starts:====
  const [filterselect, setfilterselect] = useState();
  const [filtervalue, setfiltervalue] = useState();

  const handleChange = (event) => {
    setfilterselect(event.target.value);
    setfiltervalue("");
  };

  console.log(filterselect);
  console.log(filtervalue);

   const fetchfilter = async () => {
    try {
      const res = await axios.get(
        // Apiaddress + `/api/leads/leadsoforg/?org_id=${orgID}&filterkey=${filterselect}&filtervalue=${filtervalue}`
        Apiaddress + `/api/leads/leadsofproject/?project_id=${id}&filterkey=${filterselect}&filtervalue=${filtervalue}`
    
        );
      if(res.data?.leads?.length==0){
       setleadsFromApi([]);
      }
      else{
         
        setleadsFromApi(res.data.leads);
      }
      console.log(res.data.leads);
    } catch (err) {
      console.log(err);
    }
  };

//////select btn to make priority and mark project ;
const [makePriority,setMakePriority] = useState(false);
const [makeProject,setMakeProject] = useState(false);


    return(
        <>

<Stack direction="row" sx={{width:"100%",height:"auto" }}>
      {/* 1st stack */}
      <Stack sx={{minHeight:"100vh",width:"22%",boxSizing:"border-box",}}>
      <ProjectDashboardSidebar active={"Leads Directory"}/>
      </Stack>

     {/* 2nd stack */}
     <Stack direction="column" sx={{ backgroundColor:"#f9f9f9",width:"78%", padding:"25px",boxSizing:"border-box",}}  >
      {/* <Navbar/> */}
      <NavbarForDirectory
          showAddProjectbtn={false}
          showRightSideBar={UserRole(lead, "add_lead")?true:false}
          toggleDrawer={toggleDrawer}
          RightBarName={"Add Lead "}
          leftRightTitle2={"Lead Directory"}
          leftTitle={"Project Dashboard"}
          showLeftTitle={false}
          righshow={true}
        />



    {/* <Typography sx={{mt:"20px", fontSize:"22px", fontWeight:"500",color:"#2979F2"}}>
    Project Name
    </Typography> */}
     {/* actual content  */}

     <Stack sx={{ mt:"28px",backgroundColor:"#f9f9f9"}}>


     <React.Fragment>
          <Drawer anchor="right" open={state} onClose={()=>{
            // toggleDrawer(false)
             setState(false)
              setMakePriority(false);
              setMakeProject(false);
          }}>
            <RightSidebar
              setTabledata={setTabledata}
              toggleDrawer={toggleDrawer}
              curselectedRows={
                curselectedRows.length == 0 ? null : curselectedRows
              }
              orgID={orgID}
              updateData={updateData}
              setTrigger={setTrigger}
              trigger={trigger}
              makePriority={makePriority}
              makeProject={makeProject}
              setMakePriority={setMakePriority}
              setMakeProject={setMakeProject}
            />
          </Drawer>
        </React.Fragment>


        <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            sx={{ height: "68px", marginBottom: "20px" }}
          >
            <Stack direction="column" alignItems="center">
              <Typography
                sx={{
                  fontSize: "22px",
                  fontWeight: "600",
                  marginLeft: "-15px",
                  color: "black",
                }}
              >
                Leads Directory
              </Typography>
              <Typography
                sx={{
                  color: "#B9B7D0",
                  fontSize: "12px",
                  fontWeight: "400",
                  paddingLeft: "20px",
                }}
              >
                Here is your Leads Directory list data
              </Typography>
            </Stack>
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Stack
                direction="row"
                justifyContent="space-around"
                alignItems="center"
                sx={{
                  width: "203px",
                  height: "68px",
                  borderRadius: "12px",
                  color: "#3E4954",
                  backgroundColor: "#f4f5f9",
                }}
              >


                <Accordion sx={{ width: "250px", boxShadow: "none" }}>
                  <AccordionSummary
                    expandIcon={<GridExpandMoreIcon/>}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography sx={{ display: "flex", placeItems: "center" }}>
                      <TuneOutlinedIcon sx={{ color: "#2F4CDD" }} />
                      <Typography sx={{ marginLeft: "25px" }}>
                        Fillters
                      </Typography>
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Box sx={{textAlign:"center"}}>
                      <FormControl sx={{ width: "200px" }}>
                        <InputLabel id="demo-simple-select-label">
                          Select
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={filterselect}
                          label="Select"
                          onChange={handleChange}
                        >
                          <MenuItem value={"name"}>Name</MenuItem>
                          <MenuItem value={"email"}>Email</MenuItem>
                          <MenuItem value={"phone"}>Phone</MenuItem>
                          <MenuItem value={"status"}>Status</MenuItem>
                          <MenuItem value={"city"}>City</MenuItem>
                          <MenuItem value={"state"}>State</MenuItem>
                          {/* <MenuItem value={"Name"}>Name</MenuItem> */}
                        </Select>
                      </FormControl>
                      <TextField
                        sx={{ marginTop: "20px" }}
                        placeholder="Enter the value" 
                        value={filtervalue} onChange={(e)=>{setfiltervalue(e.target.value)}}
                      />
                      <Button 
                        onClick={fetchfilter}
                        variant="contained"
                        color="primary" sx={{ height:"40px", background: "linear-gradient(90deg, #0F5DAE 0%, #009DCF 100.33%)",boxShadow: "0px 13.7981px 22.5788px rgba(50, 174, 137, 0.25)", textTransform: "none", borderRadius: "4px", width:"100px", fontSize:"15px",margin:"20px 0px 10px 0px"}}>
                         Submit
                      </Button>

                    </Box>
                  </AccordionDetails>
                </Accordion>


              </Stack>
            </Stack>
          </Stack>

             {/* ================filters and all=============================== */}
             <Box>
            {curselectedRows.length!=0 && (
              <Stack direction="row" sx={{ margin: "20px" }}>
                {UserRole(leadPriority, "add_leadprority") && 
                <Button
                  onClick={()=>{
                    // toggleDrawer(true);
                    setState(true)
                      setMakePriority(true);
                  }}
                  startIcon={<CategoryIcon />}
                  className="univercel_btn_class"
                  sx={{
                    color: "#FFFFFF",
                    textTransform: "none",
                    width: "150px",
                    background:
                      " linear-gradient(150deg, #79F1A4 13.4%, #0E5CAD 86.6%)",
                  }}
                >
                  Mark Priority
                </Button>
                }
                {/* <Button
                  startIcon={<HandshakeIcon />}
                  onClick={()=>{
                    // toggleDrawer(true);
                    setState(true)
                     setMakeProject(true)
                  }
                }
                  className="univercel_btn_class"
                  sx={{
                    color: "#FFFFFF",
                    textTransform: "none",
                    background:
                      " linear-gradient(150deg, #79F1A4 13.4%, #0E5CAD 86.6%)",
                    width: "150px",
                    marginLeft: "20px",
                  }}
                >
                  Mark Project
                </Button> */}
              </Stack>
            )}
            <Stack></Stack>
          </Box>
          <LDcustomTable
            onRowsSelectionHandler={onRowsSelectionHandler}
            rowSelected={rowSelected}
            setRowSelected={setRowSelected}
            toggleDrawer={toggleDrawer}
            newRowData={rowData}
            updateLead={updateLead}
            delIconClick={delIconClick}
            loading={loading}
            setloading={setloading}
            CircularProgress={CircularProgress}
          />


</Stack>



      </Stack>
</Stack>
        
        </>
    )
}