import { Button } from '@mui/material'
import { Box } from '@mui/system'
import React from 'react'
import DescriptionIcon from '@mui/icons-material/Description';
const DocumentItem = ({
  title,
  description,
  link
}: {
  title: string,
  description: string
  link:string
}) => {
  return (
    <Box
      sx={{
        width: "70%",
        height: "100%",
        backgroundColor: "#FFFFFF",
        boxShadow: "0px 0px 1px rgba(12, 26, 75, 0.24), 0px 3px 8px -1px rgba(50, 50, 71, 0.05)",
        borderRadius: "16px",
        display: "flex",
        flexDirection: "row",
        margin: "10px 0px 10px 7px",
        fontSize: "0.8vw"

      }}
    >
      <DescriptionIcon />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          width:"100%",
          paddingLeft:"20px"
        }}>

        <div>{title}</div>
        <div>Category</div>
        <div>{description}</div>
        <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-end", margin: "10px" }}>
          <Button
           onClick={
            ()=>{
              window.open(link);
            }
           }
            className="univercel_btn_class"
            sx={{ color: "#FFFFFF", fontSize: "0.8vw", padding: "6px 12px 6px 12px", height: "auto" }}

          >
            View
          </Button>
        </div>

      </Box>
    </Box>
  )
}

export default DocumentItem