import {
  Box,
  Checkbox,
  FormControlLabel,
  TextField,
  Typography,
} from "@mui/material";
import React from "react";
import { BlueButton } from "../../../../../../utils/common/BlueButton";
import { removeAtIndex } from "../../../../../../utils/consts";

const CheckInput = (props: any) => {
  const [state, setstate] = React.useState({
    type: "check", // normal || withtrailing || radio || check || doubletrailing
    orientation: "vertical",
    name: "Enter Field Name",
    options: [
      {
        selected: false,
        name: "Any",
      },
      {
        selected: false,
        name: "North",
      },
      {
        selected: false,
        name: "South",
      },
      {
        selected: false,
        name: "East",
      },
      {
        selected: false,
        name: "West",
      },
    ],
  });
  return (
    <Box
      sx={{
        padding: "10px",
        ml: 3,
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          padding: "10px",
          justifyContent: "center",
          alignItems: "flex-start",
          gap: "20px",
        }}
      >
        <TextField
          sx={{
            ml: "19px",
          }}
          value={state.name}
          onChange={(e) => {
            setstate({ ...state, name: e.target.value });
          }}
        ></TextField>
        {
          <div
            style={{ display: "flex", flexDirection: "column", gap: "20px" }}
          >
            {state.options.map(
              (
                e: {
                  selected: boolean;
                  name: string;
                },
                index: number
              ) => {
                return (
                  <FormControlLabel
                    control={
                      <FormControlLabel
                        control={<Checkbox value={e.selected} />}
                        label={
                          <TextField
                            value={e.name}
                            onChange={(e) => {
                              const nn = e.target.value;
                              let newnames = [...state.options];
                              newnames[index].name = nn;
                              setstate({
                                ...state,
                                options: newnames,
                              });
                            }}
                          />
                        }
                      />
                    }
                    label={
                      <Typography
                        onClick={() => {
                          setstate({
                            ...state,
                            options: removeAtIndex({
                              arr: state.options,
                              index: index,
                            }),
                          });
                        }}
                        sx={{
                          color: "#B0AFAF",
                          fontWeight: 500,
                          cursor: "pointer",
                          fontFamily: "Product Sans",
                        }}
                      >
                        Delete
                      </Typography>
                    }
                  />
                );
              }
            )}
            <div
              style={{
                marginTop: "10px",
              }}
            >
              <BlueButton
                onClick={() => {
                  let newnames = [
                    ...state.options,
                    {
                      selected: false,
                      name: "Temp",
                    },
                  ];

                  setstate({
                    ...state,
                    options: newnames,
                  });
                }}
              >
                Add
              </BlueButton>
              <BlueButton
                onClick={() => {
                  props.setData(state);
                  props.setShow();
                }}
              >
                Save Field
              </BlueButton>
            </div>
          </div>
        }
      </div>
    </Box>
  );
};

export default CheckInput;
