import { Box, Button, Container, Stack, Typography } from "@mui/material";

import GoneAreTheDaysImg from "../../Imgs/gone-are-the-days-img.png";
import WhiteArrowIcon from "../../Imgs/white-arrow-icon.svg";

const BuyerLandingGoneAreTheDaysOfOblivion = () => {
  return (
    <Box
      sx={{
        backgroundColor: "#0085FF",
        py: { xs: "30px", md: 0 },
        overflow: "hidden",
      }}
    >
      <Container>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: { xs: "30px", md: "50px" },
            flexDirection: { xs: "column-reverse", md: "row" },
          }}
        >
          <Box
            data-aos="fade-right"
            sx={{ width: { xs: "100%", md: "55%" }, py: { md: "5%" } }}
          >
            <Box>
              <Typography
                sx={{
                  fontSize: { xs: "32px", md: "40px" },
                  fontWeight: 700,
                  fontFamily: "Circular Std",
                  color: "#FFF",
                  textAlign: { xs: "center", md: "left" },
                  display: { xs: "none", md: "block" },
                  lineHeight: "1.3",
                }}
              >
                Gone are the days of oblivion
              </Typography>
              <Typography
                sx={{
                  fontSize: { xs: "32px", md: "40px" },
                  fontWeight: 700,
                  fontFamily: "Circular Std",
                  color: "#FFF",
                  textAlign: { xs: "center", md: "left" },
                  display: { xs: "block", md: "none" },
                  lineHeight: "1.3",
                }}
              >
                Gone are the <br /> days of oblivion
              </Typography>
            </Box>
            <Stack spacing={1.5} sx={{ mt: { xs: "5px", md: "10px" } }}>
              <Typography
                sx={{
                  fontSize: "24px",
                  fontFamily: "Product Sans",
                  color: "#FFF",
                  fontWeight: 700,
                  textAlign: { xs: "center", md: "left" },
                  display: { xs: "none", md: "block" },
                  lineHeight: "1.3",
                  marginBottom: "10px",
                }}
              >
                Now you have full control over your home.
              </Typography>
              <Typography
                sx={{
                  fontSize: "24px",
                  fontFamily: "Product Sans",
                  color: "#FFF",
                  fontWeight: 700,
                  textAlign: { xs: "center", md: "left" },
                  display: { xs: "block", md: "none" },
                  lineHeight: "1.3",
                  marginBottom: "10px",
                }}
              >
                Now you have full control <br /> over your home.
              </Typography>
              <Box sx={{ display: "flex", gap: "10px" }}>
                <Box
                  sx={{ lineHeight: "10px", fontSize: "30px", color: "#fff" }}
                >
                  {/* <img src={CircleCheckIcon} alt="" /> */}
                  &#x2022;
                </Box>
                <Typography
                  sx={{
                    fontSize: "16px",
                    fontFamily: "Product Sans",
                    color: "#FFF",
                    mt: "-3px",
                  }}
                >
                  EnvisionNext is changing the norm for home buyers by
                  transforming the Real Estate ecosystem.
                </Typography>
              </Box>
              <Box sx={{ display: "flex", gap: "10px" }}>
                <Box
                  sx={{ lineHeight: "10px", fontSize: "30px", color: "#fff" }}
                >
                  {/* <img src={CircleCheckIcon} alt="" /> */}
                  &#x2022;
                </Box>
                <Typography
                  sx={{
                    fontSize: "16px",
                    fontFamily: "Product Sans",
                    color: "#FFF",
                    mt: "-3px",
                  }}
                >
                  We are enhancing transparency and trust for every stakeholder
                  in the home-buying process.
                </Typography>
              </Box>
              <Box sx={{ display: "flex", gap: "10px" }}>
                <Box
                  sx={{ lineHeight: "10px", fontSize: "30px", color: "#fff" }}
                >
                  {/* <img src={CircleCheckIcon} alt="" /> */}
                  &#x2022;
                </Box>
                <Typography
                  sx={{
                    fontSize: "16px",
                    fontFamily: "Product Sans",
                    color: "#FFF",
                    mt: "-3px",
                  }}
                >
                  Get free access to our platform directly from your Builder!
                </Typography>
              </Box>

              <Box
                sx={{
                  display: "flex",
                  justifyContent: { xs: "center", md: "start" },
                }}
              >
                <Button
                  sx={{
                    background:
                      "linear-gradient(97.85deg, #FFAF2A 0.95%, #FFC666 100%)",
                    fontWeight: 500,
                    fontSize: { lg: "28px", xs: "16px" },
                    boxShadow: "rgba(242, 178, 73, 0.59) 0px 9px 12px",
                    borderRadius: "10px",
                    color: "#06386B",
                    textTransform: "none",
                    padding: "10px 30px",
                    fontFamily: "Product Sans",
                    mt: "20px",
                    "&:hover": {
                      background:
                        "linear-gradient(97.85deg, #FFAF2A 0.95%, #FFC666 100%)",
                      boxShadow: "rgba(242, 178, 73, 0.59) 10px 12px 16px",
                    },
                  }}
                >
                  Refer Your Builder
                </Button>
              </Box>
            </Stack>
          </Box>
          <Box
            data-aos="fade-up"
            sx={{
              width: { xs: "70%", md: "45%" },
              mb: { md: "-5.4%" },
            }}
          >
            <img style={{ width: "120%" }} src={GoneAreTheDaysImg} alt="" />
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

export default BuyerLandingGoneAreTheDaysOfOblivion;
