import {
  Box,
  Checkbox,
  Stack,
  Button,
  Accordion,
  AccordionSummary,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { Link, useNavigate } from "react-router-dom";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AccordionDetails from "@mui/material/AccordionDetails";
import { UserRole } from "../../../UserRoles/UserRoles";


function CPassignUnitCustomTable({
  newRowData,
  loading,
  CircularProgress,
}) {
   ////get user roles from local storage and assign 
     let roles = JSON.parse(localStorage.getItem("user_roles"))
     let unit = roles["unit"]

    console.log("unit data",newRowData); 
    const navigate = useNavigate();
    const [curpage, setcurpage] = useState(1);
    const [pageRange, setPageRange] = useState([0, 6]);

  const nextClick = () => {
    if (
      !(newRowData.length >= pageRange[0] && newRowData.length <= pageRange[1])
    ) {
      setcurpage(curpage + 1);
      setPageRange([pageRange[0] + 6, pageRange[1] + 6]);
    }
  };

  const prvClick = () => {
    if (pageRange[0] != 0 && pageRange[1] != 6) {
      setcurpage(curpage - 1);
      setPageRange([pageRange[0] - 6, pageRange[1] - 6]);
    }
  };

  const [pages, setPages] = useState([]);
  useEffect(() => {
    if (newRowData) {
      let arr = [];
      for (
        let i = 1;
        i <=
        (newRowData.length % 6 == 0
          ? newRowData.length / 6
          : newRowData.length / 6 + 1);
        i++
      ) {
        arr.push(i);
      }
      setPages(arr);
    }
  }, [newRowData]);

  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <>
     
   
        <Box sx={{ color: "black",  }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              background: "white",
              borderRadius: "5px",
              margin: " 10px 0",
              color: "#9299BC",
              fontWeight: "600",
            }}
          >
            <Stack direction="row" justifyContent="space-between" alignItems="center" sx={{width:"100%", padding:"5px"}}>
            <Box
              sx={{
                display: "grid",
                placeItems: "center",
                height: "50px",
              }}
            >
              <Box sx={{ display: "flex" }}>
                <Box sx={{ display: "grid", placeItems: "center" }}>
                  Unit No
                </Box>
                {/* <Box sx={{ display: "grid", marginLeft: "4px" }}>
                  <ArrowDropUpIcon
                    fontSize="medium"
                    sx={{ marginBottom: "-8px" }}
                  />
                  <ArrowDropDownIcon
                    fontSize="medium"
                    sx={{ marginTop: "-8px" }}
                  />
                </Box> */}
              </Box>
            </Box>

            <Box
              sx={{
                display: "grid",
                placeItems: "center",
                height: "50px",
              }}
            >
              <Box sx={{ display: "flex" }}>
                <Box sx={{ display: "grid", placeItems: "center" }}>
                  Floor No
                </Box>
                {/* <Box sx={{ display: "grid", marginLeft: "4px" }}>
                  <ArrowDropUpIcon
                    fontSize="medium"
                    sx={{ marginBottom: "-8px" }}
                  />
                  <ArrowDropDownIcon
                    fontSize="medium"
                    sx={{ marginTop: "-8px" }}
                  />
                </Box> */}
              </Box>
            </Box>

            <Box
              sx={{
                display: "grid",
                placeItems: "center",
                height: "50px",
              }}
            >
              <Box sx={{ display: "flex" }}>
                <Box sx={{ display: "grid", placeItems: "center" }}>
                  Bedrooms
                </Box>
                {/* <Box sx={{ display: "grid", marginLeft: "4px" }}>
                  <ArrowDropUpIcon
                    fontSize="medium"
                    sx={{ marginBottom: "-8px" }}
                  />
                  <ArrowDropDownIcon
                    fontSize="medium"
                    sx={{ marginTop: "-8px" }}
                  />
                </Box> */}
              </Box>
            </Box>

            <Box
              sx={{
                display: "grid",
                placeItems: "center",
                height: "50px",
              }}
            >
              <Box sx={{ display: "flex" }}>
                <Box sx={{ display: "grid", placeItems: "center" }}>
                  Bathrooms
                </Box>
                {/* <Box sx={{ display: "grid", marginLeft: "4px" }}>
                  <ArrowDropUpIcon
                    fontSize="medium"
                    sx={{ marginBottom: "-8px" }}
                  />
                  <ArrowDropDownIcon
                    fontSize="medium"
                    sx={{ marginTop: "-8px" }}
                  />
                </Box> */}
              </Box>
            </Box>

            <Box
              sx={{
                display: "grid",
                placeItems: "center",
                height: "50px",
              }}
            >
              <Box sx={{ display: "flex" }}>
                <Box sx={{ display: "grid", placeItems: "center" }}>
                  Total Are
                </Box>
                {/* <Box sx={{ display: "grid", marginLeft: "4px" }}>
                  <ArrowDropUpIcon
                    fontSize="medium"
                    sx={{ marginBottom: "-8px" }}
                  />
                  <ArrowDropDownIcon
                    fontSize="medium"
                    sx={{ marginTop: "-8px" }}
                  />
                </Box> */}
              </Box>
            </Box>

            <Box
              sx={{
                display: "grid",
                placeItems: "center",
                height: "50px",
              }}
            >
              <Box sx={{ display: "flex" }}>
                <Box sx={{ display: "grid", placeItems: "center" }}>
                  Pricing
                </Box>
                {/* <Box sx={{ display: "grid", marginLeft: "4px" }}>
                  <ArrowDropUpIcon
                    fontSize="medium"
                    sx={{ marginBottom: "-8px" }}
                  />
                  <ArrowDropDownIcon
                    fontSize="medium"
                    sx={{ marginTop: "-8px" }}
                  />
                </Box> */}
              </Box>
            </Box>

            <Box
              sx={{
                display: "grid",
                placeItems: "center",
                height: "50px",
              }}
            >
              Actions
            </Box>
            </Stack>

          </Box>
           {loading?<><Box sx={{textAlign:"center",marginTop:"50px"}}><CircularProgress color="inherit" sx={{ color: 'grey.500' }}/></Box></> :newRowData.length==0?
           <Box sx={{textAlign:"center",marginTop:"50px"}}><Typography sx={{fontSize:"25px",fontWeight:"500",color:"#B9B7D0",}}>No Matches Found</Typography></Box>:
           <Box>
          {newRowData &&
            newRowData.map((item, index) => {
              if (index >= pageRange[0] && index <= pageRange[1]) {
                return (
                  <Accordion
                    expanded={expanded === `panel${index}`}
                    onChange={handleChange(`panel${index}`)}
                    sx={{
                      margin: "10px 0",
                      boxShadow: "0",
                      border: "none",
                      width:"auto"
                    }}
                  >
                    <AccordionSummary sx={{ borderRadius: "5px", width:"100%",  boxSizing:"border-box", padding:"0px 5px 0px 5px" }}>
                      <Stack direction="row" justifyContent="space-between" alignItems="center" sx={{width:"100%"}}>
                      <Typography sx={{width:"7%"}}>
                        <Box
                          sx={{
                            display: "grid",
                            placeItems: "center",
                            // height: "50px",
                          }}
                        >
                          {item.id}
                        </Box>
                      </Typography>
                      <Typography sx={{width:"10%"}}>
                        <Box
                          sx={{
                            display: "grid",
                            placeItems: "center",
                            // height: "50px",
                          }}
                        >
                          {item.floor_no}
                        </Box>
                      </Typography>
                      <Typography sx={{width:"8%"}}>
                        <Box
                          sx={{
                            display: "grid",
                            placeItems: "center",
                            // height: "50px",
                          }}
                        >
                          {item.bedroom}
                        </Box>
                      </Typography>
                      <Typography sx={{width:"14%"}}>
                        <Box
                          sx={{
                            display: "grid",
                            placeItems: "center",
                            // height: "50px",
                          }}
                        >
                          {item.bathroom}
                        </Box>
                      </Typography>
                      <Typography sx={{width:"10%"}}>
                        <Box
                          sx={{
                            display: "grid",
                            placeItems: "center",
                            // height: "50px",
                          }}
                        >
                          {item.totalarea}
                        </Box>
                      </Typography>
                      <Typography sx={{width:"10%"}}>
                        <Box
                          sx={{
                            display: "grid",
                            placeItems: "center",
                            // height: "50px",
                          }}
                        >
                          {item.pricing}
                          
                        </Box>
                      </Typography>
                      <Typography sx={{width:"6%"}}>
                        <Box
                          sx={{
                            display: "grid",
                            placeItems: "center",
                            // height: "50px",
                          }}
                        >
                          <ExpandMoreIcon />
                        </Box>
                      </Typography>
                      </Stack>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography>
                        <Box
                          sx={{
                            color: "#969BA0",
                            fontSize: "18px",
                            padding: "0 40px",
                            marginTop: "20px",
                          }}
                        >
                          The Details Review of Unit
                        </Box>
                        <Box
                          sx={{
                            display: "grid",
                            gridTemplateColumns: "1fr 1fr 1fr",
                            padding: "0 40px",
                            marginTop: "20px",
                            rowGap: "30px",
                          }}
                        >

                        <Box sx={{ display: "flex" }}>
                            <Box>Unit No : </Box>
                            <Box sx={{ color: "#6F6F6F" }}>{item?.id}</Box>
                          </Box>
                          <Box sx={{ display: "flex" }}>
                            <Box>Floor No : </Box>
                            <Box sx={{ color: "#6F6F6F" }}>{item?.floor_no}</Box>
                          </Box>
                        
                        {item?.single_data?.map((ele,idx)=>{
                           
                           return(
                            <Box sx={{ display: "flex" }}>
                            <Box>{ele.label} : </Box>
                            <Box sx={{ color: "#6F6F6F" }}>{ele.value}</Box>
                            </Box>
                            )

                        })}

                        {item?.double_data?.map((ele,idx)=>{
                           
                           return(
                            <Box sx={{ display: "flex" }}>
                            <Box>{ele.label} : </Box>
                            <Box sx={{ color: "#6F6F6F" }}>{`${ele.value1} X ${ele.value1}`}</Box>
                            </Box>
                            )

                        })}

                        <Box sx={{ display: "flex" }}>
                            <Box>No. of Bedrooms : </Box>
                            <Box sx={{ color: "#6F6F6F" }}>{item.bedroom}</Box>
                          </Box>
                          <Box sx={{ display: "flex" }}>
                            <Box>No of Bathrooms: </Box>
                            <Box sx={{ color: "#6F6F6F" }}>{item.bathroom}</Box>
                          </Box>
                           <Box sx={{ display: "flex" }}>
                            <Box>No of Balcony: </Box>
                            <Box sx={{ color: "#6F6F6F" }}>{item.balcony}</Box>
                          </Box>
                           <Box sx={{ display: "flex" }}>
                            <Box>Total Cost: </Box>
                            <Box sx={{ color: "#6F6F6F" }}>{item.pricing}</Box>
                          </Box>

                         
                        </Box>
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "right",
                          }}
                        >
                          <Box
                            sx={{
                              display: "grid",
                              placeItems: "center",
                              height: "10vh",
                              marginRight:"50px"
                            }}
                          >
                            <Stack direction="row" sx={{ marginTop:"10px"}}>
                              <Button variant="contained" sx={{width: "200px",background:"linear-gradient(90deg, #0F5DAE 0%, #009DCF 100.33%)"}}
                                onClick={() => {
                                  navigate(`/unit-directory-w-sb/${item.id}`);
                                }}  
                              >Unit Overview
                              </Button>
                            </Stack>
                          </Box>
                        </Box>
                      </Typography>
                    </AccordionDetails>
                  </Accordion>
                );
              }
            })}

            <Box sx={{margin:"50px 0px 20px 0px"}}>
          <Box sx={{ display: "flex", justifyContent: "right" }}>
            {newRowData.length!=0 && 
            <Button variant="contained" sx={{background:"linear-gradient(90deg, #0F5DAE 0%, #009DCF 100.33%)"}}
              onClick={prvClick}>
                <ArrowBackIosIcon fontSize="small" />
                <Box sx={{ textTransform: "capitalize" }}>Previous</Box>
            </Button>}

            <Box sx={{background: "#E3E4EB",display: "flex",placeItems: "center",margin: " 0 10px",borderRadius: "5px"}}>
                <Box sx={{background: `white`,color: "black",width: "30px",borderRadius: "5px",margin: "0 10px",display: "grid",placeItems: "center"}}>{curpage}</Box>
                <Box sx={{color: "black",width: "30px",borderRadius: "5px",margin: "0 0px",display: "grid",placeItems: "center"}}>of</Box>
                <Box sx={{background: `white`,color: "black",width: "30px",borderRadius: "5px",margin: "0 10px",display: "grid",placeItems: "center"}}>{pages?.length}</Box>
            </Box>

            {newRowData.length!=0 && 
                <Button variant="contained" sx={{background:"linear-gradient(90deg, #0F5DAE 0%, #009DCF 100.33%)"}}
                    onClick={nextClick}>
                    <Box sx={{ textTransform: "capitalize" }}>Next</Box>
                    <ArrowForwardIosIcon fontSize="small" />
                </Button>
            }
          </Box>
        </Box>
        
          </Box>
          } 
        </Box>
        
      {/* </div> */}
    </>
  );
}

export default CPassignUnitCustomTable