import { Box, Stack} from '@mui/system';
import React, { useEffect, useState } from 'react';
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { Button, CircularProgress, Typography } from '@mui/material';
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

import { SuperAdminSidebar } from '../SidebarNavbar/SuperAdminSidebar';
import { SuperAdminNavbar } from '../SidebarNavbar/SuperAdminNavbar';
import { Apiaddress } from "../../../utils/consts";
import axios from 'axios';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { UserRole } from '../../../UserRoles/UserRoles';

function SuperAdminOrg() {
   //get org id list from local storage;
   let builder = JSON.parse(localStorage.getItem("SABuilderDetails"))
  
     ////get user roles from local storage and assign 
     let roles = JSON.parse(localStorage.getItem("user_roles"))
     let org = roles["org"];
     let project = roles["project"];

     const navigate  = useNavigate();

    //get data from previous page 
    const location = useLocation();
    const data = builder.orgList
    console.log("preve data =>.>",data)

   const [curpage, setcurpage] = useState(1);
   const [pageRange, setPageRange] = useState([0, 9]);
   const [pages, setPages] = useState([]);

   const [loading,setloading]= useState(true);

   const [newRowData,setnewRowData]= useState([]);

    const nextClick = () => {
    if (
      !(newRowData.length >= pageRange[0] && newRowData.length <= pageRange[1])
    ) {
      setcurpage(curpage + 1);
      setPageRange([pageRange[0] + 7, pageRange[1] + 9]);
    }
    };

  const prvClick = () => {
    if (pageRange[0] != 0 && pageRange[1] != 9) {
      setcurpage(curpage - 1);
      setPageRange([pageRange[0] - 9, pageRange[1] - 9]);
    }
    };

    useEffect(() => {
    if (newRowData) {
      let arr = [];
      for (
        let i = 1;
        i <=
        (newRowData.length % 9 == 0
          ? newRowData.length / 9
          : newRowData.length / 9 + 1);
        i++
      ) {
        arr.push(i);
      }
      setPages(arr);
    }
  }, [newRowData]);


 
  //fecting Organisation Data
  const fetchOrgdata =async ()=>{
    
    let arr=[];

    for(let i=0;i<data.length;i++){
        const orgurl=Apiaddress+`/api/org/?org_id=${data[i]}` 
        const res = await axios.get(orgurl);
        
        let obj={
            id:res.data.org.id,
            name:res.data.org.org_name,
            state:res.data.org.state,
            city:res.data.org.city,
            pincode:res.data.org.zip_code,
        }
        arr.push(obj);
    }

    setnewRowData(arr);
    setloading(false);
   
  }

  console.log(newRowData);

  useEffect(()=>{
     
    fetchOrgdata();
   
  },[])

  return (
    <>
    <Stack direction="row" sx={{width:"100%",height:"auto" }}>
         {/* 1st stack */}
      <Stack sx={{minHeight:"100vh",width:"20%",boxSizing:"border-box",}}> {/*for project directory 20% and for sales dashboard 225*/}
        <SuperAdminSidebar active={"Builder Directory"}/>
      </Stack>
        
      {/* 2nd stack */}
     <Stack direction="column" sx={{ backgroundColor:"#f9f9f9",width:"80%", padding:"25px",boxSizing:"border-box",}}  >  {/*  width for main sidebar 78px and for 80px*/}
      <SuperAdminNavbar organization={true}/>  {/*send props for get popup to verify otp from builder */}
      

       <Typography sx={{marginTop:"10px", fontSize:"16px", fontWeight:"600", marginLeft:"0px", marginBottom:"0px",color:"#2979F2",fontWeight: "400"}}>
        Here is your Organisation Directory list Data 
        </Typography>
      <Typography sx={{marginTop:"20px", fontSize:"28px", fontWeight:"600", marginLeft:"0px"}}>
         Organisation Directory   
      </Typography>
      <Typography sx={{marginTop:"10px", fontSize:"16px", fontWeight:"600", marginLeft:"0px", marginBottom:"50px",color: "#9299BC",fontWeight: "500"}}>
         Here is your Organisation Directory list associated with Builder 
      </Typography>

       {/* actual content  */}
    <Stack sx={{backgroundColor:"#f9f9f9",height:'auto'}}>  {/* mt:"28px", */}

    {/* builder list table */}
    <Box sx={{width:"100%"}}>
        <Box sx={{ color: "black", width: "100%" ,boxSizing:"border-box",display:"flex",flexDirection:"column"}}>

            <Stack direction="row" justifyContent="space-between" alignItems="center" sx={{width:"100%",backgroundColor:"white",borderRadius: "5px",margin:"0px 0px 10px 0px",padding:"10px 0px 10px 0px"}}>
              
                <Stack direction="row" justifyContent="center" alingItems="center" sx={{width:"14%",textAlign:"center",color: "#9299BC",fontWeight: "600"}}>
                        <Typography sx={{ display: "grid", placeItems: "center",fontWeight:"600",color:"black" }}>Name</Typography>
                        {/* <Box sx={{ display: "grid", marginLeft: "4px" }}>
                            <ArrowDropUpIcon fontSize="medium" sx={{ marginBottom: "-8px" }}/>
                            <ArrowDropDownIcon fontSize="medium" sx={{ marginTop: "-8px" }}/>
                        </Box> */}
                </Stack>

                 <Stack direction="row" justifyContent="center" alingItems="center" sx={{width:"14%",textAlign:"center",color: "#9299BC",fontWeight: "600"}}>
                        <Typography sx={{ display: "grid", placeItems: "center",fontWeight:"600",color:"black" }}>State</Typography>
                        {/* <Box sx={{ display: "grid", marginLeft: "4px" }}>
                            <ArrowDropUpIcon fontSize="medium" sx={{ marginBottom: "-8px" }}/>
                            <ArrowDropDownIcon fontSize="medium" sx={{ marginTop: "-8px" }}/>
                        </Box> */}
                </Stack>

                 <Stack direction="row" justifyContent="center" alingItems="center" sx={{width:"14%",textAlign:"center",color: "#9299BC",fontWeight: "600"}}>
                        <Typography sx={{ display: "grid", placeItems: "center",fontWeight:"600",color:"black" }}>City</Typography>
                        {/* <Box sx={{ display: "grid", marginLeft: "4px" }}>
                            <ArrowDropUpIcon fontSize="medium" sx={{ marginBottom: "-8px" }}/>
                            <ArrowDropDownIcon fontSize="medium" sx={{ marginTop: "-8px" }}/>
                        </Box> */}
                </Stack>

                 <Stack direction="row" justifyContent="center" alingItems="center" sx={{width:"14%",textAlign:"center",color: "#9299BC",fontWeight: "600"}}>
                        <Typography sx={{ display: "grid", placeItems: "center",fontWeight:"600",color:"black" }}>Pincode</Typography>
                        {/* <Box sx={{ display: "grid", marginLeft: "4px" }}>
                            <ArrowDropUpIcon fontSize="medium" sx={{ marginBottom: "-8px" }}/>
                            <ArrowDropDownIcon fontSize="medium" sx={{ marginTop: "-8px" }}/>
                        </Box> */}
                </Stack>

                 <Stack direction="row" justifyContent="center" alingItems="center" sx={{width:"14%",textAlign:"center",color: "#9299BC",fontWeight: "600"}}>
                        <Typography sx={{ display: "grid", placeItems: "center",fontWeight:"600",color:"black" }}>Detailed View</Typography>
                        {/* <Box sx={{ display: "grid", marginLeft: "4px" }}>
                            <ArrowDropUpIcon fontSize="medium" sx={{ marginBottom: "-8px" }}/>
                            <ArrowDropDownIcon fontSize="medium" sx={{ marginTop: "-8px" }}/>
                        </Box> */}
                </Stack>

            </Stack>
            
            {loading==true?
            <Box sx={{display:"flex",height:"80vh",padding:"10% 5% 5% 45%"}}>
                <CircularProgress color="inherit" sx={{ color: 'grey.500' }}/>
            </Box>
             :
            <Box sx={{}}>
                {newRowData.length==0?
                    <Box sx={{textAlign:"center",marginTop:"50px",height:"80vh"}}>
                        <Typography sx={{fontSize:"25px",fontWeight:"500",color:"#B9B7D0",}}>No Matches Found</Typography>
                    </Box>:
                    <Box>
                        {newRowData && newRowData?.map((item, index) => {
                            if (index >= pageRange[0] && index <= pageRange[1]) {

                                return (

                                  <>
                                   
                                    <Stack 
                                    onClick={()=>{
                                      if(UserRole(project, "view_project")){
                                        let datafromlocal = JSON.parse(localStorage.getItem("SABuilderDetails"));// get data from local storage
                                        localStorage.setItem("SABuilderDetails", JSON.stringify({...datafromlocal,selectedOrgId:item.id}))
                                         navigate("/superadmin-project")
                                      }
                                    }}
                                    direction="row" justifyContent="space-between" alignItems="center" sx={{width:"100%",borderRadius: "5px",margin:"0px 0px 10px 0px",padding:"10px 0px 10px 0px",backgroundColor:"white"}}>

                                       <Stack direction="row" justifyContent="center" alingItems="center" sx={{width:"14%",textAlign:"center",color: "#9299BC",fontWeight: "600"}}>
                                        <Typography >
                                           {item?.name}
                                        </Typography>
                                       </Stack>
                                       <Stack direction="row" justifyContent="center" alingItems="center" sx={{width:"14%",textAlign:"center",color: "#9299BC",fontWeight: "600"}}>
                                        <Typography >
                                          {item?.state}
                                        </Typography>
                                       </Stack>
                                       <Stack direction="row" justifyContent="center" alingItems="center" sx={{width:"14%",textAlign:"center",color: "#9299BC",fontWeight: "600"}}>
                                        <Typography >
                                          {item?.city}
                                        </Typography>
                                       </Stack>
                                       <Stack direction="row" justifyContent="center" alingItems="center" sx={{width:"14%",textAlign:"center",color: "#9299BC",fontWeight: "600"}}>
                                        <Typography >
                                           {item?.pincode}
                                        </Typography>
                                       </Stack>

                                       <Stack direction="row" justifyContent="center" alingItems="center" spacing={1.5} sx={{width:"14%",textAlign:"center"}}>
                                             
                                            {/* <EditIcon sx={{color: "#9299BC",cursor:"pointer"}}/> */}
                                            {UserRole(org, "view_org") && 
                                            <Link
                                                to="/superadmin-project" state={item.id}
                                                  onClick={(e)=>{
                                                    e.stopPropagation();
                                                    let datafromlocal = JSON.parse(localStorage.getItem("SABuilderDetails"));// get data from local storage
                                                    localStorage.setItem("SABuilderDetails", JSON.stringify({...datafromlocal,selectedOrgId:item.id}))
                                                  }}
                                                  >
                                                <RemoveRedEyeIcon sx={{color: "#9299BC",cursor:"pointer"}}/>
                                              </Link>
                                            }
                                            {/* <DeleteIcon sx={{color: "#9299BC"}}/> */}
                                        </Stack> 

                                    </Stack>
                                 
                                  </>
                                    
                                );
                            }
                        })} 
                      
        <Box sx={{margin:"30px 0px 20px 0px"}}>
          <Box sx={{ display: "flex", justifyContent: "right" }}>
            {newRowData.length!=0 && 
            <Button variant="contained" sx={{background:"linear-gradient(90deg, #0F5DAE 0%, #009DCF 100.33%)"}}
              onClick={prvClick}>
                <ArrowBackIosIcon fontSize="small" />
                <Box sx={{ textTransform: "capitalize" }}>Previous</Box>
            </Button>}

            <Box sx={{background: "#E3E4EB",display: "flex",placeItems: "center",margin: " 0 10px",borderRadius: "5px"}}>
                <Box sx={{background: `white`,color: "black",width: "30px",borderRadius: "5px",margin: "0 10px",display: "grid",placeItems: "center"}}>{curpage}</Box>
                <Box sx={{color: "black",width: "30px",borderRadius: "5px",margin: "0 0px",display: "grid",placeItems: "center"}}>of</Box>
                <Box sx={{background: `white`,color: "black",width: "30px",borderRadius: "5px",margin: "0 10px",display: "grid",placeItems: "center"}}>{pages?.length}</Box>
            </Box>

            {newRowData.length!=0 && 
                <Button variant="contained" sx={{background:"linear-gradient(90deg, #0F5DAE 0%, #009DCF 100.33%)"}}
                    onClick={nextClick}>
                    <Box sx={{ textTransform: "capitalize" }}>Next</Box>
                    <ArrowForwardIosIcon fontSize="small" />
                </Button>
            }
          </Box>
        </Box>
                      
        </Box>
      }
    </Box>
  }

  </Box>

  </Box>

  </Stack>
  </Stack>

  </Stack>
    </>
  )
}

export default SuperAdminOrg