import { Avatar, Button, Stack, Typography } from "@mui/material";
import { Box } from "@mui/system";
import NotificationsIcon from "@mui/icons-material/Notifications";
import AddIcon from "@mui/icons-material/Add";
import { Link, useNavigate } from "react-router-dom";
import SendIcon from "@mui/icons-material/Send";
import { useContext, useState } from "react";
import userContext from "../../../../context/profile/context";

export const NavbarForDirectory = ({
  showAddProjectbtn,
  showRightSideBar,
  toggleDrawer,
  RightBarName,
  leftTitle,
  showRightSideBarleft,
  RightBarNameleft,
  addButton,
  addTitle,
  toggleDrawerAdd,
  RightBarNameProAdd,
  showRightSideBarProjAdd,
  leftRightTitle1,
  showLeftTitle,
  leftRightTitle2,
  righshow,
  showUnitAdd,
  cpaddtitle,
  cpadd,
  qdadd,
  qdaddtext,
  unitadd,
  longOrangBgr,
  openModal,
  handleOpen,
  dontShowPath,
  memberadd,
  teamadd,
  name,
}) => {
  const navigate = useNavigate();
  const [Open, setOpen] = useState(false);
  const UC = useContext(userContext);

  return (
    <>
      <Box
        className="navbar"
        onClick={() => {
          setOpen(false);
        }}
        sx={{ width: "100%", mb: "20px" }}
      >
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography className="WelcomeText" sx={{ fontSize: "24px" }}>
            {!name ? "Hello Builder" : "Hello, " + name}
          </Typography>

          <Stack direction="row">
            <Stack
              direction="row"
              justifyContent="right"
              alignItems="center"
              spacing={2}
            >
              {showAddProjectbtn && (
                <Button
                  className="univercel_btn_class"
                  sx={{
                    color: "#FFFFFF",
                    textTransform: "none",
                    background:
                      " linear-gradient(150deg, #79F1A4 13.4%, #0E5CAD 86.6%)",
                  }}
                  startIcon={<AddIcon />}
                  onClick={() => navigate("/createproject")}
                >
                  Add Projects
                </Button>
              )}
              {showRightSideBarleft && (
                <Button
                  className="univercel_btn_class"
                  sx={{
                    color: "#FFFFFF",
                    textTransform: "none",
                  }}
                  startIcon={<SendIcon />}
                  onClick={openModal}
                >
                  {RightBarNameleft}
                </Button>
              )}
              {addButton ?? null}
              {cpadd ? (
                <Button
                  className="univercel_btn_class"
                  sx={{
                    color: "#FFFFFF",
                    textTransform: "none",
                  }}
                  startIcon={<SendIcon />}
                  onClick={toggleDrawer(true)}
                >
                  {RightBarNameleft}
                </Button>
              ) : null}

              {memberadd && (
                <Button
                  className="univercel_btn_class"
                  sx={{
                    color: "#FFFFFF",
                    textTransform: "none",
                  }}
                  startIcon={<AddIcon />}
                  onClick={toggleDrawerAdd(true)}
                >
                  {RightBarName}
                </Button>
              )}

              {teamadd && (
                <Button
                  className="univercel_btn_class"
                  sx={{
                    color: "#FFFFFF",
                    textTransform: "none",
                  }}
                  startIcon={<AddIcon />}
                  onClick={toggleDrawerAdd(true)}
                >
                  {RightBarName}
                </Button>
              )}

              {/* {showRightSideBar && RightBarName!="Add Buyer" && (
                <Button
                  className="univercel_btn_class"
                  sx={{
                    color: "#FFFFFF",
                    textTransform: "none",
                    // background:
                    //   " linear-gradient(150deg, #79F1A4 13.4%, #0E5CAD 86.6%)",
                  }}
                  startIcon={<AddIcon />}
                  onClick={toggleDrawer(true)}
                >
                  {RightBarName}
                </Button>
              )} */}

              {/* { showRightSideBar && RightBarName=="Add Member" && (
                <Button
                  className="univercel_btn_class"
                  sx={{
                    color: "#FFFFFF",
                    textTransform: "none",
                    // background:
                    //   " linear-gradient(150deg, #79F1A4 13.4%, #0E5CAD 86.6%)",
                  }}
                  startIcon={<AddIcon />}
                  onClick={toggleDrawerAdd(true)}
                >
                  {RightBarName}
                </Button>
              )}
              */}

              {showRightSideBar && RightBarName == "Add Buyer" && (
                <Box
                  title={`You can't add buyer directly.\n you can make lead as Buyer`}
                >
                  <Button
                    // className="univercel_btn_class"
                    sx={{
                      color: "#FFFFFF",
                      backgroundColor: "#969BA0",
                      textTransform: "none",
                      // background:
                      //   " linear-gradient(150deg, #79F1A4 13.4%, #0E5CAD 86.6%)",
                    }}
                    startIcon={<AddIcon />}
                    // onClick={toggleDrawer(true)}
                    disabled
                  >
                    {RightBarName}
                  </Button>
                </Box>
              )}

              {showRightSideBarProjAdd && (
                <Button
                  className="univercel_btn_class"
                  sx={{
                    color: "#FFFFFF",
                    textTransform: "none",
                    // background:
                    //   " linear-gradient(150deg, #79F1A4 13.4%, #0E5CAD 86.6%)",
                  }}
                  startIcon={<AddIcon />}
                  onClick={() => navigate("/createproject")}
                >
                  {RightBarNameProAdd}
                </Button>
              )}
              {showUnitAdd && (
                <Button
                  className="univercel_btn_class"
                  sx={{
                    color: "#FFFFFF",
                    textTransform: "none",
                    // background:
                    //   " linear-gradient(150deg, #79F1A4 13.4%, #0E5CAD 86.6%)",
                  }}
                  startIcon={<AddIcon />}
                >
                  {RightBarNameProAdd}
                </Button>
              )}
              {/* {cpadd && (
                <Button
                  className="univercel_btn_class"
                  sx={{
                    color: "#FFFFFF",
                    textTransform: "none",
                  }}
                  startIcon={<AddIcon />}
                  onClick={toggleDrawer(true)}
                >
                  {cpaddtitle}
                </Button>
              )} */}
              {qdadd && (
                <Button
                  className="univercel_btn_class"
                  sx={{
                    color: "#FFFFFF",
                    textTransform: "none",
                    // background:
                    //   " linear-gradient(150deg, #79F1A4 13.4%, #0E5CAD 86.6%)",
                  }}
                  startIcon={<AddIcon />}
                  // onClick={toggleDrawer(true)}
                  onClick={handleOpen}
                >
                  {qdaddtext}
                </Button>
              )}

              {unitadd && (
                <Button
                  className="univercel_btn_class"
                  sx={{
                    color: "#FFFFFF",
                    textTransform: "none",
                    // background:
                    //   " linear-gradient(150deg, #79F1A4 13.4%, #0E5CAD 86.6%)",
                  }}
                  startIcon={<AddIcon />}
                  onClick={() => navigate("/confirm-project-unit")}
                >
                  add unit
                </Button>
              )}
              {/* <NotificationsIcon /> */}
              {/* <Typography>My Profile</Typography>
              <Avatar sx={{ bgcolor: "orange" }}>B</Avatar> */}
              <Typography>My Profile</Typography>
              <Box
                onClick={(event) => {
                  event.stopPropagation();
                  setOpen(!Open);
                }}
              >
                <Avatar className="profileIconColor" sx={{ cursor: "pointer" }}>
                  {name?.charAt(0)?.toUpperCase()}
                </Avatar>
              </Box>
              {//The Drop Down
              Open && (
                <Box sx={{ zIndex: 2, position: "absolute", right: 0 }}>
                  <Box
                    sx={{
                      position: "absolute",
                      right: 30,
                      marginTop: "15px",
                    }}
                  >
                    <Box
                      sx={{
                        width: 0,
                        height: 0,

                        borderLeft: "15px solid transparent",
                        borderRight: "15px solid transparent",
                        borderBottom: "15px solid white",
                      }}
                    ></Box>
                  </Box>
                  <Box
                    sx={{
                      position: "absolute",
                      marginTop: "30px",
                      zIndex: 2,
                      right: 20,
                      width: 120,
                      backgroundColor: "white",
                      boxShadow: "0px 11px 15px rgba(0, 0, 0, 0.2)",
                      borderRadius: "12px",
                      padding: "10px",
                    }}
                  >
                    <Box paddingRight="20px">
                      <Link
                        to="/"
                        onClick={() => {
                          UC.logOut();
                        }}
                      >
                        <Stack
                          direction="row"
                          justifyContent="start"
                          alignItems="center"
                          className="hover_button"
                          sx={{
                            background: "white",
                          }}
                        >
                          <Typography
                            className="link_name"
                            sx={{
                              fontWeight: "400",
                              fontSize: "16px",
                              color: "#A4A4AD",
                            }}
                          >
                            {" "}
                            Logout
                          </Typography>
                        </Stack>
                      </Link>
                    </Box>
                  </Box>
                </Box>
              )}
            </Stack>
          </Stack>
        </Box>
        {/* <Stack direction="row" sx={{}}>
          <Box zIndex="1">
            <svg
              width="115"
              height="29"
              viewBox="0 0 115 29"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M115.037 7.24752L108.08 0H0V29H108.08L115.037 21.7525L122 14.505L115.037 7.24752Z"
                fill="#FBB040"
              />
            </svg>
          </Box>
          <Box sx={{ position: "absolute" }}>
            <svg
              width="140"
              height="29"
              viewBox="0 0 140 29"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              style={{ marginLeft: 100 }}
            >
              <path
                d="M115.037 7.24752L108.08 0H0V29H108.08L115.037 21.7525L122 14.505L115.037 7.24752Z"
                fill="#FBB040"
              />
            </svg>
          </Box>
          <Stack
            direction="row"
            justifyContent="left"
            alignItems="center"
            zIndex="2"
            sx={{ position: "absolute" }}
          >
            <Typography
              sx={{
                fontWeight: "400",
                fontSize: "11px",
                color: "#F9F9F9",
                paddingTop: "5px",
                marginLeft: "30px",
              }}
            >
              {leftTitle}
            </Typography>
          </Stack>
        </Stack> */}
        {!dontShowPath && (
          <Stack direction="row" sx={{}}>
            <Box zIndex="1">
              <svg
                width="115"
                height="29"
                viewBox="0 0 115 29"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M108.437 7.24752L101.879 0H0V29H101.879L108.437 21.7525L115 14.505L108.437 7.24752Z"
                  fill="#CCCCCC"
                />
              </svg>
            </Box>
            {righshow == true && longOrangBgr == true ? (
              <Box sx={{ position: "absolute" }}>
                <svg
                  width="162"
                  height="29"
                  viewBox="0 0 162 29"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  style={{ marginLeft: 100 }}
                >
                  <path
                    d="M152.754 7.24752L143.516 0H0V29H143.516L152.754 21.7525L162 14.505L152.754 7.24752Z"
                    fill="#FBB040"
                  />
                </svg>
              </Box>
            ) : (
              <Box sx={{ position: "absolute" }}>
                <svg
                  width="140"
                  height="29"
                  viewBox="0 0 140 29"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  style={{ marginLeft: 100 }}
                >
                  <path
                    d="M115.037 7.24752L108.08 0H0V29H108.08L115.037 21.7525L122 14.505L115.037 7.24752Z"
                    fill="#FBB040"
                  />
                </svg>
              </Box>
            )}
            <Stack
              direction="row"
              justifyContent="left"
              alignItems="center"
              zIndex="2"
              sx={{ position: "absolute" }}
            >
              <Typography
                sx={{
                  fontWeight: "400",
                  fontSize: "11px",
                  color: "#F9F9F9",
                  paddingTop: "5px",
                  paddingLeft: "5px",
                }}
              >
                {leftTitle}
              </Typography>
              {righshow && (
                <Typography
                  sx={{
                    fontWeight: "400",
                    fontSize: "11px",
                    color: "#F9F9F9",
                    paddingTop: "5px",
                    marginLeft: "30px",
                  }}
                >
                  {leftRightTitle2}
                </Typography>
              )}
            </Stack>
          </Stack>
        )}
      </Box>
    </>
  );
};
