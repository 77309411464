import { Box, Button, CircularProgress, FormControl, MenuItem, Select, Stack, TextField, Typography } from '@mui/material'
import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { Navigate, useNavigate } from 'react-router-dom'
import { NavbarForDirectory } from "../ProjectDashboard/DashboardComponents/NavbarForDirectory";
import { MainSidebar } from "../ProjectDashboard/DashboardComponents/MainSidebar";
import { Apiaddress } from "../../../utils/consts";


function BuilderRolePermissionEdit() {
   const navigate = useNavigate();
    
    let  token  = localStorage.getItem("crm_token");

    const [rolename,setrolename]=useState();
    const [roleId,setroleId]=useState();
    const [roleList,setRoleList]=useState();
    const [roleData,setRoleData]=useState(); 
    const [rowData,setRowData]=useState([]);
    const [trigger,setTrigger]=useState(false);
    const [loading,setloading]= useState(false);

    // console.log(rolename);
    // console.log(roleId);
    // console.log(roleData);
    // console.log(rowData);
    
    //fetch role list
    const fetchRoleList=async ()=>{
    try{
      const headers = {
        Authorization: `JWT ${token}`,  
      };  
    const roleurl = Apiaddress+"/api/roleprofile/"
    const res = await axios.get(roleurl,{headers});
    // console.log("=============================",res?.data?.roles);
    setRoleList(res?.data?.roles);
    }
    catch(err){
      console.log(err);
    }
    }

    //fetch role Data
    const fetchRoleData= (id)=>{

      for(let i=0;i<roleList.length;i++){
        if(id==roleList[i]?.id){
          setRoleData(roleList[i]);
        }
      }

    }

     //fetching Total permission Model
    const fetchTotalPermissionModel=async ()=>{
    if(!rolename) return;
    setloading(true);
    try{
        const headers = {
        Authorization: `JWT ${token}`,
      };  
       const roleurl = Apiaddress+`/api/modelpermissions/`
       const res = await axios.get(roleurl,{headers});
    // console.log("eee=============================",res?.data);

    let temp=res?.data;
    let newArray=[];
    for (let [key, value] of Object.entries( temp)) {
        
        let added={
            id:value[0].id,
            codename:value[0].codename,
            is_permission:false
        };
        let updated={
            id:value[1].id,
            codename:value[1].codename,
            is_permission:false
        };
        let deleted={
            id:value[2].id,
            codename:value[2].codename,
            is_permission:false
        };
        let viewed={
            id:value[3].id,
            codename:value[3].codename,
            is_permission:false
        };

        let obj={}
        obj.title=key;
        obj.add=added;
        obj.update=updated;
        obj.delete=deleted;
        obj.view=viewed;
        
        newArray.push(obj);
    }

    let permission=roleData?.permissions;
      for(let i=0;i<newArray?.length;i++){

        let add=newArray[i]?.add?.id;
        let deleted=newArray[i]?.delete?.id;
        let update=newArray[i]?.update?.id;
        let view=newArray[i]?.view?.id;

        let obj1 = permission?.find(ele => ele?.id === add);
        // console.log(obj1);
        let obj2 = permission?.find(ele => ele?.id === deleted);
        // console.log(obj2);
        let obj3 = permission?.find(ele => ele?.id === update);
        // console.log(obj3);
        let obj4 = permission?.find(ele => ele?.id === view);
        // console.log(obj4);

        if(obj1){
          newArray[i].add.is_permission=true
        }
        if(obj2){
          newArray[i].delete.is_permission=true;
        }
        if(obj3){
          newArray[i].update.is_permission=true;
        }
        if(obj4){
          newArray[i].view.is_permission=true;
        }

      }
        setRowData(newArray);
        setTrigger(true);
        setloading(false);
    }
    catch(err){
      console.log(err);
    }
    }

    const assignPermission=async (id,permission)=>{
        console.log(id," === ",permission);
        if(id && permission){
            try{
            const headers = {
            Authorization: `JWT ${token}`,
            };  
           let obj={
                role_id:roleId,
                permission_id:id
            }
            const roleurl = Apiaddress+"/api/roleprofile/"
            const res = await axios.put(roleurl,obj,{headers});
            console.log("tttt=========================",res?.data);
            }
            catch(err){
                console.log(err);
            }
        }
        else{
            try{
            const headers = {
            Authorization: `JWT ${token}`,
            };  
           let obj={
                role_id:roleId,
                permission_id:id
            }
            const roleurl = Apiaddress+"/api/roleprofile/"
            const res = await axios.delete(roleurl,{
              headers: {
                Authorization: `JWT ${token}`
              },
              data: {
                role_id:roleId,
                permission_id:id
              }
            });
            console.log("tttt=========================",res?.data);
            }
            catch(err){
                console.log(err);
            }
        }
    }
    
    useEffect(()=>{
      fetchRoleList();
    },[])

  return (
    <>
      <Stack direction="row" sx={{width:"100%",height:"auto" }}>
        {/* 1st stack */}
        <Stack sx={{minHeight:"100vh",width:"20%",boxSizing:"border-box",}}> {/*for project directory 20% and for sales dashboard 225*/}
            <MainSidebar active={"Teams Directory"}/>
        </Stack>
        {/* 2nd stack */}
        <Stack direction="column" sx={{ backgroundColor:"#f9f9f9",width:"80%", padding:"25px",boxSizing:"border-box",}}  >  {/*  width for main sidebar 78px and for 80px*/}

            <NavbarForDirectory
            RightBarName={"Add Member"}
            leftTitle={"Team directory"}
            righshow={true}
            leftRightTitle1={"Project Dashboard"}
            leftRightTitle2={"Member Directory"}
            />   {/*send props for get popup to verify otp from builder */}

            <Typography sx={{fontFamily:'Poppins',fontStyle:"normal",fontWeight:"600",fontSize:"28px",lineHeight:"42px",marginTop:"20px"}}>Roles Overview</Typography>

             <Stack sx={{margin:"50px 0px 30px 0px",background:"#FFFFFF"}}>
                <Stack direction="row" sx={{alignItems:"center",margin:"30px 0px 30px 40px"}}>
                  <Typography sx={{width:"20%",fontFamily:'Poppins',fontStyle:"normal",fontWeight:"500",fontSize:"18px",lineHeight:"27px"}}>Role Profile Name</Typography>
                  {/* <TextField value={rolename} onChange={(e)=>{setrolename(e.target.value)}}/> */}

                   <FormControl sx={{width:"278px", marginLeft:"0px"}}>
                    <Select
                      onChange={(e) => {
                        let [name,role_id] = e.target.value.split("-");
                          setroleId(role_id);
                          setrolename(name); 
                          fetchRoleData(role_id);
                          setTrigger(false);
                          setRowData([]);
                      }}
                      displayEmpty
                      inputProps={{ 'aria-label': 'Without label' }}>
                      {roleList && roleList.map((ele,index)=>{
                        return(
                          <MenuItem key={index} value={ele.name+"-"+ele.id} >{ele.name}</MenuItem>
                        )
                      })}
                    </Select>
                  </FormControl>

                  <Button variant="contained" sx={{background:"linear-gradient(90deg, #0F5DAE 0%, #009DCF 100.33%)",marginLeft:"40px",height:"45px"}}
                  onClick={()=>{
                    fetchTotalPermissionModel();
                  }}
                  >
                    <Box sx={{ textTransform: "capitalize" }}>View Permission</Box>
                </Button>
                </Stack>
             </Stack>

             <Stack direction="row" justifyContent="space-around" alignItems="center" sx={{width:"100%",backgroundColor:"white",borderRadius: "5px",margin:"0px 0px 10px 0px",padding:"10px 0px 10px 0px"}}>
              
                <Stack direction="row" justifyContent="center" alingItems="center" sx={{width:"14%",textAlign:"center",color: "#9299BC",fontWeight: "600"}}>
                        <Typography sx={{ display: "grid", placeItems: "center",fontWeight:"600",color:"black" }}>Module</Typography>
                </Stack>

                 <Stack direction="row" justifyContent="center" alingItems="center" sx={{width:"14%",textAlign:"center",color: "#9299BC",fontWeight: "600"}}>
                        <Typography sx={{ display: "grid", placeItems: "center",fontWeight:"600",color:"black" }}>Add</Typography>
                </Stack>

                <Stack direction="row" justifyContent="center" alingItems="center" sx={{width:"14%",textAlign:"center",color: "#9299BC",fontWeight: "600"}}>
                        <Typography sx={{ display: "grid", placeItems: "center",fontWeight:"600",color:"black" }}>Edit</Typography>
                </Stack>

                <Stack direction="row" justifyContent="center" alingItems="center" sx={{width:"14%",textAlign:"center",color: "#9299BC",fontWeight: "600"}}>
                        <Typography sx={{ display: "grid", placeItems: "center",fontWeight:"600",color:"black" }}>Delete</Typography>
                </Stack>


                 <Stack direction="row" justifyContent="center" alingItems="center" sx={{width:"14%",textAlign:"center",color: "#9299BC",fontWeight: "600"}}>
                        <Typography sx={{ display: "grid", placeItems: "center",fontWeight:"600",color:"black" }}>View</Typography>
                </Stack>

             </Stack>
             
             {loading==true?
             <Box sx={{display:"flex",height:"80vh",padding:"10% 5% 5% 45%"}}>
                <CircularProgress color="inherit" sx={{ color: 'grey.500' }}/>
            </Box> :
             <Stack sx={{overflowY:"scroll",height:"500px"}}>
              {rowData?.length==0?
              <Box sx={{textAlign:"center",marginTop:"50px"}}>
                <Typography sx={{fontSize:"25px",fontWeight:"500",color:"#B9B7D0"}}>No Matches Found</Typography>
              </Box>:
              <Box>
              {trigger && rowData && rowData.map((item,idx)=>{
                   return(
                    <>  
                    <Stack direction="row" justifyContent="space-around" alignItems="center" sx={{width:"100%",borderRadius: "5px",margin:"0px 0px 10px 0px",padding:"10px 0px 10px 0px",backgroundColor:"white",cursor:"pointer"}}>
                    <Stack direction="row" justifyContent="center" alingItems="center" sx={{width:"14%",textAlign:"center",color: "#9299BC",fontWeight: "600"}}>
                    <Typography >{item?.title}</Typography>                 
                    </Stack>
                    <Stack direction="row" justifyContent="center" alingItems="center" sx={{width:"14%",textAlign:"center",color: "#9299BC",fontWeight: "600"}}>
                    <input type="checkbox" checked={item.add.is_permission} onChange={()=>{
                        let temp = rowData;
                        temp[idx].add.is_permission = !item.add.is_permission
                        setRowData([...temp])
                        console.log(item.add.is_permission,item) 
                        assignPermission(item.add.id,item.add.is_permission);
                        }} ></input>                 
                    </Stack>
                    <Stack direction="row" justifyContent="center" alingItems="center" sx={{width:"14%",textAlign:"center",color: "#9299BC",fontWeight: "600"}}>
                    <input type="checkbox" checked={item.update.is_permission} onChange={()=>{
                        let temp = rowData;
                        temp[idx].update.is_permission = !item.update.is_permission
                        setRowData([...temp])
                        console.log(item.update.is_permission,item)
                        assignPermission(item.update.id,item.update.is_permission);
                        }}></input>                   
                    </Stack>
                    <Stack direction="row" justifyContent="center" alingItems="center" sx={{width:"14%",textAlign:"center",color: "#9299BC",fontWeight: "600"}}>
                    <input type="checkbox" checked={item.delete.is_permission} onChange={()=>{
                        let temp = rowData;
                        temp[idx].delete.is_permission = !item.delete.is_permission
                        setRowData([...temp])
                        console.log(item.delete.is_permission,item)
                        assignPermission(item.delete.id,item.delete.is_permission);
                        }}></input>                  
                    </Stack>
                    <Stack direction="row" justifyContent="center" alingItems="center" sx={{width:"14%",textAlign:"center",color: "#9299BC",fontWeight: "600"}}>
                    <input type="checkbox" checked={item.view.is_permission} onChange={()=>{
                        let temp = rowData;
                        temp[idx].view.is_permission = !item.view.is_permission
                        setRowData([...temp])
                        console.log(item.view.is_permission,item)
                        assignPermission(item.view.id,item.view.is_permission);
                        }}></input>                  
                    </Stack>
                    </Stack>
                    </> 
                   )
                })}
              </Box>
              }
            </Stack>
            }
            
            <Stack direction="row" sx={{marginTop:"50px",justifyContent:"",alignItems:"center"}}>
              <Typography sx={{width:"20%",fontFamily:'Poppins',fontStyle:"normal",fontWeight:"500",fontSize:"18px",lineHeight:"27px",marginLeft:"40px",cursor:"pointer"}}
                  onClick={()=>{navigate(-1)}}
                >Back</Typography>
                {trigger && <Button variant="contained" sx={{background:"linear-gradient(90deg, #0F5DAE 0%, #009DCF 100.33%)",marginLeft:"580px",width:"100px"}}>
                    <Box sx={{ textTransform: "capitalize" }}
                        onClick={()=>{navigate(-1)}}
                    >Save</Box>
                </Button>}
            </Stack>
       </Stack> 
      </Stack>
    </>
  )
}

export default BuilderRolePermissionEdit