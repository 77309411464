import * as React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import CircularProgress, {
  circularProgressClasses,
} from '@mui/material/CircularProgress';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';

const BorderLinearProgress = styled(LinearProgress)(({ theme, colr }) => ({
  height: 10,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 300 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: colr ? colr:'green',
    // theme.palette.mode === 'light' ? 'green' : '#308fe8',
  },
}));


export const LinearStatusBar = (props)=> {
  return (
    <Box sx={{ flexGrow: 1 }}>
      {/* <BorderLinearProgress  variant="determinate" value={10} /> */}
      
      <BorderLinearProgress  colr={props.color?props.color:"pink"} variant="determinate" value={props.value?props.value:0} />
    
    </Box>
  );
}

