import { Box, Container, Stack, Typography } from "@mui/material";
import CircleCheckIcon from "../../Imgs/circle-check-icon.svg";
import CommonLandingYourDataSecurityImg from "../../Imgs/common-landing-your-data-security-img.png";

const CommonLandingYourDataSecurity = () => {
  return (
    <Box sx={{ overflowX: "hidden" }}>
      <Container>
        <Box>
          <Typography
            data-aos="fade-right"
            sx={{
              fontSize: { xs: "24px", md: "32px", lg: "48px" },
              fontWeight: 700,
              color: "#272D4E",
              fontFamily: "Circular Std",
              textAlign: "center",
              lineHeight: "1.2",
              display: { xs: "none", md: "block" },
            }}
          >
            Your data’s security is our top priority
          </Typography>
          <Typography
            data-aos="fade-right"
            sx={{
              fontSize: { xs: "24px", md: "32px", lg: "48px" },
              fontWeight: 700,
              color: "#272D4E",
              fontFamily: "Circular Std",
              textAlign: "center",
              lineHeight: "1.2",
              display: { xs: "block", md: "none" },
            }}
          >
            Your data’s security is our <br /> top priority
          </Typography>
          <Typography
            data-aos="fade-up"
            sx={{
              fontSize: "18px",
              fontFamily: "Product Sans",
              color: "#343434",
              textAlign: "center",
              display: { xs: "none", md: "block" },
            }}
          >
            Going digital comes with some security risks. EnvisionNext gives you
            complete control over <br /> your valuable data and protects it with
            robust security measures.
          </Typography>
          <Typography
            data-aos="fade-up"
            sx={{
              fontSize: "18px",
              fontFamily: "Product Sans",
              color: "#343434",
              textAlign: "center",
              display: { xs: "block", md: "none" },
            }}
          >
            Going digital comes with some security risks. EnvisionNext gives you
            complete control over your valuable data and protects it with robust
            security measures.
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            gap: { xs: "10px", md: "30px" },
            alignItems: "center",
            flexDirection: { xs: "column", md: "row" },
            mt: "50px",
          }}
        >
          <Box data-aos="fade-left" sx={{ width: { xs: "100%", md: "45%" } }}>
            <img
              style={{ width: "100%" }}
              src={CommonLandingYourDataSecurityImg}
              alt=""
            />
          </Box>
          <Stack spacing={2} sx={{ width: { xs: "100%", md: "55%" } }}>
            <Typography
              data-aos="fade-right"
              sx={{
                fontSize: { xs: "24px", md: "32px", lg: "40px" },
                fontWeight: 700,
                color: "#343434",
                fontFamily: "Circular Std",
                lineHeight: "0.5",
              }}
            >
              EnvisionNext gives you
            </Typography>
            <Typography
              data-aos="fade-right"
              sx={{
                fontSize: "16px",
                fontFamily: "Product Sans",
                color: "#343434",
                lineHeight: "1.3",
              }}
            >
              complete control over your valuable data and protects it with
              robust security measures.
            </Typography>
            <Stack spacing={2}>
              <Box sx={{ display: "flex", gap: "10px" }}>
                <Box sx={{ lineHeight: "10px", fontSize: "30px" }}>
                  {/* <img src={CircleCheckIcon} alt="" /> */}
                  &#x2022;
                </Box>
                <Typography
                  sx={{
                    fontSize: "16px",
                    fontFamily: "Product Sans",
                    color: "#343434",
                    lineHeight: "1.3",
                  }}
                >
                  Role-level access to your information and dashboard
                </Typography>
              </Box>
              <Box sx={{ display: "flex", gap: "10px" }}>
                <Box sx={{ lineHeight: "10px", fontSize: "30px" }}>
                  {/* <img src={CircleCheckIcon} alt="" /> */}
                  &#x2022;
                </Box>
                <Typography
                  sx={{
                    fontSize: "16px",
                    fontFamily: "Product Sans",
                    color: "#343434",
                    lineHeight: "1.3",
                  }}
                >
                  Dual Data Security (DDS)
                </Typography>
              </Box>
              <Box sx={{ display: "flex", gap: "10px" }}>
                <Box sx={{ lineHeight: "10px", fontSize: "30px" }}>
                  {/* <img src={CircleCheckIcon} alt="" /> */}
                  &#x2022;
                </Box>
                <Typography
                  sx={{
                    fontSize: "16px",
                    fontFamily: "Product Sans",
                    color: "#343434",
                    lineHeight: "1.3",
                  }}
                >
                  Advanced Encryption Standard (AES)
                </Typography>
              </Box>
              <Box sx={{ display: "flex", gap: "10px" }}>
                <Box sx={{ lineHeight: "10px", fontSize: "30px" }}>
                  {/* <img src={CircleCheckIcon} alt="" /> */}
                  &#x2022;
                </Box>
                <Typography
                  sx={{
                    fontSize: "16px",
                    fontFamily: "Product Sans",
                    color: "#343434",
                    lineHeight: "1.3",
                  }}
                >
                  Controlled Data Access
                </Typography>
              </Box>
            </Stack>
          </Stack>
        </Box>
      </Container>
    </Box>
  );
};

export default CommonLandingYourDataSecurity;
