import {
  Box,
  Container,
  Grid,
  Paper,
  Stack,
  styled,
  Typography,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import BuildersDevelopers from "../../Imgs/builders-developers.svg";
import ChannelPartnerIconss from "../../Imgs/channel-partners-iconss.svg";
import HomeBuyersIcon from "../../Imgs/home-buyers-icon.svg";

const Item2 = styled(Paper)(({ theme }) => ({
  padding: "15px 25px",
  background: "#FFFFFF",
  border: "1px solid transparent",
  boxShadow:
    "0px 8px 22px -6px rgba(24, 39, 75, 0.12), 0px 14px 64px -4px rgba(24, 39, 75, 0.12)",
  borderRadius: "14px",
  "&:hover": {
    border: "1px solid #0085FF",
  },
}));

const CommonLandingAnIntegratedTechnologySolution = () => {
  const navigate = useNavigate();
  return (
    <Box sx={{ background: "#FFF", overflowX: "hidden" }}>
      <Container>
        <Stack spacing={2}>
          <Typography
            data-aos="fade-right"
            sx={{
              color: "#272D4E",
              fontSize: { xs: "28px", md2: "32px", lg: "38px" },
              fontWeight: 700,
              fontFamily: "Circular Std",
              textAlign: "center",
            }}
          >
            An integrated technology solution & a robust ecosystem
          </Typography>
          <Typography
            data-aos="fade-left"
            sx={{
              color: "#343434",
              fontSize: "20px",
              fontFamily: "Product Sans",
              textAlign: "center",
            }}
          >
            EnvisionNext is transforming the Real Estate experience for all
            stakeholders!
          </Typography>
          <Typography
            data-aos="fade-left"
            sx={{
              color: "#343434",
              fontSize: "18px",
              fontFamily: "Product Sans",
              textAlign: "center",
              display: { xs: "none", md: "block" },
            }}
          >
            For the first time, Channel Partners and Home Buyers will be an
            integral part of Builders’ <br /> business workflow, creating a
            cohesive and efficient digital ecosystem
          </Typography>
          <Typography
            data-aos="fade-left"
            sx={{
              color: "#343434",
              fontSize: "18px",
              fontFamily: "Product Sans",
              textAlign: "center",
              display: { xs: "block", md: "none" },
            }}
          >
            For the first time, Channel Partners and Home Buyers will be an
            integral part of Builders’ business workflow, creating a cohesive
            and efficient digital ecosystem
          </Typography>
        </Stack>
        <Box sx={{ flexGrow: 1, py: "50px" }}>
          <Grid
            container
            spacing={3}
            justifyContent="center"
            data-aos="fade-zoom-in"
            data-aos-easing="ease-in-back"
            data-aos-delay="200"
            data-aos-offset="0"
          >
            <Grid item xs={12} md={6} md2={4}>
              <Item2>
                <Box>
                  <img src={BuildersDevelopers} alt="" />
                </Box>

                <Box sx={{ mt: "10px" }}>
                  <Typography
                    sx={{
                      color: "#343434",
                      fontSize: "24px",
                      fontWeight: 600,
                      fontFamily: "Product Sans",
                    }}
                  >
                    Builders & Developers
                  </Typography>
                  <Typography
                    sx={{
                      color: "#343434",
                      fontSize: "18px",
                      fontFamily: "Product Sans",
                      mt: "15px",
                    }}
                  >
                    Expand your Real Estate business and build trust with your
                    customers and Channel Partners.
                  </Typography>
                  <Typography
                    sx={{
                      color: "#343434",
                      fontSize: "18px",
                      fontFamily: "Product Sans",
                      mt: "10px",
                    }}
                  >
                    Plan, execute, manage, track, and optimize all your
                    pre-sales and post-sales workflows.
                  </Typography>
                </Box>

                <Box sx={{ mt: "30px" }}>
                  <Typography
                    onClick={() => navigate("/builder-landing-page")}
                    sx={{
                      color: "#F75375",
                      cursor: "pointer",
                      "&:hover": {
                        color: "#0085FF",
                      },
                    }}
                  >
                    Explore More {">>"}
                  </Typography>
                </Box>
              </Item2>
            </Grid>
            <Grid
              item
              xs={12}
              md={6}
              md2={4}
              data-aos="fade-zoom-in"
              data-aos-easing="ease-in-back"
              data-aos-delay="500"
              data-aos-offset="0"
            >
              <Item2>
                <Box>
                  <img src={ChannelPartnerIconss} alt="" />
                </Box>

                <Box sx={{ mt: "10px" }}>
                  <Typography
                    sx={{
                      color: "#343434",
                      fontSize: "24px",
                      fontWeight: 600,
                      fontFamily: "Product Sans",
                    }}
                  >
                    Channel Partners
                  </Typography>
                  <Typography
                    sx={{
                      color: "#343434",
                      fontSize: "18px",
                      fontFamily: "Product Sans",
                      mt: "15px",
                    }}
                  >
                    Collaborate hassle-free with developers and grow your
                    commissions.
                  </Typography>
                  <Typography
                    sx={{
                      color: "#343434",
                      fontSize: "18px",
                      fontFamily: "Product Sans",
                      mt: "10px",
                    }}
                  >
                    Access project details, live inventory status, real-time
                    negotiations, and everything you need to crack the deals.
                  </Typography>
                </Box>

                <Box sx={{ mt: "30px" }}>
                  <Typography
                    onClick={() => navigate("/channel-landing-page")}
                    sx={{
                      color: "#F75375",
                      cursor: "pointer",
                      "&:hover": {
                        color: "#0085FF",
                      },
                    }}
                  >
                    Explore More {">>"}
                  </Typography>
                </Box>
              </Item2>
            </Grid>
            <Grid
              item
              xs={12}
              md={6}
              md2={4}
              data-aos="fade-zoom-in"
              data-aos-easing="ease-in-back"
              data-aos-delay="800"
              data-aos-offset="0"
            >
              <Item2>
                <Box>
                  <img src={HomeBuyersIcon} alt="" />
                </Box>

                <Box sx={{ mt: "10px" }}>
                  <Typography
                    sx={{
                      color: "#343434",
                      fontSize: "24px",
                      fontWeight: 600,
                      fontFamily: "Product Sans",
                    }}
                  >
                    Home Buyers
                  </Typography>
                  <Typography
                    sx={{
                      color: "#343434",
                      fontSize: "18px",
                      fontFamily: "Product Sans",
                      mt: "15px",
                    }}
                  >
                    Get complete visibility on the progress of your house,
                    transactions, paperwork, and more from one place.
                  </Typography>
                  <Typography
                    sx={{
                      color: "#343434",
                      fontSize: "18px",
                      fontFamily: "Product Sans",
                      mt: "10px",
                    }}
                  >
                    Communicating with the Builder was never this easy!
                  </Typography>
                </Box>

                <Box sx={{ mt: "55px" }}>
                  <Typography
                    onClick={() => navigate("/buyer-landing-page")}
                    sx={{
                      color: "#F75375",
                      cursor: "pointer",
                      "&:hover": {
                        color: "#0085FF",
                      },
                    }}
                  >
                    Explore More {">>"}
                  </Typography>
                </Box>
              </Item2>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </Box>
  );
};

export default CommonLandingAnIntegratedTechnologySolution;
