import { AuthService } from "../auth";
import { ProjectResponse } from "./types/types";


class ProjectsFetchingService{
    Auth:AuthService;
    
    constructor(e:AuthService){
        this.Auth =e;
    }

    
    
    async getBuyerProjects(){
        const re = await this.Auth.makeAuthenticatedGetRequest(
            `${this.Auth.ServerUrl}/api/buyer/getbuyerprojects/`)
         return  (await re.json())['projects'];
        
    }

    async getProjectBuyId(id:number){
       const res = await this.Auth.makeUnAuthenticatedGetRequest("http://139.59.70.194/api/projects/project/?project_id="+id)
        const data:ProjectResponse.RootObject = await res.json()
        console.log('====================================');
        console.log(data);
        console.log('====================================');
        return data;
    }   



}
export {ProjectsFetchingService};