import {
  Box,
  Checkbox,
  Container,
  Divider,
  FormControl,
  FormControlLabel,
  styled,
  Typography,
  useRadioGroup,
} from "@mui/material";

import { flexBetween, itemsCenter } from "./styles/commonStyles";
import classes from "./styles/PageOne.module.css";
import img from "../../Imgs/builder.jpg";
import { useState } from "react";


const PdfOnePageTwo = ({fileInfo}) => {

  const [pageData2,setPageData2]=useState({
    first_name:"",
    middle_name:"",
    last_name:"",
    date:"",
    anni_date:"",
    address:"",
    post_code:"",
    state:"",
    country:"",
    mail_Address:"",
    mail_post_code:"",
    mail_state:"",
    mail_country:"",
    mobile:"",
    email:"",
    landline:"",
    pan_no:"",
    nation:"",
    preff_address:"",
    res_status:"",
    occupation:"",
    org_name:"",
    designation:"",
    org_address:"",
    org_post_code:"",
    org_state:"",
    org_nation:"",
    org_phone:"",
    org_detail:"",
    sign_first:"",
    sign_second:"",
    sign_third:""
  })

  // console.log(pageData2);

  let name,value;
  const handleChange=(e)=>{
    name=e.target.name;
    value=e.target.value;
    console.log(name," ",value);
    setPageData2({...pageData2,[name]:value});
  }

  return (
    <Box>
      <Box sx={{ mb: "40px" }}>
        <Typography sx={{ textAlign: "center", }}>APPLICATION FORM</Typography>
        <Box sx={{ ...flexBetween, mt: "10px" }}>
          <Box sx={{ width: "80%" }}>
            <Box sx={{ ...itemsCenter, gap: "20px" }}>
              <Box className={classes.input}>
                <label htmlFor="">Ref. No.</label>
                <input value={""} type="number" />
              </Box>
              <Box className={classes.input}>
                <label htmlFor="">Booking date:</label>
                <input value={fileInfo.created?.substring(0,10)} type="text" />
              </Box>
            </Box>
            <Box sx={{ display: "flex", gap: "10px", mt: "10px", ml: "20px" }}>
              <Box>
                <Typography sx={{fontSize:"11px"}}>1.</Typography>
              </Box>
              <Box>
                <Typography sx={{ fontSize: "10px" }}>
                  DETAILS OF SECOND APPLICANT
                </Typography>
                <Typography sx={{ fontSize: "10px" }}>
                  Please provide the following information to enable us to
                  process your application. Fields marked * are mandatory.{" "}
                </Typography>
              </Box>
            </Box>
          </Box>
          <Box sx={{}}>
            {/* <input type="image" src={img}/> */}
            <img
              src={img}
              alt="User Image"
              style={{ width: "100px", height: "100px" }}
            />
          </Box>
        </Box>
        <Box className={classes.input} sx={{ display: "flex" }}>
          <label htmlFor="" style={{ fontSize: "14px" }}>
            *Name of Applicant Mr/Mrs/Ms:
          </label>
          <Box sx={{ display: "inline-block" }}>
            <input
              style={{ margin: "0px", textAlign: "center" }}
              value={pageData2.first_name}
              type="text"
              name="first_name" 
              onChange={handleChange}
            />
            <Typography sx={{ textAlign: "center", fontSize: "10px" }}>
              (First name)
            </Typography>
          </Box>
          <Box sx={{ display: "inline-block" }}>
            <input
              style={{ margin: "0px", textAlign: "center" }}
              value={pageData2.middle_name}
              type="text"
              name="middle_name" 
              onChange={handleChange}
            />
            <Typography sx={{ textAlign: "center", fontSize: "10px" }}>
              (Middle name)
            </Typography>
          </Box>
          <Box sx={{ display: "inline-block" }}>
            <input
              style={{ margin: "0px", textAlign: "center" }}
              value={pageData2.last_name}
              type="text"
              name="last_name" 
              onChange={handleChange}
            />
            <Typography sx={{ textAlign: "center", fontSize: "10px" }}>
              (Last name)
            </Typography>
          </Box>
        </Box>
        <Box sx={{ display: "flex", gap: "30px" }}>
          <Box className={classes.input} sx={{ display: "flex" }}>
            <label htmlFor="" style={{ fontSize: "14px" }}>
              DOB:
            </label>
            <Box sx={{ display: "inline-block" }}>
              <input
                style={{ margin: "0px", textAlign: "center" }}
                value={pageData2.date}
                type="date"
                name="date" 
                onChange={handleChange}
              />
              <Typography sx={{ textAlign: "center", fontSize: "10px" }}>
                (dd/mm/yy)
              </Typography>
            </Box>
          </Box>
          <Box className={classes.input} sx={{ display: "flex" }}>
            <label htmlFor="" style={{ fontSize: "14px" }}>
              Anniversary date:
            </label>
            <Box sx={{ display: "inline-block" }}>
              <input
                style={{ margin: "0px", textAlign: "center" }}
                value={pageData2.anni_date}
                type="date"
                name="anni_date" 
                onChange={handleChange}
              />
              <Typography sx={{ textAlign: "center", fontSize: "10px" }}>
                (dd/mm/yy)
              </Typography>
            </Box>
          </Box>
        </Box>

        <Box>
          <Box className={classes.input}>
            <label htmlFor="" style={{ fontSize: "14px" }}>
              *Permanent Address of Second Applicant:
            </label>
            <input
              style={{ width: "60%", margin: "0px", textAlign: "center" }}
              value={pageData2.address}
              type="text"
              name="address"
              onChange={handleChange}
            />
          </Box>

          <Divider
            sx={{ backgroundColor: "#000", height: "3px", mt: "10px" }}
          />

          <Box sx={{ ...itemsCenter, gap: "10px" }}>
            <Box className={classes.input}>
              <label htmlFor="">*Post Code:</label>
              <input style={{ width: "150px" }} value={pageData2.post_code} name="post_code" type="number" onChange={handleChange}/>
            </Box>
            <Box className={classes.input}>
              <label htmlFor="">*State: </label>
              <input style={{ width: "150px" }} value={pageData2.state} name="state" type="text" onChange={handleChange}/>
            </Box>
            <Box className={classes.input}>
              <label htmlFor="">*Country:</label>
              <input style={{ width: "150px" }} value={pageData2.country} name="country" type="text" onChange={handleChange}/>
            </Box>
          </Box>
          <Box className={classes.input}>
            <label htmlFor="" style={{ fontSize: "14px" }}>
              *Mailing Address of Second Applicant:
            </label>
            <input
              style={{ width: "60%", margin: "0px", textAlign: "center" }}
              value={pageData2.mail_Address}
              type="text"
              name="mail_Address"
              onChange={handleChange}
            />
          </Box>
          <Box sx={{ ...itemsCenter, gap: "10px" }}>
            <Box className={classes.input}>
              <label htmlFor="">*Post Code:</label>
              <input style={{ width: "150px" }} value={pageData2.mail_post_code} name="mail_post_code" type="number" onChange={handleChange}/>
            </Box>
            <Box className={classes.input}>
              <label htmlFor="">*State: </label>
              <input style={{ width: "150px" }} value={pageData2.mail_state} name="mail_state" type="text" onChange={handleChange}/>
            </Box>
            <Box className={classes.input}>
              <label htmlFor="">*Country:</label>
              <input style={{ width: "150px" }} value={pageData2.mail_country} name="mail_country" type="text" onChange={handleChange}/>
            </Box>
          </Box>

          <form>
            <Box sx={{ display: "flex", gap: "20px" }}>
              <Typography sx={{ fontSize: "14px" }}>
                *Preferred address for all correspondence:
              </Typography>
              <Box className={classes.formGroup}>
                <input type="checkbox" id="html2" name="preff_address" value="permanent" checked={pageData2.preff_address=="permanent"} onChange={handleChange}/>
                <label for="html2">Permanent</label>
              </Box>
              <Box className={classes.formGroup}>
                <input type="checkbox" id="css2" name="preff_address" value="mailing" checked={pageData2.preff_address=="mailing"} onChange={handleChange}/>
                <label for="css2">Mailing</label>
              </Box>
            </Box>
          </form>
          <form>
            <Box sx={{ display: "flex", gap: "10px" }}>
              <Typography sx={{ fontSize: "14px" }}>
                *Residential Status:
              </Typography>
              <Box className={classes.formGroup}>
                <input type="checkbox" id="residential2" name="res_status" value="resident" checked={pageData2.res_status=="resident"} onChange={handleChange}/>
                <label for="residential2">Resident</label>
              </Box>
              <Box className={classes.formGroup}>
                <input type="checkbox" id="non-residential2" name="res_status" value="non-resident" checked={pageData2.res_status=="non-resident"} onChange={handleChange}/>
                <label for="non-residential2">Non-Resident Indian</label>
              </Box>
              <Box className={classes.formGroup}>
                <input type="checkbox" id="foreign-residential2" name="res_status" value="foreign-resident" checked={pageData2.res_status=="foreign-resident"} onChange={handleChange}/>
                <label for="foreign-residential2">Foreign National of Indian Origin</label>
              </Box>
            </Box>
          </form>

          <Box sx={{ ...itemsCenter, gap: "10px" }}>
            <Box className={classes.input}>
              <label htmlFor="">*#Mobile No.:</label>
              <input style={{ width: "150px" }} value={pageData2.mobile} type="number" name="mobile" onChange={handleChange}/>
            </Box>
            <Box className={classes.input}>
              <label htmlFor="">*#Email Address:</label>
              <input style={{ width: "150px" }} value={pageData2.email} type="text" name="email" onChange={handleChange}/>
            </Box>
          </Box>

          <Box sx={{ ...itemsCenter, gap: "10px" }}>
            <Box className={classes.input}>
              <label htmlFor="">*#Landline No.:</label>
              <input style={{ width: "150px" }} value={pageData2.landline} type="number" name='landline' onChange={handleChange}/>
            </Box>
            <Box className={classes.input}>
              <label htmlFor="">*Pan No.:</label>
              <input style={{ width: "150px" }} value={pageData2.pan_no} type="number" name="pan_no" onChange={handleChange}/>
            </Box>
            <Box className={classes.input}>
              <label htmlFor="">*Nationality:</label>
              <input style={{ width: "150px" }} value={pageData2.nation} type="text" name="nation" onChange={handleChange}/>
            </Box>
          </Box>
          <form>
            <Box sx={{ display: "flex", gap: "10px" }}>
              <Typography sx={{ fontSize: "14px" }}>*Occupation:</Typography>
              <Box className={classes.formGroup}>
                <input type="checkbox" id="self-employed2" name="occupation" value="self-employed" checked={pageData2.occupation=="self-employed"} onChange={handleChange}/>
                <label for="self-employed2">Self-Employed</label>
              </Box>
              <Box className={classes.formGroup}>
                <input type="checkbox" id="salaried-executive2" name="occupation" value="salaried-executive" checked={pageData2.occupation=="salaried-executive"} onChange={handleChange}/>
                <label for="salaried-executive2">Salaried Executive</label>
              </Box>
              <Box className={classes.formGroup}>
                <input type="checkbox" id="investor2" name="occupation" value="investor" checked={pageData2.occupation=="investor"} onChange={handleChange}/>
                <label for="investor2">Investor</label>
              </Box>
            </Box>
          </form>

          <Box sx={{ ...itemsCenter, gap: "10px" }}>
            <Box className={classes.input}>
              <label htmlFor="">Organisation Name:</label>
              <input style={{ width: "150px" }} value={pageData2.org_name} name="org_name" type="text" onChange={handleChange}/>
            </Box>
            <Box className={classes.input}>
              <label htmlFor=""> Designation: </label>
              <input style={{ width: "150px" }} value={pageData2.designation} name="designation" type="text" onChange={handleChange}/>
            </Box>
          </Box>
          <Box className={classes.input}>
            <label htmlFor="">Organisation Address: </label>
            <input style={{ width: "150px" }} value={pageData2.org_address} name="org_address" type="text" onChange={handleChange}/>
          </Box>
          <Box sx={{ ...itemsCenter, gap: "10px" }}>
            <Box className={classes.input}>
              <label htmlFor="">*Post Code:</label>
              <input style={{ width: "150px" }} value={pageData2.org_post_code} name="org_post_code" type="number" onChange={handleChange}/>
            </Box>
            <Box className={classes.input}>
              <label htmlFor="">*State: </label>
              <input style={{ width: "150px" }} value={pageData2.org_state} name="org_state" type="text" onChange={handleChange}/>
            </Box>
            <Box className={classes.input}>
              <label htmlFor="">*Country:</label>
              <input style={{ width: "150px" }} value={pageData2.org_nation} name="org_nation" type="text" onChange={handleChange}/>
            </Box>
          </Box>
          <Box className={classes.input}>
            <label htmlFor="">Organisation Phone Numbers: </label>
            <input style={{ width: "150px" }} value={pageData2.org_phone} name="org_phone" type="number" onChange={handleChange}/>
          </Box>
          <Box className={classes.input}>
            <label htmlFor="">Other Details/Remarks:</label>
            <input style={{ width: "150px" }} value={pageData2.org_detail} name="org_detail" type="text" onChange={handleChange}/>
          </Box>

          <Box>
            <Typography sx={{ fontSize: "14px" }}>FOR INDIVIDUAL</Typography>

            <form>
              <Box className={classes.formGroup}>
                <input type="checkbox" id="Pan Card2" />
                <label for="Pan Card2">
                  Self attested copy of Pan Card/Form 16.
                </label>
              </Box>
              <Box className={classes.formGroup}>
                <input type="checkbox" id="Driving2" />
                <label for="Driving2">
                  Self attested copy of Address Proof: Driving
                  License/Passport/Electricity Bill/Aadhar card/Voter’s ID Card.
                </label>
              </Box>
              <Box className={classes.formGroup}>
                <input type="checkbox" id="Passport2" />
                <label for="Passport2">
                  Two Passport Size Colour Photograph for each Applicant. Two
                  Passport Size Colour Photograph for each Applicant.
                </label>
              </Box>
            </form>
          </Box>
          <Box>
            <Typography sx={{ fontSize: "14px" }}>
              FOR FOREIGN NATIONALS OF INDIAN ORIGIN
            </Typography>

            <form>
              <Box className={classes.formGroup}>
                <input type="checkbox" id=" Self attested copy of Passport2" />
                <label for=" Self attested copy of Passport2">
                  Self attested copy of Passport- front and back.
                </label>
              </Box>
              <Box className={classes.formGroup}>
                <input type="checkbox" id="Proof of payment2" />
                <label for="Proof of payment2">
                  Proof of payment of funds from NRE/FCNR A/c. Proof of payment
                  of funds from NRE/FCNR A/c.
                </label>
              </Box>
              <Box className={classes.formGroup}>
                <input type="checkbox" id="Self attested copy of PIO2" />
                <label for="Self attested copy of PIO2">
                  Self attested copy of PIO(Person of Indian Origin)/ OCI
                  (Overseas Citizen of India) Card.
                </label>
              </Box>
              <Box className={classes.formGroup}>
                <input type="checkbox" id="Two Passport Size Colour2" />
                <label for="Two Passport Size Colour2">
                  Two Passport Size Colour Photograph for each Applicant.
                </label>
              </Box>
            </form>
          </Box>
          <Box>
            <Typography sx={{ fontSize: "14px" }}>FOR NRI</Typography>

            <form>
              <Box className={classes.formGroup}>
                <input type="checkbox" id="nri- Self attested copy of passport2" />
                <label for="nri- Self attested copy of passport2">
                  Self attested copy of passport- front and back.
                </label>
              </Box>
              <Box className={classes.formGroup}>
                <input type="checkbox" id="nri-roof of Payment of funds from NRE2" />
                <label for="nri-roof of Payment of funds from NRE2">
                  Proof of Payment of funds from NRE/NRO A/c. attested by bank.
                </label>
              </Box>
              <Box className={classes.formGroup}>
                <input type="checkbox" id="nri-Self attested copy of Employment2" />
                <label for="nri-Self attested copy of Employment2">
                  Self attested copy of Employment Card issued by the Employer.
                </label>
              </Box>
              <Box className={classes.formGroup}>
                <input type="checkbox" id="nri- Two Passport size colour photograph for each Applicants.2" />
                <label for="nri- Two Passport size colour photograph for each Applicants.2">
                  Two Passport size colour photograph for each Applicants.
                </label>
              </Box>
            </form>
          </Box>
        </Box>
      </Box>

      <Box
        sx={{
          display: "flex",
          gap: "40px",
          justifyContent: "center",
          position: "absolute",
          bottom: "30px",
        }}
      >
        <Box className={classes.input}>
          <Box>
            <input
              style={{ textAlign: "center" }}
              value={pageData2.sign_first}
              type="text"
              name="sign_first"
              onChange={handleChange}
            />
            <Typography sx={{ textAlign: "center", mt: "10px" }}>
              Signature of First Applicant
            </Typography>
          </Box>
        </Box>
        <Box className={classes.input}>
          <Box sx={{ display: "inline-block" }}>
            <input
              style={{ margin: "0px", textAlign: "center" }}
              value={pageData2.sign_second}
              type="text"
              name="sign_second"
              onChange={handleChange}
            />
            <Typography sx={{ textAlign: "center", mt: "10px" }}>
              Signature of Second Applicant
            </Typography>
          </Box>
        </Box>
        <Box className={classes.input}>
          <Box sx={{ display: "inline-block" }}>
            <input
              style={{ margin: "0px", textAlign: "center" }}
              value={pageData2.sign_third}
              type="text"
              name="sign_third"
              onChange={handleChange}
            />
            <Typography sx={{ textAlign: "center", mt: "10px" }}>
              Signature of Third Applicant
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default PdfOnePageTwo;
