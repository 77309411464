import { Box, Button, Typography } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import ReactToPrint from "react-to-print";
import PdfOnePageEight from "./PdfOnePageEight";
import PdfOnePageEighteen from "./PdfOnePageEighteen";
import PdfOnePageEleven from "./PdfOnePageEleven";
import PdfOnePageFifteen from "./PdfOnePageFifteen";
import PdfOnePageFive from "./PdfOnePageFive";
import PdfOnePageFour from "./PdfOnePageFour";
import PdfOnePageFourteen from "./PdfOnePageFourteen";
import PdfOnePageNine from "./PdfOnePageNine";
import PdfOnePageOne from "./PdfOnePageOne";
import PdfOnePageSeven from "./PdfOnePageSeven";
import PdfOnePageSeventeen from "./PdfOnePageSeventeen";
import PdfOnePageSix from "./PdfOnePageSix";
import PdfOnePageSixteen from "./PdfOnePageSixteen";
import PdfOnePageTen from "./PdfOnePageTen";
import PdfOnePageThirteen from "./PdfOnePageThirteen";
import PdfOnePageThree from "./PdfOnePageThree";
import PdfOnePageTwelve from "./PdfOnePageTwelve";
import PdfOnePageTwo from "./PdfOnePageTwo";
import axios from "axios";
import { Apiaddress } from "../../../utils/consts";


const PdfOneMain = () => {

  const ref = useRef(); 
  
  //get data from previous page 
  const location = useLocation();
  const data = location.state;
  console.log("preve data =>.>",data);

  let id=data?.project_id;
  let file_id=data?.file_id;
  let unit_id=data?.unit_id;
  let lead_id=data?.lead_id;

  let token=localStorage.getItem("crm_token");
  
  const [fileInfo,setFileInfo]= useState({});
  const [unitInfo,setUnitInfo]=useState({});
  const [projectInfo,setProjectInfo]= useState({});

  //function for fetching Quotation Information
  const fetchfileInfo= async ()=>{
     try {
        const headers = {
        Authorization: `JWT ${token}`,
        };
        const res = await axios.get(
          Apiaddress + `/api/leads/buyerfile/?buyer_file_id=${file_id}`,
          { headers: headers }
        );
      console.log("===========================.>",res?.data?.buyer_file);
      let fileInfo=res?.data?.buyer_file;
      let unitId=fileInfo?.unit;
      let obj={
        created:fileInfo?.created_on,
        valid:fileInfo?.valid_till,
        unit_no:fileInfo?.unit,
        lead:fileInfo?.lead,
      }
      setFileInfo(obj);

      const res1 = await axios.get(
          Apiaddress + `/api/projects/unit/?unit_id=${unitId}`
        );
      console.log("===========================.>",res1?.data?.unit[0]);
      let unitInfo=res1?.data?.unit[0];
      let obj1={
        single_data:unitInfo?.layout_data?.singleData,
        double_data:unitInfo?.layout_data?.doubleData,
        bedroom:unitInfo?.layout_data?.bedroomData,
        bathroom:unitInfo?.layout_data?.bathroomData,
        balcony:unitInfo?.layout_data?.balconyData,
        unit_no:unitInfo?.id,
        charge:unitInfo?.charges_data,
        floor_no:unitInfo?.floor?.id,
      }
      setUnitInfo(obj1);

       const res2 = await axios.get(
          Apiaddress + `/api/projects/project/?project_id=${id}`
        );
      console.log("===========================.>",res2?.data?.projects[0]);
      let projectInfo=res2?.data?.projects[0];
      let obj2={
        address:projectInfo?.address_line,
        city:projectInfo?.city,
        state:projectInfo?.state,
        pincode:projectInfo?.pin_code,
        project_name:projectInfo?.name,
        rera_no:projectInfo?.rera_number,
        builder_name:projectInfo?.builder?.name
      }
      setProjectInfo(obj2);

    }
    catch(err){
      console.log(err.message);
    }
  }

  useEffect(()=>{
    fetchfileInfo();
  },[])

  const pages = [
    <PdfOnePageOne  fileInfo={fileInfo}/>,
    <PdfOnePageTwo fileInfo={fileInfo}/>,
    <PdfOnePageThree fileInfo={fileInfo}/>,
    <PdfOnePageFour fileInfo={fileInfo}/>,
    <PdfOnePageFive unitInfo={unitInfo} projectInfo={projectInfo}/>,
    <PdfOnePageSix />,
    <PdfOnePageSeven />,
    <PdfOnePageEight />,
    <PdfOnePageNine />,
    <PdfOnePageTen />,
    <PdfOnePageEleven />,
    <PdfOnePageTwelve />,
    <PdfOnePageThirteen />,
    <PdfOnePageFourteen />,
    <PdfOnePageFifteen />,
    <PdfOnePageSixteen />,
    <PdfOnePageSeventeen />,
    <PdfOnePageEighteen fileInfo={fileInfo} unitInfo={unitInfo}/>,
  ];
  return (
    <Box>
      <Box ref={ref}>
        {pages.map((page, index) => (
          <Box
            sx={{
              pageBreakAfter: "always",
              padding: "50px",
              position: "relative",
              minHeight: "85vh",
            }}
          >
            <Box>{page}</Box>
            <Typography
              sx={{
                position: "absolute",
                bottom: 0,
                left: "48%",
                fontWeight: 600,
                color: "#696969",
              }}
            >{`${index + 1}${"/"}${pages.length}`}</Typography>
          </Box>
        ))}
      </Box>

      <ReactToPrint
        trigger={() => <Box sx={{textAlign:"right",margin:"30px 50px"}}><Button variant="contained" sx={{background:"#0F5DAE",border:"1px solid #EFF0F7",boxShadow:"0px 2px 6px rgba(19, 18, 66, 0.07)",borderRadius:"5px",border:'1px solid #0F5DAE',marginLeft:"25px"}}>
          <Box sx={{ textTransform: "capitalize",margin:"0px 10px"}}>Download / Print</Box>
          </Button></Box>}
        content={() => ref.current}
      />
    </Box>
  );
};

export default PdfOneMain;
