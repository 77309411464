import { Button, Checkbox, Stack, TextField, Typography } from "@mui/material"
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { SAProjectSidebar } from "../SidebarNavbar/SAProjectSidebar";
import { SuperAdminNavbar } from "../SidebarNavbar/SuperAdminNavbar"
import { SuperAdminSidebar } from "../SidebarNavbar/SuperAdminSidebar"



export const CSVconfigurationSA = ()=>{

  const navigate = useNavigate();
  const [config, setConfig] = useState({
    maxBedroom: 0,
    maxBathroom: 0,
    maxBalcony: 0,
    hasWing: true,
  });

  const handleInput = (e) => {
    const { name, value } = e.target;
    setConfig((prev) => {
      return {
        ...prev,
        [name]: value,
      };
    });
  };

    return (
        <>
             
<Stack direction="row" sx={{width:"100%",height:"auto" }}>
      {/* 1st stack */}
      <Stack sx={{minHeight:"100vh",width:"20%",boxSizing:"border-box",}}> {/*for project directory 20% and for sales dashboard 225*/}
          {/* <SuperAdminSidebar active={"Builder Directory"}/> */}
          <SAProjectSidebar active={"Project Dashboard"}/>
      </Stack>

     {/* 2nd stack */}
     <Stack direction="column" sx={{ backgroundColor:"#f9f9f9",width:"80%", padding:"25px",boxSizing:"border-box",}}  >  {/*  width for main sidebar 78px and for 80px*/}
        <SuperAdminNavbar />  {/*send props for get popup to verify otp from builder */}
      


          <Typography sx={{marginTop:"20px", fontSize:"28px", fontWeight:"600", marginLeft:"10px", marginBottom:"50px"}}>
            Create Project 
          </Typography>
          {/* actual content  */}
          <Stack sx={{backgroundColor:"#f9f9f9"}}>  {/* mt:"28px", */}

             <Stack
            direction="column"
            sx={{ padding: "20px", backgroundColor: "#fff", marginRight: "0px" }}
          >
            <Stack
              direction="column"
              justifyContent="center"
              alignItems="flex-start"
              sx={{ minHeight: "50vh" }}
            >
              <Typography variant="h4">Configuration</Typography>
              <Stack margin="1em" direction="row" alignItems={"center"} spacing={"10px"}>
                <Typography width={"15em"}>Max Bedroom of a unit</Typography>
                <TextField
                  name="maxBedroom"
                  type={"number"}
                  value={config.maxBedroom}
                  onChange={handleInput}
                  InputProps={{ inputProps: { min: 0 } }}
                />
              </Stack>
              <Stack margin="1em" direction="row" alignItems={"center"} spacing={"10px"}>
                <Typography width={"15em"}>Max Bathroom of a unit</Typography>
                <TextField
                  name="maxBathroom"
                  type={"number"}
                  value={config.maxBathroom}
                  onChange={handleInput}
                  InputProps={{ inputProps: { min: 0 } }}
                />
              </Stack>
              <Stack margin="1em" direction="row" alignItems={"center"} spacing={"10px"}>
                <Typography width={"15em"}>Max Balcony of a unit</Typography>
                <TextField
                  name="maxBalcony"
                  type={"number"}
                  value={config.maxBalcony}
                  onChange={handleInput}
                  InputProps={{ inputProps: { min: 0 } }}
                />
              </Stack>
              <Stack margin="1em" direction="row" alignItems={"center"} spacing={"10px"}>
                <Typography width={"15em"}>Building has Wings</Typography>
                <Checkbox
                  inputProps={{ "aria-label": "Has Wing" }}
                  checked={config.hasWing}
                  onChange={(e) => setConfig((prev) => ({ ...prev, hasWing: e.target.checked }))}
                  size="medium"
                />
              </Stack>
              <Stack marginTop="auto" direction="row" alignItems={"center"} spacing={"10px"}>
                <Button
                  onClick={() => {
                    navigate("/super-admin-create-project-csvupload", {
                      state: config,
                    });
                  }}
                  className="univercel_btn_class"
                  sx={{ padding: "16px 40px 16px 40px", color: "#fff" }}
                >
                  Submit  
                </Button>
              </Stack>
            </Stack> 
          </Stack>

    </Stack>
  </Stack>
</Stack>
        </>
    )
}