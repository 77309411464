import styled from "@emotion/styled";
import { Box, Container, Grid, Paper, Typography } from "@mui/material";
import IncreasedEfficiencyIcons from "../../Imgs/increased-efficiency-icons.svg";
import StrengthenedCollaborationIcon from "../../Imgs/strengthened-collaboration-icon.svg";
import CompetitiveEdgeIcon from "../../Imgs/competitive-edge-icon.svg";
import ImprovedCommunicationIcon from "../../Imgs/improved-communication-icon.svg";
import EnhancedBrandIcon from "../../Imgs/enhanced-brand-icon.svg";
import NewAvenuesIcon from "../../Imgs/new-avenues-icon.svg";

const Item2 = styled(Paper)(({ theme }) => ({
  padding: "15px 10px",
  background: "#FFFFFF",
  boxShadow:
    "0px 8px 22px -6px rgba(24, 39, 75, 0.12), 0px 14px 64px -4px rgba(24, 39, 75, 0.12)",
  borderRadius: "14px",
}));

const ChannelLandingBecomingPartOfEnvisionNextPowersUp = () => {
  return (
    <Box sx={{ overflowX: "hidden" }}>
      <Container>
        <Typography
          data-aos="fade-down"
          sx={{
            color: "#000",
            fontSize: { xs: "28px", md2: "36px", lg: "40px" },
            fontWeight: 700,
            fontFamily: "Product Sans",
            textAlign: "center",
            lineHeight: "1.2",
          }}
        >
          Becoming a part of EnvisionNext powers <br /> up your venture with
          multi-faceted benefits
        </Typography>

        <Box sx={{ flexGrow: 1, py: "50px" }}>
          <Grid container spacing={3} justifyContent="center">
            <Grid item xs={12} md={4} md2={3} data-aos="fade-right">
              <Item2 sx={{ border: "1px solid #0890FE", pb: "40px" }}>
                <Box>
                  <img src={IncreasedEfficiencyIcons} alt="" />
                </Box>

                <Box sx={{ mt: "10px" }}>
                  <Typography
                    sx={{
                      color: "#000",
                      fontSize: { xs: "18px" },
                      fontWeight: 600,
                      fontFamily: "Product Sans",
                      pb: "25px",
                    }}
                  >
                    Increased efficiency <br />
                    across your workflows
                  </Typography>
                </Box>
              </Item2>
            </Grid>
            <Grid item xs={12} md={4} md2={3} data-aos="fade-right">
              <Item2 sx={{ border: "1px solid #FF4267" }}>
                <Box>
                  <img src={StrengthenedCollaborationIcon} alt="" />
                </Box>

                <Box sx={{ mt: "10px" }}>
                  <Typography
                    sx={{
                      color: "#000",
                      fontSize: { xs: "18px" },
                      fontWeight: 600,
                      fontFamily: "Product Sans",
                    }}
                  >
                    Strengthened collaboration with Real Estate Builders and
                    Developers
                  </Typography>
                </Box>
              </Item2>
            </Grid>
            <Grid item xs={12} md={4} md2={3} data-aos="fade-left">
              <Item2 sx={{ border: "1px solid #FFAF2A" }}>
                <Box>
                  <img src={CompetitiveEdgeIcon} alt="" />
                </Box>

                <Box sx={{ mt: "10px" }}>
                  <Typography
                    sx={{
                      color: "#000",
                      fontSize: { xs: "18px" },
                      fontWeight: 600,
                      fontFamily: "Product Sans",
                    }}
                  >
                    Competitive edge over <br />
                    other Channel Partners and Brokers
                  </Typography>
                </Box>
              </Item2>
            </Grid>
            <Grid item xs={12} md={4} md2={3} data-aos="fade-left">
              <Item2 sx={{ border: "1px solid #3629B7" }}>
                <Box>
                  <img src={ImprovedCommunicationIcon} alt="" />
                </Box>

                <Box sx={{ mt: "10px", pb: "25px" }}>
                  <Typography
                    sx={{
                      color: "#000",
                      fontSize: { xs: "18px" },
                      fontWeight: 600,
                      fontFamily: "Product Sans",
                    }}
                  >
                    Improved communication
                    <br /> with leads
                  </Typography>
                </Box>
              </Item2>
            </Grid>
            <Grid item xs={12} md={4} md2={3} data-aos="fade-right">
              <Item2 sx={{ border: "1px solid #0890FE", pb: "40px" }}>
                <Box>
                  <img src={EnhancedBrandIcon} alt="" />
                </Box>

                <Box sx={{ mt: "10px" }}>
                  <Typography
                    sx={{
                      color: "#000",
                      fontSize: { xs: "18px" },
                      fontWeight: 600,
                      fontFamily: "Product Sans",
                      pb: "25px",
                    }}
                  >
                    Enhanced brand value
                  </Typography>
                </Box>
              </Item2>
            </Grid>
            <Grid item xs={12} md={4} md2={3} data-aos="fade-left">
              <Item2 sx={{ border: "1px solid #FF4267" }}>
                <Box>
                  <img src={NewAvenuesIcon} alt="" />
                </Box>

                <Box sx={{ mt: "10px" }}>
                  <Typography
                    sx={{
                      color: "#000",
                      fontSize: { xs: "18px" },
                      fontWeight: 600,
                      fontFamily: "Product Sans",
                    }}
                  >
                    New avenues for your <br /> business growth
                  </Typography>
                </Box>
              </Item2>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </Box>
  );
};

export default ChannelLandingBecomingPartOfEnvisionNextPowersUp;
