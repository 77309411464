import { Button, Modal, Stack, TextField, Typography } from '@mui/material'
import { Box } from '@mui/system';
import React, { useEffect, useState } from 'react'
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import TextareaAutosize from '@mui/base/TextareaAutosize';
import ReactHtmlParser from 'react-html-parser';
import RichTextEditor from 'react-rte';
import axios from 'axios';
import { Apiaddress } from "../../../utils/consts";
import CloseIcon from '@mui/icons-material/Close';
import { SAProjectSidebar } from '../SidebarNavbar/SAProjectSidebar';
import { SuperAdminNavbar } from '../SidebarNavbar/SuperAdminNavbar';


function SuperAdminAmenities() {

    //get data from local Storage
    let data = JSON.parse(localStorage.getItem("SABuilderDetails"));
    console.log("projectId =>.>",data.selectedProjectId);

    const [value,setValue]=useState("");
    const [listData, setlistData] = useState("");
    const [trigger,setTrigger]= useState(false);

    const [editorValue, setEditorValue] = React.useState(RichTextEditor.createValueFromString(value, 'html'));

  const handleChange = value => {
     setEditorValue(value);
     setValue(value.toString("html"));
    };
  console.log(value);

    const [open, setOpen] = React.useState(false);  
    const handleOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };
    
    const addNewData=async ()=>{
       try {
        console.log("yes");
        const url= Apiaddress + `/api/projects/project/?project_id=${data.selectedProjectId}`;
        let obj={
            project_id:52,
            projectAmmenities:value
        }
        const res = await axios.patch(
          url,
          obj
        );
      console.log("===========================.>",res?.data);
      setTrigger(!trigger);
      setEditorValue("");
      handleClose();
    } catch (err) {
      console.log("====================",err);
       setlistData("");
    }
    }

    const fetchAmenities= async()=>{
      try {
        const res = await axios.get(
          Apiaddress + `/api/projects/project/?project_id=${data.selectedProjectId}`
        );
      console.log("===========================.>",res?.data?.projects[0]?.projectAmmenities);
      let temp=res?.data?.projects[0]?.projectAmmenities
      setlistData(temp);
      setEditorValue(RichTextEditor.createValueFromString(temp, 'html'));
    } catch (err) {
      console.log("====================",err);
       setlistData("");
    }
    }

    useEffect(()=>{
       fetchAmenities();
    },[trigger])

  return (
    <>
     <Stack direction="row" sx={{width:"100%",height:"auto" }}>
       {/* 1st stack */}
      <Stack sx={{minHeight:"100vh",width:"22%",boxSizing:"border-box",}}>
      <SAProjectSidebar active={"Project Amenities"}/>
      </Stack>
      {/* 2nd stack */}
      <Stack direction="column" sx={{ backgroundColor:"#f9f9f9",width:"78%", padding:"25px",boxSizing:"border-box",}}  >
        <SuperAdminNavbar projectAmenities={true}/> 
         {/* actual content  */}
      <Stack sx={{ mt:"28px",backgroundColor:"#f9f9f9"}}>
            
            <Stack direction="row" sx={{ marginTop: "20px" }}>
                <Box sx={{textAlign:"left",margin:"0px 0px 0px 0px"}}>
                    <Button variant="contained" size="" sx={{background:"linear-gradient(90deg, #0F5DAE 0%, #009DCF 100.33%)"}} onClick={handleOpen}>
                        <EditIcon sx={{width:"18px",height:"18px",marginRight:"5px"}}/>
                        <Box sx={{ textTransform: "capitalize" }}>Edit</Box>
                    </Button>
                </Box>
            </Stack>
            <Stack direction="row" alignItems="center" sx={{ height: "68px", marginTop: "20px" }}>
                <Typography sx={{fontSize:"22px",fontWeight: "600",color: "black"}} >
                    Project Amenities
                </Typography>
            </Stack>
      </Stack>

            <Box sx={{margin:"0px 0px 0px 20px",fontFamily:"Product Sans",fontStyle:"normal",fontWeight:"400",fontSize:"18px",lineHeight:"27px",color:"#000000"}}>{ ReactHtmlParser(listData) }</Box>
      </Stack>
    </Stack>

     {/* Modal to add Channel Partner */}

          <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description" 
      >
        <Box sx={{position: 'absolute',
             top: '50%',
             left: '50%',
             transform: 'translate(-50%, -50%)',
             width: "40%",
             height:"auto",
             bgcolor: 'white',
             border: '2px solid white',
             borderRadius:"10px",
             boxShadow: 24,
             pt: 3,
             px: 3,
             pb: 1.5,
             textAlign:"center"
            }}>
            <Box sx={{textAlign:"right"}}>
                <CloseIcon sx={{color:"gray",cursor:"pointer"}} onClick={(e)=>{
                   handleClose();
                   setTrigger(!trigger);
                }}/>
            </Box>
              <Typography sx={{
              fontSize: "18px",
              fontWeight: "600",
              marginBottom: "30px",
              marginTop:"-20px",
              color: "black",
            }}>Project Amenities</Typography>
    
            <Box sx={{textAlign:"center",display:"flex",flexDirection:"column",justifyContent:"center",alignItems:"center"}}>

                <RichTextEditor
                    value={editorValue}
                    onChange={handleChange}
                    required
                    id="body-text"
                    name="bodyText"
                    type="string"
                    multiline
                    variant="filled"
                    style={{ minHeight: 410 }}
                />

                  <Button 
                    onClick={()=>{
                      addNewData();
                    }}
                    variant="contained"
                    color="primary" sx={{ height:"40px", background: "linear-gradient(90deg, #0F5DAE 0%, #009DCF 100.33%)",boxShadow: "0px 13.7981px 22.5788px rgba(50, 174, 137, 0.25)", textTransform: "none", borderRadius: "4px", width:"100px", fontSize:"15px",margin:"20px 0px 10px 0px"}}>
                     Save
                  </Button>

                </Box>
          <Typography sx={{color:"#ff4500",fontWeight:"500",marginBottom:"20px",fontFamily:"Poppins",fontSize:"1.2rem"}}></Typography>
        </Box>
      </Modal>  
    </>
  )
}

export default SuperAdminAmenities