import {
  Box,
  Checkbox,
  Container,
  Divider,
  FormControl,
  FormControlLabel,
  Stack,
  styled,
  Typography,
  useRadioGroup,
} from "@mui/material";

import { flexBetween, itemsCenter } from "./styles/commonStyles";
import classes from "./styles/PageOne.module.css";

const PdfTwoPageOne = ({quotationInfo,projectInfo,unitInfo,leadInfo}) => {
  return (
    <Box>
      <Typography sx={{ fontSize: "14px" }}>
        Quotation for {`(${leadInfo?.lead_name})`} of {`(${projectInfo?.project_name})`} Dated{" "}
        {`(${quotationInfo?.created?.substring(0, 10)})`}
      </Typography>
      <Box sx={{ mt: "20px" }}>
        <Typography sx={{ fontSize: "18px" }}>{`PROJECT NAME : ${projectInfo?.project_name}`}</Typography>
        <Box className={classes.input} sx={{ my: "10px" }}>
          <Typography sx={{marginBottom:"20px"}}>{`BUILDER NAME : ${projectInfo?.builder_name}`}</Typography>
          <label htmlFor="">Project Location</label>
          <input value={projectInfo?.address} type="text" />
        </Box>
      </Box>
      <Stack spacing={1}>
        <Box className={classes.input}>
          <label htmlFor="">Unit No.:</label>
          <input value={quotationInfo?.unit_no} type="text" />
        </Box>
        <Box className={classes.input}>
          <label htmlFor="">Quotation Date:</label>
          <input value={quotationInfo?.created?.substring(0,10)} type="text" />
        </Box>
        <Box className={classes.input}>
          <label htmlFor="">Quotation Valid Till:</label>
          <input value={quotationInfo?.valid?.substring(0,10)} type="text" />
        </Box>
        <Box className={classes.input}>
          <label htmlFor="">Project RERA Number:</label>
          <input value={projectInfo?.rera_no} type="text" />
        </Box>
        <Box className={classes.input}>
          <label htmlFor="">Customer:</label>
          <input value={leadInfo?.lead_name} type="text" />
        </Box>
        <Box className={classes.input}>
          <label htmlFor="">Builder Name Person Name:</label>
          <input value={""} type="text" />
        </Box>
        <Box className={classes.input}>
          <label htmlFor="">Builder Name Contact Person Email ID:</label>
          <input value={""} type="text" />
        </Box>
        <Box className={classes.input}>
          <label htmlFor="">Builder Name Contact Person Mobile Number:</label>
          <input value={""} type="text" />
        </Box>
      </Stack>

      <Stack spacing={1} sx={{ mt: "20px" }}>
        <Typography>
          <span
            style={{
              borderBottom: "2px solid #000",
              display: "inline",
              fontWeight: 400,
            }}
          >
            PROJECT STATUS
          </span>
        </Typography>
        <Box className={classes.input}>
          <label htmlFor="">Current Work Completion</label>
          <input value={""} type="text" />
        </Box>
        <Box className={classes.input}>
          <label htmlFor="">Proposed Completion Date</label>
          <input value={""} type="text" />
        </Box>
        <Typography sx={{ fontSize: "16px" }}>
          Remarks:{" "}
          <span
            style={{
              borderBottom: "3px solid #000",
              paddingBottom: "3px",
              display: true ? "inline" : "inline-block",
              minWidth: "100%",
              fontWeight: 400,
            }}
          >
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Facere
            magnam esse alias qui in tempore, inventore aspernatur animi
            laudantium et quasi sed molestiae, ea veniam amet iusto dicta ipsam
            quam.
          </span>
        </Typography>
      </Stack>
    </Box>
  );
};

export default PdfTwoPageOne;
