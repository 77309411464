import { Box, Stack } from "@mui/material";
import CommonPagesBookingProcessManagement from "../Shared/CommonPagesBookingProcessManagement";
import CommonPagesChannelPartnerManagement from "../Shared/CommonPagesChannelPartnerManagement";
import CommonPagesFooter from "../Shared/CommonPagesFooter";
import CommonPagesFooterForm from "../Shared/CommonPagesFooterForm";
import CommonPagesHomeBuyerManagement from "../Shared/CommonPagesHomeBuyerManagement";
import CommonPagesInventoryManagement from "../Shared/CommonPagesInventoryManagement";
import CommonPagesLeadManagement from "../Shared/CommonPagesLeadManagement";
import CommonPagesNavbar from "../Shared/CommonPagesNavbar";
import CommonPagesResponsiveNavbar from "../Shared/CommonPagesResponsiveNavbar";
import CommonPagesRoute from "../Shared/CommonPagesRoute";
import CommonLandingAboutUs from "./CommonLandingAboutUs";
import CommonLandingAnIntegratedTechnologySolution from "./CommonLandingAnIntegratedTechnologySolution";
import CommonLandingChangeTheFaceOfYourBusiness from "./CommonLandingChangeTheFaceOfYourBusiness";
import CommonLandingHeader from "./CommonLandingHeader";
import CommonLandingKeyFeatures from "./CommonLandingKeyFeatures";
import CommonLandingMeetOurFounders from "./CommonLandingMeetOurFounders";
import CommonLandingTakeTheNextStepToElevate from "./CommonLandingTakeTheNextStepToElevate";
import CommonLandingTheBenefits from "./CommonLandingTheBenefits";
import CommonLandingTheProblemWeeSee from "./CommonLandingTheProblemWeeSee";
import CommonLandingUnleashYourGrowth from "./CommonLandingUnleashYourGrowth";
import CommonLandingYourDataSecurity from "./CommonLandingYourDataSecurity";

export const CommonLandingPage = () => {
  return (
    <>
      <CommonPagesNavbar />
      <CommonPagesResponsiveNavbar />
      <Stack spacing={{ lg: 14, md2: 12, md: 7, xs: 5 }}>
        <CommonLandingHeader />
        <CommonLandingTheProblemWeeSee />
        <CommonLandingAnIntegratedTechnologySolution />
        <CommonLandingKeyFeatures />
        <CommonPagesInventoryManagement />
        <CommonPagesChannelPartnerManagement />
        <CommonPagesHomeBuyerManagement />
        <CommonPagesLeadManagement />
        <CommonPagesBookingProcessManagement />
        <CommonLandingTakeTheNextStepToElevate />
        <CommonLandingYourDataSecurity />
        <CommonLandingTheBenefits />
        <CommonLandingChangeTheFaceOfYourBusiness />
        {/* <CommonLandingAboutUs /> */}
        <CommonLandingMeetOurFounders />
        <CommonLandingUnleashYourGrowth />
        <CommonPagesFooterForm />
        <CommonPagesFooter />
      </Stack>
    </>
  );
};
