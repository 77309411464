import { Box, Button, Stack, Typography } from "@mui/material"
import { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { NavbarForDirectory } from "../../../BuilderComponents/ProjectDashboard/DashboardComponents/NavbarForDirectory"
import { ProjectDashboardSidebar } from "../../../BuilderComponents/ProjectDashboard/DashboardComponents/ProjectDashboardSidebar"
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Apiaddress } from "../../../../utils/consts";
import axios from "axios";
import DoneIcon from '@mui/icons-material/Done';


export const EditSingleUnit = ()=>{
    const location = useLocation();
    let prevUnitId = location.state;
    console.log("unit id: ", prevUnitId)
    const navigate = useNavigate(); 
//   const [unitdata,setunitdata]=useState(data?.unittemplates? data.unittemplates:[]);

  //list to edit portions;
  const unitsList = ["Bedroom Details","Bathroom Details","Balcony Details","Pricing Details","Floor Plan Details","Media Details"];
 ///selected edit portion;
  const [selectedUnit,setSelectedUnit] = useState("bedroomdetails");



//get project id from local storage;

const projectfromlocal = JSON.parse(localStorage.getItem("BuilderSelected"))
const projectID = projectfromlocal.selectedProjectID
const unitID = projectfromlocal.selectedUintId

///get unit Details from database 
const [unitName,setUnitName] = useState("")
  useEffect(()=>{
    const uniturl = Apiaddress+"/api/projects/unit/?unit_id="+unitID
   axios.get(uniturl)
   .then((res)=>{
        const data = res?.data
        console.log("unit data=>>>>>>>.",data)
        setUnitName(data.unit[0].name)
        // setbedroomData(data.unit_template[0].layout_data.bedroomData)
        // setCurrentData(data?.unit[0].layout_data)
     })
     .catch((err)=>{
       console.log(err)
     })

  },[])

    return (
        <>
            <Stack direction="row" sx={{width:"100%",height:"auto" }}>
      {/* 1st stack */}
      <Stack sx={{minHeight:"100vh", width:"22%",boxSizing:"border-box",}}>
      <ProjectDashboardSidebar active={"Unit Directory"}/>
      </Stack>

     {/* 2nd stack */}
      <Stack direction="column" sx={{ backgroundColor:"#f9f9f9",width:"78%", padding:"25px",boxSizing:"border-box",}}  >
      {/* <Navbar/> */}
      <NavbarForDirectory
            // showAddProjectbtn={false}
            // showRightSideBar={false}
            // showRightSideBarProjAdd={false}
            // RightBarNameProAdd={"Add Project"}
            // toggleDrawer={toggleDrawer}
            // showRightSideBarleft={false}
            // RightBarNameleft={"Send invite"}
            leftRightTitle1={"Unit Dashboard"}
            leftRightTitle2={"Unit Directory"}
            leftTitle={"Project Dashboard"}
            showLeftTitle={false}
            righshow={true}
          />
         

    {/* <Typography sx={{mt:"20px", fontSize:"22px", fontWeight:"500",color:"#2979F2"}}>
    Project Name
    </Typography> */}
     {/* actual content  */}
     <Stack sx={{ mt:"28px",backgroundColor:"#f9f9f9",}}>

     <Stack direction="column"  sx={{  padding:"20px", backgroundColor:"#fff", marginRight:"0px"}}>

     <Stack direction="row" spacing={1.5}>
     <Stack direction="row" alignItems="center" spacing={1.5} sx={{margin:"70px 0px 70px 0px"}}>
     <Box  sx={{width:"29px", height:"29px",borderRadius:"50%", backgroundColor:"#FFA946", display:"flex", justifyContent:"center", alignItems:"center"}}>
        <DoneIcon sx={{color:"#fff", fontSize:"20px"}}/>
      </Box>
      <Typography sx={{fontSize:"18px", fontWeight:"500"}}>{projectfromlocal.projectName}</Typography>
    </Stack>
    <Stack direction="row" alignItems="center" spacing={1.5} sx={{margin:"70px 0px 70px 0px"}}>
     <Box  sx={{width:"29px", height:"29px",borderRadius:"50%", backgroundColor:"#FFA946", display:"flex", justifyContent:"center", alignItems:"center"}}>
        <DoneIcon sx={{color:"#fff", fontSize:"20px"}}/>
      </Box>
      <Typography sx={{fontSize:"18px", fontWeight:"500"}}>{unitName}</Typography>
    </Stack>
     </Stack>
         
         <Box>
         <Stack direction="row" sx={{margin:"30px 0px 0px 0px"}} >
        <Typography sx={{fontSize:"18px", fontWeight:"500",color:"#A2A3A6"}}>Unit Name:  {unitName}</Typography>
        {/* <EditIcon sx={{color:"#A2A3A6",marginLeft:"10px"}}/> */}  
      </Stack>

      <Stack direction="row" spacing={4} sx={{display:"flex",alignItems:"center",margin:"20px 0px 0px 0px"}} >
        {unitsList?.map((ele,index)=>{
          let id = ele.replaceAll(/\s/g,'').toLowerCase();  
          console.log(id);
          return (
          <Box
           onClick={()=>{
            setSelectedUnit(id)
           }}
          sx={{width:"63px",height:"71px",background:selectedUnit===id?"#ffb12f":"#EAECF2",borderRadius:"7px",display:"flex",justifyContent:"center",alignItems:"center",textAlign:"center",'&:hover': {
            cursor: 'pointer',
         }}}>
              <Typography sx={{fontSize:"12px",fontWeight:"500",color:selectedUnit===id?"#fff":"#A2A3A6"}}>{ele}</Typography>
          </Box>
          )
        })}
         

      </Stack>

      <Stack direction="row" sx={{alignItems:"center"}}>
      <Box sx={{display:"flex",alignItems:"center",marginRight:"150px",'&:hover': {
            cursor: 'pointer',
         }}}
      onClick={()=>{
        navigate("/unit-directory-w-sb")
      }}
      >
      <ArrowBackIcon/>
      <Typography sx={{fontSize:"18px", fontWeight:"500",margin:"0px 10px 0px 10px"}}>Back</Typography>
      </Box>


      <Box sx={{margin:"60px 0px 60px 0px"}}>
        <Link to={`/unit-directory-w-sb/edit-${selectedUnit}`} state={unitID}>{/* state:unitdata[unitnum-1],current_data:current_data */}
          <Button 
          className="univercel_btn_class"
          variant="contained"
          sx={{padding:"16px 50px 16px 50px", color:"#fff",fontSize:"18px", fontWeight:"700"}}>
              Details 
          </Button>
        </Link>
         

      </Box>
      
      </Stack>
         </Box>

     </Stack>

     </Stack>
 


      </Stack>
</Stack>
        </>
    )
}