import axios from "axios";
import { Apiaddress } from "../../utils/consts";

const getProfile=async()=>{
    const token = localStorage.getItem("crm_token")
    const url = Apiaddress+"/api/profile/" 
    const re = await axios.get(url,
        {
            headers: {
                'Content-Type': 'application/json',
                "Authorization":"JWT "+token
            }
        }
    )
    return re.data;
}
export {getProfile};