import axios from "axios";
import { Apiaddress } from "../../utils/consts";

const ProjectDetails=async(project_id:number)=>{
    try{
    const url = Apiaddress+"/api/projects/projectdetails/?project_id="+project_id;
    const re = await axios.get(url)
    const data = re.data;
    return data;
}
    catch(e){
        console.log(e)
    }
}
export {ProjectDetails};