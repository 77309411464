import { Box, Button, MenuItem, Radio, Select, TextField, Typography } from "@mui/material"
import { Stack } from "@mui/system"
import { Navbar } from "../DashboardComponents/Navbar"
import { Sidebar } from "../DashboardComponents/Sidebar"
import DoneIcon from '@mui/icons-material/Done';
import { useContext, useEffect, useState } from "react";
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import "./CreateProject.css"
import { useNavigate } from "react-router-dom";
import {
  CitySelector,
  StateSelector,
} from "volkeno-react-country-state-city";
import { createProject } from "../../../../connection/project/create_project";
import { listOrganizations } from "../../../../connection/organiztion/list_organzations";
import profileContext from "../../../../context/profile/context";
import projectContext from "../../../../context/createproject/context";

import FormControl from '@mui/material/FormControl';
import { MainSidebar } from "../DashboardComponents/MainSidebar";
import { countryDetails } from "../../../../utils/contryDetails";

export const CreateProjectProfile = () => {
  const navigate = useNavigate();
  ////selection of state city
  const [formState, setFormState] = useState({});
  const [location, setLocation] = useState({});
  const [selectedValue, setSelectedValue] = useState("a");
  const [listOfBuildersOrganization, setListOfBuildersOrganization] = useState([]);
  const user = useContext(profileContext);
  const projContext = useContext(projectContext);



  useEffect(() => {
    setLocation(countryDetails);
    const x = projContext.cpsp
    setFormState(x?.cp?.formState ?? {})
    setLocation(x?.cp?.location ?? countryDetails)
    setSelectedValue(projContext?.cpsp?.cp?.selectedValue ?? "a")
    setListOfBuildersOrganization(projContext?.cpsp?.cp?.listOfBuildersOrganization ?? [])
    console.log("formState", projContext?.cpsp?.cp?.formState)
    console.log("location", projContext?.cpsp?.cp?.location)
    console.log("selectedValue", projContext?.cpsp?.cp?.selectedValue)
    console.log("PROJCONTEXT", projContext)
    listOrganizations().then((e) => {
      setListOfBuildersOrganization(e.org_list);
    });
  }, [projContext]);


  const handleChange = (event) => {
    setSelectedValue(event.target.value);
    console.log(event.target.value);
    setFormState({
      ...formState,
      multi_building: event.target.value == "many",
    });
  };

  const handleSelectOption = (event) => {
  };

  const controlProps = (item) => ({
    checked: selectedValue === item,
    onChange: handleChange,
    value: item,
    name: "color-radio-button-demo",
    inputProps: { "aria-label": item },
  });



  const PostData = async (e) => {
    e.preventDefault();
    console.log(user, formState);
    const data = await createProject({
      ...formState,
      builder_id: user.UserState.user_obj.id,
    });
    projContext.scpsp({
      ...(projContext.cpsp ?? {}),
      "cp": {
        "formState": formState,
        "location": location,
        "selectedValue": selectedValue,
        "listOfBuildersOrganization": listOfBuildersOrganization
      }
    })

    console.log(data);
    projContext.setcreateProjectState(data);
    navigate("/createproject/projectlayout");
  }


  return (
    <>

      <Stack direction="row" sx={{ width: "100%", height: "auto" }}>
        {/* 1st stack */}
        <Stack sx={{ minHeight: "100vh", width: "20%", boxSizing: "border-box", }}> {/*for project directory 20% and for sales dashboard 225*/}
          <MainSidebar active={"Project Directory"} />
        </Stack>

        {/* 2nd stack */}
        <Stack direction="column" sx={{ backgroundColor: "#f9f9f9", width: "80%", padding: "25px", boxSizing: "border-box", }}  >  {/*  width for main sidebar 78px and for 80px*/}
          <Navbar />



          <Typography
            sx={{
              marginTop: "20px",
              fontSize: "28px",
              fontWeight: "600",
              marginLeft: "10px",
              marginBottom: "50px",
            }}
          >
            Create New Project
          </Typography>
          {/* actual content  */}
          <Stack sx={{ mt: "28px", backgroundColor: "#f9f9f9" }}>
            <Stack
              // direction="column"
              sx={{
                padding: "20px",
                backgroundColor: "#fff",
                marginRight: "0px",
              }}
            >
              <Stack
                direction="row"
                justifyContent="start"
                alignItems="center"
                sx={{ height: "100px" }}
              >
                <Stack direction="row" alignItems="center" spacing={1.5} sx={{}}>
                  <Box
                    sx={{
                      width: "29px",
                      height: "29px",
                      borderRadius: "50%",
                      backgroundColor: "#FFA946",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <DoneIcon sx={{ color: "#fff", fontSize: "20px" }} />
                  </Box>
                  <Typography sx={{ fontSize: "18px", fontWeight: "500" }}>
                    Profile
                  </Typography>
                  <Stack direction="row" alignItems="center">
                    <Box
                      sx={{
                        width: "25px",
                        height: "0px",
                        borderBottom: "2px dashed #A2A3A6",
                      }}
                    ></Box>
                    <Box
                      sx={{
                        height: "10px",
                        width: "10px",
                        borderRadius: "50%",
                        backgroundColor: "#A2A3A6",
                      }}
                    ></Box>
                  </Stack>
                </Stack>
                <Stack
                  direction="row"
                  alignItems="center"
                  spacing={1.5}
                  sx={{ marginLeft: "20px" }}
                >
                  <Box
                    sx={{
                      width: "29px",
                      height: "29px",
                      borderRadius: "50%",
                      backgroundColor: "#A2A3A6",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <DoneIcon sx={{ color: "#fff", fontSize: "20px" }} />
                  </Box>
                  <Typography sx={{ fontSize: "18px", fontWeight: "500" }}>
                    Unit layout Items
                  </Typography>
                  <Stack direction="row" alignItems="center">
                    <Box
                      sx={{
                        width: "25px",
                        height: "0px",
                        borderBottom: "2px dashed #A2A3A6",
                      }}
                    ></Box>
                    <Box
                      sx={{
                        height: "10px",
                        width: "10px",
                        borderRadius: "50%",
                        backgroundColor: "#A2A3A6",
                      }}
                    ></Box>
                  </Stack>
                </Stack>
                <Stack
                  direction="row"
                  alignItems="center"
                  spacing={1.5}
                  sx={{ marginLeft: "20px" }}
                >
                  <Box
                    sx={{
                      width: "29px",
                      height: "29px",
                      borderRadius: "50%",
                      backgroundColor: "#A2A3A6",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <DoneIcon sx={{ color: "#fff", fontSize: "20px" }} />
                  </Box>
                  <Typography sx={{ fontSize: "18px", fontWeight: "500" }}>
                    Unit Pricing Items
                  </Typography>
                  <Stack direction="row" alignItems="center">
                    <Box
                      sx={{
                        width: "25px",
                        height: "0px",
                        borderBottom: "2px dashed #A2A3A6",
                      }}
                    ></Box>
                    <Box
                      sx={{
                        height: "10px",
                        width: "10px",
                        borderRadius: "50%",
                        backgroundColor: "#A2A3A6",
                      }}
                    ></Box>
                  </Stack>
                </Stack>
                <Stack
                  direction="row"
                  alignItems="center"
                  spacing={1.5}
                  sx={{ marginLeft: "20px" }}
                >
                  <Box
                    sx={{
                      width: "29px",
                      height: "29px",
                      borderRadius: "50%",
                      backgroundColor: "#A2A3A6",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <DoneIcon sx={{ color: "#fff", fontSize: "20px" }} />
                  </Box>
                  <Typography sx={{ fontSize: "18px", fontWeight: "500" }}>
                    Templates
                  </Typography>
                  {/* <Stack direction="row" alignItems="center" >
                                <Box sx={{width:"25px", height:"0px", borderBottom:"2px dashed orange"}}></Box>
                                <Box sx={{height:"10px", width:"10px",borderRadius:"50%",  backgroundColor:"orange"}}></Box>
                               </Stack> */}
                </Stack>
              </Stack>

              <form onSubmit={PostData}>
                <Stack
                  direction="row"
                  alignItems="center"
                  spacing={2}
                  sx={{ margin: "20px 0px 40px 0px"}}
                >
                  <Typography
                    sx={{ color: "#A2A3A6", fontSize: "18px", fontWeight: 500 ,width:"200px"}}
                  >
                    Organization Name
                  </Typography>
                  <FormControl sx={{ m: 1, minWidth: 188, border: "1px transparent" }}>
                    <Select
                      required
                      defaultValue={""}
                      value={formState.org_id ?? ''}
                      onChange={(e) => {
                        if (e.target.value == "") return;
                        setFormState({ ...formState, org_id: e.target.value });
                      }}
                      inputProps={{ 'aria-label': 'Without label' }}
                      sx={{ width: "10px" }}
                    >
                      {/* <MenuItem value="">
                                    select organization
                                  </MenuItem> */}

                      {listOfBuildersOrganization.map((e) => {
                        console.log("ORGID", formState.org_id)
                        return <MenuItem value={e.id}>{e.org_name}</MenuItem>;
                      })}
                    </Select>
                  </FormControl>

                </Stack>
                <Stack
                  direction="row"
                  alignItems="center"
                  spacing={2}
                  sx={{ marginBottom: "40px" }}
                >
                  <Typography
                    sx={{ color: "#A2A3A6", fontSize: "18px", fontWeight: 500,width:"200px" }}
                  >
                    Project Name
                  </Typography>
                  <TextField
                    value={formState.name}
                    required
                    onChange={(e) => {
                      setFormState({ ...formState, name: e.target.value });
                    }}
                    sx={{ width: "188px" }}
                  />
                </Stack>
                <Stack
                  direction="row"
                  alignItems="center"
                  spacing={2}
                  sx={{ marginBottom: "40px" }}
                >
                  <Typography
                    sx={{ color: "#A2A3A6", fontSize: "18px", fontWeight: 500,width:"200px" }}
                  >
                    RERA Number (optional)
                  </Typography>
                  <TextField
                    value={formState.rera_number}

                    onChange={(e) => {
                      setFormState({ ...formState, rera_number: e.target.value });
                    }}
                    sx={{ width: "188px" }}
                  />
                </Stack>
                <Stack
                  direction="row"
                  alignItems="center"
                  spacing={2}
                  sx={{ marginBottom: "40px" }}
                >
                  <Typography
                    sx={{ color: "#A2A3A6", fontSize: "18px", fontWeight: 500,width:"200px"}}
                  >
                    Site Address
                  </Typography>
                  <TextField
                    required
                    value={formState.address_line}

                    sx={{ width: "415px" }}
                    onChange={(e) => {
                      setFormState({ ...formState, address_line: e.target.value });
                    }}
                  />
                </Stack>

                <Stack
                  direction="row"
                  justifyContent="start"
                  alignItems="center"
                  spacing={3.5}
                  sx={{ marginBottom: "30px" }}
                >
                  <Stack>
                    <Typography
                      sx={{
                        margin: "0px 15px 5px 10px",
                        color: "#170F49",
                        fontSize: "18px",
                        fontWeight: 500,
                      }}
                    >
                      State
                    </Typography>
                    {/* <TextField
                                    sx={{width:"221px", }}
                                 /> */}
                    <StateSelector
                      country={location.country}
                      name="state"
                      value={location.state}
                      countryPlaceholder="Select a country first"
                      onChange={(e) => {
                        console.log(e);
                        // setValue("country", e.name);

                        setFormState({ ...formState, state: e.name });

                        setLocation({ ...location, state: e });
                      }}
                    />
                  </Stack>
                  <Stack>
                    <Typography
                      sx={{
                        margin: "0px 15px 5px 10px",
                        color: "#170F49",
                        fontSize: "18px",
                        fontWeight: 500,
                      }}
                    >
                      City
                    </Typography>
                    {/* <TextField
                                    sx={{width:"221px", }}
                                 /> */}
                    <CitySelector
                      state={location.state}
                      name="city"
                      value={location.city}
                      statePlaceholder="Select a state first"
                      onChange={(e) => {
                        console.log(e);
                        // setValue("city", e.name);
                        setFormState({ ...formState, city: e.name });
                        setLocation({ ...location, city: e });
                      }}
                    />
                  </Stack>
                  <Stack direction="column">
                    <Typography
                      sx={{
                        margin: "0px 15px 5px 10px",
                        color: "#170F49",
                        fontSize: "18px",
                        fontWeight: 500,
                      }}
                    >
                      Pincode
                    </Typography>
                    <TextField
                      required
                      value={formState.pincode}
                      type="number" inputProps={{ min: 0, max: 999999 }}
                      sx={{
                        width: "221px",
                        marginTop: "10px",
                        marginBottom: "12px",
                      }}
                      onChange={(e) => {
                        if (e.target.value >= 999999) return;
                        setFormState({ ...formState, pincode: e.target.value });
                      }}
                    />
                  </Stack>
                </Stack>

                <Stack direction="column" sx={{ marginTop: "10px" }}>
                  <Typography
                    sx={{ color: "#A2A3A6", fontSize: "18px", fontWeight: 500, margin: "0px 15px 15px 0px" }}
                  >
                    Building Type
                  </Typography>
                  <Stack
                    direction="row"
                    justifyContent="start"
                    alignItems="center"
                    sx={{}}
                  >
                    <Radio
                      required
                      name="building_type"
                      {...controlProps("single")}
                      sx={{
                        color:
                          "linear-gradient(264.48deg, #79F1A4 -45.37%, #0E5CAD 88.97%)",
                        color: "#0F5DAE",
                        "&.Mui-checked": {
                          //   color: "red",
                          //   color: "linear-gradient(264.48deg, #79F1A4 -45.37%, #0E5CAD 88.97%)"
                        },
                      }}
                    />
                    <Typography
                      sx={{ color: "#170F49", fontSize: "18px", fontWeight: 500, marginRight: "20px" }}
                    >
                      Single Building
                    </Typography>
                    <Radio
                      required
                      name="building_type"
                      {...controlProps("many")}
                      sx={{

                        color:
                          "linear-gradient(264.48deg, #79F1A4 -45.37%, #0E5CAD 88.97%)",
                        color: "#0F5DAE",
                        "&.Mui-checked": {
                          //   color: "red",
                          //   color: "linear-gradient(264.48deg, #79F1A4 -45.37%, #0E5CAD 88.97%)"
                        },
                      }}
                    />
                    <Typography
                      sx={{ color: "#170F49", fontSize: "18px", fontWeight: 500 }}
                    >
                      Multiple Building
                    </Typography>
                  </Stack>
                </Stack>

                <Stack
                  direction="row"
                  justifyContent="end"
                  alignItems="center"
                  sx={{ marginTop: "50px" }}
                >
                  <Button
                    // onClick={PostData}
                    type="submit"
                    size="large"
                    className="univercel_btn_class"
                    sx={{ textTransform: "none", color: "#fff" }}
                    endIcon={<KeyboardDoubleArrowRightIcon />}
                  >
                    Next
                  </Button>
                </Stack>

              </form>
            </Stack>

          </Stack>




        </Stack>
      </Stack>





    </>
  );
};
