import React from "react";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { Box } from "@mui/material";
import { useNavigate } from "react-router-dom";

export default function NavigateBack({ fontSize }) {
  const navigate = useNavigate();
  return (
    <>
      <ArrowBackIosIcon
        onClick={() => {
          navigate(-1);
        }}
        sx={{
          fontSize: fontSize ? fontSize : "16px",
          cursor: "pointer",
        }}
      />
    </>
  );
}
