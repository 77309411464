import { Box, Container, Stack, Typography } from "@mui/material";
import LeverageImg from "../../Imgs/tailor-made.png";
import CommonLandingEnhanceCustomerTrust from "../../Imgs/common-landing-enhance-customer-trust.svg";
import CommonLandingGetTheCompetitiveEdge from "../../Imgs/common-landing-get-the-competitive-edge.svg";
import CommonLandingEnhanceBrandValue from "../../Imgs/common-landing-enhance-brand-value.svg";
import CommonLandingImproveWorkEfficiency from "../../Imgs/common-landing-Improve-work-efficiency.svg";
import CommonLandingImproveCustomerExperience from "../../Imgs/common-landing-improve-customer-experience.svg";
import CommonLandingStrengthenCommunication from "../../Imgs/common-landing-strengthen-communication.svg";
import CommonLandingGrowWithBetterAnalysis from "../../Imgs/common-landing-grow-with-better-analysis.svg";
import CommonLandingEasilyEstablish from "../../Imgs/common-landing-easily-establish.svg";

const CommonLandingTheBenefits = () => {
  return (
    <Box sx={{ backgroundColor: "#FFF", overflowX: "hidden" }}>
      <Container>
        <Box sx={{ textAlign: "center" }}>
          <Typography
            data-aos="fade-down"
            sx={{
              fontSize: { xs: "20px", md: "24px", lg: "28px" },
              fontWeight: 600,
              color: "#0085FF",
              fontFamily: "Product Sans",
            }}
          >
            THE BENEFITS
          </Typography>
          <Typography
            data-aos="fade-up"
            sx={{
              fontSize: { xs: "24px", md: "32px", lg: "40px" },
              fontWeight: 700,
              fontFamily: "Circular Std",
              color: "#272D4E",
              lineHeight: "1.3",
              mt: "5px",
              display: { xs: "none", md2: "block" },
            }}
          >
            Leverage the power of simplicity & integration achieve <br /> the
            growth you want
          </Typography>
          <Typography
            data-aos="fade-up"
            sx={{
              fontSize: { xs: "24px", md: "30px", lg: "40px" },
              fontWeight: 700,
              fontFamily: "Circular Std",
              color: "#272D4E",
              lineHeight: "1.3",
              mt: "5px",
              display: { xs: "block", md2: "none" },
            }}
          >
            Leverage the power of simplicity & integration achieve the growth
            you want
          </Typography>
        </Box>

        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: "50px",
            flexDirection: { xs: "column-reverse", md: "row" },
            py: { xs: "30px", md: "70px" },
          }}
        >
          <Box data-aos="fade-right" sx={{ width: { xs: "100%", md: "50%" } }}>
            <img style={{ width: "100%" }} src={LeverageImg} alt="" />
          </Box>
          <Stack spacing={1.5} sx={{ width: { xs: "100%", md: "50%" } }}>
            <Box
              data-aos="fade-zoom-in"
              data-aos-easing="ease-in-back"
              data-aos-delay="100"
              data-aos-offset="0"
              sx={{
                display: "flex",
                alignItems: "center",
                gap: "20px",
                background: "#FFFFFF",
                border: "1px solid #0890FE",
                boxShadow:
                  "0px 8px 22px -6px rgba(24, 39, 75, 0.12), 0px 14px 64px -4px rgba(24, 39, 75, 0.12)",
                borderRadius: "8px",
                padding: "10px 20px",
              }}
            >
              <Box>
                <img src={CommonLandingEnhanceCustomerTrust} alt="" />
              </Box>
              <Typography
                sx={{
                  fontSize: "18px",
                  fontFamily: "Product Sans",
                  color: "#343434",
                }}
              >
                Enhance customer trust
              </Typography>
            </Box>
            <Box
              data-aos="fade-zoom-in"
              data-aos-easing="ease-in-back"
              data-aos-delay="200"
              data-aos-offset="0"
              sx={{
                display: "flex",
                alignItems: "center",
                gap: "20px",
                background: "#FFFFFF",
                border: "1px solid #FF4267",
                boxShadow:
                  "0px 8px 22px -6px rgba(24, 39, 75, 0.12), 0px 14px 64px -4px rgba(24, 39, 75, 0.12)",
                borderRadius: "8px",
                padding: "10px 20px",
              }}
            >
              <Box>
                <img src={CommonLandingGetTheCompetitiveEdge} alt="" />
              </Box>
              <Typography
                sx={{
                  fontSize: "18px",
                  fontFamily: "Product Sans",
                  color: "#343434",
                }}
              >
                Get the competitive edge
              </Typography>
            </Box>
            <Box
              data-aos="fade-zoom-in"
              data-aos-easing="ease-in-back"
              data-aos-delay="300"
              data-aos-offset="0"
              sx={{
                display: "flex",
                alignItems: "center",
                gap: "20px",
                background: "#FFFFFF",
                border: "1px solid #FFAF2A",
                boxShadow:
                  "0px 8px 22px -6px rgba(24, 39, 75, 0.12), 0px 14px 64px -4px rgba(24, 39, 75, 0.12)",
                borderRadius: "8px",
                padding: "10px 20px",
              }}
            >
              <Box>
                <img src={CommonLandingEnhanceBrandValue} alt="" />
              </Box>
              <Typography
                sx={{
                  fontSize: "18px",
                  fontFamily: "Product Sans",
                  color: "#343434",
                }}
              >
                Enhance brand value
              </Typography>
            </Box>
            <Box
              data-aos="fade-zoom-in"
              data-aos-easing="ease-in-back"
              data-aos-delay="400"
              data-aos-offset="0"
              sx={{
                display: "flex",
                alignItems: "center",
                gap: "20px",
                background: "#FFFFFF",
                border: "1px solid #00C29A",
                boxShadow:
                  "0px 8px 22px -6px rgba(24, 39, 75, 0.12), 0px 14px 64px -4px rgba(24, 39, 75, 0.12)",
                borderRadius: "8px",
                padding: "10px 20px",
              }}
            >
              <Box>
                <img src={CommonLandingImproveWorkEfficiency} alt="" />
              </Box>
              <Typography
                sx={{
                  fontSize: "18px",
                  fontFamily: "Product Sans",
                  color: "#343434",
                }}
              >
                Improve work efficiency
              </Typography>
            </Box>
            <Box
              data-aos="fade-zoom-in"
              data-aos-easing="ease-in-back"
              data-aos-delay="500"
              data-aos-offset="0"
              sx={{
                display: "flex",
                alignItems: "center",
                gap: "20px",
                background: "#FFFFFF",
                border: "1px solid #3629B7",
                boxShadow:
                  "0px 8px 22px -6px rgba(24, 39, 75, 0.12), 0px 14px 64px -4px rgba(24, 39, 75, 0.12)",
                borderRadius: "8px",
                padding: "10px 20px",
              }}
            >
              <Box>
                <img src={CommonLandingImproveCustomerExperience} alt="" />
              </Box>
              <Typography
                sx={{
                  fontSize: "18px",
                  fontFamily: "Product Sans",
                  color: "#343434",
                }}
              >
                Improve customer experience
              </Typography>
            </Box>
            <Box
              data-aos="fade-zoom-in"
              data-aos-easing="ease-in-back"
              data-aos-delay="600"
              data-aos-offset="0"
              sx={{
                display: "flex",
                alignItems: "center",
                gap: "20px",
                background: "#FFFFFF",
                border: "1px solid #0890FE",
                boxShadow:
                  "0px 8px 22px -6px rgba(24, 39, 75, 0.12), 0px 14px 64px -4px rgba(24, 39, 75, 0.12)",
                borderRadius: "8px",
                padding: "10px 20px",
              }}
            >
              <Box>
                <img src={CommonLandingStrengthenCommunication} alt="" />
              </Box>
              <Typography
                sx={{
                  fontSize: "18px",
                  fontFamily: "Product Sans",
                  color: "#343434",
                }}
              >
                Strengthen communication with your team, partners, and customers
              </Typography>
            </Box>
            <Box
              data-aos="fade-zoom-in"
              data-aos-easing="ease-in-back"
              data-aos-delay="700"
              data-aos-offset="0"
              sx={{
                display: "flex",
                alignItems: "center",
                gap: "20px",
                background: "#FFFFFF",
                border: "1px solid #FF4267",
                boxShadow:
                  "0px 8px 22px -6px rgba(24, 39, 75, 0.12), 0px 14px 64px -4px rgba(24, 39, 75, 0.12)",
                borderRadius: "8px",
                padding: "10px 20px",
              }}
            >
              <Box>
                <img src={CommonLandingGrowWithBetterAnalysis} alt="" />
              </Box>
              <Typography
                sx={{
                  fontSize: "18px",
                  fontFamily: "Product Sans",
                  color: "#343434",
                }}
              >
                Grow with better analysis and actionable insights
              </Typography>
            </Box>
            <Box
              data-aos="fade-zoom-in"
              data-aos-easing="ease-in-back"
              data-aos-delay="800"
              data-aos-offset="0"
              sx={{
                display: "flex",
                alignItems: "center",
                gap: "20px",
                background: "#FFFFFF",
                border: "1px solid #FFAF2A",
                boxShadow:
                  "0px 8px 22px -6px rgba(24, 39, 75, 0.12), 0px 14px 64px -4px rgba(24, 39, 75, 0.12)",
                borderRadius: "8px",
                padding: "10px 20px",
              }}
            >
              <Box>
                <img src={CommonLandingEasilyEstablish} alt="" />
              </Box>
              <Typography
                sx={{
                  fontSize: "18px",
                  fontFamily: "Product Sans",
                  color: "#343434",
                }}
              >
                Easily establish a new business from scratch
              </Typography>
            </Box>
          </Stack>
        </Box>
      </Container>
    </Box>
  );
};

export default CommonLandingTheBenefits;
