import React, { useContext, useEffect } from 'react';
import { TextField, Typography, Radio,Box, Button, MenuItem, Select, FormControl, } from "@mui/material";
import { Stack } from '@mui/system';
import { Link } from 'react-router-dom';
import { useState } from 'react';
import AddIcon from '@mui/icons-material/Add';
import BuildingPatternSection from './BuildingPatternSection';
import NewPricingSection from './NewPricingSection';
import Modal from '@mui/material/Modal';
import CloseIcon from '@mui/icons-material/Close'; 
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';
 
import DoneIcon from '@mui/icons-material/Done';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import { useNavigate } from "react-router-dom";
import { Sidebar } from '../DashboardComponents/Sidebar';
import { Navbar } from '../DashboardComponents/Navbar';
import Divider from '@mui/material/Divider';
import { Apiaddress } from '../../../../utils/consts';
import { ConnectingAirportsOutlined } from '@mui/icons-material';
import currentProjectState from '../../../../context/createproject/context'; 
import axios from 'axios';
import { MainSidebar } from '../DashboardComponents/MainSidebar';


function NewPricingTemplate() {

   const navigate = useNavigate();

   const [templatename,settemplatename]=useState("");

   const [unitdata,setunitdata]=useState({
    unit_id:"",
    unit_name:"",
    total_cost:0
   });
   

   console.log(templatename);
   
  const [unittemp,setunittemp]=useState([]);

    const [seccost,setseccost]= useState({index:0,cost:0});
    const [grandtotal,setgrandtotal]=useState(0);

    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    
    const [addsec,setaddsec]=useState({
        titlename:"",
        number:0,
        sectioncost:0,
        field:[]
    });

    const totalcostcalculate=()=>{
        let data=0
        title.map((ele)=>{
            data+=+(ele.sectioncost);
        })
        setgrandtotal(data);

        let obj=unitdata;
        obj.total_cost=data;
        setunitdata(obj);
    }
    
    
    const [title,settitle]=useState([]); 

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: "350px",
        height:"250px",
        bgcolor: 'background.paper',
        boxShadow: 4,
        borderRadius:"16px",
        p: 4
      };

      console.log(title)
      
    useEffect(()=>{
        const data=title.map((ele)=>{
            if(ele.number==seccost.index){
                ele.sectioncost=seccost.cost;
            }
            return ele;
        })
        settitle(data);
        totalcostcalculate();

    },[seccost]);

    /// get all unit template id's ;
    const cpContext = useContext(currentProjectState);
    const projectID = cpContext?.createProjectState?.project?.id;
    const [unit_template_id, setUnit_template_id] = useState("")

    useEffect(()=>{

        const layouturl = Apiaddress + "/api/projects/unitlayout/?unit_layout_id=" + cpContext.createProjectState.project.unit_layout;

       axios.get(layouturl)
       .then((res)=>{
        console.log(res.data.unit_layout[0].pricing_data);
        settitle(res.data.unit_layout[0].pricing_data);
       })

        const url = Apiaddress+"/api/projects/unittemplatesbyproject/?project_id="+projectID;

        axios.get(url)
        .then((res)=>{
            console.log(res.data);
            if(res?.data?.unit_template!=="Unit template not found")
            setunittemp(res?.data?.unit_template);
        })
        .catch((err)=>{
            console.log(err)
        }) 
        
    },[])
    
    ///create pricing Template function;
    
    const createPricingTemplate = ()=>{
        if(unit_template_id=="" || templatename==""){
            return;
        }
        const url = Apiaddress+"/api/projects/unittemplate/";
        let data = {
            unit_template_id:unit_template_id,
            pricing_template_name:templatename,
            charges_data:title
        }
        //    data = JSON.stringify(data);
        console.log(data)
        
        axios.patch(url,data,{
            headers:{
                "Content-Type":"application/json"
            }
        })
        .then((res)=>{
               console.log("pricing template:  ", res.data);
               navigate("/createproject/projectpricing/projectcreateoption/mainscreen")
                })
                .catch((err)=>{
                    console.log(err)
                })
            }
  
  return (
    <>

<Stack direction="row" sx={{width:"100%",height:"auto" }}>
      {/* 1st stack */}
      <Stack sx={{minHeight:"100vh",width:"20%",boxSizing:"border-box",}}> {/*for project directory 20% and for sales dashboard 225*/}
        <MainSidebar active={"Project Directory"}/>
      </Stack>

     {/* 2nd stack */}
     <Stack direction="column" sx={{ backgroundColor:"#f9f9f9",width:"80%", padding:"25px",boxSizing:"border-box",}}  >  {/*  width for main sidebar 78px and for 80px*/}
      <Navbar/>
      


      <Typography sx={{marginTop:"20px", fontSize:"28px", fontWeight:"600", marginLeft:"10px", marginBottom:"50px"}}>
                   Create New Project
           </Typography>
     {/* actual content  */}
    <Stack sx={{backgroundColor:"#f9f9f9"}}>  {/* mt:"28px", */}

    <Stack direction="column"  sx={{padding:"20px", backgroundColor:"#fff", marginRight:"0px"}}>

{/* Add pricing Template */}
<Box sx={{}}>
<Typography className='' sx={{margin:"30px 0px 0px 0px",fontFamily:'Poppins',fontStyle:"normal",fontWeight:"700",fontSize: "20px",lineHeight:"20px",color:"#161515"}}>New Pricing Template</Typography>
{/* <Typography className='' sx={{margin:"10px 0px 0px 0px",fontFamily:'Poppins',fontStyle:"normal",fontWeight:"400",fontSize: "12px",lineHeight:"20px",color:"#B9B7D0"}}>Booking Request</Typography> */}

<Stack direction="row" sx={{alignItems:"center",margin:"30px 0px 20px 0px"}}>

<Typography component="span" className='field-name' sx={{fontSize:"20px"}}>Select Unit</Typography>
<FormControl sx={{width:"188px", marginLeft:"65px"}}>
<Select
onChange={(e)=>{
 let obj=unitdata;
 obj.unit_name=e.target.value;
 setUnit_template_id(e.target.value)
 setunitdata(obj);
}}
displayEmpty
inputProps={{ 'aria-label': 'Without label' }}
>
{unittemp.map((ele,index)=>{
return(
 <MenuItem key={index} value={ele.id}>{ele.name}</MenuItem>
)
})}
</Select>
</FormControl>

</Stack>

<Stack direction="row" sx={{alignItems:"center",margin:"30px 0px 20px 0px"}}>
<Typography component="span" className='field-name' sx={{fontSize:"20px"}}>Template Name</Typography> 
 <TextField
       sx={{
          
           width:"188px",
           marginLeft:"20px"
          }} name="" value={templatename} onChange={(e)=>{settemplatename(e.target.value)}}/>
</Stack>

<Button 
     type="submit"
      variant="contained"
       color="primary" sx={{ height:"40px", background: "linear-gradient(90deg, #0F5DAE 0%, #009DCF 100.33%)",boxShadow: "0px 13.7981px 22.5788px rgba(50, 174, 137, 0.25)", textTransform: "none", borderRadius: "4px", width:"225px", fontSize:"15px",margin:"30px 0px 20px 0px"}} onClick={()=>{setOpen(true)}}>
      <AddIcon sx={{marginRight:"10px"}}/>Add a New Section
</Button>

<Modal
  open={open}
  onClose={handleClose}
  aria-labelledby="modal-modal-title"
  aria-describedby="modal-modal-description">
 <Box sx={style}>
 <Stack direction="row" justifyContent="space-between" alignItems="center">
      <Typography id="modal-modal-title" variant="h6" component="span" sx={{fontFamily:'Poppins',fontStyle:"normal",fontWeight:"500",fontSize: "18px",lineHeight:"27px",color:"#FF6140"}}>
      Add Section
      </Typography>
      <Box onClick={()=>{setOpen(false);setaddsec("")}} sx={{cursor:"pointer"}}><CloseIcon /></Box>   
 </Stack>
 <Box sx={{alignItems:"center",margin:"40px 0px 0px 0px"}}>
  <Stack direction="row" justifyContent="start" alignItems="center" sx={{margin:"10px 0px 0px 0px",fontSize: "18px"}}>
      <Typography component="span" className='field-name'>Input Section Name</Typography>
      <TextField
       sx={{
          
           width:"188px",
           marginLeft:"10px"
          }} name="titlename" value={addsec.titlename} onChange={(e)=>{setaddsec(
              {
                  titlename:e.target.value,
                  number:title.length+1,
                  sectioncost:0,
                  field:[]
              }
              )}}/>
  </Stack>
  </Box>
  <Button 
     className="univercel_btn_class"
      variant="contained"
       color="primary" sx={{ height:"45px",textTransform: "none", borderRadius: "4px", width:"100px", fontSize:"15px",margin:"60px 0px 30px 120px"}} onClick={()=>{
          if(!addsec.titlename){
              return;
          }
          settitle(title=> title.concat(addsec));
          setOpen(false);
         //  {console.log(title)};
          setaddsec("");
      }}>
      Done
</Button>
</Box>
</Modal>

<Stack sx={{}}>
      {title.map((ele,index)=>{
          return(
              <>
              <NewPricingSection header={ele} setseccost={setseccost} index={index} title={title} settitle={settitle}/>
              </>
          )
      })}
</Stack>

<Divider sx={{margin:"40px 0px 40px 0px"}}/>

<Stack direction="row" justifyContent="right" alignItems="center" sx={{margin:"50px 50px 50px 0px"}}>
  <Typography className='field-name' sx={{fontSize:"18px",color:"#2979F2",marginRight:"20px"}}>Grand Total</Typography>
  <Stack direction="row" sx={{alignItems:"center"}}>
     <CurrencyRupeeIcon sx={{color:"#2979F2"}}/>
     <Box sx={{width:"188px",height:"52px",background:"#ECECEC",borderRadius:"8px",display:"flex",justifyContent:"center",alignItems:"center"}}>
         <Typography className='field-name' sx={{fontSize:"20px",color:"#2979F2"}}>{grandtotal}</Typography>
     </Box>
  </Stack>
</Stack>
</Box>

<Button 
   variant="contained"
   className="univercel_btn_class"
    color="primary" sx={{ height:"50px", background: "linear-gradient(90deg, #0F5DAE 0%, #009DCF 100.33%)",boxShadow: "0px 13.7981px 22.5788px rgba(50, 174, 137, 0.25)", textTransform: "none", borderRadius: "4px", width:"150px", fontSize:"15px",margin:"0px 0px 70px 70px"}}
    onClick={()=>{
     createPricingTemplate();
    }}
    >
   Save
  </Button>

     </Stack>

    </Stack>
      </Stack>
</Stack>



    </>
  )
}

export default NewPricingTemplate