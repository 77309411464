import { Box, Stack } from "@mui/material";
import CommonPagesBookingProcessManagement from "../Shared/CommonPagesBookingProcessManagement";
import CommonPagesChannelPartnerManagement from "../Shared/CommonPagesChannelPartnerManagement";
import CommonPagesFooter from "../Shared/CommonPagesFooter";
import CommonPagesFooterForm from "../Shared/CommonPagesFooterForm";
import CommonPagesHomeBuyerManagement from "../Shared/CommonPagesHomeBuyerManagement";
import CommonPagesInventoryManagement from "../Shared/CommonPagesInventoryManagement";
import CommonPagesLeadManagement from "../Shared/CommonPagesLeadManagement";
import CommonPagesNavbar from "../Shared/CommonPagesNavbar";
import CommonPagesResponsiveNavbar from "../Shared/CommonPagesResponsiveNavbar";
import BuilderLandingGetStartedWithEnvisionNext from "./BuilderLandingGetStartedWithEnvisionNext";
import BuilderLandingHeader from "./BuilderLandingHeader";
import BuilderLandingOneStopToConnect from "./BuilderLandingOneStopToConnect";
import BuilderLandingOurTeamWillSupport from "./BuilderLandingOurTeamWillSupport";
import BuilderLandingTailorMade from "./BuilderLandingTailorMade";
import BuilderLandingWhatCanYouAchieve from "./BuilderLandingWhatCanYouAchieve";

export const BuilderLandingPage = () => {
  return (
    <>
      <CommonPagesNavbar profile={"builder"}/>
      <CommonPagesResponsiveNavbar />
      <Stack spacing={{ lg: 14, md2: 12, md: 7, xs: 5 }}>
        <BuilderLandingHeader />
        <BuilderLandingOneStopToConnect />
        <BuilderLandingTailorMade />
        <CommonPagesInventoryManagement />
        <CommonPagesChannelPartnerManagement />
        <CommonPagesHomeBuyerManagement />
        <CommonPagesLeadManagement />
        <CommonPagesBookingProcessManagement />
        <BuilderLandingGetStartedWithEnvisionNext />
        <BuilderLandingOurTeamWillSupport />
        <BuilderLandingWhatCanYouAchieve />
        {/* <BuilderLandingCustomerReview /> */}
        <CommonPagesFooterForm />
        <CommonPagesFooter />
      </Stack>
    </>
  );
};
