import { Box, Checkbox, Stack, Button, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import axios from "axios";
import { Apiaddress } from "../../../utils/consts";
import { useNavigate } from "react-router-dom";
import { UserRole } from "../../../UserRoles/UserRoles";

function PDcustomTable({
  newRowData,
  delIconClick,
  updateBuyers,
  setTrigger,
  trigger,
}) {
  
  ////get user roles from local storage and assign 
  let roles = JSON.parse(localStorage.getItem("user_roles"))
  
  let project = roles["project"];

  //   console.log(newRowData);
  const [curpage, setcurpage] = useState(1);
  const [pageRange, setPageRange] = useState([0, 6]);

  const nextClick = () => {
    if (
      !(newRowData.length >= pageRange[0] && newRowData.length <= pageRange[1])
    ) {
      setcurpage(curpage + 1);
      setPageRange([pageRange[0] + 6, pageRange[1] + 6]);
    }
  };

  const prvClick = () => {
    if (pageRange[0] != 0 && pageRange[1] != 6) {
      setcurpage(curpage - 1);
      setPageRange([pageRange[0] - 6, pageRange[1] - 6]);
    }
  };

  const [pages, setPages] = useState([]);
  useEffect(() => {
    if (newRowData) {
      let arr = [];
      for (
        let i = 1;
        i <=
        (newRowData.length % 6 == 0
          ? newRowData.length / 6
          : newRowData.length / 6 + 1);
        i++
      ) {
        arr.push(i);
      }
      setPages(arr);
    }
  }, [newRowData]);

  const [curData, setcurData] = useState([]);

  useEffect(() => {
    if (newRowData) {
      // console.log(newRowData);
      setcurData(newRowData);
    }
  }, [newRowData]);

  const sortArr = (para) => {
    function compare(a, b) {
      if (a[para] < b[para]) {
        return -1;
      }
      if (a[para] > b[para]) {
        return 1;
      }
      return 0;
    }
    let arr = [...curData];
    arr.sort(compare);
    console.log(arr);
    setcurData(arr);
  };

  const sortArrDec = (para) => {
    function compare(a, b) {
      if (a[para] < b[para]) {
        return -1;
      }
      if (a[para] > b[para]) {
        return 1;
      }
      return 0;
    }
    let arr = [...curData];
    arr.sort(compare);
    arr.reverse();
    console.log(arr);
    setcurData(arr);
  };

  console.log("current data==========>:", newRowData)

  // useEffect(() => {
  //   if (curData) console.log(curData);
  // }, [curData]);

    //////set builder selected project to local storage for show to the project dashboard 
    const navigate = useNavigate();
    let builderSelected = JSON.parse(localStorage.getItem("BuilderSelected"));

    const setBuilderSelectedProject = (id)=>{    
      let data = {
        selectedProjectID:id
      }
        if(builderSelected){
            localStorage.setItem("BuilderSelected", JSON.stringify({...builderSelected,...data}))
          }else{
            localStorage.setItem("BuilderSelected", JSON.stringify(data))
        }  
    }


    ////get count of unit in the project and store to the object
    let [projectWithUnitsCount, setprojectWithUnitCount] = useState({}); 
    useEffect(()=>{
      let temp = {};
      for(let i=0; i<curData.length; i++){
      //get no. of units according to project
      let no_units = 0;
      let url= Apiaddress+"/api/projects/getcountconfirmproject/?project_id="+curData[i].project_id;
      
      axios.get(url).then((res)=>{
        no_units =res.data.unit_count;
        temp = {...temp,[curData[i].project_id]:res.data.unit_count}
        setprojectWithUnitCount({...temp,[curData[i].project_id]:res.data.unit_count})
      }).catch((err)=>{
        console.log(err);
      })
    }
  },[curData])
  console.log("temp:>",projectWithUnitsCount)

  return (
    <Box>
  
     
        <Box sx={{ color: "black", width: "100%" }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "start",
              alignItems: "center",
              background: "white",
              borderRadius: "5px",
              margin: " 10px 0",
              color: "#272D4E",  
              fontWeight: "700",
              fontFamily:"Circular Std",
              fontSize:"18px"
            }}
          >
            {/* <Box
              sx={{
                width: "6vw",
                display: "grid",
                placeItems: "center",
                height: "50px",
              }}
            >
              <Checkbox />
            </Box> */}
            <Box
              sx={{
                width: "15vw",
                display: "grid",
                placeItems: "center",
                height: "50px",
                justifyContent: "start",
                paddingLeft:"20px",
              }}
            >
              <Box sx={{ display: "flex" }}>
                <Box sx={{ display: "grid", placeItems: "center" }}>Name</Box>
                {/* <Box sx={{ display: "grid", marginLeft: "4px" }}>
                  <ArrowDropUpIcon
                    fontSize="large"
                    sx={{ marginBottom: "-10px" }}
                    onClick={() => sortArr("Name")}
                  />
                  <ArrowDropDownIcon
                    fontSize="large"
                    sx={{ marginTop: "-10px" }}
                    onClick={() => sortArrDec("Name")}
                  />
                </Box> */}
              </Box>
            </Box>

            <Box
              sx={{
                width: "15vw",
                display: "grid",
                placeItems: "center",
                height: "50px",
                justifyContent: "start",
                paddingLeft:"20px",
              }}
            >
              <Box sx={{ display: "flex" }}>
                <Box sx={{ display: "grid", placeItems: "center" }}>State</Box>
                {/* <Box sx={{ display: "grid", marginLeft: "4px" }}>
                  <ArrowDropUpIcon
                    fontSize="large"
                    sx={{ marginBottom: "-10px" }}
                    onClick={() => sortArr("State")}
                  />
                  <ArrowDropDownIcon
                    fontSize="large"
                    sx={{ marginTop: "-10px" }}
                    onClick={() => sortArrDec("State")}
                  />
                </Box> */}
              </Box>
            </Box>

            <Box
              sx={{
                width: "15vw",
                display: "grid",
                placeItems: "center",
                height: "50px",
                justifyContent: "start",
                paddingLeft:"20px"
              }}
            >
              <Box sx={{ display: "flex" }}>
                <Box sx={{ display: "grid", placeItems: "center" }}>City</Box>
                {/* <Box sx={{ display: "grid", marginLeft: "4px" }}>
                  <ArrowDropUpIcon
                    fontSize="large"
                    sx={{ marginBottom: "-10px" }}
                    onClick={() => sortArr("City")}
                  />
                  <ArrowDropDownIcon
                    fontSize="large"
                    sx={{ marginTop: "-10px" }}
                    onClick={() => sortArrDec("City")}
                  />
                </Box> */}
              </Box>
            </Box>

            <Box
              sx={{
                width: "15vw",
                display: "grid",
                placeItems: "center",
                height: "50px",
                justifyContent: "start",
                paddingLeft:"20px"
              }}
            >
              <Box sx={{ display: "flex" }}>
                <Box sx={{ display: "grid", placeItems: "center" }}>
                  Pincode
                </Box>
                {/* <Box sx={{ display: "grid", marginLeft: "4px" }}>
                  <ArrowDropUpIcon
                    fontSize="large"
                    sx={{ marginBottom: "-10px" }}
                    onClick={() => sortArr("Pincode")}
                  />
                  <ArrowDropDownIcon
                    fontSize="large"
                    sx={{ marginTop: "-10px" }}
                    onClick={() => sortArrDec("Pinecode")}
                  />
                </Box> */}
              </Box>
            </Box>

            <Box
              sx={{
                width: "15vw",
                display: "grid",
                placeItems: "center",
                height: "50px",
                justifyContent: "start",
                paddingLeft:"20px"
              }}
            >
              <Box sx={{ display: "flex" }}>
                <Box sx={{ display: "grid", placeItems: "center" }}>Unit</Box>
                {/* <Box sx={{ display: "grid", marginLeft: "4px" }}>
                  <ArrowDropUpIcon
                    fontSize="large"
                    sx={{ marginBottom: "-10px" }}
                    onClick={() => sortArr("Unit")}
                  />
                  <ArrowDropDownIcon
                    fontSize="large"
                    sx={{ marginTop: "-10px" }}
                    onClick={() => sortArrDec("Unit")}
                  />
                </Box> */}
              </Box>
            </Box>

            <Box
              sx={{
                width: "15vw",
                display: "grid",
                placeItems: "center",
                height: "50px",
                justifyContent: "start",
                paddingLeft:"20px"
              }}
            >
              Actions
            </Box>
          </Box>
          {curData?.length==0?
           <Box sx={{textAlign:"center",marginTop:"50px"}}><Typography sx={{fontSize:"25px",fontWeight:"500",color:"#B9B7D0",}}>No Matches Found</Typography></Box>:
           <Box>
          {curData &&
            curData.map((item, index) => {

              if (index >= pageRange[0] && index <= pageRange[1]) {
                return (
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      background: "white",
                      borderRadius: "5px",
                      margin: " 10px 0",
                      color:"#343434",
                      // fontFamily:"Product Sans",
                      // fontWeight: "400",
                      cursor:"pointer",
                    }}
                    onClick={()=>{
                      setBuilderSelectedProject(item.project_id)
                      navigate("/projectdashboard")   
                    }}
                  >
                    {/* <Box
                      sx={{
                        width: "6vw",
                        display: "grid",
                        placeItems: "center",
                        height: "50px",
                      }}
                    >
                      <Checkbox />
                    </Box> */}
                    <Box
                      sx={{
                        width: "15vw",
                        display: "grid",
                        placeItems: "center",
                        height: "50px",
                        justifyContent: "start",
                        fontFamily:"Product Sans",
                        fontWeight:"400",
                        paddingLeft:"20px"
                      }}
                    >
                      {item.Name}
                    </Box>
                    <Box
                      sx={{
                        width: "15vw",
                        display: "grid",
                        placeItems: "center",
                        justifyContent: "start",
                        height: "50px",
                        fontFamily:"Product Sans",
                        fontWeight:"400",
                        paddingLeft:"20px"
                      }}
                    >
                      {item.State}
                    </Box>
                    <Box
                      sx={{
                        width: "15vw",
                        display: "grid",
                        placeItems: "center",
                        justifyContent: "start",
                        height: "50px",
                        fontFamily:"Product Sans",
                        fontWeight:"400",
                        paddingLeft:"20px"
                      }}
                    >
                      {item.City}
                    </Box>
                    <Box
                      sx={{
                        width: "15vw",
                        display: "grid",
                        placeItems: "center",
                        justifyContent: "start",
                        height: "50px",
                        fontFamily:"Product Sans",
                        fontWeight:"400",
                        paddingLeft:"20px"
                      }}
                    >
                      {item.Pincode}
                    </Box>
                    <Box
                      sx={{
                        width: "15vw",
                        display: "grid",
                        placeItems: "center",
                        justifyContent: "start",
                        height: "50px",
                        fontFamily:"Product Sans",
                        fontWeight:"400",
                        paddingLeft:"20px"
                      }}
                    >
                      {/* {item.Unit} */}
                      {/* taking unit from the object according to coresponding project ids  */}
                      {projectWithUnitsCount[item.project_id]}
                    </Box>
                    <Box
                      sx={{
                        width: "15vw",
                        display: "grid",
                        placeItems: "center",
                        justifyContent: "start",
                        height: "50px",
                        fontFamily:"Product Sans",
                        fontWeight:"400",
                        paddingLeft:"20px"
                      }}
                    >
                      <Stack direction="row" sx={{ marginLeft: "0px" }}>
                        {UserRole(project, "view_project") && 
                          <RemoveRedEyeIcon fontSize="medium" 
                        onClick={(e)=>{
                          e.stopPropagation()
                          setBuilderSelectedProject(item.project_id,)
                          navigate("/projectdashboard")   
                        }}
                        />
                        }
                      {UserRole(project, "change_project") && 
                        <EditIcon
                          fontSize="medium"
                          sx={{ marginLeft: "10px" }}
                          onClick={(e) =>
                            {
                              e.stopPropagation()
                              setBuilderSelectedProject(item.project_id)
                              navigate("/builder-edit-project-building")
                            }
                          }
                        />
                      }
                       {UserRole(project, "delete_project") &&
                        <DeleteIcon
                          fontSize="medium"
                          sx={{ marginLeft: "10px" }}
                          onClick={(e) =>
                            {
                              e.stopPropagation()
                              delIconClick(item)
                            }
                          }
                        />
                       }
                      </Stack>
                    </Box>
                  </Box>
                );
              }
            })}
          </Box>
          }
        </Box>

        <Box>

          <Box sx={{margin:"50px 0px 20px 0px"}}>
          <Box sx={{ display: "flex", justifyContent: "right" }}>
            {newRowData.length!=0 && 
            <Button variant="contained" sx={{background:"linear-gradient(90deg, #0F5DAE 0%, #009DCF 100.33%)"}}
              onClick={prvClick}>
                <ArrowBackIosIcon fontSize="small" />
                <Box sx={{ textTransform: "capitalize" }}>Previous</Box>
            </Button>}

            <Box sx={{background: "#E3E4EB",display: "flex",placeItems: "center",margin: " 0 10px",borderRadius: "5px"}}>
                <Box sx={{background: `white`,color: "black",width: "30px",borderRadius: "5px",margin: "0 10px",display: "grid",placeItems: "center"}}>{curpage}</Box>
                <Box sx={{color: "black",width: "30px",borderRadius: "5px",margin: "0 0px",display: "grid",placeItems: "center"}}>of</Box>
                <Box sx={{background: `white`,color: "black",width: "30px",borderRadius: "5px",margin: "0 10px",display: "grid",placeItems: "center"}}>{pages?.length}</Box>
            </Box>

            {newRowData.length!=0 && 
                <Button variant="contained" sx={{background:"linear-gradient(90deg, #0F5DAE 0%, #009DCF 100.33%)"}}
                    onClick={nextClick}>
                    <Box sx={{ textTransform: "capitalize" }}>Next</Box>
                    <ArrowForwardIosIcon fontSize="small" />
                </Button>
            }
          </Box>
        </Box>
               
           
        </Box>
     
    </Box>
  );
}

export default PDcustomTable;
