import { Box } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react'
import builderDashboard from '../../../../context/builder_dashboard/context';
import { VaccencyStatusHistogram } from './Histogram';
import PieMaker from './PieChart'

function VaccencyStatus({update, setUpdate}) {
  const [dat, setDat] = useState([
    { name: 'booked', value: 0, start: "#0085FF", end: "#65C5FF" },
    { name: 'paid', value: 0, start: "#FF4267", end: "#FF7A93" },
    { name: 'vacant', value: 0, start: "#FFAF2A", end: "#FFC666" },
  ]);

  const OrganizationsContext = useContext(builderDashboard);
  console.log("=================>",OrganizationsContext.BuilderDashboardState);

  const collectAllTheSelected = () => {
    let booked = 0;
    let paid = 0;
    let vacant = 0;
    OrganizationsContext.BuilderDashboardState?.map(e => {
      if (e.selected) {
        if(e.Projects.length!=0){
              booked=booked+ e.vaccencyStatus.booked;
              paid=paid+ e.vaccencyStatus.paid;
              vacant=vacant+ e.vaccencyStatus.vacant;
           }   
      }
    })
    setDat(
      [
    { name: 'booked', value: booked, start: "#0085FF", end: "#65C5FF" },
    { name: 'paid', value: paid, start: "#FF4267", end: "#FF7A93" },
    { name: 'vacant', value: vacant, start: "#FFAF2A", end: "#FFC666" },
      ]
    )
  }

   useEffect(() => {
    collectAllTheSelected()

  }, [update])



  console.log(dat)
  return (
    <Box sx={{
      boxShadow: 5,
      margin: 5,
      padding: 2,
      width: "45%",
      height: 150,
      borderRadius: 2,
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      flexDirection: "row"
    }}>
      <PieMaker data={dat} />
      <VaccencyStatusHistogram booked={dat[0].value} paid={dat[1].value} vacant={dat[2].value} />
    </Box>
  )
}

export default VaccencyStatus