import { Box, Container, Stack, Typography } from "@mui/material";
import { Parallax } from "react-scroll-parallax";
import CircleCheckIconWhite from "../../Imgs/star-icon.svg";
import MobileFeature from "../../Imgs/mobile-feature.png";

const BuilderLandingWhatCanYouAchieve = () => {
  return (
    <Box sx={{ overflow: "hidden" }}>
      <Container sx={{ textAlign: "center" }}>
        <Typography
          data-aos="fade-down"
          sx={{
            color: "#272D4E",
            fontSize: { xs: "32px", md2: "42px", lg: "54px" },
            fontWeight: 700,
            fontFamily: "Circular Std",
          }}
        >
          What can you achieve with EnvisionNext?
        </Typography>
        <Typography
          data-aos="fade-up"
          sx={{
            color: "#343434",
            fontSize: "18px",
            fontFamily: "Product Sans",
          }}
        >
          Signing up for our technology solution helps you exponentially grow
          your Real Estate business with benefits spread across the processes
          and factors that matter.
        </Typography>
      </Container>
      <Box
        sx={{
          background: "#FFF",
          py: "20px",
          mt: "50px",
        }}
      >
        <Container>
          <Box
            sx={{
              display: "flex",
              gap: "40px",
              alignItems: "center",
              flexDirection: { xs: "column", md: "row" },
            }}
          >
            <Stack
              data-aos="fade-right"
              data-aos-delay="500"
              spacing={{ xs: 2, md: 6 }}
              sx={{ width: { xs: "100%", md: "35%" } }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: { xs: "row", md: "column" },
                  alignItems: { xs: "start", md: "end" },
                  gap: "10px",
                }}
              >
                <Box>
                  <img src={CircleCheckIconWhite} alt="" />
                </Box>
                <Typography
                  sx={{
                    fontSize: "18px",
                    fontWeight: 600,
                    textAlign: { xs: "left", md: "right" },
                    color: "#343434",
                    fontFamily: "Product Sans",
                  }}
                >
                  Improved work efficiency across your projects
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: { xs: "row", md: "column" },
                  alignItems: { xs: "start", md: "end" },
                  gap: "10px",
                }}
              >
                <Box>
                  <img src={CircleCheckIconWhite} alt="" />
                </Box>
                <Typography
                  sx={{
                    fontSize: "18px",
                    fontWeight: 600,
                    textAlign: { xs: "left", md: "right" },
                    color: "#343434",
                    fontFamily: "Product Sans",
                  }}
                >
                  Access to verified Channel Partners
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: { xs: "row", md: "column" },
                  alignItems: { xs: "start", md: "end" },
                  gap: "10px",
                }}
              >
                <Box>
                  <img src={CircleCheckIconWhite} alt="" />
                </Box>
                <Typography
                  sx={{
                    fontSize: "18px",
                    fontWeight: 600,
                    textAlign: { xs: "left", md: "right" },
                    color: "#343434",
                    fontFamily: "Product Sans",
                  }}
                >
                  Better avenues to establish and grow your business
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: { xs: "row", md: "column" },
                  alignItems: { xs: "start", md: "end" },
                  gap: "10px",
                }}
              >
                <Box>
                  <img src={CircleCheckIconWhite} alt="" />
                </Box>
                <Typography
                  sx={{
                    fontSize: "18px",
                    fontWeight: 600,
                    textAlign: { xs: "left", md: "right" },
                    color: "#343434",
                    fontFamily: "Product Sans",
                  }}
                >
                  Enhanced trust of customers in you
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: { xs: "row", md: "column" },
                  alignItems: { xs: "start", md: "end" },
                  gap: "10px",
                }}
              >
                <Box>
                  <img src={CircleCheckIconWhite} alt="" />
                </Box>
                <Typography
                  sx={{
                    fontSize: "18px",
                    fontWeight: 600,
                    textAlign: { xs: "left", md: "right" },
                    color: "#343434",
                    fontFamily: "Product Sans",
                  }}
                >
                  Enhanced brand value
                </Typography>
              </Box>
            </Stack>
            <Box
              sx={{
                display: { xs: "none", md: "block" },
                width: "30%",
              }}
            >
              <Parallax speed={50}>
                <img style={{ width: "100%" }} src={MobileFeature} alt="" />
              </Parallax>
            </Box>
            <Box
              sx={{
                display: { xs: "block", md: "none" },
                width: "100%",
              }}
            >
              <Box sx={{ display: "flex", justifyContent: "center" }}>
                <img style={{ width: "50%" }} src={MobileFeature} alt="" />
              </Box>
            </Box>
            <Stack
              data-aos="fade-left"
              data-aos-delay="1500"
              spacing={{ xs: 2, md: 6 }}
              sx={{ width: { xs: "100%", md: "35%" } }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: { xs: "row", md: "column" },
                  gap: "10px",
                }}
              >
                <Box>
                  <img src={CircleCheckIconWhite} alt="" />
                </Box>
                <Typography
                  sx={{
                    fontSize: "18px",
                    fontWeight: 600,
                    color: "#343434",
                    fontFamily: "Product Sans",
                  }}
                >
                  Strengthened collaboration with your team and customers
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: { xs: "row", md: "column" },
                  gap: "10px",
                }}
              >
                <Box>
                  <img src={CircleCheckIconWhite} alt="" />
                </Box>
                <Typography
                  sx={{
                    fontSize: "18px",
                    fontWeight: 600,
                    color: "#343434",
                    fontFamily: "Product Sans",
                  }}
                >
                  Competitive edge over other Real Estate Builders and
                  Developers
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: { xs: "row", md: "column" },
                  gap: "10px",
                }}
              >
                <Box>
                  <img src={CircleCheckIconWhite} alt="" />
                </Box>
                <Typography
                  sx={{
                    fontSize: "18px",
                    fontWeight: 600,
                    color: "#343434",
                    fontFamily: "Product Sans",
                  }}
                >
                  Actionable insights to stay steps ahead always
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: { xs: "row", md: "column" },
                  gap: "10px",
                }}
              >
                <Box>
                  <img src={CircleCheckIconWhite} alt="" />
                </Box>
                <Typography
                  sx={{
                    fontSize: "18px",
                    fontWeight: 600,
                    color: "#343434",
                    fontFamily: "Product Sans",
                  }}
                >
                  Superior experience for your customers
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: { xs: "row", md: "column" },
                  gap: "10px",
                }}
              >
                <Box>
                  <img src={CircleCheckIconWhite} alt="" />
                </Box>
                <Typography
                  sx={{
                    fontSize: "18px",
                    fontWeight: 600,
                    color: "#343434",
                    fontFamily: "Product Sans",
                  }}
                >
                  Best-in-class data security
                </Typography>
              </Box>
            </Stack>
          </Box>
        </Container>
      </Box>
    </Box>
  );
};

export default BuilderLandingWhatCanYouAchieve;
