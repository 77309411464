import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  Stack,
  Table,
  TextField,
  Typography,
  typographyClasses,
} from "@mui/material";
import { GridExpandMoreIcon } from "@mui/x-data-grid";
// import { type } from 'os'
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { AuthService } from "../../../../context/buyer/services/auth";
import { api, net } from "../../../../utils/consts";
import SideBar from "../../Components/sideBar/SideBar";
// import "../UnitDirectory/addUnitStyle.css"
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import { Apiaddress } from "../../../../utils/consts";
import axios from "axios";
import { countryDetails } from "../../../../utils/contryDetails";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import { CitySelector, StateSelector } from "volkeno-react-country-state-city";
import NavigateBack from "../../../CommonPages/Shared/NavigateBack";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #fff",
  boxShadow: 24,
  borderRadius: "20px",
  pt: 0,
  px: 4,
  pb: 0,
};

function AddUnitToProject() {
  let project = useParams().id;
  console.log(project);

  const user = JSON.parse(localStorage.getItem("crm_user"));
  console.log(user);

  const cpDetails = JSON.parse(localStorage.getItem("cpDetails"));

  let projectid = JSON.parse(localStorage.getItem("BuilderSelectedProject"));

  console.log(cpDetails);

  let cpOrg = cpDetails?.channelPartnerOrg;

  useEffect(() => {
    if (user) {
      setAddedBy(user?.user_details?.first_name);
    }
  }, []);

  const [location, setLocation] = useState({});
  const [stateError, setStateError] = useState(false);
  const [addressError, setAddressError] = useState(false);
  const [cityError, setCityError] = useState(false);
  const [phoneError, setPhoneError] = useState(false);
  const [phoneContactError, setPhoneContactError] = useState(false);

  const [For, setFor] = useState();
  const [Type, setType] = useState();
  const [Area, setArea] = useState();
  const [Configuration, setConfiguration] = useState();
  const [ProjectType, setProjectType] = useState();
  const [QuotingPrice, setQuotingPrice] = useState();
  const [reraNumber, setreraNumber] = useState();
  const [City, setCity] = useState("");
  const [State, setState] = useState("");
  const [Address, setAddress] = useState("");
  const [Pincode, setPincode] = useState();
  const [PropertyAge, setPropertyAge] = useState();
  const [Condition, setCondition] = useState();
  const [Remark, setRemark] = useState();
  const [OwnerName, setOwnerName] = useState();
  const [OwnerCompany, setOwnerCompany] = useState();
  const [OwnerPhone, setOwnerPhone] = useState();
  const [OwnerEmail, setOwnerEmail] = useState();
  const [OwnerContactPersonName, setOwnerContactPersonName] = useState();
  const [OwnerContactPersonNumber, setOwnerContactPersonNumber] = useState();
  const [OwnerID, setOwnerID] = useState();
  const [RatingUrgency, setRatingUrgency] = useState();
  const [RatingCost, setRatingCost] = useState();
  const [RatingProperty, setRatingProperty] = useState();
  const [AddedBy, setAddedBy] = useState();
  const [name, setname] = useState("");

  const fetchProjectDetail=async()=>{
    try{
      const res = await axios.get(
        Apiaddress + `/api/projects/project/?project_id=${projectid}`
      );
      let temp=res?.data?.projects[0];
      console.log("===========================.>", res?.data?.projects[0]);
      setState(temp?.state);
      setCity(temp?.city);
    }catch(err){
       console.log(err.message);
    }
  }

  useEffect(()=>{
    fetchProjectDetail();
  },[]);

  // for upload media
  const [img, setimg] = useState("");
  const [text, setText] = useState("");
  const [listmedia, setlistmedia] = useState([]);
  const [localmedia, setlocalmedia] = useState([]);
  const [update, setUpdate] = useState(false);
  const [imgflag, setImgFlag] = useState(false);
  const [msg, setMsg] = useState("");
  const [image, setImage] = useState("");

  const imgHandler = (e) => {
    let fileSize = e.target.files[0].size / 1024 / 1024;
    if (fileSize > 5) {
      // alert('Image size is greater than 2 MB');
      setMsg("Image size is greater than 5 MB");
      handleOpenSucc();
      return;
    }
    let url = URL.createObjectURL(e.target.files[0]);
    console.log(e.target.files[0]);
    setImage(url);
    setimg(e.target.files[0]);
    setText(e.target.files[0].name);
    setImgFlag(true);
  };

  let builderData = JSON.parse(localStorage.getItem("crm_user"));

  console.log(listmedia);

  const saveUnit = async (e) => {
    e.preventDefault();
    console.log("Yes");
    if (State === "") {
      setStateError(true);
    }
    if (City === "") {
      setCityError(true);
    }
    if (Address === "") {
      setAddressError(true);
    }
    if (OwnerPhone.length < 10) {
      setPhoneError(true);
    }
    if (OwnerContactPersonNumber && OwnerContactPersonNumber.length < 10) {
      setPhoneContactError(true);
      return;
    }

    if (
      State == "" ||
      City == "" ||
      Address == "" ||
      OwnerPhone?.length < 10
    )
      return;

    const load = {
      For: For,
      Type: Type,
      Area: Area,
      Configuration: Configuration,
      ProjectType: ProjectType,
      QuotingPrice: QuotingPrice,
      reraNumber: reraNumber,
      City: City,
      State: State,
      Address: Address,
      Pincode: Pincode,
      PropertyAge: PropertyAge,
      Condition: Condition,
      Remark: Remark,
      OwnerName: OwnerName,
      OwnerCompany: OwnerCompany,
      OwnerPhone: OwnerPhone,
      OwnerEmail: OwnerEmail,
      OwnerContactPersonName: OwnerContactPersonName,
      OwnerContactPersonNumber: OwnerContactPersonNumber,
      OwnerID: OwnerID,
      RatingUrgency: RatingUrgency,
      RatingCost: RatingCost,
      RatingProperty: RatingProperty,
      AddedBy: AddedBy,
      media: localmedia,
    };

    const url =
      Apiaddress + `/api/projects/projectCpUnit/?project_id=${project}`;
    const Auth = new AuthService();
    const res = await axios.patch(url, {
      unit_data: JSON.stringify(load),
      name: name,
      state:State,
      city:City,
      pin_code:Pincode,
      project:project,
      cp_id: Auth.getCPID(),
    });
    console.log("===========================.>", res?.data);
    setLocation({});
    setCity("");
    setState("");
    nav("/channelpartner/project/unit/");
  };

  ////for modal
  const [open, setOpen] = useState(false);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    setimg("");
    setText("");
  };

  ////for SuccessFul Modal
  const [openSucc, setOpenSucc] = useState(false);
  const handleOpenSucc = () => {
    setOpenSucc(true);
  };
  const handleCloseSucc = () => {
    setOpenSucc(false);
  };

  const onSubmitMedia = () => {
    if (img == "" || text == "") return;

    let url = Apiaddress + "/api/projects/projectmedia/";
    let type = img?.type?.split("/")[0];
    let data;

    console.log("hhhhhhhhhhhhhhhh", img);
    const headers = {
      headers: {
        "content-type": "multipart/form-data",
      },
    };

    if (type == "image") {
      data = {
        tag: text,
        is_file: false,
        image: img,
        media_type: "Images",
        builder_id: builderData.id,
        istrue: "false",
      };
    }
    console.log(data);
    axios
      .post(url, data, headers)
      .then((res) => {
        console.log("post media", res.data);
        setlocalmedia([...localmedia, res.data.media]);
        setUpdate(!update);
        // alert("Media added");
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setImgFlag(false);
        setImage("");
        handleClose();
        setMsg("Media is added Successfully");
        handleOpenSucc();
      });
  };

  ///////// delete media images from database;
  const deleteMedia = (id) => {
    console.log(id);
    let url = Apiaddress + "/api/projects/projectmedia/";
    let data = {
      data: {
        media_id: id,
      },
    };
    axios
      .delete(url, data)
      .then((res) => {
        console.log(res.data);
        setUpdate(!update);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const deleteMediaLocal = (id) => {
    let temp = localmedia.filter((ele) => {
      return ele.id != id;
    });
    setlocalmedia(temp);
  };

  const handlechange = (event, ele) => {
    let data = localmedia?.map((element, idx) => {
      if (idx == ele) {
        element.istrue = event.target.value;
      } else {
        element.istrue = "false";
      }
      return element;
    });
    setlocalmedia(data);
  };

  useEffect(() => {
    setLocation(countryDetails);
  }, []);

  //    useEffect(()=>{
  //       //get already present data through api
  //        let url = Apiaddress+"/api/projects/mediaofbuilder/?builder_id="+builderData.id

  //        axios.get(url)
  //        .then((res)=>{
  //            let data = res.data.media
  //         let arr = [];
  //         for(let i=0; i<data.length; i++){
  //             // console.log(data[i].media_type)
  //             if(data[i].media_type=="Images")
  //             arr.push(data[i])
  //         }

  //         setlistmedia(arr)

  //        }).catch((err)=>{
  //         console.log(err)
  //        })

  //   },[update])

  const getMenuItemsFromArray = (arr) => {
    return arr.map((e) => {
      //@ts-ignore
      return <MenuItem value={e}>{e}</MenuItem>;
    });
  };

  const nav = useNavigate();

  return (
    <div>
      <SideBar active={"Inventory"}>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          sx={{ marginBottom: "110px" }}
        >
          <Stack>
          <Stack direction="row">
                  <Box sx={{ pt: "5px" }}>
                    <NavigateBack fontSize="22px" />
                  </Box>
                  <Typography
                    sx={{
                      fontSize: "22px",
                      fontWeight: "600",
                      color: "black",
                    }}
                    >
                    Inventory
                  </Typography>
                </Stack>
            <Typography
              sx={{
                color: "#B9B7D0",
                fontSize: "16px",
                fontWeight: "400",
              }}
            >
              Manage your Inventory with ease
            </Typography>
          </Stack>
        </Stack>

        <form onSubmit={saveUnit}>
          <div
            style={{
              backgroundColor: "white",
              padding: "20px",
              width: "100%",
              marginTop: "-60px",
            }}
          >
            <Stack
              direction="row"
              justifyContent="start"
              alignItems="center"
              sx={{ backgroundColor: "white", marginBottom: "10px" }}
            >
              <Stack direction="column" alignItems="center">
                <Typography
                  sx={{ fontSize: "22px", fontWeight: "600", color: "black" }}
                >
                  Add Unit
                </Typography>
              </Stack>
            </Stack>

            <Stack sx={{ height: "500px", overflowY: "scroll" }}>
              <table style={{}}>
                <tr style={{ height: "70px" }}>
                  <td>
                    <Stack direction="row" alignItems="center">
                      <div className="ff" style={{ width: "200px" }}>
                        Name*
                      </div>
                      <div className="ff">
                        <TextField
                          required
                          type="text"
                          value={name}
                          style={{ width: "220px"}}
                          onChange={(e) => {
                            setname(e.target.value);
                          }}
                        ></TextField>
                      </div>
                    </Stack>
                  </td>
                  <td>
                    <Stack direction="row" alignItems="center">
                      <div className="ff" style={{ width: "200px" }}>
                        For*
                      </div>
                      <div className="ff" style={{ width: "220px" }}>
                        {" "}
                        <Select
                          required
                          onChange={(e) => {
                            //@ts-ignore
                            setFor(e.target.value);
                          }}
                        >
                          {getMenuItemsFromArray(["Buy", "Rent", "Long Lease"])}
                        </Select>
                      </div>
                    </Stack>
                  </td>
                </tr>

                <tr style={{ height: "70px" }}>
                  <td>
                    <Stack direction="row" alignItems="center">
                      <div className="ff" style={{ width: "200px" }}>
                        Type*
                      </div>
                      <div className="ff" style={{ width: "220px" }}>
                        <Select
                          required
                          onChange={(e) => {
                            //@ts-ignore
                            setType(e.target.value);
                          }}
                        >
                          {getMenuItemsFromArray([
                            "Residential",
                            "Office",
                            "Shop",
                            "Godown",
                            "Land",
                            "Farmhouse",
                          ])}
                        </Select>
                      </div>
                    </Stack>
                  </td>
                  <td>
                    <Stack direction="row" alignItems="center">
                      <div className="ff" style={{ width: "200px" }}>
                        Area (Sq.ft)*
                      </div>
                      <div className="ff" style={{ width: "220px" }}>
                        <TextField
                          type="number"
                          required
                          inputProps={{ min: 0 }}
                          value={Area}
                          style={{ width: "220px"}}
                          onChange={(e) => {
                            setArea(e.target.value);
                          }}
                        ></TextField>
                      </div>
                    </Stack>
                  </td>
                </tr>

                <tr style={{ height: "70px" }}>
                  <td>
                    <Stack direction="row" alignItems="center">
                      <div className="ff" style={{ width: "200px" }}>
                        Configuration*
                      </div>
                      <div className="ff" style={{ width: "220px" }}>
                        <Select
                          required
                          value={Configuration}
                          onChange={(e) => {
                            setConfiguration(e.target.value);
                          }}
                        >
                          {getMenuItemsFromArray(["New Project", "Resale"])}
                        </Select>
                      </div>
                    </Stack>
                  </td>
                  <td>
                    <Stack direction="row" alignItems="center">
                      <div className="ff" style={{ width: "180px" }}>
                        Quotation Price*
                      </div>
                      <div className="ff" style={{ width: "240px" }}>
                        <Stack
                          direction="row"
                          justifyContent="center"
                          alignItems="center"
                        >
                          <CurrencyRupeeIcon />
                          <TextField
                            type="number"
                            required
                            inputProps={{ min: 0 }}
                            value={QuotingPrice}
                            style={{ width: "220px"}}
                            onChange={(e) => {
                              setQuotingPrice(e.target.value);
                            }}
                          ></TextField>
                        </Stack>
                      </div>
                    </Stack>
                  </td>
                </tr>

                <tr style={{ height: "70px" }}>
                  <td>
                    <Stack direction="row" alignItems="center">
                      <div className="ff" style={{ width: "200px" }}>
                        RERA Number
                      </div>
                      <div className="ff" style={{ width: "220px" }}>
                        <TextField
                          //   type="number"
                          //   inputProps={{min:0}}
                          value={reraNumber}
                          style={{ width: "220px"}}
                          onChange={(e) => {
                            setreraNumber(e.target.value);
                          }}
                        ></TextField>
                      </div>
                    </Stack>
                  </td>
                  <td>
                    <Stack direction="row" alignItems="center">
                      <div className="ff" style={{ width: "200px" }}>
                        Address*
                      </div>
                      <div className="ff" style={{ width: "220px" }}>
                        <TextField
                          required
                          value={Address}
                          style={{ width: "220px"}}
                          onChange={(e) => {
                            setAddress(e.target.value);
                          }}
                        ></TextField>
                        {addressError && (
                          <Typography
                            sx={{
                              fontWeight: "500",
                              fontSize: "14px",
                              marginBottom: "5px",
                              color: "#da6061",
                            }}
                          >
                            Address is required
                          </Typography>
                        )}
                      </div>
                    </Stack>
                  </td>
                </tr>

                <tr style={{ height: "70px" }}>
                  <td>
                    <Stack direction="row" alignItems="center">
                      <div className="ff" style={{ width: "200px" }}>
                        State*
                      </div>
                      <div className="ff" style={{ width: "220px" }}>
                        {/* <StateSelector
                      country={location.country}
                      name="state"
                      value={location.state}
                      countryPlaceholder="Select a country first"
                      onChange={(e) => {
                        console.log(e);
                        setState(e.name);  
                        setLocation({ ...location, state: e });
                      }}
                    /> */}
                        <TextField value={State} disabled sx={{width:"220px"}}></TextField>
                        {stateError && (
                          <Typography
                            sx={{
                              fontWeight: "500",
                              fontSize: "14px",
                              marginBottom: "5px",
                              color: "#da6061",
                            }}
                          >
                            State is required
                          </Typography>
                        )}
                        {/* <Select>
                      {getMenuItemsFromArray([
                        "Furnished",
                        "Un-Furnished",
                        "Semi-Furnished",
                      ])}
                    </Select> */}
                      </div>
                    </Stack>
                  </td>
                  <td>
                    <Stack direction="row" alignItems="center">
                      <div className="ff" style={{ width: "200px" }}>
                        City*
                      </div>
                      <div className="ff" style={{ width: "220px" }}>
                        {/* <CitySelector
                        state={location.state}
                        name="city"
                        value={location.city}
                        statePlaceholder="Select a state first"
                        onChange={(e) => {
                        console.log(e);
                          setCity(e.name);
                          }}
                        /> */}
                        <TextField value={City} disabled sx={{width:"220px"}}></TextField>
                        {cityError && (
                          <Typography
                            sx={{
                              fontWeight: "500",
                              fontSize: "14px",
                              marginBottom: "5px",
                              color: "#da6061",
                            }}
                          >
                            City is required
                          </Typography>
                        )}
                        {/* <Select>
                      {getMenuItemsFromArray([
                        "Furnished",
                        "Un-Furnished",
                        "Semi-Furnished",
                      ])}
                    </Select> */}
                      </div>
                    </Stack>
                  </td>
                </tr>

                <tr style={{ height: "70px" }}>
                  <td>
                    <Stack direction="row" alignItems="center">
                      <div className="ff" style={{ width: "200px" }}>
                        Pincode
                      </div>
                      <div className="ff" style={{ width: "220px" }}>
                        <TextField
                          type="number"
                          value={Pincode}
                          style={{ width: "220px"}}
                          onChange={(e) => {
                            if (e.target.value.length > 6) {
                              return;
                            }
                            setPincode(e.target.value);
                          }}
                        ></TextField>
                      </div>
                    </Stack>
                  </td>
                  <td>
                    <Stack direction="row" alignItems="center">
                      <div className="ff" style={{ width: "200px" }}>
                        Property Age (in Years)
                      </div>
                      <div className="ff" style={{ width: "220px" }}>
                        <TextField
                          value={PropertyAge}
                          type="number"
                          inputProps={{ min: 0 }}
                          style={{ width: "220px"}}
                          onChange={(e) => {
                            setPropertyAge(e.target.value);
                          }}
                        ></TextField>
                      </div>
                    </Stack>
                  </td>
                </tr>

                <tr style={{ height: "70px" }}>
                  <td>
                    <Stack direction="row" alignItems="center">
                      <div className="ff" style={{ width: "200px" }}>
                        Condition
                      </div>
                      <div className="ff" style={{ width: "220px" }}>
                        <Select
                          onChange={(e) => {
                            //@ts-ignore
                            setCondition(e.target.value);
                          }}
                        >
                          {getMenuItemsFromArray([
                            "Furnished",
                            "Un-Furnished",
                            "Semi-Furnished",
                          ])}
                        </Select>
                      </div>
                    </Stack>
                  </td>
                  <td>
                    <Stack direction="row" alignItems="center">
                      <div className="ff" style={{ width: "200px" }}>
                        Remark
                      </div>
                      <div className="ff" style={{ width: "220px" }}>
                        <TextField
                          value={Remark}
                          style={{ width: "220px"}}
                          onChange={(e) => {
                            setRemark(e.target.value);
                          }}
                        ></TextField>
                      </div>
                    </Stack>
                  </td>
                </tr>

                <th>
                  <td>
                    <h1 style={{ marginLeft: "-10px" }}>Owner Details</h1>
                  </td>
                </th>

                <tr style={{ height: "70px" }}>
                  <td>
                    <Stack direction="row" alignItems="center">
                      <div className="ff" style={{ width: "200px" }}>
                        Name*
                      </div>
                      <div className="ff" style={{ width: "220px" }}>
                        <TextField
                          required
                          type="text"
                          value={OwnerName}
                          style={{ width: "220px"}}
                          onChange={(e) => {
                            setOwnerName(e.target.value);
                          }}
                        ></TextField>
                      </div>
                    </Stack>
                  </td>
                  <td>
                    <Stack direction="row" alignItems="center">
                      <div className="ff" style={{ width: "200px" }}>
                        Company
                      </div>
                      <div className="ff" style={{ width: "220px" }}>
                        <TextField
                          type="text"
                          value={OwnerCompany}
                          style={{ width: "220px"}}
                          onChange={(e) => {
                            setOwnerCompany(e.target.value);
                          }}
                        ></TextField>
                      </div>
                    </Stack>
                  </td>
                </tr>

                <tr style={{ height: "70px" }}>
                  <td>
                    <Stack direction="row" alignItems="center">
                      <div className="ff" style={{ width: "200px" }}>
                        Phone*
                      </div>
                      <div className="ff" style={{ width: "220px" }}>
                        <TextField
                          required
                          value={OwnerPhone}
                          type="number"
                          inputProps={{ minLength: 10, maxLength: 10 }}
                          style={{ width: "220px"}}
                          onChange={(e) => {
                            if (e.target.value.length > 10) {
                              return;
                            }
                            // setPhoneError(false);
                            setOwnerPhone(e.target.value);
                          }}
                        ></TextField>
                        {phoneError && (
                          <Typography
                            sx={{
                              fontWeight: "500",
                              fontSize: "14px",
                              marginBottom: "5px",
                              color: "#da6061",
                            }}
                          >
                            Phone Number Should be 10 Characters
                          </Typography>
                        )}
                      </div>
                    </Stack>
                  </td>
                  <td>
                    <Stack direction="row" alignItems="center">
                      <div className="ff" style={{ width: "200px" }}>
                        Email*
                      </div>
                      <div className="ff" style={{ width: "220px" }}>
                        <TextField
                          required
                          type="email"
                          value={OwnerEmail}
                          style={{ width: "220px"}}
                          onChange={(e) => {
                            setOwnerEmail(e.target.value);
                          }}
                        ></TextField>
                      </div>
                    </Stack>
                  </td>
                </tr>

                <tr style={{ height: "70px" }}>
                  <td>
                    <Stack direction="row" alignItems="center">
                      <div className="ff" style={{ width: "200px" }}>
                        Contact Person Name
                      </div>
                      <div className="ff" style={{ width: "220px" }}>
                        <TextField
                          type="text"
                          value={OwnerContactPersonName}
                          style={{ width: "220px"}}
                          onChange={(e) => {
                            setOwnerContactPersonName(e.target.value);
                          }}
                        ></TextField>
                      </div>
                    </Stack>
                  </td>
                  <td>
                    <Stack direction="row" alignItems="center">
                      <div className="ff" style={{ width: "200px" }}>
                        Contact Person Number
                      </div>
                      <div className="ff" style={{ width: "220px" }}>
                        <TextField
                          value={OwnerContactPersonNumber}
                          type="number"
                          inputProps={{ minLength: 10, maxLength: 10 }}
                          style={{ width: "220px"}}
                          onChange={(e) => {
                            if (e.target.value.length > 10) {
                              return;
                            }
                            setOwnerContactPersonNumber(e.target.value);
                          }}
                        ></TextField>
                        {phoneContactError && (
                          <Typography
                            sx={{
                              fontWeight: "500",
                              fontSize: "14px",
                              marginBottom: "5px",
                              color: "#da6061",
                            }}
                          >
                            Phone Number Should be 10 Characters
                          </Typography>
                        )}
                      </div>
                    </Stack>
                  </td>
                </tr>

                {/* <tr style={{ height: "80px" }}>
              <td>
                <Stack direction="row" alignItems="center">
                  <div className="ff" style={{ width: "200px" }}>
                    Owner ID
                  </div>
                  <div className="ff" style={{ width: "220px" }}>
                    <TextField
                      value={OwnerID}
                      onChange={(e) => {
                        setOwnerID(e.target.value);
                      }}
                    ></TextField>
                  </div>
                </Stack>
              </td>
            </tr> */}

                <th>
                  <td>
                    <h1 style={{ marginLeft: "-10px" }}>Rating Details</h1>
                  </td>
                </th>

                <tr style={{ height: "70px" }}>
                  <td>
                    <Stack direction="row" alignItems="center">
                      <div className="ff" style={{ width: "200px" }}>
                        Urgency
                      </div>
                      <div className="ff" style={{ width: "220px" }}>
                        <Select
                          onChange={(e) => {
                            //@ts-ignore
                            setRatingUrgency(e.target.value);
                          }}
                        >
                          {getMenuItemsFromArray(["Low", "Medium", "High"])}
                        </Select>
                        {/* <TextField
                      value={RatingUrgency}
                      onChange={(e) => {
                        setRatingUrgency(e.target.value);
                      }}
                    ></TextField> */}
                      </div>
                    </Stack>
                  </td>
                  <td>
                    <Stack direction="row" alignItems="center">
                      <div className="ff" style={{ width: "200px" }}>
                        Cost
                      </div>
                      <div className="ff" style={{ width: "220px" }}>
                        <Select
                          onChange={(e) => {
                            //@ts-ignore
                            setRatingCost(e.target.value);
                          }}
                        >
                          {getMenuItemsFromArray(["Low", "Medium", "High"])}
                        </Select>
                        {/* <TextField
                      value={RatingCost}
                      onChange={(e) => {
                        setRatingCost(e.target.value);
                      }}
                    ></TextField> */}
                      </div>
                    </Stack>
                  </td>
                </tr>

                <tr style={{ height: "70px" }}>
                  <td>
                    <Stack direction="row" alignItems="center">
                      <div className="ff" style={{ width: "200px" }}>
                        Property
                      </div>
                      <div className="ff" style={{ width: "220px" }}>
                        <Select
                          onChange={(e) => {
                            //@ts-ignore
                            setRatingProperty(e.target.value);
                          }}
                        >
                          {getMenuItemsFromArray(["Low", "Medium", "High"])}
                        </Select>
                        {/* <TextField
                      value={RatingProperty}
                      onChange={(e) => {
                        setRatingProperty(e.target.value);
                      }}
                    ></TextField> */}
                      </div>
                    </Stack>
                  </td>
                  <td>
                    <Stack direction="row" alignItems="center">
                      <div className="ff" style={{ width: "200px" }}>
                        Added By
                      </div>
                      <div className="ff" style={{ width: "220px" }}>
                        <TextField
                          disabled
                          value={AddedBy}
                          style={{ width: "220px"}}
                          // onChange={(e) => {
                          //   setAddedBy(e.target.value);
                          // }}
                        ></TextField>
                      </div>
                    </Stack>
                  </td>
                </tr>
              </table>

              <Stack
                direction="row"
                sx={{
                  margin: "20px 0px 0px 5px",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                {/* <Typography className="ff">Media</Typography> */}
                <Button
                  onClick={handleOpen}
                  className="univercel_btn_class"
                  sx={{
                    textTransform: "none",
                    color: "#FFF",
                    fontSize: "18px",
                    cursor: "pointer",
                    px: "25px",
                  }}
                >
                  Add Media
                </Button>
              </Stack>

              <Box
                sx={{
                  display: "flex",
                  flexWrap: "wrap",
                  margin: "30px 0px 0px 0px",
                }}
              >
                {localmedia?.map((ele, idx) => {
                  return (
                    <Stack
                      direction="row"
                      sx={{
                        alignItems: "center",
                        width: "25%",
                        margin: "10px 10px 20px 0px",
                      }}
                    >
                      <Box
                        sx={{
                          width: "100px",
                          height: "100px",
                          borderRadius: "20px",
                          backgroundColor: "#D9D9D9",
                        }}
                      >
                        <img
                          src={Apiaddress + ele.image}
                          style={{
                            height: "100%",
                            width: "100%",
                            borderRadius: "20px",
                          }}
                        ></img>
                      </Box>
                      <input
                        type="radio"
                        id="r1"
                        name="rate"
                        value="true"
                        style={{ width: "30px", marginBottom: "4px" }}
                        checked={ele?.istrue === "true"}
                        onChange={(e) => {
                          handlechange(e, idx);
                        }}
                      ></input>
                      <Typography
                        sx={{
                          color: "#A4A4AD",
                          fontSize: "12px",
                          fontWeight: 500,
                          marginRight: "10px",
                        }}
                      >
                        Highlight
                      </Typography>
                      <DeleteIcon
                        sx={{
                          fontSize: "26px",
                          fontWeight: "500",
                          color: "#EF3649",
                          margin: "0px 0px 0px 0px",
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          deleteMedia(ele.id);
                          deleteMediaLocal(ele.id);
                        }}
                      />
                    </Stack>
                  );
                })}
              </Box>
            </Stack>

            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "end",
                margin: "30px",
              }}
            >
              <Button
                // onClick={saveUnit}
                type="submit"
                sx={{
                  padding: "6px 40px",
                  color: "#06386B",
                  textTransform: "none",
                  fontSize: "20px",
                  fontFamily: "Product Sans",
                  background:
                    "linear-gradient(97.85deg, #FFAF2A 0.95%, #FFC666 100%)",
                  "&:hover": {
                    boxShadow: "0px 9px 12px rgba(242, 178, 73, 0.59)",
                  },
                }}
              >
                Submit
              </Button>
            </div>
          </div>
        </form>
      </SideBar>

      {/* modal */}
      <Modal
        open={open}
        onClose={() => {
          handleClose();
          setimg("");
          setText("");
          setImage("");
          setImgFlag(false);
        }}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
        <Box sx={{ ...style, width: 400, height: 420 }}>
          <Stack
            justifyContent="center"
            alignItems="center"
            sx={{ height: "100%" }}
          >
            {/* <Stack
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              mb: "20px",
            }}
          >
            <Button
              className="univercel_btn_class"
              variant="contained"
              sx={{ padding: "9px 26px 9px 26px" }}
              component="label"
              startIcon={
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M2 16C1.45 16 0.979333 15.8043 0.588 15.413C0.196 15.021 0 14.55 0 14V11H2V14H14V11H16V14C16 14.55 15.8043 15.021 15.413 15.413C15.021 15.8043 14.55 16 14 16H2ZM7 12V3.85L4.4 6.45L3 5L8 0L13 5L11.6 6.45L9 3.85V12H7Z"
                    fill="white"
                  />
                </svg>
              }
            >
              Upload
              <input
                onChange={imgHandler}
                type="file"
                accept="image/*"
                hidden
              />
            </Button>
          </Stack> */}

            <Typography sx={{ margin: "10px 0px", fontSize: "22px" }}>
              Add Media
            </Typography>
            <Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                {image == "" ? (
                  <Box
                    sx={{
                      width: "230px",
                      height: "180px",
                      border: image === "" ? "3px dashed #D7D7D7" : "none",
                      borderRadius: "16px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      flexDirection: "column",
                      gap: "5px",
                    }}
                  >
                    <Button
                      component="label"
                      sx={{
                        width: "80px",
                        height: "80px",
                        borderRadius: "50%",
                        border: "2px dashed #D7D7D7",
                        display: "flex",
                        justifyContent: "center",
                        "&:hover": {
                          backgroundColor: "#FFF",
                        },
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <svg
                          width="25"
                          height="26"
                          viewBox="0 0 25 26"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M3.27998 25.2535C2.42931 25.2535 1.70135 24.9509 1.09609 24.3456C0.489792 23.7393 0.186646 23.0108 0.186646 22.1602V17.5202H3.27998V22.1602H21.84V17.5202H24.9333V22.1602C24.9333 23.0108 24.6307 23.7393 24.0254 24.3456C23.4191 24.9509 22.6906 25.2535 21.84 25.2535H3.27998ZM11.0133 19.0668V6.4615L6.99198 10.4828L4.82665 8.24017L12.56 0.506836L20.2933 8.24017L18.128 10.4828L14.1066 6.4615V19.0668H11.0133Z"
                            fill="#D9D9D9"
                          />
                        </svg>
                      </Box>
                      <input
                        type="file"
                        accept="image/*"
                        hidden
                        onChange={imgHandler}
                      />
                    </Button>
                    <Typography sx={{ color: "#717579" }}>
                      Upload Media
                    </Typography>
                  </Box>
                ) : (
                  <Stack direction="column">
                    <Box
                      sx={{
                        width: "230px",
                        height: "180px",
                        border: "3px dashed #D7D7D7",
                        borderRadius: "16px",
                        marginBottom: "20px",
                      }}
                    >
                      <img
                        src={image}
                        style={{
                          width: "100%",
                          height: "100%",
                          borderRadius: "16px",
                        }}
                      />
                    </Box>
                    {/* <Box
                              sx={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                marginTop: "10px",
                              }}
                            >
                              <Button
                                component="label"
                                sx={{
                                  color: "#B0AFAF",
                                  fontSize: "18px",
                                  fontWeight: 500,
                                  fontFamily: "Product Sans",
                                  cursor: "pointer",
                                  textTransform: "none",
                                }}
                              >
                                <input
                                  type="file"
                                  accept="image/*"
                                  hidden
                                  onChange={(e) => {
                                    let url = URL.createObjectURL(
                                      e.target.files[0]
                                    );
                                    setImage(url);
                                    setSendImage(e.target.files[0]);
                                  }}
                                />
                                Edit
                              </Button>
                            </Box> */}
                  </Stack>
                )}
              </Box>
            </Box>

            {imgflag && (
              <>
                <Stack
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <TextField
                    value={text}
                    onChange={(e) => {
                      setText(e.target.value);
                    }}
                    placeholder="Add Name"
                  />
                </Stack>

                <Stack
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    mt: "20px",
                  }}
                >
                  <Button
                    onClick={onSubmitMedia}
                    className="univercel_btn_class"
                    variant="contained"
                    sx={{ padding: "9px 26px 9px 26px" }}
                    component="label"
                  >
                    Save
                  </Button>
                </Stack>
              </>
            )}
          </Stack>
        </Box>
      </Modal>

      {/* Image SuccessFul Modal */}
      <Modal
        open={openSucc}
        onClose={() => {
          handleCloseSucc();
        }}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
        <Box sx={{ ...style, width: 250, height: 130 }}>
          <Stack
            justifyContent="center"
            alignItems="center"
            sx={{ height: "100%" }}
          >
            <Typography sx={{ fontSize: "18px" }}>{msg}</Typography>
            <Stack
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                mt: "20px",
              }}
            >
              <Button   
                onClick={handleCloseSucc}
                className="univercel_btn_class"
                variant="contained"
                sx={{ padding: "9px 26px 9px 26px" }}
                component="label"
              >
              Continue
              </Button>
            </Stack>
          </Stack>
        </Box>
      </Modal>
    </div>
  );
}

{
  /* <Button
                            onClick={async () => {
                                const load = {
                                    "For": For,
                                    "Type": Type,
                                    "Area": Area,
                                    "Configuration": Configuration,
                                    "ProjectType": ProjectType,
                                    "QuotingPrice": QuotingPrice,
                                    "reraNumber": reraNumber,
                                    "City": City,
                                    "Address": Address,
                                    "Pincode": Pincode,
                                    "PropertyAge": PropertyAge,
                                    "Condition": Condition,
                                    "Remark": Remark,
                                    "OwnerName": OwnerName,
                                    "OwnerCompany": OwnerCompany,
                                    "OwnerPhone": OwnerPhone,
                                    "OwnerEmail": OwnerEmail,
                                    "OwnerContactPersonName": OwnerContactPersonName,
                                    "OwnerContactPersonNumber": OwnerContactPersonNumber,
                                    "OwnerID": OwnerID,
                                    "RatingUrgency": RatingUrgency,
                                    "RatingCost": RatingCost,
                                    "RatingProperty": RatingProperty,  
                                    "AddedBy": AddedBy,
                                    "media":localmedia  
                                }

                                const url= Apiaddress +`/api/projects/projectCpUnit/?project_id=${id}`;
                                    const Auth = new AuthService();
                                    const res = await axios.patch(url,{
                                        "unit_data": JSON.stringify(load),
                                        "name": name,
                                        "cp_id": Auth.getCPID()
                                    });
                                    console.log("===========================.>",res?.data);
                                nav("/channelpartner/project/unit/")
                            }}
                            className="univercel_btn_class"
                            sx={{ padding: "10px 30px 8px 30px", color: "#fff",marginRight:"35px"}}>
                            Submit
                        </Button> */
}

export default AddUnitToProject;
