import {
  Box,
  Checkbox,
  Container,
  Divider,
  FormControl,
  FormControlLabel,
  Stack,
  styled,
  Typography,
  useRadioGroup,
} from "@mui/material";

import { flexBetween, itemsCenter } from "./styles/commonStyles";
import classes from "./styles/PageOne.module.css";
import img from "../../Imgs/img.jpg";
import { useState } from "react";

import "./styles/PageFive.css";

const PdfOnePageFive = ({unitInfo,projectInfo}) => {
  
  const [pageData5,setPageData5]=useState({
    tower:"",
    type:"",
    carpet_area_sq:"",
    carpet_area_sqm:"",
    add_area_sq:"",
    add_area_sqm:"",
    parking_req:"",
    allot_prem:"",
    direct:"",
    corporate:"",
    referral:"",
    referral_contact:"",
    pro_contact:"",
    cp_name:"",
    cp_contact:"",
    company:"",
    repsId:"",
    source_sign:"",
    app_money_fig:"",
    app_money_word:"",
    draft_no:"",
    bank_name:"",
    bank_date:"",
    bank_favour:"",
    bank_allot_money:"",
    app_allot_gst:"",
    app_allot_gst_word:"",
    gst_draft_no:"",
    gst_bank_name:"",
    gst_bank_date:"",
    gst_bank_favour:"",
    gst_allot_money:"",
    sign_first:"",
    sign_second:"",
    sign_third:""
  })

  const handleChange=(e)=>{
    let name=e.target.name;
    let value=e.target.value;
    console.log(name," ",value);
    setPageData5({...pageData5,[name]:value});
  }

  const [paymentData1,setPaymentData1]=useState({
    due_date:"",
    percent:"",
    amount:"",
  })

  const paymentChange1=(e)=>{
    let name=e.target.name;
    let value=e.target.value;
    console.log(name," ",value);
    setPaymentData1({...paymentData1,[name]:value});
  }

   const [paymentData2,setPaymentData2]=useState({
    due_date:"",
    percent:"",
    amount:"",
  })

  const paymentChange2=(e)=>{
    let name=e.target.name;
    let value=e.target.value;
    console.log(name," ",value);
    setPaymentData2({...paymentData2,[name]:value});
  }
  
   const [paymentData3,setPaymentData3]=useState({
    due_date:"",
    percent:"",
    amount:"",
  })

  const paymentChange3=(e)=>{
    let name=e.target.name;
    let value=e.target.value;
    console.log(name," ",value);
    setPaymentData3({...paymentData3,[name]:value});
  }
  
   const [paymentData4,setPaymentData4]=useState({
    due_date:"",
    percent:"",
    amount:"",
  })

  const paymentChange4=(e)=>{
    let name=e.target.name;
    let value=e.target.value;
    console.log(name," ",value);
    setPaymentData4({...paymentData4,[name]:value});
  }

  return (
    <Box>
      <Box sx={{ mb: "40px" }}>
        <Box>
          <Box sx={{ width: "80%" }}>
            <Box className={classes.input}>
              <label htmlFor="">Ref. No.</label>
              <input value={""} type="number" />
            </Box>

            <Box sx={{ display: "flex", gap: "10px", mt: "5px", ml: "20px" }}>
              <Typography sx={{ fontSize: "10px" }}>5.</Typography>
              <Typography sx={{ fontSize: "10px" }}>
                DETAILS OF HOME APPLIED FOR AT AGRAWALS FINOPOLIS
              </Typography>
            </Box>
          </Box>
          <Box sx={{ ...itemsCenter, gap: "10px" }}>
            <Box className={classes.input}>
              <label htmlFor="">Tower:</label>
              <input style={{ width: "120px" }} value={pageData5.tower} type="text" name="tower" onChange={handleChange}/>
            </Box>
            <Box className={classes.input}>
              <label htmlFor="">Type:</label>
              <input style={{ width: "120px" }} value={pageData5.type} type="text" name="type" onChange={handleChange}/>
            </Box>
            <Box className={classes.input}>
              <label htmlFor="">Floor:</label>
              <input style={{ width: "120px" }} value={unitInfo?.floor_no} type="text"/>
            </Box>
            <Box className={classes.input}>
              <label htmlFor="">Flat No:</label>
              <input style={{ width: "120px" }} value={unitInfo?.unit_no} type="text" />
            </Box>
          </Box>
          <Box sx={{ ...itemsCenter, gap: "10px" }}>
            <Box className={classes.input}>
              <label htmlFor="">Carpet Area (sq.ft):</label>
              <input style={{ width: "120px" }} value={pageData5.carpet_area_sq} type="number" name="carpet_area_sq" onChange={handleChange}/>
            </Box>
            <Box className={classes.input}>
              <label htmlFor="">(sq.mtrs):</label>
              <input style={{ width: "120px" }} value={pageData5.carpet_area_sqm} type="number" name="carpet_area_sqm" onChange={handleChange} />
            </Box>
            <Box className={classes.input}>
              <label htmlFor="">No. of Parking (s) Requested:</label>
              <input style={{ width: "120px" }} value={pageData5.parking_req} type="number" name="parking_req" onChange={handleChange} />
            </Box>
          </Box>
          <Box className={classes.input}>
            <label htmlFor="">Additional Area: Additional Area: </label>
            <input style={{ width: "120px" }} value={pageData5.add_area_sq} type="number" name="add_area_sq" onChange={handleChange}/>
            <label htmlFor="">sq.ft. equivalent to</label>
            <input style={{ width: "120px" }} value={pageData5.add_area_sqm} type="number" name="add_area_sqm" onChange={handleChange}/>
            <label htmlFor="">sq.mtrs.</label>
          </Box>
          <Typography sx={{ fontSize: "10px" }}>
            (Enclosed Balcony, FB, Exclusive Balcony, Deck, whichever is
            applicable){" "}
          </Typography>
          <Box className={classes.input}>
            <label htmlFor="" style={{ fontSize: "12px" }}>
              Allotment Premium (Excluding tax) (In Figures):
            </label>
            <input
              style={{ width: "60%", margin: "0px", textAlign: "center" }}
              value={pageData5.allot_prem}
              type="number"
              name="allot_prem"
              onChange={handleChange}
            />
          </Box>
        </Box>
        <Box>
          <Box sx={{ width: "80%" }}>
            <Box className={classes.input}>
              <label htmlFor="">Ref. No.</label>
              <input value={""} type="number"/>
            </Box>

            <Box sx={{ display: "flex", gap: "10px", mt: "5px", ml: "20px" }}>
              <Typography sx={{ fontSize: "10px" }}>6.</Typography>
              <Typography sx={{ fontSize: "10px" }}>
                SOURCE OF BOOKING
              </Typography>
            </Box>
          </Box>
          <Box>
            <Box className={classes.input}>
              <label htmlFor="">Direct</label>
              <span style={{ marginLeft: "20px" }}>
                <label htmlFor="">Please specify:</label>
                <input style={{ width: "120px" }} value={pageData5.direct} type="text" name="direct" onChange={handleChange}/>
              </span>
            </Box>
            <Box className={classes.input}>
              <label htmlFor="">Corporate</label>
              <span style={{ marginLeft: "20px" }}>
                <label htmlFor="">Please specify:</label>
                <input style={{ width: "120px" }} value={pageData5.corporate} type="text" name="corporate" onChange={handleChange}/>
              </span>
            </Box>
            <Box className={classes.input} sx={{ display: "flex" }}>
              <label htmlFor="">Referral</label>
              <span style={{ marginLeft: "20px", marginTop: "-5px" }}>
                <Box sx={{ ...itemsCenter, gap: "10px" }}>
                  <Box className={classes.input}>
                    <label htmlFor="">*Source Name:</label>
                    <input
                      style={{ width: "150px" }}
                      value={pageData5.referral}
                      type="text"
                      name="referral"
                      onChange={handleChange}
                    />
                  </Box>
                  <Box className={classes.input}>
                    <label htmlFor="">#Contact No.</label>
                    <input
                      style={{ width: "150px" }}
                      value={pageData5.referral_contact}
                      type="number"
                      name="referral_contact"
                      onChange={handleChange}
                    />
                  </Box>
                </Box>
              </span>
            </Box>

            <Box sx={{ ...itemsCenter, gap: "10px", marginLeft: "20px" }}>
              <Box className={classes.input}>
                <label htmlFor="">Project:</label>
                <input style={{ width: "150px" }} value={projectInfo?.project_name} type="text" />
              </Box>
              <Box className={classes.input}>
                <label htmlFor="">#Contact No.</label>
                <input style={{ width: "150px" }} value={pageData5.pro_contact} type="number" name="pro_contact" onChange={handleChange}/>
              </Box>
            </Box>

            <Box className={classes.input}>
              <label htmlFor="">Channel Partner </label>
              <span style={{ marginLeft: "20px" }}>
                <label htmlFor="">Source Name:</label>
                <input style={{ width: "120px" }} value={pageData5.cp_name} type="text" name="cp_name" onChange={handleChange}/>
              </span>
              <span>
                <label htmlFor="">#Contact No.</label>
                <input style={{ width: "150px" }} value={pageData5.cp_contact} type="number" name="cp_contact" onChange={handleChange}/>
              </span>
            </Box>
            <Box sx={{ ...itemsCenter, gap: "10px", marginLeft: "20px" }}>
              <Box className={classes.input}>
                <label htmlFor="">Company:</label>
                <input style={{ width: "150px" }} value={pageData5.company} type="text" name="company" onChange={handleChange}/>
              </Box>
              <Box className={classes.input}>
                <label htmlFor="">Reps. ID:</label>
                <input style={{ width: "150px" }} value={pageData5.repsId} type="number" name="repsId" onChange={handleChange}/>
              </Box>
            </Box>
            <Box className={classes.input}>
              <label htmlFor="">Source Signature: </label>
              <input style={{ width: "150px" }} value={pageData5.source_sign} type="text" name="source_sign" onChange={handleChange}/>
            </Box>
          </Box>
        </Box>
        <Box>
          <Box sx={{ width: "80%" }}>
            <Box sx={{ display: "flex", gap: "10px", mt: "5px", ml: "20px" }}>
              <Typography sx={{ fontSize: "10px" }}>7.</Typography>
              <Typography sx={{ fontSize: "10px" }}>
                APPLICATION MONEY DETAILS
              </Typography>
            </Box>
            <Box sx={{ display: "flex", gap: "10px", mt: "5px", ml: "20px" }}>
              <Typography sx={{ fontSize: "10px" }}>1.</Typography>
              <Box>
                <Box className={classes.input} sx={{ mt: "-5px" }}>
                  <label htmlFor="">
                    Application money paid towards Allotment Premium (In
                    Figures): Rs.
                  </label>
                  <input value={pageData5.app_money_fig} type="number" name="app_money_fig" onChange={handleChange}/>
                </Box>
                <Box className={classes.input} sx={{ mt: "-5px" }}>
                  <label htmlFor="">(In words):</label>
                  <input value={pageData5.app_money_word} type="text" name="app_money_word" onChange={handleChange}/>
                </Box>
                <Box className={classes.input} sx={{ mt: "-5px" }}>
                  <label htmlFor="">Demand Draft/Pay Order/Cheque No.:</label>
                  <input value={pageData5.draft_no} type="number" name="draft_no" onChange={handleChange}/>
                </Box>
                <Box className={classes.input} sx={{ mt: "-5px" }}>
                  <label htmlFor="">Drawn on (Bank Name):</label>
                  <input value={pageData5.bank_name} type="text" name="bank_name" onChange={handleChange}/>
                </Box>
                <Box className={classes.input} sx={{ display: "flex" }}>
                  <Box>
                    <label htmlFor="" style={{ fontSize: "12px" }}>
                      Date:
                    </label>
                    <Box sx={{ display: "inline" }}>
                      <input
                        style={{ margin: "0px", textAlign: "center" }}
                        value={pageData5.bank_date}
                        type="date"
                        name="bank_date"
                        onChange={handleChange}
                      />
                      <Typography
                        sx={{ textAlign: "center", fontSize: "10px" }}
                      >
                        (dd/mm/yy)
                      </Typography>
                    </Box>
                  </Box>
                  <Box>
                    <label htmlFor="" style={{ fontSize: "12px" }}>
                      in favour of
                    </label>

                    <input
                      style={{ margin: "0px", textAlign: "center" }}
                      value={pageData5.bank_favour}
                      type="text"
                      name="bank_favour"
                      onChange={handleChange}
                    />
                  </Box>
                </Box>
                <Box className={classes.input} sx={{ mt: "-5px" }}>
                  <label htmlFor="">
                    Application money paid towards Allotment Premium (In
                    Figures): Rs.
                  </label>
                  <input value={pageData5.bank_allot_money} type="number" name="bank_allot_money" onChange={handleChange}/>
                </Box>
              </Box>
            </Box>
            <Box sx={{ display: "flex", gap: "10px", mt: "5px", ml: "20px" }}>
              <Typography sx={{ fontSize: "10px" }}>2.</Typography>
              <Box>
                <Box className={classes.input} sx={{ mt: "-5px" }}>
                  <label htmlFor="">
                    Application money paid towards GST (In Figures): Rs.
                  </label>
                  <input value={pageData5.app_allot_gst} type="number" name="app_allot_gst" onChange={handleChange}/>
                </Box>
                <Box className={classes.input} sx={{ mt: "-5px" }}>
                  <label htmlFor="">(In words):</label>
                  <input value={pageData5.app_allot_gst_word} type="text" name="app_allot_gst_word" onChange={handleChange}/>
                </Box>
                <Box className={classes.input} sx={{ mt: "-5px" }}>
                  <label htmlFor="">Demand Draft/Pay Order/Cheque No.:</label>
                  <input value={pageData5.gst_draft_no} type="number" name="gst_draft_no" onChange={handleChange}/>
                </Box>
                <Box className={classes.input} sx={{ mt: "-5px" }}>
                  <label htmlFor="">Drawn on (Bank Name):</label>
                  <input value={pageData5.gst_bank_name} type="text" name="gst_bank_name" onChange={handleChange}/>
                </Box>
                <Box className={classes.input} sx={{ display: "flex" }}>
                  <Box>
                    <label htmlFor="" style={{ fontSize: "12px" }}>
                      Date:
                    </label>
                    <Box sx={{ display: "inline" }}>
                      <input
                        style={{ margin: "0px", textAlign: "center" }}
                        value={pageData5.gst_bank_date}
                        type="date"
                        name="gst_bank_date"
                        onChange={handleChange}
                      />
                      <Typography
                        sx={{ textAlign: "center", fontSize: "10px" }}
                      >
                        (dd/mm/yy)
                      </Typography>
                    </Box>
                  </Box>
                  <Box>
                    <label htmlFor="" style={{ fontSize: "12px" }}>
                      in favour of
                    </label>

                    <input
                      style={{ margin: "0px", textAlign: "center" }}
                      value={pageData5.gst_bank_favour}
                      type="text"
                      name="gst_bank_favour"
                      onChange={handleChange}
                    />
                  </Box>
                </Box>
                <Box className={classes.input} sx={{ mt: "-5px" }}>
                  <label htmlFor="">
                    Application money paid towards Allotment Premium (In
                    Figures): Rs.
                  </label>
                  <input value={pageData5.gst_allot_money} type="number" name="gst_allot_money" onChange={handleChange}/>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>

        <Box>
          <Typography
            sx={{
              fontSize: "12px",
              borderBottom: "2px solid #000",
              display: "inline-block",
            }}
          >
            Payment schedule for the Application Money
          </Typography>
          <table>
            <tr>
              <th>Sr. No.</th>
              <th>Particulars</th>
              <th>Due Date</th>
              <th>% of Allotment Premium</th>
              <th>Amount</th>
            </tr>
            <tr>
              <td>1.</td>
              <td>Application money Part 1</td>
              <td className={classes.input}>
                <input
                  style={{ margin: "0px", textAlign: "left"}}
                  value={paymentData1.due_date}
                  type="date"
                  name="due_date"
                  onChange={paymentChange1}
                />
              </td>
              <td className={classes.input}>
                <input
                  style={{ margin: "0px", textAlign: "left"}}
                  value={paymentData1.percent}
                  type="number"
                  name="percent"
                  onChange={paymentChange1}
                />
              </td>
              <td className={classes.input}>
                <input
                  style={{ margin: "0px", textAlign: "left"}}
                  value={paymentData1.amount}
                  type="number"
                  name="amount"
                  onChange={paymentChange1}
                />
              </td>
            </tr>
            <tr>
              <td>2</td>
              <td>Application money Part 2</td>
              <td className={classes.input}>
                <input
                  style={{ margin: "0px", textAlign: "left"}}
                  value={paymentData2.due_date}
                  type="date"
                  name="due_date"
                  onChange={paymentChange2}
                />
              </td>
              <td className={classes.input}>
                <input
                  style={{ margin: "0px", textAlign: "left"}}
                  value={paymentData2.percent}
                  type="number"
                  name="percent"
                  onChange={paymentChange2}
                />
              </td>
              <td className={classes.input}>
                <input
                  style={{ margin: "0px", textAlign: "left"}}
                  value={paymentData2.amount}
                  type="number"
                  name="amount"
                  onChange={paymentChange2}
                />
              </td>
            </tr>
            <tr>
              <td>3</td>
              <td>Application money Part 3</td>
              <td className={classes.input}>
                <input
                  style={{ margin: "0px", textAlign: "left"}}
                  value={paymentData3.due_date}
                  type="date"
                  name="due_date"
                  onChange={paymentChange3}
                />
              </td>
              <td className={classes.input}>
                <input
                  style={{ margin: "0px", textAlign: "left"}}
                  value={paymentData3.percent}
                  type="number"
                  name="percent"
                  onChange={paymentChange3}
                />
              </td>
              <td className={classes.input}>
                <input
                  style={{ margin: "0px", textAlign: "left"}}
                  value={paymentData3.amount}
                  type="number"
                  name="amount"
                  onChange={paymentChange3}
                />
              </td>
            </tr>
            <tr>
              <td></td>
              <td>Total</td>
              <td className={classes.input}>
                <input
                  style={{ margin: "0px", textAlign: "left"}}
                  value={paymentData4.due_date}
                  type="date"
                  name="due_date"
                  onChange={paymentChange4}
                />
              </td>
              <td className={classes.input}>
                <input
                  style={{ margin: "0px", textAlign: "left"}}
                  value={paymentData4.percent}
                  type="number"
                  name="percent"
                  onChange={paymentChange4}
                />
              </td>
              <td className={classes.input}>
                <input
                  style={{ margin: "0px", textAlign: "left"}}
                  value={paymentData4.amount}
                  type="number"
                  name="amount"
                  onChange={paymentChange4}
                />
              </td>
            </tr>
          </table>
        </Box>
        <Box sx={{mt:"10px"}}>
          <Typography sx={{ fontSize: "10px" }}>Notes</Typography>
          <Stack spacing={0.5} sx={{ ml: "10px" }}>
            <Box sx={{ display: "flex", gap: "10px" }}>
              <Typography sx={{ fontSize: "10px" }}>1.</Typography>
              <Typography sx={{ fontSize: "10px" }}>
                The applicable GST, stamp duty and registration charges payable
                on Agreement for Sale shall be paid independently by the
                Applicant as per the demand raised by the Developer. The
                Confirmation Letter will be issued by the Developer to the
                Applicant only after receipt of 10% of Application Money.
              </Typography>
            </Box>
            <Box sx={{ display: "flex", gap: "10px" }}>
              <Typography sx={{ fontSize: "10px" }}>2.</Typography>
              <Typography sx={{ fontSize: "10px" }}>
                The application Money Part 1 and Application Money Part 2 shall
                be within time as per time frame mentioned hereinabove. NRI
                Applicant(s) and Applicant(s) travelling will need to arrange
                for a Power of attorney in advance to ensure timely execution
                and registration of the Agreement for Sale.
              </Typography>
            </Box>
            <Box sx={{ display: "flex", gap: "10px" }}>
              <Typography sx={{ fontSize: "10px" }}>3.</Typography>
              <Typography sx={{ fontSize: "10px" }}>
                The payments of the Application Monies shall be made within time
                as per time frame mentioned in this Application Form without any
                requirement of any demand letter and/ or reminder from the
                Developer. Any delay in making the payments as per the Payment
                Schedule shall attract interest at the interest Rate as set out
                in the Application Form herein below payable from the due date
                for the payament till the date of payment thereof.
              </Typography>
            </Box>
          </Stack>
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          gap: "40px",
          justifyContent: "center",
          position: "absolute",
          bottom: "30px",
        }}
      >
        <Box className={classes.input}>
          <Box>
            <input
              style={{ textAlign: "center" }}
              value={pageData5.sign_first}
              type="text"
              name="sign_first"
              onChange={handleChange}
            />
            <Typography sx={{ textAlign: "center", mt: "10px" }}>
              Signature of First Applicant
            </Typography>
          </Box>
        </Box>
        <Box className={classes.input}>
          <Box sx={{ display: "inline-block" }}>
            <input
              style={{ margin: "0px", textAlign: "center" }}
              value={pageData5.sign_second}
              type="text"
              name="sign_second"
              onChange={handleChange}
            />
            <Typography sx={{ textAlign: "center", mt: "10px" }}>
              Signature of Second Applicant
            </Typography>
          </Box>
        </Box>
        <Box className={classes.input}>
          <Box sx={{ display: "inline-block" }}>
            <input
              style={{ margin: "0px", textAlign: "center" }}
              value={pageData5.sign_third}
              type="text"
              name="sign_third"
              onChange={handleChange}
            />
            <Typography sx={{ textAlign: "center", mt: "10px" }}>
              Signature of Third Applicant
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default PdfOnePageFive;
