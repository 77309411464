import { Box } from "@mui/system";
import React, { useContext, useEffect, useState } from "react";
import profileContext from "../../../../context/profile/context";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import SearchRoundedIcon from "@mui/icons-material/SearchRounded";
import Typography from "@mui/material/Typography";
import { Button, Checkbox, Radio, Stack, TextField } from "@mui/material";
import axios from "axios";
import { api, Apiaddress, net } from "../../../../utils/consts";
import { AuthService } from "../../../../context/buyer/services/auth";
import { useLocation } from "react-router-dom";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

function RightSidebar({ unit, projectid, toggleDrawer, setState }) {
  const [clicked, setClicked] = useState(false);
  const [LastResut, setLastResut] = useState([]);
  // console.log({ unit });
  const initData = {
    name: "",
    phone: "",
    email: "",
  };

  const [Selected, setSelected] = useState(initData);
  const [selectedName,setSelectedName] = useState("");
  const [selectedEmail,setSelectedEmail] = useState("");
  const [selectedPhone,setSelectedPhone] = useState("");
  // // console.log(Selected);

  const [input, setinput] = useState();
  const [selectedValue, setSelectedValue] = useState("email");
  const [error, seterror] = useState(false);

  const handleChangeRadio = (event) => {
    setSelectedValue(event.target.value);
    setSelected(initData);
    setinput("");
    seterror(false);
  };

  const controlProps = (item) => ({
    checked: selectedValue === item,
    onChange: handleChangeRadio,
    value: item,
    name: "color-radio-button-demo",
    inputProps: { "aria-label": item },
  });

  //searching lead of project
  const searchLead = async () => {
    const Auth = new AuthService();
    try{
      let value = input.toLowerCase();
      let url1 = "";
      if (cpOrg) {
        url1 = `/api/leads/channelpartnerSelfleads/?org_id=${cpOrg}&filterkey=${selectedValue}&filtervalue=${value}`
      }
      else {
        url1 = `/api/leads/channelpartnerSelfleads/?channel_partner_id=${Auth.getCPID()}&filterkey=${selectedValue}&filtervalue=${value}`
      }
    const res = await axios.get(
      Apiaddress + url1
    );
    if (res.data.leads.length == 0) {
      setSelected({});
      setSelectedName("");
      setSelectedEmail("");
      setSelectedPhone("");
      seterror(true);
    } else {
      setSelected(res.data.leads[0]);
      setSelectedName(res?.data?.leads[0]?.name);
      setSelectedEmail(res?.data?.leads[0]?.email);
      setSelectedPhone(res?.data?.leads[0]?.phone);
      seterror(false);
    }
    console.log(res.data.leads[0]);
    }catch(err){
      console.log(err?.message);
    }
  };

  //fetching leads
  useEffect(() => {
    const x = async () => {
      const Auth = new AuthService();
      const results = await (
        await Auth.makeAuthenticatedGetRequest(
          api("/api/leads/searchleads/?query=" + "")
        )
      ).json();
      setLastResut(
        results.leads != "Leads not found" && results ? results.leads : []
      );
      // console.log(results.leads);
    };
    x();
  }, []);
  const cpDetails = JSON.parse(localStorage.getItem("cpDetails"));
  // console.log(cpDetails);
  let cpOrg = cpDetails?.channelPartnerOrg;
  // console.log({ cpDetails })
  const userData = JSON.parse(localStorage.getItem("crm_user"));
  let username = userData?.user_details?.first_name;

  const [appointment_time, setAppointment_time] = useState("");
  const [dateError, setDateError] = useState(false);

  const [check, setCheck] = useState([]);

  // console.log(check);
  let temp = [];
  const onCheck = (id) => {
    if (id === "selectAll") {
      temp.push("email");
      temp.push("whatsapp");
      setCheck(temp);
    } else if (id == "removeAll") {
      temp = [];
      setCheck(temp);
    } else if (id == "email") {
      let array = check;
      const index = array.indexOf(id);
      if (index > -1) {
        let temp1 = array.filter((ele) => {
          return ele != check[index];
        });
        temp = temp1;
      } else {
        temp = [...check, id];
      }
      setCheck(temp);
    } else if (id == "whatsapp") {
      let array = check;
      const index = array.indexOf(id);
      if (index > -1) {
        let temp1 = array.filter((ele) => {
          return ele != check[index];
        });
        temp = temp1;
      } else {
        temp = [...check, id];
      }
      setCheck(temp);
      setCheck(temp);
    }
  };

  return (
    <Box
      sx={{
        width: "420px",
        height: "100vh",
        marginTop: "-25px",
        marginRight: "25px",
        padding: "30px",
      }}
    >
      <Box
        sx={{
          position: "relative",
        }}
      >
        <Box
          sx={{
            position: "absolute",
            top: "0px",
            right: "0px",
            background: "white",
            padding: "10px",
            height: "105vh",
            width: "400px",
            marginTop: "20px",
          }}
        >
          {" "}
          <Box>
            <Box
              onClick={() => !setState()}
              sx={{
                display: "flex",
                alignItems: "center",
                gap: "10px",
                cursor: "pointer",
              }}
            >
              <ArrowBackIcon sx={{ ml: "-5px" }} />
              <Typography
                sx={{
                  color: "#2979F2",
                  fontSize: "22px",
                  fontWeight: 600,
                  fontFamily: "Circular Std",
                }}
              >
                Schedule Appointment
              </Typography>
            </Box>
            {/* <Box sx={{ display: "flex", cursor: "pointer" }}
            // onClick={toggleDrawer(false)}
            >
              <Box>
                <ArrowBackIosIcon
                  sx={{
                    fontSize: "small",
                    marginBottom: "-2px",
                  }}
                />
              </Box>
              <Box>New Requirement Form</Box>
            </Box> */}
            <Box
              sx={{
                mt: 4,
                display: "grid",
                alignItems: "center",
                height: "85vh",
              }}
            >
              <Box>
                {/* <AccordionSummary
                    sx={{
                      background: `${expanded === "panel1" ? "#FFAF2A" : "white"
                        }`,
                      borderRadius: "10px",
                    }}
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography color={"white"}>New Requirement Form</Typography>
                  </AccordionSummary> */}
                <Box>
                  <Box>Search Lead Directory</Box>

                  {/* SearchBar */}
                  <Box>
                    <Stack
                      direction="row"
                      justifyContent="start"
                      alignItems="center"
                      sx={{ margin: "20px 0px 20px -10px" }}
                    >
                      <Radio
                        required
                        name="search_type"
                        {...controlProps("email")}
                        sx={{
                          color:
                            "linear-gradient(264.48deg, #79F1A4 -45.37%, #0E5CAD 88.97%)",
                          color: "#0F5DAE",
                        }}
                      />
                      <Typography
                        sx={{
                          fontFamily: "Product Sans",
                          fontStyle: "normal",
                          fontWeight: "500",
                          fontSize: "18px",
                          lineHeight: "21px",
                          color: "#000000",
                          marginRight: "20px",
                        }}
                      >
                        Email
                      </Typography>
                      <Radio
                        required
                        name="search_type"
                        {...controlProps("phone")}
                        sx={{
                          color:
                            "linear-gradient(264.48deg, #79F1A4 -45.37%, #0E5CAD 88.97%)",
                          color: "#0F5DAE",
                        }}
                      />
                      <Typography
                        sx={{
                          fontFamily: "Product Sans",
                          fontStyle: "normal",
                          fontWeight: "500",
                          fontSize: "18px",
                          lineHeight: "21px",
                          color: "#000000",
                        }}
                      >
                        Phone Number
                      </Typography>
                    </Stack>
                  </Box>

                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "start",
                      alignItems: "center",
                      color: "#0065FE",
                      width: "100%",
                    }}
                  >
                    <TextField
                      onChange={(e) => {
                        setinput(e.target.value);
                      }}
                      value={input}
                      placeholder={
                        selectedValue == "email"
                          ? "Search by Email"
                          : "Search by Phone"
                      }
                      style={{ background: "#FFF!important" }}
                      sx={{
                        // border: "1px solid #2979F2",
                        width: "80%",
                        "& .MuiOutlinedInput-root": {
                          "&:hover fieldset": {
                            borderColor: "#2979F2",
                          },
                        },
                      }}
                      InputProps={{
                        startAdornment: (
                          <SearchRoundedIcon
                            sx={{
                              color: "#2979F2",
                              ml: 1,
                            }}
                          />
                        ),
                      }}
                    />
                    <Button
                      // variant="contained"
                      className="univercel_btn_class"
                      sx={{
                        // marginRight:"30px",
                        color: "#FFFFFF",
                        textTransform: "none",
                        fontFamily: "Product Sans",
                        borderRadius: "0px 10px 10px 0px",
                        ml: "-1.5px",
                      }}
                      onClick={searchLead}
                    >
                      <Box sx={{ textTransform: "capitalize", margin: "6px" }}>
                        Search
                      </Box>
                    </Button>
                  </Box>

                  {error && (
                    <Typography
                      sx={{
                        fontFamily: "Poppins",
                        fontStyle: "normal",
                        fontWeight: "500",
                        fontSize: "14px",
                        lineHeight: "21px",
                        color: "#FF6140",
                        margin: "7px 0px 0px 10px",
                      }}
                    >
                      No Lead Found
                    </Typography>
                  )}
                  <Box sx={{ mt: 2 }}>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        width: "400px",
                        alignItems: "center",
                        margin: "24px 0",
                      }}
                    >
                      <Typography
                        sx={{
                          color: "#84828c",
                          fontWeight: 500,
                          fontFamily: "Product Sans",
                        }}
                      >
                        Name
                      </Typography>
                      <Box>
                        <TextField
                          value={selectedName}
                          disabled
                          id="outlined-basic"
                          // onChange={(e) => setName(e.target.value)}
                        />
                      </Box>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        width: "400px",
                        alignItems: "center",
                        margin: "24px 0",
                      }}
                    >
                      <Box
                        sx={{
                          color: "#84828c",
                          fontWeight: 500,
                          fontFamily: "Product Sans",
                        }}
                      >
                        Phone Number
                      </Box>
                      <Box>
                        <TextField
                          value={selectedPhone}
                          // placeholder="+91 9999999999"
                          id="outlined-basic"
                          disabled
                          // onChange={(e) => setPhne(e.target.value)}
                        />
                      </Box>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        width: "400px",
                        alignItems: "center",
                        margin: "24px 0",
                      }}
                    >
                      <Box
                        sx={{
                          color: "#84828c",
                          fontWeight: 500,
                          fontFamily: "Product Sans",
                        }}
                      >
                        Email
                      </Box>
                      <Box>
                        <TextField
                          value={selectedEmail}
                          id="outlined-basic"
                          disabled
                          // onChange={(e) => setEmail(e.target.value)}
                        />
                      </Box>
                    </Box>
                    <Box
                      sx={{
                        border: "1px solid #9299BC",
                        height: "0px",
                        my: 2,
                      }}
                    ></Box>

                    {/* <Box>
                        <TextField
                          value={Selected?.email}
                          id="outlined-basic"
                          disabled
                          onChange={(e) => setEmail(e.target.value)}
                        />
                      </Box> */}
                    {/* </Box> */}
                    {/* <Stack
                        direction="row"
                        justifyContent="end"
                        sx={{ paddingRight: "20px" }}
                      >
                        <Stack
                          direction="row"
                          justifyContent="center"
                          alignItems="center"
                        >
                          <Checkbox
                            checked={check.indexOf("email") > -1 ? true : false}
                            onClick={() => {
                              onCheck("email");
                            }}
                          ></Checkbox>
                          <Box sx={{ marginRight: "20px" }}>Email</Box>
                        </Stack>
                        <Stack
                          direction="row"
                          justifyContent="center"
                          alignItems="center"
                        >
                          <Checkbox
                            checked={
                              check.indexOf("whatsapp") > -1 ? true : false
                            }
                            onClick={() => {
                              onCheck("whatsapp");
                            }}
                          ></Checkbox>
                          <Box>Whatsapp</Box>
                        </Stack>
                      </Stack> */}
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        width: "400px",
                        alignItems: "center",
                        margin: "24px 0",
                      }}
                    >
                      <Box
                        sx={{
                          color: "#84828c",
                          fontWeight: 500,
                          fontFamily: "Product Sans",
                        }}
                      >
                        Date & Time
                      </Box>
                      <Box>
                        <TextField
                          type="datetime-local"
                          id="outlined-basic"
                          fullWidth
                          // value={}

                          onChange={(e) => {
                            setDateError("");
                            // console.log("date", e.target.value);
                            let enteredDate = new Date(e.target.value);
                            let newDate = new Date();
                            let futureDate = new Date().setFullYear(
                              newDate.getFullYear() + 1
                            );
                            if (enteredDate < newDate) {
                              setDateError("Date / Time cannot be in the past");
                              return;
                            }
                            if (enteredDate > futureDate) {
                              // console.log({ futureDate });
                              // console.log({ enteredDate });
                              setDateError(
                                "Appointment cannot be greater than 1 year"
                              );
                              return;
                            }
                            setAppointment_time(e.target.value);
                          }}
                          sx={{
                            mr: 2,
                            "& .MuiOutlinedInput-root": {
                              "&:hover fieldset": {
                                borderColor: "#2979F2",
                              },
                            },
                          }}
                          // onChange={(e) => setEmail(e.target.value)}
                        />
                        {dateError && (
                          <Typography
                            sx={{
                              fontWeight: "500",
                              fontSize: "14px",
                              marginBottom: "5px",
                              color: "#da6061",
                            }}
                          >
                            {dateError}
                          </Typography>
                        )}
                      </Box>
                    </Box>
                
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "start",
                        alignItems: "center",
                        width: "400px",
                        margin: "10px 0",
                      }}
                     >
                      <Box
                        sx={{
                          color: "#84828c",
                          fontWeight: 500,
                          ml: "6px",
                          fontFamily: "Product Sans",
                        }}
                        >
                        Send form through
                      </Box>
                    </Box>
                    <Stack direction="row">
                      <Stack
                        direction="row"
                        justifyContent="left"
                        alignItems="center"
                      >
                        <Checkbox
                          checked={check.length === 2 ? true : false}
                          onClick={() => {
                            if (check.length === 2) {
                              onCheck("removeAll");
                            } else {
                              onCheck("selectAll");
                            }
                          }}
                        ></Checkbox>
                        <Box sx={{ marginRight: "30px" }}>All</Box>
                      </Stack>
                      <Stack
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                      >
                        <Checkbox
                          checked={check.indexOf("email") > -1 ? true : false}
                          onClick={() => {
                            onCheck("email");
                          }}
                        ></Checkbox>
                        <Box sx={{ marginRight: "30px" }}>Email</Box>
                      </Stack>
                      <Stack
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                      >
                        <Checkbox
                          checked={
                            check.indexOf("whatsapp") > -1 ? true : false
                          }
                          onClick={() => {
                            onCheck("whatsapp");
                          }}
                        ></Checkbox>
                        <Box>Whatsapp</Box>
                      </Stack>
                    </Stack>
                  </Box>
                </Box>

                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    flexDirection: "column",
                    alignItems: "center",
                    gap: "10px",
                  }}
                >
                  <Button
                    disabled={clicked}
                    sx={{
                      padding: "10px 60px",
                      color: "#06386B",
                      textTransform: "none",
                      fontSize: "20px",
                      fontFamily: "Product Sans",
                      mt: 3,
                      background:
                        "linear-gradient(97.85deg, #FFAF2A 0.95%, #FFC666 100%)",
                      "&:hover": {
                        boxShadow: "0px 9px 12px rgba(242, 178, 73, 0.59)",
                      },
                    }}
                    onClick={async () => {
                      setDateError("");
                      if (appointment_time == "") {
                        setDateError("Date / Time is Required");
                      }
                      if (
                        appointment_time == "" ||
                        unit?.unit_data.Address == ""
                      ) {
                        setDateError("Address is required");
                        return;
                      }
                      setClicked(true);
                      if (check.length === 0) {
                        return;
                      }
                      let time = new Date(appointment_time);
                      const Auth = new AuthService();
                      const bo = await (
                        await Auth.makeAuthenticatedPostRequest(
                          api("/api/leads/Appointment/"),
                          {
                            JsonBody: {
                              lead: Selected.id,
                              cp_org_app: cpOrg ? cpOrg : null,
                              channelPartner: cpDetails?.id,
                              appointment_time: time,
                              cpStandaloneUnits: projectid ? [] : [unit.id],
                              cpProjectsUnits: projectid ? [unit.id] : [],
                            },
                          }
                        )
                      ).json();
                      console.log({ id: bo.appointment.id });

                      for (let i = 0; i < check?.length; i++) {
                        if (check[i] === "whatsapp"){
                      const x = await (
                        await Auth.makeUnAuthenticatedPostRequest(
                          net("send-whatsApp"),
                          {
                            JsonBody: {
                              phoneNumber: Selected.phone, //to change to sendto (if rajan)
                              leadName: `${Selected.name}`,
                              type: "appointment",
                              address: unit?.unit_data.Address ?? "",
                              // name: unit?.unit_data.Address,
                              cpName: `${username}`, //to change to cpName
                              cpNumber: `${userData.phone}`,
                              appointment_time: time.toLocaleString(),
                              url: `https://envisionnext.in/channelpartner/appointments/${bo.appointment.id}/fill`,
                              // url: `${window.location.origin}/channelpartner/appointments/${bo.appointment.id}/fill`,
                            },
                          }
                        )
                      ).json();  
                      }
                      else if (check[i] === "email") {
                      // send Email
                      let id=(bo.appointment.id).toString();
                      const xx = await (
                        await Auth.makeAuthenticatedPostRequest(
                          net("leads/sendAppointmentMail/"),
                          {
                            JsonBody: {
                              id:id,
                              // phoneNumber: Selected.phone, //to change to sendto (if rajan)
                              // leadName: `${Selected.name}`,
                              // type: "appointment",
                              // cpName: `${username}`,
                              // cpNumber: `${userData.phone}`,
                              // cpEmail: `${userData.email}`,
                              // appointment_time: time.toLocaleString(),
                              address: unit?.unit_data.Address ?? "",
                              // mail_body: `Hi we have sent you a Appointment form please fill it <a href=https://envisionnext.in/channelpartner/appointments/${bo.appointment.id}/fill> HERE</a>`,
                            },
                          }
                        )
                      ).json();
                      }
                    }
                      setClicked(false);
                      setState(false);
                    }}
                  >
                    {clicked ? "Sending..." : "Send"}
                  </Button>
                  <Box
                    sx={{
                      textAlign: "center",
                      margin: "6px",
                      cursor: "pointer",
                    }}
                    onClick={toggleDrawer(false)}
                  >
                    <Typography
                      sx={{
                        color: "#B0AFAF",
                        fontWeight: 500,
                        fontFamily: "Product Sans",
                      }}
                    >
                      Cancel
                    </Typography>
                  </Box>

                  {/* {curselectedRows && (
                  <Button
                    onClick={saveChanges}
                    className="univercel_btn_class"
                    sx={{
                      width: "200px",
                      color: "#FFFFFF",
                      textTransform: "none",

                      marginBottom: "20px",
                    }}
                  >
                    Update Lead
                  </Button>
                )} */}
                </Box>
              </Box>
            </Box>
            {/* <Box
              sx={{
                placeItems: "center",
                display: "grid",
                marginTop: "-100px",
              }}
            > */}
            {/* <Box>
                {!updateData && (
                  <Button
                    onClick={saveChanges}
                    className="univercel_btn_class"
                    sx={{
                      width: "200px",
                      color: "#FFFFFF",
                      textTransform: "none",

                      marginBottom: "20px",
                    }}
                  >
                    Add Lead
                  </Button>
                )}
                {updateData && (
                  <Button
                    onClick={saveChanges}
                    className="univercel_btn_class"
                    sx={{
                      width: "200px",
                      color: "#FFFFFF",
                      textTransform: "none",

                      marginBottom: "20px",
                    }}
                  >
                    Update Lead
                  </Button>
                )}
              </Box> */}
            {/* </Box> */}
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export default RightSidebar;
