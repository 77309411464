import React, { useContext, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Box } from '@mui/material'
import {Building,Deal,Sale,Trend} from "../assets/icons.js"
import builderDashboard from '../../../../context/builder_dashboard/context';
import axios from 'axios';
import { Apiaddress } from "../../../../utils/consts";

function Infogrid({update, setUpdate}) {

    let  token  = localStorage.getItem("crm_token");
    let  user  = localStorage.getItem("crm_user");
    let parsedObject = JSON.parse(user);
    let user_id=parsedObject?.id;

    const [data ,setData ] = useState([]);
    const [credit,setcredit]=useState();


    const OrganizationsContext = useContext(builderDashboard);
    console.log("=================>",OrganizationsContext.BuilderDashboardState);

    const fetchCredit=async ()=>{
    try{
     const headers = {
        Authorization: `JWT ${token}`,
    };  
    const builderurl = Apiaddress+`/api/role/getbuilder/?profile_id=${user_id}`
    const res = await axios.get(builderurl,{headers});
    console.log("=============================",res.data.builder);
    setcredit(res?.data?.builder?.available_credits);
    } 
    catch(err){
      console.log(err);
    }
    }

   useEffect(()=>{
    if(user_id)
    fetchCredit();
    },[]);

    
    useEffect(() => {

    let totalProjectCount=0;
    let totalUnitCount=0;
    let creditAdd=credit;

      OrganizationsContext.BuilderDashboardState?.map(e => {
        if (e.selected) {
           totalProjectCount=totalProjectCount + e.Projects.length;
           if(e.Projects.length!=0){
              totalUnitCount=totalUnitCount+ e.vaccencyStatus.total_units;
           }   
        }});
        
    const newData = [
    {
        'name':"Active Properties",
        'value':totalProjectCount,
        'image': <Building/>,
        'gradient':"linear-gradient(96.67deg, #0085FF 1.65%, #65C5FF 96.59%)"
    },
    {
        'name':"Available Credits",
        'value':creditAdd,
        'image': <Deal/>,
        'gradient':"linear-gradient(60deg, #FF4267 13.4%, #FF7A93 86.6%)"
    },
    {
        'name':"Units For Sale",
        'value':totalUnitCount,
        'image': <Sale/>,
        'gradient':"linear-gradient(97.85deg, #FFAF2A 0.95%, #FFC666 100%)"
    },
    {
        'name':"Total Revenue",
        'value':"12,000",
        'image': <Trend/>,
        'gradient':"linear-gradient(60deg, #51459E 13.4%, #8473F0 86.6%)"
    },
    ]
    setData(newData);
        
    }, [update,credit]);

   

  return (
   <Box spacing={2.2} sx ={{margin:"10px 0px 20px 0px", display:"flex",justifyContent:"left",width:"45%",flexWrap:"wrap",alignContent:"flex-start"}}>
    {data.map((e)=><InfogridItem gradient={e.gradient} image={e.image} title={e.name} value={e.value}/>)}
   </Box>
  )
}

export default Infogrid


const InfogridItem = props => {
    return <Box sx={{padding:1,zIndex:1, borderRadius: "10px",display:"flex",width:"210px",height:113, color:"white",backgroundColor:"black",flexDirection:"row" ,justifyContent:"space-between",alignItems:"center",backgroundImage:props.gradient,margin:"0px 20px 20px 0px"}}>
    <Box sx={{
        display:"flex",
        flexDirection:"column",
        justifyContent:"center",
        padding:"0px 20px 0px 10px"
    }}>
        <Box sx={{fontSize:18}}>
            {props.title}
        </Box>
        <Box sx={{fontSize:25}}>
            {props.value}
        </Box>
    </Box>
    <Box sx={{marginRight:"10px"}}>
        {props.image}
    </Box>
    </Box> 
  }
  

  
export {InfogridItem}
  
