import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Checkbox,
  Drawer,
  FormControl,
  FormControlLabel,
  FormLabel,
  InputLabel,
  MenuItem,
  Modal,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { Stack } from "@mui/system";
import "./LDmainPage.css";
import { NavbarForDirectory } from "../../../BuilderComponents/ProjectDashboard/DashboardComponents/NavbarForDirectory";
import AddIcon from "@mui/icons-material/Add";
import SideBar from "../../Components/sideBar/SideBar";
import React, { useEffect, useState } from "react";
import { GridCloseIcon } from "@mui/x-data-grid";
import { AuthService } from "../../../../context/buyer/services/auth";
import { api } from "../../../../utils/consts";
import { BasicLayout } from "./basic_layout";
import { GetItem } from "./components/view/ViewReduser";
import FormBuilderModal, {
  getPreviewData,
} from "./components/formBuilderModal/FormBuilderModal";
import FormBuild from "./components/formBuilderModal/FormBuild";
import DeleteIcon from "@mui/icons-material/Delete";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import NavigateBack from "../../../CommonPages/Shared/NavigateBack";
import { GetSection } from "./RequirementFormTemp";

export const UpdateAtIndex = (arr, index, newVal) => {
  // takes in an array and replaces and returns a new array
  let ar = [...arr];
  //@ts-ignore
  ar[index] = newVal;
  return ar;
};

// export const GetSection = (props) => {
//   // console.log(props);
//   const [SectionState, _setSectionState] = useState(props);
//   const setSectionState = (e) => {
//     _setSectionState(e);
//     props.setSuperState(e);
//   };

//   const updateFormFields = (newFormFields) => {
//     setSectionState({
//       ...SectionState,
//       Section: {
//         formFields: newFormFields,
//       },
//     });
//   };
//   // console.log("SectionState", SectionState);
//   return (
//     <Box sx={{ width: "95%" }}>
//       <Stack direction="row">
//         <h2>{SectionState?.Section?.title ?? ""}</h2>
//       </Stack>
//       <Stack spacing={5} sx={{ ml: "30px" }}>
//         {(SectionState?.Section?.formFields ?? []).map((e, index) => {
//           // console.log("basic");
//           return GetItem({
//             ...e,
//             setCurrentValue: (y) => {
//               if (e.type == "normal") {
//                 updateFormFields(
//                   UpdateAtIndex(SectionState.Section.formFields, index, {
//                     ...e,
//                     value: y,
//                   })
//                 );
//               }
//               if (e.type == "withtrailing") {
//                 updateFormFields(
//                   UpdateAtIndex(SectionState.Section.formFields, index, {
//                     ...e,
//                     value: y,
//                   })
//                 );
//               }
//               if (e.type == "radio") {
//                 updateFormFields(
//                   UpdateAtIndex(SectionState.Section.formFields, index, {
//                     ...e,
//                     options: { ...e.options, selected: y },
//                   })
//                 );
//               }

//               if (e.type == "check") {
//                 updateFormFields(
//                   UpdateAtIndex(SectionState.Section.formFields, index, {
//                     ...e,
//                     options: UpdateAtIndex(e.options, y, {
//                       ...e.options[y],
//                       selected: !e.options[y].selected,
//                     }),
//                   })
//                 );
//               }

//               if (e.type == "doubletrailing") {
//                 if (y.index == 1) {
//                   updateFormFields(
//                     UpdateAtIndex(SectionState.Section.formFields, index, {
//                       ...e,
//                       value1: y.value,
//                     })
//                   );
//                 } else {
//                   updateFormFields(
//                     UpdateAtIndex(SectionState.Section.formFields, index, {
//                       ...e,
//                       value2: y.value,
//                     })
//                   );
//                 }
//               }
//             },
//           });
//         })}
//       </Stack>
//     </Box>
//   );
// };

function RequirementFormTempView() {
  const nav = useNavigate();
  let con = useParams().id;
  // console.log(con);

  let { state } = useLocation();
  let { temp } = state;
  // console.log(temp);

  const [basicDetailLayout, setBasicDetailLayout] = useState(BasicLayout);
  const [ExtraSections, setExtraSections] = useState();
  const [data, setdata] = useState();
  const [open, setopen] = useState({ open: false, reason: "" });
  const [FieldN, setFieldN] = useState("");
  const [Selected, setSelected] = useState("");
  const [TemplateData, setTemplateData] = useState([]);
  const [selectTemp, setSelectTemp] = useState("");

  const fetchTemplates = async () => {
    const y = new AuthService();
    const res = await (
      await y.makeAuthenticatedGetRequest(api("/api/leads/reqtemofcp/"))
    ).json();
    // console.log(res.templates);
    if (res.templates != "template not found") {
      setTemplateData(res.templates);
    }
  };

  useEffect(() => {
    fetchTemplates();
    setExtraSections(temp?.other_details);
  }, []);

  const handleAdd = (event) => {
    let temp = event.target.value;
    let details = temp.split("_");

    setBasicDetailLayout(BasicLayout);
    setSelectTemp(event.target.value);
    if (TemplateData[details[1]]?.other_details) {
      setExtraSections(TemplateData[details[1]]?.other_details);
    }
  };

  // // console.log(TemplateData);
  // // console.log(basicDetailLayout);
  // // console.log(ExtraSections);
  // // console.log(selectTemp);

  return (
    <>
      <SideBar active={"Requirements"}>
        <FormBuild
          ExtraSections={ExtraSections}
          setExtraSections={setExtraSections}
          Selected={Selected}
          open={open}
          setopen={setopen}
          setSelected={setSelected}
          con={con}
        ></FormBuild>

        <Stack sx={{ backgroundColor: "#f9f9f9", mt: "20px" }}>
          <Stack>
            <Typography
              sx={{ fontSize: "22px", fontWeight: "600", color: "black" }}
            >
              <NavigateBack fontSize="18px" />
              {temp?.name}
            </Typography>
          </Stack>

          <>
            <Box
              className="hidescrollbar"
              sx={{
                marginTop: "30px",
                width: "98%",
                px: "30px",
                pb: "30px",
                background: "white",
                borderRadius: "10px",
                minHeight: "75vh",
                overflowY: "scroll",
                color: "black",
                boxSizing: "border-box",
                "&:hover": {
                  border: "1px solid #2979F2",
                  boxShadow: "-6px 18px 15px rgba(0, 0, 0, 0.1)",
                },
              }}
            >
              <GetSection Section={basicDetailLayout}></GetSection>
              {ExtraSections?.map((e, idx) => {
                return (
                  <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    sx={{}}
                  >
                    <GetSection Section={e}></GetSection>
                    {con === "edit" && (
                      <DeleteIcon
                        sx={{
                          color: "red",
                          cursor: "pointer",
                          fontSize: "30px",
                          marginBottom: "80px",
                        }}
                        onClick={() => {
                          let temp = ExtraSections?.filter((ele, index) => {
                            if (index !== idx) {
                              return ele;
                            }
                          });
                          // console.log(temp);
                          setExtraSections(temp);
                        }}
                      />
                    )}
                  </Stack>
                );
              })}
              {con !== "view" && (
                <Button
                  onClick={() => {
                    setopen({ reason: "add", open: true });
                  }}
                  className="univercel_btn_class"
                  sx={{
                    marginTop: "30px",
                    color: "#FFFFFF",
                    width: "200px",
                    textTransform: "none",
                    height: "43px",
                    fontFamily: "Product Sans",
                    fontSize: "14px",
                  }}
                >
                  Add Section
                </Button>
              )}
            </Box>
            <Stack
              direction="row"
              justifyContent="space-betwen"
              alignItems="center"
            >
              {con === "edit" && (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "98%",
                  }}
                >
                  <Button
                    onClick={async () => {
                      // setopen({ reason: "save", open: true });
                      const x = new AuthService();
                      await x.makeAuthenticatedPatchRequest(
                        api("/api/leads/requirementtemplate/"),
                        {
                          JsonBody: {
                            template_id: temp?.id,
                            basic_details: BasicLayout,
                            other_details: ExtraSections,
                          },
                        }
                      );
                      nav("/channelpartner/requirements-form");
                    }}
                    sx={{
                      padding: "10px 30px",
                      color: "#06386B",
                      textTransform: "none",
                      mt: 2,
                      fontSize: "20px",
                      fontFamily: "Product Sans",
                      background:
                        "linear-gradient(97.85deg, #FFAF2A 0.95%, #FFC666 100%)",
                      "&:hover": {
                        boxShadow: "0px 9px 12px rgba(242, 178, 73, 0.59)",
                      },
                    }}
                  >
                    Update
                  </Button>
                </div>
              )}
              {/* <Button
                className="univercel_btn_class"
                sx={{
                  color: "#FFFFFF",
                  textTransform: "none",
                  width: "120px",
                  my: "50px",
                }}
                onClick={() => {
                  nav(-1);
                }}
              >
                Back
              </Button> */}
            </Stack>
          </>
        </Stack>
      </SideBar>
    </>
  );
}

export default RequirementFormTempView;
