import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    MenuItem,
    Drawer,
    Box,
    Button,
    Checkbox,
    FormControl,
    InputLabel,
    Select,
    TextField,
    Typography,
  } from "@mui/material";
  import { Stack } from "@mui/system";
  import TuneOutlinedIcon from "@mui/icons-material/TuneOutlined";
  import KeyboardArrowRightOutlinedIcon from "@mui/icons-material/KeyboardArrowRightOutlined";
  import KeyboardArrowUpOutlinedIcon from "@mui/icons-material/KeyboardArrowUpOutlined";
  import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined";
  import { CheckBox } from "@mui/icons-material";
  import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
  import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
  import DriveFileRenameOutlineIcon from "@mui/icons-material/DriveFileRenameOutline";
  import VisibilityIcon from "@mui/icons-material/Visibility";
  import DeleteIcon from "@mui/icons-material/Delete";
//   import "../MembersDirectory/MDmainPage.css";
  import React, { useContext, useEffect, useState } from "react";
  import { DataGrid, GridExpandMoreIcon } from "@mui/x-data-grid";
  import CategoryIcon from "@mui/icons-material/Category";
  import HandshakeIcon from "@mui/icons-material/Handshake";
  import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
  
  import axios from "axios";

  
  import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Navbar } from "../../BuilderComponents/ProjectDashboard/DashboardComponents/Navbar";
import { ProjectDashboardSidebar } from "../../BuilderComponents/ProjectDashboard/DashboardComponents/ProjectDashboardSidebar";
import { Apiaddress } from "../../../utils/consts";
import { NavbarForDirectory } from "../../BuilderComponents/ProjectDashboard/DashboardComponents/NavbarForDirectory";

import CircularProgress from '@mui/material/CircularProgress';
import builderDashboard from "../../../context/builder_dashboard/context";
import CPassignUnitCustomTable from "./CPassignUnitCustomTable";
import { useLocation } from "react-router-dom";


function CPassignedUnitView() {

 const [loading, setloading] = useState(false);

 const [curUserFromRoute, setcurUserFromRoute] =useState(null);
 const location = useLocation();

  useEffect(() => {
    setcurUserFromRoute(location.state);
  },[location]);

   
  const [newRowData, setNewRowData] = useState([]);
  const [newUnits, setNewUnits] = useState([]);
   
  console.log(newUnits);

  //fetch Units associated with CP
  const getProjectUnitcp = async () => {
    setloading(true);
    let temp=[];
     try{
        for(let i=0;i<curUserFromRoute?.units?.length;i++){
        const res1 = await axios.get(
        Apiaddress + `/api/projects/unit/?unit_id=${curUserFromRoute?.units[i]}`
        );
        // console.log("===========================.>",res1?.data?.unit[0]);
        let unitInfo=res1?.data?.unit[0];
        temp.push(unitInfo);
       }
       setNewRowData(temp);
       setloading(false);

     }catch (err) {
      console.log(err);
    }
   }

  useEffect(() => {
    getProjectUnitcp();
  }, [curUserFromRoute]);

  useEffect(() => {
    if (newRowData) {
      let arr = [];
      for (let i = 0; i < newRowData?.length; i++) {
        
        let totalcost=0;
        
        for (let j = 0; j < newRowData[i].charges_data?.length ; j++) {
          
          totalcost=totalcost+(newRowData[i].charges_data[j].sectioncost);
          
        }
        
          let obj={
            id:newRowData[i]?.id,
            floor_no:newRowData[i].floor?.id,
            bedroom:newRowData[i].layout_data?.bedroomData?.length?newRowData[i].layout_data?.bedroomData?.length:"0",
            bathroom:newRowData[i].layout_data?.bathroomData?.length? newRowData[i].layout_data?.bathroomData?.length:"0",
            balcony:newRowData[i].layout_data?.balconyData?.length? newRowData[i].layout_data?.balconyData?.length:"0",
            totalarea:newRowData[i].layout_data?.singleData[0]?.value,
            single_data:newRowData[i].layout_data?.singleData,
            double_data:newRowData[i].layout_data?.doubleData,
            pricing:totalcost
          }
          arr.push(obj); 
        }
    setNewUnits(arr);
  }

  }, [newRowData]);

    return (
        <>
        <Stack direction="row" sx={{width:"100%",height:"auto" }}>
            {/* 1st stack */}
            <Stack sx={{minHeight:"100vh", width:"22%",boxSizing:"border-box",}}>
                <ProjectDashboardSidebar active={"Occupancy Directory"} />
            </Stack>

            {/* 2nd stack */}
            <Stack direction="column" sx={{ backgroundColor:"#f9f9f9",width:"78%", padding:"25px",boxSizing:"border-box",}}  >
                {/* <Navbar/> */}
                <NavbarForDirectory
                    RightBarNameleft={"Invite Channel Partner"}
                    leftRightTitle2={"Occupancy"}
                    leftTitle={"Project Dashboard"}
                    showLeftTitle={false}
                    righshow={true}
                />
   
                {/* actual content  */}

                <Stack sx={{ mt:"28px",backgroundColor:"#f9f9f9"}}>
   
                    <Stack
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                        sx={{ height: "68px", marginBottom: "20px" }}
                    >
                    <Stack direction="column" alignItems="left">
                        <Typography
                            sx={{
                                fontSize: "22px",
                                fontWeight: "600",
                                // marginLeft: "-45px",
                                color: "black",
                            }}
                        >
                        Quotation Directory
                       </Typography>
                       <Typography
                            sx={{
                            color: "#B9B7D0",
                            fontSize: "12px",
                            fontWeight: "400",
                            paddingLeft: "0px",
                        }}
                        >
                        Here is your Unit Directory list data associated with channel partner
                        </Typography>
                    </Stack>
                    </Stack>

                    <CPassignUnitCustomTable
                        newRowData={newUnits}
                        loading={loading}
                        CircularProgress={CircularProgress}
                    />
    
                </Stack>
            </Stack>
        </Stack>
        </>
    )
}

export default CPassignedUnitView