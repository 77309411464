import { Box, Button, Input, Radio, Typography } from "@mui/material"
import { Stack } from "@mui/system"
import DoneIcon from '@mui/icons-material/Done';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import SearchIcon from '@mui/icons-material/Search';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';

import InputAdornment from '@mui/material/InputAdornment';
import OutlinedInput from '@mui/material/OutlinedInput';
import Checkbox from '@mui/material/Checkbox';
import { useContext, useEffect, useState } from "react";
import { RestaurantOutlined } from "@mui/icons-material";
// modal 
import Modal from '@mui/material/Modal';
import axios from 'axios';
import { Apiaddress } from '../../../../utils/consts';
import { ProjectDashboardSidebar } from '../../../BuilderComponents/ProjectDashboard/DashboardComponents/ProjectDashboardSidebar';
import { NavbarForDirectory } from '../../../BuilderComponents/ProjectDashboard/DashboardComponents/NavbarForDirectory';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    height:"60%",
    bgcolor: 'background.paper',
    border: '2px solid #fff',
    borderRadius:"30px",
    boxShadow: 24,
    p: 4,
    overflow:"scroll",
    whiteSpace:"nowrap",
    overflowX:"hidden"
  };

function EditFloorPlanAddUnit() {
 ////get data from previous page 
   const location = useLocation();
   const toSend = location.state;
   const data= location.state;
   console.log("preve data =>.>",data)

    const navigate = useNavigate();
    const category = useParams().id;
     
    console.log(category);
    let mediaType;
    mediaType = category=="broucher"?"Broucher":category=="image"?"Images":category=="floor"?"Floor Plans":null
    console.log("mediaType", category)
    
    const tempunits = ["103","104","104","104","104","104","104","104","104","104","104","104","104","104","104","104","104"]; ////this is for search option
    const [allSubCategory, setAllSubCategory] = useState([])
    
    ////select media 
    const [selectedCategory, setSelectCategory] = useState([]); 
    const selectSubCat = (val)=>{
        const index = selectedCategory.indexOf(val);
        if (index > -1) { // only splice selectedCategory when item is found
          let  arr = selectedCategory.filter(function(item) {
            return item !== val
          })
          setSelectCategory([...arr])
        }
        else{
          setSelectCategory([...selectedCategory,val])
        }   
      }
      
      const selectAllMedia = ()=>{
        if(selectedCategory.length!==allSubCategory.length ){
          let arr=[];
      for(let i=0; i<allSubCategory.length; i++){
        arr.push(allSubCategory[i]) 
      }
      setSelectCategory([...arr])
    }else{
      setSelectCategory([])
    }
    
      }
    /////select unit 
  const [selectedUnit, setselectedUnit] = useState([]);
  const selectNoOfUnit = (unit_id)=>{
    const index = selectedUnit.indexOf(unit_id);
    if (index > -1) { // only splice selectedUnit when item is found
      let  arr = selectedUnit.filter(function(item) {
        return item !== unit_id
      })
      setselectedUnit([...arr])
    }
    else{
      setselectedUnit([...selectedUnit,unit_id])
    }
      }
    
    ///////////get all units from api 
    const [units, setUnits]  = useState([]);
    useEffect(()=>{
    
     if(data){
        setUnits([...units,data]);
    }

    },[])

    console.log("datahai===============",units);

////get media from api
let builderData = JSON.parse(window.localStorage.getItem('crm_user'));
useEffect(()=>{
   let url = Apiaddress+"/api/projects/mediaofbuilder/?builder_id="+builderData.id

   axios.get(url)
   .then((res)=>{
       let data = res.data.media
    let arr = [];
    for(let i=0; i<data.length; i++){
        console.log("media data =============>",data[i])
        if(mediaType==data[i].media_type)
        arr.push(data[i])
    }
    console.log("all files present in media ====================>", arr)
    // setlistmedia(arr)
    setAllSubCategory(arr)

   }).catch((err)=>{
    console.log(err)
   })

},[])

const [updateLayoutData,setUpdateLayoutData]=useState();
const [prevImage,setPrevImage]=useState();

const updateLayout=async ()=>{
    let url = Apiaddress+"/api/projects/unit/?unit_id="+data;
    const re= await axios.get(url);

      console.log("unit data=>>>>>>>.",re?.data);
      // getting media list from api
     let  layoutList =re?.data?.unit[0]?.layout_data;
      console.log(layoutList);
      setUpdateLayoutData(layoutList);
}
useEffect(()=>{
  updateLayout();
},[])


  ///// end allocation for getting attached media images in 1st selected unit id

  const [alreadAssignedmedia_of_1st,setalreadAssignedmedia_of_1st] = useState([])
  let mediaIdList;
  const getImagesof1stSelectedUnit =async()=>{
   
    attachedMediaToUnit()
   
    let assignedMedia = [];  //for storing media data
    if(!selectedUnit[0]) return;
    let url = Apiaddress+"/api/projects/unit/?unit_id="+data;
    const re= await axios.get(url);

      console.log("unit data=>>>>>>>.",re?.data);
      // getting media list from api
      mediaIdList =re?.data?.unit[0]?.layout_data?.floorplan;
      console.log(mediaIdList);
    //selected media manually from ui add to media list
      for(let j=0; j<selectedCategory.length; j++){
        let index = mediaIdList.indexOf(selectedCategory[j])
        if(index){
          mediaIdList.push(selectedCategory[j]);
        }
      }
      console.log( mediaIdList);
      setalreadAssignedmedia_of_1st( mediaIdList);
      handleOpen();
      return;
  }
  console.log("already assign media to unit list ", alreadAssignedmedia_of_1st)
  // console.log("alreadAssignedmedia_of_1st",alreadAssignedmedia_of_1st)

  //////attach media to unit by passing all media id's to selected unit 
  
  const attachedMediaToUnit = ()=>{

    let templayout=updateLayoutData;
    templayout.floorplan=selectedCategory;

    let url = Apiaddress+"/api/projects/unit/?unit_id="+data;
    let headers = {
      headers:{
        "Content-Type":"application/json"
    }
    }
    console.log(selectedUnit);
    
    for(let i=0; i<selectedUnit.length; i++){
      let data = {
        unit_id:selectedUnit[i],
        layout_data:templayout
      }
      console.log(data);
       
      axios.patch(url,data,headers).then((res)=>{
        console.log("media attached to ====>",selectedUnit[i],res.data)
      }).catch((err)=>{
        console.log(err)
      })
    }
  }


//////// selected banner image 
const handelBannerImg=(e,ind)=>{
let temp =   alreadAssignedmedia_of_1st.map((ele,id)=>{
    if (id==ind){
      ele.istrue=e.target.value
    }
    else{
      ele.istrue="false"
    }
    return ele
  })

  console.log(temp)

  setalreadAssignedmedia_of_1st(temp)
}


///////save banner image ;
 
let [current_data, setCurrentData] = useState({})
useEffect(()=>{
if(!selectedUnit[0]) return;
  const uniturl = Apiaddress+"/api/projects/unit/?unit_id="+selectedUnit[0];
  
  axios.get(uniturl)
  .then((res)=>{
      const data = res?.data
      console.log("unit layout  data=>>>>>>>.",data?.unit[0].layout_data)
      setCurrentData(data?.unit[0].layout_data)
   })
   .catch((err)=>{
     console.log(err)
   })
},[selectedUnit[0]])



const updateBannerImg = ()=>{


  let url = Apiaddress+"/api/projects/unit/?unit_id="+selectedUnit[0];
  console.log(alreadAssignedmedia_of_1st);
  let st;
  if(category=="floor"){
    st = {
      unit_id:selectedUnit[0],
      layout_data:{...current_data, floorplan:alreadAssignedmedia_of_1st}
    }
  }else if(category=="image") {
    st = {
      unit_id:selectedUnit[0],
      layout_data:{...current_data, image:alreadAssignedmedia_of_1st}
    }
  }
  else{
    st = {
      unit_template_id:selectedUnit[0],
      layout_data:{...current_data, media:alreadAssignedmedia_of_1st}
    }
  }

  let headers = {
    headers:{
      "Content-Type":"application/json"
  }
  }

  axios.patch(url,st,headers)
  .then((res)=>{
    console.log("add floorplan template in unit======>", res.data);
  })
  .catch((err)=>{
    console.log(err);
  })

  }

    // for modal 1
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

     //radio button 
    const [selectedValue, setSelectedValue] = useState(allSubCategory[0]);

  const handleChange = (event) => {
    setSelectedValue(event.target.value);
  };

  const controlProps = (item) => ({
    checked: selectedValue === item,
    onChange: handleChange,
    value: item,
    name: 'color-radio-button-demo',
    inputProps: { 'aria-label': item },
  });

  const deleteImage = (ind)=>{

    ////delete locally 
    let arr = alreadAssignedmedia_of_1st.filter((ele,index)=>{
        return ind!==ele.id
    })   

    setalreadAssignedmedia_of_1st([...arr])
    let media = [];
    for(let i=0; i<arr.length; i++){
      media.push(arr[i]);
    }
    console.log(media);
    console.log(current_data);
    ///delete through api;
    let url = Apiaddress+"/api/projects/unit/";///get 1st unit media
    let headers = {
      headers:{
        "Content-Type":"application/json"
    }
    }
    let data = {
      unit_id:selectedUnit[0],
      layout_data:{...current_data,floorplan: media}
    }

    axios.patch(url,data,headers).then((res)=>{
      console.log("media attached to ====>",res.data)
    }).catch((err)=>{
      console.log(err)
    })


  }

    return(
        <>
        <Stack direction="row" sx={{width:"100%",height:"auto" }}>
       {/* 1st stack */}
      <Stack sx={{minHeight:"100vh", width:"22%",boxSizing:"border-box",}}>
      <ProjectDashboardSidebar active={"Unit Directory"}/>
      </Stack>

     {/* 2nd stack */}
      <Stack direction="column" sx={{ backgroundColor:"#f9f9f9",width:"78%", padding:"25px",boxSizing:"border-box",}}  >
      {/* <Navbar/> */}
      <NavbarForDirectory
            leftRightTitle1={"Unit Dashboard"}
            leftRightTitle2={"Unit Directory"}
            leftTitle={"Project Dashboard"}
            showLeftTitle={false}
            righshow={true}
          />
      


      <Typography sx={{marginTop:"20px", fontSize:"28px", fontWeight:"600", marginLeft:"10px", marginBottom:"50px"}}>
                 Attach FloorPlan to Units
           </Typography>
     {/* actual content  */}
    <Stack sx={{backgroundColor:"#f9f9f9"}}>  {/* mt:"28px", */}

    <Stack direction="column"  sx={{  padding:"20px 0px 20px 0px", backgroundColor:"#fff", marginRight:"0px"}}>
           {/* attach media to unit */}
           <Stack direction="row" justifyContent="space-between" >
             <Stack sx={{width:"50%",paddingLeft:"20px"}} >
              <Typography sx={{fontSize:"28px", fontWeight:"600"}}>Media View</Typography>
                 <Stack direction="row" justifyContent="center" alignItems="center">
                  <Typography sx={{fontSize:"20px", fontWeight:"600", mt:"30px",mb:"20px"}}>Media Library</Typography>
                  </Stack>
                  <Stack>
                      <Typography sx={{fontSize:"18px", fontWeight:"500", color:"#A2A3A6"}}>{category}</Typography>
                      <Stack direction="row" alignItems="center" spacing={2} sx={{mt:"20px"}} >
                      <Checkbox
                            edge="start"
                            checked={selectedCategory.length == allSubCategory.length}
                          onChange={()=>{
                            selectAllMedia()
                                    }}
                            tabIndex={-1}
                            disableRipple
                          />
                          <Typography sx={{fontSize:"20px", fontWeight:"600", mt:"30px",mb:"20px"}}>Select all</Typography>
                      </Stack>

                      {/* all subcategory map */}
                      
                      <Box sx={{margin:"auto", width:{xs:"100%",sm:"100%", lg:"90%"},  marginTop:"20px",display:"flex", justifyContent:"start", flexWrap:"wrap"}}>
                        {allSubCategory?.map((ele,index)=>{
                         let imgurl = ele.uploaded_file!==null?ele.uploaded_file:ele.image;
                         console.log("hhhhhhhhhhhhhhhhhhh=>",imgurl)
                            return(
                            <Stack key={index} 
                            onClick={()=>{
                                selectSubCat(ele);                    
                            }} 
                            direction="row" alignItems="center" justifyContent="center" sx={{borderRadius:"20px",cursor:"pointer", width:"100px", height:"100px" ,margin:{sm:"10px",lg:"30px"}, border:selectedCategory.indexOf(ele)>-1 ?"3px solid #FBB040":"3px solid #D9D9D9", }}>
                               <img src={Apiaddress+imgurl} alt="" style={{width:"100%", height:"100%",borderRadius:"20px"}}/>
                            </Stack>
                            )
                        })}
                      </Box>
                     
                  </Stack>
             </Stack>
             <Stack sx={{width:"50%", borderLeft:"1px solid #D9D9D9",padding:"0px 20px 0px 20px"}}>
               <Typography sx={{fontSize:"28px", fontWeight:"600"}}>Unit View</Typography>
               <Stack direction="row" justifyContent="center" alignItems="center">
                  <Typography sx={{fontSize:"20px", fontWeight:"600", mt:"30px",mb:"20px"}}>Select Units</Typography>
                  </Stack>
                  <Stack>
                      <Typography sx={{fontSize:"18px", fontWeight:"500", color:"#A2A3A6"}}>Units</Typography>
                      <Box sx={{display:"flex", justifyContent:"start",alignItems:"center",flexWrap:"wrap",overflowY:"scroll", maxHeight:"400px"}}>
                        {units?.map((ele,index)=>{
                            return(
                          <Stack key={index} direction="row" alignItems="center" sx={{marginRight:"20px",  width:{sm:"20%",lg:"15%"}}}>
                          <Checkbox
                            edge="start"
                            checked={selectedUnit.indexOf(ele)>-1}
                          onChange={()=>{
                            selectNoOfUnit(ele)
                                    }}
                            disableRipple
                          />
                          <Typography sx={{fontSize:"18px", fontWeight:"500", color:"#A2A3A6"}}>{ele}</Typography>
                          </Stack>
                            )
                        })}
                      </Box>
                  </Stack>
                             
             </Stack>
           </Stack>
             <Stack direction="row" justifyContent="end" alignItems="center" spacing={2} sx={{marginTop:"20px", padding:"20px"}}>
                <Button 
               onClick={async()=>{
                await getImagesof1stSelectedUnit()
               }}
                 size="large"  sx={{
                    textTransform:"none",
                     color:"black", 
                     borderRadius:"5px",
                     fontWeight:600,
                     fontSize:"14px",
                     border:"1px solid #0F5DAE",
                     }}  >End allocation</Button>
                <Link to="/unit-directory-w-sb/edit-floorplan-add" state={toSend}>
                <Button size="large" className="univercel_btn_class" sx={{textTransform:"none", color:"#fff", padding:"9px 28px 9px 28px"}}
                 >Allot more</Button>
                </Link>

             </Stack>

             {/* modal for select cover image */}
             <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={{...style}}>
            <Box  sx={{width:{sm:500,lg:800,},display:"flex",justifyContent:"start",flexWrap:"wrap",}}>

                {alreadAssignedmedia_of_1st?.map((ele,ind)=>{
                   let imgurl = ele.uploaded_file!==null?ele.uploaded_file:ele.image;
                    return(
            <Stack direction="row" justifyContent="space-around" alignItems="center" sx={{width:"398px"}}>
                <Stack  
                    onClick={()=>{                 
                    }} 
                    direction="row" alignItems="center" justifyContent="center" sx={{borderRadius:"20px",cursor:"pointer", width:"100px", height:"100px" ,margin:{sm:"10px",lg:"30px"}, bgcolor:"#D9D9D9" }}>
                       {/* <Typography sx={{fontSize:"18px", fontWeight:"600"}}>{category}</Typography>   */}
                       <img src={Apiaddress+imgurl} alt="" style={{width:"100%", height:"100%",borderRadius:"20px"}}/>
                    </Stack>
                    <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={1}>
                      <input type="radio" 
                      id="s1"
                      name="banner"
                      value="true"
                      checked={ele.istrue === "true"}
                      onChange={(e)=>{
                        handelBannerImg(e,ind)  
                      }}
                      style={{
                        height:"20px",
                        color: "linear-gradient(264.48deg, #79F1A4 -45.37%, #0E5CAD 88.97%)",
                          color: "#0F5DAE",
                        '&.Mui-checked': {},
                      }}
                      />
                            <Typography sx={{width:"200px",overflow:"hidden"}}>{ele.tag}</Typography>
                            <svg 
                            onClick={()=>{
                                deleteImage(ele.id)
                            }}
                            style={{cursor:"pointer"}}
                            width="16" height="18" viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M3 18C2.45 18 1.97933 17.8043 1.588 17.413C1.196 17.021 1 16.55 1 16V3H0V1H5V0H11V1H16V3H15V16C15 16.55 14.8043 17.021 14.413 17.413C14.021 17.8043 13.55 18 13 18H3ZM13 3H3V16H13V3ZM5 14H7V5H5V14ZM9 14H11V5H9V14Z" fill="#EF3649"/>
                            </svg>


                    </Stack>
            </Stack>
                    )
                })}
            </Box>


                <Stack direction="row" alignItems="center" justifyContent="center" sx={{mt:"20px"}}>
                <Link to="/unit-directory-w-sb/edit-floorplandetails" state={toSend}>
                <Button 
                onClick={()=>{
                  updateBannerImg()
                  // navigate("/confirm-project1")
                }}
                className="univercel_btn_class" sx={{padding:"16px 54px 16px 54px"}}>Save</Button>
                </Link>
                </Stack>
          
        </Box>
      </Modal>
              
           </Stack>

    </Stack>
      </Stack>
</Stack>
</>
)  
}

export default EditFloorPlanAddUnit