import { Stack } from "@mui/system";
import { FormControl, InputLabel, MenuItem, Modal, Radio, Select, TextField, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import "../Signup.css";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import KeyboardDoubleArrowLeftIcon from "@mui/icons-material/KeyboardDoubleArrowLeft";
import { Apiaddress } from "../../../../utils/consts";
import { useForm } from "react-hook-form";
import axios from "axios";
import { useEffect, useState } from "react";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import invalidCp from "../../../Imgs/invalidcp.png";
import validCp from "../../../Imgs/validcp.png";
import wrongCp from "../../../Imgs/wrongcp.png";
import { useNavigate } from "react-router-dom";
import { CitySelector, StateSelector } from "volkeno-react-country-state-city";
import PhoneIcon from '@mui/icons-material/Phone';
import MailIcon from '@mui/icons-material/Mail';
import { Country, State, City }  from 'country-state-city';
import CloseIcon from '@mui/icons-material/Close';
import { Document, Page, DocumentWrapper} from 'react-pdf';
import privacycondition from '../../../PDF_Content/privacycondition.pdf'
import Termsofuse from '../../../PDF_Content/Termsofuse.pdf'

export const EmailPassNo = ({
  stepsno,
  setStepsno,
  signupdata,
  setSignupdata,
  cp_id,
  selectedValue,
  setSelectedValue,
}) => {
  const [stateName, setStateName] = useState("");
  const [cityName, setCityName] = useState("");
  const [location, setLocation] = useState({});
  const [stateError, setStateError] = useState(false);
  const [cityError, setCityError] = useState(false);
  const [errMessage, setErrMessage] = useState("");

  //state and city selector
  let States=State.getStatesOfCountry('IN');
  const [stateCode,setStateCode] = useState("");
  const [allCities,setAllCities] = useState("");

  console.log(stateName);
  console.log(stateCode);
  console.log(cityName);

  useEffect(()=>{
    let Cities=City.getCitiesOfState('IN',stateCode);
    setAllCities(Cities);
  },[stateCode])

  // console.log(state, "===", city);

  const [term,setTerm] = useState(false);
  const [condition,setCondition] = useState("");
  const [openTerm, setOpenTerm] =useState(false);
    const handleOpenTerm = () => {
        setOpenTerm(true);
    };
    const handleCloseTerm = () => {
        setOpenTerm(false);
    };
    


  useEffect(() => {
    setLocation({
      country: {
        value: "IN",
        label: {
          type: "div",
          key: null,
          ref: null,
          props: {
            className: "_3sXmFundefined",
            children: [
              {
                type: "img",
                key: null,
                ref: null,
                props: {
                  src:
                    "http://purecatamphetamine.github.io/country-flag-icons/3x2/IN.svg",
                  alt: "flag",
                  className: "_2z69Y",
                },
                _owner: {
                  tag: 0,
                  key: null,
                  stateNode: null,
                  return: null,
                  child: null,
                  sibling: null,
                  index: 0,
                  ref: null,
                  pendingProps: null,
                  memoizedProps: null,
                  updateQueue: null,
                  memoizedState: null,
                  dependencies: null,
                  mode: 27,
                  flags: 1,
                  subtreeFlags: 7340055,
                  deletions: null,
                  lanes: 0,
                  childLanes: 1,
                  alternate: null,
                  actualDuration: 12.599999994039536,
                  actualStartTime: 227624.40000000596,
                  selfBaseDuration: 6.5,
                  treeBaseDuration: 11.200000002980232,
                  _debugSource: {
                    fileName:
                      "C:\\CRM\\envision-frontend-web\\src\\Components\\ChannelPartnerComponents\\SignUp\\SignupSubComponents\\Organization.jsx",
                    lineNumber: 33,
                    columnNumber: 21,
                  },
                  _debugOwner: null,
                  _debugNeedsRemount: false,
                  _debugHookTypes: null,
                },
                _store: {},
              },
              {
                type: "span",
                key: null,
                ref: null,
                props: {
                  className: "_1qEzs",
                  children: "India",
                },
                _owner: {
                  tag: 0,
                  key: null,
                  stateNode: null,
                  return: null,
                  child: null,
                  sibling: null,
                  index: 0,
                  ref: null,
                  pendingProps: null,
                  memoizedProps: null,
                  updateQueue: null,
                  memoizedState: null,
                  dependencies: null,
                  mode: 27,
                  flags: 1,
                  subtreeFlags: 7340055,
                  deletions: null,
                  lanes: 0,
                  childLanes: 1,
                  alternate: null,
                  actualDuration: 12.599999994039536,
                  actualStartTime: 227624.40000000596,
                  selfBaseDuration: 6.5,
                  treeBaseDuration: 11.200000002980232,
                  _debugSource: {
                    fileName:
                      "C:\\CRM\\envision-frontend-web\\src\\Components\\ChannelPartnerComponents\\SignUp\\SignupSubComponents\\Organization.jsx",
                    lineNumber: 33,
                    columnNumber: 21,
                  },
                  _debugOwner: null,
                  _debugNeedsRemount: false,
                  _debugHookTypes: null,
                },
                _store: {},
              },
            ],
          },
          _owner: {
            tag: 0,
            key: null,
            stateNode: null,
            return: null,
            child: null,
            sibling: null,
            index: 0,
            ref: null,
            pendingProps: null,
            memoizedProps: null,
            updateQueue: null,
            memoizedState: null,
            dependencies: null,
            mode: 27,
            flags: 1,
            subtreeFlags: 7340055,
            deletions: null,
            lanes: 0,
            childLanes: 1,
            alternate: null,
            actualDuration: 12.599999994039536,
            actualStartTime: 227624.40000000596,
            selfBaseDuration: 6.5,
            treeBaseDuration: 11.200000002980232,
            _debugSource: {
              fileName:
                "C:\\CRM\\envision-frontend-web\\src\\Components\\ChannelPartnerComponents\\SignUp\\SignupSubComponents\\Organization.jsx",
              lineNumber: 33,
              columnNumber: 21,
            },
            _debugOwner: null,
            _debugNeedsRemount: false,
            _debugHookTypes: null,
          },
          _store: {},
        },
        id: 101,
        name: "India",
        iso3: "IND",
        iso2: "IN",
        numeric_code: 356,
        phone_code: 91,
        capital: "New Delhi",
        currency: "INR",
        currency_name: "Indian rupee",
        currency_symbol: "₹",
        tld: ".in",
        native: "भारत",
        region: "Asia",
        subregion: "Southern Asia",
        timezones: "[{zoneName:'Asia\\/Kolkata'",
        latitude: "gmtOffset:19800",
        longitude: "gmtOffsetName:'UTC+05:30'",
        emoji: "abbreviation:'IST'",
        emojiU: "tzName:'Indian Standard Time'}]",
      },
    });
  }, []);

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();
  const [disable, setDisable] = useState(false);
  const [eerr, setEerr] = useState(false);

  const [inputType, setinputType] = useState("Individual");

  const handleChangeRadio = (event) => {
    setSelectedValue(event.target.value);
    if (event.target.value == "org") {
      setinputType("Organization");
    } else {
      setinputType("Organization");
    }
    // console.log(event.target.value);
  };

  const controlProps = (item) => ({
    checked: selectedValue === item,
    onChange: handleChangeRadio,
    value: item,
    name: "color-radio-button-demo",
    inputProps: { "aria-label": item },
  });

  // console.log(signupdata);

  const handleClose = () => {};
  
  const [mailTemp,setmailTemp]= useState(false);

  const SendMailToAdmin=async(data)=>{
    console.log(data);
    console.log(inputType);
    try{
      if(mailTemp==false){
        let url=Apiaddress + "/api/send-mail-to-Admin";
        let res=await axios.post(url, {
                cp_name: data.name.trim(),
                cp_email: data.email,
                cp_phone: "+91" + data.phoneNumber,
                rera_no: data.reraNo,
                account_type:inputType
              })
        setmailTemp(true);
      }
      
    }catch(err){
      console.log(err.message);
    }
  };

  const navigate = useNavigate();

  const onSubmit = async (data) => {
    if (stateName === "") {
      setStateError(true);
    }
    if (cityName === "") {
      setCityError(true);
    }
    if (stateName == "" || cityName == "") return;
    setEerr(false);
    const { name, phoneNumber, reraNo, email } = data;
    // console.log(data);
    setSignupdata({
      ...signupdata,
      email: data.email,
      phoneNo: "+91" + data.phoneNumber,
      name: data.name,
      rera_no: data.reraNo,
      org: data.orgName,
      orgAddress: data.orgAddress,
      address_line1: data.address_line1,
      address_line2: data.address_line2,
      pincode: data.pincode,
      state: stateName,
      city: cityName,
    });
    const verify_rera_endpoint =
      Apiaddress + "/api/role/channelPartnerMasterRecord/?rera_no=" + reraNo;
    try {
      const re = await axios.get(verify_rera_endpoint);

      if (re.status === 200) {
        const d = re.data;
        // console.log(re.data);
        if (d["rera_no"] == reraNo) {
          if (phoneNumber !== d["mobile"]) {
            // console.log("phonenumber dosent match");
            SendMailToAdmin(data);
            setIndex(2);
            
          } else if (email !== d["email"]) {
            // console.log("email dosent match");
            SendMailToAdmin(data);
            setIndex(3);
          } else if (phoneNumber === d["mobile"] && email === d["email"]) {
            // console.log("valid");
            setIndex(1);
            axios
              .post(Apiaddress + "/api/signup/", {
                name: data.name.trim(),
                email: data.email,
                phoneNo: "+91" + data.phoneNumber,
                rera_no: reraNo,
              })
              .then((res) => {
                // console.log("res:", res);
                setDisable(false);
                // setStepsno(prev=>prev+1);
                setStateName("");
                setCityName("");
                setLocation({
                  country: {
                    value: "IN",
                    label: {
                      type: "div",
                      key: null,
                      ref: null,
                      props: {
                        className: "_3sXmFundefined",
                        children: [
                          {
                            type: "img",
                            key: null,
                            ref: null,
                            props: {
                              src:
                                "http://purecatamphetamine.github.io/country-flag-icons/3x2/IN.svg",
                              alt: "flag",
                              className: "_2z69Y",
                            },
                            _owner: {
                              tag: 0,
                              key: null,
                              stateNode: null,
                              return: null,
                              child: null,
                              sibling: null,
                              index: 0,
                              ref: null,
                              pendingProps: null,
                              memoizedProps: null,
                              updateQueue: null,
                              memoizedState: null,
                              dependencies: null,
                              mode: 27,
                              flags: 1,
                              subtreeFlags: 7340055,
                              deletions: null,
                              lanes: 0,
                              childLanes: 1,
                              alternate: null,
                              actualDuration: 12.599999994039536,
                              actualStartTime: 227624.40000000596,
                              selfBaseDuration: 6.5,
                              treeBaseDuration: 11.200000002980232,
                              _debugSource: {
                                fileName:
                                  "C:\\CRM\\envision-frontend-web\\src\\Components\\ChannelPartnerComponents\\SignUp\\SignupSubComponents\\Organization.jsx",
                                lineNumber: 33,
                                columnNumber: 21,
                              },
                              _debugOwner: null,
                              _debugNeedsRemount: false,
                              _debugHookTypes: null,
                            },
                            _store: {},
                          },
                          {
                            type: "span",
                            key: null,
                            ref: null,
                            props: {
                              className: "_1qEzs",
                              children: "India",
                            },
                            _owner: {
                              tag: 0,
                              key: null,
                              stateNode: null,
                              return: null,
                              child: null,
                              sibling: null,
                              index: 0,
                              ref: null,
                              pendingProps: null,
                              memoizedProps: null,
                              updateQueue: null,
                              memoizedState: null,
                              dependencies: null,
                              mode: 27,
                              flags: 1,
                              subtreeFlags: 7340055,
                              deletions: null,
                              lanes: 0,
                              childLanes: 1,
                              alternate: null,
                              actualDuration: 12.599999994039536,
                              actualStartTime: 227624.40000000596,
                              selfBaseDuration: 6.5,
                              treeBaseDuration: 11.200000002980232,
                              _debugSource: {
                                fileName:
                                  "C:\\CRM\\envision-frontend-web\\src\\Components\\ChannelPartnerComponents\\SignUp\\SignupSubComponents\\Organization.jsx",
                                lineNumber: 33,
                                columnNumber: 21,
                              },
                              _debugOwner: null,
                              _debugNeedsRemount: false,
                              _debugHookTypes: null,
                            },
                            _store: {},
                          },
                        ],
                      },
                      _owner: {
                        tag: 0,
                        key: null,
                        stateNode: null,
                        return: null,
                        child: null,
                        sibling: null,
                        index: 0,
                        ref: null,
                        pendingProps: null,
                        memoizedProps: null,
                        updateQueue: null,
                        memoizedState: null,
                        dependencies: null,
                        mode: 27,
                        flags: 1,
                        subtreeFlags: 7340055,
                        deletions: null,
                        lanes: 0,
                        childLanes: 1,
                        alternate: null,
                        actualDuration: 12.599999994039536,
                        actualStartTime: 227624.40000000596,
                        selfBaseDuration: 6.5,
                        treeBaseDuration: 11.200000002980232,
                        _debugSource: {
                          fileName:
                            "C:\\CRM\\envision-frontend-web\\src\\Components\\ChannelPartnerComponents\\SignUp\\SignupSubComponents\\Organization.jsx",
                          lineNumber: 33,
                          columnNumber: 21,
                        },
                        _debugOwner: null,
                        _debugNeedsRemount: false,
                        _debugHookTypes: null,
                      },
                      _store: {},
                    },
                    id: 101,
                    name: "India",
                    iso3: "IND",
                    iso2: "IN",
                    numeric_code: 356,
                    phone_code: 91,
                    capital: "New Delhi",
                    currency: "INR",
                    currency_name: "Indian rupee",
                    currency_symbol: "₹",
                    tld: ".in",
                    native: "भारत",
                    region: "Asia",
                    subregion: "Southern Asia",
                    timezones: "[{zoneName:'Asia\\/Kolkata'",
                    latitude: "gmtOffset:19800",
                    longitude: "gmtOffsetName:'UTC+05:30'",
                    emoji: "abbreviation:'IST'",
                    emojiU: "tzName:'Indian Standard Time'}]",
                  },
                });
                setStepsno((prev) => prev + 1);
              })
              .catch((err) => {
                console.log("res:", err);
                if (err.response.data.msg.includes("error")) {
                  let errorMsg = err.response.data.msg.split("error:");
                  setErrMessage(errorMsg[1]);
                } else {
                  setErrMessage(err.response.data.msg);
                }
                setEerr(true);
                // if(err.response.data.msg = "Email already exists")setEerr(true)
                setDisable(false);
              });
            // navigate("/channelpartner/setPassword/"+cp_id)
          }
        }
      }
    } catch (e) {
      console.log("rera number not found in our records");
      SendMailToAdmin(data);
      setIndex(0);
    }
    setOpen(true);
  };
  const [Verified, setVerified] = useState(false);
  const [open, setOpen] = useState(false);
  const [index, setIndex] = useState(0);
  const modaldata = [
    {
      title: "Something went wrong",
      img: invalidCp,
      desc: "Please check your RERA Number and try again",
    },
    {
      title: "Success",
      img: validCp,
      desc: "RERA details validated",
    },
    {
      title: "Something went wrong",
      img: wrongCp,
      desc:
        "Please check your Phone Number and try again",
    },
    {
      title: "Something went wrong",
      img: wrongCp,
      desc:
        "Please check your Email and try again",
    },
  ];

  return (
    <>
      <Stack
        direction="row"
        justifyContent="start"
        alignItems="center"
        sx={{ margin: "20px 0px 10px 35px" }}
      >
        <Radio
          required
          name="search_type"
          {...controlProps("ind")}
          color="default"
        />
        <Typography
          sx={{
            fontFamily: "Product Sans",
            fontStyle: "normal",
            fontWeight: "500",
            fontSize: "18px",
            lineHeight: "21px",
            color: "#000000",
            marginRight: "20px",
          }}
        >
          Individual
        </Typography>
        <Radio
          required
          name="search_type"
          {...controlProps("org")}
          color="default"
        />
        <Typography
          sx={{
            fontFamily: "Product Sans",
            fontStyle: "normal",
            fontWeight: "500",
            fontSize: "18px",
            lineHeight: "21px",
            color: "#000000",
          }}
        >
          Organization
        </Typography>
      </Stack>
      <Dialog
        open={open}
        // onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{ borderRadius: "20px"}}
      >
        {/* <DialogTitle id="alert-dialog-title">
          {"Use Google's location service?"}
        </DialogTitle> */}
        <DialogContent
          sx={{ width: "460px", height: "260px", textAlign: "center"}}
        >  
          <Stack sx={{textAlign: "left" }}>
            <Typography sx={{fontSize:'14px',color:"#2979F2",fontFamily:"Product Sans",fontWeight:"600"}}>Contact Support</Typography>
            <Typography sx={{fontSize:'14px',display:"flex",alignItems:"centert"}}><PhoneIcon sx={{marginRight:"5px",fontSize:'18px'}}/> 999999999</Typography>
            <Typography sx={{fontSize:'14px',display:"flex",alignItems:"centert"}}><MailIcon sx={{marginRight:"5px",fontSize:'18px'}}/> support@envisionnext.in</Typography>
          </Stack>
          <div style={{ width: "98%", margin: "auto", borderRadius: "10px" }}>
            <img style={{ height: "120px" }} src={modaldata[index]["img"]} />
          </div>
          <h3 style={{ marginBottom: "5px" }}>{modaldata[index]["title"]}</h3>
          <DialogContentText id="alert-dialog-description">
            {modaldata[index]["desc"]}
          </DialogContentText>
        </DialogContent>

        {/* <DialogActions > */}
        <Stack
          direction={"row"}
          alignItems="center"
          justifyContent="center"
          sx={{ marginBottom: "30px"}}
        >
          <Button
            onClick={() => {
              setOpen(false);
            }}
            variant="contained"
            color="primary"
            sx={{
              width: "114px",
              height: "53px",
              background: "#2979F2",
              boxShadow: "0px 13.7981px 22.5788px rgba(50, 174, 137, 0.25)",
              textTransform: "none",
            }}
          >
            OK
          </Button>
        </Stack>
        {/* </DialogActions> */}
      </Dialog>

      <form onSubmit={handleSubmit(onSubmit)}>
        <Box sx={{ padding: "10px 46px", height: "auto" }}>
          {selectedValue !== "org" && (
            <Typography
              sx={{ color: "#343434", fontSize: "14px", marginBottom: "20px" }}
            >
              Note: Please select an <b>organization</b> account if you wish to
              add multiple users to your account. you can change this setting
              later.
            </Typography>
          )}
          <Typography sx={{ color: "#343434", marginBottom: "20px" }}>
            Please Enter Your Name, Phone Number and Email Address to register
          </Typography>

          <Box
            sx={{
              display: "flex",
              justifyContent: "start",
              alignItems: "center",
            }}
          >
            <Box sx={{ width: "41%" }}>
              <Typography
                sx={{
                  color: "#272D4E",
                  fontFamily: "Product Sans",
                  fontWeight: "500",
                  fontSize: "18px",
                }}
              >
                Full Name
              </Typography>
              {/* <input type="text" className="input_signup"/> */}
              <TextField
                sx={{
                  width: "100%",
                  margin: "10px 0px 20px 0px",
                }}
                type="text"
                id="outlined-error-helper-text"
                name="name"
                fullWidth
                {...register("name", { required: "Name is required." })}
                error={Boolean(errors.name)}
                helperText={errors.name?.message}
              />
            </Box>
            <Box sx={{ width: "41%", marginLeft: "30px" }}>
              <Typography
                sx={{
                  color: "#272D4E",
                  fontFamily: "Product Sans",
                  fontWeight: "500",
                  fontSize: "18px",
                }}
              >
                RERA Number
              </Typography>
              {/* <Typography sx={{fontSize:"0.8vw", color:"transparent"}}>An OTP will be send to this number</Typography> */}
              {/* <input type="text" className="input_signup_rera"/> */}
              <TextField
                sx={{
                  width: "100%",
                  margin: "10px 0px 20px 0px",
                }}
                type="tel"
                id="outlined-error-helper-text"
                name="reraNo"
                fullWidth
                {...register("reraNo", {
                  required: "Rera number is required.",
                })}
                error={Boolean(errors.reraNo)}
                helperText={errors.reraNo?.message}
              />
            </Box>
          </Box>

          <Box
            sx={{
              display: "flex",
              justifyContent: "start",
              alignItems: "center",
            }}
          >
            <Box sx={{ width: "41%" }}>
              <Typography
                sx={{
                  color: "#272D4E",
                  fontFamily: "Product Sans",
                  fontWeight: "500",
                  fontSize: "18px",
                }}
              >
                RERA Registered Phone Number
              </Typography>
              <Typography sx={{ fontSize: "0.8vw", color: "gray" }}>
                An OTP will be send to this number
              </Typography>
              {/* <input type="text" className="input_signup_rera"/> */}
              <TextField
                sx={{
                  width: "100%",
                  margin: "10px 0px 20px 0px",
                }}
                type="name"
                id="outlined-error-helper-text"
                name="phoneNumber"
                fullWidth
                {...register("phoneNumber", {
                  required: "Rera Register number is required.",
                  minLength: 10,
                  maxLength: 10,
                })}
                error={Boolean(errors.phoneNumber)}
                helperText={errors.phoneNumber?.message}
              />
              {/* {eerr && 
                                <Typography sx={{fontWeight: "500",fontSize: "14px",marginBottom:"5px", color:"#da6061"}}>User already exist!</Typography>
                            } */}
            </Box>

            <Box sx={{ width: "41%", marginLeft: "30px" }}>
              <Typography
                sx={{
                  color: "#272D4E",
                  fontFamily: "Product Sans",
                  fontWeight: "500",
                  fontSize: "18px",
                }}
              >
                RERA Registered Email
              </Typography>
              <Typography sx={{ fontSize: "0.8vw", color: "gray" }}>
                An OTP will be send to this Email
              </Typography>
              {/* <input type="text" className="input_signup"/> */}
              <TextField
                type="name"
                // inputProps={{maxLength: 10}}
                sx={{
                  width: "100%",
                  margin: "10px 0px 20px 0px",
                }}
                id="outlined-error-helper-text"
                name="email"
                fullWidth
                {...register("email", { required: "Email is required." })}
                error={Boolean(errors.email)}
                helperText={errors.email?.message}
              />
              {eerr && (
                <Typography
                  sx={{
                    fontWeight: "500",
                    fontSize: "14px",
                    marginBottom: "5px",
                    color: "#da6061",
                  }}
                >
                  {errMessage}
                </Typography>
              )}
            </Box>
          </Box>

          {selectedValue === "org" && (
            <Box
              sx={{
                display: "flex",
                justifyContent: "start",
                alignItems: "center",
              }}
            >
              <Box sx={{ width: "41%" }}>
                <Typography sx={{ fontWeight: "500", fontSize: "18px" }}>
                  Organization Name
                </Typography>
                {/* <Typography sx={{fontSize:"0.8vw", color:"gray"}}>An OTP will be send to this number</Typography> */}
                {/* <input type="text" className="input_signup_rera"/> */}
                <TextField
                  sx={{
                    width: "100%",
                    margin: "10px 0px 20px 0px",
                  }}
                  type="name"
                  id="outlined-error-helper-text"
                  name="orgName"
                  fullWidth
                  {...register("orgName", {
                    required: "Organization Name is required.",
                  })}
                  error={Boolean(errors.orgName)}
                  helperText={errors.orgName?.message}
                />
              </Box>

              {/* <Box sx={{width:"41%", marginLeft:"30px"}}>
                        <Typography sx={{fontWeight: "500",fontSize: "18px"}}>Organization Address</Typography>
                            <Typography sx={{fontSize:"0.8vw", color:"gray"}}>An OTP will be send to this Email</Typography>
                            <input type="text" className="input_signup"/>
                            <TextField
                                type="name"
                                // inputProps={{maxLength: 10}}
                                sx={{
                                    width:"100%",
                                    margin:"10px 0px 20px 0px"
                                    }}
                                    id="outlined-error-helper-text"
                                    name="orgAddress"
                                    fullWidth
                                    {...register("orgAddress", {required:"Organization Address is required."})}
                                    error={Boolean(errors.email)}
                                    helperText={errors.email?.message}
                                />
                        </Box> */}
            </Box>
          )}

          <Box
            sx={{
              display: "flex",
              justifyContent: "start",
              alignItems: "center",
            }}
          >
            <Box sx={{ width: "41%" }}>
              <Typography
                sx={{
                  color: "#272D4E",
                  fontFamily: "Product Sans",
                  fontWeight: "500",
                  fontSize: "18px",
                }}
              >
                Address
              </Typography>
              {/* <Typography sx={{fontSize:"0.8vw", color:"gray"}}>An OTP will be send to this number</Typography> */}
              {/* <input type="text" className="input_signup_rera"/> */}
              <TextField
                sx={{
                  width: "100%",
                  margin: "10px 0px 20px 0px",
                }}
                type="name"
                id="outlined-error-helper-text"
                name="address_line1"
                placeholder="Address Line 1"
                fullWidth
                {...register("address_line1", {
                  required: "Address is required.",
                })}
                error={Boolean(errors.address_line1)}
                helperText={errors.address_line1?.message}
              />
              {/* {eerr && 
                                <Typography sx={{fontWeight: "500",fontSize: "14px",marginBottom:"5px", color:"#da6061"}}>User already exist!</Typography>
                            } */}
            </Box>

            <Box sx={{ width: "41%", marginLeft: "30px" }}>
              {/* <Typography sx={{fontWeight: "500",fontSize: "18px"}}>RERA registered Email</Typography> */}
              {/* <Typography sx={{fontSize:"0.8vw", color:"gray"}}>An OTP will be send to this Email</Typography> */}
              {/* <input type="text" className="input_signup"/> */}
              <TextField
                type="name"
                // inputProps={{maxLength: 10}}
                sx={{
                  width: "100%",
                  margin: "35px 0px 10px 0px",
                }}
                id="outlined-error-helper-text"
                name="address_line2"
                placeholder="Address Line 2 (Optional)"
                fullWidth
                {...register("address_line2")}
                error={Boolean(errors.address_line2)}
                helperText={errors.address_line2?.message}
              />
              {/* {eerr && 
                                    <Typography sx={{fontWeight: "500",fontSize: "14px",marginBottom:"5px", color:"#da6061"}}>User already exist!</Typography>
                                } */}
            </Box>
          </Box>

          <Box
            sx={{
              display: "flex",
              justifyContent: "start",
              alignItems: "center",
            }}
          >
            <Box sx={{ width: "41%" }}>
              <Typography
                sx={{
                  color: "#272D4E",
                  fontFamily: "Product Sans",
                  fontWeight: "500",
                  fontSize: "18px",
                }}
              >
                State
              </Typography>

              <Box sx={{ mt: "10px" }}>
                      <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">Select State</InputLabel>
                          <Select
                            required
                            label="Select State"
                            MenuProps={{ PaperProps: { sx: { maxHeight: 200,maxWidth: 250} } }}
                            onChange={(e)=>{
                              let temp = e.target.value.split("-");
                              console.log(temp);
                              setStateName(temp[0]);
                              setStateCode(temp[1]);
                            }}
                          >
                          {States &&
                            States?.map((option) => (
                              <MenuItem key={option.name+"-"+option.isoCode} value={option.name+"-"+option.isoCode}>
                                {option.name}
                              </MenuItem>
                          ))}
                          </Select>
                        </FormControl>
                        {stateError && (
                          <Typography
                            sx={{
                              fontWeight: "500",
                              fontSize: "14px",
                              marginBottom: "5px",
                              color: "#da6061",
                            }}
                          >
                          State is required
                        </Typography>
                        )}

                {/* <StateSelector
                  country={location.country}
                  name="state"
                  value={location.state || state}
                  countryPlaceholder="Select a country first"
                  onChange={(e) => {
                    // console.log(e);
                    // setValue("country", e.name);
                    setState(e.name);
                    setLocation({ ...location, state: e });
                  }}
                />
                {stateError && (
                  <Typography
                    sx={{
                      fontWeight: "500",
                      fontSize: "14px",
                      marginBottom: "5px",
                      color: "#da6061",
                    }}
                  >
                    State is required!
                  </Typography>
                )} */}
              </Box>

            </Box>

            <Box sx={{ width: "41%", marginLeft: "30px" }}>
              <Typography
                sx={{
                  color: "#272D4E",
                  fontFamily: "Product Sans",
                  fontWeight: "500",
                  fontSize: "18px",
                }}
              >
                City
              </Typography>
              <Box sx={{ mt: "10px" }}>
                {/* <CitySelector
                  state={location.state}
                  name="city"
                  value={location.city || cityName}
                  statePlaceholder="Select a state first"
                  onChange={(e) => {
                    setCityName(e.name);
                    setLocation({ ...location, city: e });
                  }}
                /> */}
                      <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">Select City</InputLabel>
                        <Select
                            required
                            value={cityName}
                            label="Select City"
                            MenuProps={{ PaperProps: { sx: { maxHeight: 200,maxWidth: 250} } }}
                            onChange={(e)=>{
                              let temp = e.target.value.split("-");
                              setCityName(temp[0]);
                            }}
                          >
                            {allCities &&
                            allCities?.map((option) => (
                              <MenuItem key={option.name+"-"+option.isoCode} value={option.name}>
                                {option.name}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                {cityError && (
                  <Typography
                    sx={{
                      fontWeight: "500",
                      fontSize: "14px",
                      marginBottom: "5px",
                      color: "#da6061",
                    }}
                  >
                    City is required!
                  </Typography>
                )}
              </Box>
            </Box>
          </Box>

          <Box sx={{ width: "41%", mt: "20px" }}>
            <Typography
              sx={{
                color: "#272D4E",
                fontFamily: "Product Sans",
                fontWeight: "500",
                fontSize: "18px",
              }}
            >
              Pincode
            </Typography>
            <TextField
              sx={{
                //  width:"75%",
                margin: "10px 0px 10px 0px",
              }}
              id="outlined-error-helper-text"
              name="pincode"
              type="text"
              // inputProps={{ min: 100000, max: 999999 }}
              fullWidth
              {...register("pincode", {
                required: "Pincode is required",
                minLength: {
                  value: 6,
                  message: "PinCode should accept 6 character",
                },
                maxLength: {
                  value: 6,
                  message: "PinCode should accept 6 character",  
                },
              })}
              error={Boolean(errors.pincode)}
              helperText={errors.pincode?.message}
            />
          </Box>
          <Box sx={{ width: "100%", mt: "20px",display:"flex"}}>
          <input type="checkbox" id="" name="" value={term} required style={{width:"20px",height:"19px",marginRight:"10px",cursor:"pointer"}}
          onClick={()=>{setTerm(!term)}}
          ></input>
          <Stack direction="row" alignItems="center">
            <Typography sx={{
              color: "#272D4E",
              fontFamily: "Product Sans",
              fontWeight: "500",
              fontSize: "18px",
              marginRight:"5px"
            }}>
               I/We accept the {" "}
            </Typography>
            <Typography
              sx={{
                color: "#0085FF",
                cursor: "pointer",
                fontFamily: "Product Sans",
                fontWeight: "500",
                fontSize: "18px",
                marginRight:"5px",
                "&:hover":{textDecoration:"underline"}
              }}
              onClick={()=>{handleOpenTerm();setCondition("Terms")}}
              >
              {" "}
              Terms of Service 
            </Typography>
            <Typography sx={{
              color: "#272D4E",
              fontFamily: "Product Sans",
              fontWeight: "500",
              fontSize: "18px",
              marginRight:"5px"
              }}>
               and {" "}
            </Typography>
            <Typography
              sx={{
                color: "#0085FF",
                cursor: "pointer",
                fontFamily: "Product Sans",
                fontWeight: "500",
                fontSize: "18px",
                "&:hover":{textDecoration:"underline"}
              }}
              onClick={()=>{handleOpenTerm();setCondition("Privacy")}}
              >
              {" "}
              Privacy Policy
            </Typography>
          </Stack>
          </Box>
        </Box>

        <Stack
          direction="row"
          justifyContent="right"
          spacing={0}
          sx={{ height: "10vh", marginBottom: "30px" }}
        >
          {/* <Button
                 className="univercel_btn_class"
                onClick={()=>{
                    navigate("/login")
                }}
                variant="contained" 
                color="primary" sx={{marginLeft:"20px",width:"114px",height:"53px",textTransform: "none"}}>
                    Log In 
                </Button> */}
          {stepsno > 0 && stepsno < 3 && (
            <Button
              onClick={() => setStepsno((prev) => prev - 1)}
              variant="contained"
              startIcon={<KeyboardDoubleArrowLeftIcon />}
              color="primary"
              sx={{
                marginRight: "20px",
                width: "114px",
                height: "53px",
                background:
                  "linear-gradient(90deg, #0F5DAE 0%, #009DCF 100.33%)",
                boxShadow: "0px 13.7981px 22.5788px rgba(50, 174, 137, 0.25)",
                textTransform: "none",
              }}
            >
              Previous
            </Button>
          )}
          {stepsno <= 3 && (
            <Button
              type="submit"
              endIcon={<KeyboardDoubleArrowRightIcon />}
              sx={{
                color: "#FFF",
                marginRight: "20px",
                height: "45px",
                px: "20px",
                background: "#2979F2",
                textTransform: "none",
                "&:hover": {
                  background: "#2979F2",
                  boxShadow: "-4px 6px 14px rgba(23, 147, 255, 0.3)",
                },
              }}
            >
              Next
            </Button>
          )}
        </Stack>
      </form>

      {/* Modal to add Channel Partner */}

      <Modal
        open={openTerm}
        onClose={handleCloseTerm}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description" 
      >
        <Box sx={{position: 'absolute',
             top: '50%',
             left: '50%',
             transform: 'translate(-50%, -50%)',
             width: "50%",
             height:"60%",
             bgcolor: 'white',
             border: '2px solid white',
             borderRadius:"10px",
             boxShadow: 24,
             pt: 2,
             px: 2,
             pb: 1.5,
             textAlign:"center"
            }}>
            <Box sx={{textAlign:"right"}}>
                <CloseIcon sx={{color:"gray",cursor:"pointer"}} onClick={(e)=>{
                   handleCloseTerm();
                }}/>
            </Box>
              <Typography sx={{
              fontSize: "18px",
              color: "#272D4E",
              fontFamily: "Product Sans",
              fontWeight: "600",
              marginBottom: "10px",
              marginTop:"-20px",
              color: "black",
            }}>{condition=="Terms"?"Terms of Service":"Privacy Policy"}</Typography>
    
            <Box sx={{textAlign:"center",display:"flex",flexDirection:"column",justifyContent:"center",alignItems:"center",height:"90%"}}>
              <iframe src={condition=="Terms"?Termsofuse:privacycondition} style={{width:"100%",height:"100%"}}></iframe>
            </Box>
          <Typography sx={{color:"#ff4500",fontWeight:"500",marginBottom:"20px",fontFamily:"Poppins",fontSize:"1.2rem"}}></Typography>
        </Box>
      </Modal>  
    </>
  );
};
