import { Box, TextField, Typography } from '@mui/material'
import React, { useState } from 'react';
import { Stack } from '@mui/system';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import FileCopyRoundedIcon from '@mui/icons-material/FileCopyRounded'; 
import Button from "@mui/material/Button";
import Modal from '@mui/material/Modal';
import { useEffect } from 'react';
import CloseIcon from '@mui/icons-material/Close'; 
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';



function NewPricingSection(props) {

    const [inputtitle,setinputtitle]=useState([]);
    const [plank,setplank]=useState(false);

    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    
    const [costsection,setcostsection]=useState(0);

    const [addsec,setaddsec]=useState({
        key:"",
        value:"",
        index:0
    });

    // console.log(inputtitle);

    const sectioncostcalculate=()=>{
        let data=0
        inputtitle.map((ele)=>{
            data+=+(ele.value);
        })

        setcostsection(data);

        props.settitle((prev) => {
            return prev.filter((curvalue, idx) => {
                if (idx+1 == props.header.number) {
                    curvalue.field = inputtitle;
                    }
                return curvalue;
            })
        });

        props.setseccost({index:props.header.number,cost:data});
    }

    if(plank==true){
    //    console.log(inputtitle);
       sectioncostcalculate();
       setplank(false); 
    }

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: "350px",
        height:"250px",
        bgcolor: 'background.paper',
        boxShadow: 24,
        borderRadius:"16px",
        p: 4
      };

      useEffect(()=>{

        setinputtitle(props.header.field);
        
     },[]);

     console.log(inputtitle);

    
  return (
    <div>
        <Stack direction="row" justifyContent="start" alignItems="center" sx={{margin:"30px 0px 10px 0px",fontSize: "20px",color:"#000000"}}>
            <Typography sx={{fontFamily:'Poppins',fontStyle:"normal",fontWeight:"530",fontSize: "20px",lineHeight:"27px",color:"#000000",textTransform:"uppercase"}}>{props.header.titlename}</Typography>
        </Stack>
        <Stack direction="row" justifyContent="start" alignItems="center" sx={{margin:"0px 0px 10px -5px",fontSize: "20px",color:"#000000"}}>
            <Button  sx={{fontFamily:'Poppins',fontStyle:"normal",fontWeight:"500",fontSize: "16px",lineHeight:"20px",color:"#2979F2"}} onClick={()=>{setOpen(true)}}>Add a new field<AddIcon/></Button>
        </Stack>

        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description">
           <Box sx={style}>
           <Stack direction="row" justifyContent="space-between" alignItems="center">
           <Typography id="modal-modal-title" variant="h6" component="h2" sx={{fontFamily:'Poppins',fontStyle:"normal",fontWeight:"500",fontSize: "18px",lineHeight:"27px",color:"#FF6140"}}>
            Add Field
           </Typography>
           <Box onClick={()=>{setOpen(false);setaddsec("")}} sx={{cursor:"pointer"}}><CloseIcon /></Box>
           </Stack>

           <Box sx={{alignItems:"center",margin:"30px 0px 0px 0px"}}>
            <Stack direction="row" justifyContent="start" alignItems="center" sx={{margin:"30px 0px 0px 20px",fontSize: "18px"}}>
                <Typography component="span" className='field-name'>Input Name</Typography>
                <TextField
                 sx={{
                    
                     width:"188px",
                     marginLeft:"40px"
                    }} value={addsec.titlename} onChange={(e)=>{setaddsec(
                        {

                            key:e.target.value,
                            value:"",
                            index:inputtitle.length+1,
                        }
                        )}}/> 
            </Stack>
            </Box>
            <Button 
               type="submit"
                variant="contained"
                 color="primary" sx={{ height:"45px", background: "linear-gradient(90deg, #0F5DAE 0%, #009DCF 100.33%)",boxShadow: "0px 13.7981px 22.5788px rgba(50, 174, 137, 0.25)", textTransform: "none", borderRadius: "4px", width:"100px", fontSize:"15px",margin:"60px 0px 30px 120px"}} onClick={()=>{
                    if(!addsec){
                        return;
                    }
                    setinputtitle(inputtitle=> inputtitle.concat(addsec));
                    setOpen(false);
                    setaddsec("");
                }}>
                Done
        </Button>
        </Box>
      </Modal>
      
      <Box sx={{}}>
        <Stack direction="row" justifyContent="">
            <Box sx={{display:"flex",flexWrap:"wrap",width:"75%"}}>

            {inputtitle.map((ele,idx)=>{
        
                return(
                <Stack sx={{margin:"0px 40px 20px 0px"}}>
                    <Typography className='field-name' sx={{fontSize:"18px",margin:"10px 0px 10px 0px"}}>{ele.key}</Typography>
                    <Stack direction="row" sx={{alignItems:"center"}}>
                        <CurrencyRupeeIcon sx={{color:"#A2A3A6"}}/>
                        <TextField type="number" inputProps={{ min: 0 }}
                            sx={{
                                width:"188px",
                                marginLeft:"0px"
                                }} 
                                value={ele.value} onChange={(e)=>{
                                   console.log(idx+1,ele.index);
                                //    if(idx+1==ele.index){
                                    if(inputtitle[idx].key==ele.key){
                                    ele.value=e.target.value;
                                    setaddsec(
                                        {
                                            key:ele.key,
                                            index:ele.index,
                                            value:e.target.value
                                        })                         
                                   }
                                   sectioncostcalculate()
                                }
                                }
                        />
                        <DeleteIcon sx={{color:"#B0AFAF",width:"39px",height:"30px",cursor:"pointer","&:hover":{color:"#EF3649"}}}
                            onClick={()=>{
                            const newinputtitle = inputtitle.filter((x) => x !== ele);
                            setinputtitle(newinputtitle);
                            setplank(true);
                             }}
                        />
                    </Stack>
                </Stack>
                )})
            }
            </Box>
            <Box sx={{display:"flex",alignItems:"end"}}>
                {inputtitle.length!=0 && 
                <Stack>
                    <Typography className='field-name' sx={{fontSize:"18px",color:"#2979F2"}}>Total</Typography>
                       <Stack direction="row" sx={{alignItems:"center"}}>
                            <CurrencyRupeeIcon sx={{color:"#2979F2"}}/>
                            <Box sx={{width:"188px",height:"52px",background:"#ECECEC",borderRadius:"8px",display:"flex",justifyContent:"center",alignItems:"center"}}><Typography className='field-name' sx={{fontSize:"20px",color:"#2979F2"}}>{costsection}</Typography></Box>
                       </Stack>
                        
                </Stack>
                }
            </Box>
            </Stack>            
        </Box>
        

    </div>
  )
}

export default NewPricingSection