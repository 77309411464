import { AuthService } from "../../../../../context/buyer/services/auth";
import { CPprojects } from "./projects"

class CPserver {
    Auth: AuthService;
    projects: CPprojects;

    constructor(e: AuthService) {
        this.Auth = e;
        this.projects= new CPprojects(this.Auth)
    }

}
export { CPserver }