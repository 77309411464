import { Box, Button, Container, Typography } from "@mui/material";

import CommonLandingChangeTheFaceImg from "../../Imgs/common-landing-change-the-face-img.png";

const CommonLandingChangeTheFaceOfYourBusiness = () => {
  return (
    <Box
      sx={{
        background: "#0890FE",
        overflowX: "hidden",
      }}
    >
      <Container
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: { xs: "center", md: "flex-start" },
          py: { xs: "30px", md: "20px" },
          overflow: "hidden",
          flexDirection: { xs: "column-reverse", md: "row" },
          gap: "20px",
        }}
      >
        <Box
          sx={{
            width: { xs: "100%", md: "60%" },
            textAlign: { xs: "center", md: "left" },
          }}
        >
          <Typography
            data-aos="fade-down"
            sx={{
              fontSize: { xs: "24px", md: "32px", lg: "40px" },
              fontWeight: 700,
              fontFamily: "Circular Std",
              color: "#FFF",
              lineHeight: "1.2",
              display: { xs: "none", md: "block" },
            }}
          >
            Change the face of your business with our full support
          </Typography>
          <Typography
            data-aos="fade-down"
            sx={{
              fontSize: { xs: "24px", md: "30px", lg: "48px" },
              fontWeight: 700,
              fontFamily: "Circular Std",
              color: "#FFF",
              lineHeight: "1.2",
              display: { xs: "block", md: "none" },
            }}
          >
            Change the face of your <br /> business with our full support
          </Typography>
          <Typography
            data-aos="fade-up"
            sx={{
              color: "#FFF",
              fontSize: "18px",
              fontFamily: "Product Sans",
            }}
          >
            You get a dedicated Relationship Manager, your single point of
            contact, available on-call to help you set up and troubleshoot
            issues.
          </Typography>
          <Box sx={{ mt: { xs: "20px", md: "40px" } }}>
            <Typography
              data-aos="fade-up"
              sx={{
                fontWeight: 700,
                color: "#FFCB75",
                fontSize: "16px",
                fontFamily: "Product Sans",
              }}
            >
              Get started simply by booking a demo
            </Typography>
            <Button
              data-aos="fade-zoom-in"
              data-aos-easing="ease-in-back"
              data-aos-delay="300"
              data-aos-offset="0"
              sx={{
                background:
                  "linear-gradient(97.85deg, #FFAF2A 0.95%, #FFC666 100%)",
                fontWeight: 500,
                fontSize: { lg: "28px", xs: "16px" },
                boxShadow: "rgba(242, 178, 73, 0.59) 0px 9px 12px",
                borderRadius: "10px",
                color: "#06386B",
                textTransform: "none",
                padding: "10px 30px",
                fontFamily: "Product Sans",
                mt: "20px",
                "&:hover": {
                  background:
                    "linear-gradient(97.85deg, #FFAF2A 0.95%, #FFC666 100%)",
                  boxShadow: "rgba(242, 178, 73, 0.59) 10px 12px 16px",
                },
              }}
            >
              Book A Demo
            </Button>
          </Box>
        </Box>
        <Box
          data-aos="fade-zoom-in"
          data-aos-delay="300"
          data-aos-offset="150"
          sx={{
            width: { xs: "100%", md: "50%" },
            mb: { md: "-20px" },
            display: "flex",
            justifyContent: { xs: "center", md: "flex-start" },
          }}
        >
          <img
            style={{ width: "100%" }}
            src={CommonLandingChangeTheFaceImg}
            alt=""
          />
        </Box>
      </Container>
    </Box>
  );
};

export default CommonLandingChangeTheFaceOfYourBusiness;
