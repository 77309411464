import { Box, Button, TextField, Typography } from "@mui/material"
import { Stack } from "@mui/system"
import { Navbar } from "../../DashboardComponents/Navbar"
import { Sidebar } from "../../DashboardComponents/Sidebar"
import DoneIcon from '@mui/icons-material/Done';
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { MediaNavbar } from "../../DashboardComponents/MediaNavbar";


import Modal from '@mui/material/Modal';
import { MainSidebar } from "../../DashboardComponents/MainSidebar";
import { UserRole } from "../../../../../UserRoles/UserRoles";
import { NavbarForDirectory } from "../../DashboardComponents/NavbarForDirectory";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #fff',
    boxShadow: 24,
    borderRadius:"20px",
    pt: 2,
    px: 4,
    pb: 3,
  };


export const Add_Media = ()=>{
  
  ////get user roles from local storage and assign 
  let roles = JSON.parse(localStorage.getItem("user_roles"))
  let media = roles["project media"];

  const navigate = useNavigate();

  const [listmedia, setlistmedia] = useState(["Images",  "Floor Plans"]); ///"Videos","Walkthrough",

  const [selected, setSelected] = useState(listmedia[0])

  const [addname, setAddName] = useState("")

  const [open, setOpen] = useState(false);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

    return (
        <>

        
<Stack direction="row" sx={{width:"100%",height:"auto", }}>
      {/* 1st stack */}
      <Stack sx={{minHeight:"100vh",width:"20%",boxSizing:"border-box",}}>
        <MainSidebar active={"Media Library"} />
      </Stack>

     {/* 2nd stack */}
     <Stack direction="column" sx={{ backgroundColor:"#f9f9f9",width:"80%", padding:"25px",}}  >  {/*  width for main sidebar 78px and for 80px*/}
      {/* <Navbar/> */}
      <NavbarForDirectory
          // showRightSideBar={UserRole(team, "add_teams")?true:false}
          // toggleDrawer={toggleDrawer}
          RightBarName={"Add Team"}
          leftTitle={"Sales Dashboard"}
          righshow={true}
          leftRightTitle2={"Media Library"}
        />
      
      


    {/* <Typography sx={{mt:"20px", fontSize:"22px", fontWeight:"500",color:"#2979F2"}}>
    Project Name
    </Typography> */}
  {/* actual content  */}
  <Stack sx={{ mt:"28px",backgroundColor:"#f9f9f9"}}>
               {/* add media to project  */}
               <Stack sx={{padding:"20px", bgcolor:"#fff"}} >
              <Typography sx={{fontSize:"28px", fontWeight:"600"}}>Media View</Typography>
                 <Stack direction="row" justifyContent="center" alignItems="center">
                  <Typography sx={{fontSize:"20px", fontWeight:"600", mt:"30px",mb:"20px"}}>Media library</Typography>
                  </Stack>
                  <Stack>
                    <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{padding:"20px"}}>
                      <Typography sx={{fontSize:"18px", fontWeight:"500", color:"#A2A3A6"}}>Select Category</Typography>
                       {/* {UserRole(media,"add_projectmedia") && 
                       <Button onClick={()=>{
                          handleOpen()
                        }}
                      size="large" className="univercel_btn_class" sx={{textTransform:"none", color:"#fff"}} 
                       >Add More Media
                       </Button>
                       } */}
                    </Stack>
                      
                      <Box sx={{margin:"auto", width:{sm:"80%", lg:"55%"},  marginTop:"40px",display:"flex", justifyContent:"space-around", flexWrap:"wrap"}}>
                        {listmedia.map((ele,indx)=>{
                            return (
                            <Stack onClick={()=>{
                                setSelected(ele);
                            }} direction="row" alignItems="center" justifyContent="center" sx={{borderRadius:"20px",cursor:"pointer", minWidth:"100px", minHeight:"100px",padding:"5px", bgcolor:selected==ele?"orange":"#D9D9D9", }}>
                               <Typography sx={{fontSize:"18px", fontWeight:"600"}}>{ele}</Typography>  
                            </Stack>
                            )
                        })}
                           
                      </Box>
                  </Stack>
                  <Stack direction="row" justifyContent="right" alignItems="center" sx={{padding:"20px"}}>
                  <Button 
                         onClick={()=>{
                          navigate(`/createproject-upload/${selected}`)
                         }}
                       size="large" className="univercel_btn_class" sx={{textTransform:"none", color:"#fff"}}  >Next
                       
                       </Button>
                  </Stack>
             </Stack>

                {/* modal */}
                <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
        <Box sx={{ ...style, width: 300, height:150, }}>
     <Stack direction="row" justifyContent="center">
     <TextField  
     placeholder="Add Media Name"
        value={addname}
        onChange={(e)=>{
            setAddName(e.target.value);
        }}
     />
     </Stack>
       
       <Stack sx={{display:"flex", justifyContent:"center", alignItems:"center", mt:"20px"}}>
        <Button
        onClick={()=>{
            if(addname==""){
                return;
                handleClose()
            }else{
                setlistmedia([...listmedia,addname])
                handleClose()
            }
        }}
       className="univercel_btn_class"
            variant="contained"
            sx={{padding:"9px 26px 9px 26px",}}
            component="label"
          >
             Add Media
      </Button>
       </Stack>
        </Box>
      </Modal>

   

    </Stack>



      </Stack>
</Stack>

 
          
        
        </>
    )
}