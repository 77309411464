import { Box, List, ListItem, Typography } from "@mui/material";
import "./styles/PageSix.css";
import React, {useState } from 'react';
import { Stack } from "@mui/system";
import classes from "./styles/PageOne.module.css";

const PdfOnePageNine = () => {

  const [pageData9,setPageData9]=useState({
    sign_first:"",
    sign_second:"",
    sign_third:"",
    allot_data1:"",
    allot_data2:"",
    accept_data1:"",
    accept_data2:"",
  })

  // console.log(pageData9);

  let name,value;
  const handleChange=(e)=>{
    name=e.target.name;
    value=e.target.value;
    console.log(name," ",value);
    setPageData9({...pageData9,[name]:value});
  }

  return (
    <Box>
      <Box sx={{ mb: "40px" }}>
        <Stack>
          <Box sx={{ display: "flex", gap: "10px" }}>
            <Typography sx={{ fontSize: "10px" }}>6.4</Typography>
            <Typography sx={{ fontSize: "10px" }}>
              The Applicant (s) hereby acknowledge(s) to have reviewed such
              Project Documents and confirm(s) to have understood the contents
              thereof. The Applicant (s) has/have also independently
              investigated, checked, verified and confirmed the titles of the
              Owners to the Project Property and entitlement of the Developer to
              develop the Project and is fully satisfied with the same. The
              Applicant (s) further confirm(s) to have understood the contents
              thereof. The Applicant (s) has/have also independently
              investigated, checked, verified and confirmed the title of the
              Owners to the Project Property and entitlement of the Developer to
              develop the Project and is fully satisfies with the same. The
              Applicant (s) futher confirm(s) that the Applicant (s) has/have
              been suitably advised by (i) his/her/its/their lawyers with
              respect to the title of the Owners to the Project Property and
              entitlement of the Developer to develop the Project , and (ii)
              his/her/its/their architect with respect to the permissions and
              approvals and the scheme of the developement of the Project and
              after having acquainted himself/herself/themselves/itself with all
              the facts in relation to the Project and right, title and interest
              of the Owners and the Developers to the Project Property, has/have
              made this Application in respect of the Premises. The Applicant
              (s) hereby confirm(s) that the Applicant (s) shall not raise any
              requisitions or objections in this regard.
            </Typography>
          </Box>
          <Box sx={{ display: "flex", gap: "10px" }}>
            <Typography sx={{ fontSize: "10px" }}>6.5</Typography>
            <Typography sx={{ fontSize: "10px" }} className={classes.input}>
              The layout and building plans, location of the proposed building,
              the premises, specifications, amenities, facilities as shown in
              the various booklets/ brochures/ inserts/other marketing material
              and /or any other representations by the Developer, its employees
              and/or its agents shall be subject to the changes / variations to
              the same as per the decision of the Project architects and/or MIDC
              and/or any government authority and/or the Owners or the
              Developers. The Applicant (s) grant(s) explicit consent to the
              amendment of the proposed/ sanctioned plans. The Applicant(s) has 
              been informed that the full built up potential of the Said Plot is
              <input 
                style={{ textAlign: "center" }}
                value={pageData9.allot_data1}
                type="text"
                name="allot_data1"
                onChange={handleChange}
              />  Sq. Mtrs and that the Developer has proposed to utilize  
              <input 
                style={{ textAlign: "center" }}
                value={pageData9.allot_data2}
                type="text"
                name="allot_data2"
                onChange={handleChange}
              />  Sq. Mtrs in Phase I of the Said project.
            </Typography>
          </Box>
          <Box sx={{ display: "flex", gap: "10px" }}>
            <Typography sx={{ fontSize: "10px" }}>6.6</Typography>
            <Typography sx={{ fontSize: "10px" }}>
              The Applicant (s) is/are aware that the dimension, shape and size
              of the Premises will be in accordance with the building plans that
              will be finally sanctioned by MIDC and/or other statutory body ,
              which may be subject to a varation of 3% increase and/or decrease
              in the carpet area as provided herein, as presently proposed. The
              Total Allotment Premium (i.e sale consideration ) payable on the
              basis of the carpet area of the Premises, shall be recalculated
              upon confirmation by the Developer. Subject to the provisions of
              this Application/Agreement for sale, if there is any reduction in
              the carpet area (as defined under the RERA Act) of the Premises
              then , the Allotment Premium (i.e sale consideration) of the said
              Premises shall stand proportionalely reduced and the balance
              amounts shall be adjusted at the time of payment of last
              installment of the Allotment Premium payable by the Applicant(s).
              If there is any increase in the carpet area allotted to
              Applicant(s), the Developer shall demand additional amount from
              the Applicant(s) towards Allotment Premium (i.e. sale
              consideration) at the time of payment of the last installment of
              Allotment Premium payable by the Applicant(s). The Applicant(s)
              hereby gives irrecoverable consent to all of the above. The
              Apllicant(s) is aware and agrees and confirms that the Developer
              shall be entitled to make variations in the amenities and
              specifications, including but not limited to re-location of water,
              power, sewage, telephone and other service and utility connection
              , facilities and underground water tanks, pumps , recreation
              areas, club level and their dimensions as deemed fit by the
              Developer and permited under the relevant statutory rules and
              regulations related thereto.
            </Typography>
          </Box>
          <Box sx={{ display: "flex", gap: "10px" }}>
            <Typography sx={{ fontSize: "10px" }}>6.7</Typography>
            <Typography sx={{ fontSize: "10px" }}>
              It is expressly agreed that the right of the Applicant(s), on the
              execution of the Agreement for Sale and subject of the
              Applicant(s) making timely payments and complying with the terms
              thereunder, shall not only be restricted to the Premises and the
              Developer shall be entitled to deal with all the other Premises
              and common areas, amenities and car parking spaces in the Project
              without any reference or recourse or consent or concerrance from
              the Applicant(s) in any manner whatsoever.
            </Typography>
          </Box>
          <Box sx={{ display: "flex", gap: "10px" }}>
            <Typography sx={{ fontSize: "10px" }}>6.8</Typography>
            <Typography sx={{ fontSize: "10px" }}>
              The Developer has informed the Applicant(s) that this Project is
              being developed as a layout proposal consisting of construction of
              multistoried building on the said Project Property. The
              Applicant(s) is/are aware and accepts that the Derveloper shall
              ultimately develop the Project as per the proposed plans, which
              have been disclosed to the Applicant(s) and which has also been
              mentioned on the website of the Regulatory Authority.
            </Typography>
          </Box>
          <Box sx={{ display: "flex", gap: "10px" }}>
            <Typography sx={{ fontSize: "10px" }}>6.9</Typography>
            <Typography sx={{ fontSize: "10px" }} className={classes.input}>
              The Developer proposes to contruct certain amenities and
              facilities, which shall be used and enjoyed only by the
              residential flat purchasers of the Project as has been disclosed
              on the portal / website of the Regulatory Authority, presently
              being https://maharera.mahaonline.gov.in/ and on payment of
              regular taxes, charges, maintenance charges, etc. for the same.
              The Developer has the unfettered right to use and exploit the
              entire available FSI in respect of the Project layout including
              but not limited to the FSI being available for being available for
              any area under set back or reservation in any form, the FSI in
              respect of staircase, passage, lift, basement, stilt, etc, in
              present or future and/or if at any time hereafter and the
              Developer shall be entitled to use the same at any time , at its
              discretion in the manner it may deem fit and appropriate in
              accordance with the applicable laws. The Current FSI potential on
              the Said Land is  
              <input 
                style={{ textAlign: "center" }}
                value={pageData9.accept_data1}
                type="text"
                name="accept_data1"
                onChange={handleChange}
              />  and developer has proposed to utilize  
              <input 
                style={{ textAlign: "center" }}
                value={pageData9.accept_data2}
                type="text"
                name="accept_data2"
                onChange={handleChange}
              />
                FSI for the Phase I of the Said Project.
            </Typography>
          </Box>
        </Stack>
      </Box>

      <Box
        sx={{
          display: "flex",
          gap: "40px",
          justifyContent: "center",
          position: "absolute",
          bottom: "30px",
        }}
      >
        <Box className={classes.input}>
          <Box>
            <input
              style={{ textAlign: "center" }}
              value={pageData9.sign_first}
              type="text"
              name="sign_first"
              onChange={handleChange}
            />
            <Typography sx={{ textAlign: "center", mt: "10px" }}>
              Signature of First Applicant
            </Typography>
          </Box>
        </Box>
        <Box className={classes.input}>
          <Box sx={{ display: "inline-block" }}>
            <input
              style={{ margin: "0px", textAlign: "center" }}
              value={pageData9.sign_second}
              type="text"
              name="sign_second"
              onChange={handleChange}
            />
            <Typography sx={{ textAlign: "center", mt: "10px" }}>
              Signature of Second Applicant
            </Typography>
          </Box>
        </Box>
        <Box className={classes.input}>
          <Box sx={{ display: "inline-block" }}>
            <input
              style={{ margin: "0px", textAlign: "center" }}
              value={pageData9.sign_third}
              type="text"
              name="sign_third"
              onChange={handleChange}
            />
            <Typography sx={{ textAlign: "center", mt: "10px" }}>
              Signature of Third Applicant
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default PdfOnePageNine;
