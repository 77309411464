import { Box, Button, Drawer, Stack, Typography } from "@mui/material";
import React, { useState } from "react";
import SideBar from "../Components/sideBar/SideBar";
import ActiveTeam from "./ActiveTeam";
import RightSidebarTD from "./components/MembersDirectory/RightSidebarTD";
import InvitedTeam from "./InvitedTeam";

const CPTeamsjs = () => {
  const [State, setState] = useState(false);
  const [trigger, settrigger] = useState();
  const [curPage, setCurPage] = useState(1);

  return (
    <SideBar active={"Teams"}>
      <React.Fragment>
        <Drawer anchor="right" open={State} onClose={() => setState(false)}>
          <RightSidebarTD
            curselectedRows={[]}
            setTrigger={settrigger}
            trigger={trigger}
            updateData={[]}
            setState={setState}
          />
        </Drawer>
      </React.Fragment>

      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        sx={{ height: "68px", marginBottom: "20px" }}
        >
        <Box>
          <Typography
            sx={{
              fontSize: "22px",
              fontWeight: "600",
              color: "black",
            }}
            >
            Teams Directory
          </Typography>
          <Typography
            sx={{
              color: "#B9B7D0",
              fontSize: "16px",
              fontWeight: "400",
            }}
          >
          Manage your Teams
          </Typography>
        </Box>
      </Stack>

      <Box sx={{mt: "0px"}}>
        <Box
          sx={{
            borderBottom: "3px solid #e4e4e4",
            display: "flex",
            gap: "50px",
            alignItems: "center",
          }}
          >
          <Box
            onClick={() => setCurPage(1)}
            sx={{
              padding: "10px 0px",
              fontFamily: "Circular Std",
              fontWeight: "400",
              color: curPage == 1 ? "#2979F2" : "#A4A4AD",
              fontSize: curPage == 1 ? "20px" : "18px",
              borderBottom: curPage == 1 ? "3px solid #2979F2" : "",
              cursor: "pointer",
              marginBottom: "-2px",
              borderRadius: "3px",
            }}
          >
            Active
          </Box>

          <Box
            onClick={() => setCurPage(2)}
            sx={{
              padding: "10px 0px",
              fontFamily: "Circular Std",
              fontWeight: "400",
              color: curPage == 2 ? "#2979F2" : "#A4A4AD",
              fontSize: curPage == 2 ? "20px" : "18px",
              borderBottom: curPage == 2 ? "3px solid #2979F2" : "",
              cursor: "pointer",
              marginBottom: "-2px",
              borderRadius: "3px",
            }}
            >
            Invited
          </Box>

        </Box>
      </Box>
      
      {/* <Stack direction="row" justifyContent="" alignItems="center" sx={{marginTop:"10px"}}>
        <Stack sx={{}}>
        <Button
          onClick={() => setState(true)}
          className="univercel_btn_class"
          sx={{
            // marginRight:"30px",
            color: "#FFFFFF",
            textTransform: "none",
            margin: "3px",
            padding: "6px 24px",
            fontFamily: "Product Sans",
            fontSize: "16px",
            width:"150px"
          }}
        >
          Add Member
        </Button>
        </Stack>
      </Stack> */}

      <Box
        sx={{
          width: "100%",
          height: "530px",
          mt: "30px",
        }}
      >
        {curPage === 1 && (
          <ActiveTeam
            curPage={curPage}
            setCurPage={setCurPage}
            setTrigger={settrigger}
            trigger={trigger}
            setMemState={setState}
            setState={setState}
            State={State}
          />
        )}
        {curPage === 2 && (
          <InvitedTeam
            curPage={curPage}
            setCurPage={setCurPage}
            setTrigger={settrigger}
            trigger={trigger}
            setState={setState}
            setMemState={setState}
            State={State}
          />
        )}
      </Box>

      {/* <View></View> */}
    </SideBar>
  );
};

export default CPTeamsjs;
