import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  MenuItem,
  Drawer,
  Box,
  Button,
  Checkbox,
  FormControl,
  InputLabel,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { Stack } from "@mui/system";
import TuneOutlinedIcon from "@mui/icons-material/TuneOutlined";
import KeyboardArrowRightOutlinedIcon from "@mui/icons-material/KeyboardArrowRightOutlined";
import KeyboardArrowUpOutlinedIcon from "@mui/icons-material/KeyboardArrowUpOutlined";
import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined";
import { CheckBox } from "@mui/icons-material";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import DriveFileRenameOutlineIcon from "@mui/icons-material/DriveFileRenameOutline";
import VisibilityIcon from "@mui/icons-material/Visibility";
import DeleteIcon from "@mui/icons-material/Delete";
//   import "../MembersDirectory/MDmainPage.css";
import React, { useContext, useEffect, useState } from "react";
import { DataGrid, GridExpandMoreIcon } from "@mui/x-data-grid";
import CategoryIcon from "@mui/icons-material/Category";
import HandshakeIcon from "@mui/icons-material/Handshake";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import FilterAltOffIcon from '@mui/icons-material/FilterAltOff';

import axios from "axios";
// import UDcustomTable from "./UDcustomTable";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import CircularProgress from "@mui/material/CircularProgress";
// import { MarkOccupancy } from "./MarkOccupancy";
import builderDashboard from "../../../../context/builder_dashboard/context";
import { api, Apiaddress, contains, net } from "../../../../utils/consts";
import { AuthService } from "../../../../context/buyer/services/auth";
import { useNavigate } from "react-router-dom";
import StandAloneCustomTable from "../UnitDirectory/StandAloneCustomTable";
import { MarkOccupancy } from "../UnitDirectory/MarkOccupancy";
import SideBar from "../../Components/sideBar/SideBar";
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import NavigateBack from "../../../CommonPages/Shared/NavigateBack";

// import StandAloneCustomTable from "./StandAloneCustomTable";

function CpProjectUnitMainPage({ builderInvited }) {
  let builderSelected = JSON.parse(localStorage.getItem("BuilderSelected"));
  let projectid = JSON.parse(localStorage.getItem("BuilderSelectedProject"));
  console.log({ projectid });
  const nav = useNavigate();

  const [loading, setloading] = useState(false);
  const [newRowData, setNewRowData] = useState([]);
  const [state, setState] = React.useState(false);
  const [trigger, setTrigger] = useState(false);

  const [selectedUnits, setSelectedUnits] = useState([]);
  const [data, setData] = useState(null);
  const [startUnit,setStartUnit] = useState(0);
  const [totalUnit,setTotalUnit] = useState();
  const [newUnits, setNewUnits] = useState([]);
  const [curpage, setcurpage] = useState(1);
  const [matchText, setmatchText] = useState(
    "Let's Get Started, Please Add Your Data"
  );

  useEffect(() => {
    setloading(true);
    const x = async () => {
      const Auth = new AuthService();
      const res = await (
        await Auth.makeUnAuthenticatedGetRequest(
          api("/api/projects/project/?project_id=" + projectid)
        )
      ).json();
      setData(res);
      setNewRowData(res?.projects[0]?.cp_units);
      setTotalUnit(res?.projects[0]?.cp_units?.length);
    };
    x();
  }, [trigger]);

  const getInfoOfParticularunit=async ()=>{
    setloading(true);
    let arr = [];
    let st=startUnit;
    let ed=startUnit+7<newRowData?.length?startUnit+7:newRowData?.length;
    const Auth = new AuthService();
    for (let i = st; i < ed; i++) {
      let res = await (await Auth.makeUnAuthenticatedGetRequest(net(`projects/cpunit/?unit_id=${newRowData[i]}`))).json();
      console.log(res?.unit[0]);
      let temp=res?.unit[0];
      let unit_data = JSON.parse(temp?.unit_data);
      let status = "";
      if (temp?.unit_status == 1) {
        status = "Unavailable";
      } else if (temp?.unit_status == 2) {
        status = "Reserved";
      } else if (temp?.unit_status == 3) {
        status = "Registered";
      } else if (temp?.unit_status == 4) {
        status = "Booked";
      } else if (temp?.unit_status == 5) {
        status = "Available";
      }

      let obj = {
        id: temp?.id,
        name: temp?.name,
        status: status,
        for: unit_data?.For,
        type: unit_data?.Type,
        city: unit_data?.City,
        unit_data: unit_data,
      };
      arr.push(obj);
      }
      setNewUnits(arr);
      setloading(false);
  }

  // const fetchUnit = async () => {
  //   setloading(true);
  //   try {
  //     if (data) {
  //       let cpUnit = data?.projects[0]?.cp_units;
  //       console.log(cpUnit);
  //       let unit = [];
  //       const Auth = new AuthService();
  //       for (let i = 0; i < cpUnit.length; i++) {
  //         let res = await (
  //           await Auth.makeUnAuthenticatedGetRequest(
  //             net(`projects/cpunit/?unit_id=${cpUnit[i]}`)
  //           )
  //         ).json();
  //         console.log(res?.unit[0]);
  //         unit.push(res?.unit[0]);
  //       }
  //       setNewRowData(unit);
  //     }
  //   } catch (err) {
  //     console.log("====================", err);
  //     setNewRowData([]);
  //   } finally {
  //     setloading(false);
  //   }
  // };

  // useEffect(() => {
  //   fetchUnit();
  // }, [trigger, data]);

  useEffect(() => {
    if (newRowData) {
      getInfoOfParticularunit();
    }
  }, [newRowData,startUnit]);

  //fitering data starts:====
  const [filterselect, setfilterselect] = useState();
  const [filtervalue, setfiltervalue] = useState();
  const [expanded, setExpanded] = useState(false);

  const handleChange = (event) => {
    setfilterselect(event.target.value);
    setfiltervalue("");
  };

  console.log(filterselect);
  console.log(filtervalue);

  const fetchfilter = async () => {
    try {
      const Auth = new AuthService();
      const res = await (
        await Auth.makeUnAuthenticatedGetRequest(
          // api(`/api/projects/project/?filtervalue=${filtervalue}&filterkey=${filterselect}&project_id=${projectid}`)
          api(`/api/projects/projectById/?filtervalue=${filtervalue}&filterkey=${filterselect}&project_id=${projectid}`)
        )
      ).json();
      let arr=[];
      let temp=res?.filterUnit;
      for(let i=0;i<temp.length;i++){
      let unit_data = JSON.parse(temp[i]?.unit_data);
      let status = "";
      if (temp[i]?.unit_status == 1) {
        status = "Unavailable";
      } else if (temp[i]?.unit_status == 2) {
        status = "Reserved";
      } else if (temp[i]?.unit_status == 3) {
        status = "Registered";
      } else if (temp[i]?.unit_status == 4) {
        status = "Booked";
      } else if (temp[i]?.unit_status == 5) {
        status = "Available";
      }
      let obj = {
        id: temp[i]?.id,
        name: temp[i]?.name,
        status: status,
        for: unit_data?.For,
        type: unit_data?.Type,
        city: unit_data?.City,
        unit_data: unit_data,
      };
        arr.push(obj);
      }
      setNewUnits(arr);
      setloading(false);
      // setData(res);
      // setNewRowData(res?.projects?.cp_units);
      // setTotalUnit(res?.projects?.cp_units?.length);
    } catch (err) {
      console.log(err);
    }
  };


  return (
    <>
      <Stack direction="row" sx={{ width: "100%", height: "auto"}}>
        <SideBar active={"Inventory"}
        >
          <Stack
            direction="column"
            sx={{
              backgroundColor: "#f9f9f9",
              width: "100%",
              boxSizing: "border-box",
              marginTop: "0px",
            }}
          >
            <Drawer
              anchor="right"
              open={state}
              onClose={() => {
                setState(false);
              }}
            >
              <MarkOccupancy
                setState={setState}
                selectedUnits={selectedUnits}
                setSelectedUnits={setSelectedUnits}
                setTrigger={setTrigger}
                trigger={trigger}
              />
            </Drawer>
            <Stack sx={{ mt: "0px", backgroundColor: "#f9f9f9"}}>

            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              sx={{ marginBottom: "10px" }}
             >
              <Stack>
                <Stack direction="row">
                  <Box sx={{ pt: "5px" }}>
                    <NavigateBack fontSize="22px" />
                  </Box>
                  <Typography
                    sx={{
                      fontSize: "22px",
                      fontWeight: "600",
                      color: "black",
                    }}
                    >
                    Inventory
                  </Typography>
                </Stack>
                <Typography
                  sx={{
                    color: "#B9B7D0",
                    fontSize: "16px",
                    fontWeight: "400",
                  }}
                >
                  Manage your Inventory with ease
                </Typography>
              </Stack>
            </Stack>

              <Stack
                direction="row"
                justifyContent=""
                alignItems="center"
                sx={{ marginTop: "20px" }}
                >
                  <Stack direction="row" sx={{borderBottom: "3px solid #2979F2"}}>
                    <Typography sx={{
                    fontSize: "20px",
                    fontWeight: "400",
                    color: "#2979F2",
                    fontFamily: "Circular Std",
                    // borderBottom: "3px solid #2979F2",
                    paddingBottom:"10px",
                    marginTop:"-2px",
                    marginRight:"8px"
                  }}>
                    Unit List
                    </Typography>
                    <Typography sx={{
                    fontSize: "20px",
                    fontWeight: "550",
                    color: "#2979F2",
                    fontFamily: "Circular Std",
                    // borderBottom: "3px solid #2979F2",  
                    paddingBottom:"10px",
                    marginTop:"-2px",
                    marginRight:"8px"
                  }}>
                    {`of ${data?.projects[0]?.name?data?.projects[0]?.name.charAt(0).toUpperCase()+data?.projects[0]?.name.slice(1):""}`}
                      {/* {data?.projects[0]?.name.charAt(0).toUpperCase()+data?.projects[0]?.name.slice(1)} */}
                    </Typography>
                  </Stack>
                  {/* {data == null
                    ? "Individual Unit List"
                    : `Unit List of ${data?.projects[0]?.name}`} */}
            </Stack>
            <Box
                  sx={{
                    border: "1.55px solid #e4e4e4",  
                  }}
                ></Box>

              <Stack direction="row" justifyContent="space-between" alignItems="center">

                <Stack direction="row" alignItems="center" sx={{marginTop:"20px"}}>
                {!builderInvited && (
                  <Button
                    onClick={() => {
                      nav(
                        `/channelpartner/project/unit/add/${builderSelected?.selectedProjectID}`
                      );
                    }}
                    className="univercel_btn_class"
                    sx={{
                      color: "#FFFFFF",
                      textTransform: "none",
                      width: "120px",
                      padding: "8px 0px",
                      marginRight:"20px"
                    }}
                  >
                    Add unit
                  </Button>
                )}
                {selectedUnits.length != 0 && (
                  <Stack direction="row">
                    <Button
                      onClick={() => {
                        setState(true);
                      }}
                      startIcon={<CategoryIcon />}
                      className="univercel_btn_class"
                      sx={{
                        color: "#FFFFFF",
                        textTransform: "none",
                        width: "170px",
                      }}
                    >
                      Mark Occupancy
                    </Button>
                  </Stack>
                )}
                </Stack>

                <Stack
                direction="row"
                justifyContent="right"
                alignItems="center"
                sx={{
                  width: "203px",
                  height: "0px",
                  borderRadius: "12px",
                  color: "#3E4954",
                  marginTop:"20px"  
                }}
              >
              <Accordion 
              expanded={expanded}
              sx={{ width: "150px", boxShadow: "none",zIndex:"3"}}>
                  <AccordionSummary onClick={()=>{setExpanded(!expanded)}}
                    expandIcon={<GridExpandMoreIcon/>}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography sx={{ display: "flex", placeItems: "center" }}>
                      <TuneOutlinedIcon sx={{ color: "#2F4CDD" }} />
                      <Typography sx={{ marginLeft: "10px" }}>
                        Fillters
                      </Typography>
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Box sx={{textAlign:"center"}}>
                      <FormControl sx={{ width: "118px" }}>
                        <InputLabel id="demo-simple-select-label">
                          Select
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={filterselect}
                          label="Select"
                          onChange={handleChange}
                        >
                          <MenuItem value={"name"}>Name</MenuItem>
                          <MenuItem value={"state"}>State</MenuItem>
                          <MenuItem value={"city"}>City</MenuItem>
                          <MenuItem value={"pin_code"}>Pincode</MenuItem>
                        </Select>
                      </FormControl>
                      <TextField
                        sx={{ marginTop: "20px",fontSize:"8px"}}
                        placeholder="Value" 
                        value={filtervalue} onChange={(e)=>{setfiltervalue(e.target.value)}}
                      />
                      <Button 
                        // onClick={fetchfilter}
                        onClick={()=>{fetchfilter();setExpanded(false);setmatchText(
                          "No Results Found With The Applied Filters"
                        )}}
                        variant="contained"
                        className="univercel_btn_class"
                        color="primary" 
                        sx={{margin:"20px 0px 10px 0px"}}
                        >
                         Submit
                      </Button>

                    </Box>
                  </AccordionDetails>
                </Accordion>
                {expanded ==false && filtervalue && <RestartAltIcon 
                sx={{margin:"0px 10px",cursor:"pointer"}}
                onClick={()=>{
                  setfiltervalue("");
                  // setStartUnit(0);
                  // setmatchText("Let's get started, Please add your data");
                  // setcurpage(1);
                  setTrigger(!trigger);
                }}/>}
                
              </Stack>
              </Stack>

              <StandAloneCustomTable
                projectid={projectid}
                selectedUnits={selectedUnits}
                setSelectedUnits={setSelectedUnits}
                newRowData={newUnits}
                loading={loading}
                CircularProgress={CircularProgress}
                isStand={false}
                setStartUnit={setStartUnit}
                startUnit={startUnit}
                totalUnit={totalUnit}
              // fetchfilter={fetchfilter}
                matchText={matchText}
                curpage={curpage}
                setcurpage={setcurpage}
              />
            </Stack>
          </Stack>
        </SideBar>
      </Stack>
    </>
  );
}

export default CpProjectUnitMainPage;
