import { Box, List, ListItem, Typography } from "@mui/material";
import "./styles/PageSix.css";
import React, {useState } from 'react';
import { Stack } from "@mui/system";
import classes from "./styles/PageOne.module.css";

const PdfOnePageTen = () => {

  const [pageData9,setPageData9]=useState({
    sign_first:"",
    sign_second:"",
    sign_third:"",
  })

  let name,value;
  const handleChange=(e)=>{
    name=e.target.name;
    value=e.target.value;
    console.log(name," ",value);
    setPageData9({...pageData9,[name]:value});
  }

  return (
    <Box>
      <Box sx={{ mb: "40px" }}>
        <Box sx={{ ml: "30px", display: "flex", gap: "10px" }}>
          <Typography sx={{ fontSize: "10px" }}>6.10</Typography>
          <Typography sx={{ fontSize: "10px" }}>
            Car Parking Spaces: The Applicant(s) acknowledge(s) and confirms
            that the car parking space(s) as requested, if accepted, will be
            reserved for the Applicant(s) only as an additional amenity appended
            to the Premises, subject to the availability. The Applicant(s) agree
            that the final allotment of the car parking space(s) shall be at the
            time of handover of posssession of the said Premises. The
            Applicant(s) agree(s) that only exlusive use of the identified car
            parking spaces will be alloted to the Applicant(s) and the same will
            not create any other right to the car parking spaces other than
            right to user. The Applicant(s) agree(s) that the car parking spaces
            cannot be sold or dealt with independent of the Premises. All
            clauses of this Application and the Agreement for Sale pertaining to
            allotment, possession, cancellation etc. of the Premises shall also
            apply mutatis mutandis to the car parking space(s).
          </Typography>
        </Box>
        <Box sx={{ ml: "30px", display: "flex", gap: "10px" }}>
          <Typography sx={{ fontSize: "10px" }}>6.11</Typography>
          <Typography sx={{ fontSize: "10px" }}>
            The tentative location of the proposed building in which the
            proposed Premises is to be located is shown on red colour boundary
            lines on the Layout Plan annexed hereto and marked as Annexure”2”.
          </Typography>
        </Box>
        <Box sx={{ ml: "30px", display: "flex", gap: "10px" }}>
          <Typography sx={{ fontSize: "10px" }}>6.12</Typography>
          <Typography sx={{ fontSize: "10px" }}>
            The Premises will be a bare shell flat as per the tentative typical
            floor plan annnexed hereto and marked as Annexure “3”.
          </Typography>
        </Box>
        <Box sx={{ ml: "30px", display: "flex", gap: "10px" }}>
          <Typography sx={{ fontSize: "10px" }}>6.13</Typography>
          <Typography sx={{ fontSize: "10px" }}>
            The Commencement Certificate as aforesaid for the development of the
            Said project is annexed hereto and marked as Annexure “4”.
          </Typography>
        </Box>
        <Box sx={{ ml: "30px", display: "flex", gap: "10px" }}>
          <Typography sx={{ fontSize: "10px" }}>6.14</Typography>
          <Typography sx={{ fontSize: "10px" }}>
            The currently approved plans of the said project as approved by the
            Competent Authority are annexed hereto and marked as Annexure “5”.
          </Typography>
        </Box>
        <Box sx={{ ml: "30px", display: "flex", gap: "10px" }}>
          <Typography sx={{ fontSize: "10px" }}>6.15</Typography>
          <Typography sx={{ fontSize: "10px" }}>
            The bank detail for the payment of Stamp Duty Charges, Registration
            Charges, Document Handling Charges and Service Charges is annexed
            hereto and marked as Annexure “6”. The Applicant(s) undertake/s to
            make the payment of the same once he/she/they/it receives
            confirmation letter as aforesaid from the Developers.
          </Typography>
        </Box>
        <Box sx={{ ml: "30px", display: "flex", gap: "10px" }}>
          <Typography sx={{ fontSize: "10px" }}>6.16</Typography>
          <Typography sx={{ fontSize: "10px" }}>
            The Project shall be registered in different phases with MahaRERA
            and phase I has been registered under registeration no.
            P52000027215. The Project Registration certificate is annexed hereto
            and marked as Annexure “7”.
          </Typography>
        </Box>

        <Stack spacing={0.5} sx={{ ml: "10px", mt: "10px" }}>
          <Box sx={{ display: "flex", gap: "10px" }}>
            <Box>
              <Typography sx={{ fontSize: "10px" }}>7.</Typography>
            </Box>
            <Stack spacing={0.5}>
              <Typography sx={{ fontSize: "10px" }}>
                SCHEDULE OF PAYMENTS
              </Typography>
              <Box sx={{ display: "flex", gap: "10px" }}>
                <Typography sx={{ fontSize: "10px" }}>7.1</Typography>
                <Typography sx={{ fontSize: "10px" }}>
                  The Applicant(s) is aware that in the event of any of the
                  payments made by the Applicant(s) towards the Application
                  Monies and/or applicable taxes in terms of this Application,
                  not being duly honoured / cleared or delayed, then this
                  Application, shall be invalid and be deemed to have been
                  withdrawn by the Applicant(s) and rejected by the Developer.
                  On such rejection of the Application, the Developer shall
                  refund the monies in the manner mentioned in Clause 9.4 and
                  Clause 9.5 hereinbelow.
                </Typography>
              </Box>
              <Box sx={{ display: "flex", gap: "10px" }}>
                <Typography sx={{ fontSize: "10px" }}>7.2</Typography>
                <Typography sx={{ fontSize: "10px" }}>
                  In addition to the payments to be made in terms of this
                  Application, the Applicant(s) shall make payments of the
                  Allotment Premium in accordance with the Payment Schedule set
                  out herein, time being of the essence.
                </Typography>
              </Box>
              <Box sx={{ display: "flex", gap: "10px" }}>
                <Typography sx={{ fontSize: "10px" }}>7.3</Typography>
                <Typography sx={{ fontSize: "10px" }}>
                  The Developer shall as per the Payment Schedule, intimate in
                  writing to the Applicant(s) herein to make payment of the
                  amount payable as stated in Payment Schedule and the
                  Applicant(s) shall without any demur or protest, make the
                  payment within 10 days of issuance of intimation in writing by
                  the Developer, time being of the essence. In the event such
                  payment is not made within the prescribed period of 10 days,
                  then on the expiry of 10 days, the Developer shall be entitled
                  to issue a notice in writing for payment of the outstanding
                  amount along with interest at the Interest Rate payable from
                  the due date for payment till the payment thereof.
                </Typography>
              </Box>
              <Box sx={{ display: "flex", gap: "10px" }}>
                <Typography sx={{ fontSize: "10px" }}>7.4</Typography>
                <Typography sx={{ fontSize: "10px" }}>
                  It is clarified that the Allotment Premium as mentioned in
                  this Application Form is exclusive of all applicable taxes,
                  levies, charges etc., i.e the property tax, land revenue, NA
                  Taxes, other taxes (Municipal/ State/ Central) and/or other
                  statutory duties, Service Tax, VAT, GST, levies, cesses,
                  charges, deposits, premiums, duties imposed by the Statutory
                  Authorities, Stamp Duty, Registration Charges, Common Area
                  Maintainance Charges, Facility Management Fee, and /or other
                  outgoings by any other names in respect of and applicable to
                  Project Property, Project, Building and Premises, existing on
                  or imposed after the date of this Application, whether payable
                  and/or in future and/or those which is/are sub-judice,
                  including interest and penalties theron, including those which
                  may become enforceable retrospectively and computed as per
                  law, and shall be to the account and liability of and borne
                  and paid by the Applicant(s) alone, without any delay/protest.
                  Further, the Applicant(s) agrees that in the event of non
                  payment of such amount or taxes the Developer shall have first
                  lien on Premises for the recovery of such amount without
                  prejudice to the right of the Developer under clause 9.1
                  hereinbelow.
                </Typography>
              </Box>
              <Box sx={{ display: "flex", gap: "10px" }}>
                <Typography sx={{ fontSize: "10px" }}>7.5</Typography>
                <Box>
                  <Typography sx={{ fontSize: "10px" }}>
                    The Applicant(s) shall, in addition to the Allotment Premium
                    as mentioned in this Application Form for the Premises,
                    whenever called upon by the Developer, pay to the Developer
                    the following amount(s), as set out herein:
                  </Typography>
                  <Typography sx={{ fontSize: "10px" }}>
                    7.5.1. Applicable charges/deposits for
                    installation/connection for electricity/water/gas/telephone/
                    Mobile connectivity/ Internet/ IPTV/ Cable and/or Digital TV
                    Services and for other incidental charges.{" "}
                  </Typography>
                  <Typography sx={{ fontSize: "10px" }}>
                    7.5.2. The Applicant(s) shall also make the payments,
                    towards legal fees, share Application money as shall be
                    determined and specified by the Developer.
                  </Typography>
                </Box>
              </Box>
            </Stack>
          </Box>
        </Stack>
      </Box>
      <Box
        sx={{
          display: "flex",
          gap: "40px",
          justifyContent: "center",
          position: "absolute",
          bottom: "30px",
        }}
      >
        <Box className={classes.input}>
          <Box>
            <input
              style={{ textAlign: "center" }}
              value={pageData9.sign_first}
              type="text"
              name="sign_first"
              onChange={handleChange}
            />
            <Typography sx={{ textAlign: "center", mt: "10px" }}>
              Signature of First Applicant
            </Typography>
          </Box>
        </Box>
        <Box className={classes.input}>
          <Box sx={{ display: "inline-block" }}>
            <input
              style={{ margin: "0px", textAlign: "center" }}
              value={pageData9.sign_second}
              type="text"
              name="sign_second"
              onChange={handleChange}
            />
            <Typography sx={{ textAlign: "center", mt: "10px" }}>
              Signature of Second Applicant
            </Typography>
          </Box>
        </Box>
        <Box className={classes.input}>
          <Box sx={{ display: "inline-block" }}>
            <input
              style={{ margin: "0px", textAlign: "center" }}
              value={pageData9.sign_third}
              type="text"
              name="sign_third"
              onChange={handleChange}
            />
            <Typography sx={{ textAlign: "center", mt: "10px" }}>
              Signature of Third Applicant
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default PdfOnePageTen;
