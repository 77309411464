import React, { useContext, useEffect } from "react";
import { TextField } from "@mui/material";
import { useState } from "react";

import { Box, Button, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Divider from "@mui/material/Divider";
import axios from "axios";

import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

// import { listUnitTemplateOfProject } from '../../../../connection/project/unit_templates_of_project';
// import { createFloorTemplate } from '../../../../connection/project/create_floor_template';

import { MainSidebar } from "../ProjectDashboard/DashboardComponents/MainSidebar";
import { Navbar } from "../ProjectDashboard/DashboardComponents/Navbar";
import { Apiaddress } from "../../../utils/consts";
import { ConfirmProject_steps } from "../ProjectDashboard/CreateProject/ConfirmProject_steps";
import { BackeToOverviewBtn } from "../ProjectDashboard/CreateProject/BackeToOverviewBtn";
import currentProjectState from "../../../context/createproject/context";

function EditProjectFloor_Add() {
  const navigate = useNavigate();

  ////get data from previous page
  const location = useLocation();
  const data = location.state;

  console.log("preve data =>.>", data);

  const [floortemp, setfloortemp] = useState([]);

  const [floorTemplates, setFloorTemplates] = useState(data?.wing_template);

  const [floor, setFloor] = useState({
    floor_no: "",
    floor_id: "",
    floor_name: "",
  });

  //   const cpContext = useContext(currentProjectState);
  //   const projectID = cpContext?.createProjectState?.project?.id;

  //get project id from local storage;

  const projectfromlocal = JSON.parse(localStorage.getItem("BuilderSelected"));
  const projectID = projectfromlocal.selectedProjectID;
  console.log(projectID);

  ///for get floor list
  useEffect(() => {
    const floorurl =
      Apiaddress +
      "/api/projects/floortemplatesbyproject/?project_id=" +
      projectID;
    axios
      .get(floorurl)
      .then((res) => {
        const data = res?.data;
        console.log(data);
        if (data.floor_templates !== "Wing Template not found") {
          let arr = data.floor_templates;
          console.log("=====================>>>: ", arr);
          console.log("=====================>>>: ", floorTemplates);
          // finding template already present or not if present then not show
          let flag = false;
          let temp = [];
          for (let i = 0; i < arr.length; i++) {
            let ele = arr[i].id;
            for (let j = 0; j < floorTemplates.length; j++) {
              if (floorTemplates[j].floor_id == ele) {
                flag = true;
                console.log(
                  "=>>>.>>>>>>>>>>>>>>>>>>>>>>>",
                  floorTemplates[j].floor_id,
                  ele
                );
              }
            }
            if (flag === false) {
              temp.push(arr[i]);
              console.log(arr[i]);
            }
            flag = false;
          }
          setfloortemp([...temp]);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  // //function for create floor Templates
  const createFloorTemplate = () => {
    //  console.log(floor);
    setFloorTemplates([...floorTemplates, floor]);
    if (floor.floor_id == "" || floor.floor_name == "" || floor.floor_no == "")
      return;
    let url = Apiaddress + "/api/projects/buildingtemplates/";

    let st = {
      building_template_id: data?.id,
      wing_template: [...floorTemplates, floor],
    };

    let headers = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    //   console.log(st);

    axios
      .patch(url, st, headers)
      .then((res) => {
        console.log("add wing template in building======>", res.data);
        // navigate("/confirm-project-wings")
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <>
      <Stack direction="row" sx={{ width: "100%", height: "auto" }}>
        {/* 1st stack */}
        <Stack
          sx={{ minHeight: "100vh", width: "20%", boxSizing: "border-box" }}
        >
          {" "}
          {/*for project directory 20% and for sales dashboard 225*/}
          <MainSidebar active={"Project Directory"} />
        </Stack>

        {/* 2nd stack */}
        <Stack
          direction="column"
          sx={{
            backgroundColor: "#f9f9f9",
            width: "80%",
            padding: "25px",
            boxSizing: "border-box",
          }}
        >
          {" "}
          {/*  width for main sidebar 78px and for 80px*/}
          <Navbar editProject={true} />
          <Typography
            sx={{
              marginTop: "20px",
              fontSize: "28px",
              fontWeight: "600",
              marginLeft: "10px",
              marginBottom: "50px",
            }}
          >
            Add Floor
          </Typography>
          {/* actual content  */}
          <Stack sx={{ backgroundColor: "#f9f9f9" }}>
            {" "}
            {/* mt:"28px", */}
            <Stack
              direction="column"
              sx={{
                padding: "20px",
                backgroundColor: "#fff",
                marginRight: "0px",
              }}
            >
              {/* floor Template */}
              <Box sx={{}}>
                <Typography> select floor to add in Building</Typography>

                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    margin: "30px 0px 0px 70px",
                  }}
                >
                  <Typography component="span" className="field-name">
                    Floors
                  </Typography>

                  <FormControl sx={{ width: "188px", marginLeft: "40px" }}>
                    <Select
                      onChange={(e) => {
                        console.log(floortemp[e.target.value]);

                        setFloor({
                          floor_no: floortemp.length + 1,
                          floor_id: floortemp[e.target.value].id,
                          floor_name: floortemp[e.target.value].name,
                        });
                      }}
                      displayEmpty
                      inputProps={{ "aria-label": "Without label" }}
                    >
                      {floortemp.map((ele, index) => {
                        return (
                          <MenuItem key={index} value={index}>
                            {ele.name}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>

                  <Typography
                    className=""
                    sx={{
                      margin: "0px 0px 0px 20px",
                      fontFamily: "Poppins",
                      fontStyle: "normal",
                      fontWeight: "500",
                      fontSize: "12px",
                      lineHeight: "18px",
                      color: "#A2A3A6",
                    }}
                  >
                    Can't Find The Template?
                  </Typography>

                  <Link to="/builder-edit-project-floor-create" state={data}>
                    <Typography
                      className=""
                      sx={{
                        margin: "0px 0px 0px 5px",
                        fontFamily: "Poppins",
                        fontStyle: "normal",
                        fontWeight: "500",
                        fontSize: "12px",
                        lineHeight: "18px",
                        color: "#FF6140",
                        cursor: "pointer",
                      }}
                    >
                      Create New
                    </Typography>
                  </Link>
                </Box>

                <Box sx={{ margin: "60px 0px 60px 0px" }}>
                  <Link
                    onClick={() => {
                      createFloorTemplate();
                    }}
                    to="/builder-edit-project-floor"
                    state={data}
                  >
                    <Button
                      className="univercel_btn_class"
                      variant="contained"
                      sx={{
                        padding: "16px 50px 16px 50px",
                        color: "#fff",
                        fontSize: "18px",
                        fontWeight: "700",
                      }}
                    >
                      Save
                    </Button>
                  </Link>
                </Box>
              </Box>
            </Stack>
          </Stack>
        </Stack>
      </Stack>
    </>
  );
}

export default EditProjectFloor_Add;
