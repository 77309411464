import { Box, Button, FormControl, MenuItem, Select, Stack, TextField, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import { SuperAdminNavbar } from '../SidebarNavbar/SuperAdminNavbar'
import { SuperAdminSidebar } from '../SidebarNavbar/SuperAdminSidebar'
import { Apiaddress } from "../../../utils/consts";
import axios from 'axios';

function SuperAdminMemberOverview() {

  // SAteammemberDetails
  let roleDetails = JSON.parse(localStorage.getItem("SAteammemberDetails"));
    console.log(roleDetails);

  //get data from previous page 
    const location = useLocation();
    const dataobj = location.state;
    console.log("preve data =>.>",dataobj);

    let  token  = localStorage.getItem("crm_token")
    // console.log(token);

    const navigate = useNavigate();

    const [name,setname]=useState();
    const [email,setemail]=useState();
    const [phone,setphone]=useState();
    const [role,setrole]=useState();
    const [roleid,setroleid]=useState();
    const [roleData,setRoleData]=useState();
    const [trigger,setTrigger]=useState();

    // console.log(role);
    // console.log(roleid);

    const fetchProfile=async ()=>{
      try{
    const roleurl = Apiaddress+`/api/role/memberview/?member_id=${roleDetails?.selectedmemberId}`
    const res = await axios.get(roleurl);
    console.log("=============================",res?.data?.member);
      setname(res?.data?.member?.name);
      setemail(res?.data?.member?.email);
      setphone(res?.data?.member?.phone);
      setrole(res?.data?.member?.role);
    }
    catch(err){
      console.log(err);  
    }
    }

    useEffect(()=>{
      fetchProfile();
    },[trigger])

    const fetchRoleData=async ()=>{
    try{
      const headers = {
        Authorization: `JWT ${token}`,
      };  
    const roleurl = Apiaddress+"/api/roleprofile/"
    const res = await axios.get(roleurl,{headers});
    console.log("=============================",res?.data?.roles);
    setRoleData(res?.data?.roles);
    }
    catch(err){
      console.log(err);
    }
    }

    const editRole= async ()=>{
      try{
      let obj={
        name:name,
        email:email,
        phone:phone,
        role:role,
        member_id:roleDetails?.selectedmemberId
      }
    console.log(obj);
    const roleurl = Apiaddress+"/api/role/memberview/"
    const res = await axios.patch(roleurl,obj);
    console.log("=============================",res?.data);
    setTrigger(!trigger);
    navigate("/superadmin-team-member");
    }
    catch(err){
      console.log(err);
    }
    }

    useEffect(()=>{
      fetchRoleData();
    },[])

  return (
    <>
       <Stack direction="row" sx={{width:"100%",height:"auto" }}>
        {/* 1st stack */}
        <Stack sx={{minHeight:"100vh",width:"20%",boxSizing:"border-box",}}> {/*for project directory 20% and for sales dashboard 225*/}
            <SuperAdminSidebar active={"Teams Directory"}/>
        </Stack>
         {/* 2nd stack */}
        <Stack direction="column" sx={{ backgroundColor:"#f9f9f9",width:"80%", padding:"25px",boxSizing:"border-box",}}  >  {/*  width for main sidebar 78px and for 80px*/}
            <SuperAdminNavbar/>   {/*send props for get popup to verify otp from builder */}
            
            <Stack direction='row' justifyContent="space-between">
              <Typography sx={{fontFamily:'Poppins',fontStyle:"normal",fontWeight:"600",fontSize:"28px",lineHeight:"42px"}}>User Overview</Typography>
              <Button variant="contained" sx={{background:"linear-gradient(90deg, #0F5DAE 0%, #009DCF 100.33%)",width:"240px"}} 
                onClick={()=>{navigate('/superadmin-role-permission-edit')}}
                >
                <Box sx={{ textTransform: "capitalize" }}>View / Edit Role Permissions</Box>
              </Button>
            </Stack>

             <Stack sx={{margin:"50px 0px 30px 0px",background:"#FFFFFF"}}>
              <Box>
                <Stack direction="row" sx={{alignItems:"center",margin:"30px 0px 30px 40px"}}>
                  <Typography sx={{width:"20%",fontFamily:'Poppins',fontStyle:"normal",fontWeight:"500",fontSize:"18px",lineHeight:"27px"}}>Name</Typography>
                  <TextField value={name} onChange={(e)=>{setname(e.target.value)}}/>
                </Stack>

                <Stack direction="row" sx={{alignItems:"center",margin:"0px 0px 30px 40px"}}>
                  <Typography sx={{width:"20%",fontFamily:'Poppins',fontStyle:"normal",fontWeight:"500",fontSize:"18px",lineHeight:"27px"}}>Phone</Typography>
                  <TextField value={phone} onChange={(e)=>{setphone(e.target.value)}}/>
                </Stack>

                <Stack direction="row" sx={{alignItems:"center",margin:"0px 0px 30px 40px"}}>
                  <Typography sx={{width:"20%",fontFamily:'Poppins',fontStyle:"normal",fontWeight:"500",fontSize:"18px",lineHeight:"27px"}}>E-mail</Typography>
                  <TextField value={email} onChange={(e)=>{setemail(e.target.value)}}/>
                </Stack>

                <Stack direction="row" sx={{alignItems:"center",margin:"0px 0px 30px 40px"}}>
                  <Typography sx={{width:"20%",fontFamily:'Poppins',fontStyle:"normal",fontWeight:"500",fontSize:"18px",lineHeight:"27px"}}>Role</Typography>
                  <TextField value={role} disabled/>
                </Stack>
              </Box>

              <Box>
                 <Stack direction="row" sx={{alignItems:"center",margin:"30px 0px 30px 40px"}}>
                  <Typography sx={{width:"20%",fontFamily:'Poppins',fontStyle:"normal",fontWeight:"500",fontSize:"18px",lineHeight:"27px"}}>Change Role</Typography>
                  {/* <TextField/> */}

                  <FormControl sx={{width:"188px", marginLeft:"0px"}}>
                    <Select
                      onChange={(e) => {
                        let [name,role_id] = e.target.value.split("-");
                          setroleid(role_id);
                          setrole(name); 
                      }}
                      displayEmpty
                      inputProps={{ 'aria-label': 'Without label' }}>
                      {roleData && roleData.map((ele,index)=>{
                        return(
                          <MenuItem key={index} value={ele.name+"-"+ele.id} >{ele.name}</MenuItem>
                        )
                      })}
                    </Select>
                  </FormControl>

                  <Typography sx={{width:"20%",fontFamily:'Poppins',fontStyle:"normal",fontWeight:"500",fontSize:"16px",lineHeight:"27px",color:"red",marginLeft:"30px",cursor:"pointer"}} onClick={()=>{
                     navigate('/superadmin-role-overview')
                  }}>Create New</Typography>
                </Stack>
              </Box>

              <Box>
                  <Stack direction="row" sx={{margin:"50px 0px 30px 0px",background:"#FFFFFF",justifyContent:"right",alignItems:"center"}}>
                    {/* <Typography sx={{width:"20%",fontFamily:'Poppins',fontStyle:"normal",fontWeight:"500",fontSize:"18px",lineHeight:"27px",marginLeft:"40px",cursor:"pointer"}} 
                     onClick={()=>{
                      // navigate(-1);
                      navigate("/superadmin-team-member");
                     }}>Back</Typography> */}
                      <Button variant="contained" sx={{background:"linear-gradient(90deg, #0F5DAE 0%, #009DCF 100.33%)",marginRight:"100px",width:"100px"}} onClick={()=>{editRole()}}>
                        <Box sx={{ textTransform: "capitalize" }}>Save</Box>
                      </Button>
                  </Stack>
              </Box>

            </Stack>

       </Stack> 

      </Stack>

    </>
  )
}

export default SuperAdminMemberOverview 