import {
  Box,
  Checkbox,
  Stack,
  Button,
  Accordion,
  AccordionSummary,
  CircularProgress,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import KeyboardDoubleArrowLeftIcon from "@mui/icons-material/KeyboardDoubleArrowLeft";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import Lottie from "react-lottie";
import TuneOutlinedIcon from "@mui/icons-material/TuneOutlined";

import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  ArrowDropDownIconSvg,
  ArrowDropUpIconSvg,
} from "../../../BuilderComponents/BuilderDasboard/assets/icons";
import { useLocation, useNavigate } from "react-router-dom";
import { AuthService } from "../../../../context/buyer/services/auth";
import { api } from "../../../../utils/consts";
import animationData from "../../../../Lotties/NEW sin movs.json";
import { GridExpandMoreIcon } from "@mui/x-data-grid";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import axios from "axios";

function LDcustomTable({ lead_id, origin, projectid, unit }) {
  ////get user roles from local storage and assign
  const [rowData, setRowData] = useState([]);
  const [currData, setCurrData] = useState([]);
  let roles = JSON.parse(localStorage.getItem("user_roles"));
  const navigate = useNavigate();
  //   console.log(rowData);
  const [currPage, setCurrPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const cpDetails = JSON.parse(localStorage.getItem("cpDetails"));
  const [startAppointment, setStartAppointment] = useState(0);
  const [length, setLength] = useState(0);
  // console.log(cpDetails);
  let cpOrg = cpDetails?.channelPartnerOrg;
  // console.log({ cpDetails })
  // console.log({origin})
  const [expandedFilter, setExpandedFilter] = useState(false);
  const [filterselect, setfilterselect] = useState();
  const [filtervalue, setfiltervalue] = useState();
  const [selectedSortUp, setSelectedSortUp] = useState("");
  const [selectedSortDown, setSelectedSortDown] = useState("");
  const [pages, setPages] = useState(0);
  const [trigger, setTrigger] = useState(false);
  const [matchText, setmatchText] = useState(
    "Let's get started,Please schedule an Apppointment"
  );

  const handleChangeFilter = (event) => {
    setfilterselect(event.target.value);
    setfiltervalue("");
  };

  const fetchAppointments = async () => {
    const Auth = new AuthService();
    setLoading(true);
    let queryParameter = "";
    let query = "";
    // console.log({ origin });

    /** this is a common fetch function that is trigged based on the location of table import */

    switch (origin) {
      case "Inventory":
        // cp_units || cp_project_units
        queryParameter = projectid ? "cp_project_units" : "cp_units";
        query = unit.id;
        break;
      case "Leads Directory":
        queryParameter = "lead_id";
        query = lead_id;
        break;
      case "Appointments":
        queryParameter = cpOrg ? "org_id" : "";
        query = cpOrg ?? "";
        break;

      default:
        window.alert("Invalid Route");
        break;
    }
    /**
     * Calling api with query params
     */
    const res = await (
      await Auth.makeAuthenticatedGetRequest(
        api(
          `/api/leads/Appointment/?${queryParameter}=${query}&limit=${7}&offset=${startAppointment}`
        )
      )
    ).json();
    setRowData(res.apppointment ?? []);
    setCurrData(res.apppointment ?? []);
    setLength(res.total ?? 0);
    setLoading(false);
  };

  useEffect(() => {
    if (origin) {
      fetchAppointments();
      setSelectedSortUp("");
      setSelectedSortDown("");
    }
  }, [origin, startAppointment, trigger]);

  // pagination
  const nextClick = () => {
    if (currPage == pages) {
      return;
    }
    setStartAppointment((p) => p + 7);
    setRowData([]);
    setCurrPage(currPage + 1);
  };
  const prevClick = () => {
    if (currPage == 1) {
      return;
    }
    setStartAppointment((p) => p - 7);
    setRowData([]);
    setCurrPage(currPage - 1);
  };

  useEffect(() => {
    if (rowData) {
      if (length % 7 == 0) {
        let tmp = length / 7;
        setPages(tmp);
      } else {
        let tmp = Math.floor(length / 7 + 1);
        setPages(tmp);
      }
    }
  }, [rowData]);

  //sorting

  /** cpName
      leadName
      unitName
      status */

  const sortArr = (para) => {
    setSelectedSortUp(para);
    setSelectedSortDown("");
    function compare(a, b) {
      let fir, sec;
      if (para == "cpName") {
        fir = a["channelPartner"]["profile"]["user_details"][
          "first_name"
        ].toLowerCase();
        sec = b["channelPartner"]["profile"]["user_details"][
          "first_name"
        ].toLowerCase();
      } else if (para == "leadName") {
        fir = a["lead"]["name"].toLowerCase();
        sec = b["lead"]["name"].toLowerCase();
      } else if (para == "status") {
        fir = a["status"];
        sec = b["status"];
      }
      // console.log({ fir });
      // console.log({ sec });
      if (fir < sec) {
        return -1;
      }
      if (fir > sec) {
        return 1;
      } else {
        return 0;
      }
    }

    let arr = [...currData];
    // console.log(arr);
    arr.sort(compare);
    // console.log(arr);
    setCurrData(arr);
  };

  const sortArrDec = (para) => {
    setSelectedSortUp("");
    setSelectedSortDown(para);
    function compare(a, b) {
      let fir, sec;
      if (para == "cpName") {
        fir = a["channelPartner"]["profile"]["user_details"][
          "first_name"
        ].toLowerCase();
        sec = b["channelPartner"]["profile"]["user_details"][
          "first_name"
        ].toLowerCase();
      } else if (para == "leadName") {
        fir = a["lead"]["name"].toLowerCase();
        sec = b["lead"]["name"].toLowerCase();
      } else if (para == "status") {
        fir = a["status"];
        sec = b["status"];
      }
      // console.log({ fir });
      // console.log({ sec });
      if (fir > sec) {
        return -1;
      }
      if (fir < sec) {
        return 1;
      }
      return 0;
    }
    let arr = [...currData];
    arr.sort(compare);
    // console.log(arr);
    setCurrData(arr);
  };
  //lotte settings
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  //fitering data starts:====
  const fetchfilter = async () => {
    const Auth = new AuthService();
    setLoading(true);
    let queryParameter = cpOrg ? "org_id" : "";
    let query = cpOrg ?? "";
    try {
      const res = await (
        await Auth.makeAuthenticatedGetRequest(
          api(
            `/api/leads/Appointment/?${queryParameter}=${query}&limit=${7}&offset=${startAppointment}&filterkey=${filterselect}&filtervalue=${filtervalue}`
          )
        )
      ).json();
      setRowData(res.apppointment ?? []);
      setCurrData(res.apppointment ?? []);
      setLength(res.total ?? 0);
      setLoading(false);
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <>
      {origin != "Leads Directory" && (
        <Stack
          direction="row"
          justifyContent="right"
          alignItems="center"
          sx={{
            height: "68px",
            borderRadius: "12px",
            color: "#3E4954",
          }}
        >
          <Accordion
            expanded={expandedFilter}
            sx={{ width: "150px", boxShadow: "none" }}
          >
            <AccordionSummary
              onClick={() => {
                setExpandedFilter(!expandedFilter);
              }}
              expandIcon={<GridExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography sx={{ display: "flex", placeItems: "center" }}>
                <TuneOutlinedIcon sx={{ color: "#2F4CDD" }} />
                <Typography sx={{ marginLeft: "10px" }}>Fillters</Typography>
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Box sx={{ textAlign: "center" }}>
                <FormControl sx={{ width: "118px" }}>
                  <InputLabel id="demo-simple-select-label">Select</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={filterselect}
                    label="Select"
                    onChange={handleChangeFilter}
                  >
                    <MenuItem value={"lead__name"}>Lead Name</MenuItem>
                    <MenuItem value={"lead__phone"}>Lead Phone</MenuItem>
                    <MenuItem
                      value={
                        "channelPartner__profile__user_details__first_name"
                      }
                    >
                      CP Name
                    </MenuItem>
                    <MenuItem value={"channelPartner__profile__phone"}>
                      CP Phone
                    </MenuItem>
                    {/* <MenuItem value={"phone"}>Sent On</MenuItem>
                    <MenuItem value={"phone"}>Received On</MenuItem>   */}
                    <MenuItem value={"status"}>Status</MenuItem>
                  </Select>
                </FormControl>
                <TextField
                  sx={{ marginTop: "20px", fontSize: "8px" }}
                  placeholder="Value"
                  value={filtervalue}
                  onChange={(e) => {
                    setfiltervalue(e.target.value);
                  }}
                />
                <Button
                  // onClick={fetchfilter}
                  onClick={() => {
                    fetchfilter();
                    setExpandedFilter(false);
                    setmatchText("No results found with the applied Filters");
                  }}
                  variant="contained"
                  className="univercel_btn_class"
                  color="primary"
                  sx={{ margin: "20px 0px 10px 0px" }}
                >
                  Submit
                </Button>
              </Box>
            </AccordionDetails>
          </Accordion>
          {expandedFilter == false && filtervalue && (
            <RestartAltIcon
              sx={{ margin: "0px 10px", cursor: "pointer" }}
              onClick={() => {
                // setfilterselect("");
                setfiltervalue("");
                // setSelectedLeads([]);
                setStartAppointment(0);
                setmatchText("Let's get started, Please add your data");
                setCurrPage(1);
                setTrigger(!trigger);
              }}
            />
          )}
        </Stack>
      )}
      <Box sx={{ mt: 1 }}>
        <div style={{ height: 400, width: "100%" }}>
          {/* <DataGrid
      rows={rowData}
      columns={columns}
      pageSize={10}
      rowsPerPageOptions={[5]}
      onSelectionModelChange={(ids) => onRowsSelectionHandler(ids)}
    /> */}
          <Box
            // className="custom-scrollbar"
            sx={{
              color: "black",
              my: "10px",
              // width: "fit-content",
              minHeight: "400px",
              overflowX: "auto",
              boxSizing: "border-box",
              "&:hover": {
                boxShadow: "-6px 18px 15px rgba(0, 0, 0, 0.1)",
              },
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                background: "white",
                borderRadius: "5px",
                color: "#272D4E",
                fontWeight: "700",
                fontFamily: "Circular Std",
                fontSize: "18px",
                gap: "15px",
                width: "fit-content",
                padding: "10px 20px",
                borderBottom: "1px solid #E9ECFF",
                borderRadius: "20px 20px 0 0",
              }}
            >
              {cpOrg && (
                <Box
                  sx={{
                    // minWidth: "125px",
                    width: "200px",
                    display: "flex",
                    alignItems: "center",
                    gap: "10px",
                    justifyContent: "center",
                    // border:"1px solid red"
                  }}
                >
                  <Stack justifyContent="center" alignItems="center" sx={{}}>
                    <Box>Channel Partner</Box>
                    <Box sx={{ paddingRight: "80px" }}>Name</Box>
                  </Stack>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "2px",
                    }}
                  >
                    <Box
                      sx={{
                        cursor: "pointer",
                        height: "10px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        marginLeft: "5px",
                      }}
                      onClick={() => sortArr("cpName")}
                    >
                      <ArrowDropUpIconSvg focus={selectedSortUp == "cpName"} />
                    </Box>
                    <Box
                      sx={{
                        cursor: "pointer",
                        height: "10px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        marginLeft: "5px",
                      }}
                      onClick={() => sortArrDec("cpName")}
                    >
                      <ArrowDropDownIconSvg
                        focus={selectedSortDown == "cpName"}
                      />
                    </Box>
                  </Box>
                </Box>
              )}
              {cpOrg && (
                <Box
                  sx={{
                    // minWidth: "120px",
                    width: "180px",
                    display: "flex",
                    alignItems: "center",
                    gap: "10px",
                    justifyContent: "center",
                    // border:"1px solid red"
                  }}
                >
                  <Stack justifyContent="center" alignItems="center">
                    <Box>Channel Partner</Box>
                    <Box sx={{ paddingRight: "60px" }}>Number</Box>
                  </Stack>
                  {/* <Box
                  sx={{ display: "flex", flexDirection: "column", gap: "5px" }}
                >
                  <Box
                    sx={{
                      cursor: "pointer",
                      height: "10px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      marginLeft: "10px",
                    }}
                  // onClick={() => sortArr("requirement_form_name")}
                  >
                    <ArrowDropUpIconSvg />
                  </Box>
                  <Box
                    sx={{
                      cursor: "pointer",
                      height: "10px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      marginLeft: "10px",
                    }}
                  // onClick={() => sortArrDec("requirement_form_name")}
                  >
                    <ArrowDropDownIconSvg />
                  </Box>
                </Box> */}
                </Box>
              )}
              {origin != "Leads Directory" && (
                <Box
                  sx={{
                    // minWidth: "130px",
                    width: "180px",
                    display: "flex",
                    alignItems: "center",
                    gap: "10px",
                    justifyContent: "center",
                    // border:"1px solid red"
                  }}
                >
                  <Box>Lead Name</Box>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "2px",
                    }}
                  >
                    <Box
                      sx={{
                        cursor: "pointer",
                        height: "10px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        marginLeft: "5px",
                      }}
                      onClick={() => sortArr("leadName")}
                    >
                      <ArrowDropUpIconSvg
                        focus={selectedSortUp == "leadName"}
                      />
                    </Box>
                    <Box
                      sx={{
                        cursor: "pointer",
                        height: "10px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        marginLeft: "5px",
                      }}
                      onClick={() => sortArrDec("leadName")}
                    >
                      <ArrowDropDownIconSvg
                        focus={selectedSortDown == "leadName"}
                      />
                    </Box>
                  </Box>
                </Box>
              )}

              {origin != "Leads Directory" && (
                <Box
                  sx={{
                    // minWidth: "115px",
                    width: "180px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    // border:"1px solid red"
                  }}
                >
                  <Box>Lead Number</Box>
                  {/* <Box
                sx={{ display: "flex", flexDirection: "column", gap: "5px" }}
              >
                <Box
                  sx={{
                    cursor: "pointer",
                    height: "10px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginLeft: "10px",
                  }}
                // onClick={() => sortArr("Name")}
                >
                  <ArrowDropUpIconSvg />
                </Box>
                <Box
                  sx={{
                    cursor: "pointer",
                    height: "10px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginLeft: "10px",
                  }}
                // onClick={() => sortArrDec("Name")}
                >
                  <ArrowDropDownIconSvg />
                </Box>
              </Box> */}
                </Box>
              )}
              {origin != "Inventory" && (
                <Box
                  sx={{
                    // minWidth: "100px",
                    width: "180px",
                    display: "flex",
                    alignItems: "center",
                    gap: "10px",
                    justifyContent: "center",
                    // border:"1px solid red"
                  }}
                >
                  <Box>Unit Name</Box>
                  {/* <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "2px",
                    }}
                  >
                    <Box
                      sx={{
                        cursor: "pointer",
                        height: "10px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        marginLeft: "5px",
                      }}
                      onClick={() => sortArr("unitName")}
                    >
                      <ArrowDropUpIconSvg
                        focus={selectedSortUp == "unitName"}
                      />
                    </Box>
                    <Box
                      sx={{
                        cursor: "pointer",
                        height: "10px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        marginLeft: "5px",
                      }}
                      onClick={() => sortArrDec("unitName")}
                    >
                      <ArrowDropDownIconSvg
                        focus={selectedSortDown == "unitName"}
                      />
                    </Box>
                  </Box> */}
                </Box>
              )}
              {/* {origin != "Inventory" && (
                <Box
                  sx={{
                    minWidth: "120px",
                    width: "18%",
                    display: "flex",
                    gap: "10px",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Box>Unit Name</Box>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "2px",
                    }}
                  >
                    <Box
                      sx={{
                        cursor: "pointer",
                        height: "10px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        marginLeft: "5px",
                      }}
                      // onClick={() => sortArr("Name")}
                    >
                      <ArrowDropUpIconSvg />
                    </Box>
                    <Box
                      sx={{
                        cursor: "pointer",
                        height: "10px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        marginLeft: "5px",
                      }}
                      // onClick={() => sortArrDec("Name")}
                    >
                      <ArrowDropDownIconSvg />
                    </Box>
                  </Box>
                </Box>
              )} */}
              {false && (
                <Box
                  sx={{
                    width: "22%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    // border:"1px solid red"
                  }}
                >
                  <Box>Project Name</Box>
                  {/* <Box
                sx={{ display: "flex", flexDirection: "column", gap: "5px" }}
              >
                <Box
                  sx={{
                    cursor: "pointer",
                    height: "10px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginLeft: "10px",
                  }}
                // onClick={() => sortArr("Name")}
                >
                  <ArrowDropUpIconSvg />
                </Box>
                <Box
                  sx={{
                    cursor: "pointer",
                    height: "10px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginLeft: "10px",
                  }}
                // onClick={() => sortArrDec("Name")}
                >
                  <ArrowDropDownIconSvg />
                </Box>
              </Box> */}
                </Box>
              )}
              <Box
                sx={{
                  // minWidth: "160px",
                  width: "220px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  // border:"1px solid red"
                }}
              >
                <Box>Appointment Date</Box>
              </Box>
              <Box
                sx={{
                  // minWidth: "160px",
                  width: "140px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  // border:"1px solid red"
                }}
              >
                <Box>Scheduled On</Box>
              </Box>
              <Box
                sx={{
                  // width: formReceived ? "18%" : "24%",
                  // minWidth: "160px",
                  width: "180px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  // border:"1px solid red"
                }}
              >
                <Box>Response Time</Box>
              </Box>
              <Box
                sx={{
                  // minWidth: "120px",
                  width: "180px",
                  display: "flex",
                  gap: "10px",
                  alignItems: "center",
                  justifyContent: "center",
                  // border:"1px solid red"
                }}
              >
                <Box>Status</Box>
                <Box
                  sx={{ display: "flex", flexDirection: "column", gap: "2px" }}
                >
                  <Box
                    sx={{
                      cursor: "pointer",
                      height: "10px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      marginLeft: "5px",
                    }}
                    onClick={() => sortArr("status")}
                  >
                    <ArrowDropUpIconSvg focus={selectedSortUp == "status"} />
                  </Box>
                  <Box
                    sx={{
                      cursor: "pointer",
                      height: "10px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      marginLeft: "5px",
                    }}
                    onClick={() => sortArrDec("status")}
                  >
                    <ArrowDropDownIconSvg
                      focus={selectedSortDown == "status"}
                    />
                  </Box>
                </Box>
              </Box>
              <Box
                sx={{
                  // minWidth: "140px",
                  width: "180px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  // border:"1px solid red"
                }}
              >
                <Box>Notes</Box>
                {/* <Box
                sx={{ display: "flex", flexDirection: "column", gap: "5px" }}
              >
                <Box
                  sx={{
                    cursor: "pointer",
                    height: "10px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginLeft: "10px",
                  }}
                // onClick={() => sortArr("priority")}
                >
                  <ArrowDropUpIconSvg />
                </Box>
                <Box
                  sx={{
                    cursor: "pointer",
                    height: "10px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginLeft: "10px",
                  }}
                // onClick={() => sortArrDec("priority")}
                >
                  <ArrowDropDownIconSvg />
                </Box>
              </Box> */}
              </Box>
              {/* <Box sx={{ width: "10%", textAlign: "center" }}>Actions</Box> */}
            </Box>
            {loading ? (
              <Box sx={{ textAlign: "center", marginTop: "50px" }}>
                <CircularProgress color="inherit" sx={{ color: "grey.500" }} />
              </Box>
            ) : rowData.length == 0 ? (
              <>
                <Box sx={{ textAlign: "center", marginTop: "50px" }}>
                  <Typography
                    sx={{
                      fontSize: "25px",
                      fontWeight: "500",
                      color: "#B9B7D0",
                    }}
                  >
                    <Lottie options={defaultOptions} height="20%" width="20%" />
                    <Typography
                      sx={{
                        color: "#B9B7D0",
                        fontSize: "18px",
                        fontWeight: "400",
                        marginTop: "-40px",
                      }}
                    >
                      {matchText}
                    </Typography>
                  </Typography>
                </Box>
              </>
            ) : (
              <Box>
                {currData &&
                  currData.map((item, index) => {
                    // console.log("item", item);
                    let unitData;
                    if (item?.cpStandaloneUnits.length > 0) {
                      unitData = item?.cpStandaloneUnits[0];
                    } else {
                      unitData = item?.cpProjectsUnits[0];
                    }
                    // console.log({ unitData });

                    return (
                      <Box
                        sx={{
                          padding: "15px 38px 15px 20px",
                          display: "flex",
                          width: "fit-content",
                          alignItems: "center",
                          gap: "15px",
                          "&:hover": {
                            backgroundColor: "#F5F7FF",
                          },
                          background: "white",
                          borderRadius: "5px",
                        }}
                      >
                        {cpOrg && (
                          <Box
                            sx={{
                              // minWidth: "120px",
                              width: "200px",
                              display: "flex",
                              alignItems: "center",
                              gap: "10px",
                              justifyContent: "start",
                              fontFamily: "Product Sans",
                              fontWeight: "400",
                              flexDirection: "column",
                            }}
                          >
                            <Typography
                              sx={{
                                textAlign: "start",
                                width: "100%",
                                paddingLeft: "45px",
                              }}
                            >
                              {
                                item?.channelPartner?.profile?.user_details
                                  ?.first_name
                              }
                            </Typography>
                          </Box>
                        )}
                        {cpOrg && (
                          <Box
                            sx={{
                              // minWidth: "120px",
                              width: "180px",
                              display: "flex",
                              alignItems: "center",
                              gap: "10px",
                              justifyContent: "center",
                              fontFamily: "Product Sans",
                              fontWeight: "400",
                              flexDirection: "column",
                            }}
                          >
                            <Typography
                              style={{
                                fontSize: "14px",
                                textAlign: "start",
                                width: "100%",
                                paddingLeft: "50px",
                              }}
                            >
                              {item?.channelPartner?.profile?.phone.substring(
                                3
                              ) ?? ""}
                            </Typography>
                          </Box>
                        )}
                        {origin !== "Leads Directory" && (
                          <Box
                            sx={{
                              // minWidth: "130px",
                              width: "180px",
                              display: "flex",
                              alignItems: "center",
                              gap: "10px",
                              justifyContent: "center",
                              fontFamily: "Product Sans",
                              fontWeight: "400",
                            }}
                          >
                            <Typography
                              sx={{
                                textAlign: "start",
                                width: "100%",
                                paddingLeft: "35px",
                              }}
                            >
                              {item?.lead?.name}
                            </Typography>
                          </Box>
                        )}
                        {origin !== "Leads Directory" && (
                          <Box
                            sx={{
                              // minWidth: "115px",
                              width: "180px",
                              fontFamily: "Product Sans",
                              fontWeight: "400",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            <Typography
                              sx={{
                                textAlign: "start",
                                width: "100%",
                                paddingLeft: "35px",
                              }}
                            >
                              {item?.lead?.phone?item?.lead?.phone.substring(3):"__"}
                            </Typography>
                          </Box>
                        )}
                        {origin != "Inventory" && (
                          <Box
                            sx={{
                              // minWidth: "100px",
                              width: "180px",
                              fontFamily: "Product Sans",
                              fontWeight: "400",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            <Typography
                              sx={{
                                textAlign: "start",
                                width: "100%",
                                paddingLeft: "50px",
                              }}
                            >
                              {unitData?.name}
                            </Typography>
                          </Box>
                        )}
                        <Box
                          sx={{
                            // minWidth: "160px",
                            width: "180px",
                            fontFamily: "Product Sans",
                            fontWeight: "400",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <Typography
                            sx={{
                              textAlign: "start",
                              width: "100%",
                              paddingLeft: "70px",
                            }}
                          >
                            {
                              new Date(item.appointment_time)
                                .toLocaleString()
                                .split("GMT")[0]
                            }
                          </Typography>
                        </Box>
                        <Box
                          sx={{
                            // minWidth: "160px",
                            width: "180px",
                            fontFamily: "Product Sans",
                            fontWeight: "400",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <Typography
                            sx={{
                              textAlign: "start",
                              width: "100%",
                              paddingLeft: "70px",
                            }}
                          >
                            {
                              new Date(item.created_on)
                                .toLocaleString()
                                .split("GMT")[0]
                            }
                          </Typography>
                        </Box>
                        <Box
                          sx={{
                            // minWidth: "160px",
                            width: "160px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            fontFamily: "Product Sans",
                            fontWeight: "400",
                          }}
                        >
                          <Typography
                            sx={{
                              textAlign: "start",
                              width: "100%",
                              paddingLeft: "50px",
                            }}
                          >
                            {item.response_time
                              ? new Date(item.response_time)
                                  .toLocaleString()
                                  .split("GMT")[0]
                              : "__"}
                          </Typography>
                        </Box>
                        <Box
                          sx={{
                            // minWidth: "120px",
                            width: "180px",
                            fontFamily: "Product Sans",
                            fontWeight: "400",

                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <Typography
                            sx={{
                              textAlign: "start",
                              width: "100%",
                              paddingLeft: "70px",
                            }}
                          >
                            {item.status ?? ""}
                          </Typography>
                        </Box>
                        <Box
                          sx={{
                            // minWidth: "140px",
                            width: "180px",
                            fontFamily: "Product Sans",
                            fontWeight: "400",

                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <Typography
                            sx={{
                              textAlign: "start",
                              width: "100%",
                              paddingLeft: "70px",
                            }}
                          >
                            {item.description ?? ""}
                          </Typography>
                        </Box>
                      </Box>
                    );
                  })}
              </Box>
            )}
          </Box>
          <Box>
            {pages > 0 && (
              <Box sx={{ margin: "40px 0px 20px 0px" }}>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "right",
                    marginRight: "20px",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      placeItems: "center",
                      margin: " 0 10px",
                      borderRadius: "5px",
                    }}
                  >
                    <Typography sx={{}}>Page</Typography>
                    <Typography
                      sx={{
                        background: `white`,
                        color: "black",
                        width: "60px",
                        height: "30px",
                        borderRadius: "4px",
                        margin: "0 10px",
                        display: "grid",
                        placeItems: "center",
                        border: "1px solid #2979F2",
                      }}
                    >
                      {currPage}
                    </Typography>
                    <Typography
                      sx={{
                        color: "black",
                        width: "30px",
                        borderRadius: "5px",
                        margin: "0 0px",
                        display: "grid",
                        placeItems: "center",
                      }}
                    >
                      of
                    </Typography>
                    <Typography
                      sx={{
                        color: "black",
                        margin: "0px 10px 0px 0px",
                        display: "grid",
                        placeItems: "center",
                      }}
                    >
                      {pages ?? 0}
                    </Typography>
                  </Box>

                  <Button
                    variant="contained"
                    className="paginationButton"
                    sx={{ marginRight: "10px" }}
                    onClick={prevClick}
                    disabled={currPage == 1}
                  >
                    <KeyboardDoubleArrowLeftIcon fontSize="medium" />
                  </Button>

                  <Button
                    variant="contained"
                    className="paginationButton"
                    onClick={nextClick}
                    disabled={currPage == pages}
                  >
                    <KeyboardDoubleArrowRightIcon fontSize="medium" />
                  </Button>
                </Box>
              </Box>
            )}
          </Box>
        </div>
      </Box>
    </>
  );
}

export default LDcustomTable;
