

import { useContext } from "react";
import { Navigate, Outlet, Route, useNavigate } from "react-router-dom";


export const SuperAdminRoutes = ({children, ...rest})=>{
    //get data from local storage and check role type and set routs

    const user = JSON.parse(localStorage.getItem("crm_user"))
    console.log(user)

    const navigate = useNavigate();

    return (
        <>
       {user.role_type=="SUPERADMIN" ? <Outlet/> :<Navigate to="/"/>}
        </>
    )
}


