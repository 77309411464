import {
  Button,
  FormControl,
  MenuItem,
  Select,
  Stack,
  Typography,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useEffect, useState } from "react";
import { Apiaddress } from "../../../../utils/consts";
import axios from "axios";

export const MarkOccupancy = ({
  setState,
  selectedUnits,
  setSelectedUnits,
  setTrigger,
  trigger,
}) => {
  const [value, setValue] = useState("");

  useEffect(() => {
    getOccupancy();
  }, []);

  const [occupancy, setOccupancy] = useState([]);
  const getOccupancy = async () => {
    let url = Apiaddress + "/api/projects/unitstatus/";

    let res = await axios.get(url);
    console.log("occupancy: ", res.data);
    setOccupancy(res.data.unit_status);
  };

  const setOccupancyToUnit = async () => {
    if (value == "") return;
    let url = Apiaddress + "/api/projects/assignunitstatusCp/";
    // post  method:  Eg: {
    //   "unit_ids":[360,359],
    //   "unit_status_id":2
    //   }
    let data = {
      unit_ids: selectedUnits, //[360,359],
      unit_status_id: +value.split("-")[0],
    };
    console.log("data", data);
    // return;
    let res = await axios.post(url, data);
    if (res.data.error == false) {
      setState(false);
      setSelectedUnits([]);
      setTrigger(!trigger);
    }
    console.log("data re baba  :", res.data);
  };

  return (
    <>
      <Stack
        sx={{
          width: "370px",
          height: "80vh",
          marginTop: "20px",
          padding: "30px",
        }}
      >
        <Stack
          direction="row"
          onClick={() => {
            setState(false);
          }}
          sx={{
            display: "flex",
            alignItems: "center",
            gap: "10px",
            cursor: "pointer",
          }}
        >
          <ArrowBackIcon />
          <Typography
            sx={{
              color: "#2979F2",
              fontSize: "22px",
              fontWeight: 600,
              fontFamily: "Circular Std",
            }}
          >
            Units Occupancy Allocations
          </Typography>
        </Stack>

        <Stack
          direction="column"
          alignItems="left"
          justifyContent="center"
          sx={{ height: "80%" }}
        >
          <Typography
            sx={{ mb: "10px", fontWeight: "700", fontFamily: "Product Sans" }}
          >
            Select Occupancy
          </Typography>

          <FormControl sx={{ minWidth: 220 }}>
            <Select
              value={value}
              onChange={(e) => {
                setValue(e.target.value);
                //      getWingsOrFloor(e.target.value.split("-")[0]);
              }}
              displayEmpty
              inputProps={{ "aria-label": "Without label" }}
            >
              <MenuItem value="">
                <em>Select</em>
              </MenuItem>
              {occupancy.map((ele, ind) => {
                return (
                  <MenuItem
                    key={ele.id}
                    value={ele.id + "-" + ele.name}
                    sx={{ color: "black" }}
                  >
                    {ele.name}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>

          <Button
            onClick={() => {
              setOccupancyToUnit();
            }}
            sx={{
              mt: "25px",
              padding: "12px",
              color: "#06386B",
              textTransform: "none",
              fontSize: "20px",
              fontFamily: "Product Sans",
              background:
                "linear-gradient(97.85deg, #FFAF2A 0.95%, #FFC666 100%)",
              "&:hover": {
                boxShadow: "0px 9px 12px rgba(242, 178, 73, 0.59)",
              },
            }}
          >
            {" "}
            Save Changes
          </Button>
        </Stack>
      </Stack>
    </>
  );
};
