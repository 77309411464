import { Box, Button, Checkbox, Typography } from '@mui/material'
import { Stack } from '@mui/system'
import React, { useEffect, useState } from 'react'
import SideBar from '../../Components/sideBar/SideBar'
import CircularProgress from '@mui/material/CircularProgress';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import Lottie from "react-lottie";
import animationData from "../../../../Lotties/oGze35Xf1B.json";
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import { AuthService } from '../../../../context/buyer/services/auth';
import { api } from '../../../../utils/consts';
import axios from 'axios';
import { Apiaddress, net } from "../../../../utils/consts";
import NavigateBack from '../../../CommonPages/Shared/NavigateBack';

let stIndex=0;
let edIndex=0;

function DeassignProjectUnitClient() {

  const [data, setData] = useState(null);
  const [tempData, setTempData] = useState(null);
  const [token, setToken] = useState(null);
  const [newRowData, setNewRowData] = useState([]);
  const [loading, setloading] = useState(false);
  const [selectedProject,setSelectedProject] = useState([]);
  const [self, setSelf] = useState(null);
  const [trigger,setTrigger] = useState(false);
  const [projectName,setProjectName] = useState();

  const navigate=useNavigate();

  const {state}= useLocation()
  let lead_id=state?.lead_id;

  console.log(data);

  let projectid = useParams().id;

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  //table Content
  const [curpage, setcurpage] = useState(1);
  const [pageRange, setPageRange] = useState([0, 6]);
  const [pages, setPages] = useState([]);

  const nextClick = () => {  
    if (
      !(newRowData.length >= pageRange[0] && newRowData.length <= pageRange[1])
    ) {
      setcurpage(curpage + 1);
      stIndex=pageRange[0]+7;
      edIndex=pageRange[1]+7;
       if(newRowData?.length<edIndex){
        edIndex=newRowData?.length-1;
      }
      setPageRange([pageRange[0] + 7, pageRange[1] + 7]);
      setSelectedProject([]);
    }
  };

  const prvClick = () => {
    if (pageRange[0] != 0 && pageRange[1] != 6) {
      setcurpage(curpage - 1);
      stIndex=pageRange[0]-7;
      edIndex=pageRange[1]-7;
      setPageRange([pageRange[0] - 7, pageRange[1] - 7]);
      setSelectedProject([]);
    }
  };

  useEffect(() => {
    if (newRowData) {
      let arr = [];
      for (
        let i = 1;
        i <=
        (newRowData.length % 6 == 0
          ? newRowData.length / 6
          : newRowData.length / 6 + 1);
        i++
      ) {
        arr.push(i);
      }
      setcurpage(1);
      stIndex=0;
      console.log(newRowData?.length);
      if(newRowData?.length>0 && newRowData?.length<edIndex){
        edIndex=newRowData?.length-1;
      }
      else{
        edIndex=6;
      }
      setPages(arr);
      setPageRange([0,6]);  
    }
  }, [newRowData]);

  const fetchLeadDetails = async () => {
    try {
        const res = await axios.get(
            Apiaddress + `/api/leads/CpClientById/?id=${lead_id}`
          );
          setSelf(res?.data?.Client?.projects_cp_units);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    fetchLeadDetails();
  }, [trigger]);

  const filterData = (data) => {
    console.log(self);
    console.log(data);  
    let temp = [];
    if (self?.length == 0) {
      setData([]);
    } else {
      for (let i = 0; i < self?.length; i++) {
        let temp1 = data.filter((ele) => {
          return ele == self[i];
        });
        if(temp1?.length!==0){
          temp.push(temp1[0]);
        }
      }
      setData(temp);
    }
    setloading(false);
  };

  const fitData=async ()=>{
    let arr = [];
    for (let i = 0; i < data.length; i++) {
      const res = await axios.get(Apiaddress + "/api/projects/cpunit/?unit_id="+data[i]);
      let temp=res?.data?.unit[0];
      console.log("===========================.>", res?.data?.unit[0]);
      let unit_data = JSON.parse(temp?.unit_data);
        let status = "";
        if (temp?.unit_status == 1) {
          status = "Unavailable";
        } else if (temp?.unit_status == 2) {
          status = "Reserved";
        } else if (temp?.unit_status == 3) {
          status = "Registered";
        } else if (temp?.unit_status == 4) {
          status = "Booked";
        } else if (temp?.unit_status == 5) {
          status = "Available";
        }

        // if(status=="Booked"){
        //     continue;
        //   }
  
        let obj = {
          id: i + 1,
          project_id: temp?.id,
          Name: temp?.name,
          State: unit_data.Type,
          City: unit_data.City,
          Pincode: unit_data.For,
          Unit: unit_data.Area,
        };
        arr.push(obj);
      }
      console.log(arr);
      setNewRowData(arr);
  }

  useEffect(() => {
    if (data) {
      console.log(data);
      fitData();
    }
  }, [data]);

  const fetchProjectUnit= async()=>{
    setloading(true);
    const x = async () => {
      const Auth = new AuthService();
      const res = await (
        await Auth.makeUnAuthenticatedGetRequest(
          api("/api/projects/project/?project_id=" + projectid)
        )
      ).json();

      let tempdata=res?.projects[0]?.cp_units;
      setTempData(tempdata);
      setProjectName(res?.projects[0]?.name);
      console.log(res);
      setloading(false);
    };
    x();
  }

  useEffect(()=>{
    if(self){  
     fetchProjectUnit();
    }
   },[self])

  useEffect(()=>{
   if(tempData && self){  
    filterData(tempData);
   }
  },[tempData])

  let temp = [];
  const onCheck = (id) => {
    if(id==="selectAll"){
      if(newRowData?.length>0 && newRowData?.length<edIndex){
        edIndex=newRowData?.length-1;
      }
      for(let i=stIndex; i<=edIndex; i++){
        temp.push(newRowData[i]?.project_id);
      }
    setSelectedProject(temp);
    }
    else if(id=="removeAll"){
     temp=[];
      setSelectedProject(temp);  
    }
    else{
      let array = selectedProject;
      const index = array.indexOf(id);
      if (index > -1) { 
        let temp1= array.filter((ele)=>{
          return ele!=selectedProject[index];
        })
        temp=temp1;
      }
      else{
        temp = [...selectedProject,id];
      }
    }
    console.log("temp", temp);
    setSelectedProject(temp);
  };

  const deAllocateProject = async () => {
    try {
        let temp = self;
          for (let i = 0; i < selectedProject?.length; i++) {
            let temp1 = temp.filter((ele) => {
              return ele != selectedProject[i];
            });
            temp = temp1;
          }

          let obj = {
            Client_id: lead_id,
            projects_cp_units: temp,
          };
          console.log(obj);
          const res = await axios.patch(
            Apiaddress + "/api/leads/CpClient/",
            obj
          );
          console.log(res.data);
          setSelectedProject([]);
          setTrigger(!trigger);
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <>
      <Stack direction="row" sx={{ width: "100%", height: "auto"}}>
        <SideBar active={"Clients"}>
          <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              sx={{ marginBottom: "10px" }}
            >
             <Box>
              <Stack direction="row">
                  <Box sx={{ pt: "5px" }}>
                    <NavigateBack fontSize="22px" />
                  </Box>
                  <Typography
                    sx={{
                      fontSize: "22px",
                      fontWeight: "600",
                      color: "black",
                    }}
                    >
                    Clients Directory
                  </Typography>
                </Stack>
                <Typography
                  sx={{
                    color: "#B9B7D0",
                    fontSize: "16px",
                    fontWeight: "400",
                  }}
                >
                  Manage your Clients
                </Typography>
              </Box>
          </Stack>

          <Stack
                direction="row"
                justifyContent=""
                alignItems="center"
                sx={{ marginTop: "20px" }}
                >
                  <Stack direction="row" sx={{borderBottom: "3px solid #2979F2"}}>
                    <Typography sx={{
                    fontSize: "20px",
                    fontWeight: "400",
                    color: "#2979F2",
                    fontFamily: "Circular Std",
                    // borderBottom: "3px solid #2979F2",
                    paddingBottom:"10px",
                    marginTop:"-2px",
                    marginRight:"8px"
                  }}>
                    Unit List 
                    </Typography>
                    <Typography sx={{
                    fontSize: "20px",
                    fontWeight: "550",
                    color: "#2979F2",
                    fontFamily: "Circular Std",
                    // borderBottom: "3px solid #2979F2",
                    paddingBottom:"10px",
                    marginTop:"-2px",
                    marginRight:"8px"
                  }}>
                      {`of ${projectName?projectName?.charAt(0)?.toUpperCase()+projectName?.slice(1):""}`}
                    </Typography>
                  </Stack>
                  {/* {data == null
                    ? "Individual Unit List"
                    : `Unit List of ${data?.projects[0]?.name}`} */}
            </Stack>
            <Box
              sx={{
                border: "1.55px solid #e4e4e4",  
              }}
              >
            </Box>

            <Stack direction="row" justifyContent="space-between" alignItems="center" sx={{marginTop:"10px"}}>
      <Stack>
      <Box>
            {selectedProject.length != 0 && (
              <Stack direction="row" sx={{}}>
                {
                  <Button
                    onClick={() => {
                        deAllocateProject();
                    }}
                    className="univercel_btn_class"
                    sx={{
                      color: "#FFFFFF",
                      textTransform: "none",
                      width: "150px",
                      marginLeft: "0px",
                      background: "#008dff",
                      boxShadow:
                        "0px 13.7981px 22.5788px rgba(50, 174, 137, 0.25)",
                      "&:hover": { background: "#008dff" },
                    }}
                  >
                    Deallocate
                  </Button>
                }
              </Stack>
            )}
          </Box>
      </Stack>
      {/* <Stack>Hello</Stack> */}
    </Stack>
            
            <Box sx={{ color: "#A2A3A6",height:"400px",paddingTop:"10px"}}>
        <Box>
            <Box sx={{ color: "black", width: "100%" }}>
                <Box sx={{display: "flex",justifyContent: "start",alignItems: "center",background: "white",borderRadius: "5px",margin: " 10px 0",color: "#272D4E",  fontWeight: "700",fontFamily:"Circular Std",fontSize:"18px"}}>
                <Box sx={{width: "10vw",display: "grid",placeItems: "center",height: "50px"}}>
                    <Checkbox 
                    checked={(edIndex-stIndex)+1===selectedProject.length? true: false}
                  onClick={() => {
                    if((edIndex-stIndex)+1===selectedProject.length){
                      onCheck("removeAll");
                    }else{
                      onCheck("selectAll");
                    }
                  }} 
                    />
                </Box>
                <Box
                    sx={{
                        width: "20vw",
                        display: "grid",
                        placeItems: "center",
                        height: "50px",
                        justifyContent: "start",
                        paddingLeft:"20px",
                    }}>
                    <Box sx={{ display: "flex" }}>
                        <Box sx={{ display: "grid", placeItems: "center" }}>Name</Box>
                    </Box>
                </Box>

          <Box
            sx={{
              width: "20vw",
                display: "grid",
                placeItems: "center",
                height: "50px",
                justifyContent: "start",
                paddingLeft:"20px",
            }}
          >
            <Box sx={{ display: "flex" }}>
              <Box sx={{ display: "grid", placeItems: "center" }}>Type</Box>
            </Box>
          </Box>

          <Box
            sx={{
              width: "20vw",
                display: "grid",
                placeItems: "center",
                height: "50px",
                justifyContent: "start",
                paddingLeft:"20px",
            }}
          >
            <Box sx={{ display: "flex" }}>
              <Box sx={{ display: "grid", placeItems: "center" }}>City</Box>
            </Box>
          </Box>

          <Box
            sx={{
              width: "15vw",
                display: "grid",
                placeItems: "center",
                height: "50px",
                justifyContent: "start",
                paddingLeft:"20px",
            }}
          >
            <Box sx={{ display: "flex" }}>
              <Box sx={{ display: "grid", placeItems: "center" }}>
                For
              </Box>
            </Box>
          </Box>

          <Box
            sx={{
             width: "15vw",
                display: "grid",
                placeItems: "center",
                height: "50px",
                justifyContent: "start",
                paddingLeft:"20px",
            }}
          >
            <Box sx={{ display: "flex" }}>
              <Box sx={{ display: "grid", placeItems: "center" }}>Area</Box>
            </Box>
          </Box>

          {/* <Box
            sx={{
              width: "15vw",
                display: "grid",
                placeItems: "center",
                height: "50px",
                justifyContent: "start",
                paddingLeft:"20px",
            }}
          >
            Actions
          </Box> */}
        </Box>
         {loading?
           <>
           <Box sx={{textAlign:"center",marginTop:"50px"}}><CircularProgress color="inherit" sx={{ color: 'grey.500' }}/></Box>
           </>
        :newRowData?.length == 0 ?
          <Box sx={{ textAlign: "center", marginTop: "110px" }}><Typography sx={{ fontSize: "25px", fontWeight: "500", color: "#B9B7D0", }}>
            <Lottie options={defaultOptions} height="20%" width="20%" />
                <Typography
                  sx={{
                    color: "#B9B7D0",
                    fontSize: "18px",
                    fontWeight: "400",
                    marginTop: "-40px",
                  }}
                >
                  Please add your data
                </Typography>
            {/* <Button
                  variant="contained"
                  color="primary"
                  sx={{
                    height: "40px",
                    background: "#008dff",
                    boxShadow:
                      "0px 13.7981px 22.5788px rgba(50, 174, 137, 0.25)",
                    textTransform: "none",
                    borderRadius: "4px",
                    width: "140px",
                    fontSize: "15px",
                    margin: "5px 0px 5px 0px",
                    padding: "5px",
                    "&:hover": { background: "#008dff" },  
                  }}
                  onClick={(e) => {
                    navigate(`/channelpartner/inventory`);
                  }}
                  >
                  Add New Unit
                </Button> */}
            </Typography></Box> :
          <Box>
            <Box sx={{height:"400px"}}>
            {newRowData &&
              newRowData.map((item, index) => {

                if (index >= pageRange[0] && index <= pageRange[1]) {
                  return (
                    <Box
                      sx={{
                         display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      background: "white",
                      borderRadius: "5px",
                      margin: " 10px 0",
                      color:"#343434",
                      "&:hover": {
                        backgroundColor: "#F5F7FF",
                        cursor:"pointer"
                      }
                      // fontFamily:"Product Sans",
                      // fontWeight: "400",
                      // cursor:"pointer",
                      }}>
                      <Box
                      sx={{
                        width: "10vw",
                        display: "grid",
                        placeItems: "center",
                        height: "50px",
                      }}
                    >
                         {/* {`${JSON.stringify(selectedProject.indexOf(item.project_id))}`} */}
                      <Checkbox 
                      checked={selectedProject.indexOf(item.project_id)>-1? true: false}
                                onClick={() =>{
                                  onCheck(item.project_id)
                                }}
                      />
                    </Box>
                      <Box
                        sx={{
                          width: "20vw",
                        display: "grid",
                        placeItems: "center",
                        height: "50px",
                        justifyContent: "start",
                        fontFamily:"Product Sans",
                        fontWeight:"400",
                        paddingLeft:"20px"
                        }}
                      >
                        {item.Name}
                      </Box>
                      <Box
                        sx={{
                          width: "20vw",
                        display: "grid",
                        placeItems: "center",
                        justifyContent: "start",
                        height: "50px",
                        fontFamily:"Product Sans",
                        fontWeight:"400",
                        paddingLeft:"20px"
                        }}
                      >
                        {item.State}
                      </Box>
                      <Box
                        sx={{
                         width: "20vw",
                        display: "grid",
                        placeItems: "center",
                        justifyContent: "start",
                        height: "50px",
                        fontFamily:"Product Sans",
                        fontWeight:"400",
                        paddingLeft:"20px"
                        }}
                      >
                        {item.City}
                      </Box>
                      <Box
                        sx={{
                         width: "15vw",
                        display: "grid",
                        placeItems: "center",
                        justifyContent: "start",
                        height: "50px",
                        fontFamily:"Product Sans",
                        fontWeight:"400",
                        paddingLeft:"20px"
                        }}
                      >
                        {item.Pincode}
                      </Box>
                      <Box
                        sx={{
                          width: "15vw",
                        display: "grid",
                        placeItems: "center",
                        justifyContent: "start",
                        height: "50px",
                        fontFamily:"Product Sans",
                        fontWeight:"400",
                        paddingLeft:"20px"
                        }}
                      >
                        {item.Unit}
                        {/* taking unit from the object according to coresponding project ids  */}
                      </Box>
                    </Box>
                  );
                }
              })}
            </Box>

            <Box sx={{margin:"50px 0px 20px 0px"}}>
          <Box sx={{ display: "flex", justifyContent: "right",marginRight:"20px"}}>

            <Box sx={{display: "flex",placeItems: "center",margin: " 0 10px",borderRadius: "5px"}}>
              <Typography sx={{}}>Page</Typography>
              <Typography sx={{background: `white`,color: "black",width: "60px",height:"30px",borderRadius: "4px",margin: "0 10px",display: "grid",placeItems: "center",border:"1px solid #2979F2"}}>{curpage}</Typography>
              <Typography sx={{color: "black",width: "30px",borderRadius: "5px",margin: "0 0px",display: "grid",placeItems: "center"}}>of</Typography>
              <Typography sx={{color: "black",margin: "0px 10px 0px 0px",display: "grid",placeItems: "center"}}>{pages?.length}</Typography>
            </Box>

            {newRowData.length!=0 && 
            <Button variant="contained" sx={{background:"#0065FF",marginRight:"10px"}}
            className="paginationButton"
            disabled={curpage == 1}
              onClick={prvClick}>
                <KeyboardDoubleArrowLeftIcon fontSize="medium" />
            </Button>}

            {newRowData.length!=0 && 
                <Button variant="contained" sx={{background:"#0065FF"}}
                className="paginationButton"
                disabled={curpage == pages?.length}
                    onClick={nextClick}>
                    <KeyboardDoubleArrowRightIcon fontSize="medium" />
                </Button>
            }
          </Box>
        </Box>
        
          </Box>
        }
      </Box>

      <Box>


      </Box>

    </Box>

    </Box>


        </SideBar>
      </Stack>
    </>
  )
}

export default DeassignProjectUnitClient