import { Box, Checkbox, Stack, Button, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import animationData from "../../../../Lotties/NEW sin movs.json";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import axios from "axios";
import { Apiaddress } from "../../../../utils/consts";
import { useNavigate } from "react-router-dom";
import { UserRole } from "../../../../UserRoles/UserRoles";
import KeyboardDoubleArrowLeftIcon from "@mui/icons-material/KeyboardDoubleArrowLeft";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import Lottie from "react-lottie";

import {
  ArrowDropDownIconSvg,
  ArrowDropUpIconSvg,
} from "../../../BuilderComponents/BuilderDasboard/assets/icons";

function PDcustomTable({
  newRowData,
  delIconClick,
  updateBuyers,
  setTrigger,
  trigger,
  builderInvited,
  setProState,
  totalProject,
  setStartProject,
  startProject,
  CircularProgress,
  loading,
  fetchfilter,
  matchText,
  curpage,
  setcurpage,
  setState,
  setDrawerState
}) {
  ////get user roles from local storage and assign
  let roles = JSON.parse(localStorage.getItem("user_roles"));

  let project = roles["project"];

  //   console.log(newRowData);
  const [pageRange, setPageRange] = useState([0, 6]);
  const [pages, setPages] = useState([]);
  const [NoPages, setNopages] = useState(null);
  
  
  const nextClick = () => {
    if (curpage !== NoPages) {
      setStartProject(startProject + 7);
      setcurpage(curpage + 1);
    }
  };

  const prvClick = () => {
    if (curpage !== 1) {
      setStartProject(startProject - 7);
      setcurpage(curpage - 1);
    }
  };

  useEffect(() => {
    if (newRowData) {
      if (totalProject % 7 == 0) {
        let temp = totalProject / 7;
        setNopages(temp);
      } else {
        let temp = Math.floor(totalProject / 7 + 1);
        setNopages(temp);
      }
    }
  }, [newRowData]);

  const [curData, setcurData] = useState([]);

  useEffect(() => {
    if (newRowData) {
      setcurData(newRowData);
    }
  }, [newRowData]);

  const [selectedSortUp, setSelectedSortUp] = useState("");
  const [selectedSortDown, setSelectedSortDown] = useState("");

  const sortArr = (para) => {
    setSelectedSortUp(para);
    setSelectedSortDown("");
    function compare(a, b) {
      let fir = a[para];
      let sec = b[para];
      let fir1, sec1;
      if (para !== "Unit") {
        fir1 = fir.toLowerCase();
        sec1 = sec.toLowerCase();
      } else {
        fir1 = fir;
        sec1 = sec;
      }
      if (fir1 < sec1) {
        return -1;
      }
      if (fir1 > sec1) {
        return 1;
      }
      return 0;
    }

    let arr = [...curData];
    console.log(arr);
    arr.sort(compare);
    console.log(arr);

    setcurData(arr);
  };

  const sortArrDec = (para) => {
    setSelectedSortUp("");
    setSelectedSortDown(para);
    function compare(a, b) {
      let fir = a[para];
      let sec = b[para];
      let fir1, sec1;
      if (para !== "Unit") {
        fir1 = fir.toLowerCase();
        sec1 = sec.toLowerCase();
      } else {
        fir1 = fir;
        sec1 = sec;
      }

      if (fir1 < sec1) {
        return -1;
      }
      if (fir1 > sec1) {
        return 1;
      }
      return 0;
    }
    let arr = [...curData];
    arr.sort(compare);
    arr.reverse();
    console.log(arr);
    setcurData(arr);
  };  

  console.log("current data==========>:", newRowData);

  //////set builder selected project to local storage for show to the project dashboard
  const navigate = useNavigate();
  let builderSelected = JSON.parse(localStorage.getItem("BuilderSelected"));
  console.log(builderSelected);  
  const setBuilderSelectedProject = (id) => {
    let data = {
      selectedProjectID: id,
      selectedProjectType: builderInvited,
    };
    if (builderSelected) {
      localStorage.setItem(
        "BuilderSelected",
        JSON.stringify({ ...builderSelected, ...data })
      );
    } else {
      localStorage.setItem("BuilderSelected", JSON.stringify(data));
    }
  };

  console.log(curData);

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <Box>
      <Box
        sx={{
          color: "black",
          width: "100%",
          height: "400px",
          boxSizing: "border-box",
          "&:hover": {
            boxShadow: "-6px 18px 15px rgba(0, 0, 0, 0.1)",
          },
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            background: "white",
            borderRadius: "5px",
            color: "#272D4E",
            fontWeight: "700",
            fontFamily: "Circular Std",
            fontSize: "18px",
            gap: "15px",
            padding: "10px 20px",
            borderBottom: "1px solid #E9ECFF",
            borderRadius: "20px 20px 0 0",
          }}
        >
          <Box
            sx={{
              width: "25%",
              display: "flex",
              alignItems: "center",
              marginLeft:"40px"
            }}
          >
            <Box>Name</Box>
            <Box sx={{ display: "flex", flexDirection: "column", gap: "2px" }}>
              <Box
                sx={{
                  cursor: "pointer",
                  height: "10px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginLeft: "10px",
                }}
                onClick={() => sortArr("Name")}
              >
                <ArrowDropUpIconSvg focus={selectedSortUp == "Name"} />
              </Box>
              <Box
                sx={{
                  cursor: "pointer",
                  height: "10px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginLeft: "10px",
                }}
                onClick={() => sortArrDec("Name")}
              >
                <ArrowDropDownIconSvg focus={selectedSortDown == "Name"} />
              </Box>
            </Box>
          </Box>
          <Box
            sx={{
              width: "25%",
              display: "flex",
              alignItems: "center",
            }}
          >
            <Box>State</Box>
            <Box sx={{ display: "flex", flexDirection: "column", gap: "2px" }}>
              <Box
                sx={{
                  cursor: "pointer",
                  height: "10px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginLeft: "10px",
                }}
                onClick={() => sortArr("State")}
              >
                <ArrowDropUpIconSvg focus={selectedSortUp == "State"} />
              </Box>
              <Box
                sx={{
                  cursor: "pointer",
                  height: "10px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginLeft: "10px",
                }}
                onClick={() => sortArrDec("State")}
              >
                <ArrowDropDownIconSvg focus={selectedSortDown == "State"} />
              </Box>
            </Box>
          </Box>
          <Box
            sx={{
              width: "25%",
              display: "flex",
              alignItems: "center",
            }}
          >
            <Box>City</Box>
            <Box sx={{ display: "flex", flexDirection: "column", gap: "2px" }}>
              <Box
                sx={{
                  cursor: "pointer",
                  height: "10px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginLeft: "10px",
                }}
                onClick={() => sortArr("City")}
              >
                <ArrowDropUpIconSvg focus={selectedSortUp == "City"} />
              </Box>
              <Box
                sx={{
                  cursor: "pointer",
                  height: "10px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginLeft: "10px",
                }}
                onClick={() => sortArrDec("City")}
              >
                <ArrowDropDownIconSvg focus={selectedSortDown == "City"} />
              </Box>
            </Box>
          </Box>
          <Box
            sx={{
              width: "19%",
              display: "flex",
              alignItems: "center",
            }}
          >
            <Box>Pincode</Box>
          </Box>
          <Box
            sx={{
              width: "22%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Box>Unit</Box>
            <Box sx={{ display: "flex", flexDirection: "column", gap: "2px" }}>
              <Box
                sx={{
                  cursor: "pointer",
                  height: "10px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginLeft: "10px",
                }}
                onClick={() => sortArr("Unit")}
              >
                <ArrowDropUpIconSvg focus={selectedSortUp == "Unit"} />
              </Box>
              <Box
                sx={{
                  cursor: "pointer",
                  height: "10px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginLeft: "10px",
                }}
                onClick={() => sortArrDec("Unit")}
              >
                <ArrowDropDownIconSvg focus={selectedSortDown == "Unit"} />
              </Box>
            </Box>
          </Box>
          <Box sx={{ width: "22%", textAlign: "center" }}>Actions</Box>
        </Box>
        {loading ? (
                <>
                  <Box sx={{ textAlign: "center", marginTop: "50px" }}>
                    <CircularProgress
                      color="inherit"
                      sx={{ color: "grey.500" }}
                    />
                  </Box>
                </>
        ):curData?.length == 0 ? (
          <>
            <Box sx={{ textAlign: "center", marginTop: "50px" }}>
              <Typography
                sx={{ fontSize: "25px", fontWeight: "500", color: "#B9B7D0" }}
              >
                <Lottie options={defaultOptions} height="20%" width="20%" />
                <Typography
                  sx={{
                    color: "#B9B7D0",
                    fontSize: "18px",
                    fontWeight: "400",
                    marginTop: "-40px",
                  }}
                >
                  {matchText}
                </Typography>
              </Typography>
            </Box>
          </>
        ) : (
          <>
            <Box sx={{ height: "400px" }}>
              {curData &&
                curData.map((item, index) => {
                  if (index >= pageRange[0] && index <= pageRange[1]) {
                    return (
                      <Box
                        sx={{
                          backgroundColor: "#FFF",
                          padding: "15px 20px",
                          display: "flex",
                          alignItems: "center",
                          gap: "15px",
                          "&:hover": {
                            backgroundColor: "#F5F7FF",
                          },
                          cursor: "pointer",
                        }}
                        onClick={(e) => {
                          e.stopPropagation();
                          setBuilderSelectedProject(item.project_id);
                          const obj = {
                            projectId: item?.project_id,
                            state: item?.State,
                            city: item?.City,
                          };
                          localStorage.setItem(
                            "BuilderSelectedProject",item?.project_id
                          );
                          navigate("/channelpartner/project/unit/");
                        }}
                      >
                        <Box
                          sx={{
                            width: "26%",
                            fontFamily: "Product Sans",
                            fontWeight: "400",
                            marginLeft:"40px"
                          }}
                        >
                          {item.Name}
                        </Box>

                        <Box
                          sx={{
                            width: "23%",
                            fontFamily: "Product Sans",
                            fontWeight: "400",
                            paddingLeft:"3px"
                          }}
                        >
                          {item.State}
                        </Box>

                        <Box
                          sx={{
                            width: "22%",
                            fontFamily: "Product Sans",
                            fontWeight: "400",
                            paddingLeft:"27px"
                          }}
                        >
                          {item.City}
                        </Box>

                        <Box
                          sx={{
                            width: "18%",
                            fontFamily: "Product Sans",
                            fontWeight: "400",
                            paddingLeft:"32px"
                          }}
                        >
                          {item.Pincode}
                        </Box>

                        <Box
                          sx={{
                            width: "22%",
                            fontFamily: "Product Sans",
                            fontWeight: "400",
                            textAlign: "center",
                            paddingRight:"27px",
                          }}
                        >
                          {item.Unit}
                        </Box>

                        <Box
                          sx={{
                            width: "23%",
                            display: "flex",
                            justifyContent: "center",
                          }}
                        >
                          <Stack direction="row">
                          <EditIcon 
                              fontSize="medium"
                              sx={{ cursor: "pointer",marginRight:"10px"}}
                              onClick={(e) => {
                                e.stopPropagation();
                                localStorage.setItem(
                                  "BuilderSelectedProject",item?.project_id
                                );
                                setDrawerState(true);
                              }}
                            />
                            <RemoveRedEyeIcon
                              fontSize="medium"
                              sx={{ cursor: "pointer" }}
                              onClick={(e) => {
                                e.stopPropagation();
                                const obj = {  
                                  projectId: item?.project_id,
                                  state: item?.State,
                                  city: item?.City,
                                };
                                setBuilderSelectedProject(item.project_id);
                                localStorage.setItem(
                                  "BuilderSelectedProject",item?.project_id
                                  // JSON.stringify(obj)
                                );
                                navigate("/channelpartner/project/unit/");
                              }}
                            />
                          </Stack>
                        </Box>
                      </Box>
                    );
                  }
                })}
            </Box>
            <Box>
              <Box sx={{ mt: 3.5, mr: 2, mb: 3.5 }}>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "right",
                    marginRight: "20px",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      placeItems: "center",
                      margin: " 0 10px",
                      borderRadius: "5px",
                    }}
                  >
                    <Typography sx={{}}>Page</Typography>
                    <Typography
                      sx={{
                        background: `white`,
                        color: "black",
                        width: "60px",
                        height: "30px",
                        borderRadius: "4px",
                        margin: "0 10px",
                        display: "grid",
                        placeItems: "center",
                        border: "1px solid #2979F2",
                      }}
                    >
                      {curpage}
                    </Typography>
                    <Typography
                      sx={{
                        color: "black",
                        width: "30px",
                        borderRadius: "5px",
                        margin: "0 0px",
                        display: "grid",
                        placeItems: "center",
                      }}
                    >
                      of
                    </Typography>
                    <Typography
                      sx={{
                        color: "black",
                        margin: "0px 10px 0px 0px",
                        display: "grid",
                        placeItems: "center",
                      }}
                    >
                      {NoPages}
                    </Typography>
                  </Box>

                  {newRowData?.length != 0 && (
                    <Button
                      variant="contained"
                      className="paginationButton"
                      sx={{ marginRight: "10px" }}
                      disabled={curpage == 1}
                      onClick={prvClick}
                    >
                      <KeyboardDoubleArrowLeftIcon fontSize="medium" />
                    </Button>
                  )}

                  {newRowData?.length != 0 && (
                    <Button
                      variant="contained"
                      className="paginationButton"
                      onClick={nextClick}
                      disabled={curpage == NoPages}
                    >
                      <KeyboardDoubleArrowRightIcon fontSize="medium" />
                    </Button>
                  )}
                </Box>
              </Box>
            </Box>
          </>
        )}
      </Box>
    </Box>
  );
}

export default PDcustomTable;
