import { Avatar, Button, Stack, Typography } from "@mui/material";
import { Box } from "@mui/system";
import NotificationsIcon from "@mui/icons-material/Notifications";
import AddIcon from "@mui/icons-material/Add";
import { Link, useNavigate } from "react-router-dom";
import { useContext, useState } from "react";
import userContext from "../../../context/profile/context";
export const SuperAdminNavbar = ({
  showAddProjectbtn, //btn
  showAddTeambtn, //btn,
  showAddMemberbtn, //btn,
  dontDisplayPath, //
  handleOpen, //for modal
  dashboard,
  team,
  builder,
  organization,
  project,
  unit,
  createProject,
  media,
  projectAmenities,
  setState
}) => {
  const navigate = useNavigate();
  const [Open, setOpen] = useState(false);
  const UC = useContext(userContext);

  return (
    <>
      <Stack
        direction="column"
        justifyContent="space-between"
        sx={{ width: "100%", height: "100px" }}
      >
        <Stack
          direction="row"
          justifyContent="space-between"
          sx={{ width: "100%" }}
          alignItems="center"
        >
          <Stack direction="row" justifyContent="right" alignItems="center">
            <Box>
              <Typography sx={{ fontWeight: "700", fontSize: "24px" }}>
                Hello Super Admin
              </Typography>
            </Box>
          </Stack>

          <Stack direction="row">
            <Stack
              direction="row"
              justifyContent="right"
              alignItems="center"
              spacing={2}
            >
              {showAddProjectbtn && (
                <Button
                  className="univercel_btn_class"
                  sx={{ color: "#FFFFFF", textTransform: "none" }}
                  startIcon={<AddIcon />}
                  onClick={() => {
                    if (handleOpen) {
                      handleOpen(true);
                      return;
                    }else{
                      navigate("/super-admin-create-project-profile");
                    }
                    // navigate("/createproject")
                  }}
                >
                  Add Projects
                </Button>
              )}

              {showAddTeambtn && (
                <Button
                  className="univercel_btn_class"
                  sx={{ color: "#FFFFFF", textTransform: "none" }}
                  startIcon={<AddIcon />}
                  // onClick={toggleDrawer(true)}
                  onClick={()=>{
                    setState({right:true})
                  }}>
                  Add Team
                </Button>
              )}

              {showAddMemberbtn && (
                <Button
                  className="univercel_btn_class"
                  sx={{ color: "#FFFFFF", textTransform: "none" }}
                  startIcon={<AddIcon />}
                  // onClick={toggleDrawer(true)}
                  onClick={()=>{
                    setState({right:true})
                  }}>
                  Add Member
                </Button>
              )}

              <NotificationsIcon />
              <Typography>My Profile</Typography>
              <Box onClick={() => setOpen(!Open)}>
                <Avatar sx={{ bgcolor: "orange" }}>B</Avatar>
              </Box>
              {
                //The Drop Down
                Open && (
                  <Box sx={{ zIndex: 2, position: "absolute", right: 0 }}>
                    <Box
                      sx={{
                        position: "absolute",
                        right: 30,
                        marginTop: "15px",
                      }}
                    >
                      <Box
                        sx={{
                          width: 0,
                          height: 0,

                          borderLeft: "15px solid transparent",
                          borderRight: "15px solid transparent",
                          borderBottom: "15px solid white",
                        }}
                      ></Box>
                    </Box>
                    <Box
                      sx={{
                        position: "absolute",
                        marginTop: "30px",
                        zIndex: 2,
                        right: 20,
                        width: 120,
                        backgroundColor: "white",
                        boxShadow: "0px 11px 15px rgba(0, 0, 0, 0.2)",
                        borderRadius: "12px",
                        padding: "10px",
                      }}
                    >
                      <Box paddingRight="20px">
                        <Link
                          to="/"
                          onClick={() => {
                            UC.logOut();
                          }}
                        >
                          <Stack
                            direction="row"
                            justifyContent="start"
                            alignItems="center"
                            className="hover_button"
                            sx={{
                              background: "white",
                            }}
                          >
                            <Typography
                              className="link_name"
                              sx={{
                                fontWeight: "600",
                                fontSize: "12px",
                                color: "#A4A4AD",
                              }}
                            >
                              {" "}
                              Logout
                            </Typography>
                          </Stack>
                        </Link>
                      </Box>
                    </Box>
                  </Box>
                )
              }
            </Stack>
          </Stack>
        </Stack>

        <>
          <Stack
            direction="row"
            justifyContent="start"
            alignItems="center"
            sx={{ padding: "0px" }}
          >
            {(dashboard ||
              builder ||
              team ||
              organization ||
              project ||
              unit ||
              createProject ||
              media || projectAmenities) && (
              <Box zIndex="4">
                <svg
                  width="115"
                  height="29"
                  viewBox="0 0 115 29"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M108.437 7.24752L101.879 0H0V29H101.879L108.437 21.7525L115 14.505L108.437 7.24752Z"
                    fill="#CCCCCC"
                  />
                </svg>
              </Box>
            )}

            {(organization || project || unit || createProject || media || projectAmenities) && (
              <Box zIndex="3" sx={{ position: "absolute" }}>
                <svg
                  width="146"
                  height="29"
                  viewBox="0 0 146 29"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  style={{ marginLeft: 100 }}
                >
                  <path
                    d="M137.667 7.24752L129.342 0H0V29H129.342L137.667 21.7525L146 14.505L137.667 7.24752Z"
                    fill="#FBB040"
                  />
                </svg>
              </Box>
            )}

            {(project || unit || createProject || media || projectAmenities) && (
              <Box zIndex="2" sx={{ position: "absolute" }}>
                {/* <svg
                width="146"
                height="30"
                viewBox="0 0 146 30"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                style={{ marginLeft: 225 }}
              >
                <path
                  d="M145.238 14.5047L137.339 21.3752L137.339 21.3754L129.155 28.5H0.5V0.5H129.155L137.339 7.62456L137.339 7.62465L145.238 14.5047Z"
                  fill="#CCCCCC"
                  stroke="white"
                />
              </svg> */}
                <svg
                  width="115"
                  height="29"
                  viewBox="0 0 115 29"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  style={{ marginLeft: 225 }}
                >
                  <path
                    d="M108.437 7.24752L101.879 0H0V29H101.879L108.437 21.7525L115 14.505L108.437 7.24752Z"
                    fill="#CCCCCC"
                  />
                </svg>
              </Box>
            )}

            {(unit || createProject || media || projectAmenities) && (
              <Box zIndex="1" sx={{ position: "absolute" }}>
                <svg
                  width="122"
                  height="29"
                  viewBox="0 0 122 29"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  style={{ marginLeft: 325 }}
                >
                  <path
                    d="M115.037 7.24752L108.08 0H0V29H108.08L115.037 21.7525L122 14.505L115.037 7.24752Z"
                    fill="#FBB040"
                  />
                </svg>
              </Box>
            )}

            <Stack
              direction="row"
              justifyContent="left"
              alignItems="center"
              zIndex="5"
              sx={{ position: "absolute" }}
            >
              <Typography
                sx={{
                  fontWeight: "400",
                  fontSize: "11px",
                  color: "#F9F9F9",
                  width: "110px",
                  paddingLeft: "5px",
                }}
              >
                {dashboard && "Dashboard"}
                {team && "Team Directory"}
                {(builder || organization || project || unit || createProject || media || projectAmenities) &&
                  "Builder Directory"}
              </Typography>
              <Typography
                sx={{
                  fontWeight: "400",
                  fontSize: "11px",
                  color: "#F9F9F9",
                  width: "130px",
                }}
              >
                {(organization || project || unit || createProject || media || projectAmenities) && "Organization Directory"}
              </Typography>
              <Typography
                sx={{
                  fontWeight: "400",
                  fontSize: "11px",
                  color: "#F9F9F9",
                  width: "110px",
                }}
              >
                {(project || unit || createProject || media || projectAmenities) && "Project Directory"}
              </Typography>
              <Typography
                sx={{
                  fontWeight: "400",
                  fontSize: "11px",
                  color: "#F9F9F9",
                  width: "110px",
                }}
              >
                {(unit) && "Unit Directory"}
                {media && "Media Directory"}
                {createProject && "Create Project"}
                {projectAmenities && "Amenities"}
              </Typography>
            </Stack>
          </Stack>
        </>
      </Stack>
    </>
  );
};

export const Bradcums = () => {
  return <></>;
};
