import { Avatar, Button, Stack, Typography } from "@mui/material";
import { Box } from "@mui/system";
import NotificationsIcon from "@mui/icons-material/Notifications";
import AddIcon from "@mui/icons-material/Add";
import { Link, useNavigate } from "react-router-dom";
import { useContext, useState } from "react";
import userContext from "../../../../context/profile/context";
export const Navbar = ({
  showAddProjectbtn,
  dontDisplayPath,
  childButton,
  editProject,
  name,
  addProject,
  addClient,
  addProjectNew,
  addStandUnit,
  InternalTabIndex,
  addProClient,
  projectUnitAdd
}) => {
  const navigate = useNavigate();
  const [Open, setOpen] = useState(false);
  const UC = useContext(userContext);

  return (
    <>
      <Stack
        onClick={() => {
          setOpen(false);
        }}
        direction="column"
        justifyContent="space-between"
        sx={{ width: "100%"}}
      >
        <Stack
          direction="row"
          justifyContent="space-between"
          sx={{ width: "100%" }}
        >
          <Stack direction="row" justifyContent="right" alignItems="center">
            <Box>
              <Typography className="WelcomeText" sx={{ fontSize: "24px" }}>
                {name}
              </Typography>
            </Box>
          </Stack>
          <Stack direction="row">
            <Stack
              direction="row"
              justifyContent="right"
              alignItems="center"
              spacing={2}
            >
              {childButton}
              {addClient}
              {addProject}
              {InternalTabIndex==0?
              addProjectNew:
              addStandUnit}
              {addProClient}
              {projectUnitAdd}
              {showAddProjectbtn && (
                <Button
                  className="univercel_btn_class"
                  sx={{ color: "#FFFFFF", textTransform: "none" }}
                  startIcon={<AddIcon />}
                  onClick={() => navigate("/createproject")}
                >
                  Add Projects
                </Button>
              )}

              {/* <NotificationsIcon /> */}
              {/* <Typography>My Profile</Typography>
              <Box
                onClick={(event) => {
                  event.stopPropagation();
                  setOpen(!Open);
                }}
                >
                <Avatar className="profileIconColor" sx={{cursor: "pointer",color:"white"}}>
                  {name? name[7].toUpperCase():"B"}
                </Avatar>
              </Box> */}
              {//The Drop Down
              // Open && (
              //   <Box sx={{ zIndex: 2, position: "absolute", right: 0 }}>
              //     <Box
              //       sx={{
              //         position: "absolute",
              //         right: 30,
              //         marginTop: "15px",
              //       }}
              //     >
              //       <Box
              //         sx={{
              //           width: 0,
              //           height: 0,

              //           borderLeft: "15px solid transparent",
              //           borderRight: "15px solid transparent",
              //           borderBottom: "15px solid white",
              //         }}
              //       ></Box>
              //     </Box>

              //     <Box
              //       sx={{
              //         position: "absolute",
              //         marginTop: "30px",
              //         zIndex: 2,
              //         right: 20,
              //         width: 120,
              //         backgroundColor: "white",
              //         boxShadow: "0px 11px 15px rgba(0, 0, 0, 0.2)",
              //         borderRadius: "12px",
              //         padding: "10px",
              //       }}
              //       >
              //       <Box paddingRight="20px">
              //         <Link
              //           to="/"
              //           onClick={() => {
              //             UC.logOut();
              //           }}
              //         >
              //           <Stack
              //             direction="row"
              //             justifyContent="start"
              //             alignItems="center"
              //             className="hover_button"
              //             sx={{
              //               background: "white",
              //             }}
              //           >
              //             <Typography
              //               className="link_name"
              //               // className="univercel_btn_class"
              //               sx={{
              //                 fontWeight: "400",
              //                 fontSize: "16px",
              //                 color: "#A4A4AD",
              //               }}
              //             >
              //               {" "}
              //               Logout
              //             </Typography>
              //           </Stack>
              //         </Link>
              //       </Box>
              //     </Box>
              //   </Box>
              // )
            }
            </Stack>
          </Stack>
        </Stack>
        {!dontDisplayPath && (
          <>
            <Stack direction="row" sx={{}}>
              <Box zIndex="1">
                <svg
                  width="115"
                  height="29"
                  viewBox="0 0 115 29"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M108.437 7.24752L101.879 0H0V29H101.879L108.437 21.7525L115 14.505L108.437 7.24752Z"
                    fill="#CCCCCC"
                  />
                </svg>
              </Box>
              <Box sx={{ position: "absolute" }}>
                <svg
                  width="140"
                  height="29"
                  viewBox="0 0 140 29"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  style={{ marginLeft: 100 }}
                >
                  <path
                    d="M115.037 7.24752L108.08 0H0V29H108.08L115.037 21.7525L122 14.505L115.037 7.24752Z"
                    fill="#FBB040"
                  />
                </svg>
              </Box>

              <Stack
                direction="row"
                justifyContent="left"
                alignItems="center"
                zIndex="2"
                sx={{ position: "absolute" }}
              >
                <Typography
                  sx={{
                    fontWeight: "400",
                    fontSize: "11px",
                    color: "#F9F9F9",
                    paddingTop: "5px",
                    paddingLeft: "5px",
                  }}
                >
                  Project Directory
                </Typography>
                <Typography
                  sx={{
                    fontWeight: "400",
                    fontSize: "11px",
                    color: "#F9F9F9",
                    paddingTop: "5px",
                    marginLeft: "30px",
                  }}
                >
                  {showAddProjectbtn
                    ? "Project Dashboard"
                    : editProject
                    ? "Edit Project"
                    : "Create Project"}
                </Typography>
              </Stack>
            </Stack>
          </>
        )}
      </Stack>
    </>
  );
};
