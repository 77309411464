import React from 'react'
import { Box, Typography } from '@mui/material'

function CommissionProjectList() {
  return (
    <div>
        <Box sx={{textAlign:"center",marginTop:"100px"}}>
            <Typography sx={{fontSize:"25px",fontWeight:"500",color:"#B9B7D0",}}>No Commissions Found</Typography>
        </Box>
    </div>
  )
}

export default CommissionProjectList