import { Box, Button, Checkbox, Stack, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { Apiaddress, net } from "../../../../utils/consts";
import { AuthService } from "../../../../context/buyer/services/auth";
import axios from 'axios';
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";


function DeassignBuilderProject() {
  
 const [data, setData] = useState(null);
  const [trigger, setTrigger] = useState(false);
  const [token, setToken] = useState(null);
  const [newRowData, setNewRowData] = useState([]);

  useEffect(() => {
    setToken(localStorage.getItem("crm_token"));  
  },[trigger]);

  const getProjects = async () => {
    try {
      const auth = new AuthService()
      const headers = {
        Authorization: `JWT ${token}`,
      };
      const res1 =await (await auth.makeAuthenticatedGetRequest(net("role/inventeryofcp/"))).json()

      const res2=await axios.get(Apiaddress + "/api/projects/getprojectsusingtoken/",{ headers });

      console.log(res1?.data);
      console.log(res2?.data);

      setData(res2.data.projects);

    } catch (err) {
      console.log(err);
    }
  };

   useEffect(() => {
    if (data) {
      console.log(data);
      let arr = [];
      for (let i = 0; i < data.length; i++) {
        let obj = {
          id: i + 1,
          project_id: data[i].id,
          Name: data[i].name,
          State: data[i].state,
          City: data[i].city,
          Pincode: data[i].pin_code,
          Unit: data[i].cp_units.length,
        };
        arr.push(obj);
      }
      setNewRowData(arr);
    }
  }, [data]);

  useEffect(() => {
    if (token){
    // getProjects();
    }
  }, [token, trigger]);

  //table Content
  const [curpage, setcurpage] = useState(1);
  const [pageRange, setPageRange] = useState([0, 6]);
  const [pages, setPages] = useState([]);

  const nextClick = () => {
    if (
      !(newRowData.length >= pageRange[0] && newRowData.length <= pageRange[1])
    ) {
      setcurpage(curpage + 1);
      setPageRange([pageRange[0] + 6, pageRange[1] + 6]);
    }
  };

  const prvClick = () => {
    if (pageRange[0] != 0 && pageRange[1] != 6) {
      setcurpage(curpage - 1);
      setPageRange([pageRange[0] - 6, pageRange[1] - 6]);
    }
  };

  useEffect(() => {
    if (newRowData) {
      let arr = [];
      for (
        let i = 1;
        i <=
        (newRowData.length % 6 == 0
          ? newRowData.length / 6
          : newRowData.length / 6 + 1);
        i++
      ) {
        arr.push(i);
      }
      setPages(arr);
    }
  }, [newRowData]);

  return (
    <>
    <Box sx={{ color: "#A2A3A6",height:"400px",paddingTop:"20px"}}>
        <Box>
            <Box sx={{ color: "black", width: "100%" }}>
                <Box sx={{display: "flex",justifyContent: "start",alignItems: "center",background: "white",borderRadius: "5px",margin: " 10px 0",color: "#272D4E",  fontWeight: "700",fontFamily:"Circular Std",fontSize:"18px"}}>
                <Box sx={{width: "6vw",display: "grid",placeItems: "center",height: "50px"}}>
                    <Checkbox />
                </Box>
                <Box
                    sx={{
                        width: "15vw",
                        display: "grid",
                        placeItems: "center",
                        height: "50px",
                        justifyContent: "start",
                        paddingLeft:"20px",
                    }}>
                    <Box sx={{ display: "flex" }}>
                        <Box sx={{ display: "grid", placeItems: "center" }}>Name</Box>
                    </Box>
                </Box>

          <Box
            sx={{
              width: "15vw",
                display: "grid",
                placeItems: "center",
                height: "50px",
                justifyContent: "start",
                paddingLeft:"20px",
            }}
          >
            <Box sx={{ display: "flex" }}>
              <Box sx={{ display: "grid", placeItems: "center" }}>State</Box>
            </Box>
          </Box>

          <Box
            sx={{
              width: "15vw",
                display: "grid",
                placeItems: "center",
                height: "50px",
                justifyContent: "start",
                paddingLeft:"20px",
            }}
          >
            <Box sx={{ display: "flex" }}>
              <Box sx={{ display: "grid", placeItems: "center" }}>City</Box>
            </Box>
          </Box>

          <Box
            sx={{
              width: "15vw",
                display: "grid",
                placeItems: "center",
                height: "50px",
                justifyContent: "start",
                paddingLeft:"20px",
            }}
          >
            <Box sx={{ display: "flex" }}>
              <Box sx={{ display: "grid", placeItems: "center" }}>
                Pincode
              </Box>
            </Box>
          </Box>

          <Box
            sx={{
             width: "15vw",
                display: "grid",
                placeItems: "center",
                height: "50px",
                justifyContent: "start",
                paddingLeft:"20px",
            }}
          >
            <Box sx={{ display: "flex" }}>
              <Box sx={{ display: "grid", placeItems: "center" }}>Unit</Box>
            </Box>
          </Box>

          {/* <Box
            sx={{
              width: "15vw",
                display: "grid",
                placeItems: "center",
                height: "50px",
                justifyContent: "start",
                paddingLeft:"20px",
            }}
          >
            Actions
          </Box> */}
        </Box>
        {newRowData?.length == 0 ?
          <Box sx={{ textAlign: "center", marginTop: "50px" }}><Typography sx={{ fontSize: "25px", fontWeight: "500", color: "#B9B7D0", }}>No Matches Found</Typography></Box> :
          <Box>
            {newRowData &&
              newRowData.map((item, index) => {

                if (index >= pageRange[0] && index <= pageRange[1]) {
                  return (
                    <Box
                      sx={{
                         display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      background: "white",
                      borderRadius: "5px",
                      margin: " 10px 0",
                      color:"#343434",
                      // fontFamily:"Product Sans",
                      // fontWeight: "400",
                      // cursor:"pointer",
                      }}>
                      <Box
                      sx={{
                        width: "6vw",
                        display: "grid",
                        placeItems: "center",
                        height: "50px",
                      }}
                    >
                      <Checkbox />
                    </Box>
                      <Box
                        sx={{
                          width: "15vw",
                        display: "grid",
                        placeItems: "center",
                        height: "50px",
                        justifyContent: "start",
                        fontFamily:"Product Sans",
                        fontWeight:"400",
                        paddingLeft:"20px"
                        }}
                      >
                        {item.Name}
                      </Box>
                      <Box
                        sx={{
                          width: "15vw",
                        display: "grid",
                        placeItems: "center",
                        justifyContent: "start",
                        height: "50px",
                        fontFamily:"Product Sans",
                        fontWeight:"400",
                        paddingLeft:"20px"
                        }}
                      >
                        {item.State}
                      </Box>
                      <Box
                        sx={{
                         width: "15vw",
                        display: "grid",
                        placeItems: "center",
                        justifyContent: "start",
                        height: "50px",
                        fontFamily:"Product Sans",
                        fontWeight:"400",
                        paddingLeft:"20px"
                        }}
                      >
                        {item.City}
                      </Box>
                      <Box
                        sx={{
                         width: "15vw",
                        display: "grid",
                        placeItems: "center",
                        justifyContent: "start",
                        height: "50px",
                        fontFamily:"Product Sans",
                        fontWeight:"400",
                        paddingLeft:"20px"
                        }}
                      >
                        {item.Pincode}
                      </Box>
                      <Box
                        sx={{
                          width: "15vw",
                        display: "grid",
                        placeItems: "center",
                        justifyContent: "start",
                        height: "50px",
                        fontFamily:"Product Sans",
                        fontWeight:"400",
                        paddingLeft:"20px"
                        }}
                      >
                        {item.Unit}
                        {/* taking unit from the object according to coresponding project ids  */}
                      </Box>
                      <Box
                        sx={{
                         width: "15vw",
                        display: "grid",
                        placeItems: "center",
                        justifyContent: "start",
                        height: "50px",
                        fontFamily:"Product Sans",
                        fontWeight:"400",
                        paddingLeft:"20px"
                        }}
                      >
                        <Stack direction="row" sx={{ marginLeft: "10px" }}>
                          { 
                            // <RemoveRedEyeIcon fontSize="medium" sx={{cursor:"pointer"}}
                            //   onClick={(e) => {
                            //     e.stopPropagation()
                            //     setBuilderSelectedProject(item.project_id);
                            //     localStorage.setItem("BuilderSelectedProject",item.project_id)
                            //     navigate("/channelpartner/project/unit/");
                            //   }}
                            // />
                          }
                          { 
                          //   <EditIcon
                          //     fontSize="medium"
                          //     sx={{ marginLeft: "10px" }}
                          //     onClick={(e) => {
                          //       e.stopPropagation()
                          //       setBuilderSelectedProject(item.project_id)
                          //       navigate("/builder-edit-project-building")
                          //     }
                          //     }
                          //   />
                          // }
                          // { 
                          //   <DeleteIcon
                          //     fontSize="medium"
                          //     sx={{ marginLeft: "10px" }}
                          //     onClick={(e) => {
                          //       e.stopPropagation()
                          //       delIconClick(item)
                          //     }
                          //     }
                          //   />
                          }
                        </Stack>
                      </Box>
                    </Box>
                  );
                }
              })}

              <Box sx={{ margin: "50px 30px 20px 0px"}}>
          <Box sx={{ display: "flex", justifyContent: "right" }}>
            {newRowData.length != 0 &&
              <Button variant="contained" sx={{ background: "linear-gradient(90deg, #0F5DAE 0%, #009DCF 100.33%)" }}
                onClick={prvClick}>
                <ArrowBackIosIcon fontSize="small" />
                <Box sx={{ textTransform: "capitalize" }}>Previous</Box>
              </Button>}

            <Box sx={{ background: "#E3E4EB", display: "flex", placeItems: "center", margin: " 0 10px", borderRadius: "5px" }}>
              <Box sx={{ background: `white`, color: "black", width: "30px", borderRadius: "5px", margin: "0 10px", display: "grid", placeItems: "center" }}>{curpage}</Box>
              <Box sx={{ color: "black", width: "30px", borderRadius: "5px", margin: "0 0px", display: "grid", placeItems: "center" }}>of</Box>
              <Box sx={{ background: `white`, color: "black", width: "30px", borderRadius: "5px", margin: "0 10px", display: "grid", placeItems: "center" }}>{pages?.length}</Box>
            </Box>

            {newRowData.length != 0 &&
              <Button variant="contained" sx={{ background: "linear-gradient(90deg, #0F5DAE 0%, #009DCF 100.33%)" }}
                onClick={nextClick}>
                <Box sx={{ textTransform: "capitalize" }}>Next</Box>
                <ArrowForwardIosIcon fontSize="small" />
              </Button>
            }
          </Box>
        </Box>
        
          </Box>
        }
      </Box>


      <Box>


      </Box>

    </Box>

    </Box>
    </>
  )
}

export default DeassignBuilderProject