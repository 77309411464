import { Box, Button, Container, Typography } from "@mui/material";

const ChannelLandingReadyToExploreNewAvenues = () => {
  return (
    <Box
      sx={{
        backgroundColor: "#0085FF",
        py: "20px",
      }}
    >
      <Container>
        <Box
          data-aos="fade-zoom-in"
          data-aos-easing="ease-in-back"
          data-aos-delay="50"
          data-aos-offset="0"
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            gap: "20px",
            padding: { xs: "15px", md: "30px 20px" },
            borderRadius: { xs: "10px", md: "20px" },
            flexDirection: { xs: "column", md: "row" },
          }}
        >
          <Typography
            sx={{
              color: "#FFF",
              fontSize: { xs: "32px", lg: "40px" },
              fontWeight: 700,
              fontFamily: "Circular Std",
              textAlign: { xs: "center", md: "left" },
              lineHeight: "1.2",
              display: { xs: "none", md2: "block" },
            }}
          >
            Ready to explore new avenues for <br />
            your growth?
          </Typography>
          <Typography
            sx={{
              color: "#FFF",
              fontSize: { xs: "32px", lg: "40px" },
              fontWeight: 700,
              fontFamily: "Circular Std",
              textAlign: { xs: "center", md: "left" },
              lineHeight: "1.2",
              display: { xs: "none", md: "block", md2: "none" },
            }}
          >
            Ready to explore new avenues <br /> for your growth?
          </Typography>
          <Typography
            sx={{
              color: "#FFF",
              fontSize: { xs: "32px", lg: "40px" },
              fontWeight: 700,
              fontFamily: "Circular Std",
              textAlign: { xs: "center", md: "left" },
              lineHeight: "1.2",
              display: { xs: "block", md: "none" },
            }}
          >
            Ready to explore new avenues for your growth?
          </Typography>
          <Box>
            <Button
              sx={{
                background:
                  "linear-gradient(97.85deg, #FFAF2A 0.95%, #FFC666 100%)",
                fontWeight: 500,
                fontSize: { lg: "28px", xs: "16px" },
                boxShadow: "rgba(242, 178, 73, 0.59) 0px 9px 12px",
                borderRadius: "10px",
                color: "#272D4E",
                textTransform: "none",
                padding: "10px 30px",
                fontFamily: "Product Sans",
                lineHeight: "1.3",
                mt: "20px",
                "&:hover": {
                  background:
                    "linear-gradient(97.85deg, #FFAF2A 0.95%, #FFC666 100%)",
                  boxShadow: "rgba(242, 178, 73, 0.59) 10px 12px 16px",
                },
              }}
            >
              Sign up now
            </Button>
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

export default ChannelLandingReadyToExploreNewAvenues;
