import { PortableWifiOffSharp } from '@mui/icons-material';
import React, { useState } from 'react'
import { Apiaddress } from '../../../../../utils/consts';
import { ImageCorrousal } from './imageCorrousal';

export const ImageGrid = (props) => {
    console.log('BRUH@',props)
    let images = [];
    console.log(images);
    // for (let i = 0 ; i < 30;i++){
    //   images.push("https://picsum.photos/seed/building"+i+"/800/650")  
    // }
    if(props.image !== undefined ){
      if( props.image !== []){
        let obj;
        for(let i=0;i<props.image.length;i++){
          if(props.image[i].is_true=='true'){
            obj=props.image[i];
          }
          images.push(props.image[i]);
        }
        if(obj){
          let newArray=images?.filter((ele)=>{
            return(
              ele?.url!==obj?.url
            )
          })
          newArray = [obj].concat(newArray);
          images=newArray;
        }
        // console.log(images);
        // images = props.image;
      }
    }
    const [open, setopen] = useState(false)
    const inverter = ()=>{
      console.log("Bruh")
      setopen(!open);
    }
  
  if(props.image !== undefined)
  {return (  <div>
    <ImageCorrousal open={open} images={images} inverter={inverter}/>
    <div 
      onClick={inverter}
        style={{
          display:"flex",
          flexDirection:"column",
          }}>  
            {images.length >= 1 && <div style={{
              width:300,
              height:400,
              backgroundImage: `url(${Apiaddress +images[0].url})`,
              backgroundColor: "#d9d9d9",
              backgroundSize: "contain",
              backgroundRepeat: "no-repeat",
              borderRadius:"10px",
              border:"1px solid green"
            }} src={Apiaddress +   images[0].url}></div>}  
            {/* {images.length >= 1 && <img style={{
              width:300,
              height:400,
            }} src={Apiaddress +   images[0].url}/>} */}
            <div
            style={{
              display:"flex",
              flexDirection:"row",
              border:"1px solid red"
            }}>
           {images.length >= 2 && <img style={{
              width:150,
              height:150,
            }} src={Apiaddress +  images[1].url} />}
            
          {images.length >= 3 &&  <div  
            style={{
              width:150,
              height:150,
              backgroundColor:"black"
            }}
            >
                <img style={{
               width:150, 
               height:150}} src={Apiaddress +  images[2].url}/> 
            </div>}
         { images.length > 3    &&    <div
                  style={{
                    position: 'absolute',
                    width: 150,
                    height: 150,
                    backgroundColor:"black",
                    opacity:"85%",
                    color:"white",
                    display:"flex",
                    justifyContent:"center",
                    alignItems:"center"
                  }}
                  >
                    {images.length-3}+
                  </div>
            }
            </div>
            {/* <div
              style={{
                position:"absolute",
                width:"150px",
                height:"150px",
                backgroundColor:"black",
                left:"35.7%",
                top:"55%",
                opacity:"80%",
                display:"flex",
                  justifyContent:"center",
                  alignItems:"center",
              }}
              >
              <div
              style={{color:"white"}}
              > 
              {images.length - 3}+

              </div>
            </div> */}
    </div>
              </div>
        
  )}else{
    return<></>
  }
}
