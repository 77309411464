import OTPInput, { ResendOTP } from "otp-input-react";
import { useState } from "react";
import { Stack } from "@mui/system";
import { Typography } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import "../Signup.css";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import KeyboardDoubleArrowLeftIcon from "@mui/icons-material/KeyboardDoubleArrowLeft";
import axios from "axios";
import DoneIcon from "@mui/icons-material/Done";
import { Apiaddress } from "../../../../utils/consts";

export const Otp = ({ stepsno, setStepsno, signupdata, setSignupdata }) => {
  const [EmailOTP, setEmailOTP] = useState("");
  const [NumberOTP, setNumberOTP] = useState("");

  const [everify, setEverify] = useState(false);
  const [pverify, setPverify] = useState(false);
  const [perr, setPerr] = useState(false);
  const [eerr, setEerr] = useState(false);

  //=============email verify========//
  const [emailBtn, setEmailBtn] = useState(
    "linear-gradient(90deg, #0F5DAE 0%, #009DCF 100.33%)"
  );
  const onEmailVarify = () => {
    if (!EmailOTP) return;
    console.log("signupdata", {
      email: signupdata.email,
      phoneNo: signupdata.phone,
      type: "email",
      OTP: EmailOTP,
    });

    axios
      .post(Apiaddress + "/api/verifysignup/", {
        email: signupdata.email,
        phoneNo: signupdata.phone,
        type: "email",
        OTP: EmailOTP,
      })
      .then((res) => {
        console.log("res:", res);
        setEmailBtn("#5ED88A");
        setEverify(true);
      })
      .catch((err) => {
        console.log("err:", err);
        setEerr(true);
      });
  };

  //=============phone verify========//
  const [phoneBtn, setPhoneBtn] = useState(
    "linear-gradient(90deg, #0F5DAE 0%, #009DCF 100.33%)"
  );
  const onPhoneVerify = () => {
    console.log("signupdata", {
      email: signupdata.email,
      phoneNo: signupdata.phone,
      type: "email",
      OTP: NumberOTP,
    });
    if (!NumberOTP) return;
    axios
      .post(Apiaddress + "/api/verifysignup/", {
        email: signupdata.email,
        phoneNo: signupdata.phone,
        type: "phoneNo",
        OTP: NumberOTP,
      })
      .then((res) => {
        console.log("res:", res);
        setPhoneBtn("#5ED88A");
        setPverify(true);
      })
      .catch((err) => {
        console.log("err:", err);
        setPerr(true);
      });
  };
  const [resendemail, setResendemail] = useState(true);
  //=============resend email otp ========//
  const resendEmailOTP = () => {
    console.log(signupdata.email, signupdata.phone, signupdata.name);
    axios
      .post(Apiaddress + "/api/resendotp/", {
        email: signupdata.email,
        phoneNo: signupdata.phone,
        type: "email",
        name: signupdata.name,
      })
      .then((res) => {
        console.log("res:", res);
        setResendemail(true);
      })
      .catch((err) => {
        console.log("err:", err);
        alert("error");
      });
  };
  const [resendphone, setResendphone] = useState(true);
  //=============resend email otp ========//
  const resendPhoneOTP = () => {
    console.log(signupdata.email, signupdata.phone, signupdata.name);
    axios
      .post(Apiaddress + "/api/resendotp/", {
        email: signupdata.email,
        phoneNo: signupdata.phone,
        type: "phonNo",
        name: signupdata.name,
      })
      .then((res) => {
        console.log("res:", res);
        setResendphone(true);
      })
      .catch((err) => {
        console.log("err:", err);
        alert("error");
      });
  };

  ///timer for phone

  const renderTimePhone = (remainingTime) => {
    if (remainingTime == 0) setResendphone(false);
    return (
      <span>
        {" "}
        {remainingTime > 0
          ? " " + remainingTime + " seconds remaining"
          : null}{" "}
      </span>
    );
  };
  const renderButtonPhone = (buttonProps) => {
    // setResendphone(true)
    return <span {...buttonProps}>{!resendphone && " Resend Again"}</span>;
  };
  /// timer for email
  const renderTimeEmail = (remainingTime) => {
    if (remainingTime == 0) setResendemail(false);
    return (
      <span>
        {remainingTime > 0 ? " " + remainingTime + " seconds remaining" : null}
      </span>
    );
  };
  const renderButtonEmail = (buttonProps) => {
    // setResendemail(true)
    return <span {...buttonProps}> {!resendemail && " Resend Again"} </span>;
  };

  let disable;
  if (everify) {
    if (pverify) {
      disable = false;
    }
  } else {
    disable = true;
  }

  console.log(disable);

  return (
    <>
      <Box sx={{ padding: "10px 46px", height: "70vh" }}>
        <Typography sx={{ marginBottom: "30px", fontFamily: "Product Sans" }}>
          Please Provide the OTP send to your Email and Phone number For
          Verification
        </Typography>

        <Typography
          sx={{
            fontWeight: "500",
            fontSize: "18px",
            fontFamily: "Product Sans",
          }}
        >
          Phone OTP
        </Typography>
        <Typography
          sx={{
            fontSize: "0.8vw",
            color: "gray",
            marginBottom: "10px",
            fontFamily: "Product Sans",
          }}
        >
          An OTP is Sent to{" "}
          <span style={{ fontWeight: 700 }}>{signupdata.phone}</span>. Not
          Received?{" "}
          <span className="orange_hover" onClick={resendPhoneOTP}>
            {" "}
            <ResendOTP
              renderTime={renderTimePhone}
              renderButton={renderButtonPhone}
              onResendClick={() => setResendphone(true)}
            />{" "}
          </span>
        </Typography>
        <Stack
          direction="row"
          alignItems="center"
          sx={{ marginBottom: perr ? "10px" : "20px" }}
        >
          <Box>
            <OTPInput
              inputStyles={{
                border: "1px solid gray",
                borderRadius: "8px",
                boxShadow: "0px 2px 6px rgba(19, 18, 66, 0.07)",
                width: "50px",
                height: "50px",
                margin: "2px",
              }}
              value={NumberOTP}
              onChange={setNumberOTP}
              autoFocus
              OTPLength={6}
              otpType="number"
              disabled={false}
            />
            {/* <ResendOTP renderTime={renderTimePhone} renderButton={renderButton} /> */}
          </Box>
          <Box>
            {" "}
            <Button
              variant="contained"
              startIcon={phoneBtn == "#5ED88A" ? <DoneIcon /> : null}
              onClick={onPhoneVerify}
              color="success"
              sx={{
                margin: "0px 30px 0px 30px",
                background: phoneBtn,
                boxShadow: "0px 13.7981px 22.5788px rgba(50, 174, 137, 0.25)",
                textTransform: "none",
              }}
            >
              {phoneBtn == "#5ED88A" ? "Verified" : "Verify"}
            </Button>
          </Box>
        </Stack>
        {perr && (
          <Typography
            sx={{
              fontWeight: "500",
              fontSize: "16px",
              color: "#eb6270",
              marginBottom: "10px",
              fontFamily: "Product Sans",
            }}
          >
            Enter Valid OTP
          </Typography>
        )}

        <Typography
          sx={{
            fontWeight: "500",
            fontSize: "18px",
            fontFamily: "Product Sans",
          }}
        >
          Email OTP
        </Typography>
        <Typography
          sx={{
            fontSize: "0.8vw",
            color: "gray",
            marginBottom: "10px",
            fontFamily: "Product Sans",
          }}
        >
          An OTP is Sent to this{" "}
          <span style={{ fontWeight: 700 }}>{signupdata.email}</span>. Not
          Received?{" "}
          <span className="orange_hover" onClick={resendEmailOTP}>
            <ResendOTP
              renderTime={renderTimeEmail}
              renderButton={renderButtonEmail}
              onResendClick={() => setResendemail(true)}
            />
          </span>
        </Typography>
        <Stack
          direction="row"
          alignItems="center"
          sx={{ marginBottom: eerr ? "10px" : "20px" }}
        >
          <Box>
            <OTPInput
              inputStyles={{
                border: "1px solid gray",
                borderRadius: "8px",
                boxShadow: "0px 2px 6px rgba(19, 18, 66, 0.07)",
                width: "50px",
                height: "50px",
                margin: "2px",
              }}
              value={EmailOTP}
              onChange={setEmailOTP}
              OTPLength={6}
              otpType="number"
              disabled={false}
            />
            {/* <ResendOTP renderTime={renderTimeEmail} renderButton={renderButton} /> */}
          </Box>
          <Box>
            {" "}
            <Button
              variant="contained"
              startIcon={emailBtn == "#5ED88A" ? <DoneIcon /> : null}
              onClick={onEmailVarify}
              color="success"
              sx={{
                margin: "0px 30px",
                background: emailBtn,
                boxShadow: "0px 13.7981px 22.5788px rgba(50, 174, 137, 0.25)",
                textTransform: "none",
              }}
            >
              {emailBtn == "#5ED88A" ? "Verified" : "Verify"}
            </Button>
          </Box>
        </Stack>

        {eerr && (
          <Typography
            sx={{
              fontWeight: "500",
              fontSize: "16px",
              color: "#eb6270",
              marginBottom: "10px",
            }}
          >
            Enter Valid OTP
          </Typography>
        )}
      </Box>

      <Stack
        direction="row"
        justifyContent="right"
        spacing={0}
        sx={{ height: "10vh" }}
      >
        {stepsno > 0 && stepsno < 4 && (
          <Button
            className="univercel_btn_class"
            onClick={() => setStepsno((prev) => prev - 1)}
            variant="contained"
            startIcon={<KeyboardDoubleArrowLeftIcon />}
            color="primary"
            sx={{
              marginRight: "20px",
              width: "114px",
              height: "53px",
              textTransform: "none",
            }}
          >
            Previous
          </Button>
        )}
        {stepsno <= 3 && (
          <Button
            disabled={disable}
            className="univercel_btn_class"
            onClick={() => setStepsno((prev) => prev + 1)}
            variant="contained"
            endIcon={<KeyboardDoubleArrowRightIcon />}
            color="primary"
            sx={{ marginRight: "20px", height: "53px", textTransform: "none" }}
          >
            Next
          </Button>
        )}
      </Stack>

      {/* <OTPInput style={{}} value={OTP} onChange={setOTP} autoFocus OTPLength={6} otpType="number" disabled={false}  />  */}
    </>
  );
};

// {/* https://www.npmjs.com/package/otp-input-react */}
