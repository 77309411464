import axios from "axios";
import { Apiaddress, basicheaders } from "../../utils/consts";

// targetted for changing pricing data
const partchUnitWithPricingData = async (newPricingData:{"titlename":String,"number":String,"fields":Array<String>}[],unit_id:number) => {
    await patchUnit(unit_id,{
        "pricing_data":newPricingData,
    })
}

// generic patch unit_layout
const patchUnit = async (
    unit_id:number,
    data:any) => {
    const url = Apiaddress+"/api/projects/unitlayout/";
   const  re=   await axios.patch(
        url,
        {
            headers:basicheaders,
            "unit_layout_id":unit_id,
            ...data,
        }

    )
    console.log(re.data)
}

export {patchUnit,partchUnitWithPricingData};