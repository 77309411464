import { Box, Button, Container, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import HeaderOverlay from "../../Imgs/header-overlay.png";
import HeaderSliderBg from "../../Imgs/header-slider-bg.png";
import CommonLandingHeaderSliderImg1 from "../../Imgs/common-landing-header-slider-img1.png";
import CommonLandingHeaderSliderImg2 from "../../Imgs/common-landing-header-slider-img2.png";
import CommonLandingHeaderSliderImg3 from "../../Imgs/common-landing-header-slider-img3.png";
import CommonPagesNavbar from "../Shared/CommonPagesNavbar";
import "./CommonLandingHeader.css";
import CommonPagesRoute from "../Shared/CommonPagesRoute";

const CommonLandingHeader = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 7000,
  };
  return (
    <Box>
      <Slider {...settings}>
        <Box
          sx={{
            backgroundImage: `url(${HeaderSliderBg})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "contain",
            backgroundPosition: "right",
            overflowX: "hidden",
            overflowX: "hidden",
          }}
        >
          <Container sx={{ py: "20px" }}>
            <Box
              sx={{
                display: "flex",
                gap: { xs: "20px", md: "30px" },
                alignItems: "center",
                flexDirection: { xs: "column", md: "row" },
              }}
            >
              <Box
                sx={{
                  width: { xs: "100%", md: "50%" },
                  textAlign: { xs: "center", md: "left" },
                }}
              >
                <Typography
                  data-aos="fade-down"
                  sx={{
                    color: "#0085FF",
                    fontSize: { lg: "28px", md: "24px", xs: "20px" },
                    fontFamily: "Product Sans",
                    fontWeight: 600,
                    mt: "10px",
                  }}
                >
                  Real Estate CRM Software
                </Typography>
                <Typography
                  data-aos="fade-right"
                  data-aos-delay="400"
                  sx={{
                    color: "#272D4E",
                    fontSize: "40px",
                    fontWeight: 700,
                    fontFamily: "Circular Std",
                    mt: "10px",
                    lineHeight: "1.1",
                    display: { xs: "block", md: "none" },
                    textTransform: "capitalize",
                  }}
                >
                  Integrated technology solution
                </Typography>
                <Typography
                  data-aos="fade-right"
                  data-aos-delay="400"
                  sx={{
                    color: "#272D4E",
                    fontSize: { md: "48px" },
                    fontWeight: 700,
                    fontFamily: "Circular Std",
                    mt: "10px",
                    lineHeight: "1.1",
                    display: { xs: "none", md: "block" },
                    textTransform: "capitalize",
                  }}
                >
                  Integrated technology solution
                </Typography>
                <Typography
                  data-aos="fade-zoom-in"
                  data-aos-easing="ease-in-back"
                  data-aos-delay="400"
                  data-aos-offset="0"
                  sx={{
                    color: "#343434",
                    fontSize: { lg: "24px", md: "18px", xs: "14px" },
                    fontFamily: "Product Sans",
                    mt: "10px",
                    display: { xs: "none", md: "block" },
                  }}
                >
                  Digitize and grow your Real Estate business exponentially with
                  a reimagined solution - <br /> the first-ever in India!
                </Typography>
                <Typography
                  data-aos="fade-zoom-in"
                  data-aos-easing="ease-in-back"
                  data-aos-delay="400"
                  data-aos-offset="0"
                  sx={{
                    color: "#343434",
                    fontSize: { lg: "24px", md: "18px", xs: "12px" },
                    fontFamily: "Product Sans",
                    mt: "10px",
                    display: { xs: "block", md: "none" },
                  }}
                >
                  Digitize and grow your Real state business exponentially with
                  a reimagined solution - the first-ever in India!
                </Typography>
                <Button
                  data-aos="zoom-out-up"
                  data-aos-delay="400"
                  sx={{
                    background:
                      "linear-gradient(97.85deg, #FFAF2A 0.95%, #FFC666 100%)",
                    fontWeight: 500,
                    fontSize: { lg: "28px", xs: "16px" },
                    boxShadow: "rgba(242, 178, 73, 0.59) 0px 9px 12px",
                    borderRadius: "10px",
                    color: "#06386B",
                    textTransform: "none",
                    padding: "5px 30px",
                    fontFamily: "Product Sans",
                    mt: "20px",
                    "&:hover": {
                      background:
                        "linear-gradient(97.85deg, #FFAF2A 0.95%, #FFC666 100%)",
                      boxShadow: "rgba(242, 178, 73, 0.59) 10px 12px 16px",
                    },
                  }}
                >
                  Book A Demo
                </Button>
              </Box>
              <Box
                data-aos="fade-left"
                data-aos-delay="800"
                sx={{
                  width: { xs: "100%", md: "50%" },
                }}
              >
                <img
                  className="common-header-img"
                  // style={{ width: "100%" }}
                  src={CommonLandingHeaderSliderImg1}
                  alt=""
                />
              </Box>
            </Box>
          </Container>
        </Box>
        <Box
          sx={{
            backgroundImage: `url(${HeaderSliderBg})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "contain",
            backgroundPosition: "right",
            overflowX: "hidden",
            overflowX: "hidden",
          }}
        >
          <Container sx={{ py: "20px" }}>
            <Box
              sx={{
                display: "flex",
                gap: { xs: "20px", md: "30px" },
                alignItems: "center",
                flexDirection: { xs: "column", md: "row" },
              }}
            >
              <Box
                sx={{
                  width: { xs: "100%", md: "50%" },
                  textAlign: { xs: "center", md: "left" },
                }}
              >
                <Typography
                  data-aos="fade-down"
                  sx={{
                    color: "#0085FF",
                    fontSize: { lg: "28px", md: "24px", xs: "20px" },
                    fontFamily: "Product Sans",
                    fontWeight: 600,
                    mt: "10px",
                    textTransform: "capitalize",
                  }}
                >
                  Interconnected Ecosystem
                </Typography>
                <Typography
                  data-aos="fade-right"
                  data-aos-delay="400"
                  sx={{
                    color: "#272D4E",
                    fontSize: "44px",
                    fontWeight: 700,
                    fontFamily: "Circular Std",
                    mt: "10px",
                    lineHeight: "1.1",
                    display: { xs: "block", md: "none" },
                    textTransform: "capitalize",
                  }}
                >
                  For Builders, Developers, Channel Partners, & Home Buyers
                </Typography>
                <Typography
                  data-aos="fade-right"
                  data-aos-delay="400"
                  sx={{
                    color: "#272D4E",
                    fontSize: { md: "44px" },
                    fontWeight: 700,
                    fontFamily: "Circular Std",
                    mt: "10px",
                    lineHeight: "1.1",
                    display: { xs: "none", md: "block" },
                  }}
                >
                  For Builders, Developers, Channel Partners, <br /> & Home
                  Buyers
                </Typography>
                <Typography
                  data-aos="fade-zoom-in"
                  data-aos-easing="ease-in-back"
                  data-aos-delay="400"
                  data-aos-offset="0"
                  sx={{
                    color: "#343434",
                    fontSize: { lg: "24px", md: "18px", xs: "14px" },
                    fontFamily: "Product Sans",
                    mt: "10px",
                    display: { xs: "none", md: "block" },
                  }}
                >
                  Enhance trust and give your customers and partners a
                  seamless experience.
                </Typography>
                <Typography
                  data-aos="fade-zoom-in"
                  data-aos-easing="ease-in-back"
                  data-aos-delay="400"
                  data-aos-offset="0"
                  sx={{
                    color: "#343434",
                    fontSize: { lg: "24px", md: "18px", xs: "12px" },
                    fontFamily: "Product Sans",
                    mt: "10px",
                    display: { xs: "block", md: "none" },
                  }}
                >
                  Enhance trust and give your customers and partners a
                  seamless experience.
                </Typography>
                <Button
                  data-aos="zoom-out-up"
                  data-aos-delay="400"
                  sx={{
                    background:
                      "linear-gradient(97.85deg, #FFAF2A 0.95%, #FFC666 100%)",
                    fontWeight: 500,
                    fontSize: { lg: "28px", xs: "16px" },
                    boxShadow: "rgba(242, 178, 73, 0.59) 0px 9px 12px",
                    borderRadius: "10px",
                    color: "#06386B",
                    textTransform: "none",
                    padding: "5px 30px",
                    fontFamily: "Product Sans",
                    mt: "20px",
                    "&:hover": {
                      background:
                        "linear-gradient(97.85deg, #FFAF2A 0.95%, #FFC666 100%)",
                      boxShadow: "rgba(242, 178, 73, 0.59) 10px 12px 16px",
                    },
                  }}
                >
                  Book A Demo
                </Button>
              </Box>
              <Box
                data-aos="fade-left"
                data-aos-delay="800"
                sx={{
                  width: { xs: "100%", md: "50%" },
                }}
              >
                <img
                  className="common-header-img"
                  src={CommonLandingHeaderSliderImg2}
                  alt=""
                />
              </Box>
            </Box>
          </Container>
        </Box>
        <Box
          sx={{
            backgroundImage: `url(${HeaderSliderBg})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "contain",
            backgroundPosition: "right",
            overflowX: "hidden",
          }}
        >
          <Container sx={{ py: "20px" }}>
            <Box
              sx={{
                display: "flex",
                gap: { xs: "20px", md: "30px" },
                alignItems: "center",
                flexDirection: { xs: "column", md: "row" },
              }}
            >
              <Box
                sx={{
                  width: { xs: "100%", md: "50%" },
                  textAlign: { xs: "center", md: "left" },
                }}
              >
                <Typography
                  data-aos="fade-down"
                  sx={{
                    color: "#0085FF",
                    fontSize: { lg: "40px", md: "38px", xs: "28px" },
                    fontFamily: "Product Sans",
                    fontWeight: 600,
                    mt: "10px",
                  }}
                >
                  Designed by Industry Experts
                </Typography>
                <Typography
                  data-aos="fade-right"
                  data-aos-delay="400"
                  sx={{
                    color: "#272D4E",
                    fontSize: "44px",
                    fontWeight: 700,
                    fontFamily: "Circular Std",
                    mt: "10px",
                    lineHeight: "1.1",
                    display: { xs: "block", md: "none" },
                    textTransform: "capitalize",
                  }}
                >
                  Connect, manage, & keep track
                </Typography>
                <Typography
                  data-aos="fade-right"
                  data-aos-delay="400"
                  sx={{
                    color: "#272D4E",
                    fontSize: { md: "48px" },
                    fontWeight: 700,
                    fontFamily: "Circular Std",
                    mt: "10px",
                    lineHeight: "1.1",
                    display: { xs: "none", md: "block" },
                    textTransform: "capitalize",
                  }}
                >
                  Connect, manage, and keep track
                </Typography>
                <Typography
                  data-aos="fade-zoom-in"
                  data-aos-easing="ease-in-back"
                  data-aos-delay="400"
                  data-aos-offset="0"
                  sx={{
                    color: "#343434",
                    fontSize: { lg: "24px", md: "18px", xs: "14px" },
                    fontFamily: "Product Sans",
                    mt: "10px",
                    display: { xs: "none", md: "block" },
                  }}
                >
                  Of projects, workflows, and more with defined processes and
                  compliant workflows.
                </Typography>
                <Typography
                  data-aos="fade-zoom-in"
                  data-aos-easing="ease-in-back"
                  data-aos-delay="400"
                  data-aos-offset="0"
                  sx={{
                    color: "#343434",
                    fontSize: { lg: "24px", md: "18px", xs: "12px" },
                    fontFamily: "Product Sans",
                    mt: "10px",
                    display: { xs: "block", md: "none" },
                  }}
                >
                  Of projects, workflows, and more with defined processes and
                  compliant workflows.
                </Typography>
                <Button
                  data-aos="zoom-out-up"
                  data-aos-delay="400"
                  sx={{
                    background:
                      "linear-gradient(97.85deg, #FFAF2A 0.95%, #FFC666 100%)",
                    fontWeight: 500,
                    fontSize: { lg: "28px", xs: "16px" },
                    boxShadow: "rgba(242, 178, 73, 0.59) 0px 9px 12px",
                    borderRadius: "10px",
                    color: "#06386B",
                    textTransform: "none",
                    padding: "5px 30px",
                    fontFamily: "Product Sans",
                    mt: "20px",
                    "&:hover": {
                      background:
                        "linear-gradient(97.85deg, #FFAF2A 0.95%, #FFC666 100%)",
                      boxShadow: "rgba(242, 178, 73, 0.59) 10px 12px 16px",
                    },
                  }}
                >
                  Book A Demo
                </Button>
              </Box>
              <Box
                data-aos="fade-left"
                data-aos-delay="800"
                sx={{
                  width: { xs: "100%", md: "50%" },
                }}
              >
                <img
                  className="common-header-img"
                  src={CommonLandingHeaderSliderImg3}
                  alt=""
                />
              </Box>
            </Box>
          </Container>
        </Box>
      </Slider>
      <CommonPagesRoute />
    </Box>
  );
};

export default CommonLandingHeader;
