import { Box, TextField, Typography } from "@mui/material";
import React from "react";
import { BlueButton } from "../../../../../../utils/common/BlueButton";

const DoubleTrailing = (props: any) => {
  const [state, setstate] = React.useState({
    type: "doubletrailing", // normal || withtrailing || radio || check || doubletrailing
    name: "Enter Field Name",
    trailing: ["To", "INR"],
    value: "",
  });
  return (
    <div>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          padding: "10px",
          marginLeft: "16px",
          justifyContent: "center",
          alignItems: "flex-start",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "flex-start",
            flexDirection: "column",
          }}
        >
          <h3 style={{ textTransform: "capitalize" }}>{state.name}</h3>
          <TextField
            value={state.name}
            onChange={(e) => {
              setstate({
                ...state,
                name: e.target.value,
              });
            }}
          >
            {" "}
          </TextField>
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "flex-start",
            flexDirection: "column",
          }}
        >
          <h3>{state.trailing[0]}</h3>
          <TextField
            value={state.trailing[0]}
            onChange={(e) => {
              setstate({
                ...state,
                trailing: [e.target.value, state.trailing[1]],
              });
            }}
          ></TextField>
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "flex-start",
            flexDirection: "column",
          }}
        >
          <h3>{state.trailing[1]}</h3>
          <TextField
            value={state.trailing[1]}
            onChange={(e) => {
              setstate({
                ...state,
                trailing: [state.trailing[0], e.target.value],
              });
            }}
          ></TextField>
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          gap: "0.5rem",
          mt: 2,
          ml: 2,
          justifyContent: "flex-start",
          alignItems: "center",
        }}
      >
        <BlueButton
          onClick={() => {
            props.setData(state);
            props.setShow();
          }}
        >
          Save Field
        </BlueButton>

        <Typography
          onClick={() => {
            props.deleteSelf();
          }}
          sx={{
            color: "#B0AFAF",
            fontWeight: 500,
            cursor: "pointer",
            fontFamily: "Product Sans",
          }}
        >
          Delete
        </Typography>
      </Box>
    </div>
  );
};

export default DoubleTrailing;
