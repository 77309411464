import { Box, Container, Grid, Paper, styled, Typography } from "@mui/material";
import DiminishingTrustAmong from "../../Imgs/diminishing-trust-among.svg";
import LackOfTransparency from "../../Imgs/lack-of-transparency.svg";
import OutdatedProcesses from "../../Imgs/outdated-processes-icon.svg";
import SlowDigitalAdoption from "../../Imgs/slow-digital-adoption.svg";
import UnorganizedWorkflows from "../../Imgs/unorganized-workflows-icon.svg";

const Item2 = styled(Paper)(({ theme }) => ({
  padding: "15px 25px",
  background: "#FFFFFF",
  boxShadow:
    "0px 8px 22px -6px rgba(24, 39, 75, 0.12), 0px 14px 64px -4px rgba(24, 39, 75, 0.12)",
  borderRadius: "14px",
}));

const CommonLandingTheProblemWeeSee = () => {
  return (
    <Box sx={{ py: "50px", overflowX: "hidden" }}>
      <Container>
        <Typography
          data-aos="fade-down"
          sx={{
            color: "#272D4E",
            fontSize: { xs: "28px", md2: "32px", lg: "38px" },
            fontWeight: 700,
            fontFamily: "Circular Std",
            textAlign: "center",
          }}
        >
          The problem we see in the industry
        </Typography>
        <Typography
          data-aos="fade-left"
          sx={{
            color: "#343434",
            fontSize: "20px",
            fontFamily: "Product Sans",
            textAlign: "center",
          }}
        >
          The Indian Real Estate sector is struggling to keep up with evolving
          market standards and customer expectations.
        </Typography>
        <Box sx={{ flexGrow: 1, mt: "30px" }}>
          <Grid
            container
            rowSpacing={3}
            columnSpacing={{ xs: 2, md2: 3 }}
            justifyContent="center"
          >
            <Grid item xs={12} md={6} md2={4} data-aos="fade-right">
              <Item2 sx={{ border: "1px solid #FFAF2A" }}>
                <Box>
                  <img src={OutdatedProcesses} alt="" />
                </Box>

                <Box sx={{ mt: "10px" }}>
                  <Typography
                    sx={{
                      color: "#343434",
                      fontSize: "24px",
                      fontWeight: 600,
                      fontFamily: "Product Sans",
                    }}
                  >
                    Outdated Processes
                  </Typography>
                  <Typography
                    sx={{
                      color: "#343434",
                      fontSize: "18px",
                      fontFamily: "Product Sans",
                      mt: "15px",
                    }}
                  >
                    From booking to raising demand letters or handling customer
                    queries, traditional processes are not up to speed with the
                    changing market and customer needs.
                  </Typography>
                </Box>
              </Item2>
            </Grid>
            <Grid item xs={12} md={6} md2={4} data-aos="fade-up">
              <Item2 sx={{ border: "1px solid #FF4267" }}>
                <Box>
                  <img src={UnorganizedWorkflows} alt="" />
                </Box>

                <Box sx={{ mt: "10px" }}>
                  <Typography
                    sx={{
                      color: "#343434",
                      fontSize: "24px",
                      fontWeight: 600,
                      fontFamily: "Product Sans",
                    }}
                  >
                    Unorganized Workflows
                  </Typography>
                  <Typography
                    sx={{
                      color: "#343434",
                      fontSize: "18px",
                      fontFamily: "Product Sans",
                      mt: "15px",
                      paddingBottom: "25px",
                    }}
                  >
                    From inventory management to legal formalities, scattered
                    workflows reduce efficiency, limit business potential, and
                    breed customer distrust.
                  </Typography>
                </Box>
              </Item2>
            </Grid>
            <Grid item xs={12} md={6} md2={4} data-aos="fade-left">
              <Item2 sx={{ border: "1px solid #0890FE" }}>
                <Box>
                  <img src={SlowDigitalAdoption} alt="" />
                </Box>

                <Box sx={{ mt: "10px" }}>
                  <Typography
                    sx={{
                      color: "#343434",
                      fontSize: "24px",
                      fontWeight: 600,
                      fontFamily: "Product Sans",
                    }}
                  >
                    Slow Digital Adoption
                  </Typography>
                  <Typography
                    sx={{
                      color: "#343434",
                      fontSize: "18px",
                      fontFamily: "Product Sans",
                      mt: "15px",
                      paddingBottom: "25px",
                    }}
                  >
                    Compared to other industries, our Real Estate sector has
                    been slow to catch up on digitization, restricting business
                    growth amid increasing competition.
                  </Typography>
                </Box>
              </Item2>
            </Grid>
            <Grid item xs={12} md={6} md2={4} data-aos="fade-right">
              <Item2 sx={{ border: "1px solid #3629B7" }}>
                <Box>
                  <img src={LackOfTransparency} alt="" />
                </Box>

                <Box sx={{ mt: "10px" }}>
                  <Typography
                    sx={{
                      color: "#343434",
                      fontSize: "24px",
                      fontWeight: 600,
                      fontFamily: "Product Sans",
                    }}
                  >
                    Lack of Transparency
                  </Typography>
                  <Typography
                    sx={{
                      color: "#343434",
                      fontSize: "18px",
                      fontFamily: "Product Sans",
                      mt: "15px",
                      paddingBottom: "35px",
                    }}
                  >
                    Traditional workflows and processes and a lack of
                    digitization have been among the major challenges to
                    transparency.
                  </Typography>
                </Box>
              </Item2>
            </Grid>
            <Grid item xs={12} md={6} md2={4} data-aos="fade-left">
              <Item2 sx={{ border: "1px solid #00C29A" }}>
                <Box>
                  <img src={DiminishingTrustAmong} alt="" />
                </Box>

                <Box sx={{ mt: "10px" }}>
                  <Typography
                    sx={{
                      color: "#343434",
                      fontSize: "24px",
                      fontWeight: 600,
                      fontFamily: "Product Sans",
                    }}
                  >
                    Diminishing Trust among Home Buyers
                  </Typography>
                  <Typography
                    sx={{
                      color: "#343434",
                      fontSize: "18px",
                      fontFamily: "Product Sans",
                      mt: "15px",
                    }}
                  >
                    Traditional experiences and market scenarios have
                    increasingly reduced the trust of home buyers, bringing down
                    business value.
                  </Typography>
                </Box>
              </Item2>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </Box>
  );
};

export default CommonLandingTheProblemWeeSee;
