import React, { useEffect, useState } from 'react';
import { Box, Button, Stack, TextField, Typography } from "@mui/material";
import DoneIcon from '@mui/icons-material/Done';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import Pagination from '@mui/material/Pagination';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';
import Checkbox from '@mui/material/Checkbox';
import currentProjectState from '../../../../context/createproject/context' ;
import { Apiaddress } from '../../../../utils/consts';
import axios from 'axios';

import { Navbar } from "../DashboardComponents/Navbar"
import { Sidebar } from "../DashboardComponents/Sidebar"
import { useNavigate, Link, useLocation } from "react-router-dom";
import { BackeToOverviewBtn } from './BackeToOverviewBtn';
import { MainSidebar } from '../DashboardComponents/MainSidebar';


function ConfirmProject_BolconyDetails() {

  ////get data from previous page 
   const location = useLocation();
   const data = location.state.state;

   const floorData = location.state.current_data

 
  // console.log("preve data =>.>",data)
  

  let [current_data, setCurrentData] = useState({})

  const navigate = useNavigate();

    // const data=["Balcony 1","Balcony 2","Balcony 3","Balcony 4"];

    const [balconyData,setbalconyData]=useState([]);

  // console.log(balconyData);
  
  const addbalcony=()=>{
    let temp=balconyData.length+1;
    let to_insert={
      balcony_no:temp,
      length:"",
      breadth:""
    }
    setbalconyData([...balconyData,to_insert]);
  }
 
  const updatenumber=(newbalconyData,index)=>{
    for(let i=index;i<newbalconyData.length;i++){
      newbalconyData[i].balcony_no=newbalconyData[i].balcony_no-1;
    }
    // console.log(newbedroomdata);
    setbalconyData(newbalconyData);
  }

  useEffect(()=>{

   const uniturl = Apiaddress+"/api/projects/unittemplate/?unit_template_id="+data.unit_id;
   console.log(data);
   axios.get(uniturl)
   .then((res)=>{
        const data = res?.data
        console.log("updated layout  data=>>>>>>>.",data?.unit_template[0].layout_data)
        setbalconyData(data.unit_template[0].layout_data.balconyData)
        setCurrentData(data?.unit_template[0].layout_data)
     })
     .catch((err)=>{
       console.log(err)
     })
 

  },[]);


  //function for create unit Templates
  const createBalconyTemplate = ()=>{

      setCurrentData({...current_data,balconyData});
      console.log({...current_data,balconyData}); 
  //  console.log(unit); 
  //  setunitTemplates([...unitTemplates,unit])
  //  if(unit.unit_id=="" || unit.unit_name=="" || unit.unit_no=="") return;
    let url = Apiaddress+"/api/projects/unittemplate/?unit_template_id="+data.unit_id;
    
    let st = {
      unit_template_id:data.unit_id,
      layout_data:{...current_data,balconyData}
    }

    let headers = {
      headers:{
        "Content-Type":"application/json"
    }
    }
  //   console.log(st);

    axios.patch(url,st,headers)
    .then((res)=>{
      console.log("add balcony template in unit======>", res.data);
    })
    .catch((err)=>{
      console.log(err);
    })

    }



    const label = { inputProps: { 'aria-label': 'Checkbox demo' } };

  return (
    <>
    
<Stack direction="row" sx={{width:"100%",height:"auto" }}>
      {/* 1st stack */}
      <Stack sx={{minHeight:"100vh",width:"20%",boxSizing:"border-box",}}> {/*for project directory 20% and for sales dashboard 225*/}
        <MainSidebar active={"Project Directory"}/>
      </Stack>

     {/* 2nd stack */}
     <Stack direction="column" sx={{ backgroundColor:"#f9f9f9",width:"80%", padding:"25px",boxSizing:"border-box",}}  >  {/*  width for main sidebar 78px and for 80px*/}
      <Navbar/>
      


      <Typography sx={{marginTop:"20px", fontSize:"28px", fontWeight:"600", marginLeft:"10px", marginBottom:"50px"}}>
                    Project Overview 
           </Typography>
     {/* actual content  */}
    <Stack sx={{backgroundColor:"#f9f9f9"}}>  {/* mt:"28px", */}

     <Stack direction="column"  sx={{  padding:"20px", backgroundColor:"#fff", marginRight:"0px"}}>

           {/* confirm project bathroom component */}
                     



        <Stack direction="row" spacing={1.5}>
        <Stack direction="row" alignItems="center" spacing={1.5} sx={{margin:"70px 0px 70px 0px"}}>
           <Box  sx={{width:"29px", height:"29px",borderRadius:"50%", backgroundColor:"#FFA946", display:"flex", justifyContent:"center", alignItems:"center"}}>
              <DoneIcon sx={{color:"#fff", fontSize:"20px"}}/>
            </Box>
            <Typography sx={{fontSize:"18px", fontWeight:"500"}}>Project Home</Typography>
          </Stack>
          <Stack direction="row" alignItems="center" spacing={1.5} sx={{margin:"70px 0px 70px 0px"}}>
           <Box  sx={{width:"29px", height:"29px",borderRadius:"50%", backgroundColor:"#FFA946", display:"flex", justifyContent:"center", alignItems:"center"}}>
              <DoneIcon sx={{color:"#fff", fontSize:"20px"}}/>
            </Box>
            <Typography sx={{fontSize:"18px", fontWeight:"500"}}>Building 1</Typography>
          </Stack>
          <Stack direction="row" alignItems="center" spacing={1.5} sx={{margin:"70px 0px 70px 0px"}}>
           <Box  sx={{width:"29px", height:"29px",borderRadius:"50%", backgroundColor:"#FFA946", display:"flex", justifyContent:"center", alignItems:"center"}}>
              <DoneIcon sx={{color:"#fff", fontSize:"20px"}}/>
            </Box>
            <Typography sx={{fontSize:"18px", fontWeight:"500"}}>Wing 1</Typography>
          </Stack>
          <Stack direction="row" alignItems="center" spacing={1.5} sx={{margin:"70px 0px 70px 0px"}}>
           <Box  sx={{width:"29px", height:"29px",borderRadius:"50%", backgroundColor:"#FFA946", display:"flex", justifyContent:"center", alignItems:"center"}}>
              <DoneIcon sx={{color:"#fff", fontSize:"20px"}}/>
            </Box>
            <Typography sx={{fontSize:"18px", fontWeight:"500"}}>Floor 1</Typography>
          </Stack>
          <Stack direction="row" alignItems="center" spacing={1.5} sx={{margin:"70px 0px 70px 0px"}}>
           <Box  sx={{width:"29px", height:"29px",borderRadius:"50%", backgroundColor:"#FFA946", display:"flex", justifyContent:"center", alignItems:"center"}}>
              <DoneIcon sx={{color:"#fff", fontSize:"20px"}}/>
            </Box>
            <Typography sx={{fontSize:"18px", fontWeight:"500"}}>{data.unit_name} (Balcony)</Typography>
          </Stack>
          </Stack>

          <Box sx={{}}>
            <Stack direction="row" sx={{margin:"0px 0px 0px 0px",display:"flex",alignItems:"center"}} >
              <Typography sx={{fontSize:"18px", fontWeight:"500"}}>Balcony</Typography>
              <AddIcon sx={{color:"#A2A3A6",fontSize:"18px",marginLeft:"10px",cursor:"pointer"}} onClick={addbalcony}/>
            </Stack>

            
            
            {balconyData?.map((ele,index)=>{
                return(
                    <Stack direction="row" sx={{display:"flex",alignItems:"center",margin:"20px 0px 0px 0px"}} >
                      <Typography sx={{fontSize:"18px", fontWeight:"500",color:"#A2A3A6"}}>{ele.balcony_no}</Typography>
                       <TextField
                       type="number" inputProps={{ min: 0 }}
                            sx={{
                                 width:"188px",
                                 marginLeft:"10px"
                                }} value={ele.length}  onChange={(event)=>{
                                  setbalconyData((prev) => {
                                return prev.filter((curvalue, idx) => {
                                if (idx == index) {
                                    curvalue.length = event.target.value;
                                }
                                return curvalue;
                           })
                            })
                        }} 
                        />
                       <Typography sx={{fontSize:"18px", fontWeight:"500",color:"#A2A3A6",margin:"0px 0px 0px 10px"}}>L</Typography>
                       <CloseIcon sx={{fontSize:"22px", fontWeight:"500",color:"#A2A3A6",margin:"0px 0px 0px 20px"}}/>
                       <TextField
                       type="number" inputProps={{ min: 0 }}
                            sx={{
                                 width:"188px",
                                 marginLeft:"10px"
                                }} value={ele.breadth} onChange={(event)=>{
                                  setbalconyData((prev) => {
                                return prev.filter((curvalue, idx) => {
                                if (idx == index) {
                                    curvalue.breadth = event.target.value;
                                }
                                return curvalue;
                           })
                            })
                        }} 
                        />
                        <Typography sx={{fontSize:"18px", fontWeight:"500",color:"#A2A3A6",margin:"0px 0px 0px 10px"}}>B</Typography>
                        {/* <Checkbox {...label} sx={{color:"#A2A3A6",margin:"0px 0px 0px 20px"}}/>
                        <Typography sx={{fontSize:"18px", fontWeight:"500",color:"#A2A3A6",margin:"0px 0px 0px 10px"}}>Attached</Typography> */}
                        <DeleteIcon sx={{fontSize:"26px", fontWeight:"500",color:"#EF3649",margin:"0px 0px 0px 30px",cursor:"pointer"}}
                         onClick={()=>{
                            const newbalconyData = balconyData.filter((x) => x !== ele);
                            console.log(newbalconyData);
                            updatenumber(newbalconyData,index);
                        }} 
                        />
                    </Stack>
                )
            })}

            <Stack direction="row" sx={{alignItems:"center"}}>

            <Box 
             onClick={()=>{
              navigate(-1)
            }}
            sx={{display:"flex",alignItems:"center",margin:"0px 0px 0px 0px"}}>
            <ArrowBackIcon/>
            <Typography sx={{fontSize:"18px", fontWeight:"500",margin:"0px 10px 0px 10px"}}>Back</Typography>
            </Box>
            
             <Box sx={{margin:"60px 0px 60px 60px"}}>

              <Link to={`/confirm-project-unit`} state={floorData}
                onClick={()=>{
                createBalconyTemplate()
              }}
              >
                <Button 
                className="univercel_btn_class"
                variant="contained"
                sx={{padding:"16px 50px 16px 50px", color:"#fff",fontSize:"18px", fontWeight:"700",height:"50px"}}>
                    Save 
                </Button>
              </Link>

            </Box>

          </Stack>

        </Box>
                     
           </Stack>

           <BackeToOverviewBtn/>

    </Stack>
      </Stack>
</Stack>


    </>
  )
}

export default ConfirmProject_BolconyDetails