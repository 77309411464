import { Route, Routes } from "react-router-dom";
import { BuilderDashboard } from "../Components/BuilderComponents/BuilderDasboard/SalesDashboard";
import BDmainPage from "../Components/BuilderComponents/BuyersDirectory/BDmainPage";
import CPDmainPage from "../Components/BuilderComponents/ChanelPartnerDirectory/CPDmainPage";
import CPOmainPage from "../Components/BuilderComponents/ChanelPartnerDirectory/CPOmainPage";
import LDmainPage from "../Components/BuilderComponents/LeadsDirectory/LDmainPage";
import MDmainPage from "../Components/BuilderComponents/MembersDirectory/MDmainPage";
import TDmainPgae from "../Components/BuilderComponents/MembersDirectory/TDmainPgae";
import { AttachMediaToUnit } from "../Components/BuilderComponents/ProjectDashboard/CreateProject/AttachMediaToUnit";
import { AttachMediaToUnit_Category } from "../Components/BuilderComponents/ProjectDashboard/CreateProject/AttachMediaToUnit_Category";
// import ConfirmProject from "../Components/BuilderComponents/ProjectDashboard/CreateProject/ConfirmProject2_Building";
import LDoverviewPage from "../Components/BuilderComponents/LeadsDirectory/LDoverviewPage";
import ODmainPage from "../Components/BuilderComponents/OrganizationDirectory/ODmainPage";
import { Add_Media } from "../Components/BuilderComponents/ProjectDashboard/CreateProject/Add_media/Add_Media";
import { UploadMedia_To_project } from "../Components/BuilderComponents/ProjectDashboard/CreateProject/Add_media/UploadMedia_To_project";
import { ConfirmProject1 } from "../Components/BuilderComponents/ProjectDashboard/CreateProject/ConfirmProject1";
import ConfirmProject2_Building from "../Components/BuilderComponents/ProjectDashboard/CreateProject/ConfirmProject2_Building";
import ConfirmProject2_Building_add from "../Components/BuilderComponents/ProjectDashboard/CreateProject/ConfirmProject2_Building_add";
import ConfirmProject2_Building_details from "../Components/BuilderComponents/ProjectDashboard/CreateProject/ConfirmProject2_Building_details";
import ConfirmProject2_floors_details from "../Components/BuilderComponents/ProjectDashboard/CreateProject/ConfirmProject2_floors_details";
import ConfirmProject2_Floor_add from "../Components/BuilderComponents/ProjectDashboard/CreateProject/ConfirmProject2_Floor_add";
import ConfirmProject2_Floor_create from "../Components/BuilderComponents/ProjectDashboard/CreateProject/ConfirmProject2_Floor_create";
import ConfirmProject2_Flores from "../Components/BuilderComponents/ProjectDashboard/CreateProject/ConfirmProject2_Flores";
import ConfirmProject2_Unit from "../Components/BuilderComponents/ProjectDashboard/CreateProject/ConfirmProject2_Unit";
import ConfirmProject2_Unit_add from "../Components/BuilderComponents/ProjectDashboard/CreateProject/ConfirmProject2_Unit_add";
import ConfirmProject2_Wings from "../Components/BuilderComponents/ProjectDashboard/CreateProject/ConfirmProject2_Wings";
import ConfirmProject2_Wings_add from "../Components/BuilderComponents/ProjectDashboard/CreateProject/ConfirmProject2_Wings_add";
import ConfirmProject2_Wings_create from "../Components/BuilderComponents/ProjectDashboard/CreateProject/ConfirmProject2_Wings_create";
import ConfirmProject2_Wings_details from "../Components/BuilderComponents/ProjectDashboard/CreateProject/ConfirmProject2_Wings_details";
import ConfirmProject2_wing_floors from "../Components/BuilderComponents/ProjectDashboard/CreateProject/ConfirmProject2_wing_floors";
import ConfirmProject2_wing_floors_add from "../Components/BuilderComponents/ProjectDashboard/CreateProject/ConfirmProject2_wing_floors_add";
import ConfirmProject2_wing_floors_create from "../Components/BuilderComponents/ProjectDashboard/CreateProject/ConfirmProject2_wing_floors_create";
import ConfirmProject2_wing_floors_details from "../Components/BuilderComponents/ProjectDashboard/CreateProject/ConfirmProject2_wing_floors_details";
import ConfirmProject_BathroomDetails from "../Components/BuilderComponents/ProjectDashboard/CreateProject/ConfirmProject_BathroomDetails";
import ConfirmProject_BedroomDetails from "../Components/BuilderComponents/ProjectDashboard/CreateProject/ConfirmProject_BedroomDetails";
import ConfirmProject_BolconyDetails from "../Components/BuilderComponents/ProjectDashboard/CreateProject/ConfirmProject_BolconyDetails";
import ConfirmProject_FloorPlanDetails from "../Components/BuilderComponents/ProjectDashboard/CreateProject/ConfirmProject_FloorPlanDetails";
import ConfirmProject_MediaDetails from "../Components/BuilderComponents/ProjectDashboard/CreateProject/ConfirmProject_MediaDetails";
import ConfirmProject_PricingDetails from "../Components/BuilderComponents/ProjectDashboard/CreateProject/ConfirmProject_PricingDetails";
import { CreateProjectOptions } from "../Components/BuilderComponents/ProjectDashboard/CreateProject/CreateProjectOptions";
import { CreateProjectProfile } from "../Components/BuilderComponents/ProjectDashboard/CreateProject/CreateProjectProfile";
import { CreateProjectConfiguration } from "../Components/BuilderComponents/ProjectDashboard/CreateProject/CSVUpload/CreateProjectConfiguration";
import { CreateProjectCSVUpload } from "../Components/BuilderComponents/ProjectDashboard/CreateProject/CSVUpload/CreateProjectCSVUpload";
import { ProjectLayout } from "../Components/BuilderComponents/ProjectDashboard/CreateProject/ProjectLayout";
import { ProjectPricing } from "../Components/BuilderComponents/ProjectDashboard/CreateProject/ProjectPricing";
import { MainProjectCreateScreen } from "../Components/BuilderComponents/ProjectDashboard/MainProjectCreateScreen";
import { ProjectDashboard } from "../Components/BuilderComponents/ProjectDashboard/ProjectDashboard";
import BuildingPatternTemplate from "../Components/BuilderComponents/ProjectDashboard/ProjectTemplate/BuildingPatternTemplate";
import FloorTemplate from "../Components/BuilderComponents/ProjectDashboard/ProjectTemplate/FloorTemplate";
import NewPricingTemplate from "../Components/BuilderComponents/ProjectDashboard/ProjectTemplate/NewPricingTemplate";
import UnitTemplate from "../Components/BuilderComponents/ProjectDashboard/ProjectTemplate/UnitTemplate";
import WingTemplate from "../Components/BuilderComponents/ProjectDashboard/ProjectTemplate/WingTemplate";
import PDmainPage from "../Components/BuilderComponents/ProjectDirectory/PDmainPage";
import { SignupBuilder } from "../Components/BuilderComponents/Signup/SignupBuilder";
import { SignupChannelPartner } from "../Components/ChannelPartnerComponents/SignUp/SignupChannelPartner";
import { CommonLandingPage } from "../Components/CommonPages/CommonLandingPage/CommonLandingPage";
import { LoginUser } from "../Components/SetupPassAndLogin/LoginUser";
import { SetupPassword } from "../Components/SetupPassAndLogin/SetupPassword";
import { BuilderRoutes } from "../PrivateRoutes/BuilderRoutes";

import ProjectAmenities from "../Components/AllDirectories/AmenitiesDirectory/ProjectAmenities";
import { Buyers_Directory_w_sb } from "../Components/AllDirectories/BuyerDirectory/Buyers_Directory_w_sb";
import { ChannelPartener_Directory_w_sb } from "../Components/AllDirectories/ChannelPartnerDirectory/ChannelPartener_Directory_w_sb";
import { Lead_Directory_w_sb } from "../Components/AllDirectories/LeadDirectory/Lead_Directory_w_sb";
import Occupancy_Directory_w_sb from "../Components/AllDirectories/Occupancy/Occupancy_Directory_w_sb";
import { Quotation_Directory_w_sb } from "../Components/AllDirectories/QuotationDirectory/Quotation_Directory_w_sb";
import { EditBalcony } from "../Components/AllDirectories/UnitDirectory/EditSingleUnit/EditBalcony";
import { EditBathroom } from "../Components/AllDirectories/UnitDirectory/EditSingleUnit/EditBathroom";
import { EditBedroom } from "../Components/AllDirectories/UnitDirectory/EditSingleUnit/EditBedroom";
import { EditFloorPlan } from "../Components/AllDirectories/UnitDirectory/EditSingleUnit/EditFloorPlan";
import { EditMedia } from "../Components/AllDirectories/UnitDirectory/EditSingleUnit/EditMedia";
import { EditPricing } from "../Components/AllDirectories/UnitDirectory/EditSingleUnit/EditPricing";
import { EditSingleUnit } from "../Components/AllDirectories/UnitDirectory/EditSingleUnit/EditSingleUnit";
import { Unit_Directory_w_sb } from "../Components/AllDirectories/UnitDirectory/Unit_Directory_w_sb";
import { Unit_overview_w_sb } from "../Components/AllDirectories/UnitDirectory/Unit_overview_w_sb";
import AllFloorPlans from "../Components/AllFloorPlans/AllFloorPlans";
import EditProjectBuilding from "../Components/BuilderComponents/EditeProject/EditProjectBuilding";
import EditProjectBuilding_Add from "../Components/BuilderComponents/EditeProject/EditProjectBuilding_Add";
import EditProjectBuilding_Details from "../Components/BuilderComponents/EditeProject/EditProjectBuilding_Details";
import EditProjectFloor from "../Components/BuilderComponents/EditeProject/EditProjectFloor";
import EditProjectFloor_Add from "../Components/BuilderComponents/EditeProject/EditProjectFloor_Add";
import EditProjectFloor_Create from "../Components/BuilderComponents/EditeProject/EditProjectFloor_Create";
import EditProjectFloor_Details from "../Components/BuilderComponents/EditeProject/EditProjectFloor_Details";
import EditProjectUnit from "../Components/BuilderComponents/EditeProject/EditProjectUnit";
import EditProjectUnit_Add from "../Components/BuilderComponents/EditeProject/EditProjectUnit_Add";
import EditProjectUnit_Balcony from "../Components/BuilderComponents/EditeProject/EditProjectUnit_Balcony";
import EditProjectUnit_Bathroom from "../Components/BuilderComponents/EditeProject/EditProjectUnit_Bathroom";
import EditProjectUnit_Bedroom from "../Components/BuilderComponents/EditeProject/EditProjectUnit_Bedroom";
import EditProjectUnit_Create from "../Components/BuilderComponents/EditeProject/EditProjectUnit_Create.";
import EditProjectUnit_FloorPlans from "../Components/BuilderComponents/EditeProject/EditProjectUnit_FloorPlans";
import EditProjectUnit_Media from "../Components/BuilderComponents/EditeProject/EditProjectUnit_Media";
import EditProjectUnit_Pricing from "../Components/BuilderComponents/EditeProject/EditProjectUnit_Pricing";
import EditProjectWing from "../Components/BuilderComponents/EditeProject/EditProjectWing";
import EditProjectWing_Add from "../Components/BuilderComponents/EditeProject/EditProjectWing_Add";
import EditProjectWing_Create from "../Components/BuilderComponents/EditeProject/EditProjectWing_Create";
import EditProjectWing_Details from "../Components/BuilderComponents/EditeProject/EditProjectWing_Details";
import EditProjectWing_Floor from "../Components/BuilderComponents/EditeProject/EditProjectWing_Floor";
import EditProjectWing_Floor_Add from "../Components/BuilderComponents/EditeProject/EditProjectWing_Floor_Add";
import EditProjectWing_Floor_Create from "../Components/BuilderComponents/EditeProject/EditProjectWing_Floor_Create";
import EditProjectWing_Floor_Details from "../Components/BuilderComponents/EditeProject/EditProjectWing_Floor_Details";
import { AttachMediaToProject } from "../Components/BuilderComponents/ProjectDashboard/CreateProject/AttachMediaToProject";
import { AttachMediaToProject_Category } from "../Components/BuilderComponents/ProjectDashboard/CreateProject/AttachMediaToProject_Category";
import { ConfirmProject2_MediaAdd } from "../Components/BuilderComponents/ProjectDashboard/CreateProject/ConfirmProject2_MediaAdd";
import { ConfirmProjec2_MediaAddUnit } from "../Components/BuilderComponents/ProjectDashboard/CreateProject/ConfirmProject2_MediaAddUnit";
import ConfirmProject2_Unit_create from "../Components/BuilderComponents/ProjectDashboard/CreateProject/ConfirmProject2_Unit_create";
import { ProjectOverview } from "../Components/BuilderComponents/ProjectDashboard/CreateProject/ProjectOverview";
import Documents from "../Components/BuyerComponents/documents/Documents";
import Finances from "../Components/BuyerComponents/Finances/Finances";
import Dashboard from "../Components/BuyerComponents/Home/dashboard";
import Projects from "../Components/BuyerComponents/Projects/Projects";
import Tickets from "../Components/BuyerComponents/tickets/Tickets";
import Units from "../Components/BuyerComponents/Units/Units";
import CPAppointments from "../Components/ChannelPartner/appointments/Appointments";
import CPClients from "../Components/ChannelPartner/clients/Clients";
import CPDashboard from "../Components/ChannelPartner/Dashboard/Dashboard";
import CPLeads from "../Components/ChannelPartner/leads/leads";
import CPRequirements from "../Components/ChannelPartner/Requirements/Requirements";
import CPTeamsjs from "../Components/ChannelPartner/Teams/Teams.js";

import { NotificationProject } from "../Components/Notifications/NotificationProject";
import { NotificationSales } from "../Components/Notifications/NotificationSales";
import BuilderOverview from "../Components/SuperAdminComponents/BuilderDirectory/BuilderOverview";
import SuperAdminAmenities from "../Components/SuperAdminComponents/BuilderDirectory/SuperAdminAmenities";
import SuperAdminBuilder from "../Components/SuperAdminComponents/BuilderDirectory/SuperAdminBuilder";
import SuperAdminBuilderEdit from "../Components/SuperAdminComponents/BuilderDirectory/SuperAdminBuilderEdit";
import SuperAdminOrg from "../Components/SuperAdminComponents/BuilderDirectory/SuperAdminOrg";
import SuperAdminProject from "../Components/SuperAdminComponents/BuilderDirectory/SuperAdminProject";
import SuperAdminUnit from "../Components/SuperAdminComponents/BuilderDirectory/SuperAdminUnit";
import { AttachMediaTo_ProjectSA } from "../Components/SuperAdminComponents/CreateProjectBySuperAdmin/Attached_Media/AttachMediaTo_ProjectSA";
import { AttachMediaTo_Project_CategorySA } from "../Components/SuperAdminComponents/CreateProjectBySuperAdmin/Attached_Media/AttachMediaTo_Project_CategorySA";
import { AttachMediaTo_UnitSA } from "../Components/SuperAdminComponents/CreateProjectBySuperAdmin/Attached_Media/AttachMediaTo_UnitSA";
import { AttachMediaTo_Unit_CategorySA } from "../Components/SuperAdminComponents/CreateProjectBySuperAdmin/Attached_Media/AttachMediaTo_Unit_CategorySA";
import { ConfirmProjectSA } from "../Components/SuperAdminComponents/CreateProjectBySuperAdmin/ConfirmProjectSA";
import { ConfirmProject_BuildingSA } from "../Components/SuperAdminComponents/CreateProjectBySuperAdmin/ConfirmProject_Pages/ConfirmProject_BuildingSA";
import { ConfirmProject_Building_AddSA } from "../Components/SuperAdminComponents/CreateProjectBySuperAdmin/ConfirmProject_Pages/ConfirmProject_Building_AddSA";
import { ConfirmProject_Building_DetailsSA } from "../Components/SuperAdminComponents/CreateProjectBySuperAdmin/ConfirmProject_Pages/ConfirmProject_Building_DetailsSA";
import { ConfirmProject_FloorSA } from "../Components/SuperAdminComponents/CreateProjectBySuperAdmin/ConfirmProject_Pages/ConfirmProject_FloorSA";
import { ConfirmProject_Floor_AddSA } from "../Components/SuperAdminComponents/CreateProjectBySuperAdmin/ConfirmProject_Pages/ConfirmProject_Floor_AddSA";
import { ConfirmProject_Floor_CreateSA } from "../Components/SuperAdminComponents/CreateProjectBySuperAdmin/ConfirmProject_Pages/ConfirmProject_Floor_CreateSA";
import { ConfirmProject_Floor_DetailsSA } from "../Components/SuperAdminComponents/CreateProjectBySuperAdmin/ConfirmProject_Pages/ConfirmProject_Floor_Details";
import { ConfirmProject_WingSA } from "../Components/SuperAdminComponents/CreateProjectBySuperAdmin/ConfirmProject_Pages/ConfirmProject_WingSA";
import { ConfirmProject_Wing_AddSA } from "../Components/SuperAdminComponents/CreateProjectBySuperAdmin/ConfirmProject_Pages/ConfirmProject_Wing_AddSA";
import { ConfirmProject_Wing_CreateSA } from "../Components/SuperAdminComponents/CreateProjectBySuperAdmin/ConfirmProject_Pages/ConfirmProject_Wing_CreateSA";
import { ConfirmProject_Wing_FloorSA } from "../Components/SuperAdminComponents/CreateProjectBySuperAdmin/ConfirmProject_Pages/ConfirmProject_Wing_FloorSA";
import { ConfirmProject_Wing_Floor_AddSA } from "../Components/SuperAdminComponents/CreateProjectBySuperAdmin/ConfirmProject_Pages/ConfirmProject_Wing_Floor_AddSA";
import { ConfirmProject_Wing_Floor_CreateSA } from "../Components/SuperAdminComponents/CreateProjectBySuperAdmin/ConfirmProject_Pages/ConfirmProject_Wing_Floor_CreateSA";
import { ConfirmProject_Wing_Floor_DetailsSA } from "../Components/SuperAdminComponents/CreateProjectBySuperAdmin/ConfirmProject_Pages/ConfirmProject_Wing_Floor_DetailsSA";
import { ConfirmProject_Wing_DetailsSA } from "../Components/SuperAdminComponents/CreateProjectBySuperAdmin/ConfirmProject_Pages/ConfirmProoject_Wing_DetailsSA";
import { ConfirmProject_UnitSA } from "../Components/SuperAdminComponents/CreateProjectBySuperAdmin/ConfirmProject_UnitOverview/ConfirmProjectUnitSA";
import { ConfirmProject_BalconyDetailsSA } from "../Components/SuperAdminComponents/CreateProjectBySuperAdmin/ConfirmProject_UnitOverview/ConfirmProject_BalconyDetailsSA";
import { ConfirmProject_BathroomDetailsSA } from "../Components/SuperAdminComponents/CreateProjectBySuperAdmin/ConfirmProject_UnitOverview/ConfirmProject_BathroomDetailsSA";
import { ConfirmProject_BedroomDetailsSA } from "../Components/SuperAdminComponents/CreateProjectBySuperAdmin/ConfirmProject_UnitOverview/ConfirmProject_BedroomDetailsSA";
import { ConfirmProject_FloorPlan_DetailsSA } from "../Components/SuperAdminComponents/CreateProjectBySuperAdmin/ConfirmProject_UnitOverview/ConfirmProject_FloorPlan_DetailsSA";
import { ConfirmProject_Media_DetailsSA } from "../Components/SuperAdminComponents/CreateProjectBySuperAdmin/ConfirmProject_UnitOverview/ConfirmProject_Media_DetailsSA";
import { ConfirmProject_PricingDetailsSA } from "../Components/SuperAdminComponents/CreateProjectBySuperAdmin/ConfirmProject_UnitOverview/ConfirmProject_PricingDetailsSA";
import { CreateProjectOptionsSA } from "../Components/SuperAdminComponents/CreateProjectBySuperAdmin/CreateProjectOptionsSA";
import { CreateProjectProfileSA } from "../Components/SuperAdminComponents/CreateProjectBySuperAdmin/CreateProjectProfileSA";
import { MainProjectCreateScreenSA } from "../Components/SuperAdminComponents/CreateProjectBySuperAdmin/MainProjectCreateScreenSA";
import { ProjectLayoutSA } from "../Components/SuperAdminComponents/CreateProjectBySuperAdmin/ProjectLayoutSA";
import { ProjectPricingSA } from "../Components/SuperAdminComponents/CreateProjectBySuperAdmin/ProjectPricingSA";
import { BuildingTemplateSA } from "../Components/SuperAdminComponents/CreateProjectBySuperAdmin/ProjectTemplates/BuildingTemplateSA";
import { FloorTemplateSA } from "../Components/SuperAdminComponents/CreateProjectBySuperAdmin/ProjectTemplates/FloorTempalteSA";
import { PricingTemplateSA } from "../Components/SuperAdminComponents/CreateProjectBySuperAdmin/ProjectTemplates/PricingTemplateSA";
import { UnitTemplateSA } from "../Components/SuperAdminComponents/CreateProjectBySuperAdmin/ProjectTemplates/UnitTemplateSA";
import { WingTemplateSA } from "../Components/SuperAdminComponents/CreateProjectBySuperAdmin/ProjectTemplates/WingTemplateSA";
import { Floor } from "../Components/SuperAdminComponents/CreateProjectBySuperAdmin/usedTemplates/Floor";
import { Unit } from "../Components/SuperAdminComponents/CreateProjectBySuperAdmin/usedTemplates/Unit";
import { Wing } from "../Components/SuperAdminComponents/CreateProjectBySuperAdmin/usedTemplates/Wing";
import { CSVconfigurationSA } from "../Components/SuperAdminComponents/CSVcreateSuperAdmin/CSVconfigurationSA";
import { CSVuploadSA } from "../Components/SuperAdminComponents/CSVcreateSuperAdmin/CSVuploadSA";
import { SuperAdminDashboard } from "../Components/SuperAdminComponents/Dashboard/SuperAdminDashboard";
import { AddMediaSA } from "../Components/SuperAdminComponents/MediaDirectory/AddMediaSA";
import { SAMediaUpload } from "../Components/SuperAdminComponents/MediaDirectory/SAMediaUpload";
import { SAProjectDashboard } from "../Components/SuperAdminComponents/ProjectDirectory/SAProjectDashboard";
import { BasicStructurSA } from "../Components/SuperAdminComponents/SidebarNavbar/BasicStructurSA";
import SuperAdminMember from "../Components/SuperAdminComponents/TeamDirectory/SuperAdminMember";
import SuperAdminMemberOverview from "../Components/SuperAdminComponents/TeamDirectory/SuperAdminMemberOverview";
import SuperAdminRoleOverview from "../Components/SuperAdminComponents/TeamDirectory/SuperAdminRoleOverview";
import SuperAdminRolePermission from "../Components/SuperAdminComponents/TeamDirectory/SuperAdminRolePermission";
import SuperAdminTeam from "../Components/SuperAdminComponents/TeamDirectory/SuperAdminTeam";
import { SAUnitDirectory } from "../Components/SuperAdminComponents/UnitDirectory/SAUnitDirectory";
import { BuyerRoutes } from "../PrivateRoutes/BuyerRouters";
import { ChannelPartnerRoutes } from "../PrivateRoutes/CannelPartnerRoutes";
import { SuperAdminRoutes } from "../PrivateRoutes/SuperAdminRoutes";
// import Dashboard from "../Components/BuyerComponents/Home/dashboard";
import { BuilderLandingPage } from "../Components/CommonPages/BuilderLandingPage/BuilderLandingPage";
import { BuyerLandingPage } from "../Components/CommonPages/BuyerLandingPage/BuyerLandingPage";
import CPLeadOverview from "../Components/ChannelPartner/leads/LeadOverview";
import CPInventory from "../Components/ChannelPartner/inventory/Inventory";
import { ConfirmProject2_FloorPlanAdd } from "../Components/BuilderComponents/ProjectDashboard/CreateProject/ConfirmProject2_FloorPlanAdd";
import { ConfirmProjec2_FloorPlanAddUnit } from "../Components/BuilderComponents/ProjectDashboard/CreateProject/ConfirmedProject2_FloorPlanAddUnit";
import { EditProjectUnit_MediaAdd } from "../Components/BuilderComponents/EditeProject/EditProjectUnit_MediaAdd";
import { EditProjectUnit_MediaAddUnit } from "../Components/BuilderComponents/EditeProject/EditProjectUnit_MediaAddUnit";
import { EditProjectUnit_FloorPlanAdd } from "../Components/BuilderComponents/EditeProject/EditProjectUnit_FloorPlanAdd";
import EditProjectUnit_FloorPlanAddToUnit from "../Components/BuilderComponents/EditeProject/EditProjectUnit_FloorPlanAddToUnit";
import EditMediaAdd from "../Components/AllDirectories/UnitDirectory/EditSingleUnit/EditMediaAdd";
import EditMediaAddUnit from "../Components/AllDirectories/UnitDirectory/EditSingleUnit/EditMediaAddUnit";
import EditFloorPlanAdd from "../Components/AllDirectories/UnitDirectory/EditSingleUnit/EditFloorPlanAdd";
import EditFloorPlanAddUnit from "../Components/AllDirectories/UnitDirectory/EditSingleUnit/EditFloorPlanAddUnit";
import RequirementForm from "../Components/ChannelPartner/Requirements/LeadsDirectory/RequirementForm";
import RequirementFormTemplate from "../Components/ChannelPartner/Requirements/LeadsDirectory/RequirementFormTemplate";
import Request_Directory_w_sb from "../Components/AllDirectories/RequestDirectory/Request_Directory_w_sb";

// import { ChannelLandingPage } from "../Components/CommonPages/ChannelLandingPage";
import { ChannelLandingPage } from "../Components/CommonPages/ChannelLandingPage/ChannelLandingPage";
import PdfOneMain from "../Components/PDF_Content/PdfOne/PdfOneMain";
import PdfTwoMain from "../Components/PDF_Content/PdfTwo/PdfTwoMain";
import BuyerDashboard from "../Components/BuyerComponents/Home/dashboard";
import { Overview } from "../Components/BuyerComponents/Units/components/overview";
import TicketDetailView from "../Components/BuyerComponents/tickets/TicketDetailView";
import ChannelPartnerView from "../Components/AllDirectories/ChannelPartnerDirectory/ChannelPartnerView";
import SuperAdminRolePermissionEdit from "../Components/SuperAdminComponents/TeamDirectory/SuperAdminRolePermissionEdit";
import BuilderMemberOverview from "../Components/BuilderComponents/MembersDirectory/BuilderMemberOverview";
import BuilderRolePermissionEdit from "../Components/BuilderComponents/MembersDirectory/BuilderRolePermissionEdit";
import BuilderRoleOverview from "../Components/BuilderComponents/MembersDirectory/BuilderRoleOverview";
import BuilderRolePermission from "../Components/BuilderComponents/MembersDirectory/BuilderRolePermission";
import { CPUnit_Directory } from "../Components/ChannelPartner/inventory/UnitDirectory/Unit_Directory_w_sb";
import { CPUnit_overview } from "../Components/ChannelPartner/inventory/UnitDirectory/Unit_overview_w_sb";
import CPassignedUnitView from "../Components/AllDirectories/Occupancy/CPassignedUnitView";
import { CPAddUnit } from "../Components/ChannelPartner/inventory/AddUnit";
import SetPassword from "../Components/ChannelPartner/signup/SetPassword";
import StandAloneUnitOverview from "../Components/ChannelPartner/inventory/UnitDirectory/StandAloneUnitOverview";

import CommonPagesAboutUs from "../Components/CommonPages/Shared/CommonPagesAboutUs";
import CommonPagesContact from "../Components/CommonPages/Shared/CommonPagesContact";
import FillRequirement from "../Components/ChannelPartner/Requirements/FillRequirement";
import ViewRequirement from "../Components/ChannelPartner/Requirements/viewRequirement";
import CpProjectUnitMainPage from "../Components/ChannelPartner/inventory/ProjectDirectory/CpProjectUnitMainPage";
import AddUnitToProject from "../Components/ChannelPartner/inventory/ProjectDirectory/AddUnitToProject";
import AssignProjectMainPage from "../Components/ChannelPartner/leads/LeadsDirectory/AssignProjectMainPage";
import EditUnitCp from "../Components/ChannelPartner/inventory/EditUnitCp";
import TeamMember from "../Components/ChannelPartner/Teams/TeamMember";
import RequirementFormTemp from "../Components/ChannelPartner/Requirements/LeadsDirectory/RequirementFormTemp";
import RequirementFormTempView from "../Components/ChannelPartner/Requirements/LeadsDirectory/RequirementFormTempView";
import AssignProjectMainClient from "../Components/ChannelPartner/clients/LeadsDirectory/AssignProjectMainClient";
import ClientOverview from "../Components/ChannelPartner/clients/LeadsDirectory/ClientOverview";
import ViewForms from "../Components/ChannelPartner/leads/LeadsDirectory/ViewForms";
import AssignProjectMainClientLead from "../Components/ChannelPartner/leads/LeadsDirectory/AssignProjectMainClientLead";
import InventoryMain from "../Components/ChannelPartner/inventory/LeadsDirectory/InventoryMain";
import FillAppointment from "../Components/ChannelPartner/appointments/FillAppointment";
import AssignProjectSelfUnit from "../Components/ChannelPartner/leads/LeadsDirectory/AssignProjectSelfUnit";
import DeassignProjectSelfUnit from "../Components/ChannelPartner/leads/LeadsDirectory/DeassignProjectSelfUnit";
import AssignProjectUnitClient from "../Components/ChannelPartner/clients/LeadsDirectory/AssignProjectUnitClient";
import DeassignProjectUnitClient from "../Components/ChannelPartner/clients/LeadsDirectory/DeassignProjectUnitClient";

export const AllRoutes = () => {
  return (
    <>
      <Routes>
        {/* for private routs */}
        {/* ============================================ ordinary Routs=========================================== */}
        <Route path="/" element={<CommonLandingPage />} />
        <Route path="/builder-landing-page" element={<BuilderLandingPage />} />
        <Route path="/channel-landing-page" element={<ChannelLandingPage />} />
        <Route path="/buyer-landing-page" element={<BuyerLandingPage />} />
        <Route path="/about-us-page" element={<CommonPagesAboutUs />} />
        <Route path="/contact-page" element={<CommonPagesContact />} />
        <Route
          path="/channelpartner-register"
          element={<SignupChannelPartner />}  
        />
        <Route path="/builder-register" element={<SignupBuilder />} />
        <Route path="/login" element={<LoginUser />} />
        {/* <Route path="/set-password" element={<SetupPassword/>}/>    //this is removed from figma */}
        <Route
          path="/resetpassword"
          name="activation_key"
          element={<SetupPassword />}
        />
        <Route path="/dashboard" element={<BuilderDashboard />} />

        {/* ===========================================Builder Routs ============================================= */}
        <Route element={<BuilderRoutes />}>
          {/* create project profile and projects */}
          <Route path="/createproject" element={<CreateProjectProfile />} />
          <Route
            path="/createproject/projectlayout"
            element={<ProjectLayout />}
          />
          <Route
            path="/createproject/projectpricing"
            element={<ProjectPricing />}
          />
          <Route
            path="/createproject/projectpricing/projectcreateoption"
            element={<CreateProjectOptions />}
          />
          <Route
            path="/createproject/projectpricing/projectcreateoption/configuration"
            element={<CreateProjectConfiguration />}
          />
          <Route
            path="/createproject/projectpricing/projectcreateoption/csvUpload"
            element={<CreateProjectCSVUpload />}
          />
          {/* create project templates */}
          <Route
            path="/createproject/projectpricing/projectcreateoption/mainscreen"
            element={<MainProjectCreateScreen />}
          />
          <Route path="/unittemplate" element={<UnitTemplate />} />
          <Route path="/floortemplate" element={<FloorTemplate />} />
          <Route path="/wingtemplate" element={<WingTemplate />} />
          <Route
            path="/buildingpatterntemplate"
            element={<BuildingPatternTemplate />}
          />
          <Route path="/newpricingtemplate" element={<NewPricingTemplate />} />

          {/* create project profile and projects */}
          <Route path="/createproject" element={<CreateProjectProfile />} />
          <Route
            path="/createproject/projectlayout"
            element={<ProjectLayout />}
          />
          <Route
            path="/createproject/projectpricing"
            element={<ProjectPricing />}
          />
          <Route
            path="/createproject/projectpricing/projectcreateoption"
            element={<CreateProjectOptions />}
          />
          <Route
            path="/createproject/projectpricing/projectcreateoption/configuration"
            element={<CreateProjectConfiguration />}
          />
          <Route
            path="/createproject/projectpricing/projectcreateoption/csvUpload"
            element={<CreateProjectCSVUpload />}
          />
          {/* create project templates */}
          <Route
            path="/createproject/projectpricing/projectcreateoption/mainscreen"
            element={<MainProjectCreateScreen />}
          />
          <Route path="/unittemplate" element={<UnitTemplate />} />
          <Route path="/floortemplate" element={<FloorTemplate />} />
          <Route path="/wingtemplate" element={<WingTemplate />} />
          <Route
            path="/buildingpatterntemplate"
            element={<BuildingPatternTemplate />}
          />
          <Route path="/newpricingtemplate" element={<NewPricingTemplate />} />

          {/* add media to the database */}
          <Route path="/createproject-addmedia" element={<Add_Media />} />
          <Route
            path="/createproject-upload/:id"
            element={<UploadMedia_To_project />}
          />

          {/* attach media to project and unit */}
          <Route
            path="/confirm-project/attachmediatounit"
            element={<AttachMediaToUnit />}
          />
          <Route
            path="/confirm-project/attachmediatounit/:id"
            element={<AttachMediaToUnit_Category />}
          />
          <Route
            path="/confirm-project/attachmediatoproject"
            element={<AttachMediaToProject />}
          />
          <Route
            path="/confirm-project/attachmediatoproject/:id"
            element={<AttachMediaToProject_Category />}
          />

          {/* confirm project */}
          <Route
            path="/confirm-project-overview"
            element={<ConfirmProject1 />}
          />
          <Route
            path="/confirm-project-building"
            element={<ConfirmProject2_Building />}
          />
          <Route
            path="/confirm-project-wings"
            element={<ConfirmProject2_Wings />}
          />
          <Route
            path="/confirm-project-wing-floors"
            element={<ConfirmProject2_wing_floors />}
          />
          <Route
            path="/confirm-project-flores"
            element={<ConfirmProject2_Flores />}
          />
          <Route
            path="/confirm-project-unit"
            element={<ConfirmProject2_Unit />}
          />

          <Route path="/confirmed-project" element={<ProjectOverview />} />

          <Route
            path="/confirm-project-wings-details"
            element={<ConfirmProject2_Wings_details />}
          />
          <Route
            path="/confirm-project-wing-floors-details"
            element={<ConfirmProject2_wing_floors_details />}
          />
          <Route
            path="/confirm-project-floors-details"
            element={<ConfirmProject2_floors_details />}
          />
          <Route
            path="/confirm-project-building-details"
            element={<ConfirmProject2_Building_details />}
          />

          <Route
            path="/confirm-project-building-add"
            element={<ConfirmProject2_Building_add />}
          />
          <Route
            path="/confirm-project-wings-add"
            element={<ConfirmProject2_Wings_add />}
          />
          <Route
            path="/confirm-project-wing_floors_add"
            element={<ConfirmProject2_wing_floors_add />}
          />
          <Route
            path="/confirm-project-floor-add"
            element={<ConfirmProject2_Floor_add />}
          />
          <Route
            path="/confirm-project-unit-add"
            element={<ConfirmProject2_Unit_add />}
          />

          <Route
            path="/confirm-project-wings-create"
            element={<ConfirmProject2_Wings_create />}
          />
          <Route
            path="/confirm-project-wing-floors-create"
            element={<ConfirmProject2_wing_floors_create />}
          />
          <Route
            path="/confirm-project-floor-create"
            element={<ConfirmProject2_Floor_create />}
          />
          <Route
            path="/confirm-project-unit-create"
            element={<ConfirmProject2_Unit_create />}
          />

          <Route
            path="/confirm-project-bedroomdetails"
            element={<ConfirmProject_BedroomDetails />}
          />
          <Route
            path="/confirm-project-bathroomdetails"
            element={<ConfirmProject_BathroomDetails />}
          />
          <Route
            path="/confirm-project-balconydetails"
            element={<ConfirmProject_BolconyDetails />}
          />
          <Route
            path="/confirm-project-pricingdetails"
            element={<ConfirmProject_PricingDetails />}
          />
          <Route
            path="/confirm-project-floorplandetails"
            element={<ConfirmProject_FloorPlanDetails />}
          />
          <Route
            path="/confirm-project-mediadetails"
            element={<ConfirmProject_MediaDetails />}
          />
          <Route
            path="/confirm-project-media-add"
            element={<ConfirmProject2_MediaAdd />}
          />
          <Route
            path="/confirm-project-floorplan-add"
            element={<ConfirmProject2_FloorPlanAdd />}
          />
          <Route
            path="/confirm-project2/media-add-unit/:id"
            element={<ConfirmProjec2_MediaAddUnit />}
          />
          <Route
            path="/confirm-project2/floorplan-add-unit/:id"
            element={<ConfirmProjec2_FloorPlanAddUnit />}
          />
          {/* create project profile and projects */}
          <Route path="/createproject" element={<CreateProjectProfile />} />
          <Route
            path="/createproject/projectlayout"
            element={<ProjectLayout />}
          />
          <Route
            path="/createproject/projectpricing"
            element={<ProjectPricing />}
          />
          <Route
            path="/createproject/projectpricing/projectcreateoption"
            element={<CreateProjectOptions />}
          />
          <Route
            path="/createproject/projectpricing/projectcreateoption/configuration"
            element={<CreateProjectConfiguration />}
          />
          <Route
            path="/createproject/projectpricing/projectcreateoption/csvUpload"
            element={<CreateProjectCSVUpload />}
          />
          {/* create project templates */}
          <Route
            path="/createproject/projectpricing/projectcreateoption/mainscreen"
            element={<MainProjectCreateScreen />}
          />
          <Route path="/unittemplate" element={<UnitTemplate />} />
          <Route path="/floortemplate" element={<FloorTemplate />} />
          <Route path="/wingtemplate" element={<WingTemplate />} />
          <Route
            path="/buildingpatterntemplate"
            element={<BuildingPatternTemplate />}
          />
          <Route path="/newpricingtemplate" element={<NewPricingTemplate />} />

          {/* add media to the database */}
          <Route path="/createproject-addmedia" element={<Add_Media />} />
          <Route
            path="/createproject-upload/:id"
            element={<UploadMedia_To_project />}
          />

          {/* attach media to project and unit */}
          <Route
            path="/confirm-project/attachmediatounit"
            element={<AttachMediaToUnit />}
          />
          <Route
            path="/confirm-project/attachmediatounit/:id"
            element={<AttachMediaToUnit_Category />}
          />
          <Route
            path="/confirm-project/attachmediatoproject"
            element={<AttachMediaToProject />}
          />
          <Route
            path="/confirm-project/attachmediatoproject/:id"
            element={<AttachMediaToProject_Category />}
          />

          {/* confirm project */}
          <Route
            path="/confirm-project-overview"
            element={<ConfirmProject1 />}
          />
          <Route
            path="/confirm-project-building"
            element={<ConfirmProject2_Building />}
          />
          <Route
            path="/confirm-project-wings"
            element={<ConfirmProject2_Wings />}
          />
          <Route
            path="/confirm-project-wing-floors"
            element={<ConfirmProject2_wing_floors />}
          />
          <Route
            path="/confirm-project-flores"
            element={<ConfirmProject2_Flores />}
          />
          <Route
            path="/confirm-project-unit"
            element={<ConfirmProject2_Unit />}
          />

          <Route path="/confirmed-project" element={<ProjectOverview />} />

          <Route
            path="/confirm-project-wings-details"
            element={<ConfirmProject2_Wings_details />}
          />
          <Route
            path="/confirm-project-wing-floors-details"
            element={<ConfirmProject2_wing_floors_details />}
          />
          <Route
            path="/confirm-project-floors-details"
            element={<ConfirmProject2_floors_details />}
          />
          <Route
            path="/confirm-project-building-details"
            element={<ConfirmProject2_Building_details />}
          />

          <Route
            path="/confirm-project-building-add"
            element={<ConfirmProject2_Building_add />}
          />
          <Route
            path="/confirm-project-wings-add"
            element={<ConfirmProject2_Wings_add />}
          />
          <Route
            path="/confirm-project-wing_floors_add"
            element={<ConfirmProject2_wing_floors_add />}
          />
          <Route
            path="/confirm-project-floor-add"
            element={<ConfirmProject2_Floor_add />}
          />
          <Route
            path="/confirm-project-unit-add"
            element={<ConfirmProject2_Unit_add />}
          />

          <Route
            path="/confirm-project-wings-create"
            element={<ConfirmProject2_Wings_create />}
          />
          <Route
            path="/confirm-project-wing-floors-create"
            element={<ConfirmProject2_wing_floors_create />}
          />
          <Route
            path="/confirm-project-floor-create"
            element={<ConfirmProject2_Floor_create />}
          />
          <Route
            path="/confirm-project-unit-create"
            element={<ConfirmProject2_Unit_create />}
          />

          <Route
            path="/confirm-project-bedroomdetails"
            element={<ConfirmProject_BedroomDetails />}
          />
          <Route
            path="/confirm-project-bathroomdetails"
            element={<ConfirmProject_BathroomDetails />}
          />
          <Route
            path="/confirm-project-balconydetails"
            element={<ConfirmProject_BolconyDetails />}
          />
          <Route
            path="/confirm-project-pricingdetails"
            element={<ConfirmProject_PricingDetails />}
          />
          <Route
            path="/confirm-project-floorplandetails"
            element={<ConfirmProject_FloorPlanDetails />}
          />
          <Route
            path="/confirm-project-mediadetails"
            element={<ConfirmProject_MediaDetails />}
          />

          {/* sales dashboard All Directories */}
          <Route path="/projectdirectory" element={<PDmainPage />} />
          <Route path="/leadsdirectory" element={<LDmainPage />} />
          <Route path="/leadsoverview" element={<LDoverviewPage />} />
          <Route path="/teamsdirectory" element={<TDmainPgae />} />
          <Route path="/membersdirectory/:id" element={<MDmainPage />} />
          <Route path="/buyersdirectory" element={<BDmainPage />} />
          <Route path="/channelpartnerdirectory" element={<CPDmainPage />} />
          <Route path="/channelpartneroverview/:id" element={<CPOmainPage />} />
          <Route path="/organizationdirectory" element={<ODmainPage />} />
          <Route
            path="/builder-team-memberview"
            element={<BuilderMemberOverview />}
          />
          <Route
            path="/builder-role-permission-edit"
            element={<BuilderRolePermissionEdit />}
          />
          <Route
            path="/builder-role-overview"
            element={<BuilderRoleOverview />}
          />
          <Route
            path="/builder-role-permission"
            element={<BuilderRolePermission />}
          />

          {/*Project dashboard all directories*/}
          {/* <Route path="/projectdashboardd" element={<ProjectDashboardSidebar/>}/> */}
          <Route path="/projectdashboard" element={<ProjectDashboard />} />
          <Route
            path="/unit-directory-w-sb"
            element={<Unit_Directory_w_sb />}
          />
          <Route
            path="/unit-directory-w-sb/:id"
            element={<Unit_overview_w_sb />}
          />
          <Route
            path="/cannelpartner-directory-w-sb"
            element={<ChannelPartener_Directory_w_sb />}
          />
          <Route
            path="/lead-directory-w-sb"
            element={<Lead_Directory_w_sb />}
          />
          <Route
            path="/buyer-directory-w-sb"
            element={<Buyers_Directory_w_sb />}
          />
          <Route
            path="/quotation-directory-w-sb"
            element={<Quotation_Directory_w_sb />}
          />
          <Route
            path="/occupancy-directory-w-sb"
            element={<Occupancy_Directory_w_sb />}
          />
          <Route
            path="/project-amenities-w-sb"
            element={<ProjectAmenities />}
          />
          <Route
            path="/project-cp-assignunit/:id"
            element={<CPassignedUnitView />}
          />

          <Route path="/teamsdirectory" element={<TDmainPgae />} />
          <Route path="/membersdirectory/:id" element={<MDmainPage />} />
          <Route path="/buyersdirectory" element={<BDmainPage />} />
          <Route path="/channelpartnerdirectory" element={<CPDmainPage />} />
          <Route path="/channelpartneroverview/:id" element={<CPOmainPage />} />
          <Route path="/projectdirectory" element={<PDmainPage />} />
          <Route path="/organizationdirectory" element={<ODmainPage />} />
          <Route path="/leadsoverview" element={<LDoverviewPage />} />
          {/* <Route path="/unitdirectory" element={<UDmainPage />} /> */}
          {/* <Route path="/unitoverview/:id" element={<UOmainPage />} />  */}
          {/* <Route path="/qddirectory" element={<QDmainPage />} /> */}

          {/*Project dashboard all directories*/}
          {/* <Route path="/projectdashboardd" element={<ProjectDashboardSidebar/>}/> */}
          <Route path="/projectdashboard" element={<ProjectDashboard />} />
          <Route
            path="/unit-directory-w-sb"
            element={<Unit_Directory_w_sb />}
          />
          <Route
            path="/unit-directory-w-sb/:id"
            element={<Unit_overview_w_sb />}
          />
          <Route
            path="/cannelpartner-directory-w-sb"
            element={<ChannelPartener_Directory_w_sb />}
          />
          <Route
            path="/cannelpartner-project-overview/:id"
            element={<ChannelPartnerView />}
          />
          <Route
            path="/lead-directory-w-sb"
            element={<Lead_Directory_w_sb />}
          />
          <Route
            path="/buyer-directory-w-sb"
            element={<Buyers_Directory_w_sb />}
          />
          <Route
            path="/quotation-directory-w-sb"
            element={<Quotation_Directory_w_sb />}
          />
          <Route
            path="/occupancy-directory-w-sb"
            element={<Occupancy_Directory_w_sb />}
          />
          <Route
            path="/project-amenities-w-sb"
            element={<ProjectAmenities />}
          />
          <Route
            path="/buyer-request-w-sb"
            element={<Request_Directory_w_sb />}
          />

          {/* builder Edit project pages routs*/}
          <Route
            path="/builder-edit-project-building"
            element={<EditProjectBuilding />}
          />
          <Route
            path="/builder-edit-project-building-add"
            element={<EditProjectBuilding_Add />}
          />
          <Route
            path="/builder-edit-project-building-details"
            element={<EditProjectBuilding_Details />}
          />

          <Route
            path="/builder-edit-project-wing"
            element={<EditProjectWing />}
          />
          <Route
            path="/builder-edit-project-wing-add"
            element={<EditProjectWing_Add />}
          />
          <Route
            path="/builder-edit-project-wing-create"
            element={<EditProjectWing_Create />}
          />
          <Route
            path="/builder-edit-project-wing-details"
            element={<EditProjectWing_Details />}
          />

          <Route
            path="/builder-edit-project-wing"
            element={<EditProjectWing />}
          />
          <Route
            path="/builder-edit-project-wing-floor"
            element={<EditProjectWing_Floor />}
          />
          <Route
            path="/builder-edit-project-wing-floor-add"
            element={<EditProjectWing_Floor_Add />}
          />
          <Route
            path="/builder-edit-project-wing-floor-create"
            element={<EditProjectWing_Floor_Create />}
          />
          <Route
            path="/builder-edit-project-wing-floor-details"
            element={<EditProjectWing_Floor_Details />}
          />

          <Route
            path="/builder-edit-project-floor"
            element={<EditProjectFloor />}
          />
          <Route
            path="/builder-edit-project-floor-add"
            element={<EditProjectFloor_Add />}
          />
          <Route
            path="/builder-edit-project-floor-create"
            element={<EditProjectFloor_Create />}
          />
          <Route
            path="/builder-edit-project-floor-details"
            element={<EditProjectFloor_Details />}
          />

          <Route
            path="/builder-edit-project-unit"
            element={<EditProjectUnit />}
          />
          <Route
            path="/builder-edit-project-unit-add"
            element={<EditProjectUnit_Add />}
          />
          <Route
            path="/builder-edit-project-unit-create"
            element={<EditProjectUnit_Create />}
          />
          <Route
            path="/builder-edit-project-unit-bedroomdetails"
            element={<EditProjectUnit_Bedroom />}
          />
          <Route
            path="/builder-edit-project-unit-bathroomdetails"
            element={<EditProjectUnit_Bathroom />}
          />
          <Route
            path="/builder-edit-project-unit-balconydetails"
            element={<EditProjectUnit_Balcony />}
          />
          <Route
            path="/builder-edit-project-unit-pricingdetails"
            element={<EditProjectUnit_Pricing />}
          />
          <Route
            path="/builder-edit-project-unit-floorplandetails"
            element={<EditProjectUnit_FloorPlans />}
          />
          <Route
            path="/builder-edit-project-unit-floorplan-add"
            element={<EditProjectUnit_FloorPlanAdd />}
          />
          <Route
            path="/builder-edit-project-unitfloorplan-add/:id"
            element={<EditProjectUnit_FloorPlanAddToUnit />}
          />
          <Route
            path="/builder-edit-project-unit-mediadetails"
            element={<EditProjectUnit_Media />}
          />
          <Route
            path="/builder-edit-project-unit-media-add"
            element={<EditProjectUnit_MediaAdd />}
          />
          <Route
            path="/builder-edit-project-unitmedia-add/:id"
            element={<EditProjectUnit_MediaAddUnit />}
          />

          {/* single unit edit */}
          <Route
            path="/unit-directory-w-sb/edit"
            element={<EditSingleUnit />}
          />
          <Route
            path="/unit-directory-w-sb/edit-bedroomdetails"
            element={<EditBedroom />}
          />
          <Route
            path="/unit-directory-w-sb/edit-bathroomdetails"
            element={<EditBathroom />}
          />
          <Route
            path="/unit-directory-w-sb/edit-balconydetails"
            element={<EditBalcony />}
          />
          <Route
            path="/unit-directory-w-sb/edit-pricingdetails"
            element={<EditPricing />}
          />
          <Route
            path="/unit-directory-w-sb/edit-floorplandetails"
            element={<EditFloorPlan />}
          />
          <Route
            path="/unit-directory-w-sb/edit-floorplan-add"
            element={<EditFloorPlanAdd />}
          />
          <Route
            path="/unit-directory-w-sb/edit-floorplan-add-Unit/:id"
            element={<EditFloorPlanAddUnit />}
          />
          <Route
            path="/unit-directory-w-sb/edit-mediadetails"
            element={<EditMedia />}
          />
          <Route
            path="/unit-directory-w-sb/edit-media-add"
            element={<EditMediaAdd />}
          />
          <Route
            path="/unit-directory-w-sb/edit-media-add-Unit/:id"
            element={<EditMediaAddUnit />}
          />
        </Route>

        {/* ===========================================Super Admin Routs ============================================= */}
        <Route element={<SuperAdminRoutes />}>
          {/* Super Admin Dashboard */}
          <Route path="/superadmin-builder" element={<SuperAdminBuilder />} />
          <Route
            path="/superadmin-builderedit"
            element={<SuperAdminBuilderEdit />}
          />
          <Route path="/superadmin-org" element={<SuperAdminOrg />} />
          <Route path="/superadmin-project" element={<SuperAdminProject />} />
          <Route path="/superadmin-unit" element={<SuperAdminUnit />} />
          <Route path="/superadmin-team" element={<SuperAdminTeam />} />
          <Route
            path="/superadmin-team-member"
            element={<SuperAdminMember />}
          />
          <Route
            path="/superadmin-builder-overview"
            element={<BuilderOverview />}
          />
          <Route
            path="/superadmin-project-amenities"
            element={<SuperAdminAmenities />}
          />
          <Route
            path="/superadmin-team-memberview"
            element={<SuperAdminMemberOverview />}
          />
          <Route
            path="/superadmin-role-overview"
            element={<SuperAdminRoleOverview />}
          />
          <Route
            path="/superadmin-role-permission"
            element={<SuperAdminRolePermission />}
          />
          <Route
            path="/superadmin-role-permission-edit"
            element={<SuperAdminRolePermissionEdit />}
          />
          {/* Super Admin Dashboard */}
          <Route path="/superadmin-builder" element={<SuperAdminBuilder />} />
          <Route
            path="/superadmin-builderedit"
            element={<SuperAdminBuilderEdit />}
          />
          <Route path="/superadmin-org" element={<SuperAdminOrg />} />
          <Route path="/superadmin-project" element={<SuperAdminProject />} />
          <Route path="/superadmin-unit" element={<SuperAdminUnit />} />
          <Route path="/superadmin-team" element={<SuperAdminTeam />} />
          <Route
            path="/superadmin-team-member"
            element={<SuperAdminMember />}
          />
          <Route
            path="/superadmin-builder-overview"
            element={<BuilderOverview />}
          />
          <Route
            path="/superadmin-project-amenities"
            element={<SuperAdminAmenities />}
          />
          <Route
            path="/superadmin-team-memberview"
            element={<SuperAdminMemberOverview />}
          />
          <Route
            path="/superadmin-role-overview"
            element={<SuperAdminRoleOverview />}
          />
          <Route
            path="/superadmin-role-permission"
            element={<SuperAdminRolePermission />}
          />

          {/* all directories with dashboard*/}
          {/* <Route path="/projectdashboardd" element={<ProjectDashboardSidebar/>}/> */}
          <Route path="/projectdashboard" element={<ProjectDashboard />} />
          <Route
            path="/unit-directory-w-sb"
            element={<Unit_Directory_w_sb />}
          />
          <Route
            path="/unit-directory-w-sb/:id"
            element={<Unit_overview_w_sb />}
          />
          <Route
            path="/cannelpartner-directory-w-sb"
            element={<ChannelPartener_Directory_w_sb />}
          />
          <Route
            path="/lead-directory-w-sb"
            element={<Lead_Directory_w_sb />}
          />
          <Route
            path="/buyer-directory-w-sb"
            element={<Buyers_Directory_w_sb />}
          />
          <Route
            path="/quotation-directory-w-sb"
            element={<Quotation_Directory_w_sb />}
          />
          <Route
            path="/occupancy-directory-w-sb"
            element={<Occupancy_Directory_w_sb />}
          />
          <Route
            path="/project-amenities-w-sb"
            element={<ProjectAmenities />}
          />

          {/* Notifications  */}

          <Route path="/notifications" element={<NotificationSales />} />
          <Route path="/notification" element={<NotificationProject />} />

          {/* super admin routes */}
          <Route
            path="/super-admin-dashboard"
            element={<SuperAdminDashboard />}
          />
          <Route path="/SAsidebar" element={<BasicStructurSA />} />
          {/* create project by superAdmin routes */}
          <Route
            path="/super-admin-create-project-profile"
            element={<CreateProjectProfileSA />}
          />
          <Route
            path="/super-admin-create-project-layout"
            element={<ProjectLayoutSA />}
          />
          <Route
            path="/super-admin-create-project-projectpricing"
            element={<ProjectPricingSA />}
          />
          <Route
            path="/super-admin-create-project-options"
            element={<CreateProjectOptionsSA />}
          />
          <Route
            path="/super-admin-create-project-mainscreen"
            element={<MainProjectCreateScreenSA />}
          />
          {/* super admin project create by csv */}
          <Route
            path="/super-admin-create-project-csvconfiguration"
            element={<CSVconfigurationSA />}
          />
          <Route
            path="/super-admin-create-project-csvupload"
            element={<CSVuploadSA />}
          />
          {/* super admin project templates */}
          <Route
            path="/super-admin-create-project-unit-template"
            element={<UnitTemplateSA />}
          />
          <Route
            path="/super-admin-create-project-floor-template"
            element={<FloorTemplateSA />}
          />
          <Route
            path="/super-admin-create-project-wing-template"
            element={<WingTemplateSA />}
          />
          <Route
            path="/super-admin-create-project-building-template"
            element={<BuildingTemplateSA />}
          />
          <Route
            path="/super-admin-create-project-pricing-template"
            element={<PricingTemplateSA />}
          />
          <Route
            path="/super-admin-confirm-project"
            element={<ConfirmProjectSA />}
          />
          {/* super admin confirm project */}
          <Route
            path="/super-admin-confirm-project-building"
            element={<ConfirmProject_BuildingSA />}
          />
          <Route
            path="/super-admin-confirm-project-building-add"
            element={<ConfirmProject_Building_AddSA />}
          />
          <Route
            path="/super-admin-confirm-project-building-add-create-floor"
            element={<Floor />}
          />
          <Route
            path="/super-admin-confirm-project-building-add-create-wing"
            element={<Wing />}
          />
          <Route
            path="/super-admin-confirm-project-building-add-create-unit"
            element={<Unit />}
          />
          <Route
            path="/super-admin-confirm-project-building-details"
            element={<ConfirmProject_Building_DetailsSA />}
          />

          <Route
            path="/super-admin-confirm-project-floor"
            element={<ConfirmProject_FloorSA />}
          />
          <Route
            path="/super-admin-confirm-project-floor-add"
            element={<ConfirmProject_Floor_AddSA />}
          />
          <Route
            path="/super-admin-confirm-project-floor-create"
            element={<ConfirmProject_Floor_CreateSA />}
          />
          <Route
            path="/super-admin-confirm-project-floor-details"
            element={<ConfirmProject_Floor_DetailsSA />}
          />

          <Route
            path="/super-admin-confirm-project-wing"
            element={<ConfirmProject_WingSA />}
          />
          <Route
            path="/super-admin-confirm-project-wing-add"
            element={<ConfirmProject_Wing_AddSA />}
          />
          <Route
            path="/super-admin-confirm-project-wing-create"
            element={<ConfirmProject_Wing_CreateSA />}
          />
          <Route
            path="/super-admin-confirm-project-wing-details"
            element={<ConfirmProject_Wing_DetailsSA />}
          />

          <Route
            path="/super-admin-confirm-project-wing-floor"
            element={<ConfirmProject_Wing_FloorSA />}
          />
          <Route
            path="/super-admin-confirm-project-wing-floor-add"
            element={<ConfirmProject_Wing_Floor_AddSA />}
          />
          <Route
            path="/super-admin-confirm-project-wing-floor-create"
            element={<ConfirmProject_Wing_Floor_CreateSA />}
          />
          <Route
            path="/super-admin-confirm-project-wing-floor-details"
            element={<ConfirmProject_Wing_Floor_DetailsSA />}
          />

          <Route
            path="/super-admin-confirm-project-Unit"
            element={<ConfirmProject_UnitSA />}
          />
          <Route
            path="/super-admin-confirm-project-Unit-bedroomdetails"
            element={<ConfirmProject_BedroomDetailsSA />}
          />
          <Route
            path="/super-admin-confirm-project-Unit-bathroomdetails"
            element={<ConfirmProject_BathroomDetailsSA />}
          />
          <Route
            path="/super-admin-confirm-project-Unit-balconydetails"
            element={<ConfirmProject_BalconyDetailsSA />}
          />
          <Route
            path="/super-admin-confirm-project-Unit-pricingdetails"
            element={<ConfirmProject_PricingDetailsSA />}
          />
          <Route
            path="/super-admin-confirm-project-Unit-floorplandetails"
            element={<ConfirmProject_FloorPlan_DetailsSA />}
          />
          <Route
            path="/super-admin-confirm-project-Unit-mediadetails"
            element={<ConfirmProject_Media_DetailsSA />}
          />

          {/* attache media to unit and project */}
          <Route
            path="/super-admin-attachmedia-to-unit"
            element={<AttachMediaTo_UnitSA />}
          />
          <Route
            path="/super-admin-attachmedia-to-unit/:id"
            element={<AttachMediaTo_Unit_CategorySA />}
          />
          <Route
            path="/super-admin-attachmedia-to-project"
            element={<AttachMediaTo_ProjectSA />}
          />
          <Route
            path="/super-admin-attachmedia-to-project/:id"
            element={<AttachMediaTo_Project_CategorySA />}
          />

          {/* super admin project Dashboard */}
          <Route
            path="/super-admin-project-dashboard"
            element={<SAProjectDashboard />}
          />
          <Route
            path="/super-admin-unit-directory"
            element={<SAUnitDirectory />}
          />
          <Route path="/super-admin-media-directory" element={<AddMediaSA />} />
          <Route
            path="/super-admin-media-upload/:id"
            element={<SAMediaUpload />}
          />
        </Route>

        <Route
          path="/channelpartner/setPassword/:cp_id"
          element={<SetPassword />}
        ></Route>
        <Route
          path="/channelpartner/requirements-form/:id/fill"
          element={<FillRequirement />}
        />
        <Route
          path="/channelpartner/appointments/:id/fill"
          element={<FillAppointment />}
        />

        {/* ===========================================Channel Partner Routs ============================================= */}
        <Route element={<ChannelPartnerRoutes />}>
          <Route path="/channelpartner/dashboard" element={<CPDashboard />} />
          <Route path="/channelpartner/leads" element={<CPLeads />} />
          {/* <Route path="/channelpartner/leads/:id/" element={<CPLeadOverview />} /> */}
          <Route
            path="/channelpartner/leads/:id/edit"
            element={<CPLeadOverview edit={true} />}
          />
          <Route
            path="/channelpartner/client/:id"
            element={<ClientOverview />}
          />
          {/* <Route path="/channelpartner/inventory" element={<CPInventory />} /> */}
          <Route path="/channelpartner/inventory" element={<InventoryMain />} />
          <Route
            path="/channelpartner/details"
            element={<CPInventory details />}
          />
          <Route
            path="/channelpartner/unit/:id"
            element={<CPUnit_overview />}
          />
          <Route
            path="/channelpartner/project/unit/"
            element={<CpProjectUnitMainPage />}
          />
          <Route
            path="/Standalone-channelpartner/unit/:id"
            element={<StandAloneUnitOverview />}
          />
          <Route
            path="/channelpartner/appointment"
            element={<CPAppointments origin={"Appointments"} />}
          />
          <Route path="/channelpartner/unit/add/:id" element={<CPAddUnit />} />
          <Route
            path="/channelpartner/unit/edit/:id"
            element={<EditUnitCp />}
          />
          <Route
            path="/channelpartner/project/unit/add/:id"
            element={<AddUnitToProject />}
          />
          <Route path="/channelpartner/clients" element={<CPClients />} />
          <Route
            path="/channelpartner/requirements"
            element={<CPRequirements />}
          />
          <Route path="/channelpartner/teams" element={<CPTeamsjs />} />
          <Route path="/channelpartner/teams/member" element={<TeamMember />} />
          <Route
            path="/channelpartner/requirements-form-template"
            element={<RequirementFormTemplate />}
          />
          <Route
            path="/channelpartner/requirements-form-template-js"
            element={<RequirementFormTemp />}
          />
          <Route
            path="/channelpartner/requirements-form"
            element={<RequirementForm />}
          />
          <Route
            path="/channelpartner/requirements-form/:id"
            element={<RequirementFormTempView />}
          />
          <Route
            path="/channelpartner/requirements-form/:id/view"
            element={<ViewRequirement />}
          />
          <Route
            path="/channelpartner/lead/project/assign/:id"
            element={<AssignProjectMainPage />}
          />
          <Route
            path="/channelpartner/leadclient/project/assign/:id"
            element={<AssignProjectMainClientLead />}
          />
          <Route
            path="/channelpartner/client/project/assign/:id"
            element={<AssignProjectMainClient />}
          />
          <Route
            path="/channelpartner/cpform/view/:id"
            element={<ViewForms />}
          />
          <Route
            path="/channelpartner/lead/project/assign/unit/:id"
            element={<AssignProjectSelfUnit />}
          />
          <Route
            path="/channelpartner/lead/project/deassign/unit/:id"
            element={<DeassignProjectSelfUnit />}
          />
          <Route
            path="/channelpartner/client/project/assign/unit/:id"
            element={<AssignProjectUnitClient/>}
          />
          <Route
            path="/channelpartner/client/project/deassign/unit/:id"
            element={<DeassignProjectUnitClient/>}
          />
        </Route>

        {/* Notifications  */}
        {/* Notifications  */}

        <Route path="/notifications" element={<NotificationSales />} />
        <Route path="/notification" element={<NotificationProject />} />

        <Route path="/AllFloorPlans" element={<AllFloorPlans />} />

        <Route
          path="/builder-edit-project-unit-add"
          element={<EditProjectUnit_Add />}
        />
        <Route
          path="/builder-edit-project-unit-create"
          element={<EditProjectUnit_Create />}
        />
        <Route
          path="/builder-edit-project-unit-bedroomdetails"
          element={<EditProjectUnit_Bedroom />}
        />
        <Route
          path="/builder-edit-project-unit-bathroomdetails"
          element={<EditProjectUnit_Bathroom />}
        />
        <Route
          path="/builder-edit-project-unit-balconydetails"
          element={<EditProjectUnit_Balcony />}
        />
        <Route
          path="/builder-edit-project-unit-pricingdetails"
          element={<EditProjectUnit_Pricing />}
        />
        <Route
          path="/builder-edit-project-unit-floorplandetails"
          element={<EditProjectUnit_FloorPlans />}
        />
        <Route
          path="/builder-edit-project-unit-mediadetails"
          element={<EditProjectUnit_Media />}
        />

        {/* single unit edit */}
        <Route path="/unit-directory-w-sb/edit" element={<EditSingleUnit />} />
        <Route
          path="/unit-directory-w-sb/edit-bedroomdetails"
          element={<EditBedroom />}
        />
        <Route
          path="/unit-directory-w-sb/edit-bathroomdetails"
          element={<EditBathroom />}
        />
        <Route
          path="/unit-directory-w-sb/edit-balconydetails"
          element={<EditBalcony />}
        />
        <Route
          path="/unit-directory-w-sb/edit-pricingdetails"
          element={<EditPricing />}
        />
        <Route
          path="/unit-directory-w-sb/edit-floorplandetails"
          element={<EditFloorPlan />}
        />
        <Route
          path="/unit-directory-w-sb/edit-mediadetails"
          element={<EditMedia />}
        />

        <Route path="/AllFloorPlans" element={<AllFloorPlans />} />

        <Route element={<BuyerRoutes />}>
          <Route path="/buyer/dashboard" element={<BuyerDashboard />} />
          <Route path="/buyer/projects" element={<Projects />} />
          <Route path="/buyer/units" element={<Units />} />
          <Route path="/buyer/units/:id" element={<Overview />} />
          <Route path="/buyer/finances" element={<Finances />} />
          <Route path="/buyer/documents" element={<Documents />} />
          <Route path="/buyer/tickets" element={<Tickets />} />
          <Route
            path="/buyer/tickets/:id"
            element={<TicketDetailView inputdisabled={true} />}
          />
          <Route
            path="/buyer/tickets/:id/edit"
            element={<TicketDetailView inputdisabled={false} />}
          />
        </Route>

        {/* Quotation PDF generator */}
        <Route path="/pdf-for-quotation" element={<PdfTwoMain />} />
        <Route path="/pdf-for-buyerfile" element={<PdfOneMain />} />
      </Routes>
    </>
  );
};
