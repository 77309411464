import { AuthService } from "../../../../../context/buyer/services/auth";
import { Apiaddress } from "../../../../../utils/consts";

class CPprojects{
    Auth:AuthService;
    url = Apiaddress+"/api/projects/getprojectsusingtoken/"; 

    constructor(e:AuthService){
        this.Auth =e;
    }
    async getListOfProjects(){
        return (await (await this.Auth.makeAuthenticatedGetRequest(this.url)).json())['projects']
    }

    
}
export {CPprojects}