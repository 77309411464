import { Box, Button, Stack, Typography } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import BuilderWelcomeIMG from '../../Imgs/builder_welcome.png';
import userContext from "../../../context/profile/context";
import { useContext } from "react";
import { Link } from "react-router-dom";
import PhoneIcon from '@mui/icons-material/Phone';
import MailIcon from '@mui/icons-material/Mail';
import SystemUpdateAltIcon from '@mui/icons-material/SystemUpdateAlt';
import '../Components/sideBar/Sidebar.css';

function CPDashboardLock({open}) {
    const UC = useContext(userContext);
    return (
      <Dialog
      open={open}
      
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      sx={{borderRadius:"20px" }}
    >
      <DialogContent
        sx={{  width: "400px", textAlign:"center"}}
      >
        <Stack sx={{marginTop:"0px",marginLeft:"0px"}}>
          <Typography sx={{marginTop:"0px",fontWeight: "600",fontSize: "13px",display:"flex",justifyContent:"",alignItems:"center",color:"black",fontFamily: "Product Sans"}}><PhoneIcon sx={{marginRight:"5px",fontSize:"20px"}}/>
          +91 7304880751
          </Typography>
          <DialogContentText sx={{marginTop:"5px",fontWeight: "600",fontSize: "14px",display:"flex",justifyContent:"",alignItems:"center",color:"black",fontFamily: "Product Sans"}}><MailIcon sx={{marginRight:"5px",fontSize:"20px"}}/>
          support@envisionnext.in
          </DialogContentText>
        </Stack> 
         <div className="lockedImage"
          // style={{width:"98%",margin:"auto", borderRadius:"10px"}}
          > 
           {/* <img style={{width:"100%"}} src={BuilderWelcomeIMG}/> */}
         </div>
           <h3>Welcome to EnvisionNext</h3>
        <DialogContentText id="alert-dialog-description">
        We are glad to have you onboard. Our team will contact you shortly.
        </DialogContentText>
        {/* <DialogContentText sx={{marginTop:"20px",fontWeight: "400",fontSize: "14px"}}>
        99261238123
        </DialogContentText> */}
        <Link to="/">
        <Button variant="contained" 
        onClick={()=>{UC.logOut()}}
        sx={{background:"#0a2260",boxShadow:"0px 2px 6px rgba(19, 18, 66, 0.07)",borderRadius:"4px",margin:"20px 0px",padding:"10px 20px","&:hover":{background:"#0a2260"}}}>
          <Box sx={{ textTransform: "capitalize",margin:"0px 7px"}}>Logout</Box>
        </Button>
        </Link> 
      </DialogContent>
    </Dialog>
    )
}

export default CPDashboardLock