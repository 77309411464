import axios from "axios";
import { Apiaddress, basicheaders } from "../../utils/consts";

const vaccencyStatus= async (orgid:number) => {
    const url = Apiaddress + "/api/projects/vaccencystatus/";
    const re = await axios.post(
        url,
        {
            headers: basicheaders,
            orglist:[{"orgid":orgid}]
        }
    )
    // console.log(re.data)
    return re.data;}
export {vaccencyStatus}