import { Box, Button, Input, Typography } from "@mui/material"
import { Stack } from "@mui/system"
import DoneIcon from '@mui/icons-material/Done';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import { Link, useLocation, useNavigate } from "react-router-dom";
import SearchIcon from '@mui/icons-material/Search';

import InputAdornment from '@mui/material/InputAdornment';
import OutlinedInput from '@mui/material/OutlinedInput';
import Checkbox from '@mui/material/Checkbox';
import { useContext, useEffect, useState } from "react";  

import axios from 'axios';
import { Apiaddress } from '../../../../utils/consts';
import { ProjectDashboardSidebar } from '../../../BuilderComponents/ProjectDashboard/DashboardComponents/ProjectDashboardSidebar';
import { NavbarForDirectory } from '../../../BuilderComponents/ProjectDashboard/DashboardComponents/NavbarForDirectory';

function EditMediaAdd() {
   ////get data from previous page 
   const location = useLocation();
   const toSend = location.state;
   const data= toSend;
   console.log("preve data =>.>",data)

    const navigate = useNavigate();
    
    const [units, setUnits]  = useState();
    const [selectedCategory, setSelectCategory] = useState("");
    const selectcat = (val)=>{
        selectedCategory==val ? setSelectCategory(""):setSelectCategory(val)
    }

    useEffect(()=>{
    if(data){
        setUnits(data);
    }
    },[])
console.log(units)

    return(
        <>


<Stack direction="row" sx={{width:"100%",height:"auto" }}>
      {/* 1st stack */}
      <Stack sx={{minHeight:"100vh", width:"22%",boxSizing:"border-box",}}>
      <ProjectDashboardSidebar active={"Unit Directory"}/>
      </Stack>

     {/* 2nd stack */}
      <Stack direction="column" sx={{ backgroundColor:"#f9f9f9",width:"78%", padding:"25px",boxSizing:"border-box",}}  >
      {/* <Navbar/> */}
      <NavbarForDirectory
            leftRightTitle1={"Unit Dashboard"}
            leftRightTitle2={"Unit Directory"}
            leftTitle={"Project Dashboard"}
            showLeftTitle={false}
            righshow={true}
          />
      

      <Typography sx={{marginTop:"20px", fontSize:"28px", fontWeight:"600", marginLeft:"10px", marginBottom:"50px"}}>
                 Attach media to Units
           </Typography>

     {/* actual content  */}
    <Stack sx={{backgroundColor:"#f9f9f9"}}>  {/* mt:"28px", */}

    <Stack direction="column"  sx={{  padding:"20px 0px 20px 0px", backgroundColor:"#fff", marginRight:"0px"}}>
           {/* attach media to unit */}
           <Stack direction="row" justifyContent="space-between" >
             <Stack sx={{width:"50%",paddingLeft:"20px"}} >
              <Typography sx={{fontSize:"28px", fontWeight:"600"}}>Media View</Typography>
                 <Stack direction="row" justifyContent="center" alignItems="center">
                  <Typography sx={{fontSize:"20px", fontWeight:"600", mt:"30px",mb:"20px"}}>Media Library</Typography>
                  </Stack>
                  <Stack>
                      <Typography sx={{fontSize:"18px", fontWeight:"500", color:"#A2A3A6"}}>Select Category</Typography>
                      
                      <Box sx={{margin:"auto", width:{sm:"80%", lg:"55%"},  marginTop:"40px",display:"flex", justifyContent:"space-between"}}>
                            {/* <Stack onClick={()=>selectcat("broucher")} direction="row" alignItems="center" justifyContent="center" sx={{borderRadius:"20px", width:"100px", height:"100px", bgcolor:selectedCategory=="broucher"?"#FBB040":"#D9D9D9", }}>
                               <Typography sx={{fontSize:"18px", fontWeight:"600"}}>Broucher</Typography>  
                            </Stack> */}
                            <Stack onClick={()=>selectcat("image")} direction="row" alignItems="center" justifyContent="center" sx={{borderRadius:"20px", width:"100px", height:"100px", bgcolor:selectedCategory=="image"?"#FBB040":"#D9D9D9",}}>
                               <Typography sx={{fontSize:"18px", fontWeight:"600"}}>Images</Typography>  
                            </Stack>
                      </Box>
                  </Stack>
             </Stack>
             <Stack sx={{width:"50%", borderLeft:"1px solid #D9D9D9",padding:"0px 20px 0px 20px"}}>
               <Typography sx={{fontSize:"28px", fontWeight:"600"}}>Unit View</Typography>
               <Stack direction="row" justifyContent="center" alignItems="center">
                  <Typography sx={{fontSize:"20px", fontWeight:"600", mt:"30px",mb:"20px"}}>Select Units</Typography>
                  </Stack>
                  <Stack>
                      <Typography sx={{fontSize:"18px", fontWeight:"500", color:"#A2A3A6"}}>Units</Typography>
                      <Box sx={{display:"flex", justifyContent:"start",alignItems:"center",flexWrap:"wrap",overflowY:"scroll", maxHeight:"400px"}}>
        
                          <Stack direction="row" alignItems="center" sx={{marginRight:"20px",  width:{sm:"20%",lg:"15%"}}}>
                          <Checkbox
                            edge="start"
                            disabled
                            tabIndex={-1}
                            disableRipple
                          />
                          <Typography sx={{fontSize:"18px", fontWeight:"500", color:"#A2A3A6"}}>{units}</Typography>
                          </Stack>
                        
                      </Box>   
                  </Stack>
                             
             </Stack>
           </Stack>
             <Stack direction="row" justifyContent="end" alignItems="center" spacing={2} sx={{marginTop:"50px", padding:"20px"}}>
                <Link to={`/unit-directory-w-sb/edit-media-add-Unit/${selectedCategory}`} state={toSend}> 
                <Button size="large" className="univercel_btn_class" sx={{textTransform:"none", color:"#fff", padding:"9px 28px 9px 28px"}}
                 >Next</Button>
                </Link> 
                
             </Stack>

           </Stack>

    </Stack>
      </Stack>
</Stack>        
        </>
    )
}

export default EditMediaAdd