import {
  Box,
  Checkbox,
  Container,
  Divider,
  FormControl,
  FormControlLabel,
  styled,
  Typography,
  useRadioGroup,
} from "@mui/material";

import { flexBetween, itemsCenter } from "./styles/commonStyles";
import classes from "./styles/PageOne.module.css";
import img from "../../Imgs/img.jpg";
import { useState } from "react";

const PdfOnePageFour = ({fileInfo}) => {

  const [pageData4,setPageData4]=useState({
    name:"",
    office:"",
    post_code:"",
    state:"",
    country:"",
    cor_office:"",
    cor_post_code:"",
    cor_state:"",
    cor_country:"",
    tin_no:"",
    pan_no:"",
    date_auth_letter:"",
    first_name:"",
    middle_name:"",
    last_name:"",
    date_birth:"",
    mobile:"",
    email:"",
    partner1:"",
    partner2:"",
    partner3:"",
    partner4:"",
    dir_name:"",
    com_rep_name:"",
    com_rep_desig:"",
    com_rep_phone:"",
    com_rep_email:"",
    auth_sign:"",
    sign_first:"",
    sign_second:"",
    sign_third:""
  })

  // console.log(pageData4);

  let name,value;
  const handleChange=(e)=>{
    name=e.target.name;
    value=e.target.value;
    console.log(name," ",value);
    setPageData4({...pageData4,[name]:value});
  }

  return (
    <Box>
      <Box sx={{ mb: "40px" }}>
        <Box sx={{ width: "80%" }}>
          <Box sx={{ ...itemsCenter, gap: "20px" }}>
            <Box className={classes.input}>
              <label htmlFor="">Ref. No.</label>
              <input value={""} type="number" />
            </Box>
            <Box className={classes.input}>
              <label htmlFor="">Booking date:</label>
              <input value={fileInfo.created?.substring(0,10)} type="text" />
            </Box>
          </Box>
          <Box sx={{ display: "flex", gap: "10px", mt: "5px", ml: "20px" }}>
            <Typography sx={{ fontSize: "10px" }}>4.</Typography>
            <Typography sx={{ fontSize: "10px" }}>
              DETAILS OF COMPANY/PARTNERSHIP FIRM. Fields marked with * are
              mandatory.
            </Typography>
          </Box>
        </Box>
        <Box className={classes.input}>
          <label htmlFor="" style={{ fontSize: "12px" }}>
            *Name:
          </label>
          <input
            style={{ width: "60%", margin: "0px", textAlign: "center" }}
            value={pageData4.name}
            type="text"
            name="name"
            onChange={handleChange}
          />
        </Box>
        <Box className={classes.input}>
          <label htmlFor="" style={{ fontSize: "12px" }}>
            *Registered Office::
          </label>
          <input
            style={{ width: "60%", margin: "0px", textAlign: "center" }}
            value={pageData4.office}
            type="text"
            name="office"
            onChange={handleChange}
          />
        </Box>

        <Box>
          <Divider
            sx={{ backgroundColor: "#000", height: "3px", mt: "10px" }}
          />

          <Box sx={{ ...itemsCenter, gap: "10px" }}>
            <Box className={classes.input}>
              <label htmlFor="">*Post Code:</label>
              <input style={{ width: "150px" }} value={pageData4.post_code} type="number" name="post_code" onChange={handleChange}/>
            </Box>
            <Box className={classes.input}>
              <label htmlFor="">*State: </label>
              <input style={{ width: "150px" }} value={pageData4.state} type="text" name="state" onChange={handleChange} />
            </Box>
            <Box className={classes.input}>
              <label htmlFor="">*Country:</label>
              <input style={{ width: "150px" }} value={pageData4.country} type="text" name="country" onChange={handleChange}/>
            </Box>
          </Box>
          <Box className={classes.input}>
            <label htmlFor="" style={{ fontSize: "12px" }}>
              *Corporate Office:
            </label>
            <input
              style={{ width: "60%", margin: "0px", textAlign: "center" }}
              value={pageData4.cor_office}
              type="text"
              name="cor_office"
              onChange={handleChange}
            />
          </Box>

          <Box sx={{ ...itemsCenter, gap: "10px" }}>
            <Box className={classes.input}>
              <label htmlFor="">*Post Code:</label>
              <input style={{ width: "150px" }} value={pageData4.cor_post_code} type="text" name="cor_post_code" onChange={handleChange}/>
            </Box>
            <Box className={classes.input}>
              <label htmlFor="">*State: </label>
              <input style={{ width: "150px" }} value={pageData4.cor_state} type="text" name="cor_state" onChange={handleChange}/>
            </Box>
            <Box className={classes.input}>
              <label htmlFor="">*Country:</label>
              <input style={{ width: "150px" }} value={pageData4.cor_country} type="text" name="cor_country" onChange={handleChange}/>
            </Box>
          </Box>
          <Box className={classes.input}>
            <label htmlFor="">
              *Taxpayer’s Identification Number (TIN No.):{" "}
            </label>
            <input style={{ width: "300px" }} value={pageData4.tin_no} type="number" name="tin_no" onChange={handleChange}/>
          </Box>
          <Box className={classes.input}>
            <label htmlFor="">*Permanent Account Number (PAN No.): </label>
            <input style={{ width: "300px" }} value={pageData4.pan_no} type="text" name="pan_no" onChange={handleChange}/>
          </Box>
          <Box className={classes.input}>
            <label htmlFor="">
              *Date of Board Resolution/ Authority Letter:{" "}
            </label>
            <input style={{ width: "300px" }} value={pageData4.date_auth_letter} type="date" name="date_auth_letter" onChange={handleChange}/>
          </Box>
          <Box className={classes.input} sx={{ display: "flex" }}>
            <label htmlFor="" style={{ fontSize: "12px" }}>
              *In Favour of Mr/Mrs/Ms:
            </label>
            <Box sx={{ display: "inline-block" }}>
              <input
                style={{ margin: "0px", textAlign: "center" }}
                value={pageData4.first_name}
                type="text"
                name="first_name"
                onChange={handleChange}
              />
              <Typography sx={{ textAlign: "center", fontSize: "10px" }}>
                (First name)
              </Typography>
            </Box>
            <Box sx={{ display: "inline-block" }}>
              <input
                style={{ margin: "0px", textAlign: "center" }}
                value={pageData4.middle_name}
                type="text"
                name="middle_name"
                onChange={handleChange}
              />
              <Typography sx={{ textAlign: "center", fontSize: "10px" }}>
                (Middle name)
              </Typography>
            </Box>
            <Box sx={{ display: "inline-block" }}>
              <input
                style={{ margin: "0px", textAlign: "center" }}
                value={pageData4.last_name}
                type="text"
                name="last_name"
                onChange={handleChange}
              />
              <Typography sx={{ textAlign: "center", fontSize: "10px" }}>
                (Last name)
              </Typography>
            </Box>
          </Box>
          <Box className={classes.input} sx={{ display: "flex" }}>
            <label htmlFor="" style={{ fontSize: "12px" }}>
              DOB:
            </label>
            <Box sx={{ display: "inline-block" }}>
              <input
                style={{ margin: "0px", textAlign: "center" }}
                value={pageData4.date_birth}
                type="date"
                name="date_birth"
                onChange={handleChange}
              />
              <Typography sx={{ textAlign: "center", fontSize: "10px" }}>
                (dd/mm/yy)
              </Typography>
            </Box>
          </Box>
          <Box sx={{ ...itemsCenter, gap: "10px" }}>
            <Box className={classes.input}>
              <label htmlFor="">*Email Address:</label>
              <input style={{ width: "150px" }} value={pageData4.email} type="text" name="email" onChange={handleChange}/>
            </Box>
            <Box className={classes.input}>
              <label htmlFor="">*#Contact No.:</label>
              <input style={{ width: "150px" }} value={pageData4.mobile} type="number" name="mobile" onChange={handleChange}/>
            </Box>
          </Box>
          <Typography sx={{ fontSize: "10px" }}>
            The Applicant(s) in case of a Company/Partnership Firm, shall
            provide the list of its existing shareholders/partners as on the
            date of signing Application.{" "}
          </Typography>

          <Box>
            <Box>
              <Typography sx={{ fontSize: "10px" }}>
                *Name of Shareholders/Partners
              </Typography>
              <Box
                className={classes.input}
                sx={{ display: "flex", flexDirection: "column", gap: "5px" }}
              >
                <input style={{ width: "300px" }} value={pageData4.partner1} type="text" name="partner1" onChange={handleChange}/>
                <input style={{ width: "300px" }} value={pageData4.partner2} type="text" name="partner2" onChange={handleChange}/>
                <input style={{ width: "300px" }} value={pageData4.partner3} type="text" name="partner3" onChange={handleChange}/>
                <input style={{ width: "300px" }} value={pageData4.partner4} type="text" name="partner4" onChange={handleChange}/>
              </Box>
            </Box>
            <Box></Box>
          </Box>
          <Box className={classes.input}>
            <label htmlFor="">*Name of Directors: </label>
            <input style={{ width: "300px" }} value={pageData4.dir_name} type="text" name="dir_name" onChange={handleChange}/>
          </Box>
          <Divider
            sx={{ backgroundColor: "#000", height: "3px", mt: "10px" }}
          />

          <Box className={classes.input}>
            <label htmlFor="">* Name of Company Representative: </label>
            <input style={{ width: "300px" }} value={pageData4.com_rep_name} type="text" name="com_rep_name" onChange={handleChange}/>
          </Box>
          <Box className={classes.input}>
            <label htmlFor="">*Designation: </label>
            <input style={{ width: "300px" }} value={pageData4.com_rep_desig} type="text" name="com_rep_desig" onChange={handleChange}/>
          </Box>
          <Box sx={{ ...itemsCenter, gap: "10px" }}>
            <Box className={classes.input}>
              <label htmlFor="">*#Contact No.:</label>
              <input style={{ width: "150px" }} value={pageData4.com_rep_phone} type="number" name="com_rep_phone" onChange={handleChange} />
            </Box>
            <Box className={classes.input}>
              <label htmlFor="">*Email Address:</label>
              <input style={{ width: "150px" }} value={pageData4.com_rep_email} type="text" name="com_rep_email" onChange={handleChange}/>
            </Box>
          </Box>
          <Box>
            <Typography sx={{ fontSize: "12px" }}>FOR COMPANY</Typography>

            <form>
              <Box className={classes.formGroup}>
                <input type="checkbox" id="Certified Copy of Memorandum and Articles of Association.4" />
                <label for="Certified Copy of Memorandum and Articles of Association.4">
                  Certified Copy of Memorandum and Articles of Association.
                </label>
              </Box>
              <Box className={classes.formGroup}>
                <input type="checkbox" id="Certified Copy of Board Resolution.4" />
                <label for="Certified Copy of Board Resolution.4">Certified Copy of Board Resolution.</label>
              </Box>
              <Box className={classes.formGroup}>
                <input type="checkbox" id="Certified Copy of Incorporation.4" />
                <label for="Certified Copy of Incorporation.4">Certified Copy of Incorporation.</label>
              </Box>
              <Box className={classes.formGroup}>
                <input type="checkbox" id="Self-attested copy of Pan Card.4" />
                <label for="Self-attested copy of Pan Card.4">Self-attested copy of Pan Card.</label>
              </Box>
              <Box className={classes.formGroup}>
                <input type="checkbox" id="Self-attested List of Directors.4" />
                <label for="Self-attested List of Directors.4">Self-attested List of Directors.</label>
              </Box>
              <Box className={classes.formGroup}>
                <input type="checkbox" id="Self-attested copy of Pan Card of Authority Signatory.4" />
                <label for="Self-attested copy of Pan Card of Authority Signatory.4">
                  Self-attested copy of Pan Card of Authority Signatory.
                </label>
              </Box>
            </form>
          </Box>
          <Box>
            <Typography sx={{ fontSize: "12px" }}>FOR PARTNERSHIP</Typography>

            <form>
              <Box className={classes.formGroup}>
                <input type="checkbox" id="Certified copies of Partnership Deed(s).4" />
                <label for="Certified copies of Partnership Deed(s).4">
                  Certified copies of Partnership Deed(s).
                </label>
              </Box>
              <Box className={classes.formGroup}>
                <input type="checkbox" id="Certificate of LLP.4" />
                <label for="Certificate of LLP.4">
                  Certified extract of Registration from Registrar of firms/
                  Certificate of LLP.
                </label>
              </Box>
              <Box className={classes.formGroup}>
                <input type="checkbox" id="from all Partners.4" />
                <label for="from all Partners.4">
                  Self-attested copy of Authorization Letter/Power of Attorney
                  from all Partners.
                </label>
              </Box>
              <Box className={classes.formGroup}>
                <input type="checkbox" id="Self-attested copy of Pan Card.4-1" />
                <label for="Self-attested copy of Pan Card.4-1">Self-attested copy of Pan Card.</label>
              </Box>
              <Box className={classes.formGroup}>
                <input type="checkbox" id="Self-attested copy of Pan Card of Authority Signatory.4-1" />
                <label for="Self-attested copy of Pan Card of Authority Signatory.4-1">
                  Self-attested copy of Pan Card of Authority Signatory.
                </label>
              </Box>
            </form>
          </Box>
        </Box>

        <Box className={classes.input} sx={{ display: "inline-block" }}>
          <input
            style={{ margin: "0px", textAlign: "center" }}
            value={pageData4.auth_sign}
            type="text"
            name="auth_sign"
            onChange={handleChange}
          />
          <Typography sx={{ textAlign: "center", fontSize: "10px" }}>
            Authorised Signatory
          </Typography>
        </Box>
      </Box>

      <Box
        sx={{
          display: "flex",
          gap: "40px",
          justifyContent: "center",
          position: "absolute",
          bottom: "30px",
        }}
      >
        <Box className={classes.input}>
          <Box>
            <input
              style={{ textAlign: "center" }}
              value={pageData4.sign_first}
              type="text"
              name="sign_first"
              onChange={handleChange}
            />
            <Typography sx={{ textAlign: "center", mt: "10px" }}>
              Signature of First Applicant
            </Typography>
          </Box>
        </Box>
        <Box className={classes.input}>
          <Box sx={{ display: "inline-block" }}>
            <input
              style={{ margin: "0px", textAlign: "center" }}
              value={pageData4.sign_second}
              type="text"
              name="sign_second"
              onChange={handleChange}
            />
            <Typography sx={{ textAlign: "center", mt: "10px" }}>
              Signature of Second Applicant
            </Typography>
          </Box>
        </Box>
        <Box className={classes.input}>
          <Box sx={{ display: "inline-block" }}>
            <input
              style={{ margin: "0px", textAlign: "center" }}
              value={pageData4.sign_third}
              type="text"
              name="sign_third"
              onChange={handleChange}
            />
            <Typography sx={{ textAlign: "center", mt: "10px" }}>
              Signature of Third Applicant
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default PdfOnePageFour;
