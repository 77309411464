import React from 'react'
import SideBar from '../components/sideBar/SideBar'
import { View } from './components/view'

const Documents = () => {
  return (
    <SideBar active={"Documents"}>
        <View/>
    </SideBar>
  )
}

export default Documents