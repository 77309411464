import axios from "axios";
import { Apiaddress, basicheaders } from "../../utils/consts";
import { bathroomData,balconyData, bedroomData, doubleData, singleData, UnitTemplateData } from "./interfaces/create_unit_template";

const CreateUnitTemplate = async (
    single:singleData[],
    double:doubleData[],
    name:String,
    project_id:number,
    bedroom?: bedroomData[],
    bathroom?:bathroomData[],
    balcony?:balconyData[],
     ) => {
        
    const layout_data:UnitTemplateData = {
        singleData:single,
        doubleData:double,
        bedroomData: bedroom,
        bathroomData:bathroom,
        balconyData:balcony,
    };


    const payload = {
        "name":name,
        "layout_data":layout_data,
        "charges_data":{},
        "project":project_id,
    };

    // console.log("lo",payload)
    const url = Apiaddress+"/api/projects/unittemplate/";
    const re  = await axios.post(
        url ,
        {
            headers:basicheaders,
            ...payload,
        }
    ) 
    return re.data;
}
export {CreateUnitTemplate};

