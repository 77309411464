import { Box, Button, TextField, Typography } from "@mui/material"
import { Stack } from "@mui/system"
import { Navbar } from "../DashboardComponents/Navbar"
import { Sidebar } from "../DashboardComponents/Sidebar"
import DoneIcon from '@mui/icons-material/Done';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import { NavLink, useNavigate } from "react-router-dom";
import "./CreateProject.css";
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import { useContext, useEffect, useState } from "react";
import CallIcon from '@mui/icons-material/Call';

// modal 
import Modal from '@mui/material/Modal';
import { Apiaddress } from "../../../../utils/consts";
import axios from "axios";

import currentProjectState from '../../../../context/createproject/context';
import { MainSidebar } from "../DashboardComponents/MainSidebar";


const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    border: '2px solid #fff',
    borderRadius:"30px",
    boxShadow: 24,
    p: 4,
  };
  
  
export const ProjectOverview = ()=>{
    const navigate = useNavigate();
    ////get number of buildings and wings and units
    const cpContext = useContext(currentProjectState);
    const projectID = cpContext?.createProjectState?.project?.id;


    const [building_count, setbuilding_count] = useState(0)
    const [floor_count, setfloor_count] = useState(0)
    const [unit_count, setunit_count] = useState(0)
    const [wing_count, setwing_count] = useState(0)

    console.log(projectID)
    const [allTemp, setAlltemp] = useState({
     building_count:"",
     floor_count:"",
     unit_count:"",
     wing_count:"",
    })

    /////for getting count of all templates
    useEffect(()=>{
    const uniturl = Apiaddress+"/api/projects/unittemplatesbyproject/?project_id="+projectID
    const floorurl = Apiaddress+"/api/projects/floortemplatesbyproject/?project_id="+projectID
    const wingurl = Apiaddress+"/api/projects/wingtemplatesbyproject/?project_id="+projectID
    const buildingurl = Apiaddress+"/api/projects/buildingtemplatesbyproject/?project_id="+projectID
    const pricingurl = Apiaddress+"/api/projects/unittemplatesbyproject/?project_id="+projectID
    const arr = [uniturl,floorurl,wingurl,buildingurl];

   
      axios.get(uniturl)
      .then((res)=>{
        const data = res?.data
         console.log("unit temp",res.data);
         if(data.unit_template!=="Unit template not found")
         setunit_count(data.unit_template.length)
           
         })
         .catch((err)=>{
           console.log(err)
         })
         
         axios.get(floorurl)
         .then((res)=>{
           const data = res?.data
           console.log(data)
           if(data.floor_templates!=="floor templates not found")
           setfloor_count(data.floor_templates.length)
         })
         .catch((err)=>{
           console.log(err)
         })

      axios.get(wingurl)
      .then((res)=>{
            const data = res?.data
            console.log(data)
            if(data.wing_templates!=="Wing Template not found")
            setwing_count(data.wing_templates.length)
         })
         .catch((err)=>{
           console.log(err)
         })

      axios.get(buildingurl)
      .then((res)=>{
            const data = res?.data
            console.log(data)
            if(data.building_template!=="building_template not found")
            setbuilding_count(data.building_template.length)
               
         })
         .catch((err)=>{
           console.log(err)
         })
  },[])

    const [select,setSelect] = useState("");
    const handleChange = (event) => {
        setSelect(event.target.value);
        console.log(select)
      };
      


    // for modal 
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    //credit 
    const [availCredit, setAvailCredit] = useState(120);
    const [reqCredit, setReqCredit] = useState(100)

  
    // useEffect(()=>{
      
    //     const url = Apiaddress+"/api/projects/getcountconfirmproject/?project_id="+projectID
    //     axios.get(url).then((res)=>{
    //       console.log(res.data);
    //       setAlltemp({...res.data});
    //     }).catch((err)=>{
    //       console.log(err);
    //     })
    // },[])


    //// for upload file (bannerImage);
    const [img,setimg] = useState("");

    const imgHandler = (e)=>{
      setimg(e.target.files[0]);
    }

    ////confirm project ;
    const confirmProject = ()=>{
      let url = Apiaddress+"/api/projects/project/";

      const data = {
        "project_id":projectID,
        "banner_image":img,
        "projectAmmenities":select
      }
      const headers ={ 
        headers: {
        'content-type': 'multipart/form-data',
      }
    }
      console.log("data:=>.>...>>>",data)
     if(img=="" && select=="")return
      axios.patch(url,data,headers)
      .then((res)=>{
        navigate("/confirm-project-building")
        console.log("res confirm+=>>>>>>>>>>>>>", res.data);
      }).catch((err)=>{
        console.log(err);
      })
    }
    return (
        <>
        
<Stack direction="row" sx={{width:"100%",height:"auto" }}>
      {/* 1st stack */}
      <Stack sx={{minHeight:"100vh",width:"20%",boxSizing:"border-box",}}> {/*for project directory 20% and for sales dashboard 225*/}
        <MainSidebar active={"Project Directory"}/>
      </Stack>

     {/* 2nd stack */}
     <Stack direction="column" sx={{ backgroundColor:"#f9f9f9",width:"80%", padding:"25px",boxSizing:"border-box",}}  >  {/*  width for main sidebar 78px and for 80px*/}
      <Navbar/>
      


      <Typography sx={{marginTop:"20px", fontSize:"28px", fontWeight:"600", marginLeft:"10px", marginBottom:"50px"}}>
           Project Overview
            </Typography>
     {/* actual content  */}
    <Stack sx={{backgroundColor:"#f9f9f9"}}>  {/* mt:"28px", */}

   
           <Stack direction="column"  sx={{  padding:"20px", backgroundColor:"#fff", marginRight:"0px",pl:"69px"}}>

                 {/* confirm project   */}
                          
                        <Stack direction="row" justifyContent="space-between" spacing={4} alignItems="center" sx={{ width:"400px",mb:"30px",mt:"20px"}}>
                            <Typography sx={{fontSize:"18px", fontWeight:"500", color:"#A2A3A6"}}>Total Buildings</Typography>
                            <TextField disabled value={building_count} sx={{width:"188px"}}/>
                        </Stack>
                        <Stack direction="row" justifyContent="space-between" spacing={4} alignItems="center" sx={{ width:"400px",mb:"30px"}}>
                            <Typography sx={{fontSize:"18px", fontWeight:"500", color:"#A2A3A6"}}>Total Wings</Typography>
                           <TextField disabled value={wing_count} sx={{width:"188px"}}/>
                        </Stack>
                         <Stack direction="row" justifyContent="space-between" spacing={4} alignItems="center" sx={{ width:"400px",mb:"30px"}}>
                            <Typography sx={{fontSize:"18px", fontWeight:"500", color:"#A2A3A6"}}>Total Floors</Typography>
                           <TextField disabled value={floor_count} sx={{width:"188px"}}/>
                        </Stack>
                        <Stack direction="row" justifyContent="space-between" spacing={4} alignItems="center" sx={{ width:"400px",mb:"30px"}}>
                            <Typography sx={{fontSize:"18px", fontWeight:"500", color:"#A2A3A6"}}>Total Units</Typography>
                           <TextField disabled value={unit_count} sx={{width:"188px"}}/>
                        </Stack>

                        {/* <Stack direction="row" justifyContent="start" spacing={4} alignItems="center" sx={{mb:"30px"}}>
                            <Typography sx={{fontSize:"18px", fontWeight:"500", color:"#A2A3A6"}}>Amenities</Typography>
                            
                            <FormControl sx={{ m: 1, minWidth: 188, border:"1px transparent" }}>
                                <Select
                                  value={select}
                                  onChange={handleChange}
                                  displayEmpty
                                  inputProps={{ 'aria-label': 'Without label' }}
                                >
                                  <MenuItem value="">
                                    Amenities
                                  </MenuItem>
                                  <MenuItem value={"amenities 1"}>amenities 1</MenuItem>
                                  <MenuItem value={"amenities 2"}>amenities 2</MenuItem>
                                  <MenuItem value={"amenities 3"}>amenities 2</MenuItem>
                                </Select>
                                <FormHelperText>Without label</FormHelperText>
                               </FormControl>
                        </Stack> */}



                        
<Stack direction="row" sx={{alignItems:"center"}}>

<Box sx={{margin:"60px 0px 60px 0px"}}>
    <Button 
    className="univercel_btn_class"
    onClick={()=>{
      navigate("/confirm-project-building")
    }}
    variant="contained"
    sx={{padding:"10px 30px 10px 30px", color:"#fff",fontSize:"18px", fontWeight:"700"}}>
        View/Edit Details 
    </Button>
</Box>

<Box sx={{margin:"60px 0px 60px 0px"}}>
    <Button 
    className=""
    onClick={()=>{
      navigate("/projectdirectory")
    }}
    variant="contained"
    sx={{padding:"10px 80px 10px 80px", color:"#fff",fontSize:"18px", fontWeight:"700",filter:"drop-shadow(0px 13.7981px 22.5788px rgba(50, 174, 137, 0.25))",background:"linear-gradient(90deg, #0F5DAE 0%, #009DCF 100.33%)",textTransform:"none",boxShadow:"0px 13.7981px 22.5788px rgba(50, 174, 137, 0.25)",marginLeft:"30px"}}>
      Exit
    </Button>
</Box>

</Stack>

<Stack direction="row">
{/* <Box sx={{margin:"60px 60px 60px 0px"}}>
    <Button
    className=""
    onClick={()=>{
      // navigate("/")
    }}
    variant="contained"
    sx={{padding:"12px 70px 12px 70px", color:"#000000",fontSize:"18px", fontWeight:"700",filter:"drop-shadow(0px 13.7981px 22.5788px rgba(50, 174, 137, 0.25))",background:"white",textTransform:"none"}}>
        Delete 
    </Button>
</Box> */}
{/* <Box sx={{margin:"60px 0px 60px 0px"}}>
    <Button 
    className=""
    onClick={()=>{
      // navigate("/projectdirectory")
    }}
    variant="contained"
    sx={{padding:"12px 80px 12px 80px", color:"#fff",fontSize:"18px", fontWeight:"700",filter:"drop-shadow(0px 13.7981px 22.5788px rgba(50, 174, 137, 0.25))",background:"linear-gradient(90deg, #0F5DAE 0%, #009DCF 100.33%)",textTransform:"none",boxShadow:"0px 13.7981px 22.5788px rgba(50, 174, 137, 0.25)"}}>
      Exit
    </Button>
</Box> */}
</Stack>

                       

           </Stack>

             {/* modal for select cover image */}
             <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={{...style,width:{sm:300,lg:400,},textAlign:"center"}}>
                <Stack >
                     <Typography sx={{fontSize:"28px",fontWeight:700,}}> <span style={{color:"#ffb12f"}}>{reqCredit}</span> Credits required to create this project configaration  </Typography>
                </Stack>
                
                     <Typography sx={{fontSize:"28px",fontWeight:700,mt:"40px",mb:"50px"}}> <span style={{color:reqCredit<=availCredit?"#25c027":"#eb6270"}}>{availCredit}</span>  Credits available  </Typography>

                <Stack direction="row" justifyContent="space-around" sx={{mb:"50px"}}>
                    {reqCredit<=availCredit?<Button className="univercel_btn_class" sx={{padding:"16px 54px 16px 54px"}}>Confirm</Button>:null}
                      
                      <Button sx={{padding:"16px 54px 16px 54px", color:"black", border:"1px solid #0F5DAE",}}>cancel</Button>
                </Stack>

                <Stack direction="row" spacing={1} alignItems="center" justifyContent="center">
                     <CallIcon/>
                     <Typography sx={{color:" #A2A3A6", fontSize:"14px", fontWeight:"400"}}>Contact support</Typography>
                </Stack>
                
          
        </Box>
      </Modal>

    </Stack>
      </Stack>
</Stack>
          
          
        
        </>
    )
}






















// <Stack direction="row" sx={{alignItems:"center"}}>

// <Box sx={{margin:"60px 0px 60px 0px"}}>
//     <Button 
//     className="univercel_btn_class"
//     onClick={()=>{
//       navigate("/confirm-project-unit")
//     }}
//     variant="contained"
//     sx={{padding:"16px 50px 16px 50px", color:"#fff",fontSize:"18px", fontWeight:"700"}}>
//         View/edit details 
//     </Button>
// </Box>
// </Stack>

// <Stack direction="row">
// <Box sx={{margin:"60px 60px 60px 0px"}}>
//     <Button
//     className=""
//     onClick={()=>{
//       navigate("/confirm-project-unit")
//     }}
//     variant="contained"
//     sx={{padding:"12px 70px 12px 70px", color:"#000000",fontSize:"18px", fontWeight:"700",filter:"drop-shadow(0px 13.7981px 22.5788px rgba(50, 174, 137, 0.25))",background:"white",textTransform:"none"}}>
//         Delete 
//     </Button>
// </Box>
// <Box sx={{margin:"60px 0px 60px 0px"}}>
//     <Button 
//     className=""
//     onClick={()=>{
//       navigate("/confirm-project-unit")
//     }}
//     variant="contained"
//     sx={{padding:"12px 80px 12px 80px", color:"#fff",fontSize:"18px", fontWeight:"700",filter:"drop-shadow(0px 13.7981px 22.5788px rgba(50, 174, 137, 0.25))",background:"linear-gradient(90deg, #0F5DAE 0%, #009DCF 100.33%)",textTransform:"none",boxShadow:"0px 13.7981px 22.5788px rgba(50, 174, 137, 0.25)"}}>
//         Copy 
//     </Button>
// </Box>
// </Stack>