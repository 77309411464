import { Box, Button, MenuItem, Radio, Select, TextField, Typography } from "@mui/material"
import { Stack } from "@mui/system"
import { SuperAdminNavbar } from "../SidebarNavbar/SuperAdminNavbar"
import { SuperAdminSidebar } from "../SidebarNavbar/SuperAdminSidebar"


import DoneIcon from '@mui/icons-material/Done';
import { useContext, useEffect, useState } from "react";
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
// import "./CreateProject.css"
import { useNavigate } from "react-router-dom";
import {
    CitySelector,
    StateSelector,
  } from "volkeno-react-country-state-city";
  import FormControl from '@mui/material/FormControl';
  import { countryDetails } from "../../../utils/contryDetails";
import axios from "axios";
import { Apiaddress } from "../../../utils/consts";

import profileContext from "../../../context/profile/context";
import projectContext from "../../../context/createproject/context";
import { createProject } from "../../../connection/project/create_project";
import { SAProjectSidebar } from "../SidebarNavbar/SAProjectSidebar";
import { UserRole } from "../../../UserRoles/UserRoles";

export const CreateProjectProfileSA = ()=>{
   ////get user roles from local storage and assign 
   let roles = JSON.parse(localStorage.getItem("user_roles"))
   let project = roles["project"];
   let unit = roles["unit"];
   let org = roles["org"];
   let builder = roles["builder"];
   let media = roles["project media"];
   let teams = roles["teams"];
   const navigate = useNavigate();

   if(UserRole(project, "add_project")){
     navigate("/super-admin-dashboard")
   }
  ////selection of state city
  const [location, setLocation] = useState({});
  //for set country
  useEffect(() => {
    setLocation(countryDetails);

  }, []);

  ///get builder token from local storage by verifying otp and then get builder details and organization of builder to select for create project
  const SABuilderDetails = JSON.parse(localStorage.getItem("SABuilderDetails"));
  // console.log(SABuilderDetails)

  const getlistofog = async()=>{
    const token = SABuilderDetails.builder_token;
    if(!token)return;
    const re = await axios.get(Apiaddress + "/api/orglistusingtoken/", {
      headers: {
        Authorization: "JWT " + token,
      },
    });
    console.log(re.data);
      setListOfBuildersOrganization(re.data?.org_list);

  }
  useEffect(() => {

   getlistofog();
      return () => {
        console.log('This will be logged on unmount');
      };

  }, []);

  const [selectedValue, setSelectedValue] = useState("");
  const [listOfBuildersOrganization, setListOfBuildersOrganization] = useState([]);

  const handleChange = (event) => {
    setSelectedValue(event.target.value);
    setFormState({
      ...formState,
      multi_building: event.target.value == "many", //if selected value is many it is true otherwise false;
    });
  };



  const controlProps = (item) => ({
    checked: selectedValue === item,
    onChange: handleChange,
    value: item,
    name: "color-radio-button-demo",
    inputProps: { "aria-label": item },
  });

  const [trigger, setTrigger] = useState("") 
  const [formState, setFormState] = useState({});
//   const user = useContext(profileContext);// we taking builder details from local storage 
  const projContext = useContext(projectContext);

  const PostData= async (e)=>{
   e.preventDefault();  
    
    console.log(SABuilderDetails, formState);
    try{
      const data = await createProject({
        ...formState,
        builder_id: SABuilderDetails.builder_Details.id,
      });
       const set = await projContext.setcreateProjectState(data);
      console.log(data)
      setTrigger("")
      if(data)return navigate("/super-admin-create-project-layout");
    }
    catch(err){
      console.log("err", err)
    }
    
  }


    return(
        <>

               
<Stack direction="row" sx={{width:"100%",height:"auto" }}>
      {/* 1st stack */}
      <Stack sx={{minHeight:"100vh",width:"20%",boxSizing:"border-box",}}> {/*for project directory 20% and for sales dashboard 225*/}
        {/* <SuperAdminSidebar active={"Builder Directory"}/> */}
        <SAProjectSidebar active={"Project Dashboard"}/>
      </Stack>

     {/* 2nd stack */}
     <Stack direction="column" sx={{ backgroundColor:"#f9f9f9",width:"80%", padding:"25px",boxSizing:"border-box",}}  >  {/*  width for main sidebar 78px and for 80px*/}
      <SuperAdminNavbar createProject={true} />  {/*send props for get popup to verify otp from builder */}
      


      <Typography sx={{marginTop:"20px", fontSize:"28px", fontWeight:"600", marginLeft:"10px", marginBottom:"50px"}}>
           Create Project
            </Typography>
     {/* actual content  */}
    <Stack sx={{backgroundColor:"#f9f9f9"}}>  {/* mt:"28px", */}

    <Stack
            // direction="column"
            sx={{
              padding: "20px",
              backgroundColor: "#fff",
              marginRight: "0px",
            }}
          >
            <Stack
              direction="row"
              justifyContent="start"
              alignItems="center"
              sx={{ height: "100px" }}
            >
              <Stack direction="row" alignItems="center" spacing={1.5} sx={{}}>
                <Box
                  sx={{
                    width: "29px",
                    height: "29px",
                    borderRadius: "50%",
                    backgroundColor: "#FFA946",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <DoneIcon sx={{ color: "#fff", fontSize: "20px" }} />
                </Box>
                <Typography sx={{ fontSize: "18px", fontWeight: "500" }}>
                  Profile
                </Typography>
                <Stack direction="row" alignItems="center">
                  <Box
                    sx={{
                      width: "25px",
                      height: "0px",
                      borderBottom: "2px dashed #A2A3A6",
                    }}
                  ></Box>
                  <Box
                    sx={{
                      height: "10px",
                      width: "10px",
                      borderRadius: "50%",
                      backgroundColor: "#A2A3A6",
                    }}
                  ></Box>
                </Stack>
              </Stack>
              <Stack
                direction="row"
                alignItems="center"
                spacing={1.5}
                sx={{ marginLeft: "20px" }}
              >
                <Box
                  sx={{
                    width: "29px",
                    height: "29px",
                    borderRadius: "50%",
                    backgroundColor: "#A2A3A6",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <DoneIcon sx={{ color: "#fff", fontSize: "20px" }} />
                </Box>
                <Typography sx={{ fontSize: "18px", fontWeight: "500" }}>
                  Unit layout Items
                </Typography>
                <Stack direction="row" alignItems="center">
                  <Box
                    sx={{
                      width: "25px",
                      height: "0px",
                      borderBottom: "2px dashed #A2A3A6",
                    }}
                  ></Box>
                  <Box
                    sx={{
                      height: "10px",
                      width: "10px",
                      borderRadius: "50%",
                      backgroundColor: "#A2A3A6",
                    }}
                  ></Box>
                </Stack>
              </Stack>
              <Stack
                direction="row"
                alignItems="center"
                spacing={1.5}
                sx={{ marginLeft: "20px" }}
              >
                <Box
                  sx={{
                    width: "29px",
                    height: "29px",
                    borderRadius: "50%",
                    backgroundColor: "#A2A3A6",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <DoneIcon sx={{ color: "#fff", fontSize: "20px" }} />
                </Box>
                <Typography sx={{ fontSize: "18px", fontWeight: "500" }}>
                  Unit Pricing Items
                </Typography>
                <Stack direction="row" alignItems="center">
                  <Box
                    sx={{
                      width: "25px",
                      height: "0px",
                      borderBottom: "2px dashed #A2A3A6",
                    }}
                  ></Box>
                  <Box
                    sx={{
                      height: "10px",
                      width: "10px",
                      borderRadius: "50%",
                      backgroundColor: "#A2A3A6",
                    }}
                  ></Box>
                </Stack>
              </Stack>
              <Stack
                direction="row"
                alignItems="center"
                spacing={1.5}
                sx={{ marginLeft: "20px" }}
              >
                <Box
                  sx={{
                    width: "29px",
                    height: "29px",
                    borderRadius: "50%",
                    backgroundColor: "#A2A3A6",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <DoneIcon sx={{ color: "#fff", fontSize: "20px" }} />
                </Box>
                <Typography sx={{ fontSize: "18px", fontWeight: "500" }}>
                  Templates
                </Typography>
                {/* <Stack direction="row" alignItems="center" >
                                <Box sx={{width:"25px", height:"0px", borderBottom:"2px dashed orange"}}></Box>
                                <Box sx={{height:"10px", width:"10px",borderRadius:"50%",  backgroundColor:"orange"}}></Box>
                               </Stack> */}
              </Stack>
            </Stack>

            <form onSubmit={PostData} >
            <Stack
              direction="row"
              alignItems="center"
              spacing={2}
              sx={{ margin: "20px 0px 40px 0px" }}
            >
              <Typography
                sx={{ color: "#A2A3A6", fontSize: "18px", fontWeight: 500 }}
              >
                Organization Name
              </Typography>
              <FormControl sx={{ m: 1, minWidth: 188, border:"1px transparent" }}>
              <Select
                required
                displayEmpty
                onChange={(e) => {
                  if(e.target.value=="")return;
                  setFormState({ ...formState, org_id: e.target.value });
                }}
                inputProps={{ 'aria-label': 'Without label' }}
                sx={{ width: "10px" }}
              >
                {/* <MenuItem value="">
                                    select organization
                                  </MenuItem> */}
                {listOfBuildersOrganization.map((e) => {
                  // console.log(e.id, e.org_name);
                  return <MenuItem value={e.id}>{e.org_name}</MenuItem>;
                })}
              </Select>
              </FormControl>

            </Stack>
            <Stack
              direction="row"
              alignItems="center"
              spacing={2}
              sx={{ marginBottom: "40px" }}
            >
              <Typography
                sx={{ color: "#A2A3A6", fontSize: "18px", fontWeight: 500 }}
              >
                Project Name
              </Typography>
              <TextField
                required
                onChange={(e) => {
                  setFormState({ ...formState, name: e.target.value });
                }}
                sx={{ width: "188px" }}
              />
            </Stack>
            <Stack
              direction="row"
              alignItems="center"
              spacing={2}
              sx={{ marginBottom: "40px" }}
            >
              <Typography
                sx={{ color: "#A2A3A6", fontSize: "18px", fontWeight: 500 }}
              >
                RERA Number (optional)
              </Typography>
              <TextField
                onChange={(e) => {
                  setFormState({ ...formState, rera_number: e.target.value });
                }}
                sx={{ width: "188px" }}
              />
            </Stack>
            <Stack
              direction="row"
              alignItems="center"
              spacing={2}
              sx={{ marginBottom: "40px" }}
            >
              <Typography
                sx={{ color: "#A2A3A6", fontSize: "18px", fontWeight: 500 }}
              >
                Site Address
              </Typography>
              <TextField
                required
                sx={{ width: "415px" }}
                onChange={(e) => {
                  setFormState({ ...formState, address_line: e.target.value });
                }}
              />
            </Stack>

            <Stack
              direction="row"
              justifyContent="start"
              alignItems="center"
              spacing={3.5}
              sx={{ marginBottom: "30px" }}
            >
              <Stack>
                <Typography
                  sx={{
                    margin: "0px 15px 5px 10px",
                    color: "#170F49",
                    fontSize: "18px",
                    fontWeight: 500,
                  }}
                >
                  State
                </Typography>
                {/* <TextField
                                    sx={{width:"221px", }}
                                 /> */}
                <StateSelector
                  country={location.country}
                  name="state"
                  value={location.state}
                  countryPlaceholder="Select a country first"
                  onChange={(e) => {
                    console.log(e);
                    // setValue("country", e.name);

                    setFormState({ ...formState, state: e.name });

                    setLocation({ ...location, state: e });
                  }}
                />
              </Stack>
              <Stack>
                <Typography
                  sx={{
                    margin: "0px 15px 5px 10px",
                    color: "#170F49",
                    fontSize: "18px",
                    fontWeight: 500,
                  }}
                >
                  City
                </Typography>
                {/* <TextField
                                    sx={{width:"221px", }}
                                 /> */}
                <CitySelector
                  state={location.state}
                  name="city"
                  value={location.city}
                  statePlaceholder="Select a state first"
                  onChange={(e) => {
                    console.log(e);
                    // setValue("city", e.name);
                    setFormState({ ...formState, city: e.name });
                    setLocation({ ...location, city: e });
                  }}
                />
              </Stack>
              <Stack direction="column">
                <Typography
                  sx={{
                    margin: "0px 15px 5px 10px",
                    color: "#170F49",
                    fontSize: "18px",
                    fontWeight: 500,
                  }}
                >
                  Pincode
                </Typography>
                <TextField
                  required
                  type="number"
                  inputProps={{ min: 100000, max: 999999 }}
                  sx={{
                    width: "221px",
                    marginTop: "10px",
                    marginBottom: "12px",
                  }}
                  onChange={(e) => {
                    setFormState({ ...formState, pincode: e.target.value });
                  }}
                />
              </Stack>
            </Stack>

            <Stack direction="column" sx={{ marginTop: "10px" }}>
              <Typography
                sx={{ color: "#A2A3A6", fontSize: "18px", fontWeight: 500, margin: "0px 15px 15px 0px" }}
              >
                Building Type
              </Typography>
              <Stack
                direction="row"
                justifyContent="start"
                alignItems="center"
                sx={{}}
              >
                <Radio
                  required
                  name="building_type"
                  {...controlProps("single")}
                  sx={{
                    color:
                      "linear-gradient(264.48deg, #79F1A4 -45.37%, #0E5CAD 88.97%)",
                    color: "#0F5DAE",
                    "&.Mui-checked": {
                      //   color: "red",
                      //   color: "linear-gradient(264.48deg, #79F1A4 -45.37%, #0E5CAD 88.97%)"
                    },
                  }}
                />
                <Typography
                  sx={{ color: "#170F49", fontSize: "18px", fontWeight: 500, marginRight:"20px" }}
                >
                  Single Building
                </Typography>
                <Radio
                  required
                  name="building_type"
                  {...controlProps("many")}
                  sx={{

                    color:
                      "linear-gradient(264.48deg, #79F1A4 -45.37%, #0E5CAD 88.97%)",
                    color: "#0F5DAE",
                    "&.Mui-checked": {
                      //   color: "red",
                      //   color: "linear-gradient(264.48deg, #79F1A4 -45.37%, #0E5CAD 88.97%)"
                    },
                  }}
                />
                <Typography
                  sx={{ color: "#170F49", fontSize: "18px", fontWeight: 500 }}
                >
                  Multiple Building
                </Typography>
              </Stack>
            </Stack>

            <Stack
              direction="row"
              justifyContent="end"
              alignItems="center"
              sx={{ marginTop: "50px" }}
            >
              <Button
                onClick={()=>{
                  // setTrigger("done")
                  }}
                type="submit"
                size="large"
                className="univercel_btn_class"
                sx={{ textTransform: "none", color: "#fff" }}
                endIcon={<KeyboardDoubleArrowRightIcon />}
              >
                Next
              </Button>
            </Stack>

            </form>
          </Stack>

    </Stack>
      </Stack>
</Stack>
        
        </>
    )
}