// @ts-nocheck
 import CPC from "./context";
import { CPS } from "./services/cps";


export default function CPContextState(props:any)  {
    const CP = new CPS()
    return (
    <CPC.Provider value={CP}>
        {props.children}
    </CPC.Provider>
  );
}

