import React, { useContext, useEffect, useState } from 'react';
import { Box, Button, Radio, Stack, TextField, Typography } from "@mui/material";
import DoneIcon from '@mui/icons-material/Done';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import Pagination from '@mui/material/Pagination';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';
import Checkbox from '@mui/material/Checkbox';
import Modal from '@mui/material/Modal';
import { useNavigate, Link, useLocation } from "react-router-dom";
import axios from 'axios';
import { ProjectDashboardSidebar } from '../../../BuilderComponents/ProjectDashboard/DashboardComponents/ProjectDashboardSidebar';
import { NavbarForDirectory } from '../../../BuilderComponents/ProjectDashboard/DashboardComponents/NavbarForDirectory';
import { Apiaddress } from '../../../../utils/consts';


export const EditFloorPlan = ()=>{
    const navigate = useNavigate();
    const location = useLocation();
    let prevUnitId = location.state;
    console.log("unit id: ", prevUnitId)

    ////get data from previous page 
     const data = location.state.state;
     const floorData = location.state.current_data
   
    console.log("preve data =>.>",data)
  
    let [current_data, setCurrentData] = useState({})
  
  const projectfromlocal = JSON.parse(localStorage.getItem("BuilderSelected"))
  const projectID = projectfromlocal.selectedProjectID
  
  
      const [floorplan,setfloorplan]=useState([]);
  
      const handlechange=(event,ele)=>{
  
       let data= floorplan?.map((element,idx)=>{
           if(idx==ele){
            element.istrue=event.target.value
           }
           else{
             element.istrue="false"
           }
           return element;
        })
        setfloorplan(data); 
      }
  
      //  console.log(floorplan);
      console.log(current_data);
  
    //get unit Details from database 
const [unitName,setUnitName] = useState("");

const fetchfloorplan=()=>{
  const uniturl = Apiaddress+"/api/projects/unit/?unit_id="+prevUnitId
 axios.get(uniturl)
 .then((res)=>{
      const data = res?.data
      console.log("unit data=>>>>>>>.",data)
      setUnitName(data.unit[0].name)
    //   setbedroomData(data?.unit[0].layout_data.bedroomData)
    //     setCurrentData(data?.unit[0].layout_data)
    setCurrentData(data?.unit[0].layout_data);
    if(data?.unit[0].layout_data.floorplan!==null){
        setfloorplan(data?.unit[0].layout_data.floorplan);
    }
   })
   .catch((err)=>{
     console.log(err)
   })
}
useEffect(()=>{
  
  fetchfloorplan();
  fetchfloorplan();
  fetchfloorplan();
  fetchfloorplan();
   
},[])
  
     //function for create unit Templates
    const createfloorplanTemplate = ()=>{
  
        setCurrentData({...current_data,floorplan: floorplan});
        console.log({...current_data,floorplan: floorplan}); 
  
      let url = Apiaddress+"/api/projects/unit/?unit_id="+prevUnitId
      
      let st = {
        unit_id:prevUnitId,
        layout_data:{...current_data,floorplan: floorplan}
      }
  
      let headers = {
        headers:{
          "Content-Type":"application/json"
      }
      }
  
      axios.patch(url,st,headers)
      .then((res)=>{
        console.log("update floorplan in unit======>", res.data);
        navigate("/unit-directory-w-sb/edit")
      })
      .catch((err)=>{
        console.log(err);
      })
  
      }
    return(
        <>
                   <Stack direction="row" sx={{width:"100%",height:"auto" }}>
      {/* 1st stack */}
      <Stack sx={{minHeight:"100vh", width:"22%",boxSizing:"border-box",}}>
      <ProjectDashboardSidebar active={"Unit Directory"}/>
      </Stack>

     {/* 2nd stack */}
      <Stack direction="column" sx={{ backgroundColor:"#f9f9f9",width:"78%", padding:"25px",boxSizing:"border-box",}}  >
      {/* <Navbar/> */}
      <NavbarForDirectory
            leftRightTitle1={"Unit Dashboard"}
            leftRightTitle2={"Unit Directory"}
            leftTitle={"Project Dashboard"}
            showLeftTitle={false}
            righshow={true}
          />
         

         <Typography sx={{marginTop:"20px", fontSize:"28px", fontWeight:"600", marginLeft:"10px", marginBottom:"50px"}}>
                    Floor Plan Overview
           </Typography>
     {/* actual content  */}
     <Stack sx={{ mt:"28px",backgroundColor:"#f9f9f9",}}>

     <Stack direction="column"  sx={{  padding:"20px", backgroundColor:"#fff", marginRight:"0px"}}>
         
     <Stack direction="row" spacing={1.5}>
     <Stack direction="row" alignItems="center" spacing={1.5} sx={{margin:"70px 0px 70px 0px"}}>
     <Box  sx={{width:"29px", height:"29px",borderRadius:"50%", backgroundColor:"#FFA946", display:"flex", justifyContent:"center", alignItems:"center"}}>
        <DoneIcon sx={{color:"#fff", fontSize:"20px"}}/>
      </Box>
      <Typography sx={{fontSize:"18px", fontWeight:"500"}}>{projectfromlocal.projectName}</Typography>
    </Stack>
    <Stack direction="row" alignItems="center" spacing={1.5} sx={{margin:"70px 0px 70px 0px"}}>
     <Box  sx={{width:"29px", height:"29px",borderRadius:"50%", backgroundColor:"#FFA946", display:"flex", justifyContent:"center", alignItems:"center"}}>
        <DoneIcon sx={{color:"#fff", fontSize:"20px"}}/>
      </Box>
      <Typography sx={{fontSize:"18px", fontWeight:"500"}}>{unitName}</Typography>
    </Stack>
    <Stack direction="row" alignItems="center" spacing={1.5} sx={{margin:"70px 0px 70px 0px"}}>
     <Box  sx={{width:"29px", height:"29px",borderRadius:"50%", backgroundColor:"#FFA946", display:"flex", justifyContent:"center", alignItems:"center"}}>
        <DoneIcon sx={{color:"#fff", fontSize:"20px"}}/>
      </Box>
      <Typography sx={{fontSize:"18px", fontWeight:"500"}}>{"Floor Plane"}</Typography>
    </Stack>
     </Stack>

        <Box sx={{margin:"0px 0px 0px 60px"}}>

            <Stack direction="row" sx={{margin:"0px 0px 0px 0px",display:"flex",alignItems:"center"}} >
              <Typography sx={{fontSize:"18px", fontWeight:"500"}}>Floor plan</Typography>
              <Link to="/unit-directory-w-sb/edit-floorplan-add" state={prevUnitId}>
              <AddIcon sx={{color:"#A2A3A6",fontSize:"18px",marginLeft:"10px",cursor:"pointer"}}/>
              </Link>
            </Stack>

            <Box sx={{display:"flex",flexWrap:"wrap",margin:"30px 0px 0px 0px"}}>
            {floorplan?.map((ele,idx)=>{
                return(
                 <Stack direction="row" sx={{alignItems:"center",width:"35%",margin:"20px 10px 20px 0px"}}>
                 <Box sx={{width:"100px",height:"100px",borderRadius:"20px",backgroundColor:"#D9D9D9"}}>
                  <img src={Apiaddress+ele.image} style={{height:"100%",width:"100%",borderRadius:"20px"}}></img>
                 </Box> 
                 
                  <input type="radio" id="r1" name="rate" value="true" style={{width:"30px", marginBottom:"4px"}} checked={ele?.istrue === "true"} onChange={(e)=>{handlechange(e,idx)}}></input>

                 <Typography sx={{color:"#A4A4AD", fontSize:"12px", fontWeight:500, marginRight:"10px"}}>Highlight</Typography>
                 <DeleteIcon sx={{fontSize:"26px", fontWeight:"500",color:"#EF3649",margin:"0px 0px 0px 0px",cursor:"pointer"}}
                   onClick={()=>{
                            const newfloorplan = floorplan.filter((x) => x !== ele);
                            setfloorplan(newfloorplan);
                        }}
                />
              </Stack>
                )
            })}
            </Box>

            <Stack direction="row" sx={{alignItems:"center"}}>

            <Box 
             onClick={()=>{
              navigate(-1)
            }}
            sx={{display:"flex",alignItems:"center",margin:"0px 0px 0px 0px"}}>
            <ArrowBackIcon/>
            <Typography sx={{fontSize:"18px", fontWeight:"500",margin:"0px 10px 0px 10px"}}>Back</Typography>
            </Box>
            
             <Box sx={{margin:"60px 0px 60px 60px"}}>

                <Button 
            onClick={()=>{
            createfloorplanTemplate()
              }}
                className="univercel_btn_class"
                variant="contained"
                sx={{padding:"16px 50px 16px 50px", color:"#fff",fontSize:"18px", fontWeight:"700",height:"50px"}}>
                    Save 
                </Button>
              {/* </Link> */}

            </Box>

            </Stack>
           </Box>
         

     </Stack>

     </Stack>
 


      </Stack>
</Stack>
        </>
    )
}