import {
  Box,
  Container,
  Grid,
  Paper,
  Stack,
  styled,
  Typography,
} from "@mui/material";

import HomeBuyingExperience from "../../Imgs/home-buying-experience.png";
import BuyerLandingProjectDelays from "../../Imgs/buyer-landing-project-delays-icon.svg";
import BuyerLandingTediousDocument from "../../Imgs/buyer-landing-tedious-document-icon.svg";
import BuyerLandingLackOfTransparency from "../../Imgs/buyer-landing-lack-of-transparency-icon.svg";
import BuyerLandingMultipleSiteVisits from "../../Imgs/buyer-landing-multiple-site-visits-icon.svg";

const Item3 = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(1),
  color: theme.palette.text.secondary,
  boxShadow: "none",
  backgroundColor: "transparent",
}));

const BuyerLandingHomeBuyingExperience = () => {
  return (
    <Box sx={{ overflowX: "hidden" }}>
      <Container>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: "30px",
            flexDirection: { xs: "column-reverse", md: "row" },
          }}
        >
          <Box sx={{ width: { xs: "100%", md: "55%" } }}>
            <Stack>
              <Typography
                data-aos="fade-down"
                sx={{
                  fontSize: { xs: "32px", md: "40px" },
                  fontWeight: 700,
                  fontFamily: "Circular Std",
                  color: "#272D4E",
                  lineHeight: "1.3",
                  display: { xs: "none", md2: "block" },
                }}
              >
                Home-buying experience <br /> can be daunting in India
              </Typography>
              <Typography
                data-aos="fade-down"
                sx={{
                  fontSize: { xs: "32px", md: "40px" },
                  fontWeight: 700,
                  fontFamily: "Circular Std",
                  color: "#272D4E",
                  lineHeight: "1.3",
                  textAlign: { xs: "center", md: "left" },
                  display: { xs: "block", md2: "none" },
                }}
              >
                Home-buying experience can be daunting in India
              </Typography>
              <Typography
                data-aos="fade-up"
                sx={{
                  marginBottom: "10px",
                  fontSize: "16px",
                  fontFamily: "Product Sans",
                  color: "#343434",
                  display: { xs: "none", md2: "block" },
                }}
              >
                Buying a property in India often comes with a poor <br />
                post-purchase experience, diminishing your trust in Real <br />
                Estate Developers and Builders
              </Typography>
              <Typography
                data-aos="fade-up"
                sx={{
                  marginBottom: "10px",
                  fontSize: "16px",
                  fontFamily: "Product Sans",
                  color: "#343434",
                  textAlign: { xs: "center", md: "left" },
                  display: { xs: "block", md2: "none" },
                }}
              >
                Buying a property in India often comes with a poor post-purchase
                experience, diminishing your trust in Real Estate Developers and
                Builders
              </Typography>
            </Stack>
            <Box sx={{ flexGrow: 1, py: "30px" }}>
              <Grid container spacing={2}>
                <Grid xs={12} md2={6} data-aos="fade-right">
                  <Item3>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: "10px",
                        background: "#FFFFFF",
                        border: "1px solid #FFAF2A",
                        boxShadow:
                          "0px 8px 22px -6px rgba(24, 39, 75, 0.12), 0px 14px 64px -4px rgba(24, 39, 75, 0.12)",
                        borderRadius: "8px",
                        padding: "10px",
                      }}
                    >
                      <Box>
                        <img src={BuyerLandingProjectDelays} alt="" />
                      </Box>
                      <Typography
                        sx={{
                          fontSize: "18px",
                          fontWeight: 400,
                          fontFamily: "Product Sans",
                          color: "#343434",
                        }}
                      >
                        Project delays
                      </Typography>
                    </Box>
                  </Item3>
                </Grid>
                <Grid xs={12} md2={6} data-aos="fade-left">
                  <Item3>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: "10px",
                        background: "#FFFFFF",
                        border: "1px solid #FF4267",
                        boxShadow:
                          "0px 8px 22px -6px rgba(24, 39, 75, 0.12), 0px 14px 64px -4px rgba(24, 39, 75, 0.12)",
                        borderRadius: "8px",
                        padding: "10px",
                      }}
                    >
                      <Box>
                        <img src={BuyerLandingTediousDocument} alt="" />
                      </Box>
                      <Typography
                        sx={{
                          fontSize: "18px",
                          fontWeight: 400,
                          fontFamily: "Product Sans",
                          color: "#343434",
                        }}
                      >
                        Tedious document management
                      </Typography>
                    </Box>
                  </Item3>
                </Grid>
                <Grid xs={12} md2={6} data-aos="fade-right">
                  <Item3>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: "10px",
                        background: "#FFFFFF",
                        border: "1px solid #0890FE",
                        boxShadow:
                          "0px 8px 22px -6px rgba(24, 39, 75, 0.12), 0px 14px 64px -4px rgba(24, 39, 75, 0.12)",
                        borderRadius: "8px",
                        padding: "10px",
                      }}
                    >
                      <Box>
                        <img src={BuyerLandingLackOfTransparency} alt="" />
                      </Box>
                      <Typography
                        sx={{
                          fontSize: "18px",
                          fontWeight: 400,
                          fontFamily: "Product Sans",
                          color: "#343434",
                        }}
                      >
                        Lack of transparency in documentation and purchase
                        process
                      </Typography>
                    </Box>
                  </Item3>
                </Grid>
                <Grid xs={12} md2={6} data-aos="fade-left">
                  <Item3>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: "10px",
                        background: "#FFFFFF",
                        border: "1px solid #00C29A",
                        boxShadow:
                          "0px 8px 22px -6px rgba(24, 39, 75, 0.12), 0px 14px 64px -4px rgba(24, 39, 75, 0.12)",
                        borderRadius: "8px",
                        padding: "10px",
                      }}
                    >
                      <Box>
                        <img src={BuyerLandingMultipleSiteVisits} alt="" />
                      </Box>
                      <Typography
                        sx={{
                          fontSize: "18px",
                          fontWeight: 400,
                          fontFamily: "Product Sans",
                          color: "#343434",
                          display: { xs: "none", lg: "block" },
                        }}
                      >
                        Multiple site visits that <br /> are inconvenient and{" "}
                        <br />
                        time-consuming
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: "18px",
                          fontWeight: 400,
                          fontFamily: "Product Sans",
                          color: "#343434",
                          display: { xs: "block", lg: "none" },
                        }}
                      >
                        Multiple site visits that are inconvenient and
                        time-consuming
                      </Typography>
                    </Box>
                  </Item3>
                </Grid>
              </Grid>
            </Box>
          </Box>

          <Box sx={{ width: { xs: "100%", md: "45%" } }}>
            <img
              data-aos="fade-left"
              style={{ width: "100%" }}
              src={HomeBuyingExperience}
              alt=""
            />
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

export default BuyerLandingHomeBuyingExperience;
