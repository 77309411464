import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Backdrop,
  Box,
  Button,
  Checkbox,
  Drawer,
  Fade,
  FormControl,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { Stack } from "@mui/system";
import TuneOutlinedIcon from "@mui/icons-material/TuneOutlined";
import KeyboardArrowRightOutlinedIcon from "@mui/icons-material/KeyboardArrowRightOutlined";
import KeyboardArrowUpOutlinedIcon from "@mui/icons-material/KeyboardArrowUpOutlined";
import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined";
import { CheckBox } from "@mui/icons-material";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import DriveFileRenameOutlineIcon from "@mui/icons-material/DriveFileRenameOutline";
import VisibilityIcon from "@mui/icons-material/Visibility";
import DeleteIcon from "@mui/icons-material/Delete";
import "./LDmainPage.css";
import React, { useContext, useEffect, useState } from "react";
import RightSidebar from "./RightSidebar";
import { DataGrid, GridExpandMoreIcon } from "@mui/x-data-grid";
import CategoryIcon from "@mui/icons-material/Category";
import HandshakeIcon from "@mui/icons-material/Handshake";
import axios from "axios";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import LDcustomTable from "./LDcustomTable";
import { api, Apiaddress } from "../../../../utils/consts";
import profileContext from "../../../../context/profile/context";
import { NavbarForDirectory } from "../../../BuilderComponents/ProjectDashboard/DashboardComponents/NavbarForDirectory";
import TabBar from "../../Components/tabBar/TabBar";
import CPC from "../../context/context";
import { useNavigate } from "react-router-dom";
import { AuthService } from "../../../../context/buyer/services/auth";
import CircularProgress from "@mui/material/CircularProgress";
import ReportProblemIcon from "@mui/icons-material/ReportProblem";
import { GridCloseIcon } from "@mui/x-data-grid";

function LDmainPage(p) {

  ////get user roles from local storage and assign
  let roles = JSON.parse(localStorage.getItem("user_roles"));
  let lead = roles["lead"];
  let leadPriority = roles["lead prority"];
  const [TabIndex, setTabIndex] = useState(0);
  const cpDetails = JSON.parse(localStorage.getItem('cpDetails'));
  console.log(cpDetails);
  let cpOrg = cpDetails?.channelPartnerOrg;

  const [state, setState] = React.useState(false);

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 450,
    bgcolor: "background.paper",
    border: "2px solid #fff",
    borderRadius: "10px",
    boxShadow: 24,
    p: 4,
  };

  const [confirmText, setConfirmText] = useState("");
  const [toDelete, setToDelete] = useState();
  const [match, setMatch] = useState(false);
  const [open, setOpen] = useState(false);
  const handleClose = () => setOpen(false);
  const handleOpen = () => {
    setOpen(true);
  }

  const [trigger, setTrigger] = useState(false);
  const [selectedRows, setSelectedRows] = React.useState([]);
  useEffect(() => {
    console.log(selectedRows);
  }, [selectedRows]);

  const [curselectedRows, setcurselectedRows] = useState([]);
  const [rowSelected, setRowSelected] = useState(false);

  console.log(curselectedRows);
  const [tabledata, setTabledata] = useState(null);
  const [rowData, setrowData] = useState([]);
  const [idCont, setidCunt] = useState(1);
  const [orgID, setOrgID] = useState(null);
  const [leadsFromApi, setleadsFromApi] = useState([]);
  const [loading, setloading] = useState(false);
  const [curpage, setcurpage] = useState(1);
  const [totalLead,setTotalLead] = useState(null);
  const [startLead,setStartLead] = useState(0);
  //context

  const user = useContext(profileContext);

  useEffect(() => {
    console.log(user);
    if (user)
      if (user.UserState)
        if (user.UserState.user_obj)
          if (user.UserState.user_obj.id) setOrgID(user.UserState.user_obj.id);
  }, [user]);
  //context
  const cpc = useContext(CPC);

  const fetchLeads = async () => {
    setloading(true);
    const Auth = new AuthService();
    try {
      let Leads = [];
      if (TabIndex == 0) {
        setleadsFromApi([]);
        let url1 = "";
        if (cpOrg) {
          url1 = Apiaddress + `/api/leads/CpClient/?org_id=${cpOrg}&limit=${7}&offset=${startLead}`
        }
        else {
          url1 = Apiaddress + `/api/leads/CpClient/?cp_id=${Auth.getCPID()}&limit=${7}&offset=${startLead}`
        }
        const res = await axios.get(url1);
        if (res.data.Client == "Client not found") {
          setleadsFromApi([]);
        } else {
          setleadsFromApi(res.data.Client);
          setTotalLead(res?.data?.total);
        }
        setloading(false);
      } else if (TabIndex == 1) {
        setleadsFromApi([]);
        let url1 = "";
        if (cpOrg) {
          url1 = Apiaddress + `/api/leads/CpClient/?org_id=${cpOrg}&limit=${7}&offset=${startLead}`
        }
        else {
          url1 = Apiaddress + `/api/leads/CpClient/?cp_id=${Auth.getCPID()}&limit=${7}&offset=${startLead}`
        }
        const res1 = await axios.get(url1);
        if (res1.data.Client == "Client not found") {
          setleadsFromApi([]);
        } else {
          Leads = [...Leads, ...res1.data.Client];
        }
        console.log(Leads);
        setleadsFromApi(Leads);
        setloading(false);
      }
      setloading(false);
    } catch (err) {
      console.log(err);
      setloading(false);
    }
  };

  // useEffect(() => {
  //   if (leadsFromApi) {
  //     let arr = [];
  //     for (let i = 0; i < leadsFromApi.length; i++) {
  //       // console.log(leadsFromApi[i]);
  //       let obj = {
  //         id: i + 1,
  //         lead_id: leadsFromApi[i].id,
  //         Name: leadsFromApi[i]?.lead?.name,
  //         Phone: leadsFromApi[i]?.lead?.phone,
  //         Email: leadsFromApi[i]?.lead?.email,
  //         Projects: leadsFromApi[i].project,
  //         Prioprity: leadsFromApi[i]?.lead?.lead_prority?.name,
  //         DetailedView: "View",
  //         Address: leadsFromApi[i].address_line,
  //       };
  //       setidCunt(idCont + 1);
  //       arr.push(obj);
  //     }
  //     setrowData(arr);
  //   }
  // }, [leadsFromApi, trigger]);

  useEffect(() => {
    if (orgID) fetchLeads();
  }, [orgID, state, trigger, TabIndex]);

  const navigator = useNavigate();

  const updateLead = (item) => {
    navigator("/channelpartner/leads/" + item?.id);
  };

  const delIconClick = async (item) => {
    console.log(item);
    try {
      let arr = [];
      arr.push(item?.id);
      const obj = { Client_id: arr };
      const res = await axios.delete(Apiaddress + "/api/leads/CpClient/", {
        data: obj,
      });
      setTrigger(!trigger);
      console.log(res);
      handleClose();
      setConfirmText("");
      setMatch(false);
    } catch (err) {
      console.log(err);
    }
  };

  //fitering data starts:====
  const [filterselect, setfilterselect] = useState();
  const [filtervalue, setfiltervalue] = useState();

  const handleChange = (event) => {
    setfilterselect(event.target.value);
    setfiltervalue("");
  };

  console.log(filterselect);
  console.log(filtervalue);

  const fetchfilter = async () => {
    try {
      const Auth = new AuthService();
      let url1 = "";
        if (cpOrg) {
          url1 = Apiaddress + `/api/leads/CpClient/?org_id=${cpOrg}&limit=${7}&offset=${startLead}&filterkey=lead__${filterselect}&filtervalue=${filtervalue}`
        }
        else {
          url1 = Apiaddress + `/api/leads/CpClient/?cp_id=${Auth.getCPID()}&limit=${7}&offset=${startLead}&filterkey=lead__${filterselect}&filtervalue=${filtervalue}`
        }
        const res = await axios.get(url1);
        console.log(res?.data);
        if (res.data.Client == "Client not found") {
          setleadsFromApi([]);
        } else {
          setleadsFromApi(res.data.Client);
          setTotalLead(res?.data?.total);
        }
    } catch (err) {
      console.log(err);
    }
  };

  const cpuser = JSON.parse(localStorage.getItem("crm_user"));
  let username = cpuser?.user_details?.first_name;

  return (
    <>
      <Stack direction="row" sx={{ width: "100%", height: "auto" }}>
        <Stack
          sx={{
            backgroundColor: "#f9f9f9",
            width: "100%",
            boxSizing: "border-box",
          }}
        >
          {" "}
          {/* actual content  */}
          <Stack sx={{ backgroundColor: "#f9f9f9" }}>

            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              sx={{ height: "68px", marginBottom: "20px" }}

            >
              <Box>
                <Typography
                  sx={{
                    fontSize: "22px",
                    fontWeight: "600",
                    color: "black",
                  }}
                >
                  Clients Directory
                </Typography>
                <Typography
                  sx={{
                    color: "#B9B7D0",
                    fontSize: "16px",
                    fontWeight: "400",
                  }}
                >
                 Manage your Clients
                </Typography>
              </Box>
            </Stack>

            <TabBar
              onIndexChange={(e) => {
                setTabIndex(e);
                setStartLead(0);
                setcurpage(1);
                setTrigger(!trigger);
                setfiltervalue("");
              }}
              Tabs={[
                {
                  children: [
                    <LDcustomTable
                      newRowData={leadsFromApi}
                      updateLead={updateLead}
                      delIconClick={delIconClick}
                      setRowSelected={setRowSelected}
                      rowSelected={rowSelected}
                      loading={loading}
                      CircularProgress={CircularProgress}
                      handleOpen={handleOpen}
                      setToDelete={setToDelete}
                      setStartLead={setStartLead}
                      startLead={startLead}
                      curpage={curpage}
                      setcurpage={setcurpage}
                      filterselect={filterselect}
                      filtervalue={filtervalue}
                      setfilterselect={setfilterselect}
                      setfiltervalue={setfiltervalue}
                      handleChangeFilter={handleChange}
                      fetchfilter={fetchfilter}
                      totalLead={totalLead}
                      trigger={trigger}
                      setTrigger={setTrigger}
                    />,
                  ],
                  Name: "Self Clients",
                },
                {
                  children: [
                    <LDcustomTable
                      newRowData={leadsFromApi}
                      updateLead={updateLead}
                      delIconClick={delIconClick}
                      setRowSelected={setRowSelected}
                      rowSelected={rowSelected}
                      loading={loading}
                      CircularProgress={CircularProgress}
                      handleOpen={handleOpen}
                      setToDelete={setToDelete}
                      setStartLead={setStartLead}
                      startLead={startLead}
                      curpage={curpage}
                      setcurpage={setcurpage}
                      filterselect={filterselect}
                      filtervalue={filtervalue}
                      setfilterselect={setfilterselect}
                      setfiltervalue={setfiltervalue}
                      handleChange={handleChange}
                      fetchfilter={fetchfilter}
                      totalLead={totalLead}
                      trigger={trigger}
                      setTrigger={setTrigger}
                    />,
                  ],
                  Name: "All Clients",
                },
              ]}
            ></TabBar>
          </Stack>
        </Stack>
      </Stack>
      {/* delete template popup */}
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <Box sx={style}>
            <Box
              sx={{
                display: "flex",
                marginBottom: "25px",
                justifyContent: "space-between",
                marginTop: "-10px",
              }}
            >
              <Box sx={{ display: "flex", gap: "0.2rem" }}>
                <ReportProblemIcon sx={{ color: "red" }} />
                <Typography
                  sx={{
                    fontFamily: "Product Sans",
                    fontSize: "18px",
                    fontWeight: "600",
                    color: "red",
                  }}
                >
                  Delete Client
                </Typography>
              </Box>
              <Box sx={{ textAlign: "right" }}>
                <GridCloseIcon
                  sx={{ color: "gray", cursor: "pointer" }}
                  onClick={handleClose}
                />
              </Box>
            </Box>
            <Box>
              <Typography
                id="transition-modal-title"
              //   sx={{ color: success ? "green" : "black" }}
              >
                Confirm that you want to delete this Client by typing - "
                <span sx={{ fontWeight: "600" }}>{toDelete?.lead?.name}</span>
                "?
              </Typography>
              <TextField
                placeholder={toDelete?.lead?.name}
                onChange={(data) => {
                  setMatch(false);
                  setConfirmText(data.target.value);
                  if (data.target.value === toDelete?.lead?.name) { 
                    setMatch(true);
                    console.log("Yes..")
                  }
                }}
                sx={{ mt: 3, width: "100%" }}
              />
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                mt: 3,
              }}
            >
              <Button
                onClick={handleClose}
                sx={{
                  color: "#B9B7D0",
                }}
              >
                Cancel
              </Button>
              <Button
                disabled={!match}
                sx={{
                  width: "20%",
                  color: "#06386B",
                  textTransform: "none",
                  fontFamily: "Product Sans",
                  ml: 1,
                  background: !match ? "gray" : "red",
                  "&:hover": {
                    background: "red",
                    boxShadow: "0px 9px 12px rgba(242, 178, 73, 0.59)",
                  },
                }}
                className="univercel_btn_class"
                onClick={() => { delIconClick(toDelete) }}
              >
                Yes
              </Button>
            </Box>
          </Box>
        </Fade>
      </Modal>
    </>
  );
}

export default LDmainPage;
