import { Box, Checkbox, Stack, Button, Typography, CircularProgress } from "@mui/material";
import React, { useEffect, useState } from "react";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { UserRole } from "../../../UserRoles/UserRoles";

function TDcustomTable({
  newRowData,
  updateBuyers,
  setTrigger,
  trigger,
  viewMembers,
  delIconClick,
  updateTeam,
  toggleDrawer,
  setState,
}) {

  ////get user roles from local storage and assign 
  let roles = JSON.parse(localStorage.getItem("user_roles"))
  let teams = roles["teams"];

  //   console.log(newRowData);
  const [curpage, setcurpage] = useState(1);
  const [pageRange, setPageRange] = useState([0, 6]);

  const nextClick = () => {
    if (
      !(newRowData.length >= pageRange[0] && newRowData.length <= pageRange[1])
    ) {
      setcurpage(curpage + 1);
      setPageRange([pageRange[0] + 6, pageRange[1] + 6]);
    }
  };

  const prvClick = () => {
    if (pageRange[0] != 0 && pageRange[1] != 6) {
      setcurpage(curpage - 1);
      setPageRange([pageRange[0] - 6, pageRange[1] - 6]);
    }
  };

  const [pages, setPages] = useState([]);
  useEffect(() => {
    if (newRowData) {
      let arr = [];
      for (
        let i = 1;
        i <=
        (newRowData.length % 6 == 0
          ? newRowData.length / 6
          : newRowData.length / 6 + 1);
        i++
      ) {
        arr.push(i);
      }
      setPages(arr);
    }
  }, [newRowData]);

  return (
    <Box>
        <Box sx={{ color: "black", width: "100%" }}>

           <Stack direction="row" justifyContent="space-around" alignItems="center" sx={{width:"100%",backgroundColor:"white",borderRadius: "5px",margin:"0px 0px 10px 0px",padding:"10px 0px 10px 0px"}}>
              
                <Stack direction="row" justifyContent="left" alingItems="center" sx={{width:"15vw",textAlign:"center",color: "#9299BC",fontWeight: "600",paddingLeft:"20px"}}>
                        <Typography sx={{ display: "grid", placeItems: "center",fontWeight:"600",color:"black"}}>Name</Typography>
                        {/* <Box sx={{ display: "grid", marginLeft: "4px" }}>
                            <ArrowDropUpIcon fontSize="medium" sx={{ marginBottom: "-8px" }}/>
                            <ArrowDropDownIcon fontSize="medium" sx={{ marginTop: "-8px" }}/>
                        </Box> */}
                </Stack>

                 <Stack direction="row" justifyContent="left" alingItems="center" sx={{width:"15vw",textAlign:"center",color: "#9299BC",fontWeight: "600",paddingLeft:"20px"}}>
                        <Typography sx={{ display: "grid", placeItems: "center",fontWeight:"600",color:"black" }}>Member</Typography>
                        {/* <Box sx={{ display: "grid", marginLeft: "4px" }}>
                            <ArrowDropUpIcon fontSize="medium" sx={{ marginBottom: "-8px" }}/>
                            <ArrowDropDownIcon fontSize="medium" sx={{ marginTop: "-8px" }}/>
                        </Box> */}
                </Stack>

                 <Stack direction="row" justifyContent="left" alingItems="center" sx={{width:"15vw",textAlign:"center",color: "#9299BC",fontWeight: "600",paddingLeft:"20px"}}>
                        <Typography sx={{ display: "grid", placeItems: "center",fontWeight:"600",color:"black" }}>Action</Typography>
                        {/* <Box sx={{ display: "grid", marginLeft: "4px" }}>
                            <ArrowDropUpIcon fontSize="medium" sx={{ marginBottom: "-8px" }}/>
                            <ArrowDropDownIcon fontSize="medium" sx={{ marginTop: "-8px" }}/>
                        </Box> */}
                </Stack>

            </Stack>

          {newRowData.length==0?
           <Box sx={{textAlign:"center",marginTop:"50px"}}><Typography sx={{fontSize:"25px",fontWeight:"500",color:"#B9B7D0",}}>No Matches Found</Typography></Box>:
           <Box>
          {newRowData &&
            newRowData.map((item, index) => {
              if (index >= pageRange[0] && index <= pageRange[1]) {
                return (
                   <Stack direction="row" justifyContent="space-around" alignItems="center" sx={{width:"100%",borderRadius: "5px",margin:"0px 0px 10px 0px",padding:"10px 0px 10px 0px",backgroundColor:"white",cursor:"pointer",color:"#343434"}}
                    onClick={()=>{
                      viewMembers(item)
                    }}
                  >
                    <Box
                      sx={{
                        width: "15vw",
                        display: "grid",
                        placeItems: "center",
                        justifyContent: "start",
                        fontFamily:"Product Sans",
                        fontWeight:"400",
                        paddingLeft:"20px",
                        // height: "50px",
                      }}
                    >
                      {item.Name}
                    </Box>
                    <Box
                      sx={{
                        width: "15vw",
                        display: "grid",
                        placeItems: "center",
                        justifyContent: "start",
                        fontFamily:"Product Sans",
                        fontWeight:"400",
                        paddingLeft:"20px",
                        // height: "50px",
                      }}
                    >
                      {item.Members}
                    </Box>
                    <Box
                      sx={{
                        width: "15vw",
                        display: "grid",
                        placeItems: "center",
                        justifyContent: "start",
                        fontFamily:"Product Sans",
                        fontWeight:"400",
                        paddingLeft:"20px",
                        // height: "50px",
                      }}
                    >
                      <Stack direction="row" sx={{ marginLeft: "0px" }}>
                        {UserRole(teams, "view_teams") && 
                        <RemoveRedEyeIcon
                          fontSize="medium"
                          onClick={(e) =>{e.stopPropagation();viewMembers(item)}}
                        />
                        }
                        {UserRole(teams, "change_teams") && 
                        <EditIcon
                          fontSize="medium"
                          sx={{ marginLeft: "10px" }}
                          onClick={(event)=>{
                            event.stopPropagation()
                            updateTeam(item);
                            setState(true);
                            // toggleDrawer(true, item)
                          }}
                        />
                        }  
                        {UserRole(teams, "delete_teams") && 
                        <DeleteIcon
                          fontSize="medium"
                          sx={{ marginLeft: "10px" }}
                          onClick={(event) =>{
                            event.stopPropagation();
                            delIconClick(item);
                          } 
                        }
                        />
                        }
                      </Stack>
                    </Box>
                  </Stack>
                );
              }
            })}
          

          <Box sx={{margin:"50px 0px 20px 0px"}}>
          <Box sx={{ display: "flex", justifyContent: "right" }}>
            {newRowData.length!=0 && 
            <Button variant="contained" sx={{background:"linear-gradient(90deg, #0F5DAE 0%, #009DCF 100.33%)"}}
              onClick={prvClick}>
                <ArrowBackIosIcon fontSize="small" />
                <Box sx={{ textTransform: "capitalize" }}>Previous</Box>
            </Button>}

            <Box sx={{background: "#E3E4EB",display: "flex",placeItems: "center",margin: " 0 10px",borderRadius: "5px"}}>
                <Box sx={{background: `white`,color: "black",width: "30px",borderRadius: "5px",margin: "0 10px",display: "grid",placeItems: "center"}}>{curpage}</Box>
                <Box sx={{color: "black",width: "30px",borderRadius: "5px",margin: "0 0px",display: "grid",placeItems: "center"}}>of</Box>
                <Box sx={{background: `white`,color: "black",width: "30px",borderRadius: "5px",margin: "0 10px",display: "grid",placeItems: "center"}}>{pages?.length}</Box>
            </Box>

            {newRowData.length!=0 && 
                <Button variant="contained" sx={{background:"linear-gradient(90deg, #0F5DAE 0%, #009DCF 100.33%)"}}
                    onClick={nextClick}>
                    <Box sx={{ textTransform: "capitalize" }}>Next</Box>
                    <ArrowForwardIosIcon fontSize="small" />
                </Button>
            }
          </Box>
        </Box>
        
          </Box>
          } 
        </Box>
  
    </Box>
  );
}

export default TDcustomTable;
