import { Box, Button, Container, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import HeaderOverlay from "../../Imgs/header-overlay.png";
import HeaderSliderBg from "../../Imgs/header-slider-bg.png";
import ChannelLandingHeaderSliderImg1 from "../../Imgs/channel-landing-header-slider-img1.png";
import ChannelLandingHeaderSliderImg2 from "../../Imgs/channel-landing-header-slider-img2.png";
import ChannelLandingHeaderSliderImg3 from "../../Imgs/channel-landing-header-slider-img3.png";
import CommonPagesNavbar from "../Shared/CommonPagesNavbar";
import "./ChannelLandingHeader.css";

const ChannelLandingHeader = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 7000,
  };
  return (
    <Slider {...settings}>
      <Box
        sx={{
          backgroundImage: `url(${HeaderSliderBg})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "contain",
          backgroundPosition: "right",
          overflowX: "hidden",
        }}
      >
        <Container sx={{ py: "20px" }}>
          <Typography
            sx={{
              fontSize: { lg: "36px", md: "28px", xs: "24px" },
              textAlign: "center",
              fontWeight: 700,
              color: "#272D4E",
              fontFamily: "Product Sans",
            }}
          >
            Welcome, Channel Partners! Let’s empower your venture.
          </Typography>
          <Box
            sx={{
              display: "flex",
              gap: { xs: "20px", md: "30px" },
              alignItems: "center",
              flexDirection: { xs: "column", md: "row" },
            }}
          >
            <Box
              sx={{
                width: { xs: "100%", md: "50%" },
                textAlign: { xs: "center", md: "left" },
              }}
            >
              <Typography
                data-aos="fade-down"
                sx={{
                  color: "#0085FF",
                  fontSize: { lg: "28px", md: "24px", xs: "20px" },
                  fontFamily: "Product Sans",
                  fontWeight: 600,
                  mt: "10px",
                }}
              >
                Real Estate CRM Software
              </Typography>
              <Typography
                data-aos="fade-right"
                data-aos-delay="400"
                sx={{
                  color: "#272D4E",
                  fontSize: "44px",
                  fontWeight: 700,
                  fontFamily: "Circular Std",
                  mt: "10px",
                  lineHeight: "1.1",
                  display: { xs: "block", md: "none" },
                }}
              >
                Power Up Your Partnerships
              </Typography>
              <Typography
                data-aos="fade-right"
                data-aos-delay="400"
                sx={{
                  color: "#272D4E",
                  fontSize: { md: "64px" },
                  fontWeight: 700,
                  fontFamily: "Circular Std",
                  mt: "10px",
                  lineHeight: "1.1",
                  display: { xs: "none", md: "block" },
                }}
              >
                Power Up Your Partnerships
              </Typography>
              <Typography
                data-aos="fade-zoom-in"
                data-aos-easing="ease-in-back"
                data-aos-delay="400"
                data-aos-offset="0"
                sx={{
                  color: "#343434",
                  fontSize: { lg: "24px", md: "18px", xs: "14px" },
                  fontFamily: "Product Sans",
                  mt: "10px",
                  display: { xs: "none", md: "block" },
                }}
              >
                With Real Estate Developers and Builders. Upgrade your business
                with an integrated Real Estate ecosystem.
              </Typography>
              <Typography
                data-aos="fade-zoom-in"
                data-aos-easing="ease-in-back"
                data-aos-delay="400"
                data-aos-offset="0"
                sx={{
                  color: "#343434",
                  fontSize: { lg: "24px", md: "18px", xs: "12px" },
                  fontFamily: "Product Sans",
                  mt: "10px",
                  display: { xs: "block", md: "none" },
                }}
              >
                With Real Estate Developers and Builders. Upgrade your business
                with an integrated Real Estate ecosystem.
              </Typography>
              <Button
                data-aos="zoom-out-up"
                data-aos-delay="400"
                sx={{
                  background:
                    "linear-gradient(97.85deg, #FFAF2A 0.95%, #FFC666 100%)",
                  fontWeight: 500,
                  fontSize: { lg: "28px", xs: "16px" },
                  boxShadow: "rgba(242, 178, 73, 0.59) 0px 9px 12px",
                  borderRadius: "10px",
                  color: "#06386B",
                  textTransform: "none",
                  padding: "5px 30px",
                  fontFamily: "Product Sans",
                  mt: "20px",
                  "&:hover": {
                    background:
                      "linear-gradient(97.85deg, #FFAF2A 0.95%, #FFC666 100%)",
                    boxShadow: "rgba(242, 178, 73, 0.59) 10px 12px 16px",
                  },
                }}
              >
                Sign Up For Free
              </Button>
            </Box>
            <Box
              data-aos="fade-left"
              data-aos-delay="800"
              sx={{
                width: { xs: "100%", md: "50%" },
              }}
            >
              <img
                className="header-img"
                src={ChannelLandingHeaderSliderImg1}
                alt=""
              />
            </Box>
          </Box>
        </Container>
      </Box>

      <Box
        sx={{
          backgroundImage: `url(${HeaderSliderBg})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "contain",
          backgroundPosition: "right",
          overflowX: "hidden",
        }}
      >
        <Container sx={{ py: "20px" }}>
          <Box
            sx={{
              display: "flex",
              gap: { xs: "20px", md: "30px" },
              alignItems: "center",
              flexDirection: { xs: "column", md: "row" },
            }}
          >
            <Box
              sx={{
                width: { xs: "100%", md: "50%" },
                textAlign: { xs: "center", md: "left" },
              }}
            >
              <Typography
                data-aos="fade-down"
                sx={{
                  color: "#0085FF",
                  fontSize: { lg: "28px", md: "24px", xs: "20px" },
                  fontFamily: "Product Sans",
                  fontWeight: 600,
                  mt: "10px",
                }}
              >
                One-Stop Solution
              </Typography>
              <Typography
                data-aos="fade-right"
                data-aos-delay="400"
                sx={{
                  color: "#272D4E",
                  fontSize: "44px",
                  fontWeight: 700,
                  fontFamily: "Circular Std",
                  mt: "10px",
                  lineHeight: "1.1",
                  display: { xs: "block", md: "none" },
                }}
              >
                Digitally Manage & Optimize
              </Typography>
              <Typography
                data-aos="fade-right"
                data-aos-delay="400"
                sx={{
                  color: "#272D4E",
                  fontSize: { md: "64px" },
                  fontWeight: 700,
                  fontFamily: "Circular Std",
                  mt: "10px",
                  lineHeight: "1.1",
                  display: { xs: "none", md: "block" },
                }}
              >
                Digitally Manage & Optimize
              </Typography>
              <Typography
                data-aos="fade-zoom-in"
                data-aos-easing="ease-in-back"
                data-aos-delay="400"
                data-aos-offset="0"
                sx={{
                  color: "#343434",
                  fontSize: { lg: "24px", md: "18px", xs: "14px" },
                  fontFamily: "Product Sans",
                  mt: "10px",
                  display: { xs: "none", md: "block" },
                }}
              >
                Projects, inventory, leads, and sales with time-saving
                workflows.
              </Typography>
              <Typography
                data-aos="fade-zoom-in"
                data-aos-easing="ease-in-back"
                data-aos-delay="400"
                data-aos-offset="0"
                sx={{
                  color: "#343434",
                  fontSize: { lg: "24px", md: "18px", xs: "12px" },
                  fontFamily: "Product Sans",
                  mt: "10px",
                  display: { xs: "block", md: "none" },
                }}
              >
                Projects, inventory, leads, and sales with time-saving
                workflows.
              </Typography>
              <Button
                data-aos="zoom-out-up"
                data-aos-delay="400"
                sx={{
                  background:
                    "linear-gradient(97.85deg, #FFAF2A 0.95%, #FFC666 100%)",
                  fontWeight: 500,
                  fontSize: { lg: "28px", xs: "16px" },
                  boxShadow: "rgba(242, 178, 73, 0.59) 0px 9px 12px",
                  borderRadius: "10px",
                  color: "#06386B",
                  textTransform: "none",
                  padding: "5px 30px",
                  fontFamily: "Product Sans",
                  mt: "20px",
                  "&:hover": {
                    background:
                      "linear-gradient(97.85deg, #FFAF2A 0.95%, #FFC666 100%)",
                    boxShadow: "rgba(242, 178, 73, 0.59) 10px 12px 16px",
                  },
                }}
              >
                Sign Up For Free
              </Button>
            </Box>
            <Box
              data-aos="fade-left"
              data-aos-delay="800"
              sx={{
                width: { xs: "100%", md: "50%" },
              }}
            >
              <img
                className="header-img"
                src={ChannelLandingHeaderSliderImg2}
                alt=""
              />
            </Box>
          </Box>
        </Container>
      </Box>

      <Box
        sx={{
          backgroundImage: `url(${HeaderSliderBg})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "contain",
          backgroundPosition: "right",
          overflowX: "hidden",
        }}
      >
        <Container sx={{ py: "20px" }}>
          <Box
            sx={{
              display: "flex",
              gap: { xs: "20px", md: "30px" },
              alignItems: "center",
              flexDirection: { xs: "column", md: "row" },
            }}
          >
            <Box
              sx={{
                width: { xs: "100%", md: "50%" },
                textAlign: { xs: "center", md: "left" },
              }}
            >
              <Typography
                data-aos="fade-down"
                sx={{
                  color: "#0085FF",
                  fontSize: { lg: "28px", md: "24px", xs: "20px" },
                  fontFamily: "Product Sans",
                  fontWeight: 600,
                  mt: "10px",
                }}
              >
                Unleash Growth
              </Typography>
              <Typography
                data-aos="fade-right"
                data-aos-delay="400"
                sx={{
                  color: "#272D4E",
                  fontSize: "44px",
                  fontWeight: 700,
                  fontFamily: "Circular Std",
                  mt: "10px",
                  lineHeight: "1.1",
                  display: { xs: "block", md: "none" },
                }}
              >
                Seamlessly Collaborate & Grow
              </Typography>
              <Typography
                data-aos="fade-right"
                data-aos-delay="400"
                sx={{
                  color: "#272D4E",
                  fontSize: { md: "64px" },
                  fontWeight: 700,
                  fontFamily: "Circular Std",
                  mt: "10px",
                  lineHeight: "1.1",
                  display: { xs: "none", md: "block" },
                }}
              >
                Seamlessly Collaborate & Grow
              </Typography>
              <Typography
                data-aos="fade-zoom-in"
                data-aos-easing="ease-in-back"
                data-aos-delay="400"
                data-aos-offset="0"
                sx={{
                  color: "#343434",
                  fontSize: { lg: "24px", md: "18px", xs: "14px" },
                  fontFamily: "Product Sans",
                  mt: "10px",
                  display: { xs: "none", md: "block" },
                }}
              >
                Get access to the data you need, transparent commissions, and
                build trust.
              </Typography>
              <Typography
                data-aos="fade-zoom-in"
                data-aos-easing="ease-in-back"
                data-aos-delay="400"
                data-aos-offset="0"
                sx={{
                  color: "#343434",
                  fontSize: { lg: "24px", md: "18px", xs: "12px" },
                  fontFamily: "Product Sans",
                  mt: "10px",
                  display: { xs: "block", md: "none" },
                }}
              >
                Get access to the data you need, transparent commissions, and
                build trust.
              </Typography>
              <Button
                data-aos="zoom-out-up"
                data-aos-delay="400"
                sx={{
                  background:
                    "linear-gradient(97.85deg, #FFAF2A 0.95%, #FFC666 100%)",
                  fontWeight: 500,
                  fontSize: { lg: "28px", xs: "16px" },
                  boxShadow: "rgba(242, 178, 73, 0.59) 0px 9px 12px",
                  borderRadius: "10px",
                  color: "#06386B",
                  textTransform: "none",
                  padding: "5px 30px",
                  fontFamily: "Product Sans",
                  mt: "20px",
                  "&:hover": {
                    background:
                      "linear-gradient(97.85deg, #FFAF2A 0.95%, #FFC666 100%)",
                    boxShadow: "rgba(242, 178, 73, 0.59) 10px 12px 16px",
                  },
                }}
              >
                Sign Up For Free
              </Button>
            </Box>
            <Box
              data-aos="fade-left"
              data-aos-delay="800"
              sx={{
                width: { xs: "100%", md: "50%" },
              }}
            >
              <img
                className="header-img"
                src={ChannelLandingHeaderSliderImg3}
                alt=""
              />
            </Box>
          </Box>
        </Container>
      </Box>
    </Slider>
  );
};

export default ChannelLandingHeader;
