import { Box, Button } from "@mui/material"
import { useNavigate } from "react-router-dom"



export const BackeToOverviewBtn = (props)=>{
    const navigate = useNavigate();

    return (
        <>
        <Box sx={{textAlign:"right",margin:"60px 0px 60px 0px"}}>
                <Button 
                className="univercel_btn_class"
                onClick={()=>{
                    if(props.SuperAdmin){
                        navigate("/super-admin-confirm-project")
                        return;
                    }else if(props.projectDirectory){
                       navigate("/projectdirectory")
                    }
                    else{
                        navigate("/confirm-project-overview")
                    }
                }}
                variant="contained"
                 sx={{padding:"16px 50px 16px 50px", color:"#fff",fontSize:"18px", fontWeight:"700"}}>
                    {props.projectDirectory? "Back To Project Directory":"Back To Overview"}

                </Button>
         </Box>
        </>
    )
}