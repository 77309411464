import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { AuthService } from "../../../../context/buyer/services/auth";
import { api, Apiaddress } from "../../../../utils/consts";
import SideBar from "../../Components/sideBar/SideBar";
import { LeadTs } from "../types/Lead.ts";
import "../../leads/styles/row.css";
import {
  Box,
  Button,
  FormControl,
  MenuItem,
  Select,
  Stack,
  TextareaAutosize,
  TextField,
  Typography,
} from "@mui/material";
import TabBar from "../../Components/tabBar/TabBar";
import PDmainPage from "../ProjectDirectory/PDmainPage";
import CPForms from "../forms";
import Modal from "@mui/material/Modal";
import { DataGrid, GridCloseIcon, GridExpandMoreIcon } from "@mui/x-data-grid";
import BuilderOrSelfProjectClient from "./BuilderOrSelfProjectClient";
import DeassignStandAloneUnitClient from "./DeassignStandAloneUnitClient";
import NavigateBack from "../../../CommonPages/Shared/NavigateBack";

function ClientOverview() {
  const navigate = useNavigate();
  const par = useParams();
  const url = api("/api/leads/CpClientById/?id=" + par.id);
  const [LeadDetails, setLeadDetails] = useState();
  const [Name, setName] = useState("");
  const [Email, setEmail] = useState("");
  const [PhoneNo, setPhoneNo] = useState("");
  const [Address, setAddress] = useState("");
  const [priority, setPriority] = useState("");
  const [priority_id, setPriorityId] = useState("");
  const [allpriority, setallpriority] = useState([]);
  const [information, setInformation] = useState("");
  const [addedOn, setAddedOn] = useState("");
  const [info, setInfo] = useState("");
  const [isClient, setIsClient] = useState();

  let lead_id = useParams().id;
  console.log(lead_id);

  const [curPage, setCurPage] = useState(1);
  const [selectedProject, setSelectedProject] = useState([]);
  const [lead, setLead] = useState();
  const [Builder, setBuilder] = useState([]);
  const [self, setSelf] = useState([]);
  const [standAlone, setStandAlone] = useState([]);
  const [trigger, setTrigger] = useState(false);
  const [projectFlag, setProjectFlag] = useState(2);

  console.log(priority);
  console.log(priority_id);

  console.log(Builder);
  console.log(self);
  console.log(standAlone);

  const deAllocateProject = async () => {
    try {
      if (TabIndex == 0) {
        if (projectFlag == 1) {
          console.log("builder Related");
        } else if (projectFlag == 2) {
          let temp = self;
          for (let i = 0; i < selectedProject?.length; i++) {
            let temp1 = temp.filter((ele) => {
              return ele != selectedProject[i];
            });
            temp = temp1;
          }
          // return;
          let obj = {
            Client_id: +lead_id,
            cpProjects: temp,
          };
          console.log(obj);
          const res = await axios.patch(
            Apiaddress + "/api/leads/CpClient/",
            obj
          );
          console.log(res.data);
          setSelectedProject([]);
        }
      } else if (TabIndex == 1) {
        let temp = standAlone;
        for (let i = 0; i < selectedProject?.length; i++) {
          let temp1 = temp.filter((ele) => {
            return ele != selectedProject[i];
          });
          temp = temp1;
        }

        let obj = {
          Client_id: +lead_id,
          cpStandaloneUnits: temp,
        };
        const res = await axios.patch(Apiaddress + "/api/leads/CpClient/", obj);
        console.log(res.data);
        setSelectedProject([]);
      }
      setTrigger(!trigger);
    } catch (err) {
      console.log(err);
    }
  };

  const fetchLeadDetails = async () => {
    try {
      const res = await axios.get(
        Apiaddress + `/api/leads/CpClientById/?id=${lead_id}`
      );
      console.log(res?.data?.Client);
      setLead(res?.data?.Client);
      setBuilder(res?.data?.Client?.projects);
      setSelf(res?.data?.Client?.cpProjects);
      setStandAlone(res?.data?.Client?.cpStandaloneUnits);
      let phoneNumber = res?.data?.Client?.lead?.phone.slice(4);
      console.log(phoneNumber);
      console.log("lead", LeadDetails);
      setName(res?.data?.Client?.lead?.name);
      setInfo(res?.data?.Client?.description);
      setPhoneNo(res?.data?.Client?.lead?.phone.slice(4));
      setAddress(res?.data?.Client?.lead?.address_line);
      setPriority(res?.data?.Client?.lead?.lead_prority?.name);
      setPriorityId(res?.data?.Client?.lead?.lead_prority?.id);
      setEmail(res?.data?.Client?.lead?.email);
      setAddedOn(res?.data?.Client?.lead?.created_on.toString().split("T")[0]);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    fetchLeadDetails();
  }, [trigger]);

  let editOrView = localStorage.getItem("editLeadDetails");
  console.log(editOrView);

  const getpriority = async () => {
    try {
      const res = await axios.get(Apiaddress + "/api/leads/leadprority/");
      console.log(res.data);
      setallpriority(res.data.leadprority);
    } catch (err) {
      console.log(err);
    }
  };

  // const xx = async () => {
  //     const Auth = new AuthService()
  //     const res = await (await Auth.makeUnAuthenticatedGetRequest(url)).json();
  //     setLeadDetails(res)
  //     console.log(res);
  //     let phoneNumber=res.lead[0].phone.slice(4);
  //     console.log(phoneNumber);
  //     console.log("lead",LeadDetails)
  //     setName(res.lead[0].name)
  //     setPhoneNo(res.lead[0].phone.slice(4))
  //     setAddress(res.lead[0].address_line)
  //     setPriority(res.lead[0]?.lead_prority?.name)
  //     setPriorityId(res.lead[0]?.lead_prority?.id)
  // }
  // const y = async () => {
  //     // while (Name == ""){
  //         try{
  //             await xx();
  //         } catch(e){
  //             console.log("err in Lead overview lead fetching")
  //         }
  //     // }
  // }
  // useEffect(() => {
  //     y();
  //     getpriority();
  // }, []);

  const [TabIndex, setTabIndex] = useState(0);

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <SideBar active={"Clients"}>
        <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        sx={{ marginBottom: "20px" }}
      >
         <Box>
              <Stack direction="row">
                  <Box sx={{ pt: "5px" }}>
                    <NavigateBack fontSize="22px" />
                  </Box>
                  <Typography
                    sx={{
                      fontSize: "22px",
                      fontWeight: "600",
                      color: "black",
                    }}
                    >
                    Client Overview
                  </Typography>
                </Stack>
                <Typography
                  sx={{
                    color: "#B9B7D0",
                    fontSize: "16px",
                    fontWeight: "400",
                  }}
                >
                  Manage your Clients
                </Typography>
              </Box>

      </Stack> 
      
        <Box sx={{ width: "100%", display: "flex", justifyContent: "" }}>
          <Box
            sx={{
              width: "75%",
              borderRadius: "8px",
              padding: "30px",
              backgroundColor: "#FFF",
              border: "2px solid transparent",
              my: "30px",
              "&:hover": {
                boxShadow: "-6px 18px 15px rgba(0, 0, 0, 0.1)",
                border: "2px solid  #2979F2",
              },
            }}
          >
            <Typography
              sx={{
                color: "#0085FF",
                fontSize: "24px",
                fontWeight: "700",
                display: "flex",
                fontFamily: "Circular Std",
              }}
            >
              Clients
            </Typography>

            <Stack spacing={3} sx={{ mt: "20px" }}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  gap: "20px",
                }}
              >
                <Box sx={{ width: "50%", display: "flex", gap: "10px" }}>
                  <Typography
                    sx={{
                      width: "100px",
                      color: "#84828c",
                      fontSize: "18px",
                      fontWeight: 600,
                      fontFamily: "Product Sans",
                    }}
                  >
                    Name:
                  </Typography>
                  <Typography
                    sx={{
                      color: "#272D4E",
                      fontSize: "18px",
                      fontFamily: "Product Sans",
                    }}
                  >
                    {Name}
                  </Typography>
                </Box>
                <Box sx={{ width: "50%", display: "flex", gap: "10px" }}>
                  <Typography
                    sx={{
                      width: "100px",
                      color: "#84828c",
                      fontSize: "18px",
                      fontWeight: 600,
                      fontFamily: "Product Sans",
                    }}
                  >
                    Added Date:
                  </Typography>
                  <Typography
                    sx={{
                      color: "#272D4E",
                      fontSize: "18px",
                      fontFamily: "Product Sans",
                    }}
                  >
                    {addedOn}
                  </Typography>
                </Box>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  gap: "20px",
                }}
              >
                <Box sx={{ width: "50%", display: "flex", gap: "10px" }}>
                  <Typography
                    sx={{
                      width: "100px",
                      color: "#84828c",
                      fontSize: "18px",
                      fontWeight: 600,
                      fontFamily: "Product Sans",
                    }}
                  >
                    Email:
                  </Typography>
                  <Typography
                    sx={{
                      color: "#272D4E",
                      fontSize: "18px",
                      fontFamily: "Product Sans",
                    }}
                  >
                    {Email?Email:"__"}
                  </Typography>
                </Box>
                <Box sx={{ width: "50%", display: "flex", gap: "10px" }}>
                  <Typography
                    sx={{
                      width: "100px",
                      color: "#84828c",
                      fontSize: "18px",
                      fontWeight: 600,
                      fontFamily: "Product Sans",
                    }}
                  >
                    Priority:
                  </Typography>
                  <Typography
                    sx={{
                      color: "#272D4E",
                      fontSize: "18px",
                      fontFamily: "Product Sans",
                    }}
                  >
                    {priority}
                  </Typography>
                </Box>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  gap: "20px",
                }}
              >
                <Box sx={{ width: "50%", display: "flex", gap: "10px" }}>
                  <Typography
                    sx={{
                      width: "100px",
                      color: "#84828c",
                      fontSize: "18px",
                      fontWeight: 600,
                      fontFamily: "Product Sans",
                    }}
                  >
                    Phone no:
                  </Typography>
                  <Typography
                    sx={{
                      color: "#272D4E",
                      fontSize: "18px",
                      fontFamily: "Product Sans",
                    }}
                  >
                    {PhoneNo?PhoneNo:"__"}
                  </Typography>
                </Box>
                <Box sx={{ width: "50%", display: "flex", gap: "10px" }}>
                  <Typography
                    sx={{
                      width: "100px",
                      color: "#84828c",
                      fontSize: "18px",
                      fontWeight: 600,
                      fontFamily: "Product Sans",
                    }}
                  >
                    Address:
                  </Typography>
                  <Typography
                    sx={{
                      color: "#272D4E",
                      fontSize: "18px",
                      fontFamily: "Product Sans",
                    }}
                  >
                    {Address}
                  </Typography>
                </Box>
              </Box>
              {info !== "" && (
                <Box sx={{ display: "flex", gap: "10px" }}>
                  <Typography
                    sx={{
                      width: "100px",
                      color: "#84828c",
                      fontSize: "18px",
                      fontWeight: 600,
                      fontFamily: "Product Sans",
                    }}
                  >
                    Note:
                  </Typography>
                  <Typography
                    sx={{
                      color: "#272D4E",
                      fontSize: "18px",
                      fontFamily: "Product Sans",
                    }}
                  >
                    {info}
                  </Typography>
                </Box>
              )}
            </Stack>
          </Box>
        </Box>

        {/* ================Mark Priority======================= */}
        {/* <Box>
          {selectedProject.length != 0 && (
            <Stack direction="row">
              {
                <Button
                  onClick={() => {
                    deAllocateProject();
                  }}
                  className="univercel_btn_class"
                  sx={{
                    color: "#FFFFFF",
                    textTransform: "none",
                    width: "150px",
                  }}
                >
                  Deallocate
                </Button>
              }
            </Stack>
          )}
        </Box> */}

        <TabBar
          floatingActonButton={
            <div>
              {TabIndex == 0 && (
                <>
                  <Button
                    className="univercel_btn_class"
                    sx={{
                      textTransform: "none",
                      borderRadius: "4px",
                      width: "150px",
                    }}
                    onClick={(e) => {
                      navigate(
                        `/channelpartner/client/project/assign/${par.id}`
                      );
                    }}
                  >
                    Assign New Project
                  </Button>
                </>
              )}
              {TabIndex == 1 && (
                <Button
                  className="univercel_btn_class"
                  sx={{
                    textTransform: "none",
                    borderRadius: "4px",
                    width: "150px",
                  }}
                  onClick={(e) => {
                    navigate(`/channelpartner/client/project/assign/${par.id}`);
                  }}
                >
                  Assign New Project
                </Button>
              )}
              {
                            TabIndex == 2 && <Button 
                            variant="contained"
                            color="primary" sx={{ height: "40px", background: "linear-gradient(90deg, #0F5DAE 0%, #009DCF 100.33%)", boxShadow: "0px 13.7981px 22.5788px rgba(50, 174, 137, 0.25)", textTransform: "none", borderRadius: "4px", width: "140px", fontSize: "15px", margin: "5px 0px 5px 0px", padding: "5px" }}>
                            Send Requirement Form 
                        </Button>
                        }
            </div>
          }
          onIndexChange={(e)=>{setTabIndex(e);setSelectedProject([])}}
          Tabs={[
            {
              Name: "Projects",
              children: [
                // <PDmainPage />
                <BuilderOrSelfProjectClient
                  selectedProject={selectedProject}
                  setSelectedProject={setSelectedProject}
                  self={self}
                  builder={Builder}
                  trigger={trigger}
                  setTrigger={setTrigger}
                  projectFlag={projectFlag}
                  setProjectFlag={setProjectFlag}
                  deAllocateProject={deAllocateProject}
                  lead_id={lead_id}
                />,
              ],
            },
            {
              Name: "Stand Alone Units",
              children: [
                <DeassignStandAloneUnitClient
                  selectedProject={selectedProject}
                  setSelectedProject={setSelectedProject}
                  self={standAlone}
                  trigger={trigger}
                  setTrigger={setTrigger}
                  deAllocateProject={deAllocateProject}
                />,
              ],
            },
          ]}
        />
      </SideBar>
    </>
  );
}

export default ClientOverview;
