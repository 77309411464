import axios from "axios";
import { Apiaddress } from "../../utils/consts";

// This Function is used to create a project in builders account
export const createProject= async(
{
    org_id,
    builder_id,
    unit_layout,
    address_line,
    ammenities,
    city,
    multi_building,
    name,
    pincode,
    state,
    extra_info,
    rera_number,
    website
}:{org_id :Number,
    builder_id :Number,
    unit_layout :[String:any],
    name :String, 
    ammenities :[String],
    city :String,
    state :String,
    pincode :String,
    address_line :String,
    multi_building : boolean,
    website? :String,
    extra_info? :[String:any],
    rera_number? :String}
)=>{

    const url = Apiaddress+"/api/projects/project/"
        const re = await axios.post(
            url,
            {
            "org":org_id,
            "builder":builder_id,
            "unit_layout":unit_layout,
            "name":name,
            "rera_number":rera_number,
            "ProjectAmmenities":ammenities,
            "city":city,
            "state":state,
            "pin_code":pincode,
            "address_line":address_line,
            "website":website,
            "extra_info":[],
            "is_multi_building":multi_building, 
        }
        )
        const data = await re.data;
        console.log("Create Project foo >>>>", data)
        return data;
};
