import { Stack } from "@mui/system";
import { TextField, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import AddIcon from '@mui/icons-material/Add';
import "../Signup.css";
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import {
    CitySelector,
    CountrySelector,
    StateSelector,
  } from "volkeno-react-country-state-city";
  import { useState } from "react";
  
import {useForm} from "react-hook-form"
import { OrganizationList } from "./OrganizationComponents/OrganizationList";
import Divider from '@mui/material/Divider';
import axios from "axios";
import { Apiaddress } from "../../../../utils/consts";
export const Organization =({stepsno,setStepsno,signupdata})=>{
    const [showform,setShowform] = useState(true)
    const { register, handleSubmit, watch, reset , formState: { errors } } = useForm();
    const [state,setState] = useState("")
    const [city,setCity] = useState("")
    const [location, setLocation] = useState({
        country:{
            "value": "IN",
            "label": {
                "type": "div",
                "key": null,
                "ref": null,
                "props": {
                    "className": "_3sXmFundefined",
                    "children": [
                        {
                            "type": "img",
                            "key": null,
                            "ref": null,
                            "props": {
                                "src": "http://purecatamphetamine.github.io/country-flag-icons/3x2/IN.svg",
                                "alt": "flag",
                                "className": "_2z69Y"
                            },
                            "_owner": {
                                "tag": 0,
                                "key": null,
                                "stateNode": null,
                                "return": null,
                                "child": null,
                                "sibling": null,
                                "index": 0,
                                "ref": null,
                                "pendingProps": null,
                                "memoizedProps": null,
                                "updateQueue": null,
                                "memoizedState": null,
                                "dependencies": null,
                                "mode": 27,
                                "flags": 1,
                                "subtreeFlags": 7340055,
                                "deletions": null,
                                "lanes": 0,
                                "childLanes": 1,
                                "alternate": null,
                                "actualDuration": 12.599999994039536,
                                "actualStartTime": 227624.40000000596,
                                "selfBaseDuration": 6.5,
                                "treeBaseDuration": 11.200000002980232,
                                "_debugSource": {
                                    "fileName": "C:\\CRM\\envision-frontend-web\\src\\Components\\ChannelPartnerComponents\\SignUp\\SignupSubComponents\\Organization.jsx",
                                    "lineNumber": 33,
                                    "columnNumber": 21
                                },
                                "_debugOwner": null,
                                "_debugNeedsRemount": false,
                                "_debugHookTypes": null
                            },
                            "_store": {}
                        },
                        {
                            "type": "span",
                            "key": null,
                            "ref": null,
                            "props": {
                                "className": "_1qEzs",
                                "children": "India"
                            },
                            "_owner": {
                                "tag": 0,
                                "key": null,
                                "stateNode": null,
                                "return": null,
                                "child": null,
                                "sibling": null,
                                "index": 0,
                                "ref": null,
                                "pendingProps": null,
                                "memoizedProps": null,
                                "updateQueue": null,
                                "memoizedState": null,
                                "dependencies": null,
                                "mode": 27,
                                "flags": 1,
                                "subtreeFlags": 7340055,
                                "deletions": null,
                                "lanes": 0,
                                "childLanes": 1,
                                "alternate": null,
                                "actualDuration": 12.599999994039536,
                                "actualStartTime": 227624.40000000596,
                                "selfBaseDuration": 6.5,
                                "treeBaseDuration": 11.200000002980232,
                                "_debugSource": {
                                    "fileName": "C:\\CRM\\envision-frontend-web\\src\\Components\\ChannelPartnerComponents\\SignUp\\SignupSubComponents\\Organization.jsx",
                                    "lineNumber": 33,
                                    "columnNumber": 21
                                },
                                "_debugOwner": null,
                                "_debugNeedsRemount": false,
                                "_debugHookTypes": null
                            },
                            "_store": {}
                        }
                    ]
                },
                "_owner": {
                    "tag": 0,
                    "key": null,
                    "stateNode": null,
                    "return": null,
                    "child": null,
                    "sibling": null,
                    "index": 0,
                    "ref": null,
                    "pendingProps": null,
                    "memoizedProps": null,
                    "updateQueue": null,
                    "memoizedState": null,
                    "dependencies": null,
                    "mode": 27,
                    "flags": 1,
                    "subtreeFlags": 7340055,
                    "deletions": null,
                    "lanes": 0,
                    "childLanes": 1,
                    "alternate": null,
                    "actualDuration": 12.599999994039536,
                    "actualStartTime": 227624.40000000596,
                    "selfBaseDuration": 6.5,
                    "treeBaseDuration": 11.200000002980232,
                    "_debugSource": {
                        "fileName": "C:\\CRM\\envision-frontend-web\\src\\Components\\ChannelPartnerComponents\\SignUp\\SignupSubComponents\\Organization.jsx",
                        "lineNumber": 33,
                        "columnNumber": 21
                    },
                    "_debugOwner": null,
                    "_debugNeedsRemount": false,
                    "_debugHookTypes": null
                },
                "_store": {}
            },
            "id": 101,
            "name": "India",
            "iso3": "IND",
            "iso2": "IN",
            "numeric_code": 356,
            "phone_code": 91,
            "capital": "New Delhi",
            "currency": "INR",
            "currency_name": "Indian rupee",
            "currency_symbol": "₹",
            "tld": ".in",
            "native": "भारत",
            "region": "Asia",
            "subregion": "Southern Asia",
            "timezones": "[{zoneName:'Asia\\/Kolkata'",
            "latitude": "gmtOffset:19800",
            "longitude": "gmtOffsetName:'UTC+05:30'",
            "emoji": "abbreviation:'IST'",
            "emojiU": "tzName:'Indian Standard Time'}]"
        }
    });
    const [allLocations,setAllLocation] = useState([])
    
    const [organizationlist, setorganizationList] = useState([])

    const onSubmit = (data) => {
        
        console.log("citystate: ", city, state)
        if(state=="" || city=="")return
        if(!showform){setShowform(true); return}
        // console.log(data)
        setorganizationList([...organizationlist,{
            orgname:data.organizationName,
            state:state,
            city:city,
            zipcode:data.pincode,
            website_address:data.webAddress,
         }])
        setAllLocation([...allLocations,location])
        // console.log("organizationlist",organizationlist)
        //Reset the form after submit
        reset();
        // e.target.reset();
        setState("");
        setCity("");
        setLocation({
            country:{
                "value": "IN",
                "label": {
                    "type": "div",
                    "key": null,
                    "ref": null,
                    "props": {
                        "className": "_3sXmFundefined",
                        "children": [
                            {
                                "type": "img",
                                "key": null,
                                "ref": null,
                                "props": {
                                    "src": "http://purecatamphetamine.github.io/country-flag-icons/3x2/IN.svg",
                                    "alt": "flag",
                                    "className": "_2z69Y"
                                },
                                "_owner": {
                                    "tag": 0,
                                    "key": null,
                                    "stateNode": null,
                                    "return": null,
                                    "child": null,
                                    "sibling": null,
                                    "index": 0,
                                    "ref": null,
                                    "pendingProps": null,
                                    "memoizedProps": null,
                                    "updateQueue": null,
                                    "memoizedState": null,
                                    "dependencies": null,
                                    "mode": 27,
                                    "flags": 1,
                                    "subtreeFlags": 7340055,
                                    "deletions": null,
                                    "lanes": 0,
                                    "childLanes": 1,
                                    "alternate": null,
                                    "actualDuration": 12.599999994039536,
                                    "actualStartTime": 227624.40000000596,
                                    "selfBaseDuration": 6.5,
                                    "treeBaseDuration": 11.200000002980232,
                                    "_debugSource": {
                                        "fileName": "C:\\CRM\\envision-frontend-web\\src\\Components\\ChannelPartnerComponents\\SignUp\\SignupSubComponents\\Organization.jsx",
                                        "lineNumber": 33,
                                        "columnNumber": 21
                                    },
                                    "_debugOwner": null,
                                    "_debugNeedsRemount": false,
                                    "_debugHookTypes": null
                                },
                                "_store": {}
                            },
                            {
                                "type": "span",
                                "key": null,
                                "ref": null,
                                "props": {
                                    "className": "_1qEzs",
                                    "children": "India"
                                },
                                "_owner": {
                                    "tag": 0,
                                    "key": null,
                                    "stateNode": null,
                                    "return": null,
                                    "child": null,
                                    "sibling": null,
                                    "index": 0,
                                    "ref": null,
                                    "pendingProps": null,
                                    "memoizedProps": null,
                                    "updateQueue": null,
                                    "memoizedState": null,
                                    "dependencies": null,
                                    "mode": 27,
                                    "flags": 1,
                                    "subtreeFlags": 7340055,
                                    "deletions": null,
                                    "lanes": 0,
                                    "childLanes": 1,
                                    "alternate": null,
                                    "actualDuration": 12.599999994039536,
                                    "actualStartTime": 227624.40000000596,
                                    "selfBaseDuration": 6.5,
                                    "treeBaseDuration": 11.200000002980232,
                                    "_debugSource": {
                                        "fileName": "C:\\CRM\\envision-frontend-web\\src\\Components\\ChannelPartnerComponents\\SignUp\\SignupSubComponents\\Organization.jsx",
                                        "lineNumber": 33,
                                        "columnNumber": 21
                                    },
                                    "_debugOwner": null,
                                    "_debugNeedsRemount": false,
                                    "_debugHookTypes": null
                                },
                                "_store": {}
                            }
                        ]
                    },
                    "_owner": {
                        "tag": 0,
                        "key": null,
                        "stateNode": null,
                        "return": null,
                        "child": null,
                        "sibling": null,
                        "index": 0,
                        "ref": null,
                        "pendingProps": null,
                        "memoizedProps": null,
                        "updateQueue": null,
                        "memoizedState": null,
                        "dependencies": null,
                        "mode": 27,
                        "flags": 1,
                        "subtreeFlags": 7340055,
                        "deletions": null,
                        "lanes": 0,
                        "childLanes": 1,
                        "alternate": null,
                        "actualDuration": 12.599999994039536,
                        "actualStartTime": 227624.40000000596,
                        "selfBaseDuration": 6.5,
                        "treeBaseDuration": 11.200000002980232,
                        "_debugSource": {
                            "fileName": "C:\\CRM\\envision-frontend-web\\src\\Components\\ChannelPartnerComponents\\SignUp\\SignupSubComponents\\Organization.jsx",
                            "lineNumber": 33,
                            "columnNumber": 21
                        },
                        "_debugOwner": null,
                        "_debugNeedsRemount": false,
                        "_debugHookTypes": null
                    },
                    "_store": {}
                },
                "id": 101,
                "name": "India",
                "iso3": "IND",
                "iso2": "IN",
                "numeric_code": 356,
                "phone_code": 91,
                "capital": "New Delhi",
                "currency": "INR",
                "currency_name": "Indian rupee",
                "currency_symbol": "₹",
                "tld": ".in",
                "native": "भारत",
                "region": "Asia",
                "subregion": "Southern Asia",
                "timezones": "[{zoneName:'Asia\\/Kolkata'",
                "latitude": "gmtOffset:19800",
                "longitude": "gmtOffsetName:'UTC+05:30'",
                "emoji": "abbreviation:'IST'",
                "emojiU": "tzName:'Indian Standard Time'}]"
            }
         });

        
        return;

    };

    //// submit organization final
    const [disable,setDisable] = useState(false)
    const submitOrganization = ()=>{
        if(organizationlist.length===0)return
        // let json = JSON.stringify(organizationlist) 
        // console.log("list:", json, "email:", signupdata.email)
        const headers = {
            'Content-Type': 'application/json',
          }
        const data = {
            "email":signupdata.email,
            "list": organizationlist
        }

        console.log("data:",data)
          setDisable(true)
        axios.post(Apiaddress+"/api/createorganization/",data, {
            headers: headers
          })
        .then((res)=>{
            console.log("res:", res)
            setDisable(false)
            setStepsno(prev=>prev+1)
        }).catch((err)=>{
            setDisable(false)
            console.log("err", err)
        })

    }


    return(
        <>
         
          <form onSubmit={handleSubmit(onSubmit)}>
        
        <Stack
        direction="column" justifyContent="space-between" sx={{height:"85vh"}}
        >


            <Box sx={{padding:"10px 46px",}}>

                <Stack direction="column" >
                     {organizationlist.map((orgData,index)=>{
                        return <OrganizationList organizationlist={organizationlist} setorganizationList={setorganizationList} ind={index} orgData={orgData} allLocations={allLocations}/>
                     })}
                     {/* <Divider variant="inset" /> */}
                   {organizationlist.length>0 && <hr style={{width:"100%",border:"1px solid #B0AFAF"}}/>}
                    
                </Stack>

                {organizationlist.length>0 &&
                <>{showform &&  <Stack direction="row" justifyContent="right" sx={{padding:"20px"}}>
                <Button onClick={()=>{
                     setShowform(false)
                   }}>Remove</Button>
                </Stack> }
               </>}

                {showform && <>
                    <Typography sx={{marginBottom:"20px"}}>Please Provide the Organization Names</Typography>

<Typography sx={{fontWeight: "500",fontSize: "18px"}}>Organization Name</Typography>
{/* <input type="text" className="input_organization"/> */}
<TextField
     sx={{
         width:"75%",
         margin:"10px 0px 10px 0px"
        }}
        type="text"
     id="outlined-error-helper-text"
     name="organizationName"
     fullWidth
     {...register("organizationName", {required:"Organization Name is required."})}
        error={Boolean(errors.organizationName)}
        helperText={errors.organizationName?.message}
        />


<Stack direction="row" spacing={4} sx={{marginTop:"8px",width:"75%"}} >
    <Box className="box_row">
        <Typography sx={{fontWeight: "500",fontSize: "18px"}}>State</Typography>

        <StateSelector
  country={location.country}
  name="state"
  value={location.state || state}
  countryPlaceholder="Select a country first"
  onChange={(e) => {
    // console.log(e);
    // setValue("country", e.name);
    setState(e.name)
    setLocation({ ...location, state: e });
  }}
/>
    </Box>
    <Box className="box_row">
        <Typography sx={{fontWeight: "500",fontSize: "18px"}}>City</Typography>
        
        <CitySelector
  state={location.state}
  name="city"
  value={location.city || city}
  statePlaceholder="Select a state first"
  onChange={(e) => {
    setCity(e.name)
    setLocation({ ...location, city: e });
  }}
/>
    </Box>
    <Box className="box_row">
        <Typography sx={{fontWeight: "500",fontSize: "18px"}}>Pincode</Typography>
        {/* <input type="text" className="input_organization_row"/> */}
        <TextField
     sx={{
        //  width:"75%",
         margin:"10px 0px 10px 0px"
        }}
      type="tel"
     id="outlined-error-helper-text"
     name="pincode"
     inputProps={{ maxLength: 6,minLength:6 }}
     fullWidth
     {...register("pincode", {required:"pincode is required",minLength:6,maxLength:6})}
        error={Boolean(errors.pincode)}
        helperText={errors.pincode?.message}
        />
    </Box>
</Stack>

<Typography sx={{fontWeight: "500",fontSize: "18px",marginTop:"8px"}}>Website Address</Typography>
{/* <input type="text" className="input_signup"/> */}
<TextField
     sx={{
         width:"50%",
         margin:"10px 0px 10px 0px"
        }}
        type="text"
     id="outlined-error-helper-text"
     name="webAddress"
     fullWidth
     {...register("webAddress", {required:"Web Address is required."})}
        error={Boolean(errors.webAddress)}
        helperText={errors.webAddress?.message}
        />
                </>}
           
           

                {!showform ?
            <Typography sx={{color:"#999999", fontSize:"14px", fontWeight:"500",marginBottom:"10px"}}>More than 1 legal entities? <br /> Click add more button below to add additional entities.</Typography>:
            <br/>
            }

            <Button variant="contained" type="submit" size="small" disableRipple  sx={{background:!showform?'#FFA946':"green",'&:hover': {backgroundColor: !showform?'#FFA946':"green"}}} startIcon={!showform?<AddIcon/>:null}>{showform? "Save":"Add More"}</Button>
            


            </Box>

            <Stack direction="row" justifyContent="right" spacing={0} sx={{marginBottom:"2.5vh"}}>
                {(stepsno>0 && stepsno<4) && <Button
                 className="univercel_btn_class"
                onClick={()=>setStepsno(prev=>prev-1)}
                variant="contained" startIcon={<KeyboardDoubleArrowLeftIcon/>} 
                 color="primary" sx={{marginRight:"20px",width:"114px",height:"53px",textTransform: "none"}}>
                    Previous
                </Button>}
               {stepsno<=3 && <Button 
                 onClick={submitOrganization}
                 disabled={disable}
                className="univercel_btn_class"
                variant="contained" endIcon={<KeyboardDoubleArrowRightIcon/>} 
                 color="primary" sx={{marginRight:"20px",height:"53px",  textTransform: "none"}}>
                    Next 
                </Button>}
            </Stack>

        </Stack>


          </form>

        </>
    )
}
