import {
  Box,
  Button,
  Container,
  Grid,
  Paper,
  Stack,
  styled,
  Typography,
} from "@mui/material";
import EasilyCollaborate from "../../Imgs/easily-collaborate.svg";
import OnboardProject from "../../Imgs/onboard-project.svg";
import SignUp from "../../Imgs/sign-up.svg";

const Item = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(1),
  boxShadow: "none",
}));

const BuilderLandingGetStartedWithEnvisionNext = () => {
  return (
    <Box sx={{ overflowX: "hidden" }}>
      <Container>
        <Box sx={{ textAlign: "center" }}>
          <Typography
            data-aos="zoom-in"
            sx={{
              fontSize: { xs: "30px", md: "40px", lg: "54px" },
              fontWeight: 700,
              color: "#272D4E",
              fontFamily: "Circular Std",
            }}
          >
            Get started with EnvisionNext
          </Typography>
          <Typography
            data-aos="fade-up"
            sx={{
              fontSize: { xs: "30px", md: "40px", lg: "54px" },
              fontWeight: 700,
              color: "#272D4E",
              fontFamily: "Circular Std",
            }}
          >
            in no time & no setup cost
          </Typography>
        </Box>

        <Box
          sx={{
            flexGrow: 1,
            mt: { xs: "30px", md: "50px" },
            // px: { xs: "30px", md: "50px" },
          }}
        >
          <Grid container spacing={2} justifyContent="center">
            <Grid item xs={12} md={6} md2={4}>
              <Item>
                <Stack
                  data-aos="fade-zoom-in"
                  data-aos-easing="ease-in-back"
                  data-aos-delay="200"
                  data-aos-offset="0"
                  spacing={1}
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    textAlign: "center",
                    gap: "5px",
                  }}
                >
                  <Box sx={{ width: "200px" }}>
                    <img style={{ width: "100%" }} src={SignUp} alt="" />
                  </Box>
                  <Typography
                    sx={{
                      fontSize: { xs: "24px", lg: "30px" },
                      fontWeight: 600,
                      color: "#0890FE",
                      fontFamily: "Product Sans",
                      py: { md: "25px" },
                    }}
                  >
                    Sign Up
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: "18px",
                      fontWeight: 300,
                      color: "#343434",
                      fontFamily: "Product Sans",
                    }}
                  >
                    Create your account for free. <br /> No setup costs at all.
                  </Typography>
                </Stack>
              </Item>
            </Grid>
            <Grid item xs={12} md={6} md2={4}>
              <Item>
                <Stack
                  data-aos="fade-zoom-in"
                  data-aos-easing="ease-in-back"
                  data-aos-delay="400"
                  data-aos-offset="0"
                  spacing={1}
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    textAlign: "center",
                    gap: "5px",
                  }}
                >
                  <Box sx={{ width: "200px" }}>
                    <img
                      style={{ width: "100%" }}
                      src={OnboardProject}
                      alt=""
                    />
                  </Box>
                  <Typography
                    sx={{
                      fontSize: { xs: "24px", lg: "30px" },
                      fontWeight: 600,
                      color: "#FF4267",
                      fontFamily: "Product Sans",
                    }}
                  >
                    Onboard projects within a day
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: "18px",
                      fontWeight: 300,
                      color: "#343434",
                      fontFamily: "Product Sans",
                    }}
                  >
                    Quickly set up your inventory and workflows with an
                    intuitive and user-friendly interface.
                  </Typography>
                </Stack>
              </Item>
            </Grid>
            <Grid item xs={12} md={6} md2={4}>
              <Item>
                <Stack
                  data-aos="fade-zoom-in"
                  data-aos-easing="ease-in-back"
                  data-aos-delay="600"
                  data-aos-offset="0"
                  spacing={1}
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    textAlign: "center",
                    gap: "5px",
                  }}
                >
                  <Box sx={{ width: "200px" }}>
                    <img
                      style={{ width: "100%" }}
                      src={EasilyCollaborate}
                      alt=""
                    />
                  </Box>
                  <Typography
                    sx={{
                      fontSize: { xs: "24px", lg: "30px" },
                      fontWeight: 600,
                      color: "#4F46BA",
                      fontFamily: "Product Sans",
                    }}
                  >
                    Easily collaborate, manage, & optimize
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: "18px",
                      fontWeight: 300,
                      color: "#343434",
                      fontFamily: "Product Sans",
                    }}
                  >
                    Achieve the business growth you have envisioned with
                    increased efficiency.
                  </Typography>
                </Stack>
              </Item>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </Box>
  );
};

export default BuilderLandingGetStartedWithEnvisionNext;
