import { useContext, useEffect, useState } from "react";
import BuilderDashboardContext from "./context";
import ProfileContext from "../profile/context"
import { listOrganizations } from "../../connection/organiztion/list_organzations";
import { fetchProjectsFromOrganization } from "../../connection/project/list_project_with";
import { ProjectDetails } from "../../connection/project/details";
import { vaccencyStatus } from "../../connection/organiztion/vaccency_status";
const BuilderDashboardState = (props) => {
  const cp = useContext(ProfileContext)

  async function fetchDasboard() {
    let Orgs = (await listOrganizations())['org_list'];
    console.log("completed fetching the Orgs", Orgs);
    let futures = [];
    for (let i = 0; i < Orgs.length; i++) {
      const projectD = async () => {
        Orgs[i].selected = true;
        Orgs[i].vaccencyStatus = await vaccencyStatus(Orgs[i].id)
        Orgs[i].Projects = [];
        const projects = await ((await fetchProjectsFromOrganization(Orgs[i].id))['projects'])
        let miniFutures = []
        for (let j = 0; j < projects.length; j++) {
          const details = async () => {
            if (projects[j].id !== undefined) {
              Orgs[i].Projects.push(await ProjectDetails(projects[j].id))
            }
          }
          miniFutures.push(details())
        }
        await Promise.all(miniFutures);

      }
     
      futures.push(projectD())
    }
    await Promise.all(futures)

    console.log("88888=>",Orgs)
    setBuilderDashboardState(Orgs)
  }

  useEffect(() => {
    const x = async () => await fetchDasboard()
    x();
  }, [cp])

  const previousState = JSON.parse(localStorage.getItem('BuilderDashboard'))
  //take project details from locals torage first if it is present
  const [BuilderDashboardState, setBrowserState] = useState(previousState);

  ///when it call first time in projectprofilecreate page it will overwrite locals torage;
  const setBuilderDashboardState = (state) => {
    console.log("00000000000000==>", state)
    localStorage.setItem("BuilderDashboard", JSON.stringify(state))
    setBrowserState(state)
  }
  
  const [selectedProjectId,setSelectedProjectId] = useState() 
  console.log(selectedProjectId)
  return (
    <BuilderDashboardContext.Provider
      value={{ BuilderDashboardState, setBuilderDashboardState,selectedProjectId,setSelectedProjectId, }}
    >
      {props.children}
    </BuilderDashboardContext.Provider>
  );
};

export default BuilderDashboardState;