import { Box, Button, TextField, Typography } from "@mui/material"
import { Stack } from "@mui/system"
import DoneIcon from '@mui/icons-material/Done';
import { useNavigate } from "react-router-dom";
import { useState } from "react";


import Modal from '@mui/material/Modal';
import { SAProjectSidebar } from "../SidebarNavbar/SAProjectSidebar";
import { SuperAdminNavbar } from "../SidebarNavbar/SuperAdminNavbar";
import { UserRole } from "../../../UserRoles/UserRoles";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #fff',
    boxShadow: 24,
    borderRadius:"20px",
    pt: 2,
    px: 4,
    pb: 3,
  };


export const AddMediaSA = ()=>{
  let roles = JSON.parse(localStorage.getItem("user_roles"))
  let project = roles["project"];
  let unit = roles["unit"];
  let media = roles["project media"];
  let builder = roles["builder"];
  let teams = roles["teams"];

    const navigate = useNavigate();

    const [listmedia, setlistmedia] = useState(["Broucher","Images",  "Floor Plans"]); ///"Videos","Walkthrough",
  
    const [selected, setSelected] = useState(listmedia[0])
  
    const [addname, setAddName] = useState("")
  
    const [open, setOpen] = useState(false);
    const handleOpen = () => {
      setOpen(true);
    };
    const handleClose = () => {
      setOpen(false);
    };
    return(
        <>
               <Stack direction="row" sx={{width:"100%",height:"auto" }}>
      {/* 1st stack */}
      <Stack sx={{minHeight:"100vh",width:"22%",boxSizing:"border-box",}}> {/*for project directory 20% and for sales dashboard 22%*/}
        <SAProjectSidebar active={"Media Library"}/>
      </Stack>

     {/* 2nd stack */}
     <Stack direction="column" sx={{ backgroundColor:"#f9f9f9",width:"78%", padding:"25px",boxSizing:"border-box",}}  >  {/*  width for main sidebar 78px and for 80px*/}
      <SuperAdminNavbar media={true} />  {/*send props for get popup to verify otp from builder */}
      
      {/* <Typography sx={{marginTop:"20px", fontSize:"28px", fontWeight:"600", marginLeft:"10px", marginBottom:"50px"}}>
           Content Title 
            </Typography> */}
     {/* actual content  */}
    <Stack sx={{backgroundColor:"#f9f9f9",mt:"28px"}}>  {/* mt:"28px", */}

       {/* add media to project  */}
       <Stack sx={{width:"100%",padding:"20px", bgcolor:"#fff"}} >
              <Typography sx={{fontSize:"28px", fontWeight:"600"}}>Media View</Typography>
                 <Stack direction="row" justifyContent="center" alignItems="center">
                  <Typography sx={{fontSize:"20px", fontWeight:"600", mt:"30px",mb:"20px"}}>Media library</Typography>
                  </Stack>
                  <Stack>
                    <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{padding:"20px"}}>
                      <Typography sx={{fontSize:"18px", fontWeight:"500", color:"#A2A3A6"}}>Select Category</Typography>
                      {UserRole(media, "add_projectmedia") && 
                       <Button onClick={()=>{
                          handleOpen()
                        }}
                      size="large" className="univercel_btn_class" sx={{textTransform:"none", color:"#fff"}} 
                       >Add More Media
                       </Button>
                      }
                    </Stack>
                      
                      <Box sx={{margin:"auto", width:{sm:"80%", lg:"55%"},  marginTop:"40px",display:"flex", justifyContent:"space-around", flexWrap:"wrap"}}>
                        {listmedia.map((ele,indx)=>{
                            return (
                            <Stack onClick={()=>{
                                setSelected(ele);
                            }} direction="row" alignItems="center" justifyContent="center" sx={{borderRadius:"20px",cursor:"pointer", minWidth:"100px", minHeight:"100px",padding:"5px", bgcolor:selected==ele?"orange":"#D9D9D9", }}>
                               <Typography sx={{fontSize:"18px", fontWeight:"600"}}>{ele}</Typography>  
                            </Stack>
                            )
                        })}
                           
                      </Box>
                  </Stack>
                  <Stack direction="row" justifyContent="right" alignItems="center" sx={{padding:"20px"}}>
                  <Button 
                         onClick={()=>{
                          navigate(`/super-admin-media-upload/${selected}`)
                         }}
                       size="large" className="univercel_btn_class" sx={{textTransform:"none", color:"#fff"}}  >Next
                       
                       </Button>
                  </Stack>
             </Stack>

                {/* modal */}
                <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
        <Box sx={{ ...style, width: 300, height:150, }}>
     <Stack direction="row" justifyContent="center">
     <TextField  
     placeholder="Add Media Name"
        value={addname}
        onChange={(e)=>{
            setAddName(e.target.value);
        }}
     />
     </Stack>
       
       <Stack sx={{display:"flex", justifyContent:"center", alignItems:"center", mt:"20px"}}>
        
        <Button
        onClick={()=>{
            if(addname==""){
                return;
                handleClose()
            }else{
                setlistmedia([...listmedia,addname])
                handleClose()
            }
        }}
       className="univercel_btn_class"
            variant="contained"
            sx={{padding:"9px 26px 9px 26px",}}
            component="label"
          >
             Add Media
        </Button>
        

       </Stack>
        </Box>
      </Modal>


    </Stack>
      </Stack>
       </Stack>
        </>
    )
}