import "./App.css";
import MainProvider from "./context/main_provider";
import { AllRoutes } from "./Routers/AllRoutes";
import "aos/dist/aos.css";
import { useEffect } from "react";
import AOS from "aos";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";

function App() {
  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);
  return (
    <div className="App">
      <MainProvider>
        <AllRoutes />
      </MainProvider>
    </div>
  );
}

export default App;
