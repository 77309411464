import { Box, Button, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import DoneIcon from "@mui/icons-material/Done";
import { Link, useNavigate } from "react-router-dom";
import { Sidebar } from "./DashboardComponents/Sidebar";
import { Navbar } from "./DashboardComponents/Navbar";
import Frame1 from "../../Imgs/frame1.png";
import Frame2 from "../../Imgs/frame2.png";
import Frame3 from "../../Imgs/wings.png";
import Frame4 from "../../Imgs/building.png";
import Frame5 from "../../Imgs/Pricing.png";
import AddIcon from "@mui/icons-material/Add";
import currentProjectState from "../../../context/createproject/context";
import Divider from "@mui/material/Divider";
import { useContext, useEffect, useState } from "react";
import { listUnitTemplateOfProject } from "../../../connection/project/unit_templates_of_project";
import userContext from "../../../context/profile/context";
import { useGridState } from "@mui/x-data-grid";
import { Apiaddress } from "../../../utils/consts";
import axios from "axios";
import Modal from '@mui/material/Modal';
import CloseIcon from '@mui/icons-material/Close';
import { MainSidebar } from "./DashboardComponents/MainSidebar";
import Lottie from 'react-lottie';
// import unitLottie from '../../../Lotties/20997-free-isometric-building-construction-4.json'
// import floorLottie from '../../../Lotties/20874-free-isometric-building-2.json'
// import wingLottie from '../../../Lotties/21005-free-isometric-building-bundle.json'
// import buildingLottie from '../../../Lotties/20782-isometric-building-construction.json'
// import priceLottie from '../../../Lotties/102813-home-icon-lottie-animation.json'

import unitimg from '../../../Lotties/unitimg.jpeg'
import floorimg from '../../../Lotties/floorimg.jpeg'
import wingimg from '../../../Lotties/wingimg.jpeg'
import buildingimg from '../../../Lotties/buildimg.jpeg'
import priceimg from '../../../Lotties/priceimg.jpeg'

export const MainProjectCreateScreen = () => {

    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: "350px",
        height:"250px",
        bgcolor: 'background.paper',
        boxShadow: 24, 
        borderRadius:"16px",
        p: 4
      };

  const navigate = useNavigate();
  const cpContext = useContext(currentProjectState);
  const projectID = cpContext?.createProjectState?.project?.id;
    console.log(projectID)
  const user = useContext(userContext)
  
  // useEffect(() => {
  //   const fetch_all_the_templates = async () => {
  //     const data = await listUnitTemplateOfProject(projectID);
  //     console.log(data);
  //   };
  //   fetch_all_the_templates();
  // }, []);

  /// states to show all patterns;
  
  const [allPatterns, setAllPatterns] = useState({
    unitpattern:[],
    floorpattern:[],
    wingpattern:[],
    buildingpattern:[],
    pricingpattern:[]
  })

  const [unitpattern, setunitpattern ] = useState([]);
  const [floorpattern, setfloorpattern ] = useState([]);
  const [wingpattern, setwingpattern ] = useState([]);
  const [buildingpattern, setbuildingpattern ] = useState([]);
  const [pricingpattern, setpricingpattern ] = useState([]);

  const [val,setval]=useState(0);

  //// get all patterns from api;
  const getpatterns = (url,i)=>{
    axios.get(url)
       .then((res)=>{
             console.log(res.data);
             const data = res?.data
            //  setfloortemp(res?.data?.floor_templates)
            if(i===0)setAllPatterns({...allPatterns,unitpattern:data?.unit_template})
            // if(i===1)setAllPatterns({...allPatterns,floorpattern:data?.unit_template})
            // if(i===2)setAllPatterns({...allPatterns,wingpattern:data?.unit_template})
            // if(i===3)setAllPatterns({...allPatterns,buildingpattern:data?.unit_template})
  
           })
       .catch((err)=>{
         console.log(err) 
       })
  }
console.log(unitpattern)
  useEffect(()=>{
     const uniturl = Apiaddress+"/api/projects/unittemplatesbyproject/?project_id="+projectID
     const floorurl = Apiaddress+"/api/projects/floortemplatesbyproject/?project_id="+projectID
     const wingurl = Apiaddress+"/api/projects/wingtemplatesbyproject/?project_id="+projectID
     const buildingurl = Apiaddress+"/api/projects/buildingtemplatesbyproject/?project_id="+projectID
     const pricingurl = Apiaddress+"/api/projects/unittemplatesbyproject/?project_id="+projectID
     const arr = [uniturl,floorurl,wingurl,buildingurl];

    
       axios.get(uniturl)
       .then((res)=>{
         const data = res?.data
          console.log("unit temp",res.data);
          if(data.unit_template!=="Unit template not found")
            setunitpattern(data?.unit_template)
          })
          .catch((err)=>{
            console.log(err)
          })
          
          axios.get(floorurl)
          .then((res)=>{
            const data = res?.data
            console.log(data)
            if(data.floor_templates!=="floor templates not found")
            setfloorpattern(data?.floor_templates)
          })
          .catch((err)=>{
            console.log(err)
          })

       axios.get(wingurl)
       .then((res)=>{
             const data = res?.data
             console.log(data)
             if(data.wing_templates!=="Wing Template not found")
                setwingpattern(data?.wing_templates)
          })
          .catch((err)=>{
            console.log(err)
          })

       axios.get(buildingurl)
       .then((res)=>{
             const data = res?.data
             console.log(data)
             if(data.building_template!=="building_template not found")
            setbuildingpattern(data.building_template)
                
          })
          .catch((err)=>{
            console.log(err)
          })
          
          const temp = [];
       axios.get(pricingurl)
       .then((res)=>{
            if(res.data?.unit_template=="Unit template not found")return;
              const data = res?.data["unit_template"]
              //    console.log(data['unit_template'])
                 console.log(data)
                for(let i=0; i<data.length; i++){ 
                  if(Array.isArray(data[i]["charges_data"])==true) { /// if it is array then put in to the list
                    console.log(data[i]['charges_data'])
                    let d = {
                      id:data[i]["id"],
                      pricing_template_name:data[i]["pricing_template_name"],
                      pricing_template:data[i]["charges_data"]
                    }
                    temp.push(d);
                  }
                  }
                  console.log("temp:====>",temp)
                  setpricingpattern([...temp])
                })
                .catch((err)=>{
                  console.log(err)
                })
          
          
  },[])

  //// delete templates function

  const deleteunitTemplate = (id)=>{
    const url = Apiaddress+"/api/projects/unittemplate/";
    axios.delete(url,{
      data:{
        unit_template_id:id
      }
    }).then((res)=>{
      console.log(res)
     let arr = unitpattern.filter((ele,indx)=>{
      return ele.id!==id
     })
     setunitpattern([...arr])
    })
    .catch((err)=>console.log(err))
  }
  const deletefloorTemplate = (id)=>{
    const url = Apiaddress+"/api/projects/floortemplate/";
    axios.delete(url,{
     data:{
      floor_template_id :id
     }
    }).then((res)=>{
      console.log(res)
      let arr = floorpattern.filter(ele=>{
        return ele.id!==id
      })
      setfloorpattern([...arr])
    })
    .catch((err)=>console.log(err))
  }
  const deletewingTemplate = (id)=>{
    const url = Apiaddress+"/api/projects/wingtemplate/";
    axios.delete(url,{
      data:{
        wing_template_id:id
      }
    }).then((res)=>{
      console.log(res)
      let arr = wingpattern.filter(ele=>{
        return ele.id!==id
      })
      setwingpattern([...arr])
    })
    .catch((err)=>console.log(err))
  }

  const deletebuildingTemplate = (id)=>{
    const url = Apiaddress+"/api/projects/buildingtemplates/";
    axios.delete(url,{
      data:{
        building_template_id:id
      }
    }).then((res)=>{
      console.log(res)
      let arr = buildingpattern.filter(ele=>{
        return ele.id!==id
      })
      setbuildingpattern([...arr])
    })
    .catch((err)=>console.log(err))
  }

  /////delete pricing template using patch method

  const deletpricingtemplate =(id)=>{
    const url = Apiaddress+"/api/projects/unittemplate/";
        let data = {
            unit_template_id:id,
            charges_data:{}
        }
        //    data = JSON.stringify(data);
        console.log(data)
        
        axios.patch(url,data,{
            headers:{
                "Content-Type":"application/json"
            }
        })
        .then((res)=>{
               console.log("pricing template:  ", res.data);
             let arr = pricingpattern.filter(ele=>{
              return ele.id!==id;
             })
             setpricingpattern([...arr])
        })
        .catch((err)=>{
            console.log(err)
        })
  }
  
  let disabled=(unitpattern.length<1 || floorpattern.lenght<1 || wingpattern.length<1 || buildingpattern.length<1 || pricingpattern.length<1)?true:false;
  //////for getting count of all template hit confirm project api
  const confirmproject = ()=>{
    localStorage.setItem("firstUnitCount",1);
    localStorage.setItem("prevUnitCount",unitpattern.length);
    navigate("/confirm-project-overview");
  }

  // const defaultOptions = {
  //     loop: true,
  //     autoplay: true,
  //     animationData: unitLottie,
  //     rendererSettings: {
  //       preserveAspectRatio: "xMidYMid slice"
  //     }
  //   };

  // const defaultOptions1 = {
  //     loop: true,
  //     autoplay: true,
  //     animationData: unitLottie,
  //     rendererSettings: {
  //       preserveAspectRatio: "xMidYMid slice"
  //     }
  //   };

  //   const defaultOptions2 = {
  //     loop: true,
  //     autoplay: true,
  //     animationData: floorLottie,
  //     rendererSettings: {
  //       preserveAspectRatio: "xMidYMid slice"
  //     }
  //   };

  //   const defaultOptions3 = {
  //     loop: true,
  //     autoplay: true,
  //     animationData: wingLottie,
  //     rendererSettings: {
  //       preserveAspectRatio: "xMidYMid slice"
  //     }
  //   };

  //   const defaultOptions4 = {
  //     loop: true,
  //     autoplay: true,
  //     animationData: buildingLottie,
  //     rendererSettings: {
  //       preserveAspectRatio: "xMidYMid slice"
  //     }
  //   };

  //   const defaultOptions5 = {
  //     loop: true,
  //     autoplay: true,
  //     animationData: priceLottie,
  //     rendererSettings: {
  //       preserveAspectRatio: "xMidYMid slice"
  //     }
  //   };



  return (
    <>

<Stack direction="row" sx={{width:"100%",height:"auto" }}>
      {/* 1st stack */}
      <Stack sx={{minHeight:"100vh",width:"20%",boxSizing:"border-box",}}> {/*for project directory 20% and for sales dashboard 225*/}
        <MainSidebar active={"Project Directory"}/>
      </Stack>

     {/* 2nd stack */}
     <Stack direction="column" sx={{ backgroundColor:"#f9f9f9",width:"80%", padding:"25px",boxSizing:"border-box",}}  >  {/*  width for main sidebar 78px and for 80px*/}
      <Navbar/>
      

      <Typography
            sx={{
              marginTop: "20px",
              fontSize: "28px",
              fontWeight: "600",
              marginLeft: "10px",
              marginBottom: "50px",
            }}
          >
            Create New Project
          </Typography>
     {/* actual content  */}
    <Stack sx={{backgroundColor:"#f9f9f9"}}>  {/* mt:"28px", */}

    <Stack
            direction="column"
            sx={{
              padding: "20px",
              backgroundColor: "#fff",
              marginRight: "0px",
            }}
          >
            <Stack
              direction="row"
              justifyContent="start"
              alignItems="center"
              sx={{ height: "100px" }}
            >
              <Stack direction="row" alignItems="center" spacing={1.5} sx={{}}>
                <Box
                  sx={{
                    width: "29px",
                    height: "29px",
                    borderRadius: "50%",
                    backgroundColor: "#FFA946",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <DoneIcon sx={{ color: "#fff", fontSize: "20px" }} />
                </Box>
                <Typography sx={{ fontSize: "18px", fontWeight: "500" }}>
                  Profile
                </Typography>
                <Stack direction="row" alignItems="center">
                  <Box
                    sx={{
                      width: "25px",
                      height: "0px",
                      borderBottom: "2px dashed orange",
                    }}
                  ></Box>
                  <Box
                    sx={{
                      height: "10px",
                      width: "10px",
                      borderRadius: "50%",
                      backgroundColor: "orange",
                    }}
                  ></Box>
                </Stack>
              </Stack>
              <Stack
                direction="row"
                alignItems="center"
                spacing={1.5}
                sx={{ marginLeft: "20px" }}
              >
                <Box
                  sx={{
                    width: "29px",
                    height: "29px",
                    borderRadius: "50%",
                    backgroundColor: "#FFA946",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <DoneIcon sx={{ color: "#fff", fontSize: "20px" }} />
                </Box>
                <Typography sx={{ fontSize: "18px", fontWeight: "500" }}>
                  Unit layout Items
                </Typography>
                <Stack direction="row" alignItems="center">
                  <Box
                    sx={{
                      width: "25px",
                      height: "0px",
                      borderBottom: "2px dashed orange",
                    }}
                  ></Box>
                  <Box
                    sx={{
                      height: "10px",
                      width: "10px",
                      borderRadius: "50%",
                      backgroundColor: "orange",
                    }}
                  ></Box>
                </Stack>
              </Stack>
              <Stack
                direction="row"
                alignItems="center"
                spacing={1.5}
                sx={{ marginLeft: "20px" }}
              >
                <Box
                  sx={{
                    width: "29px",
                    height: "29px",
                    borderRadius: "50%",
                    backgroundColor: "#FFA946",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <DoneIcon sx={{ color: "#fff", fontSize: "20px" }} />
                </Box>
                <Typography sx={{ fontSize: "18px", fontWeight: "500" }}>
                  Unit Pricing Items
                </Typography>
                <Stack direction="row" alignItems="center">
                  <Box
                    sx={{
                      width: "25px",
                      height: "0px",
                      borderBottom: "2px dashed orange",
                    }}
                  ></Box>
                  <Box
                    sx={{
                      height: "10px",
                      width: "10px",
                      borderRadius: "50%",
                      backgroundColor: " orange",
                    }}
                  ></Box>
                </Stack>
              </Stack>
              <Stack
                direction="row"
                alignItems="center"
                spacing={1.5}
                sx={{ marginLeft: "20px" }}
              >
                <Box
                  sx={{
                    width: "29px",
                    height: "29px",
                    borderRadius: "50%",
                    backgroundColor: " orange",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <DoneIcon sx={{ color: "#fff", fontSize: "20px" }} />
                </Box>
                <Typography sx={{ fontSize: "18px", fontWeight: "500" }}>
                  Templates
                </Typography>
              </Stack>
            </Stack>

            {/* ======================Add new unit=================== */}
            <Stack direction="row" spacing={3} sx={{}}>
              <Stack direction="row" justifyContent="left" alignItems="center" sx={{width:"100%"}}>
                <Stack direction="column" spacing={2} sx={{}}>
                  <Stack
                    direction="column"
                    justifyContent="center"
                    alignItems="center"
                    sx={{
                      width: "150px",
                      height: "161px",
                      bgcolor: "#EAECF2",
                      borderRadius: "7px",
                    }}
                    >
                    <Box sx={{ width: "58px", height: "52px" }}>
                      <img
                        style={{ width: "100%", height: "100%" }}
                        src={Frame1}
                        alt=""
                      />
                    </Box>
                    <Typography
                      sx={{
                        fontSize: "12px",
                        fontWeight: 500,
                        marginTop: "10px",
                      }}
                    >
                      Unit
                    </Typography>
                  </Stack>

                  <Button
                    onClick={() => {
                      navigate("/unittemplate");
                    }}
                    startIcon={<AddIcon />}
                    sx={{ textTransform: "none" }}
                    className="univercel_btn_class"
                    >
                    Add new Unit
                  </Button>

                </Stack>

                <Stack sx={{padding:"10px"}}>
                  {unitpattern[0] ? 
                  <>
                    {unitpattern?.slice(0, 5).map((ele,index)=>{
                     // console.log("hello",ele)
                      return(
                      <Stack direction="row" spacing={4} sx={{mb:"5px",marginLeft:"30px"}}>
                        <Typography sx={{fontSize:"16px", fontWeight:"600",width:"150px"}}>{ele.name}</Typography>
                        <svg
                          onClick={()=>{
                          deleteunitTemplate(ele.id)
                          }}
                          width="16" height="18" viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M3 18C2.45 18 1.97933 17.8043 1.588 17.413C1.196 17.021 1 16.55 1 16V3H0V1H5V0H11V1H16V3H15V16C15 16.55 14.8043 17.021 14.413 17.413C14.021 17.8043 13.55 18 13 18H3ZM13 3H3V16H13V3ZM5 14H7V5H5V14ZM9 14H11V5H9V14Z" fill="#EF3649"/>
                        </svg>
                        {/* <svg width="17" height="20" viewBox="0 0 17 20" fill="none" xmlns="http://www.w3.org/2000/svg">

                        <path d="M2 20C1.45 20 0.979 19.8043 0.587 19.413C0.195667 19.021 0 18.55 0 18V4H2V18H13V20H2ZM6 16C5.45 16 4.97933 15.8043 4.588 15.413C4.196 15.021 4 14.55 4 14V2C4 1.45 4.196 0.979 4.588 0.587C4.97933 0.195667 5.45 0 6 0H15C15.55 0 16.021 0.195667 16.413 0.587C16.8043 0.979 17 1.45 17 2V14C17 14.55 16.8043 15.021 16.413 15.413C16.021 15.8043 15.55 16 15 16H6ZM6 14H15V2H6V14Z" fill="#1C1B1F"/>
                        </svg> */}

                      </Stack>
                     )
                    })}
                  </>:null}

                  {unitpattern.length>5 && <Box sx={{margin:"25px 0px 0px 25px",fontFamily:'Poppins',fontStyle:"normal",fontWeight:"500",fontSize:"14px",lineHeight:"20px",textDecorationLine:"underline",color:"#0F5DAD",cursor:"pointer"}} onClick={()=>{setval(1);setOpen(true);}}>View all</Box>}

                </Stack>
            
                {/* <Box sx={{}}>
                  <Lottie 
	                  options={defaultOptions1}
                    height={200}
                    width={200}
                  />
                </Box> */}

              </Stack>

              <Box sx={{display:"flex",justifyContent:"center",alignItems:"center"}}>
                <img src={unitimg}></img>
              </Box>

            </Stack>


            {val==1 && <Modal
               open={open}
               onClose={handleClose}
               aria-labelledby="modal-modal-title"
               aria-describedby="modal-modal-description">
               <Box sx={style}>
               <Stack direction="row" justifyContent="space-between" alignItems="center" sx={{width:"100%"}}>
               <Typography id="modal-modal-title" variant="h6" component="h2" sx={{fontFamily:'Poppins',fontStyle:"normal",fontWeight:"500",fontSize: "18px",lineHeight:"27px",color:"#FF6140"}}>
               All Units List
               </Typography>
               <Box onClick={()=>{setOpen(false)}} sx={{cursor:"pointer"}}><CloseIcon /></Box>
               </Stack>

                {/* </Stack> */}

                <Box sx={{alignItems:"center",margin:"20px 0px 0px 0px",overflow:"scroll",height:"80%",overflowX:"hidden"}}>
                {unitpattern?.map((ele,index)=>{
                  // console.log("hello",ele)
                  return(
                 <Stack direction="row" spacing={4} sx={{margin:"0px 20px 10px 20px",justifyContent:"center",alignItems:"center"}}>
                  <Box sx={{width:"40%",overflow:"hidden"}}>
                      <Typography sx={{fontSize:"16px", fontWeight:"600"}}>{ele.name}</Typography>
                  </Box>
                  <Box sx={{width:"25%",textAlign:"center"}}>
                       <svg 
                  onClick={()=>{
                    deleteunitTemplate(ele.id)
                  }}
                  width="16" height="18" viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg" cursor="pointer">
                 <path d="M3 18C2.45 18 1.97933 17.8043 1.588 17.413C1.196 17.021 1 16.55 1 16V3H0V1H5V0H11V1H16V3H15V16C15 16.55 14.8043 17.021 14.413 17.413C14.021 17.8043 13.55 18 13 18H3ZM13 3H3V16H13V3ZM5 14H7V5H5V14ZM9 14H11V5H9V14Z" fill="#EF3649"/>
                 </svg>
                  </Box>
                  {/* <Box sx={{}}>
                     <svg width="17" height="20" viewBox="0 0 17 20" fill="none" xmlns="http://www.w3.org/2000/svg" cursor="pointer">

                      <path d="M2 20C1.45 20 0.979 19.8043 0.587 19.413C0.195667 19.021 0 18.55 0 18V4H2V18H13V20H2ZM6 16C5.45 16 4.97933 15.8043 4.588 15.413C4.196 15.021 4 14.55 4 14V2C4 1.45 4.196 0.979 4.588 0.587C4.97933 0.195667 5.45 0 6 0H15C15.55 0 16.021 0.195667 16.413 0.587C16.8043 0.979 17 1.45 17 2V14C17 14.55 16.8043 15.021 16.413 15.413C16.021 15.8043 15.55 16 15 16H6ZM6 14H15V2H6V14Z" fill="#1C1B1F"/>
                     </svg>
                  </Box> */}
                
                   </Stack>
                  )
                  })}
                </Box>
           
                 </Box>
              </Modal>}



            <Divider variant="middle" sx={{ mt: "40px", mb: "40px" }} />
            {/* ======================Add new unit=================== */}
            <Stack direction="row" spacing={3} sx={{}}>
              <Stack direction="row" justifyContent="left" alignItems="center" sx={{width:"100%"}}>
                <Stack direction="column" spacing={2}>
                  <Stack
                    direction="column"
                    justifyContent="center"
                    alignItems="center"
                    sx={{
                      width: "150px",
                      height: "161px",
                      bgcolor: "#EAECF2",
                      borderRadius: "7px",
                    }}
                    >
                    <Box sx={{ width: "58px", height: "52px" }}>
                      <img
                        style={{ width: "100%", height: "100%" }}
                        src={Frame2}
                        alt=""
                      />
                    </Box>
                    <Typography
                      sx={{
                        fontSize: "12px",
                        fontWeight: 500,
                        marginTop: "10px",
                      }}
                    >
                      Floor
                    </Typography>
                  </Stack>

                  <Button
                    onClick={() => {
                      navigate("/floortemplate");
                    }}
                    startIcon={<AddIcon />}
                    sx={{ textTransform: "none" }}
                    className="univercel_btn_class"
                    >
                    Add new Floor
                  </Button>

                </Stack>

                  <Stack sx={{padding:"10px"}}>
                    {floorpattern[0]? 
                      <>
                      {floorpattern?.slice(0, 5).map((ele,index)=>{
                        console.log("hello",ele)
                        return(
                          <Stack direction="row" spacing={4} sx={{mb:"5px",marginLeft:"30px"}}>
                            <Typography sx={{fontSize:"16px", fontWeight:"600",width:"150px"}}>{ele.name}</Typography>
                            <svg
                              onClick={()=>{
                              deletefloorTemplate(ele.id)
                              }}
                              width="16" height="18" viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M3 18C2.45 18 1.97933 17.8043 1.588 17.413C1.196 17.021 1 16.55 1 16V3H0V1H5V0H11V1H16V3H15V16C15 16.55 14.8043 17.021 14.413 17.413C14.021 17.8043 13.55 18 13 18H3ZM13 3H3V16H13V3ZM5 14H7V5H5V14ZM9 14H11V5H9V14Z" fill="#EF3649"/>
                            </svg>

                            {/* <svg width="17" height="20" viewBox="0 0 17 20" fill="none" xmlns="http://www.w3.org/2000/svg">

                              <path d="M2 20C1.45 20 0.979 19.8043 0.587 19.413C0.195667 19.021 0 18.55 0 18V4H2V18H13V20H2ZM6 16C5.45 16 4.97933 15.8043 4.588 15.413C4.196 15.021 4 14.55 4 14V2C4 1.45 4.196 0.979 4.588 0.587C4.97933 0.195667 5.45 0 6 0H15C15.55 0 16.021 0.195667 16.413 0.587C16.8043 0.979 17 1.45 17 2V14C17 14.55 16.8043 15.021 16.413 15.413C16.021 15.8043 15.55 16 15 16H6ZM6 14H15V2H6V14Z" fill="#1C1B1F"/>
                            </svg> */}
                          </Stack>
                        )
                        })}
                      </>:null}

                    {floorpattern.length>5 && <Box sx={{margin:"25px 0px 0px 25px",fontFamily:'Poppins',fontStyle:"normal",fontWeight:"500",fontSize:"14px",lineHeight:"20px",textDecorationLine:"underline",color:"#0F5DAD",cursor:"pointer"}} onClick={()=>{setval(2);setOpen(true);}}>View all</Box>}
                  </Stack>


                    {/* <Box sx={{}}>
                        <Lottie 
	                      options={defaultOptions2}
                        height={200}
                        width={200}
                      />
                      </Box> */}
              </Stack>

              <Box sx={{display:"flex",justifyContent:"center",alignItems:"center"}}>
                <img src={floorimg}></img>
              </Box>

            </Stack>

             {val==2 && <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description">
           <Box sx={style}>
           <Stack direction="row" justifyContent="space-between" alignItems="center">
           <Typography id="modal-modal-title" variant="h6" component="h2" sx={{fontFamily:'Poppins',fontStyle:"normal",fontWeight:"500",fontSize: "18px",lineHeight:"27px",color:"#FF6140"}}>
            All Floors List
           </Typography>
           <Box onClick={()=>{setOpen(false)}} sx={{cursor:"pointer"}}><CloseIcon /></Box>
           </Stack>

           <Box sx={{alignItems:"center",margin:"20px 0px 0px 0px",overflow:"scroll",height:"80%",overflowX:"hidden"}}>
             {floorpattern?.map((ele,index)=>{
                  // console.log("hello",ele)
                  return(
                 <Stack direction="row" spacing={4} sx={{margin:"0px 20px 10px 20px",justifyContent:"center",alignItems:"center"}}>
                  <Box sx={{width:"40%",overflow:"hidden"}}>
                      <Typography sx={{fontSize:"16px", fontWeight:"600"}}>{ele.name}</Typography>
                  </Box>
                  <Box sx={{width:"25%",textAlign:"center"}}>
                       <svg 
                  onClick={()=>{
                    deletefloorTemplate(ele.id)
                  }}
                  width="16" height="18" viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg" cursor="pointer">
                 <path d="M3 18C2.45 18 1.97933 17.8043 1.588 17.413C1.196 17.021 1 16.55 1 16V3H0V1H5V0H11V1H16V3H15V16C15 16.55 14.8043 17.021 14.413 17.413C14.021 17.8043 13.55 18 13 18H3ZM13 3H3V16H13V3ZM5 14H7V5H5V14ZM9 14H11V5H9V14Z" fill="#EF3649"/>
                 </svg>
                  </Box>
                  {/* <Box sx={{}}>
                     <svg width="17" height="20" viewBox="0 0 17 20" fill="none" xmlns="http://www.w3.org/2000/svg" cursor="pointer">

                      <path d="M2 20C1.45 20 0.979 19.8043 0.587 19.413C0.195667 19.021 0 18.55 0 18V4H2V18H13V20H2ZM6 16C5.45 16 4.97933 15.8043 4.588 15.413C4.196 15.021 4 14.55 4 14V2C4 1.45 4.196 0.979 4.588 0.587C4.97933 0.195667 5.45 0 6 0H15C15.55 0 16.021 0.195667 16.413 0.587C16.8043 0.979 17 1.45 17 2V14C17 14.55 16.8043 15.021 16.413 15.413C16.021 15.8043 15.55 16 15 16H6ZM6 14H15V2H6V14Z" fill="#1C1B1F"/>
                     </svg>
                  </Box> */}
                
                 </Stack>
                  )
                })}
            </Box>
           
        </Box>
      </Modal>}


            <Divider variant="middle" sx={{ mt: "40px", mb: "40px" }} />
            {/* ======================Add new unit=================== */}
            <Stack direction="row" spacing={3} sx={{}}>
              <Stack direction="row" justifyContent="left" alignItems="center" sx={{width:"100%"}}>
                <Stack direction="column" spacing={2}>
                  <Stack
                    direction="column"
                    justifyContent="center"
                    alignItems="center"
                    sx={{
                      width: "150px",
                      height: "161px",
                      bgcolor: "#EAECF2",
                      borderRadius: "7px",
                    }}
                  >
                    <Box sx={{ width: "58px", height: "52px" }}>
                      <img
                        style={{ width: "100%", height: "100%" }}
                        src={Frame3}
                        alt=""
                      />
                    </Box>
                    <Typography
                      sx={{
                        fontSize: "12px",
                        fontWeight: 500,
                        marginTop: "10px",
                      }}
                    >
                      Wings
                    </Typography>
                  </Stack>

                  <Button
                    onClick={() => {
                      navigate("/wingtemplate");
                    }}
                    startIcon={<AddIcon />}
                    sx={{ textTransform: "none" }}
                    className="univercel_btn_class"
                  >
                    Add new Wing
                  </Button>

                </Stack>

                 <Stack sx={{padding:"10px"}}>
                {
                wingpattern[0]? <> 
                {wingpattern?.slice(0, 5).map((ele,index)=>{
                  return(
                    <Stack direction="row" spacing={4} sx={{mb:"5px",marginLeft:"20px"}}>
                  <Typography sx={{fontSize:"16px", fontWeight:"600",width:"150px"}}>{ele.name}</Typography>
                  <svg
                   onClick={()=>{
                    deletewingTemplate(ele.id)
                  }}
                  width="16" height="18" viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                 <path d="M3 18C2.45 18 1.97933 17.8043 1.588 17.413C1.196 17.021 1 16.55 1 16V3H0V1H5V0H11V1H16V3H15V16C15 16.55 14.8043 17.021 14.413 17.413C14.021 17.8043 13.55 18 13 18H3ZM13 3H3V16H13V3ZM5 14H7V5H5V14ZM9 14H11V5H9V14Z" fill="#EF3649"/>
                 </svg>
                 {/* <svg width="17" height="20" viewBox="0 0 17 20" fill="none" xmlns="http://www.w3.org/2000/svg">

                  <path d="M2 20C1.45 20 0.979 19.8043 0.587 19.413C0.195667 19.021 0 18.55 0 18V4H2V18H13V20H2ZM6 16C5.45 16 4.97933 15.8043 4.588 15.413C4.196 15.021 4 14.55 4 14V2C4 1.45 4.196 0.979 4.588 0.587C4.97933 0.195667 5.45 0 6 0H15C15.55 0 16.021 0.195667 16.413 0.587C16.8043 0.979 17 1.45 17 2V14C17 14.55 16.8043 15.021 16.413 15.413C16.021 15.8043 15.55 16 15 16H6ZM6 14H15V2H6V14Z" fill="#1C1B1F"/>
                  </svg> */}
                 </Stack>
                  )
                })}
                </>:null
              }

            {wingpattern.length>5 && <Box sx={{margin:"25px 0px 0px 25px",fontFamily:'Poppins',fontStyle:"normal",fontWeight:"500",fontSize:"14px",lineHeight:"20px",textDecorationLine:"underline",color:"#0F5DAD",cursor:"pointer"}} onClick={()=>{setval(3);setOpen(true);}}>View all</Box>}

            </Stack>



                {/* <Box sx={{}}>
                  <Lottie 
	                  options={defaultOptions3}
                    height={200}
                    width={200}
                  />
                </Box> */}
              </Stack>

              <Box sx={{display:"flex",justifyContent:"center",alignItems:"center"}}>
                <img src={wingimg}></img>
              </Box>
             
            </Stack>

             {val==3 && <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description">
           <Box sx={style}>
           <Stack direction="row" justifyContent="space-between" alignItems="center">
           <Typography id="modal-modal-title" variant="h6" component="h2" sx={{fontFamily:'Poppins',fontStyle:"normal",fontWeight:"500",fontSize: "18px",lineHeight:"27px",color:"#FF6140"}}>
            All Wings List
           </Typography>
           <Box onClick={()=>{setOpen(false)}} sx={{cursor:"pointer"}}><CloseIcon /></Box>
           </Stack>

           <Box sx={{alignItems:"center",margin:"20px 0px 0px 0px",overflow:"scroll",height:"80%",overflowX:"hidden"}}>
             {wingpattern?.map((ele,index)=>{
                  // console.log("hello",ele)
                  return(
                 <Stack direction="row" spacing={4} sx={{margin:"0px 20px 10px 20px",justifyContent:"center",alignItems:"center"}}>
                  <Box sx={{width:"40%",overflow:"hidden"}}>
                      <Typography sx={{fontSize:"16px", fontWeight:"600"}}>{ele.name}</Typography>
                  </Box>
                  <Box sx={{width:"25%",textAlign:"center"}}>
                       <svg 
                  onClick={()=>{
                   deletewingTemplate(ele.id)
                  }}
                  width="16" height="18" viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg" cursor="pointer">
                 <path d="M3 18C2.45 18 1.97933 17.8043 1.588 17.413C1.196 17.021 1 16.55 1 16V3H0V1H5V0H11V1H16V3H15V16C15 16.55 14.8043 17.021 14.413 17.413C14.021 17.8043 13.55 18 13 18H3ZM13 3H3V16H13V3ZM5 14H7V5H5V14ZM9 14H11V5H9V14Z" fill="#EF3649"/>
                 </svg>
                  </Box>
                  {/* <Box sx={{}}>
                     <svg width="17" height="20" viewBox="0 0 17 20" fill="none" xmlns="http://www.w3.org/2000/svg" cursor="pointer">

                      <path d="M2 20C1.45 20 0.979 19.8043 0.587 19.413C0.195667 19.021 0 18.55 0 18V4H2V18H13V20H2ZM6 16C5.45 16 4.97933 15.8043 4.588 15.413C4.196 15.021 4 14.55 4 14V2C4 1.45 4.196 0.979 4.588 0.587C4.97933 0.195667 5.45 0 6 0H15C15.55 0 16.021 0.195667 16.413 0.587C16.8043 0.979 17 1.45 17 2V14C17 14.55 16.8043 15.021 16.413 15.413C16.021 15.8043 15.55 16 15 16H6ZM6 14H15V2H6V14Z" fill="#1C1B1F"/>
                     </svg>
                  </Box> */}
                
                 </Stack>
                  )
                })}
            </Box>
           
        </Box>
      </Modal>}

            <Divider variant="middle" sx={{ mt: "40px", mb: "40px" }} />
            {/* ======================Add new unit=================== */}
            <Stack direction="row" spacing={3} sx={{}}>
              <Stack direction="row" justifyContent="left" alignItems="center" sx={{width:"100%"}}>
                <Stack direction="column" spacing={2}>
                  <Stack
                    direction="column"
                    justifyContent="center"
                    alignItems="center"
                    sx={{
                      width: "150px",
                      height: "161px",
                      bgcolor: "#EAECF2",
                      borderRadius: "7px",
                    }}
                  >
                    <Box sx={{ width: "58px", height: "52px" }}>
                      <img
                        style={{ width: "100%", height: "100%" }}
                        src={Frame4}
                        alt=""
                      />
                    </Box>
                    <Typography
                      sx={{
                        fontSize: "12px",
                        fontWeight: 500,
                        marginTop: "10px",
                      }}
                    >
                      Buildings
                    </Typography>
                  </Stack>

                  <Button
                    onClick={() => {
                      navigate("/buildingpatterntemplate");
                    }}
                    startIcon={<AddIcon />}
                    sx={{ textTransform: "none" }}
                    className="univercel_btn_class"
                  >
                    Add new Building
                  </Button>

                </Stack>

                <Stack sx={{padding:"10px"}}>
                {buildingpattern[0]?
                <>
                {buildingpattern?.slice(0, 5).map((ele,index)=>{
                  return(
                    <Stack direction="row" spacing={4} sx={{mb:"5px",marginLeft:"20px"}}>
                  <Typography sx={{fontSize:"16px", fontWeight:"600",width:"150px"}}>{ele.name}</Typography>
                  <svg 
                   onClick={()=>{
                    deletebuildingTemplate(ele.id)
                  }}
                  width="16" height="18" viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                 <path d="M3 18C2.45 18 1.97933 17.8043 1.588 17.413C1.196 17.021 1 16.55 1 16V3H0V1H5V0H11V1H16V3H15V16C15 16.55 14.8043 17.021 14.413 17.413C14.021 17.8043 13.55 18 13 18H3ZM13 3H3V16H13V3ZM5 14H7V5H5V14ZM9 14H11V5H9V14Z" fill="#EF3649"/>
                 </svg>
                 {/* <svg width="17" height="20" viewBox="0 0 17 20" fill="none" xmlns="http://www.w3.org/2000/svg">

              <path d="M2 20C1.45 20 0.979 19.8043 0.587 19.413C0.195667 19.021 0 18.55 0 18V4H2V18H13V20H2ZM6 16C5.45 16 4.97933 15.8043 4.588 15.413C4.196 15.021 4 14.55 4 14V2C4 1.45 4.196 0.979 4.588 0.587C4.97933 0.195667 5.45 0 6 0H15C15.55 0 16.021 0.195667 16.413 0.587C16.8043 0.979 17 1.45 17 2V14C17 14.55 16.8043 15.021 16.413 15.413C16.021 15.8043 15.55 16 15 16H6ZM6 14H15V2H6V14Z" fill="#1C1B1F"/>
              </svg> */}
                 </Stack>
                  )
                })}
                </>:null}

            {buildingpattern.length>5 && <Box sx={{margin:"25px 0px 0px 25px",fontFamily:'Poppins',fontStyle:"normal",fontWeight:"500",fontSize:"14px",lineHeight:"20px",textDecorationLine:"underline",color:"#0F5DAD",cursor:"pointer"}} onClick={()=>{setval(4);setOpen(true);}}>View all</Box>}

           
              </Stack>


                {/* <Box sx={{}}>
                  <Lottie 
	                  options={defaultOptions4}
                    height={200}
                    width={200}
                  />
                </Box> */}

              </Stack>

              <Box sx={{display:"flex",justifyContent:"center",alignItems:"center"}}>
                <img src={buildingimg}></img>
              </Box>
              
            </Stack>

             {val==4 && <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description">
           <Box sx={style}>
           <Stack direction="row" justifyContent="space-between" alignItems="center">
           <Typography id="modal-modal-title" variant="h6" component="h2" sx={{fontFamily:'Poppins',fontStyle:"normal",fontWeight:"500",fontSize: "18px",lineHeight:"27px",color:"#FF6140"}}>
            All Buildings List
           </Typography>
           <Box onClick={()=>{setOpen(false)}} sx={{cursor:"pointer"}}><CloseIcon /></Box>
           </Stack>

           <Box sx={{alignItems:"center",margin:"20px 0px 0px 0px",overflow:"scroll",height:"80%",overflowX:"hidden"}}>
             {buildingpattern?.map((ele,index)=>{
                  // console.log("hello",ele)
                  return(
                 <Stack direction="row" spacing={4} sx={{margin:"0px 20px 10px 20px",justifyContent:"center",alignItems:"center"}}>
                  <Box sx={{width:"40%",overflow:"hidden"}}>
                      <Typography sx={{fontSize:"16px", fontWeight:"600"}}>{ele.name}</Typography>
                  </Box>
                  <Box sx={{width:"25%",textAlign:"center"}}>
                       <svg 
                  onClick={()=>{
                    deletebuildingTemplate(ele.id)
                  }}
                  width="16" height="18" viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg" cursor="pointer">
                 <path d="M3 18C2.45 18 1.97933 17.8043 1.588 17.413C1.196 17.021 1 16.55 1 16V3H0V1H5V0H11V1H16V3H15V16C15 16.55 14.8043 17.021 14.413 17.413C14.021 17.8043 13.55 18 13 18H3ZM13 3H3V16H13V3ZM5 14H7V5H5V14ZM9 14H11V5H9V14Z" fill="#EF3649"/>
                 </svg>
                  </Box>
                  {/* <Box sx={{}}>
                     <svg width="17" height="20" viewBox="0 0 17 20" fill="none" xmlns="http://www.w3.org/2000/svg" cursor="pointer">

                      <path d="M2 20C1.45 20 0.979 19.8043 0.587 19.413C0.195667 19.021 0 18.55 0 18V4H2V18H13V20H2ZM6 16C5.45 16 4.97933 15.8043 4.588 15.413C4.196 15.021 4 14.55 4 14V2C4 1.45 4.196 0.979 4.588 0.587C4.97933 0.195667 5.45 0 6 0H15C15.55 0 16.021 0.195667 16.413 0.587C16.8043 0.979 17 1.45 17 2V14C17 14.55 16.8043 15.021 16.413 15.413C16.021 15.8043 15.55 16 15 16H6ZM6 14H15V2H6V14Z" fill="#1C1B1F"/>
                     </svg>
                  </Box> */}
                
                 </Stack>
                  )
                })}
            </Box>
           
        </Box>
      </Modal>}


            <Divider variant="middle" sx={{ mt: "40px", mb: "40px" }} />
            {/* ======================Add new unit=================== */}
            <Stack direction="row" spacing={3} sx={{}}>
              <Stack direction="row" justifyContent="left" alignItems="center" sx={{width:"100%"}}>
                <Stack direction="column" spacing={2}>
                  <Stack
                    direction="column"
                    justifyContent="center"
                    alignItems="center"
                    sx={{
                      width: "150px",
                      height: "161px",
                      bgcolor: "#EAECF2",
                      borderRadius: "7px",
                    }}
                  >
                    <Box sx={{ width: "58px", height: "52px" }}>
                      <img
                        style={{ width: "100%", height: "100%" }}
                        src={Frame5}
                        alt=""
                      />
                    </Box>
                    <Typography
                      sx={{
                        fontSize: "12px",
                        fontWeight: 500,
                        marginTop: "10px",
                      }}
                    >
                      Pricing
                    </Typography>
                  </Stack>

                  <Button
                    onClick={() => {
                      navigate("/newpricingtemplate");
                    }}
                    startIcon={<AddIcon />}
                    sx={{ textTransform: "none" }}
                    className="univercel_btn_class"
                  >
                    Add new Pricing
                  </Button>

                </Stack>

                <Stack sx={{padding:"10px"}}>
                {pricingpattern[0]? <>
                
                  {pricingpattern?.slice(0, 5).map((ele,index)=>{
                    // console.log(ele);
                    return(
                      <Stack direction="row" spacing={4} sx={{mb:"5px",marginLeft:"20px"}}>
                    <Typography sx={{fontSize:"16px", fontWeight:"600",width:"150px"}}>{ele.pricing_template_name}</Typography>
                    <svg
                    onClick={()=>{
                      deletpricingtemplate(ele.id)
                    }}
                    width="16" height="18" viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                   <path d="M3 18C2.45 18 1.97933 17.8043 1.588 17.413C1.196 17.021 1 16.55 1 16V3H0V1H5V0H11V1H16V3H15V16C15 16.55 14.8043 17.021 14.413 17.413C14.021 17.8043 13.55 18 13 18H3ZM13 3H3V16H13V3ZM5 14H7V5H5V14ZM9 14H11V5H9V14Z" fill="#EF3649"/>
                   </svg>
                   {/* <svg width="17" height="20" viewBox="0 0 17 20" fill="none" xmlns="http://www.w3.org/2000/svg">
  
                <path d="M2 20C1.45 20 0.979 19.8043 0.587 19.413C0.195667 19.021 0 18.55 0 18V4H2V18H13V20H2ZM6 16C5.45 16 4.97933 15.8043 4.588 15.413C4.196 15.021 4 14.55 4 14V2C4 1.45 4.196 0.979 4.588 0.587C4.97933 0.195667 5.45 0 6 0H15C15.55 0 16.021 0.195667 16.413 0.587C16.8043 0.979 17 1.45 17 2V14C17 14.55 16.8043 15.021 16.413 15.413C16.021 15.8043 15.55 16 15 16H6ZM6 14H15V2H6V14Z" fill="#1C1B1F"/>
                </svg> */}
                   </Stack>
                    )
                  })}
                </>:null
              }

            {pricingpattern.length>5 && <Box sx={{margin:"25px 0px 0px 25px",fontFamily:'Poppins',fontStyle:"normal",fontWeight:"500",fontSize:"14px",lineHeight:"20px",textDecorationLine:"underline",color:"#0F5DAD",cursor:"pointer"}} onClick={()=>{setval(5);setOpen(true);}}>View all</Box>}

              </Stack>


                {/* <Box sx={{}}>
                  <Lottie 
	                  options={defaultOptions5}
                    height={200}
                    width={200}
                  />
                </Box> */}
              </Stack>
              
              <Box sx={{display:"flex",justifyContent:"center",alignItems:"center"}}>
                <img src={priceimg}></img>
              </Box>

            </Stack>

              {val==5 && <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description">
           <Box sx={style}>
           <Stack direction="row" justifyContent="space-between" alignItems="center">
           <Typography id="modal-modal-title" variant="h6" component="h2" sx={{fontFamily:'Poppins',fontStyle:"normal",fontWeight:"500",fontSize: "18px",lineHeight:"27px",color:"#FF6140"}}>
            All Pricing List
           </Typography>
           <Box onClick={()=>{setOpen(false)}} sx={{cursor:"pointer"}}><CloseIcon /></Box>
           </Stack>

           <Box sx={{alignItems:"center",margin:"20px 0px 0px 0px",overflow:"scroll",height:"80%",overflowX:"hidden"}}>
             {pricingpattern?.map((ele,index)=>{
                  // console.log("hello",ele)
                  return(
                 <Stack direction="row" spacing={4} sx={{margin:"0px 20px 10px 20px",justifyContent:"center",alignItems:"center"}}>
                  <Box sx={{width:"40%",overflow:"hidden"}}>
                      <Typography sx={{fontSize:"16px", fontWeight:"600"}}>{ele.pricing_template_name}</Typography>
                  </Box>
                  <Box sx={{width:"25%",textAlign:"center"}}>
                       <svg 
                  onClick={()=>{
                    deletpricingtemplate(ele.id)
                  }}
                  width="16" height="18" viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg" cursor="pointer">
                 <path d="M3 18C2.45 18 1.97933 17.8043 1.588 17.413C1.196 17.021 1 16.55 1 16V3H0V1H5V0H11V1H16V3H15V16C15 16.55 14.8043 17.021 14.413 17.413C14.021 17.8043 13.55 18 13 18H3ZM13 3H3V16H13V3ZM5 14H7V5H5V14ZM9 14H11V5H9V14Z" fill="#EF3649"/>
                 </svg>
                  </Box>
                  {/* <Box sx={{}}>
                     <svg width="17" height="20" viewBox="0 0 17 20" fill="none" xmlns="http://www.w3.org/2000/svg" cursor="pointer">

                      <path d="M2 20C1.45 20 0.979 19.8043 0.587 19.413C0.195667 19.021 0 18.55 0 18V4H2V18H13V20H2ZM6 16C5.45 16 4.97933 15.8043 4.588 15.413C4.196 15.021 4 14.55 4 14V2C4 1.45 4.196 0.979 4.588 0.587C4.97933 0.195667 5.45 0 6 0H15C15.55 0 16.021 0.195667 16.413 0.587C16.8043 0.979 17 1.45 17 2V14C17 14.55 16.8043 15.021 16.413 15.413C16.021 15.8043 15.55 16 15 16H6ZM6 14H15V2H6V14Z" fill="#1C1B1F"/>
                     </svg>
                  </Box> */}
                
                 </Stack>
                  )
                })}
            </Box>
           
        </Box>
      </Modal>}

            {/* <Divider variant="middle" sx={{mt:"40px", mb:"40px"}}/> */}

            <Stack
              direction="row"
              justifyContent="end"
              alignItems="center"
              spacing={2}
              sx={{ marginTop: "50px" }}
            >
              {/* <Button 
                         onClick={()=>{
                          navigate(-1)
                         }}
                       size="large" className="univercel_btn_class" sx={{textTransform:"none", color:"#fff"}}  startIcon={<KeyboardDoubleArrowLeftIcon/>}>Previous</Button>
                           */}
              <Button
                size="large"
                className="univercel_btn_class"
                sx={{
                  textTransform: "none",
                  color: "#fff",
                  background:
                    "linear-gradient(264.48deg, #79F1A4 -45.37%, #0E5CAD 88.97%)",
                }}
                disabled={disabled}
                onClick={() => {
                     
                    confirmproject()
                }}
              >
                Create Project
              </Button>
            </Stack>
          </Stack>

    </Stack>




      </Stack>
</Stack>


    
    </>
  );
};
