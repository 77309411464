import React from 'react'
import SideBar from '../../ChannelPartner/Components/sideBar/SideBar'
import Slave from "./components/units"

const Units = () => {
  return (
    <SideBar active={"Units"}>
        <Slave projectId={48} clickable></Slave>
    </SideBar>
  )
}

export default Units