import { Box } from "@mui/system";
import React, { useContext, useEffect, useState } from "react";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";

import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Button,
  Checkbox,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  Modal,
  OutlinedInput,
  Select,
  TextField,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import profileContext from "../../../context/profile/context";
import axios from "axios";
import { Apiaddress } from "../../../utils/consts";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const names = ["Aditya Birla Appartments", "Tata Farms"];

function RightSidebarMD({
  setTabledata,
  curselectedRows,
  id,
  toggleDrawer,
  setTrigger,
  trigger,  
  updateMember,
  setState,
  setStateadd
}) {
  const [expanded, setExpanded] = useState("panel1");
  const [disabelAccrodian, setdisabelAccrodian] = useState(
    curselectedRows ? "true" : "false"
  );
  const [selectValue, setSelectValue] = useState("High");
  const [setProjects, setsetProjects] = React.useState([]);

  const handleChangeLeadProject = (event) => {
    const {
      target: { value },
    } = event;
    setsetProjects(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };
  const [selectArr, setselectArr] = useState([
    {
      value: "High",
      label: "High",
    },
    {
      value: "low",
      label: "low",
    },
  ]);

  const createOpt = () => { };

  const handleChange = (i) => {
    setExpanded(i);
  };

  const [colors, setColors] = useState([
    { name: "Ajay", clr: "green" },
    { name: " Tarun", clr: " yellow" },
    { name: "  Ranbir", clr: "red" },
  ]);
  const [leadname, setLeadName] = useState("");
  const [leadclr, setLeadclr] = useState("");

  const addClr = () => {
    const obj = { name: leadname, clr: leadclr };
    setColors([...colors, obj]);
    handleClose();
  };

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [name, setName] = useState("");
  const [phone, setPhne] = useState("");
  const [Email, setEmail] = useState("");
  const [Team, setTeam] = useState("");
  const [Role, setRole] = useState("");

  useEffect(() => {
    console.log(updateMember);
    if (updateMember) {
      setName(updateMember.name);
      setPhne(updateMember.phone);
      setEmail(updateMember.email);
    }
  }, [updateMember]);

  const user = useContext(profileContext);

  const [newMemberId, setnewMemeberId] = useState(null);

  const [members, setMembers] = useState(null);

  const [token, setToken] = useState(null);

  useEffect(() => {
    setToken(localStorage.getItem("crm_token"));
  }, []);

  const fetchTeams = async () => {
    try {
      const headers = {
        Authorization: `JWT ${token}`,
      };
      const res = await axios.get(
        Apiaddress + "/api/role/teamlistview/",
        { headers }
      );
      console.log(res.data.teams);
      const data = res.data.teams;

      for (let i = 0; i < data.length; i++) {
        if (data[i].id == id) {
          const k = data[i].members;
          setMembers(k);
          break;
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  const [memberId, setmemeberId] = useState(null);

  const addMemb = async () => {
    try {
      const obj = {
        team_id: parseInt(id),
        members: memberId,
      };
      console.log(obj);
      const res = await axios.patch(
        Apiaddress + "/api/role/teamview/",
        obj
      );
      setTrigger(!trigger);
      console.log(res);
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    if (memberId) {
      addMemb();
    }
  }, [memberId]);

  useEffect(() => {
    if (members && newMemberId) {
      let arr = [];
      for (let i = 0; i < members.length; i++) {
        arr.push(members[i].id);
      }
      arr.push(newMemberId);
      setmemeberId(arr);
    }
  }, [newMemberId, members]);

  useEffect(() => {
    fetchTeams();
  }, [newMemberId]);

  const [userID, setUserID] = useState(null);

  useEffect(() => {
    // console.log(user);
    // console.log(user.UserState);
    if (user)
      if (user.UserState)
        if (user.UserState.user_obj)
          if (user.UserState.user_obj.id) setUserID(user.UserState.user_obj.id);
  }, [user]);
  // console.log(userID);

  const [projectsSelectedID, setprojectsSelectedID] = useState("Agent");

  const [role,setrole]=useState();
  const [roleid,setroleid]=useState();

  const saveChanges = async () => {
    if (updateMember) {
      try {
        const obj = {
          member_id: updateMember.member_id,
          name: name,
          email: Email,
          phone: phone,
          // role: projectsSelectedID,
          role:role,
          created_by: userID ? userID : "",
        };
        const res = await axios.patch(
          Apiaddress + "/api/role/memberview/",
          obj
        );
        console.log(res);
        setTrigger(!trigger);
        setName("");
        setPhne("");
        setEmail("");
        setrole("");
        setroleid("");
        setState(false);
      } catch (err) {
        console.log(err);
      }
    } else {
      try {
        const obj = {
          name: name,
          email: Email,
          phone: phone,
          // role: projectsSelectedID ? projectsSelectedID : "",
          role:role,
          created_by: userID ? userID : "",
        };
        const res = await axios.post(
          Apiaddress + "/api/role/memberview/",
          obj
        );
        setName("");
        setPhne("");
        setEmail("");
        setrole("");
        setroleid("");
        setState(false);
        console.log(res.data);
        setnewMemeberId(res.data.member.id);
      } catch (err) {
        console.log(err);
      }
    }
    // console.log(name, phone, Email, address, priority, setProjects);
  };

  const [data, setData] = useState(null);

  // const getRoles = async () => {
  //   try {
  //     const res = await axios.get(
  //       Apiaddress + "/api/uimanager/uiroles/"
  //     );
  //     console.log("roles",res.data.uiroles);
  //     if (res.data) if (res.data.uiroles) setData(res.data.uiroles); 
  //   } catch (err) {
  //     console.log(err);
  //   }
  // };

   const getRoles=async ()=>{
    try{
      const headers = {
        Authorization: `JWT ${token}`,
      };  
    const roleurl = Apiaddress+"/api/roleprofile/"
    const res = await axios.get(roleurl,{headers});
    console.log("=============================",res?.data?.roles);
    setData(res?.data?.roles);
    }
    catch(err){
      console.log(err);
    }
    }

  useEffect(() => {
    getRoles();
  }, [token]);

  const addprofiledata = () => { };
  const [projectsSelected, setprojectsSelected] = useState(0);

  const addProjectID = (para) => {
    setprojectsSelectedID(para);
  };
  const addProjects = (e) => {
    setprojectsSelected(e.target.value);
  };

  return (
    <Box
      sx={{
        width: "420px",
        height: "80vh",
        marginTop: "-25px",
        marginRight: "25px",
        padding: "10px",
      }}
    >
      <Box
        sx={{
          position: "relative",
        }}
      >
        <Box
          sx={{
            position: "absolute",
            top: "0px",
            right: "0px",
            background: "white",
            padding: "10px",
            height: "105vh",
            width: "400px",
            marginTop: "20px",
          }}
        >
          {" "}
          <Box>
            <Box sx={{ display: "flex", cursor: "pointer" }}>
              <Box>
                <ArrowBackIosIcon
                  sx={{
                    fontSize: "small",
                    marginBottom: "-2px",
                  }}
                />
              </Box>
              <Box>Members Directory</Box>
            </Box>
            <Box
              sx={{
                display: "grid",
                alignItems: "center",
                height: "100vh",
              }}
            >
              <Box>
                <Accordion
                  expanded={expanded === "panel1"}
                  onClick={() => handleChange("panel1")}
                >
                  <AccordionSummary
                    sx={{
                      background: `${expanded === "panel1" ? "#FFAF2A" : "white"
                        }`,
                      borderRadius: "10px",
                    }}
                    // expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography sx={{ color: "white" }}>
                      Members Directory
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        width: "400px",
                        margin: "10px 0",
                      }}
                    >
                      <Box>Name</Box>
                      <Box>
                        <TextField
                          value={name}
                          id="outlined-basic"
                          onChange={(e) => setName(e.target.value)}
                        />
                      </Box>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        width: "400px",
                        margin: "10px 0",
                      }}
                    >
                      <Box>
                        <Box>Phone</Box>
                        <Box>Number</Box>
                      </Box>
                      <Box>
                        {/* <TextField value="+91" sx={{ width: "60px" }} /> */}
                        <TextField
                          value={`${phone}`}
                          placeholder="+91 9999999999"
                          id="outlined-basic"
                          onChange={(e) => setPhne(e.target.value)}
                        />
                      </Box>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        width: "400px",
                        margin: "10px 0",
                      }}
                    >
                      <Box>Email</Box>
                      <Box>
                        <TextField
                          value={Email}
                          id="outlined-basic"
                          onChange={(e) => setEmail(e.target.value)}
                        />
                      </Box>
                    </Box>
                    <Box>
                      <FormControl variant="standard" sx={{ width: "400px" }}>
                        <InputLabel id="demo-simple-select-standard-label">
                          select role
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-standard-label"
                          id="demo-simple-select-standard"
                          // value={projectsSelected}
                          // onChange={addProjects}
                          onChange={(e) => {
                            let [name,role_id] = e.target.value.split("-");
                              setroleid(role_id);
                              setrole(name); 
                      }}
                          label="Age"
                        >
                          {data &&
                            data.map((item,index) => {
                              return (
                                <MenuItem
                                  // // value={item.roleName}
                                  // onClick={() => addProjectID(item.roleName)}
                                  key={index} 
                                  value={item.name+"-"+item.id}
                                >
                                  {/* {item.roleName} */}
                                  {item.name}
                                </MenuItem>
                              );
                            })}
                        </Select>
                      </FormControl>
                    </Box>
                    {/* <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        width: "400px",
                        margin: "10px 0",
                      }}
                    >
                      <Box>Team</Box>
                      <Box>
                        <TextField
                          id="outlined-basic"
                          onChange={(e) => setTeam(e.target.value)}
                        />
                      </Box>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        width: "400px",
                        margin: "10px 0",
                      }}
                    >
                      <Box>Role</Box>
                      <Box>
                        <TextField
                          id="outlined-basic"
                          onChange={(e) => setRole(e.target.value)}
                        />
                      </Box>
                    </Box> */}
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        width: "400px",
                        margin: "10px 0",
                      }}
                    >
                      <Box></Box>
                      {!updateMember && (
                        <Button
                          onClick={saveChanges}
                          className="univercel_btn_class"
                          sx={{
                            color: "#FFFFFF",
                            textTransform: "none",
                          }}
                        >
                          Add Member
                        </Button>
                      )}

                      {updateMember && (
                        <Button
                          onClick={saveChanges}
                          className="univercel_btn_class"
                          sx={{
                            color: "#FFFFFF",
                            textTransform: "none",
                          }}
                        >
                          Update Member
                        </Button>
                      )}
                    </Box>
                  </AccordionDetails>
                </Accordion>
              </Box>
            </Box>
            <Box
              sx={{
                display: "grid",
                placeItems: "center",
              }}
            >
              {/* <Button
                onClick={saveChanges}
                className="univercel_btn_class"
                sx={{
                  width: "200px",
                  color: "#FFFFFF",
                  textTransform: "none",
                  background:
                    " linear-gradient(150deg, #79F1A4 13.4%, #0E5CAD 86.6%)",

                  marginBottom: "20px",
                }}
              >
                Save Changes
              </Button> */}
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export default RightSidebarMD;
