import { Box, Button, Checkbox, Stack, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Apiaddress, net } from "../../../../utils/consts";
import { AuthService } from "../../../../context/buyer/services/auth";
import axios from "axios";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import KeyboardDoubleArrowLeftIcon from "@mui/icons-material/KeyboardDoubleArrowLeft";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import CircularProgress from "@mui/material/CircularProgress";
import { useNavigate, useParams } from "react-router-dom";
import Lottie from "react-lottie";
import animationData from "../../../../Lotties/oGze35Xf1B.json";

let stIndex = 0;
let edIndex = 0;

function DeassignSelfProject({
  selectedProject,
  setSelectedProject,
  self,
  trigger,
  setTrigger,
  deAllocateProject,
  lead_id
}) {
  const [data, setData] = useState(null);
  const [tempData, setTempData] = useState(null);    
  const [token, setToken] = useState(null);
  const [newRowData, setNewRowData] = useState([]);
  const [loading, setloading] = useState(false);
  const [assignedUnit,setAssignedUnit] = useState(null);

  const par = useParams();
  const navigate = useNavigate();

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  console.log(data);

  const cpDetails = JSON.parse(localStorage.getItem("cpDetails"));
  console.log(cpDetails);
  let cpOrg = cpDetails?.channelPartnerOrg;

  useEffect(() => {
    setToken(localStorage.getItem("crm_token"));
  }, [trigger]);

  const fetchLeadDetails = async () => {
    try {
      const res = await axios.get(
        Apiaddress + `/api/leads/lead/?lead_id=${lead_id}`
      );
      setAssignedUnit(res?.data?.lead[0].projects_cp_units);
      console.log(res?.data?.lead[0].projects_cp_units);
    } catch (err) {
      console.log(err);
    }
  };

  const getAssignedProject=async()=>{
    const headers = {
      Authorization: `JWT ${token}`,
    };
    let unique = new Set();
    let temparray=[];
    let arr = [];
    for (let i = 0; i < assignedUnit?.length; i++) {
      const res = await axios.get(Apiaddress + "/api/projects/cpUnitProject/?unit_id="+assignedUnit[i],{headers});
      let temp=res?.data?.unit[0];
      console.log("===========================.>", res?.data?.unit[0]);
      unique?.add(JSON.stringify(temp?.project));
      temparray.push(temp?.project?.id);
      }
      unique.forEach(ele=>{
        console.log(ele)
        if(ele){
          let parsedData=JSON.parse(ele);
          let c=0;
          for(let i=0;i<temparray?.length;i++){
             if(temparray[i] && temparray[i]===parsedData?.id){
              c++;
             }
          }
          let obj = {
            // id: i + 1,
            project_id:parsedData?.id,
            Name: parsedData?.name,
            State: parsedData?.state,
            City: parsedData?.city,
            Pincode: parsedData?.pin_code,  
            Unit:c,
          };
          arr.push(obj);
        }
      })
      setNewRowData(arr);
  }

  useEffect(() => {
    if (assignedUnit) {
      getAssignedProject();
    }
  },[assignedUnit]);

  // const getProjects = async () => {
  //   setloading(true);
  //   try {
  //     const auth = new AuthService();
  //     let url = "";
  //     console.log(cpOrg);
  //     if (cpOrg) {
  //       url = `role/inventeryofcp/?orgId=${cpOrg}`;
  //     } else {
  //       url = `role/inventeryofcp/`;
  //     }
  //     const headers = {
  //       Authorization: `JWT ${token}`,
  //     };
  //     const res1 = await (
  //       await auth.makeAuthenticatedGetRequest(net(url))
  //     ).json();

  //     if(res1?.self["filtured Data"]){
  //       let datahai= res1?.self["filtured Data"]?.projects;
  //       // setTempData(datahai);
  //       setData(datahai);
  //       setloading(false);
  //     }
  //   } catch (err) {
  //     console.log(err);
  //   }
  // };

  // useEffect(() => {
  //   if (tempData) {
  //     filterData(tempData);
  //   }
  // }, [tempData]);

  // const fitData=async()=>{
  //   let arr = [];
  //     for (let i = 0; i < data.length; i++) {
  //       const res = await axios.get(
  //         Apiaddress + `/api/projects/project/?project_id=${data[i].id}`
  //       );
  //       let temp=res?.data?.projects[0];
  //       let obj = {
  //         id: i + 1,
  //         project_id: temp?.id,
  //         Name: temp?.name,
  //         State: temp?.state,
  //         City: temp?.city,
  //         Pincode: temp?.pin_code,
  //         Unit: temp?.cp_units?.length,
  //       };
  //       arr.push(obj);
  //     }
  //   setNewRowData(arr);
  // }

  // useEffect(() => {
  //   if (data) {
  //     console.log(data);
  //     fitData();
  //   }
  // }, [data]);

  useEffect(() => {
    if (token){
      // getProjects();
      fetchLeadDetails();
    }
  }, [token, trigger]);

  //table Content
  const [curpage, setcurpage] = useState(1);
  const [pageRange, setPageRange] = useState([0, 5]);
  const [pages, setPages] = useState([]);

  const nextClick = () => {
    if (
      !(newRowData.length >= pageRange[0] && newRowData.length <= pageRange[1])
    ) {
      setcurpage(curpage + 1);
      stIndex = pageRange[0] + 6;
      edIndex = pageRange[1] + 6;
      if (newRowData?.length < edIndex) {
        edIndex = newRowData?.length - 1;
      }
      setPageRange([pageRange[0] + 6, pageRange[1] + 6]);
      setSelectedProject([]);
    }
  };

  const prvClick = () => {
    if (pageRange[0] != 0 && pageRange[1] != 5) {
      setcurpage(curpage - 1);
      stIndex = pageRange[0] - 6;
      edIndex = pageRange[1] - 6;
      setPageRange([pageRange[0] - 6, pageRange[1] - 6]);
      setSelectedProject([]);
    }
  };

  useEffect(() => {
    if (newRowData) {
      let arr = [];
      for (
        let i = 1;
        i <=
        (newRowData.length % 5 == 0
          ? newRowData.length / 5
          : newRowData.length / 5 + 1);
        i++
      ) {
        arr.push(i);
      }
      stIndex = 0;
      console.log(newRowData?.length);
      if (newRowData?.length > 0 && newRowData?.length < edIndex) {
        edIndex = newRowData?.length - 1;
      } else {
        edIndex = 5;
      }
      setPages(arr);
      setPageRange([0, 5]);
    }
  }, [newRowData]);

  let temp = [];
  const onCheck = (id) => {
    if (id === "selectAll") {
      for (let i = stIndex; i <= edIndex; i++) {
        temp.push(newRowData[i].project_id);
      }
      setSelectedProject(temp);
    } else if (id == "removeAll") {
      temp = [];
      setSelectedProject(temp);
    } else {
      let array = selectedProject;
      const index = array.indexOf(id);
      if (index > -1) {
        let temp1 = array.filter((ele) => {
          return ele != selectedProject[index];
        });
        temp = temp1;
      } else {
        temp = [...selectedProject, id];
      }
    }
    console.log("temp", temp);
    setSelectedProject(temp);
  };   

  return (
    <>
    <Stack direction="row" justifyContent="space-between" alignItems="center" sx={{marginTop:"10px"}}>
      <Stack>
      <Box>
            {selectedProject.length != 0 && (
              <Stack direction="row" sx={{}}>
                {
                  <Box>
                  {selectedProject.length != 0 && (
                    <Stack direction="row" sx={{ margin: "0px" }}>
                      {
                        <Button
                          onClick={() => {
                            deAllocateProject();
                          }}
                          className="univercel_btn_class"
                          sx={{
                            color: "#FFFFFF",
                            textTransform: "none",
                            width: "150px",
                            background: "#008dff",
                            boxShadow:
                              "0px 13.7981px 22.5788px rgba(50, 174, 137, 0.25)",
                            "&:hover": { background: "#008dff" },
                          }}
                        >
                          Deallocate
                        </Button>
                      }
                    </Stack>
                  )}
                </Box>
                }
              </Stack>
            )}
          </Box>
      </Stack>
      {/* <Stack>Hello</Stack> */}
    </Stack>
      <Box
        sx={{
          color: "#A2A3A6",
          height: "400px",
          paddingTop: "10px",
          width: "100%",
        }}
      >
        <Box>
          <Box sx={{ color: "black", width: "100%" }}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "start",
                alignItems: "center",
                background: "white",
                borderRadius: "5px",
                margin: " 10px 0",
                color: "#272D4E",
                fontWeight: "700",
                fontFamily: "Circular Std",
                fontSize: "18px",
              }}
            >
              <Box
                sx={{
                  width: "10vw",
                  display: "grid",
                  placeItems: "center",
                  height: "50px",
                }}
              >  
                {/* <Checkbox
                  checked={
                    edIndex - stIndex + 1 === selectedProject.length 
                      ? true
                      : false
                  }
                  onClick={() => {
                    if (edIndex - stIndex + 1 === selectedProject.length) {
                      onCheck("removeAll");
                    } else {
                      onCheck("selectAll");
                    }
                  }}
                /> */}
              </Box>
              <Box
                sx={{
                  width: "20vw",
                  display: "grid",
                  placeItems: "center",
                  height: "50px",
                  justifyContent: "start",
                  paddingLeft: "20px",
                }}
              >
                <Box sx={{ display: "flex" }}>
                  <Box sx={{ display: "grid", placeItems: "center" }}>Name</Box>
                </Box>
              </Box>

              <Box
                sx={{
                  width: "20vw",
                  display: "grid",
                  placeItems: "center",
                  height: "50px",
                  justifyContent: "start",
                  paddingLeft: "20px",
                }}
              >
                <Box sx={{ display: "flex" }}>
                  <Box sx={{ display: "grid", placeItems: "center" }}>
                    State
                  </Box>
                </Box>
              </Box>

              <Box
                sx={{
                  width: "20vw",
                  display: "grid",
                  placeItems: "center",
                  height: "50px",
                  justifyContent: "start",
                  paddingLeft: "20px",
                }}
              >
                <Box sx={{ display: "flex" }}>
                  <Box sx={{ display: "grid", placeItems: "center" }}>City</Box>
                </Box>
              </Box>

              <Box
                sx={{
                  width: "15vw",
                  display: "grid",
                  placeItems: "center",
                  height: "50px",
                  justifyContent: "start",
                  paddingLeft: "20px",
                }}
              >
                <Box sx={{ display: "flex" }}>
                  <Box sx={{ display: "grid", placeItems: "center" }}>
                    Pincode
                  </Box>
                </Box>
              </Box>

              <Box
                sx={{
                  width: "15vw",
                  display: "grid",
                  placeItems: "center",
                  height: "50px",
                  justifyContent: "start",
                  paddingLeft: "20px",
                }}
              >
                <Box sx={{ display: "flex" }}>
                  <Box sx={{ display: "grid", placeItems: "center" }}>Unit</Box>
                </Box>
              </Box>

              {/* <Box
            sx={{
              width: "15vw",
                display: "grid",
                placeItems: "center",
                height: "50px",
                justifyContent: "start",
                paddingLeft:"20px",
            }}
          >
            Actions
          </Box> */}
            </Box>
            {loading ? (
              <>
                <Box sx={{ textAlign: "center", marginTop: "50px" }}>
                  <CircularProgress
                    color="inherit"
                    sx={{ color: "grey.500" }}
                  />
                </Box>
              </>
            ) : newRowData?.length == 0 ? (
              <Box sx={{ textAlign: "center", marginTop: "100px" }}>  
                <Typography
                  sx={{ fontSize: "25px", fontWeight: "500", color: "#B9B7D0" }}
                >
                  <Lottie options={defaultOptions} height="20%" width="20%" />
                <Typography
                  sx={{
                    color: "#B9B7D0",
                    fontSize: "18px",
                    fontWeight: "400",
                    marginTop: "-40px",
                  }}
                >
                  Please add your data
                </Typography>
                  {/* <Button
                    variant="contained"
                    color="primary"
                    sx={{
                      height: "40px",
                      background: "#008dff",
                      boxShadow:
                        "0px 13.7981px 22.5788px rgba(50, 174, 137, 0.25)",
                      textTransform: "none",
                      borderRadius: "4px",
                      width: "140px",
                      fontSize: "15px",
                      margin: "5px 0px 5px 0px",
                      padding: "5px",
                      "&:hover": { background: "#008dff" },
                    }}
                    onClick={(e) => {
                      navigate(`/channelpartner/lead/project/assign/${par.id}`);
                    }}
                  >
                    Add New Project
                  </Button> */}
                </Typography>
              </Box>
            ) : (
              <Box sx={{}}>
                <Box sx={{ height: "320px" }}>
                  {newRowData &&
                    newRowData.map((item, index) => {
                      if (index >= pageRange[0] && index <= pageRange[1]) {
                        return (
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              background: "white",
                              borderRadius: "5px",
                              margin: " 10px 0",
                              color: "#343434",
                              "&:hover": {
                                backgroundColor: "#F5F7FF",
                                cursor: "pointer",
                              },
                              // fontFamily:"Product Sans",
                              // fontWeight: "400",
                              // cursor:"pointer",
                            }}
                            onClick={()=>{navigate(`/channelpartner/lead/project/deassign/unit/${item.project_id}`,{state:{lead_id:lead_id}})}}
                          >
                            <Box
                              sx={{
                                width: "10vw",
                                display: "grid",
                                placeItems: "center",
                                height: "50px",
                              }}
                            >
                              {/* {`${JSON.stringify(selectedProject.indexOf(item.project_id))}`} */}
                              {/* <Checkbox
                                checked={
                                  selectedProject.indexOf(item.project_id) > -1
                                    ? true
                                    : false
                                }
                                onClick={() => {
                                  onCheck(item.project_id);
                                }}
                              /> */}
                            </Box>
                            <Box
                              sx={{
                                width: "20vw",
                                display: "grid",
                                placeItems: "center",
                                height: "50px",
                                justifyContent: "start",
                                fontFamily: "Product Sans",
                                fontWeight: "400",
                                paddingLeft: "20px",
                              }}
                            >
                              {item.Name}
                            </Box>
                            <Box
                              sx={{
                                width: "20vw",
                                display: "grid",
                                placeItems: "center",
                                justifyContent: "start",
                                height: "50px",
                                fontFamily: "Product Sans",
                                fontWeight: "400",
                                paddingLeft: "20px",
                              }}
                            >
                              {item.State}
                            </Box>
                            <Box
                              sx={{
                                width: "20vw",
                                display: "grid",
                                placeItems: "center",
                                justifyContent: "start",
                                height: "50px",
                                fontFamily: "Product Sans",
                                fontWeight: "400",
                                paddingLeft: "20px",
                              }}
                            >
                              {item.City}
                            </Box>
                            <Box
                              sx={{
                                width: "15vw",
                                display: "grid",
                                placeItems: "center",
                                justifyContent: "start",
                                height: "50px",
                                fontFamily: "Product Sans",
                                fontWeight: "400",
                                paddingLeft: "20px",
                              }}
                            >
                              {item.Pincode}
                            </Box>
                            <Box
                              sx={{
                                width: "15vw",
                                display: "grid",
                                placeItems: "center",
                                justifyContent: "start",
                                height: "50px",
                                fontFamily: "Product Sans",
                                fontWeight: "400",
                                paddingLeft: "20px",
                              }}
                            >
                              {item.Unit}
                              {/* taking unit from the object according to coresponding project ids  */}
                            </Box>
                          </Box>
                        );
                      }
                    })}
                </Box>

                <Box sx={{ margin: "50px 0px 20px 0px" }}>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "right",
                      marginRight: "20px",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        placeItems: "center",
                        margin: " 0 10px",
                        borderRadius: "5px",
                      }}
                    >
                      <Typography sx={{}}>Page</Typography>
                      <Typography
                        sx={{
                          background: `white`,
                          color: "black",
                          width: "60px",
                          height: "30px",
                          borderRadius: "4px",
                          margin: "0 10px",
                          display: "grid",
                          placeItems: "center",
                          border: "1px solid #2979F2",
                        }}
                      >
                        {curpage}
                      </Typography>
                      <Typography
                        sx={{
                          color: "black",
                          width: "30px",
                          borderRadius: "5px",
                          margin: "0 0px",
                          display: "grid",
                          placeItems: "center",
                        }}
                      >
                        of
                      </Typography>
                      <Typography
                        sx={{
                          color: "black",
                          margin: "0px 10px 0px 0px",
                          display: "grid",
                          placeItems: "center",
                        }}
                      >
                        {pages?.length}
                      </Typography>
                    </Box>

                    {newRowData.length != 0 && (
                      <Button
                        variant="contained"
                        className="paginationButton"
                        sx={{ marginRight: "10px" }}
                        disabled={curpage == 1}
                        onClick={prvClick}
                      >
                        <KeyboardDoubleArrowLeftIcon fontSize="medium" />
                      </Button>
                    )}

                    {newRowData.length != 0 && (
                      <Button
                        variant="contained"
                        className="paginationButton"
                        disabled={curpage == pages?.length}
                        onClick={nextClick}
                      >
                        <KeyboardDoubleArrowRightIcon fontSize="medium" />
                      </Button>
                    )}
                  </Box>
                </Box>
              </Box>
            )}
          </Box>

          <Box></Box>
        </Box>
      </Box>
    </>
  );
}

export default DeassignSelfProject;
