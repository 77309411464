import { Accordion, Box, Button, FormControl, InputLabel, MenuItem, Select, Stack, TextField, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import axios from "axios";
import { api, Apiaddress, net } from "../../../../utils/consts";
import { AuthService } from "../../../../context/buyer/services/auth";
import { Country, State, City }  from 'country-state-city';
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { send } from "process";

function RightSidebarPD({
  statesetter,
  setTrigger,
  trigger
}) {

  const cpDetails=JSON.parse(localStorage.getItem('cpDetails'));
  console.log(cpDetails);
  let cpOrg=cpDetails?.channelPartnerOrg;
  let projectid = JSON.parse(localStorage.getItem("BuilderSelectedProject"));

  const [name, setName] = useState();
  const [phone, setPhne] = useState("");
  const [Email, setEmail] = useState("");
  const [address, setAddresss] = useState("");
  const [image, setImage] = useState();
  const [sendImage, setSendImage] = useState();
  const [city, setCity] = useState("");
  const [Pincode, setPincode] = useState();
  const [NoOfUnits, setNoOfUnits] = useState();
  const [phoneError,setPhoneError]=useState(false);
  const [imgError,setImgError] = useState(false);
  const [imgErrorText,setImgErrorText] = useState("");
  const [finalImage,setFinalImage] = useState();

  console.log(city);

  const fetchProjectDetail=async()=>{
    try{
      const res = await axios.get(
        Apiaddress + `/api/projects/project/?project_id=${projectid}`
      );
      let temp=res?.data?.projects[0];
      console.log("===========================.>", res?.data?.projects[0]);
      setName(temp?.name);
      setPhne(temp?.address_line);
      setPincode(temp?.pin_code);
      setEmail(temp?.state);
      setCity(temp?.city);
      setSendImage(temp?.banner_image);
    }catch(err){
       console.log(err.message);
    }
  }

  useEffect(()=>{
    fetchProjectDetail();
  },[]);


  const createProject=async(e)=>{
    e.preventDefault();
    try{
      if(sendImage==""){
        setImgError(true);
        setImgErrorText("Please Upload Banner Image");
        return;
      }
      if (name == "" || Email == "" || city == "" ||Pincode == "" || phone == "" || sendImage == "")return;
      if(Pincode?.length<6){
        console.log(Pincode);
        setPhoneError(true);
        return;
      }
      const Auth = new AuthService();
        const headers = {
        headers: {
          "content-type": "multipart/form-data",
          },
        };
      let data = {
        project_id:projectid,
        name: name,
        state: Email,
        city: city,
        pin_code: Pincode,
        address_line: phone,
        // banner_image: image?image:sendImage,
      };
      if(image){
        data.banner_image=finalImage;
      }
      console.log(data);
      let url = Apiaddress + "/api/projects/project/";
      let res = await axios.patch(url, data, headers);
      console.log(res.data);
      setName("");
      setPhne("");
      setEmail("");
      setAddresss("");
      setCity("");
      setPincode("");
      setImage(null);
      setSendImage(null);
      statesetter(false);
      setTrigger(!trigger);
    }catch(err){
      console.log(err);
    }
  }

  const clearItems = () => {
    setName(null);
    setEmail(null);
    setPhne(null);
    setAddresss(null);
  };

  //state and city selector
  let States=State.getStatesOfCountry('IN');
  const [stateCode,setStateCode] = useState("");
  const [allCities,setAllCities] = useState();  

  useEffect(()=>{
    if(Email){
      let States=State.getStatesOfCountry('IN');
      for(let i=0;i<States.length;i++){
         if(States[i].name===Email){
          setStateCode(States[i].isoCode);
          break;
         }
      }
    }
  },[Email])


  useEffect(()=>{
    let Cities=City.getCitiesOfState('IN',stateCode);
    setAllCities(Cities);
  },[stateCode])

  return(
    <Box
      sx={{
        width: "420px",
        marginTop: "-25px",
        marginRight: "25px",
        padding: "10px",
      }}
    >
      <Box sx={{ position: "relative" }}>
        <Box
          sx={{
            position: "absolute",
            top: "0px",
            right: "0px",
            background: "white",
            padding: "10px",
            height: "105vh",
            width: "400px",
            marginTop: "20px",
          }}
        >
          <Box>
            <Stack
              direction="row"
              onClick={() => statesetter(false)}
              sx={{
                display: "flex",
                alignItems: "center",
                gap: "10px",
                cursor: "pointer",
              }}
            >
              <ArrowBackIcon />
              <Typography
                sx={{
                  color: "#2979F2",
                  fontSize: "22px",
                  fontWeight: 600,
                  fontFamily: "Circular Std",
                }}
              >
                Edit Project
              </Typography>
            </Stack>
            
            <form onSubmit={createProject}>
            <Box sx={{ mt: "20px" }}>
              <Box>
                <Accordion
                  sx={{ boxShadow: "none" }}
                >
                  <Stack spacing={3}>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        width: "400px",

                        alignItems: "center",
                      }}
                    >
                      <Typography 
                        sx={{
                          color: "#84828c",

                          fontWeight: 500,
                          fontFamily: "Product Sans",
                        }}
                      >
                        Project Name
                      </Typography>
                      <Box>
                        <TextField required
                          sx={{ width: "225px" }}
                          value={name}
                          id="outlined-basic"
                          onChange={(e) => setName(e.target.value)}
                        />
                      </Box>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        width: "400px",

                        alignItems: "center",
                      }}
                    >
                      <Typography 
                        sx={{
                          color: "#84828c",
                          fontWeight: 500,
                          fontFamily: "Product Sans",
                        }}
                      >
                        Address
                      </Typography>
                      <Box>
                        <TextField required
                          value={phone}
                          id="outlined-basic"
                          onChange={(e) => setPhne(e.target.value)}  
                          sx={{ width: "225px" }}
                        />
                      </Box>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        width: "400px",  

                        alignItems: "center",
                      }}
                    >
                      <Typography
                        sx={{
                          color: "#84828c",

                          fontWeight: 500,
                          fontFamily: "Product Sans",
                        }}
                      >
                        State
                      </Typography>
                      <Box sx={{ width: "225px" }}>

                        <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">Select State</InputLabel>
                          <Select
                            required
                            value={Email}
                            label="Select State"
                            MenuProps={{ PaperProps: { sx: { maxHeight: 200,maxWidth: 250} } }}
                            onChange={(e)=>{
                              let temp = e.target.value.split("-");
                              console.log(temp);
                              setEmail(temp[0]);
                              setStateCode(temp[1]);
                              setCity("");
                            }}
                          >
                          {States &&
                            States?.map((option) => (
                              <MenuItem key={option.name+"-"+option.isoCode} value={option.name}>
                                {option.name}
                              </MenuItem>
                          ))}
                          </Select>
                        </FormControl>

                      </Box>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        width: "400px",
                      }}
                    >
                      <Typography
                        sx={{
                          color: "#84828c",

                          fontWeight: 500,
                          fontFamily: "Product Sans",
                        }}
                      >
                        City
                      </Typography>
                      <Box sx={{ width: "225px" }}>

                      <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">Select City</InputLabel>
                        <Select
                            required
                            value={city}
                            label="Select City"
                            MenuProps={{ PaperProps: { sx: { maxHeight: 200,maxWidth: 250} } }}
                            onChange={(e)=>{
                              let temp = e.target.value.split("-");
                              setCity(temp[0]);
                            }}
                          >
                            {allCities &&
                            allCities?.map((option) => (
                              <MenuItem key={option.name+"-"+option.isoCode} value={option.name}>
                                {option.name}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Box>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        width: "400px",  
                      }}
                    >
                      <Typography 
                        sx={{
                          color: "#84828c",

                          fontWeight: 500,
                          fontFamily: "Product Sans",
                        }}
                      >
                        Pincode
                      </Typography>
                      <Box>
                        <TextField required
                          value={Pincode}
                          type="number"
                          id="outlined-basic"
                          inputProps={{ minLength: 10, maxLength: 10 }}
                          onChange={(e) =>
                            e.target.value.length <= 6
                              ? setPincode(e.target.value)
                              : null
                          }
                          sx={{ width: "225px" }}
                        />
                      </Box>
                    </Box>
                    {phoneError && (
                          <Typography
                            sx={{
                              fontWeight: "500",
                              fontSize: "14px",
                              marginBottom: "5px",
                              color: "#da6061",
                            }}
                          >
                            Pincode Should be of 6 Characters
                          </Typography>
                        )}

                    <Box>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                       
                          <Stack direction="column">
                            <Box
                              sx={{
                                width: "230px",
                                height: "180px",
                                border: "3px dashed #D7D7D7",
                                borderRadius: "16px",
                              }}
                            >
                            {image==null?
                              <img
                              src={Apiaddress+sendImage}
                              style={{
                                width: "100%",
                                height: "100%",
                                borderRadius: "16px",
                              }}
                            />:
                            <img
                                src={image}
                                style={{
                                  width: "100%",
                                  height: "100%",
                                  borderRadius: "16px",
                                }}
                              />
                            }
                            </Box>
                            <Box
                              sx={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                marginTop: "10px",
                              }}
                            >
                              <Button
                                component="label"
                                sx={{
                                  color: "#B0AFAF",
                                  fontSize: "18px",
                                  fontWeight: 500,
                                  fontFamily: "Product Sans",
                                  cursor: "pointer",
                                  textTransform: "none",
                                }}
                              >
                                <input
                                  type="file"
                                  accept="image/*"
                                  hidden
                                  onChange={(e) => {
                                  let fileSize =
                                    e.target.files[0].size / 1024 / 1024;
                                  if (fileSize > 2) {
                                    setImgErrorText("Image size is greater than 2 MB");
                                    setImgError(true);
                                    return;
                                  }
                                  let url = URL.createObjectURL(
                                    e.target.files[0]
                                  );
                                  console.log(e.target.files[0]);
                                  setImage(url);
                                  setFinalImage(e.target.files[0]);
                                  setImgError(false);
                                  }}
                                />
                                Edit
                              </Button>
                            </Box>
                          </Stack>
                        {/* )} */}
                      </Box>
                      <Stack justifyContent="center" alignItems="center">
                      {imgError && (
                          <Typography
                            sx={{
                              fontWeight: "500",
                              fontSize: "14px",
                              marginTop: "15px",
                              color: "#da6061",
                            }}
                          >
                          {imgErrorText}
                          </Typography>
                        )}
                      </Stack>
                    </Box>

                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        width: "400px",
                      }}
                    >
                      <Box></Box>
                    </Box>
                  </Stack>
                </Accordion>

                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    flexDirection: "column",
                    alignItems: "center",
                    gap: "10px",
                  }}
                >
                  <Button
                    type="submit"
                    sx={{
                      padding: "10px 60px",
                      color: "#06386B",
                      textTransform: "none",
                      fontSize: "20px",
                      fontFamily: "Product Sans",
                      background:
                        "linear-gradient(97.85deg, #FFAF2A 0.95%, #FFC666 100%)",
                      "&:hover": {
                        boxShadow: "0px 9px 12px rgba(242, 178, 73, 0.59)",
                      },
                    }}
                  >
                    Edit Project
                  </Button>
                  <Box
                    sx={{
                      textAlign: "center",
                      margin: "6px",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      statesetter(false);
                      setName("");
                      setPhne("");
                      setEmail("");
                      setCity("");
                      setPincode("");
                      setImage("");
                      setSendImage("");
                    }}
                  >
                    <Typography
                      sx={{
                        color: "#B0AFAF",
                        fontWeight: 500,
                        fontFamily: "Product Sans",
                      }}
                    >
                      Cancel
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Box>
          </form>
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

export default RightSidebarPD;
