import { Box, Checkbox, Stack, Button } from "@mui/material";
import React, { useEffect, useState } from "react";

import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { Apiaddress } from "../../../../utils/consts";

function Appointmentlist({ cpid }) {
  const [newRowData, setNewRowData] = useState([1, 2, 3, 3, 4, 5, 5]);
  const [curpage, setcurpage] = useState(1);
  const [pageRange, setPageRange] = useState([0, 4]);

  const nextClick = () => {
    if (
      !(newRowData.length >= pageRange[0] && newRowData.length <= pageRange[1])
    ) {
      setcurpage(curpage + 1);
      setPageRange([pageRange[0] + 4, pageRange[1] + 4]);
    }
  };

  const prvClick = () => {
    if (pageRange[0] != 0 && pageRange[1] != 4) {
      setcurpage(curpage - 1);
      setPageRange([pageRange[0] - 4, pageRange[1] - 4]);
    }
  };

  const [pages, setPages] = useState([]);
  useEffect(() => {
    if (newRowData) {
      let arr = [];
      for (
        let i = 1;
        i <=
        (newRowData.length % 4 == 0
          ? newRowData.length / 4
          : newRowData.length / 4 + 1);
        i++
      ) {
        arr.push(i);
      }
      setPages(arr);
    }
  }, [newRowData]);

  const [apleads, setapleads] = useState(null);

  const fetchapp = async () => {
    try {
      const res = await axios.get(
        Apiaddress + `/api/leads/channelpartnerappointment/?channel_partner_id=${cpid}`
      );
      console.log(res);
      if (res.data)
        if (res.data.appointment)
          if (res.data.appointment != "appointment not found")
            setapleads(res.data.appointment);
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    if (cpid) {
      console.log(cpid);
      fetchapp();
    }
  }, [cpid]);

  const [allpriority, setallpriority] = useState(null);

  const getpriority = async () => {
    try {
      const res = await axios.get(
        // Apiaddress + "/api/leads/leadprority"
        Apiaddress + "/api/leads/appoinmentbyproject/?project_id="+24
      );
      console.log("ggdgdgdgdgd==============>",res.data);
      setallpriority(res.data.leadprority);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getpriority();
  }, []);
  const [prioritymap, setprioritymap] = useState(null);

  const setPri = () => {
    const mp = new Map();
    for (let i = 0; i < allpriority.length; i++) {
      mp.set(allpriority[i].id, allpriority[i].name);
    }
    setprioritymap(mp);
  };

  useEffect(() => {
    if (allpriority) {
      setPri();
    }
  }, [allpriority]);

  return (
    <Box>
      {" "}
      <div style={{ height: 400, width: "100%" }}>
        <Box sx={{ color: "black", width: "100%" }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              background: "white",
              borderRadius: "5px",
              margin: " 10px 0",
              color: "#9299BC",
              fontWeight: "600",
            }}
          >
            <Box
              sx={{
                width: "10vw",
                display: "grid",
                placeItems: "center",
                height: "50px",
              }}
            >
              <Box sx={{ display: "flex" }}>
                <Box sx={{ display: "grid", placeItems: "center" }}>
                  Slot name
                </Box>
                <Box sx={{ display: "grid", marginLeft: "4px" }}>
                  <ArrowDropUpIcon
                    fontSize="medium"
                    sx={{ marginBottom: "-8px" }}
                  />
                  <ArrowDropDownIcon
                    fontSize="medium"
                    sx={{ marginTop: "-8px" }}
                  />
                </Box>
              </Box>
            </Box>

            <Box
              sx={{
                width: "10vw",
                display: "grid",
                placeItems: "center",
                height: "50px",
              }}
            >
              <Box sx={{ display: "flex" }}>
                <Box sx={{ display: "grid", placeItems: "center" }}>
                  Lead name
                </Box>
                <Box sx={{ display: "grid", marginLeft: "4px" }}>
                  <ArrowDropUpIcon
                    fontSize="medium"
                    sx={{ marginBottom: "-8px" }}
                  />
                  <ArrowDropDownIcon
                    fontSize="medium"
                    sx={{ marginTop: "-8px" }}
                  />
                </Box>
              </Box>
            </Box>

            <Box
              sx={{
                width: "10vw",
                display: "grid",
                placeItems: "center",
                height: "50px",
              }}
            >
              <Box sx={{ display: "flex" }}>
                <Box sx={{ display: "grid", placeItems: "center" }}>
                  Lead phone
                </Box>
                <Box sx={{ display: "grid", marginLeft: "4px" }}>
                  <ArrowDropUpIcon
                    fontSize="medium"
                    sx={{ marginBottom: "-8px" }}
                  />
                  <ArrowDropDownIcon
                    fontSize="medium"
                    sx={{ marginTop: "-8px" }}
                  />
                </Box>
              </Box>
            </Box>

            <Box
              sx={{
                width: "25vw",
                display: "grid",
                placeItems: "center",
                height: "50px",
              }}
            >
              <Box sx={{ display: "flex" }}>
                <Box sx={{ display: "grid", placeItems: "center" }}>
                  Lead Email
                </Box>
                <Box sx={{ display: "grid", marginLeft: "4px" }}>
                  <ArrowDropUpIcon
                    fontSize="medium"
                    sx={{ marginBottom: "-8px" }}
                  />
                  <ArrowDropDownIcon
                    fontSize="medium"
                    sx={{ marginTop: "-8px" }}
                  />
                </Box>
              </Box>
            </Box>

            <Box
              sx={{
                width: "10vw",
                display: "grid",
                placeItems: "center",
                height: "50px",
              }}
            >
              <Box sx={{ display: "flex" }}>
                <Box sx={{ display: "grid", placeItems: "center" }}>
                  Lead priority
                </Box>
                <Box sx={{ display: "grid", marginLeft: "4px" }}>
                  <ArrowDropUpIcon
                    fontSize="medium"
                    sx={{ marginBottom: "-8px" }}
                  />
                  <ArrowDropDownIcon
                    fontSize="medium"
                    sx={{ marginTop: "-8px" }}
                  />
                </Box>
              </Box>
            </Box>

            <Box
              sx={{
                width: "10vw",
                display: "grid",
                placeItems: "center",
                height: "50px",
              }}
            >
              Visit status
            </Box>
          </Box>
          {apleads &&
            apleads.map((item, index) => {
              if (index >= pageRange[0] && index <= pageRange[1]) {
                return (
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      background: "white",
                      borderRadius: "5px",
                      margin: " 10px 0",
                      color: "#06152C",
                      fontWeight: "500",
                    }}
                  >
                    <Box
                      sx={{
                        width: "10vw",
                        display: "grid",
                        placeItems: "center",
                        height: "50px",
                      }}
                    >
                      dfsf
                    </Box>
                    <Box
                      sx={{
                        width: "10vw",
                        display: "grid",
                        placeItems: "center",
                        height: "50px",
                      }}
                    >
                      {item.lead.name}
                    </Box>
                    <Box
                      sx={{
                        width: "10vw",
                        display: "grid",
                        placeItems: "center",
                        height: "50px",
                      }}
                    >
                      {item.lead.phone}
                    </Box>
                    <Box
                      sx={{
                        width: "25vw",
                        display: "grid",
                        placeItems: "center",
                        height: "50px",
                      }}
                    >
                      {item.lead.email}
                    </Box>
                    <Box
                      sx={{
                        width: "10vw",
                        display: "grid",
                        placeItems: "center",
                        height: "50px",
                      }}
                    >
                      {/* {prioritymap.get(item.lead.lead_prority)} */}
                    </Box>
                    <Box
                      sx={{
                        width: "10vw",
                        display: "grid",
                        placeItems: "center",
                        height: "50px",
                      }}
                    >
                      lkkjd
                      {/* <Stack direction="row" sx={{ marginLeft: "10px" }}>
                        <RemoveRedEyeIcon
                          fontSize="medium"
                          onClick={() => {
                            navigate(`/channelpartneroverview/${item.CPid}`, {
                              state: item,
                            });
                          }}
                        />

                        <EditIcon
                          fontSize="medium"
                          sx={{ marginLeft: "10px" }}
                          onClick={() => updateBuyers()}
                        />
                        <DeleteIcon
                          fontSize="medium"
                          sx={{ marginLeft: "10px" }}
                          onClick={() => delIconClick(item)}
                        />
                      </Stack> */}
                    </Box>
                  </Box>
                );
              }
            })}
        </Box>
        <Box>
          <Box sx={{ display: "flex", justifyContent: "right" }}>
            <Button
              variant="contained"
              sx={{
                background:
                  "linear-gradient(90deg, #0F5DAE 0%, #009DCF 100.33%)",
              }}
              onClick={prvClick}
            >
              <ArrowBackIosIcon fontSize="small" />
              <Box sx={{ textTransform: "capitalize" }}>Previous</Box>
            </Button>
            <Box
              sx={{
                background: "#E3E4EB",
                display: "flex",
                placeItems: "center",
                margin: " 0 10px",
                borderRadius: "5px",
              }}
            >
              {pages.map((page, ind) => {
                return curpage == ind + 1 ? (
                  <Box
                    sx={{
                      background: `white`,
                      color: "black",
                      width: "30px",
                      borderRadius: "5px",
                      margin: "0 10px",
                      display: "grid",
                      placeItems: "center",
                    }}
                  >
                    {page}
                  </Box>
                ) : (
                  <Box
                    sx={{
                      background: `${(0 + newRowData.length) / 2 == ind ? "white" : "#E3E4EB"
                        }`,
                      color: "black",
                      width: "30px",
                      borderRadius: "5px",
                      margin: "0 10px",
                      display: "grid",
                      placeItems: "center",
                    }}
                  >
                    {page}
                  </Box>
                );
              })}
            </Box>
            <Button
              variant="contained"
              sx={{
                background:
                  "linear-gradient(90deg, #0F5DAE 0%, #009DCF 100.33%)",
              }}
              onClick={nextClick}
            >
              <Box sx={{ textTransform: "capitalize" }}>Next</Box>
              <ArrowForwardIosIcon fontSize="small" />
            </Button>
          </Box>
        </Box>
      </div>
    </Box>
  );
}

export default Appointmentlist;
