import { Box, Stack } from "@mui/material";
import CommonPagesFooter from "../Shared/CommonPagesFooter";
import CommonPagesNavbar from "../Shared/CommonPagesNavbar";
import CommonPagesResponsiveNavbar from "../Shared/CommonPagesResponsiveNavbar";
import BuyerLandingEmpoweringHomeBuyers from "./BuyerLandingEmpoweringHomeBuyers";
import BuyerLandingForHomeBuyers from "./BuyerLandingForHomeBuyers";
import BuyerLandingGoneAreTheDaysOfOblivion from "./BuyerLandingGoneAreTheDaysOfOblivion";
import BuyerLandingHeader from "./BuyerLandingHeader";
import BuyerLandingHomeBuyingExperience from "./BuyerLandingHomeBuyingExperience";

export const BuyerLandingPage = () => {
  return (
    <>
      <CommonPagesNavbar />
      <CommonPagesResponsiveNavbar />
      <Stack spacing={{ lg: 14, md2: 12, md: 7, xs: 5 }}>
        <BuyerLandingHeader />
        <BuyerLandingHomeBuyingExperience />
        <BuyerLandingGoneAreTheDaysOfOblivion />
        <BuyerLandingEmpoweringHomeBuyers />
        <Box>
          <BuyerLandingForHomeBuyers />
          <CommonPagesFooter />
        </Box>
      </Stack>
    </>
  );
};
