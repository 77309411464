import { Box, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import "./LDmainPage.css";
import React, { useContext, useEffect, useState } from "react";
import LDcustomTable from "./LDcustomTable";
import { useLocation } from "react-router-dom";
import TabBar from "../../Components/tabBar/TabBar";
import NavigateBack from "../../../CommonPages/Shared/NavigateBack";

function LDmainPage({ location }) {
  const origin = useLocation()?.state?.origin ?? location;
  const projectid = useLocation()?.state?.projectid ?? null;
  const unit = useLocation()?.state?.unit ?? null;

  console.log({ origin });

  // const origin = useLocation()?.state?.origin ?? null;
  // console.log({ unit });
  const delIconClick = (item) => {};

  const toggleDrawer = (open, item) => (event) => {
    // // console.log(open);
    // if (
    //   event.type === "keydown" &&
    //   (event.key === "Tab" || event.key === "Shift")
    // ) {
    //   return;
    // }
    // updateLead(item);
    // setState(open);
  };

  const onRowsSelectionHandler = (item, ind, mod) => {
    // if (mod == "all") {
    //   if (curselectedRows.length != 0) {
    //     if (curselectedRows.length == item.length) {
    //       setcurselectedRows([]);
    //       setRowSelected(false);
    //       return;
    //     }
    //   } else {
    //     setcurselectedRows(item);
    //     setRowSelected(true);
    //   }
    // } else {
    //   let flag = 0;
    //   let arr = [];
    //   // console.log("ccc", item.lead_id);
    //   for (let i = 0; i < curselectedRows.length; i++) {
    //     if (curselectedRows[i].lead_id == item.lead_id) {
    //       // // console.log("called");
    //       flag = 1;
    //     } else {
    //       arr.push(curselectedRows[i]);
    //       // setcurselectedRows([...curselectedRows, curselectedRows[i]]);
    //     }
    //   }
    //   if (flag === 0) {
    //     setcurselectedRows([...curselectedRows, item]);
    //   } else {
    //     setcurselectedRows([...arr]);
    //   }
    //   setRowSelected(true);
    // }
  };

  return (
    <>
      <Stack direction="row" sx={{ width: "100%", height: "auto" }}>
        {/* 1st stack */}

        {/* 2nd stack */}
        <Stack
          direction="column"
          sx={{
            backgroundColor: "#f9f9f9",
            width: "100%",
            marginTop: "0px",
            boxSizing: "border-box",
          }}
        >
          {" "}
          {/*  width for main sidebar 78px and for 80px*/}
          {/* <Navbar/> */}
          {/* <Typography sx={{mt:"20px", fontSize:"22px", fontWeight:"500",color:"#2979F2"}}>
    Project Name
    </Typography> */}
          {/* actual content  */}
          <Stack sx={{ backgroundColor: "#f9f9f9" }}>
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              sx={{ marginBottom: "10px" }}
            >
              <Stack>
              <Stack direction="row">
                  {/* <Box sx={{ pt: "5px" }}>
                    <NavigateBack fontSize="22px" />
                  </Box> */}
                  <Typography
                    sx={{
                      fontSize: "22px",
                      fontWeight: "600",
                      color: "black",
                    }}
                    >
                    Appointment Directory
                  </Typography>
                </Stack>
                <Typography
                  sx={{
                    color: "#B9B7D0",
                    fontSize: "16px",
                    fontWeight: "400",
                  }}
                >
                  Manage your Appointments
                </Typography>
              </Stack>
            </Stack>

            {/* <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              sx={{ marginTop: "20px" }}
            >
              <Typography
                sx={{
                  fontSize: "20px",
                  fontWeight: "400",
                  color: "#2979F2",
                  fontFamily: "Circular Std",
                  borderBottom: "3px solid #2979F2",
                  paddingBottom: "10px",
                  marginTop: "-2px",
                }}
              >
                {unit ? `Appointment List of ${unit?.name}` : "Details"}
              </Typography>
            </Stack> */}
            <TabBar
              onIndexChange={(e) => {
                // setTabIndex(e);
                // setSelectedLeads([]);
                // setStartLead(0);
                // setcurpage(1);
                // setTrigger(!trigger);
                // setfiltervalue("");
              }}
              Tabs={[
                {
                  children: [
                    <Stack sx={{ height: "500px" }}>
                      <LDcustomTable
                        origin={origin}
                        projectid={projectid}
                        unit={unit}
                        delIconClick={delIconClick}
                        onRowsSelectionHandler={onRowsSelectionHandler}
                        toggleDrawer={toggleDrawer}
                      />
                    </Stack>,
                  ],
                  // Name: "Self Leads",
                  Name: unit ? `Appointment List of ${unit?.name}` : "Details",
                },
              ]}
            ></TabBar>
          </Stack>
        </Stack>
      </Stack>
    </>
  );
}

export default LDmainPage;
