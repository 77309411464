import React from 'react'
import SideBar from '../Components/sideBar/SideBar'
import View from './view'

const CPLeads = () => {
  return (
    <SideBar active={"Leads"} dontShowNav>
      <View/>
    </SideBar>
  )
}

export default CPLeads