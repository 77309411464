

import { AuthService } from '../../../context/buyer/services/auth'

import { net } from '../../../utils/consts'



import { Box, Button, TextField, Typography } from "@mui/material"
import { Stack } from "@mui/system"


import { useForm } from "react-hook-form"
import { useNavigate, useParams, useSearchParams } from "react-router-dom"
import { Apiaddress } from "../../../utils/consts"
import axios from "axios"
import { useState } from "react"

export const SetupPassword = () => {

  const [searchParams, setSearchParams] = useSearchParams();
  const { register, handleSubmit, watch, formState: { errors } } = useForm();
  const [notSame, setNotSame] = useState(false)
  const nav = useNavigate();
  const par = useParams()
  const id = par.cp_id
  const onSubmit = async (data) => {
    setNotSame(false)
    if (data.password === data.confirmPassword) {
      setNotSame(true);
      return;
    }
    console.log(key, data)

    const auth = new AuthService()
    const res = await auth.makeUnAuthenticatedPostRequest(net("role/setInvitedCPPassword/"), {
      JsonBody: {
        "oldpass": data.password,
        "newpass": data.confirmPassword,
        "cp": (id)
      }
    })
    nav("/login")
  }

  // searchParams.get("activation_key");
  const [key, setkey] = useState(searchParams.get("activation_key"));
  // console.log(searchParams.get("activation_key"))

  return (
    <>
      <Box
        sx={{
          display: "flex",
        }}
      >

        <Stack direction="column" alignItems="stretch" justifyContent="space-between" sx={{ width: "40%", backgroundColor: "#F4F6FB", height: "100vh", textAlign: "left", padding: "24px 24px", boxSizing: "border-box" }}>

          <Stack direction="row" justifyContent="end" spacing={2} alignItems="center"
            sx={{ height: "10vh" }}>
            <Typography sx={{ fontSize: "1.2vw" }}>Having trouble? <span style={{ color: "#FFA946" }}>Get Help</span> </Typography>
          </Stack>

          <Stack direction="row" justifyContent="center" spacing={2} alignItems="center"
            sx={{ height: "90vh" }}>
            <Box sx={{ padding: "10px", width: "75%" }}>

              <Typography sx={{ fontWeight: "600", fontSize: "2vw", lineHeight: "41px" }}>Set Password For Envision</Typography>
              <Typography sx={{ fontSize: "1.3vw", color: "gray", margin: "10px 0px 30px 0px", lineHeight: "20px" }}>Lorem Ipsum is simply dummy text of the printing </Typography>

              <form onSubmit={handleSubmit(onSubmit)}>


                <Typography sx={{ fontWeight: "500", fontSize: "18px", color: errors.password?.message ? "#da6061" : "black" }}>Previous Password</Typography>
                <TextField
                  sx={{
                    width: "100%",
                    margin: "10px 0px 10px 0px"
                  }}
                  type="password"
                  id="outlined-error-helper-text"
                  name="password"
                  fullWidth
                  {...register("password", {
                    required: "Password is required.",
                    // pattern: {
                    //   value:
                    //     /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/,
                    //   message:
                    //     "Password must contain at least one uppercase, one lowercase and a number ",
                    // },
                    // minLength: {
                    //   value: 8,
                    //   message: "Password must be at least 8 characters long",
                    // },
                  })}
                  error={Boolean(errors.password)}
                  helperText={errors.password && errors.password.message}
                />
                {/* {...register("password", {
                  required: "Password is required",
                  pattern: {
                    value:
                      /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/,
                    message:
                      "Password must contain at least one uppercase, one lowercase and a number ",
                  },
                  minLength: {
                    value: 8,
                    message: "Password must be at least 8 characters long",
                  },
                })}
 */}


                <Typography sx={{ fontWeight: "500", fontSize: "18px", color: errors.confirmPassword?.message ? "#da6061" : "black" }}>New Password</Typography>
                <TextField
                  sx={{
                    width: "100%",
                    margin: "10px 0px 10px 0px"
                  }}
                  type="password"
                  id="outlined-error-helper-text"
                  name="confirmPassword"
                  fullWidth
                  {...register("confirmPassword", {
                    required: "Confirm Password is required.",
                    pattern: {
                      value:
                        /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/,
                      message:
                        "Password must contain at least one uppercase, one lowercase and a number ",
                    },
                    minLength: {
                      value: 8,
                      message: "Password must be at least 8 characters long",
                    },
                  })}
                  error={errors.confirmPassword ? true : false}
                  helperText={errors.confirmPassword && errors.confirmPassword.message}
                />

                {notSame &&
                  <Typography sx={{ fontWeight: "500", fontSize: "16px", color: "#da6061" }}>Password is same </Typography>
                }
                {/*<input type="text" className="input_login" placeholder="Example@gmail.com"/>

                <Typography sx={{fontWeight: "500",fontSize: "18px"}}>Password</Typography>
                <input type="text" className="input_login"/>

                <Typography sx={{fontWeight: "500",fontSize: "18px"}}>Confirm Password</Typography>
                <input type="text" className="input_login"/> */}



                <Stack direction="row" justifyContent="center" alignItems="center"
                  sx={{ marginTop: "20px" }}>
                  <Button
                    type="submit"
                    sx={{
                      background: "linear-gradient(90deg, #0F5DAE 0%, #009DCF 100.33%)",
                      boxShadow: "0px 13.7981px 22.5788px rgba(50, 174, 137, 0.25)",
                      color: "#fff",
                      padding: "8px 70px",
                      textTransform: "none"
                    }}>Proceed</Button>
                </Stack>


              </form>

            </Box>


          </Stack>


        </Stack>


        <Stack direction="column" alignItems="center" justifyContent="center" sx={{ width: "60%", height: "100vh" }}>
          <h1>TBD</h1>
        </Stack>


      </Box>
    </>
  )
}

export default SetupPassword