import { useLocation } from "react-router-dom";
import SideBar from "../Components/sideBar/SideBar";
import LDmainPage from "./LeadsDirectory/LDmainPage";

const CPAppointments = ({ origin }) => {
  const location = useLocation()?.state?.origin ?? origin;
  // console.log(origin);
  return (
    <SideBar active={location}>
      <LDmainPage location={location} />
    </SideBar>
  );
};

export default CPAppointments;
