import { Stack } from '@mui/material'
import React from 'react'
import { BuyerSidebar } from './SideBarimpl'

const SideBar = (props) => {
  return (
    <Stack direction="row" sx={{width:"100%",height:"auto" }} onClick={(event)=>{
        // event.stopPropagation(); 
          // setOpenOrganization(false)
        }}>
              {/* 1st stack */}
              <Stack sx={{minHeight:"100vh",width:"20%",boxSizing:"border-box",}}>
                <BuyerSidebar  active={props.active}/>
              </Stack>
        
             {/* 2nd stack */}
             <Stack direction="column" sx={{ backgroundColor:"#f9f9f9",width:"80%", padding:"25px",boxSizing:"border-box",}}  >  {/*  width for main sidebar 78px and for 80px*/}
              {/* <Navbar/> */}
              
              
        
        
            {/* <Typography sx={{mt:"20px", fontSize:"22px", fontWeight:"500",color:"#2979F2"}}>
            Project Name
            </Typography> */}
             {/* actual content  */}
            <Stack sx={{ mt:"28px",backgroundColor:"#f9f9f9"}}>
         
        
            </Stack>
        
        
            {props.children}
        
            </Stack>
        </Stack>
  )
}

export default SideBar