import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  MenuItem,
  Drawer,
  Box,
  Button,
  Checkbox,
  FormControl,
  InputLabel,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { Stack } from "@mui/system";
import TuneOutlinedIcon from "@mui/icons-material/TuneOutlined";
import KeyboardArrowRightOutlinedIcon from "@mui/icons-material/KeyboardArrowRightOutlined";
import KeyboardArrowUpOutlinedIcon from "@mui/icons-material/KeyboardArrowUpOutlined";
import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined";
import { CheckBox } from "@mui/icons-material";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import DriveFileRenameOutlineIcon from "@mui/icons-material/DriveFileRenameOutline";
import VisibilityIcon from "@mui/icons-material/Visibility";
import DeleteIcon from "@mui/icons-material/Delete";
import "../../../BuilderComponents/MembersDirectory/MDmainPage.css";
import React, { useEffect, useState } from "react";
import { DataGrid, GridExpandMoreIcon } from "@mui/x-data-grid";
import CategoryIcon from "@mui/icons-material/Category";
import HandshakeIcon from "@mui/icons-material/Handshake";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import RightSidebarPD from "./RightSidebarPD";
import axios from "axios";
import PDcustomTable from "./PDcustomTable";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { UserRole } from "../../../../UserRoles/UserRoles";
import { api, Apiaddress, contains, where } from "../../../../utils/consts";
import { useParams } from "react-router-dom";
import { emitWarning } from "process";

const columns = [
  { field: "id", headerName: "id", width: 130 },
  { field: "Name", headerName: "Name", width: 130 },
  { field: "State", headerName: "State", width: 130 },
  { field: "City", headerName: "City", width: 130 },
  {
    field: "Pincode",
    headerName: "Pincode",
    // type: "number",
    width: 130,
  },
  {
    field: "Unit",
    headerName: "Unit",
    width: 130,
  },
  {
    field: "Action",
    headerName: "Action",
    headerAlign: "center",
    width: 130,
    renderCell: (params) => {
      return (
        <Stack direction="row">
          <RemoveRedEyeIcon fontSize="small" />
          <DeleteIcon fontSize="small" />
        </Stack>
      );
    },
  },
];

const rows = [
  {
    id: 1,
    Name: "#4560",
    State: "26 March 2022, 12:42 AM",
    City: "Seema Agarwal",
    Pincode: "67 St. pune",
    Unit: "Agent",
  },
];

function PDmainPage() {

  ////get user roles from local storage and assign 
  let roles = JSON.parse(localStorage.getItem("user_roles"))

  let project = roles["project"];

  const [state, setState] = React.useState(false);

  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState(open);
  };

  const [selectedRows, setSelectedRows] = React.useState([]);
  useEffect(() => {
    console.log(selectedRows);
  }, [selectedRows]);

  const [curselectedRows, setcurselectedRows] = useState(null);
  const onRowsSelectionHandler = (ids) => {
    setRowSelected(true);
    const selectedRowsData = ids.map((id) => rows.find((row) => row.id === id));
    console.log(selectedRowsData);
    setcurselectedRows(selectedRowsData);
  };

  const [tabledata, setTabledata] = useState(null);
  const [rowData, setrowData] = useState([]);
  const [idCont, setidCunt] = useState(1);

  //api

  //api

  useEffect(() => {
    const rows = [
      {
        id: 8,
        Name: "Frances",
        Phone: "912912912",
        Email: "dummmy@gamil.com",
        Team: "Agent",
        Role: "Agent",
        DetailedView: "View",
      },
    ];
    if (tabledata) {
      let obj = {
        id: idCont,
        Name: tabledata.name,
        Phone: tabledata.phone,
        Email: tabledata.Email,
        Team: tabledata.Team,
        Role: tabledata.Role,
      };
      setidCunt(idCont + 1);
      console.log(obj);
      setrowData([...rowData, obj]);
    }

    console.log(tabledata);
  }, [tabledata]);

  const [rowSelected, setRowSelected] = useState(false);

  const [data, setData] = useState(null);
  const [token, setToken] = useState(null);
  const par = useParams()
  const [trigger, setTrigger] = useState(false);
  const getProjects = async () => {
    try {
      const headers = {
        Authorization: `JWT ${token}`,
      };
      const res = await axios.get(
        Apiaddress + "/api/projects/getprojectsusingtoken/",
        { headers }
      );

      const resLead = await axios.get(api("/api/leads/lead/?lead_id=" + par.id))
      const projs = res.data.projects


      let x = [];
      for (let i = 0; i < projs.length; i++) {
        console.log("",
         resLead.data.lead[0].projects,
         projs[i].id,
          projs[i].id in resLead.data.lead[0].projects)
        if ( contains({arr:resLead.data.lead[0].projects,obj:projs[i].id})) {
          x.push(projs[i])
        }
      }


      console.log("this is x in leads overviews projects ", x);
      if (x != undefined) {
        setData(x);
        console.log("projectssss==>", res.data.projects)
      }
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    if (token) getProjects();
  }, [token, trigger]);

  useEffect(() => {
    setToken(localStorage.getItem("crm_token"));
  }, [trigger]);

  const [newRowData, setNewRowData] = useState([]);

  useEffect(() => {
    if (data) {
      console.log("this is data", data)
      let arr = [];
      for (let i = 0; i < data.length; i++) {
        let obj = {
          id: i + 1,
          project_id: data[i].id,
          Name: data[i].name,
          State: data[i].state,
          City: data[i].city,
          Pincode: data[i].pin_code,
          Unit: "unit count",
        };
        arr.push(obj);
      }
      setNewRowData(arr);
    }
  }, [data]);

  const delIconClick = async (params) => {
    console.log(params);
    try {
      const obj = {
        project_id: params.project_id,
      };

      const res = await axios.delete(
        Apiaddress + "/api/projects/project/",
        {
          data: obj,
        }
      );
      setTrigger(!trigger);
      window.alert("delted successfully");
      console.log(res);
    } catch (err) {
      console.log(err);
    }
  };
  const [updateInfo, setupdateInfo] = useState(null);
  const updateBuyers = (params) => {
    setupdateInfo(params.row);
  };

  // const sortArr = (para) => {
  //   function compare(a, b) {
  //     if (a[para] < b[para]) {
  //       return -1;
  //     }
  //     if (a[para] > b[para]) {
  //       return 1;
  //     }
  //     return 0;
  //   }
  //   let arr = newRowData;
  //   arr.sort(compare);
  //   console.log(arr);
  //   setNewRowData(arr);
  // };


  //fitering data starts:====
  const [filterselect, setfilterselect] = useState();
  const [filtervalue, setfiltervalue] = useState();

  const handleChange = (event) => {
    setfilterselect(event.target.value);
    setfiltervalue("");
  };

  console.log(filterselect);
  console.log(filtervalue);

  const fetchfilter = async () => {
    try {
      const headers = {
        Authorization: `JWT ${token}`,
      };
      const res = await axios.get(
        Apiaddress + `/api/projects/getprojectsusingtoken/?filterkey=${filterselect}&filtervalue=${filtervalue}`,
        { headers }
      );
      console.log(res.data.projects);
      if (res.data) if (res.data.projects) setData(res.data.projects);
    } catch (err) {
      console.log(err);
    }
  }



  return (
    <>


      <Stack direction="row" sx={{ width: "100%", height: "auto" }}>
        {/* 1st stack */}


        {/* 2nd stack */}
        <Stack direction="column" sx={{ backgroundColor: "#f9f9f9", width: "100%", padding: "25px", boxSizing: "border-box", }}  >  {/*  width for main sidebar 78px and for 80px*/}
          {/* <Navbar/> */}





          {/* <Typography sx={{mt:"20px", fontSize:"22px", fontWeight:"500",color:"#2979F2"}}>
    Project Name
    </Typography> */}
          {/* actual content  */}
          <Stack sx={{ backgroundColor: "#f9f9f9" }}>

            <React.Fragment>
              <Drawer anchor="right" open={state} onClose={toggleDrawer(false)}>
                <RightSidebarPD
                  setTabledata={setTabledata}
                  curselectedRows={curselectedRows}
                />
              </Drawer>
            </React.Fragment>


            <PDcustomTable
              newRowData={newRowData}
              delIconClick={delIconClick}
              updateBuyers={updateBuyers}
              setTrigger={setTrigger}
              trigger={trigger}
            />

          </Stack>




        </Stack>
      </Stack>




    </>



  );
}

export default PDmainPage;
