import { Box, Button, Container, Typography } from "@mui/material";
import ChannelLandingBecomePartOfTheEnvisionNextImg from "../../Imgs/channel-landing-become-part-envision-next-img.png";

const ChannelLandingBecomePartOfTheEnvisionNext = () => {
  return (
    <Box
      sx={{
        background: "#0085FF",
      }}
    >
      <Container>
        <Box
          data-aos="fade-zoom-in"
          data-aos-easing="ease-in-back"
          data-aos-delay="50"
          data-aos-offset="0"
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            gap: "20px",
            padding: { xs: "15px", md: "30px 20px" },
            borderRadius: { xs: "10px", md: "20px" },
            flexDirection: { xs: "column-reverse", md: "row" },
          }}
        >
          <Box
            sx={{
              width: { xs: "100%", md: "65%" },
              textAlign: { xs: "center", md: "left" },
            }}
          >
            <Typography
              sx={{
                color: "#FFF",
                fontSize: { xs: "28px", md: "40px" },
                fontWeight: 700,
                fontFamily: "Product Sans",
                lineHeight: "1.2",
              }}
            >
              Become a part of the EnvisionNext ecosystem now to take your
              business to the next level!
            </Typography>
            <Button
              sx={{
                background:
                  "linear-gradient(97.85deg, #FFAF2A 0.95%, #FFC666 100%)",
                fontWeight: 500,
                fontSize: { lg: "28px", xs: "16px" },
                boxShadow: "rgba(242, 178, 73, 0.59) 0px 9px 12px",
                borderRadius: "10px",
                color: "#06386B",
                textTransform: "none",
                padding: "10px 30px",
                fontFamily: "Product Sans",
                mt: "20px",
                "&:hover": {
                  background:
                    "linear-gradient(97.85deg, #FFAF2A 0.95%, #FFC666 100%)",
                  boxShadow: "rgba(242, 178, 73, 0.59) 10px 12px 16px",
                },
              }}
            >
              Sign Up for Free
            </Button>
          </Box>
          <Box
            sx={{
              width: { xs: "80%", md: "35%" },
              mb: { md: "-35px" },
              textAlign: "center",
            }}
          >
            <img
              style={{ width: "120%" }}
              src={ChannelLandingBecomePartOfTheEnvisionNextImg}
              alt=""
            />
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

export default ChannelLandingBecomePartOfTheEnvisionNext;
