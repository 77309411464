import React from "react";
import SideBar from "../Components/sideBar/SideBar";
import TabBar from "../Components/tabBar/TabBar";
import LDmainPage from "./LeadsDirectory/LDmainPage";

const CPClients = () => {
  return (
    <SideBar active={"Clients"} dontShowNav>
      <LDmainPage />
      {/* { //@ts-ignore
      <TabBar
  onIndexChange={(e:number)=>{}}
  floatingActonButton={<div></div>}
        Tabs={[
          {
            Name:"Builder invited project clients",
            children:[<LDmainPage pageIndex={0} />]
          
          },
          {
            Name:"elf clients",
            children:[<LDmainPage  pageIndex={1}/>]
          
          },
          {
            Name:"All Clients",
            children:[<LDmainPage pageIndex={}/>]
          
          },
        ]}
      
      >

      </TabBar>} */}
    </SideBar>
  );
};

export default CPClients;
