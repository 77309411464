import axios from "axios";
import { Apiaddress, basicheaders } from "../../utils/consts";

const fetchProjectsFromOrganization = async (selected_org_id: Array<Number>) => {
    const url = Apiaddress + "/api/projects/orgprojectlist/";
    

    const re = await axios.post(
        url,
        {
            headers: basicheaders,
            orglist:[{"orgid":selected_org_id}]
        }
    )
    // console.log(re.data)
    return re.data;
}
export {fetchProjectsFromOrganization};