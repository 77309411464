import {
    Box,
    Button,
    Checkbox,
    Drawer,
    Typography,
    Accordion,
    AccordionSummary,
  } from "@mui/material";
  import { Stack } from "@mui/system";
  import DeleteIcon from "@mui/icons-material/Delete";
  import React, { useEffect, useState } from "react";
  import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
  
  import axios from "axios";
  import RightSidebarUO from "../../../AllDirectories/UnitDirectory/RightSidebarUO";
  import { Apiaddress } from "../../../../utils/consts";
import { ProjectDashboardSidebar } from "../../../BuilderComponents/ProjectDashboard/DashboardComponents/ProjectDashboardSidebar";
import { NavbarForDirectory } from "../../../BuilderComponents/ProjectDashboard/DashboardComponents/NavbarForDirectory";
import {  useParams } from "react-router-dom";
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';
import { ImageGrid } from "../../../AllDirectories/UnitDirectory/courousal/imageGrid";
import { useNavigate } from "react-router-dom";
import { BuyerSidebar } from "../../components/sideBar/SideBarimpl";
  const columns = [
    { field: "id", headerName: "id", width: 130 },
    { field: "Unit No", headerName: "Unit No", width: 130 },
    { field: "Floor No", headerName: "Floor No", width: 130 },
    { field: "Bedrooms", headerName: "Bedrooms", width: 130 },
    {
      field: "Bathrooms",
      headerName: "Bathrooms",
      // type: "number",
      width: 130,
    },
    {
      field: "Pricing",
      headerName: "Pricing",
      width: 130,
    },
  
    {
      field: "View",
      headerName: "View",
      // headerAlign: "center",
      width: 130,
      renderCell: (params) => {
        return (
          <Stack direction="row">
            <RemoveRedEyeIcon fontSize="small" />
            <DeleteIcon fontSize="small" />
          </Stack>
        );
      },
    },
  ];
  
  const rows = [
    {
      id: 1,
      Name: "#4560",
      State: "26 March 2022, 12:42 AM",
      City: "Seema Agarwal",
      Pincode: "67 St. pune",
      Unit: "Agent",
    },
  ];
  

  export const Overview = ()=>{
    let id= useParams().id;
    console.log(id);

    const [UnitData,setUnitData]=useState([]);
    const [TotalAmount,setTotalAmount]=useState(0);
    const [images,setImages] = useState([]);
    const fetchUnitData= async ()=>{
      try {
        const res = await axios.get(
          Apiaddress + `/api/projects/unit/?unit_id=${id}`
        );

      console.log("===========================.>",res?.data?.unit);
      let tempCost=res?.data?.unit[0]?.charges_data;
      console.log(tempCost);
      if(res.data.unit==" Unit not found"){setUnitData([])}
      else{
        let Tcost=0;
        for(let i=0;i<tempCost.length;i++){
          Tcost=Tcost+tempCost[i].sectioncost;
        }
        setTotalAmount(Tcost);
        setUnitData(res?.data?.unit);
        console.log("UNitt",res?.data?.unit)
        setImages(parseImagesFromUnitData(res?.data?.unit))
        }
    } catch (err) {
      console.log("====================",err);
      setUnitData([]);
    }
    }

    const parseImagesFromUnitData=(p)=>{
      console.log("oYE Parse  ",p)

      const image = p[0]?.layout_data?.image;
      console.log("oYE image",image)

      let imageUrlBucket = []
      if(image!== undefined){
        
        image.forEach((e)=>{
          if(!e.is_file){
            imageUrlBucket.push(e.image)
          }
        });
      }
      return imageUrlBucket;
    }

    useEffect(()=>{
      fetchUnitData();
    },[]);

    const [state, setState] = React.useState(false);

    const toggleDrawer = (open) => (event) => {
      if (
        event.type === "keydown" &&
        (event.key === "Tab" || event.key === "Shift")
      ) {
        return;
      }
  
      setState(open);
    };
  
    const [selectedRows, setSelectedRows] = React.useState([]);
    useEffect(() => {
      console.log(selectedRows);
    }, [selectedRows]);
  
    const [curselectedRows, setcurselectedRows] = useState(null);
    const onRowsSelectionHandler = (ids) => {
      setRowSelected(true);
      const selectedRowsData = ids.map((id) => rows.find((row) => row.id === id));
      console.log(selectedRowsData);
      setcurselectedRows(selectedRowsData);
    };
  
    const [tabledata, setTabledata] = useState(null);
    const [rowData, setrowData] = useState([]);
    const [idCont, setidCunt] = useState(1);
  
    //api
  
    //api
  
    useEffect(() => {
      const rows = [
        {
          id: 8,
          Name: "Frances",
          Phone: "912912912",
          Email: "dummmy@gamil.com",
          Team: "Agent",
          Role: "Agent",
          DetailedView: "View",
        },
      ];
      if (tabledata) {
        let obj = {
          id: idCont,
          Name: tabledata.name,
          Phone: tabledata.phone,
          Email: tabledata.Email,
          Team: tabledata.Team,
          Role: tabledata.Role,
        };
        setidCunt(idCont + 1);
        console.log(obj);
        setrowData([...rowData, obj]);
      }
  
      console.log(tabledata);
    }, [tabledata]);
  
    const [rowSelected, setRowSelected] = useState(false);
  
    const [data, setData] = useState(null);
    const [token, setToken] = useState(null);
  
    const getProjects = async () => {
      try {
        const headers = {
          Authorization: `JWT ${token}`,
        };
        const res = await axios.get(
          Apiaddress + "/api/projects/getprojectsusingtoken/",
          { headers }
        );
        // console.log(res.data.projects);
        if (res.data) if (res.data.projects) setData(res.data.projects);
      } catch (err) {
        console.log(err);
      }
    };
    useEffect(() => {
      if (token) getProjects();
    }, [token]);
  
    useEffect(() => {
      setToken(localStorage.getItem("crm_token"));
    }, []);
    const [newRowData, setNewRowData] = useState([]);
  
    const fetchUnit = async () => {
      try {
        let arr = [];
        for (let i = 0; i < data.length; i++) {
          // let obj = {
          //   id: i + 1,
          //   project_id: data[i].id,
          //   Name: data[i].name,
          //   State: data[i].state,
          //   City: data[i].city,
          //   Pincode: data[i].pin_code,
          //   Unit: "unit",
          // };
          // arr.push(obj);
          const res = await axios.get(
            Apiaddress + `/api/projects/searchunits/?query=${data[i].id}`
          );
          // console.log(res);
          if (res) if (res.data) if (res.data.units) arr.push(res.data.units);
        }
        setNewRowData(arr);
      } catch (err) {
        console.log(err);
      }
    };
  
    useEffect(() => {
      if (data) {
        // console.log(data);
  
        fetchUnit();
      }
    }, [data]);
    const [newUnits, setNewUnits] = useState(null);
    useEffect(() => {
      if (newRowData) {
        let arr = [];
        for (let i = 0; i < newRowData.length; i++) {
          if (newRowData[i] != "No units found") {
            // console.log(newRowData[i]);
            for (let j = 0; j < newRowData[i].length; j++) {
              // console.log(newRowData[i][j]);
              arr.push(newRowData[i][j]);
            }
          }
        }
        setNewUnits(arr);
      }
    }, [newRowData]);
    return (
        <>
        <Stack direction="row" sx={{width:"100%",height:"auto" }}>
      {/* 1st stack */}
      <Stack sx={{minHeight:"100vh",width:"22%",boxSizing:"border-box",}}>
      <BuyerSidebar active={"Dashboard"}/>
      </Stack>

     {/* 2nd stack */}
     <Stack direction="column" sx={{ backgroundColor:"#f9f9f9",width:"78%", padding:"25px",boxSizing:"border-box",}}  >
      {/* <Navbar/> */}
 
    {/* <Typography sx={{mt:"20px", ontSize:"22px", fontWeight:"500",color:"#2979F2"}}>
    Project Name
    </Typography> */}
     {/* actual content  */}
    <Stack sx={{ mt:"28px",backgroundColor:"#f9f9f9"}}>

    <React.Fragment>
          <Drawer anchor="right" open={state} onClose={toggleDrawer(false)}>
            <RightSidebarUO
              setTabledata={setTabledata}
              curselectedRows={curselectedRows}
            />
          </Drawer>
        </React.Fragment>

   
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            sx={{ height: "68px", marginBottom: "20px" }}
          >
            <Stack direction="column" alignItems="center">
       
              {" "}
              <Stack direction="row" sx={{ margin: "20px" }}>
                <Button
                  className="univercel_btn_class"
                  sx={{
                    color: "#FFFFFF",
                    textTransform: "none",
                    width: "200px",
                    background:
                      "linear-gradient(90deg, #0F5DAE 0%, #009DCF 100.33%)",
                    margin: "0 10px",
                  }}
                >
                  View Quotation
                </Button>
                
              </Stack>
            </Stack>
          </Stack>

          {/* ================filters and all=============================== */}
          <Box sx={{ color: "black" }}>
            <Box sx={{ display: "flex" }}>
                {UnitData && <ImageGrid image={images}/>}
              <Box sx={{ width: "100%" }}>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    width: "100%",
                    padding: "20px",
                  }}
                >
                  <Box>
                    <Typography sx={{fontSize: "20px",fontWeight:"600"}}>
                      Unit Overview{" "}
                    </Typography>
                    <Typography>{UnitData[0]?.name}</Typography>
                  </Box>
                  {UnitData[0]?.is_booked==true?<Box>Status : Booked</Box>:<Box>Status : Available</Box>}
                </Box>

               {/*==============================start Showing Unit Data========================== */}
                <Box sx={{display: "grid",gridTemplateColumns: "1fr 1fr 1fr",rowGap: "30px",padding: "20px"}}>
                {UnitData[0]?.layout_data?.singleData.map((item,idx)=>{
                  return(
                  
                    <Box>
                      <Box>{`${item.value}    ${item.type}`}</Box>
                      <Box sx={{color:"#A2A3A6",fontFamily: 'Poppins',fontStyle:"normal",fontWeight: "500",fontSize:"16px",lineHeight: "18px",marginTop:"10px"}}>{item.label}</Box>
                    </Box>
                  )
                })}
                </Box>
                
                <Box sx={{display: "grid",gridTemplateColumns: "1fr 1fr 1fr",rowGap: "30px",padding: "20px"}}>
                {UnitData[0]?.layout_data?.doubleData.map((item,idx)=>{
                  return(
                   
                    <Box>
                      <Stack direction="row">
                         <Box>{`${item.value1}    ${item.type}`}</Box> 
                         <Box sx={{margin:"0px 10px 0px 10px"}}>  X  </Box>
                         <Box>{`${item.value1}    ${item.type}`}</Box>
                      </Stack>
                      <Box sx={{color:"#A2A3A6",fontFamily: 'Poppins',fontStyle:"normal",fontWeight: "500",fontSize:"16px",lineHeight: "18px",marginTop:"10px"}}>{item.label}</Box>
                    </Box>
                 )
                })}
                </Box>

                <Box sx={{display: "grid",gridTemplateColumns: "1fr 1fr 1fr",rowGap: "30px",padding: "20px"}}>
                {UnitData[0]?.layout_data?.bedroomData.map((item,idx)=>{
                  return(
                   
                    <Box>
                      <Stack direction="row">
                         <Box>{`${item.length}  (L)`}</Box>
                         <Box sx={{margin:"0px 10px 0px 10px"}}>  X  </Box>
                         <Box>{`${item.breadth}  (B)`}</Box>
                      </Stack>
                      <Box sx={{color:"#A2A3A6",fontFamily: 'Poppins',fontStyle:"normal",fontWeight: "500",fontSize:"16px",lineHeight: "18px",marginTop:"10px"}}>{`Bedroom No. ${item.bedroom_no}`}</Box>
                    </Box>
                 )
                })}
                </Box>

                <Box sx={{display: "grid",gridTemplateColumns: "1fr 1fr 1fr",rowGap: "30px",padding: "20px"}}>
                {UnitData[0]?.layout_data?.bathroomData.map((item,idx)=>{
                  return(
                   
                    <Box>
                      <Stack direction="row">
                         <Box>{`${item.length}  (L)`}</Box>
                         <Box sx={{margin:"0px 10px 0px 10px"}}>  X  </Box>
                         <Box>{`${item.breadth}  (B)`}</Box>
                      </Stack>
                      <Box sx={{color:"#A2A3A6",fontFamily: 'Poppins',fontStyle:"normal",fontWeight: "500",fontSize:"16px",lineHeight: "18px",marginTop:"10px"}}>{`Bathroom No. ${item.bathroom_no}`}</Box>
                    </Box>
                 )
                })}
                </Box>

                <Box sx={{display: "grid",gridTemplateColumns: "1fr 1fr 1fr",rowGap: "30px",padding: "20px"}}>
                {UnitData[0]?.layout_data?.balconyData.map((item,idx)=>{
                  return(
                   
                    <Box>
                      <Stack direction="row">
                         <Box>{`${item.length}  (L)`}</Box>
                         <Box sx={{margin:"0px 10px 0px 10px"}}>  X  </Box>
                         <Box>{`${item.breadth}  (B)`}</Box>
                      </Stack>
                      <Box sx={{color:"#A2A3A6",fontFamily: 'Poppins',fontStyle:"normal",fontWeight: "500",fontSize:"16px",lineHeight: "18px",marginTop:"10px"}}>{`Balcony No. ${item.balcony_no}`}</Box>
                    </Box>
                 )
                })}
                </Box>

              </Box>
            </Box>
            <Box
              sx={{
                margin: "20px",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Box sx={{ width: "100%" }}>
                <Box sx={{ margin: "10px",marginBottom:"30px",fontSize:"22px"}}>Pricing Details</Box>

                
                {Array.isArray(UnitData[0]?.charges_data)==true ? UnitData[0]?.charges_data?.map((item,idx)=>{
                  return(
                    <>
                    <Box sx={{color:"black",fontFamily: 'Poppins',fontStyle:"normal",fontWeight: "600",fontSize:"20px",lineHeight: "18px",margin:"10px"}}>{item?.titlename}</Box>
                    <Box sx={{display: "grid",gridTemplateColumns: "1fr 1fr 1fr",rowGap: "30px",padding: "20px"}}>
                      {item?.field?.map((ele,idx)=>{
                        return(
                   
                          <Box>
                              <Stack direction="row">
                                <CurrencyRupeeIcon sx={{color:"#A2A3A6",fontSize:"18px",marginTop:"2px"}}/>
                                <Box>{`${ele.value}`}</Box>
                              </Stack>
                              <Box sx={{color:"#A2A3A6",fontFamily: 'Poppins',fontStyle:"normal",fontWeight: "500",fontSize:"16px",lineHeight: "18px",marginTop:"10px"}}>{`${ele.key}`}</Box>
                          </Box>
                        )
                     })}
                  </Box>
                  <Box sx={{margin:"20px 0px 30px 10px"}}>
                    <Stack direction="row" sx={{}}>
                      <CurrencyRupeeIcon sx={{color:"#A2A3A6",fontSize:"18px",marginTop:"2px"}}/>
                      <Box>{`${item.sectioncost}`}</Box>
                    </Stack>           
                    <Box sx={{color:"#FF6140",fontFamily: 'Poppins',fontStyle:"normal",fontWeight: "600",fontSize:"18px",lineHeight: "20px",marginTop:"10px"}}>{`Total ${item.titlename} Amount`}</Box>
                  </Box>
                    </>
                  )
                }) : null}
                
              </Box>
              <Box>
                <Box
                  sx={{
                    background: "gray",
                    height: "450px",
                    width: "250px",
                    margin: "10px",
                    margin: "20px",
                  }}
                ></Box>
              </Box>
            </Box>

            <Box>
              <Button
                sx={{
                  color: "#FFFFFF",
                  textTransform: "none",
                  width: "500px",
                  background:
                    "linear-gradient(90deg, #0F5DAE 0%, #009DCF 100.33%)",
                  margin: "0 10px",
                }}
              >{`Grand Total Amount : ${TotalAmount}`}
              </Button>
            </Box>
          </Box>
        

    </Stack>




      </Stack>
</Stack>
        
        </>
    )
  }