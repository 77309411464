import { Box, Button, Container, Typography } from "@mui/material";

import TeamSupport from "../../Imgs/team-support.png";

const BuilderLandingOurTeamWillSupport = () => {
  return (
    <Box
      sx={{
        background: "#0890FE",
        overflowX: "hidden",
      }}
    >
      <Container
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: { xs: "center", md: "flex-start" },
          py: { xs: "30px", md: "20px" },
          overflow: "hidden",
          flexDirection: { xs: "column-reverse", md: "row" },
          gap: "20px",
        }}
      >
        <Box
          sx={{
            width: { xs: "100%", md: "60%" },
            textAlign: { xs: "center", md: "left" },
          }}
        >
          <Typography
            data-aos="fade-down"
            sx={{
              fontSize: { xs: "24px", md: "30px", lg: "48px" },
              fontWeight: 700,
              fontFamily: "Circular Std",
              color: "#FFF",
              lineHeight: "1.2",
              display: { xs: "none", md: "block" },
            }}
          >
            Our team will support you every step of the way
          </Typography>
          <Typography
            data-aos="fade-down"
            sx={{
              fontSize: { xs: "28px", md: "32px", md2: "36px", lg: "48px" },
              fontWeight: 700,
              fontFamily: "Circular Std",
              color: "#FFF",
              lineHeight: "1.2",
              display: { xs: "block", md: "none" },
            }}
          >
            Our team will support <br /> you every step of the way
          </Typography>
          <Typography
            data-aos="fade-up"
            sx={{
              color: "#FFF",
              fontSize: "18px",
              fontFamily: "Product Sans",
            }}
          >
            If you run into any issues, your dedicated Relationship Manager will
            be available on-call to help you set up and troubleshoot your
            problems
          </Typography>
          <Box sx={{ mt: { xs: "20px", md: "40px" } }}>
            <Typography
              data-aos="fade-up"
              sx={{
                fontWeight: 700,
                color: "#FFC503",
                fontSize: "16px",
                fontFamily: "Product Sans",
              }}
            >
              Get started simply by booking a demo
            </Typography>
            <Button
              data-aos="fade-zoom-in"
              data-aos-easing="ease-in-back"
              data-aos-delay="300"
              data-aos-offset="0"
              sx={{
                background: "#FFC503",
                fontWeight: 600,
                fontSize: { xs: "12px", md: "20px", lg: "24px" },
                boxShadow: "rgba(242, 178, 73, 0.59) 0px 9px 12px",
                borderRadius: "10px",
                color: "#06386B",
                fontFamily: "Product Sans",
                textTransform: "none",
                padding: "5px 30px",
                mt: "10px",
                "&:hover": {
                  background: "#FFC503",
                  boxShadow: "rgba(242, 178, 73, 0.59) 10px 12px 16px",
                },
              }}
            >
              Book A Demo
            </Button>
          </Box>
        </Box>
        <Box
          data-aos="fade-zoom-in"
          data-aos-delay="300"
          data-aos-offset="150"
          sx={{
            width: { xs: "100%", md: "50%" },
            mb: { md: "-20px" },
            display: "flex",
            justifyContent: { xs: "center", md: "flex-start" },
          }}
        >
          <img style={{ width: "100%" }} src={TeamSupport} alt="" />
        </Box>
      </Container>
    </Box>
  );
};

export default BuilderLandingOurTeamWillSupport;
