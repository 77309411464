import axios from "axios"
import { Apiaddress, basicheaders } from "../../utils/consts"

const createFloorTemplate =async(floorName:String, project_id:number,templates:{unit_no: number, unit_name: String}[])=>{
    const url = Apiaddress+"/api/projects/floortemplate/";
    console.log(templates)
    const re = await axios.post(
        url,
        {
            headers:basicheaders,
            name:floorName,
            project:project_id,
            unittemplates:templates
        }
    )
    console.log("re.data:  ", re.data)

}
export {createFloorTemplate}