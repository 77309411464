import { Box, Button, Typography } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import PdfTwoPageFour from "./PdfTwoPageFour";
import PdfTwoPageOne from "./PdfTwoPageOne";
import PdfTwoPageOneUnit from "./PdfTwoPageOneUnit"; 
import PdfTwoPageThree from "./PdfTwoPageThree";
import PdfTwoPageTwo from "./PdfTwoPageTwo";
import ReactToPrint from "react-to-print";
import { useLocation } from "react-router-dom";
import axios from "axios";
import { Apiaddress } from "../../../utils/consts";

const PdfTwoMain = () => {

  //get data from previous page 
  const location = useLocation();
  const data = location.state;
  console.log("preve data =>.>",data);

  let id=data?.project_id;
  let quotation_id=data?.quotation_id;

  const [projectInfo,setProjectInfo]= useState({});
  const [quotationInfo,setQuotationInfo]= useState({});
  const [unitInfo,setUnitInfo]=useState({});
  const [leadInfo,setLeadInfo]=useState({});
  
  //function for fetching Project Information
  const fetchProjectInfo= async ()=>{
     try {
        const res = await axios.get(
          Apiaddress + `/api/projects/project/?project_id=${id}`
        );
      console.log("===========================.>",res?.data?.projects[0]);
      let projectInfo=res?.data?.projects[0];
      let obj={
        address:projectInfo?.address_line,
        city:projectInfo?.city,
        state:projectInfo?.state,
        pincode:projectInfo?.pin_code,
        project_name:projectInfo?.name,
        rera_no:projectInfo?.rera_number,
        builder_name:projectInfo?.builder?.name
      }
      setProjectInfo(obj);
    } catch (err) {
      console.log("====================",err);
    }
  }

   //function for fetching Quotation Information
  const fetchQuotationInfo= async ()=>{
     try {
        const res = await axios.get(
          Apiaddress + `/api/leads/quotation/?quotation_id=${quotation_id}`
        );
      console.log("===========================.>",res?.data?.quotations[0]);
      let quotationInfo=res?.data?.quotations[0];
      let unitId=quotationInfo?.unit;
      let leadId=quotationInfo?.lead
      let obj={
        created:quotationInfo?.created_on,
        valid:quotationInfo?.valid_till,
        unit_no:quotationInfo?.unit,
        lead_no:quotationInfo?.lead,
        adjustment:quotationInfo?.adjustment
      }
      setQuotationInfo(obj);

       const res1 = await axios.get(
          Apiaddress + `/api/projects/unit/?unit_id=${unitId}`
        );
      console.log("===========================.>",res1?.data?.unit[0]);
      let unitInfo=res1?.data?.unit[0];
      let unitImage="";
      let floorImage="";
      for(let i=0;i<unitInfo?.layout_data?.image?.length;i++){
      if(unitInfo?.layout_data?.image[i].istrue=="true"){
        unitImage=unitInfo?.layout_data?.image[i]?.image;
        break;
      }
    }
    for(let i=0;i<unitInfo?.layout_data?.floorplan?.length;i++){
      if(unitInfo?.layout_data?.floorplan[i].istrue=="true"){
        floorImage=unitInfo?.layout_data?.floorplan[i]?.image
        break;
      }
    }
      let obj1={
        single_data:unitInfo?.layout_data?.singleData,
        double_data:unitInfo?.layout_data?.doubleData,
        bedroom:unitInfo?.layout_data?.bedroomData,
        bathroom:unitInfo?.layout_data?.bathroomData,
        balcony:unitInfo?.layout_data?.balconyData,
        unit_no:unitInfo?.id,
        charge:unitInfo?.charges_data,
        image:unitImage,
        floor:floorImage,
      }
      setUnitInfo(obj1);

      const res2 = await axios.get(
          Apiaddress + `/api/leads/lead/?lead_id=${leadId}`
        );
      console.log("===========================.>",res2?.data?.lead[0]);
      let leadInfo=res2?.data?.lead[0];
      let obj2={
        lead_name:leadInfo?.name,
      }
      setLeadInfo(obj2);
      
    } catch (err) {
      console.log("====================",err);
    }
  }

  useEffect(()=>{
      fetchProjectInfo();
      fetchQuotationInfo();
  },[])


  const ref = useRef();

  const pages = [<PdfTwoPageOne 
    projectInfo={projectInfo}
    quotationInfo={quotationInfo}
    unitInfo={unitInfo}
    leadInfo={leadInfo}
    />,
    <PdfTwoPageOneUnit 
    projectInfo={projectInfo}
    quotationInfo={quotationInfo}
    unitInfo={unitInfo}
    leadInfo={leadInfo}
    />,
    <PdfTwoPageTwo 
    projectInfo={projectInfo}
    quotationInfo={quotationInfo}
    unitInfo={unitInfo}
    leadInfo={leadInfo} 
    />,
    <PdfTwoPageThree
    projectInfo={projectInfo}
    quotationInfo={quotationInfo}
    unitInfo={unitInfo}
    leadInfo={leadInfo} 
    />, <PdfTwoPageFour 
    projectInfo={projectInfo}
    quotationInfo={quotationInfo}
    unitInfo={unitInfo}
    leadInfo={leadInfo} 
    />];
  return (
    <Box>
      <Box ref={ref}>
        {pages.map((page, index) => (
          <Box
            sx={{
              pageBreakAfter: "always",
              padding: "50px",
              position: "relative",
              minHeight: "85vh",
            }}
          >
            <Box>{page}</Box>
            <Typography
              sx={{
                position: "absolute",
                bottom: 0,
                left: "48%",
                fontWeight: 600,
                color: "#696969",
              }}
            >{`${index + 1}${"/"}${pages.length}`}</Typography>
          </Box>
        ))}
      </Box>

      <ReactToPrint
        trigger={() => <Box sx={{textAlign:"right",margin:"30px 50px"}}><Button variant="contained" sx={{background:"#0F5DAE",border:"1px solid #EFF0F7",boxShadow:"0px 2px 6px rgba(19, 18, 66, 0.07)",borderRadius:"5px",border:'1px solid #0F5DAE',marginLeft:"25px"}}>
              <Box sx={{ textTransform: "capitalize",margin:"0px 10px"}}>Download / Print</Box>
            </Button></Box>}
        content={() => ref.current}
      />
    </Box>
  );
}

export default PdfTwoMain;
