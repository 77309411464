import React from 'react'
import SideBar from '../components/sideBar/SideBar'
import ProjectSlider from './components/ProjectSlider'

const Projects = () => {
  return (
    <SideBar active={"Project"}>
        <ProjectSlider setUpdate={()=>{}} update={false}></ProjectSlider>
    </SideBar>
  )
}

export default Projects