import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  MenuItem,
  Drawer,
  Box,
  Button,
  Checkbox,
  FormControl,
  InputLabel,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { Stack } from "@mui/system";
import TuneOutlinedIcon from "@mui/icons-material/TuneOutlined";
import KeyboardArrowRightOutlinedIcon from "@mui/icons-material/KeyboardArrowRightOutlined";
import KeyboardArrowUpOutlinedIcon from "@mui/icons-material/KeyboardArrowUpOutlined";
import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined";
import { CheckBox } from "@mui/icons-material";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import DriveFileRenameOutlineIcon from "@mui/icons-material/DriveFileRenameOutline";
import VisibilityIcon from "@mui/icons-material/Visibility";
import DeleteIcon from "@mui/icons-material/Delete";
//   import "../MembersDirectory/MDmainPage.css";
import React, { useContext, useEffect, useState } from "react";
import { DataGrid, GridExpandMoreIcon } from "@mui/x-data-grid";
import CategoryIcon from "@mui/icons-material/Category";
import HandshakeIcon from "@mui/icons-material/Handshake";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import FilterAltOffIcon from '@mui/icons-material/FilterAltOff';
import RestartAltIcon from '@mui/icons-material/RestartAlt';

import axios from "axios";
import UDcustomTable from "./UDcustomTable";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import CircularProgress from "@mui/material/CircularProgress";
import { MarkOccupancy } from "./MarkOccupancy";
import builderDashboard from "../../../../context/builder_dashboard/context";
import { api, Apiaddress, contains, net } from "../../../../utils/consts";
import { AuthService } from "../../../../context/buyer/services/auth";
import { useNavigate } from "react-router-dom";
import StandAloneCustomTable from "./StandAloneCustomTable";

export const StandAloneUnitMainPage = ({
  projectid,
  builderInvited,
  setTrigger,
  trigger,
}) => {
  let builderSelected = JSON.parse(localStorage.getItem("BuilderSelected"));
  const OrganizationsContext = useContext(builderDashboard);
  const cpDetails=JSON.parse(localStorage.getItem('cpDetails'));
  let cpOrg=cpDetails?.channelPartnerOrg;

  const [newRowData, setNewRowData] = useState([]);
  const [state, setState] = React.useState(false);
  const [selectedUnits, setSelectedUnits] = useState([]);
  const [token, setToken] = useState(null);
  const [newUnits, setNewUnits] = useState([]);
  const [startUnit,setStartUnit] = useState(0);
  const [totalUnit,setTotalUnit] = useState();
  const [loading, setloading] = useState(false);
  const [curpage, setcurpage] = useState(1);
  const [matchText, setmatchText] = useState(
    "Let's get started, Please add your data"
  );

  const getInfoOfParticularunit=async ()=>{
    setloading(true);
    let arr = [];
    let st=startUnit;
    let ed=startUnit+7<newRowData?.length?startUnit+7:newRowData?.length;
    for (let i = st; i < ed; i++) {
      const res = await axios.get(Apiaddress + "/api/projects/cpunit/?unit_id="+newRowData[i].id);
      let temp=res?.data?.unit[0];
      console.log("===========================.>", res?.data?.unit[0]);
      let unit_data = JSON.parse(temp?.unit_data);
        let status = "";
        if (temp?.unit_status == 1) {
          status = "Unavailable";
        } else if (temp?.unit_status == 2) {
          status = "Reserved";
        } else if (temp?.unit_status == 3) {
          status = "Registered";
        } else if (temp?.unit_status == 4) {
          status = "Booked";
        } else if (temp?.unit_status == 5) {
          status = "Available";
        }
        let obj = {
          id: temp?.id,
          name: temp?.name,
          status: status,
          for: unit_data?.For,
          type: unit_data?.Type,
          city: unit_data?.City,
          unit_data: unit_data,
        };
        arr.push(obj);
      }
      setNewUnits(arr);  
      setloading(false);
  }

  const fetchUnit = async () => {
    setloading(true);
    try {
      const auth = new AuthService();
      if (projectid == undefined) {
          let url="";
          console.log(cpOrg);
          if(cpOrg){
            url=`role/inventeryofcp/?orgId=${cpOrg}`
          }
          else{
            url=`role/inventeryofcp/`
          }
          const headers = {
            Authorization: `JWT ${token}`,
          };
          const res = await (await auth.makeAuthenticatedGetRequest(net(url))).json()
          setNewRowData(res.self["filtured Data"].units);
          setTotalUnit(res.self["filtured Data"].units?.length);
        } else {
        const res = await axios.get(
          api("/api/role/channelpartner/?cp_id=" + auth.getCPID())
        );

        const res2 = await axios.get(
          api("/api/projects/unitsofproject/?project_id=" + projectid)
        );

        const cpUnits = res?.data?.cp[0]?.units;
        const projectUnits = res2.data.units;
        console.log(cpUnits, "=======", projectUnits);
        let rd = [];
        for (let i = 0; i < cpUnits.length; i++) {
          for (let j = 0; j < projectUnits.length; j++) {
            if (cpUnits[i] == projectUnits[j].id) {
              const r = await (
                await auth.makeUnAuthenticatedGetRequest(
                  api(`/api/projects/unit/?unit_id=${cpUnits[i]}`)
                )
              ).json();
              rd.push(r.unit[0]);
            }
          }
        }
        const Auth = new AuthService();
        const res3 = await await (
          await Auth.makeUnAuthenticatedGetRequest(
            net("projects/cpunit/?cp_id=" + Auth.getCPID())
          )
        ).json();
        console.log("res3", res3);

        setNewRowData(rd);
      }
    } catch (err) {
      console.log("====================", err);
      setNewRowData([]);
    } finally {
      setloading(false);
    }
  };

  useEffect(() => {
    fetchUnit();
  }, [trigger]);

  

  useEffect(() => {
    if (newRowData) {
      getInfoOfParticularunit();
    }
  }, [newRowData,startUnit]);

  //fitering data starts:====
  const [filterselect, setfilterselect] = useState();
  const [filtervalue, setfiltervalue] = useState();
  const [expanded, setExpanded] = useState(false);

  const handleChange = (event) => {
    setfilterselect(event.target.value);
    setfiltervalue("");
  };

  console.log(filterselect);
  console.log(filtervalue);

  const fetchfilter = async () => {
    try {
      const auth = new AuthService()
      let url="";
      console.log(cpOrg);
      if(cpOrg){
        url=`role/inventeryofcp/?orgId=${cpOrg}&filtervalue=${filtervalue}&filterkey=${filterselect}&by=unit`
      }
      else{
        url=`role/inventeryofcp/?by=unit&filterkey=${filterselect}&filtervalue=${filtervalue}`
      }
      const headers = {
        Authorization: `JWT ${token}`,
      };
      const res = await (await auth.makeAuthenticatedGetRequest(net(url))).json()
      setNewRowData(res.self["filtured Data"].units);
      setTotalUnit(res.self["filtured Data"].units?.length);
    } catch (err) {
      console.log(err);
    }
  };

  const nav = useNavigate();

  console.log(selectedUnits);

  return (
    <>
      <Stack direction="row" sx={{ width: "100%", height: "auto" }}>
        {/* 1st stack */}

        {/* 2nd stack */}
        <Stack
          direction="column"
          sx={{
            backgroundColor: "#f9f9f9",
            width: "100%",
            boxSizing: "border-box",
            paddingTop: "20px",
          }}
        >
          {/* <Navbar/> */}

          {/* actual content  */}

          <Drawer
            anchor="right"
            open={state}
            onClose={() => {
              // toggleDrawer(false)
              setState(false);
              //   setMakePriority(false);
              //   setMakeProject(false);
            }}
          >
            <MarkOccupancy
              setState={setState}
              selectedUnits={selectedUnits}
              setSelectedUnits={setSelectedUnits}
              setTrigger={setTrigger}
              trigger={trigger}
            />
          </Drawer>

          <Stack sx={{ mt: "0px", backgroundColor: "#f9f9f9",zIndex:"3"}}>
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              sx={{ marginBottom: "0px"}}
              >
              <Stack
              direction="row"
              justifyContent=""
              alignItems="center"
              sx={{ marginBottom: "0px"}}
              >
              {!builderInvited && (
                <Button
                  onClick={() => {
                    nav(`/channelpartner/unit/add/${"unit"}`, {
                      state: { project: true },
                    });
                  }}
                  className="univercel_btn_class"
                  sx={{
                    color: "#FFFFFF",
                    textTransform: "none",
                    width: "120px",
                    padding: "8px 0px",
                    marginRight: "20px",
                  }}
                >
                  Add unit
                </Button>
              )}

              {selectedUnits.length != 0 && (
                <Stack direction="row">
                  <Button
                    onClick={() => {
                      // toggleDrawer(true);
                      setState(true);
                      // setMakePriority(true);
                    }}
                    startIcon={<CategoryIcon />}
                    className="univercel_btn_class"
                    sx={{
                      color: "#FFFFFF",
                      textTransform: "none",
                      width: "170px",
                    }}
                  >
                    Mark Occupancy
                  </Button>
                </Stack>
              )}
              </Stack>

              <Stack
                direction="row"
                justifyContent="right"
                alignItems="center"
                sx={{
                  width: "203px",
                  height: "0px",
                  borderRadius: "12px",
                  color: "#3E4954"
                }}
              >
               <Accordion 
              expanded={expanded}
              sx={{ width: "150px", boxShadow: "none",zIndex:"3"}}>  
                  <AccordionSummary onClick={()=>{setExpanded(!expanded)}}
                    expandIcon={<GridExpandMoreIcon/>}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography sx={{ display: "flex", placeItems: "center" }}>
                      <TuneOutlinedIcon sx={{ color: "#2F4CDD" }} />
                      <Typography sx={{ marginLeft: "10px" }}>
                        Fillters
                      </Typography>
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Box sx={{textAlign:"center"}}>
                      <FormControl sx={{ width: "118px" }}>
                        <InputLabel id="demo-simple-select-label">
                          Select
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={filterselect}
                          label="Select"
                          onChange={handleChange}
                        >
                          <MenuItem value={"name"}>Name</MenuItem>
                          <MenuItem value={"state"}>State</MenuItem>
                          <MenuItem value={"city"}>City</MenuItem>
                          <MenuItem value={"pin_code"}>Pincode</MenuItem>
                        </Select>
                      </FormControl>
                      <TextField
                        sx={{ marginTop: "20px",fontSize:"8px"}}
                        placeholder="Value" 
                        value={filtervalue} onChange={(e)=>{setfiltervalue(e.target.value)}}
                      />
                      <Button 
                        // onClick={fetchfilter}
                        onClick={()=>{fetchfilter();setExpanded(false);setmatchText(
                          "No results found with the applied Filters"
                        )}}
                        variant="contained"
                        className="univercel_btn_class"
                        color="primary" 
                        sx={{margin:"20px 0px 10px 0px"}}
                        >
                         Submit
                      </Button>

                    </Box>
                  </AccordionDetails>
                </Accordion>
                {expanded ==false && filtervalue && <RestartAltIcon 
                sx={{margin:"0px 10px",cursor:"pointer"}}
                onClick={()=>{
                  setfiltervalue("");
                  setStartUnit(0);
                  setmatchText("Let's get started, Please add your data");
                  setcurpage(1);
                  setTrigger(!trigger);
                }}/>}
                
              </Stack>
            </Stack>

            {/* ================unit availability btn =============================== */}

            <StandAloneCustomTable
              selectedUnits={selectedUnits}
              setSelectedUnits={setSelectedUnits}
              newRowData={newUnits}
              loading={loading}
              CircularProgress={CircularProgress}
              isStand={true}
              setStartUnit={setStartUnit}
              startUnit={startUnit}
              totalUnit={totalUnit}
              // fetchfilter={fetchfilter}
              matchText={matchText}
              curpage={curpage}
              setcurpage={setcurpage}
            />
          </Stack>
        </Stack>
      </Stack>
    </>
  );
};
