import axios from "axios";
import { useEffect, useState } from "react";
import { getProfile } from "../../connection/user/get_profile";
import { Apiaddress } from "../../utils/consts";
import userContext from "./context";

export default function ProfileState(props) {
    const [UserState, setUserState] = useState()

    const fetchNew = async () => setUserState(await getProfile());

    const logOut = () => {
        setUserState(null)
        localStorage.clear()

    }
    ///user role type ;
    const getUserRoleType = async (res) => {
        if (localStorage.getItem("user_roles") == null) {
            const x =  fetchNew()
            let role_obj = {};
            const url = Apiaddress + `/api/role/listuserpermission/`;
            await axios.get(url, {
                headers: {
                    Authorization: "JWT " + localStorage.getItem("crm_token"),
                }
            }).then((res) => {
                console.log("user role type: ", res.data);
                let data = res.data.permissions;

                for (let ele of data) {
                    if (role_obj[ele.content_type]) {
                        role_obj[ele.content_type] = [...role_obj[ele.content_type], ele]
                    } else {
                        role_obj[ele.content_type] = [ele]
                    }
                }

            }).catch((err) => {
                console.log("res: ", err);
            })
            localStorage.setItem("user_roles", JSON.stringify(role_obj));
            await x
            //  console.log("role data", role_obj);
            //  console.log("role data", role_obj["api settings"])  //to get data from obj use this method ;
        }
    }
    useEffect(() => {
        const x = async () => {
            await fetchNew()
            // get userdata from api and store to the local storage for set roles and routes 
            await getUserRoleType()
            const userdata = await getProfile();
            localStorage.setItem("crm_user", JSON.stringify(userdata.user_obj));
        }
        x()
    }, [])

    return (
        <userContext.Provider value={{ UserState, fetchNew, logOut }}>
            {props.children}
        </userContext.Provider>
    );
}
