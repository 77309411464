import React, { useContext, useEffect } from 'react'
import { TextField } from "@mui/material";
import './UnitTemplate.css';
import { useState } from 'react';

import { Box, Button, Typography } from "@mui/material"
import { Stack } from "@mui/system"
import { Navbar } from "../DashboardComponents/Navbar"
import { Sidebar } from "../DashboardComponents/Sidebar"
import { useNavigate } from "react-router-dom";
import currentProjectState from '../../../../context/createproject/context'
import { fetchUnitLayout } from '../../../../connection/project/fetch_unit_layout';
import { CreateUnitTemplate } from '../../../../connection/project/create_unit_template';
import { MainSidebar } from '../DashboardComponents/MainSidebar';

let num1=5;
let num2=3;  
let num3=3;

function UnitTemplate() {
    const navigate =useNavigate();
    const cpContext = useContext(currentProjectState);



    useEffect(() => {
      const  x = async()=>{
        console.log("ffff",cpContext.createProjectState.project.unit_layout)
        const data =  await fetchUnitLayout(
          cpContext.createProjectState.project.unit_layout
          );            
          const unitData = data.unit_layout[0].layout_data;
          
          console.log("fffd",data)
          let newSingle = [] ;
            let newDouble = [];
            unitData.single.forEach(ele => {
                newSingle.push({...ele,"value":"",})
            });
            
           unitData.double.forEach( ele =>{
                newDouble.push({...ele, value1:'',value2:''})
            })
            setsingle(newSingle);
            setdouble(newDouble);
        } 
        x();
    }, []);
    

    const [template_name,setTemplateName] = useState("")

    const [single,setsingle]=useState([]);
    const [double,setdouble]=useState([]);
    const [bedroomdata,setbedroomdata]=useState([]);
    const [bathroomdata,setbathroomdata]=useState([]);
    const [balconydata,setbalconydata]=useState([]);

  const [bed,setbed]=useState([1,2,3,4,5]);
  const [bath,setbath]=useState([1,2,3]);
  const [bal,setbal]=useState([1,2,3]);
  const [bedcolor,setbedcolor]=useState();
  const [bathcolor,setbathcolor]=useState();
  const [balcolor,setbalcolor]=useState();

  const [errorSubmit,setErrorSubmit]=useState(false);

const handelSubmmit=async()=>{
    setErrorSubmit(true);
    console.log("unit tempplate================>",bedroomdata,bathroomdata,balconydata);
    const projectID = cpContext.createProjectState.project.id;
    await CreateUnitTemplate(single,double,template_name,projectID,bedroomdata,bathroomdata,balconydata);
    num1=5;
    num2=3;
    num3=3;
    setErrorSubmit(false);
    navigate("/createproject/projectpricing/projectcreateoption/mainscreen");
}

// console.log('====================================');
// console.log(cpContext);
// console.log(single);
// console.log(double);
// console.log(bedroomdata);
// console.log(bathroomdata);
// console.log(balconydata);
// console.log(template_name);
// console.log('====================================');

  return (
    <>


<Stack direction="row" sx={{width:"100%",height:"auto" }}>
      {/* 1st stack */}
      <Stack sx={{minHeight:"100vh",width:"20%",boxSizing:"border-box",}}> {/*for project directory 20% and for sales dashboard 225*/}
        <MainSidebar active={"Project Directory"}/>
      </Stack>

     {/* 2nd stack */}
     <Stack direction="column" sx={{ backgroundColor:"#f9f9f9",width:"80%", padding:"25px",boxSizing:"border-box",}}  >  {/*  width for main sidebar 78px and for 80px*/}
      <Navbar/>
      


      <Typography sx={{marginTop:"20px", fontSize:"28px", fontWeight:"600", marginLeft:"10px", marginBottom:"50px"}}>
           Unit Template
           </Typography>
     {/* actual content  */}
    <Stack sx={{backgroundColor:"#f9f9f9"}}>  {/* mt:"28px", */}

    <Stack direction="column"  sx={{  padding:"20px", backgroundColor:"#fff", marginRight:"0px"}}>


<Box>

<Box>
<Box sx={{display:"flex",alignItems:"center",margin:"-20px 0px 0px 0px"}}>
<Typography component="span" className='field-name'>Unit Template Name</Typography>
<TextField
onChange={
(e)=>setTemplateName(e.target.value)
}
sx={{
width:"188px",
marginLeft:"40px"
}}/>
</Box>
{/* <Box sx={{display:"flex",alignItems:"center",margin:"30px 0px 0px 0px"}}>
<Typography component="span" className='field-name'>Unit Number Sequence</Typography>
<TextField
sx={{

width:"68px",
marginLeft:"40px"
}}/>
<TextField
sx={{
width:"68px",
marginLeft:"40px"
}}/>
</Box> */}
</Box>

<Box sx={{display:"flex",flexWrap:"wrap",justifyContent:"space-between",margin:"10px 30px 0px 0px"}}>
{single.map((ele,index)=>{
return(
<Box sx={{display:"flex",alignItems:"center",margin:"35px 0px 0px 0px",width:"45%"}}>
<Box sx={{width:"35%",overflow:"hidden",wordWrap:"break-word"}}>
<Typography component="span" className='field-name'>{ele.label}</Typography>
</Box>
<TextField type="number" inputProps={{ min: 0 }}
  sx={{
      width:"188px",
      marginLeft:"30px"
  }} value={ele.value} onChange={(event)=>{
      setsingle((prev) => {
          return prev.filter((curvalue, idx) => {
          if (idx == index) {
              curvalue.value = event.target.value;
          }
          return curvalue;
     })
      })
  }}
  />
<Typography component="span" className='field-back' sx={{marginLeft:"10px"}}>{ele.type}</Typography>
</Box>
)
})}
</Box>

<Box sx={{padding:"30px 0px 0px 0px"}}>
{double.map((ele,index)=>{
return(
<Box sx={{display:"flex",alignItems:"center",margin:"35px 0px 0px 0px"}}>
<Box sx={{width:"25%",overflow:"hidden",wordWrap:"break-word"}}>
<Typography component="span" className='field-name'>{ele.label}</Typography>
</Box>
<TextField type="number" inputProps={{ min: 0 }}
  sx={{
      width:"109px",
      marginLeft:"40px"
  }} value={ele.value1} onChange={(event)=>{
      setdouble((prev) => {
          return prev.filter((curvalue, idx) => {
          if (idx == index) {
              curvalue.value1 = event.target.value;
          }
          return curvalue;
     })
      })
  }}/>
<Typography component="span" className='field-back' sx={{marginLeft:"10px"}}>{`Length(${ele.type})`}</Typography>
<TextField type="number" inputProps={{ min: 0 }}
  sx={{
      width:"109px",
      marginLeft:"60px"
  }} value={ele.value2} onChange={(event)=>{
      setdouble((prev) => {
          return prev.filter((curvalue, idx) => {
          if (idx == index) {
              curvalue.value2 = event.target.value;
          }
          return curvalue;
     })
      })
  }}/>
<Typography component="span" className='field-back' sx={{marginLeft:"10px"}}>{`Breadth(${ele.type})`}</Typography>
</Box> 
)
})}     
</Box>


<Box>
<Typography className='field-name' sx={{margin:"60px 0px 0px 0px"}}>Add Bedrooms*</Typography>
<Box sx={{display:"flex",flexWrap:"wrap",margin:"20px 0px 0px 0px"}}>
{
bed.map((ele,index)=>{
return(
<Box className='bedroom-box' sx={{background:bedcolor==index+1?"#FF6140":"#EAECF2",color:bedcolor==index+1?"#FFFFFF":"#5D6174"}} onClick={
  ()=>{
      setbedcolor(ele);
      let data=[];
      for(let i=1;i<=ele;i++){

        let to_insert={
          bedroom_no:i,
          length:"",
          breadth:""
      }
        data.push(to_insert);
      }
      setbedroomdata(data);
      
}}>{ele}<br></br>BHK</Box>
)
})
}
<Box className='bedroom-box' onClick={()=>{
num1=num1+1;
setbedcolor(num1);
setbed(bed=> bed.concat(num1));
let data=[];
for(let i=1;i<=num1;i++){

let to_insert={
bedroom_no:i,
length:"",
breadth:""
}
data.push(to_insert);
}
setbedroomdata(data);
}}>+<br></br>BHK</Box>
</Box>

<Box>
{ bedroomdata.map((ele,index)=>{
return(
<Box sx={{display:"flex",alignItems:"center",margin:"30px 0px 0px 50px"}}>
<Typography component="span" className='field-name'>Dimension</Typography>
<TextField type="number" inputProps={{ min: 0 }}
sx={{
  width:"109px",
  marginLeft:"40px"
}} value={ele.length} onChange={(event)=>{
      setbedroomdata((prev) => {
          return prev.filter((curvalue, idx) => {
          if (idx == index) {
              curvalue.length = event.target.value;
          }
          return curvalue;
     })
      })
  }}/>
<Typography component="span" className='field-back' sx={{marginLeft:"10px"}}>Length(ft)</Typography>
<TextField
sx={{
  width:"109px",
  marginLeft:"40px"
}} value={ele.breadth} onChange={(event)=>{
      setbedroomdata((prev) => {
          return prev.filter((curvalue, idx) => {
          if (idx == index) {
              curvalue.breadth = event.target.value;
          }
          return curvalue;
     })
      })
  }}/>
<Typography component="span" className='field-back' sx={{marginLeft:"10px"}}>Breadth(ft)</Typography>
</Box>
)
})
}
</Box> 
</Box>

<Box>
<Typography className='field-name' sx={{margin:"40px 0px 0px 0px"}}>Add Bathrooms*</Typography>
<Box sx={{display:"flex",flexWrap:"wrap",margin:"20px 0px 0px 0px"}}>
{
bath.map((ele,index)=>{
return(
<Box sx={{background:bathcolor==index+1?"#FF6140":"#EAECF2",color:bathcolor==index+1?"#FFFFFF":"#5D6174",width:"63px",height:"41px"}} className='bedroom-box' onClick={()=>{
  setbathcolor(ele);
  let data=[];
      for(let i=1;i<=ele;i++){

        let to_insert={
          bathroom_no:i,
          length:"",
          breadth:""
      }
        data.push(to_insert);
      }
      setbathroomdata(data);
}}>{ele}</Box>
)
})
}
<Box sx={{width:"63px",height:"41px"}} className='bedroom-box' onClick={()=>{
num2=num2+1;
setbath(bath=> bath.concat(num2));
setbathcolor(num2);
let data=[];
for(let i=1;i<=num2;i++){

let to_insert={
bathroom_no:i,
length:"",
breadth:""
}
data.push(to_insert);
}
setbathroomdata(data);
}}>+</Box>
</Box>
<Box>
{ bathroomdata.map((ele,index)=>{
return(
<Box sx={{display:"flex",alignItems:"center",margin:"30px 0px 0px 50px"}}>
<Typography component="span" className='field-name'>Dimension</Typography>
<TextField type="number" inputProps={{ min: 0 }}
sx={{
  width:"109px",
  marginLeft:"40px"
}} value={ele.length} onChange={(event)=>{
      setbathroomdata((prev) => {
          return prev.filter((curvalue, idx) => {
          if (idx == index) {
              curvalue.length = event.target.value;
          }
          return curvalue;
     })
      })
  }}/>
<Typography component="span" className='field-back' sx={{marginLeft:"10px"}}>Length(ft)</Typography>
<TextField
sx={{
  width:"109px",
  marginLeft:"40px"
}} value={ele.breadth} onChange={(event)=>{
      setbathroomdata((prev) => {
          return prev.filter((curvalue, idx) => {
          if (idx == index) {
              curvalue.breadth = event.target.value;
          }
          return curvalue;
     })
      })
  }}/>
<Typography component="span" className='field-back' sx={{marginLeft:"10px"}}>Breadth(ft)</Typography>
</Box>
)
})
}              
</Box> 
</Box>

<Box>
<Typography className='field-name' sx={{margin:"40px 0px 0px 0px"}}>Add Balcony*</Typography>
<Box sx={{display:"flex",flexWrap:"wrap",margin:"20px 0px 0px 0px"}}>
{
bal.map((ele,index)=>{
return(
<Box sx={{background:balcolor==index+1?"#FF6140":"#EAECF2",color:balcolor==index+1?"#FFFFFF":"#5D6174",width:"63px",height:"41px",width:"63px",height:"41px"}} className='bedroom-box' onClick={()=>{
  setbalcolor(ele);
  let data=[];
      for(let i=1;i<=ele;i++){

        let to_insert={
          balcony_no:i,
          length:"",
          breadth:""
      }
        data.push(to_insert);
      }
      setbalconydata(data);
}}>{ele}</Box>
)
})
}
<Box sx={{width:"63px",height:"41px"}} className='bedroom-box' onClick={()=>{
num3=num3+1;
setbalcolor(num3);
setbal(bal=> bal.concat(num3));
let data=[];
for(let i=1;i<=num3;i++){

let to_insert={
balcony_no:i,
length:"",
breadth:""
}
data.push(to_insert);
}
setbalconydata(data);
}}>+</Box>
</Box>
<Box>
{ balconydata.map((ele,index)=>{
return(
<Box sx={{display:"flex",alignItems:"center",margin:"30px 0px 0px 50px"}}>
<Typography component="span" className='field-name'>Dimension</Typography>
<TextField type="number" inputProps={{ min: 0 }}
sx={{
  width:"109px",
  marginLeft:"40px"
}} value={ele.length} onChange={(event)=>{
      setbalconydata((prev) => {
          return prev.filter((curvalue, idx) => {
          if (idx == index) {
              curvalue.length = event.target.value;
          }
          return curvalue;
     })
      })
  }}/>
<Typography component="span" className='field-back' sx={{marginLeft:"10px"}}>Length(ft)</Typography>
<TextField type="number" inputProps={{ min: 0 }}
sx={{
  width:"109px",
  marginLeft:"40px"
}} value={ele.breadth} onChange={(event)=>{
      setbalconydata((prev) => {
          return prev.filter((curvalue, idx) => {
          if (idx == index) {
              curvalue.breadth = event.target.value;
          }
          return curvalue;
     })
      })
  }}/>
<Typography component="span" className='field-back' sx={{marginLeft:"10px"}}>Breadth(ft)</Typography>
</Box>
)
})
}          
  
</Box> 
</Box>

<Button 
onClick={async()=>{
await handelSubmmit()
}}
disabled={errorSubmit}
variant="contained"
color="primary" sx={{ height:"50px", textTransform: "none", borderRadius: "4px", width:"150px", fontSize:"15px",margin:"60px 0px 30px 0px"}}>
Save
</Button>

</Box>




</Stack>

    </Stack>




      </Stack>
</Stack>



   


    </>
  )
}

export default UnitTemplate