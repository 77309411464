import { Accordion, AccordionDetails, AccordionSummary, Button, FormControl, InputLabel, MenuItem, Modal, Select, Stack, TextField, Typography } from '@mui/material'
import { Box } from '@mui/system';
import React, { useContext, useEffect, useState } from 'react'
import { NavbarForDirectory } from '../../BuilderComponents/ProjectDashboard/DashboardComponents/NavbarForDirectory'
import { ProjectDashboardSidebar } from '../../BuilderComponents/ProjectDashboard/DashboardComponents/ProjectDashboardSidebar'
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import TextareaAutosize from '@mui/base/TextareaAutosize';
import ReactHtmlParser from 'react-html-parser';
// import ProjectAmenitiesEdit from './ProjectAmenitiesEdit';
import RichTextEditor from 'react-rte';
import axios from 'axios';
import { Apiaddress } from "../../../utils/consts";
import CloseIcon from '@mui/icons-material/Close';
import builderDashboard from "../../../context/builder_dashboard/context";
import { GridExpandMoreIcon } from '@mui/x-data-grid';
 import TuneOutlinedIcon from "@mui/icons-material/TuneOutlined";
import RDcustomTable from './RDcustomTable';
import profileContext from "../../../context/profile/context";
import { Link } from 'react-router-dom';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import CircularProgress from '@mui/material/CircularProgress';

function Request_Directory_w_sb() {

    const user = useContext(profileContext);

    const [loading,setloading]= useState(true);
    const [token, setToken] = useState(null);
    const [trigger, setTrigger] = useState(false);
    const [newRowData, setNewRowData] = useState([]);
    const [orgID, settOrgID] = useState(null);
    const [state, setState] = React.useState(false);
    const [data, setData] = useState([]);

    let builderSelectedData = JSON.parse(localStorage.getItem("BuilderSelected"));
    let id = builderSelectedData.selectedProjectID;
    console.log("selectedProjectId===",id);

    useEffect(() => {
      setToken(localStorage.getItem("crm_token"));
    },[trigger]);

    useEffect(() => {
      
      if (data) {
        console.log(data);
        let arr = [];
        for (let i = 0; i < data.length; i++) {
          if(data[i].project==id){
          let obj = {
            r_id: data[i].id,
            lead_name: data[i]?.lead.name,
            created_at: data[i]?.created_on,
            valid: data[i]?.valid_till,
            status: data[i]?.status,
            unit_id: data[i]?.unit,
            lead_id: data[i]?.lead.id,
            unit_name:data[i]?.extra_information?.unit?.name
          };
          arr.push(obj);
          }
        }
        setNewRowData(arr);
        console.log(arr);
      }
      setloading(false);

    }, [data, trigger]);

    console.log(newRowData);

    const fetchBuyerFile = async () => {
      try {
        const headers = {
        Authorization: `JWT ${token}`,
        };
        const res = await axios.get(
          Apiaddress + `/api/leads/listbuyerfile/`,
          { headers: headers }
        );
        console.log(res?.data?.buyer_file);
        setData(res?.data?.buyer_file);
      } catch (err) {
        console.log(err);
      }
    };

    useEffect(() => {
      if (orgID) fetchBuyerFile();
    }, [orgID, state, trigger]);

    useEffect(() => {
      if (user)
        if (user.UserState)
          if (user.UserState.user_obj)
            if (user.UserState.user_obj.orgList)
              if (user.UserState.user_obj.orgList[0]) {
                console.log(user.UserState.user_obj.orgList[0]);
                settOrgID(user.UserState.user_obj.orgList[0]);
              }
    }, [user, trigger]);
    
    //getting Project Information
    const fetchProjectInfo= async ()=>{
     try {
        const res = await axios.get(
          Apiaddress + `/api/projects/project/?project_id=${id}`
        );
      console.log("===========================.>",res?.data?.projects[0]);
      let projectInfo=res?.data?.projects[0];
      let obj={
        address:projectInfo?.address_line,
        city:projectInfo?.city,
        state:projectInfo?.state,
        pincode:projectInfo?.pin_code,
        name:projectInfo?.name,
      }
      setProjectInfo(obj);
    } catch (err) {
      console.log("====================",err);
    }
  }

  useEffect(()=>{
   fetchProjectInfo();
  },[])

    //Quotation Overview States
    const [projectInfo,setProjectInfo]= useState({});
    const [leadName,setLeadName]=useState();
    const [unitOverviewName,setUnitOverviewName]=useState();
    const [leadId,setLeadId]=useState();
    const [unitId,setUnitId]=useState();
    const [fileId,setFileId]=useState();
    const [fileStatus,setFileStatus]=useState();
    const [loadingSubmitApprove,setLoadingSubmitApprove]=useState(false);
    const [loadingSubmitReject,setLoadingSubmitReject]=useState(false);

    //overview Modal functionality
    const [openOverview, setOpenOverview] = React.useState(false);
    const handleOpenOverview = () => setOpenOverview(true);
    const handleCloseOverview = () => setOpenOverview(false);

    //Approve or reject Quotation
  const ApproveOrReject=async (status)=>{
    console.log(status);
    try {
      if(leadId==="" || unitId==="" || fileId==="" || id==="")return;
      if(status=="Approved"){
        setLoadingSubmitApprove(true);
      }
      else{
        setLoadingSubmitReject(true);
      }
      // let converted_Date=new Date(date);
      const headers = {
        Authorization: `JWT ${token}`,
      };
      const obj = {
        buyer_file_id:fileId,
        lead: leadId,
        unit: unitId,
        project: id,
        status:status,
        };
        console.log(obj);
        const res = await axios.patch(
          Apiaddress + `/api/leads/buyerfile/?buyer_file_id=${fileId}`,
          obj,
          { headers: headers }
        );
        console.log("ApprovedQuotation====",res?.data);
        handleCloseOverview();
      } catch (err) {
        console.log("quotationApprovederror",err);
      }
      if(status=="Approved"){
        setLoadingSubmitApprove(false);
      }
      else{
        setLoadingSubmitReject(false);
      }
      setTrigger(!trigger);
  }

  return (
    <>
    <Stack direction="row" sx={{width:"100%",height:"auto" }}> 
        {/* 1st stack */}
        <Stack sx={{minHeight:"100vh",width:"22%",boxSizing:"border-box"}}>
            <ProjectDashboardSidebar active={ "Buyer File Directory"}/>
            <Typography>fdaffsgvsb</Typography>
        </Stack>

        {/* 2nd stack */}
        <Stack direction="column" sx={{ backgroundColor:"#f9f9f9",width:"78%", padding:"25px",boxSizing:"border-box",}}  >
            {/* <Navbar/> */}
             <NavbarForDirectory
                leftRightTitle2={"Buyer File Directory"}
                leftTitle={"Project Dashboard"}
                righshow={true}
                longOrangBgr={true}
            />
            {/* actual content  */}
            <Stack sx={{ mt:"28px",backgroundColor:"#f9f9f9"}}>
                <Stack direction="row" justifyContent="space-between" alignItems="center" sx={{ height: "68px", marginBottom: "20px" }}>
                    <Stack direction="column" alignItems="center">
                        <Typography sx={{fontSize: "22px",fontWeight: "600",marginLeft: "0px",color: "black"}}>
                            Buyer File Directory
                        </Typography>
                        <Typography sx={{color: "#B9B7D0",fontSize: "12px",fontWeight: "400",paddingLeft: "20px"}}>
                            Here is your Buyer File Directory list data
                        </Typography>
                    </Stack>
                    <Stack direction="row" justifyContent="space-between" alignItems="center">
                        <Stack direction="row" justifyContent="space-around" alignItems="center" sx={{width: "203px",height: "68px", borderRadius: "12px",color: "#3E4954",backgroundColor: "#f4f5f9"}}>

                        {/* <Accordion sx={{ width: "250px", boxShadow: "none" }}>
                            <AccordionSummary
                                expandIcon={<GridExpandMoreIcon/>}
                                aria-controls="panel1a-content"
                                id="panel1a-header">
                            <Typography sx={{ display: "flex", placeItems: "center" }}>
                                <TuneOutlinedIcon sx={{ color: "#2F4CDD" }} />
                                <Typography sx={{ marginLeft: "25px" }}>
                                    Fillters
                                </Typography>
                            </Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Box sx={{textAlign:"center"}}>
                                    <FormControl sx={{ width: "200px" }}>
                                        <InputLabel id="demo-simple-select-label">
                                            Select
                                        </InputLabel>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            // value={filterselect}
                                            label="Select"
                                            // onChange={handleChange}
                                            >
                                            <MenuItem value={"created_on"}>Created Date</MenuItem>
                                            <MenuItem value={"valid_till"}>Valid Date</MenuItem>
                                        </Select>
                                    </FormControl>
                                    <TextField type="date" sx={{ marginTop: "20px" }} placeholder="Enter the value" 
                                        // value={filtervalue} 
                                        // onChange={(e)=>{setfiltervalue(e.target.value)}}
                                    />
                                    <Button 
                                        // onClick={fetchfilter}
                                        variant="contained"
                                        color="primary" sx={{ height:"40px", background: "linear-gradient(90deg, #0F5DAE 0%, #009DCF 100.33%)",boxShadow: "0px 13.7981px 22.5788px rgba(50, 174, 137, 0.25)", textTransform: "none", borderRadius: "4px", width:"100px", fontSize:"15px",margin:"20px 0px 10px 0px"}}>
                                        Submit
                                    </Button>
                                </Box>
                            </AccordionDetails>
                        </Accordion> */}

                    </Stack>

                </Stack>
            </Stack>     
        </Stack>

         <RDcustomTable 
          newRowData={newRowData} 
          loading={loading} 
          handleOpenOverview={handleOpenOverview}
          setLeadName={setLeadName}
          setUnitOverviewName={setUnitOverviewName}
          setLeadId={setLeadId}
          setUnitId={setUnitId}
          setFileId={setFileId}
          setFileStatus={setFileStatus}
          /> 

         {/* BuyerFile Overview Modal */}
        <Modal
        open={openOverview}
        onClose={handleCloseOverview}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        >

        <Box sx={{position: 'absolute',
             top: '50%',
             left: '50%',
             transform: 'translate(-50%, -50%)',
             width: "70%",
             height:"80%",
             bgcolor: 'white',
             border: '2px solid white',
             borderRadius:"4px",
             boxShadow: 24,
             pt: 3,
             px: 3,
             pb: 1.5,
             overflow:"scroll",
             whiteSpace:"nowrap",
             overflowX:"hidden"
            //  textAlign:"center" 
            }}>
          <Stack direction="row" justifyContent="space-between">
            <Typography sx={{fontFamily:'Product Sans',fontStyle:"normal",fontWeight:"600",fontSize:"28px",lineHeight:"33px",color:"#000000"}}>Buyer File Overview</Typography>
            {fileStatus=="Approved" && 
            <Link to="/pdf-for-buyerfile" 
                state={{project_id:id,file_id:fileId,unit_id:unitId,lead_id:leadId}}
                > 
              <Button variant="contained" sx={{background:"#0F5DAE",border:"1px solid #EFF0F7",boxShadow:"0px 2px 6px rgba(19, 18, 66, 0.07)",borderRadius:"5px",border:'1px solid #0F5DAE',marginLeft:"25px"}}
              startIcon={<PictureAsPdfIcon/>}>
              <Box sx={{ textTransform: "capitalize",margin:"0px 10px"}}>Generate PDF</Box>
            </Button>
            </Link>}
          </Stack>

           <Box sx={{alignItems:"center",margin:"40px 0px 40px 0px"}}>
            <Stack direction="row" justifyContent="start" alignItems="center" sx={{margin:"30px 0px 0px 0px",fontSize: "18px"}}>
                <Typography component="span" className='field-name' sx={{width:"15%",fontFamily:'Product Sans',fontStyle:"normal",fontWeight:"500",fontSize:"18px",lineHeight:"21px",color:"#000000"}}>Customer Name</Typography>
                <TextField value={leadName} disabled
                 sx={{
                     width:"325px",
                     marginLeft:"40px"
                    }}/>
            </Stack>
            <Stack direction="row" justifyContent="start" alignItems="center" sx={{margin:"30px 0px 0px 0px",fontSize: "18px"}}>
                <Typography component="span" className='field-name' sx={{width:"15%",fontFamily:'Product Sans',fontStyle:"normal",fontWeight:"500",fontSize:"18px",lineHeight:"21px",color:"#000000"}}>Project Name</Typography>
                <TextField value={projectInfo.name} disabled
                 sx={{
                     width:"325px",
                     marginLeft:"40px"
                    }}/>
            </Stack>
            <Stack direction="row" justifyContent="start" alignItems="center" sx={{margin:"30px 0px 0px 0px",fontSize: "18px"}}>
                <Typography component="span" className='field-name' sx={{width:"15%",fontFamily:'Product Sans',fontStyle:"normal",fontWeight:"500",fontSize:"18px",lineHeight:"21px",color:"#000000"}}>Site Address</Typography>
                <TextField value={projectInfo.address} disabled
                 sx={{
                     width:"325px",
                     marginLeft:"40px"
                    }}/>
            </Stack>
            <Stack direction="row" justifyContent="start" alignItems="center" sx={{margin:"30px 0px 0px 0px",fontSize: "18px"}}>
                <Typography component="span" className='field-name' sx={{width:"15%",fontFamily:'Product Sans',fontStyle:"normal",fontWeight:"500",fontSize:"18px",lineHeight:"21px",color:"#000000"}}>City</Typography>
                <TextField value={projectInfo.city} disabled
                 sx={{
                     width:"325px",
                     marginLeft:"40px"
                    }}/>
            </Stack>
            <Stack direction="row" justifyContent="start" alignItems="center" sx={{margin:"30px 0px 0px 0px",fontSize: "18px"}}>
                <Typography component="span" className='field-name' sx={{width:"15%",fontFamily:'Product Sans',fontStyle:"normal",fontWeight:"500",fontSize:"18px",lineHeight:"21px",color:"#000000"}}>State</Typography>
                <TextField value={projectInfo.state} disabled
                 sx={{
                     width:"325px",
                     marginLeft:"40px"
                    }}/>
            </Stack>
            <Stack direction="row" justifyContent="start" alignItems="center" sx={{margin:"30px 0px 0px 0px",fontSize: "18px"}}>
                <Typography component="span" className='field-name' sx={{width:"15%",fontFamily:'Product Sans',fontStyle:"normal",fontWeight:"500",fontSize:"18px",lineHeight:"21px",color:"#000000"}}>Pin Code</Typography>
                <TextField value={projectInfo.pincode} disabled
                 sx={{
                     width:"325px",
                     marginLeft:"40px"
                    }}/>
            </Stack>
             <Stack direction="row" justifyContent="start" alignItems="center" sx={{margin:"30px 0px 0px 0px",fontSize: "18px"}}>
                <Typography component="span" className='field-name' sx={{width:"15%",fontFamily:'Product Sans',fontStyle:"normal",fontWeight:"500",fontSize:"18px",lineHeight:"21px",color:"#000000"}}>Unit Number</Typography>
                <TextField value={unitOverviewName} disabled
                 sx={{
                     width:"325px",
                     marginLeft:"40px"
                    }}/>
            </Stack>
            </Box>
            
            {fileStatus=="Pending" && 
              <>
              <Button variant="contained" sx={{background:"#0F5DAE",border:"1px solid #EFF0F7",boxShadow:"0px 2px 6px rgba(19, 18, 66, 0.07)",borderRadius:"4px",  border:'1px solid #0F5DAE',margin:"20px 0px"}} 
              onClick={()=>{ApproveOrReject("Approved")}}
              >
              {
                loadingSubmitApprove?<CircularProgress color="inherit" sx={{ padding:"0px 20px"}}/>:<Box sx={{ textTransform: "capitalize",margin:"0px 7px"}}>Approve</Box>
              }
            </Button>
            <Button variant="contained" sx={{background:"white",border:"1px solid #EFF0F7",boxShadow:"0px 2px 6px rgba(19, 18, 66, 0.07)",borderRadius:"4px",  border:'1px solid   #0F5DAE',margin:"20px 20px",color:"#0F5DAE","&:hover": {background:"white",color:"#0F5DAE"
              }}} 
                onClick={()=>{ApproveOrReject("Rejected")}}
                >
              {
                loadingSubmitReject?<CircularProgress color="inherit" sx={{ padding:"0px 20px"}}/>:<Box sx={{ textTransform: "capitalize",margin:"0px 15px"}}>Reject</Box>
              }
            </Button>  
            </>
            }
          </Box>
       
      </Modal>
 

        

    </Stack>

    </Stack>
    </>
  )
}

export default Request_Directory_w_sb