import { Box, Button, Checkbox, Stack, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { Apiaddress, net,api } from "../../../../utils/consts";
import { AuthService } from "../../../../context/buyer/services/auth";
import axios from 'axios';
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import CircularProgress from '@mui/material/CircularProgress';
import { useNavigate } from 'react-router-dom';
import Lottie from "react-lottie";
import animationData from "../../../../Lotties/oGze35Xf1B.json";

function AssignedCPForms({lead_id}) {
  
  // console.log(lead_id);
  const navigate=useNavigate();

  const [loading,setloading]=useState(false);
  const [newRowData,setNewRowData]=useState();

  const cpDetails=JSON.parse(localStorage.getItem('cpDetails'));
  // console.log(cpDetails);
  let cpOrg=cpDetails?.channelPartnerOrg;

  // console.log(newRowData);

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const fetchForms=async()=>{
    setloading(true);
    try{
      const Auth = new AuthService();
      let url="";
        // console.log(cpOrg);
      if(cpOrg){
        url=`/api/leads/requirementsofcp/?org_id=${cpOrg}&isUpdated=True`
      }
      else{
        url=`/api/leads/requirementsofcp/?isUpdated=True`
      }
      const res = await await (  
        await Auth.makeAuthenticatedGetRequest(
          api(url)
        )
      ).json();
    if(res?.requirements=="requirements not found"){
        setNewRowData([]);
      }
    else{
       let temp=res?.requirements;
       let formData=[];
       for (let i=0;i<temp?.length;i++){
        if(temp[i]?.lead?.id==lead_id && temp[i]?.replyed==true){
          console.log("Yes");
           let create_obj=temp[i].created_on;
           let update_obj=temp[i].update_on;
           let date= new Date(create_obj);
           let date1= new Date(update_obj);
           let n = date.toDateString();
           let time = date.toLocaleTimeString();
           let n1 = date1.toDateString();
           let time1 = date1.toLocaleTimeString();
           let create=n+" "+time;
           let update=n1+" "+time1;
           console.log(create);
           let obj={
            created_on:create,
            update_on:update,
            Name:temp[i]?.form_name,
            id:temp[i]?.id
           }
           console.log(obj);
           formData.push(obj);
        }
        }
        setNewRowData(formData);
    }
    setloading(false);
    }catch(err){
        console.log(err.message);
    }
  }

  //table Content
  const [curpage, setcurpage] = useState(1);
  const [pageRange, setPageRange] = useState([0, 6]);
  const [pages, setPages] = useState([]);

  const nextClick = () => {
    if (
      !(newRowData.length >= pageRange[0] && newRowData.length <= pageRange[1])
    ) {
      setcurpage(curpage + 1);
      setPageRange([pageRange[0] + 6, pageRange[1] + 6]);
    }
  };

  const prvClick = () => {
    if (pageRange[0] != 0 && pageRange[1] != 6) {
      setcurpage(curpage - 1);
      setPageRange([pageRange[0] - 6, pageRange[1] - 6]);
    }
  };

  useEffect(() => {
    if (newRowData) {
      let arr = [];
      for (
        let i = 1;
        i <=
        (newRowData.length % 6 == 0
          ? newRowData.length / 6
          : newRowData.length / 6 + 1);
        i++
      ) {
        arr.push(i);
      }
      setPages(arr);
    }
  }, [newRowData]);

  useEffect(()=>{
    fetchForms();
  },[]);

  return (
    <>
    <Box sx={{ color: "#A2A3A6",height:"400px",paddingTop:"20px"}}>
        <Box>
            <Box sx={{ color: "black", width: "100%" }}>
                <Box sx={{display: "flex",justifyContent: "start",alignItems: "center",background: "white",borderRadius: "5px",margin: " 10px 0",color: "#272D4E",  fontWeight: "700",fontFamily:"Circular Std",fontSize:"18px"}}>

                    <Box
                    sx={{
                        width: "25vw",
                        display: "grid",
                        placeItems: "center",
                        height: "50px",
                        justifyContent: "center",
                        // paddingLeft:"20px",
                        // border:'1px solid red'
                    }}>
                    <Box sx={{ display: "flex" }}>
                        <Box sx={{ display: "grid", placeItems: "center" }}>Form Name</Box>
                    </Box>
                    </Box>

                     <Box
                    sx={{
                        width: "25vw",
                        display: "grid",
                        placeItems: "center",
                        height: "50px",
                        justifyContent: "center",
                        // paddingLeft:"20px",
                        // border:'1px solid red'
                    }}>
                    <Box sx={{ display: "flex" }}>
                        <Box sx={{ display: "grid", placeItems: "center" }}>Sent Date</Box>
                    </Box>
                    </Box>

                    <Box
                    sx={{
                        width: "25vw",
                        display: "grid",
                        placeItems: "center",
                        height: "50px",
                        justifyContent: "center",
                        // paddingLeft:"20px",
                        // border:'1px solid red'
                    }}>
                    <Box sx={{ display: "flex" }}>
                        <Box sx={{ display: "grid", placeItems: "center" }}>Recieved Date</Box>
                    </Box>
                    </Box>

                    <Box
                    sx={{
                        width: "25vw",
                        display: "grid",
                        placeItems: "center",
                        height: "50px",
                        justifyContent: "center",
                        // paddingLeft:"20px",
                        // border:'1px solid red'
                    }}>
                    <Box sx={{ display: "flex" }}>
                        <Box sx={{ display: "grid", placeItems: "center" }}>Action</Box>
                    </Box>
                    </Box>

                </Box>
          
          {loading?
           <>
           <Box sx={{textAlign:"center",marginTop:"50px"}}><CircularProgress color="inherit" sx={{ color: 'grey.500' }}/></Box>
           </>:
          newRowData?.length == 0 ?
          <Box sx={{ textAlign: "center", marginTop: "70px" }}><Typography sx={{ fontSize: "25px", fontWeight: "500", color: "#B9B7D0"}}>
            <Lottie options={defaultOptions} height="20%" width="20%" />
                <Typography
                  sx={{
                    color: "#B9B7D0",
                    fontSize: "18px",
                    fontWeight: "400",
                    marginTop: "-40px",
                  }}
                >
                  Please add your data
                </Typography>
            {/* <Button
                  variant="contained"
                  color="primary"
                  sx={{
                    height: "40px",
                    background: "#008dff",
                    boxShadow:
                      "0px 13.7981px 22.5788px rgba(50, 174, 137, 0.25)",
                    textTransform: "none",
                    borderRadius: "4px",
                    width: "200px",
                    fontSize: "15px",
                    margin: "5px 0px 5px 0px",
                    padding: "5px",
                    "&:hover": { background: "#008dff" },
                  }}
                  onClick={(e) => {
                    navigate(`/channelpartner/requirements-form`);
                  }}
                  >
                  Send Requirement Form
            </Button>   */}
          </Typography></Box> :
          <Box sx={{}}>
          <Box sx={{height:"320px"}}>
            {newRowData &&
              newRowData.map((item, index) => {

                if (index >= pageRange[0] && index <= pageRange[1]) {
                  return (
                    <Box
                      sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      background: "white",
                      borderRadius: "5px",
                      margin: " 10px 0",
                      color:"#343434",
                      "&:hover": {
                        backgroundColor: "#F5F7FF",
                        cursor:"pointer"
                      }
                      }}>
        
                      <Box
                        sx={{
                          width: "22vw",
                        display: "grid",
                        placeItems: "center",
                        height: "50px",
                        justifyContent: "start",
                        fontFamily:"Product Sans",
                        fontWeight:"400",
                        // paddingLeft:"20px",
                        // border:"1px solid red"
                        }}
                      >
                      <Typography sx={{marginLeft:"102px"}}>{item?.Name}</Typography>
                      </Box>
                      <Box
                        sx={{
                          width: "25vw",
                        display: "grid",
                        placeItems: "center",
                        justifyContent: "start",
                        height: "50px",
                        fontFamily:"Product Sans",
                        fontWeight:"400",
                        // paddingLeft:"20px"
                        }}
                      >
                        <Typography sx={{marginLeft:"112px"}}>{item?.created_on}</Typography>
                      </Box>
                
                      <Box
                        sx={{
                         width: "25vw",
                        display: "grid",
                        placeItems: "center",
                        justifyContent: "start",
                        height: "50px",
                        fontFamily:"Product Sans",
                        fontWeight:"400",
                        // paddingLeft:"20px"
                        }}
                      >
                        <Typography sx={{marginLeft:"55px"}}>{item?.update_on}</Typography>
                      </Box>

                      <Box
                        sx={{
                         width: "15vw",
                        display: "grid",
                        placeItems: "center",
                        justifyContent: "start",
                        height: "50px",
                        fontFamily:"Product Sans",
                        fontWeight:"400",
                        paddingLeft:"20px"
                        }}
                        >
                        <Stack direction="row" sx={{ marginLeft: "25px" }}>
                          { 
                            <RemoveRedEyeIcon fontSize="medium" sx={{cursor:"pointer"}}
                              onClick={(e) => {
                                navigate("/channelpartner/cpform/view/"+item?.id,{state:{replyed:item?.replyed}});
                              }}
                            />
                          }
                        </Stack>
                      </Box>
                    </Box>
                  );
                }
              })}
            </Box>

          <Box sx={{margin:"50px 0px 20px 0px"}}>
          <Box sx={{ display: "flex", justifyContent: "right",marginRight:"20px"}}>

            <Box sx={{display: "flex",placeItems: "center",margin: " 0 10px",borderRadius: "5px"}}>
              <Typography sx={{}}>Page</Typography>
              <Typography sx={{background: `white`,color: "black",width: "60px",height:"30px",borderRadius: "4px",margin: "0 10px",display: "grid",placeItems: "center",border:"1px solid #2979F2"}}>{curpage}</Typography>
              <Typography sx={{color: "black",width: "30px",borderRadius: "5px",margin: "0 0px",display: "grid",placeItems: "center"}}>of</Typography>
              <Typography sx={{color: "black",margin: "0px 10px 0px 0px",display: "grid",placeItems: "center"}}>{pages?.length}</Typography>
            </Box>

            {newRowData?.length!=0 && 
            <Button variant="contained" sx={{marginRight:"10px"}}
            disabled={curpage == 1}
            className="paginationButton"
              onClick={prvClick}>
                <KeyboardDoubleArrowLeftIcon fontSize="medium" />
            </Button>}

            {newRowData?.length!=0 && 
                <Button variant="contained" sx={{}}
                disabled={curpage == pages?.length}
                className="paginationButton"
                    onClick={nextClick}>
                    <KeyboardDoubleArrowRightIcon fontSize="medium" />
                </Button>
            }
          </Box>
        </Box>
        
          </Box>
        }
            </Box>
        </Box>
    </Box>  
    </>
  )
}

export default AssignedCPForms