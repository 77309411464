import { Box, Button, FormControl, MenuItem, Select, Stack, TextField, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import { SuperAdminNavbar } from '../SidebarNavbar/SuperAdminNavbar'
import { SuperAdminSidebar } from '../SidebarNavbar/SuperAdminSidebar'
import { Apiaddress } from "../../../utils/consts";
import axios from 'axios';

function SuperAdminRoleOverview() {

    let  token  = localStorage.getItem("crm_token")  
    console.log(token);

    const [rolename,setrolename]=useState();
    const [createdroleId,setcreatedroleId]=useState();

    const navigate = useNavigate();

    //Creating New Role
    const createNewRole=async ()=>{
    try{
      const headers = {
        Authorization: `JWT ${token}`,
      };  
      let obj={ 
        name:rolename
      }
    const roleurl = Apiaddress+"/api/roleprofile/"
    const res = await axios.post(roleurl,obj,{headers});
    console.log("sssss=========================",res?.data?.group);
    setcreatedroleId(res?.data?.group?.id);
    let object={
        rolename:rolename,
        roleid:res?.data?.group?.id
    }
    localStorage.setItem("roleDetails", JSON.stringify(object));
    navigate('/superadmin-role-permission');
    }
    catch(err){
      console.log(err);
    }
    }

  return (
    <>
       <Stack direction="row" sx={{width:"100%",height:"auto" }}>
        {/* 1st stack */}
        <Stack sx={{minHeight:"100vh",width:"20%",boxSizing:"border-box",}}> {/*for project directory 20% and for sales dashboard 225*/}
            <SuperAdminSidebar active={"Teams Directory"}/>
        </Stack>
         {/* 2nd stack */}
        <Stack direction="column" sx={{ backgroundColor:"#f9f9f9",width:"80%", padding:"25px",boxSizing:"border-box",}}  >  {/*  width for main sidebar 78px and for 80px*/}
            <SuperAdminNavbar/>   {/*send props for get popup to verify otp from builder */}

            <Typography sx={{fontFamily:'Poppins',fontStyle:"normal",fontWeight:"600",fontSize:"28px",lineHeight:"42px"}}>Roles Overview</Typography>

             <Stack sx={{margin:"50px 0px 30px 0px",background:"#FFFFFF"}}>
                <Stack direction="row" sx={{alignItems:"center",margin:"30px 0px 30px 40px"}}>
                  <Typography sx={{width:"20%",fontFamily:'Poppins',fontStyle:"normal",fontWeight:"500",fontSize:"18px",lineHeight:"27px"}}>Role Profile Name</Typography>
                  <TextField value={rolename} onChange={(e)=>{setrolename(e.target.value)}}/>
                  <Button variant="contained" sx={{background:"linear-gradient(90deg, #0F5DAE 0%, #009DCF 100.33%)",marginLeft:"40px",height:"45px"}}
                  onClick={()=>{
                    createNewRole();
                  }}
                  >
                    <Box sx={{ textTransform: "capitalize" }}>Create Role</Box>
                </Button>
                </Stack>
             </Stack>

            
             <Typography sx={{width:"20%",fontFamily:'Poppins',fontStyle:"normal",fontWeight:"500",fontSize:"18px",lineHeight:"27px",marginLeft:"40px",cursor:"pointer"}}
                onClick={()=>{navigate(-1)}}
                >Back 
            </Typography> 
       </Stack> 

      </Stack>

    </>
  )
}

export default SuperAdminRoleOverview 