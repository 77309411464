
import { Box, Button, Input, Radio, Typography } from "@mui/material"
import { Stack } from "@mui/system"
import { Navbar } from "../DashboardComponents/Navbar"
import { Sidebar } from "../DashboardComponents/Sidebar"
import DoneIcon from '@mui/icons-material/Done';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import { useNavigate, useParams } from "react-router-dom";
import SearchIcon from '@mui/icons-material/Search';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';

import InputAdornment from '@mui/material/InputAdornment';
import OutlinedInput from '@mui/material/OutlinedInput';
import "./CreateProject.css";
import Checkbox from '@mui/material/Checkbox';
import { useContext, useEffect, useState } from "react";
import { RestaurantOutlined } from "@mui/icons-material";

// modal 
import Modal from '@mui/material/Modal';
import { Apiaddress } from "../../../../utils/consts";

import currentProjectState from "../../../../context/createproject/context";
import axios from 'axios';
import { MainSidebar } from "../DashboardComponents/MainSidebar";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    height:"60%",
    bgcolor: 'background.paper',
    border: '2px solid #fff',
    borderRadius:"30px",
    boxShadow: 24,
    p: 4,
    overflow:"scroll",
    whiteSpace:"nowrap",
    overflowX:"hidden"
  };
  
export const AttachMediaToProject_Category = ()=>{
  ////get project id from local/context 
  const cpContext = useContext(currentProjectState);
  const projectID = cpContext?.createProjectState?.project?.id;
    const navigate = useNavigate();
    const category = useParams().id;
    let mediaType;
    mediaType = category=="broucher"?"Broucher":category=="image"?"Images":category=="floor"?"Floor Plans":null
    // console.log(mediaType)
    
    const [allSubCategory, setAllSubCategory] = useState([])
    
    ////select media 
    const [selectedCategory, setSelectCategory] = useState([]); 
    
    console.log(selectedCategory);

    const selectSubCat = (val)=>{
        const index = selectedCategory.indexOf(val);
        console.log(index);
        if (index > -1) { // only splice selectedCategory when item is found
          let  arr = selectedCategory.filter(function(item) {
            if(item.id!=val.id){
                return item
            }
            // return item !== val
          })
          setSelectCategory([...arr])
        }
        else{
          setSelectCategory([...selectedCategory,val])
        }   
      }
      
      const selectAllMedia = ()=>{
        if(selectedCategory.length!==allSubCategory.length ){
          let arr=[];
      for(let i=0; i<allSubCategory.length; i++){
        arr.push(allSubCategory[i]) 
      }
      setSelectCategory([...arr])
    }else{
      setSelectCategory([])
    }
    
    }

////get media from api
let builderData = JSON.parse(window.localStorage.getItem('crm_user'));
useEffect(()=>{
   let url = Apiaddress+"/api/projects/mediaofbuilder/?builder_id="+builderData.id

   axios.get(url)
   .then((res)=>{
       let data = res.data.media
    let arr = [];
    for(let i=0; i<data.length; i++){
        // console.log("media data =============>",data[i])
        if(mediaType==data[i].media_type)
        arr.push(data[i])
    }
    // console.log("all files present in media ====================>", arr)
    // setlistmedia(arr)
    setAllSubCategory(arr)

   }).catch((err)=>{
    console.log(err)
   })

},[])

//////// selected banner image 
const handelBannerImg=(e,ind)=>{
let temp =  selectedCategory.map((ele,id)=>{
    if (id==ind){
      ele.istrue=e.target.value
    }
    else{
      ele.istrue="false"
    }
    return ele
  })

  console.log(temp)

  setSelectCategory(temp)
}


///////save banner image ;
 
let [current_data, setCurrentData] = useState({})
console.log(current_data);
useEffect(()=>{
  console.log(projectID);
  const projecturl = Apiaddress+"/api/projects/project/?project_id="+projectID;
  
  axios.get(projecturl)
  .then((res)=>{
      const data = res?.data
      console.log(data);
      console.log("extra data=>>>>>>>.",data?.projects[0].extra_info)
      setCurrentData(data?.projects[0].extra_info)
   })
   .catch((err)=>{
     console.log(err)
   })
},[])



const updateBannerImg = ()=>{


  let url = Apiaddress+"/api/projects/project/"
  let st;
  if(category=="floor"){
    st = {
      project_id:projectID,
      extra_info:{...current_data, floorplan:selectedCategory}
    }
  }else if(category=="image") {
    st = {
      project_id:projectID,
      extra_info:{...current_data, image:selectedCategory}
    }
  }
  else{
    st = {
      project_id:projectID,
      extra_info:{...current_data, media:selectedCategory}
    }
  }

  let headers = {
    headers:{
      "Content-Type":"application/json"
  }
  }
//   console.log(st); 

  axios.patch(url,st,headers)
  .then((res)=>{
    console.log("add floorplan template in unit======>", res.data);
    navigate("/confirm-project-overview")
  })
  .catch((err)=>{
    console.log(err);
  })

  }


    // for modal 1
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    // for modal 2
    const [open2, setOpen2] = useState(false);
    const handleOpen2 = () => setOpen2(true);
    const handleClose2 = () => setOpen2(false);

     //radio button 
  const [selectedValue, setSelectedValue] = useState(allSubCategory[0]);

  const handleChange = (event) => {
    setSelectedValue(event.target.value);
  };

  const controlProps = (item) => ({
    checked: selectedValue === item,
    onChange: handleChange,
    value: item,
    name: 'color-radio-button-demo',
    inputProps: { 'aria-label': item },
  });

  const deleteImage = (ind)=>{

    ////delete locally 
    let arr = selectedCategory.filter((ele,index)=>{
        return ind!==ele.id
    })   

   setSelectCategory([...arr])

  }

    return(
        <>


<Stack direction="row" sx={{width:"100%",height:"auto" }}>
      {/* 1st stack */}
      <Stack sx={{minHeight:"100vh",width:"20%",boxSizing:"border-box",}}> {/*for project directory 20% and for sales dashboard 225*/}
        <MainSidebar active={"Project Directory"}/>
      </Stack>

     {/* 2nd stack */}
     <Stack direction="column" sx={{ backgroundColor:"#f9f9f9",width:"80%", padding:"25px",boxSizing:"border-box",}}  >  {/*  width for main sidebar 78px and for 80px*/}
      <Navbar/>
      


      <Typography sx={{marginTop:"20px", fontSize:"28px", fontWeight:"600", marginLeft:"10px", marginBottom:"50px"}}>
                 Attach media to Project
           </Typography>

     {/* actual content  */}
    <Stack sx={{backgroundColor:"#f9f9f9"}}>  {/* mt:"28px", */}

    <Stack direction="column"  sx={{  padding:"20px 0px 20px 0px", backgroundColor:"#fff", marginRight:"0px"}}>
           {/* attach media to unit */}
           <Stack direction="row" justifyContent="space-between" >
             <Stack sx={{width:"100%",paddingLeft:"20px"}} >
              <Typography sx={{fontSize:"28px", fontWeight:"600"}}>Media View</Typography>
                 <Stack direction="row" justifyContent="center" alignItems="center">
                  <Typography sx={{fontSize:"20px", fontWeight:"600", mt:"30px",mb:"20px"}}>Media Library</Typography>
                  </Stack>
                  <Stack>
                      <Typography sx={{fontSize:"18px", fontWeight:"500", color:"#A2A3A6"}}>{category}</Typography>
                      <Stack direction="row" alignItems="center" spacing={2} sx={{mt:"20px"}} >
                      <Checkbox
                            edge="start"
                            checked={selectedCategory.length == allSubCategory.length}
                          onChange={()=>{
                            selectAllMedia()
                                    }}
                            tabIndex={-1}
                            disableRipple
                          />
                          <Typography sx={{fontSize:"20px", fontWeight:"600", mt:"30px",mb:"20px"}}>Select all</Typography>
                      </Stack>

                      {/* all subcategory map */}
                      
                      <Box sx={{margin:"auto", width:{xs:"100%",sm:"100%", lg:"90%"},  marginTop:"20px",display:"flex", justifyContent:"start", flexWrap:"wrap"}}>
                        {allSubCategory?.map((ele,index)=>{
                         let imgurl = ele.uploaded_file!==null?ele.uploaded_file:ele.image;
                            return(
                            <Stack key={index} 
                            onClick={()=>{
                                selectSubCat(ele);                    
                            }} 
                            direction="row" alignItems="center" justifyContent="center" sx={{borderRadius:"20px",cursor:"pointer", width:"100px", height:"100px" ,margin:{sm:"10px",lg:"30px"}, border:selectedCategory.indexOf(ele)>-1 ?"3px solid #FBB040":"3px solid #D9D9D9", }}>
                               {/* <Typography sx={{fontSize:"18px", fontWeight:"600"}}>{category}</Typography>   */}
                               <img src={Apiaddress+imgurl} alt="" style={{width:"100%", height:"100%",borderRadius:"20px"}}/>
                            </Stack>
                            )
                        })}
                      </Box>
                     
                  </Stack>
             </Stack>

           </Stack>
             <Stack direction="row" justifyContent="end" alignItems="center" spacing={2} sx={{marginTop:"20px", padding:"20px"}}>
                  <Button 
               onClick={async()=>{
                // handleOpen()
                // await getImagesof1stSelectedUnit()
                handleOpen();
               }}
                 size="large"  sx={{
                    textTransform:"none",
                     color:"black", 
                     borderRadius:"5px",
                     fontWeight:600,
                     fontSize:"14px",
                     border:"1px solid #0F5DAE",
                     }}  >End allocation</Button>
                
                <Button size="large" className="univercel_btn_class" sx={{textTransform:"none", color:"#fff", padding:"9px 28px 9px 28px"}}
                 onClick={()=>{
                   navigate(`/confirm-project/attachmediatoproject`)
                  //  handleOpen()
                 }}
                 >Allot more</Button>


              
               
             </Stack>

             {/* modal for select cover image */}
             <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={{...style}}>
            <Box  sx={{width:{sm:500,lg:800,},display:"flex",justifyContent:"start",flexWrap:"wrap",}}>

                {selectedCategory?.map((ele,ind)=>{
                   let imgurl = ele.uploaded_file!==null?ele.uploaded_file:ele.image;
                    return(
                <Stack direction="row" justifyContent="space-around" alignItems="center" sx={{width:"398px"}}>
                <Stack  
                    onClick={()=>{                 
                    }} 
                    direction="row" alignItems="center" justifyContent="center" sx={{borderRadius:"20px",cursor:"pointer", width:"100px", height:"100px" ,margin:{sm:"10px",lg:"30px"}, bgcolor:"#D9D9D9" }}>
                       {/* <Typography sx={{fontSize:"18px", fontWeight:"600"}}>{category}</Typography>   */}
                       <img src={Apiaddress+imgurl} alt="" style={{width:"100%", height:"100%",borderRadius:"20px"}}/>
                    </Stack>
                    <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={1}>
                      <input type="radio" 
                      id="s1"
                      name="banner"
                      value="true"
                      checked={ele.istrue === "true"}
                      onChange={(e)=>{
                        handelBannerImg(e,ind)
                      }}
                      style={{
                        height:"20px",
                        color: "linear-gradient(264.48deg, #79F1A4 -45.37%, #0E5CAD 88.97%)",
                          color: "#0F5DAE",
                        '&.Mui-checked': {},
                      }}
                      />
                            <Typography sx={{width:"200px",overflow:"hidden"}}>{ele.tag}</Typography>
                            <svg 
                            onClick={()=>{
                                deleteImage(ele.id)
                            }}
                            style={{cursor:"pointer"}}
                            width="16" height="18" viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M3 18C2.45 18 1.97933 17.8043 1.588 17.413C1.196 17.021 1 16.55 1 16V3H0V1H5V0H11V1H16V3H15V16C15 16.55 14.8043 17.021 14.413 17.413C14.021 17.8043 13.55 18 13 18H3ZM13 3H3V16H13V3ZM5 14H7V5H5V14ZM9 14H11V5H9V14Z" fill="#EF3649"/>
                            </svg>


                    </Stack>
            </Stack>
                    )
                })}
            </Box>


                <Stack direction="row" alignItems="center" justifyContent="center" sx={{mt:"20px"}}>
                <Button 
                onClick={()=>{
                  updateBannerImg()
                  // navigate("/confirm-project1")
                }}
                className="univercel_btn_class" sx={{padding:"16px 54px 16px 54px"}}>Save</Button>
                </Stack>
          
        </Box>
      </Modal>
              
           </Stack>

    </Stack>
      </Stack>
</Stack>


        
                
        
        </>
    )
}