import { Apiaddress } from "../../../../utils/consts";
import { AuthService } from "../auth";
import axios from "axios";

class DocumentFetchingService {
    Auth: AuthService;
    url = Apiaddress + "/api/buyer/buyerdocument/"

    constructor(e: AuthService) {
        this.Auth = e;
    }
    async getBuyersDocuments() {
        return await (await this.Auth.makeAuthenticatedGetRequest(this.url)).json()
    }

    getDocumentById() {


    }

    async createDocument({ file, category, notes }: { file: FileList | null, category: string, notes: string }) {
        
        const headers = {
            headers: this.Auth.getAuthHeaders(true)
            
        }

        const data = {
            uploaded_file: file,
            desc: category,
            buyer:  this.Auth.getCurrentBuyerId()
        }

        console.log("data",data,headers)
        await axios.post(this.url, data, headers)
        return await this.getBuyersDocuments()
    }



}
export { DocumentFetchingService }