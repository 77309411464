
import { Box, Stack} from '@mui/system';
import React, { useEffect, useState } from 'react';
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { Accordion, AccordionDetails, AccordionSummary, Button, CircularProgress, FormControl, InputLabel, MenuItem, Modal, Select, TextField, Typography } from '@mui/material';
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
  import TuneOutlinedIcon from "@mui/icons-material/TuneOutlined";

import { SuperAdminSidebar } from '../SidebarNavbar/SuperAdminSidebar';
import { SuperAdminNavbar } from '../SidebarNavbar/SuperAdminNavbar';
import { Apiaddress } from "../../../utils/consts";
import axios from 'axios';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { GridExpandMoreIcon } from '@mui/x-data-grid';
import { DataArraySharp } from '@mui/icons-material';
import { SAProjectSidebar } from '../SidebarNavbar/SAProjectSidebar';


export const SAUnitDirectory = ()=>{
    const navigate = useNavigate();
    
    //get data from local Storage
    let data = JSON.parse(localStorage.getItem("SABuilderDetails"));
    console.log("projectId =>.>",data.selectedProjectId);

    const [orgid,setorgid]=useState("");

  
   const [curpage, setcurpage] = useState(1);
   const [pageRange, setPageRange] = useState([0, 9]);
   const [pages, setPages] = useState([]);

   const [loading,setloading]=useState(true);

   const [newRowData,setnewRowData]= useState([]);

   const [fetchedUnit,setfetchedUnit]= useState([]);
    
   ////////////////////
   const [expanded, setExpanded] = React.useState(false);

   const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
    };
    
    //////////////////
    const nextClick = () => {
    if (
      !(newRowData.length >= pageRange[0] && newRowData.length <= pageRange[1])
    ) {
      setcurpage(curpage + 1);
      setPageRange([pageRange[0] + 9, pageRange[1] + 9]);
    }
    };

  const prvClick = () => {
    if (pageRange[0] != 0 && pageRange[1] != 9) {
      setcurpage(curpage - 1);
      setPageRange([pageRange[0] - 9, pageRange[1] - 9]);
    }
    };

    useEffect(() => {
    if (newRowData) {
      let arr = [];
      for (
        let i = 1;
        i <=
        (newRowData.length % 9 == 0
          ? newRowData.length / 9
          : newRowData.length / 9 + 1);
        i++
      ) {
        arr.push(i);
      }
      setPages(arr);
    }
  }, [newRowData]);


  //get Unit data of Projects
   const fetchUnitdata =async ()=>{
     
    try {
        const res = await axios.get(
          Apiaddress + `/api/projects/getunitsusingprojectid/?project_id=${data.selectedProjectId}`
        );
      console.log("===========================.>",res?.data?.unit);
      if(res.data.unit=="Unit not found"){
            setfetchedUnit([])
        }
      else{
            setfetchedUnit(res?.data?.unit);
        }
    } catch (err) {
      console.log("====================",err);
      setfetchedUnit([]);
    }
    setloading(false)
  };
   
   useEffect(()=>{
     
    fetchUnitdata();
   
  },[])

   //organise data from fetched data
   useEffect(() => {

    if (fetchedUnit) {
      let arr = [];
      for (let i = 0; i < fetchedUnit?.length; i++) {

          let totalcost=0;
          
           for (let j = 0; j < fetchedUnit[i]?.charges_data?.length ; j++) {

            totalcost=totalcost+(fetchedUnit[i].charges_data[j].sectioncost);

          }

          let obj={
            id:fetchedUnit[i]?.id,
            floor_no:fetchedUnit[i].floor?.id,
            bedroom:fetchedUnit[i].layout_data?.bedroomData?.length,
            bathroom:fetchedUnit[i].layout_data?.bathroomData?.length,
            balcony:fetchedUnit[i].layout_data?.balconyData?.length,
            // totalarea:fetchedUnit[i].layout_data?.singleData[0]?.value,
            totalarea:0,
            single_data:fetchedUnit[i].layout_data?.singleData,
            double_data:fetchedUnit[i].layout_data?.doubleData,
            pricing:totalcost
          }
          arr.push(obj);
        }
    // console.log(arr);

    setnewRowData(arr);
    
  }

  }, [fetchedUnit]);

  console.log(newRowData);

  //fitering data starts:====
  const [filterselect, setfilterselect] = useState();
  const [filtervalue, setfiltervalue] = useState();

  const handleChanged = (event) => {
    setfilterselect(event.target.value);
    setfiltervalue("");
  };

  console.log(filterselect);
  console.log(filtervalue);

   const fetchfilter = async () => {
    console.log("callled");
     try {
        const res = await axios.get(
          Apiaddress + `/api/projects/getunitsusingprojectid/?project_id=${data.selectedProjectId}&filterkey=${filterselect}&filtervalue=${filtervalue}`
        );
      console.log("===========================.>",res?.data?.unit);
      if(res.data.unit=="Unit not found"){
            setfetchedUnit([])
        }
      else{
            setfetchedUnit(res?.data?.unit);
        }
    } catch (err) {
      console.log("====================",err);
      setfetchedUnit([]);
    }
  };

  
    return(
        <>
            <Stack direction="row" sx={{width:"100%",height:"auto" }}>
      {/* 1st stack */}
      <Stack sx={{minHeight:"100vh",width:"22%",boxSizing:"border-box",}}> {/*for project directory 20% and for sales dashboard 22%*/}
        <SAProjectSidebar active={"Unit Directory"}/>
      </Stack>

     {/* 2nd stack */}
     <Stack direction="column" sx={{ backgroundColor:"#f9f9f9",width:"78%", padding:"25px",boxSizing:"border-box",}}  >  {/*  width for main sidebar 78px and for 80px*/}
      <SuperAdminNavbar unit={true}/>  {/*send props for get popup to verify otp from builder */}

      <Stack direction="row" justifyContent="space-between" alignItems="center" sx={{}}>
            <Box sx={{}}>
                <Typography sx={{marginTop:"10px", fontSize:"16px", fontWeight:"600", marginLeft:"0px", marginBottom:"0px",color:"#2979F2",fontWeight: "400"}}>
                    Here is your Unit Directory list Data 
                </Typography>
                <Typography sx={{marginTop:"20px", fontSize:"28px", fontWeight:"600", marginLeft:"0px"}}>
                    Unit Directory   
                </Typography>
                <Typography sx={{marginTop:"10px", fontSize:"16px", fontWeight:"600", marginLeft:"0px", marginBottom:"50px",color: "#9299BC",fontWeight: "500"}}>
                    Here is your Unit Directory list associated with Project 
                </Typography>
            </Box>

            <Accordion sx={{ width: "250px", boxShadow: "none",position:"absolute",right:"1%"}}>
                    <AccordionSummary expandIcon={<GridExpandMoreIcon/>} aria-controls="panel1a-content" id="panel1a-header">
                        <Typography sx={{ display: "flex", placeItems: "center" }}>
                            <TuneOutlinedIcon sx={{ color: "#2F4CDD"}} />
                            <Typography sx={{ marginLeft: "25px"}}>Fillters</Typography>
                        </Typography>
                    </AccordionSummary>

                    <AccordionDetails>
                        <Box sx={{textAlign:"center"}}>
                            <FormControl sx={{ width: "200px" }}>
                                <InputLabel id="demo-simple-select-label">Select</InputLabel>
                                <Select labelId="demo-simple-select-label" id="demo-simple-select" value={filterselect} label="Select"
                                    onChange={handleChanged}>

                                    <MenuItem value={"name"}>Name</MenuItem>
                                    <MenuItem value={"is_booked"}>Booked</MenuItem>
                                    <MenuItem value={"is_paid"}>Paid</MenuItem>
                                    <MenuItem value={"is_occupy"}>Occupied</MenuItem>

                                </Select>
                            </FormControl> 
                            {
                                filterselect=="name"?
                                    <TextField sx={{ marginTop: "20px",width:"92%"}} placeholder="Enter the value" value={filtervalue} onChange={(e)=>{setfiltervalue(e.target.value)}}/>:

                                    <FormControl sx={{ width: "200px", marginTop: "20px"}}>
                                        <InputLabel id="demo-simple-select-label">Choose</InputLabel>
                                        <Select labelId="demo-simple-select-label" id="demo-simple-select" value={filtervalue} label="Select" onChange={(e)=>{setfiltervalue(e.target.value)}}>
                                            <MenuItem value={'1'}>True</MenuItem>
                                            <MenuItem value={'0'}>False</MenuItem>
                                        </Select>
                                    </FormControl>
                            }
                            <Button onClick={fetchfilter} variant="contained" color="primary" sx={{ height:"40px", background: "linear-gradient(90deg, #0F5DAE 0%, #009DCF 100.33%)",boxShadow: "0px 13.7981px 22.5788px rgba(50, 174, 137, 0.25)", textTransform: "none", borderRadius: "4px", width:"100px", fontSize:"15px",margin:"20px 0px 10px 0px"}}>Submit
                            </Button>

                        </Box>
                    </AccordionDetails>

            </Accordion>
            
      </Stack>
     {/* actual content  */}
    <Stack sx={{backgroundColor:"#f9f9f9"}}>  {/* mt:"28px", */}

    <Box sx={{height:"auto",width:"100%"}}>
        <Box sx={{ color: "black", width: "100%" ,boxSizing:"border-box",display:"flex",flexDirection:"column",height:"auto"}}>

            <Stack direction="row" justifyContent="space-between" alignItems="center" sx={{width:"100%",backgroundColor:"white",borderRadius: "5px",margin:"0px 0px 10px 0px",padding:"10px 0px 10px 0px"}}>
              
                <Stack direction="row" justifyContent="center" alingItems="center" sx={{width:"14%",textAlign:"center",color: "#9299BC",fontWeight: "600"}}>
                        <Typography sx={{ display: "grid", placeItems: "center" }}>Unit No</Typography>
                        <Box sx={{ display: "grid", marginLeft: "4px" }}>
                            <ArrowDropUpIcon fontSize="medium" sx={{ marginBottom: "-8px" }}/>
                            <ArrowDropDownIcon fontSize="medium" sx={{ marginTop: "-8px" }}/>
                        </Box>
                </Stack>

                 <Stack direction="row" justifyContent="center" alingItems="center" sx={{width:"14%",textAlign:"center",color: "#9299BC",fontWeight: "600"}}>
                        <Typography sx={{ display: "grid", placeItems: "center" }}>Floor No</Typography>
                        <Box sx={{ display: "grid", marginLeft: "4px" }}>
                            <ArrowDropUpIcon fontSize="medium" sx={{ marginBottom: "-8px" }}/>
                            <ArrowDropDownIcon fontSize="medium" sx={{ marginTop: "-8px" }}/>
                        </Box>
                </Stack>

                 <Stack direction="row" justifyContent="center" alingItems="center" sx={{width:"14%",textAlign:"center",color: "#9299BC",fontWeight: "600"}}>
                        <Typography sx={{ display: "grid", placeItems: "center" }}>Bedrooms</Typography>
                        <Box sx={{ display: "grid", marginLeft: "4px" }}>
                            <ArrowDropUpIcon fontSize="medium" sx={{ marginBottom: "-8px" }}/>
                            <ArrowDropDownIcon fontSize="medium" sx={{ marginTop: "-8px" }}/>
                        </Box>
                </Stack>

                 <Stack direction="row" justifyContent="center" alingItems="center" sx={{width:"14%",textAlign:"center",color: "#9299BC",fontWeight: "600"}}>
                        <Typography sx={{ display: "grid", placeItems: "center" }}>Bathrooms</Typography>
                        <Box sx={{ display: "grid", marginLeft: "4px" }}>
                            <ArrowDropUpIcon fontSize="medium" sx={{ marginBottom: "-8px" }}/>
                            <ArrowDropDownIcon fontSize="medium" sx={{ marginTop: "-8px" }}/>
                        </Box>
                </Stack>

                 <Stack direction="row" justifyContent="center" alingItems="center" sx={{width:"14%",textAlign:"center",color: "#9299BC",fontWeight: "600"}}>
                        <Typography sx={{ display: "grid", placeItems: "center" }}>Total Area</Typography>
                        <Box sx={{ display: "grid", marginLeft: "4px" }}>
                            <ArrowDropUpIcon fontSize="medium" sx={{ marginBottom: "-8px" }}/>
                            <ArrowDropDownIcon fontSize="medium" sx={{ marginTop: "-8px" }}/>
                        </Box>
                </Stack>

                <Stack direction="row" justifyContent="center" alingItems="center" sx={{width:"14%",textAlign:"center",color: "#9299BC",fontWeight: "600"}}>
                        <Typography sx={{ display: "grid", placeItems: "center" }}>Pricing</Typography>
                        <Box sx={{ display: "grid", marginLeft: "4px" }}>
                            <ArrowDropUpIcon fontSize="medium" sx={{ marginBottom: "-8px" }}/>
                            <ArrowDropDownIcon fontSize="medium" sx={{ marginTop: "-8px" }}/>
                        </Box>
                </Stack>

                <Stack direction="row" justifyContent="center" alingItems="center" sx={{width:"14%",textAlign:"center",color: "#9299BC",fontWeight: "600"}}>
                        <Typography sx={{ display: "grid", placeItems: "center" }}>Option</Typography>
                        <Box sx={{ display: "grid", marginLeft: "4px" }}>
                            <ArrowDropUpIcon fontSize="medium" sx={{ marginBottom: "-8px" }}/>
                            <ArrowDropDownIcon fontSize="medium" sx={{ marginTop: "-8px" }}/>
                        </Box>
                </Stack>

            </Stack>

            
             {loading==true?
            <Box sx={{display:"flex",height:"80vh",padding:"10% 5% 5% 45%"}}>
                <CircularProgress color="inherit" sx={{ color: 'grey.500' }}/>
            </Box>
             :
            <Box sx={{}}>
                {newRowData.length==0?
                    <Box sx={{textAlign:"center",marginTop:"50px"}}>
                        <Typography sx={{fontSize:"25px",fontWeight:"500",color:"#B9B7D0",}}>No Matches Found</Typography>
                    </Box>:
                    <Box>
                        {newRowData && newRowData.map((item, index) => {
                            if (index >= pageRange[0] && index <= pageRange[1]) {
                                return (
                                <Stack direction="row" justifyContent="space-between" alignItems="center" sx={{width:"100%",borderRadius: "5px",margin:"0px 0px 10px 0px",padding:"5px 0px 5px 0px",backgroundColor:"white"}}>

                                <Accordion expanded={expanded === `panel${index}`} onChange={handleChange(`panel${index}`)} 
                                sx={{boxShadow: "0",border: "none",justifyContent:"space-between", width:"100%"}}
                                >
                                    <AccordionSummary>
                                        <Stack direction="row" justifyContent="center" alingItems="center" sx={{width:"14%",textAlign:"center",color: "#9299BC",fontWeight: "600",justifyContent:"center",border:"2px solid transparent"}}>
                                            <Typography >{item?.id}</Typography>
                                        </Stack>

                                        <Stack direction="row" justifyContent="center" alingItems="center" sx={{width:"14%",textAlign:"center",color: "#9299BC",fontWeight: "600",border:"2px solid transparent"}}>
                                            <Typography >{item?.floor_no}</Typography>
                                        </Stack>

                                        <Stack direction="row" justifyContent="center" alingItems="center" sx={{width:"14%",textAlign:"center",color: "#9299BC",fontWeight: "600",border:"2px solid transparent"}}>
                                            <Typography >{item?.bedroom}</Typography>
                                        </Stack>

                                        <Stack direction="row" justifyContent="center" alingItems="center" sx={{width:"14%",textAlign:"center",color: "#9299BC",fontWeight: "600",border:"2px solid transparent"}}>
                                            <Typography >{item?.bathroom}</Typography>
                                        </Stack>

                                        <Stack direction="row" justifyContent="center" alingItems="center" sx={{width:"14%",textAlign:"center",color: "#9299BC",fontWeight: "600",border:"2px solid transparent"}}>
                                            <Typography >{item?.totalarea}</Typography>
                                        </Stack>

                                        <Stack direction="row" justifyContent="center" alingItems="center" sx={{width:"14%",textAlign:"center",color: "#9299BC",fontWeight: "600",border:"2px solid transparent"}}>
                                            <Typography >{item?.pricing}</Typography>
                                        </Stack>

                                        <Stack direction="row" justifyContent="center" alingItems="center" sx={{width:"14%",textAlign:"center",color: "#9299BC",fontWeight: "600",border:"2px solid transparent"}}>
                                            <Typography ><GridExpandMoreIcon /></Typography>
                                        </Stack>

                                    </AccordionSummary>

                                    <AccordionDetails>
                                        <Typography>
                                            <Box sx={{color: "#969BA0",fontSize: "18px",padding: "0 40px",marginTop: "20px"}}>The Details Review of Unit</Box>
                                            <Box sx={{display: "grid",gridTemplateColumns: "1fr 1fr 1fr",padding: "0 40px",marginTop: "30px",rowGap: "30px"}}>

                                                <Box sx={{ display: "flex" }}>
                                                    <Box>Unit No : </Box>
                                                    <Box sx={{ color: "#6F6F6F" }}>{item?.id}</Box>
                                                </Box>

                                                <Box sx={{ display: "flex" }}>
                                                    <Box>Floor No : </Box>
                                                    <Box sx={{ color: "#6F6F6F" }}>{item?.floor_no}</Box>
                                                </Box>
                        
                                                {item?.single_data?.map((ele,idx)=>{
                           
                                                    return(
                                                        <Box sx={{ display: "flex" }}>
                                                            <Box>{ele.label} : </Box>
                                                            <Box sx={{ color: "#6F6F6F" }}>{ele.value}</Box>
                                                        </Box>
                                                    )

                                                })}

                                                {item?.double_data?.map((ele,idx)=>{
                           
                                                    return(
                                                        <Box sx={{ display: "flex" }}>
                                                            <Box>{ele.label} : </Box>
                                                            <Box sx={{ color: "#6F6F6F" }}>{`${ele.value1} X ${ele.value1}`}</Box>
                                                        </Box>
                                                    )
                                                })}

                                                <Box sx={{ display: "flex" }}>
                                                    <Box>No. of Bedrooms : </Box>
                                                    <Box sx={{ color: "#6F6F6F" }}>{item.bedroom}</Box>
                                                </Box>

                                                <Box sx={{ display: "flex" }}>
                                                    <Box>No of Bathrooms: </Box>
                                                    <Box sx={{ color: "#6F6F6F" }}>{item.bathroom}</Box>
                                                </Box>

                                                <Box sx={{ display: "flex" }}>
                                                    <Box>No of Balcony: </Box>
                                                    <Box sx={{ color: "#6F6F6F" }}>{item.balcony}</Box>
                                                </Box>

                                                <Box sx={{ display: "flex" }}>
                                                    <Box>Total Cost: </Box>
                                                    <Box sx={{ color: "#6F6F6F" }}>{item.pricing}</Box>
                                                </Box>
                                            </Box>
                                            <Box sx={{display: "flex",justifyContent: "right"}}>
                                                <Box sx={{width: "45vw",display: "grid",placeItems: "center",height: "15vh",marginRight: "30px"}}>
                            
                                                    <Stack direction="row" sx={{ marginLeft: "10px",marginTop:"20px" }}>
                                                        {/* <Button variant="contained" sx={{width: "200px",background:"linear-gradient(90deg, #0F5DAE 0%, #009DCF 100.33%)",marginRight:"20px"}}
                                                        onClick={() => {navigate(`/unit-overview/:${item?.id}`)}}
                                                        >Unit Overview</Button> */}
                                                        {/* <Button variant="contained" sx={{width: "100px",background:"linear-gradient(90deg, #0F5DAE 0%, #009DCF 100.33%)"}}>Edit</Button> */}
                                                    </Stack>
                                                </Box>
                                            </Box>
                                        </Typography>
                                    </AccordionDetails>
                                </Accordion>
                            </Stack>
                           );
                        }
                    }
                )
            }

            <Box sx={{margin:"30px 0px 20px 0px"}}>
          <Box sx={{ display: "flex", justifyContent: "right" }}>
            {newRowData.length!=0 && 
            <Button variant="contained" sx={{background:"linear-gradient(90deg, #0F5DAE 0%, #009DCF 100.33%)"}}
              onClick={prvClick}>
                <ArrowBackIosIcon fontSize="small" />
                <Box sx={{ textTransform: "capitalize" }}>Previous</Box>
            </Button>}

            <Box sx={{background: "#E3E4EB",display: "flex",placeItems: "center",margin: " 0 10px",borderRadius: "5px"}}>
                <Box sx={{background: `white`,color: "black",width: "30px",borderRadius: "5px",margin: "0 10px",display: "grid",placeItems: "center"}}>{curpage}</Box>
                <Box sx={{color: "black",width: "30px",borderRadius: "5px",margin: "0 0px",display: "grid",placeItems: "center"}}>of</Box>
                <Box sx={{background: `white`,color: "black",width: "30px",borderRadius: "5px",margin: "0 10px",display: "grid",placeItems: "center"}}>{pages?.length}</Box>
            </Box>

            {newRowData.length!=0 && 
                <Button variant="contained" sx={{background:"linear-gradient(90deg, #0F5DAE 0%, #009DCF 100.33%)"}}
                    onClick={nextClick}>
                    <Box sx={{ textTransform: "capitalize" }}>Next</Box>
                    <ArrowForwardIosIcon fontSize="small" />
                </Button>
            }
          </Box>
        </Box>
        </Box>
        }
        </Box>
        }
        </Box>

     </Box>

    </Stack>
      </Stack>
       </Stack>

        </>
    )
}