import { Box, Button, Drawer, TextField } from '@mui/material'
import React, { useContext, useEffect, useRef, useState } from 'react'
import TabBar from '../../components/tabBar/TabBar'
import DocumentItem from './DocumentItem'
import AddIcon from '@mui/icons-material/Add';
import UploadIcon from '@mui/icons-material/Upload';
import buyerContext from '../../../../context/buyer/context';
import { BuyerService } from '../../../../context/buyer/services/buyerServices';
import { Apiaddress } from '../../../../utils/consts';

export const View = (props: any) => {
  const bc: BuyerService = useContext(buyerContext);
  const [CreateDocumnetModalState, setCreateDocumnetModalState] = useState(false)
  const [Documents, setDocuments] = useState([])
  const [Category, setCategotry] = useState('')
  const [notes, setNotes] = useState("")
  const [file, setfile] = useState<FileList | null>(null)
  useEffect(() => {
    const x = async () => {
      const res = (await bc.server.documents.getBuyersDocuments()).documents;
      if (res != "Lead not found")
        setDocuments(res)
      console.log({ "le documents": await bc.server.documents.getBuyersDocuments() });
    }
    x();

  }, [bc.server.documents])
  const inputFile = useRef<HTMLInputElement | null>(null)



  return (
    <>
      <Box sx={{
        // minHeight: 500,
        margin: 5,
        color: "white",
        boxShadow: 10,
        padding: 2
      }}>
        <Box sx={{
          color: "#0065FE",
          fontSize: 24,
          fontWeight: 700,
        }}>
          Document Cabinet
        </Box>
        <TabBar

          floatingActonButton={
            <Button
              onClick={() => {
                setCreateDocumnetModalState(true)
              }}
              className="univercel_btn_class"
              sx={{ color: "#FFFFFF", padding: "6px 12px 6px 12px", height: "auto", margin: "10px" }}
              startIcon={<AddIcon />}
            >
              Add Documents
            </Button>
          }
          Tabs={[
            {
              Name: 'Recived',
              children:  [
                <div>
                  No Recived Document
                </div>
              ]
            },
            {
              Name: 'Uploaded',
              children:( ([...(Documents ? Documents : [])].map((e: {
                uploaded_file: string,
                desc: string
              }) => {
                return <DocumentItem link={Apiaddress + e['uploaded_file']} title={e['uploaded_file'].split("/")[e['uploaded_file'].split("/").length - 1]} description={e["desc"]} />
              })).length != 0) ?([...(Documents ? Documents : [])].map((e: {
                uploaded_file: string,
                desc: string
              }) => {
                return <DocumentItem link={Apiaddress + e['uploaded_file']} title={e['uploaded_file'].split("/")[e['uploaded_file'].split("/").length - 1]} description={e["desc"]} />
              })) :[<div> 
                  No Documnets Available 
                              </div>]
            },
          ]} />
      </Box>
      <Drawer anchor="right" open={CreateDocumnetModalState}>
        <Box

          sx={{
            width: "420px",
            height: "100%",
            marginRight: "25px",
            padding: "10px",
          }}
        >

          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100%"
            }}
          >

            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                width: "100%"
              }}>

              <Box
                sx={{
                  backgroundColor: "#FFAF2A",
                  width: "95%",
                  height: "60px",
                  borderRadius: "4px",
                  color: "white",
                  display: "flex",
                  alignItems: "center",
                  paddingLeft: "20px"
                }}
              >
                Add Document

              </Box>

              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",

                }}
              >
                <table cellPadding={20}>
                  <tr>
                    <td>Choose Category</td>
                    <td><TextField onChange={(e) => {
                      setCategotry(e.target.value)
                    }}></TextField></td>
                  </tr>
                  <tr>
                    <td>Attach</td>
                    <td><Button

                      onClick={() => {
                        inputFile?.current?.click();

                      }}
                      variant="contained"
                      className="univercel_btn_class"
                      sx={{ color: "#FFFFFF", padding: "6px 12px 6px 12px", height: "auto", margin: "10px" }}
                      startIcon={<UploadIcon />}
                    >
                      Upload
                      <input type='file' onInput={(e) => {
                        // @ts-ignore
                        console.log(e.currentTarget.files[0])
                        // @ts-ignore
                        setfile(e.currentTarget.files[0])
                      }} id='file' ref={inputFile} style={{ display: 'none' }} />
                    </Button></td>
                  </tr>
                  <tr>
                    <td>Notes </td>
                    <td><textarea onChange={(e) => {
                      setNotes(e.target.value)
                    }}></textarea></td>
                  </tr>
                  <tr>
                    <th colSpan={2}>

                      <Box

                        sx={{
                          display: 'flex',
                          flexDirection: "row",
                          justifyContent: "center",
                          alignItems: "center"
                        }}>
                        <Button
                          onClick={async () => {
                            console.log(file);

                            const uploaded = await bc.server.documents.createDocument(
                              {
                                file: file,
                                category: Category,
                                notes: notes
                              }
                            );
                            console.log(uploaded);
                            setDocuments(uploaded.documents);
                            setCreateDocumnetModalState(false);
                          }}
                          className="univercel_btn_class"
                          sx={{ color: "#FFFFFF", padding: "6px 20px 6px 20px", height: "auto", margin: "10px" }}

                        >
                          Upload
                        </Button>
                        <Button onClick={() => {

                          setCreateDocumnetModalState(false)
                        }} variant="text">Cancel</Button>

                      </Box>
                    </th>
                  </tr>
                </table>
              </Box>


            </Box>
          </Box>

        </Box>


      </Drawer>
    </>
  )
}
