import { Box, Button, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import DoneIcon from "@mui/icons-material/Done";
import { useNavigate } from "react-router-dom";

export const CompleteBuilderSignup = ({ stepsno, setStepsno }) => {
  const navigate = useNavigate();
  return (
    <>
      <Stack sx={{ mb: "15%" }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            gap: "10px",
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              width: "100px",
              height: "100px",
              borderRadius: "50%",
              margin: "20px",
              backgroundColor: "#FFA946",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <DoneIcon sx={{ color: "#fff", width: "60%", height: "60%" }} />
          </Box>
          <Typography
            sx={{
              fontWeight: "700",
              fontSize: "24px",
              lineHeight: "35px",
              fontFamily: "Product Sans",
            }}
          >
            Congrats You Completed Your Profile
          </Typography>

          {/* <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "45%",
            }}
          >
            <Typography
              sx={{
                textAlign: "center",
                fontFamily: "Product Sans",
                fontSize: "18px",
              }}
            >
              Lorem ipsum dolor sit amet consectetur adipisc.Lorem ipsum dolor
              sit amet consectetur adipisc.Lorem ipsum dolor sit amet
              consectetur adipisc.
            </Typography>
          </Box> */}

          <Button
            className="univercel_btn_class"
            sx={{
              width: "150px",
              margin: "10px",
              textTransform: "none",
            }}
            onClick={() => {
              navigate("/login");
            }}
          >
            Lets Get Started
          </Button>
        </Box>
      </Stack>
    </>
  );
};
