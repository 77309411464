import axios from "axios";
import { Apiaddress } from "../../utils/consts";

export const listOrganizations = async () => {
  const token = localStorage.getItem("crm_token");
  const re = await axios.get(Apiaddress + "/api/orglistusingtoken/", {
    headers: {
      Authorization: "JWT " + token,
    },
  });
  console.log(re.data);

  return re.data;
};
