import React from 'react'
import TabBar from '../Components/tabBar/TabBar'
import LDmainPage from './LeadsDirectory/LDmainPage'

const View = (props:any) => {
  return (
    <LDmainPage/>
    )
}

export default View