import {
  Button,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Box } from "@mui/system";
import { GridCloseIcon } from "@mui/x-data-grid";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { AuthService } from "../../../../../../context/buyer/services/auth";
import { BlueButton } from "../../../../../../utils/common/BlueButton";
import { api } from "../../../../../../utils/consts";
import { BasicLayout } from "../../basic_layout";
import { GetSection } from "../../RequirementFormTemplate";
import { GetItemInput } from "../input/InputReduser";
import { GetItem } from "../view/ViewReduser";

const FormBuilderModal = ({
  open,
  setExtraSections,
  ExtraSections,
  Selected,
  setopen,
  setSelected,
}: {
  open: {
    open: boolean;
    reason: string;
  };
  ExtraSections: any;
  setExtraSections: any;
  Selected: string;
  setopen: any;
  setSelected: any;
}) => {
  const nav = useNavigate();
  const [FieldN, setFieldN] = useState("");
  const [SectionData, setSectionData] = useState<{
    title: string;
    formFields: any[];
  }>({
    title: FieldN,
    formFields: [],
  });

  return (
    <Modal
      open={open.open}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        className="custom-scrollbar"
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "700px",
          bgcolor: "white",
          border: "2px solid white",
          borderRadius: "10px",
          boxShadow: 24,
          pt: 3,
          px: 3,
          pb: 1.5,
          textAlign: "center",
          height: "80vh",
          overflowY: "scroll",
        }}
      >
        <Box sx={{ textAlign: "right" }}>
          <GridCloseIcon
            sx={{ color: "gray", cursor: "pointer" }}
            onClick={(e) => {
              setopen({ ...open, open: !open.open });
            }}
          />
        </Box>
        {open.reason === "save" && (
          <Typography
            sx={{
              fontSize: "18px",
              fontWeight: "600",
              marginBottom: "30px",
              marginTop: "-20px",
              color: "black",
            }}
          >
            Template Name
          </Typography>
        )}

        {open.reason === "save" && (
          <TextField
            onChange={(e: any) => {
              setFieldN(e.target.value);
            }}
          ></TextField>
        )}
        {open.reason === "add" && (
          <Box>
            <Typography
              sx={{
                fontSize: "18px",
                fontWeight: "600",
                marginBottom: "30px",
                marginTop: "-20px",
                color: "black",
              }}
            >
              Add Section
            </Typography>
            <Box
              style={{
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
              }}
            >
              <Box
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "row",
                }}
              >
                Section Title
                <TextField
                  sx={{ margin: "10px" }}
                  label={"Title"}
                  onChange={(e) => {
                    setFieldN(e.target.value);
                  }}
                ></TextField>
              </Box>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <Select
                  value={Selected}
                  onChange={(e: SelectChangeEvent<string>) => {
                    setSelected(e.target.value);
                    // console.log("This is selected", Selected);
                  }}
                  displayEmpty
                  fullWidth
                  inputProps={{ "aria-label": "Without label" }}
                  renderValue={
                    Selected !== ""
                      ? undefined
                      : () => (
                          <Typography sx={{ color: "#aaa" }}>
                            Types Of Field
                          </Typography>
                        )
                  }
                  sx={{ color: "#aaa", textTransform: "capitalize" }}
                >
                  {[
                    "normal",
                    "withtrailing",
                    "radio",
                    "check",
                    "doubletrailing",
                  ].map((e) => {
                    return (
                      <MenuItem
                        value={e}
                        sx={{ color: "#aaa", textTransform: "capitalize" }}
                      >
                        {e}
                      </MenuItem>
                    );
                  })}
                </Select>
              </div>
            </Box>

            <Box
              sx={{
                marginTop: "10px",
                color: "#aaa",
                textTransform: "capitalize",
              }}
            >
              {Selected}
            </Box>

            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {Selected != "" &&
                GetItemInput({
                  ...getPreviewData(Selected),
                  setData: (e: any) => {
                    setSelected("");
                    setSectionData({
                      ...SectionData,
                      formFields: [...SectionData.formFields, e],
                    });
                  },
                  deleteSelf: () => {},
                })}
            </Box>
            <Box
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              {SectionData.formFields.map((e) => {
                return GetItem(e);
              })}
            </Box>
          </Box>
        )}

        <Box
          sx={{
            textAlign: "center",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Button
            onClick={async () => {
              if (FieldN !== "" && open.reason == "save") {
                const x = new AuthService();
                await x.makeAuthenticatedPostRequest(
                  api("/api/leads/requirementtemplate/"),
                  {
                    JsonBody: {
                      name: FieldN,
                      basic_details: BasicLayout,
                      other_details: ExtraSections,
                      channelPartner: x.getCPID(),
                    },
                  }
                );
                nav("/channelpartner/requirements-form");
              } else if (FieldN !== "" && open.reason == "add") {
                setExtraSections([
                  ...ExtraSections,
                  { ...SectionData, title: FieldN },
                ]);
                setSectionData({ title: FieldN, formFields: [] });
                setopen({ open: false, reason: "" });
              }
            }}
            variant="contained"
            color="primary"
            sx={{
              height: "40px",
              background: "linear-gradient(90deg, #0F5DAE 0%, #009DCF 100.33%)",
              boxShadow: "0px 13.7981px 22.5788px rgba(50, 174, 137, 0.25)",
              textTransform: "none",
              borderRadius: "4px",
              width: "150px",
              fontSize: "15px",
              margin: "20px 0px 10px 0px",
            }}
          >
            Save Section
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};
export const getPreviewData = (type: string) => {
  for (let i = 0; i < BasicLayout.formFields.length; i++) {
    // console.log(
    //   "asdasdasdasdasdasdasdasdasd",
    //   BasicLayout.formFields[i].type == type
    // );
    if (BasicLayout.formFields[i].type == type) {
      // console.log("Preview", BasicLayout.formFields[i]);
      return BasicLayout.formFields[i];
    }
  }
};
export default FormBuilderModal;
