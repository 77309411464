import { Box, Container, Stack, Typography } from "@mui/material";
import CircleCheckIcon from "../../Imgs/circle-check-icon.svg";
import TailorMade from "../../Imgs/tailor-made.svg";

const BuilderLandingTailorMade = () => {
  return (
    <Box sx={{ py: "40px", overflowX: "hidden" }}>
      <Container>
        <Box
          sx={{
            padding: "20px",
            display: "flex",
            alignItems: "center",
            flexDirection: { xs: "column", md: "row" },
            gap: { xs: "10px", md: "30px" },
            background: "#FFFFFF",
            boxShadow:
              "0px 8px 22px -6px rgba(24, 39, 75, 0.12), 0px 14px 64px -4px rgba(24, 39, 75, 0.12)",
            borderRadius: "20px",
          }}
        >
          <Box
            data-aos="zoom-in"
            data-aos-offset="300"
            sx={{
              width: { xs: "100%", md: "50%" },
              mt: { xs: "0px", md: "-100px" },
            }}
          >
            <img style={{ width: "100%" }} src={TailorMade} alt="" />
          </Box>
          <Stack spacing={2} sx={{ width: { xs: "100%", md: "50%" } }}>
            <Typography
              data-aos="fade-left"
              sx={{
                color: "#272D4E",
                fontSize: { xs: "28px", md2: "32px", lg: "38px" },
                fontWeight: 700,
                fontFamily: "Circular Std",
                lineHeight: "1.2",
              }}
            >
              Tailor-made features & processes designed by experts
            </Typography>
            <Stack spacing={2}>
              <Box sx={{ display: "flex", gap: "10px" }}>
                <Box sx={{ lineHeight: "10px", fontSize: "30px" }}>
                  {/* <img src={CircleCheckIcon} alt="" /> */}
                  &#x2022;
                </Box>
                <Typography
                  sx={{
                    fontSize: "16px",
                    color: "#343434",
                    fontFamily: "Product Sans",
                    lineHeight: "1.3",
                  }}
                >
                  Easily{" "}
                  <span style={{ fontWeight: 600 }}>
                    manage all your workflows
                  </span>{" "}
                  from one place – bookings, sales, payments, queries,
                  documentation, legal formalities, administrative tasks, and
                  more.
                </Typography>
              </Box>
              <Box sx={{ display: "flex", gap: "10px" }}>
                <Box sx={{ lineHeight: "10px", fontSize: "30px" }}>
                  {/* <img src={CircleCheckIcon} alt="" /> */}
                  &#x2022;
                </Box>
                <Typography
                  sx={{
                    fontSize: "16px",
                    color: "#343434",
                    fontFamily: "Product Sans",
                    lineHeight: "1.3",
                  }}
                >
                  Bookings, sales, payments, queries, documentation, legal
                  formalities, administrative tasks, and more
                </Typography>
              </Box>
              <Box sx={{ display: "flex", gap: "10px" }}>
                <Box sx={{ lineHeight: "10px", fontSize: "30px" }}>
                  {/* <img src={CircleCheckIcon} alt="" /> */}
                  &#x2022;
                </Box>
                <Typography
                  sx={{
                    fontSize: "16px",
                    color: "#343434",
                    fontFamily: "Product Sans",
                    lineHeight: "1.3",
                  }}
                >
                  Keep up with industry standards with well-defined and{" "}
                  <span style={{ fontWeight: 600 }}>
                    compliant processes designed by industry experts
                  </span>{" "}
                  and experienced professionals.
                </Typography>
              </Box>
            </Stack>
          </Stack>
        </Box>
      </Container>
    </Box>
  );
};

export default BuilderLandingTailorMade;
