import { Box, Button } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { AuthService } from '../../../../context/buyer/services/auth'
import { net } from '../../../../utils/consts'
import SideBar from '../../Components/sideBar/SideBar'
import { GetSection } from '../../Requirements/LeadsDirectory/RequirementFormTemp'
// import { GetSection } from '../../Requirements/LeadsDirectory/RequirementFormTemplate';

function ViewForms() {
  const nav = useNavigate();

  const par = useParams();
  const requirementFormId = par.id;
  const [RequirementData, setRequirementData] = React.useState();
  const Auth = new AuthService();
  const [loading, setloading] = useState(false);

  const { state } = useLocation();
  const { replyed } = state;

  const getRequirementForm = async () => {

    try {
      setloading(true);
      const res = await (await Auth.makeUnAuthenticatedGetRequest(net("leads/requirement/?requirement_id=" + requirementFormId))).json()
      console.log("LODE", "E", res)
      setRequirementData(res.requirement);
      setloading(false);
    }
    catch (err) {
      console.log(err.message);
    }
  }

  console.log(RequirementData);

  useEffect(() => {
    getRequirementForm();
  }, []);

  return (
    <SideBar active={"Leads"}>
      {RequirementData &&
        <Box
          className="hidescrollbar"
          sx={{
            marginTop: "50px",
            width: "98%",
            px: "30px",
            pb: "30px",
            background: "white",
            borderRadius: "20px",
            minHeight: "75vh",
            overflowY: "scroll",
            color: "black",
            boxSizing: "border-box",
            "&:hover": {
              border: "1px solid #2979F2",
              boxShadow: "-6px 18px 15px rgba(0, 0, 0, 0.1)",
            },
          }}>
          {<GetSection {...RequirementData?.basic_details} />}
          {
            RequirementData?.other_details?.map((e, index) => {
              console.log(e);
              return (
                <GetSection {...e}></GetSection>
              )
            })
          }
        </Box>
      }
      <Button className="univercel_btn_class"
        sx={{
          color: "#FFFFFF",
          textTransform: "none",
          width: "120px",
          my: "50px",
        }}
        onClick={() => { nav(-1) }}>
        Back
      </Button>
    </SideBar>
  );
};


export default ViewForms