
export const UserRole = (arr,codename)=>{
      // console.log(arr,codename);
      if(Array.isArray(arr)){
            for(let i=0; i<arr.length; i++){
                let temp = arr[i]
                if(temp.codename==codename){
                      return true;
                }
              }
            }
            return false;
      }