import React, { useContext, useEffect, useState } from 'react';
import { Box, Button, Stack, TextField, Typography } from "@mui/material";
import DoneIcon from '@mui/icons-material/Done';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import Pagination from '@mui/material/Pagination';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';


import { Navbar } from "../DashboardComponents/Navbar"
import { Sidebar } from "../DashboardComponents/Sidebar"
import { Link, useNavigate } from "react-router-dom";
import { Apiaddress } from '../../../../utils/consts';

// import currentProjectState from "../../../../context/createproject/context";
import currentProjectState from "../../../../context/createproject/context";
import axios from 'axios';
import { ConfirmProject_steps } from './ConfirmProject_steps';
import { BackeToOverviewBtn } from './BackeToOverviewBtn';
import { MainSidebar } from '../DashboardComponents/MainSidebar';

function ConfirmProject2_Building() {

  const navigate = useNavigate();

  const [buildingnum,setbuildingnum]=useState(1);

  const [buildingdata,setbuildingdata]=useState([]);

  ////get data of buliding templates 
  const cpContext = useContext(currentProjectState);
  const projectID = cpContext?.createProjectState?.project?.id;
  useEffect(()=>{
    const buildingurl = Apiaddress+"/api/projects/buildingtemplatesbyproject/?project_id="+projectID

    axios.get(buildingurl)
    .then((res)=>{
          const data = res?.data
          console.log(data)
          if(data.building_template!=="building_template not found")
         setbuildingdata(data.building_template)
        
             
       })
       .catch((err)=>{
         console.log(err)
       })

  },[])
  return (
    <>

<Stack direction="row" sx={{width:"100%",height:"auto" }}>
      {/* 1st stack */}
      <Stack sx={{minHeight:"100vh",width:"20%",boxSizing:"border-box",}}> {/*for project directory 20% and for sales dashboard 225*/}
        <MainSidebar active={"Project Directory"}/>
      </Stack>

     {/* 2nd stack */}
     <Stack direction="column" sx={{ backgroundColor:"#f9f9f9",width:"80%", padding:"25px",boxSizing:"border-box",}}  >  {/*  width for main sidebar 78px and for 80px*/}
      <Navbar/>
      


      <Typography sx={{marginTop:"20px", fontSize:"28px", fontWeight:"600", marginLeft:"10px", marginBottom:"50px"}}>
                   Project Overview 
           </Typography>
     {/* actual content  */}
    <Stack sx={{backgroundColor:"#f9f9f9"}}>  {/* mt:"28px", */}

    <Stack direction="column"  sx={{  padding:"20px", backgroundColor:"#fff", marginRight:"0px"}}>

{/* confirm project 2 content */}

<ConfirmProject_steps/>


<Box sx={{}}>
 <Typography sx={{fontSize:"18px", fontWeight:"500",lineHeight:"27px"}}>Project Home</Typography>
 <Stack direction="row" sx={{margin:"20px 0px 0px 0px",display:"flex",alignItems:"center"}} >
   <Typography sx={{fontSize:"18px", fontWeight:"500"}}>Building</Typography>
   <AddIcon sx={{color:"#A2A3A6",fontSize:"18px",marginLeft:"10px",cursor:"pointer"}} onClick={()=>{
     navigate("/confirm-project-building-add");
   }}/>
 </Stack>

   <Stack spacing={2} sx={{margin:"20px 0px 0px 0px"}}>
     <Pagination count={buildingdata.length} showFirstButton showLastButton onChange={(e,page)=>{setbuildingnum(page)}} />
   </Stack>

 <Stack direction="row" sx={{margin:"30px 0px 0px 0px"}} >
   <Typography sx={{fontSize:"18px", fontWeight:"500",color:"#A2A3A6"}}>Building {buildingnum}</Typography>
  
   <Link to="/confirm-project-building-details" state={buildingdata[buildingnum-1]}>
   <EditIcon sx={{color:"#A2A3A6",marginLeft:"10px",cursor:"pointer"}} />
   </Link>

   {/* onClick={()=>{
     // navigate("/confirm-project-building-details");
   }} */}

 </Stack>
 <Stack direction="row" sx={{display:"flex",alignItems:"center",margin:"20px 0px 0px 0px"}} >
   <Typography sx={{fontSize:"18px", fontWeight:"500",color:"#A2A3A6"}}>Building Name</Typography>
   <TextField
      sx={{
          width:"188px",
          marginLeft:"10px"
         }} value={buildingdata[buildingnum-1]?.name} disabled/>
 </Stack>
 <Box sx={{margin:"60px 0px 60px 0px"}}>
 <Link to={buildingdata[buildingnum-1]?.has_wings==false?"/confirm-project-flores":"/confirm-project-wings"} state={buildingdata[buildingnum-1]}>
     <Button 
     className="univercel_btn_class"
     variant="contained"
     sx={{padding:"16px 50px 16px 50px", color:"#fff",fontSize:"18px", fontWeight:"700"}}>
         Details 
     </Button>
   </Link>
 </Box>
</Box>


</Stack>
<BackeToOverviewBtn/>

    </Stack>
      </Stack>
</Stack>




    </>
  )
}

export default ConfirmProject2_Building