import { Box, Button } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { AuthService } from "../../../context/buyer/services/auth";
import { net } from "../../../utils/consts";
import SideBar from "../Components/sideBar/SideBar";
// import { GetSection } from "./LeadsDirectory/RequirementFormTemplate";
import { GetSection } from "./LeadsDirectory/RequirementFormTemp";

const ViewRequirement = () => {
  const nav = useNavigate();

  const par = useParams();
  const requirementFormId = par.id;
  const [RequirementData, setRequirementData] = React.useState();
  const Auth = new AuthService();
  const [loading, setloading] = useState(false);

  const { state } = useLocation();
  const { replyed } = state;

  // // console.log({ RequirementData });

  useEffect(() => {
    const fun = async () => {
      setloading(true);
      const res = await (
        await Auth.makeUnAuthenticatedGetRequest(
          net("leads/requirement/?requirement_id=" + requirementFormId)
        )
      ).json();
      // // console.log(res.requirement);
      if (replyed == true) {
        setRequirementData(res.requirement);
      } else {
        // // console.log("LODE", "E", res)
        let Section = {};
        Section.formFields = res.requirement.basic_details.formFields;
        res.requirement.basic_details.Section = Section;
        setRequirementData(res.requirement);
      }
      setloading(false);
    };
    fun();
  }, []);

  return (
    <SideBar active={"Requirements"}>
      {RequirementData && (
        <Box
          className="hidescrollbar"
          sx={{
            marginTop: "50px",
            width: "98%",
            px: "30px",
            pb: "30px",
            background: "white",
            borderRadius: "10px",
            minHeight: "75vh",
            overflowY: "scroll",
            color: "black",
            boxSizing: "border-box",
            "&:hover": {
              border: "1px solid #2979F2",
              boxShadow: "-6px 18px 15px rgba(0, 0, 0, 0.1)",
            },
          }}
        >
          {<GetSection {...RequirementData?.basic_details} />}
          {RequirementData?.other_details?.map((e, index) => {
            if (RequirementData?.replyed == true) {
              // // console.log("Yes Hai");
              return <GetSection {...e}></GetSection>;
            } else {
              // // console.log("Yes");
              return <GetSection Section={e}></GetSection>;
            }
          })}
          {/* {
            RequirementData?.other_details?.map((e ,index )=>{
            return   <GetSection Section={e} setSuperState={(e)=>{
                setOtherLayout(UpdateAtIndex(OtherLayout,index,e));
            }}></GetSection>    
            
        })
      } */}
        </Box>
      )}
      <Button
        className="univercel_btn_class"
        sx={{
          color: "#FFFFFF",
          textTransform: "none",
          width: "120px",
          my: "50px",
        }}
        onClick={() => {
          nav(-1);
        }}
      >
        Back
      </Button>
    </SideBar>
  );
};

{
  /* useEffect(() => {
    const fun = async () => {
      const res = await (
        await Auth.makeUnAuthenticatedGetRequest(
          net("leads/requirement/?requirement_id=" + requirementFormId)
        )
      ).json();
      setRequirementData(res.requirement);
      // console.log("LODE", "E", res);
    };

    fun();
  }, []); */
}

{
  /* return (
    <SideBar>
      {RequirementData && (
        <>
          <GetSection {...RequirementData?.basic_details} />
          {RequirementData?.other_details?.map((e, index) => {
            return (
              <GetSection
                Section={e}
                setSuperState={(e) => {
                  // setOtherLayout(UpdateAtIndex(OtherLayout,index,e));
                }}
              ></GetSection>
            );
          })}
        </>
      )}
    </SideBar>
  ); */
}

export default ViewRequirement;
