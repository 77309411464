import { Box, List, ListItem, Typography } from "@mui/material";
import "./styles/PageSix.css";
import React, {useState } from 'react';
import { Stack } from "@mui/system";
import classes from "./styles/PageOne.module.css";

const PdfOnePageEight = () => {

  const [pageData8,setPageData8]=useState({
    sign_first:"",
    sign_second:"",
    sign_third:"",
  })

  // console.log(pageData7);

  let name,value;
  const handleChange=(e)=>{
    name=e.target.name;
    value=e.target.value;
    console.log(name," ",value);
    setPageData8({...pageData8,[name]:value});
  }

  return (
    <Box>
      <Box sx={{ mb: "40px" }}>
        <Box sx={{ display: "flex", gap: "10px" }}>
          <Box>
            <Typography sx={{ fontSize: "10px" }}>6.</Typography>
          </Box>
          <Stack>
            <Typography sx={{ fontSize: "10px" }}>
              TITLE, SCHEME OF DEVELOPMENT, PLANS AND SPECIFICATIONS
            </Typography>
            <Box sx={{ display: "flex", gap: "10px" }}>
              <Typography sx={{ fontSize: "10px" }}>6.1</Typography>
              <Typography sx={{ fontSize: "10px" }}>
                The Applicant(s) confim(s) that the Developer has given
                inspection to the Applicant(s) and the Applicant(s) has/have
                completely seen/ inspected all the documents of title, Title
                Report issued by the lawyer relating to the Project Property
                together with the sanctioned plan and the proposed plan of the
                Project, sanctioned building plans and proposed building plans
                of the building(s) to be constructed in the Project, including
                the subject building in which the Premises is to be construted,
                designs and specifications prepared by the Architect of the
                Developer and of such other documents as are specified under
                RERA Act. The Applicant(s) also confirm to have visited the
                webpage of the Project on the website of the Regultory Authority
                @ https://maharerait.mahaonline.gov.in/ and has read and
                understood the documents in relation to the Project uploaded
                therein including title report, title documents, additional
                disclosures, sanctioned plans and layout plans, details of
                amenities and facilities, present scheme of development and
                proposed scheme of development of the Project etc. (all the
                aforesaid being hereinafter collectively referred to as “Project
                Documents”).
              </Typography>
            </Box>
            <Box sx={{ display: "flex", gap: "10px" }}>
              <Typography sx={{ fontSize: "10px" }}>6.2</Typography>
              <Typography sx={{ fontSize: "10px" }}>
                The Applicant(s) confirm(s) that the Applicant(s) has/ have
                personally visited and inspected the Project Property and
                has/have fully famillarized with the scheme of developemnt of
                the Project on the Project Property including the subject
                building.
              </Typography>
            </Box>
            <Box sx={{ display: "flex", gap: "10px" }}>
              <Typography sx={{ fontSize: "10px" }}>6.3</Typography>
              <Typography sx={{ fontSize: "10px" }}>
                The Applicant(s) are/is aware that the Developer has procured
                certain approvals and sanctions to the plans and specifications
                from the concerned government authorities i.e., MIDC, any other
                approvals from other competent authorities for development of
                the said Project, as has been disclosed under on the protal
                /website of the Regulatory Authority, presently being
                https://maharera.mahaonline.gov.in/ and shall obtain the balance
                approvals from the authorities from time to time. The Developer
                shall construct the buildings in the Project in accordance with
                the approvals and/or plans, designs and specifications and
                amendments thereto as approved by the local authority i.e., MIDC
                and the development permission granted by the Corporation vide
                Commencement Certificate issued by the Authority under Outward
                reference No. OUTWARD/MIDC/PATA1/E&MD, SDPTLGNGIV/ C-75113/2020,
                and Plan Approval No C-75113/2020 dated 27/10/2020. The
                Developer has currently obtained approvals only for the
                commercial component of the project, however, the Developer is
                expected to receive approvals for the residential component as
                well for the Said Unit. The Applicant(s) is aware that only
                contingent interest would be created on the Said Unit as
                approvals for the same have not been obtained yet. However, if
                the specified uncertain event, i.e. if Commencement
                Certificate/construction permission for the Said Unit is
                received by the Developer in future, then the same shall be
                enforeceable and the Developer shall, in accordance with the
                terms of this Application, shall enter into registered Agreement
                for Sale with the Applicant for transfer of right, title and
                interest in the Said Unit in favour of the Applicant, however,
                due to unforseen circumstances, if Commencement
                Certificate/Approvals for the construction of Said Unit is not
                obtained/is deemed as impossible, then the Developer shall
                refund the full amount received from the Applicant(s) without
                interest. The Applicant has ackowledged and agreed to this
                arrangement that this transaction is contingent on obtaining
                Commecement Certificate/further approvals and shall only take
                place if the said specified uncertain future takes place. The
                Applicant(s) is aware that, while some of the approvals for
                development of the Project have been obtained, the Owners/
                Developer have applied for and/or are in the process of applying
                for further approvals from the concerned statutory authorities.
                The Developer has informed the Applicant(s) and the Applicant(s)
                is aware that the final sanction(s) and approval (s) are yet to
                be obtained by the Owner/ Developer. The allotment of the
                Premises to the Applicant(s) shall be subject to such statutory
                approvals, permissions and amendment to various plans being
                granted by local authority i.e., MIDC, etc. in due course of
                development of the Project / Premises. The Applicant(s) agrees
                to abide by the terms and conditions of all such approvals. The
                Applicant(s) has/have applied for the allotment of the Premises
                without any objection or demur and agrees not to arise and
                waives his/her/their/its rights to raise any objection in that
                regard. The Developer, in view of continuance improvement of the
                product, may amend layout plans/floor plans of the project and
                is also entitled to make changes to the layout plans by
                constructing additional floors or additional structures on the
                building to be constructed on the project land. The Developer is
                hereby permitted through this Application by the Applicant(s)
                for carrying out amendment and revision to the layout of the
                plans as and when required and this shall be considered as
                informed consent by the Applicant(s) to the Developer, as
                contemplated under RERA Act and Rules made thereunder. Thus the
                Applicant(s) are fully aware of the Developer’s right of making
                amendment and revision to the layout plan and of making
                additions and alterations in the structure of the building. The
                Promoter even though shall have right to make amendment and
                revision in the layout plan and/or addition and alterations in
                the structure of the building as stated hereinabove, Promoter
                while exercising the said right shall not cause any alteration
                and/or reduction in the agreed area of the said Unit of the
                Applicant(s) except that on account of working tolerance limits
                of the proposed constructions of the buildings. The Applicant(s)
                is/are fully aware and informed that the Developer shall be
                developing the Project as per DCPR Rules or any other or under a
                combination of regulations as may be permitted by the MIDC or
                concerned authorities and/or as the Developer may deem fit and
                proper and as disclosed to the Applicant (s) as has been
                disclosed under RERA on the government portal/website, presently
                being https://maharera.mahaonline.gov.in/.
              </Typography>
            </Box>
          </Stack>
        </Box>
      </Box>

      <Box
        sx={{
          display: "flex",
          gap: "40px",
          justifyContent: "center",
          position: "absolute",
          bottom: "30px",
        }}
      >
        <Box className={classes.input}>
          <Box>
            <input
              style={{ textAlign: "center" }}
              value={pageData8.sign_first}
              type="text"
              name="sign_first"
              onChange={handleChange}
            />
            <Typography sx={{ textAlign: "center", mt: "10px" }}>
              Signature of First Applicant
            </Typography>
          </Box>
        </Box>
        <Box className={classes.input}>
          <Box sx={{ display: "inline-block" }}>
            <input
              style={{ margin: "0px", textAlign: "center" }}
              value={pageData8.sign_second}
              type="text"
              name="sign_second"
              onChange={handleChange}
            />
            <Typography sx={{ textAlign: "center", mt: "10px" }}>
              Signature of Second Applicant
            </Typography>
          </Box>
        </Box>
        <Box className={classes.input}>
          <Box sx={{ display: "inline-block" }}>
            <input
              style={{ margin: "0px", textAlign: "center" }}
              value={pageData8.sign_third}
              type="text"
              name="sign_third"
              onChange={handleChange}
            />
            <Typography sx={{ textAlign: "center", mt: "10px" }}>
              Signature of Third Applicant
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default PdfOnePageEight;
