import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react'
import buyerContext from '../../../../context/buyer/context';
import { BuyerService } from '../../../../context/buyer/services/buyerServices';
import { ProjectResponse } from '../../../../context/buyer/services/projects/types/types';
import { Apiaddress } from '../../../../utils/consts';

const ProjectDetails = ({ projectid }: { projectid: number }) => {
    // const previousState = JSON.parse(localStorage.getItem('BuilderDashboard'))

    var def: ProjectResponse.RootObject = { projects: [] };

    const bc: BuyerService = useContext(buyerContext);
    useEffect(() => {
        const x = async () => {
            setProject(await bc.server.projects.getProjectBuyId(projectid))
            console.log("called", Project)
            
        }
        x();
    }, [projectid])


    const [Project, setProject] = useState(def);
    const getFirstProject = () => {
        
        if (Project.projects?Project.projects?.length == 0:true) {
            return {
                name: "loading",
                city: "loading",
                banner_image: null,
                progress: "loading",
                progress_status: "loading",
            }
        } else {
            return Project.projects[0]
        }
    }
    return (
        <div
            style={{
                backgroundColor: "white",
                height: 300,
            }}
        >
            <table cellPadding={"10px"} border={0} width={"100%"}>
                <tr>
                    <td>
                        <div style={{
                            width: "100%",
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                        }}>
                            <div style={{
                                display: "flex",
                                flexDirection: "column"
                            }}>
                                <div>
                                    {getFirstProject().name}
                                </div>
                                {getFirstProject().city}

                            </div>
                            {<img height={100} width={100} src={Apiaddress + getFirstProject().banner_image !== null ? "https://images.unsplash.com/photo-1618505497364-f97e23c8b70a?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=386&q=80" : Apiaddress + getFirstProject().banner_image} alt="" />}
                        </div>
                    </td>
                </tr>
                <tr>
                    <td>
                        <div>Project Details</div>
                    </td>

                </tr>
                <tr>
                    <td>
                        <div style={{ height: 30 }}></div>
                    </td>
                </tr>
                <tr>
                    <td>
                        <div>Status</div>
                        <div>{getFirstProject().progress}</div>
                        <div>{getFirstProject().progress_status}</div>
                    </td>
                </tr>
            </table>


        </div>
    )
}

export default ProjectDetails