import React from "react";
import SideBar from "../Components/sideBar/SideBar";
import LDmainPage from "./LeadsDirectory/LDmainPage";

const CPRequirements = () => {
  return (
    <SideBar active={"Requirements"} dontShowNav>
      <LDmainPage />
    </SideBar>
  );
};

export default CPRequirements;
