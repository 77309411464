import {
  Box,
  Container,
  Paper,
  Stack,
  styled,
  Typography,
} from "@mui/material";
import EasyToUseLowFriction from "../../Imgs/easy-to-use-low-friction.svg";
import OnboardLessThanDay from "../../Imgs/onboard-less-than-day.svg";
import TakeTheNext from "../../Imgs/take-the-next.png";
import NoSoftwareSetupCost from "../../Imgs/no-software-setup-cost.svg";

const CommonLandingTakeTheNextStepToElevate = () => {
  return (
    <Box sx={{ background: "#FFF", overflowX: "hidden" }}>
      <Container>
        <Typography
          data-aos="fade-down"
          sx={{
            color: "#272D4E",
            fontSize: { xs: "28px", md: "32px", lg: "38px" },
            fontWeight: 700,
            fontFamily: "Circular Std",
            textAlign: "center",
          }}
        >
          Take the NEXT step to elevate your Real Estate business now!
        </Typography>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            flexDirection: { xs: "column-reverse", md: "row" },
            gap: "50px",
            py: "30px",
          }}
        >
          <Stack spacing={4} sx={{ width: { xs: "100%", md: "50%" } }}>
            <Box
              sx={{
                background: "#FFFFFF",
                boxShadow: "0px 8px 12px rgba(5, 20, 65, 0.15)",
                padding: "15px 20px",
                borderTop: "4px solid #FFB232",
                display: "flex",
                gap: "10px",
              }}
              data-aos="fade-zoom-in"
              data-aos-easing="ease-in-back"
              data-aos-delay="200"
              data-aos-offset="0"
            >
              <Box>
                <img src={OnboardLessThanDay} alt="" />
              </Box>
              <Box>
                <Typography
                  sx={{
                    color: "#0085FF",
                    fontSize: { xs: "18px", md: "20px", lg: "24px" },
                    fontWeight: 700,
                    fontFamily: "Product Sans",
                  }}
                >
                  Onboard your projects in less than a day
                </Typography>
                <Typography
                  sx={{
                    mt: "10px",
                    color: "#343434",
                    fontSize: "18px",
                    fontFamily: "Product Sans",
                  }}
                >
                  Our powerful and efficient user experience design lets you
                  quickly set up your projects and get started with your
                  workflows.
                </Typography>
              </Box>
            </Box>

            <Box
              sx={{
                background: "#FFFFFF",
                boxShadow: "0px 8px 12px rgba(5, 20, 65, 0.15)",
                padding: "15px 20px",
                borderTop: "4px solid #FF4267",
                display: "flex",
                gap: "10px",
              }}
              data-aos="fade-zoom-in"
              data-aos-easing="ease-in-back"
              data-aos-delay="500"
              data-aos-offset="0"
            >
              <Box>
                <img src={NoSoftwareSetupCost} alt="" />
              </Box>
              <Box>
                <Typography
                  sx={{
                    color: "#0085FF",
                    fontSize: { xs: "18px", md: "20px", lg: "24px" },
                    fontWeight: 700,
                    fontFamily: "Product Sans",
                  }}
                >
                  No software setup cost
                </Typography>
                <Typography
                  sx={{
                    mt: "10px",
                    color: "#343434",
                    fontSize: "18px",
                    fontFamily: "Product Sans",
                  }}
                >
                  You don’t have to pay anything to set up your EnvisionNext
                  account, onboard your projects, and start growing your
                  business.
                </Typography>
              </Box>
            </Box>
            <Box
              sx={{
                background: "#FFFFFF",
                boxShadow: "0px 8px 12px rgba(5, 20, 65, 0.15)",
                padding: "15px 20px",
                borderTop: "4px solid #00C29A",
                display: "flex",
                gap: "10px",
              }}
              data-aos="fade-zoom-in"
              data-aos-easing="ease-in-back"
              data-aos-delay="800"
              data-aos-offset="0"
            >
              <Box>
                <img src={EasyToUseLowFriction} alt="" />
              </Box>
              <Box>
                <Typography
                  sx={{
                    color: "#0085FF",
                    fontSize: { xs: "18px", md: "20px", lg: "24px" },
                    fontWeight: 700,
                    fontFamily: "Product Sans",
                  }}
                >
                  Easy to use, low friction
                </Typography>
                <Typography
                  sx={{
                    mt: "10px",
                    color: "#343434",
                    fontSize: "18px",
                    fontFamily: "Product Sans",
                  }}
                >
                  Our software is simple, collaborative, and efficient for you
                  and your team, so your business grows friction-free.
                </Typography>
              </Box>
            </Box>
          </Stack>
          <Box data-aos="fade-left" sx={{ width: { xs: "100%", md: "50%" } }}>
            <img style={{ width: "100%" }} src={TakeTheNext} alt="" />
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

export default CommonLandingTakeTheNextStepToElevate;
