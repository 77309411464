import {
  alertTitleClasses,
  Box,
  Button,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import { Stack } from "@mui/system";
import "./LoginUser.css";
import { Apiaddress } from "../../utils/consts";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useContext, useEffect, useState } from "react";
import userContext from "../../context/profile/context";
import Backdrop from "@mui/material/Backdrop";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import { SendMail } from "./SendMail";
import Lottie from "react-lottie";
import animationData from "../../Lotties/118485-city-skyline-building.json";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import Support from "../ChannelPartner/Components/sideBar/Support";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #fff",
  borderRadius: "10px",
  boxShadow: 24,
  p: 4,
};

export const LoginUser = () => {
  ////modal for forgot password;
  const [show, setShow] = useState(false);
  const [sendEmail, setSendEmail] = useState("");
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const [openSupport, setOpenSupport] = useState(false);
  const handleClose = () => {
    setSuccess(false);
    setOpen(false);
  };
  const [success, setSuccess] = useState(false);

  // const { mailsend, submit , ww,  formState: { error } } = useForm();

  const sendmail = (data) => {
    let url = Apiaddress + "/api/forgetpass/";
    // let url = Apiaddress+"/api/auth/forgot-password/";

    axios
      .post(url, { email: data.send_mail })
      .then((res) => {
        console.log("res", res.data);
        if (res.data.error == false) {
          setSuccess(true);
        }
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  const navigate = useNavigate();
  const UC = useContext(userContext);

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();
  const [err, setError] = useState(false);

  const onSubmitt = (data) => {
    setError(false);
    console.log(data);
    localStorage.setItem("user_roles", JSON.stringify({}));
    axios
      .post(Apiaddress + "/api/auth/login/", {
        email: data.email.toLowerCase(),
        password: data.password,
      })
      .then((res) => {
        console.log(res);
        if (!res.data.error) {
          localStorage.setItem("crm_token", res.data.token);
          console.log("logIn : ", res.data);
          UC.fetchNew();
          //navigate("/dashboard")

          ///for super admin checked first type of user
          cheUserType(res);
          getUserRoleType(res);
        }
      })
      .catch((err) => {
        console.log("logIn:", err.response.data.errors);
        if (err.response.data.error == true) {
          setError(true);
        }
      });
  };

  const cheUserType = async (res) => {
    let URL = Apiaddress + "/api/profile/";
    await axios
      .get(URL, {
        headers: {
          Authorization: "JWT " + res?.data?.token,
        },
      })
      .then(async (res) => {
        console.log("super admin data:=>", res.data);
        localStorage.setItem("crm_user", JSON.stringify(res.data.user_obj));
        localStorage.setItem("cpDetails", JSON.stringify(res.data.cpDetails));
        console.log("user", res.data.user_obj.role_type);
        const role = res.data.user_obj.role_type;
        if (role == "SUPERADMIN") {
          navigate("/super-admin-dashboard");
        } else if (role == "BUILDER") {
          navigate("/dashboard");
        } else if (role == "BUYER") {
          navigate("/buyer/dashboard");
        } else if (role == "CHANNELPARTNER") {
          navigate("/channelpartner/dashboard");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("crm_user"));
    let role;
    if (user?.role_type !== undefined) {
      role = user.role_type;
    }
    if (role == "SUPERADMIN") {
      navigate("/super-admin-dashboard");
    } else if (role == "BUILDER") {
      navigate("/dashboard");
    } else if (role == "BUYER") {
      navigate("/buyer/dashboard");
    } else if (role == "CHANNELPARTNER") {
      navigate("/channelpartner/dashboard");
    }
  }, []);

  ///user role type ;
  const getUserRoleType = async (res) => {
    let role_obj = {};
    const url = Apiaddress + `/api/role/listuserpermission/`;
    await axios
      .get(url, {
        headers: {
          Authorization: "JWT " + res?.data?.token,
        },
      })
      .then((res) => {
        console.log("user role type: ", res.data);
        let data = res.data.permissions;

        for (let ele of data) {
          if (role_obj[ele.content_type]) {
            role_obj[ele.content_type] = [...role_obj[ele.content_type], ele];
          } else {
            role_obj[ele.content_type] = [ele];
          }
        }
      })
      .catch((err) => {
        console.log("res: ", err);
      });
    localStorage.setItem("user_roles", JSON.stringify(role_obj));
    //  console.log("role data", role_obj);
    //  console.log("role data", role_obj["api settings"])  //to get data from obj use this method ;
  };

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
        }}
      >
        <Stack
          direction="column"
          alignItems="stretch"
          justifyContent="space-between"
          sx={{
            width: "40%",
            backgroundColor: "#F4F6FB",
            height: "100vh",
            textAlign: "left",
            padding: "24px 24px",
            boxSizing: "border-box",
          }}
        >
          <Stack
            direction="row"
            justifyContent="end"
            spacing={2}
            alignItems="center"
            sx={{ height: "10vh" }}  
          >
            <Typography sx={{ fontFamily: "Product Sans", fontWeight: 400 }}>
              Having trouble?{" "}
              <span style={{ color: "#0085FF", fontWeight: 400, cursor:"pointer"}} onClick={()=>{setOpenSupport(true)}}>
                Get Help
              </span>{" "}
            </Typography>
          </Stack>

          <Stack
            direction="row"
            justifyContent="center"
            spacing={2}
            alignItems="center"
            sx={{ height: "90vh" }}
          >
            <Box sx={{ padding: "10px", width: "75%" }}>
              <Typography
                sx={{
                  fontFamily: "Circular Std",
                  fontWeight: "600",
                  fontSize: "2vw",
                  lineHeight: "41px",
                }}
              >
                Login to EnvisionNext
              </Typography>
              <Typography
                sx={{
                  fontSize: "1vw",
                  color: "gray",
                  margin: "0px 0px 30px 0px",
                  fontFamily: "Product Sans",
                }}
              >
                Welcome back! Stay updated with all that’s <br /> happening on your
                dashboard.
              </Typography>

              <form onSubmit={handleSubmit(onSubmitt)}>
                <Typography
                  sx={{
                    fontWeight: "500",
                    fontSize: "18px",
                    color: errors.email?.message ? "#da6061" : "black",
                    fontFamily: "Product Sans",
                  }}
                >
                  Email
                </Typography>
                <TextField
                  sx={{
                    width: "100%",
                    margin: "10px 0px 20px 0px",
                    fontFamily: "Product Sans",
                  }}
                  type="email"
                  id="outlined-error-helper-text"
                  name="email"
                  fullWidth
                  {...register("email", { required: "Email is required." })}
                  error={Boolean(errors.email)}
                  helperText={errors.email?.message}
                />

                <Typography
                  sx={{
                    fontWeight: "500",
                    fontSize: "18px",
                    color: errors.password?.message ? "#da6061" : "black",
                    fontFamily: "Product Sans",
                  }}
                >
                  Password
                </Typography>
                <TextField
                  sx={{
                    width: "100%",
                    margin: "10px 0px 20px 0px",
                    fontFamily: "Product Sans",
                  }}
                  type={show ? "text" : "password"}
                  id="outlined-error-helper-text"
                  name="password"
                  fullWidth
                  {...register("password", {
                    required: "Password is required.",
                  })}
                  error={Boolean(errors.password)}
                  helperText={errors.password?.message}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment>
                        <IconButton onClick={() => setShow((p) => !p)}>
                          {show ? (
                            <VisibilityOff sx={{ color: "#99B2C6" }} />
                          ) : (
                            <Visibility sx={{ color: "#99B2C6" }} />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />

                {err && (
                  <Typography
                    sx={{
                      fontWeight: "500",
                      fontSize: "18px",
                      color: "#eb6270",
                      fontFamily: "Product Sans",
                    }}
                  >
                    Invalid credentials
                  </Typography>
                )}

                {/* <Typography sx={{fontWeight: "500",fontSize: "18px"}}>Password</Typography>
                <input type="email" className="input_login"/> */}

                <Stack direction="row" justifyContent="right">
                  <Typography
                    onClick={() => {
                      handleOpen();
                      // navigate("/Reset-password")
                    }}
                    sx={{
                      color: "#0085FF",
                      fontSize: "1vw",
                      fontFamily: "Product Sans",
                      cursor:"pointer"
                    }}
                  >
                    Forgot Password?
                  </Typography>
                </Stack>

                <Stack direction="row" sx={{ marginTop: "20px" }}>
                  <Button
                    type="submit"
                    sx={{
                      background:
                        "linear-gradient(97.85deg, #FFAF2A 0.95%, #FFC666 100%)",
                      fontWeight: 500,
                      fontSize: "16px",
                      boxShadow: "rgba(242, 178, 73, 0.59) 0px 9px 12px",
                      borderRadius: "6px",
                      color: "#06386B",
                      textTransform: "none",
                      padding: "10px 64px",
                      fontFamily: "Product Sans",
                      mt: "20px",
                      "&:hover": {
                        background:
                          "linear-gradient(97.85deg, #FFAF2A 0.95%, #FFC666 100%)",
                        boxShadow: "rgba(242, 178, 73, 0.59) 10px 12px 16px",
                      },
                    }}
                  >
                    Login
                  </Button>
                </Stack>
              </form>
            </Box>
          </Stack>
        </Stack>

        <Stack
          direction="column"
          alignItems="center"
          justifyContent="center"
          sx={{ width: "60%", height: "100vh" }}
        >
          {/* <h1>TBD</h1> */}
          <Lottie options={defaultOptions} height="100%" width="100%" />
        </Stack>
      </Box>

      {/* forgot password popup */}
      {/* <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <Box sx={style}>

            <Typography id="transition-modal-title" variant="h6" component="h2" sx={{color:success?"green":"black"}}>
              {success?"Check your Mail Inbox or Spam to set Password":" Enter your Registered Email ID"}
            </Typography>
            {!success && 
            <form onSubmit={handleSubmit(sendmail)}>
            <TextField 
                 sx={{
                    width:"100%",
                    margin:"10px 0px 20px 0px"
                 }}
                 type="email"
                 id="outlined-error-helper-text"
                 name="send_mail"
                 fullWidth
                 {...register("send_mail", {required:"Email is required."})}
                    error={Boolean(errors.send_mail)}
                    helperText={errors.send_mail?.message}
                />

                {err && 
                <Typography sx={{fontWeight: "500",fontSize: "18px", color:"#eb6270"}}>Invalid credentials</Typography>
                }

                <Button 
                type="submit"
                sx={{width:"100%"}} className="univercel_btn_class">
                    Submit
                </Button>
            </form>
            }
          </Box>
        </Fade>
      </Modal> */}

      <SendMail
        setOpen={setOpen}
        open={open}
        handleOpen={handleOpen}
        handleClose={handleClose}
        setSuccess={setSuccess}
        success={success}
      />
    <Support open={openSupport} setOpen={setOpenSupport}></Support>
    </>
  );
};
