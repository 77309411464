import React from 'react'
import SideBar from '../components/sideBar/SideBar'
import { View } from './components/view'
const Tickets = () => {
  return (
    <SideBar active={"Tickets"}>
      <View />
    </SideBar>
  )
}

export default Tickets