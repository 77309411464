import {
  Box,
  Button,
  Container,
  Divider,
  Grid,
  Paper,
  Stack,
  styled,
  Typography,
} from "@mui/material";
import Slider from "react-slick";
import {
  ChannelPartnersIcon,
  ComplianceIcon,
  EnhanceCustomerTrust,
  LeadsManagedIcon,
  ProjectManagedIcon,
  RealEstateDevelopersIcon,
  WothInventoriesSoldIcon,
} from "../../BuilderComponents/BuilderDasboard/assets/icons";
import BrandImg1 from "../../Imgs/brand-img1.svg";
import BrandImg2 from "../../Imgs/brand-img2.svg";
import BrandImg3 from "../../Imgs/brand-img3.svg";
import BrandImg4 from "../../Imgs/brand-img4.svg";
import BrandImg6 from "../../Imgs/brand-img6.svg";
import BrandImg7 from "../../Imgs/brand-img7.svg";
import ChannelPartnersBrokers from "../../Imgs/channel-partners-brokers.png";
import DiminishingTrustAmong from "../../Imgs/diminishing-trust-among.svg";
import EasilyCollaborate from "../../Imgs/easily-collaborate.svg";
import GetReadyToSee from "../../Imgs/get-ready-to-see.png";
import LackOfTransparency from "../../Imgs/lack-of-transparency.svg";
import OnboardProject from "../../Imgs/onboard-project.svg";
import OutdatedProcesses from "../../Imgs/outdated-processes-icon.svg";
import SignUp from "../../Imgs/sign-up.svg";
import SlowDigitalAdoption from "../../Imgs/slow-digital-adoption.svg";
import UnorganizedWorkflows from "../../Imgs/unorganized-workflows-icon.svg";
import BridgingGapsAcross from "../../Imgs/bridging-gaps-across.png";
import CircleCheckIcon from "../../Imgs/circle-check-icon.svg";
import ChannelLandingInefficientSalesWorkflowsIcon from "../../Imgs/channel-landing-inefficient-sales-workflows.svg";
import ChannelLandingInventoryAndLeadsIcon from "../../Imgs/channel-landing-Inventory-and-leads.svg";
import ChannelLandingLimitedAccessIcon from "../../Imgs/channel-landing-limited-access.svg";
import ChannelLandingLackOfTransparencyIcon from "../../Imgs/channel-landing-lack-of-transparency.svg";
import ChannelLandingAccessAllProjectDataIcon from "../../Imgs/channel-landing-access-all-project-data.svg";
import ChannelLandingConnectIcon from "../../Imgs/channel-landing-connect.svg";
import ChannelLandingSaveTimeIcon from "../../Imgs/channel-landing-save-time.svg";
import ChannelLandingConnectSmoothlyIcon from "../../Imgs/channel-landing-connect-smoothly.svg";

const Item = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(1),
  color: theme.palette.text.secondary,
  boxShadow: "none",
  backgroundColor: "transparent",
}));

const Item2 = styled(Paper)(({ theme }) => ({
  padding: "15px 10px",
  background: "#FFFFFF",
  boxShadow:
    "0px 8px 22px -6px rgba(24, 39, 75, 0.12), 0px 14px 64px -4px rgba(24, 39, 75, 0.12)",
  borderRadius: "14px",
}));

const Item3 = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(1),
  color: theme.palette.text.secondary,
  boxShadow: "none",
  backgroundColor: "transparent",
}));

const brandsImages = [
  BrandImg1,
  BrandImg2,
  BrandImg3,
  BrandImg4,
  BrandImg6,
  BrandImg7,
];

const ChannelLandingFeatures = () => {
  const settings = {
    autoplay: true,
    autoplaySpeed: 2000,
    infinite: true,
    slidesToShow: 5,
    centerPadding: "60px",
    swipeToSlide: true,
    arrows: false,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
        },
      },
    ],
  };
  return (
    <Stack spacing={{ xs: 4, md: 10 }}>
      {/* Feature One */}
      <Box sx={{ my: "60px" }}>
        <Container>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "50px",
            }}
          >
            <Box sx={{ width: "60%" }}>
              <Stack>
                <Typography
                  data-aos="fade-down"
                  sx={{
                    fontSize: "40px",
                    fontWeight: 700,
                    fontFamily: "Product Sans",
                    color: "#000",
                    lineHeight: "1.3",
                  }}
                >
                  Channel Partners & Brokers struggle with slow business growth
                </Typography>
                <Typography
                  data-aos="fade-up"
                  sx={{
                    fontSize: "24px",
                    fontFamily: "Product Sans",
                    color: "#000",
                  }}
                >
                  Amid growing competition and changing customer expectations,
                  multiple challenges hamper your growth
                </Typography>
              </Stack>
              <Box sx={{ flexGrow: 1, mt: "30px" }}>
                <Grid container spacing={2}>
                  <Grid
                    xs={12}
                    md={5.5}
                    data-aos="fade-zoom-in"
                    data-aos-easing="ease-in-back"
                    data-aos-delay="200"
                    data-aos-offset="0"
                  >
                    <Item3>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          gap: "10px",
                          background: "#FFFFFF",
                          border: "1px solid #FFAF2A",
                          boxShadow:
                            "0px 8px 22px -6px rgba(24, 39, 75, 0.12), 0px 14px 64px -4px rgba(24, 39, 75, 0.12)",
                          borderRadius: "8px",
                          padding: "10px 20px",
                        }}
                      >
                        <Box>
                          <img
                            src={ChannelLandingInefficientSalesWorkflowsIcon}
                            alt=""
                          />
                        </Box>
                        <Typography
                          sx={{
                            fontSize: "24px",
                            fontWeight: 600,
                            fontFamily: "Product Sans",
                            color: "#000",
                          }}
                        >
                          Inefficient sales workflows
                        </Typography>
                      </Box>
                    </Item3>
                  </Grid>
                  <Grid
                    xs={12}
                    md={6.5}
                    data-aos="fade-zoom-in"
                    data-aos-easing="ease-in-back"
                    data-aos-delay="500"
                    data-aos-offset="0"
                  >
                    <Item3>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          gap: "10px",
                          background: "#FFFFFF",
                          border: "1px solid #FF4267",
                          boxShadow:
                            "0px 8px 22px -6px rgba(24, 39, 75, 0.12), 0px 14px 64px -4px rgba(24, 39, 75, 0.12)",
                          borderRadius: "8px",
                          padding: "10px 20px",
                        }}
                      >
                        <Box>
                          <img
                            src={ChannelLandingInventoryAndLeadsIcon}
                            alt=""
                          />
                        </Box>
                        <Typography
                          sx={{
                            fontSize: "24px",
                            fontWeight: 600,
                            fontFamily: "Product Sans",
                            color: "#000",
                          }}
                        >
                          Inventory and leads management on paper
                        </Typography>
                      </Box>
                    </Item3>
                  </Grid>
                  <Grid
                    xs={12}
                    md={5.5}
                    data-aos="fade-zoom-in"
                    data-aos-easing="ease-in-back"
                    data-aos-delay="800"
                    data-aos-offset="0"
                  >
                    <Item3>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          gap: "10px",
                          background: "#FFFFFF",
                          border: "1px solid #0890FE",
                          boxShadow:
                            "0px 8px 22px -6px rgba(24, 39, 75, 0.12), 0px 14px 64px -4px rgba(24, 39, 75, 0.12)",
                          borderRadius: "8px",
                          padding: "10px 20px",
                        }}
                      >
                        <Box>
                          <img src={ChannelLandingLimitedAccessIcon} alt="" />
                        </Box>
                        <Typography
                          sx={{
                            fontSize: "24px",
                            fontWeight: 600,
                            fontFamily: "Product Sans",
                            color: "#000",
                          }}
                        >
                          Limited access <br /> to project data
                        </Typography>
                      </Box>
                    </Item3>
                  </Grid>
                  <Grid
                    xs={12}
                    md={6.5}
                    data-aos="fade-zoom-in"
                    data-aos-easing="ease-in-back"
                    data-aos-delay="1200"
                    data-aos-offset="0"
                  >
                    <Item3>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          gap: "10px",
                          background: "#FFFFFF",
                          border: "1px solid #00C29A",
                          boxShadow:
                            "0px 8px 22px -6px rgba(24, 39, 75, 0.12), 0px 14px 64px -4px rgba(24, 39, 75, 0.12)",
                          borderRadius: "8px",
                          padding: "10px 20px",
                        }}
                      >
                        <Box>
                          <img
                            src={ChannelLandingLackOfTransparencyIcon}
                            alt=""
                          />
                        </Box>
                        <Typography
                          sx={{
                            fontSize: "24px",
                            fontWeight: 600,
                            fontFamily: "Product Sans",
                            color: "#000",
                          }}
                        >
                          Lack of transparency <br /> in commissions
                        </Typography>
                      </Box>
                    </Item3>
                  </Grid>
                </Grid>
              </Box>
            </Box>

            <Box sx={{ width: "40%" }}>
              <img
                data-aos="fade-left"
                style={{ width: "100%" }}
                src={ChannelPartnersBrokers}
                alt=""
              />
            </Box>
          </Box>
        </Container>
      </Box>

      {/* Feature Three */}
      <Box sx={{ my: "60px" }}>
        <Container>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "50px",
            }}
          >
            <Box sx={{ width: "50%" }}>
              <img
                data-aos="fade-right"
                style={{ width: "100%" }}
                src={BridgingGapsAcross}
                alt=""
              />
            </Box>

            <Box sx={{ width: "50%" }}>
              <Typography
                data-aos="fade-down"
                sx={{
                  fontSize: "56px",
                  fontWeight: 700,
                  fontFamily: "Product Sans",
                  color: "#000",
                  lineHeight: "1.3",
                }}
              >
                Bridging gaps across the board
              </Typography>

              <Stack spacing={2} sx={{ mt: "30px" }}>
                <Box sx={{ display: "flex", gap: "10px" }}>
                  <Box>
                    <img src={CircleCheckIcon} alt="" />
                  </Box>
                  <Typography
                    sx={{
                      fontSize: { xs: "14px", lg: "18px" },
                      fontFamily: "Product Sans",
                      color: "#343434",
                      lineHeight: "1.3",
                      mt: "-4px",
                    }}
                  >
                    <span style={{ color: "#0F60B0" }}>
                      For the first time in India,
                    </span>{" "}
                    Channel Partners and Brokers have an{" "}
                    <span style={{ color: "#0F60B0" }}>
                      integrated platform
                    </span>{" "}
                  </Typography>
                </Box>
                <Box sx={{ display: "flex", gap: "10px" }}>
                  <Box>
                    <img src={CircleCheckIcon} alt="" />
                  </Box>
                  <Typography
                    sx={{
                      fontSize: { xs: "14px", lg: "18px" },
                      fontFamily: "Product Sans",
                      color: "#343434",
                      lineHeight: "1.3",
                      mt: "-4px",
                    }}
                  >
                    We aim to{" "}
                    <span style={{ color: "#0F60B0" }}>
                      enable greater transparency and trust
                    </span>{" "}
                    among Channel Partners and Builders
                  </Typography>
                </Box>
                <Box sx={{ display: "flex", gap: "10px" }}>
                  <Box>
                    <img src={CircleCheckIcon} alt="" />
                  </Box>
                  <Typography
                    sx={{
                      fontSize: { xs: "14px", lg: "18px" },
                      fontFamily: "Product Sans",
                      color: "#343434",
                      lineHeight: "1.3",
                      mt: "-4px",
                    }}
                  >
                    Benefit from{" "}
                    <span style={{ color: "#0F60B0" }}>
                      an ecosystem designed by industry experts
                    </span>{" "}
                    to transform the sector
                  </Typography>
                </Box>
              </Stack>

              <Box sx={{ flexGrow: 1, mt: "50px" }}>
                <Grid container spacing={3}>
                  <Grid
                    item
                    xs={12}
                    md={6}
                    data-aos="fade-zoom-in"
                    data-aos-easing="ease-in-back"
                    data-aos-delay="200"
                    data-aos-offset="0"
                  >
                    <Item2
                      sx={{
                        border: "1px solid #0890FE",
                        display: "flex",
                        gap: "10px",
                      }}
                    >
                      <Box>
                        <img
                          src={ChannelLandingAccessAllProjectDataIcon}
                          alt=""
                        />
                      </Box>

                      <Box sx={{ pb: "30px" }}>
                        <Typography
                          sx={{
                            color: "#000",
                            fontSize: { xs: "18px" },
                            fontWeight: 600,
                            fontFamily: "Product Sans",
                          }}
                        >
                          Access all project data and independently manage
                          inventory from a single place
                        </Typography>
                      </Box>
                    </Item2>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    md={6}
                    data-aos="fade-zoom-in"
                    data-aos-easing="ease-in-back"
                    data-aos-delay="500"
                    data-aos-offset="0"
                  >
                    <Item2
                      sx={{
                        border: "1px solid #FF4267",
                        display: "flex",
                        gap: "10px",
                      }}
                    >
                      <Box>
                        <img src={ChannelLandingConnectIcon} alt="" />
                      </Box>

                      <Box>
                        <Typography
                          sx={{
                            color: "#000",
                            fontSize: { xs: "18px" },
                            fontWeight: 600,
                            fontFamily: "Product Sans",
                          }}
                        >
                          Connect and collaborate smoothly with both parties -
                          Real Estate Developers and Home Buyers
                        </Typography>
                      </Box>
                    </Item2>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    md={6}
                    data-aos="fade-zoom-in"
                    data-aos-easing="ease-in-back"
                    data-aos-delay="800"
                    data-aos-offset="0"
                  >
                    <Item2
                      sx={{
                        border: "1px solid #FFAF2A",
                        display: "flex",
                        gap: "10px",
                      }}
                    >
                      <Box>
                        <img src={ChannelLandingSaveTimeIcon} alt="" />
                      </Box>

                      <Box sx={{ pb: "30px" }}>
                        <Typography
                          sx={{
                            color: "#000",
                            fontSize: { xs: "18px" },
                            fontWeight: 600,
                            fontFamily: "Product Sans",
                          }}
                        >
                          Save time with pre-defined workflows
                        </Typography>
                      </Box>
                    </Item2>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    md={6}
                    data-aos="fade-zoom-in"
                    data-aos-easing="ease-in-back"
                    data-aos-delay="1200"
                    data-aos-offset="0"
                  >
                    <Item2
                      sx={{
                        border: "1px solid #3629B7",
                        display: "flex",
                        gap: "10px",
                      }}
                    >
                      <Box>
                        <img src={ChannelLandingConnectSmoothlyIcon} alt="" />
                      </Box>

                      <Box>
                        <Typography
                          sx={{
                            color: "#000",
                            fontSize: { xs: "18px" },
                            fontWeight: 600,
                            fontFamily: "Product Sans",
                          }}
                        >
                          Grow business with enhanced trust and transparency
                        </Typography>
                      </Box>
                    </Item2>
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </Box>
        </Container>
      </Box>

      <Box
        sx={{
          background: "linear-gradient(90deg, #0F5DAE 0%, #009DCF 100.33%)",
          py: "20px",
        }}
      >
        <Container>
          <Box
            data-aos="fade-zoom-in"
            data-aos-easing="ease-in-back"
            data-aos-delay="50"
            data-aos-offset="0"
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              gap: "20px",
              padding: { xs: "15px", md: "30px 20px" },
              borderRadius: { xs: "10px", md: "20px" },
              flexDirection: { xs: "column", md: "row" },
            }}
          >
            <Typography
              sx={{
                color: "#FFF",
                fontSize: { xs: "16px", md: "30px", lg: "44px" },
                fontWeight: 700,
                fontFamily: "Product Sans",
              }}
            >
              Become a part of the Envision <br /> Next ecosystem now to take
              your <br />
              business to the next level!
            </Typography>
            <Box>
              <Button
                sx={{
                  background: "#FFC503",
                  fontWeight: 600,
                  fontSize: { xs: "20px", lg: "28px" },
                  boxShadow: "0px 5px 30px rgba(0, 0, 0, 0.05)",
                  borderRadius: "10px",
                  color: "#06386B",
                  textTransform: "none",
                  padding: "5px 30px",
                  "&:hover": {
                    background: "#FFC503",
                  },
                }}
              >
                Sign up for free
              </Button>
            </Box>
          </Box>
        </Container>
      </Box>

      {/* Feature Four */}
      <Box sx={{ my: "60px" }}>
        <Container>
          <Typography
            data-aos="fade-down"
            sx={{
              color: "#000",
              fontSize: { xs: "18px", md: "36px", lg: "48px" },
              fontWeight: 700,
              fontFamily: "Product Sans",
              textAlign: "center",
              textTransform: "capitalize",
            }}
          >
            Fuel your business with empowering features & an intuitive interface
          </Typography>
          <Typography
            sx={{
              color: "#000",
              fontSize: "24px",
              fontFamily: "Product Sans",
              textAlign: "center",
              textTransform: "capitalize",
            }}
          >
            Manage inventory, leads, commissions, and more from one single place
          </Typography>
          <Box sx={{ flexGrow: 1, mt: "60px" }}>
            <Grid container spacing={3} justifyContent="center">
              <Grid
                item
                xs={12}
                md={4}
                data-aos="fade-zoom-in"
                data-aos-easing="ease-in-back"
                data-aos-delay="200"
                data-aos-offset="0"
              >
                <Item2 sx={{ border: "1px solid #FFAF2A", pb: "40px" }}>
                  <Box>
                    <img src={OutdatedProcesses} alt="" />
                  </Box>

                  <Box sx={{ mt: "10px" }}>
                    <Typography
                      sx={{
                        color: "#000",
                        fontSize: { xs: "18px", lg: "24px" },
                        fontWeight: 600,
                        fontFamily: "Product Sans",
                      }}
                    >
                      AI Filter for Fake Accounts
                    </Typography>
                    <Typography
                      sx={{
                        color: "#343434",
                        fontSize: { xs: "12px", lg: "18px" },
                        fontFamily: "Product Sans",
                        mt: "15px",
                      }}
                    >
                      EnvisionNext is the first and only platform for Channel
                      Partners that uses AI to filter out fake accounts.
                    </Typography>
                  </Box>
                </Item2>
              </Grid>
              <Grid
                item
                xs={12}
                md={4}
                data-aos="fade-zoom-in"
                data-aos-easing="ease-in-back"
                data-aos-delay="500"
                data-aos-offset="0"
              >
                <Item2 sx={{ border: "1px solid #FF4267", pb: "40px" }}>
                  <Box>
                    <img src={UnorganizedWorkflows} alt="" />
                  </Box>

                  <Box sx={{ mt: "10px" }}>
                    <Typography
                      sx={{
                        color: "#000",
                        fontSize: "24px",
                        fontWeight: 600,
                        fontFamily: "Product Sans",
                      }}
                    >
                      WhatsApp Integration
                    </Typography>
                    <Typography
                      sx={{
                        color: "#343434",
                        fontSize: "18px",
                        fontFamily: "Product Sans",
                        mt: "15px",
                      }}
                    >
                      Connect and communicate with your leads directly through
                      WhatsApp integrated within the platform.
                    </Typography>
                  </Box>
                </Item2>
              </Grid>
              <Grid
                item
                xs={12}
                md={4}
                data-aos="fade-zoom-in"
                data-aos-easing="ease-in-back"
                data-aos-delay="800"
                data-aos-offset="0"
              >
                <Item2 sx={{ border: "1px solid #0890FE" }}>
                  <Box>
                    <img src={SlowDigitalAdoption} alt="" />
                  </Box>

                  <Box sx={{ mt: "10px" }}>
                    <Typography
                      sx={{
                        color: "#000",
                        fontSize: "24px",
                        fontWeight: 600,
                        fontFamily: "Product Sans",
                      }}
                    >
                      Digitized Requirement Form
                    </Typography>
                    <Typography
                      sx={{
                        color: "#343434",
                        fontSize: "18px",
                        fontFamily: "Product Sans",
                        mt: "15px",
                      }}
                    >
                      Capture and manage leads with digitized Requirement Forms.
                      Easily use this data to optimize your sales.
                    </Typography>
                  </Box>
                </Item2>
              </Grid>
              <Grid
                item
                xs={12}
                md={4}
                data-aos="fade-zoom-in"
                data-aos-easing="ease-in-back"
                data-aos-delay="1100"
                data-aos-offset="0"
              >
                <Item2 sx={{ border: "1px solid #3629B7" }}>
                  <Box>
                    <img src={LackOfTransparency} alt="" />
                  </Box>

                  <Box sx={{ mt: "10px" }}>
                    <Typography
                      sx={{
                        color: "#000",
                        fontSize: "24px",
                        fontWeight: 600,
                        fontFamily: "Product Sans",
                      }}
                    >
                      Project/Inventory Data Management
                    </Typography>
                    <Typography
                      sx={{
                        color: "#343434",
                        fontSize: "18px",
                        fontFamily: "Product Sans",
                        mt: "15px",
                      }}
                    >
                      Gone are the days of managing inventory details and photos
                      on paper. Save time by getting all project-related data
                      you need directly from the Builder in your Envision Next
                      account.
                    </Typography>
                  </Box>
                </Item2>
              </Grid>
              <Grid
                item
                xs={12}
                md={4}
                data-aos="fade-zoom-in"
                data-aos-easing="ease-in-back"
                data-aos-delay="1400"
                data-aos-offset="0"
              >
                <Item2 sx={{ border: "1px solid #00C29A" }}>
                  <Box>
                    <img src={DiminishingTrustAmong} alt="" />
                  </Box>

                  <Box sx={{ mt: "10px" }}>
                    <Typography
                      sx={{
                        color: "#000",
                        fontSize: "24px",
                        fontWeight: 600,
                        fontFamily: "Product Sans",
                      }}
                    >
                      In-App Commission <br /> Payment
                    </Typography>
                    <Typography
                      sx={{
                        color: "#343434",
                        fontSize: "18px",
                        fontFamily: "Product Sans",
                        mt: "15px",
                      }}
                    >
                      Builders pay your commissions directly to your
                      EnvisionNext account. You can withdraw your
                      payments anytime.
                    </Typography>
                  </Box>
                </Item2>
              </Grid>
            </Grid>
          </Box>
        </Container>
      </Box>
      {/* Feature Five */}
      <Box>
        <Container>
          <Box sx={{ textAlign: "center", mt: "50px" }}>
            <Typography
              data-aos="zoom-in"
              sx={{
                fontSize: { xs: "30px", md: "40px", lg: "64px" },
                fontWeight: 700,
                color: "#000",
                fontFamily: "Product Sans",
              }}
            >
              Getting start with EnvisionNext
            </Typography>
            <Typography
              data-aos="fade-up"
              sx={{
                fontSize: { xs: "20px", md: "36px", lg: "46px" },
                color: "#000",
                fontFamily: "Product Sans",
              }}
            >
              Free to use for Channel Partners & Brokers. <br /> No hidden fees
              or subscriptions
            </Typography>
          </Box>

          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              flexDirection: { md: "row", xs: "column" },
              alignItems: { xs: "center", md: "start" },
              gap: { xs: "30px", md: "50px" },
              mt: { xs: "30px", md: "50px" },
            }}
          >
            <Stack
              data-aos="fade-zoom-in"
              data-aos-easing="ease-in-back"
              data-aos-delay="200"
              data-aos-offset="0"
              spacing={1}
              sx={{
                width: { xs: "220px" },
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                textAlign: "center",
                gap: "5px",
              }}
            >
              <Box sx={{ width: "150px" }}>
                <img style={{ width: "100%" }} src={SignUp} alt="" />
              </Box>
              <Typography
                sx={{
                  fontSize: { xs: "18px", lg: "20px" },
                  fontWeight: 600,
                  color: "#0890FE",
                  fontFamily: "Product Sans",
                }}
              >
                Sign up for free
              </Typography>
              <Typography
                sx={{
                  fontSize: { xs: "18px" },
                  color: "#000",
                  fontFamily: "Product Sans",
                }}
              >
                Create your account and use EnvisionNext for free forever
              </Typography>
            </Stack>
            <Stack
              data-aos="fade-zoom-in"
              data-aos-easing="ease-in-back"
              data-aos-delay="500"
              data-aos-offset="0"
              spacing={1}
              sx={{
                width: { xs: "220px" },
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                textAlign: "center",
                gap: "5px",
              }}
            >
              <Box sx={{ width: "150px" }}>
                <img style={{ width: "100%" }} src={OnboardProject} alt="" />
              </Box>
              <Typography
                sx={{
                  fontSize: { xs: "18px", lg: "20px" },
                  fontWeight: 600,
                  color: "#FF4267",
                  fontFamily: "Product Sans",
                }}
              >
                Start collaborating <br /> with your builders
              </Typography>
              <Typography
                sx={{
                  fontSize: { xs: "18px" },
                  color: "#000",
                  fontFamily: "Product Sans",
                }}
              >
                Partner with builders to sell properties and get your
                commissions
              </Typography>
            </Stack>
            <Stack
              data-aos="fade-zoom-in"
              data-aos-easing="ease-in-back"
              data-aos-delay="800"
              data-aos-offset="0"
              spacing={1}
              sx={{
                width: { xs: "220px" },
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                textAlign: "center",
                gap: "5px",
              }}
            >
              <Box sx={{ width: "150px" }}>
                <img style={{ width: "100%" }} src={EasilyCollaborate} alt="" />
              </Box>
              <Typography
                sx={{
                  fontSize: { xs: "18px", lg: "20px" },
                  fontWeight: 600,
                  color: "#4F46BA",
                  fontFamily: "Product Sans",
                }}
              >
                Connect with leads <br />
                through the platform
              </Typography>
              <Typography
                sx={{
                  fontSize: { xs: "18px" },
                  color: "#000",
                  fontFamily: "Product Sans",
                }}
              >
                Capture, manage, and communicate with your leads from a single
                place
              </Typography>
            </Stack>
            <Stack
              data-aos="fade-zoom-in"
              data-aos-easing="ease-in-back"
              data-aos-delay="1200"
              data-aos-offset="0"
              spacing={1}
              sx={{
                width: { xs: "220px" },
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                textAlign: "center",
                gap: "5px",
              }}
            >
              <Box sx={{ width: "150px" }}>
                <img style={{ width: "100%" }} src={GetReadyToSee} alt="" />
              </Box>
              <Typography
                sx={{
                  fontSize: { xs: "18px", lg: "20px" },
                  fontWeight: 600,
                  color: "#4F46BA",
                  fontFamily: "Product Sans",
                }}
              >
                Get ready to see your business thriving
              </Typography>
              <Typography
                sx={{
                  fontSize: { xs: "18px" },
                  color: "#000",
                  fontFamily: "Product Sans",
                }}
              >
                See your business thriving with increased efficiency and
                new growth avenues
              </Typography>
            </Stack>
          </Box>
        </Container>
      </Box>

      <Box
        sx={{
          background: "linear-gradient(90deg, #0F5DAE 0%, #009DCF 100.33%)",
          py: "20px",
        }}
      >
        <Container>
          <Box
            data-aos="fade-zoom-in"
            data-aos-easing="ease-in-back"
            data-aos-delay="50"
            data-aos-offset="0"
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              gap: "20px",
              padding: { xs: "15px", md: "30px 20px" },
              borderRadius: { xs: "10px", md: "20px" },
              flexDirection: { xs: "column", md: "row" },
            }}
          >
            <Typography
              sx={{
                color: "#FFF",
                fontSize: { xs: "16px", md: "30px", lg: "44px" },
                fontWeight: 700,
                fontFamily: "Product Sans",
              }}
            >
              Make the most of the Envision Next <br />
              ecosystem by getting started today!
            </Typography>
            <Box>
              <Button
                sx={{
                  background: "#FFC503",
                  fontWeight: 600,
                  fontSize: { xs: "20px", lg: "28px" },
                  boxShadow: "0px 5px 30px rgba(0, 0, 0, 0.05)",
                  borderRadius: "10px",
                  color: "#06386B",
                  textTransform: "none",
                  padding: "5px 30px",
                  "&:hover": {
                    background: "#FFC503",
                  },
                }}
              >
                Sign up for free
              </Button>
            </Box>
          </Box>
        </Container>
      </Box>

      <Box sx={{ my: "60px" }}>
        <Container>
          <Typography
            data-aos="fade-down"
            sx={{
              color: "#000",
              fontSize: { xs: "18px", md: "36px", lg: "48px" },
              fontWeight: 700,
              fontFamily: "Product Sans",
              textTransform: "capitalize",
              textAlign: "center",
              lineHeight: "1.2",
            }}
          >
            Becoming a part of Envision Next powers <br /> up your venture with
            multi-faceted benefits
          </Typography>

          <Box sx={{ flexGrow: 1, mt: "50px" }}>
            <Grid container spacing={3} justifyContent="center">
              <Grid
                item
                xs={12}
                md={3}
                data-aos="fade-zoom-in"
                data-aos-easing="ease-in-back"
                data-aos-delay="200"
                data-aos-offset="0"
              >
                <Item2 sx={{ border: "1px solid #0890FE", pb: "40px" }}>
                  <Box>
                    <img src={OutdatedProcesses} alt="" />
                  </Box>

                  <Box sx={{ mt: "10px" }}>
                    <Typography
                      sx={{
                        color: "#000",
                        fontSize: { xs: "18px" },
                        fontWeight: 600,
                        fontFamily: "Product Sans",
                      }}
                    >
                      Increased efficiency <br />
                      across your workflows
                    </Typography>
                  </Box>
                </Item2>
              </Grid>
              <Grid
                item
                xs={12}
                md={3}
                data-aos="fade-zoom-in"
                data-aos-easing="ease-in-back"
                data-aos-delay="500"
                data-aos-offset="0"
              >
                <Item2 sx={{ border: "1px solid #FF4267" }}>
                  <Box>
                    <img src={OutdatedProcesses} alt="" />
                  </Box>

                  <Box sx={{ mt: "10px" }}>
                    <Typography
                      sx={{
                        color: "#000",
                        fontSize: { xs: "18px" },
                        fontWeight: 600,
                        fontFamily: "Product Sans",
                      }}
                    >
                      Strengthened collaboration with Real Estate Builders and
                      Developers
                    </Typography>
                  </Box>
                </Item2>
              </Grid>
              <Grid
                item
                xs={12}
                md={3}
                data-aos="fade-zoom-in"
                data-aos-easing="ease-in-back"
                data-aos-delay="800"
                data-aos-offset="0"
              >
                <Item2 sx={{ border: "1px solid #FFAF2A" }}>
                  <Box>
                    <img src={OutdatedProcesses} alt="" />
                  </Box>

                  <Box sx={{ mt: "10px" }}>
                    <Typography
                      sx={{
                        color: "#000",
                        fontSize: { xs: "18px" },
                        fontWeight: 600,
                        fontFamily: "Product Sans",
                      }}
                    >
                      Competitive edge over <br />
                      other Channel Partners and Brokers
                    </Typography>
                  </Box>
                </Item2>
              </Grid>
              <Grid
                item
                xs={12}
                md={3}
                data-aos="fade-zoom-in"
                data-aos-easing="ease-in-back"
                data-aos-delay="1200"
                data-aos-offset="0"
              >
                <Item2 sx={{ border: "1px solid #3629B7" }}>
                  <Box>
                    <img src={OutdatedProcesses} alt="" />
                  </Box>

                  <Box sx={{ mt: "10px", pb: "25px" }}>
                    <Typography
                      sx={{
                        color: "#000",
                        fontSize: { xs: "18px" },
                        fontWeight: 600,
                        fontFamily: "Product Sans",
                      }}
                    >
                      Improved communication
                      <br /> with leads
                    </Typography>
                  </Box>
                </Item2>
              </Grid>
              <Grid
                item
                xs={12}
                md={3}
                data-aos="fade-zoom-in"
                data-aos-easing="ease-in-back"
                data-aos-delay="1500"
                data-aos-offset="0"
              >
                <Item2 sx={{ border: "1px solid #0890FE", pb: "40px" }}>
                  <Box>
                    <img src={OutdatedProcesses} alt="" />
                  </Box>

                  <Box sx={{ mt: "10px" }}>
                    <Typography
                      sx={{
                        color: "#000",
                        fontSize: { xs: "18px" },
                        fontWeight: 600,
                        fontFamily: "Product Sans",
                      }}
                    >
                      Enhanced brand value
                    </Typography>
                  </Box>
                </Item2>
              </Grid>
              <Grid
                item
                xs={12}
                md={3}
                data-aos="fade-zoom-in"
                data-aos-easing="ease-in-back"
                data-aos-delay="1800"
                data-aos-offset="0"
              >
                <Item2 sx={{ border: "1px solid #FF4267" }}>
                  <Box>
                    <img src={OutdatedProcesses} alt="" />
                  </Box>

                  <Box sx={{ mt: "10px" }}>
                    <Typography
                      sx={{
                        color: "#000",
                        fontSize: { xs: "18px" },
                        fontWeight: 600,
                        fontFamily: "Product Sans",
                      }}
                    >
                      New avenues for your <br /> business growth
                    </Typography>
                  </Box>
                </Item2>
              </Grid>
            </Grid>
          </Box>
        </Container>
      </Box>

      {/* Feature Six */}

      {/* Feature Seven */}
      {/* <Box>
        <Container>
          <Typography
            data-aos="fade-zoom-in"
            data-aos-easing="ease-in-back"
            data-aos-delay="300"
            data-aos-offset="0"
            sx={{
              fontSize: { xs: "24px", md: "42px", lg: "54px" },
              fontWeight: 700,
              textAlign: "center",
              fontFamily: "Product Sans",
              lineHeight: "1.2",
            }}
          >
            Join the growing EnvisionNext ecosystem to elevate your Real Estate
            business
          </Typography>
        </Container>
        <Box
          sx={{
            backgroundColor: "#0890FE",
            mt: { xs: "20px", md: "50px" },
          }}
        >
          <Container
            sx={{
              display: "flex",
              alignItems: "center",
              flexDirection: { xs: "column", md: "row" },
            }}
          >
            <Box
              sx={{
                width: { xs: "100%", md: "300px" },
                py: { xs: "20px", md: "0px" },
              }}
            >
              <Typography
                data-aos="fade-right"
                sx={{
                  color: "#FFF",
                  fontSize: { xs: "18px", md: "22px", lg: "31px" },
                  pr: "5px",
                  fontFamily: "Product Sans",
                  textAlign: { xs: "center", md: "left" },
                }}
              >
                We speak with our powerful statistics
              </Typography>
            </Box>
            <Divider
              orientation="vertical"
              flexItem
              sx={{
                backgroundColor: "#FFF",
                display: { xs: "none", md: "block" },
              }}
            />
            <Box
              sx={{
                padding: { xs: "5px", md: "30px", lg: "60px" },
              }}
            >
              <Grid
                container
                rowSpacing={{ md: 3, xs: 1 }}
                columnSpacing={{ md: 3, xs: 1 }}
                sx={{ width: "100%", flexGrow: 1 }}
              >
                <Grid
                  item
                  xs={4}
                  data-aos="fade-zoom-in"
                  data-aos-easing="ease-in-back"
                  data-aos-delay="200"
                  data-aos-offset="0"
                >
                  <Item>
                    <Typography
                      sx={{
                        fontSize: { xs: "24px", md: "52px", lg: "72px" },
                        fontWeight: 700,
                        color: "#FFF",
                        fontFamily: "Product Sans",
                      }}
                    >
                      50K+
                    </Typography>
                    <Box sx={{ display: "flex", gap: "5px" }}>
                      <Box sx={{ display: { xs: "none", md: "block" } }}>
                        <RealEstateDevelopersIcon />
                      </Box>

                      <Typography
                        sx={{
                          fontSize: { xs: "10px", md: "18px", lg: "24px" },
                          color: "#FFF",
                          fontFamily: "Product Sans",
                        }}
                      >
                        Real Estate Developers
                      </Typography>
                    </Box>
                  </Item>
                </Grid>
                <Grid
                  data-aos="fade-zoom-in"
                  data-aos-easing="ease-in-back"
                  data-aos-delay="500"
                  data-aos-offset="0"
                  item
                  xs={4}
                >
                  <Item>
                    <Typography
                      sx={{
                        fontSize: { xs: "24px", md: "52px", lg: "72px" },
                        fontWeight: 700,
                        color: "#FFF",
                        fontFamily: "Product Sans",
                      }}
                    >
                      30K+
                    </Typography>
                    <Box sx={{ display: "flex", gap: "5px" }}>
                      <Box sx={{ display: { xs: "none", md: "block" } }}>
                        <ChannelPartnersIcon />
                      </Box>

                      <Typography
                        sx={{
                          fontSize: { xs: "10px", md: "18px", lg: "24px" },
                          color: "#FFF",
                          fontFamily: "Product Sans",
                        }}
                      >
                        Channel Partners
                      </Typography>
                    </Box>
                  </Item>
                </Grid>
                <Grid
                  data-aos="fade-zoom-in"
                  data-aos-easing="ease-in-back"
                  data-aos-delay="800"
                  data-aos-offset="0"
                  item
                  xs={4}
                >
                  <Item>
                    <Typography
                      sx={{
                        fontSize: { xs: "24px", md: "52px", lg: "72px" },
                        fontWeight: 700,
                        color: "#FFF",
                        fontFamily: "Product Sans",
                      }}
                    >
                      $252M
                    </Typography>
                    <Box sx={{ display: "flex", gap: "5px" }}>
                      <Box sx={{ display: { xs: "none", md: "block" } }}>
                        <ProjectManagedIcon />
                      </Box>

                      <Typography
                        sx={{
                          fontSize: { xs: "10px", md: "18px", lg: "24px" },
                          color: "#FFF",
                          fontFamily: "Product Sans",
                        }}
                      >
                        Project Managed
                      </Typography>
                    </Box>
                  </Item>
                </Grid>
                <Grid
                  data-aos="fade-zoom-in"
                  data-aos-easing="ease-in-back"
                  data-aos-delay="200"
                  data-aos-offset="0"
                  item
                  xs={4}
                >
                  <Item>
                    <Typography
                      sx={{
                        fontSize: { xs: "24px", md: "52px", lg: "72px" },
                        fontWeight: 700,
                        color: "#FFF",
                        fontFamily: "Product Sans",
                      }}
                    >
                      50K+
                    </Typography>
                    <Box sx={{ display: "flex", gap: "5px" }}>
                      <Box sx={{ display: { xs: "none", md: "block" } }}>
                        <LeadsManagedIcon />
                      </Box>

                      <Typography
                        sx={{
                          fontSize: { xs: "10px", md: "18px", lg: "24px" },
                          color: "#FFF",
                          fontFamily: "Product Sans",
                        }}
                      >
                        Leads Managed
                      </Typography>
                    </Box>
                  </Item>
                </Grid>
                <Grid
                  data-aos="fade-zoom-in"
                  data-aos-easing="ease-in-back"
                  data-aos-delay="500"
                  data-aos-offset="0"
                  item
                  xs={4}
                >
                  <Item>
                    <Typography
                      sx={{
                        fontSize: { xs: "24px", md: "52px", lg: "72px" },
                        fontWeight: 700,
                        color: "#FFF",
                        fontFamily: "Product Sans",
                      }}
                    >
                      19M+
                    </Typography>
                    <Box sx={{ display: "flex", gap: "5px" }}>
                      <Box sx={{ display: { xs: "none", md: "block" } }}>
                        <WothInventoriesSoldIcon />
                      </Box>

                      <Typography
                        sx={{
                          fontSize: { xs: "10px", md: "18px", lg: "24px" },
                          color: "#FFF",
                          fontFamily: "Product Sans",
                        }}
                      >
                        Woth Inventories Sold
                      </Typography>
                    </Box>
                  </Item>
                </Grid>
                <Grid
                  data-aos="fade-zoom-in"
                  data-aos-easing="ease-in-back"
                  data-aos-delay="800"
                  data-aos-offset="0"
                  item
                  xs={4}
                >
                  <Item>
                    <Typography
                      sx={{
                        fontSize: { xs: "24px", md: "52px", lg: "72px" },
                        fontWeight: 700,
                        color: "#FFF",
                        fontFamily: "Product Sans",
                      }}
                    >
                      $252M
                    </Typography>
                    <Box sx={{ display: "flex", gap: "5px" }}>
                      <Box sx={{ display: { xs: "none", md: "block" } }}>
                        <ComplianceIcon />
                      </Box>
                      <Typography
                        sx={{
                          fontSize: { xs: "10px", md: "18px", lg: "24px" },
                          color: "#FFF",
                          fontFamily: "Product Sans",
                        }}
                      >
                        Compliance
                      </Typography>
                    </Box>
                  </Item>
                </Grid>
              </Grid>
            </Box>
          </Container>
        </Box>
        <Box sx={{ py: "50px", backgroundColor: "#F5F6F8" }}>
          <Container>
            <Typography
              sx={{
                color: "#000",
                fontSize: { xs: "12px", md: "16px", lg: "22px" },
                fontWeight: 600,
                textAlign: "center",
                fontFamily: "Product Sans",
              }}
            >
              Brands that have put their trust in us
            </Typography>
            <Box
              sx={{
                mt: { xs: "15px", md: "30px" },
              }}
            >
              <Slider {...settings}>
                {brandsImages.map((img) => (
                  <Box
                    sx={{
                      padding: "20px",
                    }}
                  >
                    <img src={img} alt="" />
                  </Box>
                ))}
              </Slider>
            </Box>
          </Container>
        </Box>
      </Box> */}
    </Stack>
  );
};

export default ChannelLandingFeatures;
