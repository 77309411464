import { Box, Checkbox, Stack, Button, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import axios from "axios";
import { api } from "../../../../utils/consts";
import KeyboardDoubleArrowLeftIcon from "@mui/icons-material/KeyboardDoubleArrowLeft";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import { useNavigate } from "react-router-dom";
import {
  ArrowDropDownIconSvg,
  ArrowDropUpIconSvg,
} from "../../../BuilderComponents/BuilderDasboard/assets/icons";
import Lottie from "react-lottie";
import animationData from "../../../../Lotties/NEW sin movs.json";


function TDcustomTable({
  newRowData,
  updateBuyers,
  setTrigger,
  trigger,
  viewMembers,
  delIconClick,
  updateTeam,
  toggleDrawer,
  setMemState,
  startMember,
  setStartMember,
  setTotalMember,
  totalMember,
  setcurpage,
  curpage,
  matchText
}) {
  const navigate = useNavigate();

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  ////get user roles from local storage and assign
  let roles = JSON.parse(localStorage.getItem("user_roles"));
  let teams = roles["teams"];
  
  const [pageRange, setPageRange] = useState([0, 6]);
  const [pages, setPages] = useState([]);
  const [NoPages, setNopages] = useState(null);
  
  const nextClick = () => {
    if (curpage !== NoPages) {
      setStartMember(startMember + 7);
      setcurpage(curpage + 1);
      setTrigger(!trigger);
    }
  };

  const prvClick = () => {
    if (curpage !== 1) {
      setStartMember(startMember - 7);
      setcurpage(curpage - 1);
      setTrigger(!trigger);
    }
  };

  useEffect(() => {
    if (newRowData) {
      if (totalMember % 7 == 0) {
        let temp = totalMember / 7;
        setNopages(temp);
      } else {
        let temp = Math.floor(totalMember / 7 + 1);
        setNopages(temp);
      }
    }
  }, [newRowData]);
  const [curData, setcurData] = useState([]);
  const [selectedSortUp, setSelectedSortUp] = useState("");
  const [selectedSortDown, setSelectedSortDown] = useState("");

  useEffect(() => {
    if (newRowData) {
      setcurData(newRowData);
      setSelectedSortUp("");
      setSelectedSortDown("");
    }
  }, [newRowData]);

  const sortArr = (para) => {
    setSelectedSortUp(para);
    setSelectedSortDown("");
    function compare(a, b) {
      let fir, sec;
      let fir1, sec1;
      if (para == "first_name") {
        fir = a?.profile?.user_details?.first_name;
        sec = b?.profile?.user_details?.first_name;
        fir1 = fir.toLowerCase();
        sec1 = sec.toLowerCase();
      } else {
        fir = a[para];
        sec = b[para];
        fir1 = fir.toLowerCase();
        sec1 = sec.toLowerCase();
      }
      console.log(fir1, "===", sec1);
      if (fir1 < sec1) {
        return -1;
      }
      if (fir1 > sec1) {
        return 1;
      }
      return 0;
    }

    let arr = [...curData];
    console.log(arr);
    arr.sort(compare);
    console.log(arr);

    setcurData(arr);
  };

  const sortArrDec = (para) => {
    setSelectedSortUp("");
    setSelectedSortDown(para);
    function compare(a, b) {
      let fir, sec;
      let fir1, sec1;
      if (para == "first_name") {
        fir = a?.profile?.user_details?.first_name;
        sec = b?.profile?.user_details?.first_name;
        fir1 = fir.toLowerCase();
        sec1 = sec.toLowerCase();
      } else {
        fir = a[para];
        sec = b[para];
        fir1 = fir.toLowerCase();
        sec1 = sec.toLowerCase();
      }

      if (fir1 < sec1) {
        return -1;
      }
      if (fir1 > sec1) {
        return 1;
      }
      return 0;
    }
    let arr = [...curData];
    arr.sort(compare);
    arr.reverse();
    console.log(arr);
    setcurData([...arr]);
  };

  return (
    <Box>
      <Box
        sx={{
          color: "black",
          width: "100%",
          height: "400px",
          boxSizing: "border-box",
          "&:hover": {
            boxShadow: "-6px 18px 15px rgba(0, 0, 0, 0.1)",
          },
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            background: "white",
            borderRadius: "5px",
            color: "#272D4E",
            fontWeight: "700",
            fontFamily: "Circular Std",
            fontSize: "18px",
            gap: "15px",
            padding: "10px 20px",
            borderBottom: "1px solid #E9ECFF",
            borderRadius: "20px 20px 0 0",
          }}
        >
          {/* <Box
            sx={{
              width: "20vw",
              display: "grid",
              placeItems: "center",
              height: "50px",
              justifyContent: "start",
              paddingLeft: "39px",
            }}
          >
            <Box sx={{ display: "flex" }}>
              <Box sx={{ display: "grid", placeItems: "center" }}>Name</Box>
            </Box>
          </Box>
          <Box
            sx={{
              width: "20vw",
              display: "grid",
              placeItems: "center",
              height: "50px",
              justifyContent: "start",
              paddingLeft: "39px",
            }}
          >
            <Box sx={{ display: "flex" }}>
              <Box sx={{ display: "grid", placeItems: "center" }}>Phone</Box>
            </Box>
          </Box>

          <Box
            sx={{
              width: "20vw",
              display: "grid",
              placeItems: "center",
              height: "50px",
              justifyContent: "start",
              paddingLeft: "39px",
            }}
          >
            <Box sx={{ display: "flex" }}>
              <Box sx={{ display: "grid", placeItems: "center" }}>Email</Box>
            </Box>
          </Box>

          <Box
            sx={{
              width: "20vw",
              display: "grid",
              placeItems: "center",
              height: "50px",
              justifyContent: "start",
              paddingLeft: "39px",
            }}
          >
            <Box sx={{ display: "flex" }}>
              <Box sx={{ display: "grid", placeItems: "center" }}>Role</Box>
            </Box>
          </Box>

          <Box
            sx={{
              width: "20vw",
              display: "grid",
              placeItems: "center",
              height: "50px",
              justifyContent: "start",
              paddingLeft: "39px",
            }}
          >
            <Box sx={{ display: "flex" }}>
              <Box sx={{ display: "grid", placeItems: "center" }}>
                Joining date
              </Box>
            </Box>
          </Box>

          <Box
            sx={{
              width: "20vw",
              display: "grid",
              placeItems: "center",
              height: "50px",
              justifyContent: "start",
              paddingLeft: "39px",
            }}
          >
            Actions
          </Box> */}

          <Box
            sx={{
              width: "18%",
              display: "flex",
              alignItems: "center",
            }}
          >
            <Box>Name</Box>
            <Box sx={{ display: "flex", flexDirection: "column", gap: "5px" }}>
              <Box
                sx={{
                  cursor: "pointer",
                  height: "10px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginLeft: "10px",
                }}
                onClick={() => sortArr("first_name")}
              >
                <ArrowDropUpIconSvg focus={selectedSortUp == "first_name"} />
              </Box>
              <Box
                sx={{
                  cursor: "pointer",
                  height: "10px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginLeft: "10px",
                }}
                onClick={() => sortArrDec("first_name")}
              >
                <ArrowDropDownIconSvg
                  focus={selectedSortDown == "first_name"}
                />
              </Box>
            </Box>
          </Box>
          <Box
            sx={{
              width: "18%",
              display: "flex",
              alignItems: "center",
            }}
          >
            <Box>Phone</Box>
          </Box>
          <Box
            sx={{
              width: "18%",
              display: "flex",
              alignItems: "center",
            }}
          >
            <Box>Email</Box>
          </Box>
          <Box
            sx={{
              width: "18%",
              display: "flex",
              alignItems: "center",
            }}
          >
            <Box>Role</Box>
            <Box sx={{ display: "flex", flexDirection: "column", gap: "5px" }}>
              <Box
                sx={{
                  cursor: "pointer",
                  height: "10px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginLeft: "10px",
                }}
                onClick={() => sortArr("cp_type")}
              >
                <ArrowDropUpIconSvg focus={selectedSortUp == "cp_type"} />
              </Box>
              <Box
                sx={{
                  cursor: "pointer",
                  height: "10px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginLeft: "10px",
                }}
                onClick={() => sortArrDec("cp_type")}
              >
                <ArrowDropDownIconSvg focus={selectedSortDown == "cp_type"} />
              </Box>
            </Box>
          </Box>
          <Box
            sx={{
              width: "18%",
              display: "flex",
              alignItems: "center",
            }}
          >
            <Box>Joining date</Box>
          </Box>
          <Box sx={{ width: "10%", textAlign: "center" }}>Actions</Box>
        </Box>
        {newRowData == 0 ? (
          <Box sx={{ textAlign: "center", marginTop: "100px" }}>
            <Typography
              sx={{ fontSize: "25px", fontWeight: "500", color: "#B9B7D0" }}
            >
              <Lottie options={defaultOptions} height="20%" width="20%" />
                <Typography
                  sx={{
                    color: "#B9B7D0",
                    fontSize: "18px",
                    fontWeight: "400",
                    marginTop: "-40px",
                  }}
                >
                {matchText}
                </Typography>
              {/* <Button
                onClick={() => setMemState(true)}
                className="univercel_btn_class"
                sx={{
                  color: "#FFFFFF",
                  textTransform: "none",
                  margin: "3px",
                  padding: "6px 24px",
                  fontFamily: "Product Sans",
                  fontSize: "16px",
                }}
              >
                Add Member
              </Button> */}
            </Typography>
          </Box>
        ) : (
          <>
            <Box sx={{ height: "400px" }}>
              {curData &&
                curData.map((item, index) => {
                  if (index >= pageRange[0] && index <= pageRange[1]) {
                    // console.log(item)
                    return (
                      <Box
                        sx={{
                          backgroundColor: "#FFF",
                          padding: "15px 20px",
                          display: "flex",
                          alignItems: "center",
                          gap: "15px",
                          "&:hover": {
                            backgroundColor: "#F5F7FF",
                            cursor:"pointer"
                          },
                        }}
                        onClick={(e) => {
                          e.stopPropagation();
                          navigate("/channelpartner/teams/member", {
                            state: { member: item },
                          });
                        }}
                      >
                        {/* <Box
                        sx={{
                          width: "20vw",
                          justifyContent: "start",
                          fontFamily: "Product Sans",
                          fontWeight: "400",
                          paddingLeft: "40px",
                        }}
                      >
                        {item.user_details.first_name}
                      </Box>
                      <Box
                        sx={{
                          width: "20vw",
                          justifyContent: "start",
                          fontFamily: "Product Sans",
                          fontWeight: "400",
                          paddingLeft: "25px",
                        }}
                      >
                        {item.phone}
                      </Box>
                      <Box
                        sx={{
                          width: "20vw",
                          justifyContent: "start",
                          fontFamily: "Product Sans",
                          fontWeight: "400",
                          paddingLeft: "35px",
                        }}
                      >
                        {item.user_details.email.substring(0, 14) + "..."}
                      </Box>
                      <Box
                        sx={{
                          width: "20vw",
                          justifyContent: "start",
                          fontFamily: "Product Sans",
                          fontWeight: "400",
                          paddingLeft: "27px",
                        }}
                      >
                        {item?.cp_details?.cp_type}
                      </Box>
                      <Box
                        sx={{
                          width: "20vw",
                          justifyContent: "start",
                          fontFamily: "Product Sans",
                          fontWeight: "400",
                          paddingLeft: "20px",
                        }}
                      >
                        {item.date_of_joining}
                      </Box> */}
                        {/* <Box
                        sx={{
                          width: "20vw",
                          justifyContent: "start",
                          fontFamily: "Product Sans",
                          fontWeight: "400",
                          paddingLeft: "20px",
                        }}
                      >
                        <Stack direction="row">
                          {
                            // <RemoveRedEyeIcon
                            //   fontSize="medium"
                            //   onClick={(e) =>{e.stopPropagation();viewMembers(item)}}
                            // />
                          }
                          {
                            <EditIcon
                              fontSize="medium"
                              sx={{ marginLeft: "20px" }}
                              onClick={() => {
                                navigate("/channelpartner/teams/member", {
                                  state: { member: item },
                                });
                              }}
                              // onClick={(event)=>{
                              //   // event.stopPropagation()
                              //   updateTeam(item);
                              //   setState(true);
                              //   // toggleDrawer(true, item)
                              // }}
                            />
                          }
                          {/* {
                        <DeleteIcon
                          fontSize="medium"
                          sx={{ marginLeft: "10px" }}
                          onClick={async(event) =>{
                            console.log(item.id)
                            axios.delete(api("/api/role/channelpartner/?cp_id="+item.user),{
                              headers:{
                                "Content-Type":"application/json"
                              },
                              data:{
                                "cp_id":item.id
                              }
                            })
                          } 
                        }
                        />
                        } *}
                        </Stack>
                      </Box> */}
                        <Box
                          sx={{
                            width: "18%",
                            fontFamily: "Product Sans",
                            fontWeight: "400",
                          }}
                        >
                          {item?.profile?.user_details?.first_name}
                        </Box>
                        <Box
                          sx={{
                            width: "18%",
                            fontFamily: "Product Sans",
                            fontWeight: "400",
                          }}
                        >
                          {item?.profile?.phone}
                        </Box>
                        <Box
                          sx={{
                            width: "18%",
                            fontFamily: "Product Sans",
                            fontWeight: "400",
                          }}
                        >
                          {item?.profile?.user_details?.email.substring(0, 14) +
                            "..."}
                        </Box>
                        <Box
                          sx={{
                            width: "18%",
                            fontFamily: "Product Sans",
                            fontWeight: "400",
                          }}
                        >
                          {item?.cp_type}
                        </Box>
                        <Box
                          sx={{
                            width: "18%",
                            fontFamily: "Product Sans",
                            fontWeight: "400",
                          }}
                        >
                          {item?.profile?.date_of_joining
                            ? item?.profile?.date_of_joining
                            : "Pending"}
                        </Box>

                        <Box
                          sx={{
                            width: "10%",
                            display: "flex",
                            justifyContent: "center",
                          }}
                        >
                          <Stack direction="row">
                            <EditIcon
                              fontSize="medium"
                              sx={{cursor:"pointer"}}
                              onClick={(e) => {
                                e.stopPropagation();
                                navigate("/channelpartner/teams/member", {
                                  state: { member: item },
                                });
                              }}
                            />
                          </Stack>
                        </Box>
                      </Box>
                    );
                  }
                })}
            </Box>
            <Box sx={{ margin: "50px 0px 20px 0px" }}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "right",
                  marginRight: "20px",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    placeItems: "center",
                    margin: " 0 10px",
                    borderRadius: "5px",
                  }}
                >
                  <Typography sx={{}}>Page</Typography>
                  <Typography
                    sx={{
                      background: `white`,
                      color: "black",
                      width: "60px",
                      height: "30px",
                      borderRadius: "4px",
                      margin: "0 10px",
                      display: "grid",
                      placeItems: "center",
                      border: "1px solid #2979F2",
                    }}
                  >
                    {curpage}
                  </Typography>
                  <Typography
                    sx={{
                      color: "black",
                      width: "30px",
                      borderRadius: "5px",
                      margin: "0 0px",
                      display: "grid",
                      placeItems: "center",
                    }}
                  >
                    of
                  </Typography>
                  <Typography
                    sx={{
                      color: "black",
                      margin: "0px 10px 0px 0px",
                      display: "grid",
                      placeItems: "center",
                    }}
                  >
                    {NoPages}
                  </Typography>
                </Box>

                {curData.length != 0 && (
                  <Button
                    variant="contained"
                    className="paginationButton"
                    disabled={curpage == 1}
                    sx={{ marginRight: "10px" }}
                    onClick={prvClick}
                  >
                    <KeyboardDoubleArrowLeftIcon fontSize="medium" />
                  </Button>
                )}

                {curData.length != 0 && (
                  <Button
                    variant="contained"
                    className="paginationButton"
                    disabled={curpage == NoPages}
                    onClick={nextClick}
                  >
                    <KeyboardDoubleArrowRightIcon fontSize="medium" />
                  </Button>
                )}
              </Box>
            </Box>
          </>
        )}
      </Box>
    </Box>
  );
}

export default TDcustomTable;
