

import React, { useContext, useEffect } from "react";
import { TextField } from "@mui/material";
import { Link, useLocation } from "react-router-dom";
import { useState } from "react";

import { Box, Button, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import DoneIcon from "@mui/icons-material/Done";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import KeyboardDoubleArrowLeftIcon from "@mui/icons-material/KeyboardDoubleArrowLeft";
import { useNavigate } from "react-router-dom";
import Divider from "@mui/material/Divider";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import axios from "axios";
import { Apiaddress } from "../../../../utils/consts";

import currentProjectState from '../../../../context/createproject/context';
import { SuperAdminSidebar } from "../../SidebarNavbar/SuperAdminSidebar";
import { SuperAdminNavbar } from "../../SidebarNavbar/SuperAdminNavbar";
import { SAProjectSidebar } from "../../SidebarNavbar/SAProjectSidebar";

export const ConfirmProject_Wing_DetailsSA = ()=>{
    
  const cpContext = useContext(currentProjectState);
  const projectID = cpContext?.createProjectState?.project?.id;
    
  ////get data from previous page 
  const location = useLocation();
  let data = location.state.state;
  let building_data = location.state.data;

  console.log("preve data =>.>",data)
  console.log("current data =>.>",building_data)

  const navigate = useNavigate();

  const [floornumber, setfloornumber] = useState(5);
  const [wingname, setwingname] = useState(data?.wing_name);

  const [floordata, setfloordata] = useState([]);

  const [floortemp, setfloortemp] = useState([]);


  const addfloordata = () => {
    let temp = floordata.length + 1;
    let to_insert = {
      floor_no: temp,
      floor_name: "",
    };
    setfloordata([...floordata, to_insert]);
    setfloornumber(floordata.length + 1);
  };

  const removefloordata = (newfloordata, index) => {
    for (let i = index; i < newfloordata.length; i++) {
      newfloordata[i].floor_no = newfloordata[i].floor_no - 1;
    }
    // console.log(newbedroomdata);
    setfloordata(newfloordata);
    setfloornumber(floordata.length - 1);
  };

  // useEffect(()=>{

  //   let data=[];
  //   for(let i=1;i<=floornumber;i++){
  //     let to_insert={
  //       floor_no:i,
  //       floor_name:""
  //     }
  //     data.push(to_insert);
  //     }
  //   setfloordata(data);

  // },[floornumber]);


  ///get wing data by id 

  useEffect(()=>{
    getWingsData()
  },[])
  const getWingsData = ()=>{
    let url = Apiaddress+"/api/projects/wingtemplate/?wing_template_id="+data?.wing_id;

    axios.get(url)
    .then((res)=>{
      console.log(res.data);
      setfloornumber(res.data?.wing_template[0]?.floortemplate?.length)
      setfloordata(res.data?.wing_template[0]?.floortemplate)
    }).catch((err)=>{
      console.log(err);
    })
  }

  ////get alll floor templates 
   useEffect(()=>{
    const floorurl = Apiaddress+"/api/projects/floortemplatesbyproject/?project_id="+projectID

    axios.get(floorurl)
    .then((res)=>{
      console.log(res.data);
      setfloortemp(res.data?.floor_templates)
    }).catch((err)=>{
      console.log(err);
    })
   },[])


   const updateWingsTemplate = ()=>{
     
     let url = Apiaddress+"/api/projects/wingtemplate/";
  
    let st = {
      wing_template_id:data?.wing_id,
      floortemplate:floordata
    }

    let headers = {
      headers:{
        "Content-Type":"application/json"
    }
    }
    console.log(st); 
    
    axios.patch(url,st,headers)
    .then((res)=>{
      console.log(res);
      // navigate("/confirm-project-wings")
    })
    .catch((err)=>{
      console.log(err);
    })

   }
    return (
        <>
                
<Stack direction="row" sx={{width:"100%",height:"auto" }}>
      {/* 1st stack */}
      <Stack sx={{minHeight:"100vh",width:"20%",boxSizing:"border-box",}}> {/*for project directory 20% and for sales dashboard 225*/}
        {/* <SuperAdminSidebar active={"Builder Directory"}/> */}
        <SAProjectSidebar active={"Project Dashboard"}/>
      </Stack>

     {/* 2nd stack */}
     <Stack direction="column" sx={{ backgroundColor:"#f9f9f9",width:"80%", padding:"25px",boxSizing:"border-box",}}  >  {/*  width for main sidebar 78px and for 80px*/}
      <SuperAdminNavbar />  {/*send props for get popup to verify otp from builder */}
      


      <Typography sx={{marginTop:"20px", fontSize:"28px", fontWeight:"600", marginLeft:"10px", marginBottom:"50px"}}>
           Wing Template Details
            </Typography>
     {/* actual content  */}
    <Stack sx={{backgroundColor:"#f9f9f9"}}>  {/* mt:"28px", */}

     
    <Stack
            direction="column"
            sx={{
              padding: "20px",
              backgroundColor: "#fff",
              marginRight: "0px",
            }}
          >
            {/* Wing Template */}
            <Box sx={{}}>
              <Box sx={{ paddingBottom: "40px" }}>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    margin: "30px 0px 0px 0px",
                  }}
                >
                  <Typography component="span" className="field-name">
                    Wing Template Name
                  </Typography>
                  <TextField
                    disabled
                    sx={{
                      width: "188px",
                      marginLeft: "40px",
                    }}
                    value={wingname}
                  />
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    margin: "30px 0px 0px 0px",
                  }}
                >
                  <Typography component="span" className="field-name">
                    No of Floors in Wing{" "}
                  </Typography>
                  <TextField
                    inputProps={{ min: 0 }}
                    disabled
                    sx={{
                      width: "188px",
                      marginLeft: "40px",
                    }}
                    type="Number"
                    value={floornumber}
                  />
                </Box>

                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  sx={{
                    height: "50px",
                    background:
                      "linear-gradient(90deg, #0F5DAE 0%, #009DCF 100.33%)",
                    boxShadow:
                      "0px 13.7981px 22.5788px rgba(50, 174, 137, 0.25)",
                    textTransform: "none",
                    borderRadius: "4px",
                    width: "150px",
                    fontSize: "15px",
                    margin: "30px 0px 0px 0px",
                  }}
                  onClick={addfloordata}
                >
                  <AddIcon sx={{ marginRight: "10px" }} />
                  Add Floor
                </Button>
              </Box>

              <Divider />
              <Box>
                {floordata.map((ele, index) => {
                  return (
                    <Box
                      key={index}
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        margin: "30px 0px 0px 70px",
                      }}
                    >
                      <Typography component="span" className="field-name">
                        Floor {ele.floor_no}
                      </Typography>

                      <FormControl sx={{ width: "188px", marginLeft: "40px" }}>
                        <Select
                          value={ele.floor_name+"-"+ele.floor_id}
                          onChange={(e) => {
                            let arr = floordata;
                            let [name,id] = e.target.value.split("-");

                            arr[index].floor_name = name;
                            arr[index].floor_id = +id;
                            setfloordata([...arr]);

                              // console.log(e.target.value.split('-'));return;
                      // let arr = floordata;
                      // arr[index].floor_name = name;
                      // arr[index].floor_id = +id;
                      //  setfloordata([...arr])
                          }}
                          displayEmpty
                          inputProps={{ "aria-label": "Without label" }}
                        >
                          {floortemp.map((ele, index) => {
                            return (
                              <MenuItem key={index} value={ele.name+"-"+ele.id}>
                                {ele.name}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </FormControl>

                      <DeleteIcon
                        sx={{
                          fontSize: "26px",
                          fontWeight: "500",
                          color: "#EF3649",
                          margin: "0px 0px 0px 20px",
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          const newfloordata = floordata.filter(
                            (x) => x !== ele
                          );
                          console.log(newfloordata);
                          removefloordata(newfloordata, index);
                        }}
                      />

                      {/* <Typography className='' sx={{margin:"0px 0px 0px 20px",fontFamily:'Poppins',fontStyle:"normal",fontWeight:"500",fontSize: "12px",lineHeight:"18px",color:"#A2A3A6"}}>Can't Find The Template?</Typography>
                <Typography className='' sx={{margin:"0px 0px 0px 5px",fontFamily:'Poppins',fontStyle:"normal",fontWeight:"500",fontSize: "12px",lineHeight:"18px",color:"#FF6140",cursor:"pointer"}} onClick={()=>{navigate('/floortemplate')}}>Create New</Typography> */}
                    </Box>
                  );
                })}

                {floornumber > 0 && (
                  <>
                   <Link
                   to="/super-admin-confirm-project-wing" state={building_data} >
                  <Button
                     onClick={() => {
                      updateWingsTemplate()
                    }}
                    variant="contained"
                    color="primary"
                    sx={{
                      height: "50px",
                      background:
                        "linear-gradient(90deg, #0F5DAE 0%, #009DCF 100.33%)",
                      boxShadow:
                        "0px 13.7981px 22.5788px rgba(50, 174, 137, 0.25)",
                      textTransform: "none",
                      borderRadius: "4px",
                      width: "150px",
                      fontSize: "15px",
                      margin: "100px 0px 70px 70px",
                    }}
                  >
                    Save
                  </Button>
              </Link>
                  </>
                )}
              </Box>
            </Box>
          </Stack>   

    </Stack>
      </Stack>
</Stack>
        </>
    )
}