import { Box, Container, Stack, Typography } from "@mui/material";
import ChannelPartnerManagement from "../../Imgs/channel-partner-management.png";
import CircleCheckIconWhite from "../../Imgs/circle-check-icon.svg";
import InventoryManagement from "../../Imgs/inventory-management.png";

const CommonPagesInventoryManagement = () => {
  return (
    <Box sx={{ backgroundColor: "#FFF", overflowX: "hidden" }}>
      <Container>
        <Box
          sx={{
            display: "flex",
            gap: { xs: "10px", md: "30px" },
            alignItems: "center",
            flexDirection: { xs: "column", md: "row" },
          }}
        >
          <Box
            data-aos="zoom-in"
            data-aos-offset="180"
            sx={{ width: { xs: "100%", md: "45%" } }}
          >
            <img style={{ width: "100%" }} src={InventoryManagement} alt="" />
          </Box>
          <Stack sx={{ width: { xs: "100%", md: "55%" } }}>
            <Typography
              data-aos="fade-left"
              sx={{
                fontSize: { xs: "24px", md: "32px", lg: "40px" },
                fontWeight: 700,
                color: "#272D4E",
                fontFamily: "Circular Std",
                lineHeight: "1.2",
              }}
            >
              Inventory Management
            </Typography>
            <Stack spacing={2}>
              <Typography
                sx={{
                  fontSize: "18px",
                  color: "#343434",
                  fontFamily: "Product Sans",
                }}
              >
                Set up, manage, and track your inventory across all your
                projects in real-time
              </Typography>
              <Box sx={{ display: "flex", gap: "10px" }}>
                <Box sx={{ lineHeight: "10px", fontSize: "30px" }}>
                  {/* <img src={CircleCheckIcon} alt="" /> */}
                  &#x2022;
                </Box>
                <Typography
                  sx={{
                    fontSize: "16px",
                    fontFamily: "Product Sans",
                    color: "#343434",
                    lineHeight: "1.3",
                  }}
                >
                  <span style={{ fontWeight: 600 }}>Easily setup</span>{" "}
                  hassle-free inventory for each project
                </Typography>
              </Box>
              <Box sx={{ display: "flex", gap: "10px" }}>
                <Box sx={{ lineHeight: "10px", fontSize: "30px" }}>
                  {/* <img src={CircleCheckIcon} alt="" /> */}
                  &#x2022;
                </Box>
                <Typography
                  sx={{
                    fontSize: "16px",
                    fontFamily: "Product Sans",
                    color: "#343434",
                    lineHeight: "1.3",
                  }}
                >
                  Track
                  <span style={{ fontWeight: 600 }}> inventory status, </span>
                  get snapshots in time, manage pricing and unit from a central
                  dashboard
                </Typography>
              </Box>
              <Box sx={{ display: "flex", gap: "10px" }}>
                <Box sx={{ lineHeight: "10px", fontSize: "30px" }}>
                  {/* <img src={CircleCheckIcon} alt="" /> */}
                  &#x2022;
                </Box>
                <Typography
                  sx={{
                    fontSize: "16px",
                    fontFamily: "Product Sans",
                    color: "#343434",
                    lineHeight: "1.3",
                  }}
                >
                  Generate{" "}
                  <span style={{ fontWeight: 600 }}>
                    custom pricing quotations,
                  </span>{" "}
                  set expiry dates, and be more specific to leads
                </Typography>
              </Box>
              <Box sx={{ display: "flex", gap: "10px" }}>
                <Box sx={{ lineHeight: "10px", fontSize: "30px" }}>
                  {/* <img src={CircleCheckIcon} alt="" /> */}
                  &#x2022;
                </Box>
                <Typography
                  sx={{
                    fontSize: "16px",
                    fontFamily: "Product Sans",
                    color: "#343434",
                    lineHeight: "1.3",
                  }}
                >
                  See{" "}
                  <span style={{ fontWeight: 600 }}>
                    trends for critical metrics
                  </span>{" "}
                  on inventory status for each project
                </Typography>
              </Box>
            </Stack>
          </Stack>
        </Box>
      </Container>
    </Box>
  );
};

export default CommonPagesInventoryManagement;
