import { Box } from '@mui/system'
import React, { useState } from 'react'

const TabBar = (
    { Tabs,floatingActonButton}:{
        Tabs:TabData[],
        floatingActonButton?:React.ReactElement
    }
) =>  {
    if(Tabs.length == 0){
        throw "noTabsProvided"
    }
    const [SelectedTab, setSelectedTab] = useState(0);

    return (
    <Box sx={{
        color:"black",
        padding:"20px"
    }}>
        <Box 
            sx={{
                display:"flex",
                flexDirection:"row",              
                justifyContent:"space-between"
            }}
        >
            <Box sx={{
                display:"flex",
                flexDirection:"row",                   
            }}
            >
            {Tabs.map((e,i)=>{
                const selected =  SelectedTab === i
                return <Box
                sx={{
                    borderRadius:"10px 10px 0px 0px",
                    padding:"15px 70px 15px 70px",
                    border:"1px",
                    borderStyle: selected?"solid":'none',
                        borderColor: selected?"#0F5DAE": "#DFDFDF",
                        backgroundColor: selected?"white":"#E0E0E0"
                    }}
                    onClick={(b)=>{
                        setSelectedTab(i)  
                    }}>
                {e.Name}
                </Box>
            })}
            </Box>
            {floatingActonButton}
        </Box>
        <Box sx={{
         
        
            border:"1.44px solid #0F5DAE"
        }}>
            {Tabs[SelectedTab].children}

        </Box>
    </Box>
    )
}

export default TabBar

interface TabData{
    Name:String,
    children:React.ReactElement[]
}
