import { Box, Button, Stack, TextField, Typography } from '@mui/material'
import SearchRoundedIcon from '@mui/icons-material/SearchRounded';
import { useContext, useEffect, useRef, useState } from 'react'
import './carousel.css'
import { CountrySelector } from 'volkeno-react-country-state-city';
import builderDashboard from '../../../../context/builder_dashboard/context';
import { Apiaddress } from '../../../../utils/consts';
import { spacing } from '@mui/system';
import animationData from "../../../../Lotties/NEW sin movs.json";
import CircularProgress from "@mui/material/CircularProgress";
import Lottie from "react-lottie";


const ListProjects = ({update, setUpdate}) => {
    const [data ,setData ] = useState([]);  
    //for search functionality  
    const [searchData, setsearchdata] = useState([])
    const [loading, setloading] = useState(false);

    const OrganizationsContext = useContext(builderDashboard)
    const previousState = JSON.parse(localStorage.getItem('BuilderDashboard'))
    console.log("=================>",OrganizationsContext.BuilderDashboardState)
    // console.log("=================>",previousState)
    // OrganizationsContext.BuilderDashboardState

    const fetchData=async ()=>{
        setloading(true);
        let newDat = [];
        OrganizationsContext.BuilderDashboardState?.map(e => {   
          if (e?.selected) {
              console.log(e.projects);
              e.Projects?.map((ele,i)=>{
                  console.log("img",ele?.project.banner_image)
                  if (OrganizationsContext.setSelectedProjectId === undefined){
                      const projectID = e.Projects[i].project.id;
                      OrganizationsContext.setSelectedProjectId(projectID);
                  }
                  return  {
                      banner_img:ele?.project?.banner_image,
                      id:e?.Projects[i].project.id,
                      img: "https://images.unsplash.com/photo-1618505497364-f97e23c8b70a?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=386&q=80",
                      name:e?.Projects[i].project.name,
                      city:e?.Projects[i].project.city,
                  }
             })?.map(b=>newDat.push(b))
             setData(newDat)
             setsearchdata(newDat)
          }});
        setloading(false);
    }

    useEffect(() => {
       console.log(OrganizationsContext);
       if(OrganizationsContext.BuilderDashboardState){
        fetchData();
       }
    }, [update]);
    
    
    
    const [selectedProject,setSelectedProject] = useState(0)
    

    ///searching function 
    const [input, setinput] = useState("");
    const searchProject = (val)=>{
        setloading(true);
        if(val===""){
            setData(searchData)
            return;
        }
        let value = val.toLowerCase()
        let result = searchData.filter((data) => {
            return data?.name?.toLowerCase().includes(value) === true ;  ////data.search(value)!==-1
            });
        setData([...result])
        setloading(false);
    }

    return (
        //root big card
        <Box sx={{
            // minHeight: 500,
            // margin: 1,
            padding: "30px",
            backgroundColor: "#FFF",
            border: "2px solid transparent",
            "&:hover": {
            boxShadow: "-6px 18px 15px rgba(0, 0, 0, 0.1)",
          },
        }}>
        
            {/* <Box sx={{
                color: "#0065FE",
                fontSize: 20,
                fontWeight: 700,
                display:"flex",
                border:"1px solid green"
            }}>
                My Projects
            </Box> */}

            <Carousel 
            children={data.map((e,i) =>

                <Box
                    onClick={()=>{
                        setUpdate(!update)
                        setSelectedProject(i); 
                        OrganizationsContext.setSelectedProjectId(e.id);
                        let builderSelected = JSON.parse(localStorage.getItem("BuilderSelected"));
                        let data = {
                            selectedProjectID:e.id,
                            projectName:e.name
                          }
                            if(builderSelected){
                                localStorage.setItem("BuilderSelected", JSON.stringify({...builderSelected,...data}))
                              }else{
                                localStorage.setItem("BuilderSelected", JSON.stringify(data))
                            }
                    }}
                    sx={{
                    // width:  "23%",
                    // height: "300px",
                    width: "200px",
                    height: "300px",
                    backgroundColor: "#fff",
                    margin: "25px 15px",
                    padding: "15px",
                    color: "#272D4E",
                    border: "2px solid transparent",
                    "&:hover": {
                    cursor: "pointer",
                    // border: "2px solid #2979F2",
                    boxShadow: "-6px 18px 15px rgba(0, 0, 0, 0.1)"
                    },
                    // backgroundColor: selectedProject == i ?null:"white",
                    // backgroundImage: selectedProject == i ? " linear-gradient(90deg, #0F5DAE 0%, #009DCF 100.33%)":null,
                    // backgroundImage: selectedProject == i ? " linear-gradient(90deg, #0F5DAE 0%, #009DCF 100.33%)":null,
                    // boxShadow: selectedProject == i ?"-6px 18px 15px rgba(0, 0, 0, 0.1)":null,
                    // border: selectedProject == i ?"2px solid #2979F2":"1px solid lightgray",
                    // backgroundImage: selectedProject == i ? " linear-gradient(90deg, #0F5DAE 0%, #009DCF 100.33%)":null,
                    // borderRadius:"6px",
                    // margin: "5px",
                    // padding: "10px",
                    // display: "flex",
                    // flexDirection: "column",
                    // justifyContent: "center",
                    // alignItems: "center",
                    // boxSizing:"border-box"
                }}>
                    <Box sx={{}}>
                        <img
                            // src={"http://139.59.70.194/media/projectbanner/Screenshot_5.png"}
                            src={e.banner_img?`${Apiaddress}${e.banner_img}`:e.img}
                            style={{
                                width: "100%",
                                height: "180px",
                                borderRadius: "10px"     
                            }} />
                        <Box sx={{
                            fontFamily: "Circular Std",
                            fontSize: "18px",
                            mt: "20px",
                            // fontFamily: 'Circular Std',
                            // fontStyle:"normal",
                            // fontWeight:selectedProject == i?"700":"400",
                            // ineHeight:"23px",
                            // fontSize: 18,
                            // color: selectedProject == i?"#272D4E":"#9299BC",
                            // padding: 1,
                        }}>
                            {e.name}
                        </Box>
                        <Box sx={{
                            fontFamily: "Product Sans",
                            fontSize: "14px",
                            mt: "5px",
                            color: "#9299BC",
                            // fontFamily: 'Circular Std',
                            // fontStyle:"normal",
                            // fontWeight:selectedProject == i?"700":"400",
                            // ineHeight:"23px",
                            // fontSize: 18,
                            // color: selectedProject == i?"#272D4E":"#9299BC",
                            // padding: 1,
                        }}>
                            {e.city}
                        </Box>
                    </Box>
                </Box>)
            }
            searchProject={searchProject}
            data={data}
            loading={loading}
            setloading={setloading}
            />

        </Box>
    )
}

export default ListProjects


const Carousel = (props) => {
    const { children, searchProject, data,loading,setloading } = props;
    const [offset,setOffset] = useState(0);
    const [touchPosition, setTouchPosition] = useState(null)

    /// show all projects 
    const [showall, setshowAll] = useState(false);

    // Set the length to match current children from props



    const next = () => {
        if ((children.length-1)*200 > offset) {
            setOffset(prevState => prevState + 200)
        }
    }

    const prev = () => {
        if (offset > 0) {
            setOffset(prevState => prevState - 200)
        }
    }

    const handleTouchStart = (e) => {
        const touchDown = e.touches[0].clientX
        setTouchPosition(touchDown)
    }

    const handleTouchMove = (e) => {
        const touchDown = touchPosition

        if (touchDown === null) {
            return
        }

        const currentTouch = e.touches[0].clientX
        const diff = touchDown - currentTouch

        if (diff > 5) {
            next()
        }

        if (diff < -5) {
            prev()
        }

        setTouchPosition(null)
    }

    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: animationData,
        rendererSettings: {
          preserveAspectRatio: "xMidYMid slice",
        },
    };

    return (
        <>
            <Box sx={{
                // height: 100,
                width: "100%",
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems:"center",
                color: "#0065FE",
                marginBottom:"15px",
            }}>

            <Stack direction="column">
                <Typography
                    className=""
                    sx={{
                        color: "#4E5A85",
                        fontSize: "24px",
                        fontWeight: "500",
                        display: "flex",
                        fontFamily: "Circular Std",
                    }}
                >
                My Projects
                </Typography>
                <Typography
                  sx={{
                    color: "#B9B7D0",
                    fontSize: "16px",
                    fontWeight: "400",
                  }}
                    >
                To get started, please select the desired project from the list
                </Typography>
            </Stack>

                <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "flex-start", alignItems: "end"}}>

                    <Box sx={{ paddingRight:"10px", cursor:"pointer",fontFamily:"Product Sans",fontSize:"18px",color: "#0085FF",paddingBottom: "10px",
                    fontWeight: "400"}}
                    onClick={()=>{
                        setshowAll(!showall)
                    }}
                    >
                        {showall?"Show Less":"Show All"}
                    </Box>
                    <Box sx={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "end",
                        alignItems: "center",
                        color: "#0065FE",
                    }}>
                        <TextField 
                            sx={{
                                // border: "2px solid #2979F2",
                                marginRight: "20px",
                                borderRadius: "8px",
                                "& .MuiInputBase-root": {
                                  "& fieldset": {
                                    borderColor: "#2979F2",
                                    borderRadius: "8px",
                                  },
                                  "&:hover fieldset": {
                                    borderColor: "#2979F2",
                                  },
                                },
                            }}
                            onChange={(e)=>{
                                searchProject(e.target.value)
                            }}
                            variant="outlined"
                            placeholder="Search"
                            InputProps={{
                                disableUnderline: true,
                                startAdornment: <SearchRoundedIcon sx={{ color: "#2979F2", margin: 1}} />
                            }} />
                            
                            {!showall && (
                            <Button
                                onClick={prev}
                                sx={{
                                    minWidth: 40,
                                    height: 30,
                                    border: "1px solid #0065FE",
                                    borderRadius: 2,
                                    margin: 1,
                                }}
                            >
                            <svg
                  width="9"
                  height="16"
                  viewBox="0 0 6 10"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M5.1646 9.28016C4.97597 9.28016 4.78734 9.21015 4.63843 9.06015L1.1339 5.53016C0.845992 5.24016 0.845992 4.76016 1.1339 4.47016L4.63843 0.940156C4.92633 0.650156 5.40287 0.650156 5.69078 0.940156C5.97869 1.23016 5.97869 1.71016 5.69078 2.00016L2.71243 5.00016L5.69078 8.00016C5.97869 8.29016 5.97869 8.77015 5.69078 9.06015C5.55179 9.21015 5.36316 9.28016 5.1646 9.28016Z"
                    fill="#2979F2"
                  />
                </svg>
              </Button>
            )}

            {!showall && (
              <Button
                onClick={next}
                sx={{
                  minWidth: 40,
                  height: 30,
                  border: "1px solid #0065FE",
                  borderRadius: 2,
                  margin: 1,
                }}
              >
                <svg
                  width="9"
                  height="16"
                  viewBox="0 0 6 10"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1.48869 9.28016C1.30006 9.28016 1.11143 9.21015 0.962513 9.06015C0.674605 8.77015 0.674605 8.29016 0.962513 8.00016L3.94086 5.00016L0.962513 2.00016C0.674605 1.71016 0.674605 1.23016 0.962513 0.940156C1.25042 0.650156 1.72696 0.650156 2.01486 0.940156L5.51939 4.47016C5.8073 4.76016 5.8073 5.24016 5.51939 5.53016L2.01486 9.06015C1.86595 9.21015 1.67732 9.28016 1.48869 9.28016Z"
                    fill="#2979F2"
                  />
                </svg>
              </Button>
            )}
                    </Box>
                </Box>
            </Box>


             {!showall &&  
            <Box className="carousel-container" sx={{minHeight:"120px"}}>
                <Box className="carousel-wrapper" sx={{}}>
                    <Box
                        className="carousel-content-wrapper"
                        onTouchStart={handleTouchStart}
                        onTouchMove={handleTouchMove}>
                        <Box
                            className="carousel-content"
                            style={{ transform: `translateX(-${offset}px)` }}
                            sx={{color:"black"}}
                            >
                            {children}
                            {/* {data ? ( */}
                               {data?.length!==0 ? null : (
                                <>
                                {loading ? (
                                <>
                                <Box sx={{ textAlign: "center", marginTop: "40px",width:"100%"}}>
                                    <CircularProgress
                                        color="inherit"
                                        sx={{ color: "grey.500" }}
                                    />
                                </Box>
                                </>
                                ):
                                <Stack
                      direction="column"
                      alignItems="center"
                      justifyContent="center"
                      sx={{ color: "gray", width: "100%",height:"100%"}}
                      >
                      <Stack sx={{height:"100%"}}>
                      <Lottie options={defaultOptions} height="100%" width="30%" />
                      </Stack>
                      <Typography
                        sx={{
                          color: "#B9B7D0",
                          fontSize: "18px",
                          fontWeight: "400",
                          marginTop: "-30px",
                        }}
                      >
                      Please Add Your Project First
                      </Typography>
                    </Stack>}
                </>
                )}

                {/* {data[0]?null:<Stack direction="column" alignItems="center" justifyContent="center" sx={{color:"gray", width:"100%"}}>Data Not Found!</Stack>} */}
                       
            </Box>
            </Box>
            </Box>
            </Box>
            }

{showall && (
        <Box
          sx={{
            width: "100%",
            minHeight: "200px",
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "start",
            gap: "10px",
          }}
        >
          {children}
          {/* {data[0] ? null : ( */}
          {data?.length!==0 ? null : (
            <>
            {loading ? (
            <>
              <Box sx={{ textAlign: "center", marginTop: "40px",width:"100%"}}>
                <CircularProgress
                  color="inherit"
                  sx={{ color: "grey.500" }}
                />
              </Box>
            </>
            ):<Stack
                direction="column"
                justifyContent="center"
                alignItems="center"
                sx={{ color: "gray", width: "100%" }}
                >
                <Stack sx={{height:"100%"}}>
                  <Lottie options={defaultOptions} height="100%" width="30%" />
                </Stack>
                <Typography
                  sx={{
                    color: "#B9B7D0",
                    fontSize: "18px",
                    fontWeight: "400",
                    marginTop: "-30px",
                  }}
                  >
                Please Add Your Project First
                </Typography>
            </Stack>}
            </>
          )}
        </Box>
      )}
    </>
    )
}

export { Carousel };