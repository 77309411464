import { Typography } from "@mui/material";
import Box from "@mui/material/Box";
import { Stack } from "@mui/system";
import Button from "@mui/material/Button";
import "./Signup.css";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import KeyboardDoubleArrowLeftIcon from "@mui/icons-material/KeyboardDoubleArrowLeft";
import { EmailPassNo } from "./SignupSubComponents/EmailPassNo";
import { Otp } from "./SignupSubComponents/Otp";
import { useState } from "react";
import { Organization } from "./SignupSubComponents/Organization";
import { CompleteBuilderSignup } from "./SignupSubComponents/CompleteBuilderSignup";
import DoneIcon from "@mui/icons-material/Done";
import { PasswordCreate } from "./SignupSubComponents/PasswordCreate";

export const SignupBuilder = () => {
  const [signupdata, setSignupdata] = useState({
    email: "",
    phone: "",
    name: "",
  });

  const steps = ["Profile", "Verification", "Organization Details", "Complete"];   

  const [stepcolor, setStepcolor] = useState("#FFA946");
  const [stepsno, setStepsno] = useState(0);

  return (
    <>
      <Box
        sx={{
          display: "flex",
        }}
      >
        <Box
          sx={{
            width: "25%",
            backgroundColor: "#F4F6FB",
            height: "100vh",
            textAlign: "left",
            padding: "28px 30px",
            boxSizing: "border-box",
          }}
        >
          <Box sx={{}}>
            <Typography
              sx={{ fontWeight: "600", fontSize: "2vw", lineHeight: "41px" }}
            >
              Envision
            </Typography>
          </Box>
          <Box sx={{ marginTop: "20vh" }}>
            {/* {steps.map((step,index)=>{
                return(
                    <> */}
            {/* step 1 */}
            <Stack
              direction="row"
              justifyContent="left"
              alignItems="center"
              spacing={2}
            >
              <Box
                className="stepper_signup"
                sx={{ backgroundColor: stepsno >= 0 ? "#FFA946" : "#D9D9D9" }}
              >
                {stepsno >= 0 ? <DoneIcon sx={{ color: "#fff" }} /> : 1}
              </Box>
              <Typography sx={{ fontSize: "1.1vw",fontWeight: "500"}}>
                Profile
              </Typography>
            </Stack>
            {/* color> #FFA946 */}
            <Box>
              <Box
                sx={{
                  borderLeft:
                    stepsno > 0
                      ? "2.5px #FFA946 dashed"
                      : "2.5px Transparent dashed",
                }}
                className="vertical_line"
              ></Box>
            </Box>
            <Box>
              <Box
                sx={{
                  width: "6px",
                  height: "6px",
                  marginLeft: "16.2px",
                  marginBottom: "2px",
                  borderRadius: "50%",
                  backgroundColor: stepsno > 0 ? "#FFA946" : "transparent",
                }}
              ></Box>
            </Box>

            {/* step 2 */}
            <Stack
              direction="row"
              justifyContent="left"
              alignItems="center"
              spacing={2}
            >
              <Box
                className="stepper_signup"
                sx={{ backgroundColor: stepsno >= 1 ? "#FFA946" : "#D9D9D9" }}
              >
                {stepsno >= 1 ? <DoneIcon sx={{ color: "#fff" }} /> : 2}
              </Box>
              <Typography sx={{ fontSize: "1.1vw", fontWeight: "500" }}>
                Verification
              </Typography>
            </Stack>
            {/* color> #FFA946 */}
            <Box>
              <Box
                sx={{
                  borderLeft:
                    stepsno > 1
                      ? "2.5px #FFA946 dashed"
                      : "2.5px Transparent dashed",
                }}
                className="vertical_line"
              ></Box>
            </Box>
            <Box>
              <Box
                sx={{
                  width: "6px",
                  height: "6px",
                  marginLeft: "16.2px",
                  marginBottom: "2px",
                  borderRadius: "50%",
                  backgroundColor: stepsno > 1 ? "#FFA946" : "transparent",
                }}
              ></Box>
            </Box>

            {/* step 3 */}
            <Stack
              direction="row"
              justifyContent="left"
              alignItems="center"
              spacing={2}
            >
              <Box
                className="stepper_signup"
                sx={{ backgroundColor: stepsno >= 2 ? "#FFA946" : "#D9D9D9" }}
              >
                {stepsno >= 2 ? <DoneIcon sx={{ color: "#fff" }} /> : 3}
              </Box>
              <Typography sx={{ fontSize: "1.1vw", fontWeight: "500" }}>
                Organization Details
              </Typography>
            </Stack>
            {/* color> #FFA946 */}

            <Box>
              <Box
                sx={{
                  borderLeft:
                    stepsno > 2
                      ? "2.5px #FFA946 dashed"
                      : "2.5px Transparent dashed",
                }}
                className="vertical_line"
              ></Box>
            </Box>
            <Box>
              <Box
                sx={{
                  width: "6px",
                  height: "6px",
                  marginLeft: "16.2px",
                  marginBottom: "2px",
                  borderRadius: "50%",
                  backgroundColor: stepsno > 2 ? "#FFA946" : "transparent",
                }}
              ></Box>
            </Box>

            {/* step 4 */}
            <Stack
              direction="row"
              justifyContent="left"
              alignItems="center"
              spacing={2}
            >
              <Box
                className="stepper_signup"
                sx={{ backgroundColor: stepsno >= 3 ? "#FFA946" : "#D9D9D9" }}
              >
                {stepsno >= 3 ? <DoneIcon sx={{ color: "#fff" }} /> : 4}
              </Box>
              <Typography sx={{ fontSize: "1.1vw", fontWeight: "500" }}>
                Setup Password
              </Typography>
            </Stack>
            {/* color> #FFA946 */}

            <Box>
              <Box
                sx={{
                  borderLeft:
                    stepsno > 3
                      ? "2.5px #FFA946 dashed"
                      : "2.5px Transparent dashed",
                }}
                className="vertical_line"
              ></Box>
            </Box>
            <Box>
              <Box
                sx={{
                  width: "6px",
                  height: "6px",
                  marginLeft: "16.2px",
                  marginBottom: "2px",
                  borderRadius: "50%",
                  backgroundColor: stepsno > 3 ? "#FFA946" : "transparent",
                }}
              ></Box>
            </Box>

            {/* step 5 */}
            <Stack
              direction="row"
              justifyContent="left"
              alignItems="center"
              spacing={2}
            >
              <Box
                className="stepper_signup"
                sx={{ backgroundColor: stepsno == 4 ? "#FFA946" : "#D9D9D9" }}
              >
                {stepsno == 4 ? <DoneIcon sx={{ color: "#fff" }} /> : 5}
              </Box>
              <Typography sx={{ fontSize: "1.1vw", fontWeight: "500" }}>
                Complete
              </Typography>
            </Stack>

            {/* </>
                )
            })} */}
          </Box>
        </Box>

        <Stack
          direction="column"
          alignItems="stretch"
          justifyContent="space-between"
          sx={{ width: "75%", height: "100vh", overflowY: "scroll" }}
        >
          <Stack
            direction="row"
            justifyContent="right"
            spacing={2}
            alignItems="center"
            sx={{ height: "15vh", paddingRight: "20px" }}
          >
            <Typography>
              Having trouble? <span style={{ color: "#FFA946" }}>Get Help</span>{" "}
            </Typography>
          </Stack>

          {stepsno == 0 && (
            <EmailPassNo
              stepsno={stepsno}
              setStepsno={setStepsno}
              signupdata={signupdata}
              setSignupdata={setSignupdata}
            />
          )}
          {stepsno == 1 && (
            <Otp
              stepsno={stepsno}
              setStepsno={setStepsno}
              signupdata={signupdata}
              setSignupdata={setSignupdata}
            />
          )}
          {stepsno == 2 && (
            <Organization
              stepsno={stepsno}
              setStepsno={setStepsno}
              signupdata={signupdata}
              setSignupdata={setSignupdata}
            />
          )}
          {stepsno == 3 && (
            <PasswordCreate
              stepsno={stepsno}
              setStepsno={setStepsno}
              signupdata={signupdata}
              setSignupdata={setSignupdata}    
            />
          )}
          {stepsno == 4 && <CompleteBuilderSignup />}

          {/* <Stack direction="row" justifyContent="right" spacing={0} sx={{height:"10vh"}}>
                {(stepsno>0 && stepsno<4) && <Button
                onClick={()=>setStepsno(prev=>prev-1)}
                variant="contained" startIcon={<KeyboardDoubleArrowLeftIcon/>} 
                 color="primary" sx={{marginRight:"20px",width:"114px",height:"53px", background: "linear-gradient(90deg, #0F5DAE 0%, #009DCF 100.33%)",boxShadow: "0px 13.7981px 22.5788px rgba(50, 174, 137, 0.25)",textTransform: "none"}}>
                    Previous
                </Button>}
               {stepsno<=3 && <Button 
                onClick={()=>setStepsno(prev=>prev+1)}
                variant="contained" endIcon={<KeyboardDoubleArrowRightIcon/>} 
                 color="primary" sx={{marginRight:"20px",height:"53px", background: "linear-gradient(90deg, #0F5DAE 0%, #009DCF 100.33%)",boxShadow: "0px 13.7981px 22.5788px rgba(50, 174, 137, 0.25)", textTransform: "none"}}>
                    Next 
                </Button>}
            </Stack> */}
        </Stack>
      </Box>
    </>
  );
};
