import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { AuthService } from "../../../context/buyer/services/auth";
import { api, Apiaddress } from "../../../utils/consts";
import SideBar from "../Components/sideBar/SideBar";
import { LeadTs } from "./types/Lead";
import "./styles/row.css";
import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextareaAutosize,
  TextField,
  Typography,
} from "@mui/material";
import TabBar from "../Components/tabBar/TabBar";
import PDmainPage from "./ProjectDirectory/PDmainPage";
import CPForms from "./forms";
import DeassignStandAloneUnit from "./LeadsDirectory/DeassignStandAloneUnit";
import BuilderOrSelfProject from "./LeadsDirectory/BuilderOrSelfProject";
import AssignedCPForms from "./LeadsDirectory/AssignedCPForms";
import Modal from "@mui/material/Modal";
import { DataGrid, GridCloseIcon, GridExpandMoreIcon } from "@mui/x-data-grid";
import { CitySelector, StateSelector } from "volkeno-react-country-state-city";
import { countryDetails } from "../../../utils/contryDetails";
import LDcustomTable from "../appointments/LeadsDirectory/LDcustomTable";
import { Country, State, City }  from 'country-state-city';
import NavigateBack from "../../CommonPages/Shared/NavigateBack";

// const CPLeadOverview = ({ edit }: { edit?: boolean }) => {
const CPLeadOverview = ({ edit: boolean }) => {
  const cpDetails = JSON.parse(localStorage.getItem("cpDetails"));
  console.log(cpDetails);
  let cpOrg = cpDetails?.channelPartnerOrg;

  const navigate = useNavigate();
  const par = useParams();
  const url = api("/api/leads/lead/?lead_id=" + par.id);
  const [LeadDetails, setLeadDetails] = useState();
  const [Name, setName] = useState("");
  const [PhoneNo, setPhoneNo] = useState("");
  const [Address, setAddress] = useState("");
  const [stateName, setStateName] = useState("");
  const [city, setCityName] = useState("");
  const [pincode, setPincode] = useState("");
  const [priority, setPriority] = useState("");
  const [priority_id, setPriorityId] = useState("");
  const [allpriority, setallpriority] = useState([]);
  const [information, setInformation] = useState("");
  const [isClient, setIsClient] = useState();
  const [pinError, setPinError] = useState(false);
  const [cityError, setCityError] = useState(false);

  let lead_id = useParams().id;
  console.log(lead_id);

  const [curPage, setCurPage] = useState(1);
  const [selectedProject, setSelectedProject] = useState([]);
  const [lead, setLead] = useState();
  const [Builder, setBuilder] = useState([]);
  const [self, setSelf] = useState([]);
  const [standAlone, setStandAlone] = useState([]);
  const [trigger, setTrigger] = useState(false);
  const [projectFlag, setProjectFlag] = useState(2);
  const [phoneErr, setPhoneErr] = useState(false);

  const [location, setLocation] = useState({});

  useEffect(() => {
    setLocation(countryDetails);
  }, []);

  //state and city selector
  let States=State.getStatesOfCountry('IN');
  const [stateCode,setStateCode] = useState("");
  const [allCities,setAllCities] = useState("");
  
  console.log(stateName);
  console.log(stateCode);
  console.log(city);

  useEffect(()=>{
    if(stateName){
      let States=State.getStatesOfCountry('IN');
      for(let i=0;i<States.length;i++){
         if(States[i].name===stateName){
          setStateCode(States[i].isoCode);
          break;
         }
      }
    }
  },[stateName])

  useEffect(()=>{
    let Cities=City.getCitiesOfState('IN',stateCode);
    setAllCities(Cities);
  },[stateCode])

  const deAllocateProject = async () => {
    try {
      if (TabIndex == 0) {
        if (projectFlag == 1) {
          console.log("builder Related");
        } else if (projectFlag == 2) {
          let temp = self;
          for (let i = 0; i < selectedProject?.length; i++) {
            let temp1 = temp.filter((ele) => {
              return ele != selectedProject[i];
            });
            temp = temp1;
          }

          let obj = {
            lead_id: lead_id,
            cpProjects: temp,
          };
          console.log(obj);
          const res = await axios.patch(Apiaddress + "/api/leads/lead/", obj);
          console.log(res.data);
          setSelectedProject([]);
        }
      } else if (TabIndex == 1) {
        let temp = standAlone;
        for (let i = 0; i < selectedProject?.length; i++) {
          let temp1 = temp.filter((ele) => {
            return ele != selectedProject[i];
          });
          temp = temp1;
        }

        let obj = {
          lead_id: lead_id,
          cpStandaloneUnits: temp,
        };
        const res = await axios.patch(Apiaddress + "/api/leads/lead/", obj);
        console.log(res.data);
        setSelectedProject([]);
      }
      setTrigger(!trigger);
    } catch (err) {
      console.log(err);
    }
  };

  const fetchLeadDetails = async () => {
    try {
      const res = await axios.get(
        Apiaddress + `/api/leads/lead/?lead_id=${lead_id}`
      );
      setLead(res?.data?.lead[0]);
      setBuilder(res?.data?.lead[0].projects);
      setSelf(res?.data?.lead[0].cpProjects);
      setStandAlone(res?.data?.lead[0].cpStandaloneUnits);
    } catch (err) {
      console.log(err);
    }
  };

  const CheckClient = async () => {
    try {
      const Auth = new AuthService();
      let url = Apiaddress + `/api/leads/CpClientById/?lead_id=${lead_id}`;
      const res = await axios.get(url);
      console.log(res.data.Client);
      if (res?.data?.Client == "Lead_id not found") {
        setIsClient(true);
      } else {
        setIsClient(false);
      }
    } catch (err) {
      console.log(err.message);
    }
  };

  useEffect(() => {
    fetchLeadDetails();
    CheckClient();
  }, [trigger]);

  let editOrView = localStorage.getItem("editLeadDetails");
  console.log(editOrView);

  const getpriority = async () => {
    try {
      const res = await axios.get(Apiaddress + "/api/leads/leadprority/");
      console.log(res.data);
      setallpriority(res.data.leadprority);
    } catch (err) {
      console.log(err);
    }
  };

  const xx = async () => {
    const Auth = new AuthService();
    const res = await (await Auth.makeUnAuthenticatedGetRequest(url)).json();
    setLeadDetails(res);
    let phoneNumber
    if(res.lead[0].phone){
       phoneNumber = res.lead[0].phone.slice(3);
    }
    console.log(phoneNumber);
    console.log("lead", LeadDetails);
    setName(res.lead[0].name);
    setPhoneNo(phoneNumber);
    setAddress(res.lead[0].address_line);
    setPriority(res.lead[0]?.lead_prority?.name);
    setPriorityId(res.lead[0]?.lead_prority?.id);
    setStateName(res.lead[0]?.state);
    setCityName(res.lead[0]?.city);
    setPincode(res.lead[0]?.postcode);
  };
  const y = async () => {
    try {
      await xx();
    } catch (e) {
      console.log("err in Lead overview lead fetching");
    }
    // }
  };
  useEffect(() => {
    y();
    getpriority();
  }, []);

  const [TabIndex, setTabIndex] = useState(0);

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  //child Modal functionality
  const [openChild, setOpenChild] = React.useState(false);
  const handleOpenChild = () => {
    setOpenChild(true);
  };
  const handleCloseChild = () => {
    setOpenChild(false);
  };

  ////for SuccessFul Modal
  const [openSucc, setOpenSucc] = useState(false);
  const handleOpenSucc = () => {
    setOpenSucc(true);
  };
  const handleCloseSucc = () => {
    setOpenSucc(false);
  };

  const style1 = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #fff",
    boxShadow: 24,
    borderRadius: "20px",
    pt: 2,
    px: 4,
    pb: 3,
  };

  const markClient = async (flag) => {
    try {
      if (flag === "noinve") {
        if (information === "") {
          return;
        } else {
          const Auth = new AuthService();
          let url = Apiaddress + `/api/leads/CpClient/`;
          console.log(cpOrg);
          let obj = {
            lead: +lead_id,
            channel_partner: Auth.getCPID(),
            created_on: "",
            description: information,
            cp_org_client: cpOrg ? cpOrg : null,
          };
          const res = await axios.post(url, obj);
          let client_id = res?.data?.Client?.id;
          console.log(client_id);
          setInformation("");
          setTrigger(!trigger);
          handleCloseChild();
          handleClose();
        }
      } else if (flag === "inve" && lead_id) {
        navigate(`/channelpartner/leadclient/project/assign/${lead_id}`);
      }
    } catch (err) {
      console.log(err.message);
    }
  };

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
  };

  return (
    <>
      <SideBar
        active={"Leads"}
      >
        <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              sx={{ marginBottom: "20px" }}
            >
              <Box>
              <Stack direction="row">
                  <Box sx={{ pt: "5px" }}>
                    <NavigateBack fontSize="22px" />
                  </Box>
                  <Typography
                    sx={{
                      fontSize: "22px",
                      fontWeight: "600",
                      color: "black",
                    }}
                    >
                    Lead Overview
                  </Typography>
                </Stack>
                <Typography
                  sx={{
                    color: "#B9B7D0",
                    fontSize: "16px",
                    fontWeight: "400",
                  }}
                >
                  Manage your Leads
                </Typography>
              </Box>

            </Stack> 

        <Box sx={{ width: "100%", display: "flex", justifyContent: ""}}>
          <Box
            sx={{
              width: "75%",
              borderRadius: "8px",
              padding: "30px",
              backgroundColor: "#FFF",
              border: "2px solid transparent",
              my: "30px",
              "&:hover": {
                boxShadow: "-6px 18px 15px rgba(0, 0, 0, 0.1)",
                border: "2px solid  #2979F2",
              },
            }}
          >
            <Typography
              sx={{
                color: "#0085FF",
                fontSize: "24px",
                fontWeight: "700",
                display: "flex",
                fontFamily: "Circular Std",
              }}
            >
              Leads
            </Typography>

            {editOrView == "view" ? (
              <Stack spacing={3} sx={{ mt: "20px" }}>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    gap: "20px",
                  }}
                >
                  <Box sx={{ width: "50%", display: "flex", gap: "10px" }}>
                    <Typography
                      sx={{
                        width: "100px",
                        color: "#84828c",
                        fontSize: "18px",
                        fontWeight: 600,
                        fontFamily: "Product Sans",
                      }}
                    >
                      Name:
                    </Typography>
                    <Typography
                      sx={{
                        color: "#272D4E",
                        fontSize: "18px",
                        fontFamily: "Product Sans",
                      }}
                    >
                      {Name}
                    </Typography>
                  </Box>
                  <Box sx={{ width: "50%", display: "flex", gap: "10px" }}>
                    <Typography
                      sx={{
                        width: "100px",
                        color: "#84828c",
                        fontSize: "18px",
                        fontWeight: 600,
                        fontFamily: "Product Sans",
                      }}
                    >
                      Added Date:
                    </Typography>
                    <Typography
                      sx={{
                        color: "#272D4E",
                        fontSize: "18px",
                        fontFamily: "Product Sans",
                      }}
                    >
                      {LeadDetails?.lead[0]?.created_on?.toString().split("T")[0]}
                    </Typography>
                  </Box>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    gap: "20px",
                  }}
                >
                  <Box sx={{ width: "50%", display: "flex", gap: "10px" }}>
                    <Typography
                      sx={{
                        width: "100px",
                        color: "#84828c",
                        fontSize: "18px",
                        fontWeight: 600,
                        fontFamily: "Product Sans",
                      }}
                    >
                      Email:
                    </Typography>
                    <Typography
                      sx={{
                        color: "#272D4E",
                        fontSize: "18px",
                        fontFamily: "Product Sans",
                      }}
                    >
                      {LeadDetails?.lead[0].email?LeadDetails?.lead[0].email:"__"}
                    </Typography>
                  </Box>
                  <Box sx={{ width: "50%", display: "flex", gap: "10px" }}>
                    <Typography
                      sx={{
                        width: "100px",
                        color: "#84828c",
                        fontSize: "18px",
                        fontWeight: 600,
                        fontFamily: "Product Sans",
                      }}
                    >
                      Priority:
                    </Typography>
                    <Typography
                      sx={{
                        color: "#272D4E",
                        fontSize: "18px",
                        fontFamily: "Product Sans",
                      }}
                    >
                      {priority}
                    </Typography>
                  </Box>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    gap: "20px",
                  }}
                >
                  <Box sx={{ width: "50%", display: "flex", gap: "10px" }}>
                    <Typography
                      sx={{
                        width: "100px",
                        color: "#84828c",
                        fontSize: "18px",
                        fontWeight: 600,
                        fontFamily: "Product Sans",  
                      }}
                    >
                      Phone no:
                    </Typography>
                    <Typography
                      sx={{
                        color: "#272D4E",
                        fontSize: "18px",
                        fontFamily: "Product Sans",
                      }}
                    >
                      {PhoneNo?PhoneNo:"__"}
                    </Typography>
                  </Box>
                  <Box sx={{ width: "50%", display: "flex", gap: "10px" }}>
                    <Typography
                      sx={{
                        width: "100px",
                        color: "#84828c",
                        fontSize: "18px",
                        fontWeight: 600,
                        fontFamily: "Product Sans",
                      }}
                    >
                      Address:
                    </Typography>
                    <Typography
                      sx={{
                        color: "#272D4E",
                        fontSize: "18px",
                        fontFamily: "Product Sans",
                      }}
                    >
                      {Address}
                    </Typography>
                  </Box>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    gap: "20px",
                  }}
                >
                  <Box sx={{ width: "50%", display: "flex", gap: "10px" }}>
                    <Typography
                      sx={{
                        width: "100px",
                        color: "#84828c",
                        fontSize: "18px",
                        fontWeight: 600,
                        fontFamily: "Product Sans",
                      }}
                    >
                      State:
                    </Typography>
                    <Typography
                      sx={{
                        color: "#272D4E",
                        fontSize: "18px",
                        fontFamily: "Product Sans",
                      }}
                    >
                      {stateName}
                    </Typography>
                  </Box>
                  <Box sx={{ width: "50%", display: "flex", gap: "10px" }}>
                    <Typography
                      sx={{
                        width: "100px",
                        color: "#84828c",
                        fontSize: "18px",
                        fontWeight: 600,
                        fontFamily: "Product Sans",
                      }}
                    >
                      City:
                    </Typography>
                    <Typography
                      sx={{
                        color: "#272D4E",
                        fontSize: "18px",
                        fontFamily: "Product Sans",
                      }}
                    >
                      {city}
                    </Typography>
                  </Box>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    gap: "20px",
                  }}
                >
                  <Box sx={{ width: "50%", display: "flex", gap: "10px" }}>
                    <Typography
                      sx={{
                        width: "100px",
                        color: "#84828c",
                        fontSize: "18px",
                        fontWeight: 600,
                        fontFamily: "Product Sans",
                      }}
                    >
                      Pincode:
                    </Typography>
                    <Typography
                      sx={{
                        color: "#272D4E",
                        fontSize: "18px",
                        fontFamily: "Product Sans",
                      }}
                    >
                      {pincode}
                    </Typography>
                  </Box>
                </Box>
                {/* {info !== "" && (
                  <Box sx={{ display: "flex", gap: "10px" }}>
                    <Typography
                      sx={{
                        width: "100px",
                        color: "#84828c",
                        fontSize: "18px",
                        fontWeight: 600,
                        fontFamily: "Product Sans",
                      }}
                    >
                      Information:
                    </Typography>
                    <Typography
                      sx={{
                        color: "#272D4E",
                        fontSize: "18px",
                        fontFamily: "Product Sans",
                      }}
                    >
                      {info}
                    </Typography>
                  </Box>
                )} */}
              </Stack>
            ) : (
              <form style={{ marginTop: "20px" }}>
                <Stack spacing={3}>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      gap: "5%",
                    }}
                  >
                    <Box sx={{ width: "50%" }}>
                      <Typography
                        sx={{ fontSize: "18px", fontFamily: "Product Sans" }}
                      >
                        Name
                      </Typography>
                      <TextField
                        sx={{ width: "100%", mt: "10px" }}
                        value={Name}
                        disabled={editOrView == "view" ? true : false}
                        onChange={(e) => {
                          setName(e.target.value);
                        }}
                      >
                        {" "}
                      </TextField>
                    </Box>
                    <Box sx={{ width: "50%" }}>
                      <Typography
                        sx={{ fontSize: "18px", fontFamily: "Product Sans" }}
                      >
                        Added Date
                      </Typography>
                      <TextField
                        sx={{ width: "100%", mt: "10px" }}
                        disabled={true}
                        value={
                          LeadDetails?.lead[0].created_on
                            .toString()
                            .split("T")[0]
                        }
                      ></TextField>
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      gap: "5%",
                    }}
                  >
                    <Box sx={{ width: "50%" }}>
                      <Typography
                        sx={{ fontSize: "18px", fontFamily: "Product Sans" }}
                      >
                        Email
                      </Typography>
                      <TextField
                        sx={{ width: "100%", mt: "10px" }}
                        disabled
                        value={LeadDetails?.lead[0].email}
                      ></TextField>
                    </Box>
                    <Box sx={{ width: "50%" }}>
                      <Typography
                        sx={{ fontSize: "18px", fontFamily: "Product Sans" }}
                      >
                        Priority
                      </Typography>
                      <FormControl sx={{ width: "100%", mt: "10px" }}>
                        {/* {`${JSON.stringify(allpriority)}`} */}
                        <Select
                          value={priority + "-" + priority_id}
                          onChange={(e) => {
                            let [name, id] = e.target.value.split("-");
                            setPriority(name);
                            setPriorityId(id);
                          }}
                          inputProps={{ "aria-label": "Without label" }}
                          disabled={editOrView == "view" ? true : false}
                        >
                          {allpriority.map((ele, index) => {
                            return (
                              <MenuItem
                                key={index}
                                value={ele.name + "-" + ele.id}
                              >
                                {ele.name}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </FormControl>
                    </Box>
                  </Box>

                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      gap: "5%",
                    }}
                  >
                    <Box sx={{ width: "50%" }}>
                      <Typography
                        sx={{ fontSize: "18px", fontFamily: "Product Sans" }}
                      >
                        Phone no
                      </Typography>
                      <TextField
                        sx={{ width: "100%", mt: "10px" }}
                        value={PhoneNo}
                        type="number"
                        disabled={editOrView == "view" ? true : false}
                        onChange={(e) => {
                          if (e.target.value.length > 10) {
                            return;
                          }
                          setPhoneNo(e.target.value);
                        }}
                      ></TextField>
                    </Box>
                    <Box sx={{ width: "50%" }}>
                      <Typography
                        sx={{ fontSize: "18px", fontFamily: "Product Sans" }}
                      >
                        Address
                      </Typography>
                      <TextField
                        sx={{ width: "100%", mt: "10px" }}
                        value={Address}
                        disabled={editOrView == "view" ? true : false}
                        onChange={(e) => {
                          setAddress(e.target.value);
                        }}
                      ></TextField>
                    </Box>
                  </Box>

                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      gap: "5%",
                    }}
                  >
                    <Box sx={{ width: "50%" }}>
                      <Typography
                        sx={{ fontSize: "18px", fontFamily: "Product Sans", marginBottom: "10px" }}
                      >
                        State
                      </Typography>

                      <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">Select State</InputLabel>
                          <Select
                            value={stateName}
                            required
                            label="Select State"
                            MenuProps={{ PaperProps: { sx: { maxHeight: 200,maxWidth: 250} } }}
                            onChange={(e)=>{
                              let temp = e.target.value.split("-");
                              console.log(temp);
                              setStateName(temp[0]);
                              setStateCode(temp[1]);
                              setCityName("");
                            }}
                          >
                          {States &&
                            States?.map((option,index) => (
                              <MenuItem key={option.name+"-"+option.isoCode} value={option.name}>
                                {option.name}
                              </MenuItem>
                          ))}
                          </Select>
                        </FormControl>
                     
                    </Box>
                    <Box sx={{ width: "50%" }}>
                      <Typography
                        sx={{ fontSize: "18px", fontFamily: "Product Sans", marginBottom: "10px" }}
                      >
                        City
                      </Typography>

                      <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">Select City</InputLabel>
                        <Select
                            required
                            value={city}
                            label="Select City"
                            MenuProps={{ PaperProps: { sx: { maxHeight: 200,maxWidth: 250} } }}
                            onChange={(e)=>{
                              let temp = e.target.value.split("-");
                              setCityName(temp[0]);
                            }}
                          >
                            {allCities &&
                            allCities?.map((option) => (
                              <MenuItem key={option.name+"-"+option.isoCode} value={option.name}>
                                {option.name}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                    </Box>
                  </Box>

                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      gap: "5%",
                    }}
                  >
                    <Box sx={{ width: "50%" }}>
                      <Typography
                        sx={{ fontSize: "18px", fontFamily: "Product Sans" }}
                      >
                        Pincode
                      </Typography>
                      <TextField
                        sx={{ width: "100%", mt: "10px" }}
                        value={pincode}
                        disabled={editOrView == "view" ? true : false}
                        onChange={(e) =>
                          e.target.value.length <= 6
                            ? setPincode(e.target.value)
                            : null
                        }
                      ></TextField>
                    </Box>
                  </Box>
                  {cityError && (
                    <Typography
                      sx={{
                        fontWeight: "500",
                        fontSize: "14px",
                        marginBottom: "5px",
                        color: "#da6061",
                      }}
                    >
                      State and City is required
                    </Typography>
                  )}
                  {pinError && (
                    <Typography
                      sx={{
                        fontWeight: "500",
                        fontSize: "14px",
                        marginBottom: "5px",
                        color: "#da6061",
                      }}
                    >
                      Pincode Should be of 6 Characters
                    </Typography>
                  )}
                  {phoneErr && (
                    <Typography
                      sx={{
                        fontWeight: "500",
                        fontSize: "14px",
                        marginBottom: "5px",
                        color: "#da6061",
                      }}
                    >
                      Phone Number Should be 10 digits
                    </Typography>
                  )}


                  {/* <tr>
                    <Box>
                        <div className='center'>
                        {editOrView=="edit" &&
                            <Button

                                onClick={async (e) => {
                                    let phone="+91"+PhoneNo;
                                    const x = new AuthService()
                                    await x.makeUnAuthenticatedPatchRequest(api("/api/leads/lead/?lead_id=" + par.id), {
                                        JsonBody: {
                                            "lead_id": `${par.id}`,
                                            "name": Name,
                                            "phone": phone,
                                            "address_line": Address,
                                            "lead_prority":+priority_id
                                        }
                                    })
                                    await xx();
                                    alert("Updated Successfully");
                                }}
                                variant="contained"
                                color="primary" sx={{ height: "40px", background: "linear-gradient(90deg, #0F5DAE 0%, #009DCF 100.33%)", boxShadow: "0px 13.7981px 22.5788px rgba(50, 174, 137, 0.25)", textTransform: "none", borderRadius: "4px", width: "100px", fontSize: "15px", margin: "20px 0px 10px 0px" }}>
                                Updated
                            </Button>}
                        </div>
                    </Box>
                </tr> */}
                  <Box sx={{ display: "flex", justifyContent: "center" }}>
                    {editOrView == "edit" && (
                      <Button
                        onClick={async (e) => {

                          if (stateName === "" || city === "") {
                            setCityError(true);
                            return;
                          }
                          if (pincode === "" || pincode?.length < 6) {
                            setPinError(true);
                            return;
                          }
                          if(PhoneNo && PhoneNo.length<10){
                            setPhoneErr(true);
                            return;
                          }

                          let phone = "+91" + PhoneNo;
                          const x = new AuthService();
                          await x.makeUnAuthenticatedPatchRequest(
                            api("/api/leads/lead/?lead_id=" + par.id),
                            {
                              JsonBody: {
                                lead_id: `${par.id}`,
                                name: Name,
                                phone: phone,
                                address_line: Address,
                                lead_prority: +priority_id,
                                state: stateName,
                                city: city,
                                postcode: pincode
                              },
                            }
                          );
                          await xx();
                          // alert("Updated Successfully");
                          setCityError(false);
                          setPinError(false);
                          setPhoneErr(false);
                          handleOpenSucc();
                        }}
                        sx={{
                          background:
                            "linear-gradient(97.85deg, #FFAF2A 0.95%, #FFC666 100%)",
                          fontWeight: 500,
                          fontSize: "20px",
                          borderRadius: "6px",
                          color: "#06386B",
                          textTransform: "none",
                          padding: "8px 60px",
                          fontFamily: "Product Sans",
                          mt: "20px",
                          "&:hover": {
                            background:
                              "linear-gradient(97.85deg, #FFAF2A 0.95%, #FFC666 100%)",
                            boxShadow: "0px 9px 12px rgba(242, 178, 73, 0.59)",
                          },
                        }}
                      >
                        Update
                      </Button>
                    )}
                  </Box>
                </Stack>
              </form>
            )}
          </Box>
        </Box>

        {/* ================Mark Priority======================= */}
        {/* <Box>
          {selectedProject.length != 0 && (
            <Stack direction="row" sx={{ margin: "20px" }}>
              {
                <Button
                  onClick={() => {
                    deAllocateProject();
                  }}
                  className="univercel_btn_class"
                  sx={{
                    color: "#FFFFFF",
                    textTransform: "none",
                    width: "150px",
                    background: "#008dff",
                    boxShadow:
                      "0px 13.7981px 22.5788px rgba(50, 174, 137, 0.25)",
                    "&:hover": { background: "#008dff" },
                  }}
                >
                  Deallocate
                </Button>
              }
            </Stack>
          )}
        </Box> */}

        <TabBar
          floatingActonButton={
            <div>
              {TabIndex == 0 && (
                <>
                  {isClient && (
                    <Button
                      variant="contained"
                      color="primary"
                      sx={{
                        height: "40px",
                        background: "#008dff",
                        boxShadow:
                          "0px 13.7981px 22.5788px rgba(50, 174, 137, 0.25)",
                        textTransform: "none",
                        borderRadius: "4px",
                        width: "140px",
                        fontSize: "15px",
                        margin: "5px 10px 5px 0px",
                        padding: "5px",
                        "&:hover": { background: "#008dff" },
                      }}
                      onClick={handleOpen}
                    >
                      Mark as Client
                    </Button>
                  )}
                  <Button
                    variant="contained"
                    color="primary"
                    sx={{
                      height: "40px",
                      background: "#008dff",
                      boxShadow:
                        "0px 13.7981px 22.5788px rgba(50, 174, 137, 0.25)",
                      textTransform: "none",
                      borderRadius: "4px",
                      width: "140px",
                      fontSize: "15px",
                      margin: "5px 0px 5px 0px",
                      padding: "5px",
                      width:"180px",
                      "&:hover": { background: "#008dff" },
                    }}
                    onClick={(e) => {
                      navigate(`/channelpartner/lead/project/assign/${par.id}`);
                    }}
                  >
                    Assign New Unit
                  </Button>
                </>
              )}
              {TabIndex == 1 && (
                <>
                {isClient && (
                  <Button
                    variant="contained"
                    color="primary"
                    sx={{
                      height: "40px",
                      background: "#008dff",
                      boxShadow:
                        "0px 13.7981px 22.5788px rgba(50, 174, 137, 0.25)",
                      textTransform: "none",
                      borderRadius: "4px",
                      width: "140px",
                      fontSize: "15px",
                      margin: "5px 10px 5px 0px",
                      padding: "5px",
                      "&:hover": { background: "#008dff" },
                    }}
                    onClick={handleOpen}
                  >
                    Mark as Client
                  </Button>
                )}
                <Button
                  variant="contained"
                  color="primary"
                  sx={{
                    height: "40px",
                    background: "#008dff",
                    boxShadow:
                      "0px 13.7981px 22.5788px rgba(50, 174, 137, 0.25)",
                    textTransform: "none",
                    borderRadius: "4px",
                    width: "140px",
                    fontSize: "15px",
                    margin: "5px 0px 5px 0px",
                    padding: "5px",
                    width:"180px",
                    "&:hover": { background: "#008dff" },
                  }}
                  onClick={(e) => {
                    navigate(`/channelpartner/lead/project/assign/${par.id}`);
                  }}
                  >
                  Assign New Unit
                </Button>
                </>
              )}
              {TabIndex == 2 && (
                <>
                {isClient && (
                  <Button
                    variant="contained"
                    color="primary"
                    sx={{
                      height: "40px",
                      background: "#008dff",
                      boxShadow:
                        "0px 13.7981px 22.5788px rgba(50, 174, 137, 0.25)",
                      textTransform: "none",
                      borderRadius: "4px",
                      width: "140px",
                      fontSize: "15px",
                      margin: "5px 10px 5px 0px",
                      padding: "5px",
                      "&:hover": { background: "#008dff" },
                    }}
                    onClick={handleOpen}
                  >
                    Mark as Client
                  </Button>
                )}
                <Button
                  variant="contained"
                  color="primary"
                  sx={{
                    height: "40px",
                    background: "#008dff",
                    boxShadow:
                      "0px 13.7981px 22.5788px rgba(50, 174, 137, 0.25)",
                    textTransform: "none",
                    borderRadius: "4px",
                    width: "140px",
                    fontSize: "15px",
                    margin: "5px 0px 5px 0px",
                    padding: "5px",
                    width:"180px",
                    "&:hover": { background: "#008dff" },
                  }}
                  onClick={(e) => {
                    navigate(`/channelpartner/lead/project/assign/${par.id}`);
                  }}
                >
                  Assign New Unit
                </Button>
                </>
              )}
              {TabIndex == 3 && (
                <>
                {isClient && (
                  <Button
                    variant="contained"
                    color="primary"
                    sx={{
                      height: "40px",
                      background: "#008dff",
                      boxShadow:
                        "0px 13.7981px 22.5788px rgba(50, 174, 137, 0.25)",
                      textTransform: "none",
                      borderRadius: "4px",
                      width: "140px",
                      fontSize: "15px",
                      margin: "5px 10px 5px 0px",
                      padding: "5px",
                      "&:hover": { background: "#008dff" },
                    }}
                    onClick={handleOpen}
                  >
                    Mark as Client
                  </Button>
                )}
                <Button
                  variant="contained"
                  color="primary"
                  sx={{
                    height: "40px",
                    background: "#008dff",
                    boxShadow:
                      "0px 13.7981px 22.5788px rgba(50, 174, 137, 0.25)",
                    textTransform: "none",
                    borderRadius: "4px",
                    width: "140px",
                    fontSize: "15px",
                    margin: "5px 0px 5px 0px",
                    padding: "5px",
                    width:"180px",
                    "&:hover": { background: "#008dff" },
                  }}
                  onClick={(e) => {
                    navigate(`/channelpartner/lead/project/assign/${par.id}`);
                  }}
                >
                  Assign New Unit
                </Button>
                </>
              )}
            </div>
          }
          onIndexChange={(e) => {
            setTabIndex(e);
            setSelectedProject([]);
          }}
          Tabs={[
            {
              Name: "Projects",
              children: [
                // <PDmainPage />
                <BuilderOrSelfProject
                  selectedProject={selectedProject}
                  setSelectedProject={setSelectedProject}
                  self={self}
                  builder={Builder}
                  trigger={trigger}
                  setTrigger={setTrigger}
                  projectFlag={projectFlag}
                  setProjectFlag={setProjectFlag}
                  deAllocateProject={deAllocateProject}
                  lead_id={lead_id}
                />,
              ],
            },
            {
              Name: "Stand Alone Units",
              children: [
                <DeassignStandAloneUnit
                  selectedProject={selectedProject}
                  setSelectedProject={setSelectedProject}
                  self={standAlone}
                  trigger={trigger}
                  setTrigger={setTrigger}
                  deAllocateProject={deAllocateProject}
                />,
              ],
            },
            {
              Name: "Forms",
              children: [
                // <CPForms />
                <AssignedCPForms lead_id={lead_id} />,
              ],
            },
            {
              Name: "Appointments",
              children: [
                // <CPForms />
                <LDcustomTable origin={"Leads Directory"} lead_id={lead_id} />,
              ],
            },
          ]}
        />
      </SideBar>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "40%",
            height: "50%",
            bgcolor: "white",
            border: "2px solid white",
            borderRadius: "4px",
            boxShadow: 24,
            pt: 3,
            px: 3,
            pb: 1.5,
            whiteSpace: "nowrap",
            overflowX: "hidden",
          }}
        >
          <Box>
            <Box sx={{ display: "flex", justifyContent: "end" }}>
              <Box sx={{ textAlign: "right" }}>
                <GridCloseIcon
                  sx={{ color: "gray", cursor: "pointer" }}
                  onClick={() => {
                    handleClose();
                    setInformation("");
                  }}
                />
              </Box>
            </Box>
            <Box>
              <Typography
                sx={{
                  fontSize: "18px",
                  color: "#343434",
                  fontFamily: "Product Sans",
                }}
              >
                Mark this lead as client by assigning Projects/Units to it.
              </Typography>
              <Button
                className="univercel_btn_class"
                sx={{ width: "180px", py: "10px", mt: "10px" }}
                onClick={(e) => {
                  markClient("inve");
                }}
              >
                Add Inventory
              </Button>
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                gap: "10px",
                mt: "10px",
              }}
            >
              <Box
                sx={{
                  width: "50%",
                  height: "1.5px",
                  backgroundColor: "#343434",
                }}
              ></Box>
              <Typography
                sx={{
                  fontSize: "20px",
                  color: "#343434",
                  fontFamily: "Product Sans",
                  fontWeight: 600,
                }}
              >
                or
              </Typography>
              <Box
                sx={{
                  width: "50%",
                  height: "1.5px",
                  backgroundColor: "#343434",
                }}
              ></Box>
            </Box>
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <Typography
                sx={{
                  fontSize: "18px",
                  color: "#343434",
                  fontFamily: "Product Sans",
                }}
              >
                Mark this lead as client without assigning Projects/Units to it.
              </Typography>
              <TextareaAutosize
                minRows={3}
                placeholder="Write something..."
                style={{
                  maxwidth: "100%",
                  padding: "5px 10px",
                  marginTop: "10px",
                  fontSize: "18px",
                  color: "#343434",
                  fontFamily: "Product Sans",
                }}
                value={information}
                onChange={(e) => {
                  setInformation(e.target.value);
                }}
              />
              <Button
                sx={{
                  background:
                    "linear-gradient(97.85deg, #FFAF2A 0.95%, #FFC666 100%)",
                  fontWeight: 500,
                  width: "180px",
                  fontSize: "20px",
                  borderRadius: "6px",
                  color: "#06386B",
                  textTransform: "none",
                  padding: "10px 30px",
                  fontFamily: "Product Sans",
                  mt: "20px",
                  "&:hover": {
                    background:
                      "linear-gradient(97.85deg, #FFAF2A 0.95%, #FFC666 100%)",
                    boxShadow: "0px 9px 12px rgba(242, 178, 73, 0.59)",
                  },
                }}
                onClick={() => {
                  markClient("noinve");
                }}
              >
                Add Note
              </Button>
            </Box>
          </Box>
        </Box>
      </Modal>

      {/* lead updation SuccessFul Modal */}
      <Modal
        open={openSucc}
        onClose={() => {
          handleCloseSucc();
        }}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
        <Box sx={{ ...style1, width: 270, height: 130 }}>
          <Stack justifyContent="center" alignItems="center" sx={{ height: "100%" }}>
            <Typography sx={{ fontSize: "18px" }}>Lead is Updated Successfully</Typography>
            <Stack
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                mt: "20px",
              }}
            >
              <Button
                onClick={handleCloseSucc}
                className="univercel_btn_class"
                variant="contained"
                sx={{ padding: "9px 26px 9px 26px" }}
                component="label"
              >
                Okay
              </Button>
            </Stack>
          </Stack>
        </Box>
      </Modal>
    </>
  );
};

export default CPLeadOverview;
