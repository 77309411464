import { Box, TextField, Typography } from '@mui/material'
import React, { useState } from 'react';
import { Stack } from '@mui/system';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import FileCopyRoundedIcon from '@mui/icons-material/FileCopyRounded';
import Button from "@mui/material/Button";
import Modal from '@mui/material/Modal';
import { useEffect } from 'react';
import CloseIcon from '@mui/icons-material/Close';


export default function ConfirmProject_PricingDetails_Section(props) {

   const [inputtitle,setinputtitle]=useState([]);
   const [plank,setplank]=useState(false);

   const [costsection,setcostsection]=useState(0);

   const [open, setOpen] = React.useState(false);
   const handleOpen = () => setOpen(true);
   const handleClose = () => setOpen(false);

   const [addsec,setaddsec]=useState({
        key:"",
        value:"",
        index:0
    });

     const sectioncostcalculate=()=>{
        let data=0
        inputtitle?.map((ele)=>{
            data+=+(ele.value);
        })

        setcostsection(data);

        props.settitle((prev) => {
            return prev.filter((curvalue, idx) => {
                if (idx+1 == props.header.number) {
                    curvalue.field = inputtitle;
                    }
                return curvalue;
            })
        });

        props.setseccost({index:props.header.number,cost:data});
    }
    
     if(plank==true){
    //    console.log(inputtitle);
       sectioncostcalculate();
       setplank(false); 
    }

    // console.log(inputtitle);

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: "350px",
        height:"250px",
        bgcolor: 'background.paper',
        boxShadow: 24,
        borderRadius:"16px",
        p: 4
    };

    const updatedata=()=>{
        
        props.settitle((prev) => {
            return prev.filter((curvalue, idx) => {
                if (idx+1 == props.header.number) {
                    curvalue.field = inputtitle;
                    }
                return curvalue;
            })
        });
    }

    // console.log(inputtitle);

    useEffect(()=>{

        setinputtitle(props.header.field);
        
    },[]);

  return (
    <div>
        <Stack direction="row" sx={{margin:"30px 0px 0px 0px",display:"flex",alignItems:"center"}} >
            <Typography sx={{fontSize:"18px", fontWeight:"500"}}>{props.header.titlename}</Typography>
            <AddIcon sx={{color:"#A2A3A6",fontSize:"18px",marginLeft:"20px",cursor:"pointer"}} onClick={()=>{setOpen(true)}}/>
        </Stack>

        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description">
           <Box sx={style}>
           <Stack direction="row" justifyContent="space-between" alignItems="center">
           <Typography id="modal-modal-title" variant="h6" component="h2" sx={{fontFamily:'Poppins',fontStyle:"normal",fontWeight:"500",fontSize: "18px",lineHeight:"27px",color:"#FF6140"}}>
            Add Field
           </Typography>
           <Box onClick={()=>{setOpen(false);setaddsec("")}} sx={{cursor:"pointer"}}><CloseIcon /></Box>
           </Stack>

           <Box sx={{alignItems:"center",margin:"30px 0px 0px 0px"}}>
            <Stack direction="row" justifyContent="start" alignItems="center" sx={{margin:"30px 0px 0px 20px",fontSize: "18px"}}>
                <Typography component="span" className='field-name'>Input Name</Typography>
                <TextField
                 sx={{
                    
                     width:"188px",
                     marginLeft:"40px"
                    }} value={addsec.titlename} onChange={(e)=>{setaddsec(
                        {

                            key:e.target.value,
                            value:"",
                            index:inputtitle.length+1,
                        }
                        )}}/>
            </Stack>
            </Box>
            <Button 
               type="submit"
                variant="contained"
                 color="primary" sx={{ height:"45px", background: "linear-gradient(90deg, #0F5DAE 0%, #009DCF 100.33%)",boxShadow: "0px 13.7981px 22.5788px rgba(50, 174, 137, 0.25)", textTransform: "none", borderRadius: "4px", width:"100px", fontSize:"15px",margin:"60px 0px 30px 120px"}} onClick={()=>{
                    if(!addsec){
                        return;
                    }
                    setinputtitle(inputtitle=> inputtitle.concat(addsec));
                    setOpen(false);
                    setaddsec("");
                }}>
                Done
        </Button>
        </Box>
      </Modal>

      <Box>
        {inputtitle?.map((ele,idx)=>{

            return(
               <Stack direction="row" sx={{display:"flex",alignItems:"center",margin:"20px 0px 0px 0px"}} >
                <Box sx={{width:"25%"}}>
                    <Typography sx={{fontSize:"18px", fontWeight:"500",color:"#A2A3A6"}}>{ele.key}</Typography>
                </Box>
                <TextField type="number" inputProps={{ min: 0 }}
                    sx={{
                         width:"188px",
                         marginLeft:"10px"
                        }} value={ele.value} onChange={(e)=>{
                                   
                                   if(idx+1==ele.index){
                                    ele.value=e.target.value;
                                    setaddsec(
                                        {
                                            key:ele.key,
                                            index:ele.index,
                                            value:e.target.value
                                        })                         
                                   }
                                  updatedata();
                                  sectioncostcalculate();
                                }
                                }
                            />
                <DeleteIcon sx={{fontSize:"26px", fontWeight:"500",color:"#EF3649",margin:"0px 0px 0px 30px",cursor:"pointer"}} onClick={()=>{
                            const newinputtitle = inputtitle.filter((x) => x !== ele);
                            setinputtitle(newinputtitle);
                            updatedata();
                            setplank(true);
                            }}
                        />
            </Stack>
            )

        })}
      </Box>


    </div>
  )
}


