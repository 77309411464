import { Box, Container, Stack, Typography } from "@mui/material";
import ChannelPartnerManagement from "../../Imgs/channel-partner-management.png";
import CircleCheckIconWhite from "../../Imgs/circle-check-icon.svg";

const CommonPagesChannelPartnerManagement = () => {
  return (
    <Box sx={{ backgroundColor: "#FFF", overflowX: "hidden" }}>
      <Container>
        <Box
          sx={{
            display: "flex",
            gap: { xs: "10px", md: "30px" },
            alignItems: "center",
            flexDirection: { xs: "column-reverse", md: "row" },
            mt: "50px",
          }}
        >
          <Stack sx={{ width: { xs: "100%", md: "55%" } }}>
            <Typography
              data-aos="fade-right"
              sx={{
                fontSize: { xs: "24px", md: "32px", lg: "40px" },
                fontWeight: 700,
                color: "#272D4E",
                fontFamily: "Circular Std",
                lineHeight: "1.2",
              }}
            >
              Channel Partner Management
            </Typography>

            <Stack spacing={2}>
              <Typography
                sx={{
                  fontSize: "18px",
                  fontFamily: "Product Sans",
                  color: "#343434",
                }}
              >
                Collaborate with authentic Channel Partners and manage
                commissions, information access, and more
              </Typography>
              <Box sx={{ display: "flex", gap: "10px" }}>
                <Box sx={{ lineHeight: "10px", fontSize: "30px" }}>
                  {/* <img src={CircleCheckIcon} alt="" /> */}
                  &#x2022;
                </Box>
                <Typography
                  sx={{
                    fontSize: "16px",
                    fontFamily: "Product Sans",
                    color: "#343434",
                    lineHeight: "1.3",
                  }}
                >
                  <span style={{ fontWeight: 600 }}>
                    Onboard verified Channel Partners.
                  </span>{" "}
                  We verify each Channel Partner to restrict fake leads
                </Typography>
              </Box>
              <Box sx={{ display: "flex", gap: "10px" }}>
                <Box sx={{ lineHeight: "10px", fontSize: "30px" }}>
                  {/* <img src={CircleCheckIcon} alt="" /> */}
                  &#x2022;
                </Box>
                <Typography
                  sx={{
                    fontSize: "16px",
                    fontFamily: "Product Sans",
                    color: "#343434",
                    lineHeight: "1.3",
                  }}
                >
                  <span style={{ fontWeight: 600 }}>
                    Share your project details
                  </span>{" "}
                  easily and give relevant access. You chose what to share and
                  what not to!
                </Typography>
              </Box>
              <Box sx={{ display: "flex", gap: "10px" }}>
                <Box sx={{ lineHeight: "10px", fontSize: "30px" }}>
                  {/* <img src={CircleCheckIcon} alt="" /> */}
                  &#x2022;
                </Box>
                <Typography
                  sx={{
                    fontSize: "16px",
                    fontFamily: "Product Sans",
                    color: "#343434",
                    lineHeight: "1.3",
                  }}
                >
                  <span style={{ fontWeight: 600 }}>
                    Manage commissions and payments
                  </span>{" "}
                  for each Channel Partner
                </Typography>
              </Box>
              <Box sx={{ display: "flex", gap: "10px" }}>
                <Box sx={{ lineHeight: "10px", fontSize: "30px" }}>
                  {/* <img src={CircleCheckIcon} alt="" /> */}
                  &#x2022;
                </Box>
                <Typography
                  sx={{
                    fontSize: "16px",
                    fontFamily: "Product Sans",
                    color: "#343434",
                    lineHeight: "1.3",
                  }}
                >
                  <span style={{ fontWeight: 600 }}>
                    Get complete visibility
                  </span>{" "}
                  on the performance matrices. Get the insights to make the most
                  of your collaboration
                </Typography>
              </Box>
              <Box sx={{ display: "flex", gap: "10px" }}>
                <Box sx={{ lineHeight: "10px", fontSize: "30px" }}>
                  {/* <img src={CircleCheckIcon} alt="" /> */}
                  &#x2022;
                </Box>
                <Typography
                  sx={{
                    fontSize: "16px",
                    fontFamily: "Product Sans",
                    color: "#343434",
                    lineHeight: "1.3",
                  }}
                >
                  <span style={{ fontWeight: 600 }}>Get the insights</span> to
                  make the most of your collaboration
                </Typography>
              </Box>
            </Stack>
          </Stack>
          <Box
            data-aos="fade-left"
            data-aos-offset="140"
            sx={{ width: { xs: "100%", md: "45%" } }}
          >
            <img
              style={{ width: "100%" }}
              src={ChannelPartnerManagement}
              alt=""
            />
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

export default CommonPagesChannelPartnerManagement;
