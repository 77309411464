import { Stack, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Navbar } from "../../../BuilderComponents/ProjectDashboard/DashboardComponents/Navbar";
import CPDashboardLock from "../../Dashboard/CPDashboardLock";
import { BuyerSidebar } from "./SideBarimpl";
import { Apiaddress } from '../../../../utils/consts'
import axios from "axios";

const SideBar = (props) => {
  console.log(props);
  const user = JSON.parse(localStorage.getItem("crm_user"));
  let username = user?.user_details?.first_name;
  console.log(user?.user_details?.first_name);
  const crm_token = localStorage.getItem("crm_token")

  const [open, setOpen] = useState(false);

  useEffect(() => {
    const headers = {
      'Content-Type': 'application/json',
      "Authorization": "JWT " + crm_token
    }
    axios.get(Apiaddress + "/api/profile/", {
      headers: headers
    })
      .then((res) => {
        const data = res.data;
        console.log(data);

        if(data.user_obj.is_active===false){
          setOpen(true);
        }
        // setOpen(!(data.user_obj.is_active))

        console.log("res:", res.data)
      })
      .catch((err) => {
        console.log("err:", err)
      })
  }, [])

  return (
    <>
    <Stack
      direction="row"
      sx={{ width: "100%", height: "auto" }}  
      onClick={(event) => {
        // event.stopPropagation();
        // setOpenOrganization(false)
      }}
    >
      {/* 1st stack */}
      <Stack sx={{ minHeight: "100vh", width: "18%", boxSizing: "border-box" }}>
        <BuyerSidebar active={props.active} />  
      </Stack>

      {/* 2nd stack */}
      <Stack
        direction="column"
        sx={{
          backgroundColor: "#f9f9f9",
          width: "82%",
          paddingTop: "25px",
          paddingLeft: "25px",
          paddingRight: "25px",
          boxSizing: "border-box",
        }}
      >
        {" "}
        {/*  width for main sidebar 78px and for 80px*/}
        {/* {!props.dontShowNav && (
          <Navbar
            dontDisplayPath
            name={`Hello, ${username}`}
            childButton={props.button}
            addProject={props.addProject}
            addClient={props.addClient}
            addProjectNew={props.addProjectNew}
            addStandUnit={props.addStandUnit}
            addProClient={props.addProClient}
            InternalTabIndex={props.InternalTabIndex}
            projectUnitAdd={props.projectUnitAdd}
          />
        )} */}
        {/* actual content  */}
        <Stack sx={{ backgroundColor: "#f9f9f9" }}></Stack>
        {props.children}
      </Stack>
    </Stack>
    <CPDashboardLock open={open}/>
    </>
  );
};

export default SideBar;
