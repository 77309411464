import { Box, List, ListItem, Typography } from "@mui/material";
import "./styles/PageSix.css";
import React, {useState } from 'react';
import { Stack } from "@mui/system";
import classes from "./styles/PageOne.module.css";

const PdfOnePageSeven = () => {

   const [pageData7,setPageData7]=useState({
    sign_first:"",
    sign_second:"",
    sign_third:"",
    allot_data1:"",
    allot_data2:"",
    accept_data1:"",
    accept_data2:"",
  })

  // console.log(pageData7);

  let name,value;
  const handleChange=(e)=>{
    name=e.target.name;
    value=e.target.value;
    console.log(name," ",value);
    setPageData7({...pageData7,[name]:value});
  }

  return (
    <Box>
      <Box sx={{ mb: "40px" }}>
        <Box sx={{ ml: "20px", display: "flex", gap: "10px" }}>
          <Typography sx={{ fontSize: "10px" }}>
            accepts no responsibilty in this regard and the Applicant(s) shall
            keep the Developer fully indemnified for any cost, expenses,
            proceedings, litigations, penalties, harm or injury caused to or
            suffered by it for any reason whatsoever in this regard. Whenever
            there is any change in the residential status of the Applicant(s),
            subsequent to the signing of this Application, it shall be the sole
            responsibilty of the Applicant(s) to intimate the same in writing to
            the Developer on the very next day and comply with all the necessary
            formalities, if any, under any the applicable laws.
          </Typography>
        </Box>

        <Stack spacing={0.5} sx={{ ml: "10px", mt: "5px" }}>
          <Box sx={{ display: "flex", gap: "10px" }}>
            <Box>
              <Typography sx={{ fontSize: "10px" }}>4.</Typography>
            </Box>
            <Stack>
              <Typography sx={{ fontSize: "10px" }}>
                ALLOTMENT PREMIUM
              </Typography>
              <Box sx={{ display: "flex", gap: "10px" }}>
                <Typography sx={{ fontSize: "10px" }}>4.1</Typography>
                <Typography sx={{ fontSize: "10px" }}>
                  The Applicant(s) shall make timely payment of the Allotment
                  Premium as per the Payment Schedule at Annexure 1 hereto, time
                  being of the essence. It shall be the obligation of the
                  Applicant(s) to pay the TDS into the requisite government tax
                  account. The deduction of any amount made by the Applicant(s)
                  on account of Tax Deduction at Source as may be required under
                  prevailing law while making payment of the Allotment Premium
                  or any part payment thereof to the Developer, shall be
                  acknowleged/ credited to the Developer only upon Applicant(s)
                  submitting (a) Form 26QB along with original tax payment
                  challan within 30 days from the date of making payments of the
                  respective installment of the Allotment Premium, and (b) the
                  original tax deduction at source certificate within 30 days
                  from the date of making payment of the respective instalment
                  of the Allotment Premium and provided that the details/ amount
                  mentioned in the cer
                </Typography>
              </Box>
              <Box sx={{ display: "flex", gap: "10px" }}>
                <Typography sx={{ fontSize: "10px" }}>4.2</Typography>
                <Typography sx={{ fontSize: "10px" }} className={classes.input}>
                  This Application shall be accompanied by Cheque/ Deand Draft/
                  Pay Order/ RTGS payable at Mumbai, drawn in favour of
                  “ <input 
                      style={{ textAlign: "center" }}
                      value={pageData7.allot_data1}
                      type="text"
                      name="allot_data1"
                      onChange={handleChange}
                    /> ” towards “Application Monies Part 1” and
                  a Cheque/ Demand/ Pay Order/ RTGS payable at Mumbai, drawn in
                  favour of “ <input 
                      style={{ textAlign: "center" }}
                      value={pageData7.allot_data2}
                      type="text"
                      name="allot_data2"
                      onChange={handleChange}
                    /> ” towards ‘GST’.
                </Typography>
              </Box>
            </Stack>
          </Box>
        </Stack>
        <Stack sx={{ ml: "10px", mt: "10px" }}>
          <Box sx={{ display: "flex", gap: "10px" }}>
            <Box>
              <Typography sx={{ fontSize: "10px" }}>5.</Typography>
            </Box>
            <Stack spacing={0.5}>
              <Typography sx={{ fontSize: "10px" }}>
                ACCEPTANCE OF APPLICATION
              </Typography>
              <Box sx={{ display: "flex", gap: "10px" }}>
                <Typography sx={{ fontSize: "10px" }}>4.1</Typography>
                <Typography sx={{ fontSize: "10px" }}>
                  The Applicant(s) has submitted the Application Form, duly
                  filled in with all the details alongwith Application Money
                  Part 1 and shall pay Application Money Part 2 strictly as per
                  as per Payment Schedule as contained herein. This application
                  and /or the receipt of the Application Money Part 1 or
                  Application Money Part 2 do not confer or constitute any right
                  or entitlement or option to purchase upon th Applicant(s) in
                  or to the Premises.
                </Typography>
              </Box>
              <Box sx={{ display: "flex", gap: "10px" }}>
                <Typography sx={{ fontSize: "10px" }}>5.2</Typography>
                <Typography sx={{ fontSize: "10px" }} className={classes.input}>
                  This Application shall be accompanied by Cheque/ Deand Draft/
                  Pay Order/ RTGS payable at Mumbai, drawn in favour of
                  “ <input 
                      style={{ textAlign: "center" }}
                      value={pageData7.accept_data1}
                      type="text"
                      name="accept_data1"
                      onChange={handleChange}
                    /> ” towards “Application Monies Part 1” and
                  a Cheque/ Demand/ Pay Order/ RTGS payable at Mumbai, drawn in
                  favour of “ <input 
                      style={{ textAlign: "center" }}
                      value={pageData7.accept_data2}
                      type="text"
                      name="accept_data2"
                      onChange={handleChange}
                    /> ” towards ‘GST’.
                </Typography>
              </Box>
              <Box sx={{ display: "flex", gap: "10px" }}>
                <Typography sx={{ fontSize: "10px" }}>5.2</Typography>
                <Typography sx={{ fontSize: "10px" }}>
                  This Aplication shall be deemed to be incomplete untill the
                  realization of the Application Money Part 2.
                </Typography>
              </Box>
              <Box sx={{ display: "flex", gap: "10px" }}>
                <Typography sx={{ fontSize: "10px" }}>5.3</Typography>
                <Typography sx={{ fontSize: "10px" }}>
                  The Applicant(s) has/have submitted the self attested Know
                  Your Customer (KYC) documents like Pan Card (mandatory),
                  identity proof and present/ permanent address proof like
                  Passport/ Adhar card/ Driving License/ Voter ID Card and/or
                  alternative valid proof of identity and address to the
                  satisfaction of the Developer.
                </Typography>
              </Box>
              <Box sx={{ display: "flex", gap: "10px" }}>
                <Typography sx={{ fontSize: "10px" }}>5.4</Typography>
                <Typography sx={{ fontSize: "10px" }}>
                  If the Applicant(s) is/ are other than individuals i.e. in the
                  case of Company/ Partnership Firm/ LLP/ Sole Proprietorship/
                  Trust/ HUF/ AOP and/or any other legal entity, then the
                  Applicant(s) shall submit attested KYC documents of the Sole
                  Proprietor and by atleast two of its Directors/ Partners/
                  Trustees etc., like Pan Card (Mandatory), Identity proof such
                  as Aaadhar card/ Driving License/ Voter ID Card, incorporation
                  certificate of the entity, MOA and AOA with registered office
                  address, partnership deed, LLP agreement, AOP agreement, Trust
                  Deed etc., and accompanied by valid board resolution
                  authorising the signatory to execute this Application on
                  behalf of the Applicant.
                </Typography>
              </Box>
              <Box sx={{ display: "flex", gap: "10px" }}>
                <Typography sx={{ fontSize: "10px" }}>5.5</Typography>
                <Typography sx={{ fontSize: "10px" }}>
                  The Developer shall have the right to reject the Application
                  at its sole discretion and without assigning any reason for
                  the same. In the event of rejection of the Application, the
                  amounts paid by the Applicant(s) upto the date of rejection
                  shall be refunded to the Applicant(s) in full without any
                  interest. The Applicant(s) agree(s) that in the event of the
                  Developer not accepting his/her/their/its Application for any
                  reason whatsoever, the Applicant(s) shall have no claim,
                  right, title, interest or lien on the Premises and shall not
                  raise any objection in respect thereof.
                </Typography>
              </Box>
              <Box sx={{ display: "flex", gap: "10px" }}>
                <Typography sx={{ fontSize: "10px" }}>5.6</Typography>
                <Typography sx={{ fontSize: "10px" }}>
                  The Applicant is aware that in addtion to the right of the
                  Developer to reject the Application at its sole discretion,
                  this Application shall be invalid/ be deemed to have been
                  rejected in the event any of the payments made by Applicant(s)
                  towards Apllication Monies or part thereof is not duly
                  honoured/cleared or delayed.
                </Typography>
              </Box>
              <Box sx={{ display: "flex", gap: "10px" }}>
                <Typography sx={{ fontSize: "10px" }}>5.7</Typography>
                <Typography sx={{ fontSize: "10px" }}>
                  Confirmation Letter: Subject to Application being accepted by
                  the Developer and the Applicant(s) fulfiling all the terms and
                  conditions contained herein including realisation of
                  Apllication Monies by the Developers together with the
                  applicable taxes thereon, the Developer shall issue a
                  Confirmation Letter within 30 days of receipt of Application
                  Monies Part 2 together with the applicable taxes thereon. The
                  Allotment of the Premises Shall be deemed to have been
                  accepted only after the Developer has communicated such
                  acceptance through Confirmation Letter and in that case the
                  payments made shall be treated as holding accounts and
                  adjusted against total sale consideration payable for the
                  Premises.
                </Typography>
              </Box>
              <Box sx={{ display: "flex", gap: "10px" }}>
                <Typography sx={{ fontSize: "10px" }}>5.8</Typography>
                <Typography sx={{ fontSize: "10px" }}>
                  The Applicant(s) agree(s) and undertake (s) to execute and
                  register Agreement for Sale under the provision of RERA Act
                  and RERA Rules, in respect of the Premises, on receipt of all
                  requisite approvals, within 30 days from date of intimation by
                  the Developer and in the form as would be drawn up by the
                  Developer recording the agreed commercial terms in the format
                  as would be provided by the Developer and the Applicant(s)
                  shall pay the necessary stamp duty, registration fees and
                  incidental expenses thereon. In the event the Applicant(s)
                  fails to pay the necessary stamp duty, registration fees and
                  incidental expenses thereon and further fails to execute and
                  register the Agreement for Sale in the form and manner
                  provided by the Developer in respect of the Premises then the
                  Developer shall have the right to cancel the Allotment and
                  forfeit the monies in accordance with this Application Form.
                </Typography>
              </Box>
              <Box sx={{ display: "flex", gap: "10px" }}>
                <Typography sx={{ fontSize: "10px" }}>5.9</Typography>
                <Typography sx={{ fontSize: "10px" }}>
                  The Applicant agree(s) and undertakes to be bound by and
                  undertakes to perform all the obligations and the Terms and
                  Conditions contained herein, including the obligation to make
                  payments as per the Payment Schedule.
                </Typography>
              </Box>
            </Stack>
          </Box>
        </Stack>
      </Box>

      <Box
        sx={{
          display: "flex",
          gap: "40px",
          justifyContent: "center",
          position: "absolute",
          bottom: "30px",
        }}
      >
        <Box className={classes.input}>
          <Box>
            <input
              style={{ textAlign: "center" }}
              value={pageData7.sign_first}
              type="text"
              name="sign_first"
              onChange={handleChange}
            />
            <Typography sx={{ textAlign: "center", mt: "10px" }}>
              Signature of First Applicant
            </Typography>
          </Box>
        </Box>
        <Box className={classes.input}>
          <Box sx={{ display: "inline-block" }}>
            <input
              style={{ margin: "0px", textAlign: "center" }}
              value={pageData7.sign_second}
              type="text"
              name="sign_second"
              onChange={handleChange}
            />
            <Typography sx={{ textAlign: "center", mt: "10px" }}>
              Signature of Second Applicant
            </Typography>
          </Box>
        </Box>
        <Box className={classes.input}>
          <Box sx={{ display: "inline-block" }}>
            <input
              style={{ margin: "0px", textAlign: "center" }}
              value={pageData7.sign_third}
              type="text"
              name="sign_third"
              onChange={handleChange}
            />
            <Typography sx={{ textAlign: "center", mt: "10px" }}>
              Signature of Third Applicant
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default PdfOnePageSeven;
