import { Box, Checkbox, Stack, Button } from "@mui/material";
import React, { useEffect, useState } from "react";

import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { useNavigate } from "react-router-dom";

function Commissionlist() {
  const [newRowData, setNewRowData] = useState([]);
  const [curpage, setcurpage] = useState(1);
  const [pageRange, setPageRange] = useState([0, 4]);

  const nextClick = () => {
    if (
      !(newRowData.length >= pageRange[0] && newRowData.length <= pageRange[1])
    ) {
      setcurpage(curpage + 1);
      setPageRange([pageRange[0] + 4, pageRange[1] + 4]);
    }
  };

  const prvClick = () => {
    if (pageRange[0] != 0 && pageRange[1] != 4) {
      setcurpage(curpage - 1);
      setPageRange([pageRange[0] - 4, pageRange[1] - 4]);
    }
  };

  const [pages, setPages] = useState([]);
  useEffect(() => {
    if (newRowData) {
      let arr = [];
      for (
        let i = 1;
        i <=
        (newRowData.length % 4 == 0
          ? newRowData.length / 4
          : newRowData.length / 4 + 1);
        i++
      ) {
        arr.push(i);
      }
      setPages(arr);
    }
  }, [newRowData]);

  const [asleads, setasleads] = useState(null);

  useEffect(() => {}, []);

  return (
    <Box>
      {" "}
      <div style={{ height: 400, width: "100%" }}>
        {/* <DataGrid
    rows={newRowData}
    columns={columns}
    pageSize={10}
    rowsPerPageOptions={[5]}
    onSelectionModelChange={(ids) => onRowsSelectionHandler(ids)}
  /> */}
        <Box sx={{ color: "black", width: "100%" }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              background: "white",
              borderRadius: "5px",
              margin: " 10px 0",
              color: "#9299BC",
              fontWeight: "600",
            }}
          >
            <Box
              sx={{
                width: "10vw",
                display: "grid",
                placeItems: "center",
                height: "50px",
              }}
            >
              <Box sx={{ display: "flex" }}>
                <Box sx={{ display: "grid", placeItems: "center" }}>
                  Slot name
                </Box>
                <Box sx={{ display: "grid", marginLeft: "4px" }}>
                  <ArrowDropUpIcon
                    fontSize="medium"
                    sx={{ marginBottom: "-8px" }}
                  />
                  <ArrowDropDownIcon
                    fontSize="medium"
                    sx={{ marginTop: "-8px" }}
                  />
                </Box>
              </Box>
            </Box>

            <Box
              sx={{
                width: "10vw",
                display: "grid",
                placeItems: "center",
                height: "50px",
              }}
            >
              <Box sx={{ display: "flex" }}>
                <Box sx={{ display: "grid", placeItems: "center" }}>
                  Lead name
                </Box>
                <Box sx={{ display: "grid", marginLeft: "4px" }}>
                  <ArrowDropUpIcon
                    fontSize="medium"
                    sx={{ marginBottom: "-8px" }}
                  />
                  <ArrowDropDownIcon
                    fontSize="medium"
                    sx={{ marginTop: "-8px" }}
                  />
                </Box>
              </Box>
            </Box>

            <Box
              sx={{
                width: "10vw",
                display: "grid",
                placeItems: "center",
                height: "50px",
              }}
            >
              <Box sx={{ display: "flex" }}>
                <Box sx={{ display: "grid", placeItems: "center" }}>
                  Lead phone
                </Box>
                <Box sx={{ display: "grid", marginLeft: "4px" }}>
                  <ArrowDropUpIcon
                    fontSize="medium"
                    sx={{ marginBottom: "-8px" }}
                  />
                  <ArrowDropDownIcon
                    fontSize="medium"
                    sx={{ marginTop: "-8px" }}
                  />
                </Box>
              </Box>
            </Box>

            <Box
              sx={{
                width: "10vw",
                display: "grid",
                placeItems: "center",
                height: "50px",
              }}
            >
              <Box sx={{ display: "flex" }}>
                <Box sx={{ display: "grid", placeItems: "center" }}>
                  Lead Email
                </Box>
                <Box sx={{ display: "grid", marginLeft: "4px" }}>
                  <ArrowDropUpIcon
                    fontSize="medium"
                    sx={{ marginBottom: "-8px" }}
                  />
                  <ArrowDropDownIcon
                    fontSize="medium"
                    sx={{ marginTop: "-8px" }}
                  />
                </Box>
              </Box>
            </Box>

            <Box
              sx={{
                width: "10vw",
                display: "grid",
                placeItems: "center",
                height: "50px",
              }}
            >
              <Box sx={{ display: "flex" }}>
                <Box sx={{ display: "grid", placeItems: "center" }}>
                  Lead priority
                </Box>
                <Box sx={{ display: "grid", marginLeft: "4px" }}>
                  <ArrowDropUpIcon
                    fontSize="medium"
                    sx={{ marginBottom: "-8px" }}
                  />
                  <ArrowDropDownIcon
                    fontSize="medium"
                    sx={{ marginTop: "-8px" }}
                  />
                </Box>
              </Box>
            </Box>

            <Box
              sx={{
                width: "10vw",
                display: "grid",
                placeItems: "center",
                height: "50px",
              }}
            >
              Visit status
            </Box>
          </Box>
          {newRowData &&
            newRowData.map((item, index) => {
              if (index >= pageRange[0] && index <= pageRange[1]) {
                return (
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      background: "white",
                      borderRadius: "5px",
                      margin: " 10px 0",
                      color: "#06152C",
                      fontWeight: "500",
                    }}
                  >
                    <Box
                      sx={{
                        width: "10vw",
                        display: "grid",
                        placeItems: "center",
                        height: "50px",
                      }}
                    >
                      dfsf
                    </Box>
                    <Box
                      sx={{
                        width: "10vw",
                        display: "grid",
                        placeItems: "center",
                        height: "50px",
                      }}
                    >
                      sdfsfsdf
                    </Box>
                    <Box
                      sx={{
                        width: "10vw",
                        display: "grid",
                        placeItems: "center",
                        height: "50px",
                      }}
                    >
                      sfsdfsdf
                    </Box>
                    <Box
                      sx={{
                        width: "10vw",
                        display: "grid",
                        placeItems: "center",
                        height: "50px",
                      }}
                    >
                      sfsfsfd
                    </Box>
                    <Box
                      sx={{
                        width: "10vw",
                        display: "grid",
                        placeItems: "center",
                        height: "50px",
                      }}
                    >
                      sdfsdff
                    </Box>
                    <Box
                      sx={{
                        width: "10vw",
                        display: "grid",
                        placeItems: "center",
                        height: "50px",
                      }}
                    >
                      lkkjd
                      {/* <Stack direction="row" sx={{ marginLeft: "10px" }}>
                        <RemoveRedEyeIcon
                          fontSize="medium"
                          onClick={() => {
                            navigate(`/channelpartneroverview/${item.CPid}`, {
                              state: item,
                            });
                          }}
                        />

                        <EditIcon
                          fontSize="medium"
                          sx={{ marginLeft: "10px" }}
                          onClick={() => updateBuyers()}
                        />
                        <DeleteIcon
                          fontSize="medium"
                          sx={{ marginLeft: "10px" }}
                          onClick={() => delIconClick(item)}
                        />
                      </Stack> */}
                    </Box>
                  </Box>
                );
              }
            })}
        </Box>
        <Box>
          <Box sx={{ display: "flex", justifyContent: "right" }}>
            <Button
              variant="contained"
              sx={{
                background:
                  "linear-gradient(90deg, #0F5DAE 0%, #009DCF 100.33%)",
              }}
              onClick={prvClick}
            >
              <ArrowBackIosIcon fontSize="small" />
              <Box sx={{ textTransform: "capitalize" }}>Previous</Box>
            </Button>
            <Box
              sx={{
                background: "#E3E4EB",
                display: "flex",
                placeItems: "center",
                margin: " 0 10px",
                borderRadius: "5px",
              }}
            >
              {pages.map((page, ind) => {
                return curpage == ind + 1 ? (
                  <Box
                    sx={{
                      background: `white`,
                      color: "black",
                      width: "30px",
                      borderRadius: "5px",
                      margin: "0 10px",
                      display: "grid",
                      placeItems: "center",
                    }}
                  >
                    {page}
                  </Box>
                ) : (
                  <Box
                    sx={{
                      background: `${
                        (0 + newRowData.length) / 2 == ind ? "white" : "#E3E4EB"
                      }`,
                      color: "black",
                      width: "30px",
                      borderRadius: "5px",
                      margin: "0 10px",
                      display: "grid",
                      placeItems: "center",
                    }}
                  >
                    {page}
                  </Box>
                );
              })}
            </Box>
            <Button
              variant="contained"
              sx={{
                background:
                  "linear-gradient(90deg, #0F5DAE 0%, #009DCF 100.33%)",
              }}
              onClick={nextClick}
            >
              <Box sx={{ textTransform: "capitalize" }}>Next</Box>
              <ArrowForwardIosIcon fontSize="small" />
            </Button>
          </Box>
        </Box>
      </div>
    </Box>
  );
}

export default Commissionlist;
