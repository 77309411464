import React, { useContext, useEffect } from "react";
import { TextField } from "@mui/material";
import { Link, useLocation } from "react-router-dom";
import { useState } from "react";

import { Box, Button, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import DoneIcon from "@mui/icons-material/Done";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import KeyboardDoubleArrowLeftIcon from "@mui/icons-material/KeyboardDoubleArrowLeft";
import { useNavigate } from "react-router-dom";
import { Navbar } from "../DashboardComponents/Navbar";
import { Sidebar } from "../DashboardComponents/Sidebar";
import Divider from "@mui/material/Divider";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";

import currentProjectState from '../../../../context/createproject/context';
import { Apiaddress } from "../../../../utils/consts";
import axios from "axios";
import { MainSidebar } from "../DashboardComponents/MainSidebar";

function ConfirmProject2_wing_floors_details() {

  const cpContext = useContext(currentProjectState);
  const projectID = cpContext?.createProjectState?.project?.id;
    
  ////get data from previous page 
  const location = useLocation();
  let data = location.state.state;
  let building_data = location.state.data;

  console.log("preve data =>.>",data)
  console.log("current data =>.>",building_data)

  const navigate = useNavigate();

  const [unitnumber, setunitnumber] = useState(4);
  const [floorname, setfloorname] = useState(data?.floor_name);

  const [unitdata, setunitdata] = useState([]);

  const [unittemp, setunittemp] = useState([]);


  const addunitdata = () => {
    let temp = unitdata.length + 1;
    let to_insert = {
      unit_no: temp,
      unit_name: "",
    };
    setunitdata([...unitdata, to_insert]);
    setunitnumber(unitdata.length + 1);
  };

  const removeunitdata = (newunitdata, index) => {
    for (let i = index; i < newunitdata.length; i++) {
      newunitdata[i].unit_no = newunitdata[i].unit_no - 1;
    }
    // console.log(newbedroomdata);
    setunitdata(newunitdata);
    setunitnumber(unitdata.length - 1);
  };

  // useEffect(()=>{

  //   let data=[];
  //   for(let i=1;i<=floornumber;i++){
  //     let to_insert={
  //       floor_no:i,
  //       floor_name:""
  //     }
  //     data.push(to_insert);
  //     }
  //   setfloordata(data);

  // },[floornumber]);

  ///get floor data by id;
  useEffect(()=>{
    getWingsData()
  },[])
  const getWingsData = ()=>{
    let url = Apiaddress+"/api/projects/floortemplate/?floor_template_id="+data?.floor_id;

    axios.get(url)
    .then((res)=>{
      console.log(res.data);
      setunitnumber(res.data?.floor_template[0]?.unittemplates?.length)
      setunitdata(res.data?.floor_template[0]?.unittemplates)
    }).catch((err)=>{
      console.log(err);
    })
  }

  ////get all unit  templates 
  useEffect(()=>{
    const uniturl = Apiaddress+"/api/projects/unittemplatesbyproject/?project_id="+projectID

    axios.get(uniturl)
    .then((res)=>{
      console.log(res.data);
      setunittemp(res.data?.unit_template)
    }).catch((err)=>{
      console.log(err);
    })
   },[])

   /// update floor templates 

   const updateFloorTemplate = ()=>{
     
    let url = Apiaddress+"/api/projects/floortemplate/";
 
   let st = {
    floor_template_id:data?.floor_id,
    unittemplates:unitdata
   }

   let headers = {
     headers:{
       "Content-Type":"application/json"
   }
   }
   console.log(st); 
   
   axios.patch(url,st,headers)
   .then((res)=>{
     console.log(res);
   })
   .catch((err)=>{
     console.log(err);
   })

  }


  return (
    <>

<Stack direction="row" sx={{width:"100%",height:"auto" }}>
      {/* 1st stack */}
      <Stack sx={{minHeight:"100vh",width:"20%",boxSizing:"border-box",}}> {/*for project directory 20% and for sales dashboard 225*/}
        <MainSidebar active={"Project Directory"}/>
      </Stack>

     {/* 2nd stack */}
     <Stack direction="column" sx={{ backgroundColor:"#f9f9f9",width:"80%", padding:"25px",boxSizing:"border-box",}}  >  {/*  width for main sidebar 78px and for 80px*/}
      <Navbar/>
      


    
      <Typography
            sx={{
              marginTop: "20px",
              fontSize: "28px",
              fontWeight: "600",
              marginLeft: "10px",
              marginBottom: "50px",
            }}
          >
            Floor Template Details
          </Typography>

     {/* actual content  */}
    <Stack sx={{backgroundColor:"#f9f9f9"}}>  {/* mt:"28px", */}

    <Stack
            direction="column"
            sx={{
              padding: "20px",
              backgroundColor: "#fff",
              marginRight: "0px",
            }}
          >
            {/* Wing Template */}
            <Box sx={{}}>
              <Box sx={{ paddingBottom: "40px" }}>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    margin: "30px 0px 0px 0px",
                  }}
                >
                  <Typography component="span" className="field-name">
                    Floor Template Name
                  </Typography>
                  <TextField
                    disabled
                    sx={{
                      width: "188px",
                      marginLeft: "40px",
                    }}
                    value={floorname}
                  />
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    margin: "30px 0px 0px 0px",
                  }}
                >
                  <Typography component="span" className="field-name">
                    No of Units in Floor{" "}
                  </Typography>
                  <TextField
                    inputProps={{ min: 0 }}
                    disabled
                    sx={{
                      width: "188px",
                      marginLeft: "40px",
                    }}
                    type="Number"
                    value={unitnumber}
                  />
                </Box>

                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  sx={{
                    height: "50px",
                    background:
                      "linear-gradient(90deg, #0F5DAE 0%, #009DCF 100.33%)",
                    boxShadow:
                      "0px 13.7981px 22.5788px rgba(50, 174, 137, 0.25)",
                    textTransform: "none",
                    borderRadius: "4px",
                    width: "150px",
                    fontSize: "15px",
                    margin: "30px 0px 0px 0px",
                  }}
                  onClick={addunitdata}
                >
                  <AddIcon sx={{ marginRight: "10px" }} />
                  Add Unit
                </Button>
              </Box>

              <Divider />
              <Box>
                {unitdata.map((ele, index) => {
                  return (
                    <Box
                      key={index}
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        margin: "30px 0px 0px 70px",
                      }}
                    >
                      <Typography component="span" className="field-name">
                        Unit {ele.unit_no}
                      </Typography>

                      <FormControl sx={{ width: "188px", marginLeft: "40px" }}>
                        <Select
                          value={ele.unit_name+"-"+ele.unit_id}
                          onChange={(e) => {
                            let arr = unitdata;
                            let [name,id] = e.target.value.split("-");

                            arr[index].unit_name = name;
                            arr[index].unit_id = +id;
                            // arr[index].unit_name = e.target.value;
                            setunitdata([...arr]);
                          }}
                          displayEmpty
                          inputProps={{ "aria-label": "Without label" }}
                        >
                          {unittemp.map((ele, index) => {
                            return (
                              <MenuItem key={index} value={ele.name+"-"+ele.id}>
                                {ele.name}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </FormControl>

                      <DeleteIcon
                        sx={{
                          fontSize: "26px",
                          fontWeight: "500",
                          color: "#EF3649",
                          margin: "0px 0px 0px 20px",
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          const newunitdata = unitdata.filter((x) => x !== ele);
                          console.log(newunitdata);
                          removeunitdata(newunitdata, index);
                        }}
                      />

                      {/* <Typography className='' sx={{margin:"0px 0px 0px 20px",fontFamily:'Poppins',fontStyle:"normal",fontWeight:"500",fontSize: "12px",lineHeight:"18px",color:"#A2A3A6"}}>Can't Find The Template?</Typography>
                <Typography className='' sx={{margin:"0px 0px 0px 5px",fontFamily:'Poppins',fontStyle:"normal",fontWeight:"500",fontSize: "12px",lineHeight:"18px",color:"#FF6140",cursor:"pointer"}} onClick={()=>{navigate('/floortemplate')}}>Create New</Typography> */}
                    </Box>
                  );
                })}

                {unitnumber > 0 && (
                  <Link
                  to="/confirm-project-wing-floors" state={{state:{wing_name:building_data.name, wing_id:building_data.id}}} >
                  <Button
                    onClick={() => {
                      updateFloorTemplate()
                    }}
                    variant="contained"
                    color="primary"
                    sx={{
                      height: "50px",
                      background:
                        "linear-gradient(90deg, #0F5DAE 0%, #009DCF 100.33%)",
                      boxShadow:
                        "0px 13.7981px 22.5788px rgba(50, 174, 137, 0.25)",
                      textTransform: "none",
                      borderRadius: "4px",
                      width: "150px",
                      fontSize: "15px",
                      margin: "100px 0px 70px 70px",
                    }}
                  >
                    Save
                  </Button>
                  </Link>
                )}
              </Box>
            </Box>
          </Stack>
      

    </Stack>
      </Stack>
</Stack>


      
    </>
  );
}

export default ConfirmProject2_wing_floors_details;
