import { Button } from '@mui/material'
import React from 'react'

export const BlueButton = ({ children, onClick, extrasx }: {
    children: any,
    onClick: () => void,
    extrasx?: {}

}) => {
    return (
        <Button
            onClick={async () => {
                onClick()
            }}
            // variant="contained"
            // color="primary" sx={{ height: "40px", background: "linear-gradient(90deg, #0F5DAE 0%, #009DCF 100.33%)", boxShadow: "0px 13.7981px 22.5788px rgba(50, 174, 137, 0.25)", textTransform: "none", borderRadius: "4px", width: "100px", ...(extrasx == null ? {} : extrasx), fontSize: "15px", margin: "0px 10px 0px 10px" }}
            className="univercel_btn_class"
            sx={{
                mt: 3,
                color: "#FFFFFF",
                margin: "0px 10px 0px 10px",
                textTransform: "none",
                padding: "6px 24px",
                fontFamily: "Product Sans",
                fontSize: "16px",
            }}
        >
            {children}
        </Button>
    )
}
