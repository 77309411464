const BasicLayout=  {
    // title: "Basic Detail",
    title: "Form Fields",
    formFields: [
        // {
        //     type: "normal", // normal || withtrailing || radio || check || doubletrailing 
        //     name: "Description",
        //     value: ""
        // },
        {
            type: "normal", // normal || withtrailing || radio || check || doubletrailing 
            name: "Required number of bedrooms",
            value: ""
        },
        {
            type: "normal", // normal || withtrailing || radio || check || doubletrailing 
            name: "Required number of bathrooms",
            value: ""
        },
        {
            type: "normal", // normal || withtrailing || radio || check || doubletrailing 
            name: "Required number of balconies",
            value: ""
        },
        {
            type: "withtrailing", // normal || withtrailing || radio || check || doubletrailing 
            name: "Total builtup area",
            trailing: "Sq.ft",
            value: ""
        },
        {
            type: "radio", // normal || withtrailing || radio || check || doubletrailing 
            orientation: "horizontal",
            name: "Parking Required ?",
            trailing: "Sq.ft",
            options: {
                selected: undefined,
                names: ["Yes", "No"]
            }
        },
        {
            type: "radio", // normal || withtrailing || radio || check || doubletrailing 
            orientation: "horizontal",
            name: "Parking Location",
            options: {
                selected: undefined,
                names: ["Indoor", "Outdoor"]
            }
        },
        {
            type: "check", // normal || withtrailing || radio || check || doubletrailing 
            orientation: "horizontal",
            name: "Facing Direction",
            options: [
                {
                    selected: false,
                    name: "Any"
                },
                {
                    selected: false,
                    name: "North"
                }, {
                    selected: false,
                    name: "South"
                }, {
                    selected: false,
                    name: "East"
                }, {
                    selected: false,
                    name: "West"
                },
            ]

        },
        {
            type: "doubletrailing", // normal || withtrailing || radio || check || doubletrailing 
            name: "Prefered Budget",
            trailing: ["To", "INR"],
            value: ""
        },


    ]
}
export { BasicLayout }