import { Button, FormControl, MenuItem, Select, Stack, Typography } from "@mui/material"
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useEffect, useState } from "react";
import { Apiaddress } from "../../../utils/consts";
import axios from "axios";


export const MarkOccupancy = ({setState,selectedUnits,setSelectedUnits})=>{
  const [value, setValue] = useState("")

  useEffect(()=>{
    getOccupancy()
  },[])
  
  const [occupancy, setOccupancy] = useState([]);
  const getOccupancy = async()=>{
    let url = Apiaddress+"/api/projects/unitstatus/";
  
    let res = await axios.get(url);
     console.log("occupancy: ", res.data)
    setOccupancy(res.data.unit_status)
  }

  const setOccupancyToUnit = async()=>{
    if(value=="")return;
    let url = Apiaddress+"/api/projects/assignunitstatus/";
    // post  method:  Eg: {
    //   "unit_ids":[360,359],
    //   "unit_status_id":2
    //   }
    let data = {
      unit_ids: selectedUnits,//[360,359],
      unit_status_id:+value.split("-")[0]
    }
    console.log("data", data)
    // return;
    let res = await axios.post(url, data)
     if(res.data.error==false){
      setState(false);
      setSelectedUnits([]);
     }
    console.log("data re baba  :",res.data);



  }

    return(
        <>
        <Stack sx={{
              width: "370px",
              height: "80vh",
              marginTop: "20px",
              padding: "30px",
          }}>

            <Stack direction="row" onClick={()=>{setState(false)}} sx={{height:"5%",  cursor:"pointer"}}>
              <ArrowBackIcon/>
              <Typography>Units Occupancy Allocations</Typography>
            </Stack>

            <Stack direction="column" alignItems="left" justifyContent="center" sx={{height:"80%",}}>
            <Typography sx={{mb:"5px", fontWeight:"700"}}>Select Occupancy</Typography>
            
            <FormControl sx={{ minWidth: 220 }}>
                 <Select
                   value={value}
                   onChange={(e)=>{
                    setValue(e.target.value)
                  //      getWingsOrFloor(e.target.value.split("-")[0]);
                   }}
                   displayEmpty
                   inputProps={{ 'aria-label': 'Without label' }}
                 >
                   <MenuItem value="">
                     <em>Select</em>
                   </MenuItem>
                   {occupancy.map((ele,ind)=>{
                    return <MenuItem key={ele.id} value={ele.id+"-"+ele.name}>{ele.name}</MenuItem> 
                   })}
                 </Select>
               </FormControl>

               <Button
               onClick={()=>{
                setOccupancyToUnit();
               }}
               startIcon={<svg width="20" height="19" viewBox="0 0 20 19" fill="none" xmlns="http://www.w3.org/2000/svg">
               <path d="M9.2301 4.79052V0.781505C9.2301 0.355229 9.5701 0 10.0001 0C10.3851 0 10.7113 0.298491 10.763 0.67658L10.7701 0.781505V4.79052L15.55 4.79083C17.93 4.79083 19.8853 6.73978 19.9951 9.17041L20 9.38609V14.4254C20 16.873 18.1127 18.8822 15.768 18.995L15.56 19H4.44C2.06 19 0.11409 17.0608 0.00483778 14.6213L0 14.4047L0 9.37576C0 6.9281 1.87791 4.90921 4.22199 4.79585L4.43 4.79083H9.23V11.1932L7.63 9.54099C7.33 9.23119 6.84 9.23119 6.54 9.54099C6.39 9.69588 6.32 9.90241 6.32 10.1089C6.32 10.2659 6.3648 10.4295 6.45952 10.5679L6.54 10.6666L9.45 13.6819C9.59 13.8368 9.79 13.9194 10 13.9194C10.1667 13.9194 10.3333 13.862 10.4653 13.7533L10.54 13.6819L13.45 10.6666C13.75 10.3568 13.75 9.85078 13.45 9.54099C13.1773 9.25936 12.7475 9.23375 12.4462 9.46418L12.36 9.54099L10.77 11.1932V4.79083L9.2301 4.79052Z" fill="white"/>
               </svg>
               }
               sx={{mt:"25px",padding:"12px"}}
               className="univercel_btn_class"
               > Save Changes</Button>

            </Stack>


          </Stack>
        </>
    )
}