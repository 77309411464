import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    MenuItem,
    Drawer,
    Box,
    Button,
    Checkbox,
    FormControl,
    InputLabel,
    Select,
    TextField,
    Typography,
  } from "@mui/material";
  import { Stack } from "@mui/system";
  import TuneOutlinedIcon from "@mui/icons-material/TuneOutlined";
  import KeyboardArrowRightOutlinedIcon from "@mui/icons-material/KeyboardArrowRightOutlined";
  import KeyboardArrowUpOutlinedIcon from "@mui/icons-material/KeyboardArrowUpOutlined";
  import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined";
  import { CheckBox } from "@mui/icons-material";
  import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
  import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
  import DriveFileRenameOutlineIcon from "@mui/icons-material/DriveFileRenameOutline";
  import VisibilityIcon from "@mui/icons-material/Visibility";
  import DeleteIcon from "@mui/icons-material/Delete";
  import React, { useContext, useEffect, useState } from "react";
  import { DataGrid } from "@mui/x-data-grid";
  import CategoryIcon from "@mui/icons-material/Category";
  import HandshakeIcon from "@mui/icons-material/Handshake";
  import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
  import RightSidebarCPD from "./RightSidebarCPD";
  import profileContext from "../../../context/profile/context";
  import axios from "axios";
  import RightSidebarCPDadd from "./RightSidebarCPDadd";
  import { basicheaders } from "../../../utils/consts";
  import { useNavigate } from "react-router-dom";
  import CPDcustomTable from "./CPDcustomTable";
  import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
  import { Apiaddress } from "../../../utils/consts";
import { ProjectDashboardSidebar } from "../../BuilderComponents/ProjectDashboard/DashboardComponents/ProjectDashboardSidebar";
import { Navbar } from "../../BuilderComponents/ProjectDashboard/DashboardComponents/Navbar";
import { NavbarForDirectory } from "../../BuilderComponents/ProjectDashboard/DashboardComponents/NavbarForDirectory";

import builderDashboard from "../../../context/builder_dashboard/context";

import Modal from '@mui/material/Modal';


export const ChannelPartener_Directory_w_sb = ()=>{  


  const OrganizationsContext = useContext(builderDashboard);
  console.log(OrganizationsContext);
    
  const navigate = useNavigate();
  const columns = [
    { field: "id", headerName: "id", width: 130 },
    { field: "RERARegNo", headerName: "RERA Reg No", width: 130 },
    { field: "Type", headerName: "Type", width: 130 },
    { field: "Name", headerName: "Name", width: 130 },
    {
      field: "Email",
      headerName: "Email",
      // type: "number",
      width: 130,
    },
    {
      field: "Phone",
      headerName: "Phone",
      width: 130,
    },
    {
      field: "Action",
      headerName: "Action",
      headerAlign: "center",
      width: 130,
      renderCell: (params) => {
        return (
          <Stack direction="row" sx={{ marginLeft: "10px" }}>
            <RemoveRedEyeIcon
              fontSize="medium"
              onClick={() => navTocpo(params)}
            />
            <DeleteIcon
              fontSize="medium"
              sx={{ marginLeft: "10px" }}
              onClick={() => delIconClick(params)}
            />
          </Stack>
        );
      },
    },
  ];

  const navTocpo = (params) => {
    return; /// for temporary 
    navigate(`/channelpartneroverview/${params?.row?.CPid}`);
  };

  const rows = [
    {
      id: 1,
      RERARegNo: "#4560",
      Type: "26 March 2022, 12:42 AM",
      Name: "Seema Agarwal",
      Email: "67 St. pune",
      Phone: "Agent",
    },
  ];

  const [state, setState] = React.useState(false);

  const toggleDrawer = (open, item) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    updateBuyers(item);

    setState(open);
  };

  const [stateadd, setStateadd] = React.useState(false);

  const toggleDrawerAdd = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setStateadd(open);
  };

  const [selectedRows, setSelectedRows] = React.useState([]);
  useEffect(() => {
    console.log(selectedRows);
  }, [selectedRows]);

  const [curselectedRows, setcurselectedRows] = useState(null);
  const onRowsSelectionHandler = (ids) => {
    setRowSelected(true);
    const selectedRowsData = ids?.map((id) => rows.find((row) => row.id === id));
    console.log(selectedRowsData);
    setcurselectedRows(selectedRowsData);
  };

  const [tabledata, setTabledata] = useState(null);
  const [rowData, setrowData] = useState([]);
  const [idCont, setidCunt] = useState(1);

  //api

  //api

  useEffect(() => {
    const rows = [
      {
        id: 8,
        Name: "Frances",
        Phone: "912912912",
        Email: "dummmy@gamil.com",
        Team: "Agent",
        Role: "Agent",
        DetailedView: "View",
      },
    ];
    if (tabledata) {
      let obj = {
        id: idCont,
        Name: tabledata.name,
        Phone: tabledata.phone,
        Email: tabledata.Email,
        Team: tabledata.Team,
        Role: tabledata.Role,
      };
      setidCunt(idCont + 1);
      console.log(obj);
      setrowData([...rowData, obj]);
    }

    console.log(tabledata);
  }, [tabledata]);

  const [rowSelected, setRowSelected] = useState(false);

  const [orgID, setOrgID] = useState(null);
  const user = useContext(profileContext);

  const [builderId, setbuilderId] = useState(null);

  /// get selected project id from local storage
  let builderSelectedData = JSON.parse(localStorage.getItem("BuilderSelected"));
  let id = builderSelectedData.selectedProjectID;

  useEffect(() => {
    if (user)
      if (user.UserState)
        if (user.UserState.user_obj)
          if (user.UserState.user_obj.orgList)
            if (user.UserState.user_obj.orgList[0]) {
              setOrgID(user.UserState.user_obj.orgList[0]);
            }
  }, [user]);

  const [data, setData] = useState(null);

  const [trigger, setTrigger] = useState(false);

  useEffect(() => {
    if (user) {
      if (user.UserState) {
        if (user.UserState.user_obj) {
          setbuilderId(user.UserState.user_obj.id);
        }
      }
    }
  }, [user, trigger]);

  const getCP = async () => {
    try {
      const res = await axios.get(
        Apiaddress + "/api/role/cpofproject/?project_id="+id
      );
      console.log(res.data.cplist);
      if(res.data?.cplist=="channel partner not found"){
       setData([]);
      }
      else{
        setData(res.data.cplist);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const [datafromapi, setdatafromapi] = useState([]);

  useEffect(() => {
    if (data) {
      console.log(data);

      let arr = [];
      for (let i = 0; i < data.length; i++) {
        const obj = {
          id: i + 1,
          CPid: data[i]?.id,
          RERARegNo: data[i]?.rera_no,
          Type: data[i]?.cp_type,
          Name: data[i]?.profile?.user_details.first_name,
          Email: data[i]?.profile?.user_details.email,
          Phone: data[i]?.profile?.phone,
          profile_id: data[i]?.profile ? data[i]?.profile.id : 1,
        };
        arr.push(obj);
      }
      setdatafromapi(arr);
    }
  }, [data]);

  console.log(datafromapi);

  useEffect(() => {
    console.log(builderId);
    if (builderId) {
      getCP();
    }
  }, [builderId, trigger]);

  const [updateInfo, setupdateInfo] = useState(null);
  const updateBuyers = (params) => {
    setupdateInfo(params);
  };


  //fitering data starts:====
  const [filterselect, setfilterselect] = useState();
  const [filtervalue, setfiltervalue] = useState();

  const handleChange = (event) => {
    setfilterselect(event.target.value);
    setfiltervalue("");
  };

  console.log(filterselect);
  console.log(filtervalue);

  const fetchfilter = async () => {
    try {
      const res = await axios.get(
        Apiaddress + `/api/role/cpofproject/?project_id=${id}&filterkey=${filterselect}&filtervalue=${filtervalue}`
      );
     console.log(res.data.cplist);
      if(res.data?.cplist=="channel partner not found"){
       setData([]);
      }
      else{
        setData(res.data.cplist);
      }
    } catch (err) {
      console.log(err);
    }
  };
  

  // modal functions
  const [selectedCP,setSelectedCP]=useState("");
  const [selectedCPid,setSelectedCPid]=useState("");
  const [allCP,setallCP]=useState([]);

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  
  const handleAdd=(event)=>{
    let temp = event.target.value;
    let details = temp.split("_")

   setSelectedCP(temp);
   setSelectedCPid(details[1]);
   console.log("hhhhhhhhhhhhhhhhhhhhhhhhhhhh",details[0],details[1]);
  }


  const addToCPList= async ()=>{
    try{
      // console.log(selectedCPid);
      let CPdetails=allCP.filter((ele)=>{
        return ele["cp_id"]==selectedCPid;
      });
      // console.log("rrrr",CPdetails)
      let newProject=CPdetails[0].projects;
      newProject.push(id);
      console.log(newProject);
      let obj={
        cp_id:+selectedCPid,
        projects:newProject
      }
      console.log(obj);
      const res = await axios.patch(
        Apiaddress + `/api/role/channelpartner/`,
        obj,
        {
          headers:{
            "Content-Type":"application/json"
          }
        }
      );
     console.log(res.data);
     setTrigger(!trigger);
    }catch (err) {
      console.log(err);
    }
  }

  const getAllCP = async () => {
    console.log(builderId);
    try {
      const res = await axios.get(
        Apiaddress + `/api/role/getcpusingbuilderid/?builder_id=${builderId}`
      );
      if(res.data.channel_partner=="channel_partner not found"){
        setallCP([]);
      }
      else{
        console.log(res.data?.channel_partner);
        let temp=res.data?.channel_partner;
        let newarray=[];
        for(let i=0;i<temp.length;i++){
          let obj={
            cp_id:temp[i]?.id,
            projects:temp[i]?.projects,
            name:temp[i]?.profile?.user_details?.first_name
          }
          newarray.push(obj);
        }
        setallCP(newarray);
        handleClose();
      }
      
    } catch (err) {
      console.log(err);
      setallCP([]);
    }
  };

  const delIconClick = async (params) => {
    console.log(params.CPid);
    console.log("rrrr",allCP)
     try{
      let CPdetails=allCP.filter((ele)=>{
        return ele["cp_id"]==params.CPid;
      });

      console.log("rrrr",CPdetails)
      let newProject=CPdetails[0].projects;
      newProject=newProject.filter((ele)=>{
        return ele!=id
      })
      console.log(newProject);
      let obj={
        cp_id:CPdetails[0].cp_id,
        projects:newProject
      }
      console.log(obj);

      const res = await axios.patch(
        Apiaddress + `/api/role/channelpartner/`,
        obj,
        {
          headers:{
            "Content-Type":"application/json"
          }
        }
      );

     console.log(res.data);
     setTrigger(!trigger);
    }catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    console.log(builderId);
    if (builderId) {
      getAllCP();
    }
  }, [builderId, trigger]);

  console.log(allCP);

    return(
        <>

<Stack direction="row" sx={{width:"100%",height:"auto" }}>
      {/* 1st stack */}
      <Stack sx={{minHeight:"100vh",width:"22%",boxSizing:"border-box"}}>
      <ProjectDashboardSidebar active={"Channel Partnter Directory"}/>
      </Stack>

     {/* 2nd stack */}
     <Stack direction="column" sx={{ backgroundColor:"#f9f9f9",width:"78%", padding:"25px",boxSizing:"border-box",}}  >
      {/* <Navbar/> */}
      <NavbarForDirectory
          showAddProjectbtn={false}
          showRightSideBar={false}
          showRightSideBarleft={true}
          RightBarNameleft={"Add Channel Partner"}
          toggleDrawer={toggleDrawer}
          toggleDrawerAdd={toggleDrawerAdd}
          RightBarName={"Add Channel Partner"}
          leftRightTitle1={"Channel partner Dashboard"}
          cpaddtitle={"Add channerl partner"}
          openModal={handleOpen}
          closeModal={handleClose}
          leftRightTitle2={"Channel Partner Directory"}
          leftTitle={"Project Dashboard"}
          showLeftTitle={false}
          righshow={true}
          longOrangBgr={true}
        />

        {/* Modal to add Channel Partner */}

          <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description" 
      >
        <Box sx={{position: 'absolute',
             top: '50%',
             left: '50%',
             transform: 'translate(-50%, -50%)',
             width: "25%",
             bgcolor: 'white',
             border: '2px solid white',
             borderRadius:"10px",
             boxShadow: 24,
             pt: 3,
             px: 3,
             pb: 1.5,
             textAlign:"center"
            }}>
           <Typography sx={{
              fontSize: "18px",
              fontWeight: "600",
              marginBottom: "30px",
              color: "black",
            }}>Add Channel Partner</Typography>
            <Box sx={{textAlign:"center",display:"flex",flexDirection:"column",justifyContent:"center",alignItems:"center"}}>
                  <FormControl sx={{ width: "200px" }}>
                    <InputLabel id="demo-simple-select-label">
                      Select
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={selectedCP}
                      label="Select"
                      onChange={handleAdd}
                    >
                      {allCP.map((ele,idx)=>{
                        return(
                          <MenuItem  value={ele?.name+"_"+ele.cp_id}>{ele?.name}</MenuItem>
                        )
                      })}
                    </Select>
                  </FormControl>
                  <Button 
                    onClick={()=>{
                      addToCPList()
                    }}
                    variant="contained"
                    color="primary" sx={{ height:"40px", background: "linear-gradient(90deg, #0F5DAE 0%, #009DCF 100.33%)",boxShadow: "0px 13.7981px 22.5788px rgba(50, 174, 137, 0.25)", textTransform: "none", borderRadius: "4px", width:"100px", fontSize:"15px",margin:"20px 0px 10px 0px"}}>
                     Add
                  </Button>

                </Box>
          <Typography sx={{color:"#ff4500",fontWeight:"500",marginBottom:"20px",fontFamily:"Poppins",fontSize:"1.2rem"}}></Typography>
        </Box>
      </Modal>






    {/* <Typography sx={{mt:"20px", fontSize:"22px", fontWeight:"500",color:"#2979F2"}}>
    Project Name
    </Typography> */}

     {/* actual content  */}

     <Stack sx={{ mt:"28px",backgroundColor:"#f9f9f9"}}>

{/* <React.Fragment>
      <Drawer
        anchor="right"
        open={stateadd}
        onClose={toggleDrawerAdd(false)}
      >
        <RightSidebarCPD
          setTabledata={setTabledata}
          curselectedRows={curselectedRows}
        />
      </Drawer>
    </React.Fragment> */}

    {/* <React.Fragment> 
      <Drawer anchor="right" open={stateadd} onClose={toggleDrawerAdd(false)}>
        <RightSidebarCPDadd
          updateInfo={updateInfo}
          setTabledata={setTabledata}
          // curselectedRows={curselectedRows}  
          setupdateInfo={setupdateInfo}
          setTrigger={setTrigger}
          trigger={trigger}
          proId={id}
        />
      </Drawer>
    </React.Fragment> */}


    <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        sx={{ height: "68px", marginBottom: "20px" }}
      >
        <Stack direction="column" alignItems="center">
          <Typography
            sx={{
              fontSize: "22px",
              fontWeight: "600",
              marginLeft: "-45px",
              color: "black",
            }}
          >
            All Channel partner
          </Typography>
          <Typography
            sx={{
              color: "#B9B7D0",
              fontSize: "12px",
              fontWeight: "400",
            }}
          >
            Here is your Channel Partner Directory list data
          </Typography>
        </Stack>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Stack
            direction="row"
            justifyContent="space-around"
            alignItems="center"
            sx={{
              width: "203px",
              height: "68px",
              borderRadius: "12px",
              color: "#3E4954",
              backgroundColor: "#f4f5f9",
            }}
          >

            
            <Accordion sx={{ width: "250px", boxShadow: "none" }}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography sx={{ display: "flex", placeItems: "center" }}>
                  <TuneOutlinedIcon sx={{ color: "#2F4CDD" }} />
                  <Typography sx={{ marginLeft: "25px" }}>
                    Fillters
                  </Typography>
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Box sx={{textAlign:"center"}}>
                  <FormControl sx={{ width: "200px" }}>
                    <InputLabel id="demo-simple-select-label">
                      Select
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={filterselect}
                      label="Select"
                      onChange={handleChange}
                    >
                      <MenuItem value={"rera_no"}>Rera No.</MenuItem>
                      <MenuItem value={"cp_type"}>Type</MenuItem>
                    </Select>
                  </FormControl>
                  <TextField
                    sx={{ marginTop: "20px" }}
                    placeholder="Enter the value" 
                    value={filtervalue} onChange={(e)=>{setfiltervalue(e.target.value)}}
                  />
                  <Button 
                    onClick={fetchfilter}
                    variant="contained"
                    color="primary" sx={{ height:"40px", background: "linear-gradient(90deg, #0F5DAE 0%, #009DCF 100.33%)",boxShadow: "0px 13.7981px 22.5788px rgba(50, 174, 137, 0.25)", textTransform: "none", borderRadius: "4px", width:"100px", fontSize:"15px",margin:"20px 0px 10px 0px"}}>
                     Submit
                  </Button>

                </Box>
              </AccordionDetails>
            </Accordion>


          </Stack>
        </Stack>
      </Stack> 
   
      <CPDcustomTable
        newRowData={datafromapi}
        delIconClick={delIconClick}  
        updateBuyers={updateBuyers}
        setTrigger={setTrigger}
        trigger={trigger}
        toggleDrawer={toggleDrawer}
      />


</Stack>

      </Stack>
</Stack>
        
        </>
    )
}