import { Stack } from "@mui/system";
import { IconButton, InputAdornment, OutlinedInput, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import "../Signup.css";
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import { Apiaddress } from "../../../../utils/consts";
import {useForm} from "react-hook-form"
import { useState } from "react";
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import VisibilityIcon from '@mui/icons-material/Visibility';
import axios from "axios";



export const PasswordCreate = ({stepsno,setStepsno,signupdata}) => {


    const { register, handleSubmit, watch, formState: { errors } } = useForm();
    
    
    const [pass, setpass] = useState("");

    const onSubmit = (data) => {
        console.log(data)
        console.log(signupdata)
        
        ////create password 
        axios.post(Apiaddress+"/api/createpassword/",{
          email:signupdata.email,
          phoneNo:signupdata.phone,
          deviceToken:"token",
          password:data.password,           
        }).then((res)=>{
          console.log("res", res);
          storeTokenToLocal(data)
        }).catch((err)=>{
          console.log("err", err)
        })
        
     
    };

    const storeTokenToLocal = (data)=>{
       ////login to get token for store locally in background
        axios.post(Apiaddress+"/api/auth/login/", {
          email:signupdata.email,
          password:data.password,
        }).then((res)=>{
          console.log(res);
          localStorage.setItem('crm_token', res.data.token);//store to local storage
          setStepsno(prev=>prev+1)
      }).catch((err)=>{
          console.log("err:", err)
          alert("err")
      })
    }


    const [values, setValues] = useState({
        password: '',
        showPassword: false,
        confirmPassword:"",
        showConfirmPassword:false
      });
    
    const handleChange = (prop) => (event) => {
        setValues({ ...values, [prop]: event.target.value });
      };

    
    return (
        <>
       
                <form onSubmit={handleSubmit(onSubmit)}>

            <Box sx={{padding:"10px 46px", height:"70vh"}}>
                <Typography sx={{marginBottom:"20px", fontSize:"20px", fontWeight:"400"}}>Please setup your Password</Typography>
                
                    

                <Typography sx={{fontWeight: "500",fontSize: "18px"}}>Create Password</Typography>

                <Box sx={{ width: "40%",  height:"50px", margin:"10px 0px 0px 0px" }}>
                <OutlinedInput
            id="outlined-adornment-password"
            type={values.showPassword ? 'text' : 'password'}
            // value={values.password}
            // onChange={handleChange('password')}
            {...register("password", {required:"password is required."})}
            onChange={(e)=>setpass(e.target.value)}
            error={Boolean(errors.password)}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={()=>setValues({ ...values, showPassword: !values.showPassword })}
                  edge="end"
                >
                  {values.showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                </IconButton>
              </InputAdornment>
            }
            labelWidth={70}
              />
                </Box> 
              {errors?.password?<Typography 
              sx={{fontSize:"14px",fontWeight:"400", color:"#da6061",marginLeft:"10px",}}
              >{errors.password?.message}</Typography>:null}


                <Typography sx={{fontWeight: "500",fontSize: "18px", marginTop:"20px"}}>Confirm Password</Typography>
                <Box sx={{ width: "40%",  height:"50px", margin:"10px 0px 0px 0px" }}>
                {/* <input type="text" className="input_signup"/> */}
                <OutlinedInput
            id="outlined-adornment-password"
            type={values.showConfirmPassword ? 'text' : 'password'}
            {...register("confirmPassword", {required:"Confirm Password is required.",validate: value => value === pass})}
            error={Boolean(errors?.confirmPassword)}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={()=>setValues({ ...values, showConfirmPassword: !values.showConfirmPassword })}
                  edge="end"
                >
                  {values.showConfirmPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                </IconButton>
              </InputAdornment>
            }
            labelWidth={70}
                />
                </Box> 
                {errors?.confirmPassword?<Typography 
              sx={{fontSize:"14px",fontWeight:"400", color:"#da6061",marginLeft:"10px",}}
              >{errors.confirmPassword?.message}</Typography>:null}

                {/* <Typography sx={{fontWeight: "500",fontSize: "18px"}}>Phone Number</Typography>
                <Typography sx={{fontSize:"0.8vw", color:"gray"}}>An OTP will be send to this Number</Typography>
                <input type="text" className="input_signup"/> */}
                
            </Box>

            <Stack direction="row" justifyContent="right" spacing={0} sx={{height:"10vh"}}>
                {(stepsno>0 && stepsno<4) && <Button
                 className="univercel_btn_class"
                onClick={()=>setStepsno(prev=>prev-1)}
                variant="contained" startIcon={<KeyboardDoubleArrowLeftIcon/>} 
                 color="primary"  sx={{marginRight:"20px",width:"114px",height:"53px", textTransform: "none"}}>
                    Previous
                </Button>}
               {stepsno<=3 && <Button 
                className="univercel_btn_class"
               type="submit"
                variant="contained" endIcon={<KeyboardDoubleArrowRightIcon/>} 
                color="primary" sx={{marginRight:"20px",height:"53px", textTransform: "none"}}>
                    Next 
                </Button>}
            </Stack>
            
                     </form>
        </>
    );
}
