


import { useContext } from "react";
import { Navigate, Outlet, Route, useNavigate } from "react-router-dom";


export const ChannelPartnerRoutes = ({children, ...rest})=>{
    //get data from local storage and check role type and set routs

    const user = JSON.parse(localStorage.getItem("crm_user"))
    console.log(user)


    return (
        <>
       {/* {user?<Outlet/> :<Navigate to="/"/>} */}
       {user?.role_type=="CHANNELPARTNER" ? <Outlet/> :<Navigate to="/"/>}
        </>
    )
}
