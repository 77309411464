import { Typography } from "@mui/material";
import { Box, Stack } from "@mui/system";
import React, { useState } from "react";
import DeassignBuilderProject from "../../leads/LeadsDirectory/DeassignBuilderProject";
import DeassignSelfProject from "../../leads/LeadsDirectory/DeassignSelfProject";
import DeassignSelfProjectClient from "./DeassignSelfProjectClient";

function BuilderOrSelfProjectClient({
  selectedProject,
  setSelectedProject,
  self,
  builder,
  trigger,
  setTrigger,
  projectFlag,
  setProjectFlag,
  deAllocateProject,
  lead_id
}) {
  const [curPage, setCurPage] = useState(2);

  return (
    <div>
      <Box sx={{ mt: "20px", backgroundColor: "#f9f9f9" }}>
        <Box sx={{ width: "100%" }}>
          <Box
            sx={{
              mt: "20px",
              borderBottom: "3px solid #e4e4e4",
              display: "flex",
              gap: "50px",
            }}
          >
            {/* <Box
                  onClick={() =>{setCurPage(1);setProjectFlag(1)}}
                  sx={{
                    background: curPage==1?"white":"#E0E0E0",
                    display: "grid",
                    justifyContent: "center",
                    alignItems:"center",
                    width: "70%",
                    height:"49px",
                    borderRadius:"8px 8px 0px 0px",
                    display:'flex',
                    cursor:"pointer",
                    color:"#000000",
                    fontFamily:'Product Sans',
                    lineHeight:"20px",
                    fontWeight:"400",
                    fontSize:"16px",
                    border: curPage==1?"1px solid #0F5DAE":"1px solid gray"
                  }}
                  variant="text"
                >
                <Typography sx={{fontFamily:'Product Sans',lineHeight:"20px",fontWeight:"400",fontSize:"16px",color:"#000000"}}>Builder Invited Projects</Typography>
                </Box> */}

            <Box
              onClick={() => {
                setCurPage(2);
                setProjectFlag(2);
              }}
              sx={{
                padding: "10px 0px",
                fontFamily: "Circular Std",
                fontWeight: "400",
                color: curPage == 2 ? "#2979F2" : "#A4A4AD",
                fontSize: curPage == 2 ? "20px" : "18px",
                borderBottom: curPage == 2 ? "3px solid #2979F2" : "",
                cursor: "pointer",
                marginBottom: "-2px",
                borderRadius: "3px",
              }}
            >
              Self Projects
            </Box>
          </Box>
        </Box>
      </Box>

      <Box>
        {curPage === 1 && <DeassignBuilderProject />}
        {curPage === 2 && (
          <DeassignSelfProjectClient
            selectedProject={selectedProject}
            setSelectedProject={setSelectedProject}
            self={self}
            trigger={trigger}
            setTrigger={setTrigger}
            deAllocateProject={deAllocateProject}
            lead_id={lead_id}
          />
        )}
      </Box>
    </div>
  );
}

export default BuilderOrSelfProjectClient;
