import { Box, Button, Container, Typography } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import { useState } from "react";
import { AnimatePresence, motion } from "framer-motion";
import { useNavigate } from "react-router-dom";
import DropdownArrow from "../../Imgs/responsive-dropdown-arrow.svg";
import ResponsiveLogo from "../../Imgs/responsive-logo.png";

const CommonPagesResponsiveNavbar = () => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [serviceMenuShow, setServiceMenuShow] = useState(false);
  const [signUpMenuShow, setSignUpMenuShow] = useState(false);

  const showAnimation = {
    hidden: {
      width: 0,
      opacity: 0,
      transition: {
        duration: 0.3,
      },
    },
    show: {
      opacity: 1,
      width: "100%",
      transition: {
        duration: 0.5,
      },
    },
  };

  return (
    <Box
      sx={{
        position: "sticky",
        top: 0,
        left: 0,
        right: 0,
        zIndex: 999999,
        display: { xs: "block", md: "none" },
      }}
    >
      <Box
        sx={{
          backgroundColor: "#FFF",
          py: "15px",
        }}
      >
        <Container>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Box onClick={() => navigate("/")} sx={{ width: "40px" }}>
              <img src={ResponsiveLogo} style={{ width: "100%" }} alt="" />
            </Box>
            <Box
              sx={{
                display: "flex",
                gap: "10px",
                alignItems: "center",
              }}
            >
              <Button
                sx={{
                  background:
                    "linear-gradient(97.85deg, #FFAF2A 0.95%, #FFC666 100%)",
                  fontWeight: 500,
                  fontSize: "14px",
                  borderRadius: "5px",
                  color: "#06386B",
                  textTransform: "none",
                  padding: "5px 20px",
                  fontFamily: "Product Sans",
                  "&:hover": {
                    background:
                      "linear-gradient(97.85deg, #FFAF2A 0.95%, #FFC666 100%)",
                  },
                }}
              >
                Book A Demo
              </Button>

              <Box onClick={() => setOpen(!open)}>
                {open ? <CloseIcon /> : <MenuIcon />}
              </Box>
            </Box>
          </Box>
        </Container>
      </Box>

      <motion.div
        animate={{
          position: "absolute",
          backgroundColor: "#FFF",
          top: 64,
          zIndex: 999999999,
          width: "100%",
          height: open ? "100vh" : 0,
        }}
      >
        <Container>
          <AnimatePresence>
            {open && (
              <motion.div
                variants={showAnimation}
                initial="hidden"
                animate="show"
                exit="hidden"
              >
                <Typography
                  onClick={() => [navigate("/"), setOpen(!open)]}
                  sx={{
                    fontSize: "28px",
                    fontWeight: 700,
                    fontFamily: "Product Sans",
                    py: "8px",
                  }}
                >
                  Home
                </Typography>
              </motion.div>
            )}
          </AnimatePresence>
          <AnimatePresence>
            {open && (
              <motion.div
                variants={showAnimation}
                initial="hidden"
                animate="show"
                exit="hidden"
              >
                <Typography
                  onClick={() => navigate("/about-us-page")}
                  sx={{
                    fontSize: "28px",
                    fontWeight: 700,
                    fontFamily: "Product Sans",
                    py: "8px",
                  }}
                >
                  About Us
                </Typography>
              </motion.div>
            )}
          </AnimatePresence>
          <AnimatePresence>
            {open && (
              <motion.div
                variants={showAnimation}
                initial="hidden"
                animate="show"
                exit="hidden"
              >
                <Typography
                  onClick={() => setServiceMenuShow(!serviceMenuShow)}
                  sx={{
                    fontSize: "28px",
                    fontWeight: 700,
                    fontFamily: "Product Sans",
                    py: "8px",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  Services
                  <img src={DropdownArrow} alt="" />
                </Typography>

                {serviceMenuShow && (
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      width: "100%",
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: "20px",
                        fontWeight: 700,
                        fontFamily: "Product Sans",
                        py: "5px",
                      }}
                      onClick={() => [
                        navigate("/builder-landing-page"),
                        setOpen(!open),
                      ]}
                    >
                      For Builders & Developers
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: "20px",
                        fontWeight: 700,
                        fontFamily: "Product Sans",
                        py: "5px",
                      }}
                      onClick={() => [
                        navigate("/channel-landing-page"),
                        setOpen(!open),
                      ]}
                    >
                      For Channel Partners
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: "20px",
                        fontWeight: 700,
                        fontFamily: "Product Sans",
                        py: "5px",
                      }}
                      onClick={() => [
                        navigate("/buyer-landing-page"),
                        setOpen(!open),
                      ]}
                    >
                      For Home Buyers
                    </Typography>
                  </Box>
                )}
              </motion.div>
            )}
          </AnimatePresence>
          <AnimatePresence>
            {open && (
              <motion.div
                variants={showAnimation}
                initial="hidden"
                animate="show"
                exit="hidden"
              >
                <Typography
                  sx={{
                    fontSize: "28px",
                    fontWeight: 700,
                    fontFamily: "Product Sans",
                    py: "8px",
                  }}
                  onClick={() => [navigate("/contact-page"), setOpen(!open)]}
                >
                  Contact
                </Typography>
              </motion.div>
            )}
          </AnimatePresence>

          <AnimatePresence>
            {open && (
              <motion.div
                variants={showAnimation}
                initial="hidden"
                animate="show"
                exit="hidden"
              >
                <Typography
                  sx={{
                    fontSize: "28px",
                    fontWeight: 700,
                    fontFamily: "Product Sans",
                    py: "8px",
                  }}
                  onClick={() => [navigate("/login"), setOpen(!open)]}
                >
                  Login
                </Typography>
              </motion.div>
            )}
          </AnimatePresence>
          {/* <AnimatePresence>
            {open && (
              <motion.div
                variants={showAnimation}
                initial="hidden"
                animate="show"
                exit="hidden"
              >
                <Typography
                  sx={{
                    fontSize: "28px",
                    fontWeight: 700,
                    fontFamily: "Product Sans",
                    py: "8px",
                  }}
                  onClick={() => [
                    navigate("/builder-register"),
                    setOpen(!open),
                  ]}
                >
                  Sign Up
                </Typography>
              </motion.div>
            )}
          </AnimatePresence> */}
          <AnimatePresence>
            {open && (
              <motion.div
                variants={showAnimation}
                initial="hidden"
                animate="show"
                exit="hidden"
              >
                <Typography
                  onClick={() => setSignUpMenuShow(!signUpMenuShow)}
                  sx={{
                    fontSize: "28px",
                    fontWeight: 700,
                    fontFamily: "Product Sans",
                    py: "8px",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  Sign Up
                  <img src={DropdownArrow} alt="" />
                </Typography>

                {signUpMenuShow && (
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      width: "100%",
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: "20px",
                        fontWeight: 700,
                        fontFamily: "Product Sans",
                        py: "5px",
                      }}
                      onClick={() => [
                        navigate("/builder-register"),
                        setOpen(!open),
                      ]}
                    >
                      For Builders
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: "20px",
                        fontWeight: 700,
                        fontFamily: "Product Sans",
                        py: "5px",
                      }}
                      onClick={() => [
                        navigate("/channelpartner-register"),
                        setOpen(!open),
                      ]}
                    >
                      For Channel Partners
                    </Typography>
                    {/* <Typography
                      sx={{
                        fontSize: "20px",
                        fontWeight: 700,
                        fontFamily: "Product Sans",
                        py: "5px",
                      }}
                      onClick={() => [
                        // navigate("/buyer-landing-page"),
                        setOpen(!open),
                      ]}
                    >
                      For Home Buyers
                    </Typography> */}
                  </Box>
                )}
              </motion.div>
            )}
          </AnimatePresence>
        </Container>
      </motion.div>
    </Box>
  );
};

export default CommonPagesResponsiveNavbar;

/* import * as React from "react";
import { useRef } from "react";
import { motion, useCycle } from "framer-motion";
import { useDimensions } from "./use-dimensions";
import { MenuToggle } from "./MenuToggle";
import { Navigation } from "./Navigation";

const sidebar = {
  open: (height = 1000) => ({
    clipPath: `circle(${height * 2 + 200}px at 40px 40px)`,
    transition: {
      type: "spring",
      stiffness: 20,
      restDelta: 2,
    },
  }),
  closed: {
    clipPath: "circle(30px at 40px 40px)",
    transition: {
      delay: 0.5,
      type: "spring",
      stiffness: 400,
      damping: 40,
    },
  },
};

export const Example = () => {
  const [isOpen, toggleOpen] = useCycle(false, true);
  const containerRef = useRef(null);
  const { height } = useDimensions(containerRef);

  return (
    <motion.nav
      initial={false}
      animate={isOpen ? "open" : "closed"}
      custom={height}
      ref={containerRef}
    >
      <motion.div className="background" variants={sidebar} />
      <Navigation />
      <MenuToggle toggle={() => toggleOpen()} />
    </motion.nav>
  );
};
 */
