import {
  Box,
  Checkbox,
  Stack,
  Button,
  Accordion,
  AccordionSummary,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";

import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { UserRole } from "../../../UserRoles/UserRoles";

function LDcustomTable({
  newRowData,      
  delIconClick,
  updateBuyers,
  setTrigger,
  trigger,
  updateLead,
  onRowsSelectionHandler,   
  toggleDrawer,
  rowSelected,
  setRowSelected
}) {
  
  ////get user roles from local storage and assign 
  let roles = JSON.parse(localStorage.getItem("user_roles"))
  let lead = roles["lead"];
  let leadPriority = roles["lead prority"];

  //   console.log(newRowData);
  const [curpage, setcurpage] = useState(1);
  const [pageRange, setPageRange] = useState([0, 6]);

  const nextClick = () => {
    if (
      !(newRowData.length >= pageRange[0] && newRowData.length <= pageRange[1])
    ) {
      setcurpage(curpage + 1);
      setPageRange([pageRange[0] + 6, pageRange[1] + 6]);
    }
  };

  const prvClick = () => {
    if (pageRange[0] != 0 && pageRange[1] != 6) {
      setcurpage(curpage - 1);
      setPageRange([pageRange[0] - 6, pageRange[1] - 6]);
    }
  };

  const [pages, setPages] = useState([]);
  useEffect(() => {
    if (newRowData) {
      let arr = [];
      for (
        let i = 1;
        i <=
        (newRowData.length % 6 == 0
          ? newRowData.length / 6
          : newRowData.length / 6 + 1);
        i++
      ) {
        arr.push(i);
      }
      setPages(arr);
    }
  }, [newRowData]);

  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <Box>
      {" "}
      <div style={{ height: 400, width: "100%" }}>
        {/* <DataGrid
      rows={newRowData}
      columns={columns}
      pageSize={10}
      rowsPerPageOptions={[5]}
      onSelectionModelChange={(ids) => onRowsSelectionHandler(ids)}
    /> */}
        <Box sx={{ color: "black", width: "100%" ,boxSizing:"border-box" }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "start",
              alignItems: "center",
              background: "white",
              borderRadius: "5px",
              margin: " 10px 0",
              color: "#272D4E",  
              fontWeight: "700",
              fontFamily:"Circular Std",
              fontSize:"18px"
            }}
          >
            {UserRole(leadPriority, "add_leadprority") && 
            <Box
              sx={{
                width: "10.66%",
                display: "grid",
                placeItems: "center",
                height: "50px",
                justifyContent: "start",
                paddingLeft:"37px",
              }}
            >
              <Checkbox
                onClick={() => onRowsSelectionHandler(newRowData, -1, "all")}
              />
            </Box>
            }
            <Box
              sx={{
                width: "16.66%",
                display: "grid",
                placeItems: "center",
                height: "50px",
                justifyContent: "start",
                paddingLeft:"39px"
              }}
            >
              <Box sx={{ display: "flex", justifyContent:"center" }}>
                <Box sx={{ display: "grid", placeItems: "center" }}>Name</Box>
                {/* <Box sx={{ display: "grid", marginLeft: "4px" }}>
                  <ArrowDropUpIcon
                    fontSize="medium"
                    sx={{ marginBottom: "-8px" }}
                  />
                  <ArrowDropDownIcon
                    fontSize="medium"
                    sx={{ marginTop: "-8px" }}
                  />
                </Box> */}
              </Box>
            </Box>

            <Box
              sx={{
                width: "16.66%",
                display: "grid",
                placeItems: "center",
                height: "50px",
                justifyContent: "start",
                paddingLeft:"39px"
              }}
            >
              <Box sx={{ display: "flex", justifyContent:"center" }}>
                <Box sx={{ display: "grid", placeItems: "center" }}>Phone</Box>
                {/* <Box sx={{ display: "grid", marginLeft: "4px" }}>
                  <ArrowDropUpIcon
                    fontSize="medium"
                    sx={{ marginBottom: "-8px" }}
                  />
                  <ArrowDropDownIcon
                    fontSize="medium"
                    sx={{ marginTop: "-8px" }}
                  />
                </Box> */}
              </Box>
            </Box>

            <Box
              sx={{
                width: "16.66%",
                display: "grid",
                placeItems: "center",
                height: "50px",
                justifyContent: "start",
                paddingLeft:"39px"
              }}
            >
              <Box sx={{ display: "flex", justifyContent:"center" }}>
                <Box
                  sx={{
                    display: "grid",
                    placeItems: "center",
                  }}
                >
                  Email
                </Box>
                {/* <Box sx={{ display: "grid", marginLeft: "4px" }}>
                  <ArrowDropUpIcon
                    fontSize="medium"
                    sx={{ marginBottom: "-8px" }}
                  />
                  <ArrowDropDownIcon
                    fontSize="medium"
                    sx={{ marginTop: "-8px" }}
                  />
                </Box> */}
              </Box>
            </Box>

            <Box
              sx={{
                width: "16.66%",
                display: "grid",
                placeItems: "center",
                height: "50px",
                justifyContent: "start",
                paddingLeft:"39px"
              }}
            >
              <Box sx={{ display: "flex" , justifyContent:"center"}}>
                <Box sx={{ display: "grid", placeItems: "center" }}>
                  Priority
                </Box>
                {/* <Box sx={{ display: "grid", marginLeft: "4px" }}>
                  <ArrowDropUpIcon
                    fontSize="medium"
                    sx={{ marginBottom: "-8px" }}
                  />
                  <ArrowDropDownIcon
                    fontSize="medium"
                    sx={{ marginTop: "-8px" }}
                  />
                </Box> */}
              </Box>
            </Box>

            <Box
              sx={{
                width: "16.66%",
                display: "grid",
                placeItems: "center",
                height: "50px",
                justifyContent: "start",
                paddingLeft:"39px"
              }}
            >
              Detailed View
            </Box>
          </Box>
          {newRowData.length==0?
           <Box sx={{textAlign:"center",marginTop:"50px"}}><Typography sx={{fontSize:"25px",fontWeight:"500",color:"#B9B7D0",}}>No Matches Found</Typography></Box>:
           <Box>
             {newRowData &&
            newRowData.map((item, index) => {
              console.log("hhhhh", item)
              if (index >= pageRange[0] && index <= pageRange[1]) {
                return (
                  <Accordion
                    expanded={expanded === `panel${index}`}
                    onChange={handleChange(`panel${index}`)}
                    sx={{
                      margin: "10px 0",
                      boxShadow: "0",
                      border: "none",
                    }}
                  >
                    <AccordionSummary sx={{ borderRadius: "5px", boxSizing:"border-box"}}>
                      <Stack direction="row" justifyContent="start" alignItems="center" sx={{width:"100%"}}>
                      {UserRole(leadPriority, "add_leadprority") && 
                      <Typography sx={{width: "10.66%",display:"grid",justifyContent: "start",
                        fontFamily:"Product Sans",
                        fontWeight:"400",
                        paddingLeft:"20px"
                        }}>
                        {/* <Box
                          sx={{
                            placeItems: "center",
                            // height: "50px",
                          }}
                        >
                        </Box> */}
                          <Checkbox
                            onClick={() =>{
                              onRowsSelectionHandler(item, index, "single")
                            }}
                            onChange={handleChange(null)}
                          />
                      </Typography>
                      }
                      <Typography sx={{width: "16.66%",justifyContent: "start",
                        fontFamily:"Product Sans",
                        fontWeight:"400",
                        paddingLeft:"24px"}}>
                        <Box
                          sx={{
                            placeItems: "center",
                            // height: "50px",
                          }}
                        >
                          {item.Name}
                        </Box>
                      </Typography>
                      <Typography sx={{width: "16.66%",justifyContent: "start",
                        fontFamily:"Product Sans",
                        fontWeight:"400",
                        paddingLeft:"10px"}}>
                        <Box
                          sx={{
                            placeItems: "center",
                            // height: "50px",
                          }}
                        >
                          {item.Phone}
                        </Box>
                      </Typography>
                      <Typography sx={{width: "16.66%",justifyContent: "start",
                        fontFamily:"Product Sans",
                        fontWeight:"400",
                        paddingLeft:"15px"}}>
                        <Box
                          sx={{
                            placeItems: "center",
                            // height: "50px",
                            whiteSpace:"nowrap",
                            overflow:"hidden",
                            textOverflow:"ellipsis",
                          }}
                        >
                          {item.Email.substring(0, 14)+"..."}
                        </Box>
                      </Typography>

                      <Typography sx={{width: "16.66%",justifyContent: "start",
                        fontFamily:"Product Sans",
                        fontWeight:"400",
                        paddingLeft:"20px"}}>
                        <Box
                          sx={{
                            placeItems: "center",
                            // height: "50px",
                          }}
                        >
                          {item.Priority}
                        </Box>
                      </Typography>
                     
                      <ExpandMoreIcon sx={{marginLeft:"40px"}}
                        onChange={handleChange(`panel${index}`)}
                      />
                      </Stack>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography>
                        <Box
                          sx={{
                            margin: "10px 0 40px 50px",
                            color: "#969BA0",
                          }}
                        >
                          The Details Review of Leads
                        </Box>

                        <Stack direction="row">
                          <Stack direction="row">
                            <Box sx={{ marginLeft: "50px" }}>Adderess : </Box>
                            <Box sx={{ marginLeft: "10px"}}>{item.Address}</Box>
                          </Stack>
                          <Stack direction="row">
                            <Box sx={{ marginLeft: "70px" }}>Project : </Box>
                            <Box sx={{ marginLeft: "10px"}}>{item.Projects}</Box>
                          </Stack>
                        </Stack>
                        
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "right",
                          }}
                        >
                          <Box
                            sx={{
                              width: "15vw",
                              display: "grid",
                              placeItems: "center",
                              height: "50px",
                              marginRight: "30px",
                            }}
                          >
                            <Stack direction="row" sx={{ marginLeft: "10px" }}>
                              {UserRole(lead, "change_lead") && 
                              <Button
                              variant="contained"
                              sx={{
                                width: "100px",
                                background:
                                "linear-gradient(90deg, #0F5DAE 0%, #009DCF 100.33%)",
                              }}
                              onClick={toggleDrawer(true, item)}
                              >
                                Edit
                              </Button>
                              }

                              {UserRole(lead, "delete_lead") && 
                              <Button
                                variant="contained"
                                sx={{
                                  marginLeft: "20px",
                                  width: "100px",
                                  background:
                                    "linear-gradient(90deg, #0F5DAE 0%, #009DCF 100.33%)",
                                }}
                                onClick={() => delIconClick(item)}
                              >
                                Delete
                              </Button>
                              }
                            </Stack>
                          </Box>
                        </Box>
                      </Typography>
                    </AccordionDetails>
                  </Accordion>
                );
              }
            })}
          </Box>
          }
        </Box>
        <Box>
          <Box sx={{ display: "flex", justifyContent: "right" }}>
            {newRowData.length!=0 && <Button
              variant="contained"
              sx={{
                background:
                  "linear-gradient(90deg, #0F5DAE 0%, #009DCF 100.33%)",
              }}
              onClick={prvClick}
            >
              <ArrowBackIosIcon fontSize="small" />
              <Box sx={{ textTransform: "capitalize" }}>Previous</Box>
            </Button>}
            <Box
              sx={{
                background: "#E3E4EB",
                display: "flex",
                placeItems: "center",
                margin: " 0 10px",
                borderRadius: "5px",
              }}
            >
              {pages.map((page, ind) => {
                return curpage == ind + 1 ? (
                  <Box
                    sx={{
                      background: `white`,
                      color: "black",
                      width: "30px",
                      borderRadius: "5px",
                      margin: "0 10px",
                      display: "grid",
                      placeItems: "center",
                    }}
                  >
                    {page}
                  </Box>
                ) : (
                  <Box
                    sx={{
                      background: `${
                        (0 + newRowData.length) / 2 == ind ? "white" : "#E3E4EB"
                      }`,
                      color: "black",
                      width: "30px",
                      borderRadius: "5px",
                      margin: "0 10px",
                      display: "grid",
                      placeItems: "center",
                    }}
                  >
                    {page}
                  </Box>
                );
              })}
            </Box>
            {newRowData.length!=0 && <Button
              variant="contained"
              sx={{
                background:
                  "linear-gradient(90deg, #0F5DAE 0%, #009DCF 100.33%)",
              }}
              onClick={nextClick}
            >
              <Box sx={{ textTransform: "capitalize" }}>Next</Box>
              <ArrowForwardIosIcon fontSize="small" />
            </Button>}
          </Box>
        </Box>
      </div>
    </Box>
  );
}

export default LDcustomTable;
