import axios from "axios";
import { Apiaddress, basicheaders } from "../../utils/consts";
import { patchProject } from "./patch_project";

const defineProjectLayout= async(
    sL:Array<String>,
    sT:Array<String>,
    dL:Array<String>,
    dT:Array<String>,
    project_id:number
)=>{
    const url =Apiaddress+"/api/projects/unitlayout/"
    let data:{single:Array<{label:String,type:String}>,double:Array<{label:String,type:String}>} = {
        single:[],
        double:[],
    }

    for (let i =0;i < sL.length;i++){
        data.single.push({
            label:sL[i],
            type:sT[i],
        })
    }

    
    for (let i =0;i < dL.length;i++){
        data.double.push({
            label:dL[i],
            type: dT[i],
        })
    }
    const payload = {
        "name":"lol",
        "layout_data":data,
        "pricing_data":{},
        };

    const re =  await axios.post(
        url,
        {
            headers:basicheaders,
            ...payload
        },
    )
    const unit_layout_data = re.data;
  
    const project =  await patchProject(
        project_id,
        {
            "unit_layout":unit_layout_data.unit_layout.id
        }
    )

    console.log(project.project);
    
    return project;

}
export {defineProjectLayout};