import { Box, Button, Stack, Typography } from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import SideBar from "../../Components/sideBar/SideBar";
import { Apiaddress, net } from "../../../../utils/consts";
import { useParams } from "react-router-dom";
import AssignProjectClient from "./AssignProjectClient";
import AssignProjectSelfClient from "./AssignProjectSelfClient";
import AssignStandAloneUnitClient from "./AssignStandAloneUnitClient";
import NavigateBack from "../../../CommonPages/Shared/NavigateBack";

function AssignProjectMainClient() {
  let lead_id = useParams().id;
  console.log(lead_id);

  const [curPage, setCurPage] = useState(2);
  const [selectedProject, setSelectedProject] = useState([]);
  const [lead, setLead] = useState();
  const [Builder, setBuilder] = useState([]);
  const [self, setSelf] = useState([]);
  const [standAlone, setStandAlone] = useState([]);
  const [trigger, setTrigger] = useState(false);

  console.log(Builder);
  console.log(self);
  console.log(standAlone);

  const allocateProject = async () => {
    try {
      if (curPage == 2) {
        let temp = selectedProject;
        for (let i = 0; i < self?.length; i++) {
          temp.push(self[i]);
        }
        let obj = {
          Client_id: +lead_id,
          cpProjects: temp,
        };
        console.log(temp);
        const res = await axios.patch(Apiaddress + "/api/leads/CpClient/", obj);
        console.log(res.data);
        setSelectedProject([]);
      } else if (curPage == 3) {
        let temp = selectedProject;
        for (let i = 0; i < standAlone?.length; i++) {
          temp.push(standAlone[i]);
        }
        let obj = {
          Client_id: +lead_id,
          cpStandaloneUnits: temp,
        };
        const res = await axios.patch(Apiaddress + "/api/leads/CpClient/", obj);
        console.log(res.data);

        let url1 = Apiaddress + "/api/projects/assignunitstatusCp/";
        let data = {
          unit_ids: selectedProject,
          unit_status_id:4,
        };
        console.log("data", data);
        let res2 = await axios.post(url1, data);
        console.log(res2);

        setSelectedProject([]);
      }
      setTrigger(!trigger);
    } catch (err) {
      console.log(err);
    }
  };

  const fetchLeadDetails = async () => {
    try {
      const res = await axios.get(
        Apiaddress + `/api/leads/CpClientById/?id=${lead_id}`
      );
      console.log(res?.data?.Client);
      setLead(res?.data?.Client);
      setBuilder(res?.data?.Client?.projects);
      setSelf(res?.data?.Client?.cpProjects);
      setStandAlone(res?.data?.Client?.cpStandaloneUnits);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    fetchLeadDetails();
  }, [trigger]);

  return (
    <div>
      <SideBar active={"Clients"}>
      <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              sx={{ height: "68px", marginBottom: "20px" }}

            >
              <Box>
              <Stack direction="row">
                  <Box sx={{ pt: "5px" }}>
                    <NavigateBack fontSize="22px" />
                  </Box>
                  <Typography
                    sx={{
                      fontSize: "22px",
                      fontWeight: "600",
                      color: "black",
                    }}
                    >
                    Clients Directory
                  </Typography>
                </Stack>
                <Typography
                  sx={{
                    color: "#B9B7D0",
                    fontSize: "16px",
                    fontWeight: "400",
                  }}
                >
                  Manage your Clients
                </Typography>
              </Box>
            </Stack>

        <Stack sx={{ mt: "0px", backgroundColor: "#f9f9f9" }}>
          {/* ================Mark Priority======================= */}
          {/* <Box>
            {selectedProject.length != 0 && (
              <Stack direction="row" sx={{ margin: "20px" }}>
                {
                  <Button
                    onClick={() => {
                      allocateProject();
                    }}
                    className="univercel_btn_class"
                    sx={{
                      color: "#FFFFFF",
                      textTransform: "none",
                      width: "150px",
                      marginLeft: "30px",
                      background: "#008dff",
                      boxShadow:
                        "0px 13.7981px 22.5788px rgba(50, 174, 137, 0.25)",
                      "&:hover": { background: "#008dff" },
                    }}
                  >
                    Allocate
                  </Button>
                }
              </Stack>
            )}
          </Box> */}

          <Box
            sx={{
              width: "100%",
              mt: "20px",
              borderBottom: "3px solid #e4e4e4",
              display: "flex",
              gap: "50px",
            }}
          >
            <Box
              onClick={() => {
                setCurPage(2);
                setSelectedProject([]);
              }}
              sx={{
                padding: "10px 0px",
                fontFamily: "Circular Std",
                fontWeight: "400",
                color: curPage == 2 ? "#2979F2" : "#A4A4AD",
                fontSize: curPage == 2 ? "20px" : "18px",
                borderBottom: curPage == 2 ? "3px solid #2979F2" : "",
                cursor: "pointer",
                marginBottom: "-2px",
                borderRadius: "3px",
              }}
            >
              Self Projects
            </Box>
            <Box
              onClick={() => {
                setCurPage(3);
                setSelectedProject([]);
              }}
              sx={{
                padding: "10px 0px",
                fontFamily: "Circular Std",
                fontWeight: "400",
                color: curPage == 3 ? "#2979F2" : "#A4A4AD",
                fontSize: curPage == 3 ? "20px" : "18px",
                borderBottom: curPage == 3 ? "3px solid #2979F2" : "",
                cursor: "pointer",
                marginBottom: "-2px",
                borderRadius: "3px",
              }}
            >
              Stand Alone Units
            </Box>
          </Box>

          <Box sx={{ width: "100%", mt: "20px" }}>
            {curPage === 1 && <AssignProjectClient />}
            {curPage === 2 && (
              <AssignProjectSelfClient
                selectedProject={selectedProject}
                setSelectedProject={setSelectedProject}
                self={self}
                trigger={trigger}
                setTrigger={setTrigger}
                allocateProject={allocateProject}
                lead_id={lead_id}
              />
            )}
            {curPage === 3 && (
              <AssignStandAloneUnitClient
                selectedProject={selectedProject}
                setSelectedProject={setSelectedProject}
                self={standAlone}
                trigger={trigger}
                setTrigger={setTrigger}
                allocateProject={allocateProject}
              />
            )}
          </Box>
        </Stack>
      </SideBar>
    </div>
  );
}

export default AssignProjectMainClient;
