import { Box, Button, TextField, Typography } from "@mui/material"
import { Stack } from "@mui/system"
import DoneIcon from '@mui/icons-material/Done';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import { NavLink, useNavigate } from "react-router-dom";
// import "./CreateProject.css";
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import { useContext, useEffect, useState } from "react";
import CallIcon from '@mui/icons-material/Call';

// modal 
import Modal from '@mui/material/Modal';
import { Apiaddress } from "../../../utils/consts";
import axios from "axios";

import currentProjectState from '../../../context/createproject/context';
import { SuperAdminNavbar } from "../SidebarNavbar/SuperAdminNavbar";
import { SuperAdminSidebar } from "../SidebarNavbar/SuperAdminSidebar";
import { SAProjectSidebar } from "../SidebarNavbar/SAProjectSidebar";


const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    border: '2px solid #fff',
    borderRadius:"30px",
    boxShadow: 24,
    p: 4,
  };
  


export const ConfirmProjectSA = ()=>{
    const navigate = useNavigate();
    ////get number of buildings and wings and units
    const cpContext = useContext(currentProjectState);
    const projectID = cpContext?.createProjectState?.project?.id;


    const [building_count, setbuilding_count] = useState(0)
    const [floor_count, setfloor_count] = useState(0)
    const [unit_count, setunit_count] = useState(0)
    const [wing_count, setwing_count] = useState(0)

    console.log(projectID)
    const [allTemp, setAlltemp] = useState({
     building_count:"",
     floor_count:"",
     unit_count:"",
     wing_count:"",
    })

    /////for getting count of all templates
    useEffect(()=>{
    const uniturl = Apiaddress+"/api/projects/unittemplatesbyproject/?project_id="+projectID
    const floorurl = Apiaddress+"/api/projects/floortemplatesbyproject/?project_id="+projectID
    const wingurl = Apiaddress+"/api/projects/wingtemplatesbyproject/?project_id="+projectID
    const buildingurl = Apiaddress+"/api/projects/buildingtemplatesbyproject/?project_id="+projectID
    const pricingurl = Apiaddress+"/api/projects/unittemplatesbyproject/?project_id="+projectID
    const arr = [uniturl,floorurl,wingurl,buildingurl];

   
      axios.get(uniturl)
      .then((res)=>{
        const data = res?.data
         console.log("unit temp",res.data);
         if(data.unit_template!=="Unit template not found")
         setunit_count(data.unit_template.length)
           
         })
         .catch((err)=>{
           console.log(err)
         })
         
         axios.get(floorurl)
         .then((res)=>{
           const data = res?.data
           console.log(data)
           if(data.floor_templates!=="floor templates not found")
           setfloor_count(data.floor_templates.length)
         })
         .catch((err)=>{
           console.log(err)
         })

      axios.get(wingurl)
      .then((res)=>{
            const data = res?.data
            console.log(data)
            if(data.wing_templates!=="Wing Template not found")
            setwing_count(data.wing_templates.length)
         })
         .catch((err)=>{
           console.log(err)
         })

      axios.get(buildingurl)
      .then((res)=>{
            const data = res?.data
            console.log(data)
            if(data.building_template!=="building_template not found")
            setbuilding_count(data.building_template.length)
               
         })
         .catch((err)=>{
           console.log(err)
         })
  },[])

    const [select,setSelect] = useState("");
    const handleChange = (event) => {
        setSelect(event.target.value);
        console.log(select)
      };
      


    // for modal 
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    //credit 
    const [availCredit, setAvailCredit] = useState(120);
    const [reqCredit, setReqCredit] = useState(100)

  
    // useEffect(()=>{
      
    //     const url = Apiaddress+"/api/projects/getcountconfirmproject/?project_id="+projectID
    //     axios.get(url).then((res)=>{
    //       console.log(res.data);
    //       setAlltemp({...res.data});
    //     }).catch((err)=>{
    //       console.log(err);
    //     })
    // },[])


    //// for upload file (bannerImage);
    const [img,setimg] = useState("");

    const imgHandler = (e)=>{
      setimg(e.target.files[0]);
    }

    ////confirm project ;
    const confirmProject = async()=>{
      let url = Apiaddress+"/api/projects/project/";

      const data = {
        "project_id":projectID,
        "banner_image":img,
        "projectAmmenities":select
      }
      const headers ={ 
        headers: {
        'content-type': 'multipart/form-data',
      }
    }
      console.log("data:=>.>...>>>",data)
     if(img=="" && select==""){
      alert("select Amenities and Library Image");
      return;
     }
     let res = await axios.patch(url,data,headers);
        console.log("add amenities and banner img res+=>>>>>>>>>>>>>", res.data);
    
       const confirmProjecturl = Apiaddress+"/api/projects/confirmproject/";

      let re = await axios.post(confirmProjecturl,{
        "project_id":projectID
        })
        
        console.log("confirm project responce=>>>>>>>>>>>>>>",re.data);
        // navigate("/confirmed-project")
        navigate("/superadmin-project") //for temporary rout 

    }
    return (
        <>
        
<Stack direction="row" sx={{width:"100%",height:"auto" }}>
      {/* 1st stack */}
      <Stack sx={{minHeight:"100vh",width:"20%",boxSizing:"border-box",}}> {/*for project directory 20% and for sales dashboard 225*/}
        {/* <SuperAdminSidebar active={"Builder Directory"}/> */}
        <SAProjectSidebar active={"Project Dashboard"}/>
      </Stack>

     {/* 2nd stack */}
     <Stack direction="column" sx={{ backgroundColor:"#f9f9f9",width:"80%", padding:"25px",boxSizing:"border-box",}}  >  {/*  width for main sidebar 78px and for 80px*/}
     <SuperAdminNavbar createProject={true} />  {/*send props for get popup to verify otp from builder */}
      
      <Typography sx={{marginTop:"20px", fontSize:"28px", fontWeight:"600", marginLeft:"10px", marginBottom:"50px"}}>
           Confirm Project
            </Typography>
     {/* actual content  */}
    <Stack sx={{backgroundColor:"#f9f9f9"}}>  {/* mt:"28px", */}

    <Stack direction="column"  sx={{  padding:"20px", backgroundColor:"#fff", marginRight:"0px",pl:"69px"}}>

{/* confirm project   */}
         
       <Stack direction="row" justifyContent="space-between" spacing={4} alignItems="center" sx={{ width:"400px",mb:"30px",mt:"20px"}}>
           <Typography sx={{fontSize:"18px", fontWeight:"500", color:"#A2A3A6"}}>Total Buildings</Typography>
           <TextField disabled value={building_count} sx={{width:"188px"}}/>
       </Stack>
       <Stack direction="row" justifyContent="space-between" spacing={4} alignItems="center" sx={{ width:"400px",mb:"30px"}}>
           <Typography sx={{fontSize:"18px", fontWeight:"500", color:"#A2A3A6"}}>Total Wings</Typography>
          <TextField disabled value={wing_count} sx={{width:"188px"}}/>
       </Stack>
       <Stack direction="row" justifyContent="space-between" spacing={4} alignItems="center" sx={{ width:"400px",mb:"30px"}}>
           <Typography sx={{fontSize:"18px", fontWeight:"500", color:"#A2A3A6"}}>Total Floors</Typography>
          <TextField disabled value={floor_count} sx={{width:"188px"}}/>
       </Stack>
       <Stack direction="row" justifyContent="space-between" spacing={4} alignItems="center" sx={{ width:"400px",mb:"30px"}}>
           <Typography sx={{fontSize:"18px", fontWeight:"500", color:"#A2A3A6"}}>Total Units</Typography>
          <TextField disabled value={unit_count} sx={{width:"188px"}}/>
       </Stack>

       <Stack direction="row" justifyContent="start" spacing={4} alignItems="center" sx={{mb:"30px"}}>
           {/* <Typography sx={{fontSize:"18px", fontWeight:"500", color:"#A2A3A6"}}>Amenities</Typography> */}
           {/* <TextField/> */}
           <FormControl sx={{ m: 1, minWidth: 188, border:"1px transparent" }}>
               <Select
                 value={select}
                 onChange={handleChange}
                 displayEmpty
                 inputProps={{ 'aria-label': 'Without label' }}
               >
                 <MenuItem value="">
                   Amenities
                 </MenuItem>
                 <MenuItem value={"amenities 1"}>amenities 1</MenuItem>
                 <MenuItem value={"amenities 2"}>amenities 2</MenuItem>
                 <MenuItem value={"amenities 3"}>amenities 2</MenuItem>
               </Select>
               {/* <FormHelperText>Without label</FormHelperText> */}
              </FormControl>
       </Stack>

       <Stack direction="row" justifyContent="space-between" spacing={4} alignItems="center" sx={{mb:"30px",width:"380px"}}>
           <Typography sx={{fontSize:"18px", fontWeight:"500", color:"#A2A3A6"}}>Media Library</Typography>
         
             <Button
             className="univercel_btn_class"
                     variant="contained"
                     sx={{padding:"9px 26px 9px 26px",}}
                     component="label"
                     startIcon={
                       <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                       <path d="M2 16C1.45 16 0.979333 15.8043 0.588 15.413C0.196 15.021 0 14.55 0 14V11H2V14H14V11H16V14C16 14.55 15.8043 15.021 15.413 15.413C15.021 15.8043 14.55 16 14 16H2ZM7 12V3.85L4.4 6.45L3 5L8 0L13 5L11.6 6.45L9 3.85V12H7Z" fill="white"/>
                       </svg>
                   }
                   >
                      Setup Library
                     <input
                     onChange={imgHandler}
                       type="file"
                       hidden
                     />
               </Button>
       </Stack>
        
        <Stack direction="column" justifyContent="space-between" sx={{width:"200px", height:"80px",marginBottom:"20px"}}>
       <NavLink  to="/super-admin-attachmedia-to-unit"> 
           <Typography 
           className="text_color_linear" sx={{fontSize:"18px", fontWeight:"500",mb:"10px",textDecoration: 'underline', width:"197px"}}  display="inline">
               Attach media to Units </Typography>
               </NavLink>
       <NavLink to="/super-admin-attachmedia-to-project">{/*to="/confirm-project/attachmediatoproject" */}
           <Typography 
           className="text_color_linear" sx={{fontSize:"18px", fontWeight:"500",mb:"10px",textDecoration: 'underline'}}  display="inline">
                Attach media to Projects </Typography>
               </NavLink>
        </Stack>
     
       <Stack direction="row" justifyContent="start" alignItems="center" spacing={2} sx={{mt:"30px"}}>
            <Button
            onClick={()=>{

               }}
            sx={{padding:"16px 59px 16px 59px", color:"black",textTransform:"none", border:'1px solid #1362AD',fontSize:"18px", fontWeight:"700"}}>Discard</Button>
               <Button
                onClick={async()=>{
                   // handleOpen() ////for payment popup 
                   confirmProject()
                 }}
                 className="univercel_btn_class" sx={{padding:"16px 60px 16px 60px", color:"#fff",fontSize:"18px", fontWeight:"700"}}>Confirm</Button>
               <Button
                onClick={()=>{
                  navigate("/super-admin-confirm-project-building")
                }}
                className="univercel_btn_class" sx={{padding:"16px 60px 16px 60px", color:"#fff",fontSize:"18px", fontWeight:"700"}}>
                View Details
                </Button>
       </Stack>


</Stack>

{/* modal for select cover image */}
<Modal
open={open}
onClose={handleClose}
aria-labelledby="modal-modal-title"
aria-describedby="modal-modal-description"
>
<Box sx={{...style,width:{sm:300,lg:400,},textAlign:"center"}}>
<Stack >
    <Typography sx={{fontSize:"28px",fontWeight:700,}}> <span style={{color:"#ffb12f"}}>{reqCredit}</span> Credits required to create this project configaration  </Typography>
</Stack>

    <Typography sx={{fontSize:"28px",fontWeight:700,mt:"40px",mb:"50px"}}> <span style={{color:reqCredit<=availCredit?"#25c027":"#eb6270"}}>{availCredit}</span>  Credits available  </Typography>

<Stack direction="row" justifyContent="space-around" sx={{mb:"50px"}}>
   {reqCredit<=availCredit?<Button className="univercel_btn_class" sx={{padding:"16px 54px 16px 54px"}}>Confirm</Button>:null}
     
     <Button sx={{padding:"16px 54px 16px 54px", color:"black", border:"1px solid #0F5DAE",}}>cancel</Button>
</Stack>

<Stack direction="row" spacing={1} alignItems="center" justifyContent="center">
    <CallIcon/>
    <Typography sx={{color:" #A2A3A6", fontSize:"14px", fontWeight:"400"}}>Contact support</Typography>
</Stack>


</Box>
</Modal>

    </Stack>
      </Stack>
</Stack>
        </>
    )
}