import { AuthService } from "../../../../context/buyer/services/auth"
import { CPserver } from "./server/server"
class CPS { 
    Auth:AuthService
    server:CPserver
    constructor(){
        this.Auth = new AuthService()
        this.server = new CPserver(this.Auth)
    }
    
}

export{CPS}