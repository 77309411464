import { Box } from "@mui/system";
import React, { useContext, useEffect, useState } from "react";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import TuneOutlinedIcon from "@mui/icons-material/TuneOutlined";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Button,
  Checkbox,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  Modal,
  OutlinedInput,
  Select,
  Stack,
  TextField,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";

import AddIcon from "@mui/icons-material/Add";
import axios from "axios";
import { api, Apiaddress } from "../../../../../utils/consts";
import profileContext from "../../../../../context/profile/context";
import { GridExpandMoreIcon } from "@mui/x-data-grid";
import { AuthService } from "../../../../../context/buyer/services/auth";
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const names = ["Aditya Birla Appartments", "Tata Farms"];

function RightSidebarTD({
  curselectedRows,
  setTrigger,
  trigger,
  updateData,
  setState,
}) {
  const [expanded, setExpanded] = useState("panel1");
  const [disabelAccrodian, setdisabelAccrodian] = useState(
    curselectedRows ? "true" : "false"
  );
  const [selectValue, setSelectValue] = useState("High");
  const [setProjects, setsetProjects] = React.useState([]);

  const handleChangeLeadProject = (event) => {
    const {
      target: { value },
    } = event;
    setsetProjects(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };
  const [selectArr, setselectArr] = useState([
    {
      value: "High",
      label: "High",
    },
    {
      value: "low",
      label: "low",
    },
  ]);

  const createOpt = () => { };

  const [colors, setColors] = useState([
    { name: "Ajay", clr: "green" },
    { name: " Tarun", clr: " yellow" },
    { name: "  Ranbir", clr: "red" },
  ]);
  const [leadname, setLeadName] = useState("");
  const [leadclr, setLeadclr] = useState("");

  const addClr = () => {
    const obj = { name: leadname, clr: leadclr };
    setColors([...colors, obj]);
    handleClose();
  };

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  // useEffect(() => {
  //   console.log(updateData);
  // }, [updateData]);

  const [name, setName] = useState(updateData ? updateData.Name : "");
  const [phone, setPhne] = useState("");
  const [Email, setEmail] = useState("");
  const [Team, setTeam] = useState("");
  const [Role, setRole] = useState("");
  const [err, setError] = useState(false);

  const handleChange = (e) => {
    setRole(e.target.value);
  };

  // console.log(Role);

  const cpDetails = JSON.parse(localStorage.getItem('cpDetails'));

  // console.log(cpDetails);

  let cpOrg = cpDetails?.channelPartnerOrg;
  let cp = JSON.parse(localStorage.getItem("cpDetails"));
  let orgId = cp?.org_details?.id;
  // console.log(cp);

  const user = useContext(profileContext);

  const [newMemberId, setnewMemeberId] = useState(null);

  const [userID, setUserID] = useState(null);

  useEffect(() => {
    if (user)
      if (user.UserState)
        if (user.UserState.user_obj)
          if (user.UserState.user_obj.id) setUserID(user.UserState.user_obj.id);
  }, [user]);

  // (api("/api/role/createcpTeam/")

  const saveChanges = async (e) => {
    e.preventDefault();
    if (name == "" || email == "" || Phone == "" || Role == "" || cpOrg == "") return;
    try {
      const server = new AuthService()
      // console.log(Role);
      // return;
      const x = await (await server.makeAuthenticatedPostRequest(api("/api/role/createcpTeam/"), { JsonBody: { "name": name, "email": email.toLowerCase(), "phone": Phone, "rera": "", "cp_type": Role, "orgId": cpOrg, "account_type": "Org" } })).json()
      console.log("Channel partner teammember created", x)
      setState(false);
      setTrigger(!trigger);
    } catch (err) {
      setError(true);
      console.log(err.message);
    }
  };

  const [filterselect, setfilterselect] = useState();
  const [filtervalue, setfiltervalue] = useState();

  const [Roles, setRoles] = useState([]);
  const [Phone, setPhone] = useState();
  const [email, setemail] = useState();

  const fetchfilter = () => { };
  useEffect(() => {
    const x = async () => {
      const server = new AuthService();
      const data = await (
        await server.makeAuthenticatedGetRequest(api("/api/roleprofile/"))
      ).json();
      // console.log("ye data", data);
      setRoles(data.roles);
    };
    x();
  }, []);

  return (
    <Box
      sx={{
        height: "100vh",
        width: "400px",
        padding: "30px",
      }}
    >
      <Box
        onClick={() => !setState()}
        sx={{
          display: "flex",
          alignItems: "center",
          gap: "10px",
          cursor: "pointer",
        }}
      >
        <ArrowBackIcon sx={{ ml: "-5px" }} />
        <Typography
          sx={{
            color: "#2979F2",
            fontSize: "22px",
            fontWeight: 600,
            fontFamily: "Circular Std",
          }}
        >
          Invite Member
        </Typography>
      </Box>
      <form onSubmit={saveChanges} style={{ marginTop: "50px" }}>
        <Stack spacing={3}>
          <Box
            sx={{
              alignItems: "center",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Typography
              sx={{
                color: "#84828c",
                fontWeight: 500,
                fontFamily: "Product Sans",
              }}
            >
              Name
            </Typography>
            <Box>
              <TextField
                value={name}
                required
                type="text"
                id="outlined-basic"
                onChange={(e) => setName(e.target.value)}
                sx={{ width: "250px" }}
              />
            </Box>
          </Box>

          <Box
            sx={{
              alignItems: "center",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Typography
              sx={{
                color: "#84828c",
                fontWeight: 500,
                fontFamily: "Product Sans",
              }}
            >
              Phone no
            </Typography>
            <Box>
              <TextField
                value={Phone}
                type={"number"}
                required
                // label={"+91 9999999999"}
                id="outlined-basic"
                onChange={(e) =>
                  e.target.value.length <= 10 ? setPhone(e.target.value) : null
                }
                sx={{ width: "250px" }}
              />
            </Box>
          </Box>

          <Box
            sx={{
              alignItems: "center",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Typography
              sx={{
                color: "#84828c",
                fontWeight: 500,
                fontFamily: "Product Sans",
              }}
            >
              Email
            </Typography>
            <Box>
              <TextField
                value={email}
                id="outlined-basic"
                type={"email"}
                required
                onChange={(e) => setemail(e.target.value)}
                sx={{ width: "250px" }}
              />
            </Box>
          </Box>
          <Box
            sx={{
              alignItems: "center",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Typography
              sx={{
                color: "#84828c",
                fontWeight: 500,
                fontFamily: "Product Sans",
              }}
            >
              Role
            </Typography>
            <Box sx={{ textAlign: "center" }}>
              <FormControl sx={{ width: "250px" }}>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={Role}
                  required
                  // label="Select"
                  onChange={handleChange}
                >
                  <MenuItem value={"Individual"}>Individual</MenuItem>
                  <MenuItem value={"Admin"}>Admin</MenuItem>
                  {/* {
                                  Roles.map((e) => {
                                    console.log(e)
                                    return <MenuItem value={e.id}>{e.name}</MenuItem>
                                  })

                                } */}
                </Select>
              </FormControl>
            </Box>
          </Box>

          {err && (
            <Typography sx={{ color: "red", fontSize: "16px" }}>
              User Is already Exist
            </Typography>
          )}
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              mt: "30px",
            }}
          >
            {
              <Button
                type="submit"
                // onClick={saveChanges}
                sx={{
                  padding: "10px 50px",
                  color: "#06386B",
                  textTransform: "none",
                  fontSize: "20px",
                  fontFamily: "Product Sans",
                  background:
                    "linear-gradient(97.85deg, #FFAF2A 0.95%, #FFC666 100%)",
                  "&:hover": {
                    boxShadow: "0px 9px 12px rgba(242, 178, 73, 0.59)",
                  },
                }}
              >
                Invite Member
              </Button>
            }
          </Box>
        </Stack>
      </form>
    </Box>
  );
}

export default RightSidebarTD;
