import { Typography } from "@mui/material";
import { Box, Stack } from "@mui/system";
import axios from "axios";
import { useEffect, useState } from "react";
import { Apiaddress } from "../../../../utils/consts";

import { LinearStatusBar } from "../Components/LinearStatusBar";
import OccupancyStats from "../OccupancyStats";
import OccuPieMaker from '../OccuPieChart';

export const Availability = (props)=>{
    
   /// get selected project id from local storage
   let builderSelectedData = JSON.parse(localStorage.getItem("BuilderSelected"));
   let id = builderSelectedData?.selectedProjectID;
 

  const [noOfUnit, setNoOfUnit] = useState();

  // const [dat, setDat] = useState([
  //   { name: 'Available', value: 0, start: "#0085FF", end: "#65C5FF" },
  //   { name: 'Booked', value: 0, start: "#FF4267", end: "#FF7A93" },
  //   { name: 'Registered', value: 0, start: "#FFAF2A", end: "#FFC666" },
  //   { name: 'Reserved', value: 0, start: "#FFAF2A", end: "#FFC666" },
  //   { name: 'Unavailable', value: 0, start: "#FFAF2A", end: "#FFC666" },
  // ]);

   const [dat, setDat] = useState([
    { name: 'Available', value: 10, start: "#9299BC", end: "#9299BC" },
    { name: 'Booked', value: 20, start: "#9299BC", end: "#9299BC" },
    { name: 'Registered', value: 27, start: "#9299BC", end: "#9299BC" },
    { name: 'Reserved', value: 15, start: "#9299BC", end: "#9299BC" },
    { name: 'Unavailable', value: 35, start: "#9299BC", end: "#9299BC" },
  ]);

  const [vacancyData, setVacancyData] = useState([])


  const fetchStats = async () => {
    if(id==undefined)return;
    try {
        const res = await axios.get(
          Apiaddress + `/api/projects/vaccencystatusofproject/?project_id=${id}`
        );
      console.log("===========================.>",res?.data,id);
      setNoOfUnit(res?.data.total_units);
       
      setDat(
      [
    { name: 'Available', value:res.data?.Available, start: "#00C29A", end: "#00C29A",per:getPercent(res.data?.Available,res?.data.total_units) },
    { name: 'Booked', value:res.data?.Booked, start: "#FFB333", end: "#FFB333",per:getPercent(res.data?.Booked,res?.data.total_units) },
    { name: 'Registered', value:res.data?.Registered, start: "#0890FE", end: "#0890FE",per:getPercent(res.data?.Registered,res?.data.total_units) },
    { name: 'Reserved', value:res.data?.Reserved, start: "#3629B7", end: "#3629B7",per:getPercent(res.data?.Reserved,res?.data.total_units) },
    { name: 'Unavailable', value:res.data?.Unavailable, start: "#FF4267", end: "#FF4267",per:getPercent(res.data?.Unavailable,res?.data.total_units) },
      ]
    )

    } catch (err) {
      console.log("====================",err);
    }
  };

  useEffect(() => {
    fetchStats();
  },[props.update]);

  let temp = ["Available","Booked","Registered","Reserved","Unavailable"]
  let color = ["#FF4267","#FFAF2A","#87C7C6","#105EAD","#0890FE"]

  const getPercent = (Aof, B)=>{
      let per = (Aof*100)/B
      return per.toFixed(2)
  } 

    return (
        <Box
              sx={{
                boxShadow: 1,margin:"-8px 0px 20px 0px",padding:"15px 25px 0px 25px",width: "94%",height: 265,borderRadius: 2,alignItems: "center",backgroundColor:"#fff",border:"1px solid lightgray"
              }}>
                 <Typography sx={{fontFamily:'Poppins',fontStyle: "normal",fontWeight: "600",fontSize: "18px",lineHeight: "20px",color:noOfUnit?"#2979F2":"#9299BC",marginBottom:"20px"}}>Occupancy Status</Typography>
                 <Stack direction="row" sx={{ height:"76%"}}>
                  <Stack direction="column" justifyContent="center" alignItems="center" sx={{width:"20%"}}>
                  <OccuPieMaker data={dat} />
                 {noOfUnit && <Typography sx={{fontFamily:'Poppins',fontStyle: "normal",fontWeight: "600",fontSize: "16px",lineHeight: "20px",color:"#EB6270",marginTop:"15px"}}>
                  {`Total Units: `+noOfUnit}
                 </Typography>}
                  </Stack>
                  <Stack direction="column" justifyContent="space-around"  sx={{width:"73%"}}>
                    {dat.map((ele,index)=>{
                      return (
                    <Stack direction="row" justifyContent="space-between" alignItems="center" sx={{}}>
                      
                      <Typography sx={{color:noOfUnit?"#3E4954":"#9299BC", fontWeight:"500", fontSize:"16px", ml:"5%", width:"120px"}}>{ele.name}</Typography>
                      <Stack direction="row" justifyContent="space-between" alignItems="center" sx={{width:"70%",ml:"15%", }}>
                      <Box sx={{width:"70%",textAlign:"left"}}>
                      <LinearStatusBar value={ele.per>0?ele.per:0} color={ele.start}/>
                      </Box>
                      <Typography sx={{color:noOfUnit?"#3E4954":"#9299BC"}}>{ele.value}</Typography>
                      </Stack>

                    </Stack>
                      )
                    })}

                  </Stack>

                 </Stack>
            </Box>
    )
}