import { AuthService } from "../auth";
import { DocumentFetchingService } from "./documents";
import { ProjectsFetchingService } from "../projects/projects";
import { TicketsFetchingService } from "./tickets";
import { UnitsFetchingService } from "./units";

class Server {
    Auth: AuthService
    units: UnitsFetchingService;
    projects: ProjectsFetchingService;
    tickets: TicketsFetchingService;
    documents: DocumentFetchingService;
    constructor({ Auth }: { Auth: AuthService }) {
        this.Auth = Auth;
        this.units = new UnitsFetchingService(this.Auth);
        this.projects = new ProjectsFetchingService(this.Auth);
        this.tickets = new TicketsFetchingService(this.Auth);
        this.documents = new DocumentFetchingService(this.Auth);
    }


}

export { Server };