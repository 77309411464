import { createTheme } from "@mui/material";

const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 560,
      md: 768,
      md2: 1024,
      lg: 1200,
      xl: 1366,
    },
  },
});

export default theme;
