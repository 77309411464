import {
  Box,
  Button,
  Checkbox,
  Drawer,
  Typography,
  Accordion,
  AccordionSummary,
  Modal,
  Radio,
  TextField,
  FormControl,
  Select,
  MenuItem,
} from "@mui/material";
import { Stack } from "@mui/system";
import TuneOutlinedIcon from "@mui/icons-material/TuneOutlined";
import KeyboardArrowRightOutlinedIcon from "@mui/icons-material/KeyboardArrowRightOutlined";
import KeyboardArrowUpOutlinedIcon from "@mui/icons-material/KeyboardArrowUpOutlined";
import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined";
import { CheckBox } from "@mui/icons-material";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import DriveFileRenameOutlineIcon from "@mui/icons-material/DriveFileRenameOutline";
import VisibilityIcon from "@mui/icons-material/Visibility";
import DeleteIcon from "@mui/icons-material/Delete";
import React, { useContext, useEffect, useState } from "react";
import { DataGrid, GridCloseIcon, GridExpandMoreIcon } from "@mui/x-data-grid";
import CategoryIcon from "@mui/icons-material/Category";
import HandshakeIcon from "@mui/icons-material/Handshake";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import SearchRoundedIcon from "@mui/icons-material/SearchRounded";
import AddIcon from "@mui/icons-material/Add";
import PercentIcon from "@mui/icons-material/Percent";
import CircularProgress from "@mui/material/CircularProgress";

import axios from "axios";
import UDcustomTable from "./UDcustomTable";
import AccordionDetails from "@mui/material/AccordionDetails";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import RightSidebarUO from "./RightSidebarUO";
import { Apiaddress } from "../../../utils/consts";
import profileContext from "../../../context/profile/context";
import { ProjectDashboardSidebar } from "../../BuilderComponents/ProjectDashboard/DashboardComponents/ProjectDashboardSidebar";
import { Navbar } from "../../BuilderComponents/ProjectDashboard/DashboardComponents/Navbar";
import { NavbarForDirectory } from "../../BuilderComponents/ProjectDashboard/DashboardComponents/NavbarForDirectory";
import { useLocation, useParams } from "react-router-dom";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import { ImageGrid } from "./courousal/imageGrid";

const columns = [
  { field: "id", headerName: "id", width: 130 },
  { field: "Unit No", headerName: "Unit No", width: 130 },
  { field: "Floor No", headerName: "Floor No", width: 130 },
  { field: "Bedrooms", headerName: "Bedrooms", width: 130 },
  {
    field: "Bathrooms",
    headerName: "Bathrooms",
    // type: "number",
    width: 130,
  },
  {
    field: "Pricing",
    headerName: "Pricing",
    width: 130,
  },

  {
    field: "View",
    headerName: "View",
    // headerAlign: "center",
    width: 130,
    renderCell: (params) => {
      return (
        <Stack direction="row">
          <RemoveRedEyeIcon fontSize="small" />
          <DeleteIcon fontSize="small" />
        </Stack>
      );
    },
  },
];

const rows = [
  {
    id: 1,
    Name: "#4560",
    State: "26 March 2022, 12:42 AM",
    City: "Seema Agarwal",
    Pincode: "67 St. pune",
    Unit: "Agent",
  },
];

export const Unit_overview_w_sb = () => {
  let id = useParams().id;
  console.log(id);

  const [UnitData, setUnitData] = useState([]);
  const [TotalAmount, setTotalAmount] = useState(0);
  const [images, setImages] = useState([]);
  const [floorplan, setFloorPlan] = useState([]);
  const [unitStatus, setUnitStatus] = useState();

  // console.log("floor Plan Images====>",floorplan);

  //Deepak work for Quotation
  const [selectedUnit, setSelectedUnit] = useState({});
  const [unitCharge, setUnitCharge] = useState([]);

  const fetchUnitData = async () => {
    try {
      const res = await axios.get(
        Apiaddress + `/api/projects/unit/?unit_id=${id}`
      );

      console.log("===========================.>", res?.data?.unit);

      //Deepak work for quotation
      let obj = {
        id: res?.data?.unit[0]?.id,
        name: res?.data?.unit[0]?.name,
      };
      setSelectedUnit(obj);

      let temp = res?.data?.unit[0]?.charges_data;
      let total = 0;
      if (Object.keys(temp).length === 0 && temp.constructor === Object) {
        setUnitCharge([]);
      } else {
        setUnitCharge(temp);
        for (let i = 0; i < temp.length; i++) {
          total += +temp[i].sectioncost;
        }
      }
      setUnchangedTotal(total);
      setGrandTotal(total);

      let tempCost = res?.data?.unit[0]?.charges_data;
      let status = res?.data?.unit[0]?.unit_status;
      console.log(tempCost);
      if (res.data.unit == " Unit not found") {
        setUnitData([]);
      } else {
        let Tcost = 0;
        for (let i = 0; i < tempCost.length; i++) {
          Tcost = Tcost + tempCost[i].sectioncost;
        }
        setTotalAmount(Tcost);
        setUnitData(res?.data?.unit);
        console.log("UNitt", res?.data?.unit);
        setImages(parseImagesFromUnitData(res?.data?.unit));
        setFloorPlan(parseFloorImagesFromUnitData(res?.data?.unit));
      }

      if (status == 1) {
        setUnitStatus("Unavailable");
      } else if (status == 2) {
        setUnitStatus("Reserved");
      } else if (status == 3) {
        setUnitStatus("Registered");
      } else if (status == 4) {
        setUnitStatus("Booked");
      } else if (status == 5) {
        setUnitStatus("Available");
      }
    } catch (err) {
      console.log("====================", err);
      setUnitData([]);
    }
  };

  const parseImagesFromUnitData = (p) => {
    console.log("oYE Parse  ", p);

    const image = p[0]?.layout_data?.image;
    console.log("oYE image", image);

    let imageUrlBucket = [];
    if (image !== undefined) {
      image.forEach((e) => {
        if (!e.is_file) {
          imageUrlBucket.push(e.image);
        }
      });
    }
    return imageUrlBucket;
  };

  const parseFloorImagesFromUnitData = (p) => {
    console.log("oYE Parse  ", p);

    const FloorImage = p[0]?.layout_data?.floorplan;
    console.log("oYE Floorimage", FloorImage);

    let FloorImageUrlBucket = [];
    if (FloorImage !== undefined) {
      FloorImage.forEach((e) => {
        if (!e.is_file) {
          FloorImageUrlBucket.push(e.image);
        }
      });
    }
    return FloorImageUrlBucket;
  };

  useEffect(() => {
    fetchUnitData();
  }, []);

  const [state, setState] = React.useState(false);

  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState(open);
  };

  const [selectedRows, setSelectedRows] = React.useState([]);
  useEffect(() => {
    console.log(selectedRows);
  }, [selectedRows]);

  const [curselectedRows, setcurselectedRows] = useState(null);
  const onRowsSelectionHandler = (ids) => {
    setRowSelected(true);
    const selectedRowsData = ids.map((id) => rows.find((row) => row.id === id));
    console.log(selectedRowsData);
    setcurselectedRows(selectedRowsData);
  };

  const [tabledata, setTabledata] = useState(null);
  const [rowData, setrowData] = useState([]);
  const [idCont, setidCunt] = useState(1);

  //api

  //api

  useEffect(() => {
    const rows = [
      {
        id: 8,
        Name: "Frances",
        Phone: "912912912",
        Email: "dummmy@gamil.com",
        Team: "Agent",
        Role: "Agent",
        DetailedView: "View",
      },
    ];
    if (tabledata) {
      let obj = {
        id: idCont,
        Name: tabledata.name,
        Phone: tabledata.phone,
        Email: tabledata.Email,
        Team: tabledata.Team,
        Role: tabledata.Role,
      };
      setidCunt(idCont + 1);
      console.log(obj);
      setrowData([...rowData, obj]);
    }

    console.log(tabledata);
  }, [tabledata]);

  const [rowSelected, setRowSelected] = useState(false);

  const [data, setData] = useState(null);
  const [token, setToken] = useState(null);

  const getProjects = async () => {
    try {
      const headers = {
        Authorization: `JWT ${token}`,
      };
      const res = await axios.get(
        Apiaddress + "/api/projects/getprojectsusingtoken/",
        { headers }
      );
      // console.log(res.data.projects);
      if (res.data) if (res.data.projects) setData(res.data.projects);
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    if (token) getProjects();
  }, [token]);

  useEffect(() => {
    setToken(localStorage.getItem("crm_token"));
  }, []);
  const [newRowData, setNewRowData] = useState([]);

  const fetchUnit = async () => {
    try {
      let arr = [];
      for (let i = 0; i < data.length; i++) {
        const res = await axios.get(
          Apiaddress + `/api/projects/searchunits/?query=${data[i].id}`
        );
        // console.log(res);
        if (res) if (res.data) if (res.data.units) arr.push(res.data.units);
      }
      setNewRowData(arr);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (data) {
      // console.log(data);

      fetchUnit();
    }
  }, [data]);
  const [newUnits, setNewUnits] = useState(null);
  useEffect(() => {
    if (newRowData) {
      let arr = [];
      for (let i = 0; i < newRowData.length; i++) {
        if (newRowData[i] != "No units found") {
          // console.log(newRowData[i]);
          for (let j = 0; j < newRowData[i].length; j++) {
            // console.log(newRowData[i][j]);
            arr.push(newRowData[i][j]);
          }
        }
      }
      setNewUnits(arr);
    }
  }, [newRowData]);

  //Deepak's work for create Quotation

  /// get selected project id from local storage
  let builderSelectedData = JSON.parse(localStorage.getItem("BuilderSelected"));
  let selectedId = builderSelectedData.selectedProjectID;
  console.log("selectedProjectId===", selectedId);

  //Quotation Create States
  const [projectInfo, setProjectInfo] = useState({});
  const [leadForQuotation, setleadForQuotation] = useState([]);
  const [selectedLead, setSelectedLead] = useState();
  const [error, seterror] = useState(false);
  const [date, setDate] = useState();
  const [discount, setDiscount] = useState();
  const [grandTotal, setGrandTotal] = useState();
  const [unchangedTotal, setUnchangedTotal] = useState(0);
  const [input, setinput] = useState();
  const [loadingSubmit, setLoadingSubmit] = useState(false);

  //create Lead States and Functionality
  const [createLeadName, setCreateLeadName] = useState();
  const [createLeadPhone, setCreateLeadPhone] = useState();
  const [createLeadEmail, setCreateLeadEmail] = useState();
  const [createLeadPriority, setCreateLeadPriority] = useState();

  //Buyer request file states
  const [tempLead, setTempLead] = useState({
    name: "",
    id: 0,
  });
  const [quotation, setQuotation] = useState();
  const [quotationError, setQuotationError] = useState(false);
  const [BuyerDate, setBuyerDate] = useState();
  const [loadingBuyerSubmit, setLoadingBuyerSubmit] = useState(false);

  console.log(quotation);
  const user = useContext(profileContext);
  const [userID, setUserID] = useState(null);

  useEffect(() => {
    if (user)
      if (user.UserState)
        if (user.UserState.user_obj)
          if (user.UserState.user_obj.id) setUserID(user.UserState.user_obj.id);
  }, [user]);

  const createNewLead = async () => {
    try {
      let objPost = {
        name: createLeadName,
        phone: createLeadPhone,
        email: createLeadEmail,
        project: selectedId,
        lead_prority: createLeadPriority,
        assigned_to: [userID],
      };
      console.log("createLead====", objPost);
      const res = await axios.post(Apiaddress + "/api/leads/lead/", objPost);
      console.log("createLead====", res.data);
      setCreateLeadEmail("");
      setCreateLeadName("");
      setCreateLeadPhone("");
      setCreateLeadPriority("");
      handleCloseChildLead();
      fetchLeadForQuotation();
    } catch (err) {
      console.log(err);
    }
  };

  //final generate Quotation
  const onSubmit = async () => {
    try {
      console.log(selectedLead, "===", discount, "===", date);

      if (!selectedLead || !discount || !date) return;
      setLoadingSubmit(true);
      let converted_Date = new Date(date);
      const headers = {
        Authorization: `JWT ${token}`,
      };
      const obj = {
        lead: selectedLead?.id,
        unit: selectedUnit?.id,
        adjustment: grandTotal,
        valid_till: converted_Date,
        project: selectedId,
      };
      console.log(obj);
      const res = await axios.post(
        Apiaddress + "/api/leads/quotation/",
        obj
        // { headers: headers }
      );
      console.log("generatedQuotation====", res?.data);
      handleClose();
      setDiscount("");
      setDate("");
      setinput("");
      setSelectedLead("");
      setGrandTotal(unchangedTotal);
    } catch (err) {
      console.log("quotationerror", err);
    }
    setLoadingSubmit(false);
  };

  //fetching ProjectInfo
  const fetchProjectInfo = async () => {
    try {
      const res = await axios.get(
        Apiaddress + `/api/projects/project/?project_id=${selectedId}`
      );
      console.log("===========================.>", res?.data?.projects[0]);
      let projectInfo = res?.data?.projects[0];
      let obj = {
        address: projectInfo?.address_line,
        city: projectInfo?.city,
        state: projectInfo?.state,
        pincode: projectInfo?.pin_code,
        name: projectInfo?.name,
      };
      setProjectInfo(obj);
    } catch (err) {
      console.log("====================", err);
    }
  };

  //Parent Modal Functionality
  const [selectedValue, setSelectedValue] = useState("a");

  const handleChangeRadio = (event) => {
    setSelectedValue(event.target.value);
    setinput("");
    console.log(event.target.value);
  };

  const controlProps = (item) => ({
    checked: selectedValue === item,
    onChange: handleChangeRadio,
    value: item,
    name: "color-radio-button-demo",
    inputProps: { "aria-label": item },
  });

  const [selectedAdjustment, setSelectedAdjustment] = useState("a");

  //calculating and setting after Discount
  const grandTotalSetting = (e) => {
    if (selectedAdjustment == "percentDiscount") {
      if (e.target.value < 0 || e.target.value > 100) return;
      else {
        setDiscount(e.target.value);

        let calculatedDiscount = unchangedTotal * (e.target.value / 100);
        let result = unchangedTotal - calculatedDiscount;
        console.log("result===", result, unchangedTotal);
        setGrandTotal(result);
      }
    } else {
      if (e.target.value < 0 || e.target.value > unchangedTotal) return;
      else {
        setDiscount(e.target.value);

        let calculatedDiscount = e.target.value;
        let result = unchangedTotal - calculatedDiscount;
        console.log("result===", result, unchangedTotal);
        setGrandTotal(result);
      }
    }
  };

  const [allpriority, setallpriority] = useState(null);

  const getpriority = async () => {
    try {
      const res = await axios.get(Apiaddress + "/api/leads/leadprority/");
      console.log(res.data.leadprority);
      setallpriority(res.data.leadprority);
    } catch (err) {
      console.log(err);
    }
  };

  //fetch lead for quotation
  const fetchLeadForQuotation = async () => {
    try {
      const res = await axios.get(
        Apiaddress + "/api/leads/leadsofproject/?project_id=" + selectedId
      );
      console.log(res?.data?.leads);
      if (res?.data?.leads == "lead not found") {
        setleadForQuotation([]);
      } else {
        setleadForQuotation(res?.data?.leads);
      }
    } catch (err) {
      console.log(err);
    }
  };

  //searching lead of project
  const searchLead = () => {
    let value = input.toLowerCase();
    if (selectedValue == "phone") {
      value = "+91 " + value;
    }
    let result = leadForQuotation.filter((data) => {
      if (selectedValue == "email") {
        return data.email == value;
      } else {
        return data.phone == value;
      }
    });
    console.log("result====", result);
    if (result.length == 0) {
      setSelectedLead({});
      seterror(true);
    } else {
      setSelectedLead(result[0]);
      seterror(false);
    }
  };

  const handleChangeRadioAdjustment = (event) => {
    setSelectedAdjustment(event.target.value);
    console.log(event.target.value);
    setDiscount("");
    setGrandTotal(unchangedTotal);
  };

  const controlPropsAdjustment = (item) => ({
    checked: selectedAdjustment === item,
    onChange: handleChangeRadioAdjustment,
    value: item,
    name: "color-radio-button-demo",
    inputProps: { "aria-label": item },
  });

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  //child Modal functionality to create Lead
  const [openChildLead, setOpenChildLead] = React.useState(false);
  const handleOpenChildLead = () => {
    setOpenChildLead(true);
  };
  const handleCloseChildLead = () => {
    setOpenChildLead(false);
  };

  //Buyer File Modal functionality
  const [openBuyerFile, setOpenBuyerFile] = React.useState(false);
  const handleOpenBuyerFile = () => setOpenBuyerFile(true);
  const handleCloseBuyerFile = () => setOpenBuyerFile(false);

  //fetching quotation information associated with unit and lead
  const fetchLeadQuotation = async () => {
    console.log(selectedUnit?.id);
    console.log(tempLead);
    try {
      const res = await axios.get(
        Apiaddress +
          `/api/leads/quotationbyunitandlead/?unit_id=${selectedUnit?.id}&lead_id=${tempLead?.id}`
      );
      console.log("===========================.>", res?.data?.quotations);
      if (res?.data?.quotations != "quotations not found") {
        if (res?.data?.quotations[0]?.status == "Approved") {
          setQuotation(res?.data?.quotations[0]);
          setQuotationError(false);
        } else {
          setQuotationError(true);
        }
      } else {
        setQuotationError(true);
      }
    } catch (err) {
      console.log("====================", err);
    }
  };

  //final generate Quotation
  const onSubmitBuyer = async () => {
    try {
      if (quotationError) return;
      if (tempLead.name === "" || tempLead.id === "" || BuyerDate === "")
        return;
      setLoadingBuyerSubmit(true);
      let converted_Date = new Date(BuyerDate);
      const headers = {
        Authorization: `JWT ${token}`,
      };

      const obj = {
        lead: tempLead?.id,
        unit: selectedUnit?.id,
        valid_till: converted_Date,
        project: selectedId,
        extra_information: { quotation: quotation, unit: selectedUnit },
      };
      console.log(obj);
      const res = await axios.post(Apiaddress + "/api/leads/buyerfile/", obj, {
        headers: headers,
      });
      console.log("generatedBuyerFile====", res?.data);
      handleCloseBuyerFile();
      setBuyerDate("");
      let temp = tempLead;
      temp.id = 0;
      temp.name = "";
      setTempLead(temp);
    } catch (err) {
      console.log("Buyerfileerror", err);
    }
    setLoadingBuyerSubmit(false);
  };

  useEffect(() => {
    fetchProjectInfo();
    fetchLeadForQuotation();
    getpriority();
    setSelectedValue("email");
    setSelectedAdjustment("flatDiscount");
  }, []);

  useEffect(() => {
    fetchLeadQuotation();
  }, [tempLead]);

  return (
    <>
      <Stack direction="row" sx={{ width: "100%", height: "auto" }}>
        {/* 1st stack */}
        <Stack
          sx={{ minHeight: "100vh", width: "22%", boxSizing: "border-box" }}
        >
          <ProjectDashboardSidebar active={"Unit Directory"} />
        </Stack>

        {/* 2nd stack */}
        <Stack
          direction="column"
          sx={{
            backgroundColor: "#f9f9f9",
            width: "78%",
            padding: "25px",
            boxSizing: "border-box",
          }}
        >
          {/* <Navbar/> */}
          <NavbarForDirectory
            showAddProjectbtn={false}
            showRightSideBar={false}
            showRightSideBarProjAdd={false}
            RightBarNameProAdd={"Add Project"}
            toggleDrawer={toggleDrawer}
            showRightSideBarleft={false}
            RightBarNameleft={"Send invite"}
            leftRightTitle1={"Unit Dashboard"}
            leftRightTitle2={" Unit Overview"}
            leftTitle={"Unit Directory" + " " + " ."}
            showLeftTitle={false}
            righshow={true}
          />

          {/* <Typography sx={{mt:"20px", ontSize:"22px", fontWeight:"500",color:"#2979F2"}}>
    Project Name
    </Typography> */}
          {/* actual content  */}
          <Stack sx={{ mt: "28px", backgroundColor: "#f9f9f9" }}>
            <React.Fragment>
              <Drawer anchor="right" open={state} onClose={toggleDrawer(false)}>
                <RightSidebarUO
                  setTabledata={setTabledata}
                  curselectedRows={curselectedRows}
                />
              </Drawer>
            </React.Fragment>

            {/* Buyer Request File generation */}
            <Modal
              open={openBuyerFile}
              onClose={handleCloseBuyerFile}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box
                sx={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  width: "50%",
                  height: "80%",
                  bgcolor: "white",
                  border: "2px solid white",
                  borderRadius: "4px",
                  boxShadow: 24,
                  pt: 3,
                  px: 3,
                  pb: 1.5,
                  overflow: "scroll",
                  whiteSpace: "nowrap",
                  overflowX: "hidden",
                  //  textAlign:"center"
                }}
              >
                <Stack direction="row" justifyContent="space-between">
                  <Typography
                    sx={{
                      fontFamily: "Product Sans",
                      fontStyle: "normal",
                      fontWeight: "600",
                      fontSize: "28px",
                      lineHeight: "33px",
                      color: "#000000",
                    }}
                  >
                    Booking Request File
                  </Typography>
                </Stack>

                <Box sx={{ alignItems: "center", margin: "40px 0px 40px 0px" }}>
                  <Stack
                    direction="row"
                    justifyContent="start"
                    alignItems="center"
                    sx={{ margin: "30px 0px 0px 0px", fontSize: "18px" }}
                  >
                    <Typography
                      component="span"
                      className="field-name"
                      sx={{
                        width: "15%",
                        fontFamily: "Product Sans",
                        fontStyle: "normal",
                        fontWeight: "500",
                        fontSize: "18px",
                        lineHeight: "21px",
                        color: "#000000",
                      }}
                    >
                      Project Name
                    </Typography>
                    <TextField
                      value={projectInfo.name}
                      disabled
                      sx={{
                        width: "325px",
                        marginLeft: "40px",
                      }}
                    />
                  </Stack>
                  <Stack
                    direction="row"
                    justifyContent="start"
                    alignItems="center"
                    sx={{ margin: "30px 0px 0px 0px", fontSize: "18px" }}
                  >
                    <Typography
                      component="span"
                      className="field-name"
                      sx={{
                        width: "15%",
                        fontFamily: "Product Sans",
                        fontStyle: "normal",
                        fontWeight: "500",
                        fontSize: "18px",
                        lineHeight: "21px",
                        color: "#000000",
                      }}
                    >
                      Site Address
                    </Typography>
                    <TextField
                      value={projectInfo.address}
                      disabled
                      sx={{
                        width: "325px",
                        marginLeft: "40px",
                      }}
                    />
                  </Stack>
                  <Stack
                    direction="row"
                    justifyContent="start"
                    alignItems="center"
                    sx={{ margin: "30px 0px 0px 0px", fontSize: "18px" }}
                  >
                    <Typography
                      component="span"
                      className="field-name"
                      sx={{
                        width: "15%",
                        fontFamily: "Product Sans",
                        fontStyle: "normal",
                        fontWeight: "500",
                        fontSize: "18px",
                        lineHeight: "21px",
                        color: "#000000",
                      }}
                    >
                      City
                    </Typography>
                    <TextField
                      value={projectInfo.city}
                      disabled
                      sx={{
                        width: "325px",
                        marginLeft: "40px",
                      }}
                    />
                  </Stack>
                  <Stack
                    direction="row"
                    justifyContent="start"
                    alignItems="center"
                    sx={{ margin: "30px 0px 0px 0px", fontSize: "18px" }}
                  >
                    <Typography
                      component="span"
                      className="field-name"
                      sx={{
                        width: "15%",
                        fontFamily: "Product Sans",
                        fontStyle: "normal",
                        fontWeight: "500",
                        fontSize: "18px",
                        lineHeight: "21px",
                        color: "#000000",
                      }}
                    >
                      State
                    </Typography>
                    <TextField
                      value={projectInfo.state}
                      disabled
                      sx={{
                        width: "325px",
                        marginLeft: "40px",
                      }}
                    />
                  </Stack>
                  <Stack
                    direction="row"
                    justifyContent="start"
                    alignItems="center"
                    sx={{ margin: "30px 0px 0px 0px", fontSize: "18px" }}
                  >
                    <Typography
                      component="span"
                      className="field-name"
                      sx={{
                        width: "15%",
                        fontFamily: "Product Sans",
                        fontStyle: "normal",
                        fontWeight: "500",
                        fontSize: "18px",
                        lineHeight: "21px",
                        color: "#000000",
                      }}
                    >
                      Pin Code
                    </Typography>
                    <TextField
                      value={projectInfo.pincode}
                      disabled
                      sx={{
                        width: "325px",
                        marginLeft: "40px",
                      }}
                    />
                  </Stack>
                  <Stack
                    direction="row"
                    justifyContent="start"
                    alignItems="center"
                    sx={{ margin: "30px 0px 0px 0px", fontSize: "18px" }}
                  >
                    <Typography
                      component="span"
                      className="field-name"
                      sx={{
                        width: "15%",
                        fontFamily: "Product Sans",
                        fontStyle: "normal",
                        fontWeight: "500",
                        fontSize: "18px",
                        lineHeight: "21px",
                        color: "#000000",
                      }}
                    >
                      Unit Number
                    </Typography>
                    <TextField
                      value={selectedUnit?.name}
                      disabled
                      sx={{
                        width: "325px",
                        marginLeft: "40px",
                      }}
                    />
                  </Stack>
                  <Stack
                    direction="row"
                    justifyContent="start"
                    alignItems="center"
                    sx={{
                      margin: "30px 0px 0px 0px",
                      fontSize: "18px",
                      justifyContent: "start",
                    }}
                  >
                    <Typography
                      component="span"
                      className="field-name"
                      sx={{
                        width: "15%",
                        fontFamily: "Product Sans",
                        fontStyle: "normal",
                        fontWeight: "500",
                        fontSize: "18px",
                        lineHeight: "21px",
                        color: "#000000",
                      }}
                    >
                      Select Lead
                    </Typography>
                    <FormControl sx={{ width: "325px", marginLeft: "40px" }}>
                      <Select
                        displayEmpty
                        inputProps={{ "aria-label": "Without label" }}
                        sx={{ height: "45px" }}
                        value={tempLead.name + "-" + tempLead.id}
                        onChange={(e) => {
                          let [name, id] = e.target.value.split("-");
                          let obj = {};
                          obj.name = name;
                          obj.id = +id;
                          setTempLead(obj);
                          // fetchLeadQuotation();
                        }}
                      >
                        {leadForQuotation.map((ele, index) => {
                          return (
                            <MenuItem
                              key={index}
                              value={ele.name + "-" + ele.id}
                            >
                              {ele?.name}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </Stack>

                  {quotationError && (
                    <Typography
                      sx={{
                        fontFamily: "Poppins",
                        fontStyle: "normal",
                        fontWeight: "500",
                        fontSize: "14px",
                        lineHeight: "21px",
                        color: "#FF6140",
                        margin: "10px 0px 0px 330px",
                      }}
                    >
                      No Quotation Found
                    </Typography>
                  )}

                  <Stack
                    direction="row"
                    justifyContent="start"
                    alignItems="center"
                    sx={{ margin: "20px 0px 0px 0px", fontSize: "18px" }}
                  >
                    <Typography
                      component="span"
                      className="field-name"
                      sx={{
                        width: "15%",
                        fontFamily: "Product Sans",
                        fontStyle: "normal",
                        fontWeight: "500",
                        fontSize: "18px",
                        lineHeight: "21px",
                        color: "#000000",
                      }}
                    >
                      Valid Till
                    </Typography>
                    <TextField
                      onChange={(e) => {
                        setBuyerDate(e.target.value);
                      }}
                      value={BuyerDate}
                      type="Date"
                      sx={{
                        width: "188px",
                        marginLeft: "40px",
                      }}
                    />
                  </Stack>
                </Box>
                <Button
                  variant="contained"
                  sx={{
                    background: "#0F5DAE",
                    border: "1px solid #EFF0F7",
                    boxShadow: "0px 2px 6px rgba(19, 18, 66, 0.07)",
                    borderRadius: "4px",
                    border: "1px solid  #0F5DAE",
                    margin: "0px 0px 20px 0px",
                  }}
                  onClick={onSubmitBuyer}
                >
                  {loadingBuyerSubmit ? (
                    <CircularProgress
                      color="inherit"
                      sx={{ padding: "0px 20px" }}
                    />
                  ) : (
                    <Box
                      sx={{ textTransform: "capitalize", margin: "0px 7px" }}
                    >
                      Submit
                    </Box>
                  )}
                </Button>
              </Box>
            </Modal>

            {/*Quotation Generation Modal */}
            {/* Parent Modal  */}
            <Modal
              open={open}
              onClose={handleClose}
              aria-labelledby="parent-modal-title"
              aria-describedby="parent-modal-description"
            >
              <Box
                sx={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  width: "70%",
                  height: "80%",
                  bgcolor: "white",
                  border: "2px solid white",
                  borderRadius: "4px",
                  boxShadow: 24,
                  pt: 3,
                  px: 3,
                  pb: 1.5,
                  overflow: "scroll",
                  whiteSpace: "nowrap",
                  overflowX: "hidden",
                  //  textAlign:"center"
                }}
              >
                <Typography
                  sx={{
                    fontFamily: "Product Sans",
                    fontStyle: "normal",
                    fontWeight: "600",
                    fontSize: "28px",
                    lineHeight: "33px",
                    color: "#000000",
                  }}
                >
                  Quotation Generation Form
                </Typography>
                <Box>
                  <Stack
                    direction="row"
                    justifyContent="start"
                    alignItems="center"
                    sx={{ margin: "35px 0px 20px -10px" }}
                  >
                    <Radio
                      required
                      name="search_type"
                      {...controlProps("email")}
                      sx={{
                        color:
                          "linear-gradient(264.48deg, #79F1A4 -45.37%, #0E5CAD 88.97%)",
                        color: "#0F5DAE",
                      }}
                    />
                    <Typography
                      sx={{
                        fontFamily: "Product Sans",
                        fontStyle: "normal",
                        fontWeight: "500",
                        fontSize: "18px",
                        lineHeight: "21px",
                        color: "#000000",
                        marginRight: "20px",
                      }}
                    >
                      Email
                    </Typography>
                    <Radio
                      required
                      name="search_type"
                      {...controlProps("phone")}
                      sx={{
                        color:
                          "linear-gradient(264.48deg, #79F1A4 -45.37%, #0E5CAD 88.97%)",
                        color: "#0F5DAE",
                      }}
                    />
                    <Typography
                      sx={{
                        fontFamily: "Product Sans",
                        fontStyle: "normal",
                        fontWeight: "500",
                        fontSize: "18px",
                        lineHeight: "21px",
                        color: "#000000",
                      }}
                    >
                      Phone Number
                    </Typography>
                  </Stack>
                </Box>

                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "start",
                    alignItems: "center",
                    color: "#0065FE",
                    // border:'1px solid green'
                  }}
                >
                  <TextField
                    onChange={(e) => {
                      setinput(e.target.value);
                    }}
                    value={input}
                    variant="standard"
                    placeholder={
                      selectedValue == "email"
                        ? "Search by Email"
                        : "Search by Phone"
                    }
                    sx={{
                      boxShadow: "0px 2px 6px rgba(19, 18, 66, 0.07)",
                      borderRadius: "10px 0px 0px 10px",
                      border: "1px solid #2979F2",
                      width: "45%",
                    }}
                    InputProps={{
                      disableUnderline: true,
                      startAdornment: (
                        <SearchRoundedIcon
                          sx={{ color: "#2979F2", margin: 1 }}
                        />
                      ),
                    }}
                  />
                  <Button
                    variant="contained"
                    sx={{
                      background: "#0F5DAE",
                      border: "1px solid #EFF0F7",
                      boxShadow: "0px 2px 6px rgba(19, 18, 66, 0.07)",
                      borderRadius: "0px 10px 10px 0px",
                      border: "1px solid #0F5DAE",
                    }}
                    onClick={searchLead}
                  >
                    <Box sx={{ textTransform: "capitalize", margin: "7px" }}>
                      Search
                    </Box>
                  </Button>

                  <Button
                    variant="contained"
                    sx={{
                      background: "#0F5DAE",
                      border: "1px solid #EFF0F7",
                      boxShadow: "0px 2px 6px rgba(19, 18, 66, 0.07)",
                      borderRadius: "5px",
                      border: "1px solid #0F5DAE",
                      marginLeft: "25px",
                    }}
                    startIcon={<AddIcon />}
                    onClick={handleOpenChildLead}
                  >
                    <Box
                      sx={{ textTransform: "capitalize", margin: "6px 0px" }}
                    >
                      Create New
                    </Box>
                  </Button>
                </Box>

                {error && (
                  <Typography
                    sx={{
                      fontFamily: "Poppins",
                      fontStyle: "normal",
                      fontWeight: "500",
                      fontSize: "14px",
                      lineHeight: "21px",
                      color: "#FF6140",
                      margin: "7px 0px 0px 10px",
                    }}
                  >
                    No Lead Found
                  </Typography>
                )}

                <Box sx={{ alignItems: "center", margin: "40px 0px 40px 0px" }}>
                  <Stack
                    direction="row"
                    justifyContent="start"
                    alignItems="center"
                    sx={{ margin: "30px 0px 0px 0px", fontSize: "18px" }}
                  >
                    <Typography
                      component="span"
                      className="field-name"
                      sx={{
                        width: "15%",
                        fontFamily: "Product Sans",
                        fontStyle: "normal",
                        fontWeight: "500",
                        fontSize: "18px",
                        lineHeight: "21px",
                        color: "#000000",
                      }}
                    >
                      Customer Name
                    </Typography>
                    <TextField
                      value={selectedLead?.name ? selectedLead?.name : ""}
                      disabled
                      sx={{
                        width: "325px",
                        marginLeft: "40px",
                      }}
                    />
                  </Stack>
                  <Stack
                    direction="row"
                    justifyContent="start"
                    alignItems="center"
                    sx={{ margin: "30px 0px 0px 0px", fontSize: "18px" }}
                  >
                    <Typography
                      component="span"
                      className="field-name"
                      sx={{
                        width: "15%",
                        fontFamily: "Product Sans",
                        fontStyle: "normal",
                        fontWeight: "500",
                        fontSize: "18px",
                        lineHeight: "21px",
                        color: "#000000",
                      }}
                    >
                      Project Name
                    </Typography>
                    <TextField
                      value={projectInfo.name}
                      disabled
                      sx={{
                        width: "325px",
                        marginLeft: "40px",
                      }}
                    />
                  </Stack>
                  <Stack
                    direction="row"
                    justifyContent="start"
                    alignItems="center"
                    sx={{ margin: "30px 0px 0px 0px", fontSize: "18px" }}
                  >
                    <Typography
                      component="span"
                      className="field-name"
                      sx={{
                        width: "15%",
                        fontFamily: "Product Sans",
                        fontStyle: "normal",
                        fontWeight: "500",
                        fontSize: "18px",
                        lineHeight: "21px",
                        color: "#000000",
                      }}
                    >
                      Site Address
                    </Typography>
                    <TextField
                      value={projectInfo.address}
                      disabled
                      sx={{
                        width: "325px",
                        marginLeft: "40px",
                      }}
                    />
                  </Stack>
                  <Stack
                    direction="row"
                    justifyContent="start"
                    alignItems="center"
                    sx={{ margin: "30px 0px 0px 0px", fontSize: "18px" }}
                  >
                    <Typography
                      component="span"
                      className="field-name"
                      sx={{
                        width: "15%",
                        fontFamily: "Product Sans",
                        fontStyle: "normal",
                        fontWeight: "500",
                        fontSize: "18px",
                        lineHeight: "21px",
                        color: "#000000",
                      }}
                    >
                      City
                    </Typography>
                    <TextField
                      value={projectInfo.city}
                      disabled
                      sx={{
                        width: "325px",
                        marginLeft: "40px",
                      }}
                    />
                  </Stack>
                  <Stack
                    direction="row"
                    justifyContent="start"
                    alignItems="center"
                    sx={{ margin: "30px 0px 0px 0px", fontSize: "18px" }}
                  >
                    <Typography
                      component="span"
                      className="field-name"
                      sx={{
                        width: "15%",
                        fontFamily: "Product Sans",
                        fontStyle: "normal",
                        fontWeight: "500",
                        fontSize: "18px",
                        lineHeight: "21px",
                        color: "#000000",
                      }}
                    >
                      State
                    </Typography>
                    <TextField
                      value={projectInfo.state}
                      disabled
                      sx={{
                        width: "325px",
                        marginLeft: "40px",
                      }}
                    />
                  </Stack>
                  <Stack
                    direction="row"
                    justifyContent="start"
                    alignItems="center"
                    sx={{ margin: "30px 0px 0px 0px", fontSize: "18px" }}
                  >
                    <Typography
                      component="span"
                      className="field-name"
                      sx={{
                        width: "15%",
                        fontFamily: "Product Sans",
                        fontStyle: "normal",
                        fontWeight: "500",
                        fontSize: "18px",
                        lineHeight: "21px",
                        color: "#000000",
                      }}
                    >
                      Pin Code
                    </Typography>
                    <TextField
                      value={projectInfo.pincode}
                      disabled
                      sx={{
                        width: "325px",
                        marginLeft: "40px",
                      }}
                    />
                  </Stack>
                  <Stack
                    direction="row"
                    justifyContent="start"
                    alignItems="center"
                    sx={{ margin: "30px 0px 0px 0px", fontSize: "18px" }}
                  >
                    <Typography
                      component="span"
                      className="field-name"
                      sx={{
                        width: "15%",
                        fontFamily: "Product Sans",
                        fontStyle: "normal",
                        fontWeight: "500",
                        fontSize: "18px",
                        lineHeight: "21px",
                        color: "#000000",
                      }}
                    >
                      Unit Number
                    </Typography>
                    <TextField
                      value={selectedUnit?.name}
                      disabled
                      sx={{
                        width: "325px",
                        marginLeft: "40px",
                      }}
                    />
                  </Stack>
                </Box>

                <Box sx={{}}>
                  <Box
                    sx={{
                      background: "#0F5DAE",
                      borderRadius: "4px",
                      height: "50px",
                      justifyContent: "center",
                      alignItems: "center",
                      display: "flex",
                    }}
                  >
                    <Typography
                      sx={{
                        fontFamily: "Product Sans",
                        fontStyle: "normal",
                        fontWeight: "700",
                        fontSize: "20px",
                        lineHeight: "20px",
                        color: "#FFFFFF",
                      }}
                    >
                      Pricing Details
                    </Typography>
                  </Box>

                  <Stack
                    direction="row"
                    sx={{
                      width: "100%",
                      display: "flex",
                      flexWrap: "wrap",
                      height: "325px",
                      overflow: "scroll",
                      whiteSpace: "nowrap",
                      overflowX: "hidden",
                    }}
                  >
                    {unitCharge?.map((ele, idx) => {
                      return (
                        <Box
                          sx={{
                            width: "420px",
                            boxShadow: "0px 1px 97px rgba(3, 12, 21, 0.1)",
                            borderRadius: "0px 0px 10px 10px",
                            margin: "0px 5px 10px 5px",
                          }}
                        >
                          <Box
                            sx={{
                              background:
                                "linear-gradient(90deg, #0F5DAE 0%, #009DCF 100.33%)",
                              borderRadius: "4px",
                              height: "40px",
                              alignItems: "center",
                              display: "flex",
                              width: "50%",
                              borderRadius: "0px 100px 0px 0px",
                              margin: "20px",
                            }}
                          >
                            <Typography
                              sx={{
                                fontFamily: "Product Sans",
                                fontStyle: "normal",
                                fontWeight: "500",
                                fontSize: "18px",
                                lineHeight: "20px",
                                color: "#FFFFFF",
                                margin: "0px 20px 0px 20px",
                              }}
                            >
                              {ele?.titlename}
                            </Typography>
                          </Box>

                          <Box sx={{ margin: "20px" }}>
                            {ele?.field?.map((e) => {
                              return (
                                <Stack
                                  direction="row"
                                  sx={{ marginBottom: "10px" }}
                                >
                                  <Typography
                                    sx={{
                                      width: "50%",
                                      fontFamily: "Poppins",
                                      fontStyle: "normal",
                                      fontWeight: "500",
                                      fontSize: "18px",
                                      lineHeight: "27px",
                                      color: "#A2A3A6",
                                    }}
                                  >
                                    {e?.key}
                                  </Typography>
                                  <CurrencyRupeeIcon
                                    sx={{
                                      fontWeight: "500",
                                      fontSize: "18px",
                                      lineHeight: "27px",
                                      marginTop: "3px",
                                    }}
                                  />
                                  <Typography>{e?.value}</Typography>
                                </Stack>
                              );
                            })}
                          </Box>
                          <Box sx={{ margin: "20px" }}>
                            <Stack direction="row" sx={{}}>
                              <Typography
                                sx={{
                                  width: "50%",
                                  fontFamily: "Poppins",
                                  fontStyle: "normal",
                                  fontWeight: "500",
                                  fontSize: "18px",
                                  lineHeight: "27px",
                                  color: "#FF6140",
                                }}
                              >
                                Section Cost
                              </Typography>
                              <CurrencyRupeeIcon
                                sx={{
                                  fontWeight: "500",
                                  fontSize: "18px",
                                  lineHeight: "27px",
                                  marginTop: "3px",
                                }}
                              />
                              <Typography>{ele?.sectioncost}</Typography>
                            </Stack>
                          </Box>
                        </Box>
                      );
                    })}
                  </Stack>
                </Box>

                <Box
                  sx={{
                    background:
                      "linear-gradient(90deg, #0F5DAE 0%, #009DCF 100.33%)",
                    borderRadius: "4px",
                    height: "40px",
                    alignItems: "center",
                    display: "flex",
                    width: "20%",
                    borderRadius: "0px 100px 0px 0px",
                    margin: "20px 20px 20px 0px",
                  }}
                >
                  <Typography
                    sx={{
                      fontFamily: "Product Sans",
                      fontStyle: "normal",
                      fontWeight: "500",
                      fontSize: "18px",
                      lineHeight: "20px",
                      color: "#FFFFFF",
                      margin: "0px 20px 0px 20px",
                    }}
                  >
                    Adjustment
                  </Typography>
                </Box>

                <Box>
                  <Stack
                    direction="row"
                    justifyContent="start"
                    alignItems="center"
                    sx={{ margin: "20px 0px 20px -10px" }}
                  >
                    <Radio
                      required
                      name="search_type"
                      {...controlPropsAdjustment("flatDiscount")}
                      sx={{
                        color:
                          "linear-gradient(264.48deg, #79F1A4 -45.37%, #0E5CAD 88.97%)",
                        color: "#0F5DAE",
                      }}
                    />
                    <Typography
                      sx={{
                        fontFamily: "Product Sans",
                        fontStyle: "normal",
                        fontWeight: "500",
                        fontSize: "18px",
                        lineHeight: "21px",
                        color: "#000000",
                        marginRight: "20px",
                      }}
                    >
                      Flat Discount
                    </Typography>
                    <Radio
                      required
                      name="search_type"
                      {...controlPropsAdjustment("percentDiscount")}
                      sx={{
                        color:
                          "linear-gradient(264.48deg, #79F1A4 -45.37%, #0E5CAD 88.97%)",
                        color: "#0F5DAE",
                      }}
                    />
                    <Typography
                      sx={{
                        fontFamily: "Product Sans",
                        fontStyle: "normal",
                        fontWeight: "500",
                        fontSize: "18px",
                        lineHeight: "21px",
                        color: "#000000",
                      }}
                    >
                      Percentage Discount
                    </Typography>
                  </Stack>
                </Box>
                {selectedAdjustment == "flatDiscount" ? (
                  <Stack
                    direction="row"
                    justifyContent="start"
                    alignItems="center"
                    sx={{ margin: "30px 0px 0px 0px", fontSize: "18px" }}
                  >
                    <Typography
                      component="span"
                      className="field-name"
                      sx={{
                        width: "15%",
                        fontFamily: "Product Sans",
                        fontStyle: "normal",
                        fontWeight: "500",
                        fontSize: "18px",
                        lineHeight: "21px",
                        color: "#000000",
                      }}
                    >
                      Flat Discount
                    </Typography>
                    <CurrencyRupeeIcon
                      sx={{ color: "#000000", marginLeft: "0px" }}
                    />
                    <TextField
                      onChange={grandTotalSetting}
                      value={discount}
                      type="number"
                      inputProps={{ min: 0, max: { unchangedTotal } }}
                      sx={{
                        width: "188px",
                        marginLeft: "16px",
                      }}
                    />
                  </Stack>
                ) : (
                  <Stack
                    direction="row"
                    justifyContent="start"
                    alignItems="center"
                    sx={{ margin: "30px 0px 0px 0px", fontSize: "18px" }}
                  >
                    <Typography
                      component="span"
                      className="field-name"
                      sx={{
                        width: "15%",
                        fontFamily: "Product Sans",
                        fontStyle: "normal",
                        fontWeight: "500",
                        fontSize: "18px",
                        lineHeight: "21px",
                        color: "#000000",
                      }}
                    >
                      Percentage Discount
                    </Typography>
                    <TextField
                      onChange={grandTotalSetting}
                      value={discount}
                      type="number"
                      inputProps={{ min: 0, max: 100 }}
                      sx={{
                        width: "188px",
                        marginLeft: "40px",
                      }}
                    />
                    <PercentIcon
                      sx={{ color: "#000000", marginLeft: "10px" }}
                    />
                  </Stack>
                )}

                <Stack
                  direction="row"
                  justifyContent="start"
                  alignItems="center"
                  sx={{ margin: "20px 0px 0px 0px", fontSize: "18px" }}
                >
                  <Typography
                    component="span"
                    className="field-name"
                    sx={{
                      width: "15%",
                      fontFamily: "Product Sans",
                      fontStyle: "normal",
                      fontWeight: "500",
                      fontSize: "18px",
                      lineHeight: "21px",
                      color: "#000000",
                    }}
                  >
                    Valid Till
                  </Typography>
                  <TextField
                    onChange={(e) => {
                      setDate(e.target.value);
                    }}
                    value={date}
                    type="Date"
                    sx={{
                      width: "188px",
                      marginLeft: "40px",
                    }}
                  />
                </Stack>

                <Box sx={{ margin: "40px 20px 20px 0px", width: "50%" }}>
                  <Stack direction="row" sx={{}}>
                    <Typography
                      sx={{
                        width: "41%",
                        fontFamily: "Poppins",
                        fontStyle: "normal",
                        fontWeight: "500",
                        fontSize: "18px",
                        lineHeight: "27px",
                        color: "#FF6140",
                      }}
                    >
                      Grand Total
                    </Typography>
                    <CurrencyRupeeIcon
                      sx={{
                        fontWeight: "500",
                        fontSize: "18px",
                        lineHeight: "27px",
                        marginTop: "3px",
                      }}
                    />
                    <Typography>{grandTotal}</Typography>
                  </Stack>
                </Box>

                <Button
                  variant="contained"
                  sx={{
                    background: "#0F5DAE",
                    border: "1px solid #EFF0F7",
                    boxShadow: "0px 2px 6px rgba(19, 18, 66, 0.07)",
                    borderRadius: "4px",
                    border: "1px solid #0F5DAE",
                    margin: "20px 0px",
                  }}
                  onClick={onSubmit}
                >
                  {loadingSubmit ? (
                    <CircularProgress
                      color="inherit"
                      sx={{ padding: "0px 20px" }}
                    />
                  ) : (
                    <Box
                      sx={{ textTransform: "capitalize", margin: "0px 7px" }}
                    >
                      Submit
                    </Box>
                  )}
                  {/*  */}
                </Button>

                {/* <ChildModal /> */}
                {/* Child Modal for Creating Lead */}
                <Modal
                  hideBackdrop
                  open={openChildLead}
                  onClose={handleCloseChildLead}
                  aria-labelledby="child-modal-title"
                  aria-describedby="child-modal-description"
                >
                  <Box
                    sx={{
                      position: "absolute",
                      top: "55%",
                      left: "50%",
                      transform: "translate(-50%, -50%)",
                      width: "30%",
                      height: "60%",
                      bgcolor: "white",
                      border: "2px solid white",
                      borderRadius: "10px",
                      boxShadow: 24,
                      pt: 3,
                      px: 3,
                      pb: 1.5,
                      overflow: "scroll",
                      whiteSpace: "nowrap",
                      overflowX: "hidden",
                      textAlign: "",
                    }}
                  >
                    <Stack
                      direction="row"
                      sx={{ justifyContent: "space-between" }}
                    >
                      <Typography
                        sx={{
                          fontFamily: "Product Sans",
                          fontStyle: "normal",
                          fontWeight: "600",
                          fontSize: "20px",
                          lineHeight: "21px",
                          color: "#000000",
                        }}
                      >
                        Create New Lead
                      </Typography>
                      <Box sx={{ textAlign: "right" }}>
                        <GridCloseIcon
                          sx={{ color: "gray", cursor: "pointer" }}
                          onClick={() => {
                            handleCloseChildLead();
                            setCreateLeadEmail("");
                            setCreateLeadName("");
                            setCreateLeadPhone("");
                            setCreateLeadPriority("");
                          }}
                        />
                      </Box>
                    </Stack>

                    <Stack
                      direction="column"
                      justifyContent="start"
                      sx={{ margin: "20px 0px 20px 0px", fontSize: "18px" }}
                    >
                      <Typography
                        component="span"
                        className=""
                        sx={{
                          width: "35%",
                          fontFamily: "Product Sans",
                          fontStyle: "normal",
                          fontWeight: "500",
                          fontSize: "18px",
                          lineHeight: "21px",
                          color: "#000000",
                        }}
                      >
                        Name
                      </Typography>
                      <TextField
                        onChange={(e) => {
                          setCreateLeadName(e.target.value);
                        }}
                        value={createLeadName}
                        sx={{
                          width: "100%",
                          margin: "10px 0px",
                        }}
                      />
                    </Stack>

                    <Stack
                      direction="column"
                      justifyContent="start"
                      sx={{ margin: "20px 0px 20px 0px", fontSize: "18px" }}
                    >
                      <Typography
                        component="span"
                        className="field-name"
                        sx={{
                          width: "35%",
                          fontFamily: "Product Sans",
                          fontStyle: "normal",
                          fontWeight: "500",
                          fontSize: "18px",
                          lineHeight: "21px",
                          color: "#000000",
                        }}
                      >
                        Phone
                      </Typography>
                      <TextField
                        onChange={(e) => {
                          setCreateLeadPhone(e.target.value);
                        }}
                        placeholder="+91 9999999999"
                        value={createLeadPhone}
                        sx={{
                          width: "100%",
                          margin: "10px 0px",
                        }}
                      />
                    </Stack>

                    <Stack
                      direction="column"
                      justifyContent="start"
                      sx={{ margin: "20px 0px 20px 0px", fontSize: "18px" }}
                    >
                      <Typography
                        component="span"
                        className="field-name"
                        sx={{
                          width: "35%",
                          fontFamily: "Product Sans",
                          fontStyle: "normal",
                          fontWeight: "500",
                          fontSize: "18px",
                          lineHeight: "21px",
                          color: "#000000",
                        }}
                      >
                        Email
                      </Typography>
                      <TextField
                        onChange={(e) => {
                          setCreateLeadEmail(e.target.value);
                        }}
                        value={createLeadEmail}
                        sx={{
                          width: "100%",
                          margin: "10px 0px",
                        }}
                      />
                    </Stack>

                    <Stack
                      direction="column"
                      justifyContent="start"
                      sx={{ margin: "20px 0px 20px 0px", fontSize: "18px" }}
                    >
                      <Typography
                        component="span"
                        className="field-name"
                        sx={{
                          width: "35%",
                          fontFamily: "Product Sans",
                          fontStyle: "normal",
                          fontWeight: "500",
                          fontSize: "18px",
                          lineHeight: "21px",
                          color: "#000000",
                        }}
                      >
                        Priority
                      </Typography>
                      <TextField
                        id="outlined-select-currency"
                        select
                        value={createLeadPriority}
                        onChange={(e) => {
                          setCreateLeadPriority(e.target.value);
                        }}
                        sx={{ width: "100%", margin: "10px 0px" }}
                      >
                        {allpriority &&
                          allpriority.map((option) => (
                            <MenuItem key={option.id} value={option.id}>
                              {option.name}
                            </MenuItem>
                          ))}
                      </TextField>
                    </Stack>

                    <Box sx={{ textAlign: "center" }}>
                      <Button
                        variant="contained"
                        sx={{
                          background: "#0F5DAE",
                          border: "1px solid #EFF0F7",
                          boxShadow: "0px 2px 6px rgba(19, 18, 66, 0.07)",
                          borderRadius: "4px",
                          border: "1px solid #0F5DAE",
                          margin: "15px 0px 30px 0px",
                        }}
                      >
                        <Box
                          sx={{
                            textTransform: "capitalize",
                            margin: "0px 7px",
                          }}
                          onClick={createNewLead}
                        >
                          Submit
                        </Box>
                      </Button>
                    </Box>
                  </Box>
                </Modal>
              </Box>
            </Modal>

            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              sx={{ height: "68px", marginBottom: "20px" }}
            >
              <Stack direction="column" alignItems="center">
                <Typography
                  sx={{
                    fontSize: "22px",
                    fontWeight: "600",
                    marginLeft: "0px",
                    color: "   #2979F2",
                  }}
                >
                  {`Unit No ${UnitData[0]?.id} Overview`}
                </Typography>
                <Typography
                  sx={{
                    color: "#B9B7D0",
                    fontSize: "12px",
                    fontWeight: "400",
                    paddingLeft: "20px",
                  }}
                >
                  Here is your Unit Overview Data
                </Typography>
              </Stack>
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
              >
                {" "}
                <Stack direction="row" sx={{ margin: "20px" }}>
                  <Button
                    className="univercel_btn_class"
                    sx={{
                      color: "#FFFFFF",
                      textTransform: "none",
                      width: "200px",
                      background:
                        "linear-gradient(90deg, #0F5DAE 0%, #009DCF 100.33%)",
                      margin: "0 10px",
                    }}
                    onClick={handleOpen}
                  >
                    Create Quotation
                  </Button>
                  <Button
                    className="univercel_btn_class"
                    sx={{
                      color: "#FFFFFF",
                      textTransform: "none",
                      width: "200px",
                      background:
                        "linear-gradient(90deg, #0F5DAE 0%, #009DCF 100.33%)",
                      margin: "0 10px",
                    }}
                    onClick={handleOpenBuyerFile}
                  >
                    Booking Request File
                  </Button>
                  {/* <Button
                  className="univercel_btn_class"
                  sx={{
                    color: "#FFFFFF",
                    textTransform: "none",
                    background:
                      "linear-gradient(90deg, #0F5DAE 0%, #009DCF 100.33%)",
                    margin: "0 10px",
                    width: "200px",
                  }}
                  // onClick={toggleDrawer(true)}
                >
                  Generate Buyer File
                </Button> */}
                </Stack>
              </Stack>
            </Stack>

            {/* ================filters and all=============================== */}
            <Box sx={{ color: "black" }}>
              <Box sx={{ display: "flex" }}>
                {UnitData && <ImageGrid image={images} />}
                <Box sx={{ width: "100%" }}>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      width: "100%",
                      padding: "20px",
                    }}
                  >
                    <Box>
                      <Typography sx={{ fontSize: "20px", fontWeight: "600" }}>
                        Unit Overview{" "}
                      </Typography>
                      <Typography>{UnitData[0]?.name}</Typography>
                    </Box>
                    {/* {UnitData[0]?.is_booked==true?<Box>Status : Booked</Box>:<Box>Status : Available</Box>} */}
                    <Box>{`Status : ${unitStatus}`}</Box>
                  </Box>

                  {/*==============================start Showing Unit Data========================== */}
                  <Box
                    sx={{
                      display: "grid",
                      gridTemplateColumns: "1fr 1fr 1fr",
                      rowGap: "30px",
                      padding: "20px",
                    }}
                  >
                    {UnitData[0]?.layout_data?.singleData.map((item, idx) => {
                      return (
                        <Box>
                          <Box>{`${item.value}    ${item.type}`}</Box>
                          <Box
                            sx={{
                              color: "#A2A3A6",
                              fontFamily: "Poppins",
                              fontStyle: "normal",
                              fontWeight: "500",
                              fontSize: "16px",
                              lineHeight: "18px",
                              marginTop: "10px",
                            }}
                          >
                            {item.label}
                          </Box>
                        </Box>
                      );
                    })}
                  </Box>

                  <Box
                    sx={{
                      display: "grid",
                      gridTemplateColumns: "1fr 1fr 1fr",
                      rowGap: "30px",
                      padding: "20px",
                    }}
                  >
                    {UnitData[0]?.layout_data?.doubleData.map((item, idx) => {
                      return (
                        <Box>
                          <Stack direction="row">
                            <Box>{`${item.value1}    ${item.type}`}</Box>
                            <Box sx={{ margin: "0px 10px 0px 10px" }}> X </Box>
                            <Box>{`${item.value1}    ${item.type}`}</Box>
                          </Stack>
                          <Box
                            sx={{
                              color: "#A2A3A6",
                              fontFamily: "Poppins",
                              fontStyle: "normal",
                              fontWeight: "500",
                              fontSize: "16px",
                              lineHeight: "18px",
                              marginTop: "10px",
                            }}
                          >
                            {item.label}
                          </Box>
                        </Box>
                      );
                    })}
                  </Box>

                  <Box
                    sx={{
                      display: "grid",
                      gridTemplateColumns: "1fr 1fr 1fr",
                      rowGap: "30px",
                      padding: "20px",
                    }}
                  >
                    {UnitData[0]?.layout_data?.bedroomData.map((item, idx) => {
                      return (
                        <Box>
                          <Stack direction="row">
                            <Box>{`${item.length}  (L)`}</Box>
                            <Box sx={{ margin: "0px 10px 0px 10px" }}> X </Box>
                            <Box>{`${item.breadth}  (B)`}</Box>
                          </Stack>
                          <Box
                            sx={{
                              color: "#A2A3A6",
                              fontFamily: "Poppins",
                              fontStyle: "normal",
                              fontWeight: "500",
                              fontSize: "16px",
                              lineHeight: "18px",
                              marginTop: "10px",
                            }}
                          >{`Bedroom No. ${item.bedroom_no}`}</Box>
                        </Box>
                      );
                    })}
                  </Box>

                  <Box
                    sx={{
                      display: "grid",
                      gridTemplateColumns: "1fr 1fr 1fr",
                      rowGap: "30px",
                      padding: "20px",
                    }}
                  >
                    {UnitData[0]?.layout_data?.bathroomData.map((item, idx) => {
                      return (
                        <Box>
                          <Stack direction="row">
                            <Box>{`${item.length}  (L)`}</Box>
                            <Box sx={{ margin: "0px 10px 0px 10px" }}> X </Box>
                            <Box>{`${item.breadth}  (B)`}</Box>
                          </Stack>
                          <Box
                            sx={{
                              color: "#A2A3A6",
                              fontFamily: "Poppins",
                              fontStyle: "normal",
                              fontWeight: "500",
                              fontSize: "16px",
                              lineHeight: "18px",
                              marginTop: "10px",
                            }}
                          >{`Bathroom No. ${item.bathroom_no}`}</Box>
                        </Box>
                      );
                    })}
                  </Box>

                  <Box
                    sx={{
                      display: "grid",
                      gridTemplateColumns: "1fr 1fr 1fr",
                      rowGap: "30px",
                      padding: "20px",
                    }}
                  >
                    {UnitData[0]?.layout_data?.balconyData.map((item, idx) => {
                      return (
                        <Box>
                          <Stack direction="row">
                            <Box>{`${item.length}  (L)`}</Box>
                            <Box sx={{ margin: "0px 10px 0px 10px" }}> X </Box>
                            <Box>{`${item.breadth}  (B)`}</Box>
                          </Stack>
                          <Box
                            sx={{
                              color: "#A2A3A6",
                              fontFamily: "Poppins",
                              fontStyle: "normal",
                              fontWeight: "500",
                              fontSize: "16px",
                              lineHeight: "18px",
                              marginTop: "10px",
                            }}
                          >{`Balcony No. ${item.balcony_no}`}</Box>
                        </Box>
                      );
                    })}
                  </Box>
                </Box>
              </Box>
              <Box
                sx={{
                  margin: "20px",
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <Box sx={{ width: "100%" }}>
                  <Box
                    sx={{
                      margin: "10px",
                      marginBottom: "30px",
                      fontSize: "22px",
                    }}
                  >
                    Pricing Details
                  </Box>

                  {Array.isArray(UnitData[0]?.charges_data) == true
                    ? UnitData[0]?.charges_data?.map((item, idx) => {
                        return (
                          <>
                            <Box
                              sx={{
                                color: "black",
                                fontFamily: "Poppins",
                                fontStyle: "normal",
                                fontWeight: "600",
                                fontSize: "20px",
                                lineHeight: "18px",
                                margin: "10px",
                              }}
                            >
                              {item?.titlename}
                            </Box>
                            <Box
                              sx={{
                                display: "grid",
                                gridTemplateColumns: "1fr 1fr 1fr",
                                rowGap: "30px",
                                padding: "20px",
                              }}
                            >
                              {item?.field?.map((ele, idx) => {
                                return (
                                  <Box>
                                    <Stack direction="row">
                                      <CurrencyRupeeIcon
                                        sx={{
                                          color: "#A2A3A6",
                                          fontSize: "18px",
                                          marginTop: "2px",
                                        }}
                                      />
                                      <Box>{`${ele.value}`}</Box>
                                    </Stack>
                                    <Box
                                      sx={{
                                        color: "#A2A3A6",
                                        fontFamily: "Poppins",
                                        fontStyle: "normal",
                                        fontWeight: "500",
                                        fontSize: "16px",
                                        lineHeight: "18px",
                                        marginTop: "10px",
                                      }}
                                    >{`${ele.key}`}</Box>
                                  </Box>
                                );
                              })}
                            </Box>
                            <Box sx={{ margin: "20px 0px 30px 10px" }}>
                              <Stack direction="row" sx={{}}>
                                <CurrencyRupeeIcon
                                  sx={{
                                    color: "#A2A3A6",
                                    fontSize: "18px",
                                    marginTop: "2px",
                                  }}
                                />
                                <Box>{`${item.sectioncost}`}</Box>
                              </Stack>
                              <Box
                                sx={{
                                  color: "#FF6140",
                                  fontFamily: "Poppins",
                                  fontStyle: "normal",
                                  fontWeight: "600",
                                  fontSize: "18px",
                                  lineHeight: "20px",
                                  marginTop: "10px",
                                }}
                              >{`Total ${item.titlename} Amount`}</Box>
                            </Box>
                          </>
                        );
                      })
                    : null}
                </Box>

                <Box sx={{ width: "100%" }}>
                  <Box
                    sx={{
                      // background: "gray",
                      // height: "450px",
                      width: "100%",
                      // margin: "10px",
                      // margin: "20px",
                      // border:"1px solid red",
                      justifyContent: "right",
                      display: "flex",
                    }}
                  >
                    {" "}
                    {UnitData && <ImageGrid image={floorplan} />}
                  </Box>
                </Box>
              </Box>

              <Box>
                <Button
                  sx={{
                    color: "#FFFFFF",
                    textTransform: "none",
                    width: "500px",
                    background:
                      "linear-gradient(90deg, #0F5DAE 0%, #009DCF 100.33%)",
                    margin: "0 10px",
                  }}
                >
                  {`Grand Total Amount : ${TotalAmount}`}
                </Button>
              </Box>
            </Box>
          </Stack>
        </Stack>
      </Stack>
    </>
  );
};
