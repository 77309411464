import { Box, Button, Modal, Stack, TextField, Typography } from "@mui/material"
import axios from "axios";
import { useContext, useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { SuperAdminNavbar } from "../SidebarNavbar/SuperAdminNavbar"
import { SuperAdminSidebar } from "../SidebarNavbar/SuperAdminSidebar"
import currentProjectState from "../../../context/createproject/context";
import { Apiaddress } from "../../../utils/consts";
import { ReactComponent as Download } from "./download.svg";
import { ReactComponent as Upload } from "./upload.svg";
import ErrorDialogSA from "./ErrorDialogSA";
import { SAProjectSidebar } from "../SidebarNavbar/SAProjectSidebar";
export const CSVuploadSA = ()=>{

   const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [errorMsg,seterrorMsg]=useState("");

  const navigate = useNavigate();
  const location = useLocation();
  const config = location.state;
  const cpContext = useContext(currentProjectState);
  const projectID = cpContext?.createProjectState?.project?.id;
  console.log("ProjectId", projectID);
  const layoutInputRef = useRef();
  const pricingInputRef = useRef();
  const projectURL = Apiaddress + "/api/projects/projectdetails/?project_id=" + projectID;
  const csvprojectcreation = Apiaddress + "/api/projects/csvprojectcreation/";
  const buildingSet = new Set();


  const [unitLayoutHeaders, setunitLayoutHeaders] = useState([
    "Building_ID/number",
    "Wing_ID/number",
    "Floor_ID/number",
    "Unit_ID/number",
  ])

  // const unitLayoutHeaders = [
  //   "Building_ID/number",
  //   "Wing_ID/number",
  //   "Floor_ID/number",
  //   "Unit_ID/number",
  // ];
  const [unitPricingHeaders, setunitPricingHeaders] = useState([
    "Building_ID/number",
    "Wing_ID/number",
    "Floor_ID/number",
    "Unit_ID/number",
  ])
  // const unitPricingHeaders = [
  //   "Building_ID/number",
  //   "Wing_ID/number",
  //   "Floor_ID/number",
  //   "Unit_ID/number",
  // ];
  let time = new Date().getTime(); // some unique value
  //   console.log(config);
  const [errorOpen, setErrorOpen] = useState(false);
  const [errorData, setErrorData] = useState([]);
  const [errorType, setErrorType] = useState("");
  const [filesData, setFilesData] = useState({
    pricing: "",
    layout: "",
  });
  const [csvData, setCSVData] = useState({
    pricing: [],
    layout: [],
  });
  const [validState, setValidState] = useState({
    isLayoutValid: null,
    isPricingValid: null,
  });

  const checkValidity=(event)=>{
    //  layoutInputRef.current.click()
    //  pricingInputRef.current.click()
     let obj=validState;
     if(event=="unit"){
       layoutInputRef.current.click()
       obj.isLayoutValid=null;
     }
     else if(event=="price"){
      pricingInputRef.current.click()
      obj.isPricingValid=null;
     }
     setValidState(obj);
  }

  const generateAndDownloadCSVUnit = (data) => {
    console.log(data);
    data.push("\n");
    const csv = data.join(",");
    const href = "data:text/csv;charset=utf-8," + encodeURI(csv);
    window.open(href, "_blank");
  };

  const generateAndDownloadCSVPricing = (data) => {
    console.log(data);
    data.push("\n");
    const csv = data.join(",");
    const href = "data:text/csv;charset=utf-8," + encodeURI(csv);
    window.open(href, "_blank");
  };

  const handelFile = (e, type) => {
    if (!e.target.value.endsWith(".csv")) {
      e.target.value = "";
      alert("File is not a CSV!");
      return;
    }
    if (type == "pricing") {
      console.log("pricing called");
      setFilesData((prev) => ({ ...prev, pricing: e.target.files[0] }));
    } else if (type == "layout") {
      console.log("layout called");
      setFilesData((prev) => ({ ...prev, layout: e.target.files[0] }));
    }
  };

  const addDataToSet = (layoutData) => {
    layoutData.forEach((row, id) => {
      console.log(row["Building_ID/number"]);
      // if (id == 0) return;
      buildingSet.add(row["Building_ID/number"]);
    });
  };
  
  //  console.log("header", unitLayoutHeaders);
  //  console.log("pricing", unitPricingHeaders);
  const validateCSVFile = async (file, type) => {
    
    // let data = [];
    if (!file) {
      // alert("File not found!");
      seterrorMsg("File not found!");
      handleOpen(); 
      return;
    }
    let text = await file.text();
    let data = text.split("\n").map((rows) => rows.split(","));
    let n = data.length;

    let jsonData = [];
    let count = 0;
    console.log(data);
    // let skipIndex = config.hasWing ? 4 : 3;
    for (let i = 1; i < n; i++) {
      let temp = {};
      let hasError = false;
      if (data[i][0] === "" && data[i].length == 1) continue; // handles a weird bug
      data[i].forEach((header, id) => {
        // if (id < skipIndex) return;
        let tdata = Number(data[i][id]);
        if (data[i][id] === "" || isNaN(tdata) || tdata < 0) {
          hasError = true;
          count++;
        }
        temp[data[0][id]] = data[i][id];
      });
      if (hasError) {
        setErrorData((prev) => prev.concat({ index: i - 1, rowData: temp }));
      }
      jsonData.push(temp);
    }
    if (count != 0) {
      setErrorOpen(true);
      setErrorType(type);
    }
    if (type == "pricing") {
      setCSVData((prev) => ({ ...prev, pricing: jsonData }));

      setValidState((prev) => ({ ...prev, isPricingValid: count === 0 }));
    } else if (type == "layout") {
      setCSVData((prev) => ({ ...prev, layout: jsonData }));
      setValidState((prev) => ({ ...prev, isLayoutValid: count === 0 }));
    }
    console.log(count, jsonData);
  };

  const callAPI = async () => {

    if(!validState.isLayoutValid){
      // alert("Please Upload and validate Unit layout CSV!");
      seterrorMsg("Please Upload and validate Unit layout CSV!")
      handleOpen();
      return;
    }
    else if(!validState.isPricingValid){
      // alert("Please Upload and validate Pricing layout CSV!");
      seterrorMsg("Please Upload and validate Pricing layout CSV!")
      handleOpen();
      return;
    }

    console.log(csvData);
    addDataToSet(csvData.layout);
    console.log("buildingSet", buildingSet);
    let buildings = [];
    if (config.hasWing) {
      buildingSet.forEach((buildingId) => {
        let buildingInfo = {
          buildingId: buildingId,
        };
        let buildingUnits = csvData.layout.filter((row) => row["Building_ID/number"] == buildingId);
        let wingSet = new Set();
        let wings = [];
        buildingUnits.forEach((unit) => wingSet.add(unit["Wing_ID/number"]));
        wingSet.forEach((wingId) => {
          let wingInfo = {
            wingId: wingId,
          };
          let wingUnits = buildingUnits.filter((unit) => unit["Wing_ID/number"] == wingId);
          let floorSet = new Set();
          let floors = [];
          wingUnits.forEach((unit) => floorSet.add(unit["Floor_ID/number"]));
          floorSet.forEach((floorId) => {
            let floorInfo = {
              floorId: floorId,
            };
            let units = wingUnits.filter((unit) => unit["Floor_ID/number"] == floorId);
            // maupulate unit values here
            // console.log("units", wingUnits);
            floorInfo.units = convertUnitValue(units, {
              buildingId: buildingId,
              floorId: floorId,
              wingId: wingId,
            });
            floors.push(floorInfo);
          });
          wingInfo.floors = floors;
          wings.push(wingInfo);
        });
        buildingInfo.wings = wings;
        buildings.push(buildingInfo);
      });
      console.log(buildings);
      console.log(JSON.stringify(buildings));
    } else {
      buildingSet.forEach((buildingId) => {
        let buildingInfo = {
          buildingId: buildingId,
        };
        let buildingUnits = csvData.layout.filter((row) => row["Building_ID/number"] == buildingId);
        let floorSet = new Set();
        let floors = [];
        buildingUnits.forEach((unit) => floorSet.add(unit["Floor_ID/number"]));
        floorSet.forEach((floorId) => {
          let floorInfo = {
            floorId: floorId,
          };
          let units = buildingUnits.filter((unit) => unit["Floor_ID/number"] == floorId);
          // maupulate unit values here
          // console.log("units", wingUnits);
          floorInfo.units = convertUnitValue(units, {
            buildingId: buildingId,
            floorId: floorId,
          });
          floors.push(floorInfo);
        });
        buildingInfo.floors = floors;
        buildings.push(buildingInfo);
      });
      console.log(buildings);
    }

    //call the api

    axios
      .post(csvprojectcreation, { projectID: projectID, buildings: buildings })
      .then((res) => {
        console.log("API RESPONSE", res);
        navigate("/super-admin-confirm-project");
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const convertUnitValue = (units, config) => {
    const { buildingId, wingId, floorId } = config;
    let parsedUnit = units.map((unit, id) => {
      let unitId = unit["Unit_ID/number"];
      let pricingUnit = csvData.pricing.find(
        (unit) =>
          unit["Building_ID/number"] == buildingId &&
          unit["Wing_ID/number"] == wingId &&
          unit["Floor_ID/number"] == floorId &&
          unit["Unit_ID/number"] == unitId
      );
      delete pricingUnit["Building_ID/number"];
      delete pricingUnit["Wing_ID/number"];
      delete pricingUnit["Floor_ID/number"];
      delete pricingUnit["Unit_ID/number"];
      delete unit["Building_ID/number"];
      delete unit["Wing_ID/number"];
      delete unit["Floor_ID/number"];
      let singleData = [];
      let doubleData = [];
      let bedroomData = [];
      let bathroomData = [];
      let balconyData = [];
      let chargesData = []; //pricing data
      let bedroomCount = 0;
      let bathroomCount = 0;
      let balconyCount = 0;
      let keys = Object.keys(unit);
      // console.log("keys", keys);
      for (let i = 0; i < keys.length; i++) {
        let value = keys[i];
        // console.log("value", value);
        let dataArr = value.split("/");
        if (dataArr.length == 1) {
          // bedroomData or bathroomData or balconyData
          if (dataArr[0].startsWith("Bedroom")) {
            bedroomCount++;
            let data = {
              bedroom_no: bedroomCount,
              length: unit[keys[i]],
              breadth: unit[keys[i + 1]],
            };
            i++;
            bedroomData.push(data);
          }
          if (dataArr[0].startsWith("Bathroom")) {
            bathroomCount++;
            let data = {
              bathroom_no: bathroomCount,
              length: unit[keys[i]],
              breadth: unit[keys[i + 1]],
            };
            i++;
            bathroomData.push(data);
          }
          if (dataArr[0].startsWith("Balcony")) {
            balconyCount++;
            let data = {
              balcony_no: balconyCount,
              length: unit[keys[i]],
              breadth: unit[keys[i + 1]],
            };
            i++;
            balconyData.push(data);
          }
        }
        if (dataArr.length == 2) {
          let data = {
            label: dataArr[0],
            type: dataArr[1],
            value: unit[value],
          };
          singleData.push(data);
          //singleData
        }
        if (dataArr.length == 3) {
          // doubleData
          let data = {
            label: dataArr[0],
            type: dataArr[2],
            value1: unit[keys[i]],
            value2: unit[keys[i + 1]],
          };
          i++;
          doubleData.push(data);
        }
      }
      console.log(pricingUnit);
      keys = Object.keys(pricingUnit); // fate ga
      let titleNames = {};
      let allData = keys.map((value) => {
        let dataArr = value.split("/");
        titleNames[dataArr[0]] = 0;
        let data = {
          titlename: dataArr[0],
          key: dataArr[1],
          value: csvData.pricing[id][value],
        };
        return data;
      });

      console.log("titleNames", titleNames);
      chargesData = Object.keys(titleNames).map((titleKeys, id) => {
        let fieldArr = allData.filter((ele) => ele.titlename == titleKeys);
        let sum = 0;
        fieldArr.forEach((val) => {
          sum += Number(val.value);
        });
        return {
          titlename: titleKeys,
          number: id + 1,
          sectioncost: sum,
          field: fieldArr,  
        };
      });
      let result = {
        single: singleData,
        double: doubleData,
        bedroomData: bedroomData,
        bathroomData: bathroomData,
        balconyData: balconyData,
      };

      console.log({ layoutData: result, chargesData: chargesData });
      return { layoutData: result, chargesData: chargesData };
      // console.log("single", singleData);
      // console.log("double", doubleData);
      // console.log(bedroomData);
      // console.log(bathroomData);
      // console.log(balconyData);
    });
    return parsedUnit;
  };

  useEffect(() => {
    let unitLH = []
    let unitPH = []
    axios
      .get(projectURL)
      .then((res) => {
        const data = res?.data;
        let layoutData = res.data.project?.unit_layout.layout_data;

        let pricingData = res.data.project?.unit_layout.pricing_data;
        console.log("Layoutdata", res.data.project?.unit_layout.layout_data);
        console.log("PricingData", res.data.project?.unit_layout.pricing_data);
        let n = layoutData?.single.length;
        for (let i = 0; i < n; i++) {
          unitLH.push(`${layoutData.single[i].label}/${layoutData.single[i].type}`);
        }
        n = layoutData?.double.length;
        for (let i = 0; i < n; i++) {
          unitLH.push(`${layoutData.double[i].label}/L/${layoutData.double[i].type}`);
          unitLH.push(`${layoutData.double[i].label}/B/${layoutData.double[i].type}`);
        }
        n = pricingData?.length;
        for (let i = 0; i < n; i++) {
          let titleName = pricingData[i].titlename;
          for (let j = 0; j < pricingData[i].field.length; j++) {
            unitPH.push(`${titleName}/${pricingData[i].field[j].key}`);
          }
        }

        if (config.maxBedroom > 0) {
          let maxBedroom = config.maxBedroom;
          for (let i = 0; i < maxBedroom; i++) {
            unitLH.push(`Bedroom${i + 1}(L)`);
            unitLH.push(`Bedroom${i + 1}(B)`);
          }
        }
        if (config.maxBathroom > 0) {
          let maxBathroom = config.maxBathroom;
          for (let i = 0; i < maxBathroom; i++) {
            unitLH.push(`Bathroom${i + 1}(L)`);
            unitLH.push(`Bathroom${i + 1}(B)`);
            unitLH.push(`Bathroom${i + 1}_Attach`);
          }
        }
        if (config.maxBalcony > 0) {
          let maxBalcony = config.maxBalcony;
          for (let i = 0; i < maxBalcony; i++) {
            unitLH.push(`Balcony${i + 1}(L)`);
            unitLH.push(`Balcony${i + 1}(B)`);
            unitLH.push(`Balcony${i + 1}_Attach`);
          }
        }
        if (!config.hasWing) {
          unitLH.splice(1, 1);
          unitPH.splice(1, 1);
        }
        console.log("header", unitLH);
        console.log("pricing", unitPH);

        setunitLayoutHeaders([...unitLayoutHeaders,...unitLH]);
        setunitPricingHeaders([...unitPricingHeaders,...unitPH]);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  console.log("===================>", unitLayoutHeaders)

  return (
    <>

    
<Stack direction="row" sx={{width:"100%",height:"auto" }}>
      {/* 1st stack */}
      <Stack sx={{minHeight:"100vh",width:"20%",boxSizing:"border-box",}}> {/*for project directory 20% and for sales dashboard 225*/}
          {/* <SuperAdminSidebar active={"Builder Directory"}/> */}
          <SAProjectSidebar active={"Project Dashboard"}/>
      </Stack>

     {/* 2nd stack */}
     <Stack direction="column" sx={{ backgroundColor:"#f9f9f9",width:"80%", padding:"25px",boxSizing:"border-box",}}  >  {/*  width for main sidebar 78px and for 80px*/}
        <SuperAdminNavbar />  {/*send props for get popup to verify otp from builder */}
      


          <Typography sx={{marginTop:"20px", fontSize:"28px", fontWeight:"600", marginLeft:"10px", marginBottom:"50px"}}>
            Create Project 
          </Typography>
      
    <ErrorDialogSA
            open={errorOpen}
            errorType={errorType}
            handleClose={() => setErrorOpen(false)}
            errorData={errorData}
            setErrorData={setErrorData}
            setCSVData={setCSVData}
            setValidState={setValidState}
          />
     {/* actual content  */}
    <Stack sx={{backgroundColor:"#f9f9f9"}}>  {/* mt:"28px", */}

    <Stack
            direction="column"
            sx={{ padding: "20px", backgroundColor: "#fff", marginRight: "0px" }}
          >
            <Stack direction="column" alignItems="flex-start">
              <Typography variant="h4">Please find the sample of CSV files</Typography>
              <Stack
                width={"100%"}
                padding="1em"
                direction="row"
                alignItems={"center"}
                spacing={"10px"}
              >
                <Stack direction="column" width="100%" alignItems="flex-start" spacing="1em">
                  <Stack direction="row" spacing={"1em"}>
                    <Typography color={"#FF6140"}>Download Sample Unit Layout CSV</Typography>
                    <div onClick={() => generateAndDownloadCSVUnit(unitLayoutHeaders)}>
                      <Download />
                    </div>
                  </Stack>
                  <input
                    type="file"
                    ref={layoutInputRef}
                    onChange={(e) => handelFile(e, "layout")}
                    style={{ display: "none" }}
                  />
                  <Stack
                    width="80%"
                    direction="column"
                    spacing="1em"
                    margin={"1em"}
                    marginTop="3em"
                    alignItems="center"
                    justifyContent={"center"}
                    height="175px"
                    // onClick={() => {layoutInputRef.current.click()}}
                    onClick={() => {checkValidity('unit')}}
                    onDragOver={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                    }}
                    onDragEnter={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                    }}
                    onDrop={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      // uploadCSV(e.dataTransfer.files[0]);
                    }}
                    sx={{
                      backgroundColor: "#F9F9FB",
                    }}
                  >
                    <Upload />
                    <Typography variant="subtitle1" color="#939CAD" fontSize={"15px"}>
                      Browse your files
                    </Typography>
                  </Stack>
                  <Typography color="#A2A3A6">Upload Unit layout CSV file</Typography>
                  <Typography color={validState.isLayoutValid ? "#5DFF5D" : "#5D5DFF"}>
                    {validState.isLayoutValid === null
                      ? ""
                      : validState.isLayoutValid
                      ? "Layout CSV is Valid"
                      : "Layout CSV is Invalid"}
                  </Typography>
                  <Stack direction="row" spacing="1em" sx={{alignItems:"center"}}>
                    <Typography width="5em">File Name</Typography>
                    <TextField disabled value={filesData.layout?.name} sx={{ width: "200px" }} />
                  </Stack>
                  <Stack direction="row" justifyContent={"center"} width={"100%"} spacing="1em">
                    {/* <Typography width="5em">Validate</Typography> */}
                    <Button
                      className="univercel_btn_class"
                      onClick={() =>{validateCSVFile(filesData.layout, "layout")}}
                    >
                      Validate
                    </Button>
                  </Stack>
                </Stack>
                <Stack direction="column" width="100%" alignItems="flex-start" spacing="1em">
                  <Stack direction="row" spacing={"1em"}>
                    <Typography color={"#FF6140"}>Download Sample Unit Pricing CSV</Typography>
                    <div onClick={() => generateAndDownloadCSVPricing(unitPricingHeaders)}>
                      <Download />
                    </div>
                  </Stack>
                  <input
                    type="file"
                    ref={pricingInputRef}
                    onChange={(e) => handelFile(e, "pricing")}
                    style={{ display: "none" }}
                  />

                  <Stack
                    width="80%"
                    direction="column"
                    spacing="1em"
                    margin={"1em"}
                    marginTop="3em"
                    alignItems="center"
                    justifyContent={"center"}
                    height="175px"
                    sx={{
                      backgroundColor: "#F9F9FB",
                    }}
                    // onClick={() => pricingInputRef.current.click()}
                    onClick={() => {checkValidity('price')}}
                  >
                    <Upload />
                    <Typography variant="subtitle1" color="#939CAD" fontSize={"15px"}>
                      Browse your files
                    </Typography>
                  </Stack>
                  <Typography color="#A2A3A6">Upload pricing layout CSV file</Typography>
                  <Typography color={validState.isPricingValid ? "#5DFF5D" : "#5D5DFF"}>
                    {validState.isPricingValid === null
                      ? ""
                      : validState.isPricingValid
                      ? "Pricing CSV is Valid"
                      : "Pricing CSV is Invalid"}
                  </Typography>
                  <Stack direction="row" spacing="1em" sx={{alignItems:"center"}}>
                    <Typography width="5em">File Name</Typography>
                    <TextField disabled value={filesData.pricing?.name} sx={{ width: "200px" }} />
                  </Stack>
                  <Stack direction="row" justifyContent={"center"} width={"100%"} spacing="1em">
                    {/* <Typography width="5em">Validate</Typography> */}
                    <Button
                      className="univercel_btn_class"
                      onClick={() => {validateCSVFile(filesData.pricing, "pricing")}}
                    >
                      Validate
                    </Button>
                  </Stack>
                </Stack>
              </Stack>
              <Stack direction="row" width="100%">
                <Button
                  className="univercel_btn_class"
                  sx={{ padding: "16px 40px 16px 40px", color: "#fff", marginRight: "auto" }}
                  onClick={callAPI}
                >
                  Confirm Project
                </Button>
                <Button
                  className="univercel_btn_class"
                  sx={{ padding: "16px 40px 16px 40px", color: "#fff" }}
                   onClick={()=>navigate(-1)}
                >
                  Previous
                </Button>
              </Stack>
            </Stack>
          </Stack>

    </Stack>
      </Stack>
</Stack>

    
    {/* Notification of the Modal */}
    <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={{position: 'absolute',
             top: '50%',
             left: '50%',
             transform: 'translate(-50%, -50%)',
             width: "20%",
             bgcolor: 'white',
             border: '2px solid white',
             borderRadius:"10px",
             boxShadow: 24,
             pt: 5,
             px: 3,
             pb: 5,
             textAlign:"center"
            }}>
          <Typography sx={{color:"#ff4500",fontWeight:"500",marginBottom:"20px",fontFamily:"Poppins",fontSize:"1.2rem"}}>{errorMsg}</Typography>
        </Box>
      </Modal>
    </>
  );

}