import React, { useContext, useEffect, useState } from 'react';
import { Box, Button, Stack, TextField, Typography } from "@mui/material";
import DoneIcon from '@mui/icons-material/Done';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import Pagination from '@mui/material/Pagination';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';


import { Link, useLocation, useNavigate } from "react-router-dom";

import currentProjectState from "../../../../context/createproject/context";
import axios from 'axios';
import { Apiaddress } from '../../../../utils/consts';
import { SuperAdminSidebar } from '../../SidebarNavbar/SuperAdminSidebar';
import { SuperAdminNavbar } from '../../SidebarNavbar/SuperAdminNavbar';
import { BackeToOverviewBtn } from '../../../BuilderComponents/ProjectDashboard/CreateProject/BackeToOverviewBtn';
import { ConfirmProject_steps } from '../../../BuilderComponents/ProjectDashboard/CreateProject/ConfirmProject_steps';
import { SAProjectSidebar } from '../../SidebarNavbar/SAProjectSidebar';

export const ConfirmProject_WingSA = ()=>{
     ////get data from previous page 
     const location = useLocation();
     let data = location.state;
     let [current_data, setCurrentData] = useState(data)
     console.log("preve data =>.>",data)
   
 
   const navigate = useNavigate();
 
    const [wingnum,setwingnum]=useState(1);
 
   const [wingdata,setwingdata]=useState(data?.wing_template? data.wing_template:[]);
   
 
 
    ////get data of buliding templates 
    const cpContext = useContext(currentProjectState);
    const projectID = cpContext?.createProjectState?.project?.id;
    useEffect(()=>{
      const buildingurl = Apiaddress+"/api/projects/buildingtemplates/?building_template_id="+data.id
       console.log(data);
      axios.get(buildingurl)
      .then((res)=>{
            const data = res?.data
            console.log("updated data=>>>>>>>.",data.building_template[0])
           setwingdata(data?.building_template[0]?.wing_template)
           setCurrentData(data?.building_template[0])
         })
         .catch((err)=>{
           console.log(err)
         })
  
    },[])
 
   
    return(
        <>
        <Stack direction="row" sx={{width:"100%",height:"auto" }}>
      {/* 1st stack */}
      <Stack sx={{minHeight:"100vh",width:"20%",boxSizing:"border-box",}}> {/*for project directory 20% and for sales dashboard 225*/}
        {/* <SuperAdminSidebar active={"Builder Directory"}/> */}
        <SAProjectSidebar active={"Project Dashboard"}/>
      </Stack>

     {/* 2nd stack */}
     <Stack direction="column" sx={{ backgroundColor:"#f9f9f9",width:"80%", padding:"25px",boxSizing:"border-box",}}  >  {/*  width for main sidebar 78px and for 80px*/}
      <SuperAdminNavbar />  {/*send props for get popup to verify otp from builder */}
      
      <Typography sx={{marginTop:"20px", fontSize:"28px", fontWeight:"600", marginLeft:"10px", marginBottom:"50px"}}>
          Wing Overview
            </Typography>
     {/* actual content  */}
    <Stack sx={{backgroundColor:"#f9f9f9"}}>  {/* mt:"28px", */}

    <Stack direction="column"  sx={{  padding:"20px", backgroundColor:"#fff", marginRight:"0px"}}>

{/* confirm project wings content */}
  

     <ConfirmProject_steps wing_name={data?.name}/>
     

    <Box sx={{}}>
      <Stack direction="row" sx={{margin:"0px 0px 0px 0px",display:"flex",alignItems:"center"}} >
        <Typography sx={{fontSize:"18px", fontWeight:"500"}}>Wings</Typography>
        <Link to="/super-admin-confirm-project-wing-add" state={current_data}>
        <AddIcon sx={{color:"#A2A3A6",fontSize:"18px",marginLeft:"10px",cursor:"pointer"}} 
        onClick={()=>{
          // navigate("/super-admin-confirm-project-wing-add");
        }}
        />
        </Link>
      </Stack> 

        <Stack spacing={2} sx={{margin:"20px 0px 0px 0px"}}>
          <Pagination count={wingdata.length} showFirstButton showLastButton onChange={(e,page)=>{setwingnum(page)}} />
        </Stack>
     
      <Stack direction="row" sx={{margin:"30px 0px 0px 0px"}} >
        <Typography sx={{fontSize:"18px", fontWeight:"500",color:"#A2A3A6"}}>Wing {wingnum}</Typography>

        <Link to="/super-admin-confirm-project-wing-details" state={{state:wingdata[wingnum-1],data:current_data}} >
        <EditIcon sx={{color:"#A2A3A6",marginLeft:"10px",cursor:"pointer"}} />
        </Link>

      </Stack>
      <Stack direction="row" sx={{display:"flex",alignItems:"center",margin:"20px 0px 0px 0px"}} >
        <Typography sx={{fontSize:"18px", fontWeight:"500",color:"#A2A3A6"}}>Wing Name</Typography>
        <TextField
           sx={{
               width:"188px",
               marginLeft:"10px"
              }} value={wingdata[wingnum-1]?.wing_name?wingdata[wingnum-1]?.wing_name:""} disabled/>
      </Stack>

      <Stack direction="row" sx={{alignItems:"center"}}>
      <Box sx={{display:"flex",alignItems:"center",marginRight:"150px",'&:hover': {
            cursor: 'pointer',
         }}} 
      onClick={()=>{
        navigate(-1)
      }}
      >
      <ArrowBackIcon/>
      <Typography sx={{fontSize:"18px", fontWeight:"500",margin:"0px 10px 0px 10px"}}>Back</Typography>
      </Box>
      <Box sx={{margin:"60px 0px 60px 0px"}}>

      <Link to={"/super-admin-confirm-project-wing-floor"} state={{state:wingdata[wingnum-1],building_name:data?.name}}>
          <Button 
          className="univercel_btn_class"
          variant="contained"
          sx={{padding:"16px 50px 16px 50px", color:"#fff",fontSize:"18px", fontWeight:"700"}}>
              Details 
          </Button>
        </Link>

          {/* <Button 
          className="univercel_btn_class"
          onClick={()=>{
            navigate("/confirm-project-wing-floors")
          }}
          variant="contained"
          sx={{padding:"16px 50px 16px 50px", color:"#fff",fontSize:"18px", fontWeight:"700"}}>
              Details 
          </Button> */}
      </Box>
      </Stack>
  </Box>

               

     </Stack>
  <BackeToOverviewBtn SuperAdmin={true}/>


    </Stack>
      </Stack>
</Stack>
        </>
    )
}