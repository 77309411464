import { Box, TextField, Typography } from '@mui/material'
import React, { useState } from 'react';
import { Stack } from '@mui/system';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import FileCopyRoundedIcon from '@mui/icons-material/FileCopyRounded';
import Button from "@mui/material/Button";
import Modal from '@mui/material/Modal';
import { useEffect } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';

function Section(props) {

    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: "350px",
        height:"250px",
        bgcolor: 'background.paper',
        boxShadow: 24, 
        borderRadius:"16px",
        p: 4
      };

    const [input,setinput]=useState({
        key:"",
        value:"",
        index:0
    });

  return (
    <div>
        <Stack direction="row" justifyContent="start" alignItems="center" sx={{margin:"30px 0px 0px 40px",fontSize: "20px",color:"#000000"}}>
            <Typography sx={{fontFamily:'Poppins',fontStyle:"normal",fontWeight:"500",fontSize: "23px",lineHeight:"20px",color:"#000000",textTransform:"uppercase"}}>{props.header.titlename}</Typography>
        </Stack>
        <Stack direction="row" justifyContent="start" alignItems="center" sx={{margin:"10px 0px 0px 35px",fontSize: "20px",color:"#000000"}}>
            <Button  sx={{fontFamily:'Poppins',fontStyle:"normal",fontWeight:"500",fontSize: "18px",lineHeight:"27px",color:"#2979F2"}} onClick={()=>{setOpen(true)}}>Add a new field<AddIcon/></Button>
        </Stack>

        <Modal
            open={open}  
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description">
           <Box sx={style}>
           <Stack direction="row" justifyContent="space-between" alignItems="center">
           <Typography id="modal-modal-title" variant="h6" component="h2" sx={{fontFamily:'Poppins',fontStyle:"normal",fontWeight:"500",fontSize: "18px",lineHeight:"27px",color:"#FF6140"}}>
            Add Field
           </Typography>
           <Box onClick={()=>{setOpen(false);setinput("")}} sx={{cursor:"pointer"}}><CloseIcon /></Box>
           </Stack>

           <Box sx={{alignItems:"center",margin:"30px 0px 0px 0px"}}>
            <Stack direction="row" justifyContent="start" alignItems="center" sx={{margin:"30px 0px 0px 20px",fontSize: "18px"}}>
                <Typography component="span" className='field-name'>Input Name</Typography>
                <TextField 
                 sx={{
                     width:"188px",
                     marginLeft:"40px"
                    }} value={input.titlename} onChange={(e)=>{setinput(
                        {
                            key:e.target.value,
                            value:"",
                            index:props.header.field.length+1,
                        }
                        )}}/>
            </Stack>
            </Box>
            <Button 
               type="submit"
                variant="contained"
                 color="primary" sx={{ height:"45px", background: "linear-gradient(90deg, #0F5DAE 0%, #009DCF 100.33%)",boxShadow: "0px 13.7981px 22.5788px rgba(50, 174, 137, 0.25)", textTransform: "none", borderRadius: "4px", width:"100px", fontSize:"15px",margin:"60px 0px 30px 120px"}} onClick={()=>{
                    if(!input){
                        return;
                    }
                    props.title.map((ele)=>{
                        if(ele.number==props.header.number){
                            let arr = props.title
                             arr[props.index].field = [...ele.field,input];
                        }
                    })
                    setOpen(false);
                    // {console.log(inputtitle)};
                    setinput("");
                }}>
                Done
        </Button>
        </Box>
      </Modal>

            <Box>
            {props.header.field.map((ele)=>{
                    return(
                        <Box sx={{display:"flex",alignItems:"center",margin:"20px 0px 0px 40px"}}>
                         <Box sx={{width:"30%",overflow:"hidden",wordWrap:"break-word"}}>
                        <Typography component="span" className='field-name' sx={{marginLeft:"0px"}}>{ele.key}</Typography>
                        </Box>
                        <CurrencyRupeeIcon sx={{color:"#A2A3A6",marginLeft:"40px",fontSize:"18px"}}/>
                        <TextField disabled
                        sx={{
                             width:"188px",
                             //marginLeft:"40px"
                            }}/>
                        <Box onClick={()=>{
                            const newinputtitle = props.header.field.filter((x) => x !== ele);
                            console.log(newinputtitle);
                            let arr = props.title;
                            arr[props.index].field = newinputtitle;
                            props.settitle(arr);
                            console.log(props.title);
                            props.setplank(!props.plank);
                            // setinputtitle([...newinputtitle]);
                            }}><DeleteIcon sx={{color:"#B0AFAF",width:"39px",height:"30px",marginLeft:"30px",cursor:"pointer"}}/>
                        </Box>
                        {/* <Box>
                            <FileCopyRoundedIcon sx={{color:"#B0AFAF",cursor:"pointer",marginLeft:"20px"}}/>
                        </Box> */}
                        </Box>
                    )
                })}                
            </Box>
      
    </div>
  )
}

export default Section