import { ParallaxProvider } from "react-scroll-parallax";
import CPContextState from "../Components/ChannelPartner/context/state";
import BuilderDashboardState from "./builder_dashboard/state";
import BuyerContextState from "./buyer/state";
import CreateProjectState from "./createproject/state";
import ProfileState from "./profile/state";

//register all the providers here
function mainProvider(props: any) {
  return (
    <ParallaxProvider>
      <ProfileState>
        <CreateProjectState>
          <BuilderDashboardState>
            <BuyerContextState>
              <CPContextState>{props.children}</CPContextState>
            </BuyerContextState>
          </BuilderDashboardState>
        </CreateProjectState>
      </ProfileState>
    </ParallaxProvider>
  );
}

export default mainProvider;
