import { Box } from "@mui/system";
import React, { useContext, useEffect, useState } from "react";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";

import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import SendIcon from "@mui/icons-material/Send";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import profileContext from "../../../context/profile/context";
import { Apiaddress } from "../../../utils/consts";

import {
  Button,
  Checkbox,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  Modal,
  OutlinedInput,
  Select,
  TextField,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";

import AddIcon from "@mui/icons-material/Add";
import axios from "axios";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const names = ["Aditya Birla Appartments", "Tata Farms"];

function RightSidebarCPDadd({
  setTabledata,
  curselectedRows,
  updateInfo,
  trigger,
  setTrigger,
  setState,
}) {
  const [expanded, setExpanded] = useState("panel1");
  const [disabelAccrodian, setdisabelAccrodian] = useState(
    curselectedRows ? "true" : "false"
  );
  const [selectValue, setSelectValue] = useState("High");
  const [setProjects, setsetProjects] = React.useState([]);

  const handleChangeLeadProject = (event) => {
    const {
      target: { value },
    } = event;
    setsetProjects(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };
  const [selectArr, setselectArr] = useState([
    {
      value: "High",
      label: "High",
    },
    {
      value: "low",
      label: "low",
    },
  ]);

  const createOpt = () => { };

  const handleChange = (i) => {
    setExpanded(i);
  };

  const [colors, setColors] = useState([
    { name: "Ajay", clr: "green" },
    { name: " Tarun", clr: " yellow" },
    { name: "  Ranbir", clr: "red" },
  ]);
  const [leadname, setLeadName] = useState("");
  const [leadclr, setLeadclr] = useState("");

  const addClr = () => {
    const obj = { name: leadname, clr: leadclr };
    setColors([...colors, obj]);
    handleClose();
  };

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [name, setName] = useState("");
  const [phone, setPhne] = useState("");
  const [Email, setEmail] = useState("");
  const [Team, setTeam] = useState("");
  const [Role, setRole] = useState("");
  const [rerno, setRerNo] = useState("");
const [Type, setType] = useState("")
  const user = useContext(profileContext);

  useEffect(() => {
    if (updateInfo) {
      console.log(updateInfo);
      setName(updateInfo.Name);
      setPhne(updateInfo.Phone);
      setEmail(updateInfo.Email);
      setRerNo(updateInfo.RERARegNo);
    }
  }, [updateInfo]);

  const [profileid, setprofileid] = useState(null);

  const saveChanges = async () => {
    if (updateInfo) {
      const obj = {
        name: name,
        phone: phone,
        email: Email,
        rera: rerno,
        profile_id: updateInfo.profile_id,
      };
      const res = await axios.patch(
        Apiaddress + "/api/updateprofileinfo/",
        obj
      );
      console.log(res.data);
      setState(false)
      if (res.data.id) setprofileid(res.data.id);
    } else {
      try {
        const obj = {
          name: name,
          phone: phone,
          email: Email,
          rera: rerno,
          type: Type
        };
        const res = await axios.post(
          Apiaddress + "/api/createcpprofile/",
          obj
        );
        console.log(res.data);
        setState(false)
        if (res.data.id) setprofileid(res.data.id);
      } catch (err) {
        console.log(err);
      }
    }
  };

  useEffect(() => {
    if (profileid) saveChangesMain();
  }, [profileid]);

  const saveChangesMain = async () => {
    const uid = user.UserState.user_obj.id;
    const obj = {
      id: profileid,
      profile: profileid,
      name: name,
      phone: phone,
      Email: Email,
      rera_no: rerno,
      builder: uid,
      profile: uid,
    };
    // setTabledata(obj);
    console.log(obj);
    // console.log(name, phone, Email, address, priority, setProjects);

    if (updateInfo) {
      const objupdate = {
        cp_id: updateInfo.CPid,
        name: name,
        phone: phone,
        Email: Email,
        rera_no: rerno,
        cp_type:Type,
        builder: uid,

        profile: updateInfo.profile_id,
      };
      try {
        const res = await axios.patch(
          Apiaddress + "/api/role/channelpartner/",
          objupdate
        );
        console.log(res);
        setTrigger(!trigger);
        setName("");
        setEmail("");
        setRerNo("");
        setEmail("");
        setPhne("");
      } catch (err) {
        console.log(err);
      }
    } else {
      try {
        const objupdate = {
          id: profileid,
          profile: profileid,
          name: name,
          phone: phone,
          Email: Email,
          rera_no: rerno,
          builder: uid,
          cp_type:Type,
          profile: profileid,
        };
        const res = await axios.post(
          Apiaddress + "/api/role/channelpartner/",
          objupdate
        );
        console.log(res);
        setTrigger(!trigger);
        setName("");
        setEmail("");
        setRerNo("");
        setEmail("");
        setPhne("");
      } catch (err) {
        console.log(err);
      }
    }
  };

  const addcpdata = () => { };
  return (
    <Box
      sx={{
        width: "420px",
        height: "80vh",
        marginTop: "-25px",
        marginRight: "25px",
        padding: "10px",
      }}
    >
      <Box
        sx={{
          position: "relative",
        }}
      >
        <Box
          sx={{
            position: "absolute",
            top: "0px",
            right: "0px",
            background: "white",
            padding: "10px",
            height: "105vh",
            width: "400px",
            marginTop: "20px",
          }}
        >
          {" "}
          <Box>
            <Box sx={{ display: "flex", cursor: "pointer" }}>
              <Box>
                <ArrowBackIosIcon
                  sx={{
                    fontSize: "small",
                    marginBottom: "-2px",
                  }}
                />
              </Box>
              <Box>Add Channel Partner</Box>
            </Box>
            <Box
              sx={{
                display: "grid",
                alignItems: "center",
                height: "100vh",
              }}
            >
              <Box>
                <Accordion
                  expanded={expanded === "panel1"}
                  onClick={() => handleChange("panel1")}
                >
                  <AccordionSummary
                    sx={{
                      background: `${expanded === "panel1" ? "#FFAF2A" : "white"
                        }`,
                      borderRadius: "10px",
                    }}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography sx={{ color: "white" }}>
                      Add Channel Partner{" "}
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        width: "400px",
                        margin: "10px 0",
                      }}
                    >
                      <Box>Name</Box>
                      <Box>
                        <TextField
                          value={name}
                          id="outlined-basic"
                          onChange={(e) => setName(e.target.value)}
                        />
                      </Box>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        width: "400px",
                        margin: "10px 0",
                      }}
                    >
                      <Box>Phone Number</Box>
                      <Box>
                        <TextField
                          value={phone}
                          placeholder="+91 1234567890"
                          id="outlined-basic"
                          onChange={(e) => setPhne(e.target.value)}
                        />
                      </Box>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        width: "400px",
                        margin: "10px 0",
                      }}
                    >
                      <Box>Email</Box>
                      <Box>
                        <TextField
                          value={Email}
                          id="outlined-basic"
                          onChange={(e) => setEmail(e.target.value)}
                        />
                      </Box>
                    </Box>

                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        width: "400px",
                        margin: "10px 0",
                      }}
                    >
                      <Box>RERA no</Box>
                      <Box>
                        <TextField
                          value={rerno}
                          id="outlined-basic"
                          onChange={(e) => setRerNo(e.target.value)}
                        />
                      </Box>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        width: "400px",
                        margin: "10px 0",
                      }}
                    >
                      <Box>Type</Box>
                      <Box>
                        <Select value={Type} onChange={(e) => { setType(e.target.value) }}>
                          <MenuItem value={"Individual"}> Individual</MenuItem>
                          <MenuItem value={"Admin"}> Admin</MenuItem>
                        </Select>
                      </Box>
                    </Box>

                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        width: "400px",
                        margin: "10px 0",
                      }}
                    >
                      <Box></Box>
                      <Box>
                        {!updateInfo && (
                          <Button
                            startIcon={<SendIcon />}
                            onClick={saveChanges}
                            className="univercel_btn_class"
                            sx={{
                              color: "#FFFFFF",
                              textTransform: "none",
                            }}
                          >
                            Submit
                          </Button>
                        )}
                        {updateInfo && (
                          <Button
                            startIcon={<SendIcon />}
                            onClick={saveChanges}
                            className="univercel_btn_class"
                            sx={{
                              color: "#FFFFFF",
                              textTransform: "none",
                            }}
                          >
                            Update
                          </Button>
                        )}
                      </Box>
                    </Box>
                  </AccordionDetails>
                </Accordion>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export default RightSidebarCPDadd;
