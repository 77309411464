import * as React from "react";
import {
  Box,
  Button,
  Container,
  Popover,
  Stack,
  Typography,
} from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import classes from "./CommonPagesNavbar.module.css";
import DropdownArrow from "../../Imgs/dropdown-arrow.svg";
import Logo from "../../Imgs/logo.png";
import builderIcon from "../../Imgs/builder-icon.svg";
import channelPartnerIcon from "../../Imgs/channel-partner-icon.svg";
import homeBuyerIcon from "../../Imgs/home-buyer-icon.svg";

function SignUpPopup() {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const navigate=useNavigate();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <div>
      <Button
        aria-describedby={id}
        sx={{
          backgroundColor: "#FF6B00",
          textTransform: "none",
          px: "40px",
          borderRadius: "8px",
          color: "#FFF",
          fontSize: "16px",
          fontFamily: "Product Sans",
          "&:hover": {
            backgroundColor: "#FF6B00",
          },
        }}
        onClick={handleClick}
      >
        Sign Up
      </Button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        transformOrigin={{ horizontal: "center", vertical: "center" }}
        sx={{
          top: "80px",
          left: "-5%",
          zIndex: 999999999,
          backgroundColor: "rgba(0,0,0,0.3)",
        }}
      >
        <Box sx={{ margin: "20px", borderRadius: "30px" }}>
          <Typography
            sx={{ fontSize: "18px", color: "#000", fontFamily: "Product sans" }}
          >
            Please select the preferred sign-up option.
          </Typography>
          <Stack spacing={2} sx={{ marginTop: "16px" }}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: "10px",
                backgroundColor: "#FFF",
                boxShadow:
                  "0px 8px 22px -6px rgba(24, 39, 75, 0.12), 0px 14px 64px -4px rgba(24, 39, 75, 0.12)",
                padding: "15px",
                borderRadius: "10px",
                cursor: "pointer",
                border: "2px solid transparent",
                "&:hover": {
                  border: "2px solid #0C6FB8",
                  boxShadow:
                    "0px 8px 22px -6px rgba(24, 39, 75, 0.12), 0px 14px 64px -4px rgba(24, 39, 75, 0.12)",
                },
              }}
              onClick={()=>{navigate('/builder-register')}}
            >
              <Box>
                <img src={builderIcon} alt="" />
              </Box>
              <Box>
                <Typography
                  sx={{
                    fontSize: "20px",
                    color: "#272D4E",
                    fontFamily: "Product sans",
                  }}
                >
                  For Builders
                </Typography>
                <Typography
                  sx={{
                    color: "#9299BC",
                    fontFamily: "Product sans",
                  }}
                >
                  Optimize sales and customer management <br /> with our
                  builder-focused CRM
                </Typography>
              </Box>
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: "10px",
                backgroundColor: "#FFF",
                boxShadow:
                  "0px 8px 22px -6px rgba(24, 39, 75, 0.12), 0px 14px 64px -4px rgba(24, 39, 75, 0.12)",
                padding: "15px",
                borderRadius: "10px",
                cursor: "pointer",
                border: "2px solid transparent",
                "&:hover": {
                  border: "2px solid #FF4267",
                },
              }}
              onClick={()=>{navigate('/channelpartner-register')}}
            >
              <Box>
                <img src={channelPartnerIcon} alt="" />
              </Box>
              <Box>
                <Typography
                  sx={{
                    fontSize: "20px",
                    color: "#272D4E",
                    fontFamily: "Product sans",
                  }}
                >
                  For Channel Partners
                </Typography>
                <Typography
                  sx={{
                    color: "#9299BC",
                    fontFamily: "Product sans",
                  }}
                >
                  Enhance channel partner collaboration and <br /> communication
                  with our CRM platform
                </Typography>
              </Box>
            </Box>
            {/* <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: "10px",
                backgroundColor: "#FFF",
                boxShadow:
                  "0px 8px 22px -6px rgba(24, 39, 75, 0.12), 0px 14px 64px -4px rgba(24, 39, 75, 0.12)",
                padding: "15px",
                borderRadius: "10px",
                cursor: "pointer",
                border: "2px solid transparent",
                "&:hover": {
                  border: "2px solid #0890FE",
                },
              }}
            >
              <Box>
                <img src={homeBuyerIcon} alt="" />
              </Box>
              <Box>
                <Typography
                  sx={{
                    fontSize: "20px",
                    color: "#272D4E",
                    fontFamily: "Product sans",
                  }}
                >
                  For Home Buyers
                </Typography>
                <Typography
                  sx={{
                    color: "#9299BC",
                    fontFamily: "Product sans",
                  }}
                >
                  Manage your home buying journey with <br /> ease using our
                  all-in-one CRM solution.
                </Typography>
              </Box>
            </Box> */}
          </Stack>
        </Box>
      </Popover>
    </div>
  );
}

const CommonPagesNavbar = ({ profile }) => {
  const navigate = useNavigate();
  return (
    <Box
      className={classes.boxShadow}
      sx={{
        width: "100%",
        backgroundColor: "#FFFFFF",
        position: "sticky",
        top: 0,
        left: 0,
        right: 0,
        zIndex: 999999,
        display: { xs: "none", md: "block" },
      }}
    >
      <Container>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Box
            onClick={() => navigate("/")}
            sx={{ width: "170px", py: "10px" }}
          >
            <img src={Logo} style={{ width: "100%" }} alt="" />
          </Box>

          <Box
            className={classes.link}
            sx={{ display: "flex", gap: "20px", alignItems: "center" }}
          >
            <Link className={classes.mainLink} to="/">
              Home
            </Link>
            <Link className={classes.mainLink} to="/about-us-page">
              About Us
            </Link>

            <Box className={classes.hoverMenu}>
              <Link to="#" className={classes.mainLink}>
                Services{" "}
                <img style={{ ml: "10px" }} src={DropdownArrow} alt="" />
              </Link>

              <Box className={classes.hoverMenuItem}>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    width: "250px",
                  }}
                >
                  {/* {profile=="ChannelPartner"?} */}
                  <Link className={classes.subLink} to="/builder-landing-page">
                    For Builders & Developers
                  </Link>
                  <Link className={classes.subLink} to="/channel-landing-page">
                    For Channel Partners
                  </Link>
                  <Link className={classes.subLink} to="/buyer-landing-page">
                    For Builders & Developers
                  </Link>
                </Box>
              </Box>
            </Box>
            <Link className={classes.mainLink} to="/contact-page">
              Contact
            </Link>
            <Link className={classes.mainLink} to="/login">
              Login
            </Link>
            {/* <Button
              onClick={() => {
                if (profile == "channel partner") {
                  navigate("/channelpartner-register");
                } else if (profile == "builder") {
                  navigate("/builder-register");
                }
              }}
              sx={{
                backgroundColor: "#FF6B00",
                textTransform: "none",
                px: "40px",
                borderRadius: "8px",
                color: "#FFF",
                fontSize: "16px",
                fontFamily: "Product Sans",
                "&:hover": {
                  backgroundColor: "#FF6B00",
                },
              }}
            >
              Sign Up
            </Button> */}
            <SignUpPopup />
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

export default CommonPagesNavbar;
