import React from 'react'
import SideBar from '../Components/sideBar/SideBar'
import DashboardView from './view'

const CPDashboard = () => {
  return (
    <SideBar active={"Dashboard"}>  
        <DashboardView>    
            
        </DashboardView>
    </SideBar>
    )
}

export default CPDashboard