import {
  Box,
  Container,
  Grid,
  Paper,
  Stack,
  styled,
  Typography,
} from "@mui/material";

import BridgingGapsAcross from "../../Imgs/bridging-gaps-across.png";
import CircleCheckIcon from "../../Imgs/circle-check-icon.svg";
import ChannelLandingAccessAllProjectDataIcon from "../../Imgs/channel-landing-access-all-project-data.svg";
import ChannelLandingConnectIcon from "../../Imgs/channel-landing-connect.svg";
import ChannelLandingSaveTimeIcon from "../../Imgs/channel-landing-save-time.svg";
import ChannelLandingConnectSmoothlyIcon from "../../Imgs/channel-landing-connect-smoothly.svg";

const Item2 = styled(Paper)(({ theme }) => ({
  padding: "15px 10px",
  background: "#FFFFFF",
  boxShadow:
    "0px 8px 22px -6px rgba(24, 39, 75, 0.12), 0px 14px 64px -4px rgba(24, 39, 75, 0.12)",
  borderRadius: "14px",
}));

const ChannelLandingBridgingGaps = () => {
  return (
    <Box sx={{ overflowX: "hidden" }}>
      <Container>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: "50px",
            flexDirection: { xs: "column", md: "row" },
          }}
        >
          <Box sx={{ width: { xs: "100%", md: "45%" } }}>
            <img
              data-aos="fade-right"
              style={{ width: "100%" }}
              src={BridgingGapsAcross}
              alt=""
            />
          </Box>

          <Box sx={{ width: { xs: "100%", md: "55%" } }}>
            <Typography
              data-aos="fade-down"
              sx={{
                fontSize: { xs: "40px", md: "56px" },
                fontWeight: 700,
                fontFamily: "Circular Std",
                color: "#272D4E",
                lineHeight: "1.3",
                textAlign: { xs: "center", md: "left" },
              }}
            >
              We are bridging gaps across the board
            </Typography>

            <Stack spacing={2} sx={{ mt: "30px" }}>
              <Box sx={{ display: "flex", gap: "10px" }}>
                <Box sx={{ lineHeight: "10px", fontSize: "30px" }}>
                  {/* <img src={CircleCheckIcon} alt="" /> */}
                  &#x2022;
                </Box>
                <Typography
                  sx={{
                    fontSize: "16px",
                    fontFamily: "Product Sans",
                    color: "#343434",
                    lineHeight: "1.3",
                  }}
                >
                  <span style={{ fontWeight: 600 }}>
                    For the first time in India,
                  </span>{" "}
                  Channel Partners and Brokers have an{" "}
                  <span style={{ fontWeight: 600 }}>integrated platform.</span>{" "}
                </Typography>
              </Box>
              <Box sx={{ display: "flex", gap: "10px" }}>
                <Box sx={{ lineHeight: "10px", fontSize: "30px" }}>
                  {/* <img src={CircleCheckIcon} alt="" /> */}
                  &#x2022;
                </Box>
                <Typography
                  sx={{
                    fontSize: "16px",
                    fontFamily: "Product Sans",
                    color: "#343434",
                    lineHeight: "1.3",
                  }}
                >
                  We aim to{" "}
                  <span style={{ fontWeight: 600 }}>
                    enable greater transparency and trust
                  </span>{" "}
                  among Channel Partners and Builders.
                </Typography>
              </Box>
              <Box sx={{ display: "flex", gap: "10px" }}>
                <Box sx={{ lineHeight: "10px", fontSize: "30px" }}>
                  {/* <img src={CircleCheckIcon} alt="" /> */}
                  &#x2022;
                </Box>
                <Typography
                  sx={{
                    fontSize: "16px",
                    fontFamily: "Product Sans",
                    color: "#343434",
                    lineHeight: "1.3",
                  }}
                >
                  Benefit from{" "}
                  <span style={{ fontWeight: 600 }}>
                    an ecosystem designed by industry experts
                  </span>{" "}
                  to transform the sector.
                </Typography>
              </Box>
            </Stack>

            <Box sx={{ flexGrow: 1, py: "50px" }}>
              <Grid container spacing={3}>
                <Grid item xs={12} md2={6} data-aos="fade-right">
                  <Item2
                    sx={{
                      border: "1px solid #0890FE",
                      display: "flex",
                      gap: "10px",
                    }}
                  >
                    <Box>
                      <img
                        src={ChannelLandingAccessAllProjectDataIcon}
                        alt=""
                      />
                    </Box>

                    <Box sx={{ pb: "30px" }}>
                      <Typography
                        sx={{
                          color: "#343434",
                          fontSize: "18px",
                          fontWeight: 400,
                          fontFamily: "Product Sans",
                        }}
                      >
                        Access all project data and independently manage
                        inventory from a single place
                      </Typography>
                    </Box>
                  </Item2>
                </Grid>
                <Grid item xs={12} md2={6} data-aos="fade-left">
                  <Item2
                    sx={{
                      border: "1px solid #FF4267",
                      display: "flex",
                      gap: "10px",
                    }}
                  >
                    <Box>
                      <img src={ChannelLandingConnectIcon} alt="" />
                    </Box>

                    <Box>
                      <Typography
                        sx={{
                          color: "#343434",
                          fontSize: "18px",
                          fontWeight: 400,
                          fontFamily: "Product Sans",
                        }}
                      >
                        Connect and collaborate smoothly with both parties -
                        Real Estate Developers and Home Buyers
                      </Typography>
                    </Box>
                  </Item2>
                </Grid>
                <Grid item xs={12} md2={6} data-aos="fade-right">
                  <Item2
                    sx={{
                      border: "1px solid #FFAF2A",
                      display: "flex",
                      gap: "10px",
                    }}
                  >
                    <Box>
                      <img src={ChannelLandingSaveTimeIcon} alt="" />
                    </Box>

                    <Box sx={{ pb: "30px" }}>
                      <Typography
                        sx={{
                          color: "#343434",
                          fontSize: "18px",
                          fontWeight: 400,
                          fontFamily: "Product Sans",
                        }}
                      >
                        Save time with pre-defined workflows
                      </Typography>
                    </Box>
                  </Item2>
                </Grid>
                <Grid item xs={12} md2={6} data-aos="fade-left">
                  <Item2
                    sx={{
                      border: "1px solid #3629B7",
                      display: "flex",
                      gap: "10px",
                    }}
                  >
                    <Box>
                      <img src={ChannelLandingConnectSmoothlyIcon} alt="" />
                    </Box>

                    <Box>
                      <Typography
                        sx={{
                          color: "#343434",
                          fontSize: "18px",
                          fontWeight: 400,
                          fontFamily: "Product Sans",
                        }}
                      >
                        Grow business with enhanced trust and transparency
                      </Typography>
                    </Box>
                  </Item2>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

export default ChannelLandingBridgingGaps;
