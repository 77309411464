import { Box, Checkbox, Stack, Button, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { useNavigate } from "react-router-dom";

function CPDcustomTable({
  newRowData,
  delIconClick,
  updateBuyers,
  setTrigger,
  trigger,
  toggleDrawer,
}) {
  //   console.log(newRowData);
  const navigate = useNavigate();
  const [curpage, setcurpage] = useState(1);
  const [pageRange, setPageRange] = useState([0, 6]);

  const nextClick = () => {
    if (
      !(newRowData.length >= pageRange[0] && newRowData.length <= pageRange[1])
    ) {
      setcurpage(curpage + 1);
      setPageRange([pageRange[0] + 6, pageRange[1] + 6]);
    }
  };

  const prvClick = () => {
    if (pageRange[0] != 0 && pageRange[1] != 6) {
      setcurpage(curpage - 1);
      setPageRange([pageRange[0] - 6, pageRange[1] - 6]);
    }
  };

  const [pages, setPages] = useState([]);
  useEffect(() => {
    if (newRowData) {
      let arr = [];
      for (
        let i = 1;
        i <=
        (newRowData.length % 6 == 0
          ? newRowData.length / 6
          : newRowData.length / 6 + 1);
        i++
      ) {
        arr.push(i);
      }
      setPages(arr);
    }
  }, [newRowData]);

  return (
    <Box>
      
        {/* <DataGrid
      rows={newRowData}
      columns={columns}
      pageSize={10}
      rowsPerPageOptions={[5]}
      onSelectionModelChange={(ids) => onRowsSelectionHandler(ids)}
    /> */}
        <Box sx={{ color: "black", width: "100%" }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "start",
              alignItems: "center",
              background: "white",
              borderRadius: "5px",
              margin: " 10px 0",
              color: "#272D4E",  
              fontWeight: "700",
              fontFamily:"Circular Std",
              fontSize:"18px"
            }}
          >
            <Box
              sx={{
                width: "15vw",
                display: "grid",
                placeItems: "center",
                height: "50px",
                justifyContent: "start",
                paddingLeft:"20px",
              }}
            >
              <Box sx={{ display: "flex" }}>
                <Box sx={{ display: "grid", placeItems: "center" }}>
                  RERA Reg No
                </Box>
                {/* <Box sx={{ display: "grid", marginLeft: "4px" }}>
                  <ArrowDropUpIcon
                    fontSize="medium"
                    sx={{ marginBottom: "-8px" }}
                  />
                  <ArrowDropDownIcon
                    fontSize="medium"
                    sx={{ marginTop: "-8px" }}
                  />
                </Box> */}
              </Box>
            </Box>

            <Box
              sx={{
                width: "15vw",
                display: "grid",
                placeItems: "center",
                height: "50px",
                justifyContent: "start",
                paddingLeft:"20px",
              }}
            >
              <Box sx={{ display: "flex" }}>
                <Box sx={{ display: "grid", placeItems: "center" }}>Type</Box>
                {/* <Box sx={{ display: "grid", marginLeft: "4px" }}>
                  <ArrowDropUpIcon
                    fontSize="medium"
                    sx={{ marginBottom: "-8px" }}
                  />
                  <ArrowDropDownIcon
                    fontSize="medium"
                    sx={{ marginTop: "-8px" }}
                  />
                </Box> */}
              </Box>
            </Box>

            <Box
              sx={{
                width: "15vw",
                display: "grid",
                placeItems: "center",
                height: "50px",
                justifyContent: "start",
                paddingLeft:"20px",
              }}
            >
              <Box sx={{ display: "flex" }}>
                <Box sx={{ display: "grid", placeItems: "center" }}>Name</Box>
                {/* <Box sx={{ display: "grid", marginLeft: "4px" }}>
                  <ArrowDropUpIcon
                    fontSize="medium"
                    sx={{ marginBottom: "-8px" }}
                  />
                  <ArrowDropDownIcon
                    fontSize="medium"
                    sx={{ marginTop: "-8px" }}
                  />
                </Box> */}
              </Box>
            </Box>

            <Box
              sx={{
                width: "15vw",
                display: "grid",
                placeItems: "center",
                height: "50px",
                justifyContent: "start",
                paddingLeft:"20px",
              }}
            >
              <Box sx={{ display: "flex" }}>
                <Box sx={{ display: "grid", placeItems: "center" }}>Email</Box>
                {/* <Box sx={{ display: "grid", marginLeft: "4px" }}>
                  <ArrowDropUpIcon
                    fontSize="medium"
                    sx={{ marginBottom: "-8px" }}
                  />
                  <ArrowDropDownIcon
                    fontSize="medium"
                    sx={{ marginTop: "-8px" }}
                  />
                </Box> */}
              </Box>
            </Box>

            <Box
              sx={{
                width: "15vw",
                display: "grid",
                placeItems: "center",
                height: "50px",
                justifyContent: "start",
                paddingLeft:"20px",
              }}
            >
              <Box sx={{ display: "flex" }}>
                <Box sx={{ display: "grid", placeItems: "center" }}>Phone</Box>
                {/* <Box sx={{ display: "grid", marginLeft: "4px" }}>
                  <ArrowDropUpIcon
                    fontSize="medium"
                    sx={{ marginBottom: "-8px" }}
                  />
                  <ArrowDropDownIcon
                    fontSize="medium"
                    sx={{ marginTop: "-8px" }}
                  />
                </Box> */}
              </Box>
            </Box>

            <Box
              sx={{
                width: "15vw",
                display: "grid",
                placeItems: "center",
                height: "50px",
                justifyContent: "start",
                paddingLeft:"20px",
              }}
            >
              Actions
            </Box>
          </Box>
          {newRowData.length==0?
          <Box sx={{textAlign:"center",marginTop:"50px"}}><Typography sx={{fontSize:"25px",fontWeight:"500",color:"#B9B7D0",}}>No Matches Found</Typography></Box>:
          <Box>
          {newRowData &&
            newRowData.map((item, index) => {
              if (index >= pageRange[0] && index <= pageRange[1]) {
                return (
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      background: "white",
                      borderRadius: "5px",
                      margin: " 10px 0",
                      color:"#343434",
                      cursor:"pointer"
                    }}
                    onClick={() => {
                      navigate(`/cannelpartner-project-overview/${item.CPid}`, {
                        state: item,
                      });
                    }}
                  >
                    <Box
                      sx={{
                        width: "15vw",
                        display: "grid",
                        placeItems: "center",
                        height: "50px",
                        justifyContent: "start",
                        fontFamily:"Product Sans",
                        fontWeight:"400",
                        paddingLeft:"20px"
                      }}
                    >
                      {item.RERARegNo}
                    </Box>
                    <Box
                      sx={{
                        width: "15vw",
                        display: "grid",
                        placeItems: "center",
                        height: "50px",
                        justifyContent: "start",
                        fontFamily:"Product Sans",
                        fontWeight:"400",
                        paddingLeft:"20px",
                      }}
                    >
                      {item.Type}
                    </Box>
                    <Box
                      sx={{
                        width: "15vw",
                        display: "grid",
                        placeItems: "center",
                        height: "50px",
                        justifyContent: "start",
                        fontFamily:"Product Sans",
                        fontWeight:"400",
                        paddingLeft:"20px",
                      }}
                    >
                      {item.Name}
                    </Box>
                    <Box
                      sx={{
                        width: "15vw",
                        display: "grid",
                        placeItems: "center",
                        height: "50px",
                        justifyContent: "start",
                        fontFamily:"Product Sans",
                        fontWeight:"400",
                        paddingLeft:"20px",
                      }}
                    >
                      {item.Email.substring(0, 14)+"..."}
                    </Box>
                    <Box
                      sx={{
                        width: "15vw",
                        display: "grid",
                        placeItems: "center",
                        height: "50px",
                        justifyContent: "start",
                        fontFamily:"Product Sans",
                        fontWeight:"400",
                        paddingLeft:"20px",
                      }}
                    >
                      {item.Phone}
                    </Box>
                    <Box
                      sx={{
                        width: "15vw",
                        display: "grid",
                        placeItems: "center",
                        height: "50px",
                        justifyContent: "start",
                        fontFamily:"Product Sans",
                        fontWeight:"400",
                        paddingLeft:"20px",
                      }}
                    >  
                      <Stack direction="row" sx={{ marginLeft: "10px" }}>
                        <RemoveRedEyeIcon
                          fontSize="medium"
                          sx={{cursor:"pointer"}}
                          // onClick={() => {
                          //   navigate(`/channelpartneroverview/${item.CPid}`, {
                          //     state: item,
                          //   });
                          // }}
                          onClick={(e) => {
                            e.stopPropagation();
                            navigate(`/cannelpartner-project-overview/${item.CPid}`, {
                              state: item,
                            });
                          }}
                        />
                        {/* 
                        <EditIcon
                          fontSize="medium"
                          sx={{ marginLeft: "10px" }}
                          onClick={toggleDrawer(true, item)}
                        /> */}
                        <DeleteIcon
                          fontSize="medium"
                          sx={{ marginLeft: "10px",cursor:"pointer" }}
                          onClick={(e) =>{
                            e.stopPropagation(); 
                            delIconClick(item)
                          }}
                        />
                      </Stack>
                    </Box>
                  </Box>
                );
              }
            })}

            <Box sx={{margin:"50px 0px 20px 0px"}}>
          <Box sx={{ display: "flex", justifyContent: "right" }}>
            {newRowData.length!=0 && 
            <Button variant="contained" sx={{background:"linear-gradient(90deg, #0F5DAE 0%, #009DCF 100.33%)"}}
              onClick={prvClick}>
                <ArrowBackIosIcon fontSize="small" />
                <Box sx={{ textTransform: "capitalize" }}>Previous</Box>
            </Button>}

            <Box sx={{background: "#E3E4EB",display: "flex",placeItems: "center",margin: " 0 10px",borderRadius: "5px"}}>
                <Box sx={{background: `white`,color: "black",width: "30px",borderRadius: "5px",margin: "0 10px",display: "grid",placeItems: "center"}}>{curpage}</Box>
                <Box sx={{color: "black",width: "30px",borderRadius: "5px",margin: "0 0px",display: "grid",placeItems: "center"}}>of</Box>
                <Box sx={{background: `white`,color: "black",width: "30px",borderRadius: "5px",margin: "0 10px",display: "grid",placeItems: "center"}}>{pages?.length}</Box>
            </Box>

            {newRowData.length!=0 && 
                <Button variant="contained" sx={{background:"linear-gradient(90deg, #0F5DAE 0%, #009DCF 100.33%)"}}
                    onClick={nextClick}>
                    <Box sx={{ textTransform: "capitalize" }}>Next</Box>
                    <ArrowForwardIosIcon fontSize="small" />
                </Button>
            }
          </Box>
        </Box>
        
          </Box>
          }
        </Box>
        
    </Box>
  );
}

export default CPDcustomTable;
