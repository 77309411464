import { Box, Stack} from '@mui/system';
import React, { useEffect, useState } from 'react';
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { Accordion, AccordionDetails, AccordionSummary, Button, CircularProgress, Drawer, FormControl, InputLabel, MenuItem, Modal, Select, TextField, Typography } from '@mui/material';
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import TuneOutlinedIcon from "@mui/icons-material/TuneOutlined";

import { SuperAdminSidebar } from '../SidebarNavbar/SuperAdminSidebar';
import { SuperAdminNavbar } from '../SidebarNavbar/SuperAdminNavbar';
import { Apiaddress } from "../../../utils/consts";
import axios from 'axios';
import { Link, useLocation, useNavigate } from 'react-router-dom';

import { GridExpandMoreIcon } from '@mui/x-data-grid';
import RightSidebarSAmemberD from './RightSidebarSAmemberD';

function SuperAdminMember() {
    
  // SAteamDetails
   let roleDetails = JSON.parse(localStorage.getItem("SAteamDetails"));
    console.log(roleDetails);
    const data=roleDetails?.selectedteamId;
    const teamName=roleDetails?.selectedteamName;

    //get data from previous page 
    // const location = useLocation();
    // const dataobj = location.state;
    // const data=dataobj.id;
    // console.log("preve data =>.>",data)

    let  token  = localStorage.getItem("crm_token")
    console.log(token);

    const navigate = useNavigate();
  
   const [curpage, setcurpage] = useState(1);
   const [pageRange, setPageRange] = useState([0, 9]);
   const [pages, setPages] = useState([]);

   const [loading,setloading]= useState(true);

   const [newRowData,setnewRowData]= useState([]);

   const [trigger, setTrigger] = useState(false);

    const nextClick = () => {
    if (
      !(newRowData.length >= pageRange[0] && newRowData.length <= pageRange[1])
    ) {
      setcurpage(curpage + 1);
      setPageRange([pageRange[0] + 9, pageRange[1] + 9]);
    }
    };

  const prvClick = () => {
    if (pageRange[0] != 0 && pageRange[1] != 9) {
      setcurpage(curpage - 1);
      setPageRange([pageRange[0] - 9, pageRange[1] - 9]);
    }
    };

    useEffect(() => {
    if (newRowData) {
      let arr = [];
      for (
        let i = 1;
        i <=
        (newRowData.length % 9 == 0
          ? newRowData.length / 9
          : newRowData.length / 9 + 1);
        i++
      ) {
        arr.push(i);
      }
      setPages(arr);
    }
  }, [newRowData]); 

   //fetching TeamList
  const fetchTeamdata=async ()=>{
    
try{
    const headers = {
       Authorization: `JWT ${token}`,
    };  
    const memberurl = Apiaddress+"/api/role/teamlistviewall/"
    const res = await axios.get(memberurl,{headers});
    console.log("=============================",res.data);
    let team=res.data.teams;

    if(team=="team not found"){
       setnewRowData([]); 
    }
    else{

       let teamobj = team.find(ele => ele.id === data);
        console.log(teamobj);
       let temp=teamobj.members; 
        let arr=[];

        for(let i=0;i<temp.length;i++){ 
        let obj={
            id:temp[i].id,
            name:temp[i].name,
            email:temp[i].email,
            phone:temp[i].phone,
            role:temp[i].role
        }
        arr.push(obj);
    }
    setnewRowData(arr);
    }
    
   }
   catch(err){
      console.log(err);
      setnewRowData([]);
   }

    setloading(false);
  }

  console.log(newRowData);

  useEffect(()=>{
    
    fetchTeamdata();
   
  },[trigger])


  //fitering data starts:====
  const [filterselect, setfilterselect] = useState();
  const [filtervalue, setfiltervalue] = useState();

  const handleChange = (event) => {
    setfilterselect(event.target.value);
    setfiltervalue("");
  };

  console.log(filterselect);
  console.log(filtervalue);

  const fetchfilter= async()=>{
    
    try{
    const headers = {
       Authorization: `JWT ${token}`,
    };  
    const teamurl = Apiaddress+`/api/role/teamlistviewall/?filterkey=${filterselect}&filtervalue=${filtervalue}`
    const res = await axios.get(teamurl,{headers});
    console.log("=============================",res.data.teams);
    let temp=res.data.teams;

    if(temp=="team not found"){
       setnewRowData([]); 
    }
    else{
       let arr=[];

        for(let i=0;i<temp.length;i++){ 
        let obj={
            id:temp[i].id,
            name:temp[i].name,
            members: temp[i].members ? temp[i].members.length : 0,
        }
        arr.push(obj);
    }
    setnewRowData(arr);
    }

   }
   catch(err){
      console.log(err);
      setnewRowData([]);
   }

  }
  
  //delete Member data
  const delIconClick = async (item) => {
    
    try {
      const obj = { member_id: item.id }; 
      console.log(obj);
      const res = await axios.delete(
        Apiaddress + "/api/role/memberview/",
        {
          data: obj,
        }
      );
      setTrigger(!trigger);
      console.log(res);
    } catch (err) {
      console.log(err);
    }
  };
  
  // right Sidebar functionality

  const [state, setState] = useState({right:false});
  const [updateData, setupdateData]= useState(null);

  const updateTeam = (item) => {
    setupdateData(item);
    // console.log(item);
  };

  const toggleDrawer = (opt, item) => (event) => {

    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    if (item) updateTeam(item);
    console.log(opt);
    setState({ ...state, ["right"]: opt });
  };


  return ( 
    <>
      <Stack direction="row" sx={{width:"100%",height:"auto" }}>
      {/* 1st stack */}
      <Stack sx={{minHeight:"100vh",width:"20%",boxSizing:"border-box",}}> {/*for project directory 20% and for sales dashboard 225*/}
        <SuperAdminSidebar active={"Teams Directory"}/>
      </Stack>

     {/* 2nd stack */}
     <Stack direction="column" sx={{ backgroundColor:"#f9f9f9",width:"80%", padding:"25px",boxSizing:"border-box",}}  >  {/*  width for main sidebar 78px and for 80px*/}
      <SuperAdminNavbar showAddMemberbtn={true} setState={setState}/>   {/*send props for get popup to verify otp from builder */}

      {/* right Sidebar */}
        <React.Fragment>
          <Drawer anchor="right" open={state["right"]} close={state["right"]}>
            <RightSidebarSAmemberD
              // toggleDrawer={toggleDrawer}
              setState={setState}
              // curselectedRows={curselectedRows}
              setTrigger={setTrigger}
              trigger={trigger}
              updateData={updateData}
              setupdateData={setupdateData}
              teamId={data} 
            />
          </Drawer>
        </React.Fragment>
      
       <Stack direction="row" justifyContent="space-between" alignItems="center" sx={{}}>

          <Box sx={{}}>
            <Typography sx={{marginTop:"10px", fontSize:"16px", fontWeight:"600", marginLeft:"0px", marginBottom:"0px",color:"#2979F2",fontWeight: "400"}}>
              Here is your Member Directory list Data 
            </Typography>
            <Typography sx={{marginTop:"20px", fontSize:"28px", fontWeight:"600", marginLeft:"0px"}}> 
              Member Directory   
            </Typography>
            <Typography sx={{marginTop:"10px", fontSize:"16px", fontWeight:"600", marginLeft:"0px", marginBottom:"50px",color: "#9299BC",fontWeight: "500"}}>
              {`Here is your Member Directory list associated with ${teamName}`} 
            </Typography>
          </Box>

             <Accordion sx={{ width: "250px", boxShadow: "none",position:"absolute",right:"1%" }}>
                  <AccordionSummary
                    expandIcon={<GridExpandMoreIcon/>}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography sx={{ display: "flex", placeItems: "center" }}>
                      <TuneOutlinedIcon sx={{ color: "#2F4CDD" }} />
                      <Typography sx={{ marginLeft: "25px" }}>
                        Fillters
                      </Typography>
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Box sx={{textAlign:"center"}}>
                      <FormControl sx={{ width: "200px" }}>
                        <InputLabel id="demo-simple-select-label">
                          Select
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={filterselect}
                          label="Select"
                          onChange={handleChange}
                        >
                          <MenuItem value={"name"}>Name</MenuItem>

                        </Select>
                      </FormControl>
                      <TextField
                        sx={{ marginTop: "20px",width:"92%" }}
                        placeholder="Enter the value" 
                        value={filtervalue} onChange={(e)=>{setfiltervalue(e.target.value)}}
                      />
                      <Button 
                        onClick={fetchfilter}
                        variant="contained"
                        color="primary" sx={{ height:"40px", background: "linear-gradient(90deg, #0F5DAE 0%, #009DCF 100.33%)",boxShadow: "0px 13.7981px 22.5788px rgba(50, 174, 137, 0.25)", textTransform: "none", borderRadius: "4px", width:"100px", fontSize:"15px",margin:"20px 0px 10px 0px"}}>
                         Submit
                      </Button>

                    </Box>
                  </AccordionDetails>
                </Accordion>

       </Stack>

     {/* actual content  */}
    <Stack sx={{backgroundColor:"#f9f9f9"}}>  {/* mt:"28px", */}

    {/* builder list table */}
    <Box sx={{height:"100vh",width:"100%"}}>
        <Box sx={{ color: "black", width: "100%" ,boxSizing:"border-box",display:"flex",flexDirection:"column"}}>

            <Stack direction="row" justifyContent="space-around" alignItems="center" sx={{width:"100%",backgroundColor:"white",borderRadius: "5px",margin:"0px 0px 10px 0px",padding:"10px 0px 10px 0px"}}>
              
                <Stack direction="row" justifyContent="center" alingItems="center" sx={{width:"14%",textAlign:"center",color: "#9299BC",fontWeight: "600"}}>
                        <Typography sx={{ display: "grid", placeItems: "center",fontWeight:'600',color:"black" }}>Name</Typography>
                        {/* <Box sx={{ display: "grid", marginLeft: "4px" }}>
                            <ArrowDropUpIcon fontSize="medium" sx={{ marginBottom: "-8px" }}/>
                            <ArrowDropDownIcon fontSize="medium" sx={{ marginTop: "-8px" }}/>
                        </Box> */}
                </Stack>

                 <Stack direction="row" justifyContent="center" alingItems="center" sx={{width:"14%",textAlign:"center",color: "#9299BC",fontWeight: "600"}}>
                        <Typography sx={{ display: "grid", placeItems: "center",fontWeight:'600',color:"black" }}>Email</Typography>
                        {/* <Box sx={{ display: "grid", marginLeft: "4px" }}>
                            <ArrowDropUpIcon fontSize="medium" sx={{ marginBottom: "-8px" }}/>
                            <ArrowDropDownIcon fontSize="medium" sx={{ marginTop: "-8px" }}/>
                        </Box> */}
                </Stack>

                <Stack direction="row" justifyContent="center" alingItems="center" sx={{width:"14%",textAlign:"center",color: "#9299BC",fontWeight: "600"}}>
                        <Typography sx={{ display: "grid", placeItems: "center",fontWeight:'600',color:"black" }}>Phone</Typography>
                        {/* <Box sx={{ display: "grid", marginLeft: "4px" }}>
                            <ArrowDropUpIcon fontSize="medium" sx={{ marginBottom: "-8px" }}/>
                            <ArrowDropDownIcon fontSize="medium" sx={{ marginTop: "-8px" }}/>
                        </Box> */}
                </Stack>

                <Stack direction="row" justifyContent="center" alingItems="center" sx={{width:"14%",textAlign:"center",color: "#9299BC",fontWeight: "600"}}>
                        <Typography sx={{ display: "grid", placeItems: "center",fontWeight:'600',color:"black" }}>Role</Typography>
                        {/* <Box sx={{ display: "grid", marginLeft: "4px" }}>
                            <ArrowDropUpIcon fontSize="medium" sx={{ marginBottom: "-8px" }}/>
                            <ArrowDropDownIcon fontSize="medium" sx={{ marginTop: "-8px" }}/>
                        </Box> */}
                </Stack>


                 <Stack direction="row" justifyContent="center" alingItems="center" sx={{width:"14%",textAlign:"center",color: "#9299BC",fontWeight: "600"}}>
                        <Typography sx={{ display: "grid", placeItems: "center",fontWeight:'600',color:"black" }}>Action</Typography>
                        {/* <Box sx={{ display: "grid", marginLeft: "4px" }}>
                            <ArrowDropUpIcon fontSize="medium" sx={{ marginBottom: "-8px" }}/>
                            <ArrowDropDownIcon fontSize="medium" sx={{ marginTop: "-8px" }}/>
                        </Box> */}
                </Stack>

            </Stack>
            
             {loading==true?
            <Box sx={{display:"flex",height:"80vh",padding:"10% 5% 5% 45%"}}>
                <CircularProgress color="inherit" sx={{ color: 'grey.500' }}/>
            </Box> 
             :
            <Box sx={{}}>
                {newRowData.length==0?
                    <Box sx={{textAlign:"center",marginTop:"50px"}}>
                        <Typography sx={{fontSize:"25px",fontWeight:"500",color:"#B9B7D0",}}>No Matches Found</Typography>
                    </Box>:
                    <Box>
                        {newRowData && newRowData?.map((item, index) => {
                            if (index >= pageRange[0] && index <= pageRange[1]) {
                                return (
                                  // <Link to="/superadmin-team-memberview" state={item} onClick={(e)=>{
                                  //   e.stopPropagation();
                                  //   localStorage.setItem("SAteammemberDetails", JSON.stringify({selectedmemberId:item.id, name:item.name,email:item.email,phone:item.phone,role:item.role}))
                                  // }}>
                                    <Stack direction="row" justifyContent="space-around" alignItems="center" sx={{width:"100%",borderRadius: "5px",margin:"0px 0px 10px 0px",padding:"10px 0px 10px 0px",backgroundColor:"white",cursor:"pointer"}} onClick={()=>{}}>

                                       <Stack direction="row" justifyContent="center" alingItems="center" sx={{width:"14%",textAlign:"center",color: "#9299BC",fontWeight: "600"}}>
                                        <Typography >
                                           {item?.name}
                                        </Typography>
                                       </Stack>
                                       <Stack direction="row" justifyContent="center" alingItems="center" sx={{width:"14%",textAlign:"center",color: "#9299BC",fontWeight: "600"}}>
                                        <Typography >
                                          {item?.email}
                                        </Typography>
                                       </Stack>
                                        <Stack direction="row" justifyContent="center" alingItems="center" sx={{width:"14%",textAlign:"center",color: "#9299BC",fontWeight: "600"}}>
                                        <Typography >
                                          {item?.phone}
                                        </Typography>
                                       </Stack>
                                        <Stack direction="row" justifyContent="center" alingItems="center" sx={{width:"14%",textAlign:"center",color: "#9299BC",fontWeight: "600"}}>
                                        <Typography >
                                          {item?.role}
                                        </Typography>
                                       </Stack>

                                       <Stack direction="row" justifyContent="center" alingItems="center" spacing={1.5} sx={{width:"14%",textAlign:"center"}}>
                                          {/* <EditIcon sx={{color: "#9299BC",cursor:"pointer"}} 
                                          onClick={toggleDrawer(true, item)}
                                           /> */}
                                           <Link to="/superadmin-team-memberview" state={item} onClick={(e)=>{
                                              e.stopPropagation()
                                              localStorage.setItem("SAteammemberDetails", JSON.stringify({selectedmemberId:item.id, name:item.name,email:item.email,phone:item.phone,role:item.role}))
                                              }}>
                                            <RemoveRedEyeIcon sx={{color: "#9299BC",cursor:"pointer"}}/>
                                          </Link>  
                                          <DeleteIcon sx={{color: "#9299BC"}}
                                          onClick={(e)=>{
                                            e.stopPropagation();
                                            delIconClick(item)}}  
                                          />
                                        </Stack>

                                    </Stack>
                                  // </Link>   
                                );
                            }
                        })}

        <Box sx={{margin:"50px 0px 40px 0px"}}>
          <Box sx={{ display: "flex", justifyContent: "right" }}>
            {newRowData.length!=0 && 
            <Button variant="contained" sx={{background:"linear-gradient(90deg, #0F5DAE 0%, #009DCF 100.33%)"}}
              onClick={prvClick}>
                <ArrowBackIosIcon fontSize="small" />
                <Box sx={{ textTransform: "capitalize" }}>Previous</Box>
            </Button>}

            <Box sx={{background: "#E3E4EB",display: "flex",placeItems: "center",margin: " 0 10px",borderRadius: "5px"}}>
                <Box sx={{background: `white`,color: "black",width: "30px",borderRadius: "5px",margin: "0 10px",display: "grid",placeItems: "center"}}>{curpage}</Box>
                <Box sx={{color: "black",width: "30px",borderRadius: "5px",margin: "0 0px",display: "grid",placeItems: "center"}}>of</Box>
                <Box sx={{background: `white`,color: "black",width: "30px",borderRadius: "5px",margin: "0 10px",display: "grid",placeItems: "center"}}>{pages?.length}</Box>
            </Box>

            {newRowData.length!=0 && 
                <Button variant="contained" sx={{background:"linear-gradient(90deg, #0F5DAE 0%, #009DCF 100.33%)"}}
                    onClick={nextClick}>
                    <Box sx={{ textTransform: "capitalize" }}>Next</Box>
                    <ArrowForwardIosIcon fontSize="small" />
                </Button>
            }
          </Box>
        </Box>
      </Box>
    }
  </Box>
  }
  </Box>

  </Box>

  </Stack>
  </Stack>
</Stack>

    </>
  )
}

export default SuperAdminMember