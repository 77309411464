import { Box, Radio, TextField, Typography } from "@mui/material"
import { Stack } from "@mui/system"

import DoneIcon from '@mui/icons-material/Done';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import { useState, useContext} from "react";
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';

// modal
import * as React from 'react';
import Backdrop from '@mui/material/Backdrop';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Button from '@mui/material/Button';
import CloseIcon from '@mui/icons-material/Close';
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { defineProjectLayout } from "../../../connection/project/define_unit_layout";
import createProjectContext from "../../../context/createproject/context";
import { SuperAdminSidebar } from "../SidebarNavbar/SuperAdminSidebar";
import { SuperAdminNavbar } from "../SidebarNavbar/SuperAdminNavbar";
import { SAProjectSidebar } from "../SidebarNavbar/SAProjectSidebar";
const style = {
  position: 'absolute',
  borderRadius:"10px",
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: "400px",
  height:"300px",
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
};



export const ProjectLayoutSA = ()=>{
    const navigate = useNavigate()

    //input type and input 
    const [inputType, setInputType] = useState("") 
    const [input,setInput] = useState("")
    
    ////sq.ft and width and height
    //radio button 
    const [selectedValue, setSelectedValue] = useState('ft');
  
    const handleChange = (event) => {
      setSelectedValue(event.target.value);
    };
  
    const controlProps = (item) => ({
      checked: selectedValue === item,
      onChange: handleChange,
      value: item,
      name: 'color-radio-button-demo',
      inputProps: { 'aria-label': item }, 
    }); 
  
    // modal
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
      
    const [singleitems,setSingleItems] = useState(["Total Carpet Area (As per RERA)","Terrace Area","Enclosed Balcony Area","Other Usable Area","Total Usable Area","Total Scalable Area","Parking Area"])
    const [doubleItems, setDoubleItems] = useState(["Living Room Dimension","Dining Area Dimension","Kitchen Dimension"])
    const [singleDim_type, setSingleDim_type] = useState(["Sq.ft","Sq.ft","Sq.ft","Sq.ft","Sq.ft","Sq.ft","Sq.ft","Sq.ft","Sq.ft","Sq.ft","Sq.ft","Sq.ft"])
    const [doubleDim_type, setdoubleDim_type] = useState(["Sq.ft","Sq.ft","Sq.ft"])
  
    const cpContext = useContext(createProjectContext);
    console.log("cpContext", cpContext)
  
      // add items function 
      const handleDone = ()=>{
        console.log(input)
         if(input==="")return
         if(inputType=="single"){
          setSingleItems([...singleitems,input])
          setSingleDim_type([...singleDim_type,selectedValue])
          makeEmpty()
        }else if(inputType=="double"){
          setDoubleItems([...doubleItems,input])
          setdoubleDim_type([...doubleDim_type,selectedValue])
          console.log(doubleDim_type,selectedValue)
          makeEmpty()
        }
      }
      useEffect(()=>{
        handleDone()
      },[])
      const makeEmpty = ( )=>{
        handleClose();
        setInput("");
      }
  
      ////delete item function 
      const deleteSingleItem = (ind)=>{
          let arr = singleitems.filter((e,i)=>ind!==i)
          setSingleItems([...arr])
      }
      const deleteDoubleItem = (ind)=>{
          let arr  = doubleItems.filter((e,i)=>ind!==i)
          setDoubleItems([...arr])
      }
  
    return (
        <>
               
<Stack direction="row" sx={{width:"100%",height:"auto" }}>
      {/* 1st stack */}
      <Stack sx={{minHeight:"100vh",width:"20%",boxSizing:"border-box",}}> {/*for project directory 20% and for sales dashboard 225*/}
        {/* <SuperAdminSidebar active={"Builder Directory"}/> */}
        <SAProjectSidebar active={"Project Dashboard"}/>
      </Stack>

     {/* 2nd stack */}
     <Stack direction="column" sx={{ backgroundColor:"#f9f9f9",width:"80%", padding:"25px",boxSizing:"border-box",}}  >  {/*  width for main sidebar 78px and for 80px*/}
     <SuperAdminNavbar createProject={true} /> {/*send props for get popup to verify otp from builder */}
      


      <Typography sx={{marginTop:"20px", fontSize:"28px", fontWeight:"600", marginLeft:"10px", marginBottom:"50px"}}>
      Create New Project 
            </Typography>
     {/* actual content  */}

    <Stack sx={{ backgroundColor:"#f9f9f9"}}>

    <Stack direction="column"  sx={{  padding:"20px", backgroundColor:"#fff", marginRight:"0px"}}>
                    <Stack direction="row" justifyContent="start" alignItems="center" sx={{height:"100px", }}>
                           
                           <Stack direction="row" alignItems="center" spacing={1.5} sx={{ }}>
                             <Box  sx={{width:"29px", height:"29px",borderRadius:"50%", backgroundColor:"#FFA946", display:"flex", justifyContent:"center", alignItems:"center"}}>
                               <DoneIcon sx={{color:"#fff", fontSize:"20px"}}/>
                             </Box>
                               <Typography sx={{fontSize:"18px", fontWeight:"500"}}>Profile</Typography>
                               <Stack direction="row" alignItems="center" >
                                <Box sx={{width:"25px", height:"0px", borderBottom:"2px dashed orange"}}></Box>
                                <Box sx={{height:"10px", width:"10px",borderRadius:"50%",  backgroundColor:"orange"}}></Box>
                               </Stack>
                           </Stack>
                           <Stack direction="row" alignItems="center" spacing={1.5} sx={{ marginLeft:"20px"}}>
                             <Box  sx={{width:"29px", height:"29px",borderRadius:"50%", backgroundColor:"#FFA946", display:"flex", justifyContent:"center", alignItems:"center"}}>
                               <DoneIcon sx={{color:"#fff", fontSize:"20px"}}/>
                             </Box>
                               <Typography sx={{fontSize:"18px", fontWeight:"500"}}>Unit layout Items</Typography>
                               <Stack direction="row" alignItems="center" >
                                <Box sx={{width:"25px", height:"0px", borderBottom:"2px dashed #A2A3A6"}}></Box>
                                <Box sx={{height:"10px", width:"10px",borderRadius:"50%",  backgroundColor:"#A2A3A6"}}></Box>
                               </Stack>
                           </Stack>
                           <Stack direction="row" alignItems="center" spacing={1.5} sx={{ marginLeft:"20px"}}>
                             <Box  sx={{width:"29px", height:"29px",borderRadius:"50%", backgroundColor:"#A2A3A6", display:"flex", justifyContent:"center", alignItems:"center"}}>
                               <DoneIcon sx={{color:"#fff", fontSize:"20px"}}/>
                             </Box>
                               <Typography sx={{fontSize:"18px", fontWeight:"500"}}>Unit Pricing Items</Typography>
                               <Stack direction="row" alignItems="center" >
                                <Box sx={{width:"25px", height:"0px", borderBottom:"2px dashed #A2A3A6"}}></Box>
                                <Box sx={{height:"10px", width:"10px",borderRadius:"50%",  backgroundColor:"#A2A3A6"}}></Box>
                               </Stack>
                           </Stack>
                           <Stack direction="row" alignItems="center" spacing={1.5} sx={{ marginLeft:"20px"}}>
                             <Box  sx={{width:"29px", height:"29px",borderRadius:"50%", backgroundColor:"#A2A3A6", display:"flex", justifyContent:"center", alignItems:"center"}}>
                               <DoneIcon sx={{color:"#fff", fontSize:"20px"}}/>
                             </Box>
                               <Typography sx={{fontSize:"18px", fontWeight:"500"}}>Templates</Typography>
                              
                           </Stack>

                       </Stack>

                       <Typography sx={{color:"#FF6140", fontSize:"18px", fontWeight:500 , marginBottom:"20px"}}>Define Your Unit Layout Items*</Typography>

                       <Stack direction="row" justifyContent="start" alignItems="center" sx={{marginBottom:"20px"}}>
                       <Typography sx={{color:"#2979F2", fontSize:"18px", fontWeight:600, marginRight:"20px" }}>Items</Typography>
                       <AddIcon onClick={()=>{
                        handleOpen();
                        setInputType("single");

                        }} sx={{ color:"#2979F2"}}/>
                       <Box sx={{height:"30px", width:"30px",boxShadow:" 0px 2px 6px rgba(19, 18, 66, 0.07)"}}></Box>
                       <AddIcon sx={{ color:"#2979F2"}} onClick={()=>{
                        handleOpen();
                        setInputType("double");
                                                
                        }}/>
                       <Box sx={{height:"30px", width:"30px",boxShadow:" 0px 2px 6px rgba(19, 18, 66, 0.07)"}}></Box>
                       <Box sx={{height:"30px", width:"30px",boxShadow:" 0px 2px 6px rgba(19, 18, 66, 0.07)"}}></Box>
                       </Stack>
                          
                          {/* single items map over her */} 
                          {singleitems.map((name, index)=>{
                            return(
                              <>
                       <Stack direction="row" alignItems="center" spacing={3} sx={{marginBottom:"40px"}}>
                          <Box sx={{width:"30%",overflow:"hidden",wordWrap:"break-word"}}>
                            <Typography sx={{color:"#A2A3A6", fontSize:"18px", fontWeight:500}}>{name}</Typography>
                          </Box> 
                          <TextField
                             disabled
                            sx={{width:"188px", }}  
                            />
                            <Typography sx={{color:"#A2A3A6", fontSize:"18px", fontWeight:500}}>{singleDim_type[index]}</Typography>
                            <DeleteIcon
                             onClick={()=>{  
                              deleteSingleItem(index)
                             }}
                            sx={{color:"#A2A3A6"}}/>
                            <FileCopyIcon 
                            onClick={() =>  navigator.clipboard.writeText(name)}  
                            sx={{color:"#A2A3A6"}}/>
                       </Stack>
                              </>
                            )
                          })}
         
                          {/* double items map over her */}
                          {doubleItems.map((name,index)=>{
                            return(
                              <>
                          <Stack direction="row" alignItems="center" spacing={2} sx={{marginBottom:"20px"}}>
                          <Box sx={{width:"40%",overflow:"hidden",wordWrap:"break-word"}}>
                            <Typography sx={{color:"#A2A3A6", fontSize:"18px", fontWeight:500}}>{name}</Typography>
                          </Box>
                          <TextField
                          disabled
                            sx={{width:"188px", }}
                            />
                          <Typography sx={{color:"#A2A3A6", fontSize:"18px", fontWeight:500}}>Lenght({doubleDim_type[index]})</Typography>
                          <Typography sx={{color:"#A2A3A6", fontSize:"18px", fontWeight:500}}>{}</Typography>
                          <TextField
                          disabled
                            sx={{width:"150px", }}
                            />
                          <Typography sx={{color:"#A2A3A6", fontSize:"18px", fontWeight:500}}>Breadth({doubleDim_type[index]})</Typography>
                          <DeleteIcon 
                           onClick={()=>{
                            deleteDoubleItem(index)
                          }}
                          sx={{color:"#A2A3A6"}}/>
                            <FileCopyIcon 
                              onClick={() =>  navigator.clipboard.writeText(name)}
                            sx={{color:"#A2A3A6"}}/>
                          </Stack>
                              </>
                            )
                          })}


                          {/* modal show */}
                                           <Modal
                        aria-labelledby="transition-modal-title"
                        aria-describedby="transition-modal-description"
                        open={open}
                        onClose={handleClose}
                        closeAfterTransition
                        BackdropComponent={Backdrop}
                        BackdropProps={{
                          timeout: 500,
                        }}
                      >
                        <Fade in={open}>
                          <Box sx={{
                             position: 'absolute',
                             borderRadius:"16px",
                             top: '50%',
                             left: '50%',
                             transform: 'translate(-50%, -50%)',
                             width: "400px",
                             height:"300px",
                             bgcolor: 'background.paper',
                             boxShadow: 24,
                             p: 4,
                          }}>
                            <Stack direction="row" justifyContent="space-between">
                            <Typography id="transition-modal-title" variant="h6" component="h2" sx={{color:"#FF6140", fontSize:"18px", fontWeight:500}}>
                              Add Item
                            </Typography>
                             <CloseIcon onClick={handleClose}/>
                            </Stack>
                            <Stack direction="row" justifyContent="space-between" alignItems="center" sx={{mt:2,p:"20px"}}>
                            <Typography id="transition-modal-description" sx={{ color:"#A2A3A6", fontSize:"18px", fontWeight:500}}>
                            Input field name
                            </Typography>
                            <TextField sx={{width:"188px", }} type="text" onChange={(e)=>setInput(e.target.value)}/>
                            </Stack>
                            <Typography id="transition-modal-description" sx={{ color:"#A2A3A6", fontSize:"18px", fontWeight:500,pl:"20px"}}>
                            Units
                            </Typography>
                            {/* for single input  */}
                            {inputType=="single" && 
                            <Stack direction="row" justifyContent="start" alignItems="center" sx={{pt:"20px",pl:"10px", mb:"20px"}} >
                            <Radio 
                            name="type"
                            {...controlProps('ft')}
                                sx={{
                                    color: "linear-gradient(264.48deg, #79F1A4 -45.37%, #0E5CAD 88.97%)",
                                      color: "#0F5DAE",
                                    '&.Mui-checked': {},
                                  }}
                            />
                            <Typography sx={{color:"#170F49", fontSize:"18px", fontWeight:500}}>Ft.</Typography>
                            <Radio 
                             name="type"
                             {...controlProps('Sq.ft')}
                                sx={{
                                    color: "linear-gradient(264.48deg, #79F1A4 -45.37%, #0E5CAD 88.97%)",
                                      color: "#0F5DAE",
                                    '&.Mui-checked': {},
                                  }}
                            />
                            <Typography sx={{color:"#170F49", fontSize:"18px", fontWeight:500}}>Sq.ft</Typography>
                        </Stack>
                            }
                            

                            {/* for single input  */}
                            {inputType=="double" && 
                              <Stack direction="row" justifyContent="start" alignItems="center" sx={{pt:"20px",pl:"10px", mb:"20px"}} >
                              <Radio 
                              name="type"
                              {...controlProps('ft')}
                                  sx={{
                                      color: "linear-gradient(264.48deg, #79F1A4 -45.37%, #0E5CAD 88.97%)",
                                        color: "#0F5DAE",
                                      '&.Mui-checked': {},
                                    }}
                              />
                              <Typography sx={{color:"#170F49", fontSize:"18px", fontWeight:500}}>L x B (Ft)</Typography>
                              <Radio 
                               name="type"
                               {...controlProps('Sq.ft')}
                                  sx={{
                                      color: "linear-gradient(264.48deg, #79F1A4 -45.37%, #0E5CAD 88.97%)",
                                        color: "#0F5DAE",
                                      '&.Mui-checked': {},
                                    }}
                              />
                              <Typography sx={{color:"#170F49", fontSize:"18px", fontWeight:500}}>L x B (Sq.ft)</Typography>
                          </Stack>
  
                            }
                          

                             <Stack direction="row" alignItems="center" justifyContent="center">
                              <Button className="univercel_btn_class" size="large" sx={{color:"#fff",p:"15px 30px 15px 30px"}}
                                onClick={()=>handleDone()}
                              >Done</Button>
                             </Stack>
                          </Box>
                        </Fade>
                      </Modal>

                      <Stack direction="row" justifyContent="end" alignItems="center" sx={{marginTop:"50px"}}>
                          <Button size="large" className="univercel_btn_class" sx={{textTransform:"none", color:"#fff"}}
                           onClick={async()=>{
                            const newProjectData =  await defineProjectLayout(singleitems,singleDim_type,doubleItems,doubleDim_type,cpContext.createProjectState.project.id)
                            console.log("Patched project data",newProjectData);
                            cpContext.setcreateProjectState(newProjectData);
                            console.log(cpContext.createProjectState);
                            navigate("/super-admin-create-project-projectpricing")
                           }}
                          endIcon={<KeyboardDoubleArrowRightIcon/>} >Next</Button>
                         
                       </Stack>
                 
                    </Stack>

    </Stack>

    
      </Stack>
</Stack>
        </>
    )
}