import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  MenuItem,
  Drawer,
  Box,
  Button,
  Checkbox,
  FormControl,
  InputLabel,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { Stack } from "@mui/system";
import TuneOutlinedIcon from "@mui/icons-material/TuneOutlined";
import React, { useEffect, useState } from "react";
import { GridExpandMoreIcon } from "@mui/x-data-grid";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { api, Apiaddress } from "../../../utils/consts";
import { AuthService } from "../../../context/buyer/services/auth";
import RightSidebarTD from "./components/MembersDirectory/RightSidebarTD";
import TDcustomTable from "./components/TDcustomTabel";
import RestartAltIcon from "@mui/icons-material/RestartAlt";

function ActiveTeam({ curPage,setMemState,setTrigger,trigger,State,setState}) {

  const [teams, setTeams] = useState([]);
  // const [state, setState] = React.useState(false);
  const [totalMember,setTotalMember] = useState(null);
  const [startMember,setStartMember] = useState(0);
  const [expandedFilter, setExpandedFilter] = useState(false);
  const [curpage, setcurpage] = useState(1);
  const [matchText, setmatchText] = useState(
    "Let's get started, Please add your data"
  );

  const cpDetails=JSON.parse(localStorage.getItem('cpDetails'));
  let cpId=cpDetails?.id;
  let cpOrg=cpDetails?.channelPartnerOrg;
  let cp_type = cpDetails?.cp_type;
  let account_type= cpDetails?.account_type;

  const fetchTeams = async () => {
    const token = localStorage.getItem("crm_token");
    try {
      const headers = {
        Authorization: `JWT ${token}`,
      };
      const res = await axios.get(
        Apiaddress + `/api/role/teamofcp/?orgId=${cpOrg}&isUpdated=True&limit=${7}&offset=${startMember}`,
        { headers }
      );
      console.log(res.data.teams);
      if (res.data.teams == "template not found") {
        setTeams([]);
      } else {
        let team=res?.data?.teams;
        let members = []
        for (let i = 0 ; i< team.length; i++){
          if(curPage===1){
             if(team[i]?.id!==cpId){
              members.push(team[i])
             }
          }
        }
        setTeams(members);
        setTotalMember(res.data.total);
      }
    } catch (err) {
      console.log(err);
      setTeams([]);
    }
  };

  useEffect(() => {
    fetchTeams();
  }, [curPage,trigger]);

  //fitering data starts:====
  const [filterselect, setfilterselect] = useState();
  const [filtervalue, setfiltervalue] = useState();

  const handleChangeFilter = (event) => {
    setfilterselect(event.target.value);
    setfiltervalue("");
  };

  const fetchfilter = async () => {
    const token = localStorage.getItem("crm_token");
    try {
      const headers = {
        Authorization: `JWT ${token}`,
      };
      const res = await axios.get(
        Apiaddress + `/api/role/teamofcp/?orgId=${cpOrg}&isUpdated=True&limit=${7}&offset=${startMember}&filterkey=${filterselect}&filtervalue=${filtervalue}`,
        { headers }
      );
      console.log(res.data.teams);
      if (res.data.teams == "template not found") {
        setTeams([]);
      }
      else {
        console.log(res.data.teams);
        let team=res?.data?.teams;
        let members = []
        for (let i = 0 ; i< team.length; i++){
          if(curPage==1){
            if(team[i]?.id!==cpId){
              members.push(team[i])
            }
          }
        }
        setTeams(members);
        setTotalMember(res.data.total);
      }
    } catch (err) {
      console.log(err);
      setTeams([]);
    }
  };

  return (
    <>
    <Stack direction="row" justifyContent="space-between" alignItems="center" sx={{marginTop:"-25px"}}>
      <Stack sx={{}}>
      {(cp_type == "Individual" && account_type == "Org")?null:
        <Button
          onClick={() => setState(true)}
          className="univercel_btn_class"
          sx={{
            // marginRight:"30px",
            color: "#FFFFFF",
            textTransform: "none",
            margin: "3px",
            padding: "6px 24px",
            fontFamily: "Product Sans",
            fontSize: "16px",
            width:"150px"
          }}
        >
          Add Member
        </Button>}
      </Stack>
    
      <Stack
        direction="row"
        justifyContent="right"
        alignItems="center"
        sx={{
          width: "203px",
          height: "68px",
          borderRadius: "12px",
          color: "#3E4954",
        }}
        >
        <Accordion
          expanded={expandedFilter}
          sx={{ width: "150px", boxShadow: "none" }}
          >
          <AccordionSummary
            onClick={() => {
              setExpandedFilter(!expandedFilter);
              setStartMember(0);
            }}
            expandIcon={<GridExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            >
            <Typography sx={{ display: "flex", placeItems: "center" }}>
              <TuneOutlinedIcon sx={{ color: "#2F4CDD" }} />
              <Typography sx={{ marginLeft: "10px" }}>
                Fillters
              </Typography>
              </Typography>
              </AccordionSummary>
                  <AccordionDetails>
                    <Box sx={{ textAlign: "center" }}>
                      <FormControl sx={{ width: "118px" }}>
                        <InputLabel id="demo-simple-select-label">
                          Select
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={filterselect}
                          label="Select"
                          onChange={handleChangeFilter}
                        >
                          <MenuItem value={"profile__user__first_name"}>Name</MenuItem>
                          <MenuItem value={"profile__user__email"}>Email</MenuItem>
                          <MenuItem value={"profile__phone"}>Phone</MenuItem>
                          <MenuItem value={"cp_type"}>Role</MenuItem>
                        </Select>
                      </FormControl>
                      <TextField
                        sx={{ marginTop: "20px", fontSize: "8px" }}
                        placeholder="Value"
                        value={filtervalue}
                        onChange={(e) => {
                          setfiltervalue(e.target.value);
                        }}
                      />
                      <Button
                        onClick={() => {
                          setStartMember(0);
                          fetchfilter();
                          setExpandedFilter(false);
                          setmatchText(
                            "No results found with the applied Filters"
                          );
                        }}
                        variant="contained"
                        className="univercel_btn_class"
                        color="primary"
                        sx={{ margin: "20px 0px 10px 0px" }}
                      >
                        Submit
                      </Button>
                    </Box>
                  </AccordionDetails>
                </Accordion>
                {expandedFilter == false && filtervalue && (
                  <RestartAltIcon
                    sx={{ margin: "0px 10px", cursor: "pointer" }}
                    onClick={() => {
                      // setfilterselect("");
                      setfiltervalue("");
                      setStartMember(0);
                      setmatchText("Let's get started, Please add your data");
                      setcurpage(1);
                      setTrigger(!trigger);
                    }}
                  />
                )}
      </Stack>
    </Stack>
      {/* actual content  */}
      <Stack sx={{ backgroundColor: "#f9f9f9", height: "530px" }}>
        <TDcustomTable
          newRowData={teams}
          // setState={setState}
          setMemState={setMemState}
          setTotalMember={setTotalMember}
          totalMember={totalMember}
          setStartMember={setStartMember}
          startMember={startMember}
          setTrigger={setTrigger}
          trigger={trigger}
          setcurpage={setcurpage}
          curpage={curpage}
          matchText={matchText}
        />
      </Stack>
    </>
  );
}

export default ActiveTeam;
