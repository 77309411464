import { Box, Stack, Typography } from "@mui/material";
import React from "react";
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';

const PdfTwoPageTwo = ({quotationInfo,projectInfo,unitInfo,leadInfo}) => {
  return (
    <Box>
      <Typography sx={{ fontSize: "14px" }}>
         Quotation for {`(${leadInfo?.lead_name})`} of {`(${projectInfo?.project_name})`} Dated{" "}
        {`(${quotationInfo?.created?.substring(0, 10)})`}
      </Typography>
      <Typography sx={{ fontSize: "14px", my: "20px" }}>
        <span
          style={{
            borderBottom: "2px solid #000",
            display: "inline",
            fontSize: "16px",
            fontWeight: 400,
          }}
        >
          QUOTATION DETAILS:
        </span>{" "}
        <span
          style={{
            fontWeight: 400,
          }}
        >
        
        </span>
      </Typography>
      <Stack spacing={0.5}>
        {
          unitInfo?.charge?.map((ele)=>{
            return(
              <>
                <Typography sx={{}}>
                  <span style={{borderBottom: "1.5px solid #000",display: "inline",fontSize: "16px",fontWeight: 400,}}>{`${ele?.titlename} :`}</span>{" "}
                  <span style={{fontWeight: 400}}></span>
                </Typography>
                <Stack direction="row" sx={{width:"100%",display:"flex",flexWrap:"wrap"}}>
                {
                  ele?.field?.map((e)=>{ 
                    return(
                      <>
                        <Stack direction='row' sx={{margin:"0px 40px 10px 0px"}}>
                          <Typography sx={{}}>{`${e?.key} : ` }</Typography>
                          <CurrencyRupeeIcon sx={{fontWeight:"500",fontSize:"15px",lineHeight:"27px",marginTop:"5px"}}/>
                          <Typography sx={{}}>{`${e?.value}` }</Typography>  
                        </Stack>
                         <Stack direction='row' sx={{margin:"0px 40px 10px 0px"}}>
                          <Typography sx={{}}>{`${e?.key} : ` }</Typography>
                          <CurrencyRupeeIcon sx={{fontWeight:"500",fontSize:"15px",lineHeight:"27px",marginTop:"5px"}}/>
                          <Typography sx={{}}>{`${e?.value}` }</Typography>  
                        </Stack>
                      </>
                    )
                  })
                }
                </Stack>
                <Stack direction='row' sx={{paddingBottom:"20px"}}>
                  <Typography sx={{}}>{`Section Cost : ` }</Typography>
                  <CurrencyRupeeIcon sx={{fontWeight:"500",fontSize:"15px",lineHeight:"27px",marginTop:"5px"}}/>
                  <Typography sx={{}}>{`${ele?.sectioncost}` }</Typography>  
                </Stack>
              </>
            )
          })
        }
        <Typography>
          Discount/concession: (If there was no discount, this field should not
          show up) {""}
        </Typography>
        <Typography>GST: {""}</Typography>
        <Stack direction='row' sx={{}}>
          <Typography sx={{}}>Grand Total: </Typography>
          <CurrencyRupeeIcon sx={{fontWeight:"500",fontSize:"15px",lineHeight:"27px",marginTop:"5px"}}/>
          <Typography sx={{}}>{`${quotationInfo?.adjustment}` }</Typography>  
        </Stack>
        <Typography>Remarks: {""}</Typography>
      </Stack>
    </Box>
  );
};

export default PdfTwoPageTwo;
