import { AuthService } from "../auth"

class UnitsFetchingService{
    Auth:AuthService;
    constructor(e:AuthService){
        this.Auth = e;
    }

    getListByToken(){
        
    }

    getById(){
        
    }

}
export {UnitsFetchingService}