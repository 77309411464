import { Box, Button, Container, Typography } from "@mui/material";
import Slider from "react-slick";
import HeaderSliderBg from "../../Imgs/header-slider-bg.png";
import BuilderLandingHeaderSliderImg1 from "../../Imgs/buileder-landing-header-slider-img1.png";
import BuilderLandingHeaderSliderImg2 from "../../Imgs/buileder-landing-header-slider-img2.png";
import BuilderLandingHeaderSliderImg3 from "../../Imgs/buileder-landing-header-slider-img3.png";
import "./BuilderLandingHeader.css";

const BuilderLandingHeader = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
  };
  return (
    <Slider {...settings}>
      <Box
        sx={{
          backgroundImage: `url(${HeaderSliderBg})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "contain",
          backgroundPosition: "right",
          overflowX: "hidden",
        }}
      >
        <Container sx={{ py: "20px" }}>
          <Typography
            sx={{
              fontSize: { lg: "36px", md: "28px", xs: "24px" },
              textAlign: "center",
              fontWeight: 700,
              color: "#272D4E",
              fontFamily: "Product Sans",
            }}
          >
            Welcome, Builders! Let’s envision your growth.
          </Typography>
          <Box
            sx={{
              display: "flex",
              gap: { xs: "20px", md: "30px" },
              alignItems: "center",
              flexDirection: { xs: "column", md: "row" },
            }}
          >
            <Box
              sx={{
                width: { xs: "100%", md: "50%" },
                textAlign: { xs: "center", md: "left" },
              }}
            >
              <Typography
                data-aos="fade-down"
                sx={{
                  color: "#0085FF",
                  fontSize: { lg: "28px", md: "24px", xs: "20px" },
                  fontFamily: "Product Sans",
                  fontWeight: 600,
                  mt: "10px",
                }}
              >
                Real Estate CRM Software
              </Typography>
              <Typography
                data-aos="fade-right"
                data-aos-delay="400"
                sx={{
                  color: "#272D4E",
                  fontSize: "44px",
                  fontWeight: 700,
                  fontFamily: "Circular Std",
                  mt: "10px",
                  lineHeight: "1.1",
                  display: { xs: "block", md: "none" },
                }}
              >
                Digitize & Grow <br /> Your Business
              </Typography>
              <Typography
                data-aos="fade-right"
                data-aos-delay="400"
                sx={{
                  color: "#272D4E",
                  fontSize: { md: "64px" },
                  fontWeight: 700,
                  fontFamily: "Circular Std",
                  mt: "10px",
                  lineHeight: "1.1",
                  display: { xs: "none", md: "block" },
                }}
              >
                Digitize & Grow Your Business
              </Typography>
              <Typography
                data-aos="fade-zoom-in"
                data-aos-easing="ease-in-back"
                data-aos-delay="400"
                data-aos-offset="0"
                sx={{
                  color: "#343434",
                  fontSize: { lg: "24px", md: "18px", xs: "14px" },
                  fontFamily: "Product Sans",
                  mt: "10px",
                  display: { xs: "none", md: "block" },
                }}
              >
                With a powerfully integrated Real Estate technology solution and
                a cohesive ecosystem.
              </Typography>
              <Typography
                data-aos="fade-zoom-in"
                data-aos-easing="ease-in-back"
                data-aos-delay="400"
                data-aos-offset="0"
                sx={{
                  color: "#343434",
                  fontSize: { lg: "24px", md: "18px", xs: "12px" },
                  fontFamily: "Product Sans",
                  mt: "10px",
                  display: { xs: "block", md: "none" },
                }}
              >
                With a powerfully integrated Real Estate technology <br />{" "}
                solution and a cohesive ecosystem.
              </Typography>
              <Button
                data-aos="zoom-out-up"
                data-aos-delay="400"
                sx={{
                  background:
                    "linear-gradient(97.85deg, #FFAF2A 0.95%, #FFC666 100%)",
                  fontWeight: 500,
                  fontSize: { lg: "28px", xs: "16px" },
                  boxShadow: "rgba(242, 178, 73, 0.59) 0px 9px 12px",
                  borderRadius: "10px",
                  color: "#06386B",
                  textTransform: "none",
                  padding: "5px 30px",
                  fontFamily: "Product Sans",
                  mt: "20px",
                  "&:hover": {
                    background:
                      "linear-gradient(97.85deg, #FFAF2A 0.95%, #FFC666 100%)",
                    boxShadow: "rgba(242, 178, 73, 0.59) 10px 12px 16px",
                  },
                }}
              >
                Book A Demo
              </Button>
            </Box>
            <Box
              data-aos="fade-left"
              data-aos-delay="800"
              sx={{
                width: { xs: "100%", md: "50%" },
              }}
            >
              <img
                className="header-img"
                src={BuilderLandingHeaderSliderImg1}
                alt=""
              />
            </Box>
          </Box>
        </Container>
      </Box>
      <Box
        sx={{
          backgroundImage: `url(${HeaderSliderBg})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "contain",
          backgroundPosition: "right",
          overflowX: "hidden",
        }}
      >
        <Container sx={{ py: "20px" }}>
          <Box
            sx={{
              display: "flex",
              gap: { xs: "20px", md: "30px" },
              alignItems: "center",
              flexDirection: { xs: "column", md: "row" },
            }}
          >
            <Box
              sx={{
                width: { xs: "100%", md: "50%" },
                textAlign: { xs: "center", md: "left" },
              }}
            >
              <Typography
                data-aos="fade-down"
                sx={{
                  color: "#0085FF",
                  fontSize: { lg: "28px", md: "24px", xs: "20px" },
                  fontFamily: "Product Sans",
                  fontWeight: 600,
                  mt: "10px",
                }}
              >
                One-Stop Solution
              </Typography>
              <Typography
                data-aos="fade-right"
                data-aos-delay="400"
                sx={{
                  color: "#272D4E",
                  fontSize: "44px",
                  fontWeight: 700,
                  fontFamily: "Circular Std",
                  mt: "10px",
                  lineHeight: "1.1",
                  display: { xs: "block" },
                }}
              >
                Efficiently Manage
              </Typography>
              <Typography
                data-aos="fade-zoom-in"
                data-aos-easing="ease-in-back"
                data-aos-delay="400"
                data-aos-offset="0"
                sx={{
                  color: "#343434",
                  fontSize: { lg: "24px", md: "18px", xs: "14px" },
                  fontFamily: "Product Sans",
                  mt: "10px",
                  display: { xs: "none", md: "block" },
                }}
              >
                Your projects, sales workflows, Channel Partners, and more....
              </Typography>
              <Typography
                data-aos="fade-zoom-in"
                data-aos-easing="ease-in-back"
                data-aos-delay="400"
                data-aos-offset="0"
                sx={{
                  color: "#343434",
                  fontSize: { lg: "24px", md: "18px", xs: "12px" },
                  fontFamily: "Product Sans",
                  mt: "10px",
                  display: { xs: "block", md: "none" },
                }}
              >
                Your projects, sales workflows, Channel Partners, and....
              </Typography>
              <Button
                data-aos="zoom-out-up"
                data-aos-delay="400"
                sx={{
                  background:
                    "linear-gradient(97.85deg, #FFAF2A 0.95%, #FFC666 100%)",
                  fontWeight: 500,
                  fontSize: { lg: "28px", xs: "16px" },
                  boxShadow: "rgba(242, 178, 73, 0.59) 0px 9px 12px",
                  borderRadius: "10px",
                  color: "#06386B",
                  textTransform: "none",
                  padding: "5px 30px",
                  fontFamily: "Product Sans",
                  mt: "20px",
                  "&:hover": {
                    background:
                      "linear-gradient(97.85deg, #FFAF2A 0.95%, #FFC666 100%)",
                    boxShadow: "rgba(242, 178, 73, 0.59) 10px 12px 16px",
                  },
                }}
              >
                Book A Demo
              </Button>
            </Box>
            <Box
              data-aos="fade-left"
              data-aos-delay="800"
              sx={{
                width: { xs: "100%", md: "50%" },
              }}
            >
              <img
                className="header-img"
                src={BuilderLandingHeaderSliderImg2}
                alt=""
              />
            </Box>
          </Box>
        </Container>
      </Box>
      <Box
        sx={{
          backgroundImage: `url(${HeaderSliderBg})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "contain",
          backgroundPosition: "right",
          overflowX: "hidden",
        }}
      >
        <Container sx={{ py: "20px" }}>
          <Box
            sx={{
              display: "flex",
              gap: { xs: "20px", md: "30px" },
              alignItems: "center",
              flexDirection: { xs: "column", md: "row" },
            }}
          >
            <Box
              sx={{
                width: { xs: "100%", md: "50%" },
                textAlign: { xs: "center", md: "left" },
              }}
            >
              <Typography
                data-aos="fade-down"
                sx={{
                  color: "#0085FF",
                  fontSize: { lg: "28px", md: "24px", xs: "20px" },
                  fontFamily: "Product Sans",
                  fontWeight: 600,
                  mt: "10px",
                }}
              >
                Seamless Experience
              </Typography>
              <Typography
                data-aos="fade-right"
                data-aos-delay="400"
                sx={{
                  color: "#272D4E",
                  fontSize: "44px",
                  fontWeight: 700,
                  fontFamily: "Circular Std",
                  mt: "10px",
                  lineHeight: "1.1",
                  display: { xs: "block", md: "none" },
                }}
              >
                Enhance Home <br /> Buyers’ Trust
              </Typography>
              <Typography
                data-aos="fade-right"
                data-aos-delay="400"
                sx={{
                  color: "#272D4E",
                  fontSize: { md: "64px" },
                  fontWeight: 700,
                  fontFamily: "Circular Std",
                  mt: "10px",
                  lineHeight: "1.1",
                  display: { xs: "none", md: "block" },
                }}
              >
                Enhance Home Buyers’ Trust
              </Typography>
              <Typography
                data-aos="fade-zoom-in"
                data-aos-easing="ease-in-back"
                data-aos-delay="400"
                data-aos-offset="0"
                sx={{
                  color: "#343434",
                  fontSize: { lg: "24px", md: "18px", xs: "14px" },
                  fontFamily: "Product Sans",
                  mt: "10px",
                  display: { xs: "none", md: "block" },
                }}
              >
                With deepened customer relationships, transparency, and
                delightful experience.
              </Typography>
              <Typography
                data-aos="fade-zoom-in"
                data-aos-easing="ease-in-back"
                data-aos-delay="400"
                data-aos-offset="0"
                sx={{
                  color: "#343434",
                  fontSize: { lg: "24px", md: "18px", xs: "12px" },
                  fontFamily: "Product Sans",
                  mt: "10px",
                  display: { xs: "block", md: "none" },
                }}
              >
                With deepened customer relationships, transparency, and
                delightful experience.
              </Typography>
              <Button
                data-aos="zoom-out-up"
                data-aos-delay="400"
                sx={{
                  background:
                    "linear-gradient(97.85deg, #FFAF2A 0.95%, #FFC666 100%)",
                  fontWeight: 500,
                  fontSize: { lg: "28px", xs: "16px" },
                  boxShadow: "rgba(242, 178, 73, 0.59) 0px 9px 12px",
                  borderRadius: "10px",
                  color: "#06386B",
                  textTransform: "none",
                  padding: "5px 30px",
                  fontFamily: "Product Sans",
                  mt: "20px",
                  "&:hover": {
                    background:
                      "linear-gradient(97.85deg, #FFAF2A 0.95%, #FFC666 100%)",
                    boxShadow: "rgba(242, 178, 73, 0.59) 10px 12px 16px",
                  },
                }}
              >
                Book A Demo
              </Button>
            </Box>
            <Box
              data-aos="fade-left"
              data-aos-delay="800"
              sx={{
                width: { xs: "100%", md: "50%" },
              }}
            >
              <img
                className="header-img"
                src={BuilderLandingHeaderSliderImg3}
                alt=""
              />
            </Box>
          </Box>
        </Container>
      </Box>

      {/* <Box
        sx={{
          backgroundImage: `url(${HeaderSliderBg})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "contain",
          backgroundPosition: "right", overflowX: "hidden",
        }}
      >
        <Container sx={{ py: "20px" }}>
          <Box
            sx={{
              display: "flex",
              gap: { xs: "20px", md: "30px" },
              alignItems: "center",
              flexDirection: { xs: "column", md: "row" },
              
            }}
          >
            <Box
              sx={{
                width: { xs: "100%", md: "50%" },
                textAlign: { xs: "center", md: "left" },
              }}
            >
              <Typography
                data-aos="fade-down"
                sx={{
                  color: "#0085FF",
                  fontSize: { lg: "44px", md: "38px", xs: "28px" },
                  fontFamily: "Product Sans",
                  fontWeight: 600,
                  mt: "10px",
                }}
              >
                Real Estate CRM Software
              </Typography>
              <Typography
                data-aos="fade-right"
                data-aos-delay="400"
                sx={{
                  color: "#272D4E",
                  fontSize: "44px",
                  fontWeight: 700,
                  fontFamily: "Product Sans",
                  mt: "10px",
                  lineHeight: "1.1",
                  display: { xs: "block", md: "none" },
                }}
              >
                Digitize & Grow <br /> Your Business
              </Typography>
              <Typography
                data-aos="fade-right"
                data-aos-delay="400"
                sx={{
                  color: "#272D4E",
                  fontSize: { md: "64px" },
                  fontWeight: 700,
                  fontFamily: "Product Sans",
                  mt: "10px",
                  lineHeight: "1.1",
                  display: { xs: "none", md: "block" },
                }}
              >
                Digitize & Grow Your Business
              </Typography>
              <Typography
                data-aos="fade-zoom-in"
                data-aos-easing="ease-in-back"
                data-aos-delay="400"
                data-aos-offset="0"
                sx={{
                  color: "#343434",
                  fontSize: { lg: "24px", md: "18px", xs: "14px" },
                  fontFamily: "Product Sans",
                  mt: "10px",
                  display: { xs: "none", md: "block" },
                }}
              >
                With a powerfully integrated Real Estate technology solution and
                a cohesive ecosystem.
              </Typography>
              <Typography
                data-aos="fade-zoom-in"
                data-aos-easing="ease-in-back"
                data-aos-delay="400"
                data-aos-offset="0"
                sx={{
                  color: "#343434",
                  fontSize: { lg: "24px", md: "18px", xs: "12px" },
                  fontFamily: "Product Sans",
                  mt: "10px",
                  display: { xs: "block", md: "none" },
                }}
              >
                With a powerfully integrated Real Estate technology <br />{" "}
                solution and a cohesive ecosystem.
              </Typography>
              <Button
                data-aos="zoom-out-up"
                data-aos-delay="400"
                sx={{
                  background: "#FFC503",
                  fontWeight: 500,
                  fontSize: { lg: "28px", xs: "16px" },
                  boxShadow: "0px 5px 30px rgba(0, 0, 0, 0.05)",
                  borderRadius: "10px",
                  color: "#06386B",
                  textTransform: "none",
                  padding: "5px 30px",
                  fontFamily: "Product Sans",
                  mt: "20px",
                  "&:hover": {
                    background: "#FFC503",
                  },
                }}
              >
                Book A Demo
              </Button>
            </Box>
            <Box
              data-aos="fade-left"
              data-aos-delay="800"
              sx={{
                width: { xs: "100%", md: "50%" },
              }}
            >
              <img
                className="header-img"
                src={BuilderLandingHeaderSliderImg1}
                alt=""
              />
            </Box>
          </Box>
        </Container>
      </Box>
      <Box
        sx={{
          background: "linear-gradient(90deg, #0F5DAE 0%, #009DCF 100.33%)",
        }}
      >
        <Box
          sx={{
            backgroundImage: `url(${HeaderOverlay})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "contain",
            backgroundPosition: "right",
          }}
        >
          <Container sx={{ py: "20px" }}>
            <CommonPagesNavbar />

            <Box
              sx={{
                display: "flex",
                gap: { xs: "20px", md: "30px" },
                alignItems: "center",
                flexDirection: { xs: "column", md: "row" },
                
              }}
            >
              <Box
                sx={{
                  width: { xs: "100%", md: "50%" },
                  textAlign: { xs: "center", md: "left" },
                }}
              >
                <Typography
                  data-aos="fade-down"
                  sx={{
                    color: "#FFC503",
                    fontSize: { lg: "40px", md: "32px", xs: "24px" },
                    fontFamily: "Product Sans",
                    fontWeight: 600,
                    mt: "10px",
                  }}
                >
                  One-Stop Solution
                </Typography>
                <Typography
                  data-aos="fade-right"
                  data-aos-delay="400"
                  sx={{
                    color: "#FFF",
                    fontSize: "44px",
                    fontWeight: 700,
                    fontFamily: "Product Sans",
                    mt: "10px",
                    lineHeight: "1.1",
                    display: { xs: "block", md: "none" },
                  }}
                >
                  Efficiently Manage
                </Typography>
                <Typography
                  data-aos="fade-right"
                  data-aos-delay="400"
                  sx={{
                    color: "#FFF",
                    fontSize: { md: "64px" },
                    fontWeight: 700,
                    fontFamily: "Product Sans",
                    mt: "10px",
                    lineHeight: "1.1",
                    display: { xs: "none", md: "block" },
                  }}
                >
                  Efficiently Manage
                </Typography>
                <Typography
                  data-aos="fade-zoom-in"
                  data-aos-easing="ease-in-back"
                  data-aos-delay="400"
                  data-aos-offset="0"
                  sx={{
                    color: "#FFF",
                    fontSize: { lg: "24px", md: "18px", xs: "14px" },
                    fontFamily: "Product Sans",
                    mt: "10px",
                    display: { xs: "none", md: "block" },
                  }}
                >
                  Your projects, sales workflows, Channel <br /> Partners, and
                  more....
                </Typography>
                <Typography
                  data-aos="fade-zoom-in"
                  data-aos-easing="ease-in-back"
                  data-aos-delay="400"
                  data-aos-offset="0"
                  sx={{
                    color: "#FFF",
                    fontSize: { lg: "24px", md: "18px", xs: "12px" },
                    fontFamily: "Product Sans",
                    mt: "10px",
                    display: { xs: "block", md: "none" },
                  }}
                >
                  Your projects, sales workflows, <br /> Channel Partners, and
                  more....
                </Typography>
                <Button
                  data-aos="zoom-out-up"
                  data-aos-delay="400"
                  sx={{
                    background: "#FFC503",
                    fontWeight: 500,
                    fontSize: { lg: "28px", xs: "16px" },
                    boxShadow: "0px 5px 30px rgba(0, 0, 0, 0.05)",
                    borderRadius: "10px",
                    color: "#06386B",
                    textTransform: "none",
                    padding: "5px 30px",
                    fontFamily: "Product Sans",
                    mt: "20px",
                    "&:hover": {
                      background: "#FFC503",
                    },
                  }}
                >
                  Book A Demo
                </Button>
              </Box>
              <Box
                data-aos="fade-left"
                data-aos-delay="800"
                sx={{
                  width: { xs: "100%", md: "50%" },
                }}
              >
                <img
                  className="header-img"
                  src={BuilderLandingHeaderSliderImg2}
                  alt=""
                />
              </Box>
            </Box>
          </Container>
        </Box>
      </Box>
      <Box
        sx={{
          background: "linear-gradient(90deg, #0F5DAE 0%, #009DCF 100.33%)",
        }}
      >
        <Box
          sx={{
            backgroundImage: `url(${HeaderOverlay})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "contain",
            backgroundPosition: "right",
          }}
        >
          <Container sx={{ py: "20px" }}>
            <CommonPagesNavbar />

            <Box
              sx={{
                display: "flex",
                gap: { xs: "20px", md: "30px" },
                alignItems: "center",
                flexDirection: { xs: "column", md: "row" },
                
              }}
            >
              <Box
                sx={{
                  width: { xs: "100%", md: "50%" },
                  textAlign: { xs: "center", md: "left" },
                }}
              >
                <Typography
                  data-aos="fade-down"
                  sx={{
                    color: "#FFC503",
                    fontSize: { lg: "44px", md: "38px", xs: "28px" },
                    fontFamily: "Product Sans",
                    fontWeight: 600,
                    mt: "10px",
                  }}
                >
                  Seamless Experience
                </Typography>
                <Typography
                  data-aos="fade-right"
                  data-aos-delay="400"
                  sx={{
                    color: "#FFF",
                    fontSize: "44px",
                    fontWeight: 700,
                    fontFamily: "Product Sans",
                    mt: "10px",
                    lineHeight: "1.1",
                    display: { xs: "block", md: "none" },
                  }}
                >
                  Enhance Home Buyers’ Trust
                </Typography>
                <Typography
                  data-aos="fade-right"
                  data-aos-delay="400"
                  sx={{
                    color: "#FFF",
                    fontSize: { md: "64px" },
                    fontWeight: 700,
                    fontFamily: "Product Sans",
                    mt: "10px",
                    lineHeight: "1.1",
                    display: { xs: "none", md: "block" },
                  }}
                >
                  Enhance Home Buyers’ Trust
                </Typography>
                <Typography
                  data-aos="fade-zoom-in"
                  data-aos-easing="ease-in-back"
                  data-aos-delay="400"
                  data-aos-offset="0"
                  sx={{
                    color: "#FFF",
                    fontSize: { lg: "24px", md: "18px", xs: "14px" },
                    fontFamily: "Product Sans",
                    mt: "10px",
                    display: { xs: "none", md: "block" },
                  }}
                >
                  With deepened customer relationships, <br /> transparency, and
                  delightful experience.
                </Typography>
                <Typography
                  data-aos="fade-zoom-in"
                  data-aos-easing="ease-in-back"
                  data-aos-delay="400"
                  data-aos-offset="0"
                  sx={{
                    color: "#FFF",
                    fontSize: { lg: "24px", md: "18px", xs: "12px" },
                    fontFamily: "Product Sans",
                    mt: "10px",
                    display: { xs: "block", md: "none" },
                  }}
                >
                  With deepened customer relationships, <br /> transparency, and
                  delightful experience.
                </Typography>
                <Button
                  data-aos="zoom-out-up"
                  data-aos-delay="400"
                  sx={{
                    background: "#FFC503",
                    fontWeight: 500,
                    fontSize: { lg: "28px", xs: "16px" },
                    boxShadow: "0px 5px 30px rgba(0, 0, 0, 0.05)",
                    borderRadius: "10px",
                    color: "#06386B",
                    textTransform: "none",
                    padding: "5px 30px",
                    fontFamily: "Product Sans",
                    mt: "20px",
                    "&:hover": {
                      background: "#FFC503",
                    },
                  }}
                >
                  Book A Demo
                </Button>
              </Box>
              <Box
                data-aos="fade-left"
                data-aos-delay="800"
                sx={{
                  width: { xs: "100%", md: "50%" },
                }}
              >
                <img
                  className="header-img"
                  src={BuilderLandingHeaderSliderImg3}
                  alt=""
                />
              </Box>
            </Box>
          </Container>
        </Box>
      </Box> */}
    </Slider>
  );
};

export default BuilderLandingHeader;

{
  /* <Slider {...settings}>
  <Box
    sx={{
      background: "linear-gradient(90deg, #0F5DAE 0%, #009DCF 100.33%)",
    }}
  >
    <Box
      sx={{
        backgroundImage: `url(${HeaderOverlay})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "contain",
        backgroundPosition: "right",
      }}
    >
      <Container sx={{ py: "20px" }}>
        <CommonPagesNavbar />

        <Box
          sx={{
            display: "flex",
            gap: { xs: "20px", md: "30px" },
            alignItems: "center",
            flexDirection: { xs: "column", md: "row" },
            
          }}
        >
          <Box
            sx={{
              width: { xs: "100%", md: "50%" },
              textAlign: { xs: "center", md: "left" },
            }}
          >
            <Typography
              data-aos="fade-down"
              sx={{
                color: "#FFC503",
                fontSize: { lg: "44px", md: "38px", xs: "28px" },
                fontFamily: "Product Sans",
                fontWeight: 600,
                mt: "10px",
              }}
            >
              Real Estate CRM Software
            </Typography>
            <Typography
              data-aos="fade-right"
              data-aos-delay="400"
              sx={{
                color: "#FFF",
                fontSize: "44px",
                fontWeight: 700,
                fontFamily: "Product Sans",
                mt: "10px",
                lineHeight: "1.1",
                display: { xs: "block", md: "none" },
              }}
            >
              Digitize & Grow <br /> Your Business
            </Typography>
            <Typography
              data-aos="fade-right"
              data-aos-delay="400"
              sx={{
                color: "#FFF",
                fontSize: { md: "64px" },
                fontWeight: 700,
                fontFamily: "Product Sans",
                mt: "10px",
                lineHeight: "1.1",
                display: { xs: "none", md: "block" },
              }}
            >
              Digitize & Grow Your Business
            </Typography>
            <Typography
              data-aos="fade-zoom-in"
              data-aos-easing="ease-in-back"
              data-aos-delay="400"
              data-aos-offset="0"
              sx={{
                color: "#FFF",
                fontSize: { lg: "24px", md: "18px", xs: "14px" },
                fontFamily: "Product Sans",
                mt: "10px",
                display: { xs: "none", md: "block" },
              }}
            >
              With a powerfully integrated Real Estate technology solution and a
              cohesive ecosystem.
            </Typography>
            <Typography
              data-aos="fade-zoom-in"
              data-aos-easing="ease-in-back"
              data-aos-delay="400"
              data-aos-offset="0"
              sx={{
                color: "#FFF",
                fontSize: { lg: "24px", md: "18px", xs: "12px" },
                fontFamily: "Product Sans",
                mt: "10px",
                display: { xs: "block", md: "none" },
              }}
            >
              With a powerfully integrated Real Estate technology <br />{" "}
              solution and a cohesive ecosystem.
            </Typography>
            <Button
              data-aos="zoom-out-up"
              data-aos-delay="400"
              sx={{
                background: "#FFC503",
                fontWeight: 500,
                fontSize: { lg: "28px", xs: "16px" },
                boxShadow: "0px 5px 30px rgba(0, 0, 0, 0.05)",
                borderRadius: "10px",
                color: "#06386B",
                textTransform: "none",
                padding: "5px 30px",
                fontFamily: "Product Sans",
                mt: "20px",
                "&:hover": {
                  background: "#FFC503",
                },
              }}
            >
              Book A Demo
            </Button>
          </Box>
          <Box
            data-aos="fade-left"
            data-aos-delay="800"
            sx={{
              width: { xs: "100%", md: "50%" },
            }}
          >
            <img
              className="header-img"
              src={BuilderLandingHeaderSliderImg1}
              alt=""
            />
          </Box>
        </Box>
      </Container>
    </Box>
  </Box>
  <Box
    sx={{
      background: "linear-gradient(90deg, #0F5DAE 0%, #009DCF 100.33%)",
    }}
  >
    <Box
      sx={{
        backgroundImage: `url(${HeaderOverlay})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "contain",
        backgroundPosition: "right",
      }}
    >
      <Container sx={{ py: "20px" }}>
        <CommonPagesNavbar />

        <Box
          sx={{
            display: "flex",
            gap: { xs: "20px", md: "30px" },
            alignItems: "center",
            flexDirection: { xs: "column", md: "row" },
            
          }}
        >
          <Box
            sx={{
              width: { xs: "100%", md: "50%" },
              textAlign: { xs: "center", md: "left" },
            }}
          >
            <Typography
              data-aos="fade-down"
              sx={{
                color: "#FFC503",
                fontSize: { lg: "40px", md: "32px", xs: "24px" },
                fontFamily: "Product Sans",
                fontWeight: 600,
                mt: "10px",
              }}
            >
              One-Stop Solution
            </Typography>
            <Typography
              data-aos="fade-right"
              data-aos-delay="400"
              sx={{
                color: "#FFF",
                fontSize: "44px",
                fontWeight: 700,
                fontFamily: "Product Sans",
                mt: "10px",
                lineHeight: "1.1",
                display: { xs: "block", md: "none" },
              }}
            >
              Efficiently Manage
            </Typography>
            <Typography
              data-aos="fade-right"
              data-aos-delay="400"
              sx={{
                color: "#FFF",
                fontSize: { md: "64px" },
                fontWeight: 700,
                fontFamily: "Product Sans",
                mt: "10px",
                lineHeight: "1.1",
                display: { xs: "none", md: "block" },
              }}
            >
              Efficiently Manage
            </Typography>
            <Typography
              data-aos="fade-zoom-in"
              data-aos-easing="ease-in-back"
              data-aos-delay="400"
              data-aos-offset="0"
              sx={{
                color: "#FFF",
                fontSize: { lg: "24px", md: "18px", xs: "14px" },
                fontFamily: "Product Sans",
                mt: "10px",
                display: { xs: "none", md: "block" },
              }}
            >
              Your projects, sales workflows, Channel <br /> Partners, and
              more....
            </Typography>
            <Typography
              data-aos="fade-zoom-in"
              data-aos-easing="ease-in-back"
              data-aos-delay="400"
              data-aos-offset="0"
              sx={{
                color: "#FFF",
                fontSize: { lg: "24px", md: "18px", xs: "12px" },
                fontFamily: "Product Sans",
                mt: "10px",
                display: { xs: "block", md: "none" },
              }}
            >
              Your projects, sales workflows, <br /> Channel Partners, and
              more....
            </Typography>
            <Button
              data-aos="zoom-out-up"
              data-aos-delay="400"
              sx={{
                background: "#FFC503",
                fontWeight: 500,
                fontSize: { lg: "28px", xs: "16px" },
                boxShadow: "0px 5px 30px rgba(0, 0, 0, 0.05)",
                borderRadius: "10px",
                color: "#06386B",
                textTransform: "none",
                padding: "5px 30px",
                fontFamily: "Product Sans",
                mt: "20px",
                "&:hover": {
                  background: "#FFC503",
                },
              }}
            >
              Book A Demo
            </Button>
          </Box>
          <Box
            data-aos="fade-left"
            data-aos-delay="800"
            sx={{
              width: { xs: "100%", md: "50%" },
            }}
          >
            <img
              className="header-img"
              src={BuilderLandingHeaderSliderImg2}
              alt=""
            />
          </Box>
        </Box>
      </Container>
    </Box>
  </Box>
  <Box
    sx={{
      background: "linear-gradient(90deg, #0F5DAE 0%, #009DCF 100.33%)",
    }}
  >
    <Box
      sx={{
        backgroundImage: `url(${HeaderOverlay})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "contain",
        backgroundPosition: "right",
      }}
    >
      <Container sx={{ py: "20px" }}>
        <CommonPagesNavbar />

        <Box
          sx={{
            display: "flex",
            gap: { xs: "20px", md: "30px" },
            alignItems: "center",
            flexDirection: { xs: "column", md: "row" },
            
          }}
        >
          <Box
            sx={{
              width: { xs: "100%", md: "50%" },
              textAlign: { xs: "center", md: "left" },
            }}
          >
            <Typography
              data-aos="fade-down"
              sx={{
                color: "#FFC503",
                fontSize: { lg: "44px", md: "38px", xs: "28px" },
                fontFamily: "Product Sans",
                fontWeight: 600,
                mt: "10px",
              }}
            >
              Seamless Experience
            </Typography>
            <Typography
              data-aos="fade-right"
              data-aos-delay="400"
              sx={{
                color: "#FFF",
                fontSize: "44px",
                fontWeight: 700,
                fontFamily: "Product Sans",
                mt: "10px",
                lineHeight: "1.1",
                display: { xs: "block", md: "none" },
              }}
            >
              Enhance Home Buyers’ Trust
            </Typography>
            <Typography
              data-aos="fade-right"
              data-aos-delay="400"
              sx={{
                color: "#FFF",
                fontSize: { md: "64px" },
                fontWeight: 700,
                fontFamily: "Product Sans",
                mt: "10px",
                lineHeight: "1.1",
                display: { xs: "none", md: "block" },
              }}
            >
              Enhance Home Buyers’ Trust
            </Typography>
            <Typography
              data-aos="fade-zoom-in"
              data-aos-easing="ease-in-back"
              data-aos-delay="400"
              data-aos-offset="0"
              sx={{
                color: "#FFF",
                fontSize: { lg: "24px", md: "18px", xs: "14px" },
                fontFamily: "Product Sans",
                mt: "10px",
                display: { xs: "none", md: "block" },
              }}
            >
              With deepened customer relationships, <br /> transparency, and
              delightful experience.
            </Typography>
            <Typography
              data-aos="fade-zoom-in"
              data-aos-easing="ease-in-back"
              data-aos-delay="400"
              data-aos-offset="0"
              sx={{
                color: "#FFF",
                fontSize: { lg: "24px", md: "18px", xs: "12px" },
                fontFamily: "Product Sans",
                mt: "10px",
                display: { xs: "block", md: "none" },
              }}
            >
              With deepened customer relationships, <br /> transparency, and
              delightful experience.
            </Typography>
            <Button
              data-aos="zoom-out-up"
              data-aos-delay="400"
              sx={{
                background: "#FFC503",
                fontWeight: 500,
                fontSize: { lg: "28px", xs: "16px" },
                boxShadow: "0px 5px 30px rgba(0, 0, 0, 0.05)",
                borderRadius: "10px",
                color: "#06386B",
                textTransform: "none",
                padding: "5px 30px",
                fontFamily: "Product Sans",
                mt: "20px",
                "&:hover": {
                  background: "#FFC503",
                },
              }}
            >
              Book A Demo
            </Button>
          </Box>
          <Box
            data-aos="fade-left"
            data-aos-delay="800"
            sx={{
              width: { xs: "100%", md: "50%" },
            }}
          >
            <img
              className="header-img"
              src={BuilderLandingHeaderSliderImg3}
              alt=""
            />
          </Box>
        </Box>
      </Container>
    </Box>
  </Box>
</Slider>; */
}
