import { Box, Container, Stack, Typography } from "@mui/material";
import FounderImg from "../../Imgs/founder-img.png";

const CommonLandingMeetOurFounders = () => {
  return (
    <Box sx={{ overflowX: "hidden" }}>
      <Container>
        <Typography
          data-aos="fade-down"
          sx={{
            color: "#272D4E",
            textAlign: "center",
            fontSize: { xs: "28px", lg: "40px" },
            fontWeight: 700,
            fontFamily: "Circular Std",
          }}
        >
          Meet our Founders
        </Typography>
        <Box
          sx={{
            display: "flex",
            gap: { xs: "50px", md: "20px", md2: "50px" },
            py: "40px",
            flexDirection: { xs: "column", md: "row" },
            justifyContent: "center",
          }}
        >
          <Box
            data-aos="fade-right"
            data-aos-offset="300"
            sx={{
              width: { xs: "100%", md: "40%" },
              background: "#FFFFFF",
              boxShadow:
                "0px 5.70973px 15.7018px -4.2823px rgba(24, 39, 75, 0.12), 0px 9.99202px 45.6778px -2.85486px rgba(24, 39, 75, 0.12)",
              borderRadius: "10px",
            }}
          >
            <Box sx={{ padding: { xs: "20px", md2: "25px" } }}>
              <Box>
                <img
                  style={{
                    width: "120px",
                    height: "120px",
                    borderRadius: "50%",
                  }}
                  src={FounderImg}
                  alt=""
                />
              </Box>
              <Stack spacing={2} sx={{ mt: "20px" }}>
                <Typography
                  sx={{
                    color: "#343434",
                    fontSize: { xs: "20px", lg: "24px" },
                    fontFamily: "Product Sans",
                    fontWeight: 700,
                  }}
                >
                  Vikas Bansal
                </Typography>
                <Typography
                  sx={{
                    color: "#343434",
                    fontSize: "18px",
                    fontFamily: "Product Sans",
                  }}
                >
                  On a mission to revolutionize the Real Estate sector in India,
                  Vikas is shaping EnvisionNext with a vision to build a
                  transparent and trusted ecosystem for all stakeholders.
                </Typography>
                <Typography
                  sx={{
                    color: "#343434",
                    fontSize: "18px",
                    fontFamily: "Product Sans",
                  }}
                >
                  A Columbia University graduate, he is currently a Management
                  Consultant in New York for the 50 giant corporations
                  worldwide. He has also led technology strategy for AECOM, the
                  world's biggest infrastructure consulting firm in New York.
                </Typography>
              </Stack>
            </Box>
          </Box>
          <Box
            data-aos="fade-left"
            data-aos-offset="300"
            sx={{
              width: { xs: "100%", md: "40%" },
              background: "#FFFFFF",
              boxShadow:
                "0px 5.70973px 15.7018px -4.2823px rgba(24, 39, 75, 0.12), 0px 9.99202px 45.6778px -2.85486px rgba(24, 39, 75, 0.12)",
              borderRadius: "10px",
            }}
          >
            <Box sx={{ padding: { xs: "20px", md2: "25px" } }}>
              <Box>
                <img
                  style={{
                    width: "120px",
                    height: "120px",
                    borderRadius: "50%",
                  }}
                  src={FounderImg}
                  alt=""
                />
              </Box>
              <Stack spacing={2} sx={{ mt: "20px" }}>
                <Typography
                  sx={{
                    color: "#343434",
                    fontSize: { xs: "20px", lg: "24px" },
                    fontFamily: "Product Sans",
                    fontWeight: 700,
                  }}
                >
                  Shantanu Kuchya
                </Typography>
                <Typography
                  sx={{
                    color: "#343434",
                    fontSize: "18px",
                    fontFamily: "Product Sans",
                  }}
                >
                  A Builder himself, Shantanu is one of India's youngest success
                  stories in the Real Estate industry.
                </Typography>
                <Typography
                  sx={{
                    color: "#343434",
                    fontSize: "18px",
                    fontFamily: "Product Sans",
                  }}
                >
                  An engineering graduate from Amity University, he is the
                  Founder of RERA Easy - currently the most reputed Real Estate
                  Legal Advisory firm in Mumbai. His years of experience
                  building homes and providing legal consultation have been
                  crucial in architecting EnvisionNext.
                </Typography>
              </Stack>
            </Box>
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

export default CommonLandingMeetOurFounders;
