import React from 'react'
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import { Link } from 'react-router-dom';
import { Box, Button, Stack, Typography } from '@mui/material';
import PhoneIcon from '@mui/icons-material/Phone';
import MailIcon from '@mui/icons-material/Mail';
import SystemUpdateAltIcon from '@mui/icons-material/SystemUpdateAlt';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import Lottie from "react-lottie";
import animationData from "../../../../Lotties/building.json";

function Support({open,setOpen}) {

    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: animationData,
        rendererSettings: {
          preserveAspectRatio: "xMidYMid slice",
        },
    };

  return (
        <Dialog
        open={open}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{borderRadius:"20px" }}
      >
        <DialogContent
          sx={{  width: "400px",textAlign:"center"}}
        >
           <div style={{width:"100%"}}>
                <Lottie options={defaultOptions} height="100%" width="50%" />
           </div>
             <h2>Need Support ?</h2>
          <DialogContentText id="alert-dialog-description" sx={{marginTop:"-15px",fontWeight: "500",fontSize: "16px"}}>
              Kindly use the below options
          </DialogContentText>

          <Stack sx={{marginLeft:"110px"}}>
          <Typography sx={{marginTop:"30px",fontWeight: "600",fontSize: "16px",display:"flex",justifyContent:"",alignItems:"center",color:"black",fontFamily: "Product Sans"}}><PhoneIcon sx={{marginRight:"10px"}}/>
          +91 7304880751
          </Typography>
          <DialogContentText sx={{marginTop:"15px",fontWeight: "600",fontSize: "16px",display:"flex",justifyContent:"",alignItems:"center",color:"black",fontFamily: "Product Sans"}}><MailIcon sx={{marginRight:"10px"}}/>
          support@envisionnext.in
          </DialogContentText>
          <DialogContentText sx={{marginTop:"15px",marginBottom:"20px",fontWeight: "600",fontSize: "16px",display:"flex",justifyContent:"",alignItems:"center",color:"black",fontFamily: "Product Sans"}}><SystemUpdateAltIcon sx={{marginRight:"10px"}}/>
          https://envisionnext.in
          </DialogContentText>
          </Stack>
          {/* <DialogContentText sx={{marginTop:"20px",fontWeight: "600",fontSize: "16px",display:"flex",justifyContent:"center",alignItems:"center",color:"black",fontFamily: "Product Sans"}}><LocationOnIcon sx={{marginRight:"10px"}}/>
          Mumbai
          </DialogContentText> */}
          <Button variant="contained" 
          onClick={()=>{setOpen(false)}}
          sx={{background:"#0a2260",boxShadow:"0px 2px 6px rgba(19, 18, 66, 0.07)",borderRadius:"4px",margin:"20px 0px",padding:"10px 30px","&:hover":{background:"#0a2260"}}}>
            <Box sx={{ textTransform: "capitalize",margin:"0px 7px"}}>OK</Box>
          </Button>
        </DialogContent>
      </Dialog>
  )
}

export default Support