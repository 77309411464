import { Box, List, ListItem, Typography } from "@mui/material";
import "./styles/PageSix.css";
import React, {useState } from 'react';
import { Stack } from "@mui/system";
import classes from "./styles/PageOne.module.css";

const PdfOnePageEleven = () => {

  const [pageData11,setPageData11]=useState({
    sign_first:"",
    sign_second:"",
    sign_third:"",
  })

  let name,value;
  const handleChange=(e)=>{
    name=e.target.name;
    value=e.target.value;
    console.log(name," ",value);
    setPageData11({...pageData11,[name]:value});
  }

  return (
    <Box>
      <Box sx={{ mb: "40px" }}>
        <Box sx={{ ml: "30px" }}>
          <Typography sx={{ fontSize: "10px" }}>
            7.5.3. At the time of the handover of the Premises for fit-outs, the
            Applicants shall be liable to make payments of Adhoc Common Area
            Maintanance Charges as provided herein. During the period of
            fitouts, the Applicant(s) shall reimburse the cost of Services and
            facilities made available by the Developer to the Applicant(s)
            during the fitout period as may be determined on an open book basis.
            In the event the Applicant(s) fails to make payments of the fitout
            fees as demanded by the Dveloper, the Developer shall be entitled to
            debit the same to the Adhoc Common Area Maintenance Charges
            collected from the Applicant(s) and the Applicant(s) shall be liable
            to make good such amount on demand with interest at the Interest
            Rate.
          </Typography>
          <Typography sx={{ fontSize: "10px" }}>
            7.5.4. Applicant(s) shall be liable to deposits amounts equivalant
            to 1% of the Allotment Premium with the Developer towards interest
            free refundable and adjustable security deposit for the due
            performance of the Applicant’s obligations while undertaking fitouts
            in the Premises. The Applicant(s) shall always obtain the prior
            written approval of the Developer for carring out such fitout works.
            The Applicant(s) shall complete the fitouts works within a maximum
            period of 90 days of the Developer granting approval for such fitout
            works. Upon or after completion of fitout works, the asset
            management team of the Developer, shall inspect the fitout works
            undertaken by the Applicant(s) in the Premises and in the event it
            is asertained that: (i) During the course of fitout works in the
            Premises any damage has been caused to the structure, RCC columns,
            Beams, Flooring, Roof of the Premises/ Commom areas and/or the
            finishing and/or instalations of building(s)/ common areas and/or
            such fitout works has not been carried out as per the fitout works
            approved by the Developers and in compliance with the approved
            plans, then in such an event the Developer shall be entitled to
            adjust the fitout deposits towards the damage cost, at actuals(and
            refund the balance amount of fitout deposit to the Applicant(s)),
            and (ii) such fitout works has been carried out as per the fitout
            works approved by the Developer and in complaince with the approved
            plans and there is no alteration/ damage cost to the structure, RCC
            coolum, Beams, Floorings, roof of the Premises/ common areas and the
            finishing and the installations in the Building(s) / coomon areas,
            the Developer shall refund the fitout Deposits to the Applicant(s).
            In the event the cost incured by the Developer for rectifying
            damages is more than the fitout security deposit, then the
            Applicant(s) undertake to immediately pay for such shortfall to the
            Developer within a period of 7 days from the date of Demand been
            made by the Developer to the Applicant(s), failing which, the
            Applicant(s) shall be laible to pay such shortfall along with
            interst at the Interest Rate.
          </Typography>
          <Typography sx={{ fontSize: "10px" }}>
            7.5.5. Alteration and modifications: The Applicant(s) shall not make
            or cause to be made any addition or alteration (Structural or Non
            Structural) of whatsoever nature in or to the Premises or any part
            thereof nor alter the elevation/ colour scheme of the building and
            not to chisel or in any other manner damage the colums, beams,
            walls, slabs, or RCC structures in the said Premises/ common area or
            enclose and balcony /duct/ attached terrace, common area or any
            other usable area.
          </Typography>
        </Box>

        <Stack spacing={0.5}>
          <Box sx={{ display: "flex", gap: "10px" }}>
            <Typography sx={{ fontSize: "10px" }}>7.6</Typography>
            <Typography sx={{ fontSize: "10px" }}>
              The Applicant(s) is aware that in the event of any of the payments
              made by the Applicant(s) towards the Application Monies and/or
              applicable taxes in terms of this Application, not being duly
              honoured / cleared or delayed, then this Application, shall be
              invalid and be deemed to have been withdrawn by the Applicant(s)
              and rejected by the Developer. On such rejection of the
              Application, the Developer shall refund the monies in the manner
              mentioned in Clause 9.4 and Clause 9.5 hereinbelow.
            </Typography>
          </Box>
          <Box sx={{ display: "flex", gap: "10px" }}>
            <Typography sx={{ fontSize: "10px" }}>7.7</Typography>
            <Typography sx={{ fontSize: "10px" }}>
              It is clarified that the Applicant(s) accords his/her/its/their
              irrevocable consent that any payment made by the Applicant(s) to
              the Developer hereunder shall, notwithstanding any communication
              to the contrary by the Applicant, be appropiriated in the manner
              below:{" "}
              <ul >
                <li>
                  Firstly, Rs. 1500 /- (One Thousand Five Hundred Only) towards
                  penalty on dishonourned cheque plus applicabe GST thereon (if
                  any).
                </li>
                <li>
                  Secondly, towards interest and/or penalty on delayed payments
                  of tax/statutory dues plus applicable GST thereon (if any).
                </li>
                <li>Thirdly, towards outstanding taxes/ Statutory dues. </li>
                <li>
                  Fourthly, towards cost and expenses for enforcement of this
                  application/ agreement and recovery of all the amounts payable
                  by Applicant(s) as per the Application.{" "}
                </li>
                <li>
                  Fifthly, towards interest as on date on delayed payments under
                  this Application.{" "}
                </li>
                <li>
                  Sixthly, towards other charges payable by the Applicant(s) in
                  respect of the Premises.{" "}
                </li>
                <li>
                  Finally, towards outstanding Allotment Premium payable by the
                  Applicant(s) in espect of the Premises. No express intimation
                  or communication by the Applicant(s), with regard to
                  appropriation/ application of the payments made to the
                  Developer shall be valid or binding upon the Developer and all
                  appropriation/ application of payments made to the Developer
                  shall only be in the manner as provided in this Clause.
                  Provided.
                </li>
              </ul>
            </Typography>
          </Box>
          <Box sx={{ display: "flex", gap: "10px" }}>
            <Typography sx={{ fontSize: "10px" }}>7.8</Typography>
            <Typography sx={{ fontSize: "10px" }}>
              The Developer shall as per the Payment Schedule, intimate in
              writing to the Applicant(s) herein to make payment of the amount
              payable as stated in Payment Schedule and the Applicant(s) shall
              without any demur or protest, make the payment within 10 days of
              issuance of intimation in writing by the Developer, time being of
              the essence. In the event such payment is not made within the
              prescribed period of 10 days, then on the expiry of 10 days, the
              Developer shall be entitled to issue a notice in writing for
              payment of the outstanding amount along with interest at the
              Interest Rate payable from the due date for payment till the
              payment thereof.
            </Typography>
          </Box>
        </Stack>
      </Box>
      <Box
        sx={{
          display: "flex",
          gap: "40px",
          justifyContent: "center",
          position: "absolute",
          bottom: "30px",
        }}
      >
        <Box className={classes.input}>
          <Box>
            <input
              style={{ textAlign: "center" }}
              value={pageData11.sign_first}
              type="text"
              name="sign_first"
              onChange={handleChange}
            />
            <Typography sx={{ textAlign: "center", mt: "10px" }}>
              Signature of First Applicant
            </Typography>
          </Box>
        </Box>
        <Box className={classes.input}>
          <Box sx={{ display: "inline-block" }}>
            <input
              style={{ margin: "0px", textAlign: "center" }}
              value={pageData11.sign_second}
              type="text"
              name="sign_second"
              onChange={handleChange}
            />
            <Typography sx={{ textAlign: "center", mt: "10px" }}>
              Signature of Second Applicant
            </Typography>
          </Box>
        </Box>
        <Box className={classes.input}>
          <Box sx={{ display: "inline-block" }}>
            <input
              style={{ margin: "0px", textAlign: "center" }}
              value={pageData11.sign_third}
              type="text"
              name="sign_third"
              onChange={handleChange}
            />
            <Typography sx={{ textAlign: "center", mt: "10px" }}>
              Signature of Third Applicant
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default PdfOnePageEleven;
