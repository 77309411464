import { ContactlessOutlined } from "@mui/icons-material";
import axios from "axios";
import { Apiaddress } from "../../../../utils/consts";
import { AuthService } from "../auth";

class TicketsFetchingService {
    url = Apiaddress + "/api/buyer/buyerticket/"

    Auth: AuthService;
    constructor(e: AuthService) {
        this.Auth = e;
    }

    async getTicketsListUsingToken() {
        const res = await this.Auth.makeAuthenticatedGetRequest(this.url)
        return await res.json()
    }

    async getTicketById(id?: string) {
        const ticket = (await this.getTicketsListUsingToken()).ticket
        let arr: any = []
        ticket.map((e: any) => {
            if (e.id == id) {
                arr.push(e)
            }
        })
        return arr[0]

    }

    async getTicketMessages(id: string) {
        const x = await this.getTicketById(id);
        return x.responses

    }

    async delete(ticketid:string){
        let data = { 
            ticket_id: ticketid,
        }
        const headers = {
            headers: this.Auth.getAuthHeaders(true)
        }
 
        const res = await axios.delete(this.url+"?ticket_id="+ticketid,
            //@ts-ignore
            {body:data, ...headers}
            )
    }

    async createTicket(category: string, description: string, files: any) {

        const headers = {
            headers: this.Auth.getAuthHeaders(true)

        }

        let data = {
            category: category,
            disc: description,
            status: "open",
            buyer: this.Auth.getCurrentBuyerId()
        }

        for (let i = 0; i < files.length; i++) {
            const key = "attachments" + (i + 1)
            console.log(files[i])
            //@ts-ignore
            data[key] = files[i]

        };

        console.log("data", data, headers, files.length, files)
        await axios.post(this.url, data, headers)
        return await this.getTicketsListUsingToken()
    }

    async updateCategoryOrDescriptionOrFiles({ ticketid, category, description, files }: { ticketid: string | undefined, category: string | undefined, description: string | undefined, files: any }) {
        let data = { 
            ticket_id: ticketid,
            category: category,
            disc: description,
        }
        const headers = {
            headers: this.Auth.getAuthHeaders(true)
        }


        const lastFilesIndex=async()=>{
            let re = (await this.getTicketById(ticketid))
            let lastindex:number = 0
            for (let i = 0; i < 5; i++){ 
                // return index where first attachment is undefined
                if (re[("attachments"+i)]=== undefined){
                    return lastindex;
                }else{
                    lastindex += 1
                }
            }
        }



        const starti = await lastFilesIndex() ?? 0
        for (let i = starti ; i < files.length; i++) {
            const key = "attachments" + (i + 1)
            console.log(files[i])
            //@ts-ignore
            data[key] = files[i]

        };
        const heads = this.Auth.getAuthHeaders(true)
        console.log("upload data", data)
        const res = await axios.patch(this.url,
            data, headers
        )

    }

    async sendMessageToTheTicket(ticketid: string, messageString: string) {
        const timeElapsed = Date.now();
        const today = new Date(timeElapsed);

        const oldmessages = await this.getTicketMessages(ticketid)

        const data = {
            createdat: today.toISOString(),
            message: messageString,
            sentBy: "Buyer",
        }
        console.log("oldmessages", oldmessages)
        const newMessages = oldmessages != null ? [...oldmessages, data] : [data];


        const res = await this.Auth.makeAuthenticatedPatchRequest(this.url, {
            JsonBody: {
                ticket_id: ticketid,
                responses: newMessages,
            }
        })
        console.log(res)
        return await this.getTicketsListUsingToken()
    }

}

export { TicketsFetchingService }