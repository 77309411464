import Backdrop from "@mui/material/Backdrop";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import DeleteIcon from "@mui/icons-material/Delete";
import { useState } from "react";
// import { Apiaddress } from "../../utils/consts";
import { Box, Button, TextField, Typography } from "@mui/material";
import ReportProblemIcon from "@mui/icons-material/ReportProblem";
import { useForm } from "react-hook-form";
import axios from "axios";
import { api, Apiaddress } from "../../../../utils/consts";
import { GridCloseIcon } from "@mui/x-data-grid";
import { AuthService } from "../../../../context/buyer/services/auth";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 450,
  bgcolor: "background.paper",
  border: "2px solid #fff",
  borderRadius: "10px",
  boxShadow: 24,
  p: 4,
};

export const DeleteConfirmationModal = ({
  fetchTemplates,
  e,
  trigger,
  setTrigger,
  setSuccess,
  success,
}) => {
  ////modal for forgot password;
  const [confirmText, setConfirmText] = useState("");
  const [match, setMatch] = useState(false);
  const [open, setOpen] = useState(false);
  const handleClose = () => setOpen(false);
  const sendmail = (data) => {
    //     let url = Apiaddress + "/api/forgetpass/";
    //     axios
    //       .post(url, { email: data.send_mail })
    //       .then((res) => {
    //         // console.log("res", res.data);
    //         if (res.data.error == false) {
    //           setSuccess(true);
    //         }
    //       })
    //       .catch((err) => {
    //         // console.log("err", err);
    //         setErr("Invalid Email");
    //       });
  };
  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          gap: "6px",
          cursor: "pointer",
        }}
        onClick={() => setOpen(true)}
        // onClick={async () => {
        //   const res = window.confirm("Are you sure to delete this?", e.name);
        //   if (res) {
        //     const x = new AuthService();
        //     await x.authenticatedDelete(
        //       api("/api/leads/requirementtemplate/?template_id=" + e.id)
        //     );
        //     await fetchTemplates();
        //   }
        // }}
      >
        <DeleteIcon
          sx={{ cursor: "pointer", height: "16px" }}
          // onClick={async () => {
          //   const x = new AuthService();
          //   await x.authenticatedDelete(
          //     api(
          //       "/api/leads/requirementtemplate/?template_id=" +
          //         e.id
          //     )
          //   );
          //   await fetchTemplates();
          // }}
        ></DeleteIcon>
        <Typography
          sx={{
            fontFamily: "Product Sans",
            fontStyle: "normal",
            fontWeight: "500",
            fontSize: "14px",
            lineHeight: "21px",
            color: "#000000",
            marginRight: "20px",
          }}
        >
          Delete
        </Typography>
      </Box>
      {/* delete template popup */}
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <Box sx={style}>
            <Box
              sx={{
                display: "flex",
                marginBottom: "25px",
                justifyContent: "space-between",
                marginTop: "-10px",
              }}
            >
              <Box sx={{ display: "flex", gap: "0.2rem" }}>
                <ReportProblemIcon sx={{ color: "red" }} />
                <Typography
                  sx={{
                    fontFamily: "Product Sans",
                    fontSize: "18px",
                    fontWeight: "600",
                    color: "red",
                  }}
                >
                  Delete Template
                </Typography>
              </Box>
              <Box sx={{ textAlign: "right" }}>
                <GridCloseIcon
                  sx={{ color: "gray", cursor: "pointer" }}
                  onClick={handleClose}
                />
              </Box>
            </Box>
            <Box>
              <Typography
                id="transition-modal-title"
                //   sx={{ color: success ? "green" : "black" }}
              >
                Confirm that you want to delete template by typing - "
                <span sx={{ fontWeight: "600" }}>{e.name}</span>
                "?
              </Typography>
              <TextField
                placeholder={e.name}
                onChange={(data) => {
                  setMatch(false);
                  setConfirmText(data.target.value);
                  if (data.target.value === e.name) {
                    setMatch(true);
                  }
                }}
                sx={{ mt: 3, width: "100%" }}
              />
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-start",
                mt: 3,
                flexDirection: "row-reverse",
              }}
            >
              <Button
                disabled={!match}
                sx={{
                  width: "20%",
                  color: "#06386B",
                  textTransform: "none",
                  fontFamily: "Product Sans",
                  ml: 1,
                  background: !match ? "gray" : "red",
                  "&:hover": {
                    background: "red",
                    boxShadow: "0px 9px 12px rgba(242, 178, 73, 0.59)",
                  },
                }}
                className="univercel_btn_class"
                onClick={async () => {
                  const x = new AuthService();
                  await x.authenticatedDelete(
                    api("/api/leads/requirementtemplate/?template_id=" + e.id)
                  );
                  // await fetchTemplates();
                  setTrigger(!trigger);
                }}
              >
                Yes
              </Button>
              <Button
                onClick={handleClose}
                sx={{
                  color: "#B9B7D0",
                }}
              >
                Cancel
              </Button>
            </Box>
          </Box>
        </Fade>
      </Modal>
    </>
  );
};
