import {
  Box,
  Checkbox,
  Stack,
  Button,
  Accordion,
  AccordionSummary,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  TextField,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import KeyboardDoubleArrowLeftIcon from "@mui/icons-material/KeyboardDoubleArrowLeft";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import CircularProgress from "@mui/material/CircularProgress";
import {
  ArrowDropDownIconSvg,
  ArrowDropUpIconSvg,
} from "../../../BuilderComponents/BuilderDasboard/assets/icons";
import TuneOutlinedIcon from "@mui/icons-material/TuneOutlined";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import { DataGrid, GridExpandMoreIcon } from "@mui/x-data-grid";
import CategoryIcon from "@mui/icons-material/Category";
import Lottie from "react-lottie";
import animationData from "../../../../Lotties/NEW sin movs.json";

function LDcustomTable({
  newRowData,
  delIconClick,
  updateBuyers,
  setTrigger,
  trigger,
  updateLead,
  onRowsSelectionHandler,
  toggleDrawer,
  rowSelected,
  setRowSelected,
  onView,
  selectedLeads,
  setSelectedLeads,
  loading,
  CircularProgress,
  handleOpen,
  setToDelete,
  CheckClient,
  setState,
  totalLead,
  setStartLead,    
  startLead,
  state,
  curpage,
  setcurpage,
  setMakePriority,
  filterselect,
  filtervalue,
  setfilterselect,
  setfiltervalue,
  handleChangeFilter,
  fetchfilter,
}) {
  const [pageRange, setPageRange] = useState([0, 6]);
  const [pages, setPages] = useState([]);
  const [NoPages, setNopages] = useState(null);
  const [matchText, setmatchText] = useState(
    "Let's get started, Please add your data"
  );

  const nextClick = () => {
    if (curpage !== NoPages) {
      setStartLead(startLead + 7);
      setcurpage(curpage + 1);
      setSelectedLeads([]);
      setTrigger(!trigger);
    }
  };

  const prvClick = () => {
    if (curpage !== 1) {
      setStartLead(startLead - 7);
      setcurpage(curpage - 1);
      setSelectedLeads([]);
      setTrigger(!trigger);
    }
  };

  useEffect(() => {
    if (newRowData) {
      if (totalLead % 7 == 0) {
        let temp = totalLead / 7;
        setNopages(temp);
      } else {
        let temp = Math.floor(totalLead / 7 + 1);
        setNopages(temp);
      }
    }
  }, [newRowData]);

  const [curData, setcurData] = useState([]);
  const [selectedSortUp, setSelectedSortUp] = useState("");
  const [selectedSortDown, setSelectedSortDown] = useState("");
  useEffect(() => {
    if (newRowData) {
      setcurData(newRowData);
      setSelectedSortUp("");
      setSelectedSortDown("");
    }
  }, [newRowData]);

  let temp = [];
  const onCheck = (id) => {
    if (id === "selectAll") {
      for (let i = 0; i < newRowData?.length; i++) {
        temp.push(newRowData[i].id);
      }
      setSelectedLeads(temp);
    } else if (id == "removeAll") {
      temp = [];
      setSelectedLeads([]);
    } else {
      let array = selectedLeads;
      const index = array.indexOf(id);
      if (index > -1) {
        let temp1 = array.filter((ele) => {
          return ele != selectedLeads[index];
        });
        temp = temp1;
      } else {
        temp = [...selectedLeads, id];
      }
    }
    console.log("temp", temp);
    setSelectedLeads(temp);
  };

  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const sortArr = (para) => {
    setSelectedSortUp(para);
    setSelectedSortDown("");
    function compare(a, b) {
      let fir, sec;
      if (para == "name") {
        fir = a[para];
        sec = b[para];
      } else {
        fir = a?.lead_prority?.name;
        sec = b?.lead_prority?.name;
      }
      let fir1, sec1;

      fir1 = fir.toLowerCase();
      sec1 = sec.toLowerCase();

      console.log(fir1, "===", sec1);
      if (fir1 < sec1) {
        return -1;
      }
      if (fir1 > sec1) {
        return 1;
      }
      return 0;
    }

    let arr = [...curData];
    console.log(arr);
    arr.sort(compare);
    console.log(arr);

    setcurData(arr);
  };

  const sortArrDec = (para) => {
    setSelectedSortUp("");
    setSelectedSortDown(para);
    function compare(a, b) {
      let fir, sec;
      if (para == "name") {
        fir = a[para];
        sec = b[para];
      } else {
        fir = a?.lead_prority?.name;
        sec = b?.lead_prority?.name;
      }
      let fir1, sec1;

      fir1 = fir.toLowerCase();
      sec1 = sec.toLowerCase();

      if (fir1 < sec1) {
        return -1;
      }
      if (fir1 > sec1) {
        return 1;
      }
      return 0;
    }
    let arr = [...curData];
    arr.sort(compare);
    arr.reverse();
    console.log(arr);
    setcurData([...arr]);
  };

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const [expandedFilter, setExpandedFilter] = useState(false);

  return (
    <>
      <Box>
        <div style={{ height: 400, width: "100%" }}>
          <>
            {/* Button Container */}
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              sx={{}}
            >
              <Stack direction="row" alignItems="center">
                <Button
                  className="univercel_btn_class"
                  sx={{
                    color: "#FFFFFF",
                    textTransform: "none",
                    marginRight: "15px",
                    width: "100px",
                    // padding: "8px 24px",
                    fontFamily: "Product Sans",
                    fontSize: "15px",
                  }}
                  onClick={() => setState(true)}
                >
                  {"Add Leads"}
                </Button>
                <Box>
                  {selectedLeads.length != 0 && (
                    <Stack direction="row" sx={{}}>
                      {
                        <Button
                          onClick={() => {
                            setState(true);
                            setMakePriority(true);
                          }}
                          startIcon={<CategoryIcon />}
                          className="univercel_btn_class"
                          sx={{
                            color: "#FFFFFF",
                            textTransform: "none",
                            width: "150px",
                            background: "#008dff",
                            boxShadow:
                              "0px 13.7981px 22.5788px rgba(50, 174, 137, 0.25)",
                            "&:hover": { background: "#008dff" },
                          }}
                        >
                          Mark Priority
                        </Button>
                      }
                    </Stack>
                  )}
                </Box>
              </Stack>

              <Stack
                direction="row"
                justifyContent="right"
                alignItems="center"
                sx={{
                  width: "203px",
                  height: "68px",
                  borderRadius: "12px",
                  color: "#3E4954",
                }}
              >
                <Accordion
                  expanded={expandedFilter}
                  sx={{ width: "150px", boxShadow: "none" }}
                >
                  <AccordionSummary
                    onClick={() => {
                      setExpandedFilter(!expandedFilter);
                    }}
                    expandIcon={<GridExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography sx={{ display: "flex", placeItems: "center" }}>
                      <TuneOutlinedIcon sx={{ color: "#2F4CDD" }} />
                      <Typography sx={{ marginLeft: "10px" }}>
                        Fillters
                      </Typography>
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Box sx={{ textAlign: "center" }}>
                      <FormControl sx={{ width: "118px" }}>
                        <InputLabel id="demo-simple-select-label">
                          Select
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={filterselect}
                          label="Select"
                          onChange={handleChangeFilter}
                        >
                          <MenuItem value={"name"}>Name</MenuItem>
                          <MenuItem value={"email"}>Email</MenuItem>
                          <MenuItem value={"phone"}>Phone</MenuItem>
                        </Select>
                      </FormControl>
                      <TextField
                        sx={{ marginTop: "20px", fontSize: "8px" }}
                        placeholder="Value"
                        value={filtervalue}
                        onChange={(e) => {
                          setfiltervalue(e.target.value);
                        }}
                      />
                      <Button
                        // onClick={fetchfilter}
                        onClick={() => {
                          fetchfilter();
                          setExpandedFilter(false);
                          setmatchText(
                            "No results found with the applied Filters"
                          );
                        }}
                        variant="contained"
                        className="univercel_btn_class"
                        color="primary"
                        sx={{ margin: "20px 0px 10px 0px" }}
                      >
                        Submit
                      </Button>
                    </Box>
                  </AccordionDetails>
                </Accordion>
                {expandedFilter == false && filtervalue && (
                  <RestartAltIcon
                    sx={{ margin: "0px 10px", cursor: "pointer" }}
                    onClick={() => {
                      // setfilterselect("");
                      setfiltervalue("");
                      setSelectedLeads([]);
                      setStartLead(0);
                      setmatchText("Let's get started, Please add your data");
                      setcurpage(1);
                      setTrigger(!trigger);
                    }}
                  />
                )}
              </Stack>
            </Stack>

            <Box
              sx={{
                color: "black",
                width: "100%",
                boxSizing: "border-box",
                height: 400,
                "&:hover": {
                  boxShadow: "-6px 18px 15px rgba(0, 0, 0, 0.1)",
                },
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "start",
                  alignItems: "center",
                  background: "white",
                  borderRadius: "5px",
                  margin: " 10px 0",
                  color: "#272D4E",
                  fontWeight: "700",
                  fontFamily: "Circular Std",
                  fontSize: "18px",
                }}
              >
                {
                  <Box
                    sx={{
                      width: "12.66%",
                      display: "grid",
                      placeItems: "center",
                      height: "50px",
                      justifyContent: "start",
                      paddingLeft: "37px",
                    }}
                  >
                    <Checkbox
                      checked={
                        newRowData?.length === selectedLeads?.length
                          ? true
                          : false
                      }
                      onClick={() => {
                        if (newRowData?.length === selectedLeads?.length) {
                          onCheck("removeAll");
                        } else {
                          onCheck("selectAll");
                        }
                      }}
                    />
                  </Box>
                }
                <Box
                  sx={{
                    width: "18.66%",
                    display: "grid",
                    placeItems: "center",
                    height: "50px",
                    justifyContent: "start",
                    paddingLeft: "39px",
                  }}
                >
                  <Box sx={{ display: "flex", justifyContent: "center" }}>
                    <Box sx={{ display: "grid", placeItems: "center" }}>
                      Name
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "5px",
                      }}
                    >
                      <Box
                        sx={{
                          cursor: "pointer",
                          height: "10px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          marginLeft: "10px",
                        }}
                        onClick={() => sortArr("name")}
                      >
                        <ArrowDropUpIconSvg focus={selectedSortUp == "name"} />
                      </Box>
                      <Box
                        sx={{
                          cursor: "pointer",
                          height: "10px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          marginLeft: "10px",
                        }}
                        onClick={() => sortArrDec("name")}
                      >
                        <ArrowDropDownIconSvg
                          focus={selectedSortDown == "name"}
                        />
                      </Box>
                    </Box>
                  </Box>
                </Box>

                <Box
                  sx={{
                    width: "18.66%",
                    display: "grid",
                    placeItems: "center",
                    height: "50px",
                    justifyContent: "start",
                    paddingLeft: "39px",
                  }}
                >
                  <Box sx={{ display: "flex", justifyContent: "center" }}>
                    <Box sx={{ display: "grid", placeItems: "center" }}>
                      Phone
                    </Box>
                  </Box>
                </Box>

                <Box
                  sx={{
                    width: "18.66%",
                    display: "grid",
                    placeItems: "center",
                    height: "50px",
                    justifyContent: "start",
                    paddingLeft: "39px",
                  }}
                >
                  <Box sx={{ display: "flex", justifyContent: "center" }}>
                    <Box sx={{ display: "grid", placeItems: "center" }}>
                      Email
                    </Box>
                  </Box>
                </Box>

                <Box
                  sx={{
                    width: "18.66%",
                    display: "grid",
                    placeItems: "center",
                    height: "50px",
                    justifyContent: "start",
                    paddingLeft: "39px",
                  }}
                >
                  <Box sx={{ display: "flex", justifyContent: "center" }}>
                    <Box sx={{ display: "grid", placeItems: "center" }}>
                      Priority
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "5px",
                      }}
                    >
                      <Box
                        sx={{
                          cursor: "pointer",
                          height: "10px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          marginLeft: "10px",
                        }}
                        onClick={() => sortArr("lead_prority?.name")}
                      >
                        <ArrowDropUpIconSvg
                          focus={selectedSortUp == "lead_prority?.name"}
                        />
                      </Box>
                      <Box
                        sx={{
                          cursor: "pointer",
                          height: "10px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          marginLeft: "10px",
                        }}
                        onClick={() => sortArrDec("lead_prority?.name")}
                      >
                        <ArrowDropDownIconSvg
                          focus={selectedSortDown == "lead_prority?.name"}
                        />
                      </Box>
                    </Box>
                  </Box>
                </Box>

                <Box
                  sx={{
                    width: "18.66%",
                    display: "grid",
                    placeItems: "center",
                    height: "50px",
                    justifyContent: "start",
                    paddingLeft: "39px",
                  }}
                >
                  Actions
                </Box>
              </Box>
              {loading ? (
                <>
                  <Box sx={{ textAlign: "center", marginTop: "50px" }}>
                    <CircularProgress
                      color="inherit"
                      sx={{ color: "grey.500" }}
                    />
                  </Box>
                </>
              ) : newRowData.length == 0 ? (
                <>
                  <Box sx={{ textAlign: "center", marginTop: "50px" }}>
                    <Typography
                      sx={{
                        fontSize: "25px",
                        fontWeight: "500",
                        color: "#B9B7D0",
                      }}
                    >
                      <Lottie
                        options={defaultOptions}
                        height="20%"
                        width="20%"
                      />
                      <Typography
                        sx={{
                          color: "#B9B7D0",
                          fontSize: "18px",
                          fontWeight: "400",
                          marginTop: "-40px",
                        }}
                      >
                        {matchText}
                      </Typography>
                    </Typography>
                  </Box>
                </>
              ) : (
                <Box sx={{}}>
                  <Box sx={{ height: "350px" }}>
                    {curData &&
                      curData.map((item, index) => {
                        if (index >= pageRange[0] && index <= pageRange[1]) {
                          return (
                            <div
                              style={{
                                backgroundColor: "#FFF",
                                margin: "5px 0px",
                                borderRadius: "5px",
                                boxSizing: "border-box",
                              }}
                            >
                              <Stack
                                direction="row"
                                justifyContent="start"
                                alignItems="center"
                                sx={{
                                  width: "100%",
                                  color: "#343434",
                                  "&:hover": {
                                    backgroundColor: "#F5F7FF",
                                    cursor: "pointer",
                                  },
                                }}
                                onClick={(e) => {
                                  e.stopPropagation();
                                  updateLead(item, "view");
                                }}
                              >
                                {
                                  <Typography
                                    sx={{
                                      width: "10.66%",
                                      display: "grid",
                                      justifyContent: "start",
                                      fontFamily: "Product Sans",
                                      fontWeight: "400",
                                      paddingLeft: "20px",
                                    }}
                                  >
                                    {/* {`${JSON.stringify(selectedLeads.indexOf(item.id))}`} */}
                                    <Checkbox
                                      sx={{ marginLeft: "15px" }}
                                      checked={
                                        selectedLeads.indexOf(item.id) > -1
                                          ? true
                                          : false
                                      }
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        onCheck(item.id);
                                      }}
                                    />
                                  </Typography>
                                }
                                <Typography
                                  sx={{
                                    width: "16.66%",
                                    justifyContent: "start",
                                    fontFamily: "Product Sans",
                                    fontWeight: "400",
                                    paddingLeft: "39px",
                                  }}
                                >
                                  <Box
                                    sx={{
                                      placeItems: "center",
                                      fontWeight: "400",

                                      // height: "50px",
                                    }}
                                  >
                                    {item?.name}
                                  </Box>
                                </Typography>
                                <Typography
                                  sx={{
                                    width: "16.66%",
                                    justifyContent: "start",
                                    fontFamily: "Product Sans",
                                    fontWeight: "400",
                                    // border:"1px solid green",
                                    paddingLeft: "5px",
                                  }}
                                >
                                  <Box
                                    sx={{
                                      placeItems: "center",
                                      fontWeight: "400",

                                      // height: "50px",  
                                    }}
                                  >
                                    {item?.phone !== null
                                      ? item?.phone?.substring(3)
                                      : "__"}
                                  </Box>
                                </Typography>
                                <Typography
                                  sx={{
                                    width: "16.66%",
                                    justifyContent: "start",
                                    fontFamily: "Product Sans",
                                    fontWeight: "400",
                                    // border:"1px solid green",
                                    paddingLeft: "8px",
                                  }}
                                >
                                  <Box
                                    sx={{
                                      placeItems: "center",
                                      fontWeight: "400",

                                      // height: "50px",
                                    }}
                                  >
                                    {item?.email
                                      ? item?.email?.substring(0, 14) + "..."
                                      : "__"}
                                  </Box>
                                </Typography>

                                <Typography
                                  sx={{
                                    width: "16.66%",
                                    justifyContent: "start",
                                    fontFamily: "Product Sans",
                                    fontWeight: "400",
                                    // border:"1px solid green",
                                    paddingLeft: "10px",
                                  }}
                                >
                                  <Box
                                    sx={{
                                      placeItems: "center",
                                      fontWeight: "400",
                                    }}
                                  >
                                    {item?.lead_prority?.name}
                                  </Box>
                                </Typography>

                                <Stack spacing={1} direction="row">
                                  <EditIcon
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      updateLead(item, "edit");
                                    }}
                                    sx={{ cursor: "pointer" }}
                                  />
                                  <RemoveRedEyeIcon
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      updateLead(item, "view");
                                    }}
                                    sx={{ cursor: "pointer" }}
                                  />
                                  <DeleteIcon
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      // delIconClick(item);
                                      setToDelete(item);
                                      CheckClient(item);
                                      handleOpen();
                                    }}
                                    sx={{ cursor: "pointer" }}
                                  />
                                </Stack>
                              </Stack>
                            </div>
                          );
                        }
                      })}
                  </Box>

                  <Box sx={{ margin: "50px 0px 20px 0px" }}>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "right",
                        marginRight: "20px",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          placeItems: "center",
                          margin: " 0 10px",
                          borderRadius: "5px",
                        }}
                      >
                        <Typography sx={{}}>Page</Typography>
                        <Typography
                          sx={{
                            background: `white`,
                            color: "black",
                            width: "60px",
                            height: "30px",
                            borderRadius: "4px",
                            margin: "0 10px",
                            display: "grid",
                            placeItems: "center",
                            border: "1px solid #2979F2",
                          }}
                        >
                          {curpage}
                        </Typography>
                        <Typography
                          sx={{
                            color: "black",
                            width: "30px",
                            borderRadius: "5px",
                            margin: "0 0px",
                            display: "grid",
                            placeItems: "center",
                          }}
                        >
                          of
                        </Typography>
                        <Typography
                          sx={{
                            color: "black",
                            margin: "0px 10px 0px 0px",
                            display: "grid",
                            placeItems: "center",
                          }}
                        >
                          {NoPages}
                        </Typography>
                      </Box>

                      {newRowData.length != 0 && (
                        <Button
                          variant="contained"
                          className="paginationButton"
                          sx={{ marginRight: "10px" }}
                          onClick={prvClick}
                          disabled={curpage == 1}
                        >
                          <KeyboardDoubleArrowLeftIcon fontSize="medium" />
                        </Button>
                      )}

                      {newRowData.length != 0 && (
                        <Button
                          variant="contained"
                          className="paginationButton"
                          onClick={nextClick}
                          disabled={curpage == NoPages}
                        >
                          <KeyboardDoubleArrowRightIcon fontSize="medium" />
                        </Button>
                      )}
                    </Box>
                  </Box>
                </Box>
              )}
            </Box>
          </>
        </div>
      </Box>
    </>
  );
}

export default LDcustomTable;
