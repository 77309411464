import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Checkbox,
  Drawer,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { Stack } from "@mui/system";
import TuneOutlinedIcon from "@mui/icons-material/TuneOutlined";
import KeyboardArrowRightOutlinedIcon from "@mui/icons-material/KeyboardArrowRightOutlined";
import KeyboardArrowUpOutlinedIcon from "@mui/icons-material/KeyboardArrowUpOutlined";
import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined";
import { CheckBox } from "@mui/icons-material";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import DriveFileRenameOutlineIcon from "@mui/icons-material/DriveFileRenameOutline";
import VisibilityIcon from "@mui/icons-material/Visibility";
import DeleteIcon from "@mui/icons-material/Delete";
import "./LDmainPage.css";
import React, { useContext, useEffect, useState } from "react";
import RightSidebar from "./RightSidebar";
import { DataGrid, GridExpandMoreIcon } from "@mui/x-data-grid";
import CategoryIcon from "@mui/icons-material/Category";
import HandshakeIcon from "@mui/icons-material/Handshake";
import axios from "axios";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import LDcustomTable from "./LDcustomTable";
import { api, Apiaddress } from "../../../../utils/consts";
import profileContext from "../../../../context/profile/context";
import { NavbarForDirectory } from "../../../BuilderComponents/ProjectDashboard/DashboardComponents/NavbarForDirectory";
import TabBar from "../../Components/tabBar/TabBar";
import CPC from "../../context/context";
import { useNavigate } from "react-router-dom";
import PDmainPage from "../ProjectDirectory/PDmainPage";
import { AuthService } from "../../../../context/buyer/services/auth";
import { CPUnit_Directory } from "../UnitDirectory/Unit_Directory_w_sb";
import { StandAloneUnitMainPage } from "../UnitDirectory/StandAloneUnitMainPage";
import SideBar from "../../Components/sideBar/SideBar";

function LDmainPage(p) {
  ////get user roles from local storage and assign
  let roles = JSON.parse(localStorage.getItem("user_roles"));
  const [TabIndex, setTabIndex] = useState(0);
  const [state, setState] = React.useState(false);

  console.log(TabIndex);

  const cpDetails=JSON.parse(localStorage.getItem('cpDetails'));
  console.log(cpDetails);
  let cpOrg=cpDetails?.channelPartnerOrg;

  const navigate = useNavigate();

  const toggleDrawer = (open, item) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    updateLead(item);
    setState(open);
  };

  const [trigger, setTrigger] = useState(false);
  const [selectedRows, setSelectedRows] = React.useState([]);
  useEffect(() => {
    console.log(selectedRows);
  }, [selectedRows]);

  const [curselectedRows, setcurselectedRows] = useState([]);
  const [rowSelected, setRowSelected] = useState(false);

  console.log(curselectedRows);
  const [tabledata, setTabledata] = useState(null);
  const [rowData, setrowData] = useState([]);
  const [idCont, setidCunt] = useState(1);
  const [orgID, setOrgID] = useState(null);
  const [leadsFromApi, setleadsFromApi] = useState([]);
  //context

  const user = useContext(profileContext);

  useEffect(() => {
    console.log(user);
    // console.log(user.UserState);
    if (user)
      if (user.UserState)
        if (user.UserState.user_obj)
          if (user.UserState.user_obj.id) setOrgID(user.UserState.user_obj.id);
  }, [user]);
  //context
  const cpc = useContext(CPC);

  const fetchLeads = async () => {
    const s = new AuthService();

    const res = await (
      await s.makeAuthenticatedGetRequest(api("/api/role/inventeryofcp/"))
    ).json();

    console.log("INVENTORY", res);
  };

  useEffect(() => {
    if (leadsFromApi) {
      let arr = [];
      for (let i = 0; i < leadsFromApi.length; i++) {
        // console.log(leadsFromApi[i]);
        let obj = {
          id: i + 1,
          lead_id: leadsFromApi[i].id,
          Name: leadsFromApi[i].name,
          Phone: leadsFromApi[i].phone,
          Email: leadsFromApi[i].email,
          Projects: leadsFromApi[i].project,
          Priority: leadsFromApi[i].lead_prority.name,
          DetailedView: "View",
          Address: leadsFromApi[i].address_line,
        };
        setidCunt(idCont + 1);
        arr.push(obj);
      }
      setrowData(arr);
    }
  }, [leadsFromApi, trigger]);

  useEffect(() => {
    if (orgID) fetchLeads();
  }, [orgID, state, trigger, TabIndex]);

  useEffect(() => {
    const rows = [
      {
        id: 1,
        Name: "Snow",
        Phone: "912912912",
        Email: "dummmy@gamil.com",
        Projects: 2,
        Priority: "High",
        DetailedView: "View",
      },
    ];
    if (tabledata) {
      let obj = {
        id: idCont,
        Name: tabledata.name,
        Phone: tabledata.phone,
        Email: tabledata.Email,
        Projects: tabledata.setProjects.length,
        Priority: tabledata.priority,
        DetailedView: "View",
      };
      setidCunt(idCont + 1);
      // console.log(obj);
      setrowData([...rowData, obj]);
    }

    console.log(tabledata);
  }, [tabledata]);

  useEffect(() => {
    console.log(rowData);
  }, [rowData]);
  const navigator = useNavigate();
  const [updateData, setupdateData] = useState(null);
  const updateLead = (item) => {
    console.log("le lead", item);
    // setupdateData(item);
    navigator("/channelpartner/leads/" + item.lead_id);
  };

  const delIconClick = async (item) => {
    console.log(item);
    try {
      let arr = [];
      arr.push(item.lead_id);
      const obj = { lead_id: arr };
      const res = await axios.delete(Apiaddress + "/api/leads/lead/", {
        data: obj,
      });
      setTrigger(!trigger);
      console.log(res);
    } catch (err) {
      console.log(err);
    }
  };

  //fitering data starts:====
  const [filterselect, setfilterselect] = useState();
  const [filtervalue, setfiltervalue] = useState();

  const handleChange = (event) => {
    setfilterselect(event.target.value);
    setfiltervalue("");
  };

  console.log(filterselect);
  console.log(filtervalue);

  const fetchfilter = async () => {
    try {
      const res = await axios.get(
        Apiaddress +
          `/api/leads/channelpartnerleads/?channel_partner_id=${orgID}&filterkey=${filterselect}&filtervalue=${filtervalue}`
      );
      if (res.data?.leads?.length == 0) {
        setleadsFromApi([]);
      } else {
        setleadsFromApi(res.data.leads);
      }
      console.log(res.data.leads);
    } catch (err) {
      console.log(err);
    }
  };
  const urls = [
    ,
    "/api/leads/channelpartnerleads/?channel_partner_id=",

    "/api/leads/channelpartnerleads/?channel_partner_id=",
  ];

  //////select btn to make priority and mark project ;  
  const [makePriority, setMakePriority] = useState(false);
  const [makeProject, setMakeProject] = useState(false);
  const [InternalTabIndex, setInternalTabIndex] = useState(0);

  console.log(InternalTabIndex);

  return (
    <>
      <Stack direction="row" sx={{ width: "100%", height: "auto"}}>
        {/* 1st stack */}
        <SideBar 
        active={"Inventory"}
        InternalTabIndex={InternalTabIndex}
        // addProjectNew={
        // <Button
        //   className="univercel_btn_class"
        //     sx={{
        //       color: "#FFFFFF",
        //       textTransform: "none",
        //       margin: "3px",
        //       padding: "6px 24px",
        //       fontFamily: "Product Sans",
        //       fontSize: "16px",
        //     }}
        //     onClick={() => setState(true)}
        //   >
        //   Add New Project
        // </Button>
        // }
      addStandUnit={
        <Button
                  onClick={() => {
                    navigate(`/channelpartner/unit/add/${"unit"}`, {
                      state: { project: true },
                    });
                  }}
                  className="univercel_btn_class"
                  sx={{
                    color: "#FFFFFF",
                    textTransform: "none",
                    width: "120px",
                    padding: "8px 0px",
                    marginRight: "20px",
                  }}
                >
                  Add unit
                </Button>
      }
      >

        {/* 2nd stack */}
        <Stack
          direction="column"
          sx={{
            backgroundColor: "#f9f9f9",
            width: "100%",
            boxSizing: "border-box",
          }}
        >
          {" "}
          <Stack sx={{ mt: "0px", backgroundColor: "#f9f9f9" }}>
            <React.Fragment>
              <Drawer
                anchor="right"
                open={state}
                onClose={() => {
                  setState(false);
                  setMakePriority(false);
                  setMakeProject(false);
                }}
              >
                <RightSidebar
                  statesetter={setState}
                  setTabledata={setTabledata}
                  toggleDrawer={toggleDrawer}
                  curselectedRows={
                    curselectedRows.length == 0 ? null : curselectedRows
                  }
                  orgID={orgID}
                  updateData={updateData}
                  setTrigger={setTrigger}
                  trigger={trigger}
                  makePriority={makePriority}
                  makeProject={makeProject}
                  setMakePriority={setMakePriority}
                  setMakeProject={setMakeProject}
                />
              </Drawer>
            </React.Fragment>

            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              sx={{ marginBottom: "10px" }}
             >
              <Stack>
                <Typography
                  sx={{
                    fontSize: "22px",
                    fontWeight: "600",
                    color: "black",
                  }}
                >
                  Inventory
                </Typography>
                <Typography
                  sx={{
                    color: "#B9B7D0",
                    fontSize: "16px",
                    fontWeight: "400",
                  }}
                >
                 Manage your Inventory with ease
                </Typography>
              </Stack>
            </Stack>

            <Box>
              {curselectedRows.length != 0 && (
                <Stack direction="row" sx={{ margin: "20px" }}>
                  {
                    <Button
                      onClick={() => {
                        // toggleDrawer(true);
                        setState(true);
                        setMakePriority(true);
                      }}
                      startIcon={<CategoryIcon />}
                      className="univercel_btn_class"
                      sx={{
                        color: "#FFFFFF",
                        textTransform: "none",
                        width: "150px",
                        background:
                          " linear-gradient(150deg, #79F1A4 13.4%, #0E5CAD 86.6%)",
                      }}
                    >
                      Mark Project
                    </Button>
                  }
                  <Button
                    startIcon={<HandshakeIcon />}
                    onClick={() => {
                      // toggleDrawer(true);
                      setState(true);
                      setMakeProject(true);
                    }}
                    className="univercel_btn_class"
                    sx={{
                      color: "#FFFFFF",
                      textTransform: "none",
                      background:
                        " linear-gradient(150deg, #79F1A4 13.4%, #0E5CAD 86.6%)",
                      width: "150px",
                      marginLeft: "20px",
                    }}
                  >
                    Mark Client
                  </Button>
                </Stack>
              )}
              <Stack></Stack>
            </Box>

            <TabBar
              peresistantid="2"
              onIndexChange={(e) => {
                setTabIndex(e);
              }}
              Tabs={[
                {
                  children: [
                    <TabBar
                      peresistantid="1"
                      floatingActonButton={[
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            margin: "2px",
                          }}
                        >
                          {/* {InternalTabIndex == 0 && (
                            <Button
                              className="univercel_btn_class"
                              sx={{
                                // marginRight:"30px",
                                color: "#FFFFFF",
                                textTransform: "none",
                                margin: "3px",
                                padding: "6px 24px",
                                fontFamily: "Product Sans",
                                fontSize: "16px",
                              }}
                              onClick={() => setState(true)}
                            >
                              Add New Project
                            </Button>
                          )} */}
                        </div>
                      ]}
                      onIndexChange={(e) => setInternalTabIndex(e)}
                      Tabs={[
                        {
                          Name: "Projects",
                          children: [
                            <PDmainPage
                              builderInvited={false}
                              showonlyself={true}
                              setTrigger={setTrigger}
                              trigger={trigger}
                              setProState={setState}
                            />,
                          ],
                        },
                        {
                          Name: "Standalone Units",
                          children: [
                            <StandAloneUnitMainPage
                              builderInvited={false}
                              setTrigger={setTrigger}
                              trigger={trigger}
                            />,
                          ],
                        },
                      ]}
                    ></TabBar>,
                  ],
                  Name: "Inventories",
                },
              ]}
            ></TabBar>
          </Stack>
        </Stack>
      </SideBar>
      </Stack>
    
    </>
  );
}

export default LDmainPage;
