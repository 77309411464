import {
  Box,
  Container,
  Grid,
  Paper,
  Stack,
  styled,
  Typography,
} from "@mui/material";

import BuyerDigitalPropertyTour from "../../Imgs/buyer-landing-digital-property-tour.png";
import BuyerPropertyUpdates from "../../Imgs/buyer-landing-property-updates.png";
import BuyerPurchaseDetailsAccess from "../../Imgs/buyer-landing-purchase-details-access.png";
import BuyerSecureAccessToDocumentation from "../../Imgs/buyer-landing-secure-access-to-documentation.png";

const Item = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(1),
  color: theme.palette.text.secondary,
  boxShadow: "none",
  backgroundColor: "transparent",
}));

const BuyerLandingEmpoweringHomeBuyers = () => {
  return (
    <Box sx={{ overflowX: "hidden" }}>
      <Container>
        <Stack spacing={2} sx={{ textAlign: "center" }}>
          <Typography
            sx={{
              color: "#272D4E",
              fontSize: { xs: "32px", md: "40px" },
              fontWeight: 700,
              fontFamily: "Circular Std",
              lineHeight: "1.3",
              display: { xs: "none", md: "block" },
            }}
          >
            Empowering Home Buyers with a <br /> robust technology solution
          </Typography>
          <Typography
            sx={{
              color: "#272D4E",
              fontSize: { xs: "28px", md: "32px" },
              fontWeight: 700,
              fontFamily: "Circular Std",
              lineHeight: "1.3",
              textAlign: "center",
              display: { xs: "block", md: "none" },
            }}
          >
            Empowering Home Buyers with a robust technology solution
          </Typography>
          <Typography
            sx={{
              color: "#343434",
              fontSize: "18px",
              fontWeight: 400,
              fontFamily: "Product Sans",
              display: { xs: "none", md: "block" },
            }}
          >
            Your EnvisionNext account is a one-stop for all your purchase <br />
            details, documentation, and everything in between.
          </Typography>
          <Typography
            sx={{
              color: "#343434",
              fontSize: "18px",
              fontWeight: 400,
              fontFamily: "Product Sans",
              display: { xs: "block", md: "none" },
            }}
          >
            Your EnvisionNext account is a one-stop for all your purchase
            details, documentation, and everything in between.
          </Typography>
        </Stack>

        <Box sx={{ flexGrow: 1, mt: "50px" }}>
          <Grid container spacing={4}>
            <Grid item xs={12} md={6} data-aos="fade-right">
              <Item>
                <Stack spacing={1} sx={{ textAlign: "center" }}>
                  <Box
                    sx={{
                      width: "100%",
                      height: { xs: "auto", md2: "300px" },
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <img
                      style={{ width: "80%" }}
                      src={BuyerPropertyUpdates}
                      alt=""
                    />
                  </Box>

                  <Typography
                    sx={{
                      color: "#272D4E",
                      fontSize: { xs: "28px", md: "32px" },
                      fontWeight: 600,
                      fontFamily: "Product Sans",
                    }}
                  >
                    Property Updates
                  </Typography>
                  <Typography
                    sx={{
                      color: "#343434",
                      fontSize: "18px",
                      fontWeight: 400,
                      fontFamily: "Product Sans",
                      textAlign: "center",
                      display: { xs: "block", md: "none" },
                    }}
                  >
                    Get regular updates from your Builder on possession, project
                    completion, and more.
                  </Typography>
                  <Typography
                    sx={{
                      color: "#343434",
                      fontSize: "18px",
                      fontWeight: 400,
                      fontFamily: "Product Sans",
                      textAlign: "center",
                      display: { xs: "none", md: "block" },
                    }}
                  >
                    Get regular updates from your Builder <br /> on possession,
                    project completion, and more.
                  </Typography>
                </Stack>
              </Item>
            </Grid>
            <Grid item xs={12} md={6} data-aos="fade-left">
              <Item>
                <Stack spacing={1} sx={{ textAlign: "center" }}>
                  <Box
                    sx={{
                      width: "100%",
                      height: { xs: "auto", md2: "300px" },
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <img
                      style={{ width: "80%" }}
                      src={BuyerPurchaseDetailsAccess}
                      alt=""
                    />
                  </Box>

                  <Typography
                    sx={{
                      color: "#272D4E",
                      fontSize: { xs: "28px", md: "32px" },
                      fontWeight: 600,
                      fontFamily: "Product Sans",
                    }}
                  >
                    Purchase Details Access
                  </Typography>
                  <Typography
                    sx={{
                      color: "#343434",
                      fontSize: "18px",
                      fontWeight: 400,
                      fontFamily: "Product Sans",
                    }}
                  >
                    Access all your purchase details directly from the platform
                    on the go and save time and hassle.
                  </Typography>
                </Stack>
              </Item>
            </Grid>
            <Grid item xs={12} md={6} data-aos="fade-right">
              <Item>
                <Stack spacing={1} sx={{ textAlign: "center" }}>
                  <Box
                    sx={{
                      width: "100%",
                      height: { xs: "auto", md2: "300px" },
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <img
                      style={{ width: "80%" }}
                      src={BuyerSecureAccessToDocumentation}
                      alt=""
                    />
                  </Box>

                  <Typography
                    sx={{
                      color: "#272D4E",
                      fontSize: { xs: "28px", md: "32px" },
                      fontWeight: 600,
                      fontFamily: "Product Sans",
                      lineHeight: "1.4",
                    }}
                  >
                    Secure Access to Documentation
                  </Typography>
                  <Typography
                    sx={{
                      color: "#343434",
                      fontSize: "18px",
                      fontWeight: 400,
                      fontFamily: "Product Sans",
                    }}
                  >
                    Securely access all your financial and property-related
                    documents directly from your Builder. Save yourself from
                    unnecessary to and fro.
                  </Typography>
                </Stack>
              </Item>
            </Grid>
            <Grid item xs={12} md={6} data-aos="fade-left">
              <Item>
                <Stack spacing={1} sx={{ textAlign: "center" }}>
                  <Box
                    sx={{
                      width: "100%",
                      height: { xs: "auto", md2: "300px" },
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <img
                      style={{ width: "80%" }}
                      src={BuyerDigitalPropertyTour}
                      alt=""
                    />
                  </Box>

                  <Typography
                    sx={{
                      color: "#272D4E",
                      fontSize: { xs: "28px", md: "32px" },
                      fontWeight: 600,
                      fontFamily: "Product Sans",
                    }}
                  >
                    Digital Property Tour
                  </Typography>
                  <Typography
                    sx={{
                      color: "#343434",
                      fontSize: "18px",
                      fontWeight: 400,
                      fontFamily: "Product Sans",
                    }}
                  >
                    See how your home is shaping up with your photos, videos,
                    and 3D tours without visiting the site.
                  </Typography>
                </Stack>
              </Item>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </Box>
  );
};

export default BuyerLandingEmpoweringHomeBuyers;
