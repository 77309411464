import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Backdrop,
  Box,
  Button,
  Checkbox,
  Drawer,
  Fade,
  FormControl,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { Stack } from "@mui/system";
import TuneOutlinedIcon from "@mui/icons-material/TuneOutlined";
import KeyboardArrowRightOutlinedIcon from "@mui/icons-material/KeyboardArrowRightOutlined";
import KeyboardArrowUpOutlinedIcon from "@mui/icons-material/KeyboardArrowUpOutlined";
import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined";
import { CheckBox } from "@mui/icons-material";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import DriveFileRenameOutlineIcon from "@mui/icons-material/DriveFileRenameOutline";
import VisibilityIcon from "@mui/icons-material/Visibility";
import DeleteIcon from "@mui/icons-material/Delete";
import ReportProblemIcon from "@mui/icons-material/ReportProblem";
import { GridCloseIcon } from "@mui/x-data-grid";
import "./LDmainPage.css";
import React, { useContext, useEffect, useState } from "react";
import RightSidebar from "./RightSidebar";
import { DataGrid, GridExpandMoreIcon } from "@mui/x-data-grid";
import CategoryIcon from "@mui/icons-material/Category";
import HandshakeIcon from "@mui/icons-material/Handshake";
import axios from "axios";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import LDcustomTable from "./LDcustomTable";
import { api, Apiaddress } from "../../../../utils/consts";
import profileContext from "../../../../context/profile/context";
import { NavbarForDirectory } from "../../../BuilderComponents/ProjectDashboard/DashboardComponents/NavbarForDirectory";
import TabBar from "../../Components/tabBar/TabBar";
import CPC from "../../context/context";
import { useNavigate } from "react-router-dom";
import { AuthService } from "../../../../context/buyer/services/auth";
import CircularProgress from "@mui/material/CircularProgress";
import KeyboardDoubleArrowLeftIcon from "@mui/icons-material/KeyboardDoubleArrowLeft";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import RestartAltIcon from '@mui/icons-material/RestartAlt';

function LDmainPage(p) {

  const [TabIndex, setTabIndex] = useState(0);
  const [trigger, setTrigger] = useState(false);
  const [state, setState] = React.useState(false);
  const [selectedLeads, setSelectedLeads] = useState([]);
  const [loading, setloading] = useState(false);
  const [curpage, setcurpage] = useState(1);

  //To open Dwawer
  const toggleDrawer = (open, item) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    updateLead(item);
    setState(open);
  };

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 450,
    bgcolor: "background.paper",
    border: "2px solid #fff",
    borderRadius: "10px",
    boxShadow: 24,
    p: 4,
  };

  const [confirmText, setConfirmText] = useState("");
  const [toDelete, setToDelete] = useState();
  const [isClient, setIsClient] = useState(false);
  const [match, setMatch] = useState(false);

  const [open, setOpen] = useState(false);
  const handleClose = () => setOpen(false);
  const handleOpen = () => {
    setOpen(true);
  }

  const CheckClient = async (item) => {
    try {
      const Auth = new AuthService();
      let url = Apiaddress + `/api/leads/CpClientById/?lead_id=${item?.lead_id}`;
      const res = await axios.get(url);
      console.log(res.data.Client);
      if (res?.data?.Client == "Lead_id not found") {
        setIsClient(false);
      }
      else {
        setIsClient(true);
      }
    } catch (err) {
      console.log(err.message);
    }
  }

  const cpDetails = JSON.parse(localStorage.getItem('cpDetails'));
  console.log(cpDetails);
  let cpOrg = cpDetails?.channelPartnerOrg;

  const [curselectedRows, setcurselectedRows] = useState([]);
  const [rowSelected, setRowSelected] = useState(false);

  console.log(curselectedRows);
  console.log(rowSelected);

  console.log(curselectedRows);
  const [tabledata, setTabledata] = useState(null);
  const [rowData, setrowData] = useState([]);
  const [idCont, setidCunt] = useState(1);
  const [orgID, setOrgID] = useState(null);
  const [leadsFromApi, setleadsFromApi] = useState([]);
  const [totalLead,setTotalLead] = useState(null);
  const [startLead,setStartLead] = useState(0);
  //context

  const user = useContext(profileContext);

  const [formState, setFormState] = useState({});

  useEffect(() => {
    if (user)
      if (user.UserState)
        if (user.UserState.user_obj)
          if (user.UserState.user_obj.id) setOrgID(user.UserState.user_obj.id);
  }, [user]);
  const cpc = useContext(CPC);

  const fetchLeads = async () => {
    setloading(true);
    const Auth = new AuthService();
    try {
      let Leads = [];
      if (TabIndex == 0) {
        setleadsFromApi([]);
        let url1 = "";
        if (cpOrg) {
          url1 = `/api/leads/channelpartnerSelfleads/?limit=${7}&offset=${startLead}&org_id=${cpOrg}`
        }
        else {
          url1 = `/api/leads/channelpartnerSelfleads/?channel_partner_id=${Auth.getCPID()}&limit=${7}&offset=${startLead}`
        }
        const res = await axios.get(  
          Apiaddress + url1
        );
        console.log(res.data);
        if (res.data.leads == "Leads not found") {
          setleadsFromApi([]);
        } else {
          setleadsFromApi(res.data.leads);
          setTotalLead(res?.data?.total);
        }
      } else if (TabIndex == 1) {
        setleadsFromApi([]);
        let url1 = "";
        if (cpOrg) {
          url1 = `/api/leads/channelpartnerSelfleads/?limit=${7}&offset=${startLead}&org_id=${cpOrg}`
        }
        else {
          url1 = `/api/leads/channelpartnerSelfleads/?channel_partner_id=${Auth.getCPID()}&limit=${7}&offset=${startLead}`
        }
        const res1 = await axios.get(
          Apiaddress + url1
        );
        if (res1.data.leads == "Leads not found") {
          setleadsFromApi([]);
        } else {
          Leads = [...Leads, ...res1.data.leads];
        }
        console.log(res1.data);
        setleadsFromApi(Leads);
        setTotalLead(res1?.data?.total);
      }
      setloading(false);
    } catch (err) {
      console.log(err);
    }
  };

  const [allpriority, setallpriority] = useState(null);

  const getpriority = async () => {
    try {
      const res = await axios.get(Apiaddress + "/api/leads/leadprority/");
      console.log(res.data.leadprority);
      setallpriority(res.data.leadprority);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getpriority();
  }, []);
  const [prioritymap, setprioritymap] = useState(null);

  const setPri = () => {
    const mp = new Map();
    for (let i = 0; i < allpriority.length; i++) {
      mp.set(allpriority[i].id, allpriority[i].name);
    }
    setprioritymap(mp);
  };

  useEffect(() => {
    if (allpriority) {
      setPri();
    }
  }, [allpriority]);

  useEffect(() => {
    if (orgID) {
      fetchLeads();
    }
  }, [orgID, trigger, TabIndex]);

  useEffect(() => {
    console.log(rowData);
  }, [rowData]);

  const navigator = useNavigate();
  const updateLead = (item, value) => {
    localStorage.setItem("editLeadDetails", value);
    navigator(`/channelpartner/leads/${item.id}/edit`);  
  };

  const delIconClick = async (item) => {
    console.log(item);
    try {
      let arr = [];
      arr.push(item.id);
      const obj = { lead_id: arr };
      const res = await axios.delete(Apiaddress + "/api/leads/lead/", {
        data: obj,
      });
      setTrigger(!trigger);
      console.log(res);
      handleClose();
      setConfirmText("");
      setMatch(false);
    } catch (err) {
      console.log(err);
    }
  };

  //fitering data starts:====
  const [filterselect, setfilterselect] = useState();
  const [filtervalue, setfiltervalue] = useState();
  const [expanded, setExpanded] = useState(false);

  const handleChangeFilter = (event) => {
    setfilterselect(event.target.value);
    setfiltervalue("");
  };

  console.log(filterselect);
  console.log(filtervalue);

  const fetchfilter = async () => {  
    const Auth = new AuthService();
    try {
      let url1 = "";
        if (cpOrg) {
          url1 = `/api/leads/channelpartnerSelfleads/?limit=${7}&offset=${startLead}&org_id=${cpOrg}&filterkey=${filterselect}&filtervalue=${filtervalue}`
        }
        else {
          url1 = `/api/leads/channelpartnerSelfleads/?channel_partner_id=${Auth.getCPID()}&limit=${7}&offset=${startLead}&filterkey=${filterselect}&filtervalue=${filtervalue}`
        }
      const res = await axios.get(
        Apiaddress + url1
      );
      if (res.data.leads == "Leads not found") {
        setleadsFromApi([]);
      } else {
        setleadsFromApi(res.data.leads);
        setTotalLead(res?.data?.total);
      }
      console.log(res.data.leads);
    } catch (err) {
      console.log(err);
    }
  };

  //////select btn to make priority and mark project ;
  const [makePriority, setMakePriority] = useState(false);
  const [makeProject, setMakeProject] = useState(false);

  const cpuser = JSON.parse(localStorage.getItem("crm_user"));
  let username = cpuser?.user_details?.first_name;

  return (
    <>
      {/* 1st stack */}
      <Stack direction="row" sx={{ width: "100%", height: "auto" }}>
        {/* 2nd stack */}
        <Stack
          direction="column"
          sx={{
            backgroundColor: "#f9f9f9",
            width: "100%",
            boxSizing: "border-box",
          }}
        >
          {/* <Navbar/> */}
          {/* <NavbarForDirectory
            dontShowPath
          /> */}

          {/* actual content  */}
          <Stack sx={{ mt: "0px", backgroundColor: "#f9f9f9" }}>
            {/* Drawer Component */}
            <React.Fragment>
              <Drawer
                anchor="right"
                open={state}
                onClose={() => {
                  setState(false);
                  setMakePriority(false);
                  setSelectedLeads([]);
                }}
              >
                <RightSidebar
                  setTabledata={setTabledata}
                  toggleDrawer={toggleDrawer}
                  setState={setState}
                  curselectedRows={
                    curselectedRows.length == 0 ? null : curselectedRows
                  }
                  orgID={orgID}
                  updateData={selectedLeads}
                  setupdateData={setSelectedLeads}
                  setTrigger={setTrigger}
                  trigger={trigger}
                  makePriority={makePriority}
                  setMakePriority={setMakePriority}
                />
              </Drawer>
            </React.Fragment>

            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              sx={{ marginBottom: "20px" }}
            >
              <Box>
                <Typography
                  sx={{
                    fontSize: "22px",
                    fontWeight: "600",
                    color: "black",
                  }}
                >
                  Leads Directory
                </Typography>
                <Typography
                  sx={{
                    color: "#B9B7D0",
                    fontSize: "16px",
                    fontWeight: "400",
                  }}
                >
                  Manage your Leads
                </Typography>
              </Box>

            </Stack>


            {/* Main tab Swiching */}
            <TabBar
              onIndexChange={(e) => {
                setTabIndex(e);
                setSelectedLeads([]);
                setStartLead(0);
                setcurpage(1);
                setTrigger(!trigger);
                setfiltervalue("");
              }}
              Tabs={[
                {
                  children: [
                    <Stack sx={{ height: "500px" }}>
                      <LDcustomTable
                        selectedLeads={selectedLeads}
                        setSelectedLeads={setSelectedLeads}
                        newRowData={leadsFromApi}
                        updateLead={updateLead}
                        delIconClick={delIconClick}
                        toggleDrawer={toggleDrawer}
                        setRowSelected={setRowSelected}
                        rowSelected={rowSelected}
                        loading={loading}
                        CircularProgress={CircularProgress}
                        handleOpen={handleOpen}
                        setToDelete={setToDelete}
                        CheckClient={CheckClient}
                        setState={setState}
                        state={state}
                        totalLead={totalLead}
                        trigger={trigger}
                        setTrigger={setTrigger}
                        setStartLead={setStartLead}
                        startLead={startLead}
                        curpage={curpage}
                        setcurpage={setcurpage}
                        setMakePriority={setMakePriority}
                        filterselect={filterselect}
                        filtervalue={filtervalue}
                        setfilterselect={setfilterselect}
                        setfiltervalue={setfiltervalue}
                        handleChangeFilter={handleChangeFilter}
                        fetchfilter={fetchfilter}
                      />
                    </Stack>,
                  ],
                  Name: "Self Leads",
                },
                {
                  children: [
                    <Stack sx={{ height: "500px" }}>
                      <LDcustomTable
                        selectedLeads={selectedLeads}
                        setSelectedLeads={setSelectedLeads}
                        newRowData={leadsFromApi}
                        updateLead={updateLead}
                        delIconClick={delIconClick}
                        toggleDrawer={toggleDrawer}
                        setRowSelected={setRowSelected}
                        rowSelected={rowSelected}
                        loading={loading}
                        CircularProgress={CircularProgress}
                        handleOpen={handleOpen}
                        setToDelete={setToDelete}
                        CheckClient={CheckClient}
                        setState={setState}
                        totalLead={totalLead}
                        trigger={trigger}
                        setTrigger={setTrigger}
                        setStartLead={setStartLead}
                        startLead={startLead}
                        curpage={curpage}
                        setcurpage={setcurpage}
                        setMakePriority={setMakePriority}
                        filterselect={filterselect}
                        filtervalue={filtervalue}
                        setfilterselect={setfilterselect}
                        setfiltervalue={setfiltervalue}
                        handleChangeFilter={handleChangeFilter}
                        fetchfilter={fetchfilter}
                      />
                    </Stack>,
                  ],
                  Name: "All Leads",
                },
              ]}
            ></TabBar>

          </Stack>
        </Stack>
      </Stack>



      {/* delete template popup */}
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <Box sx={style}>
            <Box
              sx={{
                display: "flex",
                marginBottom: "25px",
                justifyContent: "space-between",
                marginTop: "-10px",
              }}
            >
              <Box sx={{ display: "flex", gap: "0.2rem" }}>
                <ReportProblemIcon sx={{ color: "red" }} />
                <Typography
                  sx={{
                    fontFamily: "Product Sans",
                    fontSize: "18px",
                    fontWeight: "600",
                    color: "red",
                  }}
                >
                  Delete Lead
                </Typography>
              </Box>
              <Box sx={{ textAlign: "right" }}>
                <GridCloseIcon
                  sx={{ color: "gray", cursor: "pointer" }}
                  onClick={handleClose}
                />
              </Box>
            </Box>
            {isClient === true ?
              <Stack >
                <Typography sx={{ fontSize: "18px" }}>This Lead is also a client.</Typography>
                <Typography sx={{ fontSize: "18px" }}>You can't delete this lead.</Typography>
              </Stack>
              :
              <>
                <Box>
                  <Typography
                    id="transition-modal-title"
                  >
                    Confirm that you want to delete this lead by typing - " 
                    <span sx={{ fontWeight: "600" }}>{toDelete?.name}</span>
                    "?
                  </Typography>
                  <TextField
                    placeholder={toDelete?.name}
                    onChange={(data) => {
                      setMatch(false);
                      setConfirmText(data.target.value);
                      if (data.target.value === toDelete?.name) {
                        setMatch(true);
                        console.log("Yes..")
                      }
                    }}
                    sx={{ mt: 3, width: "100%" }}
                  />
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "flex-end",
                    mt: 3,
                  }}
                >
                  <Button
                    onClick={handleClose}
                    sx={{
                      color: "#B9B7D0",
                    }}
                  >
                    Cancel
                  </Button>
                  <Button
                    disabled={!match}
                    sx={{
                      width: "20%",
                      color: "#06386B",
                      textTransform: "none",
                      fontFamily: "Product Sans",
                      ml: 1,
                      background: !match ? "gray" : "red",
                      "&:hover": {
                        background: "red",
                        boxShadow: "0px 9px 12px rgba(242, 178, 73, 0.59)",
                      },
                    }}
                    className="univercel_btn_class"
                    onClick={() => { delIconClick(toDelete) }}
                  >
                    Yes
                  </Button>
                </Box>
              </>}
          </Box>
        </Fade>
      </Modal>
    </>
  );
}

export default LDmainPage;
