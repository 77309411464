
import { Box, Button, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import DoneIcon from "@mui/icons-material/Done";
import { useNavigate } from "react-router-dom";
import { SuperAdminNavbar } from "../SidebarNavbar/SuperAdminNavbar";
import { SuperAdminSidebar } from "../SidebarNavbar/SuperAdminSidebar";
import { SAProjectSidebar } from "../SidebarNavbar/SAProjectSidebar";


export const CreateProjectOptionsSA = ()=>{
  const navigate = useNavigate();
    return(
        <>
        
<Stack direction="row" sx={{width:"100%",height:"auto" }}>
      {/* 1st stack */}
      <Stack sx={{minHeight:"100vh",width:"20%",boxSizing:"border-box",}}> {/*for project directory 20% and for sales dashboard 225*/}
         {/* <SuperAdminSidebar active={"Builder Directory"}/> */}
        <SAProjectSidebar active={"Project Dashboard"}/>
      </Stack>

     {/* 2nd stack */}
     <Stack direction="column" sx={{ backgroundColor:"#f9f9f9",width:"80%", padding:"25px",boxSizing:"border-box",}}  >  {/*  width for main sidebar 78px and for 80px*/}
     <SuperAdminNavbar createProject={true} /> {/*send props for get popup to verify otp from builder */}
      


      <Typography sx={{marginTop:"20px", fontSize:"28px", fontWeight:"600", marginLeft:"10px", marginBottom:"50px"}}>
      Create New Project
            </Typography>
     {/* actual content  */}
    <Stack sx={{backgroundColor:"#f9f9f9"}}>  {/* mt:"28px", */}

    <Stack
            direction="column"
            sx={{ padding: "20px", backgroundColor: "#fff", marginRight: "0px" }}
          >
            <Stack
              direction="row"
              justifyContent="start"
              alignItems="center"
              sx={{ height: "100px" }}
            >
              <Stack direction="row" alignItems="center" spacing={1.5} sx={{}}>
                <Box
                  sx={{
                    width: "29px",
                    height: "29px",
                    borderRadius: "50%",
                    backgroundColor: "#FFA946",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <DoneIcon sx={{ color: "#fff", fontSize: "20px" }} />
                </Box>
                <Typography sx={{ fontSize: "18px", fontWeight: "500" }}>Profile</Typography>
                <Stack direction="row" alignItems="center">
                  <Box
                    sx={{ width: "25px", height: "0px", borderBottom: "2px dashed orange" }}
                  ></Box>
                  <Box
                    sx={{
                      height: "10px",
                      width: "10px",
                      borderRadius: "50%",
                      backgroundColor: "orange",
                    }}
                  ></Box>
                </Stack>
              </Stack>
              <Stack direction="row" alignItems="center" spacing={1.5} sx={{ marginLeft: "20px" }}>
                <Box
                  sx={{
                    width: "29px",
                    height: "29px",
                    borderRadius: "50%",
                    backgroundColor: "#FFA946",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <DoneIcon sx={{ color: "#fff", fontSize: "20px" }} />
                </Box>
                <Typography sx={{ fontSize: "18px", fontWeight: "500" }}>
                  Unit layout Items
                </Typography>
                <Stack direction="row" alignItems="center">
                  <Box
                    sx={{ width: "25px", height: "0px", borderBottom: "2px dashed orange" }}
                  ></Box>
                  <Box
                    sx={{
                      height: "10px",
                      width: "10px",
                      borderRadius: "50%",
                      backgroundColor: "orange",
                    }}
                  ></Box>
                </Stack>
              </Stack>
              <Stack direction="row" alignItems="center" spacing={1.5} sx={{ marginLeft: "20px" }}>
                <Box
                  sx={{
                    width: "29px",
                    height: "29px",
                    borderRadius: "50%",
                    backgroundColor: "#FFA946",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <DoneIcon sx={{ color: "#fff", fontSize: "20px" }} />
                </Box>
                <Typography sx={{ fontSize: "18px", fontWeight: "500" }}>
                  Unit Pricing Items
                </Typography>
                <Stack direction="row" alignItems="center">
                  <Box
                    sx={{ width: "25px", height: "0px", borderBottom: "2px dashed #A2A3A6" }}
                  ></Box>
                  <Box
                    sx={{
                      height: "10px",
                      width: "10px",
                      borderRadius: "50%",
                      backgroundColor: "#A2A3A6",
                    }}
                  ></Box>
                </Stack>
              </Stack>
              <Stack direction="row" alignItems="center" spacing={1.5} sx={{ marginLeft: "20px" }}>
                <Box
                  sx={{
                    width: "29px",
                    height: "29px",
                    borderRadius: "50%",
                    backgroundColor: "#A2A3A6",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <DoneIcon sx={{ color: "#fff", fontSize: "20px" }} />
                </Box>
                <Typography sx={{ fontSize: "18px", fontWeight: "500" }}>Templates</Typography>
              </Stack>
            </Stack>

            <Stack
              direction="column"
              justifyContent="center"
              alignItems="center"
              sx={{ height: "50vh" }}
            >
              <Stack direction="row" justifyContent="center" alignItems="center" spacing={2}>
                <Button
                  onClick={() => {
                    navigate("/super-admin-create-project-csvconfiguration");
                  }}
                  className="univercel_btn_class"
                  sx={{ padding: "16px 40px 16px 40px", color: "#fff" }}
                >
                  CSV Upload
                </Button>
                <Button
                  onClick={() => {
                    navigate("/super-admin-create-project-mainscreen"); 
                  }}
                  sx={{
                    padding: "16px 59px 16px 59px",
                    color: "#1362AD",
                    textTransform: "none",
                    border: "1px solid #1362AD",
                  }}
                >
                  Manual
                </Button>
              </Stack>
              <Typography
                sx={{ fontSize: "18px", fontWeight: "500", color: "#A2A3A6", mt: "41px" }}
              >
                How to do you want to create the project?
              </Typography>
            </Stack>

            {/* <Stack direction="row" justifyContent="end" alignItems="center" spacing={2} sx={{marginTop:"50px"}}>
                       <Button 
                         onClick={()=>{
                          navigate(-1)
                         }}
                       size="large" className="univercel_btn_class" sx={{textTransform:"none", color:"#fff"}}  startIcon={<KeyboardDoubleArrowLeftIcon/>}>Previous</Button>
                          
                          <Button size="large" className="univercel_btn_class" sx={{textTransform:"none", color:"#fff"}}
                           onClick={()=>{
                             navigate("/createproject/projectpricing/projectcreateoption/mainscreen")
                           }}
                          endIcon={<KeyboardDoubleArrowRightIcon/>} >Next</Button>
                         
                       </Stack> */}
          </Stack>   

    </Stack>
      </Stack>
</Stack>
        </>
    )
}