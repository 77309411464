import { Box } from "@mui/system";
import React, { useContext, useEffect, useState } from "react";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";

import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import SendIcon from "@mui/icons-material/Send";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Button,
  Checkbox,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  Modal,
  OutlinedInput,
  Select,
  TextField,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";

import AddIcon from "@mui/icons-material/Add";
import axios from "axios";
import profileContext from "../../../context/profile/context";
import { Apiaddress } from "../../../utils/consts";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const names = ["Aditya Birla Appartments", "Tata Farms"];

function RightSidebarQD({
  setTabledata,
  curselectedRows,
  settrigger,
  trigger,
}) {
  const [expanded, setExpanded] = useState("panel1");
  const [disabelAccrodian, setdisabelAccrodian] = useState(
    curselectedRows ? "true" : "false"
  );
  const [selectValue, setSelectValue] = useState("High");
  const [setProjects, setsetProjects] = React.useState([]);

  const handleChangeLeadProject = (event) => {
    const {
      target: { value },
    } = event;
    setsetProjects(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };
  const [selectArr, setselectArr] = useState([
    {
      value: "High",
      label: "High",
    },
    {
      value: "low",
      label: "low",
    },
  ]);

  const createOpt = () => { };

  const handleChange = (i) => {
    setExpanded(i);
  };

  const [colors, setColors] = useState([
    { name: "Ajay", clr: "green" },
    { name: " Tarun", clr: " yellow" },
    { name: "  Ranbir", clr: "red" },
  ]);
  const [leadname, setLeadName] = useState("");
  const [leadclr, setLeadclr] = useState("");

  const addClr = () => {
    const obj = { name: leadname, clr: leadclr };
    setColors([...colors, obj]);
    handleClose();
  };

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [name, setName] = useState("");
  const [state, setState] = useState("");
  const [city, setCity] = useState("");
  const [pincode, setPincode] = useState("");

  const [orgId, setOrgID] = useState(null);

  const [val, setval] = useState(null);

  const addprofiledata = async () => {
    try {
      const obj = {
        org_name: name,
        city: city,
        zip_code: pincode,
        state: state,
      };
      const res = await axios.post(Apiaddress + "/api/org/", obj);
      console.log(res);
      if (res.data)
        if (res.data.org) if (res.data.org.id) setOrgID(res.data.org.id);
    } catch (err) {
      console.log(err);
    }
  };

  // useEffect(() => {
  //   if (orgId) {
  //     console.log(orgId);
  //   }
  // }, [orgId]);

  const [token, setToken] = useState(null);

  useEffect(() => {
    setToken(localStorage.getItem("crm_token"));
  }, []);

  const addOrgtoProfile = async () => {
    try {
      const headers = {
        Authorization: `JWT ${token}`,
      };
      const obj = {
        org_id: orgId,
      };

      const res = await axios.post(
        Apiaddress + "/api/addorgtoprofile/",
        obj,
        { headers: headers }
      );

      settrigger(!trigger);

      console.log(res);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (token && orgId) {
      addOrgtoProfile();
    }
  }, [token, orgId]);

  const [projectsSelected, setprojectsSelected] = useState(0);
  const [projectsSelectedID, setprojectsSelectedID] = useState(0);

  const addProjectID = (para) => {
    setprojectsSelectedID(para);
  };

  const addProjects = (e) => {
    setprojectsSelected(e.target.value);
  };

  const [data, setData] = useState(null);

  const getProjects = async () => {
    try {
      const headers = {
        Authorization: `JWT ${token}`,
      };
      const res = await axios.get(
        Apiaddress + "/api/projects/getprojectsusingtoken/",
        { headers }
      );
      // console.log(res.data.projects);
      if (res.data) if (res.data.projects) setData(res.data.projects);
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    if (token) getProjects();
  }, [token]);

  useEffect(() => {
    setToken(localStorage.getItem("crm_token"));
  }, []);

  // leads
  const [orgID, settOrgID] = useState(null);

  const [rowData, setrowData] = useState([]);
  const [idCont, setidCunt] = useState(1);
  const [leadsFromApi, setleadsFromApi] = useState([]);
  //context

  const user = useContext(profileContext);

  useEffect(() => {
    console.log(user);
    // console.log(user.UserState);
    if (user)
      if (user.UserState)
        if (user.UserState.user_obj)
          if (user.UserState.user_obj.orgList)
            if (user.UserState.user_obj.orgList[0]) {
              console.log(user.UserState.user_obj.orgList[0]);
              settOrgID(user.UserState.user_obj.orgList[0]);
            }
  }, [user]);
  //context

  const fetchLeads = async () => {
    try {
      const res = await axios.get(
        Apiaddress + `/api/leads/leadsoforg/?org_id=${orgID}`
      );
      console.log(res.data.leads);
      setleadsFromApi(res.data.leads);
    } catch (err) {
      console.log(err);
    }
  };

  const [allpriority, setallpriority] = useState(null);

  const getpriority = async () => {
    try {
      const res = await axios.get(
        Apiaddress + "/api/leads/leadprority/"
      );
      console.log(res.data.leadprority);
      setallpriority(res.data.leadprority);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getpriority();
  }, []);
  const [prioritymap, setprioritymap] = useState(null);

  const setPri = () => {
    const mp = new Map();
    for (let i = 0; i < allpriority.length; i++) {
      mp.set(allpriority[i].id, allpriority[i].name);
    }
    setprioritymap(mp);
  };

  useEffect(() => {
    if (allpriority) {
      setPri();
    }
  }, [allpriority]);

  useEffect(() => {
    if (leadsFromApi) {
      let arr = [];
      for (let i = 0; i < leadsFromApi.length; i++) {
        // console.log(leadsFromApi[i]);
        let obj = {
          id: i + 1,
          lead_id: leadsFromApi[i].id,
          Name: leadsFromApi[i].name,
          Phone: leadsFromApi[i].phone,
          Email: leadsFromApi[i].email,
          Projects: leadsFromApi[i].project,
          Priority: prioritymap
            ? prioritymap.get(leadsFromApi[i].lead_prority)
            : "",
          DetailedView: "View",
          Address: leadsFromApi[i].address_line,
        };
        setidCunt(idCont + 1);
        arr.push(obj);
      }
      setrowData(arr);
    }
  }, [leadsFromApi]);
  useEffect(() => {
    if (orgID) fetchLeads();
  }, [orgID, state, trigger]);

  useEffect(() => {
    console.log(rowData);
  }, [rowData]);

  //leads

  const [projectsSelectedLead, setprojectsSelectedLead] = useState(0);
  const [projectsSelectedIDlead, setprojectsSelectedIDlead] = useState(0);

  const addProjectIDlead = (para) => {
    setprojectsSelectedIDlead(para);
  };

  const addProjectsLead = (e) => {
    setprojectsSelectedLead(e.target.value);
  };

  const addQD = async () => {
    try {
      let obj = {
        adjustment: { name: "name1" },
        // valid_till: `${val} 10:20`,
        valid_till: `2023-02-02 10:20`,

        lead: projectsSelectedIDlead,
        project: projectsSelectedID,
        unit: 1,
      };
      const res = await axios.post(
        Apiaddress + "/api/leads/quotation/",
        obj
      );
      console.log(res);
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <Box
      sx={{
        width: "420px",
        height: "80vh",
        marginTop: "-25px",
        marginRight: "25px",
        padding: "10px",
      }}
    >
      <Box
        sx={{
          position: "relative",
        }}
      >
        <Box
          sx={{
            position: "absolute",
            top: "0px",
            right: "0px",
            background: "white",
            padding: "10px",
            height: "105vh",
            width: "400px",
            marginTop: "20px",
          }}
        >
          {" "}
          <Box>
            <Box sx={{ display: "flex", cursor: "pointer" }}>
              <Box>
                <ArrowBackIosIcon
                  sx={{
                    fontSize: "small",
                    marginBottom: "-2px",
                  }}
                />
              </Box>
              <Box>Add Quotation</Box>
            </Box>
            <Box
              sx={{
                display: "grid",
                alignItems: "center",
                height: "100vh",
              }}
            >
              <Box>
                <Accordion
                  expanded={true}
                  onClick={() => handleChange("panel1")}
                >
                  <AccordionSummary
                    sx={{
                      background: "#FFAF2A",
                      color: "black",
                      borderRadius: "10px",
                    }}
                    // expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    Add Quotation
                  </AccordionSummary>
                  <AccordionDetails>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        width: "400px",
                        margin: "10px 0",
                      }}
                    >
                      <Box>project</Box>
                      <Box>
                        <Box>
                          <FormControl
                            variant="standard"
                            sx={{ width: "230px" }}
                          >
                            <InputLabel id="demo-simple-select-standard-label">
                              select project
                            </InputLabel>
                            <Select
                              labelId="demo-simple-select-standard-label"
                              id="demo-simple-select-standard"
                              value={projectsSelected}
                              onChange={addProjects}
                              label="Age"
                            >
                              {data &&
                                data.map((item) => {
                                  return (
                                    <MenuItem
                                      value={item.name}
                                      onClick={() => addProjectID(item.id)}
                                    >
                                      {item.name}
                                    </MenuItem>
                                  );
                                })}
                            </Select>
                          </FormControl>
                        </Box>
                      </Box>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        width: "400px",
                        margin: "10px 0",
                      }}
                    >
                      <Box>Lead</Box>
                      <Box>
                        <Box>
                          <FormControl
                            variant="standard"
                            sx={{ width: "230px" }}
                          >
                            <InputLabel id="demo-simple-select-standard-label">
                              select lead
                            </InputLabel>
                            <Select
                              labelId="demo-simple-select-standard-label"
                              id="demo-simple-select-standard"
                              value={projectsSelectedLead}
                              onChange={addProjectsLead}
                              label="Age"
                            >
                              {rowData &&
                                rowData.map((item) => {
                                  return (
                                    <MenuItem
                                      value={item.Name}
                                      onClick={() =>
                                        addProjectIDlead(item.lead_id)
                                      }
                                    >
                                      {item.Name}
                                    </MenuItem>
                                  );
                                })}
                            </Select>
                          </FormControl>
                        </Box>
                      </Box>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        width: "400px",
                        margin: "10px 0",
                      }}
                    >
                      <Box>Valid date</Box>
                      <Box>
                        <TextField
                          value={val}
                          placeholder="yyyy-mm-dd"
                          id="outlined-basic"
                          onChange={(e) => setval(e.target.value)}
                        />
                      </Box>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        width: "400px",
                        margin: "10px 0",
                      }}
                    >
                      <Box></Box>
                      <Box>
                        <Button
                          onClick={addQD}
                          className="univercel_btn_class"
                          sx={{
                            color: "#FFFFFF",
                            textTransform: "none",
                          }}
                        >
                          submit
                        </Button>
                        {/* <Button
                          onClick={addprofiledata}
                          className="univercel_btn_class"
                          sx={{
                            color: "#B0AFAF",
                            textTransform: "none",
                            marginLeft: "10px",
                          }}
                        >
                          procced
                        </Button> */}
                      </Box>
                    </Box>
                  </AccordionDetails>
                </Accordion>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export default RightSidebarQD;
