import { Box, Stack, Typography } from "@mui/material";
import { Container } from "@mui/system";
import React from "react";
import CircleCheckIcon from "../../Imgs/circle-check-icon.svg";
import OneStopToConnectImg from "../../Imgs/one-stop-to-connect.png";

const BuilderLandingOneStopToConnect = () => {
  return (
    <Box sx={{ overflowX: "hidden" }}>
      <Container
        sx={{
          display: "flex",
          alignItems: "center",
          flexDirection: { xs: "column-reverse", md: "row" },
          gap: { xs: "50px", md: "20px" },
        }}
      >
        <Stack
          spacing={3}
          sx={{
            width: { xs: "100%", md: "55%" },
            mt: { xs: "-40px", md: "0px" },
          }}
        >
          <Typography
            data-aos="fade-right"
            data-aos-offset="200"
            data-aos-delay="200"
            sx={{
              color: "#272D4E",
              fontSize: { xs: "32px", md2: "36px", lg: "40px" },
              fontWeight: 700,
              fontFamily: "Circular Std",
              lineHeight: "1.2",
            }}
          >
            One stop to connect with Home Buyers & Channel Partners
          </Typography>
          <Stack spacing={{ xs: 1.5, md: 3 }}>
            <Box sx={{ display: "flex", gap: "10px" }}>
              <Box sx={{ mt: "-15px", fontSize: "30px" }}>
                {/* <img src={CircleCheckIcon} alt="" /> */}
                &#x2022;
              </Box>
              <Typography
                sx={{
                  fontSize: "16px",
                  color: "#343434",
                  fontFamily: "Product Sans",
                  lineHeight: "1.3",
                }}
              >
                Amid rapid digitization,{" "}
                <span style={{ fontWeight: 600 }}>
                  Real Estate Builders <br /> and Developers lack truly useful
                  options
                </span>{" "}
                to manage business and customer needs effectively.
              </Typography>
            </Box>
            <Box sx={{ display: "flex", gap: "10px" }}>
              <Box sx={{ mt: "-15px", fontSize: "30px" }}>
                {/* <img src={CircleCheckIcon} alt="" /> */}
                &#x2022;
              </Box>
              <Typography
                sx={{
                  fontSize: "16px",
                  fontFamily: "Product Sans",
                  color: "#343434",
                  lineHeight: "1.3",
                }}
              >
                For the first time in India, we are bringing an{" "}
                <span style={{ fontWeight: 600 }}>
                  integrated Real Estate technology solution.
                </span>{" "}
              </Typography>
            </Box>
            <Box sx={{ display: "flex", gap: "10px" }}>
              <Box sx={{ mt: "-15px", fontSize: "30px" }}>
                {/* <img src={CircleCheckIcon} alt="" /> */}
                &#x2022;
              </Box>
              <Typography
                sx={{
                  fontSize: "16px",
                  color: "#343434",
                  fontFamily: "Product Sans",
                  lineHeight: "1.3",
                }}
              >
                EnvisionNext is{" "}
                <span style={{ fontWeight: 600 }}>
                  not a regular CRM software.
                </span>{" "}
                It is your{" "}
                <span style={{ fontWeight: 600 }}>
                  doorway to deepen relationships
                </span>{" "}
                with Home Buyers and Channel Partners.
              </Typography>
            </Box>
            {/* <Box sx={{ display: "flex", gap: "10px" }}>
              <Box sx={{ mt: "-15px", fontSize: "30px" }}>
                {/* <img src={CircleCheckIcon} alt="" /> /}
                &#x2022;
              </Box>
              <Typography
                sx={{
                  fontSize: "16px",
                  color: "#343434",
                  fontFamily: "Product Sans",
                  lineHeight: "1.3",
                }}
              >
                It is your{" "}
                <span style={{ fontWeight: 600 }}>
                  doorway to deepen relationships
                </span>{" "}
                with Home Buyers and Channel Partners
              </Typography>
            </Box> */}
          </Stack>
        </Stack>
        <Box
          data-aos="zoom-in"
          data-aos-offset="300"
          sx={{
            width: { xs: "100%", md: "45%" },
          }}
        >
          <img style={{ width: "100%" }} src={OneStopToConnectImg} alt="" />
        </Box>
      </Container>
    </Box>
  );
};

export default BuilderLandingOneStopToConnect;
