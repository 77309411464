import axios from "axios";
import { useEffect, useState } from "react";
import { Apiaddress } from "../../../utils/consts";
import { useNavigate } from "react-router-dom";
import LockedDialog from "./components/LockedDialog";
import { Navbar } from "../ProjectDashboard/DashboardComponents/Navbar"
import OrganizationSelectionButton from "./components/OrganizationSelection";
import Infogrid from "./components/Infogrid";
import Statistics from "./components/statistics";
import ListProjects from "./components/ListProjects";
import UnitProjects from "./components/unitinProject";



import { Stack } from "@mui/system";
import { MainSidebar } from "../ProjectDashboard/DashboardComponents/MainSidebar";
import { UserRole } from "../../../UserRoles/UserRoles";
import { Typography } from "@mui/material";

export const BuilderDashboard = () => {
  ////get user roles from local storage and assign 
  let roles = JSON.parse(localStorage.getItem("user_roles"))  
  console.log(roles);
  let unit=[];
  let project=[];
  let org =[];
  if(roles){
  unit = roles["unit"]
  project = roles["project"];
  org = roles["org"];

  }
  
  const user = JSON.parse(localStorage.getItem("crm_user"));
  let username = user?.user_details?.first_name;
  console.log(user?.user_details?.first_name);

  const navigate = useNavigate();
  ////dialog box code
  const [open, setOpen] = useState(false);
  // const [open, setOpen] = useState(true);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const crm_token = localStorage.getItem("crm_token")

  console.log(crm_token)
  useEffect(() => {
    const headers = {
      'Content-Type': 'application/json',
      "Authorization": "JWT " + crm_token
    }
    axios.get(Apiaddress + "/api/profile/", {
      headers: headers
    })
      .then((res) => {
        const data = res.data;
        console.log(data.user_obj.is_active);

        if(data.user_obj.is_active===false){
          setOpen(true);
        }
        // setOpen(!(data.user_obj.is_active))

        console.log("res:", res.data)
      })
      .catch((err) => {
        console.log("err:", err)
      })
  }, [])

  ///maintain state for update the project list in the sales dashboard
  const [update, setUpdate] = useState(true)
  ///open close organization popup 
  const [OpenOrganization, setOpenOrganization] = useState(false)
  return (
    <>
      <Stack direction="row" sx={{ width: "100%", height: "auto"}} 
      // onClick={(event) => {
      //   event.stopPropagation(); 
      //   setOpenOrganization(false)
      // }}
      >
        {/* 1st stack */}
        <Stack sx={{ minHeight: "100vh", width: "20%", boxSizing: "border-box"}}>
          <MainSidebar active={"Sales Dashboard"} />  
        </Stack>

        {/* 2nd stack */}
        <Stack direction="column" sx={{ backgroundColor: "#f9f9f9", width: "80%", padding: "25px", boxSizing: "border-box"}}  >  {/*  width for main sidebar 78px and for 80px*/}
          {/* <Navbar/> */}
          <Stack direction="row" justifyContent="space-between" alignItems="center" sx={{marginBottom:"20px"}}>
            <Stack
              direction="row"
              justifyContent=""
              alignItems="center"
              sx={{width:"100%"}}
            >
            <Stack direction="row">
              <Typography className="WelcomeText" sx={{ fontSize: "24px",fontWeight:"600",marginRight:"8px"}}>
                Hello,
              </Typography>
              <Typography className="WelcomeText" sx={{ fontSize: "24px" }}>
                {username}
              </Typography>
            </Stack>
          </Stack>
          
          <Stack direction="row" alignItems="center">
            <Navbar childButton={UserRole(org, "view_org") ? <OrganizationSelectionButton setUpdate={setUpdate} update={update} OpenOrganization={OpenOrganization} setOpenOrganization={setOpenOrganization} /> : null} dontDisplayPath />
          </Stack>
        </Stack>

        <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              sx={{ marginBottom: "32px" }}
             >
              <Stack>
                <Typography
                  sx={{
                    fontSize: "22px",
                    fontWeight: "600",
                    color: "black",
                  }}
                >
                  Dashboard
                </Typography>
                <Typography
                  sx={{
                    color: "#B9B7D0",
                    fontSize: "16px",
                    fontWeight: "400",
                    width:"100%",
                   
                  }}
                >
                 Select a Project to get started 
                </Typography>
              </Stack>
            </Stack>

          {/* <Typography sx={{mt:"20px", fontSize:"22px", fontWeight:"500",color:"#2979F2"}}>  
    Project Name
    </Typography> */}
          {/* actual content  */}
          <Stack sx={{ mt: "5px", backgroundColor: "#f9f9f9" }}>  

            {/* <LockedDialog open={open} /> */}  
            <Stack direction="row" justifyContent="space-between" sx={{marginTop:"-15px"}}>  
              <Infogrid setUpdate={setUpdate} update={update} />
              <Statistics setUpdate={setUpdate} update={update} />
            </Stack>
            {UserRole(project, "view_project") &&
              <ListProjects setUpdate={setUpdate} update={update} />   
            }
            {UserRole(unit, "view_unit") &&
              <UnitProjects></UnitProjects>  
            }
          </Stack>




        </Stack>
      </Stack>




      {/* <Stack direction="row" sx={{}}>
        <Sidebar />

        <Stack
          direction="column"
          sx={{ width: "82%", backgroundColor: "#f9f9f9", padding: "25px", minHeight: "100vh" }}>
          <Navbar childButton={<OrganizationSelectionButton />} dontDisplayPath />
          <Stack
            direction="column"
            sx={{ padding: "20px", backgroundColor: "#fff", marginRight: "0px" }}>
            <LockedDialog open={open} />
            <Infogrid />
            <Statistics />
            <ListProjects />
            <UnitProjects></UnitProjects>
          </Stack>
        </Stack>
      </Stack> */}
    </>
  );
};



