import axios from "axios";
import { Apiaddress } from "../../utils/consts";

const listUnitTemplateOfProject = async (project_id:number) => {
    const url = Apiaddress+"/api/projects/unittemplatesbyproject/?project_id="+project_id;
    const re = await axios.get(url)
    const data = re.data;  
    return data;
}

export {listUnitTemplateOfProject};