import { Box, Container, Stack, Typography } from "@mui/material";
import AboutUs from "../../Imgs/about-us.png";
import CommonLandingMeetOurFounders from "../CommonLandingPage/CommonLandingMeetOurFounders";
import CommonLandingUnleashYourGrowth from "../CommonLandingPage/CommonLandingUnleashYourGrowth";
import CommonPagesFooter from "./CommonPagesFooter";
import CommonPagesFooterForm from "./CommonPagesFooterForm";
import CommonPagesNavbar from "./CommonPagesNavbar";
import CommonPagesResponsiveNavbar from "./CommonPagesResponsiveNavbar";

const CommonPagesAboutUs = () => {
  return (
    <Box sx={{ overflowX: "hidden" }}>
      <CommonPagesNavbar />
      <CommonPagesResponsiveNavbar />
      <Stack
        spacing={{ lg: 14, md: 12, xs: 6 }}
        sx={{ mt: { xs: "20px", md: "50px" } }}
      >
        <Box sx={{ backgroundColor: "#FFF" }}>
          <Container>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: { xs: "20px", md: "10px" },
                flexDirection: { xs: "column-reverse", md: "row" },
              }}
            >
              <Box sx={{ width: { xs: "100%", md: "55%" } }}>
                <Box data-aos="fade-right">
                  <Typography
                    sx={{
                      fontSize: "18px",
                      color: "#0085FF",
                      fontWeight: 700,
                      fontFamily: "Product Sans",
                    }}
                  >
                    ABOUT US
                  </Typography>
                  <Typography
                    sx={{
                      color: "#272D4E",
                      fontSize: { xs: "24px", md: "32px", lg: "40px" },
                      fontFamily: "Circular Std",
                      fontWeight: 700,
                      lineHeight: "1.2",
                      mt: "5px",
                    }}
                  >
                    Envisioning the next generation of the Real Estate industry
                  </Typography>
                </Box>
                <Stack spacing={2} sx={{ mt: "10px" }}>
                  <Typography
                    sx={{
                      color: "#343434",
                      fontSize: "18px",
                      fontFamily: "Product Sans",
                    }}
                  >
                    We are a team of industry experts and professionals,
                    including Chartered Accountants, Advocates, and Engineers,
                    with a vision to transform the real estate sector.”
                  </Typography>
                  <Typography
                    sx={{
                      color: "#343434",
                      fontSize: "18px",
                      fontFamily: "Product Sans",
                    }}
                  >
                    An organized and professional approach, combined with
                    digitization, is considered a vital element for the
                    development and growth of the industry.
                  </Typography>
                  <Typography
                    sx={{
                      color: "#343434",
                      fontSize: "18px",
                      fontFamily: "Product Sans",
                    }}
                  >
                    We are working towards uplifting the sector by involving
                    technology throughout the Real Estate cycle and enhancing
                    and defining processes to empower all the stakeholders in
                    the ecosystem.
                  </Typography>
                </Stack>
              </Box>
              <Box
                data-aos="fade-left"
                data-aos-offset="200"
                sx={{ width: { xs: "100%", md: "45%" } }}
              >
                <img style={{ width: "100%" }} src={AboutUs} alt="" />
              </Box>
            </Box>
          </Container>
        </Box>
        <CommonLandingMeetOurFounders />
        <CommonLandingUnleashYourGrowth />
        <CommonPagesFooterForm />
        <CommonPagesFooter />
      </Stack>
    </Box>
  );
};

export default CommonPagesAboutUs;
