import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  MenuItem,
  Drawer,
  Box,
  Button,
  Checkbox,
  FormControl,
  InputLabel,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { Stack } from "@mui/system";
import TuneOutlinedIcon from "@mui/icons-material/TuneOutlined";
import KeyboardArrowRightOutlinedIcon from "@mui/icons-material/KeyboardArrowRightOutlined";
import KeyboardArrowUpOutlinedIcon from "@mui/icons-material/KeyboardArrowUpOutlined";
import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined";
import { CheckBox } from "@mui/icons-material";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import DriveFileRenameOutlineIcon from "@mui/icons-material/DriveFileRenameOutline";
import VisibilityIcon from "@mui/icons-material/Visibility";
import DeleteIcon from "@mui/icons-material/Delete";
import "../MembersDirectory/MDmainPage.css";
import { Sidebar } from "../ProjectDashboard/DashboardComponents/Sidebar";
import { Navbar } from "../ProjectDashboard/DashboardComponents/Navbar";
import React, { useEffect, useState } from "react";
import { DataGrid, GridExpandMoreIcon } from "@mui/x-data-grid";
import CategoryIcon from "@mui/icons-material/Category";
import HandshakeIcon from "@mui/icons-material/Handshake";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import RightSidebarPD from "./RightSidebarPD";
import axios from "axios";
import { NavbarForDirectory } from "../ProjectDashboard/DashboardComponents/NavbarForDirectory";
import PDcustomTable from "./PDcustomTable";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Apiaddress } from "../../../utils/consts";
import { MainSidebar } from "../ProjectDashboard/DashboardComponents/MainSidebar";
import { UserRole } from "../../../UserRoles/UserRoles";

const columns = [
  { field: "id", headerName: "id", width: 130 },
  { field: "Name", headerName: "Name", width: 130 },
  { field: "State", headerName: "State", width: 130 },
  { field: "City", headerName: "City", width: 130 },
  {
    field: "Pincode",
    headerName: "Pincode",
    // type: "number",
    width: 130,
  },
  {
    field: "Unit",
    headerName: "Unit",
    width: 130,
  },
  {
    field: "Action",
    headerName: "Action",
    headerAlign: "center",
    width: 130,
    renderCell: (params) => {
      return (
        <Stack direction="row">
          <RemoveRedEyeIcon fontSize="small" />
          <DeleteIcon fontSize="small" />
        </Stack>
      );
    },
  },
];

const rows = [
  {
    id: 1,
    Name: "#4560",
    State: "26 March 2022, 12:42 AM",
    City: "Seema Agarwal",
    Pincode: "67 St. pune",
    Unit: "Agent",
  },
];

function PDmainPage() {
  
  ////get user roles from local storage and assign 
  let roles = JSON.parse(localStorage.getItem("user_roles"))
  
  let project = roles["project"];

  const [state, setState] = React.useState(false);

  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState(open);
  };

  const [selectedRows, setSelectedRows] = React.useState([]);
  useEffect(() => {
    console.log(selectedRows);
  }, [selectedRows]);

  const [curselectedRows, setcurselectedRows] = useState(null);
  const onRowsSelectionHandler = (ids) => {
    setRowSelected(true);
    const selectedRowsData = ids.map((id) => rows.find((row) => row.id === id));
    console.log(selectedRowsData);
    setcurselectedRows(selectedRowsData);
  };

  const [tabledata, setTabledata] = useState(null);
  const [rowData, setrowData] = useState([]);
  const [idCont, setidCunt] = useState(1);

  //api

  //api

  useEffect(() => {
    const rows = [
      {
        id: 8,
        Name: "Frances",
        Phone: "912912912",
        Email: "dummmy@gamil.com",
        Team: "Agent",
        Role: "Agent",
        DetailedView: "View",
      },
    ];
    if (tabledata) {
      let obj = {
        id: idCont,
        Name: tabledata.name,
        Phone: tabledata.phone,
        Email: tabledata.Email,
        Team: tabledata.Team,
        Role: tabledata.Role,
      };
      setidCunt(idCont + 1);
      console.log(obj);
      setrowData([...rowData, obj]);
    }

    console.log(tabledata);
  }, [tabledata]);

  const [rowSelected, setRowSelected] = useState(false);

  const [data, setData] = useState(null);
  const [token, setToken] = useState(null);

  const [trigger, setTrigger] = useState(false);
  const getProjects = async () => {
    try {
      const headers = {
        Authorization: `JWT ${token}`,
      };
      const res = await axios.get(
        Apiaddress + "/api/projects/getprojectsusingtoken/",
        { headers }
      );
      console.log(res.data.projects);
      if (res.data) if (res.data.projects)
       setData(res.data.projects);
       console.log("projectssss==>",res.data.projects)
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    if (token) getProjects();
  }, [token, trigger]);

  useEffect(() => {
    setToken(localStorage.getItem("crm_token"));
  }, [trigger]);
  const [newRowData, setNewRowData] = useState([]);
  useEffect(() => {
    if (data) {

      let arr = [];
      for (let i = 0; i < data.length; i++) {
        let obj = {
          id: i + 1,
          project_id: data[i].id,
          Name: data[i].name,
          State: data[i].state,
          City: data[i].city,
          Pincode: data[i].pin_code,
          Unit: "unit count",
        };
        arr.push(obj);
      }
      setNewRowData(arr);
    }
  }, [data]);

  const delIconClick = async (params) => {
    console.log(params);
    try {
      const obj = {
        project_id: params.project_id,
      };

      const res = await axios.delete(
        Apiaddress + "/api/projects/project/",
        {
          data: obj,
        }
      );
      setTrigger(!trigger);
      window.alert("delted successfully");
      console.log(res);
    } catch (err) {
      console.log(err);
    }
  };
  const [updateInfo, setupdateInfo] = useState(null);
  const updateBuyers = (params) => {
    setupdateInfo(params.row);
  };

  // const sortArr = (para) => {
  //   function compare(a, b) {
  //     if (a[para] < b[para]) {
  //       return -1;
  //     }
  //     if (a[para] > b[para]) {
  //       return 1;
  //     }
  //     return 0;
  //   }
  //   let arr = newRowData;
  //   arr.sort(compare);
  //   console.log(arr);
  //   setNewRowData(arr);
  // };


  //fitering data starts:====
  const [filterselect, setfilterselect] = useState();
  const [filtervalue, setfiltervalue] = useState();

  const handleChange = (event) => {
    setfilterselect(event.target.value);
    setfiltervalue("");
  };

  console.log(filterselect);
  console.log(filtervalue);

  const fetchfilter= async()=>{
     try {
      const headers = {
        Authorization: `JWT ${token}`,
      };
      const res = await axios.get(
        Apiaddress + `/api/projects/getprojectsusingtoken/?filterkey=${filterselect}&filtervalue=${filtervalue}`,
        { headers }
      );
      console.log(res.data.projects);
      if (res.data) if (res.data.projects) setData(res.data.projects);
    } catch (err) {
      console.log(err);
    }
  }



  return (
    <>


<Stack direction="row" sx={{width:"100%",height:"auto" }}>
      {/* 1st stack */}
      <Stack sx={{minHeight:"100vh",width:"20%",boxSizing:"border-box",}}>
        <MainSidebar active={"Project Directory"}/>
      </Stack>

     {/* 2nd stack */}
     <Stack direction="column" sx={{ backgroundColor:"#f9f9f9",width:"80%", padding:"25px",boxSizing:"border-box",}}  >  {/*  width for main sidebar 78px and for 80px*/}
      {/* <Navbar/> */}
      <NavbarForDirectory
          showAddProjectbtn={false}
          showRightSideBar={false}
          RightBarNameProAdd={"Add Project"}
          toggleDrawer={toggleDrawer}
          showRightSideBarProjAdd={UserRole(project, "add_project")? true: false}
          showRightSideBarleft={false}
          RightBarNameleft={"Send invite"}
          leftTitle={"Sales Dashboard"}
          righshow={true}
          leftRightTitle1={"Project Dashboard"}
          leftRightTitle2={"Project Directory"}
          longOrangBgr={false}
        />

      


    {/* <Typography sx={{mt:"20px", fontSize:"22px", fontWeight:"500",color:"#2979F2"}}>
    Project Name
    </Typography> */}
     {/* actual content  */}
    <Stack sx={{ mt:"28px",backgroundColor:"#f9f9f9"}}>

    <React.Fragment>
          <Drawer anchor="right" open={state} onClose={toggleDrawer(false)}>
            <RightSidebarPD
              setTabledata={setTabledata}
              curselectedRows={curselectedRows}
            />
          </Drawer>
        </React.Fragment>


        <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            sx={{ height: "68px", marginBottom: "20px" }}
          >
            <Stack direction="column" alignItems="center">
              <Typography
                sx={{
                  fontSize: "22px",
                  fontWeight: "600",
                  marginLeft: "-5px",
                  color: "black",
                }}
              >
                Project Directory
              </Typography>
              <Typography
                sx={{
                  color: "#B9B7D0",
                  fontSize: "12px",
                  fontWeight: "400",
                  paddingLeft: "20px",
                }}
              >
                Here is your Project Directory list data
              </Typography>
            </Stack>
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Stack
                direction="row"
                justifyContent="space-around"
                alignItems="center"
                sx={{
                  width: "203px",
                  height: "68px",
                  borderRadius: "12px",
                  color: "#3E4954",
                  backgroundColor: "#f4f5f9",
                }}
              >

               <Accordion sx={{ width: "250px", boxShadow: "none" }}>
                  <AccordionSummary
                    expandIcon={<GridExpandMoreIcon/>}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography sx={{ display: "flex", placeItems: "center" }}>
                      <TuneOutlinedIcon sx={{ color: "#2F4CDD" }} />
                      <Typography sx={{ marginLeft: "25px" }}>
                        Fillters
                      </Typography>
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Box sx={{textAlign:"center"}}>
                      <FormControl sx={{ width: "200px" }}>
                        <InputLabel id="demo-simple-select-label">
                          Select
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={filterselect}
                          label="Select"
                          onChange={handleChange}
                        >
                          <MenuItem value={"name"}>Name</MenuItem>
                          <MenuItem value={"state"}>State</MenuItem>
                          <MenuItem value={"city"}>City</MenuItem>
                          <MenuItem value={"pin_code"}>Pincode</MenuItem>
                          {/* <MenuItem value={"Name"}>Name</MenuItem> */}
                        </Select>
                      </FormControl>
                      <TextField
                        sx={{ marginTop: "20px" }}
                        placeholder="Enter the value" 
                        value={filtervalue} onChange={(e)=>{setfiltervalue(e.target.value)}}
                      />
                      <Button 
                        onClick={fetchfilter}
                        variant="contained"
                        color="primary" sx={{ height:"40px", background: "linear-gradient(90deg, #0F5DAE 0%, #009DCF 100.33%)",boxShadow: "0px 13.7981px 22.5788px rgba(50, 174, 137, 0.25)", textTransform: "none", borderRadius: "4px", width:"100px", fontSize:"15px",margin:"20px 0px 10px 0px"}}>
                         Submit
                      </Button>

                    </Box>
                  </AccordionDetails>
                </Accordion>

              </Stack>
            </Stack>
          </Stack>


          <PDcustomTable
            newRowData={newRowData}
            delIconClick={delIconClick}
            updateBuyers={updateBuyers}
            setTrigger={setTrigger}
            trigger={trigger}
          />

    </Stack>




      </Stack>
</Stack>



    
    </>



  );
}

export default PDmainPage;
