import { Box, Button, Drawer, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import React, { useEffect, useState } from "react";
import axios from "axios";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { ImageGrid } from "./courousal/imageGrid";
import { Apiaddress, net } from "../../../../utils/consts";
import SideBar from "../../Components/sideBar/SideBar";
import { AuthService } from "../../../../context/buyer/services/auth";
import RightSidebar from "../../appointments/LeadsDirectory/RightSidebar";
import NavigateBack from "../../../CommonPages/Shared/NavigateBack";
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';

function StandAloneUnitOverview() {
  let id = useParams().id;
  let unit = useLocation()?.state?.unit;
  let projectid = useLocation()?.state?.projectid;
  console.log({ unit });
  const navigate = useNavigate();

  useEffect(() => {
    let status = unit?.status;
    setUnitStatus(status);
    setImages(parseImagesFromUnitData(unit?.unit_data));
  }, []);
  
  const [images, setImages] = useState([]);
  const [unitStatus, setUnitStatus] = useState();

  const parseImagesFromUnitData = (p) => {
    const image = p?.media;
    console.log("oYE image", image);

    let imageUrlBucket = [];
    if (image !== undefined) {
      image.forEach((e) => {
        if (!e.is_file) {
          let obj={
            url:e.image,
            is_true:e.istrue
          }
          imageUrlBucket.push(obj);
        }
      });
    }
    return imageUrlBucket;
  };

  useEffect(() => {
    let status = unit?.status;
    setUnitStatus(status);
    setImages(parseImagesFromUnitData(unit?.unit_data));
  }, []);

  let cpData = JSON.parse(localStorage.getItem("crm_user"));

  const [UnitData, setUnitData] = useState([]);
  const [TotalAmount, setTotalAmount] = useState(0);

  //Deepak work for Quotation
  const [selectedUnit, setSelectedUnit] = useState({});
  const [unitCharge, setUnitCharge] = useState([]);

  const fetchUnitData = async () => {
    try {
      const Auth = new AuthService();
      const res = await await (
        await Auth.makeUnAuthenticatedGetRequest(
          net(
            "projects/cpunit/unit_id=" +
              id +
              "?cp_id=" +
              Auth.getCurrentBuyerId()
          )
        )
      ).json();
      // console.log("res", res);

      console.log("===========================.>", res?.data?.unit);

      //Deepak work for quotation
      let obj = {
        id: res?.data?.unit[0]?.id,
        name: res?.data?.unit[0]?.name,
      };
      setSelectedUnit(obj);

      let temp = res?.data?.unit[0]?.charges_data;
      let total = 0;
      if (Object.keys(temp).length === 0 && temp.constructor === Object) {
        setUnitCharge([]);
      } else {
        setUnitCharge(temp);
        for (let i = 0; i < temp.length; i++) {
          total += +temp[i].sectioncost;
        }
      }

      let tempCost = res?.data?.unit[0]?.charges_data;
      let status = res?.data?.unit[0]?.unit_status;
      // console.log(tempCost);
      if (res.data.unit == " Unit not found") {
        setUnitData([]);
      } else {
        let Tcost = 0;
        for (let i = 0; i < tempCost.length; i++) {
          Tcost = Tcost + tempCost[i].sectioncost;
        }
        setTotalAmount(Tcost);
        setUnitData(res?.data?.unit);
        // console.log("UNitt", res?.data?.unit);
        setImages(parseImagesFromUnitData(res?.data?.unit));
      }
    } catch (err) {
      console.log("====================", err);
      setUnitData([]);
    }
  };

  const [state, setState] = React.useState(false);

  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState(open);
  };

  // const [data, setData] = useState(null);
  // const [token, setToken] = useState(null);

  // const getProjects = async () => {
  //   try {
  //     const headers = {
  //       Authorization: `JWT ${token}`,
  //     };
  //     const res = await axios.get(
  //       Apiaddress + "/api/projects/getprojectsusingtoken/",
  //       { headers }
  //     );
  //     console.log(res.data.projects);
  //     if (res.data) if (res.data.projects) setData(res.data.projects);
  //   } catch (err) {
  //     console.log(err);
  //   }
  // };
  // useEffect(() => {
  //   if (token) getProjects();
  // }, [token]);

  // useEffect(() => {
  //   setToken(localStorage.getItem("crm_token"));
  // }, []);
  // const [newRowData, setNewRowData] = useState([]);

  // const fetchUnit = async () => {
  //   try {
  //     let arr = [];
  //     for (let i = 0; i < data.length; i++) {
  //       const res = await axios.get(
  //         Apiaddress + `/api/projects/searchunits/?query=${data[i].id}`
  //       );
  //       console.log(res);
  //       if (res) if (res.data) if (res.data.units) arr.push(res.data.units);
  //     }
  //     setNewRowData(arr);
  //   } catch (err) {
  //     console.log(err);
  //   }
  // };

  // useEffect(() => {
  //   if (data) {
  //     fetchUnit();
  //   }
  // }, [data]);


  // const [newUnits, setNewUnits] = useState(null);
  // useEffect(() => {
  //   if (newRowData) {
  //     let arr = [];
  //     for (let i = 0; i < newRowData.length; i++) {
  //       if (newRowData[i] != "No units found") {
  //         // console.log(newRowData[i]);
  //         for (let j = 0; j < newRowData[i].length; j++) {
  //           // console.log(newRowData[i][j]);
  //           arr.push(newRowData[i][j]);
  //         }
  //       }
  //     }
  //     setNewUnits(arr);
  //   }
  // }, [newRowData]);

  return (
    <>
      <React.Fragment>
        <Drawer
          anchor="right"
          open={state}
          onClose={() => {
            // toggleDrawer(false)
            setState(false);
          }}
        >
          <RightSidebar
            toggleDrawer={toggleDrawer}
            unit={unit}
            projectid={projectid}
            setState={setState}
          />
        </Drawer>
      </React.Fragment>
      <Stack direction="row" sx={{ width: "100%", height: "auto" }}>
        <SideBar active={"Inventory"}>
          {/* 2nd stack */}
          <Stack
            direction="column"
            sx={{
              backgroundColor: "#f9f9f9",
              width: "100%",
              boxSizing: "border-box",
              marginTop: "10px",
            }}
          >
            {/* actual content  */}
            <Stack sx={{ backgroundColor: "#f9f9f9" }}>
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                sx={{ height: "68px", marginBottom: "20px" }}
              >
                <Stack direction="column" alignItems="left">
                  <Stack direction="row">
                  <Box sx={{ pt: "5px" }}>
                    <NavigateBack fontSize="22px"/>
                  </Box>
                  <Typography
                    sx={{
                      fontSize: "22px",
                      fontWeight: "600",
                      color: "#2979F2",
                      marginRight:"8px",
                    }}
                  >
                    {unit?.name}
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: "22px",
                      fontWeight: "400",
                      color: "#2979F2",
                    }}
                  >
                    Overview
                  </Typography>
                  </Stack>
                  <Typography
                    sx={{
                      color: "#B9B7D0",
                      fontSize: "16px",
                      fontWeight: "400",
                    }}
                  >
                    Here is your Unit Overview Data
                  </Typography>
                </Stack>
              </Stack>

              <Stack
                direction="row"
                justifyContent="end"
                alignItems="center"
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <Button
                    onClick={() => {
                      navigate("/channelpartner/appointment", {
                        state: {
                          unit,
                          origin: "Inventory",
                          projectid,
                        },
                      });
                    }}
                    sx={{
                      //   width: "20%",
                      padding: "8px 16px",
                      color: "#06386B",
                      textTransform: "none",
                      fontFamily: "Product Sans",
                      mr: 2,
                      // "&:hover": {
                      //   boxShadow: "0px 9px 12px rgba(242, 178, 73, 0.59)",
                      // },
                    }}
                    className="univercel_btn_class"
                  >
                    Appointment List
                  </Button>
                  <Button
                    onClick={() => {
                      setState(true);
                    }}
                    sx={{
                      //   width: "20%",
                      padding: "8px 16px",
                      color: "#06386B",
                      textTransform: "none",
                      fontFamily: "Product Sans",
                      mr: 2,
                      // "&:hover": {
                      //   boxShadow: "0px 9px 12px rgba(242, 178, 73, 0.59)",
                      // },
                    }}
                    className="univercel_btn_class"
                  >
                    New Appointment
                  </Button>
                  <Typography
                    sx={{
                      fontSize: "18px",
                      fontWeight: "600",
                      color: "#343434",
                    }}
                  >{`Status: ${unitStatus}`}</Typography>
                </Box>
              </Stack>

              <Box sx={{ color: "#343434", marginTop: "30px" }}>
                <Box sx={{ display: "flex", gap: "20px" }}>
                  {unit ? (
                    <Box
                      sx={{
                        border: "4px solid lightgray",
                        padding: "10px",
                        boxShadow: "-6px 18px 15px rgba(0, 0, 0, 0.1)",
                        borderRadius: "10px",
                        width: "400px",
                      }}
                    >
                      {images?.length == 0 ? (
                        <Stack
                          justifyContent="center"
                          alignItems="center"
                          sx={{ width: "100%", height: "100%" }}
                        >
                          <Typography>No Images Found</Typography>
                        </Stack>
                      ) : (
                        <ImageGrid
                          image={images}
                          sx={{ border: "4px solid gray" }}
                        />
                      )}
                    </Box>
                  ) : (
                    <Typography>No Image Here</Typography>
                  )}
                  <Box
                    sx={{
                      padding: "25px",
                      width: "100%",
                      backgroundColor: "#FFF",
                      borderRadius: "10px",
                      boxShadow: "-6px 18px 15px rgba(0, 0, 0, 0.1)",
                      fontFamily: "Product Sans",
                    }}
                  >
                    {/*==============================start Showing Unit Data========================== */}
                    <Box
                      sx={{
                        mb: "20px",
                        fontSize: "24px",
                        fontWeight: 700,
                        fontFamily: "Circular Std",
                        paddingLeft: "10px",
                      }}
                    >
                      <Box sx={{ color: "#272D4E" }}>Property Details</Box>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        gap: "10px",
                        padding: "10px 15px",
                      }}
                    >
                      <Box sx={{ width: "25%", color: "#84828C" }}>Type :</Box>
                      <Box sx={{ color: "#272D4E" }}>
                        {unit?.unit_data?.Type}
                      </Box>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        gap: "10px",
                        padding: "10px 15px",
                      }}
                    >
                      <Box sx={{ width: "25%", color: "#84828C" }}>
                        RERA Number :
                      </Box>
                      <Box sx={{ color: "#272D4E" }}>
                        {unit?.unit_data?.reraNumber}
                      </Box>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        gap: "10px",
                        padding: "10px 15px",
                      }}
                    >
                      <Box sx={{ width: "25%", color: "#84828C" }}>
                        Quotation Price :
                      </Box>
                      <CurrencyRupeeIcon sx={{width:"15px",height:"20px",paddingTop:"0px"}}/>
                      <Box sx={{ color: "#272D4E" }}>
                        {unit?.unit_data?.QuotingPrice}
                      </Box>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        gap: "10px",
                        padding: "10px 15px",
                      }}
                    >
                      <Box sx={{ width: "25%", color: "#84828C" }}>Area (Sq.ft) :</Box>
                      <Box sx={{ color: "#272D4E" }}>
                        {unit?.unit_data?.Area}
                      </Box>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        gap: "10px",
                        padding: "10px 15px",
                      }}
                    >
                      <Box sx={{ width: "25%", color: "#84828C" }}>
                        Address :
                      </Box>
                      <Box sx={{ color: "#272D4E" }}>
                        {unit?.unit_data?.Address}
                      </Box>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        gap: "10px",
                        padding: "10px 15px",
                      }}
                    >
                      <Box sx={{ width: "25%", color: "#84828C" }}>State :</Box>
                      <Box sx={{ color: "#272D4E" }}>
                        {unit?.unit_data?.State}
                      </Box>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        gap: "10px",
                        padding: "10px 15px",
                      }}
                    >
                      <Box sx={{ width: "25%", color: "#84828C" }}>City :</Box>
                      <Box sx={{ color: "#272D4E" }}>
                        {unit?.unit_data?.City}
                      </Box>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        gap: "10px",
                        padding: "10px 15px",
                      }}
                    >
                      <Box sx={{ width: "25%", color: "#84828C" }}>
                        Pincode :
                      </Box>
                      <Box sx={{ color: "#272D4E" }}>
                        {unit?.unit_data?.Pincode}
                      </Box>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        gap: "10px",
                        padding: "10px 15px",
                      }}
                    >
                      <Box sx={{ width: "25%", color: "#84828C" }}>
                        Configuration :
                      </Box>
                      <Box sx={{ color: "#272D4E" }}>
                        {unit?.unit_data?.Configuration}
                      </Box>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        gap: "10px",
                        padding: "10px 15px",
                      }}
                    >
                      <Box sx={{ width: "25%", color: "#84828C" }}>For :</Box>
                      <Box sx={{ color: "#272D4E" }}>
                        {unit?.unit_data?.For}
                      </Box>
                    </Box>

                    <Box
                      sx={{
                        display: "flex",
                        gap: "10px",
                        padding: "10px 15px",
                      }}
                    >
                      <Box sx={{ width: "25%", color: "#84828C" }}>
                        Property Age(In Years) :
                      </Box>
                      <Box sx={{ color: "#272D4E" }}>
                        {unit?.unit_data?.PropertyAge}
                      </Box>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        gap: "10px",
                        padding: "10px 15px",
                      }}
                    >
                      <Box sx={{ width: "25%", color: "#84828C" }}>
                        Condition :
                      </Box>
                      <Box sx={{ color: "#272D4E" }}>
                        {unit?.unit_data?.Condition}
                      </Box>
                    </Box>
                  </Box>
                </Box>

                <Box
                  sx={{
                    mt: "30px",
                    display: "flex",
                    justifyContent: "space-between",
                    fontFamily: "Product Sans",
                  }}
                >
                  <Box
                    sx={{
                      width: "100%",
                      backgroundColor: "#FFF",
                      borderRadius: "10px",
                      padding: "10px 15px",
                      boxShadow: "-6px 18px 15px rgba(0, 0, 0, 0.1)",
                    }}
                  >
                    <Stack
                      direction="row"
                      justifyContent="space-between"
                      alignItems="center"
                      sx={{}}
                    >
                      <Box
                        sx={{
                          padding: "0px 20px",
                          width: "50%",
                          mt: "20px",
                          fontSize: "24px",
                          fontWeight: 700,
                          fontFamily: "Circular Std",
                        }}
                      >
                        Owner Details
                      </Box>
                      <Box
                        sx={{
                          padding: "0px 20px",
                          width: "50%",
                          mt: "20px",
                          fontSize: "24px",
                          fontWeight: 700,
                          fontFamily: "Circular Std",
                        }}
                      >
                        Rating Details
                      </Box>
                    </Stack>

                    <Stack
                      direction="row"
                      alignItems="center"
                      justifyContent="space-between"
                      sx={{}}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          gridTemplateColumns: "1fr 1fr 1fr",
                          rowGap: "0px",
                          padding: "10px 20px",
                          width: "50%",
                        }}
                      >
                        <Box sx={{ marginRight: "20px", color: "#84828C" }}>
                          Owner Name :
                        </Box>
                        <Box sx={{ color: "#272D4E" }}>
                          {unit?.unit_data?.OwnerName}
                        </Box>
                        {/* <Box>{unit?.unit_data?.OwnerContactPersonNumber}</Box> */}
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          gridTemplateColumns: "1fr 1fr 1fr",
                          rowGap: "0px",
                          padding: "10px 20px",
                          width: "50%",
                        }}
                      >
                        <Box sx={{ marginRight: "20px", color: "#84828C" }}>
                          Added By :
                        </Box>
                        {/* <Box>{unit?.unit_data?.QuotingPrice}</Box>  */}
                        <Box sx={{ color: "#272D4E" }}>
                          {unit?.unit_data?.AddedBy}
                        </Box>
                      </Box>
                    </Stack>

                    <Stack
                      direction="row"
                      alignItems="center"
                      justifyContent="space-between"
                      sx={{}}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          gridTemplateColumns: "1fr 1fr 1fr",
                          rowGap: "0px",
                          padding: "10px 20px",
                          width: "50%",
                        }}
                      >
                        <Box sx={{ marginRight: "20px", color: "#84828C" }}>
                          Owner Email :
                        </Box>
                        {/* <Box>{unit?.unit_data?.OwnerID}</Box> */}
                        <Box sx={{ color: "#272D4E" }}>
                          {unit?.unit_data?.OwnerEmail}
                        </Box>
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          gridTemplateColumns: "1fr 1fr 1fr",
                          rowGap: "0px",
                          padding: "10px 20px",
                          width: "50%",
                        }}
                      >
                        <Box sx={{ marginRight: "20px", color: "#84828C" }}>
                          Rating Property :
                        </Box>
                        <Box sx={{ color: "#272D4E" }}>
                          {unit?.unit_data?.RatingProperty}
                        </Box>
                      </Box>
                    </Stack>

                    <Stack
                      direction="row"
                      alignItems="center"
                      justifyContent="space-between"
                      sx={{}}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          gridTemplateColumns: "1fr 1fr 1fr",
                          rowGap: "0px",
                          padding: "10px 20px",
                          width: "50%",
                        }}
                      >
                        <Box sx={{ marginRight: "20px", color: "#84828C" }}>
                          Owner Phone :
                        </Box>
                        <Box sx={{ color: "#272D4E" }}>
                          {unit?.unit_data?.OwnerPhone}
                        </Box>
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          gridTemplateColumns: "1fr 1fr 1fr",
                          rowGap: "0px",
                          padding: "10px 20px",
                          width: "50%",
                        }}
                      >
                        <Box sx={{ marginRight: "20px", color: "#84828C" }}>
                          Rating Cost :
                        </Box>
                        <Box sx={{ color: "#272D4E" }}>
                          {unit?.unit_data?.RatingCost}
                        </Box>
                      </Box>
                    </Stack>

                    <Stack
                      direction="row"
                      alignItems="center"
                      justifyContent="space-between"
                      sx={{}}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          gridTemplateColumns: "1fr 1fr 1fr",
                          rowGap: "0px",
                          padding: "10px 20px",
                          width: "50%",
                        }}
                      >
                        <Box sx={{ marginRight: "20px", color: "#84828C" }}>
                          Owner Contact Person Number :
                        </Box>
                        {/* <Box>{unit?.unit_data?.QuotingPrice}</Box> */}
                        {/* <Box>{unit?.unit_data?.OwnerEmail}</Box> */}
                        <Box sx={{ color: "#272D4E" }}>
                          {unit?.unit_data?.OwnerContactPersonNumber}
                        </Box>
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          gridTemplateColumns: "1fr 1fr 1fr",
                          rowGap: "0px",
                          padding: "10px 20px",
                          width: "50%",
                        }}
                      >
                        <Box sx={{ marginRight: "20px", color: "#84828C" }}>
                          Rating Urgency :
                        </Box>
                        <Box sx={{ color: "#272D4E" }}>
                          {unit?.unit_data?.RatingUrgency}
                        </Box>
                      </Box>
                    </Stack>

                    <Stack
                      direction="row"
                      alignItems="center"
                      justifyContent="space-between"
                      sx={{}}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          gridTemplateColumns: "1fr 1fr 1fr",
                          rowGap: "0px",
                          padding: "10px 20px",
                          width: "50%",
                        }}
                      >
                        <Box sx={{ marginRight: "20px", color: "#84828C" }}>
                          Owner Contact Person Name :
                        </Box>
                        <Box sx={{ color: "#272D4E" }}>
                          {unit?.unit_data?.OwnerContactPersonName}
                        </Box>
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          gridTemplateColumns: "1fr 1fr 1fr",
                          rowGap: "0px",
                          padding: "10px 20px",
                          width: "50%",
                        }}
                      >
                        <Box sx={{ marginRight: "20px", color: "#84828C" }}>
                          Remark :
                        </Box>
                        <Box sx={{ color: "#272D4E" }}>
                          {unit?.unit_data?.Remark}
                        </Box>
                      </Box>
                    </Stack>

                    <Box
                      sx={{
                        display: "flex",
                        gridTemplateColumns: "1fr 1fr 1fr",
                        rowGap: "0px",
                        padding: "20px",
                        width: "50%",
                      }}
                    >
                      <Box sx={{ marginRight: "20px", color: "#84828C" }}>Company Name :</Box>
                      <Box>{unit?.unit_data?.OwnerCompany}</Box>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Stack>
          </Stack>
        </SideBar>
      </Stack>
    </>
  );
}

export default StandAloneUnitOverview;
