import { Typography } from "@mui/material"
import { Stack } from "@mui/system"
import { SuperAdminNavbar } from "../SidebarNavbar/SuperAdminNavbar"
import { SuperAdminSidebar } from "../SidebarNavbar/SuperAdminSidebar"





export const SuperAdminDashboard = ()=>{
    return(
        <>
              
<Stack direction="row" sx={{width:"100%",height:"auto" }}>
      {/* 1st stack */}
      <Stack sx={{minHeight:"100vh",width:"20%",boxSizing:"border-box",}}> {/*for project directory 20% and for sales dashboard 225*/}
        <SuperAdminSidebar active={"Dashboard"}/>
      </Stack>

     {/* 2nd stack */}
     <Stack direction="column" sx={{ backgroundColor:"#f9f9f9",width:"80%", padding:"25px",boxSizing:"border-box",}}  >  {/*  width for main sidebar 78px and for 80px*/}
      <SuperAdminNavbar dashboard={true} />  {/*send props for get popup to verify otp from builder */}
      

      {/* <Typography sx={{marginTop:"20px", fontSize:"28px", fontWeight:"600", marginLeft:"10px", marginBottom:"50px"}}>
           Content Title 
            </Typography> */}
     {/* actual content  */}
    <Stack sx={{backgroundColor:"#f9f9f9"}}>  {/* mt:"28px", */}

    <Stack  direction="column"  alignItems="center" justifyContent="center" sx={{}}>
                   <h1>TBD</h1>
       </Stack>

    </Stack>
      </Stack>
</Stack>
        </>
    )
}