


import { useContext } from "react";
import { Navigate, Outlet, Route, useNavigate } from "react-router-dom";


export const BuyerRoutes = ({children, ...rest})=>{
    //get data from local storage and check role type and set routs

    const user = JSON.parse(localStorage.getItem("crm_user"))

    if (!user){
        console.error("Permission Denied");
    }
    // TODO uncomment after api completes
    return (
        <>
        {user && user.role_type=="BUYER"? <Outlet/> :<Navigate to="/"/>}
        </>
    )
}