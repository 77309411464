import { Box, List, ListItem, Typography } from "@mui/material";
import "./styles/PageSix.css";
import React, {useState } from 'react';
import { Stack } from "@mui/system";
import classes from "./styles/PageOne.module.css";

const PdfOnePageFourteen = () => {

   const [pageData14,setPageData14]=useState({
    sign_first:"",
    sign_second:"",
    sign_third:"",
  })

  let name,value;
  const handleChange=(e)=>{
    name=e.target.name;
    value=e.target.value;
    console.log(name," ",value);
    setPageData14({...pageData14,[name]:value});
  }

  return (
    <Box>
      <Box sx={{ mb: "40px" }}>
        <Stack spacing={0.5} sx={{ ml: "20px", mt: "5px" }}>
          <Box sx={{ display: "flex", gap: "10px" }}>
            <Typography sx={{ fontSize: "10px" }}>9.5</Typography>
            <Typography sx={{ fontSize: "10px" }}>
              The Developer shall not be liable to pay the Applicant(s) any
              interest, compensation, damages, costs or any other expense. The
              Refund amount shall be accepted by the Applicant(s) in full
              satisfaction of all his/her/its/their claims under this Agreement
              and/or in respect of the said Premises and or against the
              Developer. Within a period of 30 (thirty) days from the execution
              and registration of the Deed of Cancellation/ cancellation
              documents (as the case may be) or on resale of the Premises to the
              third party whichever is later, the Developer shall refund balance
              amount of the Allotment Premium (if any) after deducting the
              amounts (as may be applicable) as stated in clause 9.4 hereof to
              the Applicant(s). It is clarified that upon the termination of
              allotment of Premises/ Agreement for Sale (as the case may be) as
              set out in this Application/Agreement for Sale, the Applicant(s)
              shall have no claim of any nature whatsoever on the Developer
              and/or the said Premises and/or car parking space/s and the
              Developer shall be entitled to deal with and/or dispose off the
              said Premises and/or car parking space/s in the manner it deems
              fit and proper and the only right of the Applicant(s) shall be to
              receive the refund amount.
            </Typography>
          </Box>
          <Box sx={{ display: "flex", gap: "10px" }}>
            <Typography sx={{ fontSize: "10px" }}>9.6</Typography>
            <Typography sx={{ fontSize: "10px" }}>
              It is clarified that the aforesaid consequences of termination and
              refund of Refund Amount by the Developer to the Applicant(s) shall
              also be applicable in the event the Applicant(s) voluntarily
              desires to terminate the Agreement for Sale at will for no default
              of the developer.
            </Typography>
          </Box>
        </Stack>
        <Stack spacing={0.5} sx={{ ml: "10px", mt: "10px" }}>
          <Box sx={{ display: "flex", gap: "10px" }}>
            <Box>
              <Typography sx={{ fontSize: "10px" }}>10.</Typography>
            </Box>
            <Stack spacing={0.5}>
              <Typography sx={{ fontSize: "10px" }}>
                HANDING OVER POSSESSION
              </Typography>
              <Box>
                <Box sx={{ display: "flex", gap: "10px" }}>
                  <Typography sx={{ fontSize: "10px" }}>10.1</Typography>
                  <Box>
                    <Typography sx={{ fontSize: "10px" }}>
                      The Developer shall handover possession of the Premises to
                      the Applicant(s) on or before 31/03/2024 (“Possession
                      Date”) with Occupation Certificate exclusive of grace
                      period of six months, provided however, that the Developer
                      shall be entitled to extension of time for giving
                      possession of the Premises, if the completion of the
                      Project is delayed on account of any or all of the
                      following factors:
                    </Typography>
                    <ol type="a" style={{ fontSize: "10px" }}>
                      <li>
                        Riots, flood, earthquake, storm, terrorist activities,
                        war, invasion, act of foreign enemies, civil strife,
                        hostilities, civil war, civil commotion;
                      </li>
                      <li>
                        Act/s of God affecting the development of the said
                        Project Property;
                      </li>
                      <li>
                        Any approval/permissions, notice, order, rule,
                        regulation, notification or directive of the Government
                        and/ or any local or public or private body or statutory
                        authority and/or any other Competent Authority or any
                        Court, or Tribunal or any quasi-judicial body or
                        authority, or any legal impediment or construction
                        impediment;
                      </li>
                      <li>
                        Any changes in law, notifications and/or regulations
                        levying any onerous conditions on the deveelpment of the
                        said Project Land and/or the owner and/or the Developer
                        or which may require change in plans;
                      </li>
                      <li>
                        A force majeure/vis majeure circumstances or conditions
                        or other causes beyond the control of or unforseen by
                        the Owner Developer or its agents including but not
                        limited to strikes or agitation by the workers or
                        labourers of the Developer or the contractor or
                        suppliers, delay in grant of any permissions/approvals
                        by any of the authorities, any orders passed by courts
                        affecting the development of Project.
                      </li>
                      <li>
                        In case the Applicant(s) has availed any loans
                        (including subvention scheme), then the amounts
                        disbursed by the lending bank/financial instituion to
                        the Developer, which amounts shall be refunded by the
                        Developer to such lending Bank/financial institution
                        directly and the Applicant(s) authorizes the Developer
                        to collect the original Agreement for Sale from such
                        bank/financial institution shall not be required to take
                        any consent/confirmation from the Applicant(s) at
                        anytime.{" "}
                      </li>
                      <li>
                        The amounts to be refunded to the Applicant(s) after
                        deducting the amounts as set out in (a) to (f) above is
                        hereinafter referred to as “Refund Amount”){" "}
                      </li>
                      <li>
                        Lockdown caused on account of Covid-19 or otherwise or
                        imposed by any Competent Authority/Enforcement
                        Agencies/Judicial or Quasi Judicial Body, Government,
                        Semi-Government or Local Authority, directly or
                        indirectly impacting the construction work of the
                        project. and in any of the aforesaid events the
                        Applicant(s) hereby irrevocably agrees to automatic
                        extension of date of completion of the construction of
                        the said Project and in such event the Applicant(s)
                        shall not be entitled to claim any compensation/
                        interest/damages, etc. whatsoever.
                      </li>
                    </ol>
                  </Box>
                </Box>
                <Box sx={{ display: "flex", gap: "10px" }}>
                  <Typography sx={{ fontSize: "10px" }}>10.2</Typography>
                  <Box>
                    <Typography sx={{ fontSize: "10px" }}>
                      In the event the Developer fails to abide by the schedule
                      for completing the said Project and for handing over the
                      said Premises to the Applicant(s) on the Possession Date
                      (save and except for the reasons as stated in clause 10.1
                      hereinabove), then in such event on expiry of the
                      Possession Date, the Applicant(s) shall be entitled to
                      either of the following:
                    </Typography>
                    <ol type="a" style={{ fontSize: "10px" }}>
                      <li>
                        Call upon the Developer by giving a written notice by
                        courier/ Email/ registered post A.D. at the address
                        provided by the Developers (“Interest Notice”), to pay
                        interest at the interest rate, on instalments of the
                        Allotment Premium paid by the Applicant(s). The interest
                        shall be paid on the amounts paid by the Applicant(s) as
                        on the date of the interest notice, at the interest rate
                        from the Possession Date till the date of offering to
                        hand over of the possession of the said premises by the
                        developer to the Applicant(s), it is clarified and
                        agreed by the Applicant(s) that the payment of interest
                        by the Developer to the Applicant(s) shall be adjusted
                        from the payment of next instalment that would be
                        payable by the Applicant(s) to the Developer;
                      </li>
                      <li>
                        The Applicant(s) shall exercise its right to terminate
                        the Application/Agreement for Sale by giving written
                        notice of 60 days to the Developer, by
                        courier/Email/registered post A.D. at the address
                        provided by the Developer calling upon the Developer to
                        deliver possession of the Premises to the Applicant(s)
                        (“Applicant’s termination Notice”). If the Developer
                        fails to deliver possession of the Premises to the
                        Applicants within the aforesaid period of 60 days from
                        the date of receipt of the Applicant’s Termination
                        Notice by the Developer, then the Application/Agreement
                        for Sale shall stand terminated and cancelled on the
                        expiry of aforesaid period of 60 days. Within a period
                        of 30 days from the execution and registration of the
                        Deed of Cancellation/ Letter of Cancellation (as the
                        case may be) or on resale of the Premises to a thirtd
                        party, whichever is later, the Developer, shall refund
                        to the Applicant(s) the amounts already received by
                        Developer with interest thereon at the interest rate to
                        be computed from the Possession date till the date such
                        amounts with interest are duly repaid. On such repayment
                        of the amounts by the developer (as stated in this
                        clause), the Applicant(s) shall have no claim of any
                        nature whatsoever on the Developers and/or the said
                        Premises and/or car park and the Developers shall be
                        entitled to deal with and/or dispose off the said
                        Premises and/or the car park in the manner they deems
                        fir and proper.
                      </li>
                    </ol>
                  </Box>
                </Box>
              </Box>
            </Stack>
          </Box>
        </Stack>
      </Box>

      <Box
        sx={{
          display: "flex",
          gap: "40px",
          justifyContent: "center",
          position: "absolute",
          bottom: "30px",
        }}
      >
        <Box className={classes.input}>
          <Box>
            <input
              style={{ textAlign: "center" }}
              value={pageData14.sign_first}
              type="text"
              name="sign_first"
              onChange={handleChange}
            />
            <Typography sx={{ textAlign: "center", mt: "10px" }}>
              Signature of First Applicant
            </Typography>
          </Box>
        </Box>
        <Box className={classes.input}>
          <Box sx={{ display: "inline-block" }}>
            <input
              style={{ margin: "0px", textAlign: "center" }}
              value={pageData14.sign_second}
              type="text"
              name="sign_second"
              onChange={handleChange}
            />
            <Typography sx={{ textAlign: "center", mt: "10px" }}>
              Signature of Second Applicant
            </Typography>
          </Box>
        </Box>
        <Box className={classes.input}>
          <Box sx={{ display: "inline-block" }}>
            <input
              style={{ margin: "0px", textAlign: "center" }}
              value={pageData14.sign_third}
              type="text"
              name="sign_third"
              onChange={handleChange}
            />
            <Typography sx={{ textAlign: "center", mt: "10px" }}>
              Signature of Third Applicant
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default PdfOnePageFourteen;
