import { NavbarForDirectory } from '../BuilderComponents/ProjectDashboard/DashboardComponents/NavbarForDirectory'
import { ProjectDashboardSidebar } from '../BuilderComponents/ProjectDashboard/DashboardComponents/ProjectDashboardSidebar'

import { ImageGrid } from '../AllDirectories/UnitDirectory/courousal/imageGrid'
import builderDashboard from "../../context/builder_dashboard/context";
import TuneOutlinedIcon from "@mui/icons-material/TuneOutlined";
import { useContext, useEffect, useState } from "react";
import { Apiaddress } from "../../utils/consts";
import FloorUnits from './FloorUnits'
 
import { Box } from '@mui/system'
import React from 'react'
import axios from "axios"
import { GridExpandMoreIcon } from '@mui/x-data-grid';
import {
    AccordionDetails,
    Accordion, AccordionSummary, Stack,
    MenuItem,
    Button,
    FormControl,
    InputLabel,
    Select,
    TextField,
    Typography,
  } from "@mui/material";
import { UserRole } from '../../UserRoles/UserRoles';

const AllFloorPlans = () => {
     ////get user roles from local storage and assign 
     let roles = JSON.parse(localStorage.getItem("user_roles"))
  
     let floor = roles["floor"];
     let unit = roles["unit"];

    const [state, setState] = React.useState(false);
    const toggleDrawer = (open) => (event) => {
        if (
            event.type === "keydown" &&
            (event.key === "Tab" || event.key === "Shift")
        ) {
            return;
        }

        setState(open);
    };

    let builderSelectedData = JSON.parse(localStorage.getItem("BuilderSelected"));
    let id = builderSelectedData.selectedProjectID;
 //fitering data starts:====
 const [filterselect, setfilterselect] = useState();
 const [filtervalue, setfiltervalue] = useState();

 const handleChange = (event) => {
   setfilterselect(event.target.value);
   setfiltervalue("");
 };

 console.log(filterselect);
 console.log(filtervalue);

  const fetchfilter = async () => {
    try {
       const res = await axios.get(
         Apiaddress + `/api/projects/getunitsusingprojectid/?project_id=${id}&filterkey=${filterselect}&filtervalue=${filtervalue}`
       );

     console.log(res.data.unit);
     setUnitList(res.data.unit);
   } catch (err) {
     console.log(err);
   }
 };

    const OrganizationsContext = useContext(builderDashboard)
    const [unitList, setUnitList] = useState([]);
    const [Index, setIndex] = useState()

    const [loading, setLoading] = useState(false);

    useEffect(() => {

        if (id!== undefined) {

            let url = Apiaddress + "/api/projects/getunitsusingprojectid/?project_id=" + id
            setLoading(true);
            axios.get(url).then((res) => {
                setLoading(false)
                if (res.data['unit'] !== "Unit not found") {
                    if (res?.data?.unit[0]) {
                        setUnitList(res.data.unit)
                        console.log(res.data.unit)
                        setIndex(0)
                    }
                } else {
                    setUnitList([])
                }

            }).catch((err) => {
                setLoading(false)
                console.log(err)
            })
        }

    }, [OrganizationsContext])

    return (
        <Box>
            <Stack direction="row" sx={{ width: "100%", height: "auto"}}>
                {/* 1st stack */}
                <Stack sx={{ minHeight: "100vh", width: "22%", boxSizing: "border-box", }}>
                    <ProjectDashboardSidebar active={"All Floor Plans"} />
                </Stack>

                {/* 2nd stack */}
                <Stack direction="column" sx={{ backgroundColor: "#f9f9f9", width: "78%", padding: "25px", boxSizing: "border-box", }}  >
                    {/* <Navbar/> */}
                    <NavbarForDirectory
                        showAddProjectbtn={false}
                        showRightSideBar={false}
                        showRightSideBarProjAdd={false}
                        RightBarNameProAdd={"Add Project"}
                        toggleDrawer={toggleDrawer}
                        showRightSideBarleft={false}
                        RightBarNameleft={"Send invite"}
                        // leftRightTitle1={"Unit Dashboard"}
                        leftRightTitle2={"All Floor Plans"}
                        leftTitle={"Project Dashboard"}
                        // showLeftTitle={false}
                        righshow={true}
                    />
                    
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            sx={{ height: "68px", marginBottom: "20px" }}
          >
            <Stack direction="column" alignItems="center">
              <Typography
                sx={{
                  fontSize: "22px",
                  fontWeight: "600",
                  // marginLeft: "-45px",
                  color: "black",
                }}
              >
                All Floor Plans
              </Typography>
              <Typography
                sx={{
                  color: "#B9B7D0",
                  fontSize: "12px",
                  fontWeight: "400",
                  paddingLeft: "20px",
                }}
              >
                Here is All Floor Plans 
              </Typography>
            </Stack>
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Stack
                direction="row"
                justifyContent="space-around"
                alignItems="center"
                sx={{
                  width: "203px",
                  height: "68px",
                  borderRadius: "12px",
                  color: "#3E4954",
                  backgroundColor: "#f4f5f9",
                }}
              >

              <Accordion sx={{ width: "250px", boxShadow: "none" }}>
              <AccordionSummary
                expandIcon={<GridExpandMoreIcon/>}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography sx={{ display: "flex", placeItems: "center" }}>
                  <TuneOutlinedIcon sx={{ color: "#2F4CDD" }} />
                  <Typography sx={{ marginLeft: "25px" }}>
                    Fillters
                  </Typography>
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Box sx={{textAlign:"center"}}>
                  <FormControl sx={{ width: "200px" }}>
                    <InputLabel id="demo-simple-select-label">
                      Select
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={filterselect}
                      label="Select"
                      onChange={handleChange}
                    >
                      <MenuItem value={"name"}>Name</MenuItem>
                      <MenuItem value={"is_booked"}>Booked</MenuItem>
                      <MenuItem value={"is_paid"}>Paid</MenuItem>
                      <MenuItem value={"is_occupy"}>Occupied</MenuItem>
                      {/* <MenuItem value={"Name"}>Name</MenuItem> */}
                    </Select>
                  </FormControl>
                  {
                    filterselect=="name"?
                    <TextField
                    sx={{ marginTop: "20px" }}
                    placeholder="Enter the value" 
                    value={filtervalue} onChange={(e)=>{setfiltervalue(e.target.value)}}
                  />:
                    <FormControl sx={{ width: "200px", marginTop: "20px"}}>
                    <InputLabel id="demo-simple-select-label">
                      Choose
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={filtervalue}
                      label="Select"
                      onChange={(e)=>{setfiltervalue(e.target.value)}}
                    >
                      <MenuItem value={'1'}>True</MenuItem>
                      <MenuItem value={'0'}>False</MenuItem>
                    </Select>
                  </FormControl>

                  }
                  <Button 
                    onClick={fetchfilter}
                    variant="contained"
                    color="primary" sx={{ height:"40px", background: "linear-gradient(90deg, #0F5DAE 0%, #009DCF 100.33%)",boxShadow: "0px 13.7981px 22.5788px rgba(50, 174, 137, 0.25)", textTransform: "none", borderRadius: "4px", width:"100px", fontSize:"15px",margin:"20px 0px 10px 0px"}}>
                     Submit
                  </Button>

                </Box>
              </AccordionDetails>
            </Accordion>

              </Stack>
            </Stack>
          </Stack>
                   {UserRole(unit, "view_unit") && 
                    <Box 
                    sx={{ 
                        display:"flex",  
                        flexDirection:"row"
                    }}>

                        <Box
                            sx={{ width: "100%"}}>
                            <FloorUnits loading={loading} unitList={unitList} setIndex={setIndex} />
                        </Box>
                       {unitList[Index]?.layout_data?.floorplan && unitList[Index]?.layout_data?.floorplan?.length != 0 ?
                        <Box sx={{display:"flex",alignItems:"center",paddingTop:"50px"}}>
                          <ImageGrid image={unitList[Index]?.layout_data?.floorplan?.map((element)=>{
                            return element.image ;   
                          })}/>
                        </Box>:
                        <Box sx={{border:"1.5px solid lightgray",display:"flex",alignItems:"center",height:"75%",width:"47%",justifyContent:"center",flexDirection:"column",marginTop:"120px"}}>
                           <Typography sx={{fontSize:"18px",fontFamily:"Product Sans",color:"#343434",fontWeight:"400"}}>No FloorPlan Images Found</Typography>  
                           <Typography sx={{fontSize:"18px",fontFamily:"Product Sans",color:"#343434",fontWeight:"400"}}>Please Select Another Unit</Typography> 
                        </Box>}
                    </Box>
                   }
                </Stack>
            </Stack>

        </Box>
    )
}

export default AllFloorPlans