import { Box } from "@mui/system";
import React, { useContext, useEffect, useState } from "react";
import profileContext from "../../../../context/profile/context";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import SearchRoundedIcon from "@mui/icons-material/SearchRounded";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  InputLabel,
  ListItemText,
  MenuItem,
  Modal,
  OutlinedInput,
  Radio,
  RadioGroup,
  Select,
  Stack,
  TextField,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";

import AddIcon from "@mui/icons-material/Add";
import SearchIcon from "@mui/icons-material/Search";
import axios from "axios";
import { api, Apiaddress, net } from "../../../../utils/consts";
import { AuthService } from "../../../../context/buyer/services/auth";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function RightSidebar({
  setTabledata,
  curselectedRows,
  orgID,
  toggleDrawer,
  updateData,
  setTrigger,
  trigger,
  makePriority,
  makeProject,
  setMakePriority,
  setMakeProject,
  TemplateId,
  TemplateName,
  setState,
}) {
  const [names, setNames] = useState([
    { name: "Aditya Birla Appartments", id: 2 },
    { name: "Tata Farms", id: 3 },
  ]);
  const [expanded, setExpanded] = useState(
    // curselectedRows ? "panel2" : "panel1"
    makePriority ? "panel2" : makeProject ? "panel3" : "panel1"
  );
  const [disabelAccrodian, setdisabelAccrodian] = useState(
    curselectedRows ? "true" : "false"
  );
  const [selectValue, setSelectValue] = useState("High");
  const [setProjects, setsetProjects] = React.useState([]);

  const [priority, setPriority] = useState(null);
  const handleChangeSelect = (event) => {
    setPriority(event.target.value);
  };

  const [choosenproject, setchoosenproject] = useState("");
  const handleChangeSelectpro = (event) => {
    setchoosenproject(event.target.value);
  };

  const [projectsSelected, setprojectsSelected] = useState(0);
  const [projectsSelectedID, setprojectsSelectedID] = useState(0);

  const addProjectID = (para) => {
    setprojectsSelectedID(para);
  };

  const cpDetails = JSON.parse(localStorage.getItem("cpDetails"));
  // console.log(cpDetails);
  let cpOrg = cpDetails?.channelPartnerOrg;

  const addProjects = (e) => {
    setprojectsSelected(e.target.value);
  };
  const [selectArr, setselectArr] = useState([
    {
      value: "high",
      label: "high",
    },
    {
      value: "low",
      label: "low",
    },
  ]);

  const createOpt = () => {};

  const handleChange = (i) => {
    setExpanded(i);
  };

  const [colors, setColors] = useState([
    { name: "Ajay", clr: "green" },
    { name: " Tarun", clr: " yellow" },
    { name: "  Ranbir", clr: "red" },
  ]);
  const [leadname, setLeadName] = useState("");
  const [leadclr, setLeadclr] = useState("");

  const addClr = () => {
    const obj = { name: leadname, clr: leadclr };
    setColors([...colors, obj]);
    handleClose();
  };

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [name, setName] = useState(
    curselectedRows && curselectedRows.Name ? curselectedRows.Name : ""
  );
  const [phone, setPhne] = useState("");
  const [Email, setEmail] = useState("");
  const [address, setAddresss] = useState("");

  const user = useContext(profileContext);
  const [userID, setUserID] = useState(null);

  useEffect(() => {
    // console.log(user);
    // // console.log(user.UserState);
    if (user)
      if (user.UserState)
        if (user.UserState.user_obj)
          if (user.UserState.user_obj.id) setUserID(user.UserState.user_obj.id);
  }, [user]);
  // // console.log(userID);
  useEffect(() => {
    if (updateData) {
      setName(updateData.Name);
      setEmail(updateData.Email);
      setPhne(updateData.Phone);
      setPriority(updateData.priority);
      setAddresss(updateData.Address);
    }
  }, [updateData]);

  const clearItems = () => {
    setName(null);
    setEmail(null);
    setPhne(null);
    setPriority(null);
    setAddresss(null);
  };
  const [slectedCPid, setselectedCPid] = useState(null);

  const [data, setData] = useState(null);
  const [token, setToken] = useState(null);

  const getProjects = async () => {
    try {
      const headers = {
        Authorization: `JWT ${token}`,
      };
      const res = await axios.get(
        Apiaddress + "/api/projects/getprojectsusingtoken/",
        { headers }
      );
      // // console.log(res.data.projects);
      if (res.data) if (res.data.projects) setData(res.data.projects);
    } catch (err) {
      // console.log(err);
    }
  };
  useEffect(() => {
    if (token) getProjects();
  }, [token]);

  useEffect(() => {
    setToken(localStorage.getItem("crm_token"));
  }, []);
  const [newRowData, setNewRowData] = useState([]);
  useEffect(() => {
    if (data) {
      // // console.log(data);
    }
  }, [data]);

  const [selectedCP, setselectedCP] = useState("");

  const [builderId, setbuilderId] = useState(null);

  useEffect(() => {
    if (user)
      if (user.UserState)
        if (user.UserState.user_obj)
          if (user.UserState.user_obj.orgList)
            if (user.UserState.user_obj.orgList[0]) {
            }
  }, [user]);

  const [cpdata, setcpdata] = useState(null);
  const [clicked, setClicked] = useState(false);
  useEffect(() => {
    if (user) {
      if (user.UserState) {
        if (user.UserState.user_obj) {
          setbuilderId(user.UserState.user_obj.id);
        }
      }
    }
  }, [user, trigger]);

  const getCP = async () => {
    try {
      const res = await axios.get(
        Apiaddress + `/api/role/getcpusingbuilderid/?builder_id=${builderId}`
      );
      if (res.data.channel_partner !== "channel_partner not found") {
        setcpdata(res.data.channel_partner);
      } else {
        setcpdata([]);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const [datafromapi, setdatafromapi] = useState([]);

  useEffect(() => {
    if (cpdata) {
      let arr = [];
      for (let i = 0; i < cpdata.length; i++) {
        const obj = {
          id: i + 1,
          CPid: cpdata[i]?.id,
          RERARegNo: cpdata[i]?.rera_no,
          Type: cpdata[i]?.profile.role_type,
          Name: cpdata[i]?.profile.user_details.first_name,
          Email: cpdata[i]?.profile.user_details.email,
          Phone: cpdata[i]?.profile.phone,
        };
        arr.push(obj);
      }
      setdatafromapi(arr);
    }
  }, [cpdata]);

  useEffect(() => {
    if (builderId) {
      getCP();
    }
  }, [builderId, trigger]);

  const [allpriority, setallpriority] = useState(null);

  const getpriority = async () => {
    try {
      const res = await axios.get(Apiaddress + "/api/leads/leadprority/");
      setallpriority(res.data.leadprority);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getpriority();
  }, []);

  const [SearchBy, setSearchBy] = useState("Email");

  const [Query, setQuery] = useState("");
  const [LastResut, setLastResut] = useState([]);

  const [Selected, setSelected] = useState();
  const [selectedName, setSelectedName] = useState("");
  const [selectedEmail, setSelectedEmail] = useState("");
  const [selectedPhone, setSelectedPhone] = useState("");
  // console.log(Selected);
  console.log(selectedName);

  //Deepak's Work
  const [input, setinput] = useState();
  const [selectedValue, setSelectedValue] = useState("a");
  const [error, seterror] = useState(false);

  const handleChangeRadio = (event) => {
    setSelectedValue(event.target.value);
    setinput("");
    seterror(false);
  };

  const controlProps = (item) => ({
    checked: selectedValue === item,
    onChange: handleChangeRadio,
    value: item,
    name: "color-radio-button-demo",
    inputProps: { "aria-label": item },
  });

  //searching lead of project
  const searchLead = async () => {
    const Auth = new AuthService();
    try {
      let value = input.toLowerCase();
      let url1 = "";
      if (cpOrg) {
        url1 = `/api/leads/channelpartnerSelfleads/?org_id=${cpOrg}&filterkey=${selectedValue}&filtervalue=${value}`;
      } else {
        url1 = `/api/leads/channelpartnerSelfleads/?channel_partner_id=${Auth.getCPID()}&filterkey=${selectedValue}&filtervalue=${value}`;
      }
      const res = await axios.get(Apiaddress + url1);
      if (res.data.leads.length == 0) {
        setSelected({});
        setSelectedName("");
        setSelectedEmail("");
        setSelectedPhone("");
        seterror(true);
      } else {
        setSelected(res.data.leads[0]);
        setSelectedName(res?.data?.leads[0]?.name);
        setSelectedEmail(res?.data?.leads[0]?.email);
        setSelectedPhone(res?.data?.leads[0]?.phone);
        seterror(false);
      }
      console.log(res.data.leads[0]);
    } catch (err) {
      console.log(err?.message);
    }
  };

  useEffect(() => {
    setSelectedValue("email");
  }, []);

  const [check, setCheck] = useState([]);

  // console.log(check);
  let temp = [];
  const onCheck = (id) => {
    if (id === "selectAll") {
      temp.push("email");
      temp.push("whatsapp");
      setCheck(temp);
    } else if (id == "removeAll") {
      temp = [];
      setCheck(temp);
    } else if (id == "email") {
      let array = check;
      const index = array.indexOf(id);
      if (index > -1) {
        let temp1 = array.filter((ele) => {
          return ele != check[index];
        });
        temp = temp1;
      } else {
        temp = [...check, id];
      }
      setCheck(temp);
    } else if (id == "whatsapp") {
      let array = check;
      const index = array.indexOf(id);
      if (index > -1) {
        let temp1 = array.filter((ele) => {
          return ele != check[index];
        });
        temp = temp1;
      } else {
        temp = [...check, id];
      }
      setCheck(temp);
      setCheck(temp);
    }
  };
  const userData = JSON.parse(localStorage.getItem("crm_user"));
  let username = userData?.user_details?.first_name;

  return (
    <Box
      sx={{
        width: "420px",
        height: "100vh",
        marginTop: "-25px",
        marginRight: "25px",
        padding: "30px",
      }}
    >
      <Box
        sx={{
          position: "relative",
        }}
      >
        <Box
          sx={{
            position: "absolute",
            top: "0px",
            right: "0px",
            background: "white",
            padding: "10px",
            height: "105vh",
            width: "400px",
            marginTop: "20px",
          }}
        >
          {" "}
          <Box>
            <Box
              onClick={() => !setState()}
              sx={{
                display: "flex",
                alignItems: "center",
                gap: "10px",
                cursor: "pointer",
              }}
            >
              <ArrowBackIcon sx={{ ml: "-5px" }} />
              <Typography
                sx={{
                  color: "#2979F2",
                  fontSize: "22px",
                  fontWeight: 600,
                  fontFamily: "Circular Std",
                }}
              >
                New Requirement Form
              </Typography>
            </Box>
            {/* <Box sx={{ display: "flex", cursor: "pointer" }}
            // onClick={toggleDrawer(false)}
            >
              <Box>
                <ArrowBackIosIcon
                  sx={{
                    fontSize: "small",
                    marginBottom: "-2px",
                  }}
                />
              </Box>
              <Box>New Requirement Form</Box>
            </Box> */}
            <Box
              sx={{
                display: "grid",
                alignItems: "center",
                height: "85vh",
              }}
            >
              <Box>
                {/* <AccordionSummary
                    sx={{
                      background: `${expanded === "panel1" ? "#FFAF2A" : "white"
                        }`,
                      borderRadius: "10px",
                    }}
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography color={"white"}>New Requirement Form</Typography>
                  </AccordionSummary> */}
                <Box>
                  <Box>Search leads Directory</Box>

                  {/* SearchBar */}
                  <Box>
                    <Stack
                      direction="row"
                      justifyContent="start"
                      alignItems="center"
                      sx={{ margin: "20px 0px 20px -10px" }}
                    >
                      <Radio
                        required
                        name="search_type"
                        {...controlProps("email")}
                        sx={{
                          color:
                            "linear-gradient(264.48deg, #79F1A4 -45.37%, #0E5CAD 88.97%)",
                          color: "#0F5DAE",
                        }}
                      />
                      <Typography
                        sx={{
                          fontFamily: "Product Sans",
                          fontStyle: "normal",
                          fontWeight: "500",
                          fontSize: "18px",
                          lineHeight: "21px",
                          color: "#000000",
                          marginRight: "20px",
                        }}
                      >
                        Email
                      </Typography>
                      <Radio
                        required
                        name="search_type"
                        {...controlProps("phone")}
                        sx={{
                          color:
                            "linear-gradient(264.48deg, #79F1A4 -45.37%, #0E5CAD 88.97%)",
                          color: "#0F5DAE",
                        }}
                      />
                      <Typography
                        sx={{
                          fontFamily: "Product Sans",
                          fontStyle: "normal",
                          fontWeight: "500",
                          fontSize: "18px",
                          lineHeight: "21px",
                          color: "#000000",
                        }}
                      >
                        Phone Number
                      </Typography>
                    </Stack>
                  </Box>

                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "start",
                      alignItems: "center",
                      color: "#0065FE",
                      width: "100%",
                    }}
                  >
                    <TextField
                      onChange={(e) => {
                        setinput(e.target.value);
                      }}
                      value={input}
                      placeholder={
                        selectedValue == "email"
                          ? "Search by Email"
                          : "Search by Phone"
                      }
                      style={{ background: "#FFF!important" }}
                      sx={{
                        // border: "1px solid #2979F2",
                        width: "80%",
                      }}
                      InputProps={{
                        startAdornment: (
                          <SearchRoundedIcon
                            sx={{
                              color: "#2979F2",
                              ml: 1,
                            }}
                          />
                        ),
                      }}
                    />
                    <Button
                      // variant="contained"
                      className="univercel_btn_class"
                      sx={{
                        // marginRight:"30px",
                        color: "#FFFFFF",
                        textTransform: "none",
                        fontFamily: "Product Sans",
                        borderRadius: "0px 10px 10px 0px",
                        ml: "-1.5px",
                      }}
                      onClick={searchLead}
                    >
                      <Box sx={{ textTransform: "capitalize", margin: "6px" }}>
                        Search
                      </Box>
                    </Button>
                  </Box>

                  {error && (
                    <Typography
                      sx={{
                        fontFamily: "Poppins",
                        fontStyle: "normal",
                        fontWeight: "500",
                        fontSize: "14px",
                        lineHeight: "21px",
                        color: "#FF6140",
                        margin: "7px 0px 0px 10px",
                      }}
                    >
                      No Lead Found
                    </Typography>
                  )}
                  <Box sx={{ mt: 2 }}>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        width: "400px",
                        alignItems: "center",
                        margin: "24px 0",
                      }}
                    >
                      <Typography
                        sx={{
                          color: "#84828c",
                          fontWeight: 500,
                          fontFamily: "Product Sans",
                        }}
                      >
                        Name
                      </Typography>
                      <Box>
                        <TextField
                          value={selectedName}
                          disabled
                          id="outlined-basic"
                          // onChange={(e) => setName(e.target.value)}
                        />
                      </Box>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        width: "400px",
                        alignItems: "center",
                        margin: "24px 0",
                      }}
                    >
                      <Box
                        sx={{
                          color: "#84828c",
                          fontWeight: 500,
                          fontFamily: "Product Sans",
                        }}
                      >
                        Phone Number
                      </Box>
                      <Box>
                        <TextField
                          value={selectedPhone}
                          // placeholder="+91 9999999999"
                          id="outlined-basic"
                          disabled
                          // onChange={(e) => setPhne(e.target.value)}
                        />
                      </Box>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        width: "400px",
                        alignItems: "center",
                        margin: "24px 0",
                      }}
                    >
                      <Box
                        sx={{
                          color: "#84828c",
                          fontWeight: 500,
                          fontFamily: "Product Sans",
                        }}
                      >
                        Email
                      </Box>
                      <Box>
                        <TextField
                          value={selectedEmail}
                          id="outlined-basic"
                          disabled
                          // onChange={(e) => setEmail(e.target.value)}
                        />
                      </Box>
                    </Box>

                    <Box
                      sx={{
                        border: "1px solid #9299BC",
                        height: "0px",
                        my: 2,
                      }}
                    ></Box>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "start",
                        alignItems: "center",
                        width: "400px",
                        margin: "10px 0",
                      }}
                    >
                      <Box
                        sx={{
                          color: "#84828c",
                          fontWeight: 500,
                          ml: "6px",
                          fontFamily: "Product Sans",
                        }}
                      >
                        Send form through
                      </Box>
                    </Box>
                    <Stack direction="row">
                      <Stack
                        direction="row"
                        justifyContent="left"
                        alignItems="center"
                      >
                        <Checkbox
                          checked={check.length === 2 ? true : false}
                          onClick={() => {
                            if (check.length === 2) {
                              onCheck("removeAll");
                            } else {
                              onCheck("selectAll");
                            }
                          }}
                        ></Checkbox>
                        <Box sx={{ marginRight: "30px" }}>All</Box>
                      </Stack>
                      <Stack
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                      >
                        <Checkbox
                          checked={check.indexOf("email") > -1 ? true : false}
                          onClick={() => {
                            onCheck("email");
                          }}
                        ></Checkbox>
                        <Box sx={{ marginRight: "30px" }}>Email</Box>
                      </Stack>
                      <Stack
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                      >
                        <Checkbox
                          checked={
                            check.indexOf("whatsapp") > -1 ? true : false
                          }
                          onClick={() => {
                            onCheck("whatsapp");
                          }}
                        ></Checkbox>
                        <Box>Whatsapp</Box>
                      </Stack>
                    </Stack>
                  </Box>
                </Box>

                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    flexDirection: "column",
                    alignItems: "center",
                    gap: "10px",
                  }}
                >
                  <Button
                    disabled={clicked}
                    sx={{
                      padding: "10px 60px",
                      color: "#06386B",
                      textTransform: "none",
                      fontSize: "20px",
                      fontFamily: "Product Sans",
                      mt: 3,
                      background:
                        "linear-gradient(97.85deg, #FFAF2A 0.95%, #FFC666 100%)",
                      "&:hover": {
                        boxShadow: "0px 9px 12px rgba(242, 178, 73, 0.59)",
                      },
                    }}
                    onClick={async () => {
                      if (check.length === 0) {
                        return;
                      }
                      const Auth = new AuthService();
                      setClicked(true);
                      //get template
                      const template = (
                        await (
                          await Auth.makeAuthenticatedGetRequest(
                            api(
                              "/api/leads/requirementtemplate/?template_id=" +
                                TemplateId
                            )
                          )
                        ).json()
                      ).template;
                      // create requirement
                      // console.log(template);
                      const bo = await (
                        await Auth.makeUnAuthenticatedPostRequest(
                          api("/api/leads/requirement/"),
                          {
                            JsonBody: {
                              ...template,
                              lead: Selected.id,
                              form_name: TemplateName,
                              cp_org_req: cpOrg ? cpOrg : null,
                            },
                          }
                        )
                      ).json();

                      for (let i = 0; i < check?.length; i++) {
                        if (check[i] === "email") {
                          // send Email
                          const x = await (
                            await Auth.makeAuthenticatedPostRequest(
                              net("leads/sendrequirement/"),
                              {
                                JsonBody: {
                                  email: Selected.id,
                                  cpName: `${username}`,
                                  requirement_id: bo.requirement.id,
                                  form_name: TemplateName,
                                  // mail_body: `Hi we have sent you a requirement form please fill it <a href="https://envisionnext.in/channelpartner/requirements-form/${bo.requirement.id}/fill"> HERE</a>`,
                                },
                              }
                            )
                          ).json();
                        } else if (check[i] === "whatsapp") {
                          // send Message
                          // // console.log({ Selected });
                          const x = await (
                            await Auth.makeUnAuthenticatedPostRequest(
                              net("send-whatsApp"),
                              {
                                JsonBody: {
                                  phoneNumber: Selected.phone,
                                  // "requirement_id":bo.requirement.id,
                                  // name: `${Selected.name}`,
                                  type: "requirement",
                                  cpName: `${username}`,
                                  url: `https://envisionnext.in/channelpartner/requirements-form/${bo.requirement.id}/fill`,
                                  // "url":`Hi we have sent you a requirement form please fill it <a href="https://envisionnext.in/channelpartner/requirements-form/${bo.requirement.id}/fill"> HERE</a>`,
                                },
                              }
                            )
                          ).json();
                        }
                      }
                      setClicked(false);
                      setState(false);
                    }}
                  >
                    {clicked ? "Sending..." : "Send"}
                  </Button>
                  <Box
                    sx={{
                      textAlign: "center",
                      margin: "6px",
                      cursor: "pointer",
                    }}
                    onClick={toggleDrawer(false)}
                  >
                    <Typography
                      sx={{
                        color: "#B0AFAF",
                        fontWeight: 500,
                        fontFamily: "Product Sans",
                      }}
                    >
                      Cancel
                    </Typography>
                  </Box>

                  {/* {curselectedRows && (
                  <Button
                    onClick={saveChanges}
                    className="univercel_btn_class"
                    sx={{
                      width: "200px",
                      color: "#FFFFFF",
                      textTransform: "none",

                      marginBottom: "20px",
                    }}
                  >
                    Update Lead
                  </Button>
                )} */}
                </Box>
              </Box>
            </Box>
            {/* <Box
              sx={{
                placeItems: "center",
                display: "grid",
                marginTop: "-100px",
              }}
            > */}
            {/* <Box>
                {!updateData && (
                  <Button
                    onClick={saveChanges}
                    className="univercel_btn_class"
                    sx={{
                      width: "200px",
                      color: "#FFFFFF",
                      textTransform: "none",

                      marginBottom: "20px",
                    }}
                  >
                    Add Lead
                  </Button>
                )}
                {updateData && (
                  <Button
                    onClick={saveChanges}
                    className="univercel_btn_class"
                    sx={{
                      width: "200px",
                      color: "#FFFFFF",
                      textTransform: "none",

                      marginBottom: "20px",
                    }}
                  >
                    Update Lead
                  </Button>
                )}
              </Box> */}
            {/* </Box> */}
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export default RightSidebar;
