import { AuthService } from "./auth";
import { Server } from "./connections/Server";

class BuyerService{
    
    Auth = new AuthService();
    server:Server;

    constructor(){
    this.server =  new Server({Auth:this.Auth});
    }


    getValuesForProvider(){
        
    }


}

export {BuyerService};