import React, { useContext, useEffect, useRef, useState } from 'react'
import { Box, Button, Drawer, Stack, TextField } from '@mui/material'
import { useLocation, useParams } from 'react-router-dom';
import UploadIcon from '@mui/icons-material/Upload';

import { ImageGrid } from '../../AllDirectories/UnitDirectory/courousal/imageGrid';
import SideBar from '../../ChannelPartner/Components/sideBar/SideBar';
import buyerContext from '../../../context/buyer/context';

const TicketDetailView = ({ inputdisabled }: { inputdisabled?: boolean }) => {
    const p = useParams()
    const ticketId = p.id;
    const [Ticket, setTicket] = useState()
    const [file, setfile] = useState<FileList | null>(null)
    const inputFile = useRef<HTMLInputElement | null>(null)

    const [date, setdate] = useState<string>()
    const [status, setstatus] = useState<string>()
    const [category, setcategory] = useState<string>()
    const [description, setdescription] = useState<string>()
    const [attachments, setattachments] = useState<any[]>()
    const [buyerMessages, setbuyerMessages] = useState<any>()
    const [newmessage, setnewmessage] = useState<string>()
    const bc = useContext(buyerContext)

    useEffect(() => {

        const x = async () => {
            const tickets = await bc.server.tickets.getTicketById(ticketId)
            console.log("le teri ticket", tickets)
            setTicket(tickets)
            setdate(tickets.created_on)
            setstatus(tickets.status)
            setcategory(tickets.category)
            setdescription(tickets.disc)
            const attac = [
                tickets.attachments1,
                tickets.attachments2,
                tickets.attachments3,
                tickets.attachments4,
                tickets.attachments5,
            ]
            let images = []

            for (let i = 0; i < attac.length; i++) {
                const x = attac[i]
                if (x != undefined) {
                    images.push(x)
                }
            }
            console.log("images", images)

            setattachments(images)
            setbuyerMessages(tickets["responses"].reverse())
        }
        x();
    }, [])





    console.log("ticket detais inputdisabled", inputdisabled);

    return (
        <SideBar active={"Tickets"}>
            <div>
                <Box sx={{
                    // minHeight: 500,
                    margin: 5,
                    boxShadow: 10,
                    padding: 2,
                    overflow: 'scroll'
                }}>
                    <Box sx={{
                        color: "#0065FE",
                        fontSize: 24,
                        fontWeight: 700,
                    }}>
                        Tickets
                    </Box>
                    <table  >
                        <tr>
                            <td>Date</td>
                            <td><TextField value={date?.split("T")[0]} disabled={true}  ></TextField></td>
                            <td>Status</td>
                            <td><TextField value={status} onChange={(e) => {
                                setstatus(e.target.value)
                            }} disabled={true}></TextField></td>
                        </tr>
                        <tr>
                            <td>Category</td>
                            <td><TextField value={category} onChange={(e) => {
                                setcategory(e.target.value)
                            }} disabled={inputdisabled}></TextField></td>
                        </tr>
                        <tr>
                            <td>Description</td>
                            <td colSpan={3}><textarea value={description} onChange={(e) => {
                                setdescription(e.target.value)
                            }} disabled={inputdisabled} style={{ width: "100%" }}></textarea></td>
                        </tr>
                        <tr>
                            <td>Attachments</td>
                            <td>
                                <ImageGrid image={attachments} ></ImageGrid>
                            </td>
                            <td>
                                <Button

                                    onClick={() => {
                                        inputFile?.current?.click();

                                    }}
                                    variant="contained"
                                    className="univercel_btn_class"
                                    sx={{ color: "#FFFFFF", padding: "6px 12px 6px 12px", height: "auto", margin: "10px" }}
                                    startIcon={<UploadIcon />}
                                >
                                    Upload
                                    <input type='file' multiple onInput={(e) => {
                                        // @ts-ignore
                                        setfile(e.currentTarget.files)
                                        console.log("Files", e.currentTarget.files);

                                    }} id='file' ref={inputFile} style={{ display: 'none' }} />
                                </Button>
                            </td>
                        </tr>
                        <tr>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td> <Button
                                onClick={async () => {
                                    //@ts-ignore
                                    if (newmessage[0] !== ' ') {
                                        //@ts-ignore
                                        await bc.server.tickets.sendMessageToTheTicket(ticketId, newmessage);
                                        const tickets = await bc.server.tickets.getTicketById(ticketId)
                                        console.log("le teri ticket", tickets)
                                        setTicket(tickets)
                                        setdate(tickets.created_on)
                                        setstatus(tickets.status)
                                        setcategory(tickets.category)
                                        setdescription(tickets.disc)
                                        const attac = [
                                            tickets.attachments1,
                                            tickets.attachments2,
                                            tickets.attachments3,
                                            tickets.attachments4,
                                            tickets.attachments5,
                                        ]
                                        let images = []

                                        for (let i = 0; i < attac.length; i++) {
                                            const x = attac[i]
                                            if (x != undefined) {
                                                images.push(x)
                                            }
                                        }
                                        console.log("images", images)

                                        setattachments(images)
                                        setbuyerMessages(tickets["responses"].reverse())
                                    }
                                    setnewmessage("")


                                }}
                                variant="contained"
                                className="univercel_btn_class"
                                sx={{ color: "#FFFFFF", padding: "6px 12px 6px 12px", height: "auto", margin: "10px" }}
                                startIcon={<UploadIcon />}
                            >
                                Add Response
                            </Button></td>
                        </tr>
                        <tr>
                            <td>Responses</td>
                            <td colSpan={2}>
                                <div
                                    style={{
                                        padding: "10px",
                                        display: "flex",
                                        flexDirection: "row",
                                        alignItems: "center"
                                    }}
                                >
                                    Buyer
                                    <TextField disabled={false} value={newmessage} onChange={(e) => {
                                        setnewmessage(e.target.value)
                                    }} sx={{ width: "100%", margin: "10px" }}></TextField>
                                </div>
                            </td>

                        </tr>
                        {
                            //@ts-ignore
                            buyerMessages != undefined ? buyerMessages.map((e) => {

                                return <tr>
                                    <td></td>
                                    <td colSpan={2}>
                                        <div
                                            style={{
                                                padding: "10px",
                                                display: "flex",
                                                flexDirection: "row",
                                                alignItems: "center"
                                            }}
                                        >
                                            {e.sentBy}
                                            <TextField disabled={inputdisabled} value={e.message} sx={{ width: "100%", margin: "10px" }}></TextField>
                                        </div>
                                    </td>
                                    <td>
                                        {`${e.createdat.split("T")[0]}  ${e.createdat.split("T")[1].substring(0, e.createdat.split("T")[1].length - 1).split(".")[0]}`}
                                    </td>



                                </tr>
                            }) : null
                        }
                    </table>
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "flex-end"
                        }}>
                        <Button
                            onClick={async() => {
                                await bc.server.tickets.updateCategoryOrDescriptionOrFiles({
                                    category: category,
                                    description: description,
                                    ticketid:ticketId,
                                    files:file
                                })
                                  //@ts-ignore
                                  await bc.server.tickets.sendMessageToTheTicket(ticketId, newmessage);
                                  const tickets = await bc.server.tickets.getTicketById(ticketId)
                                  console.log("le teri ticket", tickets)
                                  setTicket(tickets)
                                  setdate(tickets.created_on)
                                  setstatus(tickets.status)
                                  setcategory(tickets.category)
                                  setdescription(tickets.disc)
                                  const attac = [
                                      tickets.attachments1,
                                      tickets.attachments2,
                                      tickets.attachments3,
                                      tickets.attachments4,
                                      tickets.attachments5,
                                  ]
                                  let images = []

                                  for (let i = 0; i < attac.length; i++) {
                                      const x = attac[i]
                                      if (x != undefined) {
                                          images.push(x)
                                      }
                                  }
                                  console.log("images", images)

                                  setattachments(images)
                                  setbuyerMessages(tickets["responses"].reverse())
                            }}

                            variant="contained"
                            className="univercel_btn_class"
                            sx={{ color: "#FFFFFF", padding: "6px 12px 6px 12px", height: "auto", margin: "10px" }}
                            startIcon={<UploadIcon />}
                        >
                            Save

                        </Button>
                    </Box>


                </Box>
            </div>
        </SideBar>

    )
}

export default TicketDetailView