import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Checkbox,
  Drawer,
  FormControl,
  FormControlLabel,
  FormLabel,
  InputLabel,
  MenuItem,
  Modal,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { Stack } from "@mui/system";
import "./LDmainPage.css";
import { NavbarForDirectory } from "../../../BuilderComponents/ProjectDashboard/DashboardComponents/NavbarForDirectory";
import AddIcon from "@mui/icons-material/Add";
import SideBar from "../../Components/sideBar/SideBar";
import React, { useEffect, useState } from "react";
import { GridCloseIcon } from "@mui/x-data-grid";
import { AuthService } from "../../../../context/buyer/services/auth";
import { api } from "../../../../utils/consts";
import { BasicLayout } from "./basic_layout";
import { GetItem } from "./components/view/ViewReduser";
import FormBuilderModal, {
  getPreviewData,
} from "./components/formBuilderModal/FormBuilderModal";
import FormBuild from "./components/formBuilderModal/FormBuild";
import DeleteIcon from "@mui/icons-material/Delete";
import NavigateBack from "../../../CommonPages/Shared/NavigateBack";

export const UpdateAtIndex = (title, arr, index, newVal) => {
  // takes in an array and replaces and returns a new array
  let ar = [...arr];
  //@ts-ignore
  ar[index] = newVal;
  // // console.log({ ar })
  if (title) {
    return { title: title, ar: ar };
  } else return ar;
};

export const GetSection = (props) => {
  // // console.log({ props });
  const [SectionState, _setSectionState] = useState(props);
  const setSectionState = (e) => {
    // console.log({ e });
    _setSectionState(e);
    props.setSuperState(e);
  };

  const updateFormFields = (newFormFields) => {
    // console.log({ newFormFields });
    if (newFormFields.title) {
      setSectionState({
        ...SectionState,
        Section: {
          formFields: newFormFields.ar,
          title: newFormFields.title,
        },
      });
    } else {
      setSectionState({
        ...SectionState,
        Section: {
          formFields: newFormFields,
        },
      });
    }
  };
  // console.log("SectionState", SectionState);
  return (
    <div>
      <Stack direction="row" alignItems="center">
        <h2>{SectionState?.Section?.title ?? ""}</h2>
        {/* <DeleteIcon
          sx={{
            color: "red",
            cursor: "pointer",
            fontSize: "30px",
            marginLeft: "20px",
          }}
          onClick={()=>{}}
          /> */}
      </Stack>
      {(SectionState?.Section?.formFields ?? []).map((e, index) => {
        // // console.log("basic");
        return GetItem({
          editMode: SectionState.page == "fill",
          ...e,
          setCurrentValue: (y) => {
            if (e.type == "normal") {
              updateFormFields(
                UpdateAtIndex(
                  SectionState?.Section?.title,
                  SectionState.Section.formFields,
                  index,
                  {
                    ...e,
                    value: y,
                  }
                )
              );
            }
            if (e.type == "withtrailing") {
              updateFormFields(
                UpdateAtIndex(
                  SectionState?.Section?.title,
                  SectionState.Section.formFields,
                  index,
                  {
                    ...e,
                    value: y,
                  }
                )
              );
            }
            if (e.type == "radio") {
              updateFormFields(
                UpdateAtIndex(
                  SectionState?.Section?.title,
                  SectionState.Section.formFields,
                  index,
                  {
                    ...e,
                    options: { ...e.options, selected: y },
                  }
                )
              );
            }

            if (e.type == "check") {
              updateFormFields(
                UpdateAtIndex(
                  SectionState?.Section?.title,
                  SectionState.Section.formFields,
                  index,
                  {
                    ...e,
                    options: UpdateAtIndex("", e.options, y, {
                      ...e.options[y],
                      selected: !e.options[y].selected,
                    }),
                  }
                )
              );
            }

            if (e.type == "doubletrailing") {
              if (y.index == 1) {
                updateFormFields(
                  UpdateAtIndex(
                    SectionState?.Section?.title,
                    SectionState.Section.formFields,
                    index,
                    {
                      ...e,
                      value1: y.value,
                    }
                  )
                );
              } else {
                updateFormFields(
                  UpdateAtIndex(
                    SectionState?.Section?.title,
                    SectionState.Section.formFields,
                    index,
                    {
                      ...e,
                      value2: y.value,
                    }
                  )
                );
              }
            }
          },
        });
      })}
    </div>
  );
};

function RequirementFormTemp() {
  const [basicDetailLayout, setBasicDetailLayout] = useState(BasicLayout);
  const [ExtraSections, setExtraSections] = useState();
  const [data, setdata] = useState();
  const [open, setopen] = useState({ open: false, reason: "" });
  const [FieldN, setFieldN] = useState("");
  const [Selected, setSelected] = useState("");
  const [templateIndex, setTemplateIndex] = useState(0);
  const [TemplateData, setTemplateData] = useState([]);
  const [selectTemp, setSelectTemp] = useState("");
  const [flagDelete,setFlagDelete] = useState(false);

  const cpDetails = JSON.parse(localStorage.getItem("cpDetails"));
  // console.log(cpDetails);
  let cpOrg = cpDetails?.channelPartnerOrg;

  const fetchTemplates = async () => {
    const y = new AuthService();

    let url = "";
    // console.log(cpOrg);
    if (cpOrg) {
      url = `/api/leads/reqtemofcp/?org_id=${cpOrg}`;
    } else {
      url = `/api/leads/reqtemofcp/`;
    }

    const res = await (await y.makeAuthenticatedGetRequest(api(url))).json();
    // console.log(res.templates);
    if (res.templates != "template not found") {
      setTemplateData(res.templates);
    }
  };

  useEffect(() => {
    fetchTemplates();
    setExtraSections([]);
  }, []);

  // const DeleteSection=()=>{

  // }

  // useEffect(()=>{
  //   DeleteSection();
  // },[flagDelete])

  const handleAdd = (event) => {
    let temp = event.target.value;
    let details = temp.split("_");

    setBasicDetailLayout(BasicLayout);
    setSelectTemp(event.target.value);
    if (TemplateData[details[1]]?.other_details) {
      setExtraSections(TemplateData[details[1]]?.other_details);
      // // console.log(TemplateData[details[1]]?.other_details);
    }
    setTemplateIndex(1);
  };

  // // console.log(TemplateData);
  // // console.log(basicDetailLayout);
  // // console.log(ExtraSections);
  // // console.log(selectTemp);

  return (
    <>
      <SideBar active={"Requirements"}>
        {open && (
          <FormBuild
            ExtraSections={ExtraSections}
            setExtraSections={setExtraSections}
            Selected={Selected}
            open={open}
            setopen={setopen}
            setSelected={setSelected}
          />
        )}

        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          sx={{ height: "68px", marginBottom: "20px" }}
        >
          <Stack direction="column">
            <Typography
              sx={{
                fontSize: "22px",
                fontWeight: "600",
                color: "black",
              }}
            >
              Requirement Forms
            </Typography>
            <Typography
              sx={{
                color: "#B9B7D0",
                fontSize: "16px",
                fontWeight: "400",
              }}
            >
              Manage your Requirement Forms
            </Typography>
          </Stack>
        </Stack>

        <Stack sx={{ backgroundColor: "#f9f9f9" }}>
          <Stack spacing={2} sx={{}}>
            <Box sx={{ display: "flex", alignItems: "center", my: 2 }}>
              <NavigateBack fontSize="24px" />
              <Typography
                sx={{ fontSize: "22px", fontWeight: "600", color: "black" }}
              >
                Create New Template
              </Typography>
            </Box>
            <Stack direction="row" alignItems="center">
              <Button
                className="univercel_btn_class"
                sx={{
                  marginLeft: "20px",
                  marginRight: "32px",
                  color: "#FFFFFF",
                  textTransform: "none",
                  padding: "6px 24px",
                  fontFamily: "Product Sans",
                  fontSize: "22px",
                }}
                onClick={(e) => {
                  setTemplateIndex(1);
                  setExtraSections([]);
                  setSelectTemp("");
                }}
              >
                Create New
              </Button>
              <Typography
                sx={{
                  fontSize: "22px",
                  fontWeight: "600",
                  color: "#A4A4AD",
                  fontFamily: "Product Sans",
                  fontStyle: "normal",
                  marginRight: "32px",
                }}
              >
                or
              </Typography>
              <Typography
                sx={{
                  fontFamily: "Product Sans",
                  fontStyle: "normal",
                  fontWeight: "600",
                  fontSize: "22px",
                  lineHeight: "136.02%",
                  marginRight: "12px",
                }}
              >
                Copy from existing
              </Typography>
              <Box sx={{ width: "280px" }}>
                <Select onChange={handleAdd} value={selectTemp}>
                  {TemplateData?.map((ele, idx) => {
                    return (
                      <MenuItem value={ele?.name + "_" + idx}>
                        {ele?.name}
                      </MenuItem>
                    );
                  })}
                </Select>
              </Box>
            </Stack>
          </Stack>

          {templateIndex == 1 ? (
            <>
              <Box
                className="hidescrollbar"
                sx={{
                  marginTop: "50px",
                  width: "98%",
                  px: "30px",
                  pb: "30px",
                  background: "white",
                  borderRadius: "10px",
                  // minHeight: "75vh",
                  height:"900px",
                  overflowY: "scroll",
                  color: "black",
                  boxSizing: "border-box",
                  "&:hover": {
                    border: "1px solid #2979F2",
                    boxShadow: "-6px 18px 15px rgba(0, 0, 0, 0.1)",
                  },
                }}
              >
                <GetSection Section={basicDetailLayout}></GetSection>
                {ExtraSections.map((e, idx) => {
                  {
                    JSON.stringify(e);
                  }
                  return (
                    <Stack
                      direction="row"
                      justifyContent="space-between"
                      // alignItems="center"
                      sx={{}}
                    >
                      <GetSection Section={e}></GetSection>
                      <Box sx={{marginTop:"18px"}}>
                      <Button
                        // className="univercel_btn_class"
                        sx={{
                          color:"#E42217",
                          textTransform: "none",
                          padding: "8px 10px",
                          fontFamily: "Product Sans",
                          fontSize: "16px",
                          border:"2px solid #E42217",
                          backgroundColor:"none",
                          "&:hover":{
                            backgroundColor:"#E42217",
                            color:"#FFFFFF",
                          }
                          }}
                          onClick={() => {
                            let temp = ExtraSections?.filter((ele, index) => {
                              if (index !== idx) {
                                return ele;
                              }
                            });
                            // console.log(temp);
                            setExtraSections(temp);
                          }}
                        >
                        Delete Section
                      </Button>
                      </Box>
                      {/* <DeleteIcon
                        sx={{
                          color: "red",
                          cursor: "pointer",
                          fontSize: "30px",
                          marginTop: "25px",
                          border:"1px solid red"
                        }}
                        onClick={() => {
                          let temp = ExtraSections?.filter((ele, index) => {
                            if (index !== idx) {
                              return ele;
                            }
                          });
                          // console.log(temp);
                          setExtraSections(temp);
                        }}
                      /> */}
                    </Stack>
                  );
                })}
                <Button
                  onClick={() => {
                    setopen({ reason: "add", open: true });
                  }}
                  className="univercel_btn_class"
                  sx={{
                    mt: 3,
                    color: "#FFFFFF",
                    textTransform: "none",
                    padding: "6px 24px",
                    fontFamily: "Product Sans",
                    fontSize: "16px",
                  }}
                >
                  Add New Section
                </Button>
              </Box>

              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Button
                  onClick={() => {
                    setopen({ reason: "save", open: true });
                  }}
                  sx={{
                    padding: "10px 60px",
                    color: "#06386B",
                    textTransform: "none",
                    fontSize: "20px",
                    fontFamily: "Product Sans",
                    mt: "50px",
                    background:
                      "linear-gradient(97.85deg, #FFAF2A 0.95%, #FFC666 100%)",
                    "&:hover": {
                      boxShadow: "0px 9px 12px rgba(242, 178, 73, 0.59)",
                    },
                  }}
                >
                  Save
                </Button>
              </div>
            </>
          ) : null}
        </Stack>
      </SideBar>
    </>
  );
}

export default RequirementFormTemp;
